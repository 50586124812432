import { Edit, Visibility } from '@mui/icons-material';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';

import { GetRetailsQueryParams } from 'shared/api/visit/types';
import { theme } from 'shared/theme/theme';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { UiTableCellWithActions } from 'shared/ui/table/ui-table-cell-with-actions/UiTableCellWithActions';
import { useDevice } from 'shared/utils/hook-type-device';

import { useGetRetails } from 'entities/visit/options/useGetRetails';

import { VisitBagesForCalendar } from '../calendar/visit-bages-for-calendar';
import { MonthSelector } from '../MonthSelector';
import { generateDaysOfMonth } from './libs/generateDaysOfMonth';
import { VisitTableProps } from './type';

const STIKY_SX = {
  position: 'sticky',
  left: 0,
  background: 'white',
};

const BORDER_SX = {
  borderRight: '1px solid black',
  borderLeft: '1px solid black',
  borderColor: theme.palette.customGrey.main,
};

export const VisitTable: FC<VisitTableProps> = ({
  visits,
  selectedMonth,
  onCreateVisit,
  onOpenVisit,
  filter,
  onChangeMonth,
  loading = false,
}) => {
  const [isMobile] = useDevice();
  const retailFilter = useMemo((): GetRetailsQueryParams => {
    return {
      DepartmentsIds: filter?.VisitingDepartmentsIds,
      DivisionsIds: filter?.VisitingDivisionsIds,
      RegionsIds: filter?.VisitingRegionsIds,
      IsOnlyAllowedRequired: false,
    };
  }, [filter]);
  const { retails, isLoading } = useGetRetails(retailFilter);

  const days = useMemo(() => {
    return generateDaysOfMonth(selectedMonth);
  }, [selectedMonth]);

  if (isMobile)
    return (
      <Typography
        textAlign={'center'}
        fontWeight={'bold'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={'calc(100vh - 200px)'}
        px={4}
      >
        Данная страница пока не доступна на мобильных устройствах 😐
      </Typography>
    );

  if (isLoading || loading) return <CustomBackdrop isLoading />;
  if (
    (retailFilter.DepartmentsIds === undefined &&
      retailFilter.DivisionsIds === undefined &&
      retailFilter.RegionsIds === undefined) ||
    (retailFilter.DepartmentsIds?.length === 0 &&
      retailFilter.DivisionsIds?.length === 0 &&
      retailFilter.RegionsIds?.length === 0)
  ) {
    return (
      <Typography
        textAlign={'center'}
        fontWeight={'bold'}
        fontSize={20}
      >
        Выберите в фильтре регион, сеть или дивизион
      </Typography>
    );
  }

  if (visits.length === 0)
    return (
      <Grid
        gap={2}
        container
        flexDirection={'column'}
      >
        <MonthSelector
          onChange={onChangeMonth}
          selectedDate={selectedMonth}
        />
        <Typography
          textAlign={'center'}
          fontWeight={'bold'}
          fontSize={20}
        >
          Нет посещений в данном месяце
        </Typography>
      </Grid>
    );

  return (
    <Grid
      gap={2}
      container
      flexDirection={'column'}
    >
      <MonthSelector
        onChange={onChangeMonth}
        selectedDate={selectedMonth}
      />
      <TableContainer
        sx={{ height: 'calc(100vh - 270px)' }}
        component={Paper}
        variant='outlined'
      >
        <Table
          stickyHeader
          size='small'
        >
          {/* Header */}
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  minWidth: 100,
                  zIndex: 3,
                  borderRight: '1px solid grey',
                  borderColor: theme.palette.customGrey.main,
                  ...STIKY_SX,
                }}
              >
                Аптеки
              </TableCell>

              {days.map((day) => (
                <TableCell
                  key={day}
                  align='center'
                  sx={{
                    minWidth: 110,
                    maxWidth: 110,
                    borderRight: '1px solid grey',
                    borderColor: theme.palette.customGrey.main,
                  }}
                >
                  {day}
                </TableCell>
              ))}

              <TableCell
                align='right'
                sx={{
                  ...BORDER_SX,
                  position: 'sticky',
                  background: 'white',
                  right: 0,
                }}
              >
                Итого
              </TableCell>
            </TableRow>
          </TableHead>
          {/* ===== */}

          <TableBody>
            {retails.map((retail) => {
              return (
                <TableRow
                  hover
                  key={retail.id}
                >
                  {/* Аптеки */}
                  <TableCell
                    sx={{
                      borderRight: '1px solid grey',
                      borderColor: theme.palette.customGrey.main,
                      zIndex: 2,
                      ...STIKY_SX,
                    }}
                  >
                    {retail.shortTitle}
                  </TableCell>
                  {/* ===== */}

                  {/* Даты */}
                  {days.map((day) => {
                    const currentDate = dayjs(selectedMonth).set('D', day);
                    const currentVisit = visits.filter(
                      (visit) =>
                        visit.retailShortTitle === retail.shortTitle &&
                        dayjs(visit.visitingDate).isSame(currentDate, 'day')
                    );

                    if (currentVisit.length > 0)
                      return (
                        <UiTableCellWithActions
                          key={day}
                          actions={currentVisit.map((vis) => ({
                            onClick: () => {
                              onOpenVisit && onOpenVisit(vis);
                            },
                            text: 'Открыть посещение ' + vis.title,
                            disabled:
                              vis.visitingChecklistId === undefined ||
                              vis.visitingChecklistId === null,
                            icon: <Visibility sx={{ mr: 2 }} />,
                          }))}
                          sx={{ ...BORDER_SX }}
                        >
                          <Grid
                            width={'110px'}
                            position={'relative'}
                          >
                            <VisitBagesForCalendar visits={currentVisit} />
                          </Grid>
                        </UiTableCellWithActions>
                      );
                    else
                      return (
                        <UiTableCellWithActions
                          key={day}
                          actions={[
                            {
                              text: 'Запланировать',
                              icon: <Edit sx={{ mr: 2 }} />,
                              onClick: () => {
                                onCreateVisit &&
                                  onCreateVisit(retail.id, currentDate);
                              },
                            },
                          ]}
                          sx={{ ...BORDER_SX }}
                        />
                      );
                  })}
                  {/* ===== */}

                  {/* ИТОГО */}
                  <TableCell
                    align='right'
                    sx={{
                      ...BORDER_SX,
                      position: 'sticky',
                      background: 'white',
                      right: 0,
                    }}
                  >
                    {
                      visits.filter(
                        (visit) => visit.retailShortTitle === retail.shortTitle
                      ).length
                    }
                  </TableCell>
                  {/* ===== */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
