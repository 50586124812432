import { Typography } from '@mui/material';

import { TemplateInformationViewWithFetchData } from 'features/adaptation/template-information-view-with-fetch-data';
import { TemplateStagesList } from 'features/adaptation/TemplateStagesList/TemplateStagesList';

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridLayoutPage } from 'shared/ui/grid-layout-page';

import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { StageTemplateTaskList } from 'widgets/adaptation/StageTemplateTaskList';

export const AdaptationTemplateStagesPage = () => {
  const { templateGuid } = useParams();

  // TODO: Оч странно выглядит. МБ сделать отдельный компонент для таких случаев
  if (!templateGuid) return <Typography>Упс, что-то пошло не так</Typography>;

  const [selectedStageGuid, setSelectedStageGuid] = useState<null | string>(
    null
  );

  return (
    <LayoutPage.Page>
      <LayoutPage.Content>
        <GridLayoutPage.Container>
          <GridLayoutPage.LeftStickySection>
            <TemplateStagesList
              templateGuid={templateGuid}
              selectedStageGuid={selectedStageGuid}
              onSelectStage={setSelectedStageGuid}
            />
          </GridLayoutPage.LeftStickySection>
          <GridLayoutPage.RightTop>
            <TemplateInformationViewWithFetchData
              adaptationTemplateGuid={templateGuid}
            />
          </GridLayoutPage.RightTop>
          <GridLayoutPage.RightMainContent>
            {selectedStageGuid && (
              <StageTemplateTaskList
                stageGuid={selectedStageGuid}
                templateGuid={templateGuid}
              />
            )}
          </GridLayoutPage.RightMainContent>
        </GridLayoutPage.Container>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};

/* ============================== */
