import { RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { I_OptionProtocolStatus } from './types';

export const PROTOCOL_STATUS_TITLE = {
  [RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses.Value1]:
    'Новый',
  [RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses.Value2]:
    'В процессе',
  [RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses.Value3]:
    'Завершен',
};

export const OPTIONS_PROTOCOL_STATUSES: I_OptionProtocolStatus[] = [
  {
    id: RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses.Value1,
    label:
      PROTOCOL_STATUS_TITLE[
        RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses
          .Value1
      ],
  },
  {
    id: RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses.Value2,
    label:
      PROTOCOL_STATUS_TITLE[
        RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses
          .Value2
      ],
  },
  {
    id: RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses.Value3,
    label:
      PROTOCOL_STATUS_TITLE[
        RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses
          .Value3
      ],
  },
];
