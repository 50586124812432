import { TaxRateCalculatedResponse } from 'shared/api/services-document-flow/types';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { mixed, object, ObjectSchema } from 'yup';
import { FormCloseDocumentValue } from './types';

const REQUIRED_FIELD_TEXT = 'обязательное поле';
const REQUIRED_FIELD_TEXT_FILES = 'необходим хотя бы один файл';

export const SCHEME_VALIDATION: ObjectSchema<FormCloseDocumentValue> =
  object().shape(
    {
      isLink: SCHEMA.BOOLEAN,
      isCloseDocumentSumEquals: SCHEMA.BOOLEAN,
      closeDocumentLink: mixed<string>()
        .nullable()
        .when('isLink', ([isLink], schema) => {
          return schema.test(
            'closeDocumentLink',
            REQUIRED_FIELD_TEXT,
            (closeDocumentLink) => {
              if (!isLink) return true;

              return Boolean(closeDocumentLink);
            }
          );
        }),
      closeDocumentDate: SCHEMA.DATEJS.required(REQUIRED_FIELD_TEXT),
      closeDocumentNumber: SCHEMA.STRING_REQUIRED,
      closeDocumentSum: mixed<string | number>().when(
        'isCloseDocumentSumEquals',
        ([isCloseDocumentSumEquals], schema) => {
          return schema
            .test(
              'closeDocumentSum',
              REQUIRED_FIELD_TEXT,
              (closeDocumentSum) => {
                if (isCloseDocumentSumEquals) return true;

                return closeDocumentSum !== '' && Boolean(closeDocumentSum);
              }
            )
            .test(
              'closeDocumentSum',
              'необходимо ввести число',
              (closeDocumentSum) => {
                if (isCloseDocumentSumEquals) return true;

                return !isNaN(Number(closeDocumentSum));
              }
            )
            .test(
              'closeDocumentSum',
              'необходимо ввести положительное число',
              (closeDocumentSum) => {
                if (isCloseDocumentSumEquals) return true;
                if (closeDocumentSum === '') return true;

                return Number(closeDocumentSum) > 0;
              }
            );
        }
      ),

      closeDocumentTaxRate: mixed<TaxRateCalculatedResponse | string | number>()
        .nullable()
        .test(
          'closeDocumentTaxRate',
          'необходимо ввести число',
          (closeDocumentTaxRate) => {
            if (
              typeof closeDocumentTaxRate === 'number' ||
              typeof closeDocumentTaxRate === 'string'
            ) {
              return !isNaN(Number(closeDocumentTaxRate));
            }

            return true;
          }
        )
        .when(
          'isCloseDocumentSumEquals',
          ([isCloseDocumentSumEquals], schema) => {
            return schema.test(
              'closeDocumentTaxRate',
              REQUIRED_FIELD_TEXT,
              (closeDocumentTaxRate) => {
                if (isCloseDocumentSumEquals) return true;

                return Boolean(closeDocumentTaxRate);
              }
            );
          }
        ),
      equalsSumValue: object<FormCloseDocumentValue['equalsSumValue']>().shape({
        documentSum: mixed<string>().when(
          'isCloseDocumentSumEquals',
          ([isCloseDocumentSumEquals], schema) => {
            return schema.test(
              'closeDocumentSum',
              REQUIRED_FIELD_TEXT,
              (closeDocumentSum) => {
                if (!isCloseDocumentSumEquals) return true;

                return Boolean(closeDocumentSum);
              }
            );
          }
        ),
        documentTaxRate: mixed<TaxRateCalculatedResponse | string | number>()
          .nullable()
          .when(
            'isCloseDocumentSumEquals',
            ([isCloseDocumentSumEquals], schema) => {
              return schema.test(
                'closeDocumentTaxRate',
                REQUIRED_FIELD_TEXT,
                (closeDocumentTaxRate) => {
                  if (!isCloseDocumentSumEquals) return true;

                  return Boolean(closeDocumentTaxRate);
                }
              );
            }
          ),
      }),
      addedFiles: mixed<FormCloseDocumentValue['addedFiles']>()
        .required()
        .when(
          ['isLink', 'filesFromBackend'],
          ([isLink, filesFromBackend], schema) => {
            return schema.test(
              'addedFiles',
              REQUIRED_FIELD_TEXT_FILES,
              (addedFiles) => {
                if (isLink) return true;
                if (filesFromBackend && filesFromBackend.length > 0)
                  return true;

                return addedFiles && addedFiles.length > 0;
              }
            );
          }
        ),

      filesFromBackend: mixed<FormCloseDocumentValue['filesFromBackend']>()
        .required()
        .when(['isLink', 'addedFiles'], ([isLink, addedFiles], schema) => {
          return schema.test(
            'filesFromBackend',
            REQUIRED_FIELD_TEXT_FILES,
            (filesFromBackend) => {
              if (isLink) return true;
              if (addedFiles && addedFiles.length > 0) return true;

              return filesFromBackend && filesFromBackend.length > 0;
            }
          );
        }),
      filesDeleteFromBackend:
        mixed<FormCloseDocumentValue['filesDeleteFromBackend']>().required(),
    },
    [['filesFromBackend', 'addedFiles']]
  );
