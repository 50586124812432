import { preparationFormTaskDataForPostTaskModel } from 'features/task/form-task';
import { CreateTaskDialog } from 'features/task/form-task/FormCreatedTaskUi';
import { IFormCreatedTaskUiBase } from 'features/task/form-task/type';
import { FC } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useCreateTaskMutation } from 'shared/api/services/rtk/taskApi';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

interface ModalFormCreateSubTask {
  isOpen: boolean;
  onClose: () => void;
  taskGuid: string;
  onCreate?: () => void;
}
export const ModalFormCreateSubTask: FC<ModalFormCreateSubTask> = ({
  isOpen,
  onClose,
  taskGuid,
  onCreate,
}) => {
  const { open: openSnackbar } = useSnackbar();
  const [createTask, { isLoading, error }] = useCreateTaskMutation();

  useShowFetchErrorInSnackbar(error);

  const onSubmit = (formData: IFormCreatedTaskUiBase) => {
    const fetchData = preparationFormTaskDataForPostTaskModel(
      { ...formData, originId: 0, parentTaskGuid: taskGuid },
      false
    );

    if (fetchData)
      createTask(fetchData).then(() => {
        openSnackbar({
          duration: 3000,
          type: 'success',
          title: 'Задача создана',
        });
        onCreate && onCreate();
        onClose();
      });
  };

  return (
    <CreateTaskDialog
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};
