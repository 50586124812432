import Diversity3Icon from '@mui/icons-material/Diversity3';
import { ComponentWithUserPermission } from 'entities/user';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useDelEmployeeFromBanListMutation } from 'shared/api/service-adaptation/rtk/adaptationBanProviderApi';
import { E_ModuleGuids } from 'shared/moduleGuids';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';
import { UserNoAdaptationProcessType } from '../type';

export const renderActions: UserNoAdaptationProcessType['renderActions'] = ({
  guid,
  name,
}) => {
  const [onDeleteFromBanList, { error }] = useDelEmployeeFromBanListMutation();

  const { open: openSnackbar } = useSnackbar();

  useShowFetchErrorInSnackbar(error);

  return (
    <UiTableActions>
      <ComponentWithUserPermission
        moduleGuid={E_ModuleGuids.adaptationProcesses}
      >
        {({ isWritable }) => {
          return (
            <UiButtonAction
              disable={!isWritable}
              onClick={async () => {
                const response = await onDeleteFromBanList({
                  employeesGuids: [guid],
                });

                if ('data' in response) {
                  openSnackbar({
                    title: 'Отлично!',
                    duration: 3000,
                    text: `Сотрудник ${name} - перемещен в список "Ожидают адаптации"`,
                    type: 'success',
                  });
                }
              }}
              icon={<Diversity3Icon />}
              text={'Адаптация требуется'}
            />
          );
        }}
      </ComponentWithUserPermission>
    </UiTableActions>
  );
};
