// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { Grid } from '@mui/material';
import { FC } from 'react';

import { CustomTextField } from 'shared/ui/custom-text-field';
import { useDevice } from 'shared/utils/hook-type-device';

const MAS = [1, 2, 3, 4];

interface ProductPricesInputProps {
  value: (number | null)[];
  onChange: (value: (number | null)[]) => void;
  isError?: boolean;
  disabled?: boolean;
}

export const ProductPricesInput: FC<ProductPricesInputProps> = ({
  onChange,
  value,
  isError = false,
  disabled,
}) => {
  const [isMob] = useDevice();

  return (
    <Grid
      container
      gap={2}
      flexBasis={['100%', '27%']}
    >
      {MAS.map((_, i) => (
        <CustomTextField
          key={i}
          label={`Стоимость товара ${i + 1}`}
          propsTextField={{
            onKeyPress: (e) => {
              if (e.code === 'Enter') {
                // TODO: Костыль
                e.preventDefault();
                // e.stopPropagation();
              }
            },
            disabled,
            error: isError,
            label: isMob ? '' : `Стоимость товара ${i + 1}`,
            placeholder: 'Введите стоимость товара',
            margin: 'none',
            type: 'number',
            onVolumeChange: () => {},
            onChange: (e) => {
              const valueNew = Number(e.target.value);
              const newAns = [...value];

              newAns[i] = valueNew >= 0 ? valueNew : 0;
              onChange(newAns);
            },
            value: value ? (value[i] ? value[i] : '') : '',
          }}
        />
      ))}
    </Grid>
  );
};
