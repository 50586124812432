import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { AdvertisingSurface } from 'shared/api/marketing-surface/marketingSurfaceAutogenerateApi';
import { DATE_FORMATS } from 'shared/date-helper/constants';

import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const MARKETING_SURFACE_FIELD_MAP: FieldMapType<AdvertisingSurface>[] = [
  {
    field: 'surfaceType',
    title: 'Тип повехности',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 0,
    renderComponent: ({ surfaceType }) => {
      return <Typography>{surfaceType ? surfaceType.title : '-'}</Typography>;
    },
  },
  {
    field: 'width',
    title: 'Ширина, см',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{value.width}</Typography>;
    },
  },
  {
    field: 'height',
    title: 'Высота, см',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 2,
    renderComponent: (value) => {
      return <Typography>{value.height}</Typography>;
    },
  },
  {
    field: 'advertisingType',
    title: 'Тип рекламы',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 3,
    renderComponent: ({ advertisingType }) => {
      return (
        <Typography>{advertisingType ? advertisingType.title : '-'}</Typography>
      );
    },
  },

  {
    field: 'dateLastModified',
    title: 'Дата изменения поверхности',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 4,
    renderComponent: ({ dateLastModified }) => {
      return (
        <Typography>
          {dateLastModified
            ? dayjs(dateLastModified).format(DATE_FORMATS.clientWithTime)
            : '-'}
        </Typography>
      );
    },
  },

  {
    field: 'lastImageDateCreated',
    title: 'Дата добавления последнего изображения',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 5,
    renderComponent: ({ lastImageDateCreated }) => {
      return (
        <Typography>
          {lastImageDateCreated
            ? dayjs(lastImageDateCreated).format(DATE_FORMATS.clientWithTime)
            : '-'}
        </Typography>
      );
    },
  },
  {
    field: 'description',
    title: 'Описание',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 6,
    renderComponent: (value) => {
      return <Typography>{value.description}</Typography>;
    },
  },
];
