import { useUserDataFetch } from 'entities/user/useUserDataFetch';
import { FC } from 'react';
import {
  usePostSignUpTrainingMutation,
  usePutCancelSignUpTrainingMutation,
} from 'shared/api/traning/rtk/trainingProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { theme } from 'shared/theme/theme';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { DialigFormCancelTrainingComment } from './DialigFormCancelTrainingComment';
import { E_TRAINING_GUID_STATUSES, TrainingTableActionsProps } from './types';

export const TrainingTableActions: FC<TrainingTableActionsProps> = ({
  training,
  variant,
}) => {
  const { open: openSnackbar } = useSnackbar();

  const { statusGuid, employeeGuid, trainingEventGuid, trainingEmployeeGuid } =
    training;
  const { user } = useUserDataFetch();

  const [postSignUpTraining, { isLoading: isLoadingSignUp }] =
    usePostSignUpTrainingMutation();

  const [putCancelSignUpTraining, { isLoading }] =
    usePutCancelSignUpTrainingMutation();

  const { isOpen, open, close } = useViewModal(false);

  if (
    E_TRAINING_GUID_STATUSES['Отклонил'] === statusGuid ||
    E_TRAINING_GUID_STATUSES['Создан'] === statusGuid
  ) {
    return (
      <UiButtonAction
        text='Согласовать'
        disable={isLoadingSignUp}
        onClick={async () => {
          if (!user?.employeeGuid) return;

          const result = await postSignUpTraining({
            employeeGuid: employeeGuid || user.employeeGuid,
            trainingEventGuid: trainingEventGuid,
            type: variant === 'myTraining' ? 0 : 2,
          });

          if ('data' in result) {
            openSnackbar({
              duration: 2000,
              type: 'success',
              text: 'Запись на тренинг успешно согласованна!',
            });
          }
          if ('error' in result) {
            openSnackbar({
              duration: 2000,
              type: 'error',
              text: 'Что то пошло не так:(',
            });
          }
        }}
      />
    );
  }

  if (
    E_TRAINING_GUID_STATUSES['Согласовал'] === statusGuid ||
    E_TRAINING_GUID_STATUSES['Согласовал НР'] === statusGuid
  ) {
    return (
      <>
        <UiButtonAction
          color={theme.palette.error.main}
          onClick={open}
          text='Отклонить'
        />
        <DialigFormCancelTrainingComment
          isOpen={isOpen}
          onClose={close}
          loadingSubmite={isLoading}
          onSubmite={async (form) => {
            if (!user?.employeeGuid) return;

            const result = await putCancelSignUpTraining({
              employeeCancelerGuid: user.employeeGuid,
              trainingEventGuid: trainingEventGuid,
              trainingEmployee: trainingEmployeeGuid,
              type: variant === 'myTraining' ? 0 : 2,
              comment: form.comment,
            });

            close();

            if ('data' in result) {
              openSnackbar({
                duration: 2000,
                type: 'success',
                text: 'Запись на тренинг успешно отменена',
              });
            } else {
              openSnackbar({
                duration: 2000,
                type: 'error',
                text: 'Что то пошло не так:(',
              });
            }
          }}
        />
      </>
    );
  }

  if (
    E_TRAINING_GUID_STATUSES['Посетил'] === statusGuid ||
    E_TRAINING_GUID_STATUSES['Отсутствовал'] === statusGuid
  ) {
    return (
      <UiButtonAction
        disable
        text='Нет доступных действий'
      />
    );
  }

  return <>Появился новый статус?</>;
};
