// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useStore } from 'effector-react';
import React, { FC, ReactNode } from 'react';

import { modal } from './model';
import { UiExpensesFormModal } from './ui';

export const ExpensesFormModal: FC<{
  refArticlesInput?: React.MutableRefObject<any>;
  children: ReactNode | ReactNode[];
}> = ({ refArticlesInput, children }) => {
  const isOpenModal = useStore(modal.isOpen);

  return (
    <UiExpensesFormModal
      isOpenModal={isOpenModal}
      onClose={() => {
        modal.events.close();
        refArticlesInput?.current.focus();
      }}
    >
      {children}
    </UiExpensesFormModal>
  );
};
