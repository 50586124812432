import { useGetFiredProcessesQuery } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { renderActionsFiredAdaptations } from 'widgets/adaptation/CurrentAdaptationProcessesTable/render-actions/renderActionsFiredAdaptations';
import { AdaptationProcessesPage } from './AdaptationProcessesPage';

export const FiredAdaptationProcessesPage = () => {
  return (
    <AdaptationProcessesPage
      useGetProcesses={useGetFiredProcessesQuery}
      renderActions={renderActionsFiredAdaptations}
    />
  );
};
