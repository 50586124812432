import { Grid, SxProps, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useStoreMap } from 'effector-react';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { CustomDatePicker } from 'shared/ui/base-ui';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormEdit } from './model';

export const DocumentFormEditListAdditionalyFieldPeriod: FC = () => {
  const arrayAdditionalFields = useStoreMap(
    documentFormEdit.form,
    (document) => {
      return document && document.periodAdditionallyFields;
    }
  );
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditAdditionallyFields'
  );

  if (!arrayAdditionalFields) return <></>;

  return (
    <>
      {arrayAdditionalFields.map(
        ({ processAdditionallyField: { title, isRequired, id } }) => {
          return (
            <DocumentFormEditAdditionalyFieldPeriod
              key={id}
              required={isRequired}
              label={title}
              id={id}
              disabled={!isCanEdit}
            />
          );
        }
      )}
    </>
  );
};

const DocumentFormEditAdditionalyFieldPeriod: FC<{
  id: number;
  required?: boolean;
  label?: string;
  disabled?: boolean;
}> = ({ id, required, label = '-', disabled = false }) => {
  const value = useStoreMap(documentFormEdit.form, (form) => {
    if (form && form.stringAdditionallyFields) {
      const findPeriod = form.periodAdditionallyFields?.find(
        (item) => item.processAdditionallyField.id === id
      );

      if (findPeriod) return findPeriod;

      return { begValue: '', endValue: '' };
    } else {
      return { begValue: '', endValue: '' };
    }
  });
  const message = useStoreMap(documentFormEdit.form, (form) => {
    if (form && form.stringAdditionallyFields) {
      const findPeriod = form.periodAdditionallyFields?.find(
        (item) => item.processAdditionallyField.id === id
      );

      if (
        findPeriod &&
        findPeriod.begValue &&
        findPeriod.endValue &&
        dayjs(findPeriod.endValue) < dayjs(findPeriod.begValue)
      ) {
        return 'Дата начала не может быть больше даты окончания';
      }

      return null;
    } else {
      return null;
    }
  });
  const [isMobile] = useDevice();

  return (
    <Grid
      container
      justifyContent={'space-between'}
      gap={2}
      sx={{
        flexBasis: ['100%', '45%'],
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            backgroundColor: theme.palette.customGrey.main,
            position: 'relative',
            padding: '0px 8px',
            flexBasis: ['100%', '45%'],
          },
        }}
      >
        {label}
      </Typography>
      <Grid
        gap={2}
        sx={{
          flexBasis: ['100%', '60%'],
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
        }}
      >
        <CustomDatePicker
          label={isMobile ? '' : 'Дата начала'}
          format='DD.MM.YYYY'
          value={value.begValue ? dayjs(value.begValue) : null}
          disabled={disabled}
          onChange={(value) =>
            documentFormEdit.api.setAdditionallyFieldPeriod({
              id,
              key: 'begValue',
              value:
                value && value.isValid()
                  ? value.format('YYYY-MM-DDTHH:mm:ss')
                  : undefined,
            })
          }
          slotProps={{
            textField: {
              sx: SX_TEXTFIELD,
              color: 'warning',
              margin: 'none',
              required,
              inputProps: {
                placeholder: isMobile ? 'Дата начала' : 'дд.мм.гггг',
              },
            },
          }}
        />

        <CustomDatePicker
          label={isMobile ? '' : 'Дата окончания'}
          format='DD.MM.YYYY'
          value={value.endValue ? dayjs(value.endValue) : null}
          disabled={disabled}
          onChange={(value) =>
            documentFormEdit.api.setAdditionallyFieldPeriod({
              id,
              key: 'endValue',
              value:
                value && value.isValid()
                  ? value.format('YYYY-MM-DDTHH:mm:ss')
                  : undefined,
            })
          }
          slotProps={{
            textField: {
              disabled,
              sx: SX_TEXTFIELD,
              required,
              margin: 'none',
              inputProps: {
                placeholder: isMobile ? 'Дата окончания' : 'дд.мм.гггг',
              },
            },
          }}
        />
      </Grid>
      <Typography
        sx={{ flexBasis: '100%' }}
        color='error'
        fontSize={12}
        textAlign={'end'}
      >
        {message}
      </Typography>
    </Grid>
  );
};

const SX_TEXTFIELD: SxProps = {
  display: 'flex',
  flexBasis: ['100%', '45%'],
  flexDirection: ['column', 'row'],
  alignItems: ['stretch', 'strech'],
  [theme.breakpoints.down('sm')]: {
    '& > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline ': {
      border: 'none',
    },
  },
};
