// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { FC } from 'react';

import { UiTableCard, UiTableCardList } from 'shared/ui/table';

import { ActionsOpeningTemplateTask } from './ActionsOpeningTemplateTask';
import { OpeningTemplatesTasksTableProps } from './types';

export const OpeningTemplatesTasksList: FC<OpeningTemplatesTasksTableProps> = ({
  isUpdate,
  isLoading = false,
  onClick,
  fields,
  onChangeFields,
  visibleFields,
  data,
  templateGuid,
  stageGuid,
  countdownFieldTitle,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading || isUpdate}
    >
      {data.map((template) => (
        <UiTableCard
          data={template}
          id={String(template.guid)}
          visibleFields={visibleFields}
          key={template.guid}
          onClick={() => {
            onClick && onClick(template);
          }}
          renderActions={() => (
            <ActionsOpeningTemplateTask
              templateTask={template}
              templateGuid={templateGuid}
              stageGuid={stageGuid}
              countdownFieldTitle={countdownFieldTitle}
            />
          )}
        />
      ))}
    </UiTableCardList>
  );
};
