import { TextField } from '@mui/material';
import { useStore } from 'effector-react';
import { FC } from 'react';

import { CounterpartyModel } from 'shared/api/services-document-flow/types';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormEdit } from '../model';
import { $counterpartyOptions } from './models';

export const DocumentFormEditCounterpartyTaxNumber: FC = () => {
  const [isMobile] = useDevice();
  const options = useStore($counterpartyOptions);
  const value =
    documentFormEdit.formSelects.useSelectValueOfKey('counterparty');
  const valueInput = documentFormEdit.formSelects.useSelectValueOfKey(
    'counterpartyTaxNumber'
  );
  const isCounterpartyRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isCounterpartyRequired'
    );
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditCounterparty'
  );
  const onChangeCounterParty = (
    event: React.SyntheticEvent<Element, Event>,
    value: CounterpartyModel | string | null
  ) => {
    if (typeof value === 'string') {
      documentFormEdit.api.setFiled({
        key: 'counterpartyTaxNumber',
        value: value,
      });
    } else {
      documentFormEdit.api.setFiled({
        key: 'counterparty',
        value: value ? value : null,
      });
    }
  };

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        disabled: !isCounterpartyRequired || !isCanEdit,
        freeSolo: true,
        onChange: onChangeCounterParty,
        value: value,
        options: options,
        inputValue: valueInput || '',
        onInputChange: (event, value) => {
          documentFormEdit.api.setFiled({
            key: 'counterpartyTaxNumber',
            value: value,
          });
        },
        renderInput: (params) => (
          <TextField
            {...params}
            size='small'
            required
            disabled={!isCanEdit}
            margin={'none'}
            label={isMobile ? '' : 'ИНН'}
            placeholder='Введите ИНН контрагента'
          />
        ),
        getOptionLabel: (option) => {
          if (typeof option === 'string') {
            return option;
          } else {
            return option.taxNumber || '';
          }
        },
        renderOption: (props, option) => (
          <li
            {...props}
            key={option.id}
          >
            {option.taxNumber}
          </li>
        ),
        isOptionEqualToValue: (option, value) => option.id === value.id,
      }}
      sx={{ flexBasis: ['100%', '45%'] }}
      label='ИНН'
    />
  );
};
