// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { TaxRateCalculatedResponse } from 'shared/api/services-document-flow/types';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

export type OnchangeTaxRate = (
  e: any,
  value?: TaxRateCalculatedResponse | null | undefined | number | string
) => void;
export const UiDocumentFormTaxRatesAutocomplite: FC<{
  onFocus?: React.FocusEventHandler<HTMLDivElement> | undefined;
  disabled: boolean;
  onChange: OnchangeTaxRate;
  value: TaxRateCalculatedResponse | null | undefined | number | string;
  options: TaxRateCalculatedResponse[];
  required?: boolean;
}> = ({ disabled, onChange, options, value, onFocus, required }) => {
  const [isMobile] = useDevice();

  const [textHelper, setTextHelper] = useState<string | null>(null);

  useEffect(() => {
    if (typeof value === 'string' && isNaN(Number(value))) {
      setTextHelper('Необходимо ввеси число');
    } else {
      setTextHelper(null);
    }
  }, [value]);

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        disabled,
        onChange,
        freeSolo: true,
        value: value,
        options: options,
        forcePopupIcon: true,
        onFocus: onFocus,
        onInputChange: (_, val) => {
          const isObj = typeof value === 'object' && value !== null;

          if (isObj && val === `${value.title}  (${value.sum} Руб.)`) {
            return;
            /*  onChange({}, val); */
          } else {
            onChange({}, val);
          }
        },
        renderInput: (params) => (
          <TextField
            {...params}
            size='small'
            //type='number'

            required={required}
            label={isMobile ? '' : 'НДС'}
            placeholder='Выберите НДС'
            margin={'none'}
            helperText={textHelper}
            error={Boolean(textHelper)}
          />
        ),
        getOptionLabel: (option) => {
          if (typeof option === 'string' || typeof option === 'number') {
            return String(option);
          } else {
            return `${option.title}  (${option.sum} Руб.)` || '';
          }
        },

        renderOption: (props, option) => {
          if (typeof option === 'string' || typeof option === 'number') {
            return (
              <li
                {...props}
                key={option}
              >
                {String(option)}
              </li>
            );
          } else {
            return (
              <li
                {...props}
                key={option.id}
              >
                {`${option.title}  (${option.sum} Руб.)`}
              </li>
            );
          }
        },
        isOptionEqualToValue: () => {
          return false;
        },
      }}
      sx={{ flexBasis: ['100%', '45%'] }}
      label='НДС'
    />
  );
};
