import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { getCounterparties } from 'shared/api/services-document-flow/services';
import { ErrorResponse } from 'shared/api/services-document-flow/types';

export const fetchConterpartyOptionsFx = createEffect<
  typeof getCounterparties,
  AxiosError<ErrorResponse>
>(getCounterparties);
