import { FC } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';
import { CurrentAdaptationProcessesList } from './CurrentAdaptationProcessesList';
import { CurrentAdaptationProcessesTable } from './CurrentAdaptationProcessesTable';
import { AdaptationProcessTableType } from './type';

export const AdaptiveCurrentAdaptationProcessesTable: FC<
  AdaptationProcessTableType
> = (props) => {
  const [isMobile] = useDevice();

  if (isMobile) return <CurrentAdaptationProcessesList {...props} />;
  else return <CurrentAdaptationProcessesTable {...props} />;
};
