import { Typography } from '@mui/material';
import {
  SliderForm,
  useUpdateBannerSlider,
} from 'features/apteka-legko/banners/sliders/form-slider';
import { useParams } from 'react-router-dom';

import { LayoutPage } from 'shared/ui/layouts/layout-page';

export const PageBannersSliderEdit = () => {
  const { guid } = useParams();
  const {
    isLoadingInitialForm,
    isLoadingUpdateBannerSlider,
    handleUpdateBannerSlider,
    initialForm,
  } = useUpdateBannerSlider(guid);

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h5'
          fontWeight={'bold'}
        >
          Изменить слайд
        </Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content disabledPaddingXs>
        <SliderForm
          isLoading={isLoadingInitialForm || isLoadingUpdateBannerSlider}
          onSubmit={handleUpdateBannerSlider}
          initialValue={initialForm}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
