import { Controller } from 'react-hook-form';
import { CustomTextField } from 'shared/ui/base-ui';
import { TextFieldWithControllerComponent } from './types';
/**
 * @description связка Controller из react-hook-form и CustomDatePicker / CustomTextField / Autocomplete / Switch.
 * @see {@linkcode shared/ui/base-ui}
 */
export const TextFieldWithController: TextFieldWithControllerComponent = ({
  control,
  name,
  ...propsTextField
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <CustomTextField
            value={value}
            onChange={onChange}
            size='small'
            {...propsTextField}
            error={Boolean(error)}
            helperText={error?.message}
          />
        );
      }}
    />
  );
};
