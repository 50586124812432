// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */
// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { createApi, createEvent, createStore, sample } from 'effector-logger';
import { useStoreMap } from 'effector-react';
import { useEffect } from 'react';

import {
  ArticleResponse,
  DocumentCommentCreateRequest,
  DocumentCreateRequest,
  DocumentFirstValidateRequest,
  DocumentFirstValidationResponse,
  DocumentSecondValidateRequest,
  DocumentSecondValidationResponse,
  ErrorResponse,
  ProcessAdditionallyFieldsResponse,
  ProcessItemModel,
} from 'shared/api/services-document-flow/types';

import { getDocumentIdFx } from 'entities/document/api/document-id-effector';

import {
  fetchProcessIdFx,
  firstValidationFx,
  getProcessesProcessIdAdditionallyFieldsFx,
  postCommentDocumentFormModelFx,
  postDocumentsDocumentIdFilesCloseDocumentsFx,
  postDocumentsDocumentIdFilesReasonDocumentsFx,
  postDocumentsDraftFx,
  postDocumentsFx,
  postDocumentsSendFx,
  secondaryValidationFx,
} from './api';
import {
  setDefaultValueForPutDocumentForm,
  setField,
  setFieldDocumentFormComment,
  setFieldExpense,
} from './lib';
import {
  UseSelectArticleExpenseValue,
  UseSelectorDocumentFormModel,
  UseSelectoreArticleFirstValid,
  UseSelectoreArticleFormModel,
  UseSelectoreProcessItemModel,
} from './type';

const resetFormModel = createEvent('resetFormModel');
/*------ Stores ------*/
/* Process*/
const $processId = createStore<ProcessItemModel | null>(null, {
  name: '$process ',
}).on(fetchProcessIdFx.doneData, (_, response) => response.data);
/* Document */
const $document = createStore<Partial<DocumentCreateRequest> | null>(null, {
  name: '$document-form-model',
});

$document.on(
  getProcessesProcessIdAdditionallyFieldsFx.doneData,
  (document, response) => ({
    ...document,
    stringAdditionallyFields: response.data.stringAdditionallyFields?.map(
      ({ id }) => ({ id })
    ),
    periodAdditionallyFields: response.data.periodAdditionallyFields?.map(
      ({ id }) => ({ id })
    ),
    dateTimeAdditionallyFields: response.data.dateTimeAdditionallyFields?.map(
      ({ id }) => ({ id })
    ),
    intAdditionallyFields: response.data.intAdditionallyFields?.map(
      ({ id }) => ({ id })
    ),
    decimalAdditionallyFields: response.data.decimalAdditionallyFields?.map(
      ({ id }) => ({ id })
    ),
  })
);

/* Additionally-fields */
const $processAdditionallyFields =
  createStore<ProcessAdditionallyFieldsResponse>(
    {
      dateTimeAdditionallyFields: [],
      decimalAdditionallyFields: [],
      intAdditionallyFields: [],
      periodAdditionallyFields: [],
      stringAdditionallyFields: [],
    },
    { name: 'processAdditionallyFields' }
  );
/* comment */
const $comment = createStore<DocumentCommentCreateRequest>({
  comment: '',
});
/* files */
const $files = createStore<File[]>([]);
const apiFiles = createApi($files, {
  addFile: (files, file: File) => {
    return [...files, file];
  },
  deleteFile: (files, file: File) => {
    return files.filter((item) => item.name !== file.name);
  },
});
/* filesClose */
const $filesClose = createStore<File[]>([]);
const resetFileClose = createEvent();
const apiFilesClose = createApi($filesClose, {
  addFile: (files, file: File) => {
    return [...files, file];
  },
  deleteFile: (files, file: File) => {
    return files.filter((item) => item.name !== file.name);
  },
});

$filesClose.reset([resetFormModel, resetFileClose]);

$processAdditionallyFields.on(
  getProcessesProcessIdAdditionallyFieldsFx.doneData,
  (_, response) => response.data
);

/* validations */
const $documentFirstValidationResponse =
  createStore<DocumentFirstValidationResponse | null>(null);

$documentFirstValidationResponse
  .on(firstValidationFx.doneData, (_, response) => response.data)
  .reset(resetFormModel);

const $documentSecondValidationResponse =
  createStore<DocumentSecondValidationResponse | null>(null);

$documentSecondValidationResponse
  .on(secondaryValidationFx.doneData, (_, response) => response.data)
  .reset(resetFormModel);

/* api */
const documentFormModelApi = createApi($document, {
  initDocumentFormModel: (document, process: ProcessItemModel | null) => {
    if (!process) return null;

    return { processId: process.id, articles: [] };
  },
  setDefaultValueForPutDocumentForm,
  setField,
  setArticles: (document, articles: ArticleResponse['id'][]) => {
    if (!document) return;

    const newArticles = articles.map((id) => {
      const findArticleOnId = document.articles?.find((item) => item.id === id);

      if (findArticleOnId) {
        return { ...findArticleOnId };
      } else {
        return { id, expenses: [], sum: 0 };
      }
    });

    return { ...document, articles: newArticles };
  },
  setSumArticleId: (
    document,
    article: { id: number; sum: number | undefined }
  ) => {
    if (!document) return document;

    return {
      ...document,
      articles: document.articles?.map((item) => {
        if (item.id === article.id) {
          return { ...item, sum: article.sum };
        }

        return item;
      }),
    };
  },
  setRetailIdArticleId: (
    document,
    article: { id: number; retailId: number | undefined }
  ) => {
    if (!document) return document;

    return {
      ...document,
      articles: document.articles?.map((item) => {
        if (item.id === article.id) {
          return { ...item, retailId: article.retailId };
        }

        return item;
      }),
    };
  },
  setEstimateDocumentArticleId: (
    document,
    article: { id: number; estimateDocumentArticleId: number | undefined }
  ) => {
    if (!document) return document;

    return {
      ...document,
      articles: document.articles?.map((item) => {
        if (item.id === article.id) {
          return {
            ...item,
            estimateDocumentArticleId: article.estimateDocumentArticleId,
          };
        }

        return item;
      }),
    };
  },
  addedTypeArticles: (document, id: number) => {
    if (document) {
      const newArticle = document.articles?.map((article) => {
        if (article.id === id) {
          if (article.expenses) {
            return {
              ...article,
              expenses: [
                ...article.expenses,
                { id: 0, count: 0, price: 0, sum: 0, expenseId: 0 },
              ],
            };
          } else {
            return {
              ...article,
              expenses: [{ id: 0, count: 0, sum: 0, price: 0, expenseId: 0 }],
            };
          }
        }

        return article;
      });

      return { ...document, articles: newArticle };
    }
  },
  removeArticleidExpenseZero: (
    document
  ): Partial<DocumentCreateRequest> | null => {
    if (document) {
      return {
        ...document,
        articles: document.articles?.map((item) => {
          const filterExpenses = item.expenses?.filter(
            (expenses) => expenses.id !== 0
          );

          return {
            ...item,
            expenses:
              filterExpenses && filterExpenses.length > 0
                ? filterExpenses
                : undefined,
          };
        }),
      };
    } else {
      return document;
    }
  },
  onChangeTypeExpense: (
    document,
    payload: {
      indexExpenses: number;
      idTypeExpenses: number;
      idArticle: number;
    }
  ) => {
    if (!document) return document;

    return {
      ...document,
      articles: document.articles?.map((article) => {
        if (article.id === payload.idArticle) {
          return {
            ...article,
            expenses: article.expenses
              ? article.expenses?.map((item, index) => {
                  if (index === payload.indexExpenses) {
                    return { ...item, expenseId: payload.idTypeExpenses };
                  }

                  return item;
                })
              : [
                  {
                    expenseId: payload.idTypeExpenses,
                    price: 0,
                    count: 0,
                    sum: 0,
                    id: 0,
                  },
                ],
          };
        }

        return article;
      }),
    };
  },
  setFieldExpense,
  setAdditionallyFieldString: (
    document,
    fild: { id: number; value: string }
  ) => {
    if (document) {
      if (document.stringAdditionallyFields) {
        return {
          ...document,
          stringAdditionallyFields: document?.stringAdditionallyFields?.map(
            (item) => {
              if (item.id === fild.id) {
                return { ...item, value: fild.value };
              } else {
                return item;
              }
            }
          ),
        };
      } else {
        return { ...document, stringAdditionallyFields: [{ ...fild }] };
      }
    }
  },
  setAdditionallyFieldInt: (document, fild: { id: number; value: number }) => {
    if (document) {
      if (document.intAdditionallyFields) {
        return {
          ...document,
          intAdditionallyFields: document?.intAdditionallyFields?.map(
            (item) => {
              if (item.id === fild.id) {
                return { ...item, value: fild.value };
              } else {
                return item;
              }
            }
          ),
        };
      } else {
        return { ...document, intAdditionallyFields: [{ ...fild }] };
      }
    }
  },
  setAdditionallyFieldDec: (document, fild: { id: number; value: number }) => {
    if (document) {
      if (document.decimalAdditionallyFields) {
        return {
          ...document,
          decimalAdditionallyFields: document?.decimalAdditionallyFields?.map(
            (item) => {
              if (item.id === fild.id) {
                return { ...item, value: fild.value };
              } else {
                return item;
              }
            }
          ),
        };
      } else {
        return { ...document, decimalAdditionallyFields: [{ ...fild }] };
      }
    }
  },
  setAdditionallyFieldPeriod: (
    document,
    fild: {
      id: number;
      key: 'beginValue' | 'endValue';
      value: string | undefined;
    }
  ) => {
    if (document) {
      if (document.stringAdditionallyFields) {
        return {
          ...document,
          periodAdditionallyFields: document?.periodAdditionallyFields?.map(
            (item) => {
              if (item.id === fild.id) {
                return { ...item, [fild.key]: fild.value };
              } else {
                return item;
              }
            }
          ),
        };
      } else {
        return { ...document, stringAdditionallyFields: [{ ...fild }] };
      }
    }
  },
  setAdditionallyFieldDate: (
    document,
    fild: { id: number; value: string | undefined }
  ) => {
    if (document) {
      if (document.dateTimeAdditionallyFields) {
        return {
          ...document,
          dateTimeAdditionallyFields: document?.dateTimeAdditionallyFields?.map(
            (item) => {
              if (item.id === fild.id) {
                return { ...item, value: fild.value };
              } else {
                return item;
              }
            }
          ),
        };
      } else {
        return { ...document, dateTimeAdditionallyFields: [{ ...fild }] };
      }
    }
  },

  deleteTypeExpensions: (
    document,
    payload: {
      idArticle: number;
      indexExpenses: number;
    }
  ) => {
    if (!document) return document;

    const newArticle = document.articles?.map((article) => {
      if (article.id === payload.idArticle) {
        if (article.expenses) {
          return {
            ...article,
            expenses: article.expenses.filter(
              (item, index) => index !== payload.indexExpenses
            ),
          };
        } else {
          return { ...article };
        }
      }

      return article;
    });

    return { ...document, articles: newArticle };
  },
  onFirstValidation: () => {},
  onSecondValidation: () => {},
  postDocument: () => {},
  postDocumentSend: () => {},
  postDocumentDraft: () => {},
  putDocument: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postFiles: (store, fileName: string): void => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postFilesClose: (store, fileName: string): void => {},
});
const commentApi = createApi($comment, {
  setFieldDocumentFormComment,
});
const postComment = createEvent<number>('postComment');

$comment.reset(resetFormModel);
$processId.reset(resetFormModel);
$document.reset(resetFormModel);
$files.reset(resetFormModel);
$processAdditionallyFields.reset(resetFormModel);
/* selectors */
export const useValueDocumentFormModel: UseSelectorDocumentFormModel = (
  key
) => {
  return useStoreMap($document, (document) => {
    if (!document) return undefined;

    const value = document[key];

    return value;
  });
};
export const useValueProcess: UseSelectoreProcessItemModel = (key) => {
  return useStoreMap($processId, (document) => {
    if (!document) return undefined;

    const value = document[key];

    return value;
  });
};
export const useSelectArticleIdFormModel: UseSelectoreArticleFormModel = (
  id,
  key
) => {
  return useStoreMap({
    store: $document,
    keys: [$document],
    fn: (document) => {
      if (document) {
        const article = document.articles?.find((item) => item.id === id);

        return (article && article[key]) || null;
      }

      return null;
    },
  });
};
export const useSelectorArticleIdFirstValid: UseSelectoreArticleFirstValid = (
  id,
  key
) => {
  return useStoreMap($documentFirstValidationResponse, (firstValid) => {
    const article = firstValid?.documentArticles?.find(
      (item) => item.id === id
    );

    if (article) return article[key];
  });
};
export const useSelectArticleExpenseValue: UseSelectArticleExpenseValue = (
  id,
  index,
  key
) => {
  return useStoreMap($document, (document) => {
    if (!document) return undefined;

    const value = document.articles?.find((art) => art.id === id)?.expenses?.[
      index
    ]?.[key];

    return value || null;
  });
};

/* errors */
const $errorSecondaryValidations = createStore<ErrorResponse | null>(null, {
  name: '$errorSecondaryValidations',
});

$errorSecondaryValidations.on(secondaryValidationFx.failData, (_, err) => {
  return err.response?.data;
});
$errorSecondaryValidations.on(secondaryValidationFx.doneData, () => {
  return null;
});

const $errorFirstValidations = createStore<ErrorResponse | null>(null, {
  name: '$errorSecondaryValidations',
});

$errorFirstValidations.on(firstValidationFx.failData, (_, err) => {
  return err.response?.data;
});
$errorFirstValidations.on(firstValidationFx.doneData, () => {
  return null;
});
$errorFirstValidations.reset(resetFormModel);
$errorSecondaryValidations.reset(resetFormModel);
/* sample */

sample({
  clock: $processId,
  filter: (clock) => Boolean(clock && clock.id),
  fn: (clock) => clock?.id as number,
  target: getProcessesProcessIdAdditionallyFieldsFx,
});
sample({
  clock: documentFormModelApi.onFirstValidation,
  source: $document,
  filter: (document) =>
    Boolean(
      document &&
        document.organizationId &&
        document.articles &&
        document.articles?.length > 0 &&
        document.processId
    ),
  fn: (documnet): DocumentFirstValidateRequest => {
    const { organizationId, processId, articles } =
      documnet as Partial<DocumentCreateRequest>;

    return {
      organizationId: organizationId as number,
      processId: processId as number,
      documentArticleIds: articles?.map((item) => item.id) || [],
    };
  },
  target: firstValidationFx,
});
sample({
  clock: documentFormModelApi.onSecondValidation,
  source: $document,
  filter: (document) =>
    Boolean(
      document &&
        document.organizationId &&
        document.articles &&
        document.articles?.length > 0 &&
        document.processId
    ),
  fn: (document): DocumentSecondValidateRequest => {
    const { organizationId, processId, articles, beginPeriod, endPeriod } =
      document as Partial<DocumentCreateRequest>;

    return {
      organizationId: organizationId as number,
      processId: processId as number,
      articles: articles || [],
      beginPeriod,
      endPeriod,
    };
  },
  target: secondaryValidationFx,
});

const organizationId = $document.map((item) => item?.organizationId || null);

sample({
  clock: organizationId,
  source: $document,
  filter: (document) => {
    return Boolean(
      document && document.articles && document.articles.length > 0
    );
  },
  target: documentFormModelApi.onSecondValidation,
});
/* postDocument */
sample({
  clock: documentFormModelApi.postDocument,
  source: $document,
  filter: (document) => Boolean(document),
  fn: (document) =>
    ({
      ...document,
      closeDocumentSum: document?.isCloseDocumentSumEquals
        ? undefined
        : document?.closeDocumentSum &&
          document.closeDocumentSum.toString() !== ''
        ? document.closeDocumentSum
        : undefined,
    }) as DocumentCreateRequest,
  target: postDocumentsFx,
});

/* postDocumentDraft */
sample({
  clock: documentFormModelApi.postDocumentDraft,
  source: $document,
  filter: (document) => Boolean(document),
  fn: (document) =>
    ({
      ...document,
      articles: document?.articles?.map((item) => ({
        ...item,
        expenses:
          item.expenses && item.expenses.length > 0 ? item.expenses : undefined,
      })),
    }) as DocumentCreateRequest,
  target: postDocumentsDraftFx,
});

/* post comments */
sample({
  clock: postDocumentsDraftFx.doneData,
  source: $comment,
  filter: (source) => Boolean(source.comment && source.comment.length > 0),
  fn: (source, clock) => clock.data.id,
  target: postComment,
});
sample({
  clock: postDocumentsFx.doneData,
  source: $comment,
  filter: (source) => Boolean(source.comment && source.comment.length > 0),
  fn: (source, clock) => clock.data.id,
  target: postComment,
});
sample({
  clock: postComment,
  source: $comment,
  fn: (source, clock) => ({ documentId: clock, comment: source }),
  target: postCommentDocumentFormModelFx,
});

/* progress post document & files & comment */
const $progressPostDocumentFormModel = createStore<{
  document: {
    loading: boolean;
    complited: boolean;
    error: null | string;
  };
  comments: {
    loading: boolean;
    complited: boolean;
    error: null | string;
  } | null;
  documentSend: {
    loading: boolean;
    complited: boolean;
    error: null | string;
  } | null;
}>(
  {
    document: {
      loading: false,
      complited: false,
      error: null,
    },
    comments: null,
    documentSend: null,
  },
  { name: '$progressPostDocumentFormModel' }
);
const $progressPostReasonDocument = createStore<
  {
    file: File;
    loading: boolean;
    complited: boolean;
    error: null | string;
  }[]
>([], { name: '$progressPostReasonDocument' });
const $progressPostCloseDocument = createStore<
  {
    file: File;
    loading: boolean;
    complited: boolean;
    error: null | string;
  }[]
>([], { name: '$progressPostCloseDocument' });

$progressPostDocumentFormModel.reset(resetFormModel);
$progressPostReasonDocument.reset(resetFormModel);
$progressPostCloseDocument.reset(resetFormModel);
/* document */
$progressPostDocumentFormModel.on(postDocumentsFx.pending, (state, loading) => {
  if (!loading) return state;

  return { ...state, document: { ...state.document, loading } };
});
$progressPostDocumentFormModel.on(postDocumentsFx.done, (state) => {
  return {
    ...state,
    document: { ...state.document, complited: true, loading: false },
  };
});
$progressPostDocumentFormModel.on(postDocumentsFx.fail, (state) => {
  return {
    ...state,
    document: {
      ...state.document,
      error: 'error',
      complited: false,
      loading: false,
    },
  };
});
/* documentSend */
$progressPostDocumentFormModel.on(
  postDocumentsSendFx.pending,
  (state, loading) => {
    return { ...state, documentSend: { ...state.document, loading } };
  }
);
$progressPostDocumentFormModel.on(postDocumentsSendFx.done, (state) => {
  return { ...state, documentSend: { ...state.document, complited: true } };
});
$progressPostDocumentFormModel.on(postDocumentsSendFx.fail, (state) => {
  return {
    ...state,
    documentSend: { ...state.document, error: 'error', complited: false },
  };
});

/* document Draft */
$progressPostDocumentFormModel.on(
  postDocumentsDraftFx.pending,
  (state, loading) => {
    return { ...state, document: { ...state.document, loading } };
  }
);
$progressPostDocumentFormModel.on(postDocumentsDraftFx.doneData, (state) => {
  return { ...state, document: { ...state.document, complited: true } };
});
$progressPostDocumentFormModel.on(postDocumentsDraftFx.fail, (state) => {
  return {
    ...state,
    document: {
      ...state.document,
      error: 'error',
      complited: false,
      loading: false,
    },
  };
});
/* comment */
$progressPostDocumentFormModel.on(
  postCommentDocumentFormModelFx.pending,
  (state, loading) => {
    return { ...state, comments: { ...state.document, loading } };
  }
);
$progressPostDocumentFormModel.on(
  postCommentDocumentFormModelFx.done,
  (state) => {
    return { ...state, comments: { ...state.document, complited: true } };
  }
);
$progressPostDocumentFormModel.on(
  postCommentDocumentFormModelFx.fail,
  (state) => {
    return {
      ...state,
      comments: { ...state.document, error: 'error', complited: false },
    };
  }
);

/* files */
const setFiles = createEvent<File>('setFiles');

sample({
  clock: postDocumentsDocumentIdFilesReasonDocumentsFx,
  fn: (clock) => {
    return clock.file;
  },
  target: setFiles,
});
$progressPostReasonDocument.on(setFiles, (state, file) => {
  return [
    ...state.filter((item) => item.file.name !== file.name),
    { loading: true, file, complited: false, error: null },
  ];
});
$progressPostReasonDocument.on(
  postDocumentsDocumentIdFilesReasonDocumentsFx.done,
  (state, response) => {
    return [
      ...state.map((item) => {
        if (item.file.name === response.params.file.name) {
          return { ...item, complited: true, loading: false };
        }

        return item;
      }),
    ];
  }
);
$progressPostReasonDocument.on(
  postDocumentsDocumentIdFilesReasonDocumentsFx.fail,
  (state, response) => {
    return [
      ...state.map((item) => {
        if (item.file.name === response.params.file.name) {
          return { ...item, complited: false, error: 'error', loading: false };
        }

        return item;
      }),
    ];
  }
);

/* filesClose */
const setFilesClose = createEvent<File>('setFilesClose');

sample({
  clock: postDocumentsDocumentIdFilesCloseDocumentsFx,
  fn: (clock) => clock.file,
  target: setFilesClose,
});
$progressPostCloseDocument.on(setFilesClose, (state, file) => {
  return [
    ...state.filter((item) => item.file.name !== file.name),
    { loading: true, file, complited: false, error: null },
  ];
});
$progressPostCloseDocument.on(
  postDocumentsDocumentIdFilesCloseDocumentsFx.done,
  (state, response) => {
    return [
      ...state.map((item) => {
        if (item.file.name === response.params.file.name) {
          return { ...item, complited: true, loading: false };
        }

        return item;
      }),
    ];
  }
);
$progressPostCloseDocument.on(
  postDocumentsDocumentIdFilesCloseDocumentsFx.fail,
  (state, response) => {
    return [
      ...state.map((item) => {
        if (item.file.name === response.params.file.name) {
          return { ...item, complited: false, error: 'error', loading: false };
        }

        return item;
      }),
    ];
  }
);

const useInitFormModel = (id: number | string | undefined) => {
  return useEffect(() => {
    id && fetchProcessIdFx(Number(id));

    return () => {
      resetFormModel();
    };
  }, [id]);
};
const useInitFormEditModel = (id: number | string | undefined) => {
  return useEffect(() => {
    id && getDocumentIdFx(Number(id));

    return () => {
      resetFormModel();
    };
  }, [id]);
};

export const documentFormModel = {
  form: $document,
  formFiles: $files,
  formFilesEvent: apiFiles,
  formFilesClose: $filesClose,
  formFilesEventClose: apiFilesClose,
  formFilesResetEventClose: resetFileClose,
  firstValid: $documentFirstValidationResponse,
  errorFirstValid: $errorFirstValidations,
  secondValid: $documentSecondValidationResponse,
  errorSecondValid: $errorSecondaryValidations,
  useValueDocumentFormModel,
  useInitFormEditModel,
  formEvent: documentFormModelApi,
  process: $processId,
  processAdditionalFields: $processAdditionallyFields,
  useValueProcess,
  useInitFormModel,
  useSelectArticleIdFormModel,
  useSelectorArticleIdFirstValid,
  useSelectArticleExpenseValue,
  formPostProgress: $progressPostDocumentFormModel,
  formProcessSaveReasonDocument: $progressPostReasonDocument,
  formProcessSaveCloseDocument: $progressPostCloseDocument,
  resetFormModel,
  commentForm: $comment,
  commentFormEvent: commentApi,
};
