import { Paper, Skeleton } from '@mui/material';

export const SkeletonForm = () => {
  return (
    <Skeleton
      width={'100%'}
      height={400}
      component={Paper}
      variant='rectangular'
    />
  );
};
