import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { AdaptationProcessListItem } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const CURRENT_ADAPTATION_PROCESSES_MAP: FieldMapType<AdaptationProcessListItem>[] =
  [
    {
      field: 'employeeName',
      title: 'Имя сотрудника',
      order: 1,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.employeeName}</Typography>
      ),
    },
    {
      field: 'employeePosition',
      title: 'Должность сотрудника',
      order: 2,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.employeePosition}</Typography>
      ),
    },
    {
      field: 'adaptationTemplateTitle',
      title: 'Шаблон адаптации',
      order: 3,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.adaptationTemplateTitle || '-'}</Typography>
      ),
    },
    {
      field: 'mentorName',
      title: 'Наставник',
      order: 4,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.mentorName || '-'}</Typography>
      ),
    },
    {
      field: 'mentorPosition',
      title: 'Должность (Наставник)',
      order: 5,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.mentorPosition || '-'}</Typography>
      ),
    },

    {
      field: 'createdAt',
      title: 'Дата запуска процесса',
      order: 6,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>
          {process.createdAt
            ? dayjs(process.createdAt).format(DATE_FORMATS.clientWithTime)
            : '-'}
        </Typography>
      ),
    },
    {
      field: 'createdBy',
      title: 'Имя запустившего процесс',
      order: 7,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.createdBy || '-'}</Typography>
      ),
    },
    // {
    //   field: '',
    //   title: '',
    //   order: ,
    //   isShow: true,
    //   getTableCellProps: () => ({}),
    //   renderComponent: (process) => <Typography>{process.}</Typography>
    // },
  ];
