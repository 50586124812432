// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Divider, Grid, Typography } from '@mui/material';
import { IFormCreatedTaskUiBase } from 'features/task/form-task/type';
import { FC } from 'react';

import { deleteTasksTaskId } from 'shared/api/services/services';
import { VisitingChecklistProblemTaskGetResponse } from 'shared/api/visit/types';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { UiAddButton } from 'shared/ui/UiAddButton';

import { addedErrorObj } from 'entities/error/error-alert/error-alert-effector';

import { ProblemTaskActions } from 'widgets/tasks/problem-task-table/actions';
import { AdeptiveProblemTaskTable } from 'widgets/tasks/problem-task-table/AdeptiveProblemTaskTable';

import {
  INITIAL_STATE_FORM_TASK,
  preparationFormTaskDataForPostTaskModel,
} from 'features/task/form-task';
import { CreateTaskDialog } from 'features/task/form-task/FormCreatedTaskUi';
import { useCreateTaskMutation } from 'shared/api/services/rtk/taskApi';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { theme } from 'shared/theme/theme';
import { Tables } from 'shared/utils/controllers';
import { NavButtons } from '../../NavButtons';
import { EventsFormI, EventsFormProps, Task } from '../type';

export const EventsForm: FC<EventsFormProps> = ({
  initValue,
  onSubmit,
  onSubmitDraft,
  canComplete,
  disableAll,
  isLoading = false,
}) => {
  const [createTask, { isLoading: isCreating }] = useCreateTaskMutation();

  const {
    close: closeCreate,
    open: openCreate,
    isOpen: isOpenCreate,
    initValue: initTaskCreateValue,
  } = useViewModalWithInitFields<IFormCreatedTaskUiBase>();

  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<VisitingChecklistProblemTaskGetResponse>(
      Tables.PROBLEM_TASK
    );

  const createSubmitHandler = async (form: IFormCreatedTaskUiBase) => {
    const data = preparationFormTaskDataForPostTaskModel(form, true);

    if (!data) return;

    const fetchedData = await createTask(data);

    if (fetchedData && 'data' in fetchedData) {
      const params: EventsFormI = {
        checklistId: initValue.checklistId,
        problems: initValue.problems.map((item) => {
          if (item.idBackend === data.originAdditionallyEntityId) {
            return {
              ...item,
              tasks: [...item.tasks, { id: fetchedData.data.id } as Task],
            };
          }

          return item;
        }),
      };

      onSubmitDraft && (await onSubmitDraft(params));
    }

    closeCreate();
  };

  const isThereProblemWithoutTask =
    initValue.problems.filter((item) => item.tasks.length === 0).length > 0;

  return (
    <>
      <CreateTaskDialog
        isOpen={isOpenCreate}
        onClose={closeCreate}
        initValue={initTaskCreateValue || INITIAL_STATE_FORM_TASK}
        onSubmit={createSubmitHandler}
        isLoading={isCreating}
      />
      <Grid
        container
        component={'form'}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(initValue);
        }}
        gap={3}
      >
        <CustomBackdrop isLoading={isLoading} />
        <DocumentSectionTitle
          text='Мероприятия'
          sx={{ marginTop: 0, margin: 0 }}
        />

        {initValue.problems.map((el, index) => (
          <Grid
            key={el.id}
            container
          >
            <Grid
              container
              flexDirection={'column'}
              gap={2}
            >
              <Typography
                color={'#686868'}
                fontWeight={700}
                ml={[1, 0]}
              >
                Проблема №{index + 1}. {el.title}
              </Typography>

              <AdeptiveProblemTaskTable
                data={el.tasks}
                fields={fields}
                visibleFields={visibleFields}
                onChangeFields={setFields}
                renderActions={(task) => (
                  <ProblemTaskActions
                    task={task}
                    onDeleteTask={(taskId) => {
                      if (onSubmitDraft) {
                        deleteTasksTaskId(taskId).then(() => {
                          const params: EventsFormI = {
                            checklistId: initValue.checklistId as number,
                            problems: initValue.problems.map((item) => {
                              if (item.idBackend === el.idBackend) {
                                return {
                                  ...item,
                                  tasks: item.tasks.filter(
                                    (task) => task.id !== taskId
                                  ),
                                };
                              }

                              return item;
                            }),
                          };

                          onSubmitDraft(params);
                        });
                      } else {
                        addedErrorObj({
                          status: 'error_frontend',
                          statusText: 'error_frontend',
                          message: 'error_frontend',
                        });
                      }
                    }}
                  />
                )}
              />
              <Grid>
                <UiAddButton
                  disabled={disableAll}
                  onClick={() => {
                    openCreate({
                      ...INITIAL_STATE_FORM_TASK,
                      originAdditionallyEntityId: el.idBackend,
                      originId: 3,
                      originEntityId: initValue.checklistId,
                    });
                  }}
                >
                  Добавить мероприятие
                </UiAddButton>
              </Grid>
              <Divider sx={{ borderBottomWidth: 2 }} />
            </Grid>
          </Grid>
        ))}
        {isThereProblemWithoutTask && (
          <Typography
            sx={{ color: theme.palette.error.main }}
            paddingLeft={[1, 0]}
          >
            * Для завершения - необходимо добавить хотя бы одно мероприятие к
            каждой проблеме.{' '}
          </Typography>
        )}
        <NavButtons
          complite
          disable={!canComplete || disableAll}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
};
