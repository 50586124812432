import { Box, Grid, Typography } from '@mui/material';
import { VisitBage } from 'features/visit/VisitBage';
import { FC } from 'react';

import { VisitBagesForCalendarProps } from './type';
import { VisitBagesForCalendarSkeleton } from './VisitBagesForCalendarSkeleton';

export const VisitBagesForCalendar: FC<VisitBagesForCalendarProps> = ({
  visits,
  isLoading,
}) => {
  if (isLoading) return <VisitBagesForCalendarSkeleton />;
  if (visits.length === 0) return null;

  return (
    <Grid
      container
      flexDirection={'column'}
      gap={'5px'}
    >
      {visits.slice(0, 2).map((visit) => (
        <Box
          height={28}
          key={visit.id}
          width={'100%'}
        >
          <VisitBage visit={visit} />
        </Box>
      ))}

      {visits.length > 2 && <Typography>Ещё {visits.length - 2}</Typography>}
    </Grid>
  );
};
