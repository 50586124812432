import { AccountCircle, CheckCircleOutline } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import { CustomAutocomplete, CustomTextField } from 'shared/ui/base-ui';
import { EmployeeAvatar } from 'shared/ui/EmployeeAvatar';
import { BoardTaskFormCardFields, BoardTaskFormCardProps } from './type';

export const BoardTaskFormCard: FC<BoardTaskFormCardProps> = ({
  onSubmit,
  actions,
  initValue,
  executers,
}) => {
  // const { data: executers, isLoading: isLoadingExecuters } =
  //   useGetAllowedExecutersQuery();
  const { control, handleSubmit } = useForm<BoardTaskFormCardFields>({
    defaultValues: initValue,
  });

  return (
    <Card
      variant='outlined'
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <CardHeader
        avatar={
          <CheckCircleOutline
            color='disabled'
            sx={{ width: 30, height: 30 }}
          />
        }
        title={
          <Controller
            control={control}
            name='title'
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <CustomTextField
                required
                autoFocus
                variant='standard'
                InputProps={{ sx: { fontSize: 14 } }}
                focused
                containerSx={{ mr: 4 }}
                value={value || ''}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                placeholder='Введите название'
                label=''
                multiline
              />
            )}
          />
        }
        disableTypography
      />
      <CardContent>
        <Controller
          control={control}
          name='executor'
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <Grid
              container
              flexDirection={'row'}
              flexWrap={'nowrap'}
              gap={1}
              justifyContent={'flex-end'}
              alignItems={'flex-end'}
            >
              <CustomAutocomplete
                // loading={isLoadingExecuters}
                options={executers || []}
                isOptionEqualToValue={(eq, val) => eq.guid === val.guid}
                getOptionLabel={(empl) => {
                  return getEmployeTitle({
                    employeeName: empl.name,
                    employeePosition: empl.position?.title,
                  });
                }}
                renderOption={(props, option) => (
                  <Typography
                    {...props}
                    key={option.guid}
                    fontSize={14}
                  >
                    {getEmployeTitle({
                      employeeName: option.name,
                      employeePosition: option.position?.title,
                    })}
                  </Typography>
                )}
                sx={{ width: '100%' }}
                value={value || null}
                onChange={(_, value) => {
                  onChange(value);
                }}
                textFieldProps={{
                  InputProps: { sx: { fontSize: 14 } },
                  focused: true,
                  required: true,
                  label: '',
                  placeholder: 'Введите исполнителя',
                  variant: 'standard',
                  sx: { flex: 1 },
                }}
              />
              {value ? (
                <EmployeeAvatar
                  employee={value}
                  avatarProps={{ sx: AVATAR_SX }}
                />
              ) : (
                <Avatar sx={AVATAR_SX}>
                  <AccountCircle sx={ICON_SX} />
                </Avatar>
              )}
            </Grid>
          )}
        />
      </CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
};

const AVATAR_SX = {
  fontSize: 16,
  width: 30,
  height: 30,
};

const ICON_SX = {
  width: 23,
  height: 23,
};
