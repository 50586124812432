import { FieldMapType } from 'shared/ui/table/ui-menu-table';

// export type Tables =
//   | 'WATCH_TASKS'
//   | 'CONTROLL_TASKS'
//   | 'MY_TASKS'
//   | 'PROBLEM_TASK'
//   | 'ACTION_VISIT'
//   | 'OBSERVE_VISIT'
//   | 'ACTION_DOC'
//   | 'OBSERVE_DOC'
//   | 'CONTROLL_DOC'
//   | 'SUB_TASKS'
//   | 'TASK_INFO'
//   | 'TRAINING_CURRENT'
//   | 'TRAINING_COMPLITE'
//   | 'MY_TRAINING'
//   | 'CONTROL_TRAINING'
//   | 'TRAINING_CURRENT_SUB_TABLE'
//   | 'TRAINING_COMPLITE_SUB_TABLE';

export enum Tables {
  WATCH_TASKS = 'WATCH_TASKS',
  CONTROL_TASKS = 'CONTROLL_TASKS',
  MY_TASKS = 'MY_TASKS',
  PROBLEM_TASK = 'PROBLEM_TASK',
  ACTION_VISIT = 'ACTION_VISIT',
  OBSERVE_VISIT = 'OBSERVE_VISIT',
  ACTION_DOC = 'ACTION_DOC',
  OBSERVE_DOC = 'OBSERVE_DOC',
  CONTROL_DOC = 'CONTROLL_DOC',
  SUB_TASKS = 'SUB_TASKS',
  TASK_INFO = 'TASK_INFO',
  TRAINING_CURRENT = 'TRAINING_CURRENT',
  TRAINING_COMPLETE = 'TRAINING_COMPLITE',
  MY_TRAINING = 'MY_TRAINING',
  CONTROL_TRAINING = 'CONTROL_TRAINING',
  TRAINING_CURRENT_SUB_TABLE = 'TRAINING_CURRENT_SUB_TABLE',
  TRAINING_COMPLETE_SUB_TABLE = 'TRAINING_COMPLITE_SUB_TABLE',
  LINKS_DOCUMENT = 'LINKS_DOCUMENT',
  OPENING_RETAILS_RUNNING_TASKS_TABLE = 'OPENING_RETAILS_RUNNING_TASKS_TABLE',
  OPENING_TAMPLATES_TABLE = 'OPENING_TAMPLATES_TABLE',
  OPENING_TAMPLATES_TASKS_TABLE = 'OPENING_TAMPLATES_TASKS_TABLE',
  OPENING_RETAILS_PROCESSES_TABLE = 'OPENING_RETAILS_PROCESSES_TABLE',
  OPENING_RETAILS_PROTOCOLS_TABLE = 'OPENING_RETAILS_PROTOCOLS_TABLE',
  MARKETING_SURFACE_TABLE = 'MARKETING_SURFACE_TABLE',
  APTEKA_LEGKO_PUSH_NOTIFICATIONS_TABLE = 'APTEKA_LEGKO_PUSH_NOTIFICATIONS_TABLE_LIST',
  ADAPTATION_TEMPLATES_TABLE = 'ADAPTATION_TEMPLATES_TABLE',
  ADAPTATION_STAGE_TASKS_TABLE = 'ADAPTATION_STAGE_TASKS_TABLE',
  ADAPTATION_PROCESSES_TABLE = 'ADAPTATION_PROCESSES_TABLE',
  ADAPTATION_PROCESS_TASKS_TABLE = 'ADAPTATION_PROCESS_TASKS_TABLE',
  USER_NO_ADAPTATION_REQUIRED_TABLE = 'USER_NO_ADAPTATION_REQUIRED_TABLE',
}

export interface TableInfo {
  table: Tables;
  version: number;
  map: FieldMapType<never>[];
}
