import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { SwaggerResponse } from 'shared/api/services-auth/config';
import { EmployeePlanningHomiesGetListResponse } from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

import {
  $planningRNHomies,
  fetchPlanningRNHomiesFX,
} from './effector/planningRNHomies';

let fetching: Promise<
  SwaggerResponse<EmployeePlanningHomiesGetListResponse[]>
> | null = null;

export const useGetPlanningHomies = () => {
  const { open: openSnackbar } = useSnackbar();

  const isLoading = useStore(fetchPlanningRNHomiesFX.pending);
  const homies = useStore($planningRNHomies);

  const getHomies = async () => {
    try {
      if (homies && homies.length > 0) return;
      if (!fetching) fetching = fetchPlanningRNHomiesFX();

      const { data, status } = await fetching;

      fetching = null;

      if (!data || status !== 200) throw new Error('Что-то пошло не так');
    } catch (e) {
      console.error('useGetPlanningVisitors.getVisitors >> ', e);
      openSnackbar({
        duration: 3000,
        type: 'error',
        text: 'Упс, что-то пошло не так',
      });
    }
  };

  useEffect(() => {
    getHomies();
  }, []);

  return { isLoading, homies };
};
