/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AddCircleRounded } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { AddTaskToBoardDialog } from 'features/boards/dialogs/AddTaskToBoardDialog';
import { TaskDrawer } from 'features/task/task-drawer';
import { FC, useCallback } from 'react';
import { GetListTask } from 'shared/api/services/types';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { Tables } from 'shared/utils/controllers';
import { ModalFormCreateSubTask } from '../modal-form-create-subTask/ModalFormCreateSubTask';
import { AdeptiveSubTaskList } from '../task-table/sub-task-table/AdeptiveSubTaskList';
import { useSubTasksTab } from './hook';
import { SubTasksTabProps } from './type';

export const SubTasksTab: FC<SubTasksTabProps> = ({ canEdit, taskGuid }) => {
  const {
    changeTaskStatusModal,
    getTaskTableAction,
    handleSubmitMove,
    isMobile,
    isMovingTask,
    isOpenMove,
    subTasks,
    closeMove,
  } = useSubTasksTab(taskGuid);

  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<GetListTask>(Tables.SUB_TASKS);

  const {
    close: closeSubTask,
    isOpen: isOpenSubTask,
    open: openSubTask,
  } = useViewModal();

  const {
    close: closeDrawer,
    initValue: selectedTaskGuid,
    isOpen: isOpenDrawer,
    open: openDrawer,
  } = useViewModalWithInitFields<string>();

  const openDrawerByGuid = useCallback((sub: GetListTask) => {
    openDrawer(sub.guid);
  }, []);

  return (
    <>
      {/* Перенос задачи на доску */}
      <AddTaskToBoardDialog
        isOpen={isOpenMove}
        onClose={closeMove}
        isLoading={isMovingTask}
        onSubmit={handleSubmitMove}
      />

      {/* Создание подзадач */}
      <ModalFormCreateSubTask
        isOpen={isOpenSubTask}
        onClose={closeSubTask}
        taskGuid={taskGuid}
      />

      {!isMobile && (
        <TaskDrawer
          isOpen={isOpenDrawer}
          onClose={closeDrawer}
          taskGuid={selectedTaskGuid!}
        />
      )}

      {changeTaskStatusModal}

      <Grid container>
        <Button
          startIcon={<AddCircleRounded />}
          variant='outlined'
          onClick={openSubTask}
          disabled={!canEdit}
        >
          Создать подзадачу
        </Button>

        {subTasks && subTasks.length > 0 && (
          <AdeptiveSubTaskList
            data={subTasks}
            fields={fields}
            onChangeFields={setFields}
            visibleFields={visibleFields}
            onClick={openDrawerByGuid}
            renderActions={getTaskTableAction}
          />
        )}
      </Grid>
    </>
  );
};
