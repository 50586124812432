import { TextField } from '@mui/material';
import { FC } from 'react';

import { DocumentExpenseFirstValidationResponse } from 'shared/api/services-document-flow/types';
import { PropsAutocompliteDocumentForm } from 'shared/types/type';
import { useDevice } from 'shared/utils/hook-type-device';

import {
  CustomAutoCompliteAdaptive,
  renderOptionsCustomAutoCompliteAdaptive,
} from '../custom-autocomplite-adaptive';

export const UiExpenseTypeAutocomplite: FC<
  PropsAutocompliteDocumentForm<DocumentExpenseFirstValidationResponse>
> = ({ onChange, options, value, selectedExpenseIds }) => {
  const [isMobile] = useDevice();

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        sx: {
          width: '100%',
        },
        onChange,
        value,
        options,
        getOptionDisabled(option) {
          return Boolean(
            selectedExpenseIds?.some(
              (select) => select === option.id && !option.hasExtendedConditions
            )
          );
        },
        renderInput: (params) => (
          <TextField
            required
            {...params}
            size='small'
            label={isMobile ? '' : 'Вид расхода'}
            placeholder='Выберите вид расхода'
            margin={'normal'}
          />
        ),
        getOptionLabel: (option) => option.title || '-',
        renderOption: renderOptionsCustomAutoCompliteAdaptive,
      }}
      sx={{ flexBasis: ['100%', '45%'] }}
      label='Вид расхода'
    />
  );
};
