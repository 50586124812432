import dayjs from 'dayjs';

import { TaskCreate } from 'shared/api/services/types';

import { FORMAT_DATE_FOR_BACKEND } from '../constants';
import { STATUSES_NEW_TASK } from '../constants/statuses';
import { IFormCreatedTaskUiBase } from '../type';

type PreparationFormTaskDataForPostTaskModel = (
  form: IFormCreatedTaskUiBase,
  isDraft?: boolean
) => TaskCreate | null;

export const preparationFormTaskDataForPostTaskModel: PreparationFormTaskDataForPostTaskModel =
  (form, isDraft) => {
    if (!form.title || !form.executer) return null;

    return {
      parentTaskGuid: form.parentTaskGuid || undefined,
      isControl: form.isControl,
      isReminderCoExecutor: false,
      isReminderCreator: false,
      isReminderExecutor: false,
      isReminderObserver: false,
      isTarget: form.isTarget,
      originId: form.originId || 0,
      statusId: isDraft ? STATUSES_NEW_TASK.DRAFT.id : STATUSES_NEW_TASK.NEW.id,
      coExecutorsGuids: form.coExecuter?.map((el) => el.guid) || [],
      dateStart: form.dateStart
        ? dayjs(form.dateStart).format(FORMAT_DATE_FOR_BACKEND)
        : undefined,
      dateFinish: form.dateFinish
        ? dayjs(form.dateFinish).format(FORMAT_DATE_FOR_BACKEND)
        : undefined,
      description: form.description,
      executorGuid: form.executer.guid,
      observersGuids: form.observers?.map((el) => el.guid) || [],
      originAdditionallyEntityId: form.originAdditionallyEntityId,
      originEntityId: form.originEntityId,
      title: form.title,
      units: form.units || undefined,
      boardColumnGuid: form.boardColumnGuid || undefined,
    };
  };
