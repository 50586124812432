import { FC } from 'react';

import { CustomTextField } from 'shared/ui/custom-text-field';

export const UiExpensesFormPriceExpens: FC<{
  value: number | null | undefined;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  helperText?: string;
  error?: boolean;
}> = ({ value, onChange, helperText, error }) => {
  return (
    <CustomTextField
      propsTextField={{
        value: value || '',
        onChange,
        required: true,
        placeholder: 'Введите сумму',
        type: 'number',
        helperText,
        error,
      }}
      sx={{
        flexBasis: ['100%', '45%'],
      }}
      label='Сумма'
    />
  );
};
