import { Grid, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import iconAl from './iconAl.png';

export const PreviewPushNotificationOnMobile: FC<{
  dateTimeSending?: string;
  title?: string;
  message?: string;
  image?: string;
}> = ({ dateTimeSending, title, message, image }) => {
  const theme = useTheme();

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      flex={1}
      gap={[8]}
      sx={{
        backgroundColor: theme.palette.customGrey.main,
        borderRadius: 2,
        paddingX: [2],
        paddingY: [10],
      }}
    >
      <Grid
        display={'flex'}
        flexDirection={'column'}
      >
        <Typography
          textAlign={'center'}
          color={'white'}
          variant='h1'
          fontWeight={'bold'}
        >
          {dayjs(dateTimeSending).format('HH:MM')}
        </Typography>
        <Typography
          textAlign={'center'}
          color={'white'}
          fontWeight={'bold'}
        >
          {dayjs(dateTimeSending).format('dddd, D MMMM')}
        </Typography>
      </Grid>
      {title && (
        <Grid
          display={'flex'}
          flexDirection={'column'}
          sx={{ backgroundColor: 'white', borderRadius: 2 }}
          padding={3}
          gap={2}
        >
          <Grid
            display={'flex'}
            flexDirection={'row'}
            gap={1}
          >
            <img
              src={iconAl}
              width={55}
              height={55}
              alt='complite.png'
            />
            <Grid>
              <Typography fontWeight={'bold'}>{title}</Typography>
              <Typography
                maxHeight={'12rem'}
                overflow={'hidden'}
                maxWidth={'100%'}
                whiteSpace={'pre-line'}
              >
                {message}
              </Typography>
            </Grid>
          </Grid>
          {image && (
            <Grid
              maxHeight={178}
              position={'relative'}
            >
              <img
                src={image}
                style={{ objectFit: 'contain', width: '100%' }}
                height={'100%'}
                alt='data-image'
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
