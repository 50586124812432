import { useCallback, useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { AdaptationManagerResponsesGetListStage } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import {
  useCreateAdaptationStageMutation,
  useDeleteAdaptationStageMutation,
  useGetAdaptationStagesQuery,
  useUpdateAdaptationStagesMutation,
} from 'shared/api/service-adaptation/rtk/adaptationStagesProviderApi';
import { changeElementPositionInArray } from 'shared/utils/changeElementPositionInArray';

export const useTemplateStagesList = (templateGuid: string) => {
  const { data: rowStages, isLoading: isLoadingStages } =
    useGetAdaptationStagesQuery(templateGuid);

  const [createStage, { isLoading: isCreatingStage }] =
    useCreateAdaptationStageMutation();
  const [deleteStage, { isLoading: isDeletingStage }] =
    useDeleteAdaptationStageMutation();
  const [updateStage, { isLoading: isUpdatingStage }] =
    useUpdateAdaptationStagesMutation();

  /* ============== */
  const isLoading = useMemo(() => {
    return isCreatingStage || isDeletingStage || isUpdatingStage;
  }, [isCreatingStage, isDeletingStage, isUpdatingStage]);

  const stages = useMemo(() => {
    if (!rowStages) return [];

    return [...rowStages].sort((a, b) => a.stageNumber - b.stageNumber);
  }, [rowStages]);
  /* =============== */

  /* Creating */
  const handleCreateStage = useCallback(() => {
    if (stages)
      createStage({
        adaptationGuid: templateGuid,
        data: {
          stageNumber:
            stages.length === 0 ? 1 : stages[stages.length - 1].stageNumber + 1,
        },
      });
  }, [templateGuid, stages, createStage]);
  /* ========== */

  /* Deleting */
  const handleDeleteStage = useCallback(
    (stageGuid: string) => {
      deleteStage({ stageGuids: [stageGuid], adaptationGuid: templateGuid });
    },
    [deleteStage, templateGuid]
  );
  /* ======= */

  /* Updating */
  const handleUpdate = useCallback(
    (stages: AdaptationManagerResponsesGetListStage[]) => {
      updateStage({
        adaptationGuid: templateGuid,
        data: { stages },
      });
    },
    [updateStage, templateGuid]
  );

  const handleDrag = useCallback(
    (event: DropResult) => {
      if (event.destination) {
        if (event.destination.index === event.source.index) return;

        const newStages = changeElementPositionInArray(
          stages,
          event.source.index,
          event.destination.index
        ).map((el, i) => ({ ...el, stageNumber: i + 1 }));

        handleUpdate(newStages);
      }
    },
    [handleUpdate, stages]
  );
  /* ========= */

  return {
    isLoading,
    stages,
    isLoadingStages,
    handleDrag,
    handleDeleteStage,
    handleCreateStage,
  };
};
