import { Skeleton, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const LeftLabelWithChildren: FC<
  PropsWithChildren<{
    label: string;
    isLoading?: boolean;
    skeletonWidth?: number;
    skeletonHeight?: string | number | undefined;
  }>
> = ({
  children,
  label,
  isLoading = false,
  skeletonWidth = 200,
  skeletonHeight = '1.4em',
}) => {
  return (
    <Typography
      display={'flex'}
      flexWrap={'wrap'}
      gap={1}
    >
      <b>{label}:</b>{' '}
      {isLoading ? (
        <Skeleton
          variant='rectangular'
          width={skeletonWidth}
          height={skeletonHeight}
        />
      ) : (
        children
      )}
    </Typography>
  );
};
