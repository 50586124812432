import { AdaptationProcessesCurrentListParams } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { CONVERT_ADAPTATION_CURRENT_PRECESSES_LIST_MAP } from '../convertMaps';
import { convertURLToObject } from '../convertURLToObject';
import { URLToObjConverter } from '../type';

export const convertURLToCurrentAdaptationProcessList: URLToObjConverter<
  AdaptationProcessesCurrentListParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_ADAPTATION_CURRENT_PRECESSES_LIST_MAP
  ) as AdaptationProcessesCurrentListParams;
};
