import { FC } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTablePagination,
  UiTableRowWithActions,
} from 'shared/ui/table';

import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';

import { PushNotificationsTableActions } from './actions';
import { PushNotificationsTableProps } from './types';

export const PushNotificationsTable: FC<PushNotificationsTableProps> = ({
  isLoading,
  isUpdate,
  visibleFields,
  fields,
  onChangeFields,
  onClick,
  data,
  count,
  page,
  limit,
  onChangeLimit,
  onChangePage,
}) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={7} />}
      header={
        <UiTableHeader
          viewCells={visibleFields}
          allCells={fields}
          setViewCells={onChangeFields}
        />
      }
      body={data.map((pushNotification) => (
        <UiTableRowWithActions
          key={pushNotification.distributionGuid || Math.random()}
          onClick={() => {
            onClick?.(pushNotification);
          }}
          hover
          renderActions={() => (
            <PushNotificationsTableActions {...pushNotification} />
          )}
        >
          {visibleFields.map((el) => (
            <UiTableCell
              key={el.field}
              {...el.getTableCellProps()}
            >
              {el.renderComponent(pushNotification) || 'Error'}
            </UiTableCell>
          ))}
        </UiTableRowWithActions>
      ))}
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage + 1);
          }}
          page={page - 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};
