import { Typography } from '@mui/material';
import { RetailOpeningProcessResponsesGetListEmployee } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';

export const groupByPositions = (
  option: RetailOpeningProcessResponsesGetListEmployee
) => option.position.title;

export const getOptionLabelEmployee = (
  option: RetailOpeningProcessResponsesGetListEmployee
) => `${option.name} (${option.position.title})`;

export const renderOptionEmployee = (
  params: React.HTMLAttributes<HTMLLIElement>,
  option: RetailOpeningProcessResponsesGetListEmployee
) => (
  <Typography
    {...params}
    key={option.guid}
  >
    {getOptionLabelEmployee(option)}
  </Typography>
);

export const isOptionEqualToValueGuid = (
  option: RetailOpeningProcessResponsesGetListEmployee,
  value: RetailOpeningProcessResponsesGetListEmployee
) => option.guid === value.guid;
