import { array, object, ObjectSchema } from 'yup';

import {
  VisitingChecklistBlockGetResponse,
  VisitingChecklistItemGetResponse,
} from 'shared/api/visit/types';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';

import { EvaluationPaperFormI } from './type';

/* Form schems */
export const VisitingChecklistItemGetResponseSchema: ObjectSchema<VisitingChecklistItemGetResponse> =
  object().shape({
    isCommentAllowed: SCHEMA.BOOLEAN.required(),
    comment: SCHEMA.STRING,
    itemId: SCHEMA.NUMBER.required(),
    value: SCHEMA.BOOLEAN.required(),
    itemTitle: SCHEMA.STRING,
  });

export const VisitingChecklistBlockGetResponseSchema: ObjectSchema<VisitingChecklistBlockGetResponse> =
  object().shape({
    blockId: SCHEMA.NUMBER.required(),
    blockTitle: SCHEMA.STRING,
    items: array(VisitingChecklistItemGetResponseSchema.required()),
  });

export const EvaluationPaperFormSchema: ObjectSchema<EvaluationPaperFormI> =
  object().shape({
    blocks: array(
      VisitingChecklistBlockGetResponseSchema.required()
    ).required(),
  });
/* ==== */
