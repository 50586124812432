import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

/* utils */
export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  /* padding: grid * 2,
      margin: `0 0 ${grid}px 0`, */

  // change background colour if dragging
  /* background: isDragging ? "lightgreen" : "grey", */

  // styles we need to apply on draggables
  ...draggableStyle,
});
