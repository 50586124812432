import { Add, Delete } from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { FC, memo, ReactNode, useState, useTransition } from 'react';
import { useParams } from 'react-router-dom';

import { useCreateTemplateStageMutation } from 'features/retail-opeting-process/use-create-template-stage';

import { useUpdateTemplateStagesDnD } from 'features/retail-opeting-process/use-update-template-stages-dnd';
import {
  useGetOpeningTemplateDetailQuery,
  useGetOpeningTemplatesStagesQuery,
  useGetOpeningTemplatesStageTasksListQuery,
} from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { DnDItem, DnDList } from 'shared/dnd';

import { OpeningTemplateMainInformation } from 'entities/retail-opening-process/opening-template-main-information';

import { useConfirmDeleteTemplateStage } from 'features/retail-opeting-process/delete-template-stage';
import { theme } from 'shared/theme/theme';
import { DialogConfirmForm } from 'shared/ui/form';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { ActionCreateTemplateTaskWithSpeedDial } from 'widgets/retail-opening-process/action-create-template-task-with-speed-dial';
import {
  OpeningTemplatesTasksAdaptiveTable,
  useTableCellsOpeningTamplatesTasksFromLocalStorage,
} from 'widgets/retail-opening-process/opening-templatest-tasks-table';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { GridLayout } from './layouts/lauout-with-stage-mainInfo-table';

export const PageOpeningTemplateGuid = () => {
  const params = useParams();

  const [selectStagerGuid, setSelectStagerGuid] = useState<string | null>(null);

  /* GET TEMPLATE */
  const { data: openingTemplate, isLoading: isLoadingOpeningTemplate } =
    useGetOpeningTemplateDetailQuery(
      { guid: params.guid as string },
      { skip: !params.guid }
    );

  /* GET STAGES */
  const {
    data: stages,
    isFetching: isFetchingSteges,
    isLoading: isLoadingSteges,
  } = useGetOpeningTemplatesStagesQuery(
    { templateGuid: params.guid as string },
    {
      skip: !params.guid,
    }
  );

  /* CREATE STAGE */
  const { hanldeCreateStage, isLoadingCreateTemplateStage } =
    useCreateTemplateStageMutation(
      params.guid || null,
      stages ? stages.length + 1 : 1
    );

  /* update stage */

  const { isLoadingUpdatesOrderStages, handleDragEnd } =
    useUpdateTemplateStagesDnD({
      templateGuid: params.guid || null,
      stages: stages || EMPT_ARR,
    });

  const disabledMutationStageActions =
    isLoadingCreateTemplateStage || isFetchingSteges || isLoadingSteges;

  return (
    <LayoutPage.Page>
      <LayoutPage.Content>
        <GridLayout.Container>
          <GridLayout.LeftStickySection>
            {isLoadingSteges && !stages && (
              <Grid
                display={'flex'}
                flexGrow={1}
              >
                <Skeleton
                  width={'100%'}
                  height={300}
                  variant='rectangular'
                />
              </Grid>
            )}

            {stages && (
              <>
                <ContainerStage>
                  <DnDList
                    onDragEnd={handleDragEnd}
                    isDropDisabled={isLoadingUpdatesOrderStages}
                    droppableId={'openingRetailTemplateStagesList'}
                  >
                    {stages.map((stage, index) => {
                      return (
                        <DnDItem
                          key={stage.guid}
                          isDragDisabled={isLoadingUpdatesOrderStages}
                          draggableId={stage.guid}
                          dragEventOnlyIcon
                          index={index}
                        >
                          <ItemStage
                            key={stage.guid}
                            stageGuid={stage.guid}
                            selected={stage.guid === selectStagerGuid}
                            stageNumber={stage.stageNumber}
                            templateGuid={params.guid}
                            selectStage={setSelectStagerGuid}
                            disabledDeleteButton={disabledMutationStageActions}
                          />
                        </DnDItem>
                      );
                    })}
                  </DnDList>
                </ContainerStage>
                <LoadingButton
                  loading={isLoadingCreateTemplateStage}
                  disabled={disabledMutationStageActions}
                  fullWidth
                  onClick={hanldeCreateStage}
                  startIcon={<Add />}
                  variant='outlined'
                >
                  добавить этап
                </LoadingButton>
              </>
            )}
          </GridLayout.LeftStickySection>
          <GridLayout.RightMainContent>
            {openingTemplate && selectStagerGuid && (
              <>
                <OpeningRetailProcessTemplateStageTasksList
                  templateGuid={openingTemplate.guid}
                  stageGuid={selectStagerGuid}
                  countdownFieldTitle={openingTemplate.countdownField.title}
                />
                <ActionCreateTemplateTaskWithSpeedDial
                  templateGuid={openingTemplate.guid}
                  stageGuid={selectStagerGuid}
                  countdownFieldTitle={openingTemplate.countdownField.title}
                />
              </>
            )}
          </GridLayout.RightMainContent>
          <GridLayout.RightTop>
            <Paper sx={{ p: 1 }}>
              <OpeningTemplateMainInformation
                openingTemplate={openingTemplate}
                isLoading={isLoadingOpeningTemplate}
              />
            </Paper>
          </GridLayout.RightTop>
        </GridLayout.Container>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};

/* TEBLE_TASK_WITH */

const OpeningRetailProcessTemplateStageTasksList: FC<{
  templateGuid: string;
  stageGuid: string;
  countdownFieldTitle: string;
}> = ({ templateGuid, stageGuid, countdownFieldTitle }) => {
  const [allFields, onChangeFields, visibleFields] =
    useTableCellsOpeningTamplatesTasksFromLocalStorage();

  const {
    data: templatesTasks,
    isFetching: isFetchingTemplatesTasks,
    isLoading: isLoadingTemplatesTasks,
  } = useGetOpeningTemplatesStageTasksListQuery({
    templateGuid,
    stageGuid,
  });

  return (
    <Grid component={Paper}>
      <OpeningTemplatesTasksAdaptiveTable
        data={templatesTasks || EMPT_ARR}
        fields={allFields}
        isLoading={isLoadingTemplatesTasks}
        isUpdate={isFetchingTemplatesTasks}
        visibleFields={visibleFields}
        onChangeFields={onChangeFields}
        templateGuid={templateGuid}
        stageGuid={stageGuid}
        countdownFieldTitle={countdownFieldTitle}
      />
    </Grid>
  );
};

/* CONTAINER_STAGE */
const ContainerStage: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Grid
      flexGrow={1}
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      sx={{
        maxWidth: '100%',
        '& > *': {
          flexGrow: 1,
        },
      }}
    >
      <List
        subheader={<ListSubheader>Этапы</ListSubheader>}
        disablePadding
        sx={{
          maxHeight: 400,
          overflowY: 'auto',
          ...theme.mixins.scrollBar,
        }}
      >
        {children}
      </List>
    </Grid>
  );
};

/* ITEM_STAGE */
interface ItemStageProps {
  stageNumber: number;
  stageGuid: string;
  disabledDeleteButton?: boolean;
  selectStage: (guid: string) => unknown;
  selected?: boolean;
  templateGuid?: string | null;
}

const ItemStage: FC<ItemStageProps> = memo(
  ({
    disabledDeleteButton,

    stageGuid,
    stageNumber,
    selectStage,
    selected,
    templateGuid,
  }) => {
    const [isPending, startTransitions] = useTransition();
    const {
      isLoadingDeleteTemplateStage,
      onCancelDeleteTemplateStage,
      onConfirmDeleteTemplateStage,
      onOpenDialogConfirmDeleteTempateStage,
      isOpenConfirmDialogDeleteTemplateStage,
    } = useConfirmDeleteTemplateStage(templateGuid || null, stageGuid);

    return (
      <>
        <ListItem
          sx={{ width: '100%' }}
          disablePadding
        >
          <LoadingButton
            color='error'
            disabled={disabledDeleteButton}
            onClick={onOpenDialogConfirmDeleteTempateStage}
            sx={{ minWidth: 0 }}
          >
            <Delete />
          </LoadingButton>
          <ListItemButton
            selected={selected || isPending}
            onClick={() => {
              startTransitions(() => {
                selectStage(stageGuid);
              });
            }}
          >
            <ListItemText>{`${stageNumber}  Этап`}</ListItemText>
          </ListItemButton>
        </ListItem>
        <DialogConfirmForm
          title='Удалить процесс'
          open={isOpenConfirmDialogDeleteTemplateStage}
          onClose={onCancelDeleteTemplateStage}
          isLoading={isLoadingDeleteTemplateStage}
          onConfirmation={onConfirmDeleteTemplateStage}
        >
          <Typography>{`Вы уверены что хотите удалить ${stageNumber} Этап?`}</Typography>
        </DialogConfirmForm>
      </>
    );
  }
);
