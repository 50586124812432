import { TaskDrawer, useTaskDrawer } from 'features/task/task-drawer';
import { FC } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTableRow,
} from 'shared/ui/table';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';
import { AdaptationProcessTasksTableType } from './type';

export const AdaptationProcessTasksTable: FC<
  AdaptationProcessTasksTableType
> = ({
  data: tasks,
  isLoading = false,
  isUpdate = false,
  fields,
  onChangeFields,
  visibleFields,
}) => {
  const { isOpenDrawer, tableClickHandler, selectedTaskGuid, closeDrawer } =
    useTaskDrawer();

  return (
    <>
      <UiTable
        isLoading={isLoading}
        isUpdating={isUpdate}
        skeleton={<UiTableSkeleton limit={5} />}
        header={
          <UiTableHeader
            allCells={fields}
            viewCells={visibleFields}
            setViewCells={onChangeFields}
          />
        }
        body={
          <>
            {tasks &&
              tasks.length !== 0 &&
              tasks.map((task) => (
                <UiTableRow
                  key={task.guid}
                  hover
                  onClick={() => {
                    tableClickHandler(task);
                  }}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: task.isOverdue
                      ? 'rgba(255, 0, 0, 0.1)'
                      : undefined,
                  }}
                >
                  {visibleFields.map((el) => (
                    <UiTableCell
                      key={el.field + task.guid}
                      {...el.getTableCellProps()}
                    >
                      {el.renderComponent(task) || 'Error'}
                    </UiTableCell>
                  ))}
                </UiTableRow>
              ))}
          </>
        }
      />
      <TaskDrawer
        isOpen={isOpenDrawer}
        onClose={closeDrawer}
        taskGuid={selectedTaskGuid}
        // afterDelete={update}
      />
    </>
  );
};
