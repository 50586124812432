import { useStore } from 'effector-react';

import {
  OnchangeTaxRate,
  UiDocumentFormTaxRatesAutocomplite,
} from 'shared/ui/UiDocumentFormTaxRatesAutocomplite';

import { AxiosError } from 'axios';
import { createEffect, createStore } from 'effector';
import { getTaxRatesCalculated } from 'shared/api/services-document-flow/services';
import { TaxRateCalculatedResponse } from 'shared/api/services-document-flow/types';

import { documentFormModel } from 'entities/document-form';
import { useEffect } from 'react';
import { DocumentFormTaxRatesAutocomplite } from '../document-form-tax-rates-autocomplite/DocumentFormTaxRatesAutocomplite';

export const fetchCloseTaxRatesEditOptionsFx = createEffect<
  typeof getTaxRatesCalculated,
  AxiosError<Error>
>(getTaxRatesCalculated);

const $taxRateEditOptions = createStore<TaxRateCalculatedResponse[]>([]).on(
  fetchCloseTaxRatesEditOptionsFx.doneData,
  (_, response) => response.data
);

export const DocumentFormCloseTaxRatesAutocomplite = () => {
  const options = useStore($taxRateEditOptions);
  const valueTaxRateId = documentFormModel.useValueDocumentFormModel(
    'closeDocumentTaxRateId'
  );
  const valueTaxRate =
    (valueTaxRateId && options.find((item) => item.id === valueTaxRateId)) ||
    null;
  const valueTaxSum = documentFormModel.useValueDocumentFormModel(
    'closeDocumentTaxSum'
  );
  const closeDocumentSum =
    documentFormModel.useValueDocumentFormModel('closeDocumentSum');
  const value =
    (valueTaxRate &&
      (valueTaxSum || valueTaxSum === 0) && {
        ...valueTaxRate,
        sum: valueTaxSum,
      }) ||
    null;

  const handleChangeTaxRate: OnchangeTaxRate = (event, value) => {
    if (typeof value === 'string' || typeof value === 'number') {
      documentFormModel.formEvent.setField({
        key: 'closeDocumentTaxSum',
        value: value ? value : null,
      });
      documentFormModel.formEvent.setField({
        key: 'closeDocumentTaxRateId',
        value: null,
      });
    } else {
      documentFormModel.formEvent.setField({
        key: 'closeDocumentTaxRateId',
        value: value ? value.id : null,
      });
      documentFormModel.formEvent.setField({
        key: 'closeDocumentTaxSum',
        value: value?.sum,
      });
    }
  };

  useEffect(() => {
    if (options.length > 0 && value) {
      const find = options.find((item) => item.id === value.id);

      if (find?.sum !== value.sum) {
        documentFormModel.formEvent.setField({
          key: 'closeDocumentTaxSum',
          value: find ? find.sum : null,
        });
      }
    }
  }, [options]);

  const isTaxCalculateRequired = documentFormModel.useValueProcess(
    'isTaxCalculateRequired'
  );
  const isCloseDocumentSumEquals = documentFormModel.useValueDocumentFormModel(
    'isCloseDocumentSumEquals'
  );

  return !isCloseDocumentSumEquals ? (
    <UiDocumentFormTaxRatesAutocomplite
      disabled={!isTaxCalculateRequired}
      onChange={handleChangeTaxRate}
      onFocus={() => {
        closeDocumentSum &&
          fetchCloseTaxRatesEditOptionsFx({ Sum: closeDocumentSum });
      }}
      options={options}
      value={value || valueTaxSum}
    />
  ) : (
    <DocumentFormTaxRatesAutocomplite disabled />
  );
};
