import dayjs from 'dayjs';

import { BasicVisitInfoFormI, VisitingGetResponseNew } from '../type';

type PrepareDataToInitStage1Type = (
  data: VisitingGetResponseNew
) => BasicVisitInfoFormI;

export const prepareDataToInitStage1: PrepareDataToInitStage1Type = (data) => {
  return {
    checklist: data.checklist,
    visitingDate: dayjs(data.visitingDate),
    homies: data.members || [],
    retails: [data.retail],
    maker:
      (data.visitor.employeeName || '') + (data.visitor.employeePosition || ''),
  };
};
