import { BanEmployeForAdaptationProcess } from 'features/adaptation/ban-employe-for-adaptation-process/BanEmployeForAdaptationProcess';
import { PreparatoryRunAdaptationProcessAction } from 'features/adaptation/preparatory-run-adaptation-process-form';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';
import { AdaptationProcessTableType } from '../type';

export const renderActionsWaitingAdaptations: AdaptationProcessTableType['renderActions'] =
  ({ employeeGuid, employeeName }) => {
    return (
      <UiTableActions>
        <PreparatoryRunAdaptationProcessAction
          employeeGuid={employeeGuid}
          employeeName={employeeName}
        />
        <BanEmployeForAdaptationProcess employeeGuid={employeeGuid} />
      </UiTableActions>
    );
  };
