import { Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface anyObject {}

export const createIsOptionEqualToValueByKey = <T extends anyObject>(
  key: keyof T
) => {
  return (option: T, value: T) => option[key] === value[key];
};

export const createLabelOnKey = <T extends anyObject>(
  key: keyof T
): ((option: T) => string) => {
  return (option: T) => {
    const label = String(option[key]);

    return label;
  };
};

export const createRenderOptionByKey = <T extends anyObject>({
  keyGuid,
  keyLabel,
}: {
  keyGuid: keyof T;
  keyLabel: keyof T;
}) => {
  return (props: React.HTMLAttributes<HTMLLIElement>, option: T) => {
    const key = String(option[keyGuid]);
    const label = String(option[keyLabel]);

    return (
      <Typography
        {...props}
        key={key}
      >
        {label}
      </Typography>
    );
  };
};
