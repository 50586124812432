import { Button } from '@mui/material';
import { useUserDataFetch } from 'entities/user/useUserDataFetch';
import { FC, useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { OnlineStoreRequestsFirebaseCreateMessage } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { usePostFirebaseDistributionTestMutation } from 'shared/api/online-store-service/firebase/rtk';
import { useViewModal } from 'shared/hooks/useViewModal';
import { phoneNormolized } from 'shared/phone-helper/phone-helper';
import { useFormPushNotificationsContext } from '../forms/form-push-notifications/hooks';
import { TestNotificationFrom } from '../forms/form-test-notification';

export const TestNotificationActionButton: FC<{ disabled: boolean }> = ({
  disabled,
}) => {
  const { open, isOpen, close } = useViewModal();

  const { control } = useFormPushNotificationsContext();

  const title = useWatch({ control, name: 'distributionTitle' });
  const message = useWatch({ control, name: 'message' });
  const url = useWatch({ control, name: 'url' });
  const imageData = useWatch({ control, name: 'imageData' });

  const { user, loading: isLoadingUserData } = useUserDataFetch();

  const initialForm = useMemo(() => {
    if (title === '') {
      return null;
    }

    return {
      title,
      message,
      url,
      imageData: imageData?.data,
      phone: user?.cellular,
    };
  }, [title, message, url, imageData, user?.cellular]);

  const [
    createTestNotification,
    { isLoading: isLoadingSendingTestNotofication, error, data, reset },
  ] = usePostFirebaseDistributionTestMutation();

  useShowFetchErrorInSnackbar(error);

  const onSubmit = useCallback(
    async ({
      message,
      url,
      ...other
    }: OnlineStoreRequestsFirebaseCreateMessage) => {
      const response = await createTestNotification({
        params: {
          ...other,
          phone: phoneNormolized(other.phone),
          message: message !== '' ? message : undefined,
          url: url !== '' ? url : undefined,
        },
      });

      if ('data' in response) {
        console.log(response);
      }
    },
    [createTestNotification]
  );

  return (
    <>
      <Button
        onClick={open}
        disabled={disabled || !initialForm || isLoadingUserData}
        variant={'contained'}
        color={'customBlue'}
      >
        Тестовое уведомление
      </Button>
      {initialForm && (
        <TestNotificationFrom
          isLoading={isLoadingSendingTestNotofication}
          onSubmit={onSubmit}
          initialForm={initialForm}
          isOpen={isOpen}
          onClose={() => {
            reset();
            close();
          }}
          messageRespons={data}
        />
      )}
    </>
  );
};
