import { STAGES } from '../constants';
import { StageStatuses } from '../type';

export const checkStatusWithSelected: checkStatusWithSelected = (
  currPath,
  stage,
  is
) => {
  if (currPath === stage) return StageStatuses.selected;

  return is ? StageStatuses.available : StageStatuses.disable;
};

type checkStatusWithSelected = (
  currPath: string,
  stage: STAGES,
  is?: boolean
) => StageStatuses;
