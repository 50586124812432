import { Box, Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useStore, useStoreMap } from 'effector-react';
import {
  $retailsOptions,
  fetchRetailFx,
} from 'features/document/expenses-form-retail-autocomplite/model';
import { UiRetailAutocomplite } from 'features/document/expenses-form-retail-autocomplite/ui/UiRetailAutocomplite';
import {
  textNormSumArticle,
  UiTotalSumArticle,
} from 'features/document/expenses-form-total-sum-article/UiTotalSumArticle';
import { FC, memo, useCallback, useEffect, useMemo } from 'react';

import {
  DocumentExpenseFirstValidationResponse,
  DocumentExtendedConditionFirstValidationResponse,
} from 'shared/api/services-document-flow/types';
import { PropsAutocompliteDocumentForm } from 'shared/types/type';
import {
  UIArticleDescription,
  UiArticleTitle,
  UiButtonAddedTypeArticle,
  UiExpenseFormExpendetCondition,
} from 'shared/ui/article';
import { UiExpensesFormPriceExpens } from 'shared/ui/article/UiExpensesFormPriceExpens';
import { UiExpenseTypeAutocomplite } from 'shared/ui/article/UiExpenseTypeAutocomplite';
import { CustomTextField } from 'shared/ui/custom-text-field';

import { UiEstimateAutocomplite } from 'widgets/expenses-form-article/ExpensesFormArticle';

import { documentFormEdit } from '../model';

export const ExpensesFormEditListArticles = () => {
  const articles =
    documentFormEdit.formSelects.useSelectValueOfKey('documentArticles');

  if (!articles) return null;

  return (
    <Grid>
      {articles.map((article) => {
        return (
          <ArticleId
            id={article.articleId}
            key={article.articleId}
          />
        );
      })}
    </Grid>
  );
};

const ArticleId: FC<{ id: number }> = ({ id }) => {
  const hasExpenses =
    documentFormEdit.firstValidSelectors.useSelectorArticleIdFirstValid(
      id,
      'hasExpenses'
    );
  const title =
    documentFormEdit.firstValidSelectors.useSelectorArticleIdFirstValid(
      id,
      'title'
    );
  const sum = documentFormEdit.formSelects.useSelectArticleIdFormEdit(
    id,
    'sum'
  );

  const normSumArticle =
    documentFormEdit.firstValidSelectors.useSelectorArticleIdFirstValid(
      id,
      'normSum'
    );
  const notValid = Boolean(normSumArticle && sum && normSumArticle < sum);

  const articleDescriptionInformation = useStoreMap({
    store: documentFormEdit.firstdValid,
    keys: [documentFormEdit.firstdValid],
    fn: (response) => {
      if (response && response.documentArticles) {
        return (
          response.documentArticles
            .find((item) => item.id === id)
            ?.expenses?.map((expense) => ({
              description: expense.description,
              id: expense.id,
            })) || null
        );
      }

      return null;
    },
  });

  const isEstimated = useStoreMap(documentFormEdit.form, (state) => {
    if (state) return state.process.isEstimated;

    return false;
  });
  const isArticleRetailDisplayed = useStoreMap(
    documentFormEdit.form,
    (state) => {
      if (state) return state.process.isArticleRetailDisplayed;

      return false;
    }
  );
  const isArticleRetailRequired = useStoreMap(
    documentFormEdit.form,
    (state) => {
      if (state) return state.process.isArticleRetailRequired;

      return false;
    }
  );
  const ProcessId =
    documentFormEdit.formSelects.useSelectProcessValueOfKey('id');
  const estimateDocumentArticle =
    documentFormEdit.formSelects.useSelectArticleIdFormEdit(
      id,
      'estimateDocumentArticle'
    );
  const retail = documentFormEdit.formSelects.useSelectArticleIdFormEdit(
    id,
    'retail'
  );

  if (!hasExpenses) {
    return (
      <Box
        component={'article'}
        sx={{
          margin: '16px 0px 8px 0px',
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <UiArticleTitle
          variant={'not-expenses'}
          title={title || 'not_title'}
        />
        {isArticleRetailDisplayed && (
          <RetailAutocompliteFormEdit
            idArticle={id}
            required={isArticleRetailRequired}
            //indexExpenses={0}
          />
        )}
        {isEstimated && (
          <UiEstimateAutocomplite
            ArticleId={id}
            RetailId={retail?.id}
            ProcessId={ProcessId}
            value={
              (estimateDocumentArticle &&
                estimateDocumentArticle.estimateDocumentArticleId) ||
              null
            }
            setValue={(es) => {
              documentFormEdit.api.setEstimateDocumentArticle({
                id,
                estimateDocumentArticle: es,
              });
            }}
          />
        )}
        <UiTotalSumArticle
          value={sum}
          onChange={(event) => {
            documentFormEdit.api.setSumArticleId({
              id,
              sum: Number(event.target.value),
            });
          }}
          error={notValid}
          helperText={textNormSumArticle(normSumArticle)}
        />
      </Box>
    );
  }

  return (
    <Box
      component={'article'}
      sx={{
        flexBasis: '100%',
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
      }}
    >
      <UiArticleTitle
        variant={'expenses'}
        title={title || 'not_title'}
      />
      <UIArticleDescription description={articleDescriptionInformation || []} />
      {isArticleRetailDisplayed && (
        <RetailAutocompliteFormEdit
          idArticle={id}
          required={isArticleRetailRequired}
          //indexExpenses={0}
        />
      )}
      {isEstimated && (
        <UiEstimateAutocomplite
          ArticleId={id}
          RetailId={retail?.id}
          ProcessId={ProcessId}
          value={
            (estimateDocumentArticle &&
              estimateDocumentArticle.estimateDocumentArticleId) ||
            null
          }
          setValue={(es) => {
            documentFormEdit.api.setEstimateDocumentArticle({
              id,
              estimateDocumentArticle: es,
            });
          }}
        />
      )}

      <ListExpensesFormEdit id={id} />
      <UiButtonAddedTypeArticle
        onClick={() => documentFormEdit.api.addedTypeArticles(id)}
      />
    </Box>
  );
};

const ListExpensesFormEdit: FC<{ id: number }> = ({ id }) => {
  const expenses = documentFormEdit.formSelects.useSelectArticleIdFormEdit(
    id,
    'documentArticleExpenses'
  );

  useEffect(() => {
    if (!expenses || expenses.length === 0)
      documentFormEdit.api.addedTypeArticles(id);
  }, [expenses]);

  const selectedExpenseIds = expenses
    ? expenses.map((item) => item.expenseId)
    : [];

  if (!expenses) return null;

  return (
    <>
      {expenses.map((_, index) => (
        <ExpensesFormEditExpense
          key={index}
          idArticle={id}
          selectedExpenseIds={selectedExpenseIds}
          indexExpenses={index}
        />
      ))}
    </>
  );
};

export const ExpensesFormEditExpense: FC<{
  indexExpenses: number;
  idArticle: number;
  selectedExpenseIds: number[];
}> = memo(({ indexExpenses, idArticle, selectedExpenseIds }) => {
  const lengthExpenses =
    documentFormEdit.formSelects.useSelectArticleIdFormEdit(
      idArticle,
      'documentArticleExpenses'
    )?.length || 0;
  const onDelet = useCallback(() => {
    return documentFormEdit.api.deleteTypeExpensions({
      idArticle,
      indexExpenses,
    });
  }, [idArticle, indexExpenses]);
  /* type expense */
  const typeExpensesValue =
    documentFormEdit.formSelects.useSelectArticleExpenseValue(
      idArticle,
      indexExpenses,
      'expenseId'
    );
  const optionsTypeExpensesValue =
    documentFormEdit.firstValidSelectors.useSelectorArticleIdFirstValid(
      idArticle,
      'expenses'
    );
  const onChangeExpenseType: PropsAutocompliteDocumentForm<DocumentExpenseFirstValidationResponse>['onChange'] =
    (e, value) => {
      documentFormEdit.api.setFieldExpense({
        id: idArticle,
        index: indexExpenses,
        fild: { key: 'expenseId', value: value?.id || 0 },
      });
    };
  /* expenses_expendet_conditions */
  const idExpanseType =
    documentFormEdit.formSelects.useSelectArticleExpenseValue(
      idArticle,
      indexExpenses,
      'expenseId'
    );
  const hasExtendedConditions = useStoreMap({
    store: documentFormEdit.firstdValid,
    keys: [documentFormEdit.firstdValid, idExpanseType],
    fn: (response) => {
      if (response?.documentArticles) {
        return (
          response?.documentArticles
            ?.find((item) => item.id)
            ?.expenses?.find((item) => item.id === idExpanseType)
            ?.hasExtendedConditions || false
        );
      }

      return false;
    },
  });
  const optionsExtendedConditions = useStoreMap({
    store: documentFormEdit.firstdValid,
    keys: [documentFormEdit.firstdValid, idExpanseType],
    fn: (response) => {
      return (
        response?.documentArticles
          ?.find((item) => item.id)
          ?.expenses?.find((item) => item.id === idExpanseType)
          ?.extendedConditions || []
      );
    },
  });
  const { value, expenses } = useStoreMap({
    store: documentFormEdit.form,
    keys: [documentFormEdit.form, optionsExtendedConditions],
    fn: (document) => {
      if (!optionsExtendedConditions || !document)
        return { value: null, expenses: [] };

      const expenses = document.documentArticles?.find(
        (item) => item.articleId === idArticle
      )?.documentArticleExpenses;
      const expense = (expenses && expenses[indexExpenses]) || null;
      const value =
        optionsExtendedConditions.find(
          (item) =>
            expense &&
            expense.extendedCondition &&
            item.id === expense.extendedCondition.id
        ) || null;

      return { value, expenses };
    },
  });
  /* Retail */
  const retailsOptions = useStore($retailsOptions);
  const loadingRetailsOptions = useStore(fetchRetailFx.pending);

  useEffect(() => {
    if (retailsOptions.length === 0 && !loadingRetailsOptions) {
      fetchRetailFx();
    }
  }, [retailsOptions, loadingRetailsOptions]);

  /* Count */
  const count = documentFormEdit.formSelects.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'count'
  );
  const idTypeExpense =
    documentFormEdit.formSelects.useSelectArticleExpenseValue(
      idArticle,
      indexExpenses,
      'expenseId'
    );

  const isCountFromPeriod = useStoreMap({
    store: documentFormEdit.firstdValid,
    keys: [documentFormEdit.firstdValid, idTypeExpense],
    fn: (state) => {
      return (
        state?.documentArticles
          ?.find((state) => state.id === idArticle)
          ?.expenses?.find((expens) => expens.id === idTypeExpense)
          ?.isCountFromPeriod || false
      );
    },
  });

  const periodCount = useStoreMap({
    store: documentFormEdit.form,
    keys: [documentFormEdit.form, isCountFromPeriod],
    fn: (document) => {
      if (!isCountFromPeriod) return null;
      if (document && document.beginPeriod && document.endPeriod) {
        return dayjs(document.endPeriod).diff(
          dayjs(document.beginPeriod),
          'day',
          true
        );
      }

      return null;
    },
  });

  useEffect(() => {
    if (isCountFromPeriod && periodCount)
      documentFormEdit.api.setFieldExpense({
        id: idArticle,
        index: indexExpenses,
        fild: { key: 'count', value: periodCount },
      });
  }, [isCountFromPeriod, periodCount]);

  /* sum */
  const sum = documentFormEdit.formSelects.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'sum'
  );
  const extendedConditionId =
    documentFormEdit.formSelects.useSelectArticleExpenseValue(
      idArticle,
      indexExpenses,
      'extendedCondition'
    )?.id;
  const expenseId = documentFormEdit.formSelects.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'expenseId'
  );
  const expensesFirstValid =
    documentFormEdit.firstValidSelectors.useSelectorArticleIdFirstValid(
      idArticle,
      'expenses'
    );
  const normSumArticle =
    documentFormEdit.firstValidSelectors.useSelectorArticleIdFirstValid(
      idArticle,
      'normSum'
    );

  const normSumExpense = expensesFirstValid?.find(
    (item) => item.id === expenseId
  )?.normSum;

  const normSumExtendConditions = expensesFirstValid
    ?.find((item) => item.id === expenseId)
    ?.extendedConditions?.find((item) => item.id === extendedConditionId)
    ?.normSum;

  const normSum = normSumExtendConditions || normSumExpense || normSumArticle;
  const notValid = useMemo(() => {
    if (!count || !sum || !normSum) return false;

    return Boolean(normSum * count < sum);
  }, [normSum, sum, count]);

  return (
    <Grid
      container
      sx={{ display: 'flex' }}
      justifyContent={'space-between'}
      //paddingTop={3}
    >
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography>{`Расход №${indexExpenses + 1}`}</Typography>
        <Button
          disabled={lengthExpenses < 2}
          onClick={onDelet}
        >
          Удалить
        </Button>
      </Grid>
      <UiExpenseTypeAutocomplite
        options={optionsTypeExpensesValue || []}
        selectedExpenseIds={selectedExpenseIds}
        value={
          (optionsTypeExpensesValue &&
            optionsTypeExpensesValue.find(
              (item) => item.id === typeExpensesValue
            )) ||
          null
        }
        onChange={onChangeExpenseType}
      />
      <UiExpenseFormExpendetCondition
        disabled={!hasExtendedConditions}
        value={value || null}
        options={optionsExtendedConditions}
        getOptionsDisabled={(option) => {
          if (
            expenses &&
            expenses.some(
              (expense) => expense?.extendedCondition?.id === option.id
            )
          )
            return true;

          return false;
        }}
        onChange={(event, value) => {
          documentFormEdit.api.setFieldExpense({
            id: idArticle,
            index: indexExpenses,
            fild: {
              key: 'extendedCondition',
              value:
                (value as DocumentExtendedConditionFirstValidationResponse) ||
                undefined,
            },
          });
        }}
      />

      <CustomTextField
        propsTextField={{
          disabled: isCountFromPeriod,
          required: true,
          value: periodCount || count || '',
          onChange: (event) => {
            documentFormEdit.api.setFieldExpense({
              index: indexExpenses,
              id: idArticle,
              fild: { key: 'count', value: Number(event.target.value) },
            });
          },
          placeholder: 'Введите количество',
          type: 'number',
        }}
        sx={{
          flexBasis: ['100%', '45%'],
        }}
        label='Количество'
      />
      <UiExpensesFormPriceExpens
        value={sum}
        onChange={(event) => {
          documentFormEdit.api.setFieldExpense({
            index: indexExpenses,
            id: idArticle,
            fild: { key: 'sum', value: Number(event.target.value) },
          });
        }}
        error={notValid}
        helperText={textNormSumArticle(normSum)}
      />
    </Grid>
  );
});

export const RetailAutocompliteFormEdit: FC<{
  //indexExpenses: number;
  idArticle: number;
  required?: boolean;
}> = ({ idArticle, required }) => {
  const retailsOptions = useStore($retailsOptions);
  const value = documentFormEdit.formSelects.useSelectArticleIdFormEdit(
    idArticle,
    'retail'
  );
  const loadingRetailsOptions = useStore(fetchRetailFx.pending);

  useEffect(() => {
    if (retailsOptions.length === 0 && !loadingRetailsOptions) {
      fetchRetailFx();
    }
  }, [retailsOptions, loadingRetailsOptions]);

  return (
    <UiRetailAutocomplite
      onChange={(e, value) => {
        documentFormEdit.api.setRetailIdArticleId({
          id: idArticle,
          retail: value,
        });
        if (!value) {
          documentFormEdit.api.setEstimateDocumentArticle({
            id: idArticle,
            estimateDocumentArticle: null,
          });
        }
      }}
      required={required}
      options={retailsOptions}
      value={value}
    />
  );
};
documentFormEdit.form.watch((state) => {
  console.log(state?.documentArticles);
});
