import { Grid, Skeleton } from '@mui/material';

export const EventsFormSkeleton = () => {
  return (
    <Grid
      container
      flexDirection={'column'}
      gap={1}
    >
      <Skeleton
        variant='rounded'
        height={35}
      />
      <Skeleton
        variant='rounded'
        height={24}
        width={200}
      />

      <Skeleton
        variant='rounded'
        height={73}
      />
      <Skeleton
        variant='rounded'
        height={73}
      />
      <Skeleton
        variant='rounded'
        height={73}
      />

      <Skeleton
        variant='rounded'
        height={36}
        width={225}
      />
    </Grid>
  );
};
