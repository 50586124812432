import { Box, TextField, Typography } from '@mui/material';
import { useStoreMap } from 'effector-react';
import { RetailAutocomplite } from 'features/document/expenses-form-retail-autocomplite/ExpenseRetailAutocomplite';
import { ExpensesFormTotalSumArticle } from 'features/document/expenses-form-total-sum-article/TotalSumArticle';
import { FC, useEffect, useState } from 'react';

import { getDocumentsEstimateArticles } from 'shared/api/services-document-flow/services';
import {
  EstimateDocumentArticleModel,
  GetDocumentsEstimateArticlesQueryParams,
} from 'shared/api/services-document-flow/types';
import {
  UIArticleDescription,
  UiArticleTitle,
  UiButtonAddedTypeArticle,
} from 'shared/ui/article';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

import {
  documentFormModel,
  useSelectArticleIdFormModel,
} from 'entities/document-form';

export const ArticleItem: FC<{
  id: number;
  ListExpenses: FC<{ id: number }>;
}> = ({ id, ListExpenses }) => {
  const articleResponseIsExpenses =
    documentFormModel.useSelectorArticleIdFirstValid(id, 'hasExpenses');
  const RetailId = documentFormModel.useSelectArticleIdFormModel(
    id,
    'retailId'
  );
  const ProcessId = useStoreMap(documentFormModel.process, (state) => {
    return state?.id;
  });

  if (!articleResponseIsExpenses)
    return (
      <ArticleItemNotExpens
        id={id}
        ArticleId={id}
        ProcessId={ProcessId}
        RetailId={RetailId}
      />
    );

  return (
    <ArticleItemExpens
      id={id}
      ListExpenses={ListExpenses}
    />
  );
};
export const ArticleItemExpens: FC<{
  id: number;
  ListExpenses: FC<{ id: number }>;
}> = ({ id, ListExpenses }) => {
  const isEstimated = useStoreMap(documentFormModel.process, (state) => {
    if (state) return state.isEstimated;

    return false;
  });
  const isArticleRetailDisplayed = useStoreMap(
    documentFormModel.process,
    (state) => {
      if (state) return state.isArticleRetailDisplayed;

      return false;
    }
  );
  const isArticleRetailRequired = useStoreMap(
    documentFormModel.process,
    (state) => {
      if (state) return state.isArticleRetailRequired;

      return false;
    }
  );
  const RetailId = documentFormModel.useSelectArticleIdFormModel(
    id,
    'retailId'
  );
  const ProcessId = useStoreMap(documentFormModel.process, (state) => {
    return state?.id;
  });
  const estimateDocumentArticleId = useSelectArticleIdFormModel(
    id,
    'estimateDocumentArticleId'
  );

  if (!ProcessId) {
    return <Typography>{`${ProcessId} = undefined`}</Typography>;
  }

  return (
    <Box
      component={'article'}
      sx={{
        flexBasis: '100%',
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ArticleTitle
        id={id}
        variant={'expenses'}
      />
      <ArticleDescriptionInformation id={id} />
      {isArticleRetailDisplayed && (
        <RetailAutocomplite
          idArticle={id}
          required={isArticleRetailRequired}
          //indexExpenses={0}
        />
      )}
      {isEstimated && (
        <UiEstimateAutocomplite
          ArticleId={id}
          RetailId={RetailId}
          ProcessId={ProcessId}
          value={estimateDocumentArticleId || null}
          setValue={(es) => {
            documentFormModel.formEvent.setEstimateDocumentArticleId({
              id,
              estimateDocumentArticleId: es
                ? es.estimateDocumentArticleId
                : undefined,
            });
          }}
        />
      )}
      <ListExpenses id={id} />
      <UiButtonAddedTypeArticle
        onClick={() => documentFormModel.formEvent.addedTypeArticles(id)}
      />
    </Box>
  );
};

export const UiEstimateAutocomplite: FC<
  Omit<GetDocumentsEstimateArticlesQueryParams, 'RetailId' | 'ProcessId'> & {
    setValue: (est: EstimateDocumentArticleModel | null) => void;
    value: number | null;
    RetailId: number | undefined | null;
    ProcessId: number | undefined | null;
  }
> = ({ ProcessId, ArticleId, RetailId, setValue, value }) => {
  const [isMobile] = useDevice();

  const [options, setOptions] = useState<EstimateDocumentArticleModel[]>([]);

  useEffect(() => {
    if (RetailId && ProcessId) {
      getDocumentsEstimateArticles({ ProcessId, ArticleId, RetailId }).then(
        (response) => setOptions(response.data)
      );
    } else {
      setOptions([]);
    }
  }, [ProcessId, ArticleId, RetailId]);

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        disabled: !RetailId,
        sx: {
          width: '100%',
        },
        onChange: (e, value) => {
          setValue(value);
        },
        value:
          options.find((item) => item.estimateDocumentArticleId === value) ||
          null,
        options: options,
        renderInput: (params) => (
          <TextField
            {...params}
            size='small'
            label={isMobile ? '' : 'Смета'}
            placeholder='Выберите смету'
            margin={'none'}
          />
        ),

        isOptionEqualToValue: (option, value) =>
          option.estimateDocumentArticleId === value.estimateDocumentArticleId,
        renderOption: (props, options) => (
          <li
            {...props}
            key={options.estimateDocumentArticleId}
          >
            {`${options.estimateDocumentTitle} (Сумма по смете: ${options.estimateDocumentArticleSum}, Остаток по смете: ${options.estimateDocumentArticleBalance})`}
          </li>
        ),
        getOptionLabel: (option) => {
          return `${option.estimateDocumentTitle} (Сумма по смете: ${option.estimateDocumentArticleSum}, Остаток по смете: ${option.estimateDocumentArticleBalance}) `;
        },
      }}
      sx={{ flexBasis: ['100%'] }}
      label='Смета'
    />
  );
};

export const ArticleItemNotExpens: FC<
  {
    id: number;
    RetailId: number | null | undefined;
    ProcessId: number | null | undefined;
  } & Omit<GetDocumentsEstimateArticlesQueryParams, 'RetailId' | 'ProcessId'>
> = ({ id, ProcessId, RetailId }) => {
  const isEstimated = useStoreMap(documentFormModel.process, (state) => {
    if (state) return state.isEstimated;

    return false;
  });
  const isArticleRetailDisplayed = useStoreMap(
    documentFormModel.process,
    (state) => {
      if (state) return state.isArticleRetailDisplayed;

      return false;
    }
  );
  const isArticleRetailRequired = useStoreMap(
    documentFormModel.process,
    (state) => {
      if (state) return state.isArticleRetailRequired;

      return false;
    }
  );
  const estimateDocumentArticleId = useSelectArticleIdFormModel(
    id,
    'estimateDocumentArticleId'
  );

  return (
    <Box
      component={'article'}
      sx={{
        margin: '16px 0px 8px 0px',
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <ArticleTitle
        id={id}
        variant={'not-expenses'}
      />
      {isArticleRetailDisplayed && (
        <RetailAutocomplite
          idArticle={id}
          required={isArticleRetailRequired}
          //indexExpenses={0}
        />
      )}

      {isEstimated && (
        <UiEstimateAutocomplite
          ArticleId={id}
          RetailId={RetailId}
          ProcessId={ProcessId}
          value={estimateDocumentArticleId || null}
          setValue={(es) => {
            documentFormModel.formEvent.setEstimateDocumentArticleId({
              id,
              estimateDocumentArticleId: es
                ? es.estimateDocumentArticleId
                : undefined,
            });
          }}
        />
      )}
      <ExpensesFormTotalSumArticle id={id} />
    </Box>
  );
};

const ArticleTitle: FC<{
  id: number;
  variant: 'expenses' | 'not-expenses';
}> = ({ id, variant }) => {
  const title = documentFormModel.useSelectorArticleIdFirstValid(id, 'title');

  return (
    <UiArticleTitle
      title={title || '-'}
      variant={variant}
    />
  );
};

const ArticleDescriptionInformation: FC<{ id: number }> = ({ id }) => {
  const articleDescriptionInformation = useStoreMap({
    store: documentFormModel.firstValid,
    keys: [documentFormModel.firstValid],
    fn: (response) => {
      if (response && response.documentArticles) {
        return (
          response.documentArticles
            .find((item) => item.id === id)
            ?.expenses?.map((expense) => ({
              description: expense.description,
              id: expense.id,
            })) || null
        );
      }

      return null;
    },
  });

  return (
    articleDescriptionInformation && (
      <UIArticleDescription description={articleDescriptionInformation} />
    )
  );
};
