import Grid from '@mui/material/Grid/Grid';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import { Link } from 'react-router-dom';

import { DetailsUi } from 'shared/ui/details-ui';
import { CustomTitleSectionDetailsUi } from 'shared/ui/ui';
import { transformNumberSummInText } from 'shared/utils/transformSummInText';

import { FC } from 'react';
import { ExpenseCardUi } from './ExpenseCardUi';
import { ListArticlesExpenseCardUiProps } from './types';

export const ListArticlesExpenseCardUi: FC<ListArticlesExpenseCardUiProps> = ({
  articles,
  isDetailsVariant,
}) => {
  return (
    <DetailsUi
      textSummary={'Расходы по статьям'}
      defaultOpen={true}
      isDetailsVariant={isDetailsVariant}
    >
      <Grid>
        <CustomTitleSectionDetailsUi title='Расход по статьям' />
        {articles &&
          articles.map((item) => {
            const estimateId = item.estimateDocumentArticle?.estimateDocumentId;

            return (
              <Grid
                key={item.id}
                container
                flexDirection={'column'}
                padding={['0px 16px']}
              >
                <Grid
                  container
                  justifyContent={['space-between']}
                  padding={['20px 0px']}
                >
                  <Grid
                    display={'flex'}
                    flexDirection={['column', 'row']}
                    gap={1}
                  >
                    <Typography fontWeight={700}>{item.title}</Typography>
                    {estimateId && (
                      <Link
                        to={`/document-flow/${estimateId}`}
                        target='_blank'
                      >
                        <Typography
                          sx={{
                            textDecoration: 'underline',
                            width: '100%',
                            color: 'black',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          ({`На основании: Смета ${estimateId}`})
                        </Typography>
                      </Link>
                    )}
                  </Grid>
                  <Typography fontWeight={700}>
                    Сумма {item.sum && transformNumberSummInText(item.sum)}
                  </Typography>
                </Grid>
                <Stack
                  display={'flex'}
                  direction={'row'}
                  flexWrap='wrap'
                  sx={{ width: '100%' }}
                >
                  {item.expenses &&
                    item.expenses.map((expense, index) => {
                      return (
                        <ExpenseCardUi
                          key={index}
                          sx={{
                            marginBottom: 2,
                            marginRight: [0, 2],
                            flexBasis: ['100%', '30%'],
                          }}
                          index={index}
                          data={{
                            price: expense.price,
                            sum: expense.sum,
                            title: expense.title,
                            count: expense.count,
                            retail: expense.retail,
                            extendedCondition: expense.extendedCondition,
                            normSum: expense.normSum,
                          }}
                        />
                      );
                    })}
                </Stack>
              </Grid>
            );
          })}
      </Grid>
    </DetailsUi>
  );
};
