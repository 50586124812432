import { createStore } from 'effector';

import { DocumentListItemResponse } from 'shared/api/services-document-flow/types';

import { getDocumentsObserveFx } from '../api/document-list-effector';

export type keys = keyof DocumentListItemResponse;
export const $documentObserveList = createStore<
  DocumentListItemResponse[] | null
>([], {
  name: '$documentObserveList',
});
$documentObserveList.on(
  getDocumentsObserveFx.doneData,
  (state, response) => response.data
);
