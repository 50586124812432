import { Box } from '@mui/material';
import { FC } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useGetAdaptationEventsQuery } from 'shared/api/service-adaptation/rtk/adaptationTemplatesProviderApi';
import {
  AutocompliteWithController,
  TextFieldWithController,
} from 'shared/fields-with-contoller';
import { SwitchWithController } from 'shared/fields-with-contoller/switch-with-controller';
import { FormLayout } from 'shared/ui/form';
import { createIsOptionEqualToValueByKey } from 'shared/utils/autocomplite-helpers';
import { getTitleFromObject } from 'shared/utils/getTitleFromObject';
import {
  CreateAdaptationTemplateFormFields,
  CreateAdaptationTemplateFormProps,
} from './type';

export const CreateAdaptationTemplateForm: FC<
  CreateAdaptationTemplateFormProps
> = ({ initValue, onSubmit, renderFormActions, disabled = false }) => {
  const { data: events, isLoading } = useGetAdaptationEventsQuery();

  const { handleSubmit, control } = useForm<CreateAdaptationTemplateFormFields>(
    {
      defaultValues: {
        isAutorun: initValue?.isAutorun || false,
        title: initValue?.title || '',
        events: initValue?.events || [],
      },
    }
  );

  const isAuto = useWatch({
    control,
    name: 'isAutorun',
  });

  return (
    <FormLayout
      actions={renderFormActions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextFieldWithController
        control={control}
        name='title'
        required
        label='Название'
        placeholder='Введите название'
        disabled={disabled}
        autoFocus
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <SwitchWithController
          control={control}
          name='isAutorun'
          formControlLabelProps={{ label: 'Автозапуск', sx: { flex: 0.3 } }}
          size='medium'
          disabled={disabled}
        />

        <AutocompliteWithController
          control={control}
          name='events'
          options={events || []}
          loading={isLoading}
          getOptionLabel={getTitleFromObject}
          multiple
          isOptionEqualToValue={createIsOptionEqualToValueByKey('guid')}
          disabled={!isAuto}
          textFieldProps={{
            label: 'События',
            placeholder: 'Выберите события',
            //required: isAuto,
          }}
          sx={{ flex: 0.65 }}
        />
      </Box>
    </FormLayout>
  );
};
