import { yupResolver } from '@hookform/resolvers/yup';
import {
  AutocompleteRenderGetTagProps,
  Button,
  Chip,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  CustomAutocomplete,
  CustomDatePicker,
  CustomTextField,
} from 'shared/ui/base-ui';

import { LoadingButton } from '@mui/lab';
import {
  useGetAllowedCoExecutersQuery,
  useGetAllowedExecutersQuery,
} from 'shared/api/services/rtk/optionApi';
import { DialogForForm, FormLayout } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { INITIAL_STATE_FORM_TASK } from './constants';
import { SCHEMA_TASK } from './constants/schema-task';
import { DEFAULT_OPTIONS_AUTOCOMPLETE } from './libs';
import { FormCreatedTaskUiComponent, IFormCreatedTaskUiBase } from './type';
import { SwitchUi } from './ui/SwitchUi';

export const TaskForm: FormCreatedTaskUiComponent = ({
  initialForm,
  onSubmit,
  renderFormActions,
}) => {
  const { data: optionsExecuters, isLoading: isLoadingExecutor } =
    useGetAllowedExecutersQuery();
  const { data: optionsCoExecuters, isLoading: isLoadingCoExecutor } =
    useGetAllowedCoExecutersQuery();

  const { control, handleSubmit } = useForm<IFormCreatedTaskUiBase>({
    shouldUseNativeValidation: false,
    defaultValues: { ...INITIAL_STATE_FORM_TASK, ...initialForm },
    resolver: yupResolver(SCHEMA_TASK),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  return (
    <FormLayout
      actions={renderFormActions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit, console.log)}
      container
      flexDirection={'column'}
      gap={2}
    >
      <Controller
        name='isTarget'
        control={control}
        render={({ field: { value, onChange } }) => (
          <SwitchUi
            label='Целевая задача'
            control={
              <Switch
                color='secondary'
                checked={value}
                onChange={onChange}
              />
            }
            labelPlacement='start'
          />
        )}
      />

      <Controller
        name='title'
        control={control}
        render={({ field, fieldState }) => (
          <CustomTextField
            autoFocus
            autoComplete='off'
            label={'Новая задача'}
            {...field}
            placeholder='Введите название задачи'
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            required
          />
        )}
      />

      <Controller
        name='description'
        control={control}
        render={({ field }) => {
          return (
            <CustomTextField
              multiline
              rows={4}
              {...field}
              label='Описание'
            />
          );
        }}
      />

      <Controller
        name='executer'
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomAutocomplete
            loading={isLoadingExecutor}
            textFieldProps={{
              label: 'Исполнитель',
              placeholder: 'Введите фамилию сотрудника',
              required: true,
            }}
            {...DEFAULT_OPTIONS_AUTOCOMPLETE}
            placeholder='Введите фамилию сотрудника'
            options={optionsExecuters || []}
            value={value}
            isOptionEqualToValue={(el, eq) => el.guid === eq.guid}
            renderOption={(props, option) => (
              <Typography
                {...props}
                key={option.guid}
              >
                {option.name + option.position?.title}
              </Typography>
            )}
            onChange={(_, value) => {
              onChange(value);
            }}
            sx={{ flexBasis: ['100%', '0'] }}
          />
        )}
      />

      <Controller
        name='coExecuter'
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <CustomAutocomplete
              textFieldProps={{
                label: 'Соисполнитель(ли)',
                placeholder: 'Введите фамилию сотрудника',
              }}
              {...DEFAULT_OPTIONS_AUTOCOMPLETE}
              loading={isLoadingCoExecutor}
              sx={{ flexBasis: ['100%', '0'] }}
              multiple
              options={optionsCoExecuters || []}
              value={value || []}
              onChange={(_, value) => {
                onChange(value);
              }}
              isOptionEqualToValue={(el, eq) => el.guid === eq.guid}
              renderOption={(props, option) => (
                <Typography
                  {...props}
                  key={option.guid}
                >
                  {option.name + option.position?.title}
                </Typography>
              )}
              renderTags={(
                value,
                getTagProps: AutocompleteRenderGetTagProps
              ) => {
                return value.map((chip, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size='small'
                    {...getTagProps({ index })}
                    sx={{
                      borderRadius: '8px',
                      maxWidth: '200px',
                      margin: 0,
                    }}
                    label={chip?.name}
                  />
                ));
              }}
            />
          );
        }}
      />

      <Controller
        name='observers'
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <CustomAutocomplete
              textFieldProps={{
                label: 'Наблюдатель(ли)',
                placeholder: 'Введите фамилию сотрудника',
              }}
              {...DEFAULT_OPTIONS_AUTOCOMPLETE}
              sx={{ flexBasis: ['100%', '0'] }}
              multiple
              options={optionsCoExecuters || []}
              value={value || []}
              onChange={(_, value) => {
                onChange(value);
              }}
              renderOption={(props, option) => (
                <Typography
                  {...props}
                  key={option.guid}
                >
                  {option.name + option.position?.title}
                </Typography>
              )}
              renderTags={(
                value,
                getTagProps: AutocompleteRenderGetTagProps
              ) => {
                return value.map((chip, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size='small'
                    {...getTagProps({ index })}
                    sx={{
                      borderRadius: '8px',
                      maxWidth: '200px',
                      margin: 0,
                    }}
                    label={chip?.name}
                  />
                ));
              }}
            />
          );
        }}
      />

      <Grid
        container
        justifyContent={'space-between'}
        flexDirection={['column', 'row']}
      >
        <Controller
          name='dateStart'
          control={control}
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => (
            <CustomDatePicker
              containerSx={{
                flexBasis: '45%',
              }}
              label='Дата начала'
              value={value}
              onChange={onChange}
              slotProps={{
                textField: {
                  error: invalid,
                  helperText: error?.message,
                  sx: { width: '100%' },
                },
              }}
            />
          )}
        />

        <Controller
          name='dateFinish'
          control={control}
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => (
            <CustomDatePicker
              label='Дата завершения'
              value={value}
              onChange={onChange}
              containerSx={{
                flexBasis: '45%',
              }}
              slotProps={{
                textField: {
                  error: invalid,
                  helperText: error?.message,
                  sx: { width: '100%' },
                },
              }}
            />
          )}
        />
      </Grid>

      <Grid
        container
        flexDirection={'row'}
        flexWrap={'nowrap'}
        gap={4}
      >
        <Controller
          name='isControl'
          control={control}
          render={({ field: { onChange, value } }) => (
            <SwitchUi
              control={
                <Switch
                  checked={value}
                  color='secondary'
                  onChange={onChange}
                />
              }
              label='Контроль после завершения'
              labelPlacement='start'
            />
          )}
        />
      </Grid>
    </FormLayout>
  );
};

interface CreateTaskDialogProps {
  isOpen: boolean;
  onClose: () => void;
  initValue?: Partial<IFormCreatedTaskUiBase>;
  onSubmit: (form: IFormCreatedTaskUiBase) => void;
  onDraftSubmit?: (form: IFormCreatedTaskUiBase) => void;
  isLoading?: boolean;
}
export const CreateTaskDialog: FC<CreateTaskDialogProps> = ({
  isOpen,
  onClose,
  initValue = INITIAL_STATE_FORM_TASK,
  onSubmit,
  onDraftSubmit,
  isLoading = false,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Создание задачи' />}
    >
      <TaskForm
        initialForm={initValue}
        onSubmit={onSubmit}
        renderFormActions={(handleSubmit) => [
          <LoadingButton
            loading={isLoading}
            variant='contained'
            key={3}
            type='submit'
          >
            Поставить задачу
          </LoadingButton>,
          onDraftSubmit && (
            <LoadingButton
              variant='contained'
              color='customBlue'
              key={2}
              loading={isLoading}
              onClick={handleSubmit(onDraftSubmit)}
            >
              Создать как черновик
            </LoadingButton>
          ),

          <Button
            variant='contained'
            key={1}
            onClick={onClose}
            color='customGrey'
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};

interface UpdateTaskDialogProps {
  isOpen: boolean;
  onClose: () => void;
  initValue: IFormCreatedTaskUiBase;
  onSubmit: (form: IFormCreatedTaskUiBase) => void;
  isLoading?: boolean;
}
export const UpdateTaskDialog: FC<UpdateTaskDialogProps> = ({
  isOpen,
  onClose,
  initValue,
  onSubmit,
  isLoading = false,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Редактирование задачи' />}
    >
      <TaskForm
        initialForm={initValue}
        onSubmit={onSubmit}
        renderFormActions={() => [
          <LoadingButton
            loading={isLoading}
            variant='contained'
            key={3}
            type='submit'
          >
            Сохранить
          </LoadingButton>,
          <Button
            variant='contained'
            key={1}
            onClick={onClose}
            color='customGrey'
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
