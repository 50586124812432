import { useCallback } from 'react';

/* PARAMS */
interface usePaginationStateHandlerWithFilterParams<T, KeyPageSize, KeyPage> {
  filter: T;
  setFilter: (filter: T) => void;
  /** ключь filter для кол-во элементов на странице  */
  keyPageSize: KeyPageSize;
  /**  ключь filter для изменения страницы  */
  keyPage: KeyPage;
}

/* RETURNS */
interface usePaginationStateHandlerWithFilterReturn<
  T,
  KeyPageSize extends keyof T,
  KeyPage extends keyof T,
> {
  handleChangePage: (page: number) => void;
  handleChangePageSize: (pageSize: number) => void;
  currentPage: T[KeyPage];
  pageSize: T[KeyPageSize];
}

export const usePaginationStateHandlerWithFilter = <
  T,
  KeyPage extends keyof T,
  KeyPageSize extends keyof T,
>(
  params: usePaginationStateHandlerWithFilterParams<T, KeyPageSize, KeyPage>
): usePaginationStateHandlerWithFilterReturn<T, KeyPageSize, KeyPage> => {
  const { filter, setFilter, keyPage, keyPageSize } = params;
  const handleChangePage = useCallback(
    (CurrentPage: number) => setFilter({ ...filter, [keyPage]: CurrentPage }),
    [setFilter, keyPage, filter]
  );

  const handleChangePageSize = useCallback(
    (PageSize: number) => setFilter({ ...filter, [keyPageSize]: PageSize }),
    [setFilter, keyPageSize, filter]
  );

  return {
    handleChangePage,
    handleChangePageSize,
    currentPage: filter[keyPage],
    pageSize: filter[keyPageSize],
  };
};
