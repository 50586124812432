import { VisitingChecklistGetResponse } from 'shared/api/visit/types';

import { INIT_PROBLEM } from '../constants';
import { ProblemsFormI } from '../type';

type PrepareDataToInitStage5Type = (
  data: VisitingChecklistGetResponse | null
) => ProblemsFormI;

export const prepareDataToInitStage5: PrepareDataToInitStage5Type = (data) => {
  if (data && data.problems && data.problems.length > 0)
    return {
      problems: data.problems.map((problem) => ({
        id: problem.problemId,
        title: problem.problemTitle || '',
        files: {
          newFiles: [],
          oldFiles:
            problem.problemFiles?.map((file) => ({
              name: file.problemFileTitle || '',
              id: file.problemFileId || null,
            })) || [],
          toDelete: [],
          toUpload: [],
        },
      })),
    };
  else return { problems: [INIT_PROBLEM] };
};
