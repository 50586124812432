import { useMemo } from 'react';
import { GetBoardsQueryParams } from 'shared/api/services/types';
import { convertURLToBoardList } from 'shared/url-helper/conventors/convertURLToBoardList';
import { useURLState } from 'shared/url-helper/useURLState';

export const useUrlBoardList = () => {
  const [query, setQuery] = useURLState<GetBoardsQueryParams>(
    convertURLToBoardList
  );

  const memoQ = useMemo(() => {
    if (!query || Object.keys(query).length === 0)
      return {
        Limit: 20,
        Page: 1,
        IsArchive: false,
        SearchText: '',
      };
    else return query;
  }, [query]);

  return [memoQ, setQuery] as const;
};
