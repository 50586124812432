import { Divider, Grid, Stack, Typography } from '@mui/material';
import { CommentInput } from 'features/task/comment-input';
import { TaskComment } from 'features/task/task-comment';
import { FC } from 'react';
import { useTaskCommentTab } from './hook';
import { TaskCommentTabProps } from './type';
import { hasLink } from './utils/hasLink';

export const TaskCommentTab: FC<TaskCommentTabProps> = ({
  taskGuid,
  canAddComment,
}) => {
  const {
    clearReply,
    comments,
    deleteComment,
    isSendingComment,
    sendComment,
    replyComment,
    setReplyComment,
  } = useTaskCommentTab(taskGuid);

  return (
    <Grid container>
      <Stack
        divider={<Divider />}
        gap={1}
        px={[1, 0]}
        pb={[1, 0]}
        width={'100%'}
      >
        {comments && comments.length > 0 ? (
          comments.map((comment) => (
            <TaskComment
              key={comment.guid}
              disable={!canAddComment}
              commentItem={comment}
              hasChildren={hasLink(comments, comment)}
              onDel={deleteComment}
              onReply={setReplyComment}
              replyComent={
                comment.parentGuid
                  ? comments.find((com) => com.guid === comment.parentGuid)
                  : undefined
              }
            />
          ))
        ) : (
          <Typography textAlign={'center'}>Нет комментариев</Typography>
        )}

        {canAddComment && (
          <CommentInput
            replyComment={replyComment}
            isLoading={isSendingComment}
            onSend={sendComment}
            onCloseReply={clearReply}
          />
        )}
      </Stack>
    </Grid>
  );
};
