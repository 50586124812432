import { TaskIdMainComponent } from '../../features/task/task-id-component/TaskIdMainComponent';
import { LayoutFetch } from '../../widgets/main-layout-app/LauoutFetch';

export const TasksId = () => {
  return (
    <LayoutFetch>
      <TaskIdMainComponent />
    </LayoutFetch>
  );
};
