import { array, mixed, object, ObjectSchema } from 'yup';

import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';

import { FilesI, MyFile, Problem, ProblemsFormI } from './type';

/* Form schems */
export const MyFileSchema: ObjectSchema<MyFile> = object().shape({
  id: SCHEMA.NUMBER.required().nullable(),
  name: SCHEMA.STRING_REQUIRED,
});

export const FilesSchema: ObjectSchema<FilesI> = object().shape({
  oldFiles: array(MyFileSchema.required()).required(),
  newFiles: array(MyFileSchema.required()).required(),
  // TODO: что-то надо сделать с типом
  toUpload: array(mixed().required()).required(),
  toDelete: array(SCHEMA.NUMBER.required()).required(),
});

export const ProblemSchema: ObjectSchema<Problem> = object().shape({
  id: SCHEMA.NUMBER.required(),
  title: SCHEMA.STRING_REQUIRED,
  files: FilesSchema.required(),
});

export const ProblemsFormSchema: ObjectSchema<ProblemsFormI> = object().shape({
  problems: array(ProblemSchema.required()).required(),
});
/* ==== */
