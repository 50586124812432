import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import {
  getDocumentsAction,
  getDocumentsControl,
  getDocumentsObserve,
} from 'shared/api/services-document-flow/services';
import { ErrorResponse } from 'shared/api/services-document-flow/types';

export const getDocumentsControlFx = createEffect<
  typeof getDocumentsControl,
  AxiosError<ErrorResponse>
>(getDocumentsControl);
export const getDocumentsActionFx = createEffect<
  typeof getDocumentsAction,
  AxiosError<ErrorResponse>
>(getDocumentsAction);
export const getDocumentsObserveFx = createEffect<
  typeof getDocumentsObserve,
  AxiosError<ErrorResponse>
>(getDocumentsObserve);
