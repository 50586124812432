import { TASK_INFO_MAP } from 'features/task/task-id-component/constants';
import { DOCUMENT_ESTIMATED_ARTICLES } from 'pages/document/DocumentGuid';
import { ADAPTATION_PROCESS_TASKS_MAP } from 'widgets/adaptation/AdaptationProcessTasksTable/constants';
import { ADAPTATION_STAGE_TASKS_MAP } from 'widgets/adaptation/AdaptationStageTasksTable/constants';
import { ADAPTATION_TEMPLATES_TABLE_MAP } from 'widgets/adaptation/AdaptationTemplatesTable/constants';
import { CURRENT_ADAPTATION_PROCESSES_MAP } from 'widgets/adaptation/CurrentAdaptationProcessesTable/constants';
import { USER_NO_ADAPTATION_REQUIRED_MAP } from 'widgets/adaptation/UserNoAdaptationRequiredTable/constants';
import { APTEKA_LEGKO_PUSH_NOTIFICATIONS_FIELD_MAP } from 'widgets/apteka-legko/push-notifications/push-notifications-table/use-table-from-local-storage/constants';
import { DOCUMENT_ACTION_TABLE_CELLS } from 'widgets/document/document-table';
import { MARKETING_SURFACE_FIELD_MAP } from 'widgets/marketing-surface/marketing-surface-table/use-table-from-local-storage/constants';
import { RUNNING_PROTOCOLS_FIELD_MAP } from 'widgets/retail-opening-process/opening-running-tasks-table/use-table-from-local-storage/constants';
import { OPENING_TAMPLATES_FIELD_MAP } from 'widgets/retail-opening-process/opening-templates-table/use-table-from-local-storage/constants';
import { OPENING_TAMPLATES_TASKS_FIELD_MAP } from 'widgets/retail-opening-process/opening-templatest-tasks-table/use-table-from-local-storage/constants';
import { RUNNING_PROTOCOLS_FIELD_MAP as RUNNING_PROTOCOLS_FIELD_MAP_1 } from 'widgets/retail-opening-process/protocol-opening-retails-table/process-sub-table/process-opening-retails-table/use-table-from-local-storage/constants';
import { RUNNING_PROTOCOLS_FIELD_MAP as RUNNING_PROTOCOLS_FIELD_MAP_2 } from 'widgets/retail-opening-process/protocol-opening-retails-table/use-table-from-local-storage/constants';
import { TASK_PROBLEM_FIELD_MAP } from 'widgets/tasks/problem-task-table';
import { TASK_TABLE_MAP } from 'widgets/tasks/task-table/constants';
import { COMPLITED_TRAINING_FIELD_MAP } from 'widgets/training/training-table/complited-training-table/constants';
import { CONTROL_TRAINING_FIELD_MAP } from 'widgets/training/training-table/control-training-table/constants';
import { CURRENT_TRAINING_FIELD_MAP } from 'widgets/training/training-table/current-training-table/constants';
import { MY_TRAINING_FIELD_MAP } from 'widgets/training/training-table/my-training-table/constants';
import { CURRENT_TRAINING_FIELD_MAP_SUBTABLE } from 'widgets/training/training-table/training-table-with-sub-table/constants';
import { VISIT_FIELD_MAP } from 'widgets/visits/visits-table';
import { tablesController } from './tablesController';
import { Tables } from './type';

// type TablesVersionsType = {
//   [key in Tables]: number;
// };

const TABLES_VERSIONS = {
  DOCUMENT_ESTIMATED_ARTICLES: 1,
  TASK_INFO_MAP: 7,
  DOCUMENT_ACTION_TABLE_CELLS: 1,
  TASK_PROBLEM_FIELD_MAP: 1,
  TASK_TABLE_MAP: 6,
  COMPLITED_TRAINING_FIELD_MAP: 1,
  CONTROL_TRAINING_FIELD_MAP: 1,
  CURRENT_TRAINING_FIELD_MAP: 1,
  MY_TRAINING_FIELD_MAP: 1,
  CURRENT_TRAINING_FIELD_MAP_SUBTABLE: 1,
  VISIT_FIELD_MAP: 1,
  OPENING_RETAILS_RUNNING_TASKS_TABLE: 2,
  OPENING_TAMPLATES_TABLE: 2,
  OPENING_TAMPLATES_TASKS_TABLE: 2,
  OPENING_RETAILS_PROCESSES_TABLE: 2,
  OPENING_RETAILS_PROTOCOLS_TABLE: 2,
  MARKETING_SURFACE_TABLE: 1,
  APTEKA_LEGKO_PUSH_NOTIFICATIONS_TABLE: 3,
  ADAPTATION_TEMPLATE_TABLE: 1,
  ADAPTATION_STAGE_TASKS_TABLE: 1,
  ADAPTATION_PROCESSES_TABLE: 2,
  ADAPTATION_PROCESS_TASKS_TABLE: 1,
  USER_NO_ADAPTATION_REQUIRED_TABLE: 1,
};

tablesController.register(
  Tables.OPENING_RETAILS_PROTOCOLS_TABLE,
  TABLES_VERSIONS.OPENING_RETAILS_PROTOCOLS_TABLE,
  RUNNING_PROTOCOLS_FIELD_MAP_2
);
tablesController.register(
  Tables.OPENING_RETAILS_PROCESSES_TABLE,
  TABLES_VERSIONS.OPENING_RETAILS_PROCESSES_TABLE,
  RUNNING_PROTOCOLS_FIELD_MAP_1
);
tablesController.register(
  Tables.OPENING_RETAILS_RUNNING_TASKS_TABLE,
  TABLES_VERSIONS.OPENING_RETAILS_RUNNING_TASKS_TABLE,
  RUNNING_PROTOCOLS_FIELD_MAP
);
tablesController.register(
  Tables.OPENING_TAMPLATES_TABLE,
  TABLES_VERSIONS.OPENING_TAMPLATES_TABLE,
  OPENING_TAMPLATES_FIELD_MAP
);
tablesController.register(
  Tables.OPENING_TAMPLATES_TASKS_TABLE,
  TABLES_VERSIONS.OPENING_TAMPLATES_TASKS_TABLE,
  OPENING_TAMPLATES_TASKS_FIELD_MAP
);

/* DOCUMENT_ESTIMATED_ARTICLES */
tablesController.register(
  Tables.LINKS_DOCUMENT,
  TABLES_VERSIONS.DOCUMENT_ESTIMATED_ARTICLES,
  DOCUMENT_ESTIMATED_ARTICLES
);
/* ===== */

/* TASK_INFO_MAP */
tablesController.register(
  Tables.TASK_INFO,
  TABLES_VERSIONS.TASK_INFO_MAP,
  TASK_INFO_MAP
);
/* ===== */

/* DOCUMENT_ACTION_TABLE_CELLS */
tablesController.register(
  Tables.ACTION_DOC,
  TABLES_VERSIONS.DOCUMENT_ACTION_TABLE_CELLS,
  DOCUMENT_ACTION_TABLE_CELLS
);
tablesController.register(
  Tables.OBSERVE_DOC,
  TABLES_VERSIONS.DOCUMENT_ACTION_TABLE_CELLS,
  DOCUMENT_ACTION_TABLE_CELLS
);
tablesController.register(
  Tables.CONTROL_DOC,
  TABLES_VERSIONS.DOCUMENT_ACTION_TABLE_CELLS,
  DOCUMENT_ACTION_TABLE_CELLS
);
/* ===== */

/* TASK_PROBLEM_FIELD_MAP */
tablesController.register(
  Tables.PROBLEM_TASK,
  TABLES_VERSIONS.TASK_PROBLEM_FIELD_MAP,
  TASK_PROBLEM_FIELD_MAP
);
/* ===== */

/* TASK_TABLE_MAP */
tablesController.register(
  Tables.MY_TASKS,
  TABLES_VERSIONS.TASK_TABLE_MAP,
  TASK_TABLE_MAP
);
tablesController.register(
  Tables.CONTROL_TASKS,
  TABLES_VERSIONS.TASK_TABLE_MAP,
  TASK_TABLE_MAP
);
tablesController.register(
  Tables.WATCH_TASKS,
  TABLES_VERSIONS.TASK_TABLE_MAP,
  TASK_TABLE_MAP
);
// tablesController.register(
//   Tables.PROBLEM_TASK,
//   TABLES_VERSIONS.TASK_TABLE_MAP,
//   TASK_TABLE_MAP
// );
tablesController.register(
  Tables.SUB_TASKS,
  TABLES_VERSIONS.TASK_TABLE_MAP,
  TASK_TABLE_MAP
);
/* ===== */

/* COMPLITED_TRAINING_FIELD_MAP */
tablesController.register(
  Tables.TRAINING_COMPLETE,
  TABLES_VERSIONS.COMPLITED_TRAINING_FIELD_MAP,
  COMPLITED_TRAINING_FIELD_MAP
);
/* ===== */

/* CONTROL_TRAINING_FIELD_MAP */
tablesController.register(
  Tables.CONTROL_TRAINING,
  TABLES_VERSIONS.CONTROL_TRAINING_FIELD_MAP,
  CONTROL_TRAINING_FIELD_MAP
);
/* ===== */

/* CURRENT_TRAINING_FIELD_MAP */
tablesController.register(
  Tables.TRAINING_CURRENT,
  TABLES_VERSIONS.CURRENT_TRAINING_FIELD_MAP,
  CURRENT_TRAINING_FIELD_MAP
);
/* ===== */

/* MY_TRAINING_FIELD_MAP */
tablesController.register(
  Tables.MY_TRAINING,
  TABLES_VERSIONS.MY_TRAINING_FIELD_MAP,
  MY_TRAINING_FIELD_MAP
);
/* ===== */

/* CURRENT_TRAINING_FIELD_MAP_SUBTABLE */
tablesController.register(
  Tables.TRAINING_CURRENT_SUB_TABLE,
  TABLES_VERSIONS.CURRENT_TRAINING_FIELD_MAP_SUBTABLE,
  CURRENT_TRAINING_FIELD_MAP_SUBTABLE
);
/* ===== */

/* VISIT_FIELD_MAP */
tablesController.register(
  Tables.ACTION_VISIT,
  TABLES_VERSIONS.VISIT_FIELD_MAP,
  VISIT_FIELD_MAP
);
tablesController.register(
  Tables.OBSERVE_VISIT,
  TABLES_VERSIONS.VISIT_FIELD_MAP,
  VISIT_FIELD_MAP
);
/* ===== */

/* VISIT_FIELD_MAP */
tablesController.register(
  Tables.MARKETING_SURFACE_TABLE,
  TABLES_VERSIONS.MARKETING_SURFACE_TABLE,
  MARKETING_SURFACE_FIELD_MAP
);
/* ===== */

/* APTEKA_LEGKO_PUSH_NOTIFICATIONS_TABLE */
tablesController.register(
  Tables.APTEKA_LEGKO_PUSH_NOTIFICATIONS_TABLE,
  TABLES_VERSIONS.APTEKA_LEGKO_PUSH_NOTIFICATIONS_TABLE,
  APTEKA_LEGKO_PUSH_NOTIFICATIONS_FIELD_MAP
);
/* ===== */

/* ADAPTATION */
tablesController.register(
  Tables.ADAPTATION_TEMPLATES_TABLE,
  TABLES_VERSIONS.ADAPTATION_TEMPLATE_TABLE,
  ADAPTATION_TEMPLATES_TABLE_MAP
);

tablesController.register(
  Tables.ADAPTATION_STAGE_TASKS_TABLE,
  TABLES_VERSIONS.ADAPTATION_STAGE_TASKS_TABLE,
  ADAPTATION_STAGE_TASKS_MAP
);

tablesController.register(
  Tables.ADAPTATION_PROCESSES_TABLE,
  TABLES_VERSIONS.ADAPTATION_PROCESSES_TABLE,
  CURRENT_ADAPTATION_PROCESSES_MAP
);
tablesController.register(
  Tables.ADAPTATION_PROCESS_TASKS_TABLE,
  TABLES_VERSIONS.ADAPTATION_PROCESS_TASKS_TABLE,
  ADAPTATION_PROCESS_TASKS_MAP
);
tablesController.register(
  Tables.USER_NO_ADAPTATION_REQUIRED_TABLE,
  TABLES_VERSIONS.USER_NO_ADAPTATION_REQUIRED_TABLE,
  USER_NO_ADAPTATION_REQUIRED_MAP
);
/* ===== */
