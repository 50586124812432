export const checkIntersectiOnNumberArray = <T extends string | number>(
  arrFirst: T[],
  arrSecond: T[]
) => {
  let isIntersection = false;
  let i = arrFirst.length;

  while (i > 0) {
    i = i - 1;
    if (arrSecond.includes(arrFirst[i])) {
      i = 0;
      isIntersection = true;
    }
  }

  return isIntersection;
};
