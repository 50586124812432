import { useCallback, useMemo } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { CoreSystemEnumsObjectActions } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import {
  useGetAptekaLegkoBannersBrandsDetailQuery,
  useUpdateAptekaLegkoBannersBrandsMutation,
} from 'shared/api/service-apteka-legko-banners/rtk/aptekaLegkoBannersBrandProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { BrandFormValue } from '../types';

export const useEditBannerBrands = (guid?: string) => {
  const {
    open: openModalForm,
    isOpen,
    close: closeModalForm,
  } = useViewModal(false);

  const {
    data: brandByGuid,
    isLoading: isLoadingBrandByGuid,
    error: errorBrandByGuid,
  } = useGetAptekaLegkoBannersBrandsDetailQuery(
    { BrandsGuid: guid as string },
    { skip: !guid || !isOpen }
  );

  const initialValue: BrandFormValue | undefined = useMemo(() => {
    if (!brandByGuid) return undefined;

    const { url, imageSite } = brandByGuid;

    return {
      link: url || '',
      imageUrl: imageSite,
      imageData: null,
      imageAction: CoreSystemEnumsObjectActions.None,
    };
  }, [brandByGuid]);

  const [updateBannerBrand, { isLoading: isLoadingUpdateBannerBrand, error }] =
    useUpdateAptekaLegkoBannersBrandsMutation();

  useShowFetchErrorInSnackbar(error || errorBrandByGuid);

  const handleUpdateBannerBrand = useCallback(
    async (form: BrandFormValue) => {
      const { imageAction, imageData, imageUrl, link } = form;

      if ((!imageData && !imageUrl) || !guid) return null;

      const response = await updateBannerBrand({
        Brands: {
          image:
            imageAction === CoreSystemEnumsObjectActions.None
              ? null
              : imageData?.data,
          url: link !== '' ? link : null,
          bannerBrandGuid: guid,
        },
      });

      if ('data' in response) {
        closeModalForm();
      }
    },
    [updateBannerBrand, closeModalForm, guid]
  );

  return {
    isOpen,
    isLoadingUpdateBannerBrand,
    handleUpdateBannerBrand,
    openModalForm,
    closeModalForm,
    initialValue,
    isLoadingInitialValue: isLoadingBrandByGuid,
  };
};
