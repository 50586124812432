import { object, ObjectSchema } from 'yup';

import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';

import { IFormFastTaskUi } from '../type';

export const FormFastTaskSchema: ObjectSchema<IFormFastTaskUi> = object().shape(
  {
    executor: SCHEMA.EMPLOYEE.required(),
    title: SCHEMA.STRING_REQUIRED,
    dateFinish: SCHEMA.DATEJS.required().nullable(),
  }
);
