import { Alert, AlertTitle, Slide, Stack } from '@mui/material';
import { useStore } from 'effector-react';

import {
  $errorStack,
  deleteError,
} from 'entities/error/error-alert/error-alert-effector';

export const ErrorAlert = () => {
  const errorStack = useStore($errorStack);

  if (!errorStack) return null;

  return (
    <Stack
      spacing={1}
      sx={{ position: 'fixed', bottom: 0, zIndex: 9999 }}
    >
      {errorStack.reverse().map((error) => (
        <Slide
          in
          direction='right'
          key={error.id}
        >
          <Alert
            color='error'
            onClose={() => deleteError(error.id)}
          >
            <AlertTitle>{`${error.status} : ${error.statusText}`}</AlertTitle>
            {error.message}
          </Alert>
        </Slide>
      ))}
    </Stack>
  );
};
