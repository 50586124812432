import { Delete, Download, FilePresentRounded } from '@mui/icons-material';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { FileListItemProps } from './type';

export const FileListItem: FC<FileListItemProps> = ({
  fileName,
  disableDelete,
  disableDownload,
  onDelete,
  onDownload,
  variant = 'del',
}) => {
  return (
    <Paper
      elevation={1}
      sx={{
        paddingLeft: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          flexShrink: 1,
          gap: 1,
          overflow: 'hidden',
        }}
      >
        <FilePresentRounded color={'action'} />
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {fileName}
        </Typography>
      </Grid>

      <Grid
        sx={{
          width: 85,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          onClick={onDownload}
          disabled={disableDownload}
        >
          <Download />
        </IconButton>
        <IconButton
          disabled={disableDelete}
          onClick={onDelete}
        >
          {variant === 'del' ? <Delete /> : <RestoreFromTrashIcon />}
        </IconButton>
      </Grid>
    </Paper>
  );
};
