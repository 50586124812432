import { Avatar } from '@mui/material';
import { forwardRef } from 'react';
import { getAvatarName } from 'shared/utils/getAvatarName';
import { getColorByGuid } from 'shared/utils/getColorByGuid';
import { EmployeeAvatarProps } from './style';

// export const EmployeeAvatar: FC<EmployeeAvatarProps> = ({
export const EmployeeAvatar = forwardRef<HTMLDivElement, EmployeeAvatarProps>(
  ({ employee, avatarProps }, ref) => {
    return (
      <Avatar
        {...avatarProps}
        ref={ref}
        sx={{
          bgcolor: getColorByGuid(employee.guid),
          ...avatarProps?.sx,
        }}
      >
        {getAvatarName(employee.name)}
      </Avatar>
    );
  }
);
