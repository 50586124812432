// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Grid, Modal } from '@mui/material';
import { FC, ReactNode } from 'react';

export const UiExpensesFormModal: FC<{
  children: ReactNode | ReactNode[];
  onClose: () => any;
  isOpenModal: boolean;
}> = ({ children, onClose, isOpenModal }) => {
  return (
    <Modal
      open={isOpenModal}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      onClose={onClose}
    >
      <Grid
        sx={{
          backgroundColor: 'white',
          maxHeight: ['100vh', '80vh'],
          height: ['100vh', '100%'],
          maxWidth: 1200,
          width: ['100vw', '100%'],
        }}
        paddingTop={[1, 4]}
        paddingBottom={[1, 5]}
        paddingLeft={[1, 6]}
        paddingRight={[1, 4]}
      >
        {children}
      </Grid>
    </Modal>
  );
};
