import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FC } from 'react';

import { ListItemProps } from './type';

export const ListItem: FC<ListItemProps> = ({
  title,
  value,
  onChange,
  disabled,
}) => {
  return (
    <FormControlLabel
      sx={{
        margin: 0,
      }}
      labelPlacement='start'
      control={
        <Checkbox
          disabled={disabled}
          checked={value}
          onChange={(e, newValue) => {
            onChange(newValue);
          }}
          color='primary'
          sx={{ '& .MuiSvgIcon-root': { fontSize: 34 }, marginLeft: 6 }}
        />
      }
      label={
        <Typography
          width={'100%'}
          ml={1}
        >
          {title}
        </Typography>
      }
    />
  );
};
