import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { usePostFilesMutation } from 'shared/api/services/rtk/fileApi';

export const usePostTaskFiles = (taskGuid: string) => {
  const [postFile, { isLoading, error }] = usePostFilesMutation();

  useShowFetchErrorInSnackbar(error);

  const addFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      try {
        const promArray = [];

        for (let i = 0; i < e.currentTarget.files.length; i++) {
          const element = e.currentTarget.files[i] as unknown as string;

          promArray.push(postFile({ taskGuid, file: element }));
        }

        await Promise.all(promArray);
      } catch (e) {
        //
      }
    }
  };

  return { isLoading, addFiles };
};
