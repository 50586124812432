import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { theme } from 'shared/theme/theme';

import { STYLE_STIKY_CELL } from '../row-actions/constants';
import { UiMenuTable } from '../ui-menu-table';
import { UiTableHeaderType } from './type';

export const UiTableHeader: UiTableHeaderType = ({
  viewCells,
  allCells,
  setViewCells = () => {
    return {};
  },
  headProps,
  showSettings = true,
}) => {
  return (
    <TableHead {...headProps}>
      <TableRow>
        {viewCells.map((el) => (
          <TableCell
            key={String(el.field)}
            {...el.getTableCellProps()}
          >
            <Typography
              fontWeight={700}
              color={theme.palette.customGrey.dark}
            >
              {el.title}
            </Typography>
          </TableCell>
        ))}

        {showSettings && (
          <TableCell
            align='center'
            sx={STYLE_STIKY_CELL}
          >
            <UiMenuTable
              fields={allCells}
              setFields={setViewCells}
            />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
