import { useStoreMap } from 'effector-react';
import { FC } from 'react';

import { UiExpenseFormExpendetCondition } from 'shared/ui/article/UiExpenseFormExpendetCondition';

import { documentFormModel } from 'entities/document-form';

export const ExpenseFormExpendetCondition: FC<{
  indexExpense: number;
  idArticle: number;
}> = ({ indexExpense, idArticle }) => {
  const idExpanseType = documentFormModel.useSelectArticleExpenseValue(
    idArticle,
    indexExpense,
    'expenseId'
  );

  const hasExtendedConditions = useStoreMap({
    store: documentFormModel.firstValid,
    keys: [documentFormModel.firstValid, idExpanseType],
    fn: (response) => {
      return (
        response?.documentArticles
          ?.find((item) => item.id)
          ?.expenses?.find((item) => item.id === idExpanseType)
          ?.hasExtendedConditions || false
      );
    },
  });

  const optionsExtendedConditions = useStoreMap({
    store: documentFormModel.firstValid,
    keys: [documentFormModel.firstValid, idExpanseType],
    fn: (response) => {
      return (
        response?.documentArticles
          ?.find((item) => item.id)
          ?.expenses?.find((item) => item.id === idExpanseType)
          ?.extendedConditions || []
      );
    },
  });

  const { value, expenses } = useStoreMap({
    store: documentFormModel.form,
    keys: [documentFormModel.form, optionsExtendedConditions],
    fn: (document) => {
      if (!optionsExtendedConditions || !document)
        return { value: null, expenses: [] };

      const expenses = document.articles?.find((item) => item.id === idArticle)
        ?.expenses;
      const expense = (expenses && expenses[indexExpense]) || null;
      const value =
        optionsExtendedConditions.find(
          (item) => item.id === expense?.extendedConditionId
        ) || null;

      return { value, expenses };
    },
  });

  return (
    <UiExpenseFormExpendetCondition
      disabled={!hasExtendedConditions}
      value={value || null}
      options={optionsExtendedConditions}
      getOptionsDisabled={(option) => {
        if (
          expenses &&
          expenses.some((expense) => expense?.extendedConditionId === option.id)
        )
          return true;

        return false;
      }}
      onChange={(event, value) => {
        documentFormModel.formEvent.setFieldExpense({
          id: idArticle,
          index: indexExpense,
          fild: {
            key: 'extendedConditionId',
            value: value ? value.id : undefined,
          },
        });
      }}
    />
  );
};
