import { getBoardLink } from 'entities/boards/libs/getBoardLink';
import { useNavigate } from 'react-router-dom';

export const useBoardNavigation = () => {
  const navigate = useNavigate();

  const navigateToBoard = (boardGuid: string) => {
    navigate(getBoardLink(boardGuid));
  };

  return { navigateToBoard };
};
