import { Typography } from '@mui/material';
import { FC } from 'react';

export const DocumentOrgUi: FC<{ organization?: string }> = ({
  organization,
}) => {
  return (
    <Typography sx={{ minWidth: 'max-content' }}>
      {organization ? organization : '-'}
    </Typography>
  );
};
