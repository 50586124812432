import { Box, Stack, Typography } from '@mui/material';

import { theme } from 'shared/theme/theme';

import { Link } from 'shared/ui/base-ui';
import { USEFUL_LINKS } from './constants';

export const StackUsefulLinks = () => {
  return (
    <Stack
      padding='20px 20px 20px 23px'
      sx={{
        borderLeft: 'solid 1px',
        borderRight: 'solid 1px',
        borderBottom: 'solid 1px',
        borderRadius: '0px 0px 10px 10px ',
        borderColor: theme.palette.primary.main,
      }}
      paddingX={[2, 2]}
      paddingY={[2]}
      spacing={2}
    >
      {USEFUL_LINKS.map((link) => {
        return (
          <Box key={link.description}>
            <Link
              to={link.href}
              target='_blank'
              fontSize={[14, 20]}
              fontWeight={'bold'}
            >
              {link.title}
            </Link>
            <Typography>{link.description}</Typography>
          </Box>
        );
      })}
    </Stack>
  );
};
