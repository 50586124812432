import { Logout } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { theme } from 'shared/theme/theme';

import { logOut } from 'entities/user/authorization-effector';

export const useLogOutUser = () => {
  const navigate = useNavigate();
  const onLogOut = useCallback(() => {
    navigate('/authorization');
    logOut();
  }, [navigate]);

  return onLogOut;
};

export const LogOutButton = () => {
  const onLogOut = useLogOutUser();

  return (
    <Button
      onClick={onLogOut}
      endIcon={<Logout />}
      sx={{
        textTransform: 'none',
        color: theme.palette.customGrey.dark,
        position: 'relative',
        top: '1px',
        fontWeight: 700,
      }}
    >
      Выход
    </Button>
  );
};
