import { Drawer, Grid } from '@mui/material';
import { TaskIdMainComponent } from 'features/task/task-id-component/TaskIdMainComponent';
import { FC } from 'react';

import { TaskDrawerProps } from './type';

export const TaskDrawer: FC<TaskDrawerProps> = ({
  isOpen,
  onClose,
  taskGuid,
  drawerProps,
}) => {
  if (!taskGuid) return null;

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      variant='temporary'
      PaperProps={{ sx: { width: 'clamp(565px, 40%, 800px)' } }}
      {...drawerProps}
    >
      <Grid width={'100%'}>
        <TaskIdMainComponent
          taskGuid={taskGuid}
          isDrawer
          onClose={onClose}
        />
      </Grid>
    </Drawer>
  );
};
