import { Button, Dialog, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';

import { ModalChangeStatusTaskProps } from './type';

export const ModalChangeStatusTask: FC<ModalChangeStatusTaskProps> = ({
  task,
  onClose,
  isLoading = false,
  isOpen,
  onChange,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { width: [183, 250] } }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <CustomBackdrop isLoading={isLoading} />
      <Typography
        height={[33, 45]}
        display='flex'
        alignItems={'center'}
        paddingLeft={2}
        fontWeight={700}
        fontSize={[14, 18]}
      >
        Изменить статус
      </Typography>

      <Stack>
        {task?.accessActions.allowedStatuses?.map((status) => {
          const backgroundColor =
            status.title === task.status
              ? theme.palette.customGreen.main
              : 'white';

          return (
            <Button
              key={status.id}
              onClick={() => {
                onChange && onChange(task.guid, status);
              }}
              variant='contained'
              sx={{
                height: [33, 45],
                textTransform: 'none',
                borderRadius: 0,
                justifyContent: 'flex-start',
                color: 'black',
                ':hover': {
                  color: 'white',
                },
                backgroundColor,
                fontSize: [14, 18],
              }}
            >
              {status.title}
            </Button>
          );
        })}
        <Button
          onClick={onClose}
          variant='contained'
          sx={{
            backgroundColor: theme.palette.customGrey.main,
            borderRadius: '0',
            textTransform: 'none',
            color: theme.palette.customGrey.dark,
            height: [33, 45],
            fontSize: [14, 18],
            '&:hover': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.dark,
            },
          }}
        >
          Закрыть
        </Button>
      </Stack>
    </Dialog>
  );
};
