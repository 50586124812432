import { Edit, Upload } from '@mui/icons-material';
import { FC } from 'react';

import { useTheme } from '@mui/material';
import { ComponentWithUserPermission } from 'entities/user';
import {
  DialogFormMarketingSurface,
  prepareSurfaceFromBackendToInitialFormValue,
  useDialogMarketingSurfaceFormCreate,
  useDialogMarketingSurfaceFormUpdate,
} from 'features/marketing-surface/dialog-form-marketing-surface';
import { AdvertisingSurface } from 'shared/api/marketing-surface/marketingSurfaceAutogenerateApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { E_ModuleGuids } from 'shared/moduleGuids';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';
import { SwipeableDrawerImagesListUi } from './view-list-images';

export const MarketingSurfaceActions: FC<{ surface: AdvertisingSurface }> = ({
  surface,
}) => {
  const theme = useTheme();
  const {
    open: onOpenImagesDawer,
    isOpen: isOpenImagesDrawer,
    close: onCloseImagesDrawer,
  } = useViewModal();

  const {
    isLoadingPostMarketingSurface,
    isOpenDialogFormCreateMarketingSurface,
    onCloseDialogFormCreateMarketingSurface,
    onOpenDialogFormCreateMarketingSurface,
    onSubmitCreateMarketingSurface,
  } = useDialogMarketingSurfaceFormCreate({ retailGuid: surface.retailGuid });

  const {
    onOpenDialogFormUpdateMarketingSurface,
    isOpenDialogFormUpdateMarketingSurface,
    onCloseDialogFormUpdateMarketingSurface,
    isLoadingUpdateMarketingSurface,
    onSubmitUpdateMarketingSurface,
  } = useDialogMarketingSurfaceFormUpdate({
    retailGuid: surface.retailGuid,
    guid: surface.guid,
  });

  return (
    <>
      <UiTableActions>
        {surface.guid && (
          <UiButtonAction
            onClick={onOpenImagesDawer}
            color={theme.palette.customBlue.main}
            icon={<Upload />}
            text={'Изображения'}
          />
        )}
        <ComponentWithUserPermission
          moduleGuid={E_ModuleGuids.marketingSurface}
        >
          {(permission) =>
            surface.guid ? (
              <UiButtonAction
                disable={!permission.isWritable}
                onClick={onOpenDialogFormUpdateMarketingSurface}
                text={'Редактировать'}
                icon={<Edit />}
              />
            ) : (
              <UiButtonAction
                disable={!permission.isWritable}
                onClick={onOpenDialogFormCreateMarketingSurface}
                text={'Заполнить'}
                icon={<Edit />}
              />
            )
          }
        </ComponentWithUserPermission>
      </UiTableActions>
      {surface.guid && (
        <SwipeableDrawerImagesListUi
          onOpenImagesDawer={onOpenImagesDawer}
          onCloseImagesDrawer={onCloseImagesDrawer}
          isOpenImagesDrawer={isOpenImagesDrawer}
          surfaceGuid={surface.guid}
        />
      )}

      {!surface.guid && (
        <DialogFormMarketingSurface
          isOpen={isOpenDialogFormCreateMarketingSurface}
          onClose={onCloseDialogFormCreateMarketingSurface}
          onSubmitForm={onSubmitCreateMarketingSurface}
          isLoading={isLoadingPostMarketingSurface}
          initialValue={prepareSurfaceFromBackendToInitialFormValue(surface)}
        />
      )}
      {surface.guid && (
        <DialogFormMarketingSurface
          isOpen={isOpenDialogFormUpdateMarketingSurface}
          onClose={onCloseDialogFormUpdateMarketingSurface}
          onSubmitForm={onSubmitUpdateMarketingSurface}
          isLoading={isLoadingUpdateMarketingSurface}
          initialValue={prepareSurfaceFromBackendToInitialFormValue(surface)}
        />
      )}
    </>
  );
};
