import dayjs from 'dayjs';
import { APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS } from 'entities/apteka-legko/banners/sliders/links';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { CoreSystemEnumsObjectActions } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import {
  useGetAptekaLegkoBannersSliderDetailQuery,
  useUpdateAptekaLegkoBannersSliderMutation,
} from 'shared/api/service-apteka-legko-banners/rtk/aptekaLegkoBannersSliderProviderApi';
import { SliderFormValue } from '../types';

export const useUpdateBannerSlider = (guid?: string) => {
  const navigate = useNavigate();

  const [
    updateBannerSlider,
    { isLoading: isLoadingUpdateBannerSlider, error },
  ] = useUpdateAptekaLegkoBannersSliderMutation();

  useShowFetchErrorInSnackbar(error);

  const { data, isLoading: isLoadingInitialForm } =
    useGetAptekaLegkoBannersSliderDetailQuery(
      { sliderGuid: guid as string },
      { skip: !guid }
    );

  const initialForm = useMemo(() => {
    if (!data) return undefined;

    const {
      imageMobile,
      imageSite,
      periodEnd,
      periodStart,
      url,
      erid,
      organizationTitle,
    } = data;

    return {
      dateStart: dayjs(periodStart),
      dateFinish: dayjs(periodEnd),
      link: url || undefined,
      organization: organizationTitle || undefined,
      erid: erid || undefined,
      imageDesktopData: null,
      imageDesktopDataAction: CoreSystemEnumsObjectActions.None,
      imageDesktopUrl: imageSite,
      imageMobileData: null,
      imageMobileDataAction: CoreSystemEnumsObjectActions.None,
      imageMobileUrl: imageMobile,
    };
  }, [data]);

  const handleUpdateBannerSlider = useCallback(
    async ({
      imageDesktopData,
      imageMobileData,
      dateFinish,
      dateStart,
      erid,
      organization,
      link,
      imageDesktopDataAction,
      imageMobileDataAction,
    }: SliderFormValue) => {
      if (!dateStart || !dateFinish || !guid) {
        return null;
      }

      const response = await updateBannerSlider({
        slider: {
          imageMobile:
            imageMobileDataAction === CoreSystemEnumsObjectActions.Insert
              ? imageMobileData?.data
              : null,
          imageSite:
            imageDesktopDataAction === CoreSystemEnumsObjectActions.Insert
              ? imageDesktopData?.data
              : null,
          periodStart: dateStart?.format(),
          periodEnd: dateFinish?.format(),
          url: link,
          erid: erid,
          organizationTitle: organization,
          sliderGuid: guid,
        },
      });

      if ('data' in response) {
        navigate(APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS.list);
      }
    },
    [guid, navigate, updateBannerSlider]
  );

  return {
    isLoadingInitialForm,
    isLoadingUpdateBannerSlider,
    handleUpdateBannerSlider,
    initialForm,
  };
};
