import { transformResponseError } from 'shared/api/libs';
import { adaptationApi } from '../adaptationApi';
import {
  AdaptationManagerRequestsStageCreate,
  AdaptationManagerRequestsStagesUpdate,
  AdaptationManagerResponsesGetListStage,
  AdaptationsStagesDeleteParams,
} from '../adaptationAutogenerateApi';
import { adaptationProviderApi } from './adaptationProviderApi';

const adaptationStagesProviderApi = adaptationProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /** Получение списка этапов шаблона */
    getAdaptationStages: build.query<
      AdaptationManagerResponsesGetListStage[],
      string
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.stages.AdaptationsStagesDetail(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];
        return [
          'stages',
          ...res.map((el) => ({ type: 'stages' as const, id: el.guid })),
        ];
      },
    }),
    /* ============================== */

    /** Удаление этапов шаблона */
    deleteAdaptationStage: build.mutation<void, AdaptationsStagesDeleteParams>({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.stages.AdaptationsStagesDelete(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      // TODO: Вот тут можно и подумать
      invalidatesTags: ['stages'],
    }),
    /* ======================= */

    /** Создание этапа шаблона */
    createAdaptationStage: build.mutation<
      AdaptationManagerResponsesGetListStage,
      { adaptationGuid: string; data: AdaptationManagerRequestsStageCreate }
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.stages.AdaptationsStagesCreateCreate(
              args.adaptationGuid,
              args.data
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: ['stages'],
    }),
    /* ======================= */

    /** Обновление этапов шаблона */
    updateAdaptationStages: build.mutation<
      AdaptationManagerResponsesGetListStage[],
      { adaptationGuid: string; data: AdaptationManagerRequestsStagesUpdate }
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.stages.AdaptationsStagesUpdateCreate(
              args.adaptationGuid,
              args.data
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          adaptationStagesProviderApi.util.updateQueryData(
            'getAdaptationStages',
            args.adaptationGuid,
            (draft) => {
              if (args.data.stages) return args.data.stages;
              else draft;
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    /* ========================== */
  }),
});

export const {
  useGetAdaptationStagesQuery,
  useCreateAdaptationStageMutation,
  useDeleteAdaptationStageMutation,
  useUpdateAdaptationStagesMutation,
} = adaptationStagesProviderApi;
