import { ALL_ROUTES, Routes } from './types';

export const TASKS: Routes[] = [
  {
    title: 'Делаю',
    path: ALL_ROUTES.myTasks,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Контролирую',
    path: ALL_ROUTES.createdByMe,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Наблюдаю',
    path: ALL_ROUTES.monitoringTasks,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  // {
  //   title: 'Доски',
  //   path: ALL_ROUTES.boards,
  //   isWork: true,
  //   children: null,
  //   description: null,
  //   icon: null,
  //   fullPath: '',
  // },

  /* Don't work */
  {
    title: 'Уведомления',
    path: ALL_ROUTES.notification,
    isWork: false,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Шаблон задач',
    path: ALL_ROUTES.templateTasks,
    isWork: false,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Мои идеи',
    path: ALL_ROUTES.myIdeas,
    isWork: false,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  /* ===== */
];
