import { RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcessTask } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { RunningTasksWithStageNumber } from '../types';

export const prepareResponseRunnungTasksToTasksStages = (
  responseTasks: RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcessTask[]
): RunningTasksWithStageNumber[] => {
  return responseTasks.reduce<RunningTasksWithStageNumber[]>(
    (accum, current) => {
      const findIndex = accum.findIndex(
        (item) => item.stageNumber === current.stageNumber
      );

      if (findIndex !== -1) {
        accum[findIndex].tasks.push(current);
      } else {
        accum.push({
          stageNumber: current.stageNumber,
          tasks: [current],
        });
      }

      return accum;
    },
    []
  );
};
