import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC, useState } from 'react';

import { ButtonWithActions } from 'shared/ui/button-with-actions';
import { UiSearchBar } from 'shared/ui/ui-search-bar';

import { PlanningRNSearchBarProps } from './type';

export const PlanningRNSearchBar: FC<PlanningRNSearchBarProps> = ({
  onCreateConferenceEvent,
  onCreateOtherEvent,
  onCreatePlanning,
  onSearch,
}) => {
  const [search, setSearch] = useState('');

  const clearSearch = () => {
    setSearch('');
    onSearch('');
  };

  return (
    <UiSearchBar
      changeValue={setSearch}
      textValue={search}
      onClear={clearSearch}
      onCompliteInput={onSearch}
    >
      <Button
        variant='contained'
        onClick={onCreatePlanning}
        startIcon={<Add />}
        color='customBlue'
      >
        Посещение
      </Button>

      <ButtonWithActions
        variant='contained'
        color='customBlue'
        startIcon={<Add />}
        endIcon={undefined}
        actions={[
          { text: 'Сборы', onClick: onCreateConferenceEvent },
          { text: 'Другое', onClick: onCreateOtherEvent },
        ]}
      >
        Мероприятие
      </ButtonWithActions>
    </UiSearchBar>
  );
};
