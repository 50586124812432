import { Grid, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';

import {
  ProtocolMainInformationFieldProps,
  ProtocolMainInformationPros,
} from './types';

export const ProtocolMainInformation: FC<ProtocolMainInformationPros> = ({
  protocol,
  isLoading,
}) => {
  const { title, department, retail } = protocol || {};

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={1}
    >
      <Typography
        variant='h6'
        fontWeight={'bold'}
      >
        Протокол
      </Typography>
      {isLoading && <></>}

      <ProtocolMainInformationField
        name={'Название'}
        value={
          isLoading ? (
            <Skeleton
              variant='rectangular'
              width={800}
              height={'1.4em'}
            />
          ) : (
            title
          )
        }
      />
      <ProtocolMainInformationField
        name={'Сеть'}
        value={
          isLoading ? (
            <Skeleton
              variant='rectangular'
              width={200}
              height={'1.4em'}
            />
          ) : (
            department?.title
          )
        }
      />
      <ProtocolMainInformationField
        name={'Аптека'}
        value={
          isLoading ? (
            <Skeleton
              variant='rectangular'
              width={200}
              height={'1.4em'}
            />
          ) : (
            retail?.title
          )
        }
      />
    </Grid>
  );
};

const ProtocolMainInformationField: FC<ProtocolMainInformationFieldProps> = ({
  name,
  value,
}) => {
  return (
    <Grid
      container
      //gap={{ xs: 1, lg: 2 }}
    >
      <Typography
        fontWeight={'bold'}
        mr={{ xs: 1, lg: 2 }}
      >{`${name} : `}</Typography>
      <Typography>{value || '-'}</Typography>
    </Grid>
  );
};
