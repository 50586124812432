import { useCallback, useEffect, useState } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import {
  useDeleteTaskCommentMutation,
  useGetTaskCommentsQuery,
  useSendCommentMutation,
} from 'shared/api/services/rtk/commentApi';
import { GetListTaskComment } from 'shared/api/services/types';

export const useTaskCommentTab = (taskGuid: string) => {
  const { data: comments, error: error1 } = useGetTaskCommentsQuery(taskGuid);
  const [qDeleteComment, { error: error2 }] = useDeleteTaskCommentMutation();
  const [qSendComment, { isLoading: isSendingComment, error: error3 }] =
    useSendCommentMutation();

  useShowFetchErrorInSnackbar(error1);
  useShowFetchErrorInSnackbar(error2);
  useShowFetchErrorInSnackbar(error3);

  /* Комментарий для ответа */
  const [replyComment, setReplyComment] = useState<GetListTaskComment | null>(
    null
  );

  /* Scrolling to comment field */
  useEffect(() => {
    const field = document.getElementById('CommentField');

    field?.focus();
    field?.scrollIntoView({
      block: 'center',
      inline: 'center',
    });
  }, [comments, replyComment]);
  /* ===== */

  const deleteComment = useCallback(
    (commentGuid: string) => {
      qDeleteComment({
        taskGuid,
        commentGuid,
      });
    },
    [taskGuid]
  );

  const sendComment = useCallback(
    (message: string, replyGuid?: string) => {
      qSendComment({
        taskGuid,
        args: {
          comment: message,
          parentGuid: replyGuid,
        },
      });
    },
    [taskGuid]
  );

  const clearReply = useCallback(() => {
    setReplyComment(null);
  }, []);

  return {
    clearReply,
    sendComment,
    deleteComment,
    comments,
    isSendingComment,
    setReplyComment,
    replyComment,
  };
};
