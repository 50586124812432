import { FormDocumentDelegateActionValue } from './types';

export const DEFAULT_VALUE: FormDocumentDelegateActionValue = {
  delegateActions: [
    {
      action: null,
      employee: null,
    },
  ],
};
