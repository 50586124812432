import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { TextFieldWithController } from 'shared/fields-with-contoller';
import { DialogForForm, FormLayout } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { object } from 'yup';
import {
  BanEmployeForAdaptationFormValue,
  DialogBanEmployeeForAdaptationProcessProps,
} from './types';

export const DialogBanEmployeeForAdaptationProcess: FC<
  DialogBanEmployeeForAdaptationProcessProps
> = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const { control, handleSubmit } = useForm<BanEmployeForAdaptationFormValue>({
    resolver: yupResolver(
      object().shape({
        comment: SCHEMA.STRING_REQUIRED,
      })
    ),
  });

  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Адаптация не требуется' />}
    >
      <FormLayout
        onSubmit={handleSubmit(onSubmit)}
        actions={[
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            variant='contained'
            type='submit'
            key={1}
          >
            Сохранить
          </LoadingButton>,
          <Button
            variant='contained'
            color={'customGrey'}
            disabled={isLoading}
            onClick={onClose}
            key={2}
          >
            Отменить
          </Button>,
        ]}
      >
        <TextFieldWithController
          control={control}
          minRows={4}
          maxRows={8}
          multiline
          name={'comment'}
          label={'Комментарий'}
        />
      </FormLayout>
    </DialogForForm>
  );
};
