import { FC } from 'react';
import { useGetMarketingSurfaceImageQuery } from 'shared/api/marketing-surface/rtk/marketingSurfaceProviderApi';
import type { ImageSurfaceWithFetchProps } from './types';

export const ImageSurfaceWithFetch: FC<ImageSurfaceWithFetchProps> = ({
  image,
  children,
}) => {
  const { data: imageData, isLoading: isLoadingImageData } =
    useGetMarketingSurfaceImageQuery({
      queryParams: { imageName: image.fileName },
    });

  return children({
    src: imageData,
    isLoading: isLoadingImageData,
  });
};
