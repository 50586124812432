import { useStore, useStoreMap } from 'effector-react';
import { useEffect } from 'react';

import { UiDocumentFormReasonTypeAutocomplite } from 'shared/ui/UiDocumentFormReasonTypeAutocomplite';

import { documentFormModel } from 'entities/document-form';

import { fetchTypeReasonDocumentFx } from './api';
import { $optionsDocumentReasonType, documentTypeId } from './model';

export const DocumentFormReasonTypeAutocomplite = () => {
  useEffect(() => {
    fetchTypeReasonDocumentFx();
  }, []);

  const options = useStore($optionsDocumentReasonType);
  const isReasonDocumentRequired = documentFormModel.useValueProcess(
    'isReasonDocumentRequired'
  );
  const value = useStoreMap({
    store: documentTypeId,
    keys: options,
    fn: (id, options) => options.find((item) => item.id === id) || null,
  });

  if (!isReasonDocumentRequired) return null;

  return (
    <UiDocumentFormReasonTypeAutocomplite
      disabled={!isReasonDocumentRequired}
      onChange={(event, value) => {
        documentFormModel.formEvent.setField({
          key: 'reasonDocumentTypeId',
          value: value ? value.id : null,
        });
      }}
      value={value}
      options={options}
    />
  );
};
