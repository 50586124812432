import { FC, useState } from 'react';

import { Button } from '@mui/material';

import { useViewModal } from 'shared/hooks/useViewModal';

import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';

import {
  FilterTraining,
  prepareFormDataToQueryParams,
  useUrlStateTrainingFilter,
} from 'widgets/training/filter';

import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { UiSearchBar } from 'shared/ui/ui-search-bar';

import { FormSignUpForTraining } from 'widgets/training/form-sign-up-for-trainig';
import { LayoutWithManagerBarTrainingProps } from './types';

export const LayoutWithManagerBarTraining: FC<
  LayoutWithManagerBarTrainingProps
> = ({ children, settingsFilter }) => {
  const { isOpen, open, close } = useViewModal(false);
  const { filter, setFilter } = useUrlStateTrainingFilter();
  const [SearchText, setSearchText] = useState<string>('');

  const {
    isOpen: isOpenAllowedTrainings,
    open: onOpenAllowedTraining,
    close: onCloseAllowedTraining,
  } = useViewModal(false);

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <UiSearchBar
          textValue={SearchText}
          changeValue={setSearchText}
          onClear={() => {
            setFilter({ ...filter, SearchText: '' });
            setSearchText('');
          }}
          onCompliteInput={(SearchText) => setFilter({ ...filter, SearchText })}
        >
          <Button
            variant='contained'
            color='customBlue'
            onClick={onOpenAllowedTraining}
            sx={{ order: [2, 0], paddingX: 2 }}
          >
            Записаться
          </Button>
          <FilterButton
            onClick={open}
            sx={{ order: [2, 0] }}
          />
        </UiSearchBar>
      </LayoutPage.Bar>

      <LayoutPage.Content>{children}</LayoutPage.Content>

      {/* FOMS */}

      <FilterTraining
        open={isOpen}
        onClose={close}
        settingsFields={settingsFilter}
        onSubmite={(form) => {
          setFilter({
            ...filter,
            ...prepareFormDataToQueryParams(form),
            Page: 1,
          });
          close();
        }}
      />

      <FormSignUpForTraining
        isOpen={isOpenAllowedTrainings}
        onClose={onCloseAllowedTraining}
      />
    </LayoutPage.Page>
  );
};
