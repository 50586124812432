/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdaptationManagerRequestsAdaptationCreate {
  /**
   * Наименование шаблона
   * @minLength 1
   */
  title: string;
  /** Признак автозапуска */
  isAutorun: boolean;
  /**
   * Список событий для автозапуска<br />
   * options: api/AdaptationEvents<br />
   * *Необходимо указать хотябы один, если IsAutorun == true<br />
   */
  eventGuids: string[];
}

export interface AdaptationManagerRequestsAdaptationUpdate {
  /**
   * Наименование шаблона
   * @minLength 1
   */
  title: string;
  /** Признак автозапуска */
  isAutorun: boolean;
  /**
   * Список событий для автозапуска<br />
   * options: api/AdaptationEvents<br />
   * *Необходимо указать хотябы один, если IsAutorun == true<br />
   */
  eventGuids: string[];
}

export interface AdaptationManagerRequestsBannedEmployeeCreate {
  /**
   * Guid сотрудника
   * @format uuid
   */
  employeeGuid: string;
  /**
   * Комментарий
   * @minLength 1
   */
  comment: string;
}

export interface AdaptationManagerRequestsPreparedStageTask {
  /**
   * Guid шаблона задачи
   * @format uuid
   */
  stageTaskGuid: string;
  /**
   * Guid сотрудника, подобранного для должности постановщика
   * @format uuid
   */
  creatorGuid: string;
  /**
   * Guid сотрудника, подобранного для должности исполнителя
   * @format uuid
   */
  executorGuid: string;
  /** Guid'ы сотрудников, подобранных для должностей соисполнителей */
  coExecutorsGuids: string[];
  /** Guid'ы сотрудников, подобранных для должностей наблюдателей */
  observersGuids: string[];
}

export interface AdaptationManagerRequestsPreparedStageTasksCreate {
  /**
   * Guid шаблона адаптации
   * @format uuid
   */
  adaptationGuid: string;
  /**
   * Guid нового сотрудника
   * @format uuid
   */
  newEmployeeGuid: string;
  /**
   * Guid наставника
   * @format uuid
   */
  mentorGuid: string;
  /**
   * Дата отсчета дней
   * @format date-time
   */
  countdownDate: string;
  /** Список задач с подобранными сотрудниками */
  stageTasks: AdaptationManagerRequestsPreparedStageTask[];
}

export interface AdaptationManagerRequestsStageCreate {
  /**
   * Номер этапа
   * @format int32
   */
  stageNumber: number;
}

/** Запрос на создание шаблона задачи */
export interface AdaptationManagerRequestsStageTaskCreate {
  /**
   * Наименование задачи
   * @minLength 1
   */
  title: string;
  /** Описание задачи */
  description?: string | null;
  /**
   * Кол-во дней отсчета для даты начала
   * @format int32
   */
  startDaysCount: number;
  /**
   * Кол-во дней отсчета для даты завершения
   * @format int32
   */
  finishDaysCount: number;
  /**
   * Guid должность - постановщик
   * @format uuid
   */
  creatorPositionGuid?: string | null;
  /**
   * Guid должность - исполнитель
   * @format uuid
   */
  executorPositionGuid: string;
  /** Список Guid'ов должностей - соисполнителей */
  coExecutorsPositionsGuids: string[];
  /** Список Guid'ов должностей - наблюдателей */
  observersPositionsGuids: string[];
  /** Уведомлять постановщика */
  isReminderCreator: boolean;
  /** Уведомлять исполнителя */
  isReminderExecutor: boolean;
  /** Уведомлять соисполнителя */
  isReminderCoExecutor: boolean;
  /** Уведомлять наблюдателя */
  isReminderObserver: boolean;
  /**
   * Период уведомления постановщика (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCreator?: number | null;
  /**
   * Период уведомления исполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodExecutor?: number | null;
  /**
   * Период уведомления соисполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCoExecutor?: number | null;
  /**
   * Период уведомления наблюдателя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodObserver?: number | null;
  /** Файлы */
  addingFiles?: AdaptationManagerRequestsStakeTaskFileCreate[] | null;
}

export interface AdaptationManagerRequestsStageTaskUpdate {
  /**
   * Наименование задачи
   * @minLength 1
   */
  title: string;
  /** Описание задачи */
  description?: string | null;
  /**
   * Кол-во дней отсчета для даты начала
   * @format int32
   */
  startDaysCount: number;
  /**
   * Кол-во дней отсчета для даты завершения
   * @format int32
   */
  finishDaysCount: number;
  /**
   * Guid должность - постановщик
   * @format uuid
   */
  creatorPositionGuid?: string | null;
  /**
   * Guid должность - исполнитель
   * @format uuid
   */
  executorPositionGuid: string;
  /** Список Guid'ов должностей - соисполнителей */
  coExecutorsPositionsGuids: string[];
  /** Список Guid'ов должностей - наблюдателей */
  observersPositionsGuids: string[];
  /** Уведомлять постановщика */
  isReminderCreator: boolean;
  /** Уведомлять исполнителя */
  isReminderExecutor: boolean;
  /** Уведомлять соисполнителя */
  isReminderCoExecutor: boolean;
  /** Уведомлять наблюдателя */
  isReminderObserver: boolean;
  /**
   * Период уведомления постановщика (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCreator?: number | null;
  /**
   * Период уведомления исполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodExecutor?: number | null;
  /**
   * Период уведомления соисполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCoExecutor?: number | null;
  /**
   * Период уведомления наблюдателя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodObserver?: number | null;
  /** Добавляемые файлы */
  addingFiles?: AdaptationManagerRequestsStakeTaskFileCreate[] | null;
  /** Guid'ы удаляемых файловы */
  deletingFiles?: string[] | null;
}

export interface AdaptationManagerRequestsStageUpdate {
  /**
   * Guid этапа
   * @format uuid
   */
  guid: string;
  /**
   * Номер этапа
   * @format int32
   */
  stageNumber: number;
}

export interface AdaptationManagerRequestsStagesUpdate {
  /** Обновляемые этапы */
  stages?: AdaptationManagerRequestsStageUpdate[] | null;
}

export interface AdaptationManagerRequestsStakeTaskFileCreate {
  /**
   * Наименование файла
   * @minLength 1
   */
  title: string;
  /**
   * Данные файла (строка base64)
   * @minLength 1
   */
  data: string;
}

/** @format int32 */
export enum AdaptationManagerResponsesFilterItemType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface AdaptationManagerResponsesGetAdaptation {
  /**
   * Guid шаблона
   * @format uuid
   */
  guid: string;
  /**
   * Наименование шаблона
   * @minLength 1
   */
  title: string;
  /** Признак автозапуска */
  isAutorun: boolean;
  /** Перечень выбранных для автозапуска событий */
  events: AdaptationManagerResponsesGetListAdaptationEvent[];
  /**
   * Дата создания
   * @format date-time
   */
  createdAt: string;
  /**
   * Создатель
   * @minLength 1
   */
  createdBy: string;
}

export interface AdaptationManagerResponsesGetAllowedAdaptationProcess {
  /** Доступные наставники */
  mentors: AdaptationManagerResponsesGetListEmployee[];
  /** Доступные шаблоны процесса адаптации */
  adaptations: AdaptationManagerResponsesGetListAdaptation[];
  /** Рекомендуется пропустить первый этап */
  skipFirstStage: boolean;
  /**
   * Дата отсчета дней (Дата трудоустройства)
   * @format date-time
   */
  countdownDate?: string | null;
}

/** Сотрудник детально */
export interface AdaptationManagerResponsesGetEmployee {
  /**
   * Guid сотрудника
   * @format uuid
   */
  guid: string;
  /**
   * Имя сотрудника
   * @minLength 1
   */
  name: string;
  /** Должность */
  position: AdaptationManagerResponsesGetListPosition;
  /** Признак сотрудника в отпуске */
  isVacation: boolean;
  /** Подразделение */
  retail: AdaptationManagerResponsesGetListRetail;
}

export interface AdaptationManagerResponsesGetFilter {
  /** Регионы */
  regions: AdaptationManagerResponsesGetFilterItem[];
  /** Сети */
  departments: AdaptationManagerResponsesGetFilterItem[];
  /** Подразделения */
  retails: AdaptationManagerResponsesGetFilterItem[];
  /** Сотрудники */
  employees: AdaptationManagerResponsesGetFilterItem[];
}

export interface AdaptationManagerResponsesGetFilterItem {
  /**
   * Guid элемента
   * @format uuid
   */
  guid: string;
  /**
   * Наименование элемента
   * @minLength 1
   */
  title: string;
  type: AdaptationManagerResponsesFilterItemType;
  /** Список Guid'ов элементов, для которых актуальна текущая запись */
  parentGuids: string[];
}

export interface AdaptationManagerResponsesGetListAdaptation {
  /**
   * Guid шаблона
   * @format uuid
   */
  guid: string;
  /**
   * Наименование шаблона
   * @minLength 1
   */
  title: string;
  /** Признак автозапуска */
  isAutorun: boolean;
  /**
   * Дата создания
   * @format date-time
   */
  createdAt: string;
  /**
   * Создатель
   * @minLength 1
   */
  createdBy: string;
}

export interface AdaptationManagerResponsesGetListAdaptationEvent {
  /**
   * Guid события
   * @format uuid
   */
  guid: string;
  /**
   * Наименование события
   * @minLength 1
   */
  title: string;
}

export interface AdaptationManagerResponsesGetListAdaptationProcess {
  /**
   * Guid запущееного процесса
   * @format uuid
   */
  guid: string;
  /** Первый этап был пропущен */
  skipFirstStage?: boolean;
  /** Сотрудник */
  mentor: AdaptationManagerResponsesGetListEmployee;
  adaptation: AdaptationManagerResponsesGetListAdaptation;
  /**
   * Дата запуска
   * @format date-time
   */
  createdAt: string;
  /**
   * Запустил
   * @minLength 1
   */
  createdBy: string;
}

export interface AdaptationManagerResponsesGetListAdaptationProcessStage {
  /**
   * Наименование этапа
   * @minLength 1
   */
  title: string;
  /** Список задач этапа */
  items: AdaptationManagerResponsesGetListAdaptationProcessTask[];
}

export interface AdaptationManagerResponsesGetListAdaptationProcessTask {
  /**
   * Guid задачи
   * @format uuid
   */
  guid: string;
  /**
   * Наименование задачи
   * @minLength 1
   */
  title: string;
  /**
   * Дата начала
   * @format date-time
   */
  dateStart: string | null;
  /**
   * Дата завершения
   * @format date-time
   */
  dateFinish: string | null;
  /**
   * Статус задачи
   * @minLength 1
   */
  status: string;
  /**
   * Постановщик
   * @minLength 1
   */
  creatorName: string | null;
  /**
   * Исполнитель
   * @minLength 1
   */
  executorName: string | null;
  /**
   * HR
   * @format int32
   */
  units: number | null;
  /** Просрочена */
  isOverdue: boolean;
  /** Целевая */
  isTarget: boolean;
}

export interface AdaptationManagerResponsesGetListBannedEmployee {
  /** Сотрудник */
  employee: AdaptationManagerResponsesGetListEmployee;
  /** Название подразделения основного трудоустройства */
  retailTitle?: string | null;
  /** Комментарий к внесению в банлист */
  comment?: string | null;
}

/** Сотрудник */
export interface AdaptationManagerResponsesGetListEmployee {
  /**
   * Guid сотрудника
   * @format uuid
   */
  guid: string;
  /**
   * Имя сотрудника
   * @minLength 1
   */
  name: string;
  /** Должность */
  position: AdaptationManagerResponsesGetListPosition;
  /** Признак сотрудника в отпуске */
  isVacation: boolean;
}

export interface AdaptationManagerResponsesGetListEmployeeAdaptationProcess {
  /** Сотрудник */
  employee: AdaptationManagerResponsesGetListEmployee;
  adaptationProcess?: AdaptationManagerResponsesGetListAdaptationProcess;
}

/** Сотрудник, подобранный для определенной должности */
export interface AdaptationManagerResponsesGetListEmployeeByPosition {
  /** Должность */
  requeredPosition: AdaptationManagerResponsesGetListPosition;
  /** Сотрудник */
  employee?: AdaptationManagerResponsesGetListEmployee;
}

/** Должность */
export interface AdaptationManagerResponsesGetListPosition {
  /**
   * Guid
   * @format uuid
   */
  guid: string;
  /**
   * Наименование
   * @minLength 1
   */
  title: string;
}

/** Подразделение */
export interface AdaptationManagerResponsesGetListRetail {
  /**
   * Guid
   * @format uuid
   */
  guid: string;
  /**
   * Наименование
   * @minLength 1
   */
  title: string;
}

/** Элемент списка этапов */
export interface AdaptationManagerResponsesGetListStage {
  /**
   * Guid этапа
   * @format uuid
   */
  guid: string;
  /**
   * Номер этапа
   * @format int32
   */
  stageNumber: number;
}

export interface AdaptationManagerResponsesGetListStageTask {
  /**
   * Guid шаблона задачи
   * @format uuid
   */
  guid: string;
  /**
   * Наименование шаблона задачи
   * @minLength 1
   */
  title: string;
  /**
   * Кол-во дней отсчета для даты начала
   * @format int32
   */
  startDaysCount: number;
  /**
   * Кол-во дней отсчета для даты завершения
   * @format int32
   */
  finishDaysCount: number;
  /**
   * Должность - постановщик
   * @minLength 1
   */
  creatorPositionTitle: string;
  /**
   * Должность - исполнитель
   * @minLength 1
   */
  executorPositionTitle: string;
}

export interface AdaptationManagerResponsesGetListStageTaskFile {
  /**
   * Guid файла
   * @format uuid
   */
  guid: string;
  /**
   * Путь файла
   * @minLength 1
   */
  path: string;
  /**
   * Наименование файла
   * @minLength 1
   */
  title: string;
}

/** Подобранне для шаблона задачи сотрудники */
export interface AdaptationManagerResponsesGetListTaskPreparedEmployee {
  /**
   * Номер этапа
   * @format int32
   */
  stageNumber: number;
  /**
   * Guid шаблона задачи
   * @format uuid
   */
  stageTaskGuid: string;
  /**
   * Наименование шаблона задачи
   * @minLength 1
   */
  stageTaskTitle: string;
  /** Сотрудник, подобранный для определенной должности */
  creator: AdaptationManagerResponsesGetListEmployeeByPosition;
  /** Сотрудник, подобранный для определенной должности */
  executor: AdaptationManagerResponsesGetListEmployeeByPosition;
  /** Сотрудники, подобранные для должностей соисполнителей */
  coExecutors: AdaptationManagerResponsesGetListEmployeeByPosition[];
  /** Сотрудники, подобранные для должностей наблюдателей */
  observers: AdaptationManagerResponsesGetListEmployeeByPosition[];
}

export interface AdaptationManagerResponsesGetStageTask {
  /**
   * Guid шаблона задачи
   * @format uuid
   */
  guid: string;
  /**
   * Наименование шаблона задачи
   * @minLength 1
   */
  title: string;
  /**
   * Описание задачи
   * @minLength 1
   */
  description: string | null;
  /**
   * Кол-во дней отсчета для даты начала
   * @format int32
   */
  startDaysCount: number;
  /**
   * Кол-во дней отсчета для даты завершения
   * @format int32
   */
  finishDaysCount: number;
  /** Должность */
  creatorPosition: AdaptationManagerResponsesGetListPosition;
  /** Должность */
  executorPosition: AdaptationManagerResponsesGetListPosition;
  /** Список должностей - соисполнителей */
  coExecutorsPositions: AdaptationManagerResponsesGetListPosition[];
  /** Список должностей - наблюдателей */
  observersPositions: AdaptationManagerResponsesGetListPosition[];
  /** Уведомлять постановщика */
  isReminderCreator: boolean;
  /** Уведомлять исполнителя */
  isReminderExecutor: boolean;
  /** Уведомлять соисполнителя */
  isReminderCoExecutor: boolean;
  /** Уведомлять наблюдателя */
  isReminderObserver: boolean;
  /**
   * Период уведомления постановщика (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCreator: number | null;
  /**
   * Период уведомления исполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodExecutor: number | null;
  /**
   * Период уведомления соисполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCoExecutor: number | null;
  /**
   * Период уведомления наблюдателя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodObserver: number | null;
  /** Список файлов */
  files: AdaptationManagerResponsesGetListStageTaskFile[];
}

export interface CoreResponsesError {
  /**
   * Код ошибки
   * @format int32
   */
  code: number;
  /** Сообщение */
  message?: string | null;
}

export interface PaginatedAdaptationManagerResponsesGetListAdaptation {
  /**
   * Общее кол-во элементов
   * @format int32
   */
  count: number;
  /** Элементы */
  items: AdaptationManagerResponsesGetListAdaptation[];
}

export interface PaginatedAdaptationManagerResponsesGetListBannedEmployee {
  /**
   * Общее кол-во элементов
   * @format int32
   */
  count: number;
  /** Элементы */
  items: AdaptationManagerResponsesGetListBannedEmployee[];
}

export interface PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess {
  /**
   * Общее кол-во элементов
   * @format int32
   */
  count: number;
  /** Элементы */
  items: AdaptationManagerResponsesGetListEmployeeAdaptationProcess[];
}

export interface MicrosoftAspNetCoreMvcProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface AdaptationProcessesPreparedStageTasksListParams {
  /**
   * Guid шаблона адаптации
   * @format uuid
   */
  AdaptationGuid: string;
  /**
   * Guid нового сотрудника
   * @format uuid
   */
  NewEmployeeGuid: string;
  /**
   * Guid наставника
   * @format uuid
   */
  MentorGuid: string;
  /** Перечень Guid'ов этапов адаптации, которые необходимо пропустить (в результате не будет задач по этим этапам) */
  StagesToSkipGuids?: string[];
}

export interface AdaptationProcessesCurrentListParams {
  /**
   * Номер страницы
   * @format int32
   */
  Page: number;
  /**
   * Кол-во элементов на странице
   * @format int32
   */
  Limit: number;
  /** Guid'ы регионов */
  RegionsGuids?: string[];
  /** Guid'ы сетей */
  DepartmentsGuids?: string[];
  /** Guid'ы подразделенийы */
  RetailsGuids?: string[];
  /** Guid'ы сотрудников */
  EmployeesGuids?: string[];
  /** Guid'ы шаблонов адаптации */
  AdaptationsGuids?: string[];
  /** Строка поиска по сотрудникам/наименованию шаблона */
  SearchText?: string;
  /**
   * Сортировка.<br />
   * Указывается одно из следующих полей (если не указано, то будет использовано ProcessCreatedAtDesc):<br />
   * EmployeeNameAsc - имя сотрудника по возрастанию;<br />
   * EmployeeNameDesc - имя сотрудника по убыванию;<br />
   * MentorNameAsc - имя наставника по возрастанию;<br />
   * MentorNameDesc - имя наставника по убыванию;<br />
   * AdaptationTitleAsc - наименование шаблона адаптации по возрастанию;<br />
   * AdaptationTitleDesc - наименование шаблона адаптации по убыванию;<br />
   * ProcessCreatedAtAsc - дата запуска процесса по возрастанию;<br />
   * ProcessCreatedAtDesc - дата запуска процесса по убыванию;<br />
   * ProcessCreatedByAsc - имя запустившего процесс по возрастанию;<br />
   * ProcessCreatedByDesc - имя запустившего процесс по убыванию;<br />
   */
  OrderBy?: string;
}

export interface AdaptationProcessesCompletedListParams {
  /**
   * Номер страницы
   * @format int32
   */
  Page: number;
  /**
   * Кол-во элементов на странице
   * @format int32
   */
  Limit: number;
  /** Guid'ы регионов */
  RegionsGuids?: string[];
  /** Guid'ы сетей */
  DepartmentsGuids?: string[];
  /** Guid'ы подразделенийы */
  RetailsGuids?: string[];
  /** Guid'ы сотрудников */
  EmployeesGuids?: string[];
  /** Guid'ы шаблонов адаптации */
  AdaptationsGuids?: string[];
  /** Строка поиска по сотрудникам/наименованию шаблона */
  SearchText?: string;
  /**
   * Сортировка.<br />
   * Указывается одно из следующих полей (если не указано, то будет использовано ProcessCreatedAtDesc):<br />
   * EmployeeNameAsc - имя сотрудника по возрастанию;<br />
   * EmployeeNameDesc - имя сотрудника по убыванию;<br />
   * MentorNameAsc - имя наставника по возрастанию;<br />
   * MentorNameDesc - имя наставника по убыванию;<br />
   * AdaptationTitleAsc - наименование шаблона адаптации по возрастанию;<br />
   * AdaptationTitleDesc - наименование шаблона адаптации по убыванию;<br />
   * ProcessCreatedAtAsc - дата запуска процесса по возрастанию;<br />
   * ProcessCreatedAtDesc - дата запуска процесса по убыванию;<br />
   * ProcessCreatedByAsc - имя запустившего процесс по возрастанию;<br />
   * ProcessCreatedByDesc - имя запустившего процесс по убыванию;<br />
   */
  OrderBy?: string;
}

export interface AdaptationProcessesFiredListParams {
  /**
   * Номер страницы
   * @format int32
   */
  Page: number;
  /**
   * Кол-во элементов на странице
   * @format int32
   */
  Limit: number;
  /** Guid'ы регионов */
  RegionsGuids?: string[];
  /** Guid'ы сетей */
  DepartmentsGuids?: string[];
  /** Guid'ы подразделенийы */
  RetailsGuids?: string[];
  /** Guid'ы сотрудников */
  EmployeesGuids?: string[];
  /** Guid'ы шаблонов адаптации */
  AdaptationsGuids?: string[];
  /** Строка поиска по сотрудникам/наименованию шаблона */
  SearchText?: string;
  /**
   * Сортировка.<br />
   * Указывается одно из следующих полей (если не указано, то будет использовано ProcessCreatedAtDesc):<br />
   * EmployeeNameAsc - имя сотрудника по возрастанию;<br />
   * EmployeeNameDesc - имя сотрудника по убыванию;<br />
   * MentorNameAsc - имя наставника по возрастанию;<br />
   * MentorNameDesc - имя наставника по убыванию;<br />
   * AdaptationTitleAsc - наименование шаблона адаптации по возрастанию;<br />
   * AdaptationTitleDesc - наименование шаблона адаптации по убыванию;<br />
   * ProcessCreatedAtAsc - дата запуска процесса по возрастанию;<br />
   * ProcessCreatedAtDesc - дата запуска процесса по убыванию;<br />
   * ProcessCreatedByAsc - имя запустившего процесс по возрастанию;<br />
   * ProcessCreatedByDesc - имя запустившего процесс по убыванию;<br />
   */
  OrderBy?: string;
}

export interface AdaptationProcessesWaitingListParams {
  /**
   * Номер страницы
   * @format int32
   */
  Page: number;
  /**
   * Кол-во элементов на странице
   * @format int32
   */
  Limit: number;
  /** Guid'ы регионов */
  RegionsGuids?: string[];
  /** Guid'ы сетей */
  DepartmentsGuids?: string[];
  /** Guid'ы подразделенийы */
  RetailsGuids?: string[];
  /** Guid'ы сотрудников */
  EmployeesGuids?: string[];
  /** Строка поиска по сотрудникам/наименованию шаблона */
  SearchText?: string;
  /**
   * Сортировка.<br />
   * Указывается одно из следующих полей (если не указано, то будет использовано EmployeeNameAsc):<br />
   * EmployeeNameAsc - имя сотрудника по возрастанию;<br />
   * EmployeeNameDesc - имя сотрудника по убыванию;<br />
   */
  OrderBy?: string;
}

export interface AdaptationProcessesBannedListParams {
  /**
   * Номер страницы
   * @format int32
   */
  Page: number;
  /**
   * Кол-во элементов на странице
   * @format int32
   */
  Limit: number;
  /** Строка поиска по сотрудникам */
  SearchText?: string;
  /**
   * Сортировка.<br />
   * Указывается одно из следующих полей (если не указано, то будет использовано ProcessCreatedAtDesc):<br />
   * EmployeeNameAsc - имя сотрудника по возрастанию;<br />
   * EmployeeNameDesc - имя сотрудника по убыванию;<br />
   * RetailTitleAsc - наименование подразделения по возрастанию;<br />
   * RetailTitleDesc - наименование подразделения по убыванию;<br />
   */
  OrderBy?: string;
}

export interface AdaptationProcessesBannedDeleteParams {
  employeesGuids?: string[];
}

export interface AdaptationsListParams {
  /**
   * Номер страницы
   * @format int32
   */
  Page: number;
  /**
   * Кол-во элементов на странице
   * @format int32
   */
  Limit: number;
  /** Строка поиска по наименованию */
  SearchText?: string;
}

export interface AdaptationsDeleteParams {
  /** Guid'ы шаблонов */
  adaptationsGuids?: string[];
}

export interface AdaptationsStagesDeleteParams {
  /** Guid'ы этапов */
  stageGuids?: string[];
  /**
   * Guid шаблона
   * @format uuid
   */
  adaptationGuid: string;
}

export interface AdaptationsStagesStageTasksDeleteParams {
  /** Guid'ы шаблонов задачи */
  stageTaskGuids?: string[];
  /**
   * Guid шаблона
   * @format uuid
   */
  adaptationGuid: string;
  /**
   * Guid этапа
   * @format uuid
   */
  stageGuid: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>(
    { body, secure, path, type, query, format, baseUrl, cancelToken, ...params }: FullRequestParams
  ): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title AdaptationManager.Service
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  adaptationEvents = {
    /**
     * No description
     *
     * @tags AdaptationEvents
     * @name AdaptationEventsList
     * @summary Получение списка событий автозапуска
     * @request GET:/v1/adaptationmanagerweb/api/AdaptationEvents
     * @secure
     * @response `200` `(AdaptationManagerResponsesGetListAdaptationEvent)[]` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Server Error
     */
    AdaptationEventsList: (params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetListAdaptationEvent[], void | CoreResponsesError>({
        path: `/v1/adaptationmanagerweb/api/AdaptationEvents`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  adaptationProcesses = {
    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesAllowedDetail
     * @summary Получение параметров запуска процесса адаптации, доступных для указанного пользователя
     * @request GET:/v1/adaptationmanagerweb/api/AdaptationProcesses/Allowed/{employeeGuid}
     * @secure
     * @response `200` `AdaptationManagerResponsesGetAllowedAdaptationProcess` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationProcessesAllowedDetail: (employeeGuid: string, params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetAllowedAdaptationProcess, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/Allowed/${employeeGuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesPreparedStageTasksList
     * @summary Возвращает список подобранных сотрудников для задач адаптации
     * @request GET:/v1/adaptationmanagerweb/api/AdaptationProcesses/PreparedStageTasks
     * @secure
     * @response `200` `(AdaptationManagerResponsesGetListTaskPreparedEmployee)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Адаптируемый сотрудник не указан или указан не верно;<br /> 2 - У адаптируемого сотрудника не настроено или настроено не верно трудоустройство;<br /> 3 - Выбранный сотрудник добавлен в список сотрудников, для которых не требуется адаптация;<br /> 4 - Указанный наставник не найден;<br /> 5 - Шаблон адаптации не указан или указан не верно;<br /> 6 - Для данного сотрудника уже была запущена адаптация;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `void` Server Error
     */
    AdaptationProcessesPreparedStageTasksList: (
      query: AdaptationProcessesPreparedStageTasksListParams,
      params: RequestParams = {}
    ) =>
      this.request<AdaptationManagerResponsesGetListTaskPreparedEmployee[], CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/PreparedStageTasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesPreparedStageTasksCreate
     * @summary Генерирует задачи и запускает процесс адаптации
     * @request POST:/v1/adaptationmanagerweb/api/AdaptationProcesses/PreparedStageTasks
     * @secure
     * @response `200` `AdaptationManagerResponsesGetListEmployeeAdaptationProcess` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Адаптируемый сотрудник не указан или указан не верно;<br /> 2 - У адаптируемого сотрудника не настроено или настроено не верно трудоустройство;<br /> 3 - Выбранный сотрудник добавлен в список сотрудников, для которых не требуется адаптация;<br /> 4 - Указанный наставник не найден;<br /> 5 - Шаблон адаптации не указан или указан не верно;<br /> 6 - Для данного сотрудника уже была запущена адаптация;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `void` Server Error
     */
    AdaptationProcessesPreparedStageTasksCreate: (
      data: AdaptationManagerRequestsPreparedStageTasksCreate,
      params: RequestParams = {}
    ) =>
      this.request<AdaptationManagerResponsesGetListEmployeeAdaptationProcess, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/PreparedStageTasks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesCurrentList
     * @summary Получение списка "Текущие"
     * @request GET:/v1/adaptationmanagerweb/api/AdaptationProcesses/Current
     * @secure
     * @response `200` `PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationProcessesCurrentList: (query: AdaptationProcessesCurrentListParams, params: RequestParams = {}) =>
      this.request<PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/Current`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesCompletedList
     * @summary Получение списка "Завершенные"
     * @request GET:/v1/adaptationmanagerweb/api/AdaptationProcesses/Completed
     * @secure
     * @response `200` `PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationProcessesCompletedList: (query: AdaptationProcessesCompletedListParams, params: RequestParams = {}) =>
      this.request<PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/Completed`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesFiredList
     * @summary Получение списка "Уволенные"
     * @request GET:/v1/adaptationmanagerweb/api/AdaptationProcesses/Fired
     * @secure
     * @response `200` `PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationProcessesFiredList: (query: AdaptationProcessesFiredListParams, params: RequestParams = {}) =>
      this.request<PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/Fired`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesWaitingList
     * @summary Получение списка "Ожидают адаптации"
     * @request GET:/v1/adaptationmanagerweb/api/AdaptationProcesses/Waiting
     * @secure
     * @response `200` `PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationProcessesWaitingList: (query: AdaptationProcessesWaitingListParams, params: RequestParams = {}) =>
      this.request<PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/Waiting`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesDelete
     * @summary Удаление запущенного процесса адаптации
     * @request DELETE:/v1/adaptationmanagerweb/api/AdaptationProcesses/{adaptationProcessGuid}
     * @secure
     * @response `200` `void` Success
     * @response `204` `void` Процесс удален
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `CoreResponsesError` Not Found
     * @response `500` `CoreResponsesError` Internal Server Error
     */
    AdaptationProcessesDelete: (adaptationProcessGuid: string, params: RequestParams = {}) =>
      this.request<void, void | CoreResponsesError>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/${adaptationProcessGuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesBannedList
     * @summary Получение списка сотрудников, которым не требуется адаптация (банлист)
     * @request GET:/v1/adaptationmanagerweb/api/AdaptationProcesses/Banned
     * @secure
     * @response `200` `PaginatedAdaptationManagerResponsesGetListBannedEmployee` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Server Error
     */
    AdaptationProcessesBannedList: (query: AdaptationProcessesBannedListParams, params: RequestParams = {}) =>
      this.request<PaginatedAdaptationManagerResponsesGetListBannedEmployee, void | CoreResponsesError>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/Banned`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesBannedCreate
     * @summary Добавление сотрудника в банлист
     * @request POST:/v1/adaptationmanagerweb/api/AdaptationProcesses/Banned
     * @secure
     * @response `200` `AdaptationManagerResponsesGetListBannedEmployee` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Комментарий обязателен;<br /> 2 - Указанный сотрудник не найден;<br /> 3 - Указанный сотрудник уже внесен в список;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Server Error
     */
    AdaptationProcessesBannedCreate: (
      data: AdaptationManagerRequestsBannedEmployeeCreate,
      params: RequestParams = {}
    ) =>
      this.request<AdaptationManagerResponsesGetListBannedEmployee, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/Banned`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdaptationProcesses
     * @name AdaptationProcessesBannedDelete
     * @summary Исключение сотрудника из банлиста
     * @request DELETE:/v1/adaptationmanagerweb/api/AdaptationProcesses/Banned
     * @secure
     * @response `204` `void` No Content
     * @response `400` `void` Расшифровка кодов ошибок:<br /> 1 - Комментарий обязателен;<br /> 2 - Указанный сотрудник не найден;<br /> 3 - Указанный сотрудник уже внесен в список;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Server Error
     */
    AdaptationProcessesBannedDelete: (query: AdaptationProcessesBannedDeleteParams, params: RequestParams = {}) =>
      this.request<void, void | CoreResponsesError>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/Banned`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),
  };
  adaptationProcessTasks = {
    /**
     * No description
     *
     * @tags AdaptationProcessTasks
     * @name AdaptationProcessesAdaptationProcessTasksDetail
     * @summary Возвращает список задач из задачника по запущенному процессу
     * @request GET:/v1/adaptationmanagerweb/api/AdaptationProcesses/{adaptationProcessGuid}/AdaptationProcessTasks
     * @secure
     * @response `200` `(AdaptationManagerResponsesGetListAdaptationProcessStage)[]` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `void` Указанный процесс не найден
     * @response `500` `void` Server Error
     */
    AdaptationProcessesAdaptationProcessTasksDetail: (adaptationProcessGuid: string, params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetListAdaptationProcessStage[], CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/AdaptationProcesses/${adaptationProcessGuid}/AdaptationProcessTasks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  adaptations = {
    /**
     * No description
     *
     * @tags Adaptations
     * @name AdaptationsList
     * @summary Получение списка шаблонов процессов адаптации
     * @request GET:/v1/adaptationmanagerweb/api/Adaptations
     * @secure
     * @response `200` `PaginatedAdaptationManagerResponsesGetListAdaptation` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationsList: (query: AdaptationsListParams, params: RequestParams = {}) =>
      this.request<PaginatedAdaptationManagerResponsesGetListAdaptation, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/Adaptations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Adaptations
     * @name AdaptationsCreate
     * @summary Создание шаблона процесса адаптации
     * @request POST:/v1/adaptationmanagerweb/api/Adaptations
     * @secure
     * @response `201` `AdaptationManagerResponsesGetListAdaptation` Created
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Для автоматически запускаемых шаблонов необходимо указать хотябы одно событие;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationsCreate: (data: AdaptationManagerRequestsAdaptationCreate, params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetListAdaptation, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/Adaptations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Adaptations
     * @name AdaptationsDelete
     * @summary Удаление шаблонов процессов адаптации
     * @request DELETE:/v1/adaptationmanagerweb/api/Adaptations
     * @secure
     * @response `200` `void` Success
     * @response `204` `void` Шаблоны удалены
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    AdaptationsDelete: (query: AdaptationsDeleteParams, params: RequestParams = {}) =>
      this.request<void, void | CoreResponsesError>({
        path: `/v1/adaptationmanagerweb/api/Adaptations`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Adaptations
     * @name AdaptationsNonPaginatedList
     * @summary Получение списка шаблонов процессов адаптации
     * @request GET:/v1/adaptationmanagerweb/api/Adaptations/NonPaginated
     * @secure
     * @response `200` `(AdaptationManagerResponsesGetListAdaptation)[]` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationsNonPaginatedList: (params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetListAdaptation[], CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/Adaptations/NonPaginated`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Adaptations
     * @name AdaptationsDetail
     * @summary Получение подробной ифнормации по шаблону адаптации
     * @request GET:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}
     * @secure
     * @response `200` `AdaptationManagerResponsesGetAdaptation` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `void` Шаблон адаптации не найден
     */
    AdaptationsDetail: (adaptationGuid: string, params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetAdaptation, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Adaptations
     * @name AdaptationsUpdate
     * @summary Обновление ифнормации по шаблону адаптации
     * @request PUT:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}
     * @secure
     * @response `200` `AdaptationManagerResponsesGetListAdaptation` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Для автоматически запускаемых шаблонов необходимо указать хотябы одно событие;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    AdaptationsUpdate: (
      adaptationGuid: string,
      data: AdaptationManagerRequestsAdaptationUpdate,
      params: RequestParams = {}
    ) =>
      this.request<
        AdaptationManagerResponsesGetListAdaptation,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  employees = {
    /**
     * No description
     *
     * @tags Employees
     * @name EmployeesList
     * @summary Получение списка сотрудников
     * @request GET:/v1/adaptationmanagerweb/api/Employees
     * @secure
     * @response `200` `(AdaptationManagerResponsesGetListEmployee)[]` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Server Error
     */
    EmployeesList: (params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetListEmployee[], void | CoreResponsesError>({
        path: `/v1/adaptationmanagerweb/api/Employees`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employees
     * @name EmployeesDetail
     * @summary Получение подробной информации по сотруднику
     * @request GET:/v1/adaptationmanagerweb/api/Employees/{employeeGuid}
     * @secure
     * @response `200` `AdaptationManagerResponsesGetEmployee` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Сотрудник не найден
     * @response `500` `CoreResponsesError` Server Error
     */
    EmployeesDetail: (employeeGuid: string, params: RequestParams = {}) =>
      this.request<
        AdaptationManagerResponsesGetEmployee,
        void | MicrosoftAspNetCoreMvcProblemDetails | CoreResponsesError
      >({
        path: `/v1/adaptationmanagerweb/api/Employees/${employeeGuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  filter = {
    /**
     * No description
     *
     * @tags Filter
     * @name FilterList
     * @summary Получение списков элементов для фильтра
     * @request GET:/v1/adaptationmanagerweb/api/Filter
     * @secure
     * @response `200` `AdaptationManagerResponsesGetFilter` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Server Error
     */
    FilterList: (params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetFilter, void | CoreResponsesError>({
        path: `/v1/adaptationmanagerweb/api/Filter`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  positions = {
    /**
     * No description
     *
     * @tags Positions
     * @name PositionsList
     * @summary Получение списка должностей
     * @request GET:/v1/adaptationmanagerweb/api/Positions
     * @secure
     * @response `200` `(AdaptationManagerResponsesGetListPosition)[]` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Server Error
     */
    PositionsList: (params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetListPosition[], void | CoreResponsesError>({
        path: `/v1/adaptationmanagerweb/api/Positions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  stages = {
    /**
     * No description
     *
     * @tags Stages
     * @name AdaptationsStagesDetail
     * @summary Получение списка этапов шаблона
     * @request GET:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}/Stages
     * @secure
     * @response `200` `(AdaptationManagerResponsesGetListStage)[]` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationsStagesDetail: (adaptationGuid: string, params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetListStage[], CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}/Stages`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stages
     * @name AdaptationsStagesDelete
     * @summary Удаление этапов шаблона
     * @request DELETE:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}/Stages
     * @secure
     * @response `200` `void` Success
     * @response `204` `void` Шаблоны удалены
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный шаблон не найден;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    AdaptationsStagesDelete: (
      { adaptationGuid, ...query }: AdaptationsStagesDeleteParams,
      params: RequestParams = {}
    ) =>
      this.request<void, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}/Stages`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stages
     * @name AdaptationsStagesCreateCreate
     * @summary Создание этапа шаблона
     * @request POST:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}/Stages/Create
     * @secure
     * @response `201` `AdaptationManagerResponsesGetListStage` Created
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный шаблон не найден;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    AdaptationsStagesCreateCreate: (
      adaptationGuid: string,
      data: AdaptationManagerRequestsStageCreate,
      params: RequestParams = {}
    ) =>
      this.request<
        AdaptationManagerResponsesGetListStage,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}/Stages/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stages
     * @name AdaptationsStagesUpdateCreate
     * @summary Обновление этапов шаблона
     * @request POST:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}/Stages/Update
     * @secure
     * @response `200` `(AdaptationManagerResponsesGetListStage)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный шаблон не найден;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    AdaptationsStagesUpdateCreate: (
      adaptationGuid: string,
      data: AdaptationManagerRequestsStagesUpdate,
      params: RequestParams = {}
    ) =>
      this.request<
        AdaptationManagerResponsesGetListStage[],
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}/Stages/Update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  stageTasks = {
    /**
     * No description
     *
     * @tags StageTasks
     * @name AdaptationsStagesStageTasksDetail
     * @summary Получение списка шаблонов задач для этапа
     * @request GET:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}/Stages/{stageGuid}/StageTasks
     * @secure
     * @response `200` `(AdaptationManagerResponsesGetListStageTask)[]` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AdaptationsStagesStageTasksDetail: (adaptationGuid: string, stageGuid: string, params: RequestParams = {}) =>
      this.request<AdaptationManagerResponsesGetListStageTask[], CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}/Stages/${stageGuid}/StageTasks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StageTasks
     * @name AdaptationsStagesStageTasksCreate
     * @summary Создание шаблона задачи
     * @request POST:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}/Stages/{stageGuid}/StageTasks
     * @secure
     * @response `201` `AdaptationManagerResponsesGetStageTask` Created
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный этап не найден;<br /> 2 - Наименование задачи не может быть пустым;<br /> 3 - Кол-во дней для отсчета даты начала или даты завершения указаны не верно;<br /> 4 - Период напоминания для Постановщика не указан или указан не верно;<br /> 5 - Период Исполнителя для Постановщика не указан или указан не верно;<br /> 6 - Период напоминания для Соисполнителя не указан или указан не верно;<br /> 7 - Период напоминания для Наблюдателя не указан или указан не верно;<br /> 8 - Указанная должность Постановщика не найдена;<br /> 9 - Указанная должность Исполнителя не найдена;<br /> 10 - Как минимум одна из указанных должностей Соисполнителей не найдена;<br /> 11 - Как минимум одна из указанных должностей Наблюдателей не найдена;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    AdaptationsStagesStageTasksCreate: (
      adaptationGuid: string,
      stageGuid: string,
      data: AdaptationManagerRequestsStageTaskCreate,
      params: RequestParams = {}
    ) =>
      this.request<
        AdaptationManagerResponsesGetStageTask,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}/Stages/${stageGuid}/StageTasks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StageTasks
     * @name AdaptationsStagesStageTasksDelete
     * @summary Удаление шаблонов задач
     * @request DELETE:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}/Stages/{stageGuid}/StageTasks
     * @secure
     * @response `200` `void` Success
     * @response `204` `void` Шаблоны задач удалены
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `void` Расшифровка кодов ошибок:<br /> 1 - Указанный этап шаблона не найден;<br />
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    AdaptationsStagesStageTasksDelete: (
      { adaptationGuid, stageGuid, ...query }: AdaptationsStagesStageTasksDeleteParams,
      params: RequestParams = {}
    ) =>
      this.request<void, CoreResponsesError | void>({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}/Stages/${stageGuid}/StageTasks`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StageTasks
     * @name AdaptationsStagesStageTasksDetail2
     * @summary Получение информации по шаблону задачи
     * @request GET:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}/Stages/{stageGuid}/StageTasks/{stageTaskGuid}
     * @originalName AdaptationsStagesStageTasksDetail2
     * @duplicate
     * @secure
     * @response `200` `AdaptationManagerResponsesGetStageTask` Success
     * @response `400` `CoreResponsesError` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Задача не найдена
     */
    AdaptationsStagesStageTasksDetail2: (
      adaptationGuid: string,
      stageGuid: string,
      stageTaskGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<
        AdaptationManagerResponsesGetStageTask,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}/Stages/${stageGuid}/StageTasks/${stageTaskGuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StageTasks
     * @name AdaptationsStagesStageTasksUpdate
     * @summary Обновление шаблона задачи
     * @request PUT:/v1/adaptationmanagerweb/api/Adaptations/{adaptationGuid}/Stages/{stageGuid}/StageTasks/{stageTaskGuid}
     * @secure
     * @response `200` `AdaptationManagerResponsesGetStageTask` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный этап не найден;<br /> 2 - Наименование задачи не может быть пустым;<br /> 3 - Кол-во дней для отсчета даты начала или даты завершения указаны не верно;<br /> 4 - Период напоминания для Постановщика не указан или указан не верно;<br /> 5 - Период Исполнителя для Постановщика не указан или указан не верно;<br /> 6 - Период напоминания для Соисполнителя не указан или указан не верно;<br /> 7 - Период напоминания для Наблюдателя не указан или указан не верно;<br /> 8 - Указанная должность Постановщика не найдена;<br /> 9 - Указанная должность Исполнителя не найдена;<br /> 10 - Как минимум одна из указанных должностей Соисполнителей не найдена;<br /> 11 - Как минимум одна из указанных должностей Наблюдателей не найдена;<br /> 12 - Следующие удаляемые файлы не найдены: {FileGuids};<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    AdaptationsStagesStageTasksUpdate: (
      adaptationGuid: string,
      stageGuid: string,
      stageTaskGuid: string,
      data: AdaptationManagerRequestsStageTaskUpdate,
      params: RequestParams = {}
    ) =>
      this.request<
        AdaptationManagerResponsesGetStageTask,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/adaptationmanagerweb/api/Adaptations/${adaptationGuid}/Stages/${stageGuid}/StageTasks/${stageTaskGuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
