import { Typography } from '@mui/material';

import { RETAIL_OPENING_PROCESS_HELPER_LINKS } from 'entities/retail-opening-process/links';
import { Link } from 'shared/ui/base-ui';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import { ProcessOpeningRetailsTableData } from '../types';

export const RUNNING_PROTOCOLS_FIELD_MAP: FieldMapType<ProcessOpeningRetailsTableData>[] =
  [
    {
      field: 'title',
      title: 'Название шаблона',
      getTableCellProps: () => {
        return {
          sx: {
            maxWidth: 300,
          },
        };
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        const { processGuid, protocolGuid, guid } = value;

        return (
          <Link
            to={RETAIL_OPENING_PROCESS_HELPER_LINKS.createLinkRunningProcess({
              processGuid: processGuid,
              protocolGuid: protocolGuid,
              openingTemplateGuid: guid,
            })}
          >
            {value.title}
          </Link>
        );
      },
    },
    {
      field: 'isAutorun',
      title: 'Автозапуск',
      getTableCellProps: () => {
        return {
          sx: {
            maxWidth: 300,
          },
        };
      },
      isShow: true,
      order: 2,
      renderComponent: (value) => {
        return <Typography>{value.isAutorun ? 'Да' : 'Нет'}</Typography>;
      },
    },
    {
      field: 'processGuid',
      title: 'Гуид процесса',
      getTableCellProps: () => {
        return {
          sx: {
            maxWidth: 300,
          },
        };
      },
      isShow: false,
      order: 3,
      renderComponent: (value) => {
        return <Typography>{value.processGuid}</Typography>;
      },
    },
  ];
