import { mixed, object, ObjectSchema } from 'yup';

import { DocumentFlowFilterBaseForm } from './types';

export const SCHEME_BASE_FILTER_FORM: ObjectSchema<DocumentFlowFilterBaseForm> =
  object({}).shape(
    {
      sumTo: mixed<number | string>().required(),
      sumFrom: mixed<number | string>().required(),
      articles: mixed<DocumentFlowFilterBaseForm['articles']>().required(),
      contractors:
        mixed<DocumentFlowFilterBaseForm['contractors']>().required(),
      statuses: mixed<DocumentFlowFilterBaseForm['statuses']>().required(),
      organizations:
        mixed<DocumentFlowFilterBaseForm['organizations']>().required(),
      documentTypes:
        mixed<DocumentFlowFilterBaseForm['documentTypes']>().required(),
      dateFrom: mixed<NonNullable<DocumentFlowFilterBaseForm['dateFrom']>>()
        .required()
        .nullable()
        .when('dateTo', ([dateTo], scheme) => {
          return scheme.test(
            'dateTo',
            '"Дата начала" должна быть меньше или равна "Дате окончания"',
            (dateFrom) => {
              if (dateFrom && dateTo) {
                return dateFrom.isBefore(dateTo) || dateFrom.isSame(dateTo);
              }

              return true;
            }
          );
        }),
      dateTo: mixed<NonNullable<DocumentFlowFilterBaseForm['dateTo']>>()
        .required()
        .nullable()
        .when('dateFrom', ([dateFrom], scheme) => {
          return scheme.test(
            'dateFrom',
            '"Дата окончания" должна быть больше или равна "Дате начала"',
            (dateTo) => {
              if (dateTo && dateFrom) {
                return dateFrom.isBefore(dateTo) || dateFrom.isSame(dateTo);
              }

              return true;
            }
          );
        }),
    },
    [['dateTo', 'dateFrom']]
  );
