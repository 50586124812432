import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { BANNERS_SLIDER_ASPECT_RATIO } from 'entities/apteka-legko/banners/sliders/constants';
import { FC, useEffect } from 'react';
import {
  DatePikerWithController,
  TextFieldWithController,
} from 'shared/fields-with-contoller';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DEFAULT_VALUES } from './constants';
import { useImageFormField } from './hooks/useImageFormField';

import { APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS } from 'entities/apteka-legko/banners/sliders/links';
import { LinkButton } from 'shared/ui/base-ui/link';
import { ImageUploaderPreview } from 'shared/ui/image-uploader-preview';
import { SliderFormProps, SliderFormValue } from './types';
import { ContainerSection } from './ui/ContainerSection';
import { SCHEME_VALIDATE_SLIDER_FORM } from './validation';

export const SliderForm: FC<SliderFormProps> = ({
  initialValue,
  isLoading,
  onSubmit,
}) => {
  const { control, setValue, handleSubmit, reset } = useForm<SliderFormValue>({
    defaultValues: initialValue || DEFAULT_VALUES,
    resolver: yupResolver(SCHEME_VALIDATE_SLIDER_FORM),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    reset(initialValue);
  }, [initialValue]);

  const {
    onChangeInputImage: onChangeInputImageDesktop,
    imageDataValue: imageDesktopData,
    imageUrlValue: imageDesktopUrl,
  } = useImageFormField({
    setValue,
    control,
    keyAction: 'imageDesktopDataAction',
    keyData: 'imageDesktopData',
    keyUrl: 'imageDesktopUrl',
  });
  const {
    onChangeInputImage: onChangeInputImageMobile,
    imageDataValue: imageMobileData,
    imageUrlValue: imageMobileUrl,
  } = useImageFormField({
    setValue,
    control,
    keyAction: 'imageMobileDataAction',
    keyData: 'imageMobileData',
    keyUrl: 'imageMobileUrl',
  });

  return (
    <Grid
      component={'form'}
      display={'flex'}
      flexDirection={'column'}
      onSubmit={handleSubmit(onSubmit, (errors) => {
        console.log('errors form : ', errors);
      })}
    >
      <Grid
        display={'flex'}
        flexDirection={'column'}
        gap={2}
      >
        <ContainerSection title='Изображения'>
          <Grid
            display={'grid'}
            gridTemplateColumns={['1fr', '1fr 1fr']}
            paddingX={[1, 0]}
            gap={4}
          >
            <Controller
              control={control}
              name='imageDesktopData'
              render={({ fieldState: { error } }) => {
                return (
                  <ImageUploaderPreview
                    disabledButton={isLoading}
                    onChangeInput={onChangeInputImageDesktop}
                    imageData={imageDesktopData}
                    imageUrl={imageDesktopUrl}
                    textHeader={'Десктопная версия'}
                    textHeaderTooltip={'1440 х 391 px'}
                    maxHeightContainerImage={178}
                    maxWidthContainerImage={
                      178 * BANNERS_SLIDER_ASPECT_RATIO.desktop
                    }
                    uploadButtonId={'desktop'}
                    error={error?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='imageDesktopData'
              render={({ fieldState: { error } }) => {
                return (
                  <ImageUploaderPreview
                    disabledButton={isLoading}
                    onChangeInput={onChangeInputImageMobile}
                    imageData={imageMobileData}
                    imageUrl={imageMobileUrl}
                    textHeader={'Мобильная версия'}
                    textHeaderTooltip={'390 х 276 px'}
                    maxHeightContainerImage={178}
                    maxWidthContainerImage={
                      178 * BANNERS_SLIDER_ASPECT_RATIO.mobile
                    }
                    uploadButtonId={'mobile'}
                    error={error?.message}
                  />
                );
              }}
            />
          </Grid>
        </ContainerSection>

        <ContainerSection title='Период размещения'>
          <Grid
            display={'grid'}
            gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}
            gap={2}
          >
            <DatePikerWithController
              control={control}
              disabled={isLoading}
              name='dateStart'
              fullWidth
              label={'Дата начала'}
            />
            <DatePikerWithController
              control={control}
              disabled={isLoading}
              name='dateFinish'
              fullWidth
              label={'Дата окончания'}
            />
          </Grid>
        </ContainerSection>
        <ContainerSection title='Маркировка'>
          <Grid
            display={'grid'}
            gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}
            gap={2}
          >
            <TextFieldWithController
              control={control}
              disabled={isLoading}
              name='organization'
              fullWidth
              label='Юридическое лицо'
            />
            <TextFieldWithController
              control={control}
              disabled={isLoading}
              fullWidth
              name='erid'
              label='Erid'
            />
          </Grid>
        </ContainerSection>
        <ContainerSection title='Другие настройки'>
          <Grid
            display={'grid'}
            gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}
            gap={2}
          >
            <TextFieldWithController
              control={control}
              disabled={isLoading}
              fullWidth
              name='link'
              label='Ссылка'
            />
          </Grid>
        </ContainerSection>
      </Grid>
      <Grid sx={{ position: 'sticky', bottom: 0, marginTop: 10, zIndex: 2 }}>
        <ContainerActionsForm>
          <LoadingButton
            disabled={isLoading}
            loading={isLoading}
            type='submit'
            variant='contained'
          >
            Сохранить
          </LoadingButton>
          <LinkButton
            disabled={isLoading}
            color='customGrey'
            variant='contained'
            to={APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS.list}
          >
            Закрыть
          </LinkButton>
        </ContainerActionsForm>
      </Grid>
    </Grid>
  );
};
