import { useEffect, useState } from 'react';
import { getAllLocalStorageKeys } from 'shared/user-app-settings/libs/getAllLocalStorageKeys';
import { TableRowSettingType } from 'shared/user-app-settings/type';
import { useAppSettings } from 'shared/user-app-settings/user-app-settings-context';
import { Tables } from 'shared/utils/controllers';

export const useTablesSettingsVersionMigrationController = () => {
  const { appSettings, updateAppSettings } = useAppSettings();
  const [isMigrated, setIsMigrated] = useState(false);

  useEffect(() => {
    if (isMigrated || !appSettings) return;

    const allKeys = getAllLocalStorageKeys();

    const newSettings: TableRowSettingType[] = [];

    Object.values(Tables).forEach((key) => {
      const localStorageKey = allKeys.find((storageKey) =>
        storageKey.includes(key)
      );

      if (!localStorageKey) return;

      const localStorageItem = localStorage.getItem(localStorageKey);

      if (!localStorageItem) throw new Error('Тут происходит что-то странное');

      const storageSettings = JSON.parse(localStorageItem);

      newSettings.push({
        settings: storageSettings,
        table: key,
        version: Number(localStorageKey.at(-1)),
      });

      localStorage.removeItem(localStorageKey);
    });

    if (newSettings.length > 0) {
      updateAppSettings({ ...appSettings, tableRowSettings: newSettings });
    }

    setIsMigrated(true);
  }, [appSettings]);
};
