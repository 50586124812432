import dayjs from 'dayjs';
import { FC } from 'react';

import { Grid } from '@mui/material';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { LeftLabelWithChildren } from 'shared/ui/left-label-with-children';
import { RunProcessInfoViewProps } from './types';

export const RunProcessInfoView: FC<RunProcessInfoViewProps> = ({
  skipStages,
  countdownDate,
  isLoading = false,
}) => {
  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={1}
    >
      <LeftLabelWithChildren
        isLoading={isLoading}
        label='Пропустить этапы'
      >
        {skipStages
          ? skipStages?.reduce<string>((accum, current, index) => {
              let str = `${current.stageNumber} Этап`;

              if (index > 0) {
                str = `, ${str}`;
              }

              return accum + str;
            }, '')
          : '-'}
      </LeftLabelWithChildren>
      <LeftLabelWithChildren label='Отсчет дней от'>
        {countdownDate ? dayjs(countdownDate).format(DATE_FORMATS.client) : '-'}
      </LeftLabelWithChildren>
    </Grid>
  );
};
