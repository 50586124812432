import { FC, ReactNode } from 'react';

import { DocumentActionsReturnDocumentCommentModal } from './DocumentActionsReturnDocumentCommentModal';
import { DocumentActionManagerAgreeRequestOrderresetModal } from './OrderManagerAgreeReques';

export const LayoutWithDocumentActionsModal: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <>
      {children}
      <DocumentActionManagerAgreeRequestOrderresetModal />
      <DocumentActionsReturnDocumentCommentModal />
    </>
  );
};
