import { Autocomplete, CircularProgress } from '@mui/material';

import { Controller } from 'react-hook-form';
import { CustomTextField } from 'shared/ui/base-ui';
import { AutocompliteWithControllerComponent } from './type';
/**
 * @description связка Controller из react-hook-form и CustomDatePicker / CustomTextField / Autocomplete / Switch.
 * @see {@linkcode shared/ui/base-ui}
 */
export const AutocompliteWithController: AutocompliteWithControllerComponent =
  ({
    name,
    control,
    loading,
    freeSolo,
    slotProps,
    textFieldProps,
    ...otherProps
  }) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Autocomplete
              value={value}
              onChange={(event, value) => onChange(value)}
              {...otherProps}
              size='small'
              loading={loading}
              freeSolo={freeSolo}
              loadingText={'Загрузка...'}
              slotProps={{
                ...slotProps,
              }}
              renderInput={(inputProps) => (
                <CustomTextField
                  {...inputProps}
                  {...textFieldProps}
                  error={Boolean(error)}
                  helperText={error?.message || textFieldProps.helperText}
                  InputProps={{
                    ...inputProps.InputProps,
                    ...textFieldProps.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress
                            color='primary'
                            size={20}
                          />
                        ) : null}
                        {inputProps.InputProps.endAdornment}
                        {textFieldProps.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          );
        }}
      />
    );
  };
