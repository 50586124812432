import { FC } from 'react';

import {
  UiTableCard,
  UiTableCardList,
  UiTableCardPagination,
} from 'shared/ui/table';
import { TrainingTableActions } from '../actions';
import { ControlTrainingTableProps } from './types';

export const ControlTrainingList: FC<ControlTrainingTableProps> = ({
  count,
  data,
  fields,
  limit,
  onChangeFields,
  onChangeLimit,
  onChangePage,
  page,
  visibleFields,
  isLoading = false,
  onClick,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading}
      pagination={
        <UiTableCardPagination
          count={count}
          onPageChange={onChangePage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowsPerPageChange={onChangeLimit}
        />
      }
    >
      {data.map((training) => (
        <UiTableCard
          data={training}
          id={training.trainingEventGuid}
          visibleFields={visibleFields}
          key={training.trainingEventGuid}
          onClick={() => {
            onClick && onClick(training);
          }}
          renderActions={() => {
            return (
              <TrainingTableActions
                variant='myTraining'
                training={{
                  statusGuid: training.statusGuid,
                  trainingEventGuid: training.trainingEventGuid,
                  employeeGuid: training.employeeGuid,
                  trainingEmployeeGuid: training.trainingEmployeeGuid,
                }}
              />
            );
          }}
        />
      ))}
    </UiTableCardList>
  );
};
