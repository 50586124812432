import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';

import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import { CreatePlanningRNForm } from 'widgets/visits/forms/create-planning-rn-form';

import { CreatePlanningRNDialogProps } from './type';

export const CreatePlanningRNDialog: FC<CreatePlanningRNDialogProps> = ({
  isOpen,
  onClose,
  isLoading = false,
  onSubmit,
  initValue,
  blocked,
}) => {
  return (
    <DialogForForm
      head={<DialogTitleForForm title='Планирование' />}
      open={isOpen}
      onClose={onClose}
    >
      <CreatePlanningRNForm
        initValue={initValue}
        blocked={blocked}
        actions={() => [
          <Button
            color='customGrey'
            variant='contained'
            onClick={onClose}
            key={1}
          >
            Закрыть
          </Button>,
          <LoadingButton
            loading={isLoading}
            color='primary'
            variant='contained'
            key={2}
            type='submit'
          >
            Сохранить
          </LoadingButton>,
        ]}
        onSubmit={onSubmit}
      />
    </DialogForForm>
  );
};
