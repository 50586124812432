import { Box } from '@mui/material';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { TextFieldWithController } from 'shared/fields-with-contoller';
import { SwitchWithController } from 'shared/fields-with-contoller/switch-with-controller';
import { RemindFormRowProps } from './type';

export const RemindFormRow: FC<RemindFormRowProps> = ({
  control,
  fieldLabel,
  fieldName,
  switchLabel,
  switchName,
  disabled = false,
}) => {
  const isSwitched = useWatch({ control, name: switchName });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
      }}
    >
      <SwitchWithController
        control={control}
        name={switchName}
        size='medium'
        disabled={disabled}
        formControlLabelProps={{
          label: switchLabel,
          sx: { flex: 0.5, mx: [0.5, 0] },
        }}
      />

      <TextFieldWithController
        control={control}
        name={fieldName}
        type='number'
        InputProps={{ inputProps: { min: 0 } }}
        label={fieldLabel}
        disabled={disabled || !isSwitched}
        containerSx={{ flex: 0.45 }}
      />
    </Box>
  );
};
