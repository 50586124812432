import { findElementsByIds } from 'shared/tarnsform-helper/findElementsByIds';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import {
  useLazyGetDocumentFlowOptionsOptionsBaseFilterForm,
  useTransfromUrlStateToBaseFormFilter,
} from '../../hooks';
import { DocumentFlowFilterActionForm } from '../type';
import { useUrlStateDocumentActionFilter } from '../use-url-state-document-action-filter';

export const useInitialActionFilterFormFromUrlState = () => {
  const { filter } = useUrlStateDocumentActionFilter();
  const { initBaseFilter } = useTransfromUrlStateToBaseFormFilter();
  const { fetchEmployee, isLoadingAnyLaziOptionsDocumentFlow } =
    useLazyGetDocumentFlowOptionsOptionsBaseFilterForm();

  const onInitial = async (): Promise<DocumentFlowFilterActionForm> => {
    const [baseFilters, optionsEmployee] = await Promise.all([
      initBaseFilter(filter),
      fetchEmployee(undefined, true).unwrap(),
    ]);

    const initialFormState: DocumentFlowFilterActionForm = {
      ...baseFilters,
      creators: findElementsByIds(
        optionsEmployee,
        filter?.CreatorIdFilters || EMPT_ARR
      ),
    };

    return initialFormState;
  };

  return [onInitial, isLoadingAnyLaziOptionsDocumentFlow] as const;
};
