import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import {
  deleteDocumentsDocumentId,
  getDocumentsDocumentId,
} from 'shared/api/services-document-flow/services';
import { ErrorResponse } from 'shared/api/services-document-flow/types';

export const getDocumentIdFx = createEffect<
  typeof getDocumentsDocumentId,
  AxiosError<ErrorResponse>
>(getDocumentsDocumentId);

export const deleteDocumentIdFx = createEffect<
  typeof deleteDocumentsDocumentId,
  AxiosError<ErrorResponse>
>(deleteDocumentsDocumentId);
