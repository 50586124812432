export const VkSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='18'
      fill='none'
      viewBox='0 0 32 18'
    >
      <path
        fill='#1B75BB'
        d='M.987 1.217h5.441c.206 0 .41.102.462.307 1.232 2.713 2.772 5.17 4.62 7.319.154.256.513.205.667-.051.565-1.382 1.643-4.76-.41-7.166-.205-.204-.103-.563.154-.665 2.053-.87 4.106-.973 6.057-.102.41.204.667.614.667 1.023l.462 6.96c0 .308.308.564.565.513 2.258-.46 3.593-3.89 4.928-7.422.154-.46.564-.716 1.026-.716h4.98c.41 0 .718.358.667.768-.462 2.507-2.977 5.783-4.312 7.319a1.467 1.467 0 000 1.893l4.466 5.323c.308.358.051.973-.462.973h-5.647c-.462 0-.924-.205-1.232-.512l-3.952-3.276c-.205-.204-.616-.307-.77-.051-.513.614-.462 1.33-.667 2.969-.052.511-.565.87-1.078.87h-3.85a3.448 3.448 0 01-1.592-.359C9.97 16.111 3.913 12.374.474 1.984c-.103-.409.154-.767.513-.767z'
      ></path>
    </svg>
  );
};
