import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import { CustomColorPickerProps } from './type';

export const CustomColorPicker: FC<CustomColorPickerProps> = ({
  onChange,
  options,
  value,
}) => {
  return (
    <Grid
      container
      flexDirection={'row'}
      gap={1}
      justifyContent={'flex-start'}
      flexWrap={'wrap'}
      alignItems={'center'}
    >
      {options.map((color) => (
        <Box
          onClick={() => {
            onChange(color);
          }}
          key={color}
          sx={{
            boxSizing: 'border-box',
            background: color,
            width: 30,
            height: 30,
            opacity: value === color ? 1 : 0.5,
            borderRadius: '50%',
            transition: 'ease-in-out .2s',
            cursor: 'pointer',
          }}
        />
      ))}
    </Grid>
  );
};
