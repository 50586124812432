import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { SwipeableDrawerImagesListUiContextValue } from './types';

const SwipeableDrawerImagesListUiContext =
  createContext<SwipeableDrawerImagesListUiContextValue>(
    {} as SwipeableDrawerImagesListUiContextValue
  );

const SwipeableDrawerImagesListUiContextOnOpen = createContext<
  (surfaceGuid: string) => void
>(() => {
  return null;
});

export const SwipeableDrawerImagesListUiProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [selectSurfaceGuid, seSelectSurfaceGuid] = useState<
    null | string | undefined
  >(null);

  const onOpenImagesDrawerAndSelectSurfaceGuid = useCallback(
    (surfaceGuid: string) => {
      seSelectSurfaceGuid(surfaceGuid);
    },
    []
  );

  const onCloseImagesDrawerAndSelectSurfaceGuid = useCallback(() => {
    seSelectSurfaceGuid(null);
  }, []);

  return (
    <SwipeableDrawerImagesListUiContext.Provider
      value={{
        onOpenImagesDrawerAndSelectSurfaceGuid,
        selectSurfaceGuid,
        onCloseImagesDrawerAndSelectSurfaceGuid,
        isOpenImagesDrawer: Boolean(selectSurfaceGuid),
      }}
    >
      <SwipeableDrawerImagesListUiContextOnOpen.Provider
        value={onOpenImagesDrawerAndSelectSurfaceGuid}
      >
        {children}
      </SwipeableDrawerImagesListUiContextOnOpen.Provider>
    </SwipeableDrawerImagesListUiContext.Provider>
  );
};

/* use context */
export const useContextOnOpenImagesDrawerAndSelectSurfaceGuid = () =>
  useContext(SwipeableDrawerImagesListUiContextOnOpen);
export const useContextSwipeableDrawerImagesListUi = () =>
  useContext(SwipeableDrawerImagesListUiContext);
