import { AdaptationProcessesCurrentListParams } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { useGetAdaptationProcessesFilterQuery } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { useGetAdaptationTemplatesQuery } from 'shared/api/service-adaptation/rtk/adaptationTemplatesProviderApi';
import { getGuidsFromArray } from 'shared/tarnsform-helper/getGuidsFromArray';
import { getObjByGuids } from 'shared/tarnsform-helper/getObjByGuids';
import { AdaptationProcessesFilterFormFields } from '../forms/AdaptationProcessesFilterForm/type';

export const useAdaptationProcessesFilterConverter = () => {
  /* Form options */
  const { data: adaptations, isLoading: isLoadingAdaptations } =
    useGetAdaptationTemplatesQuery();
  const { data: backFilter, isLoading: isLoadingFilter } =
    useGetAdaptationProcessesFilterQuery();

  /* ======== */

  const convertFilterToForm = (
    filter: Omit<
      AdaptationProcessesCurrentListParams,
      'Page' | 'Limit' | 'SearchText' | 'OrderBy'
    >
  ): AdaptationProcessesFilterFormFields => {
    return {
      adaptations: getObjByGuids(adaptations, filter.AdaptationsGuids),
      departments: getObjByGuids(
        backFilter?.departments,
        filter.DepartmentsGuids
      ),
      employees: getObjByGuids(backFilter?.employees, filter.EmployeesGuids),
      regions: getObjByGuids(backFilter?.regions, filter.RegionsGuids),
      retails: getObjByGuids(backFilter?.retails, filter.RetailsGuids),
    };
  };

  const convertFormToFilter = (
    form: AdaptationProcessesFilterFormFields
  ): Omit<
    AdaptationProcessesCurrentListParams,
    'Page' | 'Limit' | 'SearchText' | 'OrderBy'
  > => {
    return {
      AdaptationsGuids: getGuidsFromArray(form.adaptations),
      DepartmentsGuids: getGuidsFromArray(form.departments),
      EmployeesGuids: getGuidsFromArray(form.employees),
      RegionsGuids: getGuidsFromArray(form.regions),
      RetailsGuids: getGuidsFromArray(form.retails),
    };
  };

  return {
    convertFormToFilter,
    convertFilterToForm,
    isLoading: isLoadingAdaptations || isLoadingFilter,
  };
};
