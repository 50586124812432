import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useDeleteOpeningTemplatesStageTaskMutation } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useConfirmDeleteOpeningTemplateTask = (params: {
  templateGuid: string;
  stageGuid: string;
  stageTaskGuids: string[];
}) => {
  const { templateGuid, stageGuid, stageTaskGuids } = params;
  const [deleteTemplates, { isLoading: isLoadingDeleteTemplate }] =
    useDeleteOpeningTemplatesStageTaskMutation();
  const { open: openSnackBar } = useSnackbar();
  const {
    isOpen: isOpenConfirmDialog,
    close: onCancelConfirmation,
    open: onOpenDialogConfirm,
  } = useViewModal();

  const onConfirmDelete = useCallback(async () => {
    await deleteTemplates({
      stageTaskGuids,
      templateGuid,
      stageGuid,
    }).then((response) => {
      if ('error' in response) {
        openSnackBar({
          text: getMessageFromFetchBaseQueryError(response.error),
          type: 'error',
          duration: 3000,
        });
      } else {
        openSnackBar({
          text: 'Шаблон успешно удален',
          type: 'success',
          duration: 3000,
        });
        onCancelConfirmation();
      }
    });
  }, [stageGuid, templateGuid, stageTaskGuids]);

  return {
    onCancelConfirmation,
    onConfirmDelete,
    onOpenDialogConfirm,
    isOpenConfirmDialog,
    isLoadingDeleteTemplate,
  };
};
