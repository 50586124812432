import { useNavigate, useSearchParams } from 'react-router-dom';

export const useUrlRedirect = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const redirect = (defaultPath?: string) => {
    const redirectPath = params.get('redirect') || defaultPath;

    if (redirectPath) navigate(redirectPath);
    else console.error('no path to redirect');
  };

  return redirect;
};
