import dayjs from 'dayjs';
import { CalendarDay } from 'features/calendar/calendar-day';
import { FC, memo } from 'react';

import { isEqDay } from 'shared/date-helper/isEqDay';
import { theme } from 'shared/theme/theme';
import { UiCalendarCellContainer } from 'shared/ui/calendar/ui-calendar-cell-container';

import { VisitBagesForCalendar } from '../visit-bages-for-calendar';
import { VisitCalendarCellProps } from './type';

export const VisitCalendarCell: FC<VisitCalendarCellProps> = memo(
  ({
    date,
    visits,
    isSelected = false,
    isToday = false,
    isLoading = false,
    onClick,
  }) => {
    const day = dayjs(date).date();

    const visitsOfCurrentDay = visits.filter((visit) =>
      isEqDay(date, visit.visitingDate)
    );

    return (
      <UiCalendarCellContainer
        containerProps={{
          onClick: () => {
            onClick && onClick(dayjs(date));
          },
          sx: {
            p: 1,
            height: 140,
            cursor: 'pointer',
            ':hover': {
              borderColor: theme.palette.grey[400],
            },
          },
        }}
      >
        <CalendarDay
          day={day}
          isSelected={isSelected}
          isToday={isToday}
        />
        <VisitBagesForCalendar
          visits={visitsOfCurrentDay}
          isLoading={isLoading}
        />
      </UiCalendarCellContainer>
    );
  }
);
