import { Grid, SxProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const STYLES_CONTAINER: SxProps = {
  display: 'grid',
  gridTemplateColumns: [
    '1fr',
    '1fr',
    '1fr 1fr',
    '1fr 1fr 1fr',
    '1fr 1fr 1fr 1fr',
  ],
  gap: [3],
};

export const NavigationCardContainer: FC<PropsWithChildren> = ({
  children,
}) => {
  return <Grid sx={STYLES_CONTAINER}>{children}</Grid>;
};
