import dayjs from 'dayjs';

import {
  onChangeTypePayBeforDate,
  UiDocumentFormPayBeforDate,
} from 'shared/ui/UiDocumentFormPayBeforDate';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormPayBeforDate = () => {
  const value = documentFormModel.useValueDocumentFormModel('payBeforeDate');
  const onChangePayBeforDate: onChangeTypePayBeforDate = (value) =>
    documentFormModel.formEvent.setField({
      key: 'payBeforeDate',
      value: dayjs(value).format('YYYY-MM-DDTHH:mm:ss') || '',
    });

  return (
    <UiDocumentFormPayBeforDate
      value={value || null}
      onChange={onChangePayBeforDate}
    />
  );
};
