import Drawer from '@mui/material/Drawer/Drawer';
import { createContext, useContext, useEffect } from 'react';

import { Box, IconButton, SxProps, Theme, useMediaQuery } from '@mui/material';
import { theme } from 'shared/theme/theme';

import CloseIcon from '@mui/icons-material/Close';

import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED } from './constants';
import { useDrawerSetWidth } from './CustomDrawerLayout';
import { CustomDrawerComponent } from './types';
import { closedMixin, openedMixin } from './utils';

const DrawerContextProvider = createContext<boolean>(false);

export const useIsOpenDrawerContext = () => useContext(DrawerContextProvider);

const PAPER_SX: SxProps<Theme> = {
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRight: 'none',
  paddingBottom: 10,
};

export const CustomDrawer: CustomDrawerComponent = ({
  open,
  children,
  onClose,
}) => {
  const set = useDrawerSetWidth();

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (open) {
      set(DRAWER_WIDTH);
    } else {
      set(90);
    }
  }, [open]);

  return (
    <>
      {/* DESCTOP */}
      <Drawer
        variant={'permanent'}
        onClose={onClose}
        open={open}
        sx={{
          width: DRAWER_WIDTH_CLOSED,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          '*': { ...theme.mixins.scrollBar },
          ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': {
              ...openedMixin(theme),
              ...theme.mixins.stickyTopFromAppBar(0, 'fixed'),
            },
          }),
          ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': {
              ...closedMixin(theme),
              ...theme.mixins.stickyTopFromAppBar(0, 'fixed'),
            },
          }),
          display: { xs: 'none', sm: 'block' },
        }}
        PaperProps={{
          sx: PAPER_SX,
        }}
      >
        <DrawerContextProvider.Provider value={open}>
          {children}
        </DrawerContextProvider.Provider>
      </Drawer>

      {/* MOBILE */}
      {isDownSm && (
        <Drawer
          variant='temporary'
          open={open}
          onClose={onClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 'auto',
            },
          }}
          PaperProps={{ sx: PAPER_SX }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              sx={{
                color: 'white',
                ...theme.mixins.heightAppBar,
              }}
              onClick={(e) => {
                onClose?.(e, 'backdropClick');
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <DrawerContextProvider.Provider value={open}>
            {children}
          </DrawerContextProvider.Provider>
        </Drawer>
      )}
    </>
  );
};
