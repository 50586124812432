import { useCallback } from 'react';
import { GetTasksMeControllerWebQueryParams } from 'shared/api/services/types';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { ModalFilterWatchingTasks } from '../modal-filter-watching-tasks';
import { WatchTaskFormFields } from '../task-watch-filter-form';
import { useConvertWatchTaskFilter } from './useConvertWatchTaskFilter';

export const useModalFilterWatchingTasks = (
  filter: GetTasksMeControllerWebQueryParams | undefined,
  setFilter: (filter: GetTasksMeControllerWebQueryParams | undefined) => void
) => {
  const { close, initValue, isOpen, open } =
    useViewModalWithInitFields<WatchTaskFormFields>();

  const { convertFormToFilter, covertFilterToForm } =
    useConvertWatchTaskFilter();

  const openHandler = () => {
    open(covertFilterToForm(filter));
  };

  const onSubmit = useCallback((data: WatchTaskFormFields) => {
    setFilter({ Limit: 20, Page: 1, ...filter, ...convertFormToFilter(data) });
    close();
  }, []);

  return {
    modalFilterWatchingTasks: (
      <ModalFilterWatchingTasks
        isOpen={isOpen}
        onClose={close}
        defaultValue={initValue}
        onSubmit={onSubmit}
      />
    ),
    open: openHandler,
  };
};
