import dayjs from 'dayjs';

import { UniversalDate } from './type';

export const isEqDay = (a: UniversalDate, b: UniversalDate) => {
  const ad = dayjs(a);
  const bd = dayjs(b);

  // const isToday = ad.format('DD.MM.YYYY') === bd.format('DD.MM.YYYY');
  // return isToday;
  return ad.isSame(bd, 'date');
};
