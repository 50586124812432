import { E_ModuleGuids } from 'shared/moduleGuids';
import { ALL_ROUTES, Routes } from './types';

export const RETAIL_OPENING_PROCESS: Routes = {
  title: 'Процесс открытия аптек',
  path: ALL_ROUTES.retailOpeningProcess,
  isWork: true,
  children: [
    {
      title: 'Шаблоны',
      path: ALL_ROUTES.openingTemplates,
      isWork: true,
      description: null,
      icon: null,
      fullPath: '',
      children: null,
      premission: {
        permissionGuid: E_ModuleGuids.openingRetailsTemplate,
        fields: ['isReadable'],
      },
    },
    {
      title: 'Процессы',
      path: ALL_ROUTES.retailOpeningProcessProtocol,
      defaultQueryParams: '?Page=1&Limit=20&SearchText=&Statuses=2',
      isWork: true,
      description: null,
      icon: null,
      fullPath: '',
      children: null,
      premission: {
        permissionGuid: E_ModuleGuids.openingRetailsProtocols,
        fields: ['isReadable'],
      },
    },
  ],
  description: 'Процесс открытия аптек',
  icon: null,
  fullPath: '',
};
