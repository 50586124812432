import { FC } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTablePagination,
  UiTableRowWithActions,
} from 'shared/ui/table';

import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';

import { ActionsOpeningTemplate } from './ActionsOpeningTemplate';
import { OpeningTemplatesTableProps } from './types';

export const OpeningTemplatesTable: FC<OpeningTemplatesTableProps> = ({
  isLoading,
  isUpdate,
  limit,
  data,
  visibleFields,
  fields,
  onChangeFields,
  page,
  onChangePage,
  onChangeLimit,
  count,
}) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={limit} />}
      header={
        <UiTableHeader
          viewCells={visibleFields}
          allCells={fields}
          setViewCells={onChangeFields}
        />
      }
      body={data.map((template) => (
        <UiTableRowWithActions
          key={template.guid}
          renderActions={() => <ActionsOpeningTemplate template={template} />}
        >
          {visibleFields.map((el) => (
            <UiTableCell
              key={el.field + template.guid}
              {...el.getTableCellProps()}
            >
              {el.renderComponent(template) || 'Error'}
            </UiTableCell>
          ))}
        </UiTableRowWithActions>
      ))}
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage + 1);
          }}
          page={page - 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};
