import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useFormPreparedStagesTasksContext } from '../../hooks';
import { PreparedTask } from '../prepared-task';
import { PreparedStageProps } from './types';

export const PreparedStage: FC<PreparedStageProps> = ({
  indexStage,
  stageNumber,
}) => {
  const { control } = useFormPreparedStagesTasksContext();

  const { fields: tasksForStage } = useFieldArray({
    control,
    name: `stages.${indexStage}.tasks`,
  });

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={2}
    >
      <Typography
        fontWeight={'bold'}
        textAlign={'center'}
      >{`${stageNumber} Этап`}</Typography>
      {tasksForStage.map(({ id }, indexTask) => {
        return (
          <PreparedTask
            key={id}
            indexStage={indexStage}
            indexTask={indexTask}
          />
        );
      })}
    </Grid>
  );
};
