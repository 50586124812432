import { Paper, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { theme } from 'shared/theme/theme';

import { SectionMainPageRightProps } from './type';

export const SectionMainPageRight: FC<SectionMainPageRightProps> = ({
  children,
  title,
  color,
  mainProps,
}) => {
  const colorMemo = useMemo(() => {
    return color ? color : theme.palette.primary.main;
  }, [color]);

  return (
    <Paper
      sx={{
        boxShadow: 'none',
        overflow: 'hidden',
        //marginBottom: 2,
        ...mainProps,
      }}
    >
      <Typography
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          paddingLeft: [1, 0],
          backgroundColor: colorMemo,
          borderRadius: [0, '10px 10px 0px 0px'],
          borderColor: theme.palette.customGrey.dark,
          borderWidth: [0, 1],
          borderStyle: 'solid',
          borderBottom: 'none',
          fontSize: [16, 16],
          fontWeight: [400, 'bold'],
          color: color ? 'black' : '#fff',
          display: 'flex',
          justifyContent: ['center'],
          alignItems: 'center',
          height: ['37px'],
        }}
      >
        {title}
      </Typography>
      {children}
    </Paper>
  );
};
