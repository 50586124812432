// eslint-disable-next-line budapestian/global-constant-pattern
import {
  CardCollapsButton,
  CardCollapse,
  CardCollapsIconButton,
} from './card-collaps';
import { CardStack, CardStackItem } from './card-stack';
import { CardContainer } from './CardContainer';
import { CardFooter } from './CardFooter';
import { CardHeader } from './CardHeader';
import { useCardContext } from './context';

export const Card = {
  Container: CardContainer,
  Header: CardHeader,
  Collapse: CardCollapse,
  Footer: CardFooter,
  CollapsIconButton: CardCollapsIconButton,
  CollapsButton: CardCollapsButton,
  Stack: CardStack,
  StackItem: CardStackItem,
  useContext: useCardContext,
};
