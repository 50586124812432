import { object, ObjectSchema } from 'yup';

import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { FormOpeningTemplatesValue } from './types';

export const OENING_TEMPLATE_VALIDATE: ObjectSchema<FormOpeningTemplatesValue> =
  object().shape({
    title: SCHEMA.STRING_REQUIRED,
    isAutorun: SCHEMA.BOOLEAN,
    countdownField: object()
      .shape({
        title: SCHEMA.STRING_REQUIRED,
        guid: SCHEMA.STRING_REQUIRED,
      })
      .nonNullable()
      .required('countdownField - обязательное поле'),
    autorunTrigger: object()
      .shape({
        title: SCHEMA.STRING_REQUIRED,
        guid: SCHEMA.STRING_REQUIRED,
      })
      .when('isAutorun', ([isAutorun], schema) => {
        return schema.test(
          'autorunTrigger',
          'need applay autorunTrigger',
          (autorunTrigger) => {
            if (!isAutorun) return true;
            if (isAutorun) {
              return Boolean(autorunTrigger);
            }
          }
        );
      })
      .nullable(),
  });
