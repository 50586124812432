type GetObjByIdFun = <T extends { id: number }>(
  obj: T[] | undefined | null,
  id: number | undefined | null
) => T | null;

export const getObjById: GetObjByIdFun = (obj, id) => {
  if (!obj || id === null || id === undefined) return null;

  return obj.find((el) => el.id === id) || null;
};
