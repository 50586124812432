/**
 * @deprecated  duble src/shared/utils/files/arrayBufferToBase64.tsx
 *
 * use import { arrayBufferToBase64 } from 'shared/utils/files';
 *
 */
export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
};
