import type { OrganizationModel } from '../../types';

export const sortOrganizations = (
  organizations: OrganizationModel[]
): OrganizationModel[] => {
  return organizations.sort((a, b) => {
    let orderA = 0;
    let orderB = 0;
    if (a.isMainCurrentEmployment) {
      orderA = orderA + 10;
    }
    if (b.isMainCurrentEmployment) {
      orderB = orderB + 10;
    }
    if (a.title > b.title) {
      orderB++;
    } else if (a.title < b.title) {
      orderA++;
    }
    return orderB - orderA;
  });
};
