import {
  Grid,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';

import { theme } from '../../theme/theme';
import { useDevice } from '../../utils/hook-type-device';

export interface CustomTextFieldProps {
  propsTextField: TextFieldProps;
  label?: string;
  sx?: SxProps<Theme> | undefined;
}
/**
 * @deprecated use CustomTextField from shared/ui/base-ui/CustomTextField
 * */
export const CustomTextField: (props: CustomTextFieldProps) => JSX.Element = (
  props
) => {
  const [isMobile] = useDevice();

  return (
    <Grid sx={{ flexBasis: '100%', alignItems: 'center', ...props.sx }}>
      <Typography
        sx={{
          display: 'none',
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            backgroundColor: theme.palette.customGrey.main,
            position: 'relative',
            padding: '0px 8px',
            flexBasis: ['100%', '45%'],
          },
        }}
      >
        {props.label}
      </Typography>

      <TextField
        size='small'
        fullWidth
        margin={'normal'}
        {...props.propsTextField}
        label={isMobile ? '' : props.label}
        sx={{
          '& > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
            borderWidth: ['0px', '1px'],
          },
          my: 0,

          ...props.propsTextField.sx,
        }}
      />
    </Grid>
  );
};
