import { FormCloseDocumentValue } from './types';

export const DEFAULT_VALUE: FormCloseDocumentValue = {
  isLink: false,
  closeDocumentLink: '',
  addedFiles: [],
  filesFromBackend: [],
  filesDeleteFromBackend: [],
  closeDocumentNumber: '',
  closeDocumentDate: null,
  closeDocumentSum: '',
  closeDocumentTaxRate: null,
  isCloseDocumentSumEquals: false,
  equalsSumValue: {
    documentSum: '',
    documentTaxRate: null,
  },
};
