// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Grid, SxProps, Theme, ToggleButton } from '@mui/material';

import { STYLES_TOGGLE_BUTTON } from './style';
import { MyToggleButtonGroupPropsType } from './type';

export const MyToggleButtonGroup: MyToggleButtonGroupPropsType = ({
  getLable,
  onChange,
  value,
  containerProps,
  buttonProps,
  getSelected,
}) => {
  const buttonSxProps: SxProps<Theme> = buttonProps?.sx || {};

  return (
    <Grid
      gap={1}
      container
      flexWrap={'wrap'}
      justifyContent={'stretch'}
      alignItems={'stretch'}
      flexDirection={'row'}
      {...containerProps}
    >
      {value.map((val) => {
        const label = getLable(val);

        return (
          <ToggleButton
            {...buttonProps}
            // TODO: Не хочит спредить два объекта
            // @ts-ignore
            sx={{
              ...STYLES_TOGGLE_BUTTON,
              ...buttonSxProps,
            }}
            color='primary'
            size='small'
            key={label}
            value={label}
            selected={getSelected(val)}
            onChange={(_, newValue) => {
              const obj = value.find((v) => getLable(v) === newValue);

              if (obj) onChange(obj);
            }}
          >
            {label}
          </ToggleButton>
        );
      })}
    </Grid>
  );
};
