import { Divider, Stack } from '@mui/material';
import { FC } from 'react';
import { CardStackProps } from '../types';

export const CardStack: FC<CardStackProps> = ({ children, ...otherProps }) => {
  return (
    <Stack
      gap={1}
      px={2}
      divider={<Divider />}
      {...otherProps}
    >
      {children}
    </Stack>
  );
};
