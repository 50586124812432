import { Delete } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { useConfirmDeleteMarketingSurfaceImage } from 'features/marketing-surface/delete-image';
import { FC } from 'react';
import { DialogConfirmForm } from 'shared/ui/form';
import { ActionDeleteMarketingSurfaceImageProps } from './types';

export const ActionDeleteMarketingSurfaceImage: FC<
  ActionDeleteMarketingSurfaceImageProps
> = ({ hookParams }) => {
  const {
    isLoadingDeleteImage,
    isOpenDialogConfirmationDeleteSutfaceImage,
    onCancelDeleteSurfaceImage,
    onConfirmationDeleteSurfaceImage,
    onOpenDialogConfirmationDeleteSurfaceImage,
  } = useConfirmDeleteMarketingSurfaceImage(hookParams);

  return (
    <>
      <IconButton
        color='error'
        onClick={onOpenDialogConfirmationDeleteSurfaceImage}
      >
        <Delete />
      </IconButton>
      <DialogConfirmForm
        title='Удалить изображение'
        open={isOpenDialogConfirmationDeleteSutfaceImage}
        onClose={onCancelDeleteSurfaceImage}
        isLoading={isLoadingDeleteImage}
        onConfirmation={onConfirmationDeleteSurfaceImage}
      >
        <Typography>{`Вы уверены что хотите удалить ${hookParams.imageName}?`}</Typography>
      </DialogConfirmForm>
    </>
  );
};
