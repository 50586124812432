import { Grid, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';

import {
  OpeningTemplateMainInformationFieldProps,
  OpeningTemplateMainInformationProps,
} from './types';

export const OpeningTemplateMainInformation: FC<
  OpeningTemplateMainInformationProps
> = ({ openingTemplate, isLoading, title = 'Основаня информация' }) => {
  const {
    title: titleTemplate,
    isAutorun,
    autorunTrigger,
    countdownField,
  } = openingTemplate || {
    countdownField: {
      title: undefined,
    },
  };

  return (
    <Grid
      container
      flexDirection={'column'}
      gap={1}
    >
      <Typography
        variant='h6'
        fontWeight={'bold'}
      >
        {title}
      </Typography>
      <OpeningTemplateMainInformationField
        name='Название'
        value={
          isLoading ? (
            <Skeleton
              variant='rectangular'
              height={'1.3em'}
              width={100}
            />
          ) : (
            titleTemplate
          )
        }
      />

      <OpeningTemplateMainInformationField
        name='Отсчет дней от'
        value={
          isLoading ? (
            <Skeleton
              variant='rectangular'
              height={'1.3em'}
              width={200}
            />
          ) : (
            countdownField.title
          )
        }
      />
      <OpeningTemplateMainInformationField
        name='Автоматический запуск'
        value={
          isLoading ? (
            <Skeleton
              variant='rectangular'
              height={'1.3em'}
              width={200}
            />
          ) : isAutorun ? (
            'Да'
          ) : (
            'Нет'
          )
        }
      />
      <OpeningTemplateMainInformationField
        name='Триггер Автоматического запуска'
        value={
          isLoading ? (
            <Skeleton
              variant='rectangular'
              height={'1.3em'}
              width={200}
            />
          ) : (
            autorunTrigger?.title
          )
        }
      />
    </Grid>
  );
};

const OpeningTemplateMainInformationField: FC<
  OpeningTemplateMainInformationFieldProps
> = ({ name, value }) => {
  return (
    <Grid
      container
      gap={{ xs: 1, lg: 2 }}
    >
      <Typography fontWeight={'bold'}>{`${name} : `}</Typography>
      <Typography>{value || '-'}</Typography>
    </Grid>
  );
};
