import { Slide, useScrollTrigger } from '@mui/material';
import { FC } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';

interface HideOnScrollProps {
  window?: () => Window;
  children: React.ReactElement;
}

export const HideOnScroll: FC<HideOnScrollProps> = (props) => {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const [isMobile] = useDevice();

  const isIn = isMobile ? !trigger : true;

  return (
    <Slide
      appear={false}
      direction='down'
      in={isIn}
    >
      {children}
    </Slide>
  );
};
