import { getTaskLink } from 'entities/task/libs/getTaskLink';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getTasksTaskId } from 'shared/api/services/services';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';

// Перенаправляет на новую страницу задачи
export const RedirectFromIdToGuid = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getTasksTaskId(Number(id)).then((newTask) => {
      navigate(getTaskLink(newTask.data.guid));
    });
  }, []);

  return <CustomBackdrop isLoading />;
};
