import { ClickAwayListener, Grid } from '@mui/material';
import { FC } from 'react';
import { BoardColumnHeader } from '../BoardColumnHeader';
import { ColumnFormCard } from '../ColumnFormCard/ColumnFormCard';
import { useMutableBoardColumnHeader } from './hook';
import { MutableBoardColumnHeaderProps } from './type';

export const MutableBoardColumnHeader: FC<MutableBoardColumnHeaderProps> = ({
  column,
  actions = [],
  cardProps,
  isLoading = false,
  onUpdateSubmit,
  canEdit = true,
}) => {
  const { formState, formActions, formInitValue, extraCardActions } =
    useMutableBoardColumnHeader(column, actions, canEdit, isLoading);

  if (formState.isOpen && canEdit)
    return (
      <ClickAwayListener onClickAway={formState.close}>
        <Grid>
          <ColumnFormCard
            actions={formActions}
            onSubmit={(form) => {
              onUpdateSubmit(form).then(formState.close);
            }}
            initValue={formInitValue}
          />
        </Grid>
      </ClickAwayListener>
    );

  return (
    <BoardColumnHeader
      column={column}
      actions={extraCardActions}
      cardProps={{
        onDoubleClick: formState.open,
        ...cardProps,
      }}
    />
  );
};
