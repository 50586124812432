import dayjs from 'dayjs';
import { array, object, ObjectSchema } from 'yup';

import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';

import { CreateOtherEventFormFields } from '../type';

export const createOtherEventFormSchema: ObjectSchema<CreateOtherEventFormFields> =
  object().shape({
    title: SCHEMA.STRING_REQUIRED,
    dateStart: SCHEMA.STRING_REQUIRED,
    dateEnd: SCHEMA.STRING_REQUIRED.when('dateStart', ([dateStart], schema) => {
      return schema.test(
        'dateEnd',
        'Дата окончания не может быть раньше даты начала',
        (dateEnd) => {
          return (
            dayjs(dateEnd).isAfter(dayjs(dateStart)) ||
            dayjs(dateEnd).isSame(dayjs(dateStart))
          );
        }
      );
    }),
    directionId: SCHEMA.NUMBER.required(),
    homiesIds: array(SCHEMA.NUMBER.required()).min(0).required(),
    isVisitingBlocked: SCHEMA.BOOLEAN,
  });
