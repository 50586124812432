// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { AuthServiceResponsesOnlyPermission } from 'shared/api/services-auth/types';

import { Routes } from '../constants';

// Говорит есть ли доступ у вкладке по 3м поялм permission
export const getPermissionStatus = (
  route: Routes,
  permission: AuthServiceResponsesOnlyPermission
) => {
  return route.premission!.fields.reduce((prev, cure) => {
    return prev && permission[cure];
  }, true);
};
