// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { FormAgreeСhain } from 'features/document/form-agree-chain';
import { FC, useState } from 'react';

import {
  DocumentAgreeProcessActionResponse,
  EmployeeModel,
} from 'shared/api/services-document-flow/types';
import background from 'shared/img/document-flow/arrow.png';

interface UiDocumentFormAgreeChainProps {
  disabled?: boolean;
  initValue: DocumentAgreeProcessActionResponse[];
  isAgree: boolean;
  isManualAgree: boolean;
  isExtendedAgree: boolean;
  backendValue: DocumentAgreeProcessActionResponse[];
  setDocumentAgreeChain: (
    form: DocumentAgreeProcessActionResponse[]
  ) => any | void;
  options: EmployeeModel[] | null;
}
type UiDocumentFormAgreeChainComponent = FC<UiDocumentFormAgreeChainProps>;

export const UiDocumentFormAgreeChain: UiDocumentFormAgreeChainComponent = ({
  initValue,
  isAgree,
  isManualAgree,
  isExtendedAgree,
  backendValue,
  setDocumentAgreeChain,
  options,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Grid
      container
      justifyContent={'center'}
      marginTop={[2]}
    >
      <Grid
        container
        justifyContent={'center'}
      >
        {!isAgree && <Typography>Необходимо заполнить расходы</Typography>}
        {isAgree &&
          initValue?.map((item) => {
            return (
              <Box
                key={item.employeeId}
                sx={{
                  backgroundImage: `url(${background})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPositionX: '100%',
                  backgroundSize: '110px 70px',
                  minWidth: 180,
                  height: 70,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginBottom: 2,
                  marginRight: 1,
                }}
              >
                <Typography sx={{ marginRight: '43px' }}>
                  {item.employeeName}
                </Typography>
              </Box>
            );
          })}
      </Grid>
      <Button
        disabled={
          disabled ||
          ((isAgree && isExtendedAgree) || isManualAgree ? false : true)
        }
        variant='contained'
        color={'secondary'}
        size='large'
        sx={{ paddingLeft: [2], paddingRight: [2], marginTop: 2 }}
        onClick={() => setOpen(true)}
      >
        Редактировать
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid
          sx={{
            backgroundColor: 'white',
            width: ['100vw', '80vh'],
            maxHeight: ['100vh', '70vh'],
            overflowY: 'scroll',
          }}
        >
          <FormAgreeСhain
            backendValue={backendValue}
            initValue={initValue}
            options={options}
            setDocumentAgreeChain={setDocumentAgreeChain}
            onClose={() => {
              setOpen(false);
            }}
          />
        </Grid>
      </Modal>
    </Grid>
  );
};
