import { RetailOpeningProcessResponsesOpeningProcessGetListPreparedStageTask } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { FormPreparedStagesValue, PreparedStageTasksValue } from '../types';

export const preparedResponseToFormValue = (
  response: RetailOpeningProcessResponsesOpeningProcessGetListPreparedStageTask[]
): FormPreparedStagesValue => {
  return {
    stages: response
      .reduce<PreparedStageTasksValue[]>((accum, current) => {
        const indexSome = accum.findIndex(
          (stage) => stage.stageNumber === current.stageNumber
        );

        if (indexSome !== -1) {
          accum[indexSome].tasks.push(current);

          return accum;
        } else {
          accum.push({ stageNumber: current.stageNumber, tasks: [current] });

          return accum;
        }
      }, [])
      .sort((a, b) => a.stageNumber - b.stageNumber),
  };
};
