import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useCreateOpeningTemplatesStageMutation } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useCreateTemplateStageMutation = (
  templateGuid: string | null,
  stageNumber: number
) => {
  const { open: openSnackBar } = useSnackbar();
  /* CREATE STAGE */
  const [createTemplateStage, { isLoading: isLoadingCreateTemplateStage }] =
    useCreateOpeningTemplatesStageMutation();

  const hanldeCreateStage = useCallback(async () => {
    if (!templateGuid) return null;

    return await createTemplateStage({
      templateGuid,
      stage: {
        stageNumber,
      },
    }).then((response) => {
      if ('error' in response) {
        openSnackBar({
          text: getMessageFromFetchBaseQueryError(response.error),
          type: 'error',
          duration: 3000,
        });
      } else {
        openSnackBar({
          text: `Этап - ${stageNumber}, успешно добавлен`,
          type: 'success',
          duration: 3000,
        });
      }

      return response;
    });
  }, [stageNumber, templateGuid, openSnackBar]);

  return { isLoadingCreateTemplateStage, hanldeCreateStage };
};
