export type Title =
  | 'Наблюдаю'
  | 'Контролирую'
  | 'Делаю'
  | 'Главная'
  | 'Задачник'
  | 'Календарь'
  | 'Документооборот'
  | 'Посещение аптек'
  | 'Тех. поддержка'
  | 'Тех. поддержка (intraDesk)'
  | 'О компании'
  | 'Адреса аптек и офисов'
  | 'Уведомления'
  | 'Шаблон задач'
  | 'Мои идеи'
  | ''
  | 'Личный кабинет'
  | 'Создать документ'
  | 'Служебная записка на оплату счёта'
  | 'Служебная записка на выдачу денежных средств в подотчёт'
  | 'Служебная записка на оплату командировки'
  | 'Новое посещение'
  | 'Мои посещения'
  | 'Черновики'
  | 'Этап 6'
  | 'Этап 5'
  | 'Этап 4'
  | 'Этап 3'
  | 'Этап 2'
  | 'Этап 1'
  | 'Планирование'
  | 'Планирование РН'
  | 'Доски'
  /* training */
  | 'Тренинги'
  | 'Текущие'
  | 'Завершенные'
  | 'Мои тренинги'
  | 'Контролирую'
  /* reatils-opening-process */
  | 'Процесс открытия аптек'
  | 'Шаблоны'
  | 'Шаблон'
  | 'Процессы'
  /* Marketing surface */
  | 'Рекламные поверхности'
  | 'Мои доски'
  /* apteka legko */
  | 'Аптека Легко'
  | 'Баннеры'
  | 'Колл-центр'
  | 'Статьи'
  | 'Push-уведомления'
  | 'Отправить'
  | 'Адаптация'
  | 'Баннеры'
  | 'Слайдер'
  | 'Добавить'
  | 'Список'
  | 'Редактировать'
  | 'Бренды'
  /* Adaptation */
  | 'Текущие процессы'
  | 'Завершённые процессы'
  | 'Уволенные'
  | 'Ожидают адаптации'
  | 'Адаптация не требуется';

export enum ALL_ROUTES {
  //
  monitoringTasks = 'monitoring-tasks',
  chargeTasks = 'charge-tasks',
  myTasks = 'my-tasks',
  main = '',
  tasks = 'tasks',
  tasksId = 'tasks/:id',
  tasksGuid = 'task/:guid',
  //
  calendar = 'calendar',
  //
  documentFlow = 'document-flow',
  documentFlowId = 'document-flow/:id',
  documentFlowIdEdit = 'document-flow/:id/edit',
  //
  visitingPharmacies = 'visiting-pharmacies',
  //
  support = 'https://gz.intradesk.ru',
  about = 'about',
  pharmacyAddresses = 'pharmacy-addresses',
  createdByMe = 'created-by-me',
  notification = 'notification',
  templateTasks = 'template-tasks',
  myIdeas = 'my-ideas',
  user = 'user',
  action = 'action',
  observe = 'observe',
  controls = 'controls',
  createdDocument = 'created-document',
  invocePayment = 'invoce-payment',
  invocePayment2 = 'invoce-payment2',
  invocePayment3 = 'invoce-payment3',
  new = 'new',
  my = 'my',
  whatch = 'whatch',
  stage1 = 'stage1/:id',
  stage2 = 'stage2/:id',
  stage3 = 'stage3/:id',
  stage4 = 'stage4/:id',
  stage5 = 'stage5/:id',
  stage6 = 'stage6/:id',
  counterparties = 'counterparties',
  createdDocumentId = 'created-document/:id',
  documentFlowIdComments = 'document-flow/:id/:comments',
  authorization = 'authorization',
  complite = 'complite',
  planning = 'planning',
  createPlanning = 'create-planning',
  // newVisit = 'new-visit'
  visit = 'visit/:id',
  visitInfo = 'visit-info/:id',
  planingRN = 'planing-rn',
  // Trainings
  training = 'training',
  currentTraining = 'current-training',
  compliteTraining = 'complite-training',
  myTraining = 'my-training',
  controlTraining = 'control-training',
  /* retail-opening-process */
  retailOpeningProcess = 'retail-opening-process',
  openingTemplates = 'opening-templates',
  openingTemplate = 'opening-templates/:guid',
  openingTemplateRunProcess = 'opening-templates/:guid/run-process',
  retailOpeningProcessProtocol = 'process',
  retailOpeningProcessProtocolGuid = 'process/:guid',

  /* marketing surface */
  marketingSurface = 'marketing-surface',
  //
  myBoards = 'my-boards',
  boards = 'boards',
  boardGuid = 'board/:guid',

  /* apteka legko */
  aptekaLegko = 'apteka-legko',
  aptekaLegkoPush = 'push',
  aptekaLegkoPushList = 'list',
  aptekaLegkoPushCreated = 'created',
  aptekaLegkoPushByGuid = ':guid',
  aptekaLegkoPushEdit = ':guid/edit',
  aptekaLegkoBanners = 'banners',
  /* SLIDER */
  aptekaLegkoBannersSliders = 'sliders',
  aptekaLegkoBannersSlidersList = 'slider-list',
  aptekaLegkoBannersSlidersCreated = 'slider-created',
  aptekaLegkoBannersSlidersEdit = ':guid/edit',
  /* Brands */
  aptekaLegkoBannersBrand = 'brands',
  aptekaLegkoCallCenter = 'https://call-center.garzdrav.ru/',
  aptekaLegkoArticles = 'https://blog.aptekalegko.ru/list',

  /* adaptation */
  adaptation = 'adaptation',
  adaptationTemplates = 'adaptation-templates',
  adaptationTemplatesRunProcess = 'adaptation-templates/run-process',
  adaptationTemplatesList = 'list',
  adaptationTemplatesStages = 'stages/:templateGuid',
  currentAdaptationProcesses = 'current-adaptation-processes',
  completedAdaptationProcesses = 'completed-adaptation-processes',
  firedAdaptationProcesses = 'fired-adaptation-processes',
  waitingAdaptationProcesses = 'waiting-adaptation-processes',
  notAdaptationRequired = 'no-adaptation-required',
}

export type Path = ALL_ROUTES;

export type Routes = {
  title: Title;
  path: Path;
  isWork: boolean;
  children: Routes[] | null;
  description: null | string;
  icon: string | null;
  fullPath: string;
  premission?: {
    permissionGuid: string;
    fields: ('isDeletable' | 'isWritable' | 'isReadable')[];
  };
  target?: '_blank';
  defaultQueryParams?: string;
  isOtherResource?: boolean;
};
