import { createStore, sample } from 'effector';

import { TaxRateCalculatedResponse } from 'shared/api/services-document-flow/types';

import { documentFormModel } from 'entities/document-form';

import { fetchTaxRatesOptionsFx } from './api';

export const $taxRateOptions = createStore<TaxRateCalculatedResponse[]>([]).on(
  fetchTaxRatesOptionsFx.doneData,
  (_, response) => response.data
);

export const $taxRateId = documentFormModel.form.map(
  (document) => (document && document.taxRateId) || null
);

const totalSum = documentFormModel.secondValid.map(
  (document) => document && document.totalSum
);

sample({
  clock: totalSum,
  filter: (totalSum) => Boolean(totalSum),
  fn: (totalSum) => ({ Sum: totalSum as number }),
  target: fetchTaxRatesOptionsFx,
});
