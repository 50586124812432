// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from '@mui/material';
import { Event } from 'effector';
import { FC, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';

import { LayoutPage } from 'shared/ui/layouts/layout-page';

import { UiSearchBar } from 'shared/ui/ui-search-bar';

interface LayoutPageDocumentsListWithFilterBarProps {
  children: ReactNode;
  inputSearch: string;
  /** depricate */
  changeInputSearch?: Event<string>;
  onCompliteChangeInput?: Event<any>;
  onOpenModalFilter?: () => void;
  modals?: ReactNode;
  /* ========== */

  onChangeInputSearch?: (value: string) => void;
  onCompliteInput?: ((value: string) => void) | undefined;
  FilterAction?: ReactNode;
}

export const LayoutPageDocumentsListWithFilterBar: FC<
  LayoutPageDocumentsListWithFilterBarProps
> = ({
  children,
  inputSearch,
  changeInputSearch,
  onCompliteChangeInput,
  onOpenModalFilter,
  modals,
  FilterAction,
  onChangeInputSearch,
  onCompliteInput,
}) => {
  const onClear = useCallback(() => {
    /* OLD */
    changeInputSearch?.('');
    onCompliteChangeInput?.();
    /* NEW */
    onChangeInputSearch?.('');
    onCompliteInput?.('');
  }, [onCompliteInput, onChangeInputSearch, onCompliteChangeInput]);

  const navigate = useNavigate();

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <UiSearchBar
          onCompliteInput={onCompliteInput || onCompliteChangeInput}
          textValue={inputSearch}
          onClear={onClear}
          changeValue={onChangeInputSearch || changeInputSearch || console.log}
        >
          <Button
            variant='contained'
            color='customBlue'
            onClick={() => navigate('/document-flow/created-document')}
          >
            Создать документ
          </Button>
          {!FilterAction && <FilterButton onClick={onOpenModalFilter} />}
          {FilterAction}
        </UiSearchBar>

        {modals}
      </LayoutPage.Bar>
      <LayoutPage.Content>{children}</LayoutPage.Content>
    </LayoutPage.Page>
  );
};
