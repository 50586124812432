import {
  ControllFilterItemModel,
  GetTasksMeControllerWebQueryParams,
} from 'shared/api/services/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { getDayjs } from 'shared/tarnsform-helper/getDayjs';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { getObjByIds } from 'shared/tarnsform-helper/getObjByIds';
import { concatArray } from 'shared/utils/concatArray';

import { generateFilterDeps } from 'entities/task/libs/generateFilterDeps';

import {
  useGetControllFiltersQuery,
  useGetOriginsQuery,
  useGetStatusesQuery,
} from 'shared/api/services/rtk/optionApi';
import { getGuidsFromArray } from 'shared/tarnsform-helper/getGuidsFromArray';
import { getObjByGuids } from 'shared/tarnsform-helper/getObjByGuids';
import { TARGET_OPTIONS } from '../task-filter-form/constants';
import { TASK_WATCHING_FILTER_DEFAULT_VALUE } from '../task-watch-filter-form/constants';
import { WatchTaskFormFields } from '../task-watch-filter-form/type';

enum FilterItemType {
  'parentOrgDirections',
  'parentOrgDepartments',
  'parentOrgOffices',
  'parentOrgPositions',
  'parentRegions',
  'parentDepartments',
  'parentDivisions',
  'parentRetails',
}

const getIdsByType = (
  filter: ControllFilterItemModel[] | null,
  key: FilterItemType
) => {
  if (filter === null) return undefined;

  return getGuidsFromArray(
    filter.filter((el) => String(el.type) === String(key))
  );
};

export const useConvertWatchTaskFilter = () => {
  const { data: controlFilter, isLoading: isLoadingControllFilter } =
    useGetControllFiltersQuery();
  const { data: origins, isLoading: isLoadingOrigins } = useGetOriginsQuery();
  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetStatusesQuery();

  const covertFilterToForm = (
    filter: GetTasksMeControllerWebQueryParams | undefined
  ): WatchTaskFormFields => {
    if (filter === undefined || Object.keys(filter).length === 0)
      return getDefaulteForm();

    return {
      dateStart: getDayjs(filter.StartDate),
      dateFinish: getDayjs(filter.FinishDate),
      departamentsAndDepts:
        getObjByGuids(
          generateFilterDeps(controlFilter?.departmentsAndDepts),
          concatArray(filter.DepartmentGuids, filter.OrgDepartmentGuids)
        ) || null,
      directionsAndRegions:
        getObjByGuids(
          generateFilterDeps(controlFilter?.directionsAndRegions),
          concatArray(filter.OrgDirectionGuids, filter.RegionGuids)
        ) || null,
      employees:
        getObjByGuids(
          generateFilterDeps(controlFilter?.employees),
          filter.EmployeeGuids
        ) || null,
      officesAndDivisions:
        getObjByGuids(
          generateFilterDeps(controlFilter?.officesAndDivisions),
          concatArray(filter.OrgOfficeGuids, filter.DivisionGuids)
        ) || null,
      positionsAndRetails:
        getObjByGuids(
          generateFilterDeps(controlFilter?.positionsAndRetails),
          concatArray(filter.OrgPositionGuids, filter.RetailGuids)
        ) || null,
      isTarget:
        filter.IsTarget === undefined
          ? null
          : TARGET_OPTIONS.find((el) => el.value === filter.IsTarget) || null,
      origin: getObjByIds(origins, filter.OriginsIds) || [],
      statuses: (statuses || []).map((status) => ({
        ...status,
        selected:
          filter.StatusIds?.some((statusId) => statusId === status.id) || false,
      })),
    };
  };

  const convertFormToFilter = (
    form: WatchTaskFormFields
  ): Omit<GetTasksMeControllerWebQueryParams, 'Page' | 'Limit'> => {
    return {
      DepartmentGuids: getIdsByType(
        form.departamentsAndDepts,
        FilterItemType.parentDepartments
      ),
      DivisionGuids: getIdsByType(
        form.officesAndDivisions,
        FilterItemType.parentDivisions
      ),
      EmployeeGuids: getGuidsFromArray(form.employees),
      RetailGuids: getIdsByType(
        form.positionsAndRetails,
        FilterItemType.parentRetails
      ),
      RegionGuids: getIdsByType(
        form.directionsAndRegions,
        FilterItemType.parentRegions
      ),
      OrgDepartmentGuids: getIdsByType(
        form.departamentsAndDepts,
        FilterItemType.parentOrgDepartments
      ),
      OrgDirectionGuids: getIdsByType(
        form.directionsAndRegions,
        FilterItemType.parentOrgDirections
      ),
      OrgOfficeGuids: getIdsByType(
        form.officesAndDivisions,
        FilterItemType.parentOrgOffices
      ),
      OrgPositionGuids: getIdsByType(
        form.positionsAndRetails,
        FilterItemType.parentOrgPositions
      ),
      FinishDate: form.dateFinish?.format(DATE_FORMATS.server),
      IsTarget: form.isTarget === null ? undefined : form.isTarget.value,
      OriginsIds: getIdsFromArray(form.origin),
      StartDate: form.dateStart?.format(DATE_FORMATS.server),
      StatusIds: getIdsFromArray(form.statuses?.filter((el) => el.selected)),
    };
  };

  const getDefaulteForm = (): WatchTaskFormFields => {
    return {
      ...TASK_WATCHING_FILTER_DEFAULT_VALUE,
      statuses: (statuses || []).map((el) => ({ ...el, selected: false })),
    };
  };

  return {
    getDefaulteForm,
    convertFormToFilter,
    covertFilterToForm,
    isLoading: isLoadingControllFilter || isLoadingOrigins || isLoadingStatuses,
  };
};
