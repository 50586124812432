import { RetailGetListResponseNew } from 'features/visit/Stage 1/type';
import { useEffect, useState } from 'react';

import { getRetails } from 'shared/api/visit/services';
import { GetRetailsQueryParams } from 'shared/api/visit/types';

export const useGetRetails = (filter?: GetRetailsQueryParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [retails, setRetails] = useState<RetailGetListResponseNew[]>([]);

  const getMyRetails = async () => {
    setIsLoading(true);

    const { data, status } = await getRetails(filter);

    if (status === 200 && data)
      setRetails(data as unknown as RetailGetListResponseNew[]);
    setIsLoading(false);
  };

  useEffect(() => {
    getMyRetails();
  }, [filter]);

  return { retails, isLoading };
};
