import { mixed, ObjectSchema } from 'yup';
import { SCHEME_BASE_FILTER_FORM } from '../scheme';
import { DocumentFlowFilterObserveForm } from './type';

export const SCHEME_OBSERVE_FILTER_FORM: ObjectSchema<DocumentFlowFilterObserveForm> =
  SCHEME_BASE_FILTER_FORM.shape({
    departmentsAndDepts:
      mixed<DocumentFlowFilterObserveForm['departmentsAndDepts']>().required(),
    offices: mixed<DocumentFlowFilterObserveForm['offices']>().required(),
    employees: mixed<DocumentFlowFilterObserveForm['employees']>().required(),
    directionsAndRegions:
      mixed<DocumentFlowFilterObserveForm['directionsAndRegions']>().required(),
    positions: mixed<DocumentFlowFilterObserveForm['positions']>().required(),
  });
