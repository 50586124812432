import { Typography } from '@mui/material';
import { DOCUMENT_LINKS } from 'entities/document/libs/getDocLink';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { Link } from 'shared/ui/base-ui';

export const DocumentTitleUi: FC<{ title?: string; id: number }> = ({
  id,
  title,
}) => {
  return (
    <Link
      to={DOCUMENT_LINKS.createDocLink(id)}
      sx={{ textDecoration: 'none' }}
    >
      <Typography
        sx={{
          minWidth: ['auto', '200px'],
          maxWidth: ['auto', '350px'],
          flexGrow: 1,
          '&:hover': { cursor: 'pointer', color: theme.palette.primary.main },
        }}
      >
        {title}
      </Typography>
    </Link>
  );
};
