import {
  GetBoardsQueryParams,
  GetBoardsTasksBoardGuidQueryParams,
  GetTasksMeControllerWebQueryParams,
  GetTasksMeExecutorQueryParams,
} from 'shared/api/services/types';

import {
  AdaptationProcessesCurrentListParams,
  AdaptationsListParams,
} from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { ConvertMap, ConvertMapWithType, ConvertTypes } from './type';

export const CONVERT_WATCH_TASK_LIST_FILTER_MAP: ConvertMapWithType<GetTasksMeControllerWebQueryParams> =
  [
    { key: 'DepartmentGuids', type: ConvertTypes.masString },
    { key: 'DivisionGuids', type: ConvertTypes.masString },
    { key: 'EmployeeGuids', type: ConvertTypes.masString },
    { key: 'FinishDate', type: ConvertTypes.string },
    { key: 'IsTarget', type: ConvertTypes.boolean },
    { key: 'OrderBy', type: ConvertTypes.string },
    { key: 'OrgDepartmentGuids', type: ConvertTypes.masString },
    { key: 'OrgDirectionGuids', type: ConvertTypes.masString },
    { key: 'OrgOfficeGuids', type: ConvertTypes.masString },
    { key: 'OrgPositionGuids', type: ConvertTypes.masString },
    { key: 'OriginsIds', type: ConvertTypes.masNumber },
    { key: 'Page', type: ConvertTypes.number },
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'RegionGuids', type: ConvertTypes.masString },
    { key: 'RetailGuids', type: ConvertTypes.masString },
    { key: 'SearchText', type: ConvertTypes.string },
    { key: 'StartDate', type: ConvertTypes.string },
    { key: 'StatusIds', type: ConvertTypes.masNumber },
  ];

export const CONVERT_TASK_LIST_FILTER_MAP: ConvertMapWithType<GetTasksMeExecutorQueryParams> =
  [
    { key: 'CoExecutorsGuids', type: ConvertTypes.masString },
    { key: 'CreatorsGuids', type: ConvertTypes.masString },
    { key: 'ExecutorsGuids', type: ConvertTypes.masString },
    { key: 'FinishDate', type: ConvertTypes.string },
    { key: 'IsTarget', type: ConvertTypes.boolean },
    { key: 'OrderBy', type: ConvertTypes.string },
    { key: 'OriginsIds', type: ConvertTypes.masNumber },
    { key: 'Page', type: ConvertTypes.number },
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'SearchText', type: ConvertTypes.string },
    { key: 'StartDate', type: ConvertTypes.string },
    { key: 'StatusIds', type: ConvertTypes.masNumber },
  ];

export const CONVERT_VISIT_FILTER_MAP: ConvertMap = [
  { key: 'BeginDate', type: ConvertTypes.string },
  { key: 'EndDate', type: ConvertTypes.string },
  { key: 'SearchText', type: ConvertTypes.string },
  { key: 'ChecklistStatuses', type: ConvertTypes.masNumber },
  { key: 'VisitingDepartmentsIds', type: ConvertTypes.masNumber },
  { key: 'VisitingDivisionsIds', type: ConvertTypes.masNumber },
  { key: 'VisitingRegionsIds', type: ConvertTypes.masNumber },
  { key: 'VisitingRetailsIds', type: ConvertTypes.masNumber },
  { key: 'VisitorDepartmentsIds', type: ConvertTypes.masNumber },
  { key: 'VisitorIds', type: ConvertTypes.masNumber },
  { key: 'VisitorOfficesIds', type: ConvertTypes.masNumber },
  { key: 'VisitorRegionsIds', type: ConvertTypes.masNumber },
  { key: 'Page', type: ConvertTypes.number },
  { key: 'Limit', type: ConvertTypes.number },
  { key: 'VisitorPositionsIds', type: ConvertTypes.masNumber },
];

export const CONVERT_BOARD_LIST_MAP: ConvertMapWithType<GetBoardsQueryParams> =
  [
    { key: 'IsArchive', type: ConvertTypes.boolean },
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'Page', type: ConvertTypes.number },
    { key: 'SearchText', type: ConvertTypes.string },
  ];

export const CONVERT_BOARD_TASK_LIST_MAP: ConvertMapWithType<GetBoardsTasksBoardGuidQueryParams> =
  [
    { key: 'CoExecutorsGuids', type: ConvertTypes.masString },
    { key: 'CreatorsGuids', type: ConvertTypes.masString },
    { key: 'ExecutorsGuids', type: ConvertTypes.masString },
    { key: 'FinishDate', type: ConvertTypes.string },
    { key: 'IsTarget', type: ConvertTypes.boolean },
    { key: 'OriginsIds', type: ConvertTypes.masNumber },
    { key: 'SearchText', type: ConvertTypes.string },
    { key: 'StartDate', type: ConvertTypes.string },
    { key: 'StatusIds', type: ConvertTypes.masNumber },
  ];

export const CONVERT_ADAPTATION_TEMPLATE_LIST_MAP: ConvertMapWithType<AdaptationsListParams> =
  [
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'Page', type: ConvertTypes.number },
    { key: 'SearchText', type: ConvertTypes.string },
  ];

export const CONVERT_ADAPTATION_CURRENT_PRECESSES_LIST_MAP: ConvertMapWithType<AdaptationProcessesCurrentListParams> =
  [
    { key: 'AdaptationsGuids', type: ConvertTypes.masString },
    { key: 'DepartmentsGuids', type: ConvertTypes.masString },
    { key: 'EmployeesGuids', type: ConvertTypes.masString },
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'OrderBy', type: ConvertTypes.string },
    { key: 'Page', type: ConvertTypes.number },
    { key: 'RegionsGuids', type: ConvertTypes.masString },
    { key: 'RetailsGuids', type: ConvertTypes.masString },
    { key: 'SearchText', type: ConvertTypes.string },
  ];
