import { createContext, FC, PropsWithChildren, useContext } from 'react';

import { FormFetchOptionsContextValue } from './types';

const FormFetchOptionsContext = createContext<FormFetchOptionsContextValue>(
  {} as FormFetchOptionsContextValue
);

export const useFormFetchOptionsContext = () =>
  useContext(FormFetchOptionsContext);

export const FormFetchOptionsContextProvider: FC<
  PropsWithChildren<FormFetchOptionsContextValue>
> = ({ children, ...otherProps }) => {
  return (
    <FormFetchOptionsContext.Provider value={otherProps}>
      {children}
    </FormFetchOptionsContext.Provider>
  );
};
