import { Button } from '@mui/material';
import { useStore, useStoreMap } from 'effector-react';
import { postDocumentsDocumentIdDoActionFx } from 'features/document/document-actions/api/document-actions';
import { ConfirmModal } from 'features/visit/ConfirmModal/ConfirmModal';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteDocumentIdFx } from 'entities/document/api/document-id-effector';

import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import {
  DialogFormCloseDocument,
  useDialogFormCloseDocument,
} from './form-close-document';

import {
  DialogFormAgreeOrganization,
  useDialogFormAgreeOrganization,
} from './form-agree-organization';
import { documentActionReturnDcumentCommentModal } from './models/document-actions-return-document';
import { documentIdActions } from './models/document-id-actions';
import { orderManagerAgreeRequestModal } from './models/order-manager-agree-request';

export const DocumentIdActionsBottom: FC<{
  documentId: number;
}> = ({ documentId }) => {
  documentIdActions.useInit(documentId);

  const canDelete = useStoreMap(documentIdActions.actions, (store) => {
    if (!store) return false;

    return store.canDelete;
  });
  const canReject = useStoreMap(documentIdActions.actions, (store) => {
    if (!store) return false;

    return Boolean(store.documentActions?.find((item) => item.id === 8));
  });

  const nextAction = useStoreMap(documentIdActions.actions, (store) => {
    if (!store) return undefined;

    return store.documentActions?.find(
      ({ id }) =>
        id === 1 || id === 2 || id === 3 || id === 5 || id === 6 || id === 7
    );
  });
  const canEdit = useStoreMap(documentIdActions.actions, (store) => {
    if (!store) return false;

    return store.canEdit;
  });
  const navigate = useNavigate();

  const loadingDelDoc = useStore(deleteDocumentIdFx.pending);

  const [isOpen, setIsOpen] = useState(false);
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      canDelete && setIsOpen(true);
    },
    [canDelete]
  );
  const handleConfirmDelDocument = useCallback(async () => {
    if (loadingDelDoc) return null;
    await deleteDocumentIdFx(documentId);
    setIsOpen(false);
  }, [documentId, loadingDelDoc]);

  const loadingNextAction = useStore(postDocumentsDocumentIdDoActionFx.pending);
  /* action agree organization */
  const {
    isLoadingInitialValueAgreeOrganizationForm,
    isLoadingSubmitAgreeOrganizationForm,
    isOpenDialogFormAgreeOrganization,
    onCloseDialogFormAgreeOrganization,
    onOpenDialogFormAgreeOrganization,
    onSubmitAgreeOrganizationForm,
    initialValueAgreeOrganizationForm,
  } = useDialogFormAgreeOrganization(documentId);
  /* action close document */

  const {
    isOpenDialogCloseDocument,
    onOpenDialogCloseDocument,
    onCloseDialogCloseDocument,
    initialValueFormCloseDocument,
    loadingInitialValue,
    onSubmitCloseDocumentForm,
    isLoadingPostDocumentDoAction,
    isLodingDeleteCloseDocumentFile,
    isLodingPostCloseDocumentFile,
  } = useDialogFormCloseDocument({ documentId: documentId });

  if (!nextAction && !canDelete && !canReject && !canEdit) return null;

  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        text={'Удалить документ?'}
        onConfirm={handleConfirmDelDocument}
      />
      <ContainerActionsForm>
        <Button
          variant='contained'
          color={nextAction ? 'secondary' : 'customBlue'}
          size='large'
          disabled={(!nextAction && !canEdit) || loadingNextAction}
          onClick={(e) => {
            e.stopPropagation();
            if (loadingNextAction) return null;
            if (nextAction) {
              if (nextAction.id === 3) {
                orderManagerAgreeRequestModal.events.onOpen({
                  documentId,
                  actionId: nextAction.id,
                });
              } else if (nextAction.id === 5) {
                onOpenDialogCloseDocument();
              } else if (nextAction.id === 7) {
                onOpenDialogFormAgreeOrganization();
              } else if (nextAction.id === 8) {
                documentActionReturnDcumentCommentModal.events.open(documentId);
              } else {
                const requestBody = {
                  actionTaskId: nextAction.id,
                };

                postDocumentsDocumentIdDoActionFx({
                  documentId,
                  requestBody,
                });
              }
            } else if (canEdit) {
              navigate(`/document-flow/${documentId}/edit`);
            }
          }}
        >
          {(nextAction && nextAction.name) || 'Внести изменения'}
        </Button>

        <Button
          color='error'
          disabled={(!canReject && !canDelete) || loadingDelDoc}
          variant='contained'
          size='large'
          onClick={(e) => {
            e.stopPropagation();
            if (loadingDelDoc) return null;
            if (canDelete) {
              handleClick(e);
            } else if (canReject) {
              documentActionReturnDcumentCommentModal.events.open(documentId);
            }
          }}
        >
          {(canDelete && 'Удалить') || (canReject && 'Отклонить') || 'Удалить'}
        </Button>
      </ContainerActionsForm>
      <DialogFormAgreeOrganization
        isOpen={isOpenDialogFormAgreeOrganization}
        initialValue={initialValueAgreeOrganizationForm}
        loading={
          isLoadingSubmitAgreeOrganizationForm ||
          isLoadingInitialValueAgreeOrganizationForm
        }
        onClose={onCloseDialogFormAgreeOrganization}
        documentId={documentId}
        onSubmit={onSubmitAgreeOrganizationForm}
      />
      <DialogFormCloseDocument
        initialValue={initialValueFormCloseDocument}
        isOpen={isOpenDialogCloseDocument}
        onClose={onCloseDialogCloseDocument}
        onSubmit={onSubmitCloseDocumentForm}
        isLoading={
          loadingInitialValue ||
          isLoadingPostDocumentDoAction ||
          isLodingDeleteCloseDocumentFile ||
          isLodingPostCloseDocumentFile
        }
      />
    </>
  );
};
