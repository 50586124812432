import { Typography } from '@mui/material';
import { FC, memo } from 'react';

import { VisitingGetListResponse } from 'shared/api/visit/types';
import { theme } from 'shared/theme/theme';
import {
  UiTableCard,
  UiTableCardList,
  useTableCellsFromLocalStorage,
} from 'shared/ui/table';

import { Tables } from 'shared/utils/controllers';
import { VisitActionButtons } from './actions';
import { ObserverVisitsListProps } from './type';

export const ObserverVisitsList: FC<ObserverVisitsListProps> = memo(
  ({ visits, onOpen, onDelete, onCreateChecklist }) => {
    const [fields, setFields, visibleFields] =
      useTableCellsFromLocalStorage<VisitingGetListResponse>(
        Tables.OBSERVE_VISIT
      );

    return (
      <UiTableCardList
        fields={fields}
        onChangeFields={setFields}
        containerProps={{
          height: '100%',
          //alignItems: 'flex-end',
          flexWrap: 'nowrap',
        }}
        stackContainerProps={{
          padding: 1,
          height: '100%',
          sx: {
            overflowX: 'auto',
          },
        }}
      >
        {visits.length ? (
          visits.map((visit) => (
            <UiTableCard
              visibleFields={visibleFields}
              id={String(visit.id)}
              data={visit}
              renderHeaderChildren={() => (
                <VisitActionButtons
                  visit={visit}
                  onOpen={onOpen}
                  onCreateChecklist={onCreateChecklist}
                  onDelete={onDelete}
                />
              )}
              key={visit.id}
              paperProps={{
                sx: visit.isCurrentEmployee
                  ? {
                      position: 'relative',
                      border: '2px solid black',
                      borderColor: theme.palette.primary.main,
                    }
                  : undefined,
              }}
            />
          ))
        ) : (
          <Typography
            fontWeight={700}
            fontSize={20}
          >
            В выбранном дне нет посещений
          </Typography>
        )}
      </UiTableCardList>
    );
  }
);
