import {
  RetailOpeningProcessRequestsOpeningProcessPreparedStageTask,
  RetailOpeningProcessResponsesOpeningProcessGetListPreparedStageTask,
} from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';

import { filterUndefinedAndNullItemFromArray } from 'shared/utils/filterUndefinedAndNullItemFromArray';
import { PreparedStageTasksValue } from '../types';

export const preparedFormValueStagesToSubmitStageTasks = (
  stages: PreparedStageTasksValue[]
): RetailOpeningProcessRequestsOpeningProcessPreparedStageTask[] => {
  return stages.reduce<
    RetailOpeningProcessRequestsOpeningProcessPreparedStageTask[]
  >((accum, current) => {
    return [
      ...accum,
      ...filterUndefinedAndNullItemFromArray(
        current.tasks.map(mapFromValueTaskToSubminTask)
      ),
    ];
  }, []);
};

const mapFromValueTaskToSubminTask = (
  task: RetailOpeningProcessResponsesOpeningProcessGetListPreparedStageTask
): RetailOpeningProcessRequestsOpeningProcessPreparedStageTask | undefined => {
  const { stageTaskGuid, creator, coExecutors, observers, executor } = task;

  if (!creator.employee || !executor.employee) return undefined;

  return {
    stageTaskGuid,

    creatorGuid: creator.employee.guid,

    executorGuid: executor.employee.guid,

    coExecutorsGuids: filterUndefinedAndNullItemFromArray(
      coExecutors.map((observ) => observ.employee?.guid)
    ),

    observersGuids: filterUndefinedAndNullItemFromArray(
      observers.map((observ) => observ.employee?.guid)
    ),
  };
};
