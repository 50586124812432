import trainingPng from 'shared/img/site-guide/training.png';
import { E_ModuleGuids } from 'shared/moduleGuids';
import { ALL_ROUTES, Routes } from './types';

export const ADAPTATION: Routes = {
  children: [
    {
      title: 'Шаблоны',
      description: '',
      fullPath: '',
      icon: null,
      isWork: true,
      path: ALL_ROUTES.adaptationTemplates,
      children: null,
      premission: {
        permissionGuid: E_ModuleGuids.adaptationTemplates,
        fields: ['isReadable'],
      },
    },

    {
      title: 'Текущие процессы',
      description: '',
      fullPath: '',
      icon: null,
      isWork: true,
      path: ALL_ROUTES.currentAdaptationProcesses,
      children: null,
      premission: {
        permissionGuid: E_ModuleGuids.adaptationProcesses,
        fields: ['isReadable'],
      },
    },
    {
      title: 'Завершённые процессы',
      description: '',
      fullPath: '',
      icon: null,
      isWork: true,
      path: ALL_ROUTES.completedAdaptationProcesses,
      children: null,
      premission: {
        permissionGuid: E_ModuleGuids.adaptationProcesses,
        fields: ['isReadable'],
      },
    },
    {
      title: 'Уволенные',
      description: '',
      fullPath: '',
      icon: null,
      isWork: true,
      path: ALL_ROUTES.firedAdaptationProcesses,
      children: null,
      premission: {
        permissionGuid: E_ModuleGuids.adaptationProcesses,
        fields: ['isReadable'],
      },
    },
    {
      title: 'Ожидают адаптации',
      description: '',
      fullPath: '',
      icon: null,
      isWork: true,
      path: ALL_ROUTES.waitingAdaptationProcesses,
      children: null,
      premission: {
        permissionGuid: E_ModuleGuids.adaptationProcesses,
        fields: ['isReadable'],
      },
    },
    {
      title: 'Адаптация не требуется',
      description: '',
      fullPath: '',
      icon: null,
      isWork: true,
      path: ALL_ROUTES.notAdaptationRequired,
      children: null,
      premission: {
        permissionGuid: E_ModuleGuids.adaptationProcesses,
        fields: ['isReadable'],
      },
    },
  ],
  title: 'Адаптация',
  description: 'Адаптация сотрудников',
  fullPath: '',
  icon: trainingPng,
  isWork: true,
  path: ALL_ROUTES.adaptation,
};
