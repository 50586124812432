import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { VisitTableProps } from './type';
import { VisitsList } from './VisitsList';
import { VisitsTable } from './VisitsTable';

export const AdeptiveVisitsTable: FC<VisitTableProps> = (props) => {
  const [isMobile] = useDevice();

  if (isMobile) return <VisitsList {...props} />;
  else return <VisitsTable {...props} />;
};
