import { Grid } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { UiCalendarCellContainerProps } from './type';

export const UiCalendarCellContainer: FC<UiCalendarCellContainerProps> = ({
  children,
  containerProps,
}) => {
  return (
    <Grid
      {...containerProps}
      sx={{
        border: '1px solid black',
        borderColor: theme.palette.grey[200],
        ...containerProps?.sx,
      }}
    >
      {children}
    </Grid>
  );
};
