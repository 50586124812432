import { checkIntersectiOnArrays } from 'shared/utils/checkIntersectiOnArrays';
import { checkIsNotEmptyArray } from 'shared/utils/checkIsNotEmptyArray';

export const createChecker = <T extends { guid: string }>(array: T[]) => {
  return (keys: string[]) => {
    if (checkIsNotEmptyArray(array)) {
      return checkIntersectiOnArrays(
        array.map((item) => item.guid),
        keys
      );
    }

    return true;
  };
};

export const createFilterOnCheckers = (
  checkers: ((keys: string[]) => boolean)[]
) => {
  return <T extends { guid: string; parentGuids: string[] }>(item: T) => {
    const checkFlags = checkers.map((checker) => checker(item.parentGuids));

    return checkFlags.every((value) => value === true);
  };
};
