import { Button, Divider, Stack } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useDevice } from 'shared/utils/hook-type-device';

import { LoadingButton } from '@mui/lab';
import { DialogForForm, FormLayout } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { RemindeFormFields, RemindeFormProps } from './type';
import { RemindeFormRow } from './ui/RemindeFormRow/RemindeFormRow';

export const RemindeForm: FC<RemindeFormProps> = ({
  onSubmit,
  initValue,
  actions,
  taskInfo,
}) => {
  const [isMobile] = useDevice();
  const { control, handleSubmit } = useForm<RemindeFormFields>({
    defaultValues: initValue,
  });

  return (
    <FormLayout
      actions={actions(handleSubmit)}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      container
      flexDirection={'column'}
      gap={3}
    >
      <Stack
        flexDirection={'column'}
        gap={[0, 2]}
        mb={[11, 0]}
        divider={isMobile ? <Divider /> : undefined}
      >
        {taskInfo.creator && (
          <Controller
            control={control}
            name='creator'
            render={({ field: { value, onChange } }) => (
              <RemindeFormRow
                lable='Постановщик'
                onChange={onChange}
                value={value}
              />
            )}
          />
        )}

        {taskInfo.executor && (
          <Controller
            control={control}
            name='executor'
            render={({ field: { value, onChange } }) => (
              <RemindeFormRow
                lable='Исполнитель'
                onChange={onChange}
                value={value}
              />
            )}
          />
        )}

        {taskInfo.coExecutor && (
          <Controller
            control={control}
            name='coExecutor'
            render={({ field: { value, onChange } }) => (
              <RemindeFormRow
                lable='Соисполнитель'
                onChange={onChange}
                value={value}
              />
            )}
          />
        )}

        {taskInfo.observer && (
          <Controller
            control={control}
            name='observer'
            render={({ field: { value, onChange } }) => (
              <RemindeFormRow
                lable='Наблюдатель'
                onChange={onChange}
                value={value}
              />
            )}
          />
        )}
      </Stack>
    </FormLayout>
  );
};

export interface RemindeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: RemindeFormFields) => void;
  isLoading?: boolean;
  initValue?: RemindeFormFields;
  taskInfo: {
    executor: boolean;
    coExecutor: boolean;
    observer: boolean;
    creator: boolean;
  };
}
export const RemindeDialog: FC<RemindeDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initValue,
  isLoading,
  taskInfo,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Изменение напоминаний' />}
    >
      <RemindeForm
        onSubmit={onSubmit}
        initValue={initValue}
        taskInfo={taskInfo}
        actions={() => [
          <LoadingButton
            loading={isLoading}
            key={1}
            variant='contained'
            type='submit'
          >
            Сохранить
          </LoadingButton>,
          <Button
            key={2}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
