// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { VisitingGetResponseNew } from 'features/visit/Stage 1/type';
import { useEffect, useState } from 'react';

import { getVisitingsVisitingId } from 'shared/api/visit/services';

export const useGetVisit = (visitId: number | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [visit, setVisit] = useState<VisitingGetResponseNew | null>(null);
  const [status, setStatus] = useState<number | null>(null);

  const getVisit = async () => {
    if (visitId) {
      setIsLoading(true);
      try {
        const { data, status } = await getVisitingsVisitingId(visitId);

        if (status === 200 && data) {
          setStatus(200);
          setVisit(data as unknown as VisitingGetResponseNew);
        }
      } catch (e) {
        // @ts-ignore
        setStatus(e.status);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getVisit();
  }, [visitId]);

  return { isLoading, visit, getVisit, status };
};
