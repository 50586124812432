import { useState } from 'react';

import { postEvents } from 'shared/api/visit/services';
import {
  EventCreateRequest,
  EventGetListResponse,
} from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useCreateVisitsEvent = (
  afterCreate?: (event: EventGetListResponse) => void
) => {
  const { open: openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const create = async (fetchData: EventCreateRequest) => {
    setIsLoading(true);
    try {
      const { data, status } = await postEvents(fetchData);

      if (!data || status !== 200) throw new Error('Упс, что-то пошло не так');
      afterCreate && afterCreate(data);
      setIsLoading(false);

      return data;
    } catch (e) {
      console.error('useCreateVisitsEvent.create >> ', e);
      setIsLoading(false);

      const error = e as { message: string };

      openSnackbar({
        duration: 6000,
        type: 'error',
        text: error.message,
      });

      return Promise.reject(e);
    }
  };

  return { isLoading, createEvent: create };
};
