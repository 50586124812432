import { BoardTaskFilterFormFields } from './type';

export const INIT_BOARD_TASK_FILTER: BoardTaskFilterFormFields = {
  coExecutors: [],
  creators: [],
  executors: [],
  finishDate: null,
  isTarget: null,
  origins: [],
  startDate: null,
  statuses: [],
};
