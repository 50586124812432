import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import { UiTableCard, UiTableCardList } from 'shared/ui/table';
import { EmployeeForTrainingListProps } from './types';

export const EmployeeForTrainingList: FC<EmployeeForTrainingListProps> = ({
  data,
  fields,
  onChangeFields,
  visibleFields,
  isLoading = false,
  onClick,
}) => {
  const dataWithNumber = data.map((employe, i) => ({
    ...employe,
    number: i + 1,
  }));

  return (
    <UiTableCardList
      fields={fields}
      stackContainerProps={{
        padding: 1,
      }}
      onChangeFields={onChangeFields}
    >
      {isLoading && (
        <CircularProgress
          color='inherit'
          sx={{ margin: '0 auto' }}
        />
      )}
      {dataWithNumber.map(
        (employe) =>
          employe.employeeGuid && (
            <UiTableCard
              data={employe}
              id={employe.employeeGuid}
              visibleFields={visibleFields}
              key={employe.employeeGuid}
              onClick={() => {
                onClick && onClick(employe);
              }}
            />
          )
      )}
    </UiTableCardList>
  );
};
