import { Grid, SxProps, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useStoreMap } from 'effector-react';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { CustomDatePicker } from 'shared/ui/base-ui';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormListAdditionalyFieldPeriod: FC = () => {
  const arrayAdditionalFields = useStoreMap(
    documentFormModel.processAdditionalFields,
    (processAdditionalFields) => {
      return (
        processAdditionalFields &&
        processAdditionalFields.periodAdditionallyFields
      );
    }
  );

  if (!arrayAdditionalFields) return <></>;

  return (
    <>
      {arrayAdditionalFields.map(({ id, isRequired, title }) => {
        return (
          <DocumentFormAdditionalyFieldPeriod
            key={id}
            required={isRequired}
            label={title}
            id={id}
          />
        );
      })}
    </>
  );
};

const DocumentFormAdditionalyFieldPeriod: FC<{
  id: number;
  required?: boolean;
  label?: string;
}> = ({ id, required, label = '-' }) => {
  const value = useStoreMap(documentFormModel.form, (form) => {
    if (form && form.stringAdditionallyFields) {
      const findPeriod = form.periodAdditionallyFields?.find(
        (item) => item.id === id
      );

      if (findPeriod) return findPeriod;

      return { beginValue: '', endValue: '' };
    } else {
      return { beginValue: '', endValue: '' };
    }
  });
  const message = useStoreMap(documentFormModel.form, (form) => {
    if (form && form.stringAdditionallyFields) {
      const findPeriod = form.periodAdditionallyFields?.find(
        (item) => item.id === id
      );

      if (
        findPeriod &&
        findPeriod.beginValue &&
        findPeriod.endValue &&
        dayjs(findPeriod.endValue) < dayjs(findPeriod.beginValue)
      ) {
        return 'Дата начала не может быть больше даты окончания';
      }

      return null;
    } else {
      return null;
    }
  });
  const [isMobile] = useDevice();

  return (
    <Grid
      container
      justifyContent={'space-between'}
      gap={2}
      sx={{
        flexBasis: ['100%', '45%'],
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            backgroundColor: theme.palette.customGrey.main,
            position: 'relative',
            padding: '0px 8px',
            flexBasis: ['100%', '45%'],
          },
        }}
      >
        {label}
      </Typography>

      <Grid
        gap={2}
        sx={{
          flexBasis: ['100%', '60%'],
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
        }}
      >
        <CustomDatePicker
          label={isMobile ? '' : 'Дата начала'}
          format='DD.MM.YYYY'
          value={value.beginValue ? dayjs(value.beginValue) : null}
          onChange={(value) =>
            documentFormModel.formEvent.setAdditionallyFieldPeriod({
              id,
              key: 'beginValue',
              value: value?.format('YYYY-MM-DDTHH:mm:ss') || '',
            })
          }
          containerSx={SX_TEXTFIELD}
          slotProps={{
            textField: {
              color: 'warning',
              required,
              margin: 'none',
              inputProps: {
                placeholder: isMobile ? 'Дата начала' : 'дд.мм.гггг',
              },
            },
          }}
        />

        <CustomDatePicker
          label={isMobile ? '' : 'Дата окончания'}
          format='DD.MM.YYYY'
          value={value.endValue ? dayjs(value.endValue) : null}
          onChange={(value) =>
            documentFormModel.formEvent.setAdditionallyFieldPeriod({
              id,
              key: 'endValue',
              value:
                value && value.isValid()
                  ? value.format('YYYY-MM-DDTHH:mm:ss')
                  : undefined,
            })
          }
          containerSx={SX_TEXTFIELD}
          slotProps={{
            textField: {
              required,
              margin: 'none',
              inputProps: {
                placeholder: isMobile ? 'Дата окончания' : 'дд.мм.гггг',
              },
            },
          }}
        />
      </Grid>

      <Typography
        sx={{ flexBasis: '100%' }}
        color='error'
        fontSize={12}
        textAlign={'end'}
      >
        {message}
      </Typography>
    </Grid>
  );
};

const SX_TEXTFIELD: SxProps = {
  display: 'flex',
  flexBasis: ['100%', '45%'],
  flexDirection: ['column', 'row'],
  alignItems: ['stretch', 'strech'],
  // gap: 10,
};
