import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import {
  useCreateColumnMutation,
  useGetBoardQuery,
  useGetColumnsTasksQuery,
} from 'shared/api/services/rtk/boardApi';
import { useCreateTaskMutation } from 'shared/api/services/rtk/taskApi';
import { GetListBoard, TaskCreate } from 'shared/api/services/types';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { useURLBoard } from '../hooks/useURLBoard';

export interface BoardPageContextFields {
  boardGuid: string;

  isCreatingColumn: boolean;
  createColumnHandler: (title: string, color: string) => void;

  isCreatingTask: boolean;
  createTaskHandler: (arg: TaskCreate) => Promise<void>;

  taskDrawer: {
    open: (taskGuid: string) => void;
    isOpen: boolean;
    close: () => void;
    taskGuid: string | undefined;
  };

  board: GetListBoard | undefined;
  isLoadingBoardInfo: boolean;

  showMainLoader: boolean;
}

export const BOARD_PAGE_CONTEXT_INIT: BoardPageContextFields = {
  boardGuid: '',

  isCreatingColumn: false,
  createColumnHandler: () => {
    //
  },

  isCreatingTask: false,
  createTaskHandler: async () => {
    //
  },
  taskDrawer: {
    close: () => {
      //
    },
    isOpen: false,
    open: () => {
      //
    },
    taskGuid: '',
  },

  board: undefined,
  isLoadingBoardInfo: false,

  showMainLoader: false,
};

export const BoardPageContext = createContext<BoardPageContextFields>(
  BOARD_PAGE_CONTEXT_INIT
);

export const useBoardPage = () => useContext(BoardPageContext);

interface BoardPageProviderProps extends PropsWithChildren {
  boardGuid: string;
}
export const BoardPageProvider: FC<BoardPageProviderProps> = ({
  boardGuid,
  children,
}) => {
  const [filter] = useURLBoard();
  const { close, initValue, isOpen, open } =
    useViewModalWithInitFields<string>();
  const [createColumn, { isLoading: isCreatingColumn }] =
    useCreateColumnMutation();
  const [createTask, { isLoading: isCreatingTask }] = useCreateTaskMutation();
  const { data: board, isLoading: isLoadingBoardInfo } =
    useGetBoardQuery(boardGuid);

  const { isFetching } = useGetColumnsTasksQuery({
    boardGuid,
    qParams: filter,
  });

  const showMainLoader = isLoadingBoardInfo || isFetching;

  const createColumnHandler = useCallback(
    (title: string, color: string) => {
      createColumn({ boardGuid, queryArg: { title, color } });
    },
    [boardGuid]
  );

  const createTaskHandler = useCallback(async (arg: TaskCreate) => {
    await createTask(arg);
  }, []);

  return (
    <BoardPageContext.Provider
      value={{
        boardGuid,
        createColumnHandler,
        isCreatingColumn,
        createTaskHandler,
        isCreatingTask,
        taskDrawer: {
          close,
          isOpen,
          open,
          taskGuid: initValue,
        },
        board,
        isLoadingBoardInfo,
        showMainLoader,
      }}
    >
      {children}
    </BoardPageContext.Provider>
  );
};
