import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { CreateAdaptationTemplateForm } from './CreateAdaptationTemplateForm';
import { CreateAdaptationTemplateDialogProps } from './type';

export const EditAdaptationTemplateDialog: FC<
  CreateAdaptationTemplateDialogProps
> = ({ isOpen, onClose, isLoading, ...formProps }) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Редактирование шаблона' />}
    >
      <CreateAdaptationTemplateForm
        {...formProps}
        disabled={isLoading}
        renderFormActions={() => [
          <LoadingButton
            loading={isLoading}
            key={1}
            variant='contained'
            type='submit'
          >
            Редактировать шаблон
          </LoadingButton>,
          <Button
            key={2}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
