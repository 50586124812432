import dayjs from 'dayjs';

import {
  onChangePeriod,
  UiExpensesFormPeriodPicker,
} from 'shared/ui/UiExpensesFormPeriodPicker';

import { documentFormEdit } from '../model';

export const ExpensesFormEditPeriodPicker = () => {
  const isExpenseByPeriod =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isExpenseByPeriod'
    );
  const periodTitle =
    documentFormEdit.formSelects.useSelectProcessValueOfKey('periodTitle');
  const isPeriod = Boolean(isExpenseByPeriod);

  const beginPeriod =
    documentFormEdit.formSelects.useSelectValueOfKey('beginPeriod');
  const endPeriod =
    documentFormEdit.formSelects.useSelectValueOfKey('endPeriod');
  const onChangeBegin: onChangePeriod = (value) =>
    documentFormEdit.api.setFiled({
      key: 'beginPeriod',
      value: dayjs(value).isValid()
        ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss')
        : null,
    });
  const onChangeEnd: onChangePeriod = (value) =>
    documentFormEdit.api.setFiled({
      key: 'endPeriod',
      value: dayjs(value).isValid()
        ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss')
        : null,
    });

  if (!isPeriod) return null;

  return (
    <UiExpensesFormPeriodPicker
      isPeriod={isPeriod}
      periodTitle={periodTitle || ''}
      beginPeriod={beginPeriod || null}
      endPeriod={endPeriod || null}
      onChangeBegin={onChangeBegin}
      onChangeEnd={onChangeEnd}
    />
  );
};
