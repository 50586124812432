import { findElementsByIds } from 'shared/tarnsform-helper/findElementsByIds';
import { getDayjs } from 'shared/tarnsform-helper/getDayjs';
import { EMPT_ARR } from 'widgets/training/filter/constants';

import {
  DocumentFlowFilterBaseForm,
  DocumentFlowGetBaseQueryParamsWithOutPagesParams,
} from '../types';
import { useLazyGetDocumentFlowOptionsOptionsBaseFilterForm } from './useLazyGetDocumentFlowOptionsOptionsBaseFilterForm';

export const useTransfromUrlStateToBaseFormFilter = () => {
  const {
    fetchStatuses,
    fetchOrganizations,
    fetchArticles,
    fetchCounterparty,
    fetchDocumentTypes,
    isLoadingAnyLaziOptionsDocumentFlow,
  } = useLazyGetDocumentFlowOptionsOptionsBaseFilterForm();

  const initBaseFilter = async (
    filter?: DocumentFlowGetBaseQueryParamsWithOutPagesParams | undefined
  ): Promise<DocumentFlowFilterBaseForm> => {
    const [
      optionsStatuse,
      optionsOrganization,
      optionsArticle,
      optionsCounterparty,
      optionsDocumentTypes,
    ] = await Promise.all([
      fetchStatuses(undefined, true).unwrap(),
      fetchOrganizations(undefined, true).unwrap(),
      fetchArticles(undefined, true).unwrap(),
      fetchCounterparty(undefined, true).unwrap(),
      fetchDocumentTypes(undefined, true).unwrap(),
    ]);

    const initialFormState: DocumentFlowFilterBaseForm = {
      statuses: findElementsByIds(
        optionsStatuse,
        filter?.StatusIdFilters || EMPT_ARR
      ),
      organizations: findElementsByIds(
        optionsOrganization,
        filter?.OrganizationIdFilters || EMPT_ARR
      ),
      articles: findElementsByIds(
        optionsArticle,
        filter?.ArticleIdFilters || EMPT_ARR
      ),
      contractors: findElementsByIds(
        optionsCounterparty,
        filter?.CounterpartyIdFilters || EMPT_ARR
      ),
      documentTypes: findElementsByIds(
        optionsDocumentTypes,
        filter?.ProcessIdFilters || EMPT_ARR
      ),
      dateFrom: getDayjs(filter?.StartDateCreatedFilter),
      dateTo: getDayjs(filter?.FinishDateCreatedFilter),
      sumFrom:
        filter?.TotalSumFrom && !isNaN(Number(filter.TotalSumFrom))
          ? Number(filter?.TotalSumFrom)
          : '',
      sumTo:
        filter?.TotalSumTo && !isNaN(Number(filter.TotalSumTo))
          ? Number(filter?.TotalSumTo)
          : '',
    };

    return initialFormState;
  };

  return { initBaseFilter, isLoadingAnyLaziOptionsDocumentFlow };
};
