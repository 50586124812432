import { Add, Delete, Edit } from '@mui/icons-material';
import { Chip, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { UiTableCard } from 'shared/ui/table';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';

import { PLANNING_RN_MAP } from './constants';
import { PlanningRNListActionsProps, PlanningRNListProps } from './type';

export const PlanningRNList: FC<PlanningRNListProps> = ({
  plannings,
  onCreateVisit,
  onDelete,
  onEdit,
}) => {
  if (plannings.length === 0)
    return (
      <Typography textAlign={'center'}>
        Нет запланированных посещений
      </Typography>
    );

  return (
    <Stack gap={1}>
      {plannings.map((planning) => (
        <UiTableCard
          key={planning.id}
          data={planning}
          id={String(planning.id)}
          visibleFields={PLANNING_RN_MAP}
          renderActions={() => (
            <PlanningRNListActions
              onCreateVisit={onCreateVisit}
              onDelete={onDelete}
              onEdit={onEdit}
              planning={planning}
            />
          )}
          renderHeaderChildren={() => (
            <Chip
              label='Планирование'
              sx={{ ml: 1 }}
              variant='outlined'
              color='primary'
              size='small'
            />
          )}
        />
      ))}
    </Stack>
  );
};

export const PlanningRNListActions: FC<PlanningRNListActionsProps> = ({
  onCreateVisit,
  onDelete,
  onEdit,
  planning,
}) => {
  return (
    <UiTableActions>
      {planning.canCreateVisiting && (
        <UiButtonAction
          text='Создать посещение'
          onClick={() => {
            onCreateVisit(planning);
          }}
          icon={<Add />}
          disable={!planning.canCreateVisiting}
        />
      )}

      <UiButtonAction
        text='Редактировать'
        onClick={() => {
          onEdit(planning);
        }}
        icon={<Edit />}
        color={theme.palette.customBlue.main}
      />

      {planning.canDelete && (
        <UiButtonAction
          text='Удалить'
          onClick={() => {
            onDelete(planning);
          }}
          icon={<Delete />}
          disable={!planning.canDelete}
          color={theme.palette.error.main}
        />
      )}
    </UiTableActions>
  );
};
