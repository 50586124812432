import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { SwaggerResponse } from 'shared/api/visit/config';
import { EmployeePlanningVisitorsGetListResponse } from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

import {
  $planningRNVisitors,
  fetchPlanningRNVisitorsFX,
} from './effector/planningRNVisitors';

let fetching: Promise<
  SwaggerResponse<EmployeePlanningVisitorsGetListResponse[]>
> | null = null;

export const useGetPlanningVisitors = () => {
  const { open: openSnackbar } = useSnackbar();

  const isLoading = useStore(fetchPlanningRNVisitorsFX.pending);
  const visitors = useStore($planningRNVisitors);

  const getVisitors = async () => {
    try {
      if (visitors && visitors.length > 0) return;
      if (!fetching) fetching = fetchPlanningRNVisitorsFX();

      const { data, status } = await fetching;

      fetching = null;

      if (!data || status !== 200) throw new Error('Упс, что-то пошло не так');
    } catch (e) {
      console.error('useGetPlanningVisitors.getVisitors >> ', e);
      openSnackbar({
        duration: 3000,
        type: 'error',
        text: 'Упс, что-то пошло не так',
      });
    }
  };

  useEffect(() => {
    getVisitors();
  }, []);

  return { isLoading, visitors };
};
