import { TextField, UseAutocompleteProps } from '@mui/material';
import { FC } from 'react';

import { ReasonDocumentTypeModel } from 'shared/api/services-document-flow/types';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

export const UiDocumentFormReasonTypeAutocomplite: FC<{
  disabled: boolean;
  onChange: UseAutocompleteProps<
    ReasonDocumentTypeModel,
    undefined,
    undefined,
    undefined
  >['onChange'];
  value: UseAutocompleteProps<
    ReasonDocumentTypeModel,
    undefined,
    undefined,
    undefined
  >['value'];
  options: UseAutocompleteProps<
    ReasonDocumentTypeModel,
    undefined,
    undefined,
    undefined
  >['options'];
}> = ({ disabled, onChange, value, options }) => {
  const [isMobile] = useDevice();

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        disabled,
        onChange,
        value: value || null,
        options: options,
        renderInput: (params) => (
          <TextField
            {...params}
            disabled={disabled}
            required={true}
            size='small'
            label={isMobile ? '' : 'Документ  - основание'}
            placeholder='Выберите документ-основание'
            margin={'none'}
          />
        ),
        getOptionLabel: (option) => option.title || '',
        renderOption: (props, option) => (
          <li
            {...props}
            key={option.id}
          >
            {option.title}
          </li>
        ),
        isOptionEqualToValue: (option, value) => option.id === value.id,
      }}
      sx={{ flexBasis: ['100%', '45%'] }}
      label='Документ  - основание*'
    />
  );
};
