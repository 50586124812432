import { createFilterOptions, Grid, Typography } from '@mui/material';

import { CounterpartyModel } from 'shared/api/services-document-flow/types';

export const renderOptionCounterparty = (
  params: React.HTMLAttributes<HTMLLIElement>,
  { title, id, taxNumber, regReasonCode }: CounterpartyModel
) => (
  <li
    {...params}
    key={id}
  >
    <Grid>
      <Typography fontWeight={'bold'}>{title}</Typography>
      {taxNumber && <Typography>{`ИНН: ${taxNumber}`} </Typography>}
      {regReasonCode && <Typography>{`КПП: ${regReasonCode}`}</Typography>}
    </Grid>
  </li>
);

export const filterOptionsCounterparty = createFilterOptions<CounterpartyModel>(
  {
    stringify: ({ title, regReasonCode, taxNumber }) =>
      `${title} ${regReasonCode} ${taxNumber}`,
  }
);
