export const createDownloadDomEventsForBase64File = (params: {
  fileType: string;
  data: string;
  fileName: string;
}): { download: () => void } => {
  const { data, fileName, fileType } = params;
  const link = document.createElement('a');

  link.href = `data:${fileType};base64,` + data;
  link.setAttribute('download', `${fileName}`);

  document.body.appendChild(link);

  return {
    download: () => {
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    },
  };
};
