export const DocumentSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='42'
      height='48'
      fill='none'
      viewBox='0 0 42 48'
    >
      <path
        fill='#116E6B'
        d='M34.92 38.665H28.3c-.277 0-.544.106-.732.3a1.018 1.018 0 00-.303.72v6.258H2.079V2.048h32.842v17.175L37 16.893V1.029a.988.988 0 00-.303-.72.992.992 0 00-.732-.308H1.045C.766 0 .5.105.302.299a1.023 1.023 0 00-.303.73V46.98c0 .273.107.537.303.721.197.194.464.299.732.299h28.558c.276 0 .535-.105.732-.299l3.212-3.138 3.15-3.12c.197-.193.304-.457.304-.72V22.5l-2.08 2.33v13.834h.01zm-5.577 7.322v-5.274h5.542l-5.542 5.274z'
      ></path>
      <path
        fill='#116E6B'
        d='M31 12H9v3h22v-3zM31 17H6v3h25v-3zM6 23v2h23.277L31 23.726V23H6zM6 28v2.983h12.26l-.73-.785-.895-.965a.504.504 0 01-.061-.595.507.507 0 01.435-.242l.121.018 4.653 1.138a.572.572 0 01.252.146l1.2 1.302H24.4l2.6-2.931v-.06H6V28z'
      ></path>
      <path
        fill='#2B9793'
        d='M41.828 15.906l-2.054-1.774a.55.55 0 00-.34-.132h-.036a.52.52 0 00-.359.176l-2.6 2.898-2.09 2.327-3.92 4.365-1.739 1.932-1.847 2.055-2.682 2.986-.592.659-.618-.659-1.238-1.326a.591.591 0 00-.26-.15l-4.807-1.15-.125-.017c-.18 0-.35.096-.449.246a.508.508 0 00.063.606l.924.983.753.8 4.376 4.672c.36.377.861.597 1.39.597a1.943 1.943 0 001.417-.624l9.336-10.39 2.09-2.326 5.434-6.052a.47.47 0 00-.027-.702z'
      ></path>
    </svg>
  );
};
