import { Grid } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { CustomToggleButtonGroup } from 'shared/ui/base-ui';

import { useGetControllFilters } from 'entities/task/hooks/useGetControllFilters';
import {
  useGetOriginsQuery,
  useLazyGetStatusesQuery,
} from 'shared/api/services/rtk/optionApi';
import {
  AutocompliteWithController,
  DatePikerWithController,
} from 'shared/fields-with-contoller';
import { FormLayout } from 'shared/ui/form';
import {
  createIsOptionEqualToValueByKey,
  createLabelOnKey,
  createRenderOptionByKey,
} from 'shared/utils/autocomplite-helpers';
import { TARGET_OPTIONS } from '../task-filter-form/constants';
import { TASK_WATCHING_FILTER_DEFAULT_VALUE } from './constants';
import { changeStatus } from './libs/changeStatus';
import { isCheck } from './libs/isCheck';
import { TaskWatchingFilterFormProps, WatchTaskFormFields } from './type';

export const TaskWatchingFilterForm: FC<TaskWatchingFilterFormProps> = ({
  onSubmit,
  defaultValue,
  actions,
}) => {
  const [fetchStatuses] = useLazyGetStatusesQuery();
  const { data: origins, isLoading: isLoadingOrigins } = useGetOriginsQuery();

  const {
    directionsAndRegions,
    departamentsAndDepts,
    officeAndDivision,
    positionsAndRetails,
    employees,
    isLoading,
  } = useGetControllFilters();

  const { control, handleSubmit, setValue, getValues } =
    useForm<WatchTaskFormFields>({
      defaultValues:
        defaultValue ||
        (async () => {
          const { data: statuss } = await fetchStatuses();

          return {
            ...TASK_WATCHING_FILTER_DEFAULT_VALUE,
            statuses:
              (statuss || []).map((el) => ({ ...el, selected: false })) || null,
          };
        }),
    });

  const directionsAndRegionsValue = useWatch({
    control,
    name: 'directionsAndRegions',
  });
  const departamentsAndDeptsValue = useWatch({
    control,
    name: 'departamentsAndDepts',
  });
  const officesAndDivisionsValue = useWatch({
    control,
    name: 'officesAndDivisions',
  });
  const positionsAndRetailsValue = useWatch({
    control,
    name: 'positionsAndRetails',
  });

  const directionsAndRegionsOptions = useMemo(() => {
    return directionsAndRegions || [];
  }, [directionsAndRegions]);

  const departamentsAndDeptsOption = useMemo(() => {
    if (!departamentsAndDepts) return [];

    const arr = departamentsAndDepts.filter((item) =>
      isCheck(item, directionsAndRegionsValue)
    );

    return arr;
  }, [departamentsAndDepts, directionsAndRegionsValue]);

  const officesAndDivisionsOption = useMemo(() => {
    if (!officeAndDivision) return [];

    const arr = officeAndDivision.filter(
      (item) =>
        isCheck(item, directionsAndRegionsValue) &&
        isCheck(item, departamentsAndDeptsValue)
    );

    return arr;
  }, [officeAndDivision, directionsAndRegionsValue, departamentsAndDeptsValue]);

  const positionsAndRetailsOption = useMemo(() => {
    if (!positionsAndRetails) return [];

    const arr = positionsAndRetails.filter(
      (item) =>
        isCheck(item, directionsAndRegionsValue) &&
        isCheck(item, officesAndDivisionsValue) &&
        isCheck(item, departamentsAndDeptsValue)
    );

    return arr;
  }, [
    positionsAndRetails,
    directionsAndRegionsValue,
    departamentsAndDeptsValue,
    officesAndDivisionsValue,
  ]);

  const employeesOption = useMemo(() => {
    if (!employees) return [];

    const arr = employees.filter(
      (item) =>
        isCheck(item, positionsAndRetailsValue) &&
        isCheck(item, directionsAndRegionsValue) &&
        isCheck(item, officesAndDivisionsValue) &&
        isCheck(item, departamentsAndDeptsValue)
    );

    return arr;
  }, [
    employees,
    directionsAndRegionsValue,
    departamentsAndDeptsValue,
    officesAndDivisionsValue,
    positionsAndRetailsValue,
  ]);

  const filterMap = useMemo(() => {
    return [
      {
        textFieldProps: {
          label: 'Направление или регион',
          placeholder: 'Выберите направление или регион',
        },
        name: 'directionsAndRegions' as const,
        options: directionsAndRegionsOptions,
      },
      {
        textFieldProps: {
          label: 'Служба или аптечная сеть',
          placeholder: 'Выберите службу или аптечную сеть',
        },
        name: 'departamentsAndDepts' as const,
        options: departamentsAndDeptsOption,
      },
      {
        textFieldProps: {
          label: 'АУП или Дивизион',
          placeholder: 'Выберите АУП или Дивизион',
        },
        name: 'officesAndDivisions' as const,
        options: officesAndDivisionsOption,
      },
      {
        textFieldProps: {
          label: 'Должность АУП или номер аптеки',
          placeholder: 'Выберите должность АУП или номер аптеки',
        },
        name: 'positionsAndRetails' as const,
        options: positionsAndRetailsOption,
      },
      {
        textFieldProps: {
          label: 'ФИО сотрудника',
          placeholder: 'Выберите ФИО сотрудника',
        },
        name: 'employees' as const,
        options: employeesOption,
      },
    ];
  }, [
    directionsAndRegionsOptions,
    departamentsAndDeptsOption,
    officesAndDivisionsOption,
    positionsAndRetailsOption,
    employeesOption,
  ]);

  /* Удаление паритетных выбранных фильтров */
  useEffect(() => {
    const values = getValues();

    setValue(
      'employees',
      values.employees.filter((el) =>
        employeesOption.some((el2) => el2.guid === el.guid)
      )
    );
  }, [employeesOption]);

  useEffect(() => {
    const values = getValues();

    setValue(
      'positionsAndRetails',
      values.positionsAndRetails.filter((el) =>
        positionsAndRetailsOption.some((el2) => el2.guid === el.guid)
      )
    );
  }, [positionsAndRetailsOption]);

  useEffect(() => {
    const values = getValues();

    setValue(
      'officesAndDivisions',
      values.officesAndDivisions.filter((el) =>
        officesAndDivisionsOption.some((el2) => el2.guid === el.guid)
      )
    );
  }, [officesAndDivisionsOption]);

  useEffect(() => {
    const values = getValues();

    setValue(
      'departamentsAndDepts',
      values.departamentsAndDepts.filter((el) =>
        departamentsAndDeptsOption.some((el2) => el2.guid === el.guid)
      )
    );
  }, [departamentsAndDeptsOption]);
  /* ===== */

  return (
    <FormLayout
      actions={actions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit, console.error)}
    >
      {filterMap.map((el, i) => (
        <AutocompliteWithController
          key={i}
          {...el}
          control={control}
          loading={isLoading}
          noOptionsText='Нет совпадений'
          loadingText='Загрузка...'
          getOptionLabel={createLabelOnKey('title')}
          multiple
          isOptionEqualToValue={createIsOptionEqualToValueByKey('guid')}
          disabled={el.options.length === 0 && !isLoading}
          renderOption={createRenderOptionByKey({
            keyGuid: 'guid',
            keyLabel: 'title',
          })}
        />
      ))}

      {/* ================== */}
      <Controller
        control={control}
        name='statuses'
        render={({ field: { value, onChange } }) => (
          <CustomToggleButtonGroup
            value={value || []}
            getLable={(val) => val.title || ''}
            getSelected={(val) => val.selected}
            onChange={(newStatus) => {
              onChange(changeStatus(value || [], newStatus));
            }}
            label='Статус'
            containerProps={{ px: [1, 0], pt: [1, 0] }}
          />
        )}
      />
      {/* ================== */}

      {/* ================== */}
      <AutocompliteWithController
        control={control}
        name='isTarget'
        options={TARGET_OPTIONS}
        textFieldProps={{
          label: 'Задачи',
          placeholder: 'Выберите фильтр',
        }}
        isOptionEqualToValue={createIsOptionEqualToValueByKey('id')}
        getOptionLabel={createLabelOnKey('title')}
        renderOption={createRenderOptionByKey({
          keyGuid: 'id',
          keyLabel: 'title',
        })}
      />
      {/* ================== */}

      {/* ================== */}
      <AutocompliteWithController
        control={control}
        name='origin'
        textFieldProps={{
          label: 'Источник',
          placeholder: 'Выберите источники',
        }}
        multiple
        noOptionsText='Нет совпадений'
        loading={isLoadingOrigins}
        isOptionEqualToValue={createIsOptionEqualToValueByKey('id')}
        loadingText='Загрузка...'
        options={origins || []}
        getOptionLabel={createLabelOnKey('title')}
        renderOption={createRenderOptionByKey({
          keyGuid: 'id',
          keyLabel: 'title',
        })}
      />
      {/* ================== */}

      <Grid
        container
        flexDirection={['column', 'row']}
        justifyContent={'space-between'}
        flexWrap={'nowrap'}
        gap={2}
      >
        <DatePikerWithController
          control={control}
          name='dateStart'
          sx={{ width: '100%' }}
          label={'Дата начала'}
          slotProps={{ textField: { placeholder: 'Введите дату' } }}
        />

        <DatePikerWithController
          control={control}
          name={'dateFinish'}
          sx={{ width: '100%' }}
          label={'Дата завершения'}
          slotProps={{ textField: { placeholder: 'Введите дату' } }}
        />
      </Grid>
    </FormLayout>
  );
};
