import { PhotoCamera } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMarketingSurfaceUploadImages } from 'features/marketing-surface/upload-images';
import { FC } from 'react';
import { useGetMarketingSurfaceImagesListQuery } from 'shared/api/marketing-surface/rtk/marketingSurfaceProviderApi';
import { useMyAppMediaQuery } from 'shared/hooks/useMyAppMediaQuery';
import { SwipeableDrawerUi } from 'shared/ui/swipeable-drawer-ui';
import { ImageSurfaceListUi } from './ImageSurfaceListUi';

interface SwipeableDrawerImagesListUiProps {
  onOpenImagesDawer: () => void;
  onCloseImagesDrawer: () => void;
  isOpenImagesDrawer: boolean;
  surfaceGuid: string | null | undefined;
}
export const SwipeableDrawerImagesListUi: FC<
  SwipeableDrawerImagesListUiProps
> = ({
  onOpenImagesDawer,
  onCloseImagesDrawer,
  isOpenImagesDrawer,
  surfaceGuid,
}) => {
  const { cols, variant } = useMyAppMediaQuery<{
    cols: number | undefined;
    variant?: 'masonry' | 'standard';
  }>([
    { cols: 1, variant: 'standard' },
    { cols: 2 },
    { cols: 3, variant: 'masonry' },
    { cols: 3 },
    { cols: 3 },
  ]);

  const {
    data: imagesList,
    currentData,
    isFetching: isLoadinImagesList,
  } = useGetMarketingSurfaceImagesListQuery(
    { queryParams: { surfaceGuid: surfaceGuid as string } },
    {
      skip: !surfaceGuid || !isOpenImagesDrawer,
    }
  );

  const { onUploadImages, isLoadingUploadImages } =
    useMarketingSurfaceUploadImages({ surfaceGuid: surfaceGuid });

  return (
    <SwipeableDrawerUi
      onClose={onCloseImagesDrawer}
      onOpen={onOpenImagesDawer}
      PaperProps={{
        sx: {
          minHeight: '80vh',
        },
      }}
      open={isOpenImagesDrawer}
    >
      {surfaceGuid && currentData === imagesList && (
        <ImageSurfaceListUi
          imagesList={imagesList}
          isLoading={isLoadinImagesList}
          variant={variant}
          surfaceGuid={surfaceGuid}
          cols={cols}
        />
      )}
      <LoadingButton
        component='label'
        role={undefined}
        disabled={isLoadingUploadImages}
        variant='contained'
        loading={isLoadingUploadImages}
        tabIndex={-1}
        fullWidth
        size='large'
        sx={{ position: 'fixed', bottom: 0, borderRadius: 0 }}
        startIcon={<PhotoCamera />}
      >
        Загрузить фото
        <input
          hidden
          type='file'
          multiple
          accept='image/gif, image/jpeg, image/png'
          onChange={onUploadImages}
        />
      </LoadingButton>
    </SwipeableDrawerUi>
  );
};
