import { Controller } from 'react-hook-form';
import { CustomDatePicker } from 'shared/ui/base-ui';
import { DatePikerWithControllerComponent } from './types';

/**
 * @description связка Controller из react-hook-form и CustomDatePicker / CustomTextField / Autocomplete / Switch.
 * @see {@linkcode shared/ui/base-ui}
 */
export const DatePikerWithController: DatePikerWithControllerComponent = ({
  control,
  name,
  fullWidth,
  containerSx,
  ...propsDatePiker
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <CustomDatePicker
            value={value}
            onChange={onChange}
            slotProps={{
              textField: {
                size: 'small',
                error: Boolean(error),
                helperText: error?.message,
                fullWidth: fullWidth,
              },
            }}
            containerSx={
              fullWidth ? { ...containerSx, width: '100%' } : containerSx
            }
            {...propsDatePiker}
          />
        );
      }}
    />
  );
};
