import { Button, Grid } from '@mui/material';
import { FC } from 'react';

import { ConfirmDialog } from './ConfirmDialog';

interface ConfirmStageDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  handleNext: () => void;
  isLoading: boolean;
}
export const ConfirmStageDialog: FC<ConfirmStageDialogProps> = ({
  isOpen,
  handleClose,
  handleConfirm,
  handleNext,
  isLoading,
}) => {
  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={handleClose}
      title='Сохранить изменения?'
      isLoading={isLoading}
      actions={
        <Grid container>
          <Button
            onClick={handleConfirm}
            variant='contained'
            size='large'
            sx={{
              borderRadius: '0px',
            }}
          >
            Сохранить
          </Button>

          <Button
            onClick={handleNext}
            variant='contained'
            size='large'
            sx={{
              borderRadius: '0px',
            }}
          >
            Не сохранять
          </Button>

          <Button
            onClick={handleClose}
            variant='contained'
            color='error'
            size='large'
            sx={{
              borderRadius: '0px',
            }}
          >
            Закрыть
          </Button>
        </Grid>
      }
    />
  );
};
