import { Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import { CreateVisitDialog } from 'features/visit/create-visit-dialog';
import { VisitFilterDialog } from 'features/visit/visit-filter-dialog/VisitFilterDialog';
import { VisitCalendar } from 'features/visit/VisitCalendar';
import { VisitTable } from 'features/visit/VisitTable/VisitTable';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { getVisitingsObserve } from 'shared/api/visit/services';
import { GetVisitingsActionQueryParams } from 'shared/api/visit/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { convertURLToVisitFilterWithoutPagination } from 'shared/url-helper/conventors/convertURLToVisitFilterWithoutPagination';
import { useURLState } from 'shared/url-helper/useURLState';
import { useDevice } from 'shared/utils/hook-type-device';

import { useCreateChecklist } from 'entities/visit/checkList/hooks/useCreateChecklist';
import { useConfirmModalVisitDelete } from 'entities/visit/visit/hooks/useConfirmModalVisitDelete';
import { useCreateVisit } from 'entities/visit/visit/hooks/useCreateVisit';
import { useGetVisitsForPlanning } from 'entities/visit/visit/hooks/useGetVisitsForPlanning';
import { useVisitNavigation } from 'entities/visit/visit/hooks/useVisitNavigation';

import { PlanningSearchBar } from 'features/visit/PlanningSearchBar';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { convertCreateVisitFormToFetch } from 'widgets/visits/forms/create-visit-from/libs/convertCreateVisitFormToFetch';
import { CreateVisitFormFields } from 'widgets/visits/forms/create-visit-from/type';
import {
  convertFetchDataToInitVisitFilterForm,
  convertVisitFilterFormFieldsToFetchData,
  VisitFilterFormFields,
} from 'widgets/visits/forms/visit-filter-form';

// TODO: Пока так
export const changeVisitFormat = createEvent<'Календарь' | 'Таблица'>();
export const $selectedVisitFormat = createStore<'Календарь' | 'Таблица'>(
  'Календарь'
).on(changeVisitFormat, (_, value) => value);
//

export const getDateRange = (date: Dayjs) => {
  return {
    BeginDate: date.startOf('month').format(DATE_FORMATS.server),
    EndDate: date.endOf('month').format(DATE_FORMATS.server),
  };
};

export const PlanningPage = () => {
  const [isMobile] = useDevice();
  const { open: openSnackbar } = useSnackbar();
  const { navigateToCheckList } = useVisitNavigation();
  const { createChecklist, isLoading: isLoadingCreateChecklist } =
    useCreateChecklist();

  const viewType = useStore($selectedVisitFormat);

  /* Filters */
  const [filters, setFilters] = useURLState<GetVisitingsActionQueryParams>(
    convertURLToVisitFilterWithoutPagination
  );
  /* ===== */

  /* Visits */
  const { isLoading, update, visits } = useGetVisitsForPlanning(
    getVisitingsObserve,
    filters
  );
  /* ===== */

  /* Delete */
  const { deleteVisit, modal } = useConfirmModalVisitDelete(update);
  /* ===== */

  /* Date */
  const hasDateRangeInFilter = useMemo(() => {
    return filters?.BeginDate && filters?.EndDate;
  }, []);

  const initCalendarDay = useMemo(() => {
    if (hasDateRangeInFilter && filters?.BeginDate)
      return dayjs(filters.BeginDate);
    else return dayjs();
  }, []);

  const [selectedMonth, setSelectedMonth] = useState(initCalendarDay);
  const [selectedDay, setSelectedDay] = useState(initCalendarDay);

  const changeMonthHandle = useCallback(
    (newMonth: Dayjs) => {
      setSelectedMonth(newMonth);
      setFilters({ ...filters, ...getDateRange(newMonth) });
    },
    [filters, setFilters]
  );

  // Инициализация даты в фильтре
  useEffect(() => {
    if (!hasDateRangeInFilter) {
      setFilters({ ...filters, ...getDateRange(selectedMonth) });
    }
  }, []);
  /* ===== */

  /* Search */
  const applySearch = useCallback(
    (search: string) => {
      setFilters({ ...filters, SearchText: search });
    },
    [filters, setFilters]
  );
  /* ===== */

  /* Create visit */
  const { close, isOpen, open, initValue } =
    useViewModalWithInitFields<Partial<CreateVisitFormFields>>();
  const { isLoading: isCreatingVisit, createVisit } = useCreateVisit();

  const handleSubmitCreateVisit = (form: CreateVisitFormFields) => {
    const fetchData = convertCreateVisitFormToFetch(form);

    createVisit(fetchData).then(() => {
      update();
      close();
    });
  };

  const handleSubmitCreateChecklist = (form: CreateVisitFormFields) => {
    if (form.retailsIds.length > 1) {
      openSnackbar({
        duration: 3000,
        type: 'error',
        text: 'Для создания чек-листа должна быть выбрана только одна аптека',
      });

      return;
    }

    const fetchData = convertCreateVisitFormToFetch(form);

    createVisit(fetchData).then((visit) => {
      if (visit) {
        createChecklist(visit[0].id).then((checklist) => {
          if (checklist) {
            if (checklist.isCompetitorComparison)
              navigateToCheckList(checklist.id, 2);
            else navigateToCheckList(checklist.id, 4);
          }
        });
      }
    });
  };
  /* ===== */

  /* Filter */
  const {
    close: closeFilterDialog,
    isOpen: isOpenFilterDialog,
    open: openFilterDialog,
  } = useViewModal();

  const handleSubmitFilter = (form: VisitFilterFormFields) => {
    setFilters(convertVisitFilterFormFieldsToFetchData(form));
    closeFilterDialog();
  };
  /* ===== */

  const openCreateVisits = useCallback(() => {
    open({
      date: selectedDay.format(DATE_FORMATS.server),
    });
  }, [selectedDay]);

  const onCreateChecklist = useCallback((visitId: number) => {
    createChecklist(visitId).then((checklist) => {
      if (checklist) navigateToCheckList(checklist.id);
    });
  }, []);

  return (
    <LayoutPage.Page>
      {modal}
      <CustomBackdrop isLoading={isLoadingCreateChecklist} />
      <CreateVisitDialog
        isOpen={isOpen}
        onClose={close}
        initValue={initValue}
        isLoading={isCreatingVisit || isLoadingCreateChecklist}
        onSubmitCreateChecklist={handleSubmitCreateChecklist}
        onSubmitPlanningVisit={handleSubmitCreateVisit}
      />

      <VisitFilterDialog
        isOpen={isOpenFilterDialog}
        onClose={closeFilterDialog}
        disableDates
        initValue={convertFetchDataToInitVisitFilterForm(filters)}
        onSubmit={handleSubmitFilter}
      />

      <LayoutPage.Bar>
        <MemoizedPlanningSearchBar
          onSubmitInput={applySearch}
          onOpenFilter={openFilterDialog}
          onOpenPlanning={openCreateVisits}
          initSearch={filters?.SearchText}
        />
        {/* ==== */}
      </LayoutPage.Bar>
      <LayoutPage.Content>
        {/* Bottom */}
        {viewType === 'Календарь' ? (
          <VisitCalendar
            visits={visits}
            isLoading={isLoading}
            selectedMonth={selectedMonth}
            onChangeMonth={changeMonthHandle}
            onDeleteVisit={deleteVisit}
            onOpenVisit={navigateToCheckList}
            onCreateChecklist={onCreateChecklist}
            onChangeSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />
        ) : (
          <>
            {!isMobile ? (
              <VisitTable
                loading={isLoading}
                onChangeMonth={changeMonthHandle}
                selectedMonth={selectedMonth}
                visits={visits}
                filter={filters}
                onOpenVisit={(visit) =>
                  visit.visitingChecklistId &&
                  navigateToCheckList(visit.visitingChecklistId)
                }
                onCreateVisit={(retailId, date) => {
                  open({
                    date: date.format(DATE_FORMATS.server),
                    retailsIds: [retailId],
                  });
                }}
              />
            ) : (
              <Typography
                textAlign={'center'}
                fontWeight={'bold'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                height={'calc(100vh - 200px)'}
                px={4}
              >
                Данная страница пока не доступна на мобильных устройствах 😐
              </Typography>
            )}
          </>
        )}
        {/* ==== */}
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};

const MemoizedPlanningSearchBar = memo(PlanningSearchBar);
