import { useCallback, useEffect, useState } from 'react';

import { VisitingGetListResponse } from 'shared/api/visit/types';

import { useGetVisitsType } from './useGetVisits';

const LIMIT_VISITS_FOR_MONTH = 1000;

export const useGetVisitsForPlanning: useGetVisitsType = (getVisit, filter) => {
  const [count, setCount] = useState(-1);
  const [isLoading, setIsLoadig] = useState(false);
  const [visits, setVisits] = useState<VisitingGetListResponse[]>([]);

  const getVisits = useCallback(async () => {
    if (filter?.BeginDate && filter.EndDate) {
      setIsLoadig(true);

      const { status, data } = await getVisit({
        ...filter,
        Page: undefined,
        Limit: LIMIT_VISITS_FOR_MONTH,
      });

      setIsLoadig(false);

      if (status === 200 && data) {
        setCount(data.count);
        data.items && setVisits(data.items);
      }
    }
  }, [filter, getVisit]);

  useEffect(() => {
    getVisits();
  }, [filter]);

  return {
    count,
    visits,
    isLoading,
    update: getVisits,
  };
};
