/* isAuthUser */
import { createEffect, createEvent, createStore, sample } from 'effector';

import { getEmployee } from 'shared/api/services-auth/services';
import { AuthResponsesEmployeeInformation } from 'shared/api/services-auth/types';

import { authorizationFx, logOut } from './authorization-effector';

/* Events for isAuthUser */
export const changeIsAuthUser = createEvent<boolean>('changeIsAuthUser');
/* Store for is auth user */
export const $isAuthUser = createStore(false, { name: '$isAuthUser' })
  .on(changeIsAuthUser, (_, isAuth) => isAuth)
  .on(logOut, () => false);
/* -------------------------------------------------------------------------------------------- */
sample({
  source: authorizationFx.doneData,
  fn: () => true,
  target: changeIsAuthUser,
});

export const fetchUserDataFx = createEffect(async () => {
  return await getEmployee();
});
export const $userData = createStore<null | AuthResponsesEmployeeInformation>(
  null
)
  .on(fetchUserDataFx.doneData, (_, response) => response.data)
  .reset(logOut);

sample({ clock: $isAuthUser, target: fetchUserDataFx });
