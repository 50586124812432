import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import { FC } from 'react';

export const SwitchUi: FC<FormControlLabelProps> = (formControlLabelProps) => {
  return (
    <FormControlLabel
      sx={{
        marginLeft: [0],
        paddingLeft: [2, 0],
        paddingRight: [2, 0],
        boxSizing: 'border-box',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        '& > span:nth-of-type(2)': {
          fontSize: [16, 16],
        },
        '&.Mui-disabled .MuiFormControlLabel-label': {
          color: 'black',
        },
      }}
      labelPlacement='start'
      {...formControlLabelProps}
    />
  );
};
