import { ConfirmModal } from 'features/visit/ConfirmModal/ConfirmModal';
import { useCallback, useMemo, useState } from 'react';

import { useViewModal } from 'shared/hooks/useViewModal';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';

import { useDeleteVisit } from './useDeleteVisit';

export const useConfirmModalVisitDelete = (afterDelete: () => void) => {
  const { deleteVisit, isLoading: isLoadingDelete } = useDeleteVisit();
  const [toDel, setToDel] = useState<number | null>(null);
  const {
    isOpen: isConfirmOpen,
    close: closeConfirm,
    open: openConfirm,
  } = useViewModal();

  const delVisit = useCallback(() => {
    closeConfirm();
    toDel !== null &&
      deleteVisit(toDel).then(() => {
        setToDel(null);
        afterDelete();
      });
  }, [closeConfirm, deleteVisit, afterDelete, toDel]);

  const prepareToDelete = useCallback(
    (visitId: number) => {
      setToDel(visitId);
      openConfirm();
    },
    [openConfirm]
  );

  const modal = useMemo(() => {
    return (
      <>
        <ConfirmModal
          isOpen={isConfirmOpen}
          onClose={closeConfirm}
          text='Удалить посещение?'
          onConfirm={delVisit}
        />
        <CustomBackdrop isLoading={isLoadingDelete} />
      </>
    );
  }, [isConfirmOpen, closeConfirm, delVisit, isLoadingDelete]);

  return {
    deleteVisit: prepareToDelete,
    modal,
  };
};
