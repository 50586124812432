import { CoreSystemEnumsObjectActions } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { SliderFormValue } from './types';

export const DEFAULT_VALUES: SliderFormValue = {
  dateStart: null,
  dateFinish: null,
  link: '',
  organization: '',
  erid: '',
  imageDesktopData: null,
  imageDesktopDataAction: CoreSystemEnumsObjectActions.None,
  imageDesktopUrl: '',
  imageMobileData: null,
  imageMobileDataAction: CoreSystemEnumsObjectActions.None,
  imageMobileUrl: null,
};
