import { LocationOn, Phone } from '@mui/icons-material';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { RetailCardProps } from './type';

const GREY = theme.palette.grey[700];
const MAIN = theme.palette.primary.main;

export const MapRetailPopup: FC<RetailCardProps> = ({
  retail,
  onSelect,
  disable,
  isSelected,
  onUnSelect,
}) => {
  const color = !disable ? MAIN : GREY;

  return (
    <Grid
      container
      flexDirection={'column'}
      alignContent={'center'}
      justifyContent={'space-between'}
    >
      <Typography
        fontWeight={700}
        variant='subtitle1'
        color={color}
      >
        {retail.title}
      </Typography>

      <Grid mb={2}>
        <Grid
          container
          alignItems={'center'}
          wrap='nowrap'
          gap={1}
        >
          <LocationOn sx={{ color: color }} />
          {retail.address}
        </Grid>

        {retail.phone && (
          <Grid
            container
            alignItems={'center'}
            gap={1}
          >
            <Phone sx={{ color: color }} />
            {retail.phone}
          </Grid>
        )}
      </Grid>

      {isSelected ? (
        <Button
          variant='contained'
          size='small'
          sx={{ width: '100%' }}
          onClick={onUnSelect}
          color='customBlue'
          disabled={disable}
        >
          Убрать аптеку
        </Button>
      ) : (
        <Button
          variant='contained'
          size='small'
          sx={{ width: '100%' }}
          onClick={onSelect}
          disabled={disable}
        >
          Выбрать аптеку
        </Button>
      )}
    </Grid>
  );
};

export const RetailCard: FC<RetailCardProps> = ({
  retail,
  onSelect,
  disable,
  isSelected,
  onUnSelect,
}) => {
  const color = !disable ? MAIN : GREY;

  return (
    <Paper
      elevation={2}
      component={Grid}
      px={[2, 4]}
      py={[2, 3]}
      container
      flexDirection={['column', 'row']}
      gap={[1, 0]}
      alignContent={'center'}
      justifyContent={'space-between'}
    >
      <Grid
        flexBasis={'25%'}
        container
        alignItems={'center'}
      >
        <Typography
          fontWeight={700}
          color={color}
        >
          {retail.title}
        </Typography>
      </Grid>

      <Grid flexBasis={'40%'}>
        <Grid
          container
          alignItems={'center'}
          wrap='nowrap'
          gap={1}
        >
          <LocationOn sx={{ color: color }} />
          {retail.address}
        </Grid>

        {retail.phone && (
          <Grid
            container
            alignItems={'center'}
            gap={1}
          >
            <Phone sx={{ color: color }} />
            {retail.phone}
          </Grid>
        )}
      </Grid>

      <Grid
        flexBasis={'32%'}
        container
        justifyContent={'flex-end'}
        alignItems={'center'}
      >
        <Button
          variant='contained'
          size='small'
          sx={{ width: ['100%', '80%'] }}
          onClick={isSelected ? onUnSelect : onSelect}
          color={isSelected ? 'customBlue' : 'primary'}
          disabled={disable}
        >
          {isSelected ? 'Убрать аптеку' : 'Выбрать аптеку'}
        </Button>
      </Grid>
    </Paper>
  );
};
