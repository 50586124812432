import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_API_URL } from 'shared/api/constants';

export const aptekaLegkoBannersProviderApi = createApi({
  reducerPath: 'aptekaLegkoBannersProviderApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL }),
  tagTypes: ['slider-list', 'slider-by-guid', 'brands-list', 'brand-by-guid'],
  endpoints: (build) => ({}),
});

export const {} = aptekaLegkoBannersProviderApi;
