import { Button } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useCreateAdaptationTemplateMutation } from 'shared/api/service-adaptation/rtk/adaptationTemplatesProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { getGuidsFromArray } from 'shared/tarnsform-helper/getGuidsFromArray';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import { CreateAdaptationTemplateDialog } from '../forms/CreateAdaptationTemplateForm/CreateAdaptationTemplateDialog';
import { CreateAdaptationTemplateFormFields } from '../forms/CreateAdaptationTemplateForm/type';
import { AdaptationTemplatesSearchBarProps } from './type';

export const AdaptationTemplatesSearchBar: FC<
  AdaptationTemplatesSearchBarProps
> = ({ initSearch, onClear, onSubmit }) => {
  /* Create template dialog */
  const [createTemplate, { isLoading, error }] =
    useCreateAdaptationTemplateMutation();

  useShowFetchErrorInSnackbar(error);

  const {
    close: closeCreateTemplate,
    isOpen: isOpenCreateTemplate,
    open: openCreateTemplate,
  } = useViewModal();

  const handleCreateTemplate = useCallback(
    (form: CreateAdaptationTemplateFormFields) => {
      createTemplate({
        isAutorun: form.isAutorun,
        title: form.title,
        eventGuids: getGuidsFromArray(form.events),
      }).then((res) => {
        if ('error' in res) return;
        closeCreateTemplate();
      });
    },
    [closeCreateTemplate, createTemplate]
  );
  /* ===== */

  /* Search */
  const [search, setSearch] = useState(initSearch || '');
  const handleClear = useCallback(() => {
    if (search !== '') {
      onClear?.();
      setSearch('');
    }
  }, [onClear, setSearch]);
  /* ===== */

  return (
    <>
      <CreateAdaptationTemplateDialog
        isOpen={isOpenCreateTemplate}
        onClose={closeCreateTemplate}
        onSubmit={handleCreateTemplate}
        isLoading={isLoading}
      />

      <UiSearchBar
        changeValue={setSearch}
        onCompliteInput={onSubmit}
        onClear={handleClear}
        textValue={search}
      >
        <Button
          variant='contained'
          color='customBlue'
          onClick={openCreateTemplate}
        >
          Создать шаблон
        </Button>
      </UiSearchBar>
    </>
  );
};
