import {
  CheckCircle,
  Dashboard,
  DateRange,
  Delete,
  DeveloperBoardOff,
  NotificationAdd,
  Person,
  PersonAdd,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import { BoardAppActionBar } from 'features/boards/BoardAppActionBar';
import { AddTaskToBoardDialog } from 'features/boards/dialogs/AddTaskToBoardDialog';
import { FC, useCallback } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useMoveTaskToAnotherBoardMutation } from 'shared/api/services/rtk/boardApi';
import {
  useChangeCoExecutorsAnsObserversMutation,
  useChangeExecutorsMutation,
  useChangeRemindMutation,
  useChangeStartFinishDatesMutation,
  useChangeStatusesToNextMutation,
  useDeleteTasksMutation,
} from 'shared/api/services/rtk/taskApi';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { ChangeCoExecutersAndObserversDialog } from '../forms/ChangeCoExecutersAndObserversForm/ChangeCoExecutersAndObserversForm';
import { ChangeExecuterDialog } from '../forms/ChangeExecuterForm/ChangeExecuterForm';
import { ChangeStartFinishDateDialog } from '../forms/ChangeStartFinishDateForm/ChangeStartFinishDateForm';
import { prepareRemideFormDataToFetch } from '../reminde-form/libs/prepareRemideFormDataToFetch';
import { RemindeDialog } from '../reminde-form/RemindeForm';
import { TaskMultiActionsProps } from './type';

export const TaskMultiActions: FC<TaskMultiActionsProps> = ({
  taskGuids,
  unselectAll,
}) => {
  const { open: openSnackbar } = useSnackbar();
  const openConfirm = useConfirm();

  const [
    changeExecutors,
    { isLoading: isLoadingChangeExecutors, error: executorsChangeError },
  ] = useChangeExecutorsMutation();
  const [
    changeCoExecutersAnsObservers,
    {
      isLoading: isLoadingChangeCoExecutersAnsObservers,
      error: coExecutorsAndObserversChangeError,
    },
  ] = useChangeCoExecutorsAnsObserversMutation();
  const [
    changeStartFinishDates,
    {
      isLoading: isLoadingChangeStartFinishDates,
      error: startFinishDatesChangeError,
    },
  ] = useChangeStartFinishDatesMutation();
  const [
    moveTasksToBoard,
    { isLoading: isLoadingMoveTasksToBoard, error: moveTasksToBoardError },
  ] = useMoveTaskToAnotherBoardMutation();
  const [
    changeRemind,
    { isLoading: isLoadingChangeRemind, error: remindChangeError },
  ] = useChangeRemindMutation();
  //
  //
  const [deleteTasks, { error: deleteTasksError }] = useDeleteTasksMutation();
  const [changeStatuses, { error: statusesChangeError }] =
    useChangeStatusesToNextMutation();
  const [unPin, { error: unPinError }] = useMoveTaskToAnotherBoardMutation();

  useShowFetchErrorInSnackbar(
    executorsChangeError ||
      coExecutorsAndObserversChangeError ||
      startFinishDatesChangeError ||
      moveTasksToBoardError ||
      remindChangeError ||
      deleteTasksError ||
      statusesChangeError ||
      unPinError
  );

  const changeExecuterView = useViewModal();
  const changeCoExecutersAndObserversView = useViewModal();
  const changeStartFinishDateView = useViewModal();
  const addTaskToBoardView = useViewModal();
  const remindView = useViewModal();

  const openSuccessSnackbar = useCallback(
    (message: string) => {
      openSnackbar({
        duration: 3000,
        type: 'success',
        title: message,
      });
    },
    [openSnackbar]
  );

  const getAdditionalActions = useCallback(() => {
    return [
      {
        icon: <Person />,
        onClick: changeExecuterView.open,
        title: 'Изменить исполнителя',
      },
      {
        icon: <PersonAdd />,
        onClick: changeCoExecutersAndObserversView.open,
        title: 'Изменить соисполнителя/наблюдателя',
      },
      {
        icon: <DateRange />,
        onClick: changeStartFinishDateView.open,
        title: 'Изменить дату начала/завершения',
      },
      {
        icon: <Dashboard />,
        onClick: addTaskToBoardView.open,
        title: 'Перенести на доску',
      },
      {
        icon: <NotificationAdd />,
        onClick: remindView.open,
        title: 'Напоминания',
      },
    ];
  }, [
    changeExecuterView.open,
    changeCoExecutersAndObserversView.open,
    changeStartFinishDateView.open,
    addTaskToBoardView.open,
    remindView.open,
  ]);

  const getFastActions = useCallback(() => {
    return [
      {
        icon: <Delete />,
        onClick: () => {
          openConfirm({
            actionTitle: 'Удалить задачи',
            submitButtonTitle: 'Удалить',
            actionBody: (
              <Typography color={'GrayText'}>
                Все задачи будут безвозвратно удалены
              </Typography>
            ),
            buttonColor: 'error',
            onSubmit: async () => {
              await deleteTasks(taskGuids).then((res) => {
                if ('error' in res) return;
                unselectAll?.();
                openSuccessSnackbar('Задачи удалены');
              });
            },
          });
        },
        title: 'Удалить',
      },
      {
        icon: <CheckCircle />,
        onClick: () => {
          changeStatuses(taskGuids).then((res) => {
            if ('error' in res) return;
            unselectAll?.();
            openSuccessSnackbar('Задачи завершены');
          });
        },
        title: 'Завершить задачи',
      },
      {
        icon: <DeveloperBoardOff />,
        title: 'Открепить от доски',
        onClick: () => {
          unPin({ tasksGuids: taskGuids })
            .unwrap()
            .then(() => {
              unselectAll?.();
              openSuccessSnackbar('Задачи задачи откреплены');
            });
        },
      },
    ];
  }, [taskGuids]);

  return (
    <>
      <ChangeExecuterDialog
        isOpen={changeExecuterView.isOpen}
        onClose={changeExecuterView.close}
        isLoading={isLoadingChangeExecutors}
        onSubmit={(form) => {
          changeExecutors({
            executorGuid: form.executer.guid,
            tasksGuids: taskGuids,
          }).then((res) => {
            if ('error' in res) return;
            changeExecuterView.close();
            unselectAll?.();
            openSuccessSnackbar('Исполнители изменены');
          });
        }}
      />
      <ChangeCoExecutersAndObserversDialog
        isOpen={changeCoExecutersAndObserversView.isOpen}
        onClose={changeCoExecutersAndObserversView.close}
        isLoading={isLoadingChangeCoExecutersAnsObservers}
        onSubmit={(form) => {
          changeCoExecutersAnsObservers({
            isCoExecutorsChanged: form.isCoExecutorsChanged,
            isObserversChanged: form.isObserversChanged,
            tasksGuids: taskGuids,
            coExecutorsGuids: form.coExecuter?.map((el) => el.guid),
            observersGuids: form.observers?.map((el) => el.guid),
          }).then((res) => {
            if ('error' in res) return;
            changeCoExecutersAndObserversView.close();
            unselectAll?.();
            openSuccessSnackbar('Соисполнители/наблюдатели изменены');
          });
        }}
      />
      <ChangeStartFinishDateDialog
        isOpen={changeStartFinishDateView.isOpen}
        onClose={changeStartFinishDateView.close}
        isLoading={isLoadingChangeStartFinishDates}
        onSubmit={(form) => {
          changeStartFinishDates({
            dateFinish: form.finisDate.format(DATE_FORMATS.server),
            dateStart: form.startDate?.format(DATE_FORMATS.server),
            tasksGuids: taskGuids,
            isDateStartChanged: !!form.isDateStartChanged,
          }).then((res) => {
            if ('error' in res) return;
            changeStartFinishDateView.close();
            unselectAll?.();
            openSuccessSnackbar('Даты изменены');
          });
        }}
      />
      <AddTaskToBoardDialog
        isOpen={addTaskToBoardView.isOpen}
        onClose={addTaskToBoardView.close}
        isLoading={isLoadingMoveTasksToBoard}
        onSubmit={(form) => {
          moveTasksToBoard({
            tasksGuids: taskGuids,
            boardColumnGuid: form.columnGuid,
          }).then((res) => {
            if ('error' in res) return;
            addTaskToBoardView.close();
            unselectAll?.();
            openSuccessSnackbar('Задачи перемещены на доску');
          });
        }}
      />
      <RemindeDialog
        isOpen={remindView.isOpen}
        onClose={remindView.close}
        isLoading={isLoadingChangeRemind}
        onSubmit={(form) => {
          changeRemind({
            ...prepareRemideFormDataToFetch(form),
            tasksGuids: taskGuids,
          }).then((res) => {
            if ('error' in res) return;
            remindView.close();
            unselectAll?.();
            openSuccessSnackbar('Напоминания установлены');
          });
        }}
        taskInfo={{
          coExecutor: true,
          creator: true,
          executor: true,
          observer: true,
        }}
        initValue={{
          coExecutor: {
            isReminde: false,
            isCustom: false,
            ticks: null,
          },
          creator: {
            isReminde: false,
            isCustom: false,
            ticks: null,
          },
          executor: {
            isReminde: false,
            isCustom: false,
            ticks: null,
          },
          observer: {
            isReminde: false,
            isCustom: false,
            ticks: null,
          },
        }}
      />

      <BoardAppActionBar
        isOpen={taskGuids.length > 0}
        count={taskGuids.length}
        onClose={unselectAll}
        getAdditionalActions={getAdditionalActions}
        getFastActions={getFastActions}
      />
    </>
  );
};
