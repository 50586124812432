// TODO: МБ вынести в shared utils
export const getNextId = <T extends { id: number }>(
  saveFilter: T[]
): number => {
  if (saveFilter.length === 0) return 1;

  return (
    saveFilter.reduce((prev, cure) => {
      if (prev < cure.id) return cure.id;
      else return prev;
    }, saveFilter[0].id) + 1
  );
};
