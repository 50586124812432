import { useCallback } from 'react';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { checkIsNotEmptyArray } from 'shared/utils/checkIsNotEmptyArray';
import { createDownloadDomEventsForBase64File } from 'shared/utils/createDownloadDomEventsForBase64File';
import { useLazyGetFilesQuery } from '../filesProviderApi';

export const useDownloadFileFromServiceFile = () => {
  const { open: openSnackbar } = useSnackbar();
  const [fetchFile, ...other] = useLazyGetFilesQuery();

  const downloadFile = useCallback(
    async (params: { path: string; fileName?: string }) => {
      const { path, fileName } = params;

      const { data: response } = await fetchFile({
        path: [path],
      }).then((response) => {
        if ('error' in response) {
          openSnackbar({
            type: 'error',
            title: 'Упс :(',
            text: 'Что то пошло не так',
            duration: 3000,
          });

          return { ...response };
        }

        return { ...response };
      });

      if (!checkIsNotEmptyArray(response)) {
        console.error('response file - undefined');
        openSnackbar({
          type: 'error',
          title: 'Упс :(',
          text: 'В ответе от сервера пришел - пустой массив или null. Возможно файл удален.',
          duration: 3000,
        });

        return null;
      }

      const { data, path: pathResponse } = response[0];

      if (!pathResponse || !data) {
        console.error(`pathResponse - ${pathResponse}`);
        console.error(`data - ${data}`);
        openSnackbar({
          type: 'error',
          title: 'Упс :(',
          text: `pathResponse = ${pathResponse} , data = ${Boolean(data)}`,
          duration: 3000,
        });

        return;
      }

      const arr = pathResponse.split('/');
      const fileNameAndType = arr.pop();
      const fileType = fileNameAndType?.split('.')[1];

      if (!fileType || !data) {
        console.error(`pathResponse - ${pathResponse}`);
        console.error(`data - ${data}`);
        openSnackbar({
          type: 'error',
          title: 'Упс :(',
          text: `fileType = ${fileType} , fileNameAndType = ${fileNameAndType}`,
          duration: 3000,
        });

        return;
      }

      const events = createDownloadDomEventsForBase64File({
        data,
        fileName: fileName || fileNameAndType,
        fileType,
      });

      events.download();
    },
    [fetchFile]
  );

  return [downloadFile, ...other] as const;
};
