import {
  useGetDocumentFlowArticlesQuery,
  useGetDocumentFlowCounterpartyQuery,
  useGetDocumentFlowEmployeeAllQuery,
  useGetDocumentFlowOrganizationsQuery,
  useGetDocumentFlowProcessListQuery,
  useGetDocumentFlowStatusesQuery,
} from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';

type keysOptions =
  | 'optionsStatus'
  | 'optionsOrganization'
  | 'optionsArticle'
  | 'optionsCounterparty'
  | 'optionsEmployeeAll'
  | 'optionsDocumentType';

export const useGetDocumentFlowOptionsOptionsBaseFilterForm = (
  skips: { [key in keysOptions]?: boolean } | undefined
) => {
  const { data: optionsStatus, isLoading: isLoadingOptionsStatus } =
    useGetDocumentFlowStatusesQuery(undefined, { skip: skips?.optionsStatus });

  const { data: optionsOrganization, isLoading: isLoadingOptionsOrganization } =
    useGetDocumentFlowOrganizationsQuery(undefined, {
      skip: skips?.optionsOrganization,
    });

  const { data: optionsArticle, isLoading: isLoadingOptionsArticle } =
    useGetDocumentFlowArticlesQuery(undefined, { skip: skips?.optionsArticle });

  const { data: optionsCounterparty, isLoading: isLoadingOptionsCounterparty } =
    useGetDocumentFlowCounterpartyQuery(undefined, {
      skip: skips?.optionsCounterparty,
    });

  const { data: optionsEmployeeAll, isLoading: isLoadingOptionsEmployeeAll } =
    useGetDocumentFlowEmployeeAllQuery(undefined, {
      skip: skips?.optionsEmployeeAll,
    });

  const { data: optionsDocumentType, isLoading: isLoadingOptionsDocumentType } =
    useGetDocumentFlowProcessListQuery(undefined, {
      skip: skips?.optionsDocumentType,
    });

  const isLoadingAnyOptions =
    isLoadingOptionsStatus ||
    isLoadingOptionsOrganization ||
    isLoadingOptionsCounterparty ||
    isLoadingOptionsEmployeeAll ||
    isLoadingOptionsDocumentType ||
    isLoadingOptionsArticle;

  return {
    optionsStatus,
    optionsOrganization,
    optionsArticle,
    optionsCounterparty,
    optionsEmployeeAll,
    optionsDocumentType,
    isLoadingOptionsStatus,
    isLoadingOptionsOrganization,
    isLoadingOptionsArticle,
    isLoadingOptionsCounterparty,
    isLoadingOptionsEmployeeAll,
    isLoadingOptionsDocumentType,
    isLoadingAnyOptions,
  };
};
