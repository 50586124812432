// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { SwaggerResponse } from 'shared/api/services-document-flow/config';
import {
  getProcessesProcessId,
  getProcessesProcessIdAdditionallyFields,
  postDocuments,
  postDocumentsDocumentIdDoAction,
  postDocumentsDocumentIdDocumentComments,
  postDocumentsDocumentIdFilesCloseDocuments,
  postDocumentsDocumentIdFilesReasonDocuments,
  postDocumentsDraft,
  postDocumentsFirstStageValidate,
  postDocumentsSecondStageValidate,
  putDocumentsDocumentId,
} from 'shared/api/services-document-flow/services';
import {
  DocumentCommentCreateRequest,
  DocumentReasonDocumentFileModel,
  DocumetnDoActionRequest,
  ErrorResponse,
} from 'shared/api/services-document-flow/types';

export const fetchProcessIdFx = createEffect<
  typeof getProcessesProcessId,
  AxiosError<ErrorResponse>
>(getProcessesProcessId);

/* first validation */
export const firstValidationFx = createEffect<
  typeof postDocumentsFirstStageValidate,
  AxiosError<ErrorResponse>
>(postDocumentsFirstStageValidate);
/* secondary validation */
export const secondaryValidationFx = createEffect<
  typeof postDocumentsSecondStageValidate,
  AxiosError<ErrorResponse>
>(postDocumentsSecondStageValidate);

export const getProcessesProcessIdAdditionallyFieldsFx = createEffect<
  typeof getProcessesProcessIdAdditionallyFields,
  AxiosError<ErrorResponse>
>(getProcessesProcessIdAdditionallyFields);
export const postDocumentsFx = createEffect<
  typeof postDocuments,
  AxiosError<ErrorResponse>
>(postDocuments);
export const postDocumentsSendFx = createEffect<
  { documentId: number; requestBody: DocumetnDoActionRequest },
  SwaggerResponse<any>,
  AxiosError<ErrorResponse>
>(
  async (params: {
    documentId: number;
    requestBody: DocumetnDoActionRequest;
  }) => {
    return postDocumentsDocumentIdDoAction(
      params.documentId,
      params.requestBody
    );
  }
);
export const postDocumentsDraftFx = createEffect<
  typeof postDocumentsDraft,
  AxiosError<ErrorResponse>
>(postDocumentsDraft);
export const putDocumentsDraftFx = createEffect<
  typeof putDocumentsDocumentId,
  AxiosError<ErrorResponse>
>(putDocumentsDocumentId);

interface typePostCommentDocumentFormModelFx {
  documentId: number;
  comment: DocumentCommentCreateRequest;
}
export const postCommentDocumentFormModelFx = createEffect({
  async handler(params: typePostCommentDocumentFormModelFx) {
    return postDocumentsDocumentIdDocumentComments(
      params.documentId,
      params.comment
    );
  },
});

/* FILES */

export const postDocumentsDocumentIdFilesReasonDocumentsFx = createEffect<
  { id: number; file: File },
  SwaggerResponse<DocumentReasonDocumentFileModel>,
  AxiosError<ErrorResponse>
>({
  handler: async (params) => {
    const formFile = new FormData();

    formFile.append('formFile', params.file);

    return postDocumentsDocumentIdFilesReasonDocuments(params.id, {
      /* @ts-ignore */
      formFile: params.file,
    });
  },
});

export const postAllDocumentsDocumentIdFilesReasonDocumentsFx = createEffect({
  async handler(params: { id: number; files: File[] }) {
    return await Promise.all(
      params.files.map((file) => {
        return postDocumentsDocumentIdFilesReasonDocumentsFx({
          id: params.id,
          file,
        });
      })
    );
  },
});
/* Close Files */
export const postDocumentsDocumentIdFilesCloseDocumentsFx = createEffect<
  { id: number; file: File },
  SwaggerResponse<DocumentReasonDocumentFileModel>,
  AxiosError<ErrorResponse>
>({
  handler: async (params) => {
    const formFile = new FormData();

    formFile.append('formFileClose', params.file);

    return postDocumentsDocumentIdFilesCloseDocuments(params.id, {
      /* @ts-ignore */
      formFile: params.file,
    });
  },
});

export const postAllDocumentsDocumentIdFilesCloseDocumentsFx = createEffect({
  async handler(params: { id: number; files: File[] }) {
    return await Promise.all(
      params.files.map((file) => {
        return postDocumentsDocumentIdFilesCloseDocumentsFx({
          id: params.id,
          file,
        });
      })
    );
  },
});
