import { Delete, Edit } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { useConfirmDeleteOpeningTemplateTask } from 'features/retail-opeting-process/delete-opening-template-task';
import { DialogFormTemplateTask } from 'features/retail-opeting-process/form-template-task';
import { useDialogCreateTemplateTask } from 'features/retail-opeting-process/form-template-task/libs';
import { FC } from 'react';
import { RetailOpeningProcessResponsesOpeningTemplateGetListStageTask } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';

import { theme } from 'shared/theme/theme';
import { DialogConfirmForm } from 'shared/ui/form';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';

export const ActionsOpeningTemplateTask: FC<{
  templateTask: RetailOpeningProcessResponsesOpeningTemplateGetListStageTask;
  templateGuid: string;
  stageGuid: string;
  countdownFieldTitle: string;
}> = ({ templateTask, templateGuid, stageGuid, countdownFieldTitle }) => {
  const {
    isLoadingSumbiteTask,
    isOpenDialogFormTemplateTask,
    onCloseDialogFormTemplateTask,
    onOpenDialogFormTemplateTask,
    onSubmitFormCreateTemplateTask,
    isLoadingInitialValue,
    initialValue,
  } = useDialogCreateTemplateTask(templateGuid, stageGuid, templateTask.guid);

  const {
    onCancelConfirmation,
    onConfirmDelete,
    onOpenDialogConfirm,
    isOpenConfirmDialog,
    isLoadingDeleteTemplate,
  } = useConfirmDeleteOpeningTemplateTask({
    stageTaskGuids: [templateTask.guid],
    templateGuid,
    stageGuid,
  });

  return (
    <UiTableActions>
      <UiButtonAction
        text='Изменить'
        onClick={onOpenDialogFormTemplateTask}
        color={theme.palette.customBlue.main}
        icon={<Edit />}
      />

      <UiButtonAction
        text='Удалить'
        onClick={onOpenDialogConfirm}
        icon={<Delete />}
        color={theme.palette.error.main}
      />
      <DialogFormTemplateTask
        isOpen={isOpenDialogFormTemplateTask}
        onClose={onCloseDialogFormTemplateTask}
        onSubmit={onSubmitFormCreateTemplateTask}
        isLoadingSubmit={isLoadingSumbiteTask}
        isLoadingInitialValue={isLoadingInitialValue}
        initialValue={initialValue}
        countdownFieldTitle={countdownFieldTitle}
      />
      <DialogConfirmForm
        title='Удалить шаблон задачи'
        open={isOpenConfirmDialog}
        onClose={onCancelConfirmation}
        isLoading={isLoadingDeleteTemplate}
        onConfirmation={onConfirmDelete}
      >
        <Typography>{`Вы уверены что хотите удалить шаблон задачи - ${templateTask.title}?`}</Typography>
      </DialogConfirmForm>
    </UiTableActions>
  );
};
