import { Typography } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

export const UiArticleTitle: FC<{
  title: string;
  variant: 'expenses' | 'not-expenses';
}> = ({ title, variant }) => {
  return (
    <Typography
      color={theme.palette.customGrey.dark}
      fontWeight={700}
      marginBottom={'20px'}
      marginTop={'20px'}
      sx={{ flexBasis: variant === 'not-expenses' ? ['30%'] : [] }}
    >
      {title}
    </Typography>
  );
};
