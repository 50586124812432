import { Routes } from '../constants';

type GetFullPathRouteFun = (
  routes: Routes[],
  fullPathParant: string
) => Routes[];

export const getFullPathRoute: GetFullPathRouteFun = (
  routes,
  fullPathParant
) => {
  const ans = routes.map((route) => {
    let children: Routes[] | null = null;

    if (route.children) {
      children = getFullPathRoute(
        route.children,
        fullPathParant === '' ? route.path : fullPathParant + '/' + route.path
      );
    }

    return {
      ...route,
      fullPath: !route.isOtherResource
        ? fullPathParant + '/' + route.path
        : route.path,
      children,
    };
  });

  return ans;
};
