import { TableCell, TableHead, TableRow } from '@mui/material';
import { memo } from 'react';

import { TABLE_HEAD_RAWS } from './constant';

export const PlanningRNTableHead = memo(() => {
  return (
    <TableHead>
      {TABLE_HEAD_RAWS.map((row, i) => (
        <TableRow
          key={i}
          sx={{ position: 'relative' }}
        >
          {row.map((cell) => (
            <TableCell
              key={cell.id}
              {...cell.cellProps}
            >
              {cell.renderCell()}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
});
