import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useDeleteTaskMutation } from 'shared/api/services/rtk/taskApi';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useDeleteTask = (callback?: () => unknown) => {
  const { open: openSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [deleteTaskQ, { error }] = useDeleteTaskMutation();

  useShowFetchErrorInSnackbar(error);

  const deleteTask = useCallback((taskGuid: string) => {
    confirm({
      actionTitle: 'Удалить задачу',
      onSubmit: async () => {
        await deleteTaskQ(taskGuid).then(() => {
          callback?.();
          openSnackbar({
            text: <strong>Задача удалена</strong>,
            type: 'success',
            duration: 3000,
          });
        });
      },
      actionBody: (
        <Typography color={'GrayText'}>
          Задача будет удалена без возможности восстановления.
        </Typography>
      ),
      submitButtonTitle: 'Удалить',
      buttonColor: 'error',
    });
  }, []);

  return deleteTask;
};
