import { Delete } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { useStore } from 'effector-react';
import { FC } from 'react';

import { InputFileUi } from 'shared/ui/file-input-ui-DEPRECETED/FileInputUi';
import { UiDocumentFormFiles } from 'shared/ui/UiDocumentFormFiles';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormReasoneFiles: FC = () => {
  const reasonFiles = useStore(documentFormModel.formFiles);
  const isReasonDocumentRequired = documentFormModel.useValueProcess(
    'isReasonDocumentRequired'
  );

  if (!isReasonDocumentRequired) return null;

  return (
    <UiDocumentFormFiles
      Input={
        <InputFileUi
          onAddFile={documentFormModel.formFilesEvent.addFile}
          disabled={!isReasonDocumentRequired}
          required={isReasonDocumentRequired && reasonFiles.length === 0}
        />
      }
      List={
        <>
          {reasonFiles.map((file) => {
            return (
              <Typography
                sx={{
                  display: 'flex',
                  flexBasis: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                key={file.name + file.size}
              >
                {file.name}
                <IconButton
                  color='error'
                  onClick={() =>
                    documentFormModel.formFilesEvent.deleteFile(file)
                  }
                >
                  <Delete />
                </IconButton>
              </Typography>
            );
          })}
          {reasonFiles.length === 0 && (
            <Typography sx={{ display: ['none', 'flex'] }}>
              Наименование прикрепленного файла...
            </Typography>
          )}
        </>
      }
    />
  );
};
