// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { GetDocumentsControlQueryParams } from 'shared/api/services-document-flow/types';

export const defaultFilter: GetDocumentsControlQueryParams = {
  PageSize: 20,
  StatusIdFilters: [],
  SearchText: '',
  CurrentPage: 1,
};
