import { Skeleton } from '@mui/material';
import { FC } from 'react';
import { NavigationCardBaseUi } from './NavigationCardBaseUi';
import { NavigationCardPropsBaseUi } from './types';

export const NavigationCardSceleton: FC<NavigationCardPropsBaseUi> = ({
  Icon,
  sx,
}) => {
  return (
    <NavigationCardBaseUi
      Icon={Icon}
      sx={sx}
    >
      <Skeleton
        variant='rounded'
        height={'1.5rem'}
        sx={{ display: 'flex', flexGrow: 1 }}
      />
    </NavigationCardBaseUi>
  );
};
