import { Dayjs } from 'dayjs';
import {
  AdaptationManagerResponsesGetListAdaptation,
  AdaptationManagerResponsesGetListEmployee,
  AdaptationManagerResponsesGetListStage,
} from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { mixed, object } from 'yup';

export const VALIDATE_SCHEMA_PREPARATORY_RUN_ADAPTATION_PROCESS =
  object().shape({
    mentor: mixed<AdaptationManagerResponsesGetListEmployee>()
      .nullable()
      .test('mentor', 'Обязательное поле', (option) => Boolean(option)),
    adaptation: mixed<AdaptationManagerResponsesGetListAdaptation>()
      .nullable()
      .test('adaptation', 'Обязательное поле', (option) => Boolean(option)),
    skipStages: mixed<AdaptationManagerResponsesGetListStage[]>().required(),
    countdownDate: mixed<Dayjs>().nullable(),
  });
