import { NavButtons } from 'features/visit/NavButtons';
import {
  BasicVisitInfoForm,
  BasicVisitInfoFormSkeleton,
  prepareDataToInitStage1,
} from 'features/visit/Stage 1';
import { useMemo } from 'react';

import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';

import { useChecklist } from 'entities/visit/checkList/context/ChecklistContext';
import { useGetVisit } from 'entities/visit/visit/hooks/useGetVisit';

import { STAGES } from './VisitLayout';

export const Stage1Page = () => {
  const { stageNavigate, checkList, isLoading, status } = useChecklist();

  const {
    visit,
    isLoading: isLoadingVisit,
    status: visitStatus,
  } = useGetVisit(checkList?.visitingId || null);

  const onSubmit = () => {
    if (checkList) {
      const nextStage = checkList.isCompetitorComparison
        ? STAGES.stage2
        : STAGES.stage4;

      stageNavigate(nextStage);
    }
  };

  const initVal = useMemo(() => {
    if (visit) return prepareDataToInitStage1(visit);
    else return undefined;
  }, [visit]);

  if (isLoading || isLoadingVisit) return <BasicVisitInfoFormSkeleton />;
  if (visitStatus === 400) return <h1>Такого посещения нет</h1>;
  if (status === 400) return <h1>Такого чеклиста нет</h1>;
  if (visit && checkList)
    return (
      <BasicVisitInfoForm
        onSubmit={onSubmit}
        onDraftSubmit={onSubmit}
        initValue={initVal}
        disableAll
        footer={
          <NavButtons
            // disable
            isLoading={isLoading}
          />
        }
        header={
          <DocumentSectionTitle
            text='Основная информация'
            sx={{ marginTop: 0, margin: 0 }}
          />
        }
      />
    );
  else return <h1>Что-то пошло не так</h1>;
};
