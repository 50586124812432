import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { theme } from 'shared/theme/theme';
import {
  SwipeableDrawerImagesListUiWithContext,
  useContextOnOpenImagesDrawerAndSelectSurfaceGuid,
} from './actions/view-list-images';
import { MarketingSurfaceList } from './MarketingSurfaceList';
import { MarketingSurfaceTable } from './MarketingSurfaceTable';
import { MarketingSurfaceTableProps } from './types';

export const MarketingSurfaceAdaptiveTable: FC<MarketingSurfaceTableProps> = (
  props
) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onOpenImagesDrawerAndSelectSurfaceGuid =
    useContextOnOpenImagesDrawerAndSelectSurfaceGuid();
  /*   if (isMobile) return <MarketingSurfaceList {...props} />;
  else return <MarketingSurfaceTable {...props} />; */

  return (
    <>
      {isMobile ? (
        <MarketingSurfaceList
          {...props}
          onClick={(surface) =>
            surface.guid && onOpenImagesDrawerAndSelectSurfaceGuid(surface.guid)
          }
        />
      ) : (
        <MarketingSurfaceTable
          {...props}
          onClick={(surface) =>
            surface.guid && onOpenImagesDrawerAndSelectSurfaceGuid(surface.guid)
          }
        />
      )}
      <SwipeableDrawerImagesListUiWithContext />
    </>
  );
};
