import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Tooltip, Typography, useTheme } from '@mui/material';

import { FC } from 'react';
import { TextWithEndIconTooltipProps } from './types';

export const TextWithEndIconTooltip: FC<TextWithEndIconTooltipProps> = ({
  text,
  tooltipTitle,
  typographyProps,
}) => {
  const theme = useTheme();

  return (
    <Tooltip
      arrow
      title={tooltipTitle}
      placement='right'
      PopperProps={{
        sx: {
          '.MuiTooltip-tooltip': {
            backgroundColor: theme.palette.customGrey.main,
            color: theme.palette.customGrey.contrastText,
          },
          '.MuiTooltip-arrow': {
            color: theme.palette.customGrey.main,
          },
        },
      }}
      sx={{ width: 'fit-content' }}
    >
      <Grid
        display={'flex'}
        gap={1}
        alignItems={'center'}
      >
        <Typography
          fontWeight={'bold'}
          {...typographyProps}
        >
          {text}
        </Typography>
        <HelpOutlineIcon
          sx={{ color: theme.palette.customGrey.contrastText }}
          fontSize='small'
        />
      </Grid>
    </Tooltip>
  );
};
