import {
  GetListTask,
  GetTasksMeExecutorQueryParams,
} from 'shared/api/services/types';
import { OrderFilter } from 'shared/ui/table/types/order-props';

export const orderToTaskFilter = (
  order: OrderFilter<GetListTask>
): Pick<GetTasksMeExecutorQueryParams, 'OrderBy'> => {
  if (order.order && order.orderBy) {
    return {
      OrderBy: `${order.orderBy} ${order.order}`,
    };
  } else
    return {
      OrderBy: undefined,
    };
};

export const taskFilterToOrder = (
  filter: GetTasksMeExecutorQueryParams | undefined
): OrderFilter<GetListTask> => {
  if (filter && filter.OrderBy) {
    const [orderBy, order] = filter.OrderBy.split(' ') as [
      OrderFilter<GetListTask>['orderBy'],
      OrderFilter<GetListTask>['order'],
    ];

    return {
      order,
      orderBy,
    };
  } else {
    return {
      order: null,
      orderBy: null,
    };
  }
};
