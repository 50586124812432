export type URLToObjConverter<T> = (param: URLSearchParams) => T;

export type URLToObjConverterByMap = (
  searchParams: URLSearchParams,
  convertMap: ConvertMap
) => unknown;

export enum ConvertTypes {
  string = 'string',
  number = 'number',
  masNumber = 'masNumber',
  masString = 'masString',
  boolean = 'boolean',
}

export type ConvertMap = {
  key: string;
  type: ConvertTypes;
}[];

export type ConvertMapWithType<T> = {
  key: keyof T;
  type: ConvertTypes;
}[];

/* useUrlState */
export type UseURLStateReturn<T> = readonly [
  T | undefined,
  (arg: T | undefined) => void,
];

export type UseURLStateFun = <T>(
  URLToObjConvertor: URLToObjConverter<T>,
  isTestUpdateForBrowserGoBack?: boolean
) => UseURLStateReturn<T>;
/* ===== */
