import { CurrentAdaptationProcessesSearchBar } from 'features/adaptation/CurrentAdaptationProcessesSearchBar';

import { useAdaptationProcessesFilterConverter } from 'features/adaptation/hooks/useAdaptationProcessesFilterConverter';
import { useAdaptationProcessesPage } from 'features/adaptation/hooks/useAdaptationProcessesPage';
import { FC } from 'react';
import {
  AdaptationProcessListItem,
  useGetCurrentProcessesQuery,
} from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';

import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';

import { Tables } from 'shared/utils/controllers';
import { AdaptiveCurrentAdaptationProcessesTable } from 'widgets/adaptation/CurrentAdaptationProcessesTable/AdaptiveCurrentAdaptationProcessesTable';
import { AdaptationProcessTableType } from 'widgets/adaptation/CurrentAdaptationProcessesTable/type';

interface AdaptationProcessesPageProps {
  useGetProcesses: typeof useGetCurrentProcessesQuery;
  renderActions: AdaptationProcessTableType['renderActions'];
}
export const AdaptationProcessesPage: FC<AdaptationProcessesPageProps> = ({
  useGetProcesses,
  renderActions,
}) => {
  const {
    convertFilterToForm,
    convertFormToFilter,
    isLoading: isLoadingInit,
  } = useAdaptationProcessesFilterConverter();

  const {
    handleChangeLimit,
    handleChangePage,
    handleClearSearch,
    handleSearch,
    handleChangeOrder,
    tableOrder,
    handleChangeFilter,
    ...filter
  } = useAdaptationProcessesPage();

  const { Limit, Page, SearchText } = filter;

  const { data: processes, isLoading, isFetching } = useGetProcesses(filter);

  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<AdaptationProcessListItem>(
      Tables.ADAPTATION_PROCESSES_TABLE
    );

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <CurrentAdaptationProcessesSearchBar
          onClear={handleClearSearch}
          onSearch={handleSearch}
          initValue={SearchText}
          filter={convertFilterToForm(filter)}
          isLoading={isLoadingInit}
          onChangeFilter={(form) => {
            handleChangeFilter(convertFormToFilter(form));
            //
          }}
        />
      </LayoutPage.Bar>

      <LayoutPage.Content>
        <AdaptiveCurrentAdaptationProcessesTable
          count={processes?.count || 0}
          data={processes?.items || []}
          fields={fields}
          limit={Limit}
          page={Page}
          onChangeFields={setFields}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
          order={tableOrder}
          onChangeOrder={handleChangeOrder}
          renderActions={renderActions}
          visibleFields={visibleFields}
          isLoading={isLoading || isLoading}
          isUpdate={isFetching}
          // onClick={}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
