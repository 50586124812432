import { Grid, Typography } from '@mui/material';
import { TARGET_OPTIONS } from 'features/task/task-filter-form/constants';
import { changeStatus } from 'features/task/task-filter-form/libs/changeStatus';
import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useGetAllEmployeesQuery,
  useGetOriginsQuery,
  useLazyGetStatusesQuery,
} from 'shared/api/services/rtk/optionApi';
import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import {
  CustomAutocomplete,
  CustomDatePicker,
  CustomToggleButtonGroup,
} from 'shared/ui/base-ui';
import { FormLayout } from 'shared/ui/form';
import { INIT_BOARD_TASK_FILTER } from './constants';
import { BoardTaskFilterFormFields, BoardTaskFilterFormProps } from './type';

export const BoardTaskFilterForm: FC<BoardTaskFilterFormProps> = ({
  onSubmit,
  renderFormActions,
  initForm,
}) => {
  const [fetchStatuses] = useLazyGetStatusesQuery();
  const { data: employees, isLoading: isLoadingEmployee } =
    useGetAllEmployeesQuery();
  const { isLoading: loadingOrigins, data: originOptions } =
    useGetOriginsQuery();

  const clearInitValue = useMemo(async () => {
    const { data: statuss } = await fetchStatuses();

    return {
      ...INIT_BOARD_TASK_FILTER,
      statuses: statuss?.map((el) => ({ ...el, selected: false })) || [],
    };
  }, []);

  const { handleSubmit, control } = useForm<BoardTaskFilterFormFields>({
    defaultValues: initForm
      ? initForm
      : async () => {
          return await clearInitValue;
        },
  });

  return (
    <FormLayout
      onSubmit={handleSubmit(onSubmit)}
      actions={renderFormActions(handleSubmit)}
    >
      <Controller
        control={control}
        name='statuses'
        render={({ field: { value, onChange } }) => (
          <>
            {value && (
              <CustomToggleButtonGroup
                label='Статус'
                value={value}
                getLable={(val) => val.title || ''}
                getSelected={(val) => val.selected}
                onChange={(newStatus) => {
                  onChange(changeStatus(value, newStatus));
                }}
                containerProps={{ px: [1, 0], pt: [1, 0] }}
              />
            )}
          </>
        )}
      />

      <Controller
        control={control}
        name='isTarget'
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            options={TARGET_OPTIONS}
            textFieldProps={{
              label: 'Задачи',
              placeholder: 'Выберите фильтр',
            }}
            value={TARGET_OPTIONS.find((el) => el.value === value) || null}
            noOptionsText='Все'
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(val, eq) => val.id === eq.id}
            onChange={(_, val) =>
              onChange(!val || val.value === undefined ? null : val.value)
            }
            renderOption={(props, option) => (
              <li
                {...props}
                key={option.id}
              >
                {option.title}
              </li>
            )}
          />
        )}
      />

      <Controller
        control={control}
        name='origins'
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Источник',
              placeholder: 'Выберите источники',
            }}
            multiple
            loading={loadingOrigins}
            loadingText={'Загрузка...'}
            noOptionsText='Нет совпадений'
            onChange={(_, value) => onChange(value)}
            value={value || []}
            isOptionEqualToValue={(val, eq) => val.id === eq.id}
            options={originOptions || []}
            getOptionLabel={(option) => `${option?.title}`}
            renderOption={(props, option) => (
              <li
                {...props}
                key={`${option.id} ${option.title}`}
              >{`${option?.title}`}</li>
            )}
          />
        )}
      />

      <Controller
        control={control}
        name='executors'
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            multiple
            textFieldProps={{
              label: 'Исполнитель',
              placeholder: 'Выберите исполнителей',
            }}
            getOptionLabel={(val) =>
              getEmployeTitle({
                employeeName: val.name,
                employeePosition: val.position?.title,
              })
            }
            renderOption={(props, val) => (
              <Typography
                {...props}
                key={val.guid}
              >
                {getEmployeTitle({
                  employeeName: val.name,
                  employeePosition: val.position?.title,
                })}
              </Typography>
            )}
            isOptionEqualToValue={(val, eq) => val.guid === eq.guid}
            options={employees || []}
            value={value}
            onChange={(_, val) => {
              onChange(val);
            }}
          />
        )}
      />

      <Controller
        control={control}
        name='creators'
        render={({ field: { onChange, value } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Постановщик',
              placeholder: 'Выберите постановщиков',
            }}
            loading={isLoadingEmployee}
            loadingText={'Загрузка...'}
            isOptionEqualToValue={(val, eq) => val.guid === eq.guid}
            multiple
            noOptionsText='Нет совпадений'
            options={employees || []}
            getOptionLabel={(option) =>
              `${option?.name}, ${option?.position?.title}`
            }
            onChange={(_, val) => onChange(val)}
            value={value || []}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option.guid}
              >{`${option?.name},  ${option?.position?.title} `}</li>
            )}
          />
        )}
      />

      <Controller
        control={control}
        name='coExecutors'
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Соисполнитель',
              placeholder: 'Выберите соисполнителей',
            }}
            loading={isLoadingEmployee}
            loadingText={'Загрузка...'}
            value={value || []}
            multiple
            isOptionEqualToValue={(val, eq) => val.guid === eq.guid}
            noOptionsText='Нет совпадений'
            options={employees || []}
            getOptionLabel={(option) =>
              `${option?.name}, ${option?.position?.title}`
            }
            onChange={(_, val) => onChange(val)}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option.guid}
              >{`${option?.name},  ${option?.position?.title} `}</li>
            )}
          />
        )}
      />

      <Grid
        container
        flexDirection={['column', 'row']}
        justifyContent={'space-between'}
        flexWrap={'nowrap'}
        gap={1}
      >
        {/* ================== */}
        <Controller
          control={control}
          name='startDate'
          render={({ field: { value, onChange } }) => (
            <CustomDatePicker
              sx={{ width: '100%' }}
              value={value}
              onChange={onChange}
              label='Дата начала'
              slotProps={{ textField: { placeholder: 'Введите дату' } }}
            />
          )}
        />

        {/* ================== */}

        {/* ================== */}
        <Controller
          control={control}
          name='finishDate'
          render={({ field: { onChange, value } }) => (
            <CustomDatePicker
              sx={{ width: '100%' }}
              value={value}
              onChange={onChange}
              label='Дата завершения'
              slotProps={{ textField: { placeholder: 'Введите дату' } }}
            />
          )}
        />
        {/* ================== */}
      </Grid>
    </FormLayout>
  );
};
