// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { createEvent, createStore, sample } from 'effector';

import { DocumetnDoActionRequest } from 'shared/api/services-document-flow/types';

import { postDocumentsDocumentIdDoActionFx } from '../api/document-actions';

const $documentActionReturnDcumentCommentModal = createStore<{
  documentId: null | number;
  comment: string;
}>({
  documentId: null,
  comment: '',
});
const open = createEvent<number>();
const changeComment = createEvent<string>();
const reset = createEvent();
const submiteReturnDocument = createEvent();

$documentActionReturnDcumentCommentModal.reset(reset);
$documentActionReturnDcumentCommentModal.on(open, (store, documentId) => ({
  ...store,
  documentId,
}));
$documentActionReturnDcumentCommentModal.on(
  changeComment,
  (store, comment) => ({ ...store, comment })
);

sample({
  clock: submiteReturnDocument,
  source: $documentActionReturnDcumentCommentModal,
  filter: (source) => Boolean(source.documentId && source.comment.length > 0),
  fn: (source) => {
    const requestBody = {
      actionTaskId: 8,
      comment: source.comment,
    } as DocumetnDoActionRequest;

    return {
      documentId: source.documentId as number,
      requestBody,
    };
  },
  target: postDocumentsDocumentIdDoActionFx,
});
sample({ clock: postDocumentsDocumentIdDoActionFx.done, target: reset });

export const documentActionReturnDcumentCommentModal = {
  store: $documentActionReturnDcumentCommentModal,
  events: {
    open,
    changeComment,
    submiteReturnDocument,
    reset,
  },
};
