import { Box, FormControl, Grid, TextareaAutosize } from '@mui/material';
import { useStoreMap } from 'effector-react';

import { theme } from 'shared/theme/theme';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormComment = () => {
  const commentValue = useStoreMap(
    documentFormModel.commentForm,
    (comment) => comment.comment
  );

  return (
    <Grid
      container
      sx={{ marginTop: 2 }}
    >
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          width: '100%',
          '& > div': {
            flexBasis: ['100%', '100%'],
            maxWidth: ['100%', '100%'],
            '& textarea': {
              boxSizing: 'border-box',
              borderRadius: ['5px', '10px'],
              padding: ['4px', 1],
              fontSize: [16, 16],
              border: ['none', `solid 1px ${theme.palette.customGrey.dark}`],
            },
          },
          paddingRight: [1, 0],
          paddingLeft: [1, 0],
          position: 'relative',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
        >
          <TextareaAutosize
            //TODO :  виновник ResizeObserver loop completed with undelivered notifications   https://github.com/mui/material-ui/issues/39105
            /*  ref={ref} */
            tabIndex={99}
            /*  disabled={!canEditModify} */
            className={'TextareaAutosize_comment'}
            style={{ width: '100%' /* minHeight: isMobile ? 18 : 24 */ }}
            placeholder='Введите комментарий'
            aria-label='empty textarea'
            value={commentValue || ''}
            onChange={(e) =>
              documentFormModel.commentFormEvent.setFieldDocumentFormComment({
                key: 'comment',
                value: e.target.value,
              })
            }
          />
        </Box>
        {/*  <Button
          variant="contained"
          color="secondary"
          size="small"
          sx={{
            position: ["absolute", "relative"],
            transform: ["translate(0,-50%)", "none"],
            textTransform: "none",
            fontWeight: "bold",
            minWidth: ["30px", "91px"],
            maxWidth: ["30px", "91px"],
            maxHeight: ["30px", "40px"],
            minHeight: ["30px", "40px"],
            borderRadius: ["5px", "10px"],
            top: ["50%", 0],
            fontSize: [35, 14],
            right: [16, 0],
            "& > svg": {
              display: ["block", "none"],
            },
            "& > p": {
              display: ["none", "block"],
            },
          }}
        >
          <Send />
          <Typography fontWeight={700} fontSize={14}>
            Отправить
          </Typography>
        </Button> */}
      </FormControl>
    </Grid>
  );
};
