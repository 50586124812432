export const MANUAL = {
  status: [
    {
      title: 'Отложена',
      description:
        'Cтатус позволяет создать задачу с полным набором параметров без отправки задачи: исполнителю, соисполнителям, наблюдателем, задача отображается только у постановщика;',
    },
    {
      title: 'Новая',
      description:
        'Новая задача, созданная пользователем, данный статус присваивается задаче по умолчанию при создании задачи;',
    },
    {
      title: 'В работе',
      description:
        'Задача принята исполнителем и находится в работе, пользователь работает над задачей;',
    },
    {
      title: 'Выполнена',
      description:
        'Задача выполнена исполнителем и находится на согласовании у постановщика задачи, промежуточный статус перед завершением задачи, статус доступен только для задач, требующих контроль со стороны постановщика;',
    },
    {
      title: 'Завершена',
      description: 'Задача считается выполненной',
    },
    {
      title: 'Ожидание',
      description:
        'Задача принята исполнителем, но исполнитель не работает над данной задачей.',
    },
  ],
};
