import { Grid, Paper } from '@mui/material';
import { FC } from 'react';

import { BannerBrandsItemUiProps } from './types';

export const BannerBrandsItemUi: FC<BannerBrandsItemUiProps> = ({
  children,
  Action,
  skipAfterSxBlock,
}) => {
  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={3}
    >
      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          boxSizing: 'border-box',
          position: 'relative',
          borderRadius: '10px',
          overflow: 'hidden',
          width: '100%',
          '&::after': skipAfterSxBlock
            ? {}
            : {
                content: '""',
                display: 'block',
                paddingTop: '52.0833%',
              },
        }}
      >
        {children}
      </Paper>

      {Action}
    </Grid>
  );
};
