import { Box, Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { CommentReply } from '../comment-reply/CommentReply';
import { TaskCommentProps } from './type';

export const TaskComment: FC<TaskCommentProps> = ({
  commentItem,
  replyComent,
  onDel,
  onReply,
  hasChildren = false,
  disable = false,
}) => {
  const { guid, parentGuid, comment, dateCreated, employee, canDelete } =
    commentItem;

  const dateDayJs = dayjs(dateCreated as string)
    .locale('ru')
    .format('llll')
    .replace('г.,', '');

  const date = dateDayJs[0].toUpperCase() + dateDayJs.slice(1);

  return (
    <Box
      key={guid}
      display={'flex'}
      flexDirection={'column'}
    >
      {parentGuid && replyComent && <CommentReply commentReply={replyComent} />}
      <Typography
        color={theme.palette.customGrey.dark}
        fontSize={12}
      >
        {date}
      </Typography>
      <Typography fontWeight={700}>{employee.name}</Typography>
      <Typography>{comment}</Typography>
      <Grid
        container
        justifyContent={'flex-end'}
      >
        <Button
          disabled={disable}
          size='small'
          onClick={() => {
            onReply && onReply(commentItem);
          }}
          sx={{
            textTransform: 'none',
            color: theme.palette.customGrey.dark,
            fontWeight: 700,
          }}
        >
          Ответить
        </Button>
        <Button
          disabled={!canDelete || hasChildren}
          size='small'
          sx={{
            textTransform: 'none',
            color: theme.palette.customGrey.dark,
            fontWeight: 700,
          }}
          onClick={() => {
            onDel && onDel(commentItem.guid);
          }}
        >
          Удалить
        </Button>
      </Grid>
    </Box>
  );
};
