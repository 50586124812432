/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Рекламная поверхность */
export interface AdvertisingSurface {
  /**
   * Идентификатор
   * @format uuid
   */
  guid?: string | null;
  /**
   * Идентификатор аптеки
   * @format uuid
   */
  retailGuid: string;
  /**
   * Ширина
   * @format double
   */
  width?: number | null;
  /**
   * Высота
   * @format double
   */
  height?: number | null;
  /** Описание */
  description?: string | null;
  /** Для РП требуется загрузка новых изображений */
  surfaceUpdateDaysExpired: boolean;
  /**
   * Дата загрузки последнего изображения
   * @format date-time
   */
  lastImageDateCreated?: string | null;
  /**
   * Дата изменения
   * @format date-time
   */
  dateLastModified?: string | null;
  /**
   * Использовать AdvertisingType
   * @deprecated
   * @format uuid
   */
  advertisingTypeGuid?: string | null;
  /**
   * Использовать SurfaceType
   * @deprecated
   * @format uuid
   */
  surfaceTypeGuid: string;
  /** Тип рекламы */
  advertisingType?: AdvertisingType;
  /** Тип РП */
  surfaceType: SurfaceType;
}

/** Рекламная поверхность */
export type AdvertisingSurfacePost = {
  /**
   * Идентификатор
   * @format uuid
   */
  guid: string | null;
  /**
   * Идентификатор аптеки
   * @format uuid
   */
  retailGuid: string;
  /**
   * Ширина
   * @format double
   */
  width: number | null;
  /**
   * Высота
   * @format double
   */
  height: number | null;
  /**
   * Описание
   * @minLength 1
   */
  description: string | null;
  /**
   * Идентификатор типа рекламы
   * @format uuid
   */
  advertisingTypeGuid: string;
  /**
   * Идентификатор типа РП
   * @format uuid
   */
  surfaceTypeGuid: string;
};

/** Тип рекламы */
export interface AdvertisingType {
  /**
   * Идентификатор
   * @format uuid
   */
  guid: string;
  /**
   * Название
   * @minLength 1
   */
  title: string;
}

/** Тип рекламы */
export interface AdvertisingTypePost {
  /**
   * Идентификатор
   * @format uuid
   */
  guid: string;
  /**
   * Название
   * @minLength 1
   */
  title: string;
}

export interface HttpValidationProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  errors?: Record<string, string[]>;
  [key: string]: any;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

/** Информация о сохраненном изображении рекламной поверхности */
export interface SurfaceFileInfo {
  /**
   * Название файла
   * @minLength 1
   */
  fileName: string;
  /**
   * Дата создания
   * @format date-time
   */
  createdAt: string;
}

/** Аптека */
export interface SurfaceRetail {
  /**
   * Идентификатор
   * @format uuid
   */
  guid: string;
  /**
   * Название аптеки
   * @minLength 1
   */
  title: string;
  /**
   * Сеть в которой находится аптека
   * @minLength 1
   */
  departmentTitle: string;
  /** В аптеке есть РП, которые превысили срок обновления изображений */
  surfaceUpdateDaysExpired: boolean;
  /** В аптеке запрещено добавлять РП */
  isLocked: boolean;
}

/** Аптека */
export interface SurfaceRetailPost {
  /**
   * Идентификатор
   * @format uuid
   */
  guid: string;
  /** В аптеке есть РП, которые превысили срок обновления изображений */
  surfaceUpdateDaysExpired: boolean;
  /** В аптеке запрещено добавлять РП */
  isLocked: boolean;
}

/** Тип РП */
export interface SurfaceType {
  /**
   * Идентификатор
   * @format uuid
   */
  guid: string;
  /**
   * Название
   * @minLength 1
   */
  title: string;
  /** Обязателен для каждой аптеки */
  isRequired: boolean;
  /**
   * Допустимое количество прошедших дней с последнего обновления изображений РП
   * @format int32
   */
  requiredUpdateDays: number;
}

/** Тип РП */
export interface SurfaceTypePost {
  /**
   * Идентификатор
   * @format uuid
   */
  guid: string;
  /**
   * Название
   * @minLength 1
   */
  title: string;
  /** Обязателен для каждой аптеки */
  isRequired: boolean;
  /**
   * Допустимое количество прошедших дней с последнего обновления изображений РП
   * @format int32
   */
  requiredUpdateDays: number;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Рекламные поверхности
 * @version v1
 * @contact Гусевский Даниил Игоревич <gusevskii.di@garzdrav.ru>
 *
 * API для работы с модулем "Рекламные поверхности" (РП).
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  advertisingSurface = {
    /**
     * No description
     *
     * @tags AdvertisingSurface
     * @name AdvertisingSurfacesDetail
     * @summary Список РП.
     * @request GET:/v1/advertisingsurfaces/AdvertisingSurfaces/{retailGuid}
     * @secure
     * @response `200` `(AdvertisingSurface)[]` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    AdvertisingSurfacesDetail: (
      retailGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<
        AdvertisingSurface[],
        HttpValidationProblemDetails | ProblemDetails
      >({
        path: `/v1/advertisingsurfaces/AdvertisingSurfaces/${retailGuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdvertisingSurface
     * @name AdvertisingSurfaceAddCreate
     * @summary Добавление РП.
     * @request POST:/v1/advertisingsurfaces/AdvertisingSurface/Add
     * @secure
     * @response `200` `AdvertisingSurface` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    AdvertisingSurfaceAddCreate: (
      data: AdvertisingSurfacePost,
      params: RequestParams = {}
    ) =>
      this.request<
        AdvertisingSurface,
        HttpValidationProblemDetails | ProblemDetails
      >({
        path: `/v1/advertisingsurfaces/AdvertisingSurface/Add`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdvertisingSurface
     * @name AdvertisingSurfaceUpdateCreate
     * @summary Обновление РП.
     * @request POST:/v1/advertisingsurfaces/AdvertisingSurface/Update
     * @secure
     * @response `200` `AdvertisingSurface` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    AdvertisingSurfaceUpdateCreate: (
      data: AdvertisingSurfacePost,
      params: RequestParams = {}
    ) =>
      this.request<
        AdvertisingSurface,
        HttpValidationProblemDetails | ProblemDetails
      >({
        path: `/v1/advertisingsurfaces/AdvertisingSurface/Update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdvertisingSurface
     * @name AdvertisingSurfaceDeleteDelete
     * @summary Удаление РП.
     * @request DELETE:/v1/advertisingsurfaces/AdvertisingSurface/Delete/{surfaceGuid}
     * @secure
     * @response `200` `void` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    AdvertisingSurfaceDeleteDelete: (
      surfaceGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<void, HttpValidationProblemDetails | ProblemDetails>({
        path: `/v1/advertisingsurfaces/AdvertisingSurface/Delete/${surfaceGuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  advertisingTypes = {
    /**
     * No description
     *
     * @tags AdvertisingTypes
     * @name AdvertisingTypesList
     * @summary Список типов рекламы.
     * @request GET:/v1/advertisingsurfaces/AdvertisingTypes
     * @secure
     * @response `200` `(AdvertisingType)[]` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    AdvertisingTypesList: (params: RequestParams = {}) =>
      this.request<
        AdvertisingType[],
        HttpValidationProblemDetails | ProblemDetails
      >({
        path: `/v1/advertisingsurfaces/AdvertisingTypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdvertisingTypes
     * @name AdvertisingTypesAddCreate
     * @summary Добавление типа рекламы.
     * @request POST:/v1/advertisingsurfaces/AdvertisingTypes/Add
     * @secure
     * @response `200` `AdvertisingType` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    AdvertisingTypesAddCreate: (
      data: AdvertisingTypePost,
      params: RequestParams = {}
    ) =>
      this.request<
        AdvertisingType,
        HttpValidationProblemDetails | ProblemDetails
      >({
        path: `/v1/advertisingsurfaces/AdvertisingTypes/Add`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdvertisingTypes
     * @name AdvertisingTypesUpdateCreate
     * @summary Обновление типа рекламы.
     * @request POST:/v1/advertisingsurfaces/AdvertisingTypes/Update
     * @secure
     * @response `200` `AdvertisingType` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    AdvertisingTypesUpdateCreate: (
      data: AdvertisingTypePost,
      params: RequestParams = {}
    ) =>
      this.request<
        AdvertisingType,
        HttpValidationProblemDetails | ProblemDetails
      >({
        path: `/v1/advertisingsurfaces/AdvertisingTypes/Update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AdvertisingTypes
     * @name AdvertisingTypesDeleteDelete
     * @summary Удаление типа рекламы.
     * @request DELETE:/v1/advertisingsurfaces/AdvertisingTypes/Delete/{typeGuid}
     * @secure
     * @response `200` `void` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    AdvertisingTypesDeleteDelete: (
      typeGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<void, HttpValidationProblemDetails | ProblemDetails>({
        path: `/v1/advertisingsurfaces/AdvertisingTypes/Delete/${typeGuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name FilesInfoDetail
     * @summary Список файлов прикрепленных к РП.
     * @request GET:/v1/advertisingsurfaces/FilesInfo/{surfaceGuid}
     * @secure
     * @response `200` `(SurfaceFileInfo)[]` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    FilesInfoDetail: (surfaceGuid: string, params: RequestParams = {}) =>
      this.request<
        SurfaceFileInfo[],
        HttpValidationProblemDetails | ProblemDetails
      >({
        path: `/v1/advertisingsurfaces/FilesInfo/${surfaceGuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Поддерживаемые форматы: .jpg, .jpeg, .png, .gif
     *
     * @tags Files
     * @name UploadImageCreate
     * @summary Прикрепление изображений к РП.
     * @request POST:/v1/advertisingsurfaces/UploadImage/{surfaceGuid}
     * @secure
     * @response `200` `void` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    UploadImageCreate: (
      surfaceGuid: string,
      data: {
        files: File[];
      },
      params: RequestParams = {}
    ) =>
      this.request<void, HttpValidationProblemDetails | ProblemDetails>({
        path: `/v1/advertisingsurfaces/UploadImage/${surfaceGuid}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name DeleteImageDelete
     * @summary Удаление файла прикрепленного к РП.
     * @request DELETE:/v1/advertisingsurfaces/DeleteImage/{imageName}/{surfaceGuid}
     * @secure
     * @response `200` `void` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    DeleteImageDelete: (
      imageName: string,
      surfaceGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<void, HttpValidationProblemDetails | ProblemDetails>({
        path: `/v1/advertisingsurfaces/DeleteImage/${imageName}/${surfaceGuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name DownloadImageDetail
     * @summary Загрузка конкретного изображения.
     * @request GET:/v1/advertisingsurfaces/DownloadImage/{imageName}
     * @secure
     * @response `200` `void` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    DownloadImageDetail: (imageName: string, params: RequestParams = {}) =>
      this.request<void, HttpValidationProblemDetails | ProblemDetails>({
        path: `/v1/advertisingsurfaces/DownloadImage/${imageName}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  retails = {
    /**
     * No description
     *
     * @tags Retails
     * @name RetailsList
     * @summary Список аптек доступных пользователю.
     * @request GET:/v1/advertisingsurfaces/Retails
     * @secure
     * @response `200` `(SurfaceRetail)[]` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    RetailsList: (params: RequestParams = {}) =>
      this.request<
        SurfaceRetail[],
        HttpValidationProblemDetails | ProblemDetails
      >({
        path: `/v1/advertisingsurfaces/Retails`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Retails
     * @name RetailsCreate
     * @summary Обновление настроек аптеки.
     * @request POST:/v1/advertisingsurfaces/Retails
     * @secure
     * @response `200` `void` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    RetailsCreate: (data: SurfaceRetailPost, params: RequestParams = {}) =>
      this.request<void, HttpValidationProblemDetails | ProblemDetails>({
        path: `/v1/advertisingsurfaces/Retails`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  surfaceTypes = {
    /**
     * No description
     *
     * @tags SurfaceTypes
     * @name SurfaceTypesList
     * @summary Список типов РП.
     * @request GET:/v1/advertisingsurfaces/SurfaceTypes
     * @secure
     * @response `200` `(SurfaceType)[]` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    SurfaceTypesList: (params: RequestParams = {}) =>
      this.request<
        SurfaceType[],
        HttpValidationProblemDetails | ProblemDetails
      >({
        path: `/v1/advertisingsurfaces/SurfaceTypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SurfaceTypes
     * @name SurfaceTypesAddCreate
     * @summary Добавление типа РП.
     * @request POST:/v1/advertisingsurfaces/SurfaceTypes/Add
     * @secure
     * @response `200` `SurfaceType` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    SurfaceTypesAddCreate: (
      data: SurfaceTypePost,
      params: RequestParams = {}
    ) =>
      this.request<SurfaceType, HttpValidationProblemDetails | ProblemDetails>({
        path: `/v1/advertisingsurfaces/SurfaceTypes/Add`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SurfaceTypes
     * @name SurfaceTypesUpdateCreate
     * @summary Обновление типа РП.
     * @request POST:/v1/advertisingsurfaces/SurfaceTypes/Update
     * @secure
     * @response `200` `SurfaceType` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    SurfaceTypesUpdateCreate: (
      data: SurfaceTypePost,
      params: RequestParams = {}
    ) =>
      this.request<SurfaceType, HttpValidationProblemDetails | ProblemDetails>({
        path: `/v1/advertisingsurfaces/SurfaceTypes/Update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SurfaceTypes
     * @name SurfaceTypesDeleteDelete
     * @summary Удаление типа РП.
     * @request DELETE:/v1/advertisingsurfaces/SurfaceTypes/Delete/{typeGuid}
     * @secure
     * @response `200` `void` Success
     * @response `400` `HttpValidationProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    SurfaceTypesDeleteDelete: (typeGuid: string, params: RequestParams = {}) =>
      this.request<void, HttpValidationProblemDetails | ProblemDetails>({
        path: `/v1/advertisingsurfaces/SurfaceTypes/Delete/${typeGuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
}
