export const useCheckContainsEventTargetOfRef = <
  T extends HTMLElement | null,
  C extends HTMLElement,
>(
  ref: T,
  params: {
    cbOnContains?: (e: React.MouseEvent<C, MouseEvent>) => unknown;
    cbOnNotContains?: (e: React.MouseEvent<C, MouseEvent>) => unknown;
  }
) => {
  return (e: React.MouseEvent<C, MouseEvent>) => {
    if (ref?.contains(e.target as Node)) {
      params.cbOnContains?.(e);
    } else {
      params.cbOnNotContains?.(e);
    }
  };
};
