import { Add } from '@mui/icons-material';
import { Grid, Paper } from '@mui/material';
import { useCreateStageTemplateTask } from 'features/adaptation/hooks/useCreateStageTemplateTask';
import { useDeleteStageTemplateTask } from 'features/adaptation/hooks/useDeleteStageTemplateTask';
import { useEditTemplateStageTask } from 'features/adaptation/hooks/useEditTemplateStageTask';
import { FC, memo, useCallback } from 'react';
import { AdaptationManagerResponsesGetListStageTask } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { useGetAdaptationStageTasksQuery } from 'shared/api/service-adaptation/rtk/adaptationStageTasksProviderApi';
import { AnimatedFAB } from 'shared/ui/AnimatedFAB';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { Tables } from 'shared/utils/controllers';
import {
  AdaptationStageTasksTableActions,
  AdaptiveAdaptationStageTasksTable,
} from '../AdaptationStageTasksTable';
import { StageTemplateTaskListProps } from './type';

export const StageTemplateTaskList: FC<StageTemplateTaskListProps> = ({
  stageGuid,
  templateGuid,
}) => {
  /* Редактирование шаблона задачи */
  const {
    EditTemplateTaskDialog,
    openEditTemplateTaskDialog,
    isEditingTemplateTask,
  } = useEditTemplateStageTask(templateGuid, stageGuid);
  /* ===== */

  /* Создание шаблона задачи */
  const {
    CreateTemplateTaskDialog,
    isCreatingTemplateTask,
    openCreateTemplateTaskDialog,
  } = useCreateStageTemplateTask(templateGuid, stageGuid);
  /* ===== */

  /* Удаление шаблона задачи */
  const { handleDeleteTask, isDeleting } = useDeleteStageTemplateTask(
    templateGuid,
    stageGuid
  );
  /* ===== */

  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<AdaptationManagerResponsesGetListStageTask>(
      Tables.ADAPTATION_STAGE_TASKS_TABLE
    );

  const {
    data: stageTasks,
    isLoading: isLoadingStageTasks,
    isFetching,
  } = useGetAdaptationStageTasksQuery({
    adaptationGuid: templateGuid,
    stageGuid,
  });

  const actions = useCallback(
    (task: AdaptationManagerResponsesGetListStageTask) => (
      <AdaptationStageTasksTableActions
        onDelete={handleDeleteTask}
        onEdit={openEditTemplateTaskDialog}
        task={task}
      />
    ),
    [handleDeleteTask, openEditTemplateTaskDialog]
  );

  return (
    <Grid>
      {CreateTemplateTaskDialog}
      {EditTemplateTaskDialog}

      <AnimatedFAB
        icon={<Add />}
        onClick={openCreateTemplateTaskDialog}
        text='Создать задачу'
        variant='extended'
        color='primary'
        sx={{ position: 'fixed', right: 32, bottom: 32 }}
      />

      <Paper sx={{ pb: 0.5 }}>
        <MemoizedAdaptiveAdaptationStageTasksTable
          data={stageTasks || []}
          fields={fields}
          onChangeFields={setFields}
          visibleFields={visibleFields}
          renderActions={actions}
          isLoading={isLoadingStageTasks}
          isUpdate={
            isCreatingTemplateTask ||
            isFetching ||
            isDeleting ||
            isEditingTemplateTask
          }
          onClick={(task) => openEditTemplateTaskDialog(task.guid)}
        />
      </Paper>
    </Grid>
  );
};

const MemoizedAdaptiveAdaptationStageTasksTable = memo(
  AdaptiveAdaptationStageTasksTable
);
