import { LoadingButton } from '@mui/lab';

import { APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS } from 'entities/apteka-legko/push-notifications';
import { FC } from 'react';
import { LinkButton } from 'shared/ui/base-ui/link';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { FORM_ID } from '../forms/form-push-notifications/constants';
import { TestNotificationActionButton } from './TestNotificationActionButton';

export const FormPushNotificationsActions: FC<{
  isLoading?: boolean;
  disabledSendAction?: boolean;
  disabledTestSendAction?: boolean;
  disabledCloseActions?: boolean;
}> = ({
  isLoading,
  disabledSendAction,
  disabledTestSendAction = false,
  disabledCloseActions,
}) => {
  return (
    <ContainerActionsForm>
      <LoadingButton
        loading={isLoading}
        disabled={disabledSendAction}
        type='submit'
        form={FORM_ID}
        variant='contained'
        color='secondary'
      >
        Отправить
      </LoadingButton>
      <TestNotificationActionButton disabled={disabledTestSendAction} />

      <LinkButton
        disabled={disabledCloseActions}
        variant='contained'
        color={'customGrey'}
        to={APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS.list}
      >
        Закрыть
      </LinkButton>
    </ContainerActionsForm>
  );
};
