import { FC } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTableRowWithActions,
} from 'shared/ui/table';

import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';

import { ActionsProcessOpeningRetailsTable } from './ActionsProcessOpeningRetailsTable';
import { ProcessOpeningRetailsTableProps } from './types';

export const ProcessOpeningRetailsTable: FC<
  ProcessOpeningRetailsTableProps
> = ({ isLoading, isUpdate, data, visibleFields, fields, onChangeFields }) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={1} />}
      header={
        <UiTableHeader
          viewCells={visibleFields}
          allCells={fields}
          setViewCells={onChangeFields}
        />
      }
      body={data.map((process) => (
        <UiTableRowWithActions
          key={process.processGuid}
          renderActions={() => (
            <ActionsProcessOpeningRetailsTable
              openingProcessGuid={process.processGuid}
            />
          )}
        >
          {visibleFields.map((el) => (
            <UiTableCell
              key={el.field + process.processGuid}
              {...el.getTableCellProps()}
            >
              {el.renderComponent(process) || 'Error'}
            </UiTableCell>
          ))}
        </UiTableRowWithActions>
      ))}
    />
  );
};
