import { GetBoardsTasksBoardGuidQueryParams } from 'shared/api/services/types';
import { CONVERT_BOARD_TASK_LIST_MAP } from '../convertMaps';
import { convertURLToObject } from '../convertURLToObject';
import { URLToObjConverter } from '../type';

export const convertURLToBoardTaskList: URLToObjConverter<
  GetBoardsTasksBoardGuidQueryParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_BOARD_TASK_LIST_MAP
  ) as GetBoardsTasksBoardGuidQueryParams;
};
