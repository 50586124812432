import { useURLState } from 'shared/url-helper/useURLState';

import { useMemo } from 'react';
import { GetDocumentsControlQueryParams } from 'shared/api/services-document-flow/types';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import { ConvertMapWithType, URLToObjConverter } from 'shared/url-helper/type';
import { CONVERT_DOCUMENT_BASE_LIST_FILTER_MAP } from '../constants';

const convertorURLToDocumentControlFilter: URLToObjConverter<
  GetDocumentsControlQueryParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_DOCUMENT_ACTION_LIST_FILTER_MAP
  ) as GetDocumentsControlQueryParams;
};

const CONVERT_DOCUMENT_ACTION_LIST_FILTER_MAP: ConvertMapWithType<GetDocumentsControlQueryParams> =
  [...CONVERT_DOCUMENT_BASE_LIST_FILTER_MAP];

const DEFAULT: GetDocumentsControlQueryParams = {
  CurrentPage: 1,
  PageSize: 20,
};

export const useUrlStateDocumentControlFilter = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToDocumentControlFilter,
    true
  );
  const filterWithDefault = useMemo(() => {
    return Object.assign({ ...DEFAULT }, filter);
  }, [filter]);

  return {
    filter: filterWithDefault,
    setFilter,
  };
};
