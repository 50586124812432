import { VisitingChecklistGetResponse } from 'shared/api/visit/types';

import { CompetitiveAdvantagesFormI } from '../type';

type PrepareDataToInitStage3Type = (
  data: VisitingChecklistGetResponse
) => CompetitiveAdvantagesFormI;
export const prepareDataToInitStage3: PrepareDataToInitStage3Type = (data) => {
  if (data.competitorBlocks) {
    return {
      blocks: data.competitorBlocks.map((el) => ({
        blockId: el.competitorBlockId,
        blockTitle: el.competitorBlockTitle || '',
        blockItems:
          el.competitorBlockItems?.map((el2) => ({
            itemId: el2.competitorItemId,
            //
            myValue: el2.competitorItemValue || null,
            // myValue: el2.competitorItemValue || 1,
            itemTitle: el2.competitorItemTitle || '',
            comment: el2.competitorItemComment,
            canAddComent: el2.competitorItemIsCommentAllowed,
            competitorsValue:
              data.cometitors?.map((el3) => ({
                competitorId: el3.competitorId,
                value:
                  data.competitorValues?.find(
                    (el4) =>
                      el4.competitorId === el3.competitorId &&
                      el4.competitorItemId === el2.competitorItemId
                    //
                  )?.competitorValue || null,
                // )?.competitorValue || 1,
              })) || [],
          })) || [],
      })),
    };
  } else return { blocks: [] };
};
