import { Comment } from '@mui/icons-material';
import { Badge, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { TaskStatusChip } from 'features/task/task-status-chip/TaskStatusChip';
import { TaskStatuses } from 'features/task/task-status-chip/type';
import { FC } from 'react';

import { GetListTask } from 'shared/api/services/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { theme } from 'shared/theme/theme';
import { Link } from 'shared/ui/base-ui';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

import { getTaskLink } from 'entities/task/libs/getTaskLink';
import { isLateDateInfo } from 'entities/task/libs/isLateDateInfo';
import { useChangeTaskStatusModal } from 'features/task/hooks/useChangeTaskStatusModal';

export const TASK_TABLE_MAP: FieldMapType<GetListTask>[] = [
  {
    field: 'title',
    title: 'Название',
    isShow: true,
    order: 1,
    getTableCellProps: () => ({}),
    renderComponent: (task) => (
      <Grid width={['auto', '300px']}>
        <Link
          fontWeight={700}
          to={getTaskLink(task.guid)}
          sx={{
            textDecorationLine: 'none',
            wordBreak: 'break-word',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {task.title}
        </Link>
      </Grid>
    ),
  },
  {
    field: 'dateStart',
    title: 'Дата начала',
    isShow: true,
    order: 2,
    getTableCellProps: () => ({ align: 'center' }),
    renderComponent: (task) => {
      const date = task.dateStart
        ? dayjs(task.dateStart).format('DD.MM.YYYY')
        : '-';

      return <Typography>{date}</Typography>;
    },
  },
  {
    field: 'dateFinish',
    title: 'Дата завершения',
    isShow: true,
    order: 3,
    getTableCellProps: () => ({ align: 'center' }),
    renderComponent: (task) => {
      if (!task.dateFinish) return <Typography>-</Typography>;
      if (task.status === 'Завершена')
        return (
          <Typography>
            {dayjs(task.dateFinish).format(DATE_FORMATS.client)}
          </Typography>
        );

      const info = isLateDateInfo(task.dateFinish);

      return (
        <Typography
          color={info.isRed ? 'red' : undefined}
          sx={{
            background:
              info.isPink && !info.isRed
                ? 'rgba(255, 100, 100, 0.2)'
                : undefined,
            borderRadius: 4,
          }}
          textAlign={'center'}
          lineHeight={2}
        >
          {dayjs(task.dateFinish).format(DATE_FORMATS.client)}
        </Typography>
      );
    },
  },
  {
    field: 'status',
    title: 'Статус',
    isShow: true,
    order: 4,
    getTableCellProps: () => ({ align: 'center' }),
    renderComponent: (task) => {
      const RenderStakStatus: FC<{ task: GetListTask }> = ({ task }) => {
        const { modal, open } = useChangeTaskStatusModal();

        return (
          <>
            <TaskStatusChip
              status={task.status as TaskStatuses}
              onClick={(e) => {
                e.stopPropagation();
                open(task);
              }}
            />

            {modal}
          </>
        );
      };

      return <RenderStakStatus task={task} />;
    },
  },
  {
    field: 'creatorName',
    title: 'Постановщик',
    isShow: true,
    order: 5,
    getTableCellProps: () => ({ align: 'center' }),
    renderComponent: (task) => <Typography>{task.creatorName}</Typography>,
  },
  {
    field: 'units',
    title: 'Юниты',
    isShow: true,
    order: 6,
    getTableCellProps: () => ({ align: 'center' }),
    renderComponent: (task) => <Typography>{task.units || '-'}</Typography>,
  },
  {
    field: 'executorName',
    title: 'Исполнитель',
    isShow: true,
    order: 7,
    getTableCellProps: () => ({ align: 'center' }),
    renderComponent: (task) => (
      <Typography>{task.executorName || '-'}</Typography>
    ),
  },
  {
    field: 'hasSubTasks',
    title: 'Подзадачи',
    isShow: true,
    order: 8,
    getTableCellProps: () => ({ align: 'center' }),
    renderComponent: (task) => (
      <Typography>{task.hasSubTasks ? 'Есть' : 'Нет'}</Typography>
    ),
  },
  {
    field: 'dateFactFinish',
    title: 'Дата фактического завершения',
    isShow: true,
    order: 9,
    getTableCellProps: () => ({ align: 'center' }),
    renderComponent: (task) => {
      const date = task.dateFactFinish
        ? dayjs(task.dateFactFinish).format('DD.MM.YYYY')
        : '-';

      return <Typography>{date}</Typography>;
    },
  },
  {
    field: 'originTitle',
    title: 'Источник',
    isShow: true,
    order: 10,
    getTableCellProps: () => ({ align: 'center' }),
    renderComponent: (task) => <Typography>{task.originTitle}</Typography>,
  },
  {
    field: 'parentTaskTitle',
    title: 'Родительская задача',
    isShow: true,
    order: 11,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      if (task.parentTaskGuid && task.parentTaskTitle)
        return (
          <Grid width={['auto', '300px']}>
            <Link
              sx={{
                textDecorationLine: 'none',
                wordBreak: 'break-word',
              }}
              to={getTaskLink(task.parentTaskGuid)}
            >
              {task.parentTaskTitle}
            </Link>
          </Grid>
        );
      else return <Typography>-</Typography>;
    },
  },
  {
    field: 'unreadCommentsCount',
    title: 'Непрочитанные сообщения',
    isShow: true,
    order: 12,
    getTableCellProps: () => ({ align: 'center', sx: { maxWidth: 140 } }),
    renderComponent: (task) => {
      return (
        <Badge
          badgeContent={task.unreadCommentsCount}
          color='primary'
        >
          <Comment sx={{ color: theme.palette.customGrey.contrastText }} />
        </Badge>
      );
    },
  },
  // {
  //   field: '',
  //   title: '',
  //   isShow: ,
  //   order: ,
  //   getTableCellProps: () => ({}),
  //   renderComponent: (task) => ,
  // },
];
