import { AdaptationTemplatesPage } from 'pages/adaptation/AdaptationTemplatesPage';
import { AdaptationTemplateStagesPage } from 'pages/adaptation/AdaptationTemplateStagesPage';
import { CompletedAdaptationProcessesPage } from 'pages/adaptation/CompletedAdaptationProcessesPage';
import { CurrentAdaptationProcessesPage } from 'pages/adaptation/CurrentAdaptationProcessesPage';
import { FiredAdaptationProcessesPage } from 'pages/adaptation/FiredAdaptationProcessesPage';
import { WaitingAdaptationProcessesPage } from 'pages/adaptation/WaitingAdaptationProcessesPage';
import { RouteObject } from 'react-router-dom';

import { AdaptationProcessesRunPage } from 'pages/adaptation/AdaptationProcessesRunPage';
import { UserNotAdaptationRequiredPage } from 'pages/adaptation/UserNotAdaptationRequiredPage';
import { ALL_ROUTES } from './constants';

export const ROUTES_ADAPTATION: RouteObject[] = [
  {
    path: ALL_ROUTES.adaptation,
    children: [
      {
        path: ALL_ROUTES.adaptationTemplates,
        element: <AdaptationTemplatesPage />,
      },
      {
        path: ALL_ROUTES.adaptationTemplatesRunProcess,
        element: <AdaptationProcessesRunPage />,
      },
      {
        path: ALL_ROUTES.currentAdaptationProcesses,
        element: <CurrentAdaptationProcessesPage />,
      },
      {
        path: ALL_ROUTES.completedAdaptationProcesses,
        element: <CompletedAdaptationProcessesPage />,
      },
      {
        path: ALL_ROUTES.firedAdaptationProcesses,
        element: <FiredAdaptationProcessesPage />,
      },
      {
        path: ALL_ROUTES.notAdaptationRequired,
        element: <UserNotAdaptationRequiredPage />,
      },
      {
        path: ALL_ROUTES.waitingAdaptationProcesses,
        element: <WaitingAdaptationProcessesPage />,
      },
      {
        path: ALL_ROUTES.adaptationTemplatesStages,
        element: <AdaptationTemplateStagesPage />,
      },
    ],
  },
];
