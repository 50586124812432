import { FC, useEffect, useState } from 'react';
import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import { ActionVisitSearchBarProps } from './type';

export const ActionVisitSearchBar: FC<ActionVisitSearchBarProps> = ({
  applySearch,
  onOpenFilter,
  initSearch = '',
}) => {
  const [search, setSearch] = useState(initSearch);

  const clear = () => {
    setSearch('');
    applySearch('');
  };

  useEffect(() => {
    setSearch(initSearch);
  }, [initSearch]);

  return (
    <UiSearchBar
      textValue={search}
      changeValue={setSearch}
      onClear={clear}
      onCompliteInput={applySearch}
    >
      <FilterButton onClick={onOpenFilter} />
    </UiSearchBar>
  );
};
