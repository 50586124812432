import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { CreateBoardForm } from 'features/boards/forms/CreateBoardForm';
import { FC } from 'react';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { CreateBoardDialogProps } from './type';

export const CreateBoardDialog: FC<CreateBoardDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initValue,
  isLoading = false,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Создание доски' />}
    >
      <CreateBoardForm
        onSubmit={onSubmit}
        initForm={initValue}
        renderFormActions={() => [
          <LoadingButton
            loading={isLoading}
            variant='contained'
            type='submit'
            key={1}
          >
            Создать
          </LoadingButton>,
          <Button
            variant='contained'
            color='customGrey'
            onClick={onClose}
            key={2}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
