import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ResponsesTrainingProcessModel } from 'shared/api/traning/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const COMPLITED_TRAINING_FIELD_MAP: FieldMapType<ResponsesTrainingProcessModel>[] =
  [
    {
      field: 'trainingTitle',
      title: 'Название Тренинга',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.trainingTitle}</Typography>;
      },
    },
    {
      field: 'trainer',
      title: 'Тренер',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.trainer}</Typography>;
      },
    },
    {
      field: 'subscribedPeopleCount',
      title: 'Кол-во записавшихся человек',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.subscribedPeopleCount}</Typography>;
      },
    },
    {
      field: 'dateCreated',
      title: 'Дата тренинга',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return (
          <Typography>
            {dayjs(value.timeStart).format(DATE_FORMATS.client)}
          </Typography>
        );
      },
    },
    {
      field: 'peopleCount',
      title: 'Кол-во человек',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.peopleCount}</Typography>;
      },
    },
    {
      field: 'isFinish',
      title: 'Завершен',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.isFinish ? 'Да' : 'Нет'}</Typography>;
      },
    },
    {
      field: 'timeStart',
      title: 'Время тренинга',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return (
          <Typography>{`${dayjs(value.timeStart).format('HH:mm')} - ${dayjs(
            value.timeFinish
          ).format('HH:mm')}`}</Typography>
        );
      },
    },
    {
      field: 'visitedPeopleCount',
      title: 'Кол-во посетивших',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.visitedPeopleCount}</Typography>;
      },
    },
  ];
