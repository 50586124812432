import { Typography } from '@mui/material';
import { useFilterPushNotificationsListPage } from 'features/apteka-legko/push-notifications/filters';

import { useGetFirebaseDistributionsQuery } from 'shared/api/online-store-service/firebase/rtk';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

import {
  PushNotificationsAdaptiveTable,
  useTablePushNotificationsFromLocalStorage,
} from 'widgets/apteka-legko/push-notifications/push-notifications-table';
import { EMPT_ARR } from 'widgets/training/filter/constants';

export const PageListNotification = () => {
  const { filter, handleChangePage, handleChangePageSize } =
    useFilterPushNotificationsListPage();

  const { data, isLoading, isFetching } = useGetFirebaseDistributionsQuery({
    params: filter,
  });
  const [allFields, onChangeFields, visibleFields] =
    useTablePushNotificationsFromLocalStorage();

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h5'
          fontWeight={'bold'}
        >
          Список push-уведомлений
        </Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <PushNotificationsAdaptiveTable
          data={data?.items || EMPT_ARR}
          fields={allFields}
          isLoading={isLoading}
          isUpdate={isFetching}
          visibleFields={visibleFields}
          onChangeFields={onChangeFields}
          page={filter.Page}
          count={data?.count || 20}
          limit={filter.Limit}
          onChangeLimit={handleChangePageSize}
          onChangePage={handleChangePage}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
