import { useStore, useStoreMap } from 'effector-react';

import {
  OnchangeTaxRate,
  UiDocumentFormTaxRatesAutocomplite,
} from 'shared/ui/UiDocumentFormTaxRatesAutocomplite';

import { documentFormModel } from 'entities/document-form';

import { FC } from 'react';
import { $taxRateId, $taxRateOptions } from './model';

export const DocumentFormTaxRatesAutocomplite: FC<{ disabled?: boolean }> = ({
  disabled,
}) => {
  const options = useStore($taxRateOptions);
  const value = useStoreMap({
    store: $taxRateId,
    keys: options,
    fn: (taxRateId, options) =>
      options.find((item) => item.id === taxRateId) || null,
  });
  const handleChangeTaxRate: OnchangeTaxRate = (event, value) => {
    if (typeof value === 'string' || typeof value === 'number') {
      documentFormModel.formEvent.setField({
        key: 'taxSum',
        value: value ? value : null,
      });
      documentFormModel.formEvent.setField({
        key: 'taxRateId',
        value: undefined,
      });
    } else {
      documentFormModel.formEvent.setField({
        key: 'taxRateId',
        value: value ? value.id : null,
      });
      documentFormModel.formEvent.setField({
        key: 'taxSum',
        value: undefined,
      });
    }
  };
  const isTaxCalculateRequired = documentFormModel.useValueProcess(
    'isTaxCalculateRequired'
  );

  return (
    <UiDocumentFormTaxRatesAutocomplite
      disabled={!isTaxCalculateRequired || Boolean(disabled)}
      onChange={handleChangeTaxRate}
      options={options}
      value={value}
    />
  );
};
