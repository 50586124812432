import { useUrlBoardList } from './useUrlBoardList';

export const useQueryBoardList = () => {
  const [query, setQuery] = useUrlBoardList();

  const toggleArchive = () => {
    setQuery({ ...query, IsArchive: !query.IsArchive });
  };

  const changeLimit = (limit: number) => {
    setQuery({ ...query, Limit: limit });
  };

  const changePage = (page: number) => {
    setQuery({ ...query, Page: page });
  };

  const changeSearch = (search: string) => {
    setQuery({ ...query, SearchText: search });
  };

  return { toggleArchive, changeLimit, changePage, query, changeSearch };
};
