import { transformResponseError } from 'shared/api/libs';
import { adaptationApi } from '../adaptationApi';
import {
  AdaptationManagerRequestsBannedEmployeeCreate,
  AdaptationManagerResponsesGetListBannedEmployee,
  AdaptationProcessesBannedDeleteParams,
  AdaptationProcessesBannedListParams,
} from '../adaptationAutogenerateApi';
import {
  transformResponseBackendUserNoAdaptationRequiredToViewType,
  UserNoAdaptationRequiredWithPagination,
} from '../normolize-data/transformResponseBackendUserNoAdaptationRequiredToViewType';
import { adaptationProviderApi } from './adaptationProviderApi';

const adaptationBanProviderApi = adaptationProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /** Получение списка сотрудников, которым не требуется адаптация (банлист) */
    getBannedEmployees: build.query<
      UserNoAdaptationRequiredWithPagination,
      AdaptationProcessesBannedListParams
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesBannedList(
              args
            );
          return {
            data: transformResponseBackendUserNoAdaptationRequiredToViewType(
              response.data
            ),
          };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [
          ...res.items.map((el) => ({
            type: 'banned_employee' as const,
            id: el.guid,
          })),
          'banned_employee_list',
        ];
      },
    }),
    /* ======================================================================= */

    /** Добавление сотрудника в банлист */
    addEmployeeToBanList: build.mutation<
      AdaptationManagerResponsesGetListBannedEmployee,
      AdaptationManagerRequestsBannedEmployeeCreate
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesBannedCreate(
              args
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      // TODO: надо подумать
      invalidatesTags: ['banned_employee_list', 'adaptation_process_list'],
    }),
    /* ================================ */

    /** Исключение сотрудника из банлиста */
    delEmployeeFromBanList: build.mutation<
      void,
      AdaptationProcessesBannedDeleteParams
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesBannedDelete(
              args
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      // TODO: надо подумать
      invalidatesTags: ['banned_employee_list', 'adaptation_process_list'],
    }),
    /* =================================== */
  }),
});

export const {
  useGetBannedEmployeesQuery,
  useAddEmployeeToBanListMutation,
  useDelEmployeeFromBanListMutation,
} = adaptationBanProviderApi;
