import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { OnlineStoreResponsesFirebaseDistribution } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { DATE_FORMATS } from 'shared/date-helper/constants';

import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const APTEKA_LEGKO_PUSH_NOTIFICATIONS_FIELD_MAP: FieldMapType<OnlineStoreResponsesFirebaseDistribution>[] =
  [
    {
      field: 'distributionTitle',
      title: 'Заголовок',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 0,
      renderComponent: ({ distributionTitle }) => {
        return <Typography>{distributionTitle}</Typography>;
      },
    },
    {
      field: 'message',
      title: 'Текст',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: ({ message }) => {
        return <Typography>{message}</Typography>;
      },
    },
    {
      field: 'dateTimeSending',
      title: 'Дата отправки',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 2,
      renderComponent: ({ dateTimeSending }) => {
        return (
          <Typography>
            {dayjs(dateTimeSending).format(DATE_FORMATS.clientWithTime)}
          </Typography>
        );
      },
    },
    {
      field: 'status',
      title: 'Статус',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 3,
      renderComponent: ({ status }) => {
        return <Typography>{status}</Typography>;
      },
    },
    {
      field: 'userCount',
      title: 'Количество получателей',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 4,
      renderComponent: ({ userCount }) => {
        return <Typography>{userCount}</Typography>;
      },
    },
    {
      field: 'successCount',
      title: 'Доставлено',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 5,
      renderComponent: ({ successCount }) => {
        return <Typography>{successCount}</Typography>;
      },
    },
    {
      field: 'notificationTypeTitle',
      title: 'Тип',
      getTableCellProps: () => {
        return {};
      },
      isShow: false,
      order: 6,
      renderComponent: ({ notificationTypeTitle }) => {
        return <Typography>{notificationTypeTitle}</Typography>;
      },
    },
    {
      field: 'notificationTypeValue',
      title: 'Кнопка/промокод',
      getTableCellProps: () => {
        return {};
      },
      isShow: false,
      order: 7,
      renderComponent: ({ notificationTypeValue }) => {
        return <Typography>{notificationTypeValue || '-'}</Typography>;
      },
    },
    {
      field: 'isSaveForUser',
      title: 'Сохранить в профиле',
      getTableCellProps: () => {
        return {};
      },
      isShow: false,
      order: 8,
      renderComponent: ({ isSaveForUser }) => {
        return <Typography>{isSaveForUser ? 'Да' : 'Нет'}</Typography>;
      },
    },
    {
      field: 'userCreated',
      title: 'Создал(а)',
      getTableCellProps: () => {
        return {};
      },
      isShow: false,
      order: 9,
      renderComponent: ({ userCreated }) => {
        return <Typography>{userCreated}</Typography>;
      },
    },
    {
      field: 'userDeleted',
      title: 'Удалил(а)',
      getTableCellProps: () => {
        return {};
      },
      isShow: false,
      order: 10,
      renderComponent: ({ userDeleted }) => {
        return <Typography>{userDeleted}</Typography>;
      },
    },
  ];
