import { FC } from 'react';

import { Button } from '@mui/material';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { TaskFilterForm } from '../task-filter-form/TaskFilterForm';
import { TaskFilterModalProps } from './type';

export const TaskFilterModal: FC<TaskFilterModalProps> = ({
  taskListVariant,
  onClose,
  isOpen,
  onSubmit,
  defaultValue,
}) => {
  return (
    <DialogForForm
      onClose={onClose}
      open={isOpen}
      head={<DialogTitleForForm title='Фильтры' />}
    >
      <TaskFilterForm
        taskListVariant={taskListVariant}
        onSubmit={onSubmit}
        defaultValue={defaultValue}
        actions={() => [
          <Button
            key={1}
            variant='contained'
            type='submit'
          >
            Сохранить
          </Button>,
          <Button
            key={2}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
