import { Grid } from '@mui/material';
import { FC } from 'react';

import { ProductGetListResponse } from 'shared/api/visit/types';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { CustomTextField } from 'shared/ui/custom-text-field';
import { useDevice } from 'shared/utils/hook-type-device';

const MAS = [1, 2, 3, 4];

interface CompetitorsProductInfoProps {
  value: (ProductGetListResponse | null)[];
  onChange: (value: (ProductGetListResponse | null)[]) => void;
  options: ProductGetListResponse[];
  onChangeSearch: (search: string) => void;
  disabled?: boolean;
  isLoading?: boolean;
}
export const CompetitorsProductInfo: FC<CompetitorsProductInfoProps> = ({
  value,
  onChange,
  options,
  onChangeSearch,
  disabled,
  isLoading = false,
}) => {
  const [isMob] = useDevice();

  return (
    <Grid
      container
      gap={2}
      flexBasis={['100%', '70%']}
    >
      {MAS.map((_, i) => (
        <CustomAutoCompliteAdaptive
          key={i}
          label={`Наименование товара ${i + 1}`}
          propsAutocomplite={{
            loading: isLoading,
            noOptionsText: 'Введите наименование товара',
            disabled,
            options: value[i]
              ? [...options, value[i] as ProductGetListResponse]
              : options,
            getOptionDisabled: (option) =>
              value.some((el) => el?.id === option.id),
            getOptionLabel: (option) => option.title || '',
            value: value ? value[i] : null,
            isOptionEqualToValue: (option, value) => option.id === value.id,
            onChange: (_, newValue) => {
              const newMas = [...value];

              newMas[i] = newValue;
              onChange(newMas);
            },
            onInputChange: (_, value) => {
              onChangeSearch(value);
            },
            renderInput: (params) => (
              <CustomTextField
                propsTextField={{
                  ...params,
                  margin: 'none',
                  label: isMob ? '' : `Наименование товара ${i + 1}`,
                  placeholder: 'Выберите товар',
                  size: 'small',
                }}
              />
            ),
          }}
        />
      ))}
    </Grid>
  );
};
