// eslint-disable-next-line budapestian/global-constant-pattern
import { Grid } from '@mui/material';
import { FC, forwardRef, ReactNode } from 'react';

const LayoutBar: FC<{ children: ReactNode }> = ({ children }) => {
  return <Grid paddingX={[1, 2]}>{children}</Grid>;
};

const LayoutContent: FC<{
  children: ReactNode;
  disabledPaddingXs?: boolean | undefined;
}> = ({ children, disabledPaddingXs = false }) => {
  return <Grid paddingX={[disabledPaddingXs ? 0 : 1, 2]}>{children}</Grid>;
};

// const LayoutMain: FC<{ children: ReactNode }> = ({ children }) => {
const LayoutMain = forwardRef<HTMLDivElement, { children: ReactNode }>(
  ({ children }, ref) => {
    return (
      <Grid
        ref={ref}
        flexDirection={'column'}
        display={'flex'}
        paddingTop={0}
        paddingBottom={6}
        gap={2}
        sx={{ margin: '0px auto', width: '100%' }}
      >
        {children}
      </Grid>
    );
  }
);

export const LayoutPage = {
  Bar: LayoutBar,
  Content: LayoutContent,
  Page: LayoutMain,
};
