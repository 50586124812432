export const getBound = (cords: [number, number][]) => {
  const x = 0;
  const y = 1;
  const delta = 0.2;

  let bottom = [0, 0];
  let top = [Infinity, Infinity];

  let right = [0, 0];
  let left = [Infinity, Infinity];

  // Поиск крайних точек
  cords.forEach((cord) => {
    // top = y -> min
    if (cord[y] < top[y]) top = cord;

    // bottom = y -> max
    if (cord[y] > bottom[y]) bottom = cord;

    // left = x -> min
    if (cord[x] < left[x]) left = cord;

    // right = x -> max
    if (cord[x] > right[x]) right = cord;
  });

  return [
    [left[x] - delta, top[y] - delta],
    [right[x] + delta, bottom[y] + delta],
  ];
};
