import { TaskFilterFormFields } from './type';

export const TARGET_OPTIONS = [
  { id: 1, title: 'Целевые', value: true },
  { id: 2, title: 'Нецелевые', value: false },
];

export const TASK_FILTER_FORM_DEFAULT_VALUE: TaskFilterFormFields = {
  coExecutor: [],
  creator: [],
  dateFinish: null,
  dateStart: null,
  executor: [],
  isTarget: null,
  originValue: [],
  statuses: [],
};
