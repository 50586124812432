import { Grid } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const ContainerBannerBrands: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid
      display={'grid'}
      gap={3}
      gridTemplateColumns={[
        'repeat(1,1fr)',
        'repeat(2,1fr)',
        'repeat(3,1fr)',
        'repeat(4,1fr)',
        'repeat(5,1fr)',
      ]}
    >
      {children}
    </Grid>
  );
};
