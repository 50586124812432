import {
  FilterItemType,
  ObserveFilterItemModel,
} from 'shared/api/services-document-flow/types';

export const filterByFilterItemType = (
  items: ObserveFilterItemModel[],
  type: FilterItemType
): ObserveFilterItemModel[] => {
  const filter = items.filter((item) => Number(item.type) === Number(type));

  return filter;
};

export const filterByFilterItemTypeByDirections = (
  items: ObserveFilterItemModel[]
) => {
  return filterByFilterItemType(items, FilterItemType['0']);
};

export const filterByFilterItemTypeByRegions = (
  items: ObserveFilterItemModel[]
) => {
  return filterByFilterItemType(items, FilterItemType['4']);
};
export const filterByFilterItemTypeByOrgDepartments = (
  items: ObserveFilterItemModel[]
) => {
  return filterByFilterItemType(items, FilterItemType['1']);
};

export const filterByFilterItemTypeByDepartments = (
  items: ObserveFilterItemModel[]
) => {
  return filterByFilterItemType(items, FilterItemType['5']);
};
