import { Typography } from '@mui/material';
import { HTMLAttributes, ReactNode } from 'react';
import { RetailTreeNodeModel } from 'shared/api/traning/types';

/* AUTOCOMPLITE - RETAILS */
export const renderOptionRetail = (
  props: HTMLAttributes<HTMLLIElement>,
  { title, guid }: RetailTreeNodeModel
): ReactNode => {
  return (
    <Typography
      {...props}
      key={guid || title}
    >{`${title} `}</Typography>
  );
};

export const getOptionLabelRetail = ({
  title,
}: RetailTreeNodeModel): string => {
  return `${title} `;
};

export const isOptionEqualToValueRetail = (
  option: RetailTreeNodeModel,
  value: RetailTreeNodeModel
) => {
  if (value.guid !== null) {
    return value.guid === option.guid;
  }

  return value.title === option.title;
};

export const treeToArray = (tree: RetailTreeNodeModel[]) => {
  const stack: RetailTreeNodeModel[] = [...tree];
  const res = [];

  while (stack.length) {
    const next = stack.pop();

    if (next && next.nodes && Array.isArray(next.nodes)) {
      stack.push({ ...next, nodes: undefined });
      stack.push(...next.nodes);
    } else {
      next && res.push(next);
    }
  }

  return res.reverse();
};
