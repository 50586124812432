import { Divider, Grid, Paper, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';

import { InfoBlockProps } from './type';

export const InfoBlock: FC<InfoBlockProps> = ({ data, competitors }) => {
  const mySum = useMemo(() => {
    let sum = 0;
    let count = 0;

    data.blocks.forEach((el) => {
      el.blockItems.forEach((el2) => {
        sum += el2.myValue || 0;
        ++count;
      });
    });

    return Math.round((sum / count) * 100) / 100;
  }, [data]);

  const otherSum = useMemo(() => {
    let other = competitors.map((el) => ({
      competitorId: el.competitorId,
      title: el.competitorTitle,
      sum: 0,
      count: 0,
      avr: 0,
    }));

    data.blocks.forEach((el) => {
      el.blockItems.forEach((el2) => {
        el2.competitorsValue.forEach((el3) => {
          other = other.map((el4) =>
            el4.competitorId === el3.competitorId
              ? {
                  ...el4,
                  sum: el4.sum + (el3.value || 0),
                  count: el4.count + 1,
                }
              : el4
          );
        });
      });
    });

    other = other.map((el) => ({
      ...el,
      avr: Math.round((el.sum / el.count) * 100) / 100,
    }));

    return other;
  }, [data]);

  return (
    <Paper
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography fontWeight={700}>
        Итоговое количество баллов по конкурентам
      </Typography>

      <Grid
        container
        flexDirection={['column', 'row']}
        gap={[1, 0]}
      >
        <Grid
          container
          flexDirection={'row'}
          width={['100%', '50%']}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography fontWeight={700}>Моя аптека</Typography>
          <Typography
            fontWeight={700}
            sx={{
              marginRight: 4,
            }}
          >
            {Number.isNaN(mySum) ? 'undef' : mySum}
          </Typography>
        </Grid>

        <Divider />

        {otherSum.map((el, i) => (
          <React.Fragment key={el.competitorId}>
            <Grid
              container
              flexDirection={'row'}
              width={['100%', '50%']}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography width={'80%'}>{el.title || ''}</Typography>
              <Typography
                sx={{
                  marginRight: 4,
                }}
              >
                {Number.isNaN(el.avr) ? 'undef' : el.avr}
              </Typography>
            </Grid>
            {i !== otherSum.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
};
