import { useCallback } from 'react';
import { UseDowloadFileBlob } from './types';
import { useCreateDownloadFileHandler } from './useCreateDownloadFileHandler';

export const useDowloadFileBlob: UseDowloadFileBlob = ({
  filter,
  useLazyDownloadFileFromList,
  createFileName,
}) => {
  const [downloadDocumentExcelFiles, { isLoading }] =
    useLazyDownloadFileFromList();

  const handlerDownloadWithFilter = useCallback(async () => {
    return await downloadDocumentExcelFiles({ qParams: filter }, false);
  }, [downloadDocumentExcelFiles, filter]);

  const { handleDownloadFiles } = useCreateDownloadFileHandler({
    createFileName: createFileName,
    downloadFn: handlerDownloadWithFilter,
  });

  return {
    handleDownloadFiles,
    isLoading,
  };
};
