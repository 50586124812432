// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useStageDraftConfirm } from 'features/visit/use-stage-draft-confirm/useStageDraftConfirm';
import { FC, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  useController,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';

import { useDebounce } from 'shared/hooks/useDebounce';
import { CustomTextField } from 'shared/ui/custom-text-field';
import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';
import { UiAddButton } from 'shared/ui/UiAddButton';
import { UiHeadBlock } from 'shared/ui/UiHeadBlock';

import { useGetCompetitors } from 'entities/visit/options/useGetCompetitors';
import { useSearchProduct } from 'entities/visit/options/useSearchProduct';

import { CompetitorsInput } from '../../CompetitorsInput';
import { CompetitorsProductInfo } from '../../CompetitorsProductInfo';
import { ConfirmStageDialog } from '../../ConfirmDialog/ConfirmStageDialog';
import { NavButtons } from '../../NavButtons';
import { ProductPricesInput } from '../../ProductPricesInput';
import { INIT_FORM_VALUE } from '../constants';
import { InformationAboutCompetitorsFormSchema } from '../schems';
import {
  InformationAboutCompetitorsFormI,
  InformationAboutCompetitorsFormProps,
} from '../type';

export const InformationAboutCompetitorsForm: FC<
  InformationAboutCompetitorsFormProps
> = ({ initValue, onSubmit, retailId, disableAll, onDraftSubmit }) => {
  /* Products */
  const [search, setSearch] = useState('');
  const debSearch = useDebounce(search, 300);
  const { products } = useSearchProduct(debSearch);
  /* === */

  /* Form */
  const { handleSubmit, control, formState, getValues } =
    useForm<InformationAboutCompetitorsFormI>({
      values: initValue,
      resolver: yupResolver(InformationAboutCompetitorsFormSchema),
    });
  const { isDirty, isSubmitting } = formState;

  const { fields, append, remove } = useFieldArray({
    name: 'competitors',
    control,
  });

  const val = useWatch({
    name: fields.map(
      (_, i) => `competitors.${i}.competitor`
    ) as 'competitors.1.competitor'[],
    control,
  });
  /* ==== */

  /* Competitors */
  const { competitors, isLoading: isCompetitorsLoading } =
    useGetCompetitors(retailId);

  const filterOption = useMemo(() => {
    return competitors.filter((el) => !val.some((el2) => el2?.id === el.id));
  }, [val, competitors]);
  /* === */

  /* Draft */
  const {
    handleClose,
    handleConfirm,
    handleNext,
    onSubmit: onSub,
    isLoading: draftLoading,
    isDialogOpen,
  } = useStageDraftConfirm(isDirty, onSubmit, onDraftSubmit);
  /* ==== */

  return (
    <Grid
      container
      component={'form'}
      flexDirection={'column'}
      onSubmit={handleSubmit(onSub)}
      gap={1}
    >
      <ConfirmStageDialog
        handleClose={handleClose}
        handleConfirm={() => {
          handleConfirm(getValues());
        }}
        handleNext={handleNext}
        isLoading={draftLoading}
        isOpen={isDialogOpen}
      />

      <DocumentSectionTitle
        text='Информация о конкурентах'
        sx={{ marginTop: 0, margin: 0 }}
      />

      {/* <TransitionGroup> */}
      {fields.map((filed, index) => (
        // <Collapse key={filed.id}>
        <Grid
          key={filed.id}
          container
          gap={1}
          mb={1}
        >
          <Grid
            container
            ml={[1, 0]}
          >
            <UiHeadBlock
              disable={fields.length === 1 || disableAll}
              onClick={() => remove(index)}
              title={`Конурент №${index + 1}`}
            />
          </Grid>

          <Grid
            container
            gap={2}
          >
            <Controller
              name={`competitors.${index}.competitor`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <CompetitorsInput
                  isLoading={isCompetitorsLoading}
                  isError={!!error}
                  option={value ? [...filterOption, value] : filterOption}
                  onChange={onChange}
                  value={value}
                  disabled={disableAll}
                />
              )}
            />

            <Controller
              name={`competitors.${index}.checksCount`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label='Количество чеков'
                  propsTextField={{
                    placeholder: 'Введите количество чеков',
                    error: !!error,
                    disabled: disableAll,
                    margin: 'none',
                    type: 'number',
                    onVolumeChange: () => {},
                    onChange: (e) => {
                      const value = e.target.value;

                      if (value.length === 0) {
                        onChange(null);

                        return;
                      }

                      const numValue = Number(value);

                      onChange(numValue >= 0 ? numValue : 0);
                    },
                    value: value !== null ? value : '',
                  }}
                />
              )}
            />

            <Grid
              container
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Controller
                name={'product'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CompetitorsProductInfo
                    onChange={onChange}
                    value={value}
                    options={products}
                    onChangeSearch={setSearch}
                    disabled={disableAll}
                  />
                )}
              />

              <Input
                index={index}
                control={control}
                disabled={disableAll}
              />
            </Grid>
          </Grid>
        </Grid>
        // </Collapse>
      ))}
      {/* </TransitionGroup> */}

      <UiAddButton
        sx={{ width: 'fit-content' }}
        disabled={competitors.length <= fields.length || disableAll}
        onClick={() => append(INIT_FORM_VALUE)}
      >
        Добавить конкурента
      </UiAddButton>
      <NavButtons
        // disable={disableAll}
        isLoading={isSubmitting}
      />
    </Grid>
  );
};

interface InputProps {
  index: number;
  control: Control<InformationAboutCompetitorsFormI, any>;
  disabled?: boolean;
}

const Input: FC<InputProps> = ({ index, control, disabled }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: control,
    name: `competitors.${index}.productPrice`,
  });

  return (
    <ProductPricesInput
      disabled={disabled}
      onChange={onChange}
      value={value}
      isError={!!error}
    />
  );
};
