import { Box } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { CalendarDayProps } from './type';

export const CalendarDay: FC<CalendarDayProps> = ({
  day,
  isSelected,
  isToday = false,
  containerProps,
}) => {
  return (
    <Box
      {...containerProps}
      sx={{
        width: 33,
        height: 33,
        borderRadius: '50%',
        backgroundColor: isSelected ? theme.palette.customBlue.main : 'white',
        color: isSelected ? 'white' : 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: isToday ? 'underline' : 'none',
        ...containerProps?.sx,
      }}
    >
      {day}
    </Box>
  );
};
