/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AdaptationManagerRequestsStakeTaskFileCreate,
  AdaptationManagerResponsesGetListPosition,
  AdaptationManagerResponsesGetListStageTaskFile,
} from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { array, object, ObjectSchema } from 'yup';
import { AdaptationTemplateTaskFormFields } from './type';

export const POSITION_SCHEMA: ObjectSchema<AdaptationManagerResponsesGetListPosition> =
  object().shape({
    guid: SCHEMA.STRING.required(),
    title: SCHEMA.STRING.required(),
  });

export const READY_TO_LOAD_FILE_SCHEMA: ObjectSchema<AdaptationManagerRequestsStakeTaskFileCreate> =
  object().shape({
    title: SCHEMA.STRING.required(),
    data: SCHEMA.STRING.required(),
  });

export const LOADED_FILE_SCHEMA: ObjectSchema<AdaptationManagerResponsesGetListStageTaskFile> =
  object().shape({
    guid: SCHEMA.STRING.required(),
    path: SCHEMA.STRING.required(),
    title: SCHEMA.STRING.required(),
  });

export const SCHEMA_TEMPLATE_TASK: ObjectSchema<AdaptationTemplateTaskFormFields> =
  object().shape({
    title: SCHEMA.STRING_REQUIRED,
    description: SCHEMA.STRING.required().nullable(),

    startDaysCount: SCHEMA.NUMBER_POSITIVE.required('*Обязательное поле').test(
      'finishDaysCount',
      '*Дата начала должна быть меньше даты завершения',
      (_, context) => {
        const min = context.from![0].value.startDaysCount as number;
        const max = context.from![0].value.finishDaysCount as number;

        return min <= max;
      }
    ),
    finishDaysCount: SCHEMA.NUMBER_POSITIVE.required('*Обязательное поле'),

    reminderPeriodCreator: SCHEMA.NUMBER_POSITIVE.required().nullable(),
    reminderPeriodExecutor: SCHEMA.NUMBER_POSITIVE.required().nullable(),
    reminderPeriodCoExecutor: SCHEMA.NUMBER_POSITIVE.required().nullable(),
    reminderPeriodObserver: SCHEMA.NUMBER_POSITIVE.required().nullable(),

    isReminderCreator: SCHEMA.BOOLEAN,
    isReminderExecutor: SCHEMA.BOOLEAN,
    isReminderCoExecutor: SCHEMA.BOOLEAN,
    isReminderObserver: SCHEMA.BOOLEAN,

    creatorPosition: POSITION_SCHEMA.nullable(),
    executorPosition: POSITION_SCHEMA.required(),
    coExecutorsPositions: array(POSITION_SCHEMA).required(),
    observersPositions: array(POSITION_SCHEMA).required(),

    addingFiles: array(READY_TO_LOAD_FILE_SCHEMA).required().nullable(),
    deletingFiles: array(LOADED_FILE_SCHEMA).required().nullable(),
    files: array(LOADED_FILE_SCHEMA).required().nullable(),
  });
