import { TaskCreate } from 'shared/api/services/types';

import { STATUSES_NEW_TASK } from 'features/task/form-task/constants/statuses';
import { IFormFastTaskUi } from '../type';

export const prepareDataToFetch = (
  data: IFormFastTaskUi & { originId: number },
  isDraft: boolean
): TaskCreate => {
  const { dateFinish, executor, originId, title } = data;

  return {
    isControl: false,
    isReminderCoExecutor: false,
    isReminderCreator: false,
    isReminderExecutor: false,
    isReminderObserver: false,
    isTarget: false,
    originId: originId,
    statusId: isDraft ? STATUSES_NEW_TASK.DRAFT.id : STATUSES_NEW_TASK.NEW.id,
    executorGuid: executor?.guid,
    dateFinish: dateFinish?.format('YYYY-MM-DDTHH:mm:ss'),
    title: title || 'Без названия',
    observersGuids: [],
    coExecutorsGuids: [],
  };
};
