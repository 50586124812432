import { FC, SVGProps } from 'react';

export const LogoSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 178 178'
    >
      <path
        fill='#116E6B'
        d='M125.331 43.917v-7.24C125.331 16.506 108.825 0 88.653 0 68.482 0 51.976 16.506 51.976 36.677v15.284h72.488c.557-2.522.867-5.198.867-8.044z'
      ></path>
      <path
        fill='#116E6B'
        d='M125.331 51.96v-8.043c0 2.862-.31 5.538-.867 8.044h.867z'
      ></path>
      <path
        fill='#2B9793'
        d='M51.96 132.864c0-2.676.28-5.167.774-7.533h-.773v15.283c0 20.172 16.505 36.677 36.677 36.677s36.677-16.505 36.677-36.677v-15.283h-72.58a36.657 36.657 0 00-.774 7.533z'
      ></path>
      <path
        fill='#116E6B'
        d='M36.677 51.96C16.506 51.96 0 68.467 0 88.639c0 20.172 16.506 36.677 36.677 36.677h15.284V51.961H36.677z'
      ></path>
      <path
        fill='#2B9793'
        d='M140.614 51.96h-15.283v73.371h15.283c20.172 0 36.677-16.506 36.677-36.678 0-20.187-16.505-36.692-36.677-36.692z'
      ></path>
      <path
        fill='#116E6B'
        d='M125.331 51.96h-73.37v73.371c7.286-34.342 65.682-38.89 73.37-73.37z'
      ></path>
      <path
        fill='#2B9793'
        d='M125.331 51.96c-7.688 34.481-66.084 39.03-73.37 73.371h73.37v-73.37z'
      ></path>
      <path
        fill='#F6F6F6'
        d='M125.331 51.96c.247 6.111.371 12.221.464 18.347.108 6.11.092 12.22.139 18.346-.047 6.11-.047 12.221-.139 18.347l-.17 9.173c-.109 3.063-.155 6.11-.294 9.173-.14-3.063-.202-6.11-.294-9.173l-.17-9.173c-.093-6.111-.093-12.22-.14-18.347.047-6.11.047-12.22.14-18.346.092-6.126.201-12.236.464-18.346z'
      ></path>
      <path
        fill='#F6F6F6'
        d='M125.331 125.331c-6.111.247-12.221.371-18.347.464-6.11.108-12.22.093-18.346.139-6.11-.046-12.22-.046-18.346-.139l-9.174-.17c-3.062-.109-6.11-.155-9.173-.294 3.063-.14 6.11-.201 9.173-.294l9.174-.17c6.11-.093 12.22-.093 18.346-.14 6.11.047 12.221.047 18.346.14 6.111.092 12.236.201 18.347.464z'
      ></path>
    </svg>
  );
};
