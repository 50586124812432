import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';

import { useCreateOpeningTemplatesProcessPreparedStageTasksMutation } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

import { RETAIL_OPENING_PROCESS_HELPER_LINKS } from 'entities/retail-opening-process/links';
import { useNavigate } from 'react-router-dom';
import { preparedFormValueStagesToSubmitStageTasks } from '../libs/prepared-form-value-stages-to-submit-stage-tasks';
import { FormPreparedStagesValue } from '../types';

export const useRunOpeningTemplateProcess = (params: {
  countdownDate: string | undefined;
  openingTemplateGuid: string | undefined;
  protocolGuid: string | undefined;
}) => {
  const navigate = useNavigate();
  const { open: openSnackBar } = useSnackbar();
  const [
    runProcess,
    { isLoading: isLoadingSubmiteRunOpeningTemplatesProcess },
  ] = useCreateOpeningTemplatesProcessPreparedStageTasksMutation();

  const { countdownDate, openingTemplateGuid, protocolGuid } = params;

  const handleRunOpeningTemplatesProcess: (
    form: FormPreparedStagesValue
  ) => unknown = useCallback(
    async ({ stages }) => {
      if (!countdownDate || !openingTemplateGuid || !protocolGuid) {
        openSnackBar({
          title: 'Что то пошло не так',
          text: `countdownDate = ${countdownDate}, openingTemplateGuid = ${openingTemplateGuid}, protocolGuid = ${protocolGuid}`,
          type: 'error',
          duration: 3000,
        });

        return;
      }

      runProcess({
        params: {
          countdownDate,
          openingTemplateGuid,
          protocolGuid,
          stageTasks: preparedFormValueStagesToSubmitStageTasks(stages),
        },
      }).then((response) => {
        if ('error' in response) {
          openSnackBar({
            text: getMessageFromFetchBaseQueryError(response.error),
            type: 'error',
            duration: 3000,
          });
        } else {
          openSnackBar({
            text: 'Процесс успешно запущен',
            type: 'success',
            duration: 3000,
          });
          close();
          navigate(
            RETAIL_OPENING_PROCESS_HELPER_LINKS.createLinkRunningProcess({
              openingTemplateGuid,
              processGuid: response.data.guid,
              protocolGuid,
            }),
            { replace: true }
          );
        }
      });
    },
    [runProcess]
  );

  return {
    isLoadingSubmiteRunOpeningTemplatesProcess,
    handleRunOpeningTemplatesProcess,
  };
};
