import { Add } from '@mui/icons-material';
import { FC } from 'react';

import { UiTableCellWithActions } from 'shared/ui/table/ui-table-cell-with-actions';

import { CELL_SX } from '../style';
import { EmptyPlanningRNCellProps } from './type';

export const EmptyPlanningRNCell: FC<EmptyPlanningRNCellProps> = ({
  onCreateConverenceEvent,
  onCreateOtherEvent,
  onCreateVisit,
}) => {
  const emptyCellActions = [
    {
      onClick: onCreateVisit,
      text: 'Добавить посещение',
      icon: <Add sx={{ mr: 2 }} />,
    },
    {
      onClick: onCreateConverenceEvent,
      text: 'Добавить сборы',
      icon: <Add sx={{ mr: 2 }} />,
    },
    {
      onClick: onCreateOtherEvent,
      text: 'Добавить мероприятие',
      icon: <Add sx={{ mr: 2 }} />,
    },
  ];

  return (
    <UiTableCellWithActions
      colSpan={2}
      sx={{ ...CELL_SX }}
      actions={emptyCellActions}
    />
  );
};
