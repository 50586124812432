import { Lock, LockOpen } from '@mui/icons-material';
import { alpha, Typography } from '@mui/material';
import { SurfaceRetail } from 'shared/api/marketing-surface/marketingSurfaceAutogenerateApi';

export const retailsSurfaceSortOptions = (
  a: SurfaceRetail,
  b: SurfaceRetail
) => {
  if (a.departmentTitle === b.departmentTitle) {
    const index = a.title.localeCompare(b.title);

    return index;
  }

  return a.departmentTitle > b.departmentTitle ? 1 : -1;
};

export const retailSurfaceGroupBy = (option: SurfaceRetail) =>
  option.departmentTitle;

export const retailSurfaceGetOptionLabel = (option: SurfaceRetail) =>
  option.title;

export const retailSurfaceIsOptionEqualToValue = (
  option: SurfaceRetail,
  value: SurfaceRetail
) => option.guid === value.guid;

export const retailSurfaceRenderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: SurfaceRetail
) => {
  const title = option.title;

  return (
    <Typography
      {...props}
      sx={(theme) => ({
        ...(option.surfaceUpdateDaysExpired && {
          backgroundColor: alpha(theme.palette.error.main, 0.3),
        }),
      })}
      key={option.guid}
    >
      {option.isLocked ? <Lock sx={SX_ICON} /> : <LockOpen sx={SX_ICON} />}
      {title}
    </Typography>
  );
};

const SX_ICON = { marginRight: 1 };
