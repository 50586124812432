import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { UiCalendarCellContainer } from '../ui-calendar-cell-container';
import { UiCalendareHeadCellProps } from './type';

export const UiCalendareHeadCell: FC<UiCalendareHeadCellProps> = ({
  children,
  containerProps,
}) => {
  return (
    <UiCalendarCellContainer
      containerProps={{
        ...containerProps,
        sx: {
          backgroundColor: theme.palette.customGreen.main,
          ...containerProps?.sx,
        },
      }}
    >
      {children}
    </UiCalendarCellContainer>
  );
};
