import { GetVisitingsActionQueryParams } from 'shared/api/visit/types';

import { convertURLToObject } from '../convertURLToObject';
import { ConvertMap, ConvertTypes, URLToObjConverter } from '../type';

type VisitingsFilterTypeWithoutPagination = Omit<
  GetVisitingsActionQueryParams,
  'Limit' | 'Page'
>;
export const convertURLToVisitFilterWithoutPagination: URLToObjConverter<
  VisitingsFilterTypeWithoutPagination
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_VISIT_FILTER_WITHOUT_PAGINATION_MAP
  ) as VisitingsFilterTypeWithoutPagination;
};

export const CONVERT_VISIT_FILTER_WITHOUT_PAGINATION_MAP: ConvertMap = [
  {
    key: 'BeginDate',
    type: ConvertTypes.string,
  },
  {
    key: 'EndDate',
    type: ConvertTypes.string,
  },
  {
    key: 'SearchText',
    type: ConvertTypes.string,
  },
  {
    key: 'ChecklistStatuses',
    type: ConvertTypes.masNumber,
  },
  {
    key: 'VisitingDepartmentsIds',
    type: ConvertTypes.masNumber,
  },
  {
    key: 'VisitingDivisionsIds',
    type: ConvertTypes.masNumber,
  },
  {
    key: 'VisitingRegionsIds',
    type: ConvertTypes.masNumber,
  },
  {
    key: 'VisitingRetailsIds',
    type: ConvertTypes.masNumber,
  },
  {
    key: 'VisitorDepartmentsIds',
    type: ConvertTypes.masNumber,
  },
  {
    key: 'VisitorIds',
    type: ConvertTypes.masNumber,
  },
  {
    key: 'VisitorOfficesIds',
    type: ConvertTypes.masNumber,
  },
  {
    key: 'VisitorRegionsIds',
    type: ConvertTypes.masNumber,
  },
  {
    key: 'VisitorPositionsIds',
    type: ConvertTypes.masNumber,
  },
];
