import { useTransfromUrlStateToBaseFormFilter } from '../../hooks';
import { useUrlStateDocumentObserveFilter } from '../use-url-state-document-observe-filter';

import { useLazyGetDocumentFlowObserveFiltersQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';

import { findElementsByIds } from 'shared/tarnsform-helper/findElementsByIds';
import { EMPT_ARR } from 'widgets/training/filter/constants';

import {
  filterByFilterItemTypeByDepartments,
  filterByFilterItemTypeByDirections,
  filterByFilterItemTypeByOrgDepartments,
  filterByFilterItemTypeByRegions,
} from '../libs';
import { DocumentFlowFilterObserveForm } from '../type';

export const useInitialObserveFilterFormFromUrlState = () => {
  const { filter } = useUrlStateDocumentObserveFilter();
  const { initBaseFilter, isLoadingAnyLaziOptionsDocumentFlow } =
    useTransfromUrlStateToBaseFormFilter();
  const [fetchObserveFiltersOptions] =
    useLazyGetDocumentFlowObserveFiltersQuery();

  const onInitial = async (): Promise<DocumentFlowFilterObserveForm> => {
    const [baseFilters, observeFiltersOptions] = await Promise.all([
      initBaseFilter(filter),
      fetchObserveFiltersOptions(undefined, true).unwrap(),
    ]);

    return {
      ...baseFilters,
      positions: findElementsByIds(
        observeFiltersOptions.positions,
        filter.OrgPositionIds || EMPT_ARR
      ),
      employees: findElementsByIds(
        observeFiltersOptions.employees,
        filter.EmployeeIds || EMPT_ARR
      ),
      offices: findElementsByIds(
        observeFiltersOptions.offices,
        filter.OrgOfficeIds || EMPT_ARR
      ),
      departmentsAndDepts: [
        ...findElementsByIds(
          filterByFilterItemTypeByOrgDepartments(
            observeFiltersOptions.departmentsAndDepts
          ),
          filter.OrgDepartmentIds || EMPT_ARR
        ),
        ...findElementsByIds(
          filterByFilterItemTypeByDepartments(
            observeFiltersOptions.departmentsAndDepts
          ),
          filter.DepartmentIds || EMPT_ARR
        ),
      ],
      directionsAndRegions: [
        ...findElementsByIds(
          filterByFilterItemTypeByDirections(
            observeFiltersOptions.directionsAndRegions
          ),
          filter.OrgDirectionIds || EMPT_ARR
        ),
        ...findElementsByIds(
          filterByFilterItemTypeByRegions(
            observeFiltersOptions.directionsAndRegions
          ),
          filter.RegionIds || EMPT_ARR
        ),
      ],
    };
  };

  return [onInitial, isLoadingAnyLaziOptionsDocumentFlow] as const;
};
