import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { BoardTaskFilterForm } from 'features/boards/forms/BoardTaskFilterForm/BoardTaskFilterForm';
import { FC } from 'react';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { BoardTaskFilterDialogProps } from './type';

export const BoardTaskFilterDialog: FC<BoardTaskFilterDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initValue,
  isLoading,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Фильтр задач' />}
    >
      <BoardTaskFilterForm
        onSubmit={onSubmit}
        initForm={initValue}
        renderFormActions={() => [
          <LoadingButton
            loading={isLoading}
            variant='contained'
            type='submit'
            key={1}
          >
            Применить
          </LoadingButton>,
          <Button
            variant='contained'
            color='customGrey'
            onClick={onClose}
            key={2}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
