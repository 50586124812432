import { ObserveFilterItemModel } from 'shared/api/services-document-flow/types';
import { ObserveFilterItemModelWithMyKeys } from '../type';
import { observeFilterItemModelWithMyKeysToStringKeyArray } from './observeFilterItemModelWithMyKeysToStringKeyArray';
import { prepareObserveFilterItemModalWithMyKeys } from './prepareObserveFilterModalWithMyKeys';

export const filteredNewValues = (
  values: ObserveFilterItemModel[],
  allowedOptions: ObserveFilterItemModelWithMyKeys[]
) =>
  values
    .map(prepareObserveFilterItemModalWithMyKeys)
    .filter((item) =>
      observeFilterItemModelWithMyKeysToStringKeyArray(allowedOptions).includes(
        item.myKey
      )
    );
