import { FC } from 'react';

import { OverPageSpeedDial } from 'shared/ui/over-page-speed-dial';

import { TASK_PAGE_SPEED_DIAL_MAP } from './constant';
import { TaskPageSpeedDialProps } from './type';

export const TaskPageSpeedDial: FC<TaskPageSpeedDialProps> = ({
  onAddFile,
  onEdit,
  onNotify,
  onDelete,
}) => {
  const map = TASK_PAGE_SPEED_DIAL_MAP.map((el) => {
    switch (el.tooltip) {
      case 'Редактировать':
        return { ...el, action: onEdit };
      case 'Напоминания':
        return { ...el, action: onNotify };
      case 'Прикрепить файл':
        return { ...el, action: onAddFile };
      case 'Удалить':
        return { ...el, action: onDelete };
      default:
        return el;
    }
  });

  return <OverPageSpeedDial actionMap={map} />;
};
