import { RetailGetListResponseNew } from 'features/visit/Stage 1/type';

import { isEqDay } from 'shared/date-helper/isEqDay';
import { UniversalDate } from 'shared/date-helper/type';

export const findBlockedRetails = (
  retails: RetailGetListResponseNew[],
  selectedDate: UniversalDate
) => {
  const filtered = retails
    .filter(
      (retail) =>
        retail.allowedDates &&
        !retail.allowedDates.some((allowedDate) =>
          isEqDay(allowedDate, selectedDate)
        )
    )
    .map((retail) => retail.id);

  return filtered.length === 0 ? null : filtered;
};
