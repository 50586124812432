// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { createApi, createEvent, createStore, sample } from 'effector';

import { GetDocumentsObserveQueryParams } from 'shared/api/services-document-flow/types';
import { paginationTasks } from 'shared/types/pagination-type';

import { getDocumentsObserveFx } from 'entities/document/api/document-list-effector';

import { defaultFilter } from './constant';

const $documentObserverPaginations = createStore<paginationTasks>(
  {
    CurrentPage: 0,
    HasNext: false,
    HasPrevious: false,
    PageSize: 20,
    TotalCount: 0,
    TotalPages: 0,
  },
  { name: 'document-observe-pagination-filter' }
);

$documentObserverPaginations.on(getDocumentsObserveFx.done, (_, { result }) => {
  return JSON.parse(result.headers['x-pagination']) as paginationTasks;
});

const $documentObserverFilter = createStore<GetDocumentsObserveQueryParams>(
  defaultFilter,
  {
    name: 'document-observe-filter',
  }
);
const apiFilter = createApi($documentObserverFilter, {
  setAll: (filter, payload: GetDocumentsObserveQueryParams) => {
    return payload;
  },
});
const upDateList = createEvent();

sample({ clock: $documentObserverFilter, target: getDocumentsObserveFx });
sample({
  clock: upDateList,
  source: $documentObserverFilter,
  target: getDocumentsObserveFx,
});

export const documentObserverFilter = {
  filter: $documentObserverFilter,
  events: apiFilter,
  upDateList,
  headerPaginations: $documentObserverPaginations,
};
