import { getTasksTaskGuidFilesFileGuid } from 'shared/api/services/services';

export const downloadTaskFile = async (params: {
  taskGuid: string;
  fileGuid: string;
  fileName: string;
}) => {
  const { fileGuid, fileName, taskGuid } = params;

  return await getTasksTaskGuidFilesFileGuid(taskGuid, fileGuid, {
    responseType: 'blob',
  })
    .then((response) => {
      return response.data;
    })
    .then((blob) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${fileName}`);

      // 3. Append to html pageF
      document.body.appendChild(link);

      // 4. Force download
      link.click();
    });
};
