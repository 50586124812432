// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */
// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { createApi, createEvent, createStore, sample } from 'effector';
import { useStoreMap } from 'effector-react';
import { CombineArticle } from 'features/document/document-form-articles-autocomplite';

import {
  DocumentAgreeProcessActionResponse,
  DocumentArticleExpenseModel,
  DocumentArticleModel,
  DocumentArticleSecondValidateRequest,
  DocumentFirstValidationResponse,
  DocumentModel,
  DocumentSecondValidateRequest,
  DocumentSecondValidationResponse,
  DocumetnDoActionRequest,
  EstimateDocumentArticleModel,
  RetailModel,
} from 'shared/api/services-document-flow/types';

import { UseSelectoreArticleFirstValid } from 'entities/document-form';

import { documentFormEditApi } from './api';
import { $documentEditAgreeChain } from './document-form-edit-agree-chain/DocumentFormEditAgreeChain';
import {
  getQueryForFirstValidFromDocumentModel,
  mapDocumentSecondValidationResponseToDocumentArticleModel,
  prependDocumentArticlesModelToDocumentArticleSecondValidateRequest,
  prependDocumentModelToPutDocumentBody,
  setFieldExpense,
} from './lib';
import {
  setFiled,
  useSelectArticleExpenseValue,
  useSelectArticleIdFormEdit,
  useSelectProcessValueOfKey,
  useSelectValueOfKey,
} from './selects';
import { ParamsPutDocument } from './type';

/* DOCUMENT_FORM_EDIT */
const $documentFormEdit = createStore<null | DocumentModel>(null, {
  name: '$documentFormEdit',
});

const resetDocumentFormEdit = createEvent('resetDocumentFormEdit');

$documentFormEdit.on(
  documentFormEditApi.getDocument.doneData,
  (_, response) => {
    return { ...response.data };
  }
);
$documentFormEdit.on(
  documentFormEditApi.refetchDocumentIdEdit.doneData,
  (_, response) => {
    return { ...response.data };
  }
);

const api = createApi($documentFormEdit, {
  setFiled,
  setArticles: (document, articles: CombineArticle[]) => {
    if (!document) return;

    const newArticles = articles.map((art) => {
      const findArticleOnId = document.documentArticles?.find(
        (item) => item.articleId === art.id
      );

      if (findArticleOnId) {
        return { ...findArticleOnId };
      } else {
        return {
          articleId: art.id,
          expenses: [],
          sum: 0,
          estimateDocumentArticle: {
            estimateDocumentArticleBalance: 0,
            estimateDocumentArticleId: 0,
            estimateDocumentArticleSum: 0,
            estimateDocumentTitle: '',
            estimateDocumentId: 0,
          },
          documentArticleExpenses: [],
          articleTitle: '',
          retail: {
            id: 0,
            isMainGroup: false,
            groupTitle: '',
            title: '',
          },
        };
      }
    });

    return { ...document, documentArticles: newArticles };
  },
  setSumArticleId: (document, article: { id: number; sum: number }) => {
    if (!document) return document;

    return {
      ...document,
      documentArticles: document.documentArticles?.map((item) => {
        if (item.articleId === article.id) {
          return { ...item, sum: article.sum };
        }

        return item;
      }),
    };
  },
  setRetailIdArticleId: (
    document,
    payload: { id: number; retail: RetailModel | null }
  ) => {
    if (!document) return document;

    const newDoc = {
      ...document,
      documentArticles: document.documentArticles?.map((item) => {
        if (item.articleId === payload.id) {
          const newItem = {
            ...item,
            retail: payload.retail,
            estimateDocumentArticle: null,
          };

          return newItem;
        }

        return item;
      }),
    };

    return newDoc as DocumentModel;
  },
  setEstimateDocumentArticle: (
    document,
    article: {
      id: number;
      estimateDocumentArticle: EstimateDocumentArticleModel | null;
    }
  ) => {
    if (!document) return document;

    return {
      ...document,
      documentArticles: document.documentArticles?.map((item) => {
        if (item.articleId === article.id) {
          return {
            ...item,
            estimateDocumentArticle: article.estimateDocumentArticle,
          };
        }

        return item;
      }),
    } as DocumentModel;
  },
  deleteTypeExpensions: (
    document,
    payload: {
      idArticle: number;
      indexExpenses: number;
    }
  ) => {
    if (!document) return document;

    const newArticle = document.documentArticles?.map((article) => {
      if (article.articleId === payload.idArticle) {
        if (article.documentArticleExpenses) {
          return {
            ...article,
            documentArticleExpenses: article.documentArticleExpenses.filter(
              (item, index) => index !== payload.indexExpenses
            ),
          };
        } else {
          return { ...article };
        }
      }

      return article;
    });

    return { ...document, documentArticles: newArticle };
  },
  addedTypeArticles: (document, id: number): DocumentModel | null => {
    if (document) {
      const newArticle: DocumentArticleModel[] | undefined =
        document.documentArticles?.map((article) => {
          if (article.articleId === id) {
            const newExpenses = {} as DocumentArticleExpenseModel;

            return {
              ...article,
              documentArticleExpenses: [
                ...(article.documentArticleExpenses || []),
                newExpenses,
              ],
            };
          }

          return article;
        });

      return { ...document, documentArticles: newArticle };
    } else {
      return null;
    }
  },
  removeArticleidExpenseZero: (document): DocumentModel | null => {
    if (document) {
      return {
        ...document,
        documentArticles: document.documentArticles.map((item) => {
          const filterExpenses = item.documentArticleExpenses?.filter(
            (expenses) => expenses.expenseId !== 0
          );

          return {
            ...item,
            documentArticleExpenses:
              filterExpenses && filterExpenses.length > 0
                ? filterExpenses
                : (undefined as unknown as DocumentArticleExpenseModel[]),
          };
        }),
      };
    } else {
      return document;
    }
  },
  setFieldExpense,
  /* AdditionallyFields */
  setAdditionallyFieldString: (
    document,
    fild: { id: number; value: string }
  ) => {
    if (!document) return document;
    if (document.stringAdditionallyFields) {
      return {
        ...document,
        stringAdditionallyFields: document?.stringAdditionallyFields?.map(
          (item) => {
            if (item.processAdditionallyField.id === fild.id) {
              return { ...item, value: fild.value };
            } else {
              return item;
            }
          }
        ),
      };
    }
  },
  setAdditionallyFieldInt: (document, fild: { id: number; value: number }) => {
    if (!document) return document;
    if (document.intAdditionallyFields) {
      return {
        ...document,
        intAdditionallyFields: document?.intAdditionallyFields?.map((item) => {
          if (item.processAdditionallyField.id === fild.id) {
            return { ...item, value: fild.value };
          } else {
            return item;
          }
        }),
      };
    }
  },
  setAdditionallyFieldDec: (document, fild: { id: number; value: number }) => {
    if (!document) return document;
    if (document.decimalAdditionallyFields) {
      return {
        ...document,
        decimalAdditionallyFields: document?.decimalAdditionallyFields?.map(
          (item) => {
            if (item.processAdditionallyField.id === fild.id) {
              return { ...item, value: fild.value };
            } else {
              return item;
            }
          }
        ),
      };
    }
  },
  setAdditionallyFieldPeriod: (
    document,
    fild: {
      id: number;
      key: 'begValue' | 'endValue';
      value: string | undefined;
    }
  ) => {
    if (!document) return null;
    if (document.stringAdditionallyFields) {
      return {
        ...document,
        periodAdditionallyFields: document?.periodAdditionallyFields?.map(
          (item) => {
            if (item.processAdditionallyField.id === fild.id) {
              return { ...item, [fild.key]: fild.value };
            } else {
              return item;
            }
          }
        ),
      };
    }
  },
  setAdditionallyFieldDate: (
    document,
    fild: { id: number; value: string | undefined }
  ) => {
    if (!document) return document;
    if (document.dateTimeAdditionallyFields) {
      return {
        ...document,
        dateTimeAdditionallyFields: document?.dateTimeAdditionallyFields?.map(
          (item) => {
            if (item.processAdditionallyField.id === fild.id) {
              return { ...item, value: fild.value };
            } else {
              return item;
            }
          }
        ),
      };
    }
  },
});

/*MAPPING_ADDITIONALY_FIELDS*/
$documentFormEdit.on(
  documentFormEditApi.getAdditionallyFields.doneData,
  (document, response) => {
    if (!document) return document;

    const stringAdditionallyFields = document.stringAdditionallyFields || [];

    if (response.data.stringAdditionallyFields) {
      for (
        let index = 0;
        index < response.data.stringAdditionallyFields.length;
        index++
      ) {
        const element = response.data.stringAdditionallyFields[index];

        if (
          stringAdditionallyFields &&
          !stringAdditionallyFields.some(
            (item) => item.processAdditionallyField.id === element.id
          )
        ) {
          stringAdditionallyFields.push({
            id: 0,
            value: undefined,
            processAdditionallyField: {
              id: element.id,
              title: element.title || 'undefined',
              isRequired: element.isRequired,
              isArray: element.isArray,
            },
          });
        }
      }
    }

    const intAdditionallyFields = document.intAdditionallyFields || [];

    if (response.data.intAdditionallyFields) {
      for (
        let index = 0;
        index < response.data.intAdditionallyFields.length;
        index++
      ) {
        const element = response.data.intAdditionallyFields[index];

        if (
          intAdditionallyFields &&
          !intAdditionallyFields.some(
            (item) => item.processAdditionallyField.id === element.id
          )
        ) {
          intAdditionallyFields.push({
            id: 0,
            value: undefined,
            processAdditionallyField: {
              id: element.id,
              title: element.title || 'undefined',
              isRequired: element.isRequired,
              isArray: element.isArray,
            },
          });
        }
      }
    }

    const decimalAdditionallyFields = document.decimalAdditionallyFields || [];

    if (response.data.decimalAdditionallyFields) {
      for (
        let index = 0;
        index < response.data.decimalAdditionallyFields.length;
        index++
      ) {
        const element = response.data.decimalAdditionallyFields[index];

        if (
          decimalAdditionallyFields &&
          !decimalAdditionallyFields.some(
            (item) => item.processAdditionallyField.id === element.id
          )
        ) {
          decimalAdditionallyFields.push({
            id: 0,
            value: undefined,
            processAdditionallyField: {
              id: element.id,
              title: element.title || 'undefined',

              isRequired: element.isRequired,
              isArray: element.isArray,
            },
          });
        }
      }
    }

    const periodAdditionallyFields = document.periodAdditionallyFields || [];

    if (response.data.periodAdditionallyFields) {
      for (
        let index = 0;
        index < response.data.periodAdditionallyFields.length;
        index++
      ) {
        const element = response.data.periodAdditionallyFields[index];

        if (
          periodAdditionallyFields &&
          !periodAdditionallyFields.some(
            (item) => item.processAdditionallyField.id === element.id
          )
        ) {
          periodAdditionallyFields.push({
            id: 0,
            begValue: undefined,
            endValue: undefined,
            processAdditionallyField: {
              id: element.id,
              title: element.title || 'undefined',

              isRequired: element.isRequired,
              isArray: element.isArray,
            },
          });
        }
      }
    }

    const dateTimeAdditionallyFields =
      document.dateTimeAdditionallyFields || [];

    if (response.data.dateTimeAdditionallyFields) {
      for (
        let index = 0;
        index < response.data.dateTimeAdditionallyFields.length;
        index++
      ) {
        const element = response.data.dateTimeAdditionallyFields[index];

        if (
          dateTimeAdditionallyFields &&
          !dateTimeAdditionallyFields.some(
            (item) => item.processAdditionallyField.id === element.id
          )
        ) {
          dateTimeAdditionallyFields.push({
            id: 0,
            value: undefined,
            processAdditionallyField: {
              id: element.id,
              title: element.title || 'undefined',

              isRequired: element.isRequired,
              isArray: element.isArray,
            },
          });
        }
      }
    }

    return {
      ...document,
      stringAdditionallyFields: [...stringAdditionallyFields],
      intAdditionallyFields: [...intAdditionallyFields],
      decimalAdditionallyFields: [...decimalAdditionallyFields],
      periodAdditionallyFields: [...periodAdditionallyFields],
    };
  }
);

/* FIRST_VALIDATION */
const $firstdValid = createStore<null | DocumentFirstValidationResponse>(null, {
  name: 'form-edit-first-valid',
});

$firstdValid.on(
  documentFormEditApi.firstValidation.doneData,
  (_, response) => response.data
);
$firstdValid.on(
  documentFormEditApi.initialFirstValidation.doneData,
  (_, response) => response.data
);

const useSelectorArticleIdFirstValid: UseSelectoreArticleFirstValid = (
  id,
  key
) => {
  return useStoreMap($firstdValid, (firstValid) => {
    const article = firstValid?.documentArticles?.find(
      (item) => item.id === id
    );

    if (article) return article[key];
  });
};

/* SECOND_VALIDATE */
const $secondValid = createStore<null | DocumentSecondValidationResponse>(
  null,
  { name: 'form-edit-second-valid' }
);

$secondValid.on(
  documentFormEditApi.secondValitation.doneData,
  (_, response) => response.data
);
$secondValid.on(
  documentFormEditApi.initialSecondaryValidation.doneData,
  (_, response) => response.data
);
$documentFormEdit.on(
  documentFormEditApi.secondValitation.doneData,
  (document, response) => {
    if (!document) return null;

    return {
      ...document,
      documentArticles: response.data.documentArticles
        ? mapDocumentSecondValidationResponseToDocumentArticleModel(
            response.data.documentArticles
          )
        : [],
      totalSum: response.data.totalSum,
    };
  }
);

const onSecondValidation = createEvent<any>();

/* AgreeChain */
const $agreeChain = createStore<DocumentAgreeProcessActionResponse[]>([]);

$agreeChain.on(
  documentFormEditApi.getAgreeChain.doneData,
  (_, response) => response.data
);

/* Store for delete reason files */
const $deleteReasonFiles = createStore<number[]>([], {
  name: 'document-form-edit-deleted-files',
});
const addedFileToDeleteStore = createEvent<number>('addedFileToDeleteStore');
const removeFilesFromDeleteStore = createEvent<number>(
  'removeFilesFromDeleteStore'
);

$deleteReasonFiles.on(addedFileToDeleteStore, (store, id) => [...store, id]);
$deleteReasonFiles.on(removeFilesFromDeleteStore, (store, id) =>
  store.filter((item) => item !== id)
);

/* files-reason */
const $filesReason = createStore<File[]>([], {
  name: 'document-form-edit-files-reason',
});
const ApiFilesReason = createApi($filesReason, {
  addFile: (files, file: File) => {
    return [...files, file];
  },
  deleteFile: (files, file: File) => {
    return files.filter((item) => item.name !== file.name);
  },
});
/* Store for delete close files */
const $deleteCloseFiles = createStore<number[]>([], {
  name: 'document-form-edit-deleted-files-close',
});
const addedFileToDeleteCloseStore = createEvent<number>(
  'addedFileToDeleteStore'
);
const removeFilesFromDeletCloseStore = createEvent<number>(
  'removeFilesFromDeleteStore'
);

$deleteCloseFiles.on(addedFileToDeleteCloseStore, (store, id) => [
  ...store,
  id,
]);
$deleteCloseFiles.on(removeFilesFromDeletCloseStore, (store, id) =>
  store.filter((item) => item !== id)
);

/* files-close */
const resetFileClose = createEvent();
const $filesClose = createStore<File[]>([], {
  name: 'document-form-edit-files-close',
});
const ApiFilesClose = createApi($filesClose, {
  addFile: (files, file: File) => {
    return [...files, file];
  },
  deleteFile: (files, file: File) => {
    return files.filter((item) => item.name !== file.name);
  },
});

/* validation for get documents */
sample({
  clock: documentFormEditApi.getDocument.doneData,
  fn: (response) => {
    return getQueryForFirstValidFromDocumentModel(response.data);
  },
  target: documentFormEditApi.initialFirstValidation,
});
sample({
  clock: documentFormEditApi.initialFirstValidation.doneData,
  source: $documentFormEdit,
  filter: (source) => Boolean(source),
  fn: (document) => {
    const query: DocumentSecondValidateRequest = {
      organizationId: document!.organization.id,
      processId: document!.process.id,
      articles:
        prependDocumentArticlesModelToDocumentArticleSecondValidateRequest(
          document!.documentArticles
        ) as DocumentArticleSecondValidateRequest[],

      documentId: document!.id,
    };

    return query;
  },
  target: documentFormEditApi.initialSecondaryValidation,
});
/* validition for events */
sample({ clock: onSecondValidation, source: $documentFormEdit });
sample({
  clock: onSecondValidation,
  source: $documentFormEdit,
  filter: (document) =>
    Boolean(
      document &&
        document.organization.id &&
        document.documentArticles &&
        document.documentArticles?.length > 0 &&
        document.process.id
    ),
  fn: (document): DocumentSecondValidateRequest => {
    const { organization, process, documentArticles, beginPeriod, endPeriod } =
      document as DocumentModel;

    return {
      organizationId: organization.id as number,
      processId: process.id as number,
      articles: documentArticles
        ? (prependDocumentArticlesModelToDocumentArticleSecondValidateRequest(
            document!.documentArticles
          ) as DocumentArticleSecondValidateRequest[])
        : (undefined as unknown as DocumentArticleSecondValidateRequest[]),
      beginPeriod,
      endPeriod,
      documentId: document!.id,
    };
  },
  target: documentFormEditApi.secondValitation,
});
sample({
  clock: api.setFiled,
  source: $documentFormEdit,
  filter: (document, setFiled) =>
    Boolean(
      document &&
        document.organization.id &&
        document.documentArticles &&
        document.documentArticles?.length > 0 &&
        document.process.id
    ) && Boolean(setFiled.key === 'organization'),
  fn: (document): DocumentSecondValidateRequest => {
    const { organization, process, documentArticles, beginPeriod, endPeriod } =
      document as DocumentModel;

    return {
      organizationId: organization.id as number,
      processId: process.id as number,
      articles:
        prependDocumentArticlesModelToDocumentArticleSecondValidateRequest(
          documentArticles
        ) as DocumentArticleSecondValidateRequest[],

      beginPeriod,
      endPeriod,
      documentId: document!.id,
    };
  },
  target: documentFormEditApi.secondValitation,
});

/* _________________________________________________________ */
/* сохранение изменений в документе*/
const putDocument = createEvent<boolean>(); //saved
const $isNeedDoActionDocuments = createStore<boolean>(false); //

$isNeedDoActionDocuments.on(
  putDocument,
  (_, isNeedDoActionDocuments) => isNeedDoActionDocuments
);

//  Запускаем сохранение документа
const $progressPutDocument = createStore<{
  isLoading: boolean;
  isComplite: boolean;
  error: null | any;
}>(
  { isLoading: false, isComplite: false, error: null },
  { name: '$progressSaveDocument' }
);

sample({
  clock: putDocument,
  source: { document: $documentFormEdit, agreeChain: $documentEditAgreeChain },
  filter: (source) => Boolean(source.document),
  fn: ({ document, agreeChain }) => {
    const params: ParamsPutDocument = {
      documentId: document!.id,
      body: {
        ...prependDocumentModelToPutDocumentBody(document as DocumentModel),
        agreeChain: agreeChain.map(
          ({ employeeId, orderNumber, isProcessAgreeChain }) => ({
            employeeId,
            orderNumber,
            isProcessAgreeChain,
          })
        ),
      },
    };

    return params;
  },
  target: documentFormEditApi.putDocument,
});
$progressPutDocument.on(
  documentFormEditApi.putDocument.pending,
  (state, isLoading) => ({ ...state, isLoading })
);
$progressPutDocument.on(documentFormEditApi.putDocument.doneData, (state) => ({
  ...state,
  isComplite: true,
  error: null,
}));
$progressPutDocument.on(
  documentFormEditApi.putDocument.failData,
  (state, error) => ({ ...state, error })
);
//  ------------------------------

// Сохранени reason-files
const saveReasonFiles = createEvent(); ///saved files reason
const $isNeedSaveReasonFiles = sample({
  clock: $filesReason,
  fn: (files) => files.length > 0,
});
const $progressSaveReasonDocument = createStore<
  { isLoading: boolean; isComplite: boolean; error: null | any; name: string }[]
>([], {
  name: '$progressSaveDocument',
});

sample({
  clock: documentFormEditApi.putDocument.doneData,
  target: saveReasonFiles,
});
sample({
  clock: saveReasonFiles,
  source: {
    files: $filesReason,
    document: $documentFormEdit,
    isNeed: $isNeedSaveReasonFiles,
  },
  filter: ({ isNeed }) => isNeed,
  fn: ({ files, document }) => {
    return { id: document!.id, files };
  },
  target: documentFormEditApi.postAllDocumentsDocumentIdFilesReasonDocuments,
});
$progressSaveReasonDocument.on(
  documentFormEditApi.postDocumentsDocumentIdFileReasonDocuments,
  (store, params) => {
    return store.map((item) => {
      if (item.name === params.file.name) {
        return { ...item, isLoading: true };
      }

      return item;
    });
  }
);

$progressSaveReasonDocument.on(ApiFilesReason.addFile, (store, params) => {
  return [
    ...store,
    { isLoading: false, isComplite: false, name: params.name, error: null },
  ];
});
$progressSaveReasonDocument.on(ApiFilesReason.deleteFile, (store, params) => {
  return store.filter((item) => item.name !== params.name);
});
$progressSaveReasonDocument.on(
  documentFormEditApi.postDocumentsDocumentIdFileReasonDocuments.done,
  (store, request) => {
    return store.map((item) => {
      if (item.name === request.params.file.name) {
        return { ...item, isLoading: false, isComplite: true, error: null };
      }

      return item;
    });
  }
);
$progressSaveReasonDocument.on(
  documentFormEditApi.postDocumentsDocumentIdFileReasonDocuments.fail,
  (store, request) => {
    return store.map((item) => {
      if (item.name === request.params.file.name) {
        return {
          ...item,
          isLoading: false,
          isComplite: false,
          error: request.error.response?.data,
        };
      }

      return item;
    });
  }
);
// --------------------------------

// Удаление reason-files
const deletedReasonFiles = createEvent(); //deleted files reason
const repeatDeleteReasonFiles = createEvent<number>();
const $isNeedDeleteReasonFiles = sample({
  clock: $deleteReasonFiles,
  fn: (files) => files.length > 0,
});
const $progressDeleteReasonDocument = createStore<
  { isLoading: boolean; isComplite: boolean; error: null | any; id: number }[]
>([], {
  name: '$progressDeleteReasonDocument',
});

/* Delete all reason files for id in store deleted */
sample({
  clock: documentFormEditApi.putDocument.doneData,
  target: deletedReasonFiles,
});
sample({
  clock: deletedReasonFiles,
  source: {
    files: $deleteReasonFiles,
    document: $documentFormEdit,
    isNeed: $isNeedDeleteReasonFiles,
  },
  filter: ({ isNeed }) => isNeed,
  fn: ({ files, document }) => {
    return { documentId: document!.id, files };
  },
  target: documentFormEditApi.deleteReasonFilesDocument,
});
/* Repeat delete reason files for id in store deleted */
sample({
  clock: repeatDeleteReasonFiles,
  source: { document: $documentFormEdit },
  fn: ({ document }, fileId) => {
    return { documentId: document!.id, fileId };
  },
  target: documentFormEditApi.deleteReasonFileDocument,
});
$progressDeleteReasonDocument.on(removeFilesFromDeleteStore, (store, id) => {
  return store.filter((item) => item.id !== id);
});
$progressDeleteReasonDocument.on(
  documentFormEditApi.deleteReasonFileDocument,
  (store, params) => {
    return store.map((item) => {
      if (item.id === params.fileId) {
        return { ...item, isLoading: true };
      }

      return item;
    });
  }
);
$progressDeleteReasonDocument.on(addedFileToDeleteStore, (store, params) => {
  return [
    ...store,
    { isLoading: false, isComplite: false, id: params, error: null },
  ];
});
$progressDeleteReasonDocument.on(
  removeFilesFromDeleteStore,
  (store, params) => {
    return store.filter((item) => item.id !== params);
  }
);
$progressDeleteReasonDocument.on(
  documentFormEditApi.deleteReasonFileDocument.done,
  (store, request) => {
    return store.map((item) => {
      if (item.id === request.params.fileId) {
        return { ...item, isLoading: false, isComplite: true, error: null };
      }

      return item;
    });
  }
);
$progressDeleteReasonDocument.on(
  documentFormEditApi.deleteReasonFileDocument.fail,
  (store, request) => {
    return store.map((item) => {
      if (item.id === request.params.fileId) {
        return {
          ...item,
          isLoading: false,
          isComplite: false,
          error: request.error.response?.data,
        };
      }

      return item;
    });
  }
);
// --------------------------------

// Сохранени close-files
const saveCloseFiles = createEvent(); ///saved files reason
const $isNeedSaveCloseFiles = sample({
  clock: $filesClose,
  fn: (files) => files.length > 0,
});
const $progressSaveCloseFiles = createStore<
  { isLoading: boolean; isComplite: boolean; error: null | any; name: string }[]
>([], {
  name: '$progressSaveDocument',
});

sample({
  clock: documentFormEditApi.putDocument.doneData,
  target: saveCloseFiles,
});
sample({
  clock: saveCloseFiles,
  source: {
    files: $filesClose,
    document: $documentFormEdit,
    isNeed: $isNeedSaveCloseFiles,
  },
  filter: ({ isNeed }) => isNeed,
  fn: ({ files, document }) => {
    return { id: document!.id, files };
  },
  target: documentFormEditApi.postAllDocumentsDocumentIdFilesCloseDocuments,
});

$progressSaveCloseFiles.on(ApiFilesClose.addFile, (store, params) => {
  return [
    ...store,
    { isLoading: false, isComplite: false, name: params.name, error: null },
  ];
});
$progressSaveCloseFiles.on(ApiFilesClose.deleteFile, (store, params) => {
  return store.filter((item) => item.name !== params.name);
});
$progressSaveCloseFiles.on(
  documentFormEditApi.postDocumentsDocumentIdFileCloseDocuments,
  (store, params) => {
    return store.map((item) => {
      if (item.name === params.file.name) {
        return { ...item, isLoading: true };
      }

      return item;
    });
  }
);
$progressSaveCloseFiles.on(
  documentFormEditApi.postDocumentsDocumentIdFileCloseDocuments.done,
  (store, request) => {
    return store.map((item) => {
      if (item.name === request.params.file.name) {
        return { ...item, isLoading: false, isComplite: true, error: null };
      }

      return item;
    });
  }
);
$progressSaveCloseFiles.on(
  documentFormEditApi.postDocumentsDocumentIdFileCloseDocuments.fail,
  (store, request) => {
    return store.map((item) => {
      if (item.name === request.params.file.name) {
        return {
          ...item,
          isLoading: false,
          isComplite: false,
          error: request.error.response?.data,
        };
      }

      return item;
    });
  }
);
// --------------------------------

// Удаление Close-files
const deletedCloseFiles = createEvent(); //deleted files reason
const repeatDeleteCloseFiles = createEvent<number>();
const $isNeedDeleteCloseFiles = sample({
  clock: $deleteCloseFiles,
  fn: (files) => files.length > 0,
});
const $progressDeleteCloseFiles = createStore<
  { isLoading: boolean; isComplite: boolean; error: null | any; id: number }[]
>([], {
  name: '$progressDeleteCloseFiles',
});

sample({
  clock: documentFormEditApi.putDocument.doneData,
  target: deletedCloseFiles,
});
/* Delete all files in store deleted */
sample({
  clock: deletedCloseFiles,
  source: {
    files: $deleteCloseFiles,
    document: $documentFormEdit,
    isNeed: $isNeedDeleteCloseFiles,
  },
  filter: ({ isNeed }) => isNeed,
  fn: ({ files, document }) => {
    return { documentId: document!.id, files };
  },
  target: documentFormEditApi.deleteCloseFilesDocument,
});
/* Repeat delete files for id in store deleted */
sample({
  clock: repeatDeleteCloseFiles,
  source: { document: $documentFormEdit },
  fn: ({ document }, fileId) => {
    return { documentId: document!.id, fileId };
  },
  target: documentFormEditApi.deleteCloseFileDocument,
});
$progressDeleteCloseFiles.on(removeFilesFromDeletCloseStore, (store, id) => {
  return store.filter((item) => item.id !== id);
});
$progressDeleteCloseFiles.on(
  documentFormEditApi.deleteCloseFileDocument,
  (store, params) => {
    return store.map((item) => {
      if (item.id === params.fileId) {
        return { ...item, isLoading: true };
      }

      return item;
    });
  }
);
$progressDeleteCloseFiles.on(addedFileToDeleteCloseStore, (store, params) => {
  return [
    ...store,
    { isLoading: false, isComplite: false, id: params, error: null },
  ];
});
$progressDeleteCloseFiles.on(
  removeFilesFromDeletCloseStore,
  (store, params) => {
    return store.filter((item) => item.id !== params);
  }
);
$progressDeleteCloseFiles.on(
  documentFormEditApi.deleteCloseFileDocument.done,
  (store, request) => {
    return store.map((item) => {
      if (item.id === request.params.fileId) {
        return { ...item, isLoading: false, isComplite: true, error: null };
      }

      return item;
    });
  }
);
$progressDeleteCloseFiles.on(
  documentFormEditApi.deleteCloseFileDocument.fail,
  (store, request) => {
    return store.map((item) => {
      if (item.id === request.params.fileId) {
        return {
          ...item,
          isLoading: false,
          isComplite: false,
          error: request.error.response?.data,
        };
      }

      return item;
    });
  }
);
// --------------------------------

// Отправка документа на согласование
const postDocumentSend = createEvent(); // post document DoAction id 1
const $complitePutDocumentAndFiles = sample({
  source: {
    progressSaveReasonDocument: $progressSaveReasonDocument,
    progressSaveCloseFiles: $progressSaveCloseFiles,
    progressDeleteCloseFiles: $progressDeleteCloseFiles,
    progressDeleteReasonDocument: $progressDeleteReasonDocument,
    progressPutDocument: $progressPutDocument,
  },
  fn: ({
    progressSaveReasonDocument,
    progressSaveCloseFiles,
    progressDeleteCloseFiles,
    progressDeleteReasonDocument,
    progressPutDocument,
  }) => {
    const complitedSaveReasonDocument = !progressSaveReasonDocument.some(
      (item) => item.error || item.isLoading || !item.isComplite
    );
    const complitedSaveCloseFiles = !progressSaveCloseFiles.some(
      (item) => item.error || item.isLoading || !item.isComplite
    );
    const complitedDeleteCloseFiles = !progressDeleteCloseFiles.some(
      (item) => item.error || item.isLoading || !item.isComplite
    );
    const complitedDeleteReasonDocument = !progressDeleteReasonDocument.some(
      (item) => item.error || item.isLoading || !item.isComplite
    );
    const complitedPutDocument =
      progressPutDocument.isComplite &&
      !progressPutDocument.error &&
      !progressPutDocument.isLoading;

    return (
      complitedSaveReasonDocument &&
      complitedSaveCloseFiles &&
      complitedDeleteCloseFiles &&
      complitedDeleteReasonDocument &&
      complitedPutDocument
    );
  },
});
const $progressSendDocument = createStore<{
  isLoading: boolean;
  isComplite: boolean;
  error: null | any;
}>(
  { isLoading: false, isComplite: false, error: null },
  { name: '$progressSendDocument' }
);

sample({
  clock: $complitePutDocumentAndFiles,
  source: $isNeedDoActionDocuments,
  filter: (isNeed, isComplited) => isNeed && isComplited,
  target: postDocumentSend,
});
sample({
  clock: postDocumentSend,
  source: { document: $documentFormEdit, isNeed: $isNeedDoActionDocuments },
  filter: ({ isNeed }) => isNeed,
  fn: (source) => ({
    documentId: source.document!.id,
    requestBody: { actionTaskId: 1 } as DocumetnDoActionRequest,
  }),
  target: documentFormEditApi.postDocumentsSend,
});

$progressSendDocument.on(
  documentFormEditApi.postDocumentsSend.pending,
  (state, isLoading) => ({ ...state, isLoading })
);
$progressSendDocument.on(
  documentFormEditApi.postDocumentsSend.doneData,
  (state) => ({
    ...state,
    isComplite: true,
    error: null,
  })
);
$progressSendDocument.on(
  documentFormEditApi.postDocumentsSend.failData,
  (state, error) => ({ ...state, error })
);
// --------------------------------

/* updateDocument */
const updateDocument = createEvent<number>(); // update document
/* sample({ clock: documentFormEditApi.postDocumentsSend.done, fn: (request) => request.params.documentId, target: updateDocument }); */
/* sample({
  clock: $complitePutDocumentAndFiles,
  source: { isNeedDoActionDocuments: $isNeedDoActionDocuments, document: $documentFormEdit },
  filter: ({ isNeedDoActionDocuments, document }, complited) => !isNeedDoActionDocuments && complited && Boolean(document),
  fn: ({ document }) => document!.id,
  target: updateDocument,
}); */

sample({
  clock: updateDocument,
  fn: (id) => id,
  target: documentFormEditApi.refetchDocumentIdEdit,
});

sample({
  clock: documentFormEditApi.refetchDocumentIdEdit.doneData,
  fn: (response) => response.data.process.id,
  target: documentFormEditApi.getAdditionallyFields,
});
/* RESET */
/* RESET */
$documentFormEdit.reset(resetDocumentFormEdit);
$secondValid.reset(resetDocumentFormEdit);
$firstdValid.reset(resetDocumentFormEdit);
$agreeChain.reset(resetDocumentFormEdit);

$deleteReasonFiles.reset([resetDocumentFormEdit, updateDocument]);
$filesReason.reset([resetDocumentFormEdit, updateDocument]);
$filesClose.reset([resetFileClose, resetDocumentFormEdit, updateDocument]);

$deleteCloseFiles.reset([updateDocument, resetDocumentFormEdit]);
$progressDeleteCloseFiles.reset([updateDocument, resetDocumentFormEdit]);
$progressDeleteReasonDocument.reset([updateDocument, resetDocumentFormEdit]);
$progressPutDocument.reset([updateDocument, resetDocumentFormEdit]);
$progressSaveCloseFiles.reset([updateDocument, resetDocumentFormEdit]);
$progressSaveReasonDocument.reset([updateDocument, resetDocumentFormEdit]);
$progressSendDocument.reset([
  documentFormEditApi.refetchDocumentIdEdit.doneData,
  resetDocumentFormEdit,
]);

export const documentFormEdit = {
  form: $documentFormEdit,
  formReset: resetDocumentFormEdit,
  firstdValid: $firstdValid,
  firstValidSelectors: {
    useSelectorArticleIdFirstValid,
  },
  secondValid: $secondValid,
  onSecondValidation,
  api,
  formSelects: {
    useSelectValueOfKey,
    useSelectProcessValueOfKey,
    useSelectArticleIdFormEdit,
    useSelectArticleExpenseValue,
  },
  /* delete reason files */
  deleteReasonFiles: $deleteReasonFiles,
  addedFileToDeleteStore,
  removeFilesFromDeleteStore,
  repeatDeleteReasonFiles,
  /* save rason files */
  filesReason: $filesReason,
  ApiFilesReason,
  /* delete close files */
  deleteCloseFiles: $deleteCloseFiles,
  addedFileToDeleteCloseStore,
  removeFilesFromDeletCloseStore,
  repeatDeleteCloseFiles,
  /* save close files */
  filesClose: $filesClose,
  ApiFilesClose,

  /* progresses api */
  progresses: {
    putDocument: $progressPutDocument,
    saveReasonFiles: $progressSaveReasonDocument,
    deleteReasonFiles: $progressDeleteReasonDocument,
    saveCloseFiles: $progressSaveCloseFiles,
    deleteCloseFiles: $progressDeleteCloseFiles,
    sendDocument: $progressSendDocument,
  },
  isNeed: {
    isNeedDoActionDocuments: $isNeedDoActionDocuments,
    isNeedDeleteCloseFiles: $isNeedDeleteCloseFiles,
    isNeedDeleteReasonFiles: $isNeedDeleteReasonFiles,
    isNeedSaveCloseFiles: $isNeedSaveCloseFiles,
    isNeedSaveReasonFiles: $isNeedSaveReasonFiles,
  },
  /* ------ */
  putDocument,
  resetFileClose,
  updateDocument,
  postDocumentSend,
};
