// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { createEvent, createStore, sample } from 'effector';
import { postDocumentsDocumentIdDoActionFx } from 'features/document/document-actions/api/document-actions';

const $modalDocumentActionManagerAgreeRequest = createStore<{
  actionId: null | number;
  documentId: null | number;
  orderDate: null | string;
  orderNumber: string;
}>({
  actionId: null,
  documentId: null,
  orderDate: null,
  orderNumber: '',
});
const changeOrderNumber = createEvent<string>();
const changeOrderDate = createEvent<string | null>();
const openModalActionManagerAgreeRequest = createEvent<{
  documentId: number;
  actionId: number;
}>();
const resetModal = createEvent<any | undefined>();

$modalDocumentActionManagerAgreeRequest.on(
  changeOrderDate,
  (state, orderDate) => {
    return { ...state, orderDate };
  }
);
$modalDocumentActionManagerAgreeRequest.on(
  changeOrderNumber,
  (state, orderNumber) => {
    return { ...state, orderNumber };
  }
);
$modalDocumentActionManagerAgreeRequest.on(
  openModalActionManagerAgreeRequest,
  (state, params) => {
    return { ...params, orderDate: null, orderNumber: '' };
  }
);
$modalDocumentActionManagerAgreeRequest.reset(resetModal);
sample({
  clock: postDocumentsDocumentIdDoActionFx.done,
  filter: (clock) => Boolean(clock.params.requestBody.actionTaskId === 3),
  target: resetModal,
});
export const orderManagerAgreeRequestModal = {
  store: $modalDocumentActionManagerAgreeRequest,
  events: {
    reset: resetModal,
    changeOrderDate,
    changeOrderNumber,
    onOpen: openModalActionManagerAgreeRequest,
  },
};
