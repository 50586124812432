import { array, object, ObjectSchema } from 'yup';

import {
  ChecklistGetListResponse,
  EmployeeVisitingHomiesGetListResponse,
} from 'shared/api/visit/types';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';

import { BasicVisitInfoFormI, RetailGetListResponseNew } from './type';

/* From schems */
export const HOMY_SCHEMA: ObjectSchema<EmployeeVisitingHomiesGetListResponse> =
  object().shape({
    id: SCHEMA.NUMBER.required(),
    blockedDates: array(SCHEMA.STRING_REQUIRED),
    employeeName: SCHEMA.STRING,
    employeePosition: SCHEMA.STRING,
  });

export const RETAIL_SCHEMA: ObjectSchema<RetailGetListResponseNew> =
  object().shape({
    departmentId: SCHEMA.NUMBER.required(),
    id: SCHEMA.NUMBER.required(),
    endWeekDayTime: SCHEMA.STRING.required(),
    endWeekEndTime: SCHEMA.STRING.required(),
    startWeekDayTime: SCHEMA.STRING.required(),
    startWeekEndTime: SCHEMA.STRING.required(),
    address: SCHEMA.STRING,
    allowedDates: array(SCHEMA.STRING_REQUIRED).nullable(),
    coordinateX: SCHEMA.NUMBER,
    coordinateY: SCHEMA.NUMBER,
    manager: SCHEMA.STRING.nullable(),
    phone: SCHEMA.STRING.nullable(),
    title: SCHEMA.STRING,
    shortTitle: SCHEMA.STRING,
  });

export const CHECKLIST_SCHEMS: ObjectSchema<ChecklistGetListResponse> =
  object().shape({
    id: SCHEMA.NUMBER.required(),
    title: SCHEMA.STRING,
  });

export const BasicVisitInfoFormSchema: ObjectSchema<BasicVisitInfoFormI> =
  object().shape({
    homies: array(HOMY_SCHEMA.required()).min(0).required(),
    retails: array(RETAIL_SCHEMA.required()).required().min(1),
    visitingDate: SCHEMA.DATEJS.required(),
    checklist: CHECKLIST_SCHEMS.required(),
    maker: SCHEMA.STRING_REQUIRED,
  });
/* ==== */
