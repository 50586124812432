import { Skeleton } from '@mui/material';
import { BannerBrandsItemUi } from 'entities/apteka-legko/banners/brands/ui/banner-brands-item-ui';
import { FC } from 'react';
import { BannerBrandsItemSkeletonProps } from './types';

export const BannerBrandsItemSkeleton: FC<BannerBrandsItemSkeletonProps> = ({
  ActionSkeleton,
}) => {
  return (
    <BannerBrandsItemUi Action={ActionSkeleton}>
      <Skeleton
        variant='rounded'
        sx={{ width: '100%', height: '100%', flex: 1 }}
      />
    </BannerBrandsItemUi>
  );
};
