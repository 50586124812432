import {
  Add,
  Check,
  FormatListBulleted,
  HourglassEmpty,
  Pause,
  WorkOutline,
} from '@mui/icons-material';

import { TaskStatuses, TaskStatusIcon } from './type';

export const TASK_STATUS_ICONS: TaskStatusIcon[] = [
  {
    status: TaskStatuses['Отложена'],
    icon: <Pause />,
    color: 'default',
  },
  {
    status: TaskStatuses['Новая'],
    icon: <Add />,
    color: 'primary',
  },
  {
    status: TaskStatuses['В работе'],
    icon: <WorkOutline />,
    color: 'primary',
  },
  {
    status: TaskStatuses['Выполнена'],
    icon: <Check />,
    color: 'secondary',
  },
  {
    status: TaskStatuses['Завершена'],
    icon: <Check />,
    color: 'secondary',
  },
  {
    status: TaskStatuses['Ожидание'],
    icon: <HourglassEmpty />,
    color: 'default',
  },
  {
    color: 'default',
    status: TaskStatuses['Черновик'],
    icon: <FormatListBulleted />,
  },
  {
    color: 'default',
    status: TaskStatuses['На проверке'],
    icon: <FormatListBulleted />,
  },
];
