import {
  RetailOpeningProcessRequestsOpeningTemplateStageTaskCreate,
  RetailOpeningProcessRequestsOpeningTemplateStageTaskUpdate,
} from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { FormTemplateTaskValue, numberForForm } from '../../types';

const getNumber = (arg: numberForForm): number => {
  if (arg === '') {
    throw new Error(' "" - не корректное значение');
  }

  return Number(arg);
};
const checkIsNeedRemained = (checked: boolean, value: numberForForm) =>
  checked ? getNumber(value) : undefined;

export const prepareFormValueToFetchCreateBody = (
  form: FormTemplateTaskValue
):
  | RetailOpeningProcessRequestsOpeningTemplateStageTaskCreate
  | RetailOpeningProcessRequestsOpeningTemplateStageTaskUpdate
  | null => {
  const {
    title,
    description,
    startDaysCount,
    finishDaysCount,
    creatorPosition,
    executorPosition,
    coExecutorsPositions,
    observersPositions,
    /* reminder */
    isReminderCoExecutor,
    reminderPeriodCoExecutor,
    isReminderCreator,
    reminderPeriodCreator,
    isReminderExecutor,
    reminderPeriodExecutor,
    isReminderObserver,
    reminderPeriodObserver,
    /* files */
    addingFiles,
    deletingFiles,
  } = form;

  if (!executorPosition) return null;

  return {
    title,
    description,
    startDaysCount: getNumber(startDaysCount),
    finishDaysCount: getNumber(finishDaysCount),
    /* REMINDER */
    isReminderCoExecutor,
    reminderPeriodCoExecutor: checkIsNeedRemained(
      isReminderCoExecutor,
      reminderPeriodCoExecutor
    ),
    isReminderCreator,
    reminderPeriodCreator: checkIsNeedRemained(
      isReminderCreator,
      reminderPeriodCreator
    ),
    isReminderExecutor,
    reminderPeriodExecutor: checkIsNeedRemained(
      isReminderExecutor,
      reminderPeriodExecutor
    ),
    isReminderObserver,
    reminderPeriodObserver: checkIsNeedRemained(
      isReminderObserver,
      reminderPeriodObserver
    ),
    creatorPositionGuid: creatorPosition ? creatorPosition.guid : undefined,
    executorPositionGuid: executorPosition.guid,

    coExecutorsPositionsGuids: coExecutorsPositions.map((item) => item.guid),
    observersPositionsGuids: observersPositions.map((item) => item.guid),

    deletingFiles:
      deletingFiles && deletingFiles.length > 0
        ? deletingFiles.map(({ guid }) => guid)
        : undefined,
    addingFiles:
      addingFiles && addingFiles.length > 0 ? addingFiles : undefined,
  };
};
