import { VisitingCreateRequest } from 'shared/api/visit/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';

import { CreateVisitByPlanningRNFormFields } from '../type';

export const convertCreateVisitRNToFetch = (
  form: CreateVisitByPlanningRNFormFields
): VisitingCreateRequest => {
  return {
    checklistId: form.checkList.id,
    visitingDate: form.date.format(DATE_FORMATS.server),
    homiesIds: getIdsFromArray(form.homies),
    retailIds: [form.retails.id],
    visitingPlanningId: form.planningId,
  };
};
