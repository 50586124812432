import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useStoreMap } from 'effector-react';
import { FC, useEffect } from 'react';

import { CounterpartyModel } from 'shared/api/services-document-flow/types';
import { theme } from 'shared/theme/theme';

import { fetchConterpartyOptionsFx } from 'widgets/document-create/counterparty/fetch-effector';
import { $counterpartyOptions } from 'widgets/document-create/counterparty/model';

const EXEMPLE: CounterpartyModel[] = [
  {
    title: 'exemple1',
    id: 99999999,
    regReasonCode: '123456789',
    taxNumber: '111111111111',
  },
  {
    title: 'exemple2',
    id: 99999999,
    regReasonCode: '',
    taxNumber: '111111111111',
  },
  {
    title: 'exemple3',
    id: 99999999,
    regReasonCode: '123456711',
    taxNumber: '1234567891',
  },
  {
    title: 'exemple4',
    id: 99999999,
    regReasonCode: '',
    taxNumber: '1234567891',
  },
];

export const Counterparty: FC = () => {
  const counterparties = useStoreMap($counterpartyOptions, (store) =>
    store.sort((a, b) => ((a.title || 'not') < (b.title || 'not') ? 1 : -1))
  );

  useEffect(() => {
    fetchConterpartyOptionsFx();
  }, []);
  if (!counterparties) return null;

  return (
    <Grid
      container
      flexDirection={'column'}
      padding={'8px'}
      alignItems={['center', 'stretch']}
    >
      <Typography
        fontWeight={700}
        fontSize={[16, 20]}
        marginTop={[1, 3]}
        marginBottom={[2]}
        width={'100%'}
      >
        Counterparties
      </Typography>
      <TableContainer
        sx={{
          /* height: "calc(100vh - 230px)", */
          /* boxShadow: "0px -5px 5px -5px rgba(34, 60, 80, 0.6) inset;", */
          paddingBottom: 2,
          zIndex: 7,
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: '650px' }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Наименование</TableCell>
              <TableCell>ИНН</TableCell>
              <TableCell>КПП</TableCell>
              <TableCell>ИД</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {EXEMPLE.map(({ title, taxNumber, regReasonCode, id }) => {
              const is = isValid(taxNumber, regReasonCode);

              return (
                <TableRow key={id}>
                  <TableCell
                    sx={{ color: is ? theme.palette.customGreen.main : 'red' }}
                  >
                    {title}
                  </TableCell>
                  <TableCell
                    sx={{ color: is ? theme.palette.customGreen.main : 'red' }}
                  >
                    <Typography>{taxNumber}</Typography>
                    <Typography>{taxNumber?.length}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{ color: is ? theme.palette.customGreen.main : 'red' }}
                  >
                    <Typography>{regReasonCode || '-'}</Typography>
                    <Typography>{regReasonCode?.length}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{ color: is ? theme.palette.customGreen.main : 'red' }}
                  >
                    {id}
                  </TableCell>
                </TableRow>
              );
            })}
            {counterparties.map(({ title, taxNumber, regReasonCode, id }) => {
              const is = isValid(taxNumber, regReasonCode);

              return (
                <TableRow key={id}>
                  <TableCell
                    sx={{ color: is ? theme.palette.customGreen.main : 'red' }}
                  >
                    {title}
                  </TableCell>
                  <TableCell
                    sx={{ color: is ? theme.palette.customGreen.main : 'red' }}
                  >
                    <Typography>{taxNumber}</Typography>
                    <Typography>{taxNumber?.length}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{ color: is ? theme.palette.customGreen.main : 'red' }}
                  >
                    <Typography>{regReasonCode}</Typography>
                    <Typography>{regReasonCode?.length}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{ color: is ? theme.palette.customGreen.main : 'red' }}
                  >
                    {id}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const isValid = (
  taxNumber: string | undefined,
  regReasonCode: string | undefined
) => {
  if (
    taxNumber?.length === 12 &&
    (regReasonCode?.length === 0 || regReasonCode?.length === 9)
  ) {
    return true;
  } else if (taxNumber?.length === 10 && regReasonCode?.length === 9) {
    return true;
  }

  return false;
};
