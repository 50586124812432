import { useMemo } from 'react';

import { useGetControllFiltersQuery } from 'shared/api/services/rtk/optionApi';
import { generateFilterDeps } from '../libs/generateFilterDeps';

export const useGetControllFilters = () => {
  const { data, isLoading } = useGetControllFiltersQuery();

  const directionsAndRegions = useMemo(() => {
    return generateFilterDeps(data?.directionsAndRegions);
  }, [data]);

  const departamentsAndDepts = useMemo(() => {
    return generateFilterDeps(data?.departmentsAndDepts);
  }, [data]);

  const officeAndDivision = useMemo(() => {
    return generateFilterDeps(data?.officesAndDivisions);
  }, [data]);

  const positionsAndRetails = useMemo(() => {
    return generateFilterDeps(data?.positionsAndRetails);
  }, [data]);

  const employees = useMemo(() => {
    return generateFilterDeps(data?.employees);
  }, [data]);

  return {
    isLoading,
    directionsAndRegions,
    departamentsAndDepts,
    officeAndDivision,
    positionsAndRetails,
    employees,
  };
};
