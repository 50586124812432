// Имя формата Фамилия И.О. => ФИ
export const getAvatarName = (name: string) => {
  const separateName = name.split(' ');
  const FFam = separateName[0].at(0);
  const FName = separateName[1].at(0);

  if (!FFam || !FName) throw new Error('Неправильный формат ФИО');

  return FFam + FName;
};
