import { Typography } from '@mui/material';
import { documentGuid } from 'entities/document/model/document-id';
import { DocumentListItemResponse } from 'shared/api/services-document-flow/types';
import { UiCellListFiles } from 'shared/ui/table/ui-cell-list-files/UiCellListFiles';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import {
  DocumentCommentCountUi,
  DocumentCounterpartyContractNumberUi,
  DocumentCounterpartyUi,
  DocumentDateCreatedUi,
  DocumentOperationTypeUi,
  DocumentOrgUi,
  DocumentPayBeforeDateUi,
  DocumentProcessAgreeUi,
  DocumentStatusUi,
  DocumentTaskUi,
  DocumentTitleUi,
} from './ui';

export const DOCUMENT_ACTION_TABLE_CELLS: FieldMapType<DocumentListItemResponse>[] =
  [
    {
      field: 'id',
      title: 'ID',
      order: 0,
      isShow: true,
      renderComponent: (value) => <Typography>{value.id}</Typography>,
      getTableCellProps: () => ({}),
    },
    {
      field: 'title',
      title: 'Название',
      order: 0,
      isShow: true,
      renderComponent: (value) => (
        <DocumentTitleUi
          title={value.title}
          id={value.id}
        />
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'status',
      title: 'Статус',
      order: 0,
      isShow: true,
      renderComponent: (value) => <DocumentStatusUi status={value.status} />,
      getTableCellProps: () => ({}),
    },
    {
      field: 'action',
      title: 'Задание',
      order: 0,
      isShow: true,
      renderComponent: (value) => <DocumentTaskUi task={value.action} />,
      getTableCellProps: () => ({}),
    },
    {
      field: 'dateCreated',
      title: 'Дата создания',
      order: 0,
      isShow: true,
      renderComponent: ({ dateCreated }) => (
        <DocumentDateCreatedUi dateCreated={dateCreated} />
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'organization',
      title: 'ЮЛ',
      order: 0,
      isShow: true,
      renderComponent: ({ organization }) => (
        <DocumentOrgUi organization={organization} />
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'operation',
      title: 'Вид операции',
      order: 0,
      isShow: true,
      renderComponent: ({ operation }) => (
        <DocumentOperationTypeUi operation={operation} />
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'counterparty',
      title: 'Контрагент',
      order: 0,
      isShow: true,
      renderComponent: ({ counterparty }) => (
        <DocumentCounterpartyUi counterparty={counterparty} />
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'counterpartyContractNumber',
      title: 'Договор',
      order: 0,
      isShow: true,
      renderComponent: ({ counterpartyContractNumber }) => (
        <DocumentCounterpartyContractNumberUi
          counterpartyContractNumber={counterpartyContractNumber}
        />
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'commentCount',
      title: 'Комментарий',
      order: 0,
      isShow: true,
      renderComponent: ({ id, hasUnreadComment, commentCount }) => (
        <DocumentCommentCountUi
          id={id}
          hasUnreadComment={hasUnreadComment}
          commentCount={commentCount}
        />
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'agreeProcess',
      title: 'Процесс согласования',
      order: 0,
      isShow: true,
      renderComponent: ({ agreeProcess }) => (
        <DocumentProcessAgreeUi processAgree={agreeProcess} />
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'payBeforeDate',
      title: 'Оплатить до',
      order: 0,
      isShow: true,
      renderComponent: ({ payBeforeDate }) => (
        <DocumentPayBeforeDateUi payBeforeDate={payBeforeDate} />
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'reasonDocumentFiles',
      title: 'Документ основания',
      order: 0,
      isShow: true,
      renderComponent: ({ reasonDocumentFiles, id }) => (
        <UiCellListFiles
          onDownload={({ id: fileId, title }) => {
            documentGuid.downloadFileReasonDocumentId({
              id,
              fileId,
              fileName: title || '',
            });
          }}
          documents={reasonDocumentFiles?.map((item) => ({
            id: item.id,
            title: item.fileTitle || 'not-file-name',
          }))}
        />
      ),
      getTableCellProps: () => ({ align: 'center' }),
    },
  ];
