import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { VisitingPlanningGetListResponse } from 'shared/api/visit/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const PLANNING_RN_MAP: FieldMapType<VisitingPlanningGetListResponse>[] =
  [
    {
      field: 'title',
      title: 'Название',
      isShow: true,
      order: 0,
      getTableCellProps: () => ({}),
      renderComponent: (planning) => <Typography>{planning.title}</Typography>,
    },
    {
      field: 'department',
      title: 'Сеть',
      isShow: true,
      order: 1,
      getTableCellProps: () => ({}),
      renderComponent: (planning) => (
        <Typography>{planning.department.title}</Typography>
      ),
    },
    {
      field: 'periodStart',
      title: 'Период',
      isShow: true,
      order: 2,
      getTableCellProps: () => ({}),
      renderComponent: (planning) => {
        const startDate = dayjs(planning.periodStart).format(
          DATE_FORMATS.client
        );
        const endDate = dayjs(planning.periodFinish).format(
          DATE_FORMATS.client
        );

        return <Typography>{`${startDate} - ${endDate}`}</Typography>;
      },
    },
    {
      field: 'members',
      title: 'Участники',
      isShow: true,
      order: 3,
      getTableCellProps: () => ({}),
      renderComponent: (planning) => {
        if (planning.members && planning.members.length > 0)
          return (
            <>
              {planning.members.map((member) => (
                <Typography key={member.id}>{member.employeeName}</Typography>
              ))}
            </>
          );

        return <Typography>-</Typography>;
      },
    },
    {
      field: 'visitor',
      title: 'Посетитель',
      isShow: true,
      order: 4,
      getTableCellProps: () => ({}),
      renderComponent: (planning) => (
        <Typography>{planning.visitor.employeeName}</Typography>
      ),
    },
  ];
