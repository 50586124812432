import { Fade, Paper } from '@mui/material';
import { FC, useRef } from 'react';
import { AppActionBarProps } from './type';

export const AppActionBar: FC<AppActionBarProps> = ({
  isOpen,
  children,
  fadeProps,
  paperProps,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Fade
      {...fadeProps}
      in={isOpen}
    >
      <Paper
        ref={ref}
        {...paperProps}
        elevation={4}
        sx={{
          position: 'fixed',
          bottom: 24,
          left: `calc(50% - ${(ref.current?.clientWidth || 0) / 2}px)`,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          ...paperProps?.sx,
        }}
      >
        {children}
      </Paper>
    </Fade>
  );
};
