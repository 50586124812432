import { yupResolver } from '@hookform/resolvers/yup';
import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { RetailSelectDialog } from 'features/visit/RetailSelectDialog';
import { FC, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { DATE_FORMATS } from 'shared/date-helper/constants';
import { useViewModal } from 'shared/hooks/useViewModal';
import { CustomAutocomplete, CustomTextField } from 'shared/ui/base-ui';

import { useGetCheckList } from 'entities/visit/options/useGetCheckList';
import { useGetEmployees } from 'entities/visit/options/useGetEmployees';

import { VisitDatePicker } from 'widgets/visits/forms/create-visit-from/components/VisitDatePicker';

import { ChecklistsInput } from '../../ChecklistsInput';
import { EmployeesInput } from '../../EmployeesInput';
import { BasicVisitInfoFormSchema } from '../schems';
import { BasicVisitInfoFormI, BasicVisitInfoFormProps } from '../type';

export const BasicVisitInfoForm: FC<BasicVisitInfoFormProps> = ({
  initValue,
  onSubmit,
  disabled,
  disableAll,
  footer,
  header,
  onIsValidChange,
}) => {
  const { close, isOpen, open } = useViewModal();

  const { employees, isLoading: isEmployeesLoading } = useGetEmployees();
  const { checkLists, isLoading: isCheckListsLoading } = useGetCheckList();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<BasicVisitInfoFormI>({
    defaultValues: initValue ? initValue : { homies: [] },
    // values: initValue,
    resolver: yupResolver(BasicVisitInfoFormSchema),
  });

  useEffect(() => {
    onIsValidChange && onIsValidChange(isValid);
  }, [isValid, onIsValidChange]);

  const visitingDate = useWatch({ control, name: 'visitingDate' });
  const retails = useWatch({ control, name: 'retails' });

  return (
    <Grid
      container
      flexDirection={'column'}
      component={'form'}
      onSubmit={handleSubmit(onSubmit, console.log)}
      gap={2}
    >
      <Grid px={[1, 1, 1, 1, 0]}>{header}</Grid>

      <Controller
        name='maker'
        control={control}
        render={({ field: { value } }) => (
          <CustomTextField
            label='Инициатор'
            sx={{ width: '100%' }}
            value={value}
            disabled
          />
        )}
      />

      <Controller
        name='visitingDate'
        control={control}
        render={({ field: { onChange, value } }) => (
          <VisitDatePicker
            onChange={onChange}
            value={value}
            retails={retails}
            disable={
              disabled?.some((el) => el === 'visitingDate') || disableAll
            }
          />
        )}
      />

      <Controller
        name='homies'
        control={control}
        render={({ field: { onChange, value } }) => (
          <EmployeesInput
            options={employees}
            onChange={onChange}
            value={value}
            disabled={disabled?.some((el) => el === 'homies') || disableAll}
            isLoading={isEmployeesLoading}
          />
        )}
      />

      <Controller
        name='checklist'
        control={control}
        render={({ field: { onChange, value } }) => (
          <ChecklistsInput
            isLoading={isCheckListsLoading}
            disabled={disabled?.some((el) => el === 'checklist') || disableAll}
            options={checkLists}
            onChange={onChange}
            value={value}
          />
        )}
      />

      <Controller
        name='retails'
        control={control}
        render={({ field: { onChange, value } }) => {
          const date = visitingDate
            ? dayjs(visitingDate, DATE_FORMATS.client)
            : undefined;

          return (
            <Grid
              container
              gap={2}
            >
              {!value || value.length === 0 ? (
                <Button
                  startIcon={<Add />}
                  onClick={open}
                  variant='contained'
                >
                  Выбрать Аптеки
                </Button>
              ) : (
                <>
                  <CustomAutocomplete
                    sx={{ flex: 1 }}
                    textFieldProps={{
                      label: 'Аптеки',
                    }}
                    disabled={
                      disabled?.some((el) => el === 'retails') || disableAll
                    }
                    options={[]}
                    onChange={(_, val) => {
                      onChange(val);
                    }}
                    multiple
                    value={value}
                    getOptionLabel={(val) => val.shortTitle || ''}
                  />
                  {disabled?.some((el) => el === 'retails') ||
                  disableAll ? null : (
                    <Button onClick={open}>Изменить</Button>
                  )}
                </>
              )}
              <RetailSelectDialog
                isOpen={isOpen}
                onClose={close}
                multiple
                value={value}
                onChange={onChange}
                selectedDate={date}
              />
            </Grid>
          );
        }}
      />

      {footer}
    </Grid>
  );
};
