interface MyObj {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const countNotUndef = (obj?: MyObj): number => {
  if (!obj) return 0;

  let count = 0;

  for (const key in obj) {
    if (obj[key] === undefined) continue;

    if (Array.isArray(obj[key])) {
      if (obj[key].length > 0) {
        count++;
        continue;
      } else {
        continue;
      }
    }

    if (obj[key] !== undefined) count++;
  }

  return count;
};
