import { CreateLinkRunningProcessParams } from 'entities/adaptation/links';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';
import { useURLState } from 'shared/url-helper/useURLState';

const convertorURLToPrepareTaskParams: URLToObjConverter<
  CreateLinkRunningProcessParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_PREPARE_ADAPTATION_TASKS_PARAMS_MAP
  ) as CreateLinkRunningProcessParams;
};

const CONVERT_PREPARE_ADAPTATION_TASKS_PARAMS_MAP: ConvertMapWithType<CreateLinkRunningProcessParams> =
  [
    { key: 'AdaptationGuid', type: ConvertTypes.string },
    { key: 'NewEmployeeGuid', type: ConvertTypes.string },
    { key: 'MentorGuid', type: ConvertTypes.string },
    { key: 'countdownDate', type: ConvertTypes.string },
    { key: 'SkipStage', type: ConvertTypes.masString },
  ];

export const useUrlStatePrepareAdaptationTasksParams = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToPrepareTaskParams,
    true
  );

  return {
    filter,
    setFilter,
  };
};
