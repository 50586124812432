import {
  Backdrop,
  Box,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import { FC, useState } from 'react';

import { OverPageSpeedDialProps } from './type';

export const OverPageSpeedDial: FC<OverPageSpeedDialProps> = ({
  actionMap,
  speedDialProps,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        sx={{
          transform: 'translateZ(0px)',
          flexGrow: 1,
          zIndex: 100,
          position: 'fixed',
          bottom: 0,
          right: 0,
          top: open ? 0 : undefined,
          left: open ? 0 : undefined,
        }}
      >
        <Backdrop open={open} />
        <SpeedDial
          ariaLabel='SpeedDial tooltip example'
          sx={{ position: 'absolute', bottom: 32, right: 16 }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          {...speedDialProps}
        >
          {actionMap.map((action) => (
            <SpeedDialAction
              key={action.tooltip}
              icon={action.icon}
              tooltipTitle={action.tooltip}
              tooltipOpen
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                handleClose();
                action.action();
              }}
            />
          ))}
        </SpeedDial>
      </Box>
    </>
  );
};
