import { Button } from '@mui/material';
import { DialogBrandForm } from './DialogBrandForm';

import { FC } from 'react';
import { useEditBannerBrands } from './hooks';

export const ButtonWithDialogBrandFormUpdate: FC<{ guid: string }> = ({
  guid,
}) => {
  const {
    isLoadingInitialValue,
    isLoadingUpdateBannerBrand,
    initialValue,
    isOpen,
    handleUpdateBannerBrand,
    openModalForm,
    closeModalForm,
  } = useEditBannerBrands(guid);

  return (
    <>
      <Button
        onClick={openModalForm}
        variant={'contained'}
        size='small'
      >
        Изменить
      </Button>
      <DialogBrandForm
        title={'Изменить баннер бренда'}
        isOpen={isOpen}
        isLoading={isLoadingUpdateBannerBrand || isLoadingInitialValue}
        onSubmit={handleUpdateBannerBrand}
        initialValue={initialValue}
        onClose={closeModalForm}
      />
    </>
  );
};
