import { Grid } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { CustomToggleButtonGroup } from 'shared/ui/base-ui/custom-toggle-button-group/CustomToggleButtonGroup';

import {
  useGetAllEmployeesQuery,
  useGetOriginsQuery,
  useLazyGetStatusesQuery,
} from 'shared/api/services/rtk/optionApi';
import {
  AutocompliteWithController,
  DatePikerWithController,
} from 'shared/fields-with-contoller';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { FormLayout } from 'shared/ui/form';
import {
  createIsOptionEqualToValueByKey,
  createLabelOnKey,
  createRenderOptionByKey,
} from 'shared/utils/autocomplite-helpers';
import { TARGET_OPTIONS, TASK_FILTER_FORM_DEFAULT_VALUE } from './constants';
import { changeStatus } from './libs/changeStatus';
import { TaskFilterFormFields, TaskFilterFormProps } from './type';

export const TaskFilterForm: FC<TaskFilterFormProps> = ({
  onSubmit,
  defaultValue,
  taskListVariant,
  actions,
}) => {
  const [fetchStatuses] = useLazyGetStatusesQuery();

  const { data: employees, isLoading: isLoadingEmployee } =
    useGetAllEmployeesQuery();

  const { isLoading: loadingOrigins, data: originOptions } =
    useGetOriginsQuery();

  const {
    control,
    handleSubmit,
    formState: { isLoading: isLoadingForm },
  } = useForm<TaskFilterFormFields>({
    defaultValues:
      defaultValue ||
      (async () => {
        const { data: statuss } = await fetchStatuses();

        return {
          ...TASK_FILTER_FORM_DEFAULT_VALUE,
          statuses: statuss?.map((el) => ({ ...el, selected: false })) || [],
        };
      }),
  });

  return (
    <FormLayout
      actions={actions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit, console.error)}
    >
      <CustomBackdrop isLoading={isLoadingForm} />

      {/* ================== */}
      <Controller
        control={control}
        name='statuses'
        render={({ field: { value, onChange } }) => (
          <>
            {value && (
              <CustomToggleButtonGroup
                label='Статус'
                value={value}
                getLable={(val) => val.title || ''}
                getSelected={(val) => val.selected}
                onChange={(newStatus) =>
                  onChange(changeStatus(value, newStatus))
                }
                containerProps={{ px: [1, 0], pt: [1, 0] }}
              />
            )}
          </>
        )}
      />
      {/* ================== */}

      {/* ================== */}
      <AutocompliteWithController
        control={control}
        name='isTarget'
        options={TARGET_OPTIONS}
        textFieldProps={{
          label: 'Задачи',
          placeholder: 'Выберите фильтр',
        }}
        defaultValue={null}
        getOptionLabel={createLabelOnKey('title')}
        isOptionEqualToValue={createIsOptionEqualToValueByKey('id')}
        renderOption={createRenderOptionByKey({
          keyGuid: 'id',
          keyLabel: 'title',
        })}
      />
      {/* ================== */}

      {/* ================== */}
      <AutocompliteWithController
        control={control}
        name='originValue'
        textFieldProps={{
          label: 'Источник',
          placeholder: 'Выберите источники',
        }}
        multiple
        loading={loadingOrigins}
        loadingText={'Загрузка...'}
        noOptionsText='Нет совпадений'
        isOptionEqualToValue={createIsOptionEqualToValueByKey('id')}
        options={originOptions || []}
        getOptionLabel={createLabelOnKey('title')}
        renderOption={(props, option) => (
          <li
            {...props}
            key={`${option.id} ${option.title}`}
          >{`${option?.title}`}</li>
        )}
      />
      {/* ================== */}

      {/* ================== */}
      {taskListVariant === 'forMyTasks' && (
        <AutocompliteWithController
          control={control}
          name='creator'
          textFieldProps={{
            label: 'Постановщик',
            placeholder: 'Выберите постановщиков',
          }}
          loading={isLoadingEmployee}
          loadingText={'Загрузка...'}
          isOptionEqualToValue={createIsOptionEqualToValueByKey('guid')}
          multiple
          noOptionsText='Нет совпадений'
          options={employees || []}
          getOptionLabel={(option) =>
            `${option?.name}, ${option?.position?.title}`
          }
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.guid}
            >{`${option?.name},  ${option?.position?.title} `}</li>
          )}
        />
      )}
      {/* ================== */}

      {/* ================== */}
      {taskListVariant !== 'forMyTasks' && (
        <AutocompliteWithController
          control={control}
          name='executor'
          textFieldProps={{
            label: 'Исполнитель',
            placeholder: 'Выберите исполнителей',
          }}
          loading={isLoadingEmployee}
          loadingText={'Загрузка...'}
          multiple
          isOptionEqualToValue={createIsOptionEqualToValueByKey('guid')}
          noOptionsText='Нет совпадений'
          options={employees || []}
          getOptionLabel={(option) =>
            `${option?.name}, ${option?.position?.title}`
          }
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.guid}
            >{`${option?.name},  ${option?.position?.title} `}</li>
          )}
        />
      )}
      {/* ================== */}

      {/* ================== */}
      <AutocompliteWithController
        control={control}
        name='coExecutor'
        textFieldProps={{
          label: 'Соисполнитель',
          placeholder: 'Выберите соисполнителей',
        }}
        loading={isLoadingEmployee}
        loadingText={'Загрузка...'}
        multiple
        isOptionEqualToValue={createIsOptionEqualToValueByKey('guid')}
        noOptionsText='Нет совпадений'
        options={employees || []}
        getOptionLabel={(option) => `${option.name}, ${option.position?.title}`}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.guid}
          >{`${option?.name},  ${option?.position?.title} `}</li>
        )}
      />
      {/* ================== */}

      <Grid
        container
        flexDirection={['column', 'row']}
        justifyContent={'space-between'}
        flexWrap={'nowrap'}
        gap={2}
      >
        <DatePikerWithController
          control={control}
          name='dateStart'
          sx={{ width: '100%' }}
          label='Дата начала'
          slotProps={{ textField: { placeholder: 'Введите дату' } }}
        />

        <DatePikerWithController
          control={control}
          name='dateFinish'
          sx={{ width: '100%' }}
          label='Дата завершения'
          slotProps={{ textField: { placeholder: 'Введите дату' } }}
        />
      </Grid>
    </FormLayout>
  );
};
