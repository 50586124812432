import { useStore, useStoreMap } from 'effector-react';
import { useEffect } from 'react';

import { PropsAutocompliteDocumentForm } from 'shared/types/type';

import { documentFormModel } from 'entities/document-form/model';

import { articlesAutocomplite, fetchArticles } from './model';
import { CombineArticle } from './types';
import { ArticlesAutocomplite, MultiArticlesAutocomplite } from './ui';

export const DocumentFormArticlesAutocomplite = () => {
  const ProcessId = documentFormModel.useValueProcess('id');
  const isMultiArticles = documentFormModel.useValueProcess('isMultiArticles');
  const options = useStore(articlesAutocomplite.options);
  const value = useStoreMap({
    store: articlesAutocomplite.value,
    keys: [articlesAutocomplite.value],
    fn: (state) => state,
    /*  updateFilter: (update, current) => {
      if (update.length === current.length) {
        return false;
      }
      return true;
    }, */
  });
  const isAllArticlesRequired = documentFormModel.useValueProcess(
    'isAllArticlesRequired'
  );
  const onChange: PropsAutocompliteDocumentForm<
    CombineArticle[] | CombineArticle
  >['onChange'] = (e, value) => {
    articlesAutocomplite.events.onChange(value);
  };

  useEffect(() => {
    if (ProcessId) {
      fetchArticles.fetchArticlesFx({ ProcessId });
      fetchArticles.fetchTypeArticleFx();
    }
  }, [ProcessId]);
  useEffect(() => {
    if (options && isAllArticlesRequired) {
      onChange(null, options);
    }
  }, [options, isAllArticlesRequired]);
  if (isMultiArticles || (options.length > 0 && isAllArticlesRequired)) {
    return (
      <MultiArticlesAutocomplite
        disabled={isAllArticlesRequired}
        value={value}
        options={options}
        onChange={onChange}
      />
    );
  }

  return (
    <ArticlesAutocomplite
      disabled={isAllArticlesRequired}
      value={value.length > 0 ? value[0] : null}
      options={options}
      onChange={onChange}
    />
  );
};
