import { yupResolver } from '@hookform/resolvers/yup';

import { Grid } from '@mui/material';
import { FC, useEffect } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormState,
} from 'react-hook-form';

import { LoadingButton } from '@mui/lab';

import { FormFetchOptionsContextProvider } from './form-fetch-options-context';
import { SHEME_VALID_PREPARED_TASKS } from './scheme';
import { FormPreparedStagesTasksProps } from './types';
import { PreparedStage } from './ui';

export const FormPreparedStagesTasks: FC<FormPreparedStagesTasksProps> = ({
  initialValue,
  onSubmite,
  isLoadingSubmite,
  formFetchOptionsContextValue,
}) => {
  const methods = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(SHEME_VALID_PREPARED_TASKS),
    reValidateMode: 'onChange',
    mode: 'all',
  });
  const { isValid } = useFormState({ control: methods.control });
  const { fields: stages } = useFieldArray({
    control: methods.control,
    name: 'stages',
  });

  useEffect(() => {
    methods.trigger();
  }, []);

  return (
    <FormProvider {...methods}>
      <FormFetchOptionsContextProvider {...formFetchOptionsContextValue}>
        <Grid
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          component='form'
          onSubmit={methods.handleSubmit(onSubmite)}
        >
          {stages.map((stage, index) => {
            return (
              <PreparedStage
                key={stage.id}
                indexStage={index}
                stageNumber={stage.stageNumber}
              />
            );
          })}
          <LoadingButton
            loading={isLoadingSubmite}
            disabled={isLoadingSubmite || !isValid}
            type='submit'
            variant={'contained'}
          >
            Запустить процесс
          </LoadingButton>
        </Grid>
      </FormFetchOptionsContextProvider>
    </FormProvider>
  );
};
