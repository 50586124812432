import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSaveFilters } from 'shared/user-app-settings';
import { CreateFilterModal } from 'widgets/main-layout-app/save-filter/CreateFilterModal/CreateFilterModal';
import { isDisableBookmark } from './disabledPathnames';

export const BookmarkButton: FC = () => {
  const router = useLocation();
  const { allFilters, saveFilter, deleteFilter } = useSaveFilters();
  const { close, isOpen, open } = useViewModal();

  const filter = useMemo(() => {
    return (
      allFilters.find(
        (filter) => filter.url === router.pathname + router.search
      ) || null
    );
  }, [allFilters, router]);

  if (isDisableBookmark(router.pathname)) return null;

  return (
    <>
      <IconButton
        color='primary'
        sx={{ m: 1, mr: 2, height: '40px' }}
        onClick={() => {
          if (filter) deleteFilter(filter.id);
          else open();
        }}
      >
        {filter ? <Bookmark /> : <BookmarkBorder />}
      </IconButton>
      <CreateFilterModal
        isOpen={isOpen}
        onClose={close}
        onSubmit={({ title }) => {
          saveFilter({
            key: 'bookMark',
            title: title,
            url: router.pathname + router.search,
          });
          close();
        }}
      />
    </>
  );
};
