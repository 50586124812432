import { Typography } from '@mui/material';
import { RetailOpeningProcessResponsesOpeningTemplateGetListStageTask } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';

import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const OPENING_TAMPLATES_TASKS_FIELD_MAP: FieldMapType<RetailOpeningProcessResponsesOpeningTemplateGetListStageTask>[] =
  [
    {
      field: 'title',
      title: 'Задача',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.title}</Typography>;
      },
    },
    {
      field: 'creatorPositionTitle',
      title: 'Постановщик (Должность)',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.creatorPositionTitle}</Typography>;
      },
    },
    {
      field: 'executorPositionTitle',
      title: 'Исполнитель (Должность)',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.executorPositionTitle}</Typography>;
      },
    },
    {
      field: 'finishDaysCount',
      title: 'Завершениe (кол-во дней) ',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.finishDaysCount}</Typography>;
      },
    },
    {
      field: 'startDaysCount',
      title: 'Начало (кол-во дней)',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.startDaysCount}</Typography>;
      },
    },
  ];
