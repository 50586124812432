// eslint-disable-next-line budapestian/global-constant-pattern
import { preparedResponseProtocolsToTableData } from './libs/preparedResponseProtocolsToTableData';
import { ProtocolOpeningRetailsTableAdaptiveTable } from './ProtocolOpeningRetailsTableAdaptiveTable';
import { useTableCellsProtocolFromLocalStorage } from './use-table-from-local-storage';

export const ProtocolsOpeningRetails = {
  useCellFromLocalStorage: useTableCellsProtocolFromLocalStorage,
  Table: ProtocolOpeningRetailsTableAdaptiveTable,
  prepareResponseToDataTeble: preparedResponseProtocolsToTableData,
};
