import { generatePath } from 'react-router-dom';
import { creatRoutePathFromArray } from 'shared/routes';
import { ALL_ROUTES } from 'shared/routes/constants/types';

const PUSH_LIST = creatRoutePathFromArray([
  ALL_ROUTES.aptekaLegko,
  ALL_ROUTES.aptekaLegkoPush,
  ALL_ROUTES.aptekaLegkoPushList,
]);

const PUSH_CREATED = creatRoutePathFromArray([
  ALL_ROUTES.aptekaLegko,
  ALL_ROUTES.aptekaLegkoPush,
  ALL_ROUTES.aptekaLegkoPushCreated,
]);
const PUSH_EDIT = creatRoutePathFromArray([
  ALL_ROUTES.aptekaLegko,
  ALL_ROUTES.aptekaLegkoPush,
  ALL_ROUTES.aptekaLegkoPushEdit,
]);
const PUSH_BY_GUID = creatRoutePathFromArray([
  ALL_ROUTES.aptekaLegko,
  ALL_ROUTES.aptekaLegkoPush,
  ALL_ROUTES.aptekaLegkoPushEdit,
]);

export const APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS = {
  createLinkByGuid: (guid: string) => {
    return generatePath(PUSH_BY_GUID, { guid });
  },
  createLinkEdit: (guid: string) => {
    return generatePath(PUSH_EDIT, { guid });
  },
  list: PUSH_LIST,
  created: PUSH_CREATED,
};
