import { Typography } from '@mui/material';
import { APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS } from 'entities/apteka-legko/push-notifications';
import { PushNotificationSvg } from 'shared/svg/PushNotificationSvg';

import { LayoutPage } from 'shared/ui/layouts/layout-page';
import {
  NavigationCardProps,
  NavigationCardStack,
} from 'shared/ui/navigation-card';

const NAV_ITEMS: NavigationCardProps[] = [
  {
    to: APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS.created,
    title: 'Отправить push-уведомление',
    Icon: <PushNotificationSvg fontSize={'large'} />,
  },
  {
    to: APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS.list,
    title: 'Список push-уведомлений',
    Icon: <PushNotificationSvg fontSize={'large'} />,
  },
];

export const PageMainNotification = () => {
  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography>Push-уведомления</Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <NavigationCardStack.Container>
          {NAV_ITEMS.map((props, index) => {
            return (
              <NavigationCardStack.Card
                {...props}
                key={index}
              />
            );
          })}
        </NavigationCardStack.Container>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
