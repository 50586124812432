import { transformResponseError } from 'shared/api/libs';
import {
  deleteTasksTaskGuidCommentsCommentGuid,
  getTasksTaskGuidComments,
  postTasksTaskGuidComments,
} from '../services';
import { GetListTaskComment, TaskCommentCreate } from '../types';
import { taskProviderApi } from './taskProviderApi';

const commentApi = taskProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /* ===================================== */
    /* == Получение комментариев к задаче == */
    /* ===================================== */
    getTaskComments: build.query<GetListTaskComment[], string>({
      queryFn: async (taskGuid) => {
        try {
          const response = await getTasksTaskGuidComments(taskGuid);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];

        return res.map((file) => ({ type: 'task/comment', id: file.guid }));
      },
    }),
    /* ===================================== */
    /* ===================================== */
    /* ===================================== */

    /* ================================= */
    /* == Отправка комментария задачи == */
    /* ================================= */
    sendComment: build.mutation<
      GetListTaskComment,
      { taskGuid: string; args: TaskCommentCreate }
    >({
      queryFn: async ({ taskGuid, args }) => {
        try {
          const response = await postTasksTaskGuidComments(taskGuid, args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async ({ taskGuid }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const patchResult = dispatch(
            commentApi.util.updateQueryData(
              'getTaskComments',
              taskGuid,
              (draft) => {
                draft.push(data);
              }
            )
          );
        } catch {}
      },
    }),
    /* ================================= */
    /* ================================= */
    /* ================================= */

    /* ================================= */
    /* == Удаление комментария задачи == */
    /* ================================= */
    deleteTaskComment: build.mutation<
      undefined,
      { taskGuid: string; commentGuid: string }
    >({
      queryFn: async ({ commentGuid, taskGuid }) => {
        try {
          const response = await deleteTasksTaskGuidCommentsCommentGuid(
            taskGuid,
            commentGuid
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async (
        { commentGuid, taskGuid },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          commentApi.util.updateQueryData(
            'getTaskComments',
            taskGuid,
            (draft) => {
              return draft.filter((comment) => comment.guid !== commentGuid);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    /* ================================= */
    /* ================================= */
    /* ================================= */
  }),
});

export const {
  useGetTaskCommentsQuery,
  useDeleteTaskCommentMutation,
  useSendCommentMutation,
} = commentApi;
