import { RemindeFormFields } from '../type';

export interface TaskReminde {
  isReminderExecutor: boolean;
  isReminderCoExecutor: boolean;
  isReminderObserver: boolean;
  isReminderCreator: boolean;
  reminderPeriodCreator: number | undefined;
  reminderPeriodExecutor: number | undefined;
  reminderPeriodCoExecutor: number | undefined;
  reminderPeriodObserver: number | undefined;
}

export const prepareRemideFormDataToFetch = (data: RemindeFormFields) => {
  return {
    isReminderExecutor: data.executor.isReminde,
    isReminderCoExecutor: data.coExecutor.isReminde,
    isReminderObserver: data.observer.isReminde,
    isReminderCreator: data.creator.isReminde,
    reminderPeriodCreator: data.creator.isReminde
      ? data.creator.ticks || undefined
      : undefined,
    reminderPeriodExecutor: data.executor.isReminde
      ? data.executor.ticks || undefined
      : undefined,
    reminderPeriodCoExecutor: data.coExecutor.isReminde
      ? data.coExecutor.ticks || undefined
      : undefined,
    reminderPeriodObserver: data.observer.isReminde
      ? data.observer.ticks || undefined
      : undefined,
  };
};
