import { MoreVert } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { getAvatarStyle } from 'features/boards/BoardListItemCard/libs/getAvatarStyle';
import { FC } from 'react';
import { theme } from 'shared/theme/theme';
import { IconWithMenu } from 'shared/ui/icon-with-menu';
import { TaskCardStatusIcon } from 'shared/ui/TaskCardStatusIcon';
import { getAvatarName } from 'shared/utils/getAvatarName';
import { TaskCardProps } from './type';

export const TaskCard: FC<TaskCardProps> = ({
  actions,
  executor,
  status,
  subTitle,
  title,
  onClick,
  cardProps,
  disableStatus = false,
  onClickStatus,
  onTitleClick,
  onAvatarClick,
  isLoading = false,
  isSelected = false,
  isGhost = false,
}) => {
  return (
    <Card
      variant='outlined'
      onClick={onClick}
      {...cardProps}
      sx={{
        position: 'relative',
        borderColor: isSelected ? theme.palette.primary.main : undefined,
        opacity: isGhost ? 0.5 : undefined,
        ...cardProps?.sx,
      }}
    >
      {isLoading && (
        <LinearProgress
          color='primary'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 4,
          }}
        />
      )}

      <CardHeader
        action={
          actions ? (
            <IconWithMenu
              icon={<MoreVert />}
              options={actions}
            />
          ) : undefined
        }
        avatar={
          <TaskCardStatusIcon
            status={status}
            disable={disableStatus}
            iconProps={{
              onClick: (e) => {
                if (!disableStatus && onClickStatus) {
                  onClickStatus(e);
                }
              },
            }}
          />
        }
        titleTypographyProps={{
          onClick: onTitleClick,
        }}
        title={title}
        subheader={subTitle}
      />
      <CardContent
        component={Grid}
        container
        alignItems={'center'}
        justifyContent={'flex-end'}
      >
        {executor && (
          <Tooltip title={executor.name}>
            <Avatar
              sx={getAvatarStyle(executor.guid)}
              onClick={onAvatarClick}
            >
              {getAvatarName(executor.name)}
            </Avatar>
          </Tooltip>
        )}
      </CardContent>
    </Card>
  );
};
