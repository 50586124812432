import { useMemo } from 'react';
import { AdaptationProcessesCurrentListParams } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { AdaptationProcessListItem } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { OrderFilter } from 'shared/ui/table/types/order-props';
import { useAdaptationProcessesURLState } from './useAdaptationProcessesURLState';

// IN_DEV
// TODO: Попросить Олега что-бы сделал как в задачнике
enum AdaptationOrderFields {
  adaptationTemplateTitle = 'adaptationTemplateTitle',
  employeeName = 'employeeName',
  mentorName = 'mentorName',
  createdAt = 'createdAt',
  createdBy = 'createdBy',
}

// Получение поля сортировки по фильтру
const getAdaptationOrderField = (
  orderBy: AdaptationOrderFields
): string | undefined => {
  switch (orderBy) {
    case AdaptationOrderFields.adaptationTemplateTitle:
      return 'AdaptationTitle';
    case AdaptationOrderFields.createdAt:
      return 'ProcessCreatedAt';
    case AdaptationOrderFields.createdBy:
      return 'ProcessCreatedBy';
    case AdaptationOrderFields.employeeName:
      return 'EmployeeName';
    case AdaptationOrderFields.mentorName:
      return 'MentorName';

    default:
      return undefined;
  }
};

// Получение названия поля для фильтра
const getAdaptationOrderFieldByFilter = (
  filter: string
): keyof AdaptationProcessListItem | undefined => {
  switch (filter) {
    case 'AdaptationTitle':
      return AdaptationOrderFields.adaptationTemplateTitle;
    case 'ProcessCreatedAt':
      return AdaptationOrderFields.createdAt;
    case 'ProcessCreatedBy':
      return AdaptationOrderFields.createdBy;
    case 'EmployeeName':
      return AdaptationOrderFields.employeeName;
    case 'MentorName':
      return AdaptationOrderFields.mentorName;

    default:
      undefined;
  }
};

// Получение фильтра из сортировки таблицы
const orderToAdaptationProcessFilter = (
  order: OrderFilter<AdaptationProcessListItem>
) => {
  if (!order.order) return undefined;

  const firstPart = getAdaptationOrderField(
    order.orderBy as AdaptationOrderFields
  );

  if (!firstPart) return undefined;

  return `${firstPart}${
    order.order.charAt(0).toUpperCase() + order.order.slice(1)
  }`;
};

// Получение сортировки для таблицы из фильтра
const adaptationProcessFilterToOrder = (
  filter: string | undefined
): OrderFilter<AdaptationProcessListItem> => {
  if (!filter) return { order: null, orderBy: null };

  const sort = ['Asc', 'Desc'];
  let last;
  let first;

  if (filter.includes(sort[0])) {
    last = filter.slice(-3);
    first = filter.slice(0, -3);
  } else if (filter.includes(sort[1])) {
    last = filter.slice(-4);
    first = filter.slice(0, -4);
  } else {
    return { order: null, orderBy: null };
  }

  const orderBy = getAdaptationOrderFieldByFilter(first);

  return {
    orderBy,
    order: last.toLowerCase(),
  } as OrderFilter<AdaptationProcessListItem>;
};

export const useAdaptationProcessesPage = () => {
  const [filter, setFilter] = useAdaptationProcessesURLState();

  /* Search */
  const handleSearch = (search: string) => {
    setFilter({ ...filter, SearchText: search, Page: 1 });
  };
  const handleClearSearch = () => {
    setFilter({ ...filter, SearchText: '', Page: 1 });
  };
  /* ===== */

  /* Pagination */
  const handleChangeLimit = (val: number) => {
    setFilter({ ...filter, Limit: val, Page: 1 });
  };
  const handleChangePage = (val: number) => {
    setFilter({ ...filter, Page: val });
  };
  /* ===== */

  /* Order */
  const handleChangeOrder = (val: OrderFilter<AdaptationProcessListItem>) => {
    setFilter({ ...filter, OrderBy: orderToAdaptationProcessFilter(val) });
  };

  const tableOrder = useMemo((): OrderFilter<AdaptationProcessListItem> => {
    return adaptationProcessFilterToOrder(filter.OrderBy);
  }, [filter.OrderBy]);
  /* ===== */

  const handleChangeFilter = (
    form: Omit<
      AdaptationProcessesCurrentListParams,
      'Page' | 'Limit' | 'SearchText' | 'OrderBy'
    >
  ) => {
    setFilter({ ...filter, ...form });
  };

  return {
    ...filter,
    handleSearch,
    handleClearSearch,
    handleChangeLimit,
    handleChangePage,
    handleChangeOrder,
    tableOrder,
    handleChangeFilter,
  };
};
