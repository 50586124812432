import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';
import { FC } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { CustomDatePicker } from 'shared/ui/base-ui';
import { DialogForForm, FormLayout } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { VALIDATION_SCHEMA } from './schema';
import {
  ChangeStartFinishDateFormFields,
  ChangeStartFinishDateFormProps,
} from './type';

export const ChangeStartFinishDateForm: FC<ChangeStartFinishDateFormProps> = ({
  onSubmit,
  renderFormActions,
  initValue,
}) => {
  const { control, handleSubmit } = useForm<ChangeStartFinishDateFormFields>({
    defaultValues: initValue,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const isDateStartChanged = useWatch({
    control,
    name: 'isDateStartChanged',
  });

  return (
    <FormLayout
      actions={renderFormActions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit, console.error)}
    >
      <Grid
        container
        gap={2}
        flexDirection={'column'}
        // flexWrap={'nowrap'}
        // alignItems={'flex-end'}
      >
        <Grid
          container
          // gap={2}
          flexDirection={'column'}
          // flex={'100%'}
          flexWrap={'nowrap'}
          // alignItems={'center'}
        >
          <Controller
            control={control}
            name='isDateStartChanged'
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    value={value}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                    }}
                  />
                }
                label='Изменить дату начала'
              />
            )}
          />

          <Collapse in={isDateStartChanged}>
            <Controller
              control={control}
              name='startDate'
              rules={{ required: isDateStartChanged }}
              render={({ field: { value, onChange } }) => (
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      required: isDateStartChanged,
                    },
                  }}
                  disabled={!isDateStartChanged}
                  sx={{ width: '100%' }}
                  label='Дата начала'
                  value={value || null}
                  onChange={onChange}
                />
              )}
            />
          </Collapse>
        </Grid>

        <Controller
          control={control}
          name='finisDate'
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error, invalid },
          }) => (
            <CustomDatePicker
              sx={{ width: '100%' }}
              label='Дата завершения'
              value={value || null}
              onChange={onChange}
              slotProps={{
                textField: {
                  required: true,
                  error: invalid,
                  helperText: error?.message,
                },
              }}
            />
          )}
        />
      </Grid>
    </FormLayout>
  );
};

interface ChangeStartFinishDateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: ChangeStartFinishDateFormFields) => void;
  isLoading?: boolean;
  initValue?: ChangeStartFinishDateFormFields;
}
export const ChangeStartFinishDateDialog: FC<
  ChangeStartFinishDateDialogProps
> = ({ isOpen, onClose, onSubmit, isLoading, initValue }) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={
        <DialogTitleForForm title='Изменение даты начала и даты окончания' />
      }
    >
      <ChangeStartFinishDateForm
        onSubmit={onSubmit}
        initValue={initValue}
        renderFormActions={() => [
          <LoadingButton
            loading={isLoading}
            key={1}
            variant='contained'
            type='submit'
          >
            Сохранить
          </LoadingButton>,
          <Button
            key={2}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
