import { Grid } from '@mui/material';
import { FC } from 'react';
import { CardHeaderProps } from './types';

export const CardHeader: FC<CardHeaderProps> = ({
  children,
  ...otherProps
}) => {
  return <Grid {...otherProps}>{children}</Grid>;
};
