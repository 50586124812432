import { STAGES } from 'features/visit/Stager/constants';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ALL_ROUTES } from 'shared/routes/constants/types';

export const useStageNavigator = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const stageNavigate = useCallback(
    (stage: STAGES) => {
      navigate(
        '/' +
          ALL_ROUTES.visitingPharmacies +
          '/' +
          ALL_ROUTES.new +
          '/' +
          stage +
          '/' +
          id
      );
    },
    [id]
  );

  const navigateToStart = useCallback(
    (isCompetitorInformation: boolean, id: number) => {
      navigate(
        '/' +
          ALL_ROUTES.visitingPharmacies +
          '/' +
          ALL_ROUTES.new +
          '/' +
          (isCompetitorInformation ? STAGES.stage2 : STAGES.stage4) +
          '/' +
          id
      );
    },
    []
  );

  return { navigateToStart, stageNavigate };
};
