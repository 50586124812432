import { Chip, Typography } from '@mui/material';
import { FC } from 'react';

import { EmployeeVisitingHomiesGetListResponse } from 'shared/api/visit/types';
import { CustomAutocomplete } from 'shared/ui/base-ui';

interface EmployeesInputProsp {
  value: EmployeeVisitingHomiesGetListResponse[];
  onChange: (value: EmployeeVisitingHomiesGetListResponse[]) => void;
  options: EmployeeVisitingHomiesGetListResponse[];
  isLoading?: boolean;
  disabled?: boolean;
}
export const EmployeesInput: FC<EmployeesInputProsp> = ({
  value,
  onChange,
  options,
  disabled,
  isLoading = false,
}) => {
  return (
    <CustomAutocomplete
      textFieldProps={{
        label: 'Участники посещения',
        placeholder: 'Выберите участников',
      }}
      loading={isLoading}
      loadingText='Загрузка участников...'
      disabled={disabled}
      multiple
      options={options}
      getOptionLabel={(option) =>
        option
          ? (option.employeeName || '') + ' ' + (option.employeePosition || '')
          : ''
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value || []}
      onChange={(_, value) => {
        onChange(value);
      }}
      renderOption={(props, option) => {
        return (
          <Typography
            {...props}
            key={option.id}
          >
            {option
              ? (option.employeeName || '') +
                ' ' +
                (option.employeePosition || '')
              : ''}
          </Typography>
        );
      }}
      renderTags={(value, getTagProps) => {
        return value.map((chip, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            size='small'
            {...getTagProps({ index })}
            sx={{
              borderRadius: '8px',
              maxWidth: '200px',
              margin: 0,
            }}
            label={chip?.employeeName}
          />
        ));
      }}
    />
  );
};
