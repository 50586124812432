import { VisitingChecklistCompetitorGetResponse } from 'shared/api/visit/types';

import { INIT_FORM_VALUE } from '../constants';
import { InformationAboutCompetitorsFormI } from '../type';

const NULL_MAS = [null, null, null, null];

type PrepareDataToInitStage2Type = (
  data: VisitingChecklistCompetitorGetResponse[] | undefined
) => InformationAboutCompetitorsFormI;

export const prepareDataToInitStage2: PrepareDataToInitStage2Type = (data) => {
  if (data && data.length > 0) {
    return {
      competitors: data.map((el) => ({
        competitor: { id: el.competitorId, title: el.competitorTitle } || null,
        checksCount: el.checksCount || null,
        productPrice: el.products?.map((prod) => prod.productPrice) || NULL_MAS,
      })),
      product:
        data[0].products?.map((el) => ({
          title: el.productTitle,
          id: el.productId,
        })) || NULL_MAS,
    };
  } else {
    return {
      competitors: [INIT_FORM_VALUE],
      product: NULL_MAS,
    };
  }
};
