import { ControlTaskProvider } from 'pages/tasks/contexts/control-task-context /ControlTaskContext';
import { MyTaskProvider } from 'pages/tasks/contexts/my-tasks-context/MyTaskContext';
import { WatchTaskProvider } from 'pages/tasks/contexts/watch-task-context';
import { ControlTasks } from 'pages/tasks/ControlTasks';
import { MyTasks } from 'pages/tasks/MyTasks';
import { RedirectFromIdToGuid } from 'pages/tasks/RedirectFromIdToGuid';
import { TasksId } from 'pages/tasks/TaskId';
import { WatchingTasks } from 'pages/tasks/WatchingTasks';
import { RouteObject } from 'react-router-dom';

import { ALL_ROUTES } from 'shared/routes/constants/types';

export const ROUTES_TASKS: RouteObject[] = [
  {
    path: ALL_ROUTES.tasks,
    children: [
      // {
      //   path: ALL_ROUTES.boards,
      //   element: <MyBoardsPage />,
      // },
      // {
      //   path: ALL_ROUTES.boardGuid,
      //   element: <BoardPage />,
      // },
      {
        path: ALL_ROUTES.myTasks,
        element: (
          <MyTaskProvider>
            <MyTasks />
          </MyTaskProvider>
        ),
      },
      {
        path: ALL_ROUTES.createdByMe,
        element: (
          <ControlTaskProvider>
            <ControlTasks />
          </ControlTaskProvider>
        ),
      },
      {
        path: ALL_ROUTES.monitoringTasks,
        element: (
          <WatchTaskProvider>
            <WatchingTasks />
          </WatchTaskProvider>
        ),
      },
      {
        path: ALL_ROUTES.tasksGuid,
        element: <TasksId />,
      },
    ],
  },
  {
    path: ALL_ROUTES.tasksId,
    element: <RedirectFromIdToGuid />,
  },
];
