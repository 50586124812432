import { usePaginationStateHandlerWithFilter } from 'shared/hooks/usePaginationStateHandlerWithFilter';

import { useUrlStatePushNotificationsFilter } from './use-url-state-push-notifications-list';

export const useFilterPushNotificationsListPage = () => {
  const { filter, setFilter } = useUrlStatePushNotificationsFilter();

  const { handleChangePage, handleChangePageSize, currentPage, pageSize } =
    usePaginationStateHandlerWithFilter({
      filter,
      setFilter,
      keyPage: 'Page',
      keyPageSize: 'Limit',
    });

  return {
    handleChangePage,
    handleChangePageSize,
    currentPage,
    pageSize,
    filter,
  };
};
