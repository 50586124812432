import { DeleteAdaptationProcess } from 'features/adaptation/delete-adaptation-process';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';
import { AdaptationProcessTableType } from '../type';

export const renderActionsCurrentAdaptations: AdaptationProcessTableType['renderActions'] =
  ({ adaptationProcessGuid }) => {
    return (
      <UiTableActions>
        {adaptationProcessGuid && (
          <DeleteAdaptationProcess
            adaptationProcessGuid={adaptationProcessGuid}
          />
        )}
      </UiTableActions>
    );
  };
