import { ControllFilterItemModel } from 'shared/api/services/types';

export interface MyControllFilterItemModel extends ControllFilterItemModel {
  depId: string;
  deps: string[];
}

const FILTER_ITEM_TYPE = [
  'parentOrgDirections',
  'parentOrgDepartments',
  'parentOrgOffices',
  'parentOrgPositions',
  'parentRegions',
  'parentDepartments',
  'parentDivisions',
  'parentRetails',
];

const buildPsevdoGuid = (prefix: string, guids: string[]): string[] => {
  return guids.map((guid) => `${prefix}_${guid}`);
};

export const generateFilterDeps = (
  store: ControllFilterItemModel[] | undefined | null
): MyControllFilterItemModel[] | null => {
  if (!store) return null;

  return store.map((item) => {
    const {
      parentDepartments,
      parentDivisions,
      parentOrgDepartments,
      parentOrgDirections,
      parentOrgOffices,
      parentOrgPositions,
      parentRegions,
      parentRetails,
    } = item;

    return {
      ...item,
      deps: [
        ...buildPsevdoGuid('parentDepartments', parentDepartments || []),
        ...buildPsevdoGuid('parentDivisions', parentDivisions || []),
        ...buildPsevdoGuid('parentOrgDepartments', parentOrgDepartments || []),
        ...buildPsevdoGuid('parentOrgDirections', parentOrgDirections || []),
        ...buildPsevdoGuid('parentOrgOffices', parentOrgOffices || []),
        ...buildPsevdoGuid('parentOrgPositions', parentOrgPositions || []),
        ...buildPsevdoGuid('parentRegions', parentRegions || []),
        ...buildPsevdoGuid('parentRetails', parentRetails || []),
      ],
      depId: `${FILTER_ITEM_TYPE[item.type]}_${item.guid}`,
    };
  });
};
