// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { getProblemFilesDownloadProblemFileId } from 'shared/api/visit/services';

export const downloadFile = async (fileId: number, fileName: string) => {
  const { status, data: base64Data } =
    await getProblemFilesDownloadProblemFileId(fileId, {
      responseType: 'blob',
    });

  if (status !== 200) return;

  const url = window.URL.createObjectURL(new Blob([base64Data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${fileName}`);
  // // 3. Append to html pageF
  document.body.appendChild(link);
  // // 4. Force download
  link.click();
  // // 5. Clean up and remove the link
  link!.parentNode!.removeChild(link);
};
