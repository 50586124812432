import { RetailOpeningProcessResponsesGetListPosition } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';

export const sortPositions = (
  positions: RetailOpeningProcessResponsesGetListPosition[]
) => {
  return [...positions].sort((a, b) => {
    if (a.category.title === b.category.title) {
      return a.title > b.title ? 1 : -1;
    }

    return a.category.title > b.category.title ? 1 : -1;
  });
};
