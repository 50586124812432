export const ProfileSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      fill='none'
      viewBox='0 0 22 22'
    >
      <path
        fill='#116E6B'
        d='M0 18.778c.114-.336.143-.691.268-1.026.423-1.13 1.169-1.97 2.238-2.516a21.922 21.922 0 014.056-1.59c.434-.122.641-.02.792.401l2.001 5.576c.08-.38.15-.71.216-1.038.126-.63.25-1.262.378-1.892a.586.586 0 00-.02-.314c-.297-.879-.587-1.76-.88-2.64-.052-.154-.073-.306.001-.457a.487.487 0 01.47-.28c.98-.002 1.96-.008 2.94.002.419.004.616.313.48.728-.277.847-.557 1.694-.844 2.538a.946.946 0 00-.037.52c.184.881.353 1.766.53 2.648.009.047.03.091.054.159.63-1.755 1.25-3.488 1.87-5.22l.124-.341c.145-.399.36-.512.76-.399.935.262 1.847.59 2.74.971.518.222 1.039.443 1.525.727 1.478.867 2.255 2.166 2.32 3.878.018.469.06.947-.103 1.404-.27.76-.8 1.212-1.596 1.35-.034.007-.066.022-.1.033H1.804a1.783 1.783 0 01-1.237-.536A1.791 1.791 0 010 20.195v-1.031a.328.328 0 000-.258v-.128z'
      ></path>
      <path
        fill='#116E6B'
        d='M0 18.907c.074.085.063.172 0 .257v-.257zM10.993 0a5.99 5.99 0 015.993 5.988 5.977 5.977 0 01-5.99 6.006A5.98 5.98 0 015.001 5.99 5.993 5.993 0 0110.993 0z'
      ></path>
    </svg>
  );
};
