/* eslint-disable @typescript-eslint/no-empty-function */
import { useUserDataFetch } from 'entities/user/useUserDataFetch';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { postEmployeeUpdateAccountSettings } from 'shared/api/services-auth/services';
import { SaveFilterProvider } from './save-filter-context';
import { TableRawSettingsProvider } from './table-raw-settings-context';
import { AppSettingsType } from './type';

export interface UserAppSettingsContextType {
  appSettings: AppSettingsType | undefined;
  updateAppSettings: (newAppSettings: AppSettingsType | undefined) => void;
}

export const USER_APP_SETTINGS_CONTEXT_INIT_VALUE: UserAppSettingsContextType =
  {
    appSettings: undefined,
    updateAppSettings: () => {},
  };

export const UserAppSettingsContext = createContext<UserAppSettingsContextType>(
  USER_APP_SETTINGS_CONTEXT_INIT_VALUE
);

export const useAppSettings = () => useContext(UserAppSettingsContext);

export const UserAppSettingsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { postAppSettings } = usePostAppSettings();
  const { user } = useUserDataFetch();
  const [appSettings, setAppSettings] = useState<AppSettingsType | undefined>();

  useEffect(() => {
    console.log('app_v : 0.1.11');
  }, []);

  useEffect(() => {
    // postAppSettings('{}');
    if (!user) setAppSettings(undefined);
    else if (!user.accountSettings) setAppSettings(INIT_APP_SETTINGS);
    else setAppSettings(parseUserSettings(user.accountSettings));
  }, [user]);

  const updateAppSettings = (newAppSettings: AppSettingsType | undefined) => {
    postAppSettings(JSON.stringify(newAppSettings));
    setAppSettings(newAppSettings);
  };

  /* DEV */
  // useEffect(() => {
  //   console.log('appSettings >> ', appSettings);
  // }, [appSettings]);
  /* ===== */

  return (
    <UserAppSettingsContext.Provider value={{ appSettings, updateAppSettings }}>
      <SaveFilterProvider>
        <TableRawSettingsProvider>{children}</TableRawSettingsProvider>
      </SaveFilterProvider>
    </UserAppSettingsContext.Provider>
  );
};

export const parseUserSettings = (strSettings: string): AppSettingsType => {
  return JSON.parse(strSettings);
};

const INIT_APP_SETTINGS = {};

export const usePostAppSettings = () => {
  const [isLoading, setIsLoading] = useState(false);

  const postAppSettings = async (settings: string) => {
    try {
      setIsLoading(true);
      await postEmployeeUpdateAccountSettings({ settings });
    } catch (error) {
      console.error('usePostAppSettings.postAppSettings', error);
    }
    setIsLoading(false);
  };

  return { postAppSettings, isLoading };
};
