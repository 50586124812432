import {
  OnlineStoreResponsesFirebaseNotificationType,
  OnlineStoreResponsesFirebaseTopic,
} from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import {
  createIsOptionEqualToValueByKey,
  createLabelOnKey,
  createRenderOptionByKey,
} from 'shared/utils/autocomplite-helpers';

export const isOptionEqualToValueByFirebaseTopic =
  createIsOptionEqualToValueByKey<OnlineStoreResponsesFirebaseTopic>(
    'topicGuid'
  );
export const getOptionLabelByFirebaseTopic =
  createLabelOnKey<OnlineStoreResponsesFirebaseTopic>('topicTitle');

export const renderOptionByFirebaseTopic =
  createRenderOptionByKey<OnlineStoreResponsesFirebaseTopic>({
    keyGuid: 'topicGuid',
    keyLabel: 'topicTitle',
  });

export const isOptionEqualToValueByFirebaseNotificationType =
  createIsOptionEqualToValueByKey<OnlineStoreResponsesFirebaseNotificationType>(
    'notificationTypeGuid'
  );
export const getOptionLabelByFirebaseNotificationType =
  createLabelOnKey<OnlineStoreResponsesFirebaseNotificationType>(
    'notificationTypeTitle'
  );

export const renderOptionByFirebaseNotificationType =
  createRenderOptionByKey<OnlineStoreResponsesFirebaseNotificationType>({
    keyGuid: 'notificationTypeGuid',
    keyLabel: 'notificationTypeTitle',
  });
