import {
  Box,
  Grid,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from '@mui/material';
import { FC } from 'react';
import { theme } from 'shared/theme/theme';

export const SwipeableDrawerUi: FC<SwipeableDrawerProps> = ({
  PaperProps,
  children,
  ...otherProps
}) => {
  return (
    <SwipeableDrawer
      anchor='bottom'
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        ...PaperProps,
        sx: {
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          ...(PaperProps || { sx: {} }).sx,
        },
      }}
      {...otherProps}
    >
      <Grid sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'fixed',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            right: 0,
            left: 0,
            height: 40,
            backgroundColor: 'white',
            zIndex: 10,
            boxShadow: '0px 5px 5px -5px rgba(34, 60, 80, 0.6)',
          }}
        >
          <Box
            sx={{
              width: 60,
              height: 6,
              backgroundColor: theme.palette.customGrey.main,
              borderRadius: 3,
              position: 'absolute',
              top: 17,
              left: 'calc(50% - 30px)',
            }}
          />
        </Box>
        <Box sx={{ height: 40 }} />
        <Grid sx={{ maxHeight: '80vh', overflow: 'auto' }}>{children}</Grid>
      </Grid>
    </SwipeableDrawer>
  );
};
