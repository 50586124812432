import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { ALL_ROUTES } from 'shared/routes/constants/types';

import { Link } from 'shared/ui/base-ui';

import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const OPENING_TAMPLATES_FIELD_MAP: FieldMapType<RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate>[] =
  [
    {
      field: 'title',
      title: 'Название Шаблона',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 0,
      renderComponent: (value) => {
        return (
          <Link
            to={`/${
              ALL_ROUTES.retailOpeningProcess
            }/${ALL_ROUTES.openingTemplate.replace(':guid', value.guid)}`}
          >
            {value.title}
          </Link>
        );
      },
    },
    {
      field: 'isAutorun',
      title: 'Автоматический запуск',
      getTableCellProps: () => {
        return { align: 'center' };
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Typography>{value.isAutorun ? 'Да' : 'Нет'}</Typography>;
      },
    },
    {
      field: 'createdAt',
      title: 'Дата создания',
      getTableCellProps: () => {
        return { align: 'center' };
      },
      isShow: true,
      order: 2,
      renderComponent: (value) => {
        return (
          <Typography>
            {dayjs(value.createdAt).format(DATE_FORMATS.clientWithTime)}
          </Typography>
        );
      },
    },
    {
      field: 'createdBy',
      title: 'Автор',
      getTableCellProps: () => {
        return { align: 'center' };
      },
      isShow: true,
      order: 2,
      renderComponent: (value) => {
        return <Typography>{value.createdBy}</Typography>;
      },
    },
  ];
