import { Grid, Skeleton } from '@mui/material';

export const BasicVisitInfoFormSkeleton = () => {
  return (
    <Grid
      container
      flexDirection={'column'}
      gap={2}
    >
      <Skeleton
        height={35}
        variant='rounded'
      />
      <Skeleton
        height={40}
        variant='rounded'
      />
      <Skeleton
        height={40}
        variant='rounded'
      />
      <Skeleton
        variant='rounded'
        height={40}
        width={250}
      />
      <Skeleton
        height={40}
        variant='rounded'
      />
    </Grid>
  );
};
