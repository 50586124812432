import dayjs from 'dayjs';
import {
  CoreSystemEnumsObjectActions,
  OnlineStoreResponsesFirebaseDistribution,
} from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { NotificationCreateFormValue } from '../forms/form-push-notifications';

export const prepareDistributionNotificationToFormValue = (
  distribytion: OnlineStoreResponsesFirebaseDistribution
): NotificationCreateFormValue => {
  const init: NotificationCreateFormValue = {
    distributionTitle: distribytion.distributionTitle,
    message: distribytion.message || '',
    url: distribytion.url || '',
    imageUrl: distribytion.imageUrl || null,
    imageAction: CoreSystemEnumsObjectActions.None,

    fileAction: CoreSystemEnumsObjectActions.None,
    fileUrl: distribytion.fileUrl || null,
    topics: distribytion.topics,

    isSendingNow: !distribytion.dateTimeSending,
    dateTimeSending: distribytion.dateTimeSending
      ? dayjs(distribytion.dateTimeSending)
      : null,

    isSaveForUser: distribytion.isSaveForUser,
    recipientsFrom: distribytion.fileUrl ? 'file' : 'topics',
    notificationType:
      distribytion.notificationTypeGuid && distribytion.notificationTypeTitle
        ? {
            notificationTypeGuid: distribytion.notificationTypeGuid,
            notificationTypeTitle: distribytion.notificationTypeTitle,
          }
        : null,
    notificationTypeValue: distribytion.notificationTypeValue || '',
  };

  return init;
};
