import { useStoreMap } from 'effector-react';
import { FC } from 'react';

import { CustomTextField } from 'shared/ui/custom-text-field';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormListAdditionalyFieldsString: FC = () => {
  const arrayAdditionalFields = useStoreMap(
    documentFormModel.processAdditionalFields,
    (processAdditionalFields) => {
      return (
        processAdditionalFields &&
        processAdditionalFields.stringAdditionallyFields
      );
    }
  );

  if (!arrayAdditionalFields) return <></>;

  return (
    <>
      {arrayAdditionalFields.map(({ id, isRequired, title }) => {
        return (
          <DocumentFormAdditionalyFieldString
            key={id}
            required={isRequired}
            label={title}
            id={id}
          />
        );
      })}
    </>
  );
};

const DocumentFormAdditionalyFieldString: FC<{
  id: number;
  required?: boolean;
  label?: string;
}> = ({ id, required, label = '-' }) => {
  const value = useStoreMap(documentFormModel.form, (form) => {
    if (form && form.stringAdditionallyFields) {
      return (
        form.stringAdditionallyFields.find((item) => item.id === id)?.value ||
        ''
      );
    } else {
      return '';
    }
  });
  const [isMobile] = useDevice();

  return (
    <CustomTextField
      key={id}
      propsTextField={{
        required: required,
        value,
        onChange: (e) => {
          documentFormModel.formEvent.setAdditionallyFieldString({
            id,
            value: e.target.value,
          });
        },
        placeholder: isMobile ? label : '',
      }}
      label={label}
      sx={{ flexBasis: ['100%', '45%'] }}
    />
  );
};
