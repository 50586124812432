import { Close, MoreVert } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useMemo } from 'react';
import { AppActionBar } from 'shared/ui/app-action-bar';
import { IconWithMenu } from 'shared/ui/icon-with-menu';
import { BoardAppActionBarProps } from './type';

export const BoardAppActionBar: FC<BoardAppActionBarProps> = ({
  isOpen,
  onClose,
  count,
  getAdditionalActions,
  getFastActions,
}) => {
  const fastActions = useMemo(() => {
    return getFastActions?.() || [];
  }, [getFastActions]);

  const additionActions = useMemo(() => {
    return getAdditionalActions?.() || [];
  }, [getAdditionalActions]);

  return (
    <AppActionBar
      isOpen={isOpen}
      paperProps={{
        sx: { pl: 2 },
        onClick: (e) => {
          e.stopPropagation();
        },
      }}
    >
      <Typography>Выбрано {count}</Typography>
      <ButtonGroup
        variant='text'
        size='large'
        color='primary'
      >
        {fastActions.map((action) => (
          <Tooltip
            key={action.title}
            title={action.title}
            arrow
          >
            <Button onClick={action.onClick}>{action.icon}</Button>
          </Tooltip>
        ))}
      </ButtonGroup>
      <IconWithMenu
        icon={<MoreVert />}
        options={additionActions}
      />
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </AppActionBar>
  );
};
