import { FC } from 'react';

import { useMediaQuery } from '@mui/material';
import { theme } from 'shared/theme/theme';
import { OpeningTemplatesTasksList } from './OpeningTemplatesTasksList';
import { OpeningTemplatesTasksTable } from './OpeningTemplatesTasksTable';
import { OpeningTemplatesTasksTableProps } from './types';

export const OpeningTemplatesTasksAdaptiveTable: FC<
  OpeningTemplatesTasksTableProps
> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) return <OpeningTemplatesTasksList {...props} />;
  else return <OpeningTemplatesTasksTable {...props} />;
};
