import { addOnIndexAndReorderTasks } from 'entities/task/libs/addOnIndexAndReorderTasks';
import { cbSortTaskOnOrder } from 'entities/task/libs/cbSortTaskOnOrder';
import { deleteOnIndexAndReorderTasks } from 'entities/task/libs/deleteOnIndexAndReorderTasks';

import { GetListBoardTask } from '../../types';

export const updateTasksListForBoardOnChangeTasksOrder =
  (dropParams: IDropParamsTasks) => (taskList: GetListBoardTask[]) => {
    const { toColumnGuid, fromColumnGuid, toIndex, fromIndex, taskGuid } =
      dropParams;

    /* ищем таску которую нужно перенести */
    const task = taskList.find((item) => item.taskGuid === taskGuid);
    if (!task) {
      return taskList;
    }

    /* Все задачи из колонки в которую нужно перенести */
    const tasksForToColumn = taskList
      .filter((item) => item.boardColumnGuid === toColumnGuid)
      .sort(cbSortTaskOnOrder);

    /* Если переносим из другой колонки то ищем все задачи для этой колонки */
    const tasksForFromColumn =
      toColumnGuid !== fromColumnGuid
        ? taskList
            .filter((item) => item.boardColumnGuid === fromColumnGuid)
            .sort(cbSortTaskOnOrder)
        : undefined;

    let newTasksListForToColumn: GetListBoardTask[] = [];
    let newTasksListForFromColumn: GetListBoardTask[] = [];

    /* Задачи без колонок в которых происходит перемещение */
    const newTasksListWithOutToColumnAndFromColumn = [
      ...taskList.filter(
        (item) =>
          item.boardColumnGuid !== toColumnGuid &&
          item.boardColumnGuid !== fromColumnGuid
      ),
    ];

    /* Перемещение в колонке */
    if (!tasksForFromColumn) {
      /* Вначале удаляем задачу из колонки с изменением order всех зависимых задач  */
      const tasksForToColumnWithOutTask = deleteOnIndexAndReorderTasks({
        arr: tasksForToColumn,
        deleteIndex: fromIndex,
      });
      /* Добавляем задачу с изменением order всех зависимых задач  */
      newTasksListForToColumn = addOnIndexAndReorderTasks({
        arr: tasksForToColumnWithOutTask,
        item: task,
        addToIndex: toIndex,
      });
    } /* Перемещение из другой колонки */ else {
      /* Формируем новую колонку без задачи, которую перенесли */
      newTasksListForFromColumn = deleteOnIndexAndReorderTasks({
        arr: tasksForFromColumn,
        deleteIndex: fromIndex,
      });
      /* Формируем новую колонку с задачей, которую перенесли */
      newTasksListForToColumn = addOnIndexAndReorderTasks({
        arr: tasksForToColumn,
        item: { ...task, boardColumnGuid: toColumnGuid },
        addToIndex: toIndex,
      });
    }

    return [
      ...newTasksListWithOutToColumnAndFromColumn,
      ...newTasksListForToColumn,
      ...newTasksListForFromColumn, // если перемещение в колонке - пустой массив
    ];
  };

/**
 * @description Нахождение индекса следующей задачи
 * @param tasks список задач
 * @param prevTaskGuid guid предыдущей задачи
 * @param currentTaskGuid guid задачи, которой надо найти индекс
 * @returns новый индекс текущей задачи
 */
export const findIndexTo = (
  tasks: GetListBoardTask[],
  prevTaskGuid: string,
  currentTaskGuid: string
) => {
  // Находим предыдущую задачу
  const prevTask = tasks.find((el) => el.taskGuid === prevTaskGuid);
  if (!prevTask) throw new Error('Нет задачи');

  // Находим задачи колонки в которую перетаскиваем задачу, без текущей задачи
  const columnTasks = tasks.filter(
    (el) =>
      el.boardColumnGuid === prevTask.boardColumnGuid &&
      el.taskGuid !== currentTaskGuid
  );

  // Сортируем задачи колонки
  columnTasks.sort(
    (a, b) => a.boardColumnOrderNumber - b.boardColumnOrderNumber
  );

  // Находим index предыдущей задачи + 1
  return columnTasks.findIndex((el) => el.taskGuid === prevTaskGuid) + 1;
};

/**
 * @description Получение guid колонки откуда перетаскивается задача
 * @param tasks Список всех задач
 * @param taskGuid guid задачи
 * @returns guid колонки откуда перетаскивается задача
 */
export const findFromColumnGuid = (
  tasks: GetListBoardTask[],
  taskGuid: string
) => {
  const task = tasks.find((task) => task.taskGuid === taskGuid);

  if (!task) throw new Error('Нет задачи');

  return task.boardColumnGuid;
};

/**
 * @description Нахождение index в старой колонке
 * @param tasks Список задач
 * @param taskGuid guid перетаскиваемой задачи
 * @returns index задачи в прошлой колонке
 */
export const findTaskIndexInColumn = (
  tasks: GetListBoardTask[],
  taskGuid: string
) => {
  // Получение задачи
  const task = tasks.find((task) => task.taskGuid === taskGuid);

  if (!task) throw new Error('Нет задачи');

  // Получение задач колонки
  const fromColumnTasks = tasks.filter(
    (el) => el.boardColumnGuid === task.boardColumnGuid
  );

  // Сортировка
  fromColumnTasks.sort(
    (a, b) => a.boardColumnOrderNumber - b.boardColumnOrderNumber
  );

  // Нахождение index
  const fromIndex = fromColumnTasks.findIndex((el) => el.taskGuid === taskGuid);

  return fromIndex;
};

export interface IDropParamsTasks {
  taskGuid: string;
  toColumnGuid: string;
  fromColumnGuid: string;
  toIndex: number;
  fromIndex: number;
}
