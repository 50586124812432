export const YouTubeSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='21'
      fill='none'
      viewBox='0 0 26 21'
    >
      <path
        fill='#ED2D2C'
        color='#ED2D2C'
        d='M12.976 20.926c-.051 0-6.725-.051-8.778-.256-.102 0-.256-.051-.41-.051a3.628 3.628 0 01-2.464-1.177c-.77-.87-1.027-2.816-1.027-2.816A46.174 46.174 0 01.04 12.02V9.87c0-2.303.257-4.606.257-4.657 0-.051.257-1.945 1.027-2.815.616-.717 1.54-1.126 2.515-1.177h.051C7.432.965 12.873.914 12.925.914c.051 0 5.492.05 9.034.307h.103c.975 0 1.848.46 2.464 1.177.77.87 1.026 2.815 1.026 2.815s.257 2.354.257 4.657v2.15c0 2.252-.257 4.555-.257 4.607 0 0-.256 1.944-1.026 2.815-.616.716-1.54 1.125-2.516 1.177h-.05c-3.492.307-8.933.307-8.984.307zM9.845 6.288v9.878l8.47-4.811-8.47-5.067z'
      ></path>
    </svg>
  );
};
