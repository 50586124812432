import { Link as LinkMui, LinkProps as LinkPropsMui } from '@mui/material';
import { FC } from 'react';
import {
  Link as LinkRouter,
  LinkProps as LinkPropsRouter,
} from 'react-router-dom';

export const Link: FC<LinkPropsRouter & Omit<LinkPropsMui, 'href'>> = ({
  children,
  ...props
}) => {
  return (
    <LinkMui
      component={LinkRouter}
      {...props}
    >
      {children}
    </LinkMui>
  );
};
