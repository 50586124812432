import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useCreateAdaptationStageTaskMutation } from 'shared/api/service-adaptation/rtk/adaptationStageTasksProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { CreateAdaptationTemplateTaskDialog } from '../forms/AdaptationTemplateTaskForm/Dialogs/CreateAdaptationTemplateTaskDialog';
import { transformFromToAdaptationTemplateStageTaskFetch } from '../forms/AdaptationTemplateTaskForm/libs/transformFromToAdaptationTemplateStageTaskFetch';
import { AdaptationTemplateTaskFormFields } from '../forms/AdaptationTemplateTaskForm/type';

export const useCreateStageTemplateTask = (
  templateGuid: string,
  stageGuid: string
) => {
  const { open: openSnackbar } = useSnackbar();
  const { close, isOpen, open } = useViewModal();

  const [createTask, { isLoading }] = useCreateAdaptationStageTaskMutation();

  const handleCreateTask = useCallback(
    (form: AdaptationTemplateTaskFormFields) => {
      createTask({
        adaptationGuid: templateGuid,
        stageGuid,
        data: transformFromToAdaptationTemplateStageTaskFetch(form),
      }).then((res) => {
        if ('error' in res) {
          openSnackbar({
            duration: 5000,
            type: 'error',
            title: getMessageFromFetchBaseQueryError(res.error),
          });

          return;
        }

        close();
      });
    },
    [templateGuid, stageGuid, close]
  );

  return {
    CreateTemplateTaskDialog: (
      <CreateAdaptationTemplateTaskDialog
        isOpen={isOpen}
        isLoading={isLoading}
        onSubmit={handleCreateTask}
        onClose={close}
      />
    ),
    openCreateTemplateTaskDialog: open,
    isCreatingTemplateTask: isLoading,
  };
};
