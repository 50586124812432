import { IFormCreatedTaskUiBase } from '../type';

export const INITIAL_STATE_FORM_TASK: IFormCreatedTaskUiBase = {
  title: '',
  executer: null,
  coExecuter: null,
  observers: null,
  dateStart: null,
  dateFinish: null,
  description: '',
  units: 0,
  isTarget: false,
  isControl: true,
  originEntityId: undefined,
  originId: undefined,
  originAdditionallyEntityId: undefined,
  guid: undefined,
  boardColumnGuid: undefined,
  parentTaskGuid: undefined,
};
