import { FC, useEffect, useLayoutEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';

import { useForm, useWatch } from 'react-hook-form';
import { useGetDocumentFlowObserveFiltersQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';

import { AutocompliteWithController } from 'shared/fields-with-contoller';
import { getOptionLabelByTitle } from 'shared/tarnsform-helper/getOptionLabelByTitle';

import {
  isOptionEqualToValueByIdAndType,
  renderOptionByObserveDocumentFilterInputs,
} from 'entities/document/inputs/observer-filter-autocomplite';
import { ObserveFilterItemModel } from 'shared/api/services-document-flow/types';

import { EMPT_ARR } from 'widgets/training/filter/constants';
import { DEFAULT_BASE_FORM_VAUE } from '../constants';
import { DocumentFlowBaseFormFilterModal } from '../ui';
import { DocumentFlowContainerBaseFormFilterModalProps } from '../ui/document-flow-container-base-form-filter-modal';
import { filterAllowedOptions } from './libs';
import { FilterAllowedOptionsParams } from './libs/filterAllowedOptions';
import { SCHEME_OBSERVE_FILTER_FORM } from './scheme';
import { DocumentFlowFilterObserveForm } from './type';

const DEFAULT_VAUE: DocumentFlowFilterObserveForm = {
  ...DEFAULT_BASE_FORM_VAUE,
  employees: [],
  departmentsAndDepts: [],
  directionsAndRegions: [],
  offices: [],
  positions: [],
};

export const DocumentObserveFilterModal: FC<
  Omit<DocumentFlowContainerBaseFormFilterModalProps, 'onSubmit'> & {
    isLoading?: boolean;
    onSubmit: (form: DocumentFlowFilterObserveForm) => void;
    initialValue: DocumentFlowFilterObserveForm | undefined;
  }
> = ({ isOpen, isLoading, onSubmit, onClose, initialValue = DEFAULT_VAUE }) => {
  const skip = !isOpen;

  const {
    data: observeFilterOptions,
    isLoading: isLoadingObserveFilterOptions,
  } = useGetDocumentFlowObserveFiltersQuery();

  const isLoadingAny = isLoading || isLoadingObserveFilterOptions;

  const methods = useForm<DocumentFlowFilterObserveForm>({
    defaultValues: initialValue,
    resolver: yupResolver(SCHEME_OBSERVE_FILTER_FORM),
  });

  const { handleSubmit, reset, control, setValue } = methods;

  useLayoutEffect(() => {
    if (isOpen) {
      reset(initialValue);
    }
  }, [isOpen, initialValue]);

  const directionsAndRegions = useWatch({
    control,
    name: 'directionsAndRegions',
  });
  const departmentsAndDepts = useWatch({
    control,
    name: 'departmentsAndDepts',
  });
  const offices = useWatch({
    control,
    name: 'offices',
  });
  const positions = useWatch({
    control,
    name: 'positions',
  });
  const employees = useWatch({
    control,
    name: 'employees',
  });

  const onChangeDepsAutocomplite = (key: keyof FilterAllowedOptionsParams) => {
    return (
      e: React.SyntheticEvent<Element, Event>,
      value: ObserveFilterItemModel[]
    ) => {
      if (!observeFilterOptions) return;

      const {
        newObserveFilterOptionsAll,
        filterDepartmentsAndOrgDepartmensValues,
        filterEmployeesValues,
        filterOfficesValues,
        filterPositionsValues,
        directionsAndRegionsValues,
      } = filterAllowedOptions({
        observeFilterOptionsAll: observeFilterOptions,
        directionsAndRegionsValues: directionsAndRegions,
        departmentsAndDeptsValue: departmentsAndDepts,
        officeValue: offices,
        positionsValue: positions,
        employesValue: employees,
        [key]: value,
      });

      setObserveFilterOptions(newObserveFilterOptionsAll);
      setValue('directionsAndRegions', directionsAndRegionsValues);
      setValue('departmentsAndDepts', filterDepartmentsAndOrgDepartmensValues);
      setValue('offices', filterOfficesValues);
      setValue('positions', filterPositionsValues);
      setValue('employees', filterEmployeesValues);
    };
  };

  const [observeFilterOptionsState, setObserveFilterOptions] =
    useState(observeFilterOptions);

  useEffect(() => {
    if (observeFilterOptions && initialValue) {
      const { newObserveFilterOptionsAll } = filterAllowedOptions({
        observeFilterOptionsAll: observeFilterOptions,
        directionsAndRegionsValues: initialValue.directionsAndRegions,
        departmentsAndDeptsValue: initialValue.departmentsAndDepts,
        officeValue: initialValue.offices,
        positionsValue: initialValue.positions,
        employesValue: initialValue.employees,
      });

      setObserveFilterOptions(newObserveFilterOptionsAll);
    }
  }, [observeFilterOptions, initialValue]);

  return (
    <DocumentFlowBaseFormFilterModal.Container
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit, console.error)}
    >
      <AutocompliteWithController
        control={control}
        name={'directionsAndRegions'}
        multiple
        options={observeFilterOptions?.directionsAndRegions || EMPT_ARR}
        renderOption={renderOptionByObserveDocumentFilterInputs}
        getOptionLabel={getOptionLabelByTitle}
        onChange={onChangeDepsAutocomplite('directionsAndRegionsValues')}
        isOptionEqualToValue={isOptionEqualToValueByIdAndType}
        loading={isLoadingAny}
        textFieldProps={{
          label: 'Направление и Регионы',
        }}
      />
      <AutocompliteWithController
        control={control}
        name={'departmentsAndDepts'}
        multiple
        options={observeFilterOptionsState?.departmentsAndDepts || EMPT_ARR}
        renderOption={renderOptionByObserveDocumentFilterInputs}
        getOptionLabel={getOptionLabelByTitle}
        isOptionEqualToValue={isOptionEqualToValueByIdAndType}
        loading={isLoadingAny}
        onChange={onChangeDepsAutocomplite('departmentsAndDeptsValue')}
        textFieldProps={{
          label: 'Служба или аптечная сеть',
        }}
      />
      <AutocompliteWithController
        control={control}
        name={'offices'}
        multiple
        options={observeFilterOptionsState?.offices || EMPT_ARR}
        renderOption={renderOptionByObserveDocumentFilterInputs}
        getOptionLabel={getOptionLabelByTitle}
        isOptionEqualToValue={isOptionEqualToValueByIdAndType}
        loading={isLoadingAny}
        onChange={onChangeDepsAutocomplite('officeValue')}
        textFieldProps={{
          label: 'АУП',
        }}
      />
      <AutocompliteWithController
        control={control}
        name={'positions'}
        multiple
        options={observeFilterOptionsState?.positions || EMPT_ARR}
        renderOption={renderOptionByObserveDocumentFilterInputs}
        getOptionLabel={getOptionLabelByTitle}
        isOptionEqualToValue={isOptionEqualToValueByIdAndType}
        loading={isLoadingAny}
        onChange={onChangeDepsAutocomplite('positionsValue')}
        textFieldProps={{
          label: 'Должность АУП',
        }}
      />
      <AutocompliteWithController
        control={control}
        name={'employees'}
        multiple
        options={observeFilterOptionsState?.employees || EMPT_ARR}
        renderOption={renderOptionByObserveDocumentFilterInputs}
        getOptionLabel={getOptionLabelByTitle}
        isOptionEqualToValue={isOptionEqualToValueByIdAndType}
        loading={isLoadingAny}
        onChange={onChangeDepsAutocomplite('employesValue')}
        textFieldProps={{
          label: 'ФИО сотрудника',
        }}
      />
      <DocumentFlowBaseFormFilterModal.Provider {...methods}>
        <DocumentFlowBaseFormFilterModal.BaseInputs
          isLoading={isLoadingAny}
          skip={skip}
        />
      </DocumentFlowBaseFormFilterModal.Provider>
    </DocumentFlowBaseFormFilterModal.Container>
  );
};
