import { ThemeProvider } from '@mui/material';
import { LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import isBetween from 'dayjs/plugin/isBetween';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfirmProvider } from 'shared/confirm/ConfirmContext';
import { RouteProvider } from 'shared/routes/context/RouteProvider';
import { SnackbarProvider } from 'shared/snackbar-helper/SnackbarContext';
import { store } from 'shared/store/store';
import { UserAppSettingsProvider } from 'shared/user-app-settings/user-app-settings-context';
import App from './App';
import './index.css';
import { theme } from './shared/theme/theme';
//app
dayjs.locale('ru');
dayjs.extend(isBetween);

const ruLocale =
  ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <UserAppSettingsProvider>
    <RouteProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={'ru'}
            localeText={ruLocale}
          >
            <SnackbarProvider>
              <ConfirmProvider>
                <App />
              </ConfirmProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </RouteProvider>
  </UserAppSettingsProvider>
);
{
  /* <React.StrictMode></React.StrictMode> */
}
