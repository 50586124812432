import TextField from '@mui/material/TextField';
import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { AdeptLayoutForUiComponent } from '../adept-layout-for-ui-component';
import { CustomTextFieldProps } from './type';
/**
 * @description
 * Для работы с react-hook-form использовать
 * AutocompliteWithController / DatePikerWithController / TextFieldWithController / SwitchWithController.
 * @see {@link /shared/fields-with-contoller/ }
 */
export const CustomTextField: FC<CustomTextFieldProps> = ({
  containerSx,
  ...props
}) => {
  const [isMob] = useDevice();

  return (
    <AdeptLayoutForUiComponent
      containerSx={containerSx}
      label={props.label || ''}
    >
      <TextField
        {...props}
        sx={{
          width: '100%',
          ...props.sx,
        }}
        className='subvariant-adept'
        label={!isMob ? props.label : undefined}
        autoComplete='off'
      />
    </AdeptLayoutForUiComponent>
  );
};
