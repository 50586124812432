import { Typography } from '@mui/material';

import { APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS } from 'entities/apteka-legko/push-notifications';
import {
  FormNotificationsWithActions,
  FormNotificationsWithActionsProps,
  prepareDistributionNotificationToFormValue,
  prepareFormNotificationToFetchBody,
} from 'features/apteka-legko/push-notifications/form-push-notifications';

import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';

import {
  useGetFirebaseDistributionBuyGuidQuery,
  usePostFirebaseDistributionMutation,
} from 'shared/api/online-store-service/firebase/rtk';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

export const PageEditNotification = () => {
  const { guid } = useParams();

  const { data: distribytionByGuid, isLoading: isLoadingDistributionByGuid } =
    useGetFirebaseDistributionBuyGuidQuery(
      { guid: guid as string },
      { skip: !guid }
    );

  const initialForm = useMemo(() => {
    if (distribytionByGuid) {
      return prepareDistributionNotificationToFormValue(distribytionByGuid);
    }
  }, [distribytionByGuid]);

  const navigate = useNavigate();

  const [
    createDistrebutionNotifications,
    { isLoading: isLoadingDistributionNotifications, error },
  ] = usePostFirebaseDistributionMutation();

  const handleEditDistributionNotifications = useCallback<
    FormNotificationsWithActionsProps['onSubmit']
  >(
    async (form) => {
      const prepareBody = prepareFormNotificationToFetchBody(form);

      const response = await createDistrebutionNotifications({
        params: { ...prepareBody, distributionGuid: guid },
      });

      if ('data' in response) {
        navigate(APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS.list);
      }
    },
    [createDistrebutionNotifications, navigate, guid]
  );

  useShowFetchErrorInSnackbar(error);

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h5'
          fontWeight={'bold'}
        >
          Отправить push-уведомление (Редактирование)
        </Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content disabledPaddingXs>
        <FormNotificationsWithActions
          initialForm={initialForm}
          disabledSendActions={
            isLoadingDistributionByGuid ||
            isLoadingDistributionNotifications ||
            !distribytionByGuid?.isEditable
          }
          isLoading={
            isLoadingDistributionNotifications || isLoadingDistributionByGuid
          }
          onSubmit={handleEditDistributionNotifications}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
