import { useCallback, useLayoutEffect, useState } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';
import { transformFilterActionToUrlParams } from '../lib';
import { DocumentFlowFilterActionForm } from '../type';
import { useUrlStateDocumentActionFilter } from '../use-url-state-document-action-filter';
import { useInitialActionFilterFormFromUrlState } from './useInitialActionFilterFormFromUrlState';

export const useDocumentActionFilterModal = () => {
  const { isOpen, close, open } = useViewModal();
  const [onInitial, isLoadingInit] = useInitialActionFilterFormFromUrlState();
  const { setFilter, filter } = useUrlStateDocumentActionFilter();
  const [initialValue, setInitialValue] = useState<
    DocumentFlowFilterActionForm | undefined
  >();
  const onSubmitDocumentActionFilterModal = useCallback(
    (form: DocumentFlowFilterActionForm) => {
      const urlParams = transformFilterActionToUrlParams(form);

      setFilter({
        ...filter,
        ...urlParams,
        CurrentPage: 1,
      });
      close();
    },
    [filter, close]
  );

  useLayoutEffect(() => {
    if (isOpen) {
      onInitial().then((initValue) => {
        setInitialValue(initValue);
      });
    }
  }, [isOpen, setInitialValue]);

  return {
    onSubmitDocumentActionFilterModal,
    initialValueDocumentActionFilterModalForm: initialValue,
    isLoadingInitDocumentActionFilterModalForm: isLoadingInit,
    onCloseDocumentActionFilterModalForm: close,
    onOpenDocumentActionFilterModalForm: open,
    isOpenDocumentActionFilterModalForm: isOpen,
  };
};
