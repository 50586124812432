import { ListItemIcon, ListItemIconProps } from '@mui/material';
import { FC } from 'react';

export const ContextMenuItemIcon: FC<ListItemIconProps> = ({
  sx,
  children,
  ...props
}) => {
  return (
    <ListItemIcon
      sx={{ color: 'inherit', ...sx }}
      {...props}
    >
      {children}
    </ListItemIcon>
  );
};
