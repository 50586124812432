import { VisitingChecklistStageFourRequest } from 'shared/api/visit/types';

import { ProblemsFormI } from '../type';
import { arrayBufferToBase64 } from './arrayBufferToBase64';

type PrepareDataToFetchStage5Type = (
  data: ProblemsFormI
) => Promise<VisitingChecklistStageFourRequest>;

export const prepareDataToFetchStage5: PrepareDataToFetchStage5Type = async (
  data
) => {
  const problems = await Promise.all(
    data.problems.map(async (problem) =>
      problem.title.length > 0
        ? {
            problemId: problem.id,
            problemTitle: problem.title,
            addingFiles: await Promise.all(
              problem.files.toUpload.map(async (file) => {
                const file2 = file as unknown as File;
                const buf = await file2.arrayBuffer();

                return {
                  problemFileData: arrayBufferToBase64(buf),
                  problemFileTitle: file2.name,
                };
              })
            ),
            deletingFiles: problem.files.toDelete,
          }
        : undefined
    )
  );

  const ans = {
    problems: problems.filter((el) => el !== undefined),
  };

  return ans as VisitingChecklistStageFourRequest;
};
