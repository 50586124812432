import { FC } from 'react';
import {
  AutocompliteWithController,
  DatePikerWithController,
  TextFieldWithController,
} from 'shared/fields-with-contoller';
import { isOptionEqualToValueById } from 'shared/tarnsform-helper/isOptionEqualToValueById';
import { EMPT_ARR } from 'widgets/training/filter/constants';

import { groupByArticleType } from 'entities/document/inputs/article-autocomplite';
import { renderOptionCounterparty } from 'entities/document/inputs/counterparty-autocomplite';
import { filterOptionsCounterparty } from 'entities/document/inputs/counterparty-autocomplite/render-helper';
import { groupByIsMainCurrentEmployment } from 'entities/document/inputs/organization-autocomplite';
import { getOptionLabelByTitle } from 'shared/tarnsform-helper/getOptionLabelByTitle';
import { useGetDocumentFlowOptionsOptionsBaseFilterForm } from '../../hooks';

import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { renderOptionByTitleAndId } from 'shared/tarnsform-helper/renderOptionByTitleAndId';
import { DocumentFlowFilterBaseForm } from '../../types';
import { DocumentFlowBaseInputsFormFilterModalProps } from './types';

/**
 * Требуется FormProvider
 */
export const DocumentFlowBaseInputsFormFilterModal: FC<
  DocumentFlowBaseInputsFormFilterModalProps
> = ({ isLoading, skip }) => {
  const { control } = useFormContext<DocumentFlowFilterBaseForm>();

  const {
    optionsArticle,
    optionsCounterparty,
    optionsDocumentType,
    optionsOrganization,
    optionsStatus,
  } = useGetDocumentFlowOptionsOptionsBaseFilterForm({
    optionsArticle: skip,
    optionsCounterparty: skip,
    optionsDocumentType: skip,
    optionsEmployeeAll: skip,
    optionsOrganization: skip,
    optionsStatus: skip,
  });

  return (
    <>
      <AutocompliteWithController
        control={control}
        name={'statuses'}
        multiple
        disabled={isLoading}
        loading={isLoading}
        options={optionsStatus || EMPT_ARR}
        textFieldProps={{ label: 'Статусы' }}
        isOptionEqualToValue={isOptionEqualToValueById}
        getOptionLabel={getOptionLabelByTitle}
        renderOption={renderOptionByTitleAndId}
      />
      <AutocompliteWithController
        control={control}
        multiple
        disabled={isLoading}
        loading={isLoading}
        name={'organizations'}
        options={optionsOrganization || EMPT_ARR}
        textFieldProps={{ label: 'ЮЛ' }}
        getOptionLabel={getOptionLabelByTitle}
        isOptionEqualToValue={isOptionEqualToValueById}
        groupBy={groupByIsMainCurrentEmployment}
        renderOption={renderOptionByTitleAndId}
      />
      <AutocompliteWithController
        control={control}
        name={'articles'}
        multiple
        disabled={isLoading}
        loading={isLoading}
        options={optionsArticle || EMPT_ARR}
        textFieldProps={{ label: 'Статьи' }}
        isOptionEqualToValue={isOptionEqualToValueById}
        groupBy={groupByArticleType}
        getOptionLabel={getOptionLabelByTitle}
        renderOption={renderOptionByTitleAndId}
      />
      <AutocompliteWithController
        control={control}
        name={'contractors'}
        multiple
        disabled={isLoading}
        loading={isLoading}
        options={optionsCounterparty || EMPT_ARR}
        textFieldProps={{ label: 'Контрагент' }}
        isOptionEqualToValue={isOptionEqualToValueById}
        getOptionLabel={getOptionLabelByTitle}
        filterOptions={filterOptionsCounterparty}
        renderOption={renderOptionCounterparty}
      />

      <AutocompliteWithController
        control={control}
        name={'documentTypes'}
        multiple
        disabled={isLoading}
        loading={isLoading}
        options={optionsDocumentType || EMPT_ARR}
        textFieldProps={{ label: 'Тип документа' }}
        isOptionEqualToValue={isOptionEqualToValueById}
        getOptionLabel={getOptionLabelByTitle}
        renderOption={renderOptionByTitleAndId}
      />
      {/* DATE */}
      <Grid
        display={'flex'}
        flexDirection={['column', 'row']}
        gap={2}
      >
        <DatePikerWithController
          control={control}
          name={'dateFrom'}
          fullWidth
          disabled={isLoading}
          loading={isLoading}
          label={'Дата начала'}
          containerSx={{ flexBasis: undefined }}
        />
        <DatePikerWithController
          control={control}
          name={'dateTo'}
          fullWidth
          disabled={isLoading}
          loading={isLoading}
          label={'Дата окончания'}
          containerSx={{ flexBasis: undefined }}
        />
      </Grid>
      {/* SUM */}
      <Grid
        display={'flex'}
        flexDirection={['column', 'row']}
        gap={2}
      >
        <TextFieldWithController
          control={control}
          type='number'
          disabled={isLoading}
          name={'sumFrom'}
          label={'Сумма от'}
        />
        <TextFieldWithController
          control={control}
          disabled={isLoading}
          type='number'
          name={'sumTo'}
          label={'До'}
        />
      </Grid>
    </>
  );
};
