import { RemindeFormFields } from './type';

export const REMINDE_OPTIONS = [
  {
    id: 1,
    title: 'За 1 день',
    value: 1,
  },
  {
    id: 2,
    title: 'За неделю',
    value: 7,
  },
  {
    id: 3,
    title: 'За месяц',
    value: 30,
  },
  {
    id: 4,
    title: 'Свой вариант',
    value: null,
  },
];

export const INIT_REMINDE_FORM_VALUE: RemindeFormFields = {
  coExecutor: {
    isReminde: false,
    ticks: null,
    isCustom: false,
  },
  creator: {
    isReminde: false,
    ticks: null,
    isCustom: false,
  },
  executor: {
    isReminde: false,
    ticks: null,
    isCustom: false,
  },
  observer: {
    isReminde: false,
    ticks: null,
    isCustom: false,
  },
};
