import { createEffect, createStore } from 'effector';

import { getDepartments } from 'shared/api/visit/services';
import { DepartmentGetListResponse } from 'shared/api/visit/types';

export const fetchPlanningRNDepartmentsFX = createEffect({
  handler: async () => {
    return getDepartments();
  },
});

export const $planningRNDepartments = createStore<DepartmentGetListResponse[]>(
  []
).on(fetchPlanningRNDepartmentsFX.doneData, (_, res) => res.data);
