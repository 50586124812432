import {
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TableRow,
  Typography,
} from '@mui/material';
import { useStore, useStoreMap } from 'effector-react';

import { DocumentActionsReturnDocumentCommentModal } from 'features/document/document-actions/DocumentActionsReturnDocumentCommentModal';
import { DocumentActionManagerAgreeRequestOrderresetModal } from 'features/document/document-actions/OrderManagerAgreeReques';
import { DocumentIdActionsBottom } from 'features/document/document-actions/ui';
import { documentIdComment } from 'features/document/document-id/document-id-comments/model';
import { DocumentIdComments } from 'features/document/document-id/document-id-comments/ui';
import { UiDocumentIdProcessAgreeChain } from 'features/document/document-id/document-id-process-agree-chain/UiDocumentIdProcessAgreeChain';
import { FC, Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  DocumentEstimatedArticleModel,
  DocumentModel,
} from 'shared/api/services-document-flow/types';
import { DetailsUi } from 'shared/ui/details-ui';
import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  useTableCellsFromLocalStorage,
} from 'shared/ui/table';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import {
  CustomTitleSectionDetailsUi,
  DateFildAdaptive,
  DocumentNotFound,
  LayoutSection,
  RowFieldView,
  SX_BODY,
  SX_TITLE,
  TextFieldAdaptive,
} from 'shared/ui/ui';
import { formatingDate } from 'shared/utils/formating-date';
import { normolizeTitleFile } from 'shared/utils/notmolize-title-filter';
import { transformNumberSummInText } from 'shared/utils/transformSummInText';

import { getDocumentIdFx } from 'entities/document/api/document-id-effector';
import { documentGuid } from 'entities/document/model/document-id';

import dayjs from 'dayjs';
import { ListActionsDocument } from 'features/document/document-actions/ListActionsDocument';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { ContextMenu } from 'shared/ui/base-ui/context-menu';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { Tables } from 'shared/utils/controllers';
import {
  ListArticlesExpenseCardUi,
  prepareDocumentArticlesModelToArticlesView,
} from 'widgets/document-create/expenses-for-articles-viwe';
import { DocumentTitleUi } from 'widgets/document/document-table/ui';

export const DocumentGuid = () => {
  const files = useStoreMap(
    documentGuid.store,
    (document) => document && document.reasonDocumentFiles
  );
  const idDoc = useStoreMap(
    documentGuid.store,
    (document) => document && document.id
  );
  const isComments = useStoreMap(documentIdComment.comments, (store) =>
    Boolean(store.length > 0)
  );
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const documentId = useStore(documentGuid.store);
  const agreeProcess = useStore(documentGuid.agreeProcess);
  const params = useParams();
  const notFound = useStore(documentGuid.notFound);
  const loading = useStore(getDocumentIdFx.pending);

  useEffect(() => {
    getDocumentIdFx(Number(params.id));

    return () => {
      setRef(null);
      documentGuid.reset();
    };
  }, [params.id]);

  useEffect(() => {
    if (params.comments && isComments && ref) {
      setTimeout(() => {
        ref.scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [params.comments, isComments, ref]);

  const { documentEstimatedArticles } = documentId || {};

  if (loading) {
    return (
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        height={'80vh'}
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (notFound) {
    return <DocumentNotFound />;
  }
  if (!documentId) return null;

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h6'
          fontWeight={'bold'}
          textAlign={['center', 'start']}
        >
          {documentId.title}
        </Typography>
      </LayoutPage.Bar>

      <Grid
        container
        justifyContent={'flex-end'}
        sx={{ position: 'sticky' }}
        paddingRight={2}
      >
        <ContextMenu.Provider>
          <ContextMenu.IconButton sx={{ width: 40, height: 40 }} />
          <ListActionsDocument documentId={documentId.id} />
        </ContextMenu.Provider>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        flexDirection={'column'}
        gap={2}
        display={['block', 'flex']}
        paddingX={[0, 2]}
        height={'100%'}
      >
        <TextFieldAdaptive
          title='Текущий статус'
          body={documentId.documentStatus?.title || ''}
        />
        <TextFieldAdaptive
          title='Номер документа'
          body={String(documentId.id)}
        />
        <TextFieldAdaptive
          title='Отправитель'
          body={documentId.creator ? documentId.creator.employeeName || '' : ''}
        />

        <DateFildAdaptive
          title='Дата создания'
          date={documentId.dateCreated}
        />
        <DateFildAdaptive
          title='Дата оплаты'
          date={documentId.datePaid}
        />
        <DateFildAdaptive
          title='Дата завершения'
          date={documentId.dateCompleted}
        />
      </Grid>

      <Grid
        paddingX={[0, 2]}
        paddingBottom={3}
      >
        {/* =================== Информация о документе =================== */}

        <LayoutSection
          title='Информация о документе'
          defaultOpen
        >
          <RowFieldView title='Юр. лицо'>
            {documentId.organization.title}
          </RowFieldView>

          <RowFieldView title='Подразделение'>
            {(documentId.documentArticles &&
              documentId.documentArticles[0]?.retail &&
              documentId.documentArticles?.[0].retail.title) ||
              '-'}
          </RowFieldView>
          <RowFieldView title='Статья ДДС'>
            {documentId.documentArticles?.map((item) => (
              <Typography
                key={item.articleId}
                sx={{ padding: ['16px', '0px'] }}
              >
                {item.articleTitle}
              </Typography>
            ))}
          </RowFieldView>
        </LayoutSection>
        {/* =================== documentArticles =================== */}
        {documentId.documentArticles && (
          <ListArticlesExpenseCardUi
            articles={prepareDocumentArticlesModelToArticlesView(
              documentId.documentArticles
            )}
          />
        )}
        {/* =================== Дополнительная информация о документе =================== */}
        {(Boolean(documentId.beginPeriod && documentId.endPeriod) ||
          Boolean(documentId.intAdditionallyFields?.length) ||
          Boolean(documentId.decimalAdditionallyFields?.length) ||
          Boolean(documentId.stringAdditionallyFields?.length) ||
          Boolean(documentId.dateTimeAdditionallyFields?.length) ||
          Boolean(documentId.periodAdditionallyFields?.length) ||
          Boolean(documentId.orderDate || documentId.orderNumber)) && (
          <LayoutSection
            title={'Дополнительная информация о документе'}
            defaultOpen={true}
          >
            {documentId.orderNumber && (
              <RowFieldView title='Номер приказа'>
                {documentId.orderNumber}
              </RowFieldView>
            )}
            {documentId.orderDate && (
              <RowFieldView title='Дата приказа'>
                {formatingDate(documentId.orderDate, false)}
              </RowFieldView>
            )}
            {documentId.beginPeriod && documentId.endPeriod && (
              <RowFieldView title='Дата начала / Дата окончания'>
                {`${formatingDate(
                  documentId.beginPeriod,
                  false
                )} / ${formatingDate(documentId.endPeriod, false)}`}
              </RowFieldView>
            )}
            {documentId.stringAdditionallyFields &&
              documentId.stringAdditionallyFields.map((item) => (
                <RowFieldView
                  key={item.id}
                  title={item.processAdditionallyField.title || 'not_title'}
                >
                  {item.value}
                </RowFieldView>
              ))}
            {documentId.dateTimeAdditionallyFields &&
              documentId.dateTimeAdditionallyFields.map((item) => (
                <RowFieldView
                  key={item.id}
                  title={item.processAdditionallyField.title || 'not_title'}
                >
                  {formatingDate(item.value)}
                </RowFieldView>
              ))}
            {documentId.periodAdditionallyFields &&
              documentId.periodAdditionallyFields.map((item) => (
                <RowFieldView
                  key={item.id}
                  title={item.processAdditionallyField.title || 'not_title'}
                >
                  {`${formatingDate(item.begValue, false)} / ${formatingDate(
                    item.endValue,
                    false
                  )}`}
                </RowFieldView>
              ))}
            {documentId.decimalAdditionallyFields &&
              documentId.decimalAdditionallyFields.map((item) => (
                <RowFieldView
                  key={item.id}
                  title={item.processAdditionallyField.title || 'not_title'}
                >
                  {item.value}
                </RowFieldView>
              ))}
            {documentId.intAdditionallyFields &&
              documentId.intAdditionallyFields.map((item) => (
                <RowFieldView
                  key={item.id}
                  title={item.processAdditionallyField.title || 'not_title'}
                >
                  {item.value}
                </RowFieldView>
              ))}
          </LayoutSection>
        )}
        {/* =================== Документ-основание =================== */}
        {(documentId.totalSum || files) && (
          <LayoutSection
            title={'Документ-основание'}
            defaultOpen={true}
          >
            {documentId.reasonDocumentNumber && (
              <RowFieldView title='Номер документа'>
                {documentId.reasonDocumentNumber}
              </RowFieldView>
            )}
            {documentId.reasonDocumentDate && (
              <RowFieldView title='Дата документа'>
                {formatingDate(documentId.reasonDocumentDate)}
              </RowFieldView>
            )}
            {documentId.payBeforeDate && (
              <RowFieldView title='Оплатить до'>
                {formatingDate(documentId.payBeforeDate)}
              </RowFieldView>
            )}

            {documentId.totalSum !== undefined && (
              <RowFieldView title='Общая сумма'>
                {transformNumberSummInText(documentId.totalSum)}
              </RowFieldView>
            )}
            {documentId.taxSum !== undefined && (
              <RowFieldView title='НДС'>
                {transformNumberSummInText(documentId.taxSum)}
                {documentId.taxRate ? ` (${documentId.taxRate.title})` : ''}
              </RowFieldView>
            )}

            {files && files.length > 0 && (
              <Grid
                container
                item
                marginBottom={3}
                flexBasis={['100%', '50%']}
                flexDirection={['column', 'row']}
              >
                <Typography sx={{ flexBasis: '40%', ...SX_TITLE }}>
                  Файл
                </Typography>
                <Grid>
                  <>
                    {files.map((file) => (
                      <Typography
                        sx={{
                          ...SX_BODY,
                          maxWidth: [300, 'auto'],
                          wordWrap: 'break-word',
                        }}
                        key={file.id}
                        onClick={() => {
                          idDoc &&
                            documentGuid.downloadFileReasonDocumentId({
                              id: idDoc,
                              fileId: file.id,
                              fileName: file.fileTitle || '',
                            });
                        }}
                      >
                        {file.fileTitle
                          ? normolizeTitleFile(file.fileTitle)
                          : '-'}
                      </Typography>
                    ))}
                  </>
                </Grid>
              </Grid>
            )}
          </LayoutSection>
        )}
        {/* =================== Cвязанные документа =================== */}
        {documentEstimatedArticles && documentEstimatedArticles.length > 0 && (
          <DetailsUi
            textSummary={'Cвязанные документа'}
            defaultOpen={true}
          >
            <TableDocumentEstimatedArticles
              list={documentEstimatedArticles}
              estimateBalance={documentId.estimateBalance}
            />
          </DetailsUi>
        )}
        {/* =================== Контрагент =================== */}
        {documentId.process.isCounterpartyRequired &&
          !documentId.process.isTransferToAccountablePerson && (
            <LayoutSection
              title='Контрагент'
              defaultOpen
            >
              <RowFieldView title='ИНН'>
                {documentId.counterparty
                  ? documentId.counterparty.taxNumber
                  : documentId.counterpartyTaxNumber}
              </RowFieldView>
              {/* ======= */}
              <RowFieldView title='КПП'>
                {documentId.counterparty
                  ? documentId.counterparty.regReasonCode
                  : documentId.counterpartyRegReasonCode}
              </RowFieldView>

              {/* ======= */}
              <RowFieldView title='Контрагент'>
                {documentId.counterparty ? documentId.counterparty?.title : '-'}
              </RowFieldView>

              {/* ========= */}
              <RowFieldView title='№ Договора'>
                {documentId.counterpartyContractNumber}
              </RowFieldView>

              {/* ======== */}
              <RowFieldView title='Ссылка'>
                {documentId.counterpartyContractLink}
              </RowFieldView>
              {/* ==== */}
            </LayoutSection>
          )}
        {/* =================== Закрывающий документ =================== */}
        {isShowDocumentSection(documentId, 'closeDocument') && (
          <LayoutSection
            title={'Закрывающий документ'}
            defaultOpen={true}
          >
            {documentId.closeDocumentSum && (
              <RowFieldView title='Сумма'>
                {transformNumberSummInText(documentId.closeDocumentSum)}
              </RowFieldView>
            )}

            {documentId.closeDocumentTaxSum !== undefined && (
              <RowFieldView title='НДС'>
                {transformNumberSummInText(documentId.closeDocumentTaxSum)}
                {documentId.closeDocumentTaxRate
                  ? ` (${documentId.closeDocumentTaxRate.title})`
                  : ''}
              </RowFieldView>
            )}
            {documentId.closeDocumentDate && (
              <RowFieldView title='Дата'>
                {dayjs(documentId.closeDocumentDate).format(
                  DATE_FORMATS.client
                )}
              </RowFieldView>
            )}
            {documentId.closeDocumentNumber && (
              <RowFieldView title='Номер докуемнта'>
                {documentId.closeDocumentNumber}
              </RowFieldView>
            )}

            {documentId.isCloseDocumentDiadok &&
              documentId.closeDocumentDiadokLink && (
                <RowFieldView title='Закрывающий документ в ЭДО'>
                  {documentId.closeDocumentDiadokLink}
                </RowFieldView>
              )}

            {!documentId.isCloseDocumentDiadok &&
              documentId.closeDocumentFiles &&
              documentId.closeDocumentFiles.length > 0 && (
                <Grid sx={{ flexBasis: ['100%', '50%'] }}>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: ['column', 'row'],
                    }}
                  >
                    <Typography
                      sx={{
                        ...SX_TITLE,
                        flexBasis: '40%',
                        display: 'flex',
                      }}
                    >
                      Файл
                    </Typography>
                    <Grid>
                      {documentId.closeDocumentFiles.map((file) => {
                        return (
                          <Typography
                            sx={{
                              ...SX_BODY,
                              maxWidth: [300, 'auto'],
                              wordWrap: 'break-word',
                            }}
                            key={file.id}
                            onClick={() => {
                              idDoc &&
                                documentGuid.downloadFileCloseDocumentId({
                                  id: idDoc,
                                  fileId: file.id,
                                  fileName: file.fileTitle || '',
                                });
                            }}
                          >
                            {file.fileTitle
                              ? normolizeTitleFile(file.fileTitle)
                              : '-'}
                          </Typography>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </LayoutSection>
        )}
        {/* =================== ???????????????????? =================== */}
        <UiDocumentIdProcessAgreeChain agreeProcess={agreeProcess} />
        <DocumentIdComments />
        <div
          style={{ marginTop: '100px' }}
          ref={setRef}
          id='comments'
        ></div>
        <DocumentActionManagerAgreeRequestOrderresetModal />

        <DocumentIdActionsBottom documentId={documentId.id} />
        <DocumentActionsReturnDocumentCommentModal />
      </Grid>
    </LayoutPage.Page>
  );
};

const isShowDocumentSection = (
  document: DocumentModel,
  sectionName: 'closeDocument' | 'counterparty'
): boolean => {
  switch (sectionName) {
    case 'closeDocument':
      return Boolean(
        (document.closeDocumentFiles &&
          document.closeDocumentFiles.length > 0) ||
          (document.closeDocumentDiadokLink &&
            document.closeDocumentDiadokLink.length > 0) ||
          document.closeDocumentDate ||
          document.closeDocumentNumber ||
          document.closeDocumentSum ||
          document.closeDocumentTaxRate ||
          document.closeDocumentTaxSum
      );

    default:
      return true;
  }
};

const TableDocumentEstimatedArticles: FC<{
  list: DocumentEstimatedArticleModel[];
  estimateBalance?: number | string;
}> = ({ list, estimateBalance }) => {
  const [allFields, setFields, visibleFields] = useTableCellsFromLocalStorage(
    Tables.LINKS_DOCUMENT
  );

  return (
    <>
      <CustomTitleSectionDetailsUi title='Cвязанные документа' />
      <Grid
        container
        item
        marginBottom={3}
        width={['100%', '50%']}
        flexBasis={['100%', '50%']}
        flexDirection={['column', 'row']}
      >
        <Typography sx={{ flexBasis: '35%', ...SX_TITLE }}>
          Остаток по документу
        </Typography>
        <Typography sx={{ ...SX_BODY }}>
          {transformNumberSummInText(Number(estimateBalance))}
        </Typography>
      </Grid>
      <Grid display={['none', 'flex']}>
        <UiTable
          header={
            <UiTableHeader
              viewCells={visibleFields}
              setViewCells={setFields}
              allCells={allFields}
            />
          }
          body={list.map((document) => (
            <TableRow key={document.documentId}>
              {visibleFields
                .filter((el) => el.isShow)
                .map((el) => (
                  <UiTableCell
                    key={el.field + document.documentId}
                    {...el.getTableCellProps()}
                  >
                    {el.renderComponent(document) || 'Error'}
                  </UiTableCell>
                ))}
            </TableRow>
          ))}
        />
      </Grid>

      <Stack
        gap={2}
        display={['flex', 'none']}
      >
        {list.map((item) => (
          <Paper
            sx={{ padding: 2 }}
            key={item.documentId}
          >
            {allFields.map((field) => {
              return (
                <Fragment key={field.title}>
                  <Typography fontWeight={'bold'}>{field.title}</Typography>
                  {field.renderComponent(item)}
                </Fragment>
              );
            })}
          </Paper>
        ))}
      </Stack>
    </>
  );
};

export const DOCUMENT_ESTIMATED_ARTICLES: FieldMapType<DocumentEstimatedArticleModel>[] =
  [
    {
      field: 'documentTitle',
      title: 'Наименование документа',
      order: 0,
      isShow: true,
      renderComponent: (value) => (
        <DocumentTitleUi
          id={value.documentId}
          title={value.documentTitle}
        ></DocumentTitleUi>
      ),
      getTableCellProps: () => ({ padding: 'none' }),
    },
    {
      field: 'documentArticleTitle',
      title: 'Статья',
      order: 0,
      isShow: true,
      renderComponent: (value) => (
        <Typography>{value.documentArticleTitle}</Typography>
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'documentStatusTitle',
      title: 'Статус',
      order: 0,
      isShow: true,
      renderComponent: (value) => (
        <Typography>{value.documentStatusTitle}</Typography>
      ),
      getTableCellProps: () => ({}),
    },
    {
      field: 'documentCreator',
      title: 'Инициатор',
      order: 0,
      isShow: true,
      renderComponent: (value) => (
        <Typography>{value.documentCreator}</Typography>
      ),
      getTableCellProps: () => ({}),
    },
  ];
