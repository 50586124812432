// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { AxiosError } from 'axios';
import { createEvent, createStore, sample } from 'effector';

import {
  DocumentCommentResponse,
  ErrorResponse,
} from 'shared/api/services-document-flow/types';

import { documentGuid } from 'entities/document/model/document-id';
import { addedError } from 'entities/error/error-alert/error-alert-effector';

import {
  deleteDocumentsDocumentIdDocumentCommentsCommentIdFx,
  getDocumentsDocumentIdDocumentCommentsFx,
  postDocumentsDocumentIdDocumentCommentsFx,
} from './api';

const $commentsDocumentId = createStore<DocumentCommentResponse[]>([]).on(
  getDocumentsDocumentIdDocumentCommentsFx.doneData,
  (state, response) => response.data
);
const reset = createEvent();

$commentsDocumentId.reset(reset);
sample({
  clock: documentGuid.store,
  filter: (documentId) => Boolean(documentId),
  fn: (documentId) => documentId!.id,
  target: getDocumentsDocumentIdDocumentCommentsFx,
});

const changeCommentReply = createEvent<null | DocumentCommentResponse>();
const $commentReply = createStore<DocumentCommentResponse | null>(null)
  .on(changeCommentReply, (_, comment) => comment)
  .reset([postDocumentsDocumentIdDocumentCommentsFx.done, reset]);
const changeInput = createEvent<string>();
const $commentInput = createStore('')
  .on(changeInput, (_, message) => message)
  .reset([postDocumentsDocumentIdDocumentCommentsFx.done, reset]);
/* delete comment */

const deleteComment = createEvent<number>();

sample({
  clock: deleteComment,
  source: documentGuid.store,
  filter: (document) => Boolean(document),
  fn: (document, idComment) => {
    return { documentId: document!.id, commentId: idComment };
  },
  target: deleteDocumentsDocumentIdDocumentCommentsCommentIdFx,
});
sample({
  clock: deleteDocumentsDocumentIdDocumentCommentsCommentIdFx.fail,
  fn: (data) => {
    const error = data.error;

    return error as AxiosError<ErrorResponse>;
  },
  target: addedError,
});

/* ---------------- */
const sendComment = createEvent<any>();

sample({
  clock: sendComment,
  source: {
    document: documentGuid.store,
    comment: $commentInput,
    commentReply: $commentReply,
  },
  filter: ({ document }) => Boolean(document),
  fn: ({ document, comment, commentReply }) => {
    return {
      documentId: document!.id as number,
      comment: {
        parentId: commentReply ? commentReply.id : undefined,
        comment,
      },
    };
  },
  target: postDocumentsDocumentIdDocumentCommentsFx,
});

sample({
  clock: [
    postDocumentsDocumentIdDocumentCommentsFx.doneData,
    deleteDocumentsDocumentIdDocumentCommentsCommentIdFx.done,
  ],
  source: documentGuid.store,
  filter: (document) => Boolean(document),
  fn: (document) => document!.id,
  target: getDocumentsDocumentIdDocumentCommentsFx,
});
sample({
  clock: [
    getDocumentsDocumentIdDocumentCommentsFx.failData,
    deleteDocumentsDocumentIdDocumentCommentsCommentIdFx.failData,
    postDocumentsDocumentIdDocumentCommentsFx.failData,
  ],
  filter: (clock) => Boolean(clock),
  fn: (clock) => clock,
  target: addedError,
});

export const documentIdComment = {
  comments: $commentsDocumentId,
  deleteComment,
  input: $commentInput,
  sendComment,
  changeInput,
  commentReply: $commentReply,
  changeCommentReply,
  reset,
};
