import dayjs from 'dayjs';

import { VisitingPlanningCreateRequest } from 'shared/api/visit/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';

import { CreatePlanningRNFormFields } from '../type';

export const convertCreatePlanningFormDataToFetch = (
  form: CreatePlanningRNFormFields
): VisitingPlanningCreateRequest => {
  const startPeriod = dayjs(form.month).set('D', 1);
  const finishPerion = dayjs(form.month).set('D', form.month.daysInMonth());

  return {
    departmentId: form.departmentId,
    visitorId: form.visitorId,
    homiesIds: form.homiesIds,
    periodStart: startPeriod.format(DATE_FORMATS.server),
    periodFinish: finishPerion.format(DATE_FORMATS.server),
  };
};
