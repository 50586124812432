import { Grid } from '@mui/material';
import { useStoreMap } from 'effector-react';

import { documentFormModel } from 'entities/document-form';

import { ExpensesFormListExpense } from 'widgets/expenses-form-expense/ExpensesFormListExpense';

import { ArticleItem } from './ExpensesFormArticle';

export const ExpensesFormListArticles = () => {
  const responseArticles = useStoreMap({
    store: documentFormModel.firstValid,
    keys: [documentFormModel.firstValid],
    fn: (store) => store?.documentArticles,
  });
  const documentArticles = useStoreMap({
    store: documentFormModel.form,
    keys: [documentFormModel.form, responseArticles],
    fn: (state) => {
      const articlesCanExpenses: number[] = [];
      const articlesNotCanExpenses: number[] = [];

      if (!responseArticles || !state || !state.articles) {
        return null;
      }
      state.articles.forEach((art) => {
        if (responseArticles.find((item) => item.id === art.id)?.hasExpenses) {
          articlesCanExpenses.push(art.id);
        } else {
          articlesNotCanExpenses.push(art.id);
        }
      });

      return [...articlesCanExpenses, ...articlesNotCanExpenses];
    },
    updateFilter: (update, current) => {
      if (!current) return true;
      if (!update) return true;
      if (current.length === update.length) return false;

      return true;
    },
  });

  return (
    <Grid>
      {documentArticles?.map((id) => (
        <ArticleItem
          id={id}
          key={id}
          ListExpenses={ExpensesFormListExpense}
        />
      ))}
    </Grid>
  );
};
