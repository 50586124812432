import { AuthServiceResponsesOnlyPermission } from 'shared/api/services-auth/types';

import { Routes } from '../constants';
import { getPermissionStatus } from './getPermissionStatus';

export const getRoutesWithAccess = (
  permissions: AuthServiceResponsesOnlyPermission[],
  routes: Routes[]
): Routes[] => {
  return routes
    .filter((route) => {
      if (!route.premission || route.premission === undefined) return true;

      const permission = permissions.find(
        (premission) =>
          premission.moduleGuid === route.premission?.permissionGuid
      );

      if (!permission) return true;

      return getPermissionStatus(route, permission);
    })
    .map((route) => {
      if (route.children === null) return route;

      return {
        ...route,
        children: getRoutesWithAccess(permissions, route.children),
      };
    });
};
