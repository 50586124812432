import { FC } from 'react';
import {
  UiTableCard,
  UiTableCardList,
  UiTableCardPagination,
} from 'shared/ui/table';
import { AdaptationProcessTableType } from './type';

export const CurrentAdaptationProcessesList: FC<AdaptationProcessTableType> = ({
  fields,
  onChangeFields,
  visibleFields,
  isLoading = false,
  count,
  data,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  renderActions,
  onClick,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading}
      pagination={
        <UiTableCardPagination
          count={count}
          onPageChange={onChangePage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowsPerPageChange={onChangeLimit}
        />
      }
    >
      {data.map((process) => (
        <UiTableCard
          data={process}
          id={String(process.employeeGuid)}
          visibleFields={visibleFields}
          key={process.employeeGuid}
          onClick={() => {
            onClick && onClick(process);
          }}
          renderActions={(isOpen, setIsLoading) => {
            return renderActions(process, { isOpen, setIsLoading });
          }}
        />
      ))}
    </UiTableCardList>
  );
};
