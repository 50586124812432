import { ALL_ROUTES, Routes } from './types';

export const BOARD: Routes[] = [
  {
    title: 'Мои доски',
    path: ALL_ROUTES.myBoards,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
];
