import { TextField } from '@mui/material';
import { useStore } from 'effector-react';
import { FC } from 'react';

import { CounterpartyModel } from 'shared/api/services-document-flow/types';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormEdit } from '../model';
import { $counterpartyOptions } from './models';

export const DocumentFormEditCounterpartyTitle: FC = () => {
  const [isMobile] = useDevice();
  const options = useStore($counterpartyOptions);
  const value =
    documentFormEdit.formSelects.useSelectValueOfKey('counterparty');
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditCounterparty'
  );

  const onChangeCounterParty = (
    event: React.SyntheticEvent<Element, Event>,
    value: CounterpartyModel | string | null
  ) => {
    if (typeof value !== 'string') {
      documentFormEdit.api.setFiled({
        key: 'counterparty',
        value: value ? value : null,
      });
    }
  };

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        onChange: onChangeCounterParty,
        disabled: !isCanEdit,
        value: value,
        options: options,
        renderInput: (params) => (
          <TextField
            {...params}
            disabled={!isCanEdit}
            size='small'
            label={isMobile ? '' : 'Контрагент'}
            placeholder='Введите контрагента'
            margin={'none'}
          />
        ),
        getOptionLabel: (option) => option.title || '',
        renderOption: (props, option) => (
          <li
            {...props}
            key={option.id}
          >
            {option.title}
          </li>
        ),
        isOptionEqualToValue: (option, value) => option.id === value.id,
      }}
      sx={{ flexBasis: ['100%', '100%'] }}
      label='Контрагент'
    />
  );
};
