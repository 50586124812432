import { Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { downloadFileFromUrlString } from 'shared/utils/downloadFileFromUrlString';
import { useCreateDownloadFileHandlerParams } from './types';

export const useCreateDownloadFileHandler = ({
  downloadFn,
  createFileName,
}: useCreateDownloadFileHandlerParams) => {
  const { open: openSnackbar } = useSnackbar();

  const handleDownloadFiles = useCallback(async () => {
    const response = await downloadFn();

    if ('data' in response && response.data) {
      const { url, name } = downloadFileFromUrlString(createFileName)(
        response.data
      );

      openSnackbar({
        type: 'success',
        title: 'Файл готов!',
        text: (
          <Grid>
            <Typography>Если файл не скачался автоматически: </Typography>
            <Typography
              sx={{ textDecorationLine: 'underline' }}
              component={'a'}
              download={name}
              href={url}
            >
              ссылка для скачивания
            </Typography>
          </Grid>
        ),
        duration: 20000,
      });
    } else if ('error' in response) {
      openSnackbar({
        type: 'error',
        title: getMessageFromFetchBaseQueryError(
          response.error || {
            status: 999,
            data: 'handleDownloadFiles - error',
          }
        ),
        duration: 3000,
      });
    }
  }, [downloadFn, openSnackbar]);

  return { handleDownloadFiles };
};
