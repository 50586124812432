import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { UiDelButton } from './UiDelButton';

interface HeadBlockUiProps {
  onClick?: () => void;
  disable?: boolean;
  title?: string;
}
export const UiHeadBlock: FC<HeadBlockUiProps> = ({
  onClick,
  disable,
  title,
}) => {
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Typography
        color={theme.palette.customGrey.dark}
        fontWeight={700}
      >
        {title}
      </Typography>
      <UiDelButton
        onClick={onClick}
        disabled={disable}
      >
        Удалить
      </UiDelButton>
    </Grid>
  );
};
