import { FC, Fragment } from 'react';

import { Grid, LinearProgress } from '@mui/material';
import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';
import { SliderList } from '../slider-list/SliderList';
import { SliderListSkeleton } from '../slider-list/SliderListSkeleton';
import { SliderListOnMonthsProps } from './types';

export const SliderListOnMonths: FC<SliderListOnMonthsProps> = ({
  data,
  isLoading,
  isUpdate,
}) => {
  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={3}
    >
      <Grid height={'4px'}>{isUpdate && <LinearProgress />}</Grid>
      {isLoading && (
        <>
          <DocumentSectionTitle
            text={'Загрузка...'}
            sx={{ textAlign: 'start', padding: '8px 16px' }}
          />
          <SliderListSkeleton />
        </>
      )}
      {data?.map(({ sliders, viewDate }) => {
        return (
          <Fragment key={viewDate}>
            <DocumentSectionTitle
              text={viewDate}
              sx={{ textAlign: 'start', padding: '8px 16px' }}
            />
            <SliderList items={sliders} />
          </Fragment>
        );
      })}
    </Grid>
  );
};
