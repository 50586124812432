import { createEffect, createStore, sample } from 'effector';

import {
  getOrganizations,
  getOrganizationsAllowed,
} from 'shared/api/services-document-flow/services';
import { OrganizationModel } from 'shared/api/services-document-flow/types';

export const fetchOrganizationsFx = createEffect({
  name: 'fetchOrganizationsFx',
  async handler(params: {
    isOrganizationOnlyForMyOffice: boolean;
    processId?: number;
  }) {
    if (params.isOrganizationOnlyForMyOffice && params.processId) {
      return getOrganizationsAllowed({ processId: params.processId });
    }

    return getOrganizations();
  },
});
export const $options = createStore<OrganizationModel[]>([], {
  name: '$organizationsOptions ',
});

sample({
  clock: fetchOrganizationsFx.doneData,
  fn: (clock) => clock.data,
  target: $options,
});
