import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import {
  getOptionLabelThemeTrainingFn,
  getOptionLabelTrainingFn,
  isOptionEqualToValueThemeTrainingFn,
  isOptionEqualToValueTrainingFn,
  renderOptionLabelDateTrainingFn,
  renderOptionLabelThemeTrainingFn,
  renderOptionLabelTrainingFn,
} from './types';

/* THEME */
export const getOptionLabelThemeTraining: getOptionLabelThemeTrainingFn = (
  option
) => {
  return String(option.title);
};

export const renderOptionThemeTraining: renderOptionLabelThemeTrainingFn = (
  props,
  option
) => {
  return <Typography {...props}>{option.title}</Typography>;
};
export const isOptionEqualToValueThemeTraining: isOptionEqualToValueThemeTrainingFn =
  (option, value) => option.trainingGuid === value.trainingGuid;

/* DATE */
export const getOptionLabelDateTraining: (option: string) => string = (
  date
) => {
  return dayjs(date).format(DATE_FORMATS.client);
};

export const renderOptionDateTraining: renderOptionLabelDateTrainingFn = (
  props,
  date
) => {
  return (
    <Typography {...props}>
      {dayjs(date).format(DATE_FORMATS.client)}
    </Typography>
  );
};

/* TRAINING */

export const renderOptionTraining: renderOptionLabelTrainingFn = (
  props,
  option
) => {
  return (
    <Typography
      {...props}
      key={option.guid}
    >
      {option.trainerName}
    </Typography>
  );
};

export const isOptionEqualToValueTraining: isOptionEqualToValueTrainingFn = (
  option,
  value
) => option.guid === value.guid;

export const getOptionLabelTraining: getOptionLabelTrainingFn = (option) => {
  return String(option.trainerName);
};
