import { CustomTextField } from 'shared/ui/custom-text-field';

import { documentFormEdit } from '../model';

export const DocumentFormEditCounterpartyContractNumber = () => {
  const counterpartyContractNumberValue =
    documentFormEdit.formSelects.useSelectValueOfKey(
      'counterpartyContractNumber'
    );
  const isCounterpartyRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isCounterpartyRequired'
    );
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditCounterparty'
  );

  return (
    <CustomTextField
      propsTextField={{
        disabled: !isCounterpartyRequired || !isCanEdit,
        placeholder: 'Введите № договора',
        onChange: (event) => {
          documentFormEdit.api.setFiled({
            key: 'counterpartyContractNumber',
            value: event.target.value,
          });
        },
        value: counterpartyContractNumberValue || '',
      }}
      sx={{
        flexBasis: ['100%', '45%'],
      }}
      label='№ договора'
    />
  );
};
