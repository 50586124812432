import {
  retailsSurfaceSortOptions,
  retailSurfaceGetOptionLabel,
  retailSurfaceGroupBy,
  retailSurfaceIsOptionEqualToValue,
  retailSurfaceRenderOption,
} from 'entities/marketing-surface/inputs';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { SurfaceRetail } from 'shared/api/marketing-surface/marketingSurfaceAutogenerateApi';
import {
  useGetMarketingSurfaceListForRetailQuery,
  useLazyGetMarketingSurfaceAllowedRetailsQuery,
} from 'shared/api/marketing-surface/rtk/marketingSurfaceProviderApi';
import { CustomAutocomplete } from 'shared/ui/base-ui';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

import {
  MarketingSurfaceAdaptiveTable,
  useTableMarketingSurfaceFromLocalStorage,
} from 'widgets/marketing-surface/marketing-surface-table';
import { SwipeableDrawerImagesListUiProvider } from 'widgets/marketing-surface/marketing-surface-table/actions/view-list-images/';
import { EMPT_ARR } from 'widgets/training/filter/constants';

export const MarketingSurfacePage = () => {
  const [selectRetail, setSelectRetail] = useState<SurfaceRetail | null>(null);
  const onSelectRetail = useCallback(
    (_: unknown, value: SurfaceRetail | null) => {
      setSelectRetail(value);
    },
    []
  );
  const [
    fetchAllowedRetails,
    { data: allowedRetails, isLoading: isLoadingAllowedRetails },
  ] = useLazyGetMarketingSurfaceAllowedRetailsQuery();

  useEffect(() => {
    fetchAllowedRetails().then((response) => {
      if (response.data && response.data.length === 1) {
        setSelectRetail(response.data[0]);
      }
    });
  }, []);

  const {
    data: marketingSurface,
    isLoading: isLoadingMarketingSurface,
    isFetching: isUpdatingMarketingSurface,
  } = useGetMarketingSurfaceListForRetailQuery(
    { queryParams: { retailsGuid: selectRetail?.guid as string } },
    { skip: !selectRetail || !selectRetail.guid }
  );

  const sortedAllowedRetails = useMemo(() => {
    if (!allowedRetails) return EMPT_ARR;

    return [...allowedRetails].sort(retailsSurfaceSortOptions);
  }, [allowedRetails]);

  const [allFields, onChangeFields, visibleFields] =
    useTableMarketingSurfaceFromLocalStorage();

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <CustomAutocomplete
          options={sortedAllowedRetails}
          loading={isLoadingAllowedRetails}
          disabled={isLoadingAllowedRetails}
          value={selectRetail}
          onChange={onSelectRetail}
          groupBy={retailSurfaceGroupBy}
          renderOption={retailSurfaceRenderOption}
          getOptionLabel={retailSurfaceGetOptionLabel}
          isOptionEqualToValue={retailSurfaceIsOptionEqualToValue}
          textFieldProps={{
            label: 'Доступные аптеки',
            placeholder: 'Выберите нужную аптеку',
          }}
        />
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <SwipeableDrawerImagesListUiProvider>
          <MarketingSurfaceAdaptiveTable
            data={marketingSurface || EMPT_ARR}
            isLoading={isLoadingMarketingSurface}
            isUpdate={isUpdatingMarketingSurface}
            onChangeFields={onChangeFields}
            visibleFields={visibleFields}
            fields={allFields}
          />
        </SwipeableDrawerImagesListUiProvider>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
