import dayjs from 'dayjs';

export const isLateDateInfo = (date: string) => {
  const dateFinishDayjs = dayjs(date).startOf('D');
  const todayDayjs = dayjs().startOf('D');

  const diffDays = todayDayjs.diff(dateFinishDayjs, 'day');

  const isRed = diffDays > 0;
  const isPink = diffDays >= -2 && diffDays <= 0;

  return {
    isRed,
    isPink,
  };
};
