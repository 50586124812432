import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { AdaptationTemplateTaskForm } from '../AdaptationTemplateTaskForm';
import { EditAdaptationTemplateTaskDialogProps } from './type';

export const EditAdaptationTemplateTaskDialog: FC<
  EditAdaptationTemplateTaskDialogProps
> = ({ isOpen, onClose, onSubmit, isLoading, initValue }) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Редактирование шаблона задачи' />}
    >
      <AdaptationTemplateTaskForm
        onSubmit={onSubmit}
        disabled={isLoading}
        initValue={initValue}
        renderFormActions={() => [
          <LoadingButton
            type='submit'
            key={1}
            variant='contained'
            loading={isLoading}
          >
            Редактировать
          </LoadingButton>,
          <Button
            onClick={onClose}
            key={2}
            variant='contained'
            color='customGrey'
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
