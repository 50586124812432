import { FC } from 'react';
import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTablePagination,
  UiTableRowWithActions,
} from 'shared/ui/table';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';
import { AdaptationTemplatesTableProps } from './type';

export const AdaptationTemplatesTable: FC<AdaptationTemplatesTableProps> = ({
  data: templates,
  isLoading = false,
  isUpdate = false,
  onClick,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  count,
  renderActions,
  fields,
  onChangeFields,
  visibleFields,
}) => {
  return (
    <UiTable
      isLoading={isLoading && !templates.length}
      isUpdating={isUpdate}
      skeleton={<UiTableSkeleton limit={limit} />}
      header={
        <UiTableHeader
          allCells={fields}
          viewCells={visibleFields}
          setViewCells={onChangeFields}
        />
      }
      body={
        <>
          {templates &&
            templates.length !== 0 &&
            templates.map((template) => (
              <UiTableRowWithActions
                key={template.guid}
                hover
                onClick={() => {
                  onClick?.(template);
                }}
                renderActions={(isOpen, setIsLoading) => {
                  return renderActions(template, { isOpen, setIsLoading });
                }}
                sx={{ cursor: 'pointer' }}
              >
                {visibleFields.map((el) => (
                  <UiTableCell
                    key={el.field + template.guid}
                    {...el.getTableCellProps()}
                  >
                    {el.renderComponent(template) || 'Error'}
                  </UiTableCell>
                ))}
              </UiTableRowWithActions>
            ))}
        </>
      }
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage + 1);
          }}
          page={page === 0 ? 0 : page - 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};
