import { Fab, SpeedDialIcon, Typography } from '@mui/material';
import { DialogFormTemplateTask } from 'features/retail-opeting-process/form-template-task';
import { useDialogCreateTemplateTask } from 'features/retail-opeting-process/form-template-task/libs';
import { FC } from 'react';
import { ActionCreateTemplateTaskWithSpeedDialProps } from './types';

export const ActionCreateTemplateTaskWithSpeedDial: FC<
  ActionCreateTemplateTaskWithSpeedDialProps
> = ({ templateGuid, stageGuid, countdownFieldTitle }) => {
  const {
    isLoadingSumbiteTask,
    isOpenDialogFormTemplateTask,
    onCloseDialogFormTemplateTask,
    onOpenDialogFormTemplateTask,
    onSubmitFormCreateTemplateTask,
  } = useDialogCreateTemplateTask(templateGuid, stageGuid);

  return (
    <>
      <Fab
        variant='extended'
        color='primary'
        sx={{
          position: 'fixed',
          bottom: 32,
          right: 16,
          height: 56,
          borderRadius: 28,
          '& > p': {
            opacity: 0,
            width: 0,
            overflow: 'hidden',
          },
          '&:hover > p': {
            opacity: 1,
            width: { xs: 0, lg: '100%' },
            overflow: 'none',
            mr: { xs: 0, lg: 1 },
          },
        }}
        onClick={onOpenDialogFormTemplateTask}
      >
        <Typography textTransform={'none'}>Добавить шаблон задачи</Typography>
        <SpeedDialIcon />
      </Fab>

      <DialogFormTemplateTask
        isOpen={isOpenDialogFormTemplateTask}
        onClose={onCloseDialogFormTemplateTask}
        isLoadingSubmit={isLoadingSumbiteTask}
        onSubmit={onSubmitFormCreateTemplateTask}
        countdownFieldTitle={countdownFieldTitle}
      />
    </>
  );
};
