// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { useStageDraftConfirm } from 'features/visit/use-stage-draft-confirm/useStageDraftConfirm';
import { FC } from 'react';
import {
  Control,
  Controller,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';

import { VisitingChecklistCompetitorGetResponse } from 'shared/api/visit/types';
import { theme } from 'shared/theme/theme';
import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';

import { ConfirmStageDialog } from '../../ConfirmDialog/ConfirmStageDialog';
import { NavButtons } from '../../NavButtons';
import { CompetitiveAdvantagesFormSchema } from '../schems';
import {
  CompetitiveAdvantagesFormI,
  CompetitiveAdvantagesFormProps,
} from '../type';
import { InfoBlock } from './InfoBlock/InfoBlock';
import { RatingBlock } from './RatingBlock/RatingBlock';

export const CompetitiveAdvantagesForm: FC<CompetitiveAdvantagesFormProps> = ({
  onSubmit,
  initValue,
  cometitors,
  disableAll,
  onDraftSubmit,
}) => {
  /* Form */
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting },
    getValues,
  } = useForm<CompetitiveAdvantagesFormI>({
    resolver: yupResolver(CompetitiveAdvantagesFormSchema),
    values: initValue,
  });

  const { fields } = useFieldArray({
    name: 'blocks',
    control,
  });
  /* ==== */

  /* Draft */
  const {
    handleClose,
    handleConfirm,
    handleNext,
    onSubmit: onSub,
    isLoading,
    isDialogOpen,
  } = useStageDraftConfirm(isDirty, onSubmit, onDraftSubmit);
  /* ==== */

  return (
    <Grid
      container
      component={'form'}
      onSubmit={handleSubmit(onSub)}
      gap={3}
    >
      <ConfirmStageDialog
        handleClose={handleClose}
        handleConfirm={() => {
          handleConfirm(getValues());
        }}
        handleNext={handleNext}
        isLoading={isLoading}
        isOpen={isDialogOpen}
      />

      <DocumentSectionTitle
        text='Оценка конкурентных преимуществ'
        sx={{ marginTop: 0, margin: 0 }}
      />

      <Grid
        container
        gap={3}
      >
        {fields.map((el, index) => (
          <Grid
            key={el.blockId}
            container
            gap={3}
            px={[1, 0]}
          >
            <Typography
              color={theme.palette.customGreen.dark}
              fontWeight={700}
            >
              {el.blockTitle}
            </Typography>

            {el.blockItems.map((el2, index2) => (
              <Grid
                key={el2.itemId}
                container
                gap={1}
              >
                <Controller
                  name={`blocks.${index}.blockItems.${index2}`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid },
                  }) => (
                    <RatingBlock
                      canAddComent={el2.canAddComent}
                      disabled={disableAll}
                      invalid={invalid}
                      value={value}
                      onChange={onChange}
                      title={el2.itemTitle || ''}
                      cometitors={cometitors || []}
                    />
                  )}
                />
              </Grid>
            ))}
          </Grid>
        ))}

        <AvgBlock
          cometitors={cometitors}
          control={control}
        />
      </Grid>
      <NavButtons isLoading={isSubmitting} />
    </Grid>
  );
};

interface AvgBlockProps {
  control: Control<CompetitiveAdvantagesFormI, any>;
  cometitors: VisitingChecklistCompetitorGetResponse[];
}

const AvgBlock: FC<AvgBlockProps> = ({ control, cometitors }) => {
  const blocks = useWatch({ control, name: 'blocks' });

  return (
    <Grid
      container
      flexDirection={'column'}
      gap={3}
      px={[1, 0]}
    >
      <Typography
        color={theme.palette.customGreen.dark}
        fontWeight={700}
      >
        Итого
      </Typography>
      <InfoBlock
        data={{ blocks: blocks }}
        competitors={cometitors || []}
      />
    </Grid>
  );
};
