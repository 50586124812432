import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { AdeptLayoutForUiComponentProps } from './type';

export const AdeptLayoutForUiComponent: FC<AdeptLayoutForUiComponentProps> = ({
  children,
  containerSx,
  label,
}) => {
  return (
    <Grid sx={{ flexBasis: '100%', alignItems: 'center', ...containerSx }}>
      <Typography
        sx={{
          display: 'none',
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            backgroundColor: theme.palette.customGrey.main,
            position: 'relative',
            padding: '0px 8px',
            flexBasis: ['100%', '45%'],
          },
        }}
      >
        {label}
      </Typography>

      {children}
    </Grid>
  );
};
