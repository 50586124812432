import { PopperProps } from '@mui/material';
import { useMemo } from 'react';

import { useModifiersPopperParams } from './types';

export const useModifiersPopper = ({
  arrowAnchorEl,
  squareWidth,
  percentOfsquareWidth,
  dataAtrrForHidenArrow,
}: useModifiersPopperParams) => {
  const modifiers: PopperProps['modifiers'] = useMemo(() => {
    return [
      {
        name: 'arrow',
        enabled: true,
        options: {
          element: arrowAnchorEl,
          padding: squareWidth / 2,
        },
      },

      {
        name: 'preventOverflow',
        options: {
          padding: squareWidth * percentOfsquareWidth,
        },
      },
      {
        name: 'applyArrowHide',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          const { arrow } = state.elements;

          if (arrow) {
            if (state.modifiersData?.arrow?.centerOffset !== 0) {
              arrow.setAttribute(dataAtrrForHidenArrow, '');
            } else {
              arrow.removeAttribute(dataAtrrForHidenArrow);
            }
          }
        },
      },
    ];
  }, [arrowAnchorEl, squareWidth, percentOfsquareWidth]);

  return modifiers;
};
