// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { GetDocumentsObserveQueryParams } from 'shared/api/services-document-flow/types';

export const defaultFilter: GetDocumentsObserveQueryParams = {
  PageSize: 20,
  DepartmentIds: undefined,
  EmployeeIds: undefined,
  OrderBy: undefined,
  OrgDepartmentIds: undefined,
  OrgDirectionIds: undefined,
  OrgOfficeIds: undefined,
  OrgPositionIds: undefined,
  OrganizationIdFilters: undefined,
  /**
   *
   * - Format: int32
   */
  RegionIds: undefined,
  CurrentPage: 1,
};
