import { Grid, Paper, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { NavigationCardPropsBaseUi } from './types';

export const NavigationCardBaseUi: FC<
  PropsWithChildren<NavigationCardPropsBaseUi>
> = ({ Icon, children, sx }) => {
  const theme = useTheme();

  return (
    <Grid
      component={Paper}
      gap={[2, 3]}
      display={'flex'}
      padding={[2, 3]}
      alignItems={'center'}
      color={'inherit'}
      sx={sx}
    >
      <Grid
        sx={{
          backgroundColor: theme.palette.customGreen.main,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          minWidth: 76,
          minHeight: 76,
        }}
      >
        {Icon}
      </Grid>
      {children}
    </Grid>
  );
};
