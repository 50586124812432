import { Grid } from '@mui/material';
import { Authorization } from 'pages/authorization/Authorization';
import { Main } from 'pages/main/Main';
import { createBrowserRouter } from 'react-router-dom';

import { MAX_WIDTH } from 'shared/constant/size';
import { ALL_ROUTES } from 'shared/routes/constants/types';

import { MainLayoutApp } from 'widgets/main-layout-app';

import { ROUTES_APTEKA_LEGKO } from './apteka-legko';
import { ROUTES_ADAPTATION } from './adaptation';
import { BOARD } from './board';
import { ROUTES_DOCUMENTS_FLOW } from './document';
import { ROUTES_MARKETING_SURFACE } from './marketing-surface';
import { ROUTES_RETAIL_OPENING_PROCESS } from './retail-opening-process';
import { ROUTES_TASKS } from './task';
import { ROUTES_TRAINING } from './training';
import { ROUTES_USER } from './user';
import { ROUTES_VIST } from './visit';

const ROUTES = [
  {
    path: '/',
    element: <MainLayoutApp />,
    children: [
      {
        path: '/',
        element: (
          <Grid
            maxWidth={MAX_WIDTH}
            margin={'0 auto'}
          >
            <Main />
          </Grid>
        ),
      },
      ...ROUTES_TASKS,
      ...ROUTES_DOCUMENTS_FLOW,
      ...ROUTES_VIST,
      ...ROUTES_USER,
      ...ROUTES_TRAINING,
      ...ROUTES_RETAIL_OPENING_PROCESS,
      ...ROUTES_MARKETING_SURFACE,
      ...ROUTES_ADAPTATION,
      ...BOARD,
      ...ROUTES_APTEKA_LEGKO,
    ],
  },
  {
    path: '/' + ALL_ROUTES.authorization,
    element: <Authorization />,
  },
];

export const router = createBrowserRouter(ROUTES);
