import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { LoadingButton } from '@mui/lab';
import { DownloadExcelFileFromListProps } from './types';

export const DownloadExcelFileFromList = (
  props: DownloadExcelFileFromListProps
) => {
  return (
    <LoadingButton
      variant='contained'
      color='primary'
      startIcon={<SimCardDownloadIcon />}
      {...props}
    >
      Excel
    </LoadingButton>
  );
};
