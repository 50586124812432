/* eslint-disable budapestian/global-constant-pattern */

import { TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { STYLE_STIKY_CELL } from '../row-actions/constants';

import { useCheckContainsEventTargetOfRef } from 'shared/hooks/use-check-contains-event-target-of-ref';
import { ContextMenu } from 'shared/ui/base-ui/context-menu';
import { UiTableRowContextMenuWithActionsProps } from './type';

export const UiTableRowContextMenuWithActions: FC<
  UiTableRowContextMenuWithActionsProps
> = ({ Actions, onClick, ...props }) => {
  const [ref, setRef] = useState<null | HTMLTableCellElement>(null);
  const onClickWithSkipOnConteinsCell = useCheckContainsEventTargetOfRef(ref, {
    cbOnContains: () => {
      console.log('click on actions cell');
    },
    cbOnNotContains: onClick,
  });

  return (
    <TableRow
      onClick={onClickWithSkipOnConteinsCell}
      {...props}
    >
      {props.children}

      <TableCell
        ref={setRef}
        align='right'
        data-action-cell
        sx={STYLE_STIKY_CELL}
      >
        <ContextMenu.Provider>
          <ContextMenu.IconButton data-action-cell />
          {Actions}
        </ContextMenu.Provider>
      </TableCell>
    </TableRow>
  );
};
