import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FC, useState } from 'react';
import {
  UiTable,
  UiTableCell,
  UiTableHeaderWithSort,
  UiTablePagination,
  UiTableRow,
} from 'shared/ui/table';

import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';

import { Collapse, IconButton, Paper, Typography } from '@mui/material';
import { theme } from 'shared/theme/theme';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import { ProcessOpeningRetails } from './process-sub-table/process-opening-retails-table';
import {
  ProtocolOpeningRetailsItem,
  ProtocolOpeningRetailsTableProps,
} from './types';

export const ProtocolOpeningRetailsTable: FC<
  ProtocolOpeningRetailsTableProps
> = ({
  isLoading,
  isUpdate,
  data,
  visibleFields,
  fields,
  onChangeFields,
  page,
  onChangeLimit,
  onChangePage,
  count,
  limit,
  order,
  onChangeOrder,
}) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={1} />}
      header={
        <UiTableHeaderWithSort
          viewCells={[
            {
              field: 'guid',
              title: '',
              getTableCellProps: () => {
                return {};
              },
              isShow: true,
              renderComponent: () => {
                return <></>;
              },
              order: 0,
            },
            ...visibleFields,
          ]}
          allCells={fields}
          setViewCells={onChangeFields}
          order={order.order}
          orderBy={order.orderBy}
          onChangeOrder={onChangeOrder}
          orderByList={['title', 'department', 'retail']}
        />
      }
      body={data.map((protocol) => (
        <TableRowWithSubTable
          key={protocol.guid}
          protocol={protocol}
          visibleFields={visibleFields}
        />
      ))}
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage + 1);
          }}
          page={page - 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};

const TableRowWithSubTable: FC<{
  protocol: ProtocolOpeningRetailsItem;
  visibleFields: FieldMapType<ProtocolOpeningRetailsItem>[];
}> = ({ protocol, visibleFields }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [allFieldsProcess, onChangeFieldsProcess, visibleFieldsProcess] =
    ProcessOpeningRetails.useCellFromLocalStorage();

  const isSowSubTable = protocol.openingProcesses.length > 0;

  return (
    <>
      <UiTableRow key={protocol.guid}>
        <UiTableCell
          key={'1'}
          sx={{
            borderBottom: 'unset',
            borderTop: 'solid 1px',
            borderColor: theme.palette.customGrey.main,
          }}
        >
          <IconButton
            disabled={!isSowSubTable}
            aria-label='expand row'
            size='small'
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </UiTableCell>
        {visibleFields.map((el) => (
          <UiTableCell
            key={el.field + protocol.guid}
            {...el.getTableCellProps()}
          >
            {el.renderComponent(protocol) || 'Error'}
          </UiTableCell>
        ))}
      </UiTableRow>
      {isSowSubTable && (
        <UiTableRow>
          <UiTableCell colSpan={visibleFields.length + 2}>
            <Collapse
              collapsedSize={0}
              in={isOpen}
              timeout={'auto'}
              unmountOnExit
            >
              <Typography
                variant='h6'
                gutterBottom
                fontWeight={'bold'}
                component='div'
              >
                Процессы
              </Typography>
              <Paper>
                {protocol.openingProcesses.length > 0 && (
                  <ProcessOpeningRetails.Table
                    data={protocol.openingProcesses}
                    onChangeFields={onChangeFieldsProcess}
                    fields={allFieldsProcess}
                    visibleFields={visibleFieldsProcess}
                  />
                )}
              </Paper>
            </Collapse>
          </UiTableCell>
        </UiTableRow>
      )}
    </>
  );
};
