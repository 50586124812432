import { Typography } from '@mui/material';
import { FC } from 'react';

export const DocumentTaskUi: FC<{ task?: string }> = ({ task }) => {
  return (
    <Typography sx={{ minWidth: 'fit-content' }}>
      {task ? task : '-'}
    </Typography>
  );
};
