import { Grid } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

export const LayoutFirebaseNotificationFormPage: FC<
  PropsWithChildren<{ Actions?: ReactNode; Preview?: ReactNode }>
> = ({ children, Actions, Preview }) => {
  return (
    <Grid
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        '& > *:nth-of-type(1)': {
          gridColumn: ['1/13', '1/7'],
          paddingBottom: 6,
        },
        '& > *:nth-of-type(2)': {
          gridColumn: { sm: '8/13', lg: '8/12', xl: '9/12' },
          display: { xs: 'none', sm: 'flex' },
          paddingBottom: 6,
        },
        '& > *:nth-of-type(3)': {
          gridColumn: '1/13',
          position: 'sticky',
          bottom: 0,
          zIndex: 2,
        },
      }}
    >
      <Grid>{children}</Grid>
      <Grid>{Preview}</Grid>
      <Grid>{Actions}</Grid>
    </Grid>
  );
};
