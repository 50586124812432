import { Dashboard, DeveloperBoardOff } from '@mui/icons-material';
import { Box } from '@mui/material';
import { AddTaskToBoardDialog } from 'features/boards/dialogs/AddTaskToBoardDialog';
import { AddTaskToBoardFormFields } from 'features/boards/forms/AddTaskToBoardForm';
import { MutableBoardTaskCard } from 'features/boards/MutableBoardTaskCard';
import { checkCanEditTask } from 'features/task/task-id-component/libs/checkCanEditTask';
import { useBoardDragDrop } from 'pages/boards/context/BoardDragDropContext';
import { useBoardPage } from 'pages/boards/context/BoardPageContext';
import { FC, memo, useCallback, useMemo } from 'react';
import { useMoveTaskToAnotherBoardMutation } from 'shared/api/services/rtk/boardApi';
import { GetListBoardTask } from 'shared/api/services/types';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { MenuOption } from 'shared/ui/icon-with-menu';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { useBoardTaskCardForBoardPage } from './hook';
import { BoardTaskCardForBoardPageProps } from './type';

const findTask = (taskList: string[], taskGuid: string) => {
  return taskList.includes(taskGuid);
};

export const BoardTaskCardForBoardPage: FC<BoardTaskCardForBoardPageProps> = ({
  task,
  cardProps,
  onClick,
  boxProps,
}) => {
  const { selectTask, selectedTasks, dragTask } = useBoardDragDrop();
  const { handleUpdateStatus, handleUpdateTask, isLoading } =
    useBoardTaskCardForBoardPage(task);

  const selectHandler: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.metaKey || e.ctrlKey) {
        e.stopPropagation();
        selectTask(task.taskGuid);
      }
    },
    [selectTask]
  );

  const isSelected = useMemo(() => {
    return findTask(selectedTasks, task.taskGuid);
  }, [selectedTasks, task.taskGuid]);

  const isGhost = useMemo(() => {
    return !!dragTask && isSelected && dragTask !== task.taskGuid;
  }, [dragTask, isSelected, task.taskGuid]);

  const { board } = useBoardPage();

  const executers = useMemo(() => {
    if (!board) return EMPT_ARR;

    const newExecuters = [];

    newExecuters.push(board.creator);

    if (board.employees) {
      newExecuters.push(...board.employees);
    }

    return newExecuters;
  }, [board]);

  /* Task moving */
  const {
    close: closeMove,
    isOpen: isOpenMove,
    open: openMove,
    initValue: initTaskGuid,
  } = useViewModalWithInitFields<string>();
  const [moveTask, { isLoading: isMovingTask }] =
    useMoveTaskToAnotherBoardMutation();
  /* ===== */

  const getOptions = useCallback((task: GetListBoardTask): MenuOption[] => {
    return [
      {
        icon: <Dashboard />,
        disabled: !task.accessActions.canMoveToBoard,
        onClick: () => {
          openMove(task.taskGuid);
        },
        title: 'Перенести на другую доску',
      },
      {
        icon: <DeveloperBoardOff />,
        onClick: () => {
          moveTask({ tasksGuids: [task.taskGuid] });
        },
        title: 'Открепить',
        disabled: !task.accessActions.canMoveToBoard,
      },
    ];
  }, []);

  const actions = useMemo(() => {
    return getOptions?.(task) || EMPT_ARR;
  }, [getOptions]);

  const handleMoveSubmit = (form: AddTaskToBoardFormFields) => {
    if (initTaskGuid)
      moveTask({
        tasksGuids: [initTaskGuid],
        boardColumnGuid: form.columnGuid,
      }).then(closeMove);
  };

  return (
    <Box
      {...boxProps}
      onClickCapture={selectHandler}
    >
      <MemoizedMutableBoardTaskCard
        executers={executers}
        onTaskChangeSubmit={handleUpdateTask}
        isLoading={isLoading}
        onStatusClick={handleUpdateStatus}
        cardProps={cardProps}
        task={task}
        isGhost={isGhost}
        actions={actions}
        onClick={onClick}
        isSelected={isSelected}
        canEdit={checkCanEditTask(task.accessActions)}
      />

      <AddTaskToBoardDialog
        isOpen={isOpenMove}
        onClose={closeMove}
        onSubmit={handleMoveSubmit}
        isLoading={isMovingTask}
      />
    </Box>
  );
};

const MemoizedMutableBoardTaskCard = memo(MutableBoardTaskCard);
