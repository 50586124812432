import { transformResponseError } from 'shared/api/libs';
import { adaptationApi } from '../adaptationApi';
import {
  AdaptationManagerRequestsPreparedStageTasksCreate,
  AdaptationManagerResponsesGetAllowedAdaptationProcess,
  AdaptationManagerResponsesGetEmployee,
  AdaptationManagerResponsesGetFilter,
  AdaptationManagerResponsesGetListAdaptationProcessStage,
  AdaptationManagerResponsesGetListAdaptationProcessTask,
  AdaptationManagerResponsesGetListEmployee,
  AdaptationManagerResponsesGetListEmployeeAdaptationProcess,
  AdaptationManagerResponsesGetListTaskPreparedEmployee,
  AdaptationProcessesCompletedListParams,
  AdaptationProcessesCurrentListParams,
  AdaptationProcessesFiredListParams,
  AdaptationProcessesPreparedStageTasksListParams,
  AdaptationProcessesWaitingListParams,
  PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess,
} from '../adaptationAutogenerateApi';
import { adaptationProviderApi } from './adaptationProviderApi';

/* TODO: Временно */
export interface AdaptationProcessListItem {
  adaptationProcessGuid?: string;
  adaptationTemplateTitle?: string;
  createdAt?: string;
  createdBy?: string;

  employeeGuid: string;
  employeeName: string;
  employeePosition: string;

  mentorName?: string;
  mentorPosition?: string;
}

export interface PaginatedAdaptationProcessList {
  count: number;
  items: AdaptationProcessListItem[];
}

const transformBackProcessToClientProcess = (
  data: PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess
): PaginatedAdaptationProcessList => {
  return {
    count: data.count,
    items: data.items.map((item) => ({
      adaptationProcessGuid: item.adaptationProcess?.guid,
      adaptationTemplateTitle: item.adaptationProcess?.adaptation.title,
      employeeGuid: item.employee.guid,
      employeeName: item.employee.name,
      employeePosition: item.employee.position.title,
      mentorName: item.adaptationProcess?.mentor.name,
      mentorPosition: item.adaptationProcess?.mentor.position.title,
      createdAt: item.adaptationProcess?.createdAt,
      createdBy: item.adaptationProcess?.createdBy,
    })),
  };
};

/* ========== */

const adaptationProcessesProviderApi = adaptationProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /** Получение списка "Текущие" */
    getCurrentProcesses: build.query<
      //   PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess,
      PaginatedAdaptationProcessList,
      AdaptationProcessesCurrentListParams
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesCurrentList(
              args
            );
          return { data: transformBackProcessToClientProcess(response.data) };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [
          ...res.items.map((el) => ({
            type: 'adaptation_process' as const,
            id: el.employeeGuid,
          })),
          'adaptation_process_list',
        ];
      },
    }),
    /* =========================== */

    /** Получение списка "Завершенные" */
    getCompletedProcesses: build.query<
      //   PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess,
      PaginatedAdaptationProcessList,
      AdaptationProcessesCompletedListParams
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesCompletedList(
              args
            );
          return { data: transformBackProcessToClientProcess(response.data) };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [
          ...res.items.map((el) => ({
            type: 'adaptation_process' as const,
            id: el.employeeGuid,
          })),
          'adaptation_process_list',
        ];
      },
    }),
    /* =============================== */

    /** Получение списка "Уволенные" */
    getFiredProcesses: build.query<
      //   PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess,
      PaginatedAdaptationProcessList,
      AdaptationProcessesFiredListParams
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesFiredList(
              args
            );
          return { data: transformBackProcessToClientProcess(response.data) };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [
          ...res.items.map((el) => ({
            type: 'adaptation_process' as const,
            id: el.employeeGuid,
          })),
          'adaptation_process_list',
        ];
      },
    }),
    /* ============================= */

    /** Получение списка "Ожидают адаптации" */
    getWaitingProcesses: build.query<
      //   PaginatedAdaptationManagerResponsesGetListEmployeeAdaptationProcess,
      PaginatedAdaptationProcessList,
      AdaptationProcessesWaitingListParams
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesWaitingList(
              args
            );
          return { data: transformBackProcessToClientProcess(response.data) };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [
          ...res.items.map((el) => ({
            type: 'adaptation_process' as const,
            id: el.employeeGuid,
          })),
          'adaptation_process_list',
        ];
      },
    }),
    /* ===================================== */

    /** Возвращает список задач из задачника по запущенному процессу */
    getAdaptationProcessTasks: build.query<
      AdaptationManagerResponsesGetListAdaptationProcessStage[],
      string
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptationProcessTasks.AdaptationProcessesAdaptationProcessTasksDetail(
              args
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return res
          .reduce<AdaptationManagerResponsesGetListAdaptationProcessTask[]>(
            (accum, current) => {
              if (!current.items) return accum;
              return [...accum, ...current.items];
            },
            []
          )
          .map((el) => ({
            type: 'task' as const,
            id: el.guid,
          }));
      },
    }),
    /* ============================================================== */

    /** Получение списков элементов для фильтра */
    getAdaptationProcessesFilter: build.query<
      AdaptationManagerResponsesGetFilter,
      void
    >({
      queryFn: async () => {
        try {
          const { data } = await adaptationApi.filter.FilterList();
          return {
            data: data,
          };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: ['filter'],
    }),
    /* ======================================== */

    /** Получение списка сотрудников */
    getAdaptationProcessesEmployees: build.query<
      AdaptationManagerResponsesGetListEmployee[],
      void
    >({
      queryFn: async () => {
        try {
          const response = await adaptationApi.employees.EmployeesList();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: ['employees'],
    }),
    /** Получение подробной информации о сотруднике */
    getAdaptationProcessesEmployeByGuid: build.query<
      AdaptationManagerResponsesGetEmployee,
      {
        params: {
          employeGuid: string;
        };
      }
    >({
      queryFn: async ({ params }) => {
        try {
          const response = await adaptationApi.employees.EmployeesDetail(
            params.employeGuid
          );
          return {
            data: response.data,
          };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: ['employees'],
    }),
    /* ============================== */

    /** Удаление запущенного процесса адаптации */
    deleteAdaptationProcesses: build.mutation<
      void,
      {
        params: {
          guid: string;
        };
      }
    >({
      queryFn: async ({ params }) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesDelete(
              params.guid
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: ['adaptation_process_list'],
    }),
    /* ============================== */
    /** Получение параметров запуска процесса адаптации, доступных для указанного пользователя */
    getAdaptationProcessesRunParams: build.query<
      AdaptationManagerResponsesGetAllowedAdaptationProcess,
      {
        params: {
          employeeGuid: string;
        };
      }
    >({
      queryFn: async ({ params }) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesAllowedDetail(
              params.employeeGuid
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: ['adaptation_process_list'],
    }),
    /* ============================== */

    /** Получение параметров запуска процесса адаптации, доступных для указанного пользователя */
    getAdaptationProcessesPreparedStageTasksList: build.query<
      AdaptationManagerResponsesGetListTaskPreparedEmployee[],
      {
        params: AdaptationProcessesPreparedStageTasksListParams;
      }
    >({
      queryFn: async ({ params }) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesPreparedStageTasksList(
              params
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: ['adaptation_process_list'],
    }),
    /* ============================== */

    /** Генерирует задачи и запускает процесс адаптации */
    postRunAdaptationProcess: build.mutation<
      AdaptationManagerResponsesGetListEmployeeAdaptationProcess,
      {
        params: AdaptationManagerRequestsPreparedStageTasksCreate;
      }
    >({
      queryFn: async ({ params }) => {
        try {
          const response =
            await adaptationApi.adaptationProcesses.AdaptationProcessesPreparedStageTasksCreate(
              params
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: ['adaptation_process_list'],
    }),
    /* ============================== */
  }),
});

export const {
  useGetCurrentProcessesQuery,
  useGetCompletedProcessesQuery,
  useGetFiredProcessesQuery,
  useGetWaitingProcessesQuery,
  useGetAdaptationProcessTasksQuery,
  useGetAdaptationProcessesFilterQuery,
  useGetAdaptationProcessesEmployeesQuery,
  useDeleteAdaptationProcessesMutation,
  useGetAdaptationProcessesRunParamsQuery,
  useLazyGetAdaptationProcessesRunParamsQuery,
  useGetAdaptationProcessesPreparedStageTasksListQuery,
  useGetAdaptationProcessesEmployeByGuidQuery,
  usePostRunAdaptationProcessMutation,
} = adaptationProcessesProviderApi;
