import { useTheme } from '@mui/material';
import { FC, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { DocumetnDoActionRequest } from 'shared/api/services-document-flow/types';
import { ContextMenu } from 'shared/ui/base-ui/context-menu';
import { DialogConfirmForm } from 'shared/ui/form';
import { postDocumentsDocumentIdDoActionFx } from './api/document-actions';
import {
  DialogFormAgreeOrganization,
  useDialogFormAgreeOrganization,
} from './form-agree-organization';
import {
  DialogFormCloseDocument,
  useDialogFormCloseDocument,
} from './form-close-document';
import {
  DialogFormDocumentDelegateAction,
  useDialogFormDocumentDelegateAction,
} from './form-delegate-action';
import { useDeleteDocumentIdActions, useGetDocumentIdActions } from './hooks';
import { getActionsIcon } from './libs/get-actions-icon';
import { getDocumentFlowColorAction } from './libs/get-document-flow-color-action';
import { documentActionReturnDcumentCommentModal } from './models/document-actions-return-document';
import { orderManagerAgreeRequestModal } from './models/order-manager-agree-request';
import { DocumentActionsListItems, ListActionsDocumentProps } from './types';

export const ListActionsDocument: FC<ListActionsDocumentProps> = ({
  documentId,
}) => {
  const theme = useTheme();
  const { isOpen } = ContextMenu.useContextMenu();

  const [actions, isLoadingActions] = useGetDocumentIdActions(
    isOpen ? documentId : undefined
  );

  /* DELETE */
  const {
    isOpenDealogConfirmDelete,
    onOpenDialogConfirmDelete,
    handleConfirmDelDocument,
    onCloseDialogConfirmDelete,
  } = useDeleteDocumentIdActions(documentId);

  /* EDIT */

  const navigate = useNavigate();
  const toEditDocumentPage = useCallback(() => {
    navigate(`/document-flow/${documentId}/edit`);
  }, [documentId, navigate]);
  /* action agree organization */
  const {
    isLoadingInitialValueAgreeOrganizationForm,
    isLoadingSubmitAgreeOrganizationForm,
    isOpenDialogFormAgreeOrganization,
    onCloseDialogFormAgreeOrganization,
    onOpenDialogFormAgreeOrganization,
    onSubmitAgreeOrganizationForm,
    initialValueAgreeOrganizationForm,
  } = useDialogFormAgreeOrganization(documentId);

  /* action close document */

  const {
    isOpenDialogCloseDocument,
    onOpenDialogCloseDocument,
    onCloseDialogCloseDocument,
    initialValueFormCloseDocument,
    loadingInitialValue,
    isLoadingPostDocumentDoAction,
    isLodingDeleteCloseDocumentFile,
    isLodingPostCloseDocumentFile,
    onSubmitCloseDocumentForm,
  } = useDialogFormCloseDocument({ documentId: documentId });

  /* action delegate */

  const {
    isLoadingSubmitFormDelegateAction,
    isOpenDialogFormDelegateAction,
    onCloseDialogFormDelegateAction,
    onOpenDialogFormDelegateAction,
    handleSubmitFormDelegateAction,
  } = useDialogFormDocumentDelegateAction(documentId);

  const allActions: DocumentActionsListItems[] = [];

  if (actions) {
    if (actions.documentActions.length > 0) {
      actions.documentActions.forEach((item) => {
        let onHandleClick = () => {
          console.log(`Action : ${item.name}`);
        };

        if (item.id === 3) {
          onHandleClick = () =>
            orderManagerAgreeRequestModal.events.onOpen({
              documentId,
              actionId: item.id,
            });
        } else if (item.id === 5) {
          onHandleClick = onOpenDialogCloseDocument;
        } else if (item.id === 7) {
          onHandleClick = onOpenDialogFormAgreeOrganization;
        } else if (item.id === 8) {
          onHandleClick = () =>
            documentActionReturnDcumentCommentModal.events.open(documentId);
        } else if (item.id === 14) {
          onHandleClick = onOpenDialogFormDelegateAction;
        } else {
          const requestBody = {
            actionTaskId: item.id,
          } as DocumetnDoActionRequest;

          onHandleClick = () =>
            postDocumentsDocumentIdDoActionFx({
              documentId,
              requestBody,
            });
        }
        allActions.push({
          id: item.id,
          name: item.name,
          Icon: getActionsIcon(item.id),
          color: getDocumentFlowColorAction(item.id, theme),
          onHandleClick,
        });
      });
    }

    const canEdit = actions && actions.canEdit;

    if (canEdit) {
      allActions.push({
        id: 'edit',
        name: 'Внести изменения',
        Icon: getActionsIcon('edit'),
        color: getDocumentFlowColorAction('edit', theme),
        onHandleClick: toEditDocumentPage,
      });
    }

    const canDelete = actions && actions.canDelete;

    if (canDelete) {
      allActions.push({
        id: 'delete',
        name: 'Удалить',
        Icon: getActionsIcon('delete'),
        color: getDocumentFlowColorAction('delete', theme),
        onHandleClick: onOpenDialogConfirmDelete,
      });
    }
  }

  return (
    <>
      {/* MENU */}
      <ContextMenu.ContainerMenu>
        <ContextMenu.MenuItemList
          list={allActions}
          isEmpty={!isLoadingActions && allActions.length === 0}
          isLoading={isLoadingActions && allActions.length === 0}
          isUpdating={isLoadingActions && allActions.length > 0}
        />
      </ContextMenu.ContainerMenu>

      {/* FORMS */}
      <DialogConfirmForm
        open={isOpenDealogConfirmDelete}
        onClose={onCloseDialogConfirmDelete}
        textConfirmAction='Удалить'
        actionButtonColor='error'
        title={'Удалить документ?'}
        onConfirmation={handleConfirmDelDocument}
      />
      <DialogFormAgreeOrganization
        isOpen={isOpenDialogFormAgreeOrganization}
        initialValue={initialValueAgreeOrganizationForm}
        loading={
          isLoadingSubmitAgreeOrganizationForm ||
          isLoadingInitialValueAgreeOrganizationForm
        }
        onClose={onCloseDialogFormAgreeOrganization}
        documentId={documentId}
        onSubmit={onSubmitAgreeOrganizationForm}
      />
      <DialogFormCloseDocument
        initialValue={initialValueFormCloseDocument}
        isOpen={isOpenDialogCloseDocument}
        onClose={onCloseDialogCloseDocument}
        onSubmit={onSubmitCloseDocumentForm}
        isLoading={
          loadingInitialValue ||
          isLoadingPostDocumentDoAction ||
          isLodingDeleteCloseDocumentFile ||
          isLodingPostCloseDocumentFile
        }
      />

      <DialogFormDocumentDelegateAction
        isOpen={isOpenDialogFormDelegateAction}
        onSubmitForm={handleSubmitFormDelegateAction}
        documentId={documentId}
        onClose={onCloseDialogFormDelegateAction}
        isLoading={isLoadingSubmitFormDelegateAction}
      />
    </>
  );
};
