import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC, useLayoutEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { CoreSystemEnumsObjectActions } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { TextFieldWithController } from 'shared/fields-with-contoller';
import { TextWithEndIconTooltip } from 'shared/ui/base-ui/text-with-end-icon-tooltip';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { ImageUploaderPreview } from 'shared/ui/image-uploader-preview';
import { fileListToArrayBase64 } from 'shared/utils/files';
import { INITIAL_FORM } from './constants';
import { BrandFormValue, DialogBrandFormProps } from './types';

export const DialogBrandForm: FC<DialogBrandFormProps> = ({
  isOpen = false,
  onSubmit,
  isLoading = false,
  initialValue,
  onClose,
  title,
}) => {
  const { control, setValue, handleSubmit, reset } = useForm<BrandFormValue>({
    defaultValues: initialValue || INITIAL_FORM,
  });

  const imageData = useWatch({ control, name: 'imageData' });
  const imageUrl = useWatch({ control, name: 'imageUrl' });

  useLayoutEffect(() => {
    reset(initialValue || INITIAL_FORM);
  }, [initialValue, reset, isOpen]);

  return (
    <DialogForForm
      open={isOpen}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      head={
        <TextWithEndIconTooltip
          text={title}
          tooltipTitle='288 х 150 px'
        />
      }
    >
      <Controller
        control={control}
        name='imageData'
        render={({ fieldState: { error } }) => {
          return (
            <ImageUploaderPreview
              disabledButton={isLoading}
              onChangeInput={async (e) => {
                if (e.currentTarget.files) {
                  const formFiles = await fileListToArrayBase64(
                    e.currentTarget.files
                  );

                  setValue('imageData', formFiles[0], {
                    shouldValidate: true,
                  });
                  setValue('imageAction', CoreSystemEnumsObjectActions.Insert);
                  setValue('imageUrl', null);
                }
              }}
              imageData={imageData}
              imageUrl={imageUrl}
              maxHeightContainerImage={288 * 0.5208}
              maxWidthContainerImage={288}
              uploadButtonId={'bannerBrandImg'}
              error={error?.message}
              elevation={3}
              borderRadius={1}
              overflow='hidden'
            />
          );
        }}
      />
      <TextFieldWithController
        control={control}
        disabled={isLoading}
        name={'link'}
        label={'Ссылка'}
      />
      <ContainerActionsForm>
        <LoadingButton
          loading={isLoading}
          variant='contained'
          type='submit'
        >
          Сохранить
        </LoadingButton>
        <Button
          onClick={onClose}
          disabled={isLoading}
          variant='contained'
          color='customGrey'
        >
          Отменить
        </Button>
      </ContainerActionsForm>
    </DialogForForm>
  );
};
