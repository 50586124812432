import { RetailOpeningProcessResponsesOpeningTemplateGetStageTask } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { FormTemplateTaskValue } from '../../types';

export const prepareTemplateTaskResponseToFormValue = (
  responseData: RetailOpeningProcessResponsesOpeningTemplateGetStageTask
): FormTemplateTaskValue => {
  const {
    title,
    description,
    startDaysCount,
    finishDaysCount,
    creatorPosition,
    executorPosition,
    coExecutorsPositions,
    observersPositions,
    /* reminder */
    isReminderCoExecutor,
    reminderPeriodCoExecutor,
    isReminderCreator,
    reminderPeriodCreator,
    isReminderExecutor,
    reminderPeriodExecutor,
    isReminderObserver,
    reminderPeriodObserver,
    /* files */
    files,
  } = responseData;

  const initValue: FormTemplateTaskValue = {
    title,
    description: description || '',
    startDaysCount,
    finishDaysCount,
    creatorPosition,
    executorPosition,
    coExecutorsPositions,
    observersPositions,
    isReminderCoExecutor,
    reminderPeriodCoExecutor: reminderPeriodCoExecutor || '',
    isReminderCreator,
    reminderPeriodCreator: reminderPeriodCreator || '',
    isReminderExecutor,
    reminderPeriodExecutor: reminderPeriodExecutor || '',
    isReminderObserver,
    reminderPeriodObserver: reminderPeriodObserver || '',
    addingFiles: [],
    deletingFiles: [],
    files: files || [],
  };

  return initValue;
};
