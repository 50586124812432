import { GetVisitingsActionQueryParams } from 'shared/api/visit/types';

import { CONVERT_VISIT_FILTER_MAP } from '../convertMaps';
import { convertURLToObject } from '../convertURLToObject';
import { URLToObjConverter } from '../type';

export const convertURLToVisitFilter: URLToObjConverter<
  Omit<GetVisitingsActionQueryParams, 'Limit' | 'Page'>
> = (searchParams) => {
  return convertURLToObject(searchParams, CONVERT_VISIT_FILTER_MAP) as Omit<
    GetVisitingsActionQueryParams,
    'Limit' | 'Page'
  >;
};
