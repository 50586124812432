import dayjs from 'dayjs';
import { FC, memo, useMemo } from 'react';

import { DATE_FORMATS, DAY_NAMES } from 'shared/date-helper/constants';
import { genDaysElementForMonthCalendar } from 'shared/date-helper/genDaysElementForMonthCalendar';
import { isEqDay } from 'shared/date-helper/isEqDay';
import { UiCalendarContainer } from 'shared/ui/calendar/ui-calendar-container';

import { OtherMonthColendarCell } from '../other-month-calendar-cell';
import { VisitCalendarCell } from '../visit-calendar-cell';
import { VisitCalendarHeadCell } from '../visit-calendar-head-cell';
import { VisitignCalendarProps } from './type';

const TODAY = new Date();

const VisitCalendarHeader: FC = memo(() => {
  return (
    <>
      {DAY_NAMES.map((dayName) => (
        <VisitCalendarHeadCell
          weekDayName={dayName}
          key={dayName}
        />
      ))}
    </>
  );
});

export const VisitignCalendar: FC<VisitignCalendarProps> = ({
  onChangeDay,
  selectedDay,
  selectedMonth,
  visits,
  isLoading = false,
}) => {
  const calendar = useMemo(() => {
    return genDaysElementForMonthCalendar(
      selectedMonth.format(DATE_FORMATS.server)
    );
  }, [selectedMonth]);

  return (
    <UiCalendarContainer>
      <VisitCalendarHeader />

      {calendar.map((day) => {
        const isSelected = isEqDay(selectedDay, day);
        const IsDateFromSelectMonth =
          selectedMonth.month() === dayjs(day).month();

        if (!IsDateFromSelectMonth)
          return (
            <OtherMonthColendarCell
              day={day}
              key={day}
            />
          );
        else
          return (
            <VisitCalendarCell
              isLoading={isLoading}
              key={day}
              date={day}
              visits={visits}
              isSelected={isSelected}
              isToday={isEqDay(TODAY, day)}
              onClick={onChangeDay}
            />
          );
      })}
    </UiCalendarContainer>
  );
};
