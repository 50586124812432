import { useURLState } from 'shared/url-helper/useURLState';

import { useMemo } from 'react';
import { GetDocumentsActionQueryParams } from 'shared/api/services-document-flow/types';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';
import { CONVERT_DOCUMENT_BASE_LIST_FILTER_MAP } from '../constants';

const convertorURLToDocumentActionFilter: URLToObjConverter<
  GetDocumentsActionQueryParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_DOCUMENT_ACTION_LIST_FILTER_MAP
  ) as GetDocumentsActionQueryParams;
};

const CONVERT_DOCUMENT_ACTION_LIST_FILTER_MAP: ConvertMapWithType<GetDocumentsActionQueryParams> =
  [
    ...CONVERT_DOCUMENT_BASE_LIST_FILTER_MAP,
    { key: 'CreatorIdFilters', type: ConvertTypes.masNumber },
  ];

const DEFAULT: GetDocumentsActionQueryParams = {
  CurrentPage: 1,
  PageSize: 20,
};

export const useUrlStateDocumentActionFilter = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToDocumentActionFilter,
    true
  );
  const filterWithDefault = useMemo(() => {
    return Object.assign({ ...DEFAULT }, filter);
  }, [filter]);

  return {
    filter: filterWithDefault,
    setFilter,
  };
};
