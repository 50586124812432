import { useCallback, useEffect, useMemo } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import {
  useCreateOpeningTemplatesStageTaskMutation,
  useGetOpeningTemplatesStageTaskQuery,
  useUpdateOpeningTemplatesStageTaskMutation,
} from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { FormTemplateTaskValue } from '../../types';
import { prepareFormValueToFetchCreateBody } from './prepareFormToCreateBody';
import { prepareTemplateTaskResponseToFormValue } from './prepareTemplateTaskResponseToFormValue';

export const useDialogCreateTemplateTask = (
  templateGuid: string,
  stageGuid: string,
  stageTaskGuid?: string
) => {
  const { open, close, isOpen } = useViewModal();
  const { open: openSnackbar } = useSnackbar();
  const [createTemplateTask, { isLoading: isLoadingCreateTemplateTask }] =
    useCreateOpeningTemplatesStageTaskMutation();
  const [updateTemplateTask, { isLoading: isLoadingUpdateTemplateTask }] =
    useUpdateOpeningTemplatesStageTaskMutation();
  const {
    data: templateTaskDetail,
    isFetching: isFetchingTemplateTask,
    isLoading: isLoadingTemplateTast,
    error,
  } = useGetOpeningTemplatesStageTaskQuery(
    {
      templateGuid,
      stageGuid,
      stageTaskGuid: stageTaskGuid as string,
    },
    {
      skip: !isOpen || !stageTaskGuid,
    }
  );
  const memoInitialValue = useMemo(
    () =>
      templateTaskDetail &&
      prepareTemplateTaskResponseToFormValue(templateTaskDetail),
    [templateTaskDetail]
  );

  useEffect(() => {
    if (error) {
      openSnackbar({
        text: getMessageFromFetchBaseQueryError(error),
        type: 'error',
        duration: 3000,
      });
    }
  }, [error]);

  const onSubmitFormCreateTemplateTask = useCallback(
    async (form: FormTemplateTaskValue) => {
      const prepareData = prepareFormValueToFetchCreateBody(form);

      if (!prepareData) {
        openSnackbar({
          title: 'Этого не должно было произойти!',
          type: 'error',
          duration: 4000,
        });

        return;
      }

      const fnFetchTask = stageTaskGuid
        ? updateTemplateTask({
            templateGuid,
            stageGuid,
            stageTaskGuid,
            body: prepareData,
          })
        : createTemplateTask({
            templateGuid,
            stageGuid,
            body: prepareData,
          });

      await fnFetchTask.then((response) => {
        if ('error' in response) {
          openSnackbar({
            text: getMessageFromFetchBaseQueryError(response.error),
            type: 'error',
            duration: 3000,
          });
        } else {
          openSnackbar({
            text: `Шаблон задача  успешно ${
              stageTaskGuid ? 'обновлен' : 'добавлен'
            }`,
            type: 'success',
            duration: 2000,
          });
          close();
        }
      });
    },
    [openSnackbar, templateGuid, stageGuid, stageTaskGuid]
  );

  return {
    onOpenDialogFormTemplateTask: open,
    isOpenDialogFormTemplateTask: isOpen,
    onCloseDialogFormTemplateTask: close,
    onSubmitFormCreateTemplateTask,
    isLoadingSumbiteTask:
      isLoadingCreateTemplateTask || isLoadingUpdateTemplateTask,
    isLoadingInitialValue: isFetchingTemplateTask || isLoadingTemplateTast,
    initialValue: memoInitialValue,
  };
};
