import { useCallback } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';
import { useViewModal } from 'shared/hooks/useViewModal';

import { ALL_ROUTES } from 'shared/routes/constants/types';
import { FormRunOpeningRetailProcessValue } from '../types';

export const useCreateDialogFormRunOpeningRetailProcess = () => {
  const navigate = useNavigate();

  const {
    isOpen: isOpenDialogRunProcess,
    close: onCloseDialogRunProcess,
    open: onOpenDialogRunProcess,
  } = useViewModal();

  const handleSubmit = useCallback(
    async (form: FormRunOpeningRetailProcessValue) => {
      //TODO : added skipStages and date to queryParams
      const { template, skipStages, date, protocol } = form;

      if (!template || !protocol) {
        console.error('not template or protocol');

        return;
      }

      const searchParamsObj: {
        ProtocolGuid: string;
        StagesToSkipGuids?: string[];
        countdownDate?: string;
      } = {
        ProtocolGuid: protocol.guid,
      };

      if (skipStages && skipStages.length > 0) {
        searchParamsObj.StagesToSkipGuids = skipStages.map((item) => item.guid);
      }
      if (date) {
        searchParamsObj.countdownDate = date.format();
      }

      navigate({
        pathname: `/${
          ALL_ROUTES.retailOpeningProcess
        }/${ALL_ROUTES.openingTemplateRunProcess.replace(
          ':guid',
          template.guid
        )}`,
        search: createSearchParams(searchParamsObj).toString(),
      });
      onCloseDialogRunProcess();

      return null;
    },
    []
  );

  return {
    isOpenDialogRunProcess,
    onCloseDialogRunProcess,
    onOpenDialogRunProcess,
    handleSubmit,
  };
};
