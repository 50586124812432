import { createStore } from 'effector';

import { ReasonDocumentTypeModel } from 'shared/api/services-document-flow/types';

import { documentFormModel } from 'entities/document-form';

import { fetchTypeReasonDocumentFx } from './api';

export const $optionsDocumentReasonType = createStore<
  ReasonDocumentTypeModel[]
>([]).on(fetchTypeReasonDocumentFx.doneData, (_, response) => response.data);

export const documentTypeId = documentFormModel.form.map(
  (document) => (document && document.reasonDocumentTypeId) || null
);

/* sample({
  clock: $optionsDocumentReasonType,
  source: documentTypeId,
  filter: (source, clock) => Boolean(source) && clock.some((item) => item.id === source),
  fn: () => undefined,
  target: documentCreate.api.onChangeReasonDocumentTypeId,
}); */
