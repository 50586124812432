import { getToken } from 'shared/utils/getToken';
import { Api, RequestParams } from './aptekaLegoBannersAutogenerateApi';

export const createApi = new Api<typeof AxiosRequestConfigForSecurityWorker>({
  baseURL: 'https://api.garzdrav.ru:7090',
  securityWorker(getT) {
    if (getT) {
      return getT();
    }
  },
});

const AxiosRequestConfigForSecurityWorker =
  async (): Promise<void | RequestParams> => {
    const accesToken = await getToken();
    if (accesToken) {
      return { headers: { Authorization: `Bearer ${accesToken}` } };
    }
  };

createApi.setSecurityData(AxiosRequestConfigForSecurityWorker);

export const aptekaLegkoBannersApi = createApi;
