import { FC } from 'react';

import { ChecklistGetListResponse } from 'shared/api/visit/types';
import { CustomAutocomplete } from 'shared/ui/base-ui';

interface ChecklistsInputProps {
  value: ChecklistGetListResponse | null;
  onChange: (value: ChecklistGetListResponse | null) => void;
  disabled?: boolean;
  options: ChecklistGetListResponse[];
  isLoading?: boolean;
}
export const ChecklistsInput: FC<ChecklistsInputProps> = ({
  onChange,
  value,
  disabled,
  options,
  isLoading,
}) => {
  return (
    <CustomAutocomplete
      textFieldProps={{
        label: 'Оценочный лист',
        placeholder: 'Выберите оценочный лис',
        required: true,
      }}
      loading={isLoading}
      loadingText='Загрузка оценочных листов...'
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => option.title || ''}
      value={value || null}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, value) => {
        onChange(value);
      }}
    />
  );
};
