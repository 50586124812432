import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { AdaptationProcessesFilterForm } from '../AdaptationProcessesFilterForm';
import { AdaptationProcessesFilterDialogProps } from './type';

export const AdaptationProcessesFilterDialog: FC<
  AdaptationProcessesFilterDialogProps
> = ({ isOpen, onSubmit, isLoading, onClose, initValue }) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Фильтры' />}
    >
      <AdaptationProcessesFilterForm
        onSubmit={onSubmit}
        disabled={isLoading}
        initValue={initValue}
        renderFormActions={() => [
          <LoadingButton
            type='submit'
            key={1}
            variant='contained'
            loading={isLoading}
          >
            Применить
          </LoadingButton>,
          <Button
            onClick={onClose}
            key={2}
            variant='contained'
            color='customGrey'
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
