import { Grid } from '@mui/material';
import { FC } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

import { UiMapProps } from './type';

export const UiMap: FC<UiMapProps> = ({
  children,
  containerProps,
  mapContainerProps,
}) => {
  return (
    <Grid
      width={'100%'}
      {...containerProps}
    >
      <MapContainer
        style={{
          width: '100%',
          height: '100%',
        }}
        center={[60, 90]}
        zoom={3}
        scrollWheelZoom={true}
        preferCanvas={true}
        {...mapContainerProps}
      >
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        {children}
      </MapContainer>
    </Grid>
  );
};
