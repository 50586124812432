import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { delEmpty } from '../utils/delEmpty';
import { URLToObjConverter, UseURLStateFun } from './type';

export const useURLState: UseURLStateFun = <T>(
  URLToObjConvertor: URLToObjConverter<T>,
  isTestUpdateForBrowserGoBack = false
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<T | undefined>(
    URLToObjConvertor(searchParams)
  );

  const handlFilter = useCallback(
    (filter: T | undefined) => {
      if (filter) {
        const prepData = delEmpty(filter);

        setSearchParams(prepData);
      }

      if (!isTestUpdateForBrowserGoBack) setFilters(filter);
    },
    [isTestUpdateForBrowserGoBack]
  );

  useEffect(() => {
    if (isTestUpdateForBrowserGoBack) {
      setFilters((prev) => {
        const cur = URLToObjConvertor(searchParams);
        const prevStr = JSON.stringify(prev);
        const curStr = JSON.stringify(cur);

        if (prevStr === curStr) return prev;
        else return cur;
      });
    }
  }, [isTestUpdateForBrowserGoBack, searchParams]);

  return [filters, handlFilter] as const;
};
