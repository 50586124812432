import {
  EventsForm,
  EventsFormI,
  EventsFormSkeleton,
  prepareDataToInitStage6,
} from 'features/visit/Stage 6';
import { prepareDataToFetchStage6 } from 'features/visit/Stage 6/libs/prepareDataToFetchStage6';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  postChecklistsVisitingChecklistIdComplete,
  postChecklistsVisitingChecklistIdStages5,
} from 'shared/api/visit/services';
import { ALL_ROUTES } from 'shared/routes/constants/types';

import { useChecklist } from 'entities/visit/checkList/context/ChecklistContext';

export const Stage6Page = () => {
  const navigate = useNavigate();
  const { checkList, isLoading, getCheckList, status } = useChecklist();

  const canComplete = useMemo(() => {
    if (checkList) {
      const {
        isStageOneComplete,
        isStageTwoComplete,
        isStageThreeComplete,
        isStageFourComplete,
        isCompetitorComparison,
        problems,
      } = checkList;

      const hasTasks =
        problems && !problems.some((el) => el.problemTasks?.length === 0);

      if (isCompetitorComparison) {
        return (
          isStageOneComplete &&
          isStageTwoComplete &&
          isStageThreeComplete &&
          isStageFourComplete &&
          hasTasks
        );
      } else {
        return isStageThreeComplete && isStageFourComplete && hasTasks;
      }
    } else return false;
  }, [checkList]);

  const onSubmit = async (data: EventsFormI) => {
    if (checkList) {
      try {
        const fetchData = prepareDataToFetchStage6(data);

        await postChecklistsVisitingChecklistIdStages5(checkList.id, fetchData);
        await postChecklistsVisitingChecklistIdComplete(checkList.id);

        navigate(
          '/' + ALL_ROUTES.visitingPharmacies + '/' + ALL_ROUTES.complite
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onDraft = async (data: EventsFormI) => {
    if (checkList) {
      try {
        const fetchData = prepareDataToFetchStage6(data);

        await postChecklistsVisitingChecklistIdStages5(checkList.id, fetchData);
        await getCheckList();
      } catch (e) {
        console.error(e);
      }
    }
  };

  if (isLoading && !checkList) return <EventsFormSkeleton />;
  if (!checkList || status === 400) return <h1>Такого чеклиста нет</h1>;

  return (
    <>
      <EventsForm
        initValue={prepareDataToInitStage6(checkList)}
        onSubmit={onSubmit}
        onSubmitDraft={onDraft}
        canComplete={canComplete}
        disableAll={!checkList.canEdit}
        isLoading={isLoading}
      />
    </>
  );
};
