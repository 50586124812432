import { useStoreMap } from 'effector-react';
import { FC } from 'react';

import { CustomTextField } from 'shared/ui/custom-text-field';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormListAdditionallyFieldDec = () => {
  const arrayDecAdditionalFields = useStoreMap(
    documentFormModel.processAdditionalFields,
    (processAdditionalFields) => {
      return (
        (processAdditionalFields &&
          processAdditionalFields.decimalAdditionallyFields) ||
        []
      );
    }
  );

  return (
    <>
      {arrayDecAdditionalFields.map(({ id, title, isRequired }) => (
        <DocumentFormAdditionallyFieldDec
          key={id}
          id={id}
          required={isRequired}
          label={title || '-'}
        />
      ))}
    </>
  );
};

const DocumentFormAdditionallyFieldDec: FC<{
  id: number;
  required?: boolean;
  label: string;
}> = ({ label, id, required }) => {
  const value = useStoreMap(documentFormModel.form, (form) => {
    if (form && form.decimalAdditionallyFields) {
      return (
        form.decimalAdditionallyFields.find((item) => item.id === id)?.value ||
        ''
      );
    } else {
      return '';
    }
  });
  const [isMobile] = useDevice();

  return (
    <CustomTextField
      propsTextField={{
        value,
        size: 'small',
        required,
        label: isMobile ? undefined : label,
        margin: 'none',
        type: 'number',
        onChange: (e) =>
          documentFormModel.formEvent.setAdditionallyFieldDec({
            id,
            value: Number(e.target.value),
          }),
        inputProps: { placeholder: label, maxLength: 99, step: 0.01 },
      }}
      sx={{ flexBasis: '45%' }}
      label={isMobile ? label : undefined}
    />
  );
};
