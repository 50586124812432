import { TextField } from '@mui/material';
import { FC } from 'react';

import { PropsAutocompliteDocumentForm } from 'shared/types/type';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

import { CombineArticle } from '../types';

export const ArticlesAutocomplite: FC<
  PropsAutocompliteDocumentForm<CombineArticle>
> = ({ value, onChange, options, disabled }) => {
  const [isMobile] = useDevice();

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        sx: {
          width: '100%',
        },
        value: value,
        disabled,
        options: options,
        getOptionLabel: (option) => option.title || '-',
        onChange: onChange,
        groupBy: (option) => String(option.typeTitle),
        isOptionEqualToValue: (option, value) => option.id === value.id,
        renderInput: (params) => (
          <TextField
            {...params}
            placeholder={value ? '' : 'Выберите статью'}
            label={isMobile ? '' : 'Статья ДДС'}
            required
            margin={'normal'}
            size='small'
          />
        ),

        renderOption: (props, option) => (
          <li
            {...props}
            key={option.id}
          >
            {option.title}
          </li>
        ),
      }}
      sx={{ flexBasis: ['100%', '45%'] }}
      label='Статья ДДС*'
    />
  );
};
