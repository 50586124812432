import { FC } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';
import { AdaptationStageTasksList } from './AdaptationStageTasksList';
import { AdaptationStageTasksTable } from './AdaptationStageTasksTable';
import { AdaptationStageTasksTableProps } from './type';

export const AdaptiveAdaptationStageTasksTable: FC<
  AdaptationStageTasksTableProps
> = (props) => {
  const [isMobile] = useDevice();

  if (isMobile) return <AdaptationStageTasksList {...props} />;
  else return <AdaptationStageTasksTable {...props} />;
};
