import { MoreVert } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import { FC } from 'react';
import { useContextMenu } from '../context';

export const ContextMenuIconButton: FC<IconButtonProps> = ({
  children,
  ...props
}) => {
  const { handleClickOnButton } = useContextMenu();

  return (
    <IconButton
      aria-label='more'
      id='long-button'
      aria-haspopup='true'
      {...props}
      onClick={handleClickOnButton}
    >
      {children || <MoreVert />}
    </IconButton>
  );
};
