import { WatchTaskFormFields } from './type';

export const TASK_WATCHING_FILTER_DEFAULT_VALUE: WatchTaskFormFields = {
  dateFinish: null,
  dateStart: null,
  departamentsAndDepts: [],
  directionsAndRegions: [],
  employees: [],
  isTarget: null,
  officesAndDivisions: [],
  origin: [],
  positionsAndRetails: [],
  statuses: [],
};
