// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { EventGetListResponse } from 'shared/api/visit/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const EVENT_RN_MAP: FieldMapType<EventGetListResponse>[] = [
  {
    field: 'title',
    title: 'Название',
    isShow: true,
    order: 0,
    getTableCellProps: () => ({}),
    renderComponent: (event) => <Typography>{event.title}</Typography>,
  },
  {
    field: 'department',
    title: 'Сеть',
    isShow: true,
    order: 1,
    getTableCellProps: () => ({}),
    renderComponent: (event) => (
      <Typography>{event.department?.title || '-'}</Typography>
    ),
  },

  {
    field: 'endDate',
    title: 'Период',
    isShow: true,
    order: 2,
    getTableCellProps: () => ({}),
    renderComponent: (event) => {
      const startDate = dayjs(event.startDate).format(DATE_FORMATS.client);
      const endDate = dayjs(event.endDate).format(DATE_FORMATS.client);

      return <Typography>{`${startDate} - ${endDate}`}</Typography>;
    },
  },
  {
    field: 'members',
    title: 'Участники',
    isShow: true,
    order: 3,
    getTableCellProps: () => ({}),
    renderComponent: (event) => {
      const hasMembers = event.members && event.members.length > 0;

      if (!hasMembers) return <Typography>-</Typography>;

      return (
        <Stack>
          {event.members!.map((member) => (
            <Typography key={member.id}>{member.employeeName}</Typography>
          ))}
        </Stack>
      );
    },
  },
  {
    field: 'direction',
    title: 'Направление',
    isShow: true,
    order: 4,
    getTableCellProps: () => ({}),
    renderComponent: (event) => (
      <Typography>{event.direction.title}</Typography>
    ),
  },
];
