import { Grid } from '@mui/material';

import { sample } from 'effector';

import { DocumentFormPayBeforDate } from 'features/document/document-form-pay-befor-date/DocumentFormPayBeforDate';
import { DocumentFormReasonDate } from 'features/document/document-form-reason-date/DocumentFormReasonDate';
import { DocumentFormReasonNumber } from 'features/document/document-form-reason-number/DocumentFormReasonNumber';
import { DocumentFormReasonTypeAutocomplite } from 'features/document/document-form-reason-type-autocomplite/DocumentFormReasonTypeAutocomplite';
import { DocumentFormReasoneFiles } from 'features/document/document-form-reasone-files/ui';
import { DocumentFormTaxRatesAutocomplite } from 'features/document/document-form-tax-rates-autocomplite/DocumentFormTaxRatesAutocomplite';
import { DocumentFormTotalSum } from 'features/document/document-form-total-sum/DocumentFormTotalSum';

import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';

import {
  documentFormModel,
  postAllDocumentsDocumentIdFilesCloseDocumentsFx,
  postAllDocumentsDocumentIdFilesReasonDocumentsFx,
  postDocumentsDocumentIdFilesReasonDocumentsFx,
  postDocumentsDraftFx,
  postDocumentsFx,
} from 'entities/document-form';
import {
  getOptionLabelServiceType,
  isOptionEqualToValueServiceType,
  renderOptionServiceType,
} from 'entities/document/inputs/service-types-autocomplite';
import { addedErrorObj } from 'entities/error/error-alert/error-alert-effector';
import { useMemo } from 'react';
import { useGetDocumentServiceTypesQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';
import { CustomAutocomplete } from 'shared/ui/base-ui';
import { EMPT_ARR } from 'widgets/training/filter/constants';

sample({ clock: postDocumentsFx.doneData, fn: (document) => document.data.id });
sample({
  clock: postDocumentsFx.doneData,
  source: documentFormModel.formFiles,
  fn: (files, reponse) => {
    return { id: reponse.data.id, files };
  },
  target: postAllDocumentsDocumentIdFilesReasonDocumentsFx,
});
sample({
  clock: postDocumentsDraftFx.doneData,
  source: documentFormModel.formFiles,
  fn: (files, reponse) => {
    return { id: reponse.data.id, files };
  },
  target: postAllDocumentsDocumentIdFilesReasonDocumentsFx,
});
sample({
  clock: postDocumentsFx.doneData,
  source: documentFormModel.formFilesClose,
  fn: (files, reponse) => {
    return { id: reponse.data.id, files };
  },
  target: postAllDocumentsDocumentIdFilesCloseDocumentsFx,
});
sample({
  clock: postDocumentsDraftFx.doneData,
  source: documentFormModel.formFilesClose,
  fn: (files, reponse) => {
    return { id: reponse.data.id, files };
  },
  target: postAllDocumentsDocumentIdFilesCloseDocumentsFx,
});

sample({
  clock: postDocumentsDocumentIdFilesReasonDocumentsFx.failData,
  fn: (error) => {
    return {
      status: error.response?.data.code
        ? String(error.response?.data.code)
        : 'not code',
      message: error.response?.data.additionally || 'not message',
      statusText: error.response?.data.message
        ? String(error.response?.data.message)
        : 'not statusText',
    };
  },
  target: addedErrorObj,
});
export const SectionReasonDocument = () => {
  const isAccountantPayment = documentFormModel.useValueProcess(
    'isAccountantPayment'
  );

  const isServiceTypeRequired = documentFormModel.useValueProcess(
    'isServiceTypeRequired'
  );

  const { data: optionsServiseType, isLoading: isLoadingServiceType } =
    useGetDocumentServiceTypesQuery(undefined, {
      skip: !isServiceTypeRequired,
    });

  const serviceTypeId =
    documentFormModel.useValueDocumentFormModel('serviceTypeId');

  const serviceTypeValue = useMemo(() => {
    if (!serviceTypeId || !optionsServiseType) return null;

    return optionsServiseType.find((item) => item.id === serviceTypeId) || null;
  }, [serviceTypeId, optionsServiseType]);

  return (
    <Grid
      container
      justifyContent={'space-between'}
      gap={2}
    >
      <DocumentSectionTitle
        text={'Документ - основание'}
        sx={{ marginTop: [2, 4] }}
      />

      <DocumentFormReasonTypeAutocomplite />

      <Grid
        width={['100%', '45%']}
        gap={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <DocumentFormReasonNumber />
        <DocumentFormReasonDate />
      </Grid>

      <Grid container>
        <DocumentFormReasoneFiles />
      </Grid>
      {isAccountantPayment && <DocumentFormPayBeforDate />}
      {isServiceTypeRequired && (
        <CustomAutocomplete
          sx={{
            flexBasis: ['100%', '45%'],
          }}
          fullWidth
          loading={isLoadingServiceType}
          options={optionsServiseType || EMPT_ARR}
          textFieldProps={{ label: 'Вид услуги' }}
          value={serviceTypeValue}
          renderOption={renderOptionServiceType}
          isOptionEqualToValue={isOptionEqualToValueServiceType}
          getOptionLabel={getOptionLabelServiceType}
          onChange={(_, value) => {
            documentFormModel.formEvent.setField({
              key: 'serviceTypeId',
              value: value ? value.id : null,
            });
          }}
        />
      )}
      <DocumentFormTotalSum />
      <DocumentFormTaxRatesAutocomplite />
    </Grid>
  );
};
