import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  CustomAutocomplete,
  CustomDatePicker,
  CustomTextField,
} from 'shared/ui/base-ui';

import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useGetAllowedExecutersQuery } from 'shared/api/services/rtk/optionApi';
import { DialogForForm, FormLayout } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { INIT_FORM } from './constants';
import { FormFastTaskSchema } from './libs/schema';
import { FormFastTaskUiProps, IFormFastTaskUi } from './type';

export const FastTaskForm: FC<FormFastTaskUiProps> = ({
  renderFormActions,
  initialValue,
  onSubmit,
}) => {
  const { data: optionsExecuters, isLoading: isLoadingEx } =
    useGetAllowedExecutersQuery();

  const { control, handleSubmit } = useForm<IFormFastTaskUi>({
    shouldUseNativeValidation: false,
    defaultValues: initialValue,
    resolver: yupResolver(FormFastTaskSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  return (
    <FormLayout
      actions={renderFormActions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit, (err) => console.log(err))}
    >
      <Controller
        name='title'
        control={control}
        render={({ field: { value, onChange }, fieldState: { invalid } }) => {
          return (
            <CustomTextField
              value={value || ''}
              required
              onChange={onChange}
              label='Название задачи'
              placeholder='Введите название задачи'
              error={invalid}
            />
          );
        }}
      />

      <Controller
        name='executor'
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <CustomAutocomplete
              textFieldProps={{
                label: 'Исполнитель',
                required: true,
              }}
              loading={isLoadingEx}
              loadingText='Загрузка...'
              options={optionsExecuters || []}
              isOptionEqualToValue={(option, value) =>
                option.guid === value.guid
              }
              value={value}
              onChange={(_, val) => {
                onChange(val);
              }}
              getOptionLabel={(option) =>
                (option.name || '') + ' ' + (option.position?.title || '')
              }
              renderOption={(props, option) => {
                return (
                  <li
                    {...props}
                    key={option.guid}
                  >
                    {(option.name || '') + ' ' + (option.position?.title || '')}
                  </li>
                );
              }}
            />
          );
        }}
      />

      <Controller
        name='dateFinish'
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <CustomDatePicker
              label='Дата завершения'
              slotProps={{ textField: { placeholder: 'Введите дату' } }}
              onChange={onChange}
              value={value}
            />
          );
        }}
      />
    </FormLayout>
  );
};

interface CreateFastTaskDialogProps {
  isOpen: boolean;
  onClose: () => void;
  initValue?: IFormFastTaskUi;
  onSubmit: (form: IFormFastTaskUi) => void;
  onDraftSubmit?: (form: IFormFastTaskUi) => void;
  isLoading?: boolean;
}
export const CreateFastTaskDialog: FC<CreateFastTaskDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initValue = INIT_FORM,
  isLoading,
  onDraftSubmit,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Создание задачи' />}
    >
      <FastTaskForm
        onSubmit={onSubmit}
        initialValue={initValue}
        renderFormActions={(handleSubmit) => [
          <LoadingButton
            loading={isLoading}
            variant='contained'
            key={3}
            type='submit'
          >
            Поставить задачу
          </LoadingButton>,
          onDraftSubmit && (
            <LoadingButton
              variant='contained'
              color='customBlue'
              key={2}
              loading={isLoading}
              onClick={handleSubmit(onDraftSubmit)}
            >
              Создать как черновик
            </LoadingButton>
          ),
          <Button
            variant='contained'
            key={1}
            onClick={onClose}
            color='customGrey'
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
