import { ALL_ROUTES, Routes } from './types';

export const ABOUT: Routes[] = [
  {
    title: 'Адреса аптек и офисов',
    path: ALL_ROUTES.pharmacyAddresses,
    isWork: false,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
];
