import {
  ClickAwayListener,
  Fade,
  Grid,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from '@mui/material';

import { useContextMenu } from '../../context';
import {
  CLASS_NAME_TRIANGLE,
  PERCENT_OF_WIDTH,
  SQUARE_WIDTH,
} from './constants';

import { useModifiersPopper, useStylesPopperWithTringle } from './libs';
import { ContextMenuContainerComponent } from './types';

export const ContextMenuContainer: ContextMenuContainerComponent = ({
  children,
  squareWidth = SQUARE_WIDTH,
  percentOfsquareWidth = PERCENT_OF_WIDTH,
  dataAtrrForHidenArrow = 'data-hide',
  classNameTriangle = CLASS_NAME_TRIANGLE,
}) => {
  const theme = useTheme();
  const { isOpen, handleClose, anchorEl, arrowAnchorEl, setArrowAnchorEl } =
    useContextMenu();

  const popperModifiers = useModifiersPopper({
    arrowAnchorEl,
    squareWidth,
    percentOfsquareWidth,
    dataAtrrForHidenArrow,
  });

  const { popperStyle, triangleStyle } = useStylesPopperWithTringle({
    dataAtrrForHidenArrow,
    percentOfsquareWidth,
    squareWidth,
    classNameTriangle,
  });

  return (
    <Popper
      sx={popperStyle}
      open={isOpen}
      anchorEl={anchorEl}
      placement={'auto'}
      transition
      modifiers={popperModifiers}
    >
      {({ TransitionProps }) => {
        return (
          <Fade
            {...TransitionProps}
            timeout={350}
          >
            <Paper elevation={4}>
              <Grid
                ref={setArrowAnchorEl}
                className={classNameTriangle}
                sx={triangleStyle}
              />
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={{
                    maxHeight: 250,
                    overflowY: 'auto',
                    ...theme.mixins.customScrollBar('light'),
                  }}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        );
      }}
    </Popper>
  );
};
