import { FC, PropsWithChildren, useMemo } from 'react';
import { getNextId } from '../libs/getNextId';
import { SaveFilterType } from '../type';
import { useAppSettings } from '../user-app-settings-context';
import { SaveFilterContext } from './save-filter-context';
import { CreateFilterArgs } from './type';

export const SaveFilterProvider: FC<PropsWithChildren> = ({ children }) => {
  const { appSettings, updateAppSettings } = useAppSettings();

  const getFilterByKey = (key: string) => {
    if (
      !appSettings ||
      !appSettings.saveFilters ||
      appSettings.saveFilters.length === 0
    ) {
      return [];
    }

    return appSettings.saveFilters.filter(
      (saveFilter) => saveFilter.key === key
    );
  };

  const allFilters = useMemo(() => {
    if (!appSettings || !appSettings.saveFilters) return [];
    else return appSettings.saveFilters;
  }, [appSettings?.saveFilters]);

  const saveFilter = (args: CreateFilterArgs) => {
    if (!appSettings) {
      throw new Error(
        'Невозможно создать фильтр так как настройки отсутствуют'
      );
    }

    if (!appSettings.saveFilters) {
      const newSaveFilter: SaveFilterType[] = [{ id: 1, ...args }];

      updateAppSettings({ ...appSettings, saveFilters: newSaveFilter });

      return;
    }

    const newSaveFilter = [...appSettings.saveFilters];

    newSaveFilter.push({ id: getNextId(newSaveFilter), ...args });
    updateAppSettings({ ...appSettings, saveFilters: newSaveFilter });
  };

  const deleteFilter = (id: number) => {
    if (!appSettings || !appSettings.saveFilters) return;

    const newSaveFilter = [...appSettings.saveFilters].filter(
      (el) => el.id !== id
    );

    updateAppSettings({ ...appSettings, saveFilters: newSaveFilter });
  };

  return (
    <SaveFilterContext.Provider
      value={{ getFilterByKey, saveFilter, allFilters, deleteFilter }}
    >
      {children}
    </SaveFilterContext.Provider>
  );
};
