import { Delete } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { FC, ReactNode } from 'react';

import { theme } from 'shared/theme/theme';

export const UiDocumentFormProcessPostDocument: FC<{
  isOpen: boolean;
  ItemsProcess: ReactNode | ReactNode[];
  ButtonsBottom: ReactNode | ReactNode[];
}> = ({ isOpen, ItemsProcess, ButtonsBottom }) => {
  return (
    <Backdrop
      open={isOpen}
      /* open={true} */ sx={{ zIndex: 9900 }}
    >
      <Grid
        container
        sx={{ backgroundColor: 'white', padding: [1, 2], borderRadius: '8px' }}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'space-between'}
        minHeight={'180px'}
        maxHeight={'90vh'}
        width={['100%', '60%']}
      >
        <Grid
          container
          flexDirection={'column'}
          sx={{
            maxHeight: '400px',
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              width: 8,
              position: 'relative',
              right: '1px',
              backgroundColor: theme.palette.customGrey.main,
              borderRadius: 20,
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.customGrey.dark,
              borderRadius: 20,
            },
          }}
        >
          <Grid
            container
            flexDirection={'column'}
            padding={2}
          >
            {ItemsProcess}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent='space-around'
        >
          {ButtonsBottom}
        </Grid>
      </Grid>
    </Backdrop>
  );
};

const UiProgressIcon: FC<{
  loading: boolean;
  complited: boolean;
  error: null | string;
  onRepeat: () => void;
  onDeletedFiles?: () => void;
}> = ({ loading, complited, error, onRepeat, onDeletedFiles }) => {
  if (loading) {
    return (
      <IconButton>
        <CircularProgress size={16} />
      </IconButton>
    );
  }
  if (complited) {
    return (
      <IconButton>
        <DoneIcon color={'secondary'} />
      </IconButton>
    );
  }
  if (error)
    return (
      <Box>
        {onDeletedFiles && (
          <IconButton color='error'>
            <Delete onClick={onDeletedFiles} />
          </IconButton>
        )}
        <IconButton>
          <ReplayIcon onClick={onRepeat} />
        </IconButton>
      </Box>
    );

  return null;
};

export const UiItemProcess: FC<{
  loading: boolean;
  complited: boolean;
  error: null | string;
  onRepeat: () => void;
  onDeletedFiles?: () => void;
  title: string;
}> = ({ loading, complited, error, onRepeat, title, onDeletedFiles }) => {
  return (
    <Grid
      minWidth={[320, 400]}
      minHeight={40}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ marginBottom: 1 }}
      color={
        error
          ? theme.palette.error.main
          : complited
          ? theme.palette.primary.main
          : 'black'
      }
    >
      <Typography marginRight={[0, 2]}>{title}</Typography>
      <UiProgressIcon
        loading={loading}
        complited={complited}
        error={error}
        onRepeat={onRepeat}
        onDeletedFiles={onDeletedFiles}
      />
    </Grid>
  );
};
