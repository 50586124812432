import { useCallback, useState } from 'react';

import { postVisitingsDelete } from 'shared/api/visit/services';

export const useDeleteVisit = () => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteVisit = useCallback(async (visitId: number) => {
    setIsLoading(true);
    try {
      await postVisitingsDelete({ ids: [visitId] });
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }, []);

  return { isLoading, deleteVisit };
};
