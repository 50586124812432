export const INIT_FORM = {
  executor: null,
  title: null,
  dateFinish: null,
  coExecutors: [],
  status: {
    id: 1,
    title: 'Новая',
  },
  observers: [],
  subTasks: [],
};
