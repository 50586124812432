import { Box, Grid, Paper } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { CustomColorPicker, CustomTextField } from 'shared/ui/base-ui';
import { COLUMN_COLORS } from '../CreateBoardColumnButton/constants';
import { ColumnFormCardFields, ColumnFormCardProps } from './type';

export const ColumnFormCard: FC<ColumnFormCardProps> = ({
  onSubmit,
  initValue,
  actions,
}) => {
  const { control, handleSubmit } = useForm<ColumnFormCardFields>({
    defaultValues: initValue || { color: COLUMN_COLORS[0] },
  });

  const color = useWatch({ control, name: 'color' });

  return (
    <Paper
      sx={{
        p: 2,
        pb: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        position: 'relative',
        overflow: 'hidden',
      }}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        position={'absolute'}
        sx={{
          width: '100%',
          height: 4,
          left: 0,
          top: 0,
          background: color,
          transition: 'ease-in-out .2s',
        }}
      />
      <Controller
        control={control}
        name='title'
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <CustomTextField
            value={value || ''}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            variant='standard'
            label='Название'
            placeholder='Введите название'
            required
            autoFocus
            focused
          />
        )}
      />

      <Controller
        control={control}
        name='color'
        render={({ field: { value, onChange } }) => (
          <CustomColorPicker
            options={COLUMN_COLORS}
            onChange={onChange}
            value={value}
          />
        )}
      />

      <Grid>{actions}</Grid>
    </Paper>
  );
};
