import { Grid, Skeleton } from '@mui/material';

export const VisitBagesForCalendarSkeleton = () => {
  return (
    <Grid
      container
      flexDirection={'column'}
      justifyContent={'space-between'}
      gap={'5px'}
      width={'100%'}
    >
      <Grid
        container
        flexDirection={'column'}
        gap={'5px'}
        width={'100%'}
      >
        <Skeleton variant='rounded' />
        <Skeleton variant='rounded' />
      </Grid>

      <Skeleton
        variant='rounded'
        width={'50%'}
      />
    </Grid>
  );
};
