import { Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'shared/theme/theme';
import { ColumnHeader } from 'shared/ui/ColumnHeader';
import { BoardColumnHeaderProps } from './type';

const DEFAULT_COLOR = theme.palette.primary.main;

export const BoardColumnHeader: FC<BoardColumnHeaderProps> = ({
  column,
  actions,
  cardProps,
}) => {
  return (
    <ColumnHeader
      color={column.color || DEFAULT_COLOR}
      actions={actions}
      cardProps={cardProps}
    >
      <Tooltip title={column.title.length > 20 ? column.title : ''}>
        <Typography
          fontWeight={'bold'}
          fontSize={20}
          textOverflow={'ellipsis'}
          whiteSpace={'nowrap'}
          overflow={'hidden'}
          width={'100%'}
        >
          {column.title}
        </Typography>
      </Tooltip>
    </ColumnHeader>
  );
};
