import { ALL_ROUTES, Routes } from './types';

export const USER: Routes = {
  title: 'Личный кабинет',
  path: ALL_ROUTES.user,
  isWork: false,
  description: null,
  icon: null,
  children: null,
  fullPath: '',
};
