// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { createApi, createStore, sample } from 'effector';

import {
  documentFormModel,
  firstValidationFx,
  secondaryValidationFx,
} from 'entities/document-form';

export const $isOpenModal = createStore(false).reset(
  documentFormModel.resetFormModel
);

const events = createApi($isOpenModal, {
  open: () => true,
  close: () => false,
});

sample({
  clock: events.open,
  target: documentFormModel.formEvent.onFirstValidation,
});

sample({ clock: secondaryValidationFx.doneData, target: events.close });
sample({ clock: firstValidationFx.fail, target: events.close });

export const modal = {
  isOpen: $isOpenModal,
  events,
};
