import { AddTaskToBoardFormFields } from 'features/boards/forms/AddTaskToBoardForm';
import { useChangeTaskStatusModal } from 'features/task/hooks/useChangeTaskStatusModal';
import { useDeleteTask } from 'features/task/hooks/useDeleteTask';
import { useCallback } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useMoveTaskToAnotherBoardMutation } from 'shared/api/services/rtk/boardApi';
import { useGetSubTasksQuery } from 'shared/api/services/rtk/taskApi';
import { GetListTask } from 'shared/api/services/types';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { useDevice } from 'shared/utils/hook-type-device';
import { TaskTableActions } from '../task-table';

export const useSubTasksTab = (taskGuid: string) => {
  const { open: openSnackbar } = useSnackbar();
  const deleteTask = useDeleteTask();
  const { data: subTasks } = useGetSubTasksQuery(taskGuid);
  const [isMobile] = useDevice();

  const { modal: changeTaskStatusModal, open: openStatusChangeModal } =
    useChangeTaskStatusModal();

  /* ===== */
  const {
    close: closeMove,
    isOpen: isOpenMove,
    initValue: moveTaskGuid,
    open: openMove,
  } = useViewModalWithInitFields<string>();

  const [moveTask, { isLoading: isMovingTask, error }] =
    useMoveTaskToAnotherBoardMutation();

  useShowFetchErrorInSnackbar(error);

  const handleSubmitMove = useCallback(
    (form: AddTaskToBoardFormFields) => {
      if (moveTaskGuid)
        moveTask({
          tasksGuids: [moveTaskGuid],
          boardColumnGuid: form.columnGuid,
        }).then(() => {
          openSnackbar({
            duration: 3000,
            type: 'success',
            title: 'Задача перемещена',
          });
          closeMove();
        });
    },
    [moveTaskGuid]
  );
  /* ===== */

  const getTaskTableAction = useCallback((sub: GetListTask) => {
    return (
      <TaskTableActions
        onChangeStatus={() => {
          openStatusChangeModal(sub);
        }}
        onDelete={deleteTask}
        onMoveToBoard={openMove}
        task={sub}
      />
    );
  }, []);

  return {
    getTaskTableAction,
    handleSubmitMove,
    isOpenMove,
    changeTaskStatusModal,
    subTasks,
    isMobile,
    isMovingTask,
    closeMove,
  };
};
