import { useCallback, useState } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';

export const useTaskDrawer = () => {
  /* Task Drawer */
  const [selectedTaskGuid, setSelectedTaskGuid] = useState<string | null>(null);
  const {
    close: closeDrawer,
    isOpen: isOpenDrawer,
    open: openDrawer,
  } = useViewModal();

  const tableClickHandler = useCallback((task: { guid: string }) => {
    setSelectedTaskGuid(task.guid);
    openDrawer();
  }, []);

  return { selectedTaskGuid, closeDrawer, isOpenDrawer, tableClickHandler };
};
