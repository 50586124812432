import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { ProblemTaskList } from './ProblemTaskList';
import { ProblemTaskTable } from './ProblemTaskTable';
import { ProblemTaskTableProps } from './type';

export const AdeptiveProblemTaskTable: FC<ProblemTaskTableProps> = (props) => {
  const [isMobile] = useDevice();

  if (isMobile) return <ProblemTaskList {...props} />;
  else return <ProblemTaskTable {...props} />;
};
