export const getNormolizeNumberPhone = (phone: string) => {
  const arr = phone.replace(/\s+/g, '').split('');
  const normPhone = arr
    .filter((item) => item !== '(' && item !== ')' && item !== '-')
    .join('');

  return normPhone;
};
export const replacePhone = (phone: string) => {
  if (phone.length === 12) {
    return phone.replace('+7', '');
  } else if (phone.length === 11) {
    return phone.replace('8', '');
  }

  return phone;
};
export const phoneNormolized = (phone: string) =>
  replacePhone(getNormolizeNumberPhone(phone));

export const checkValidPhone = (phone: string) => {
  return Boolean(phone.length === 10);
};
