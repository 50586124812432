import { renderOptionEmployeNameWithPosition } from 'entities/document/inputs/employee-all-autocomplite';
import { FC, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompliteWithController } from 'shared/fields-with-contoller';

import { isOptionEqualToValueById } from 'shared/tarnsform-helper/isOptionEqualToValueById';

import { EMPT_ARR } from 'widgets/training/filter/constants';
import { useGetDocumentFlowOptionsOptionsBaseFilterForm } from '../hooks';

import { yupResolver } from '@hookform/resolvers/yup';

import { DEFAULT_BASE_FORM_VAUE } from '../constants';
import { DocumentFlowBaseFormFilterModal } from '../ui';
import { DocumentFlowContainerBaseFormFilterModalProps } from '../ui/document-flow-container-base-form-filter-modal';
import { SCHEME_ACTION_FILTER_FORM } from './scheme';
import { DocumentFlowFilterActionForm } from './type';

const DEFAULT_VAUE: DocumentFlowFilterActionForm = {
  ...DEFAULT_BASE_FORM_VAUE,
  creators: [],
};

export const DocumentActionFilterModal: FC<
  Omit<DocumentFlowContainerBaseFormFilterModalProps, 'onSubmit'> & {
    isLoading?: boolean;
    onSubmit: (form: DocumentFlowFilterActionForm) => void;
    initialValue: DocumentFlowFilterActionForm | undefined;
  }
> = ({ isOpen, onClose, initialValue, isLoading, onSubmit }) => {
  const skip = !isOpen;

  const { optionsEmployeeAll } = useGetDocumentFlowOptionsOptionsBaseFilterForm(
    {
      optionsEmployeeAll: skip,
    }
  );

  const methods = useForm<DocumentFlowFilterActionForm>({
    defaultValues: initialValue || DEFAULT_VAUE,
    resolver: yupResolver(SCHEME_ACTION_FILTER_FORM),
  });
  const { control, handleSubmit, reset } = methods;

  useLayoutEffect(() => {
    if (isOpen) {
      reset(initialValue);
    }
  }, [isOpen, initialValue]);

  return (
    <DocumentFlowBaseFormFilterModal.Container
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit, console.error)}
    >
      <AutocompliteWithController
        control={control}
        name={'creators'}
        multiple
        disabled={isLoading}
        loading={isLoading}
        options={optionsEmployeeAll || EMPT_ARR}
        textFieldProps={{ label: 'Инициатор' }}
        isOptionEqualToValue={isOptionEqualToValueById}
        getOptionLabel={(option) => option.employeeName}
        renderOption={renderOptionEmployeNameWithPosition}
      />
      <DocumentFlowBaseFormFilterModal.Provider {...methods}>
        <DocumentFlowBaseFormFilterModal.BaseInputs
          isLoading={isLoading}
          skip={skip}
        />
      </DocumentFlowBaseFormFilterModal.Provider>
    </DocumentFlowBaseFormFilterModal.Container>
  );
};
