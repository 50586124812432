// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Delete, RepeatRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { default as dayjs } from 'dayjs';
import { useStore, useStoreMap } from 'effector-react';
import { AlertErrorDocumentFormEdit } from 'features/document/document-form-edit/alert-error/AlertErrorDocumentFormEdit';
import { documentFormEditApi } from 'features/document/document-form-edit/api';
import { fetchConterpartyOptionsFx } from 'features/document/document-form-edit/counterparty/api';
import { DocumentFormEditCounterpartyContractLink } from 'features/document/document-form-edit/counterparty/DocumentFormEditCounterpartyContractLink';
import { DocumentFormEditCounterpartyContractNumber } from 'features/document/document-form-edit/counterparty/DocumentFormEditCounterPartyContractNumber';
import { DocumentFormEditCounterPartyReasonCode } from 'features/document/document-form-edit/counterparty/DocumentFormEditCounterPartyReasonCode';

import { DocumentFormEditCounterpartyTitle } from 'features/document/document-form-edit/counterparty/DocumentFormEditCounterPartyTitle';
import { DocumentFormEditAgreeChain } from 'features/document/document-form-edit/document-form-edit-agree-chain/DocumentFormEditAgreeChain';
import { DocumentFormEditTaxRatesAutocomplite } from 'features/document/document-form-edit/document-form-edit-tax-rates-autocomplite/DocumentFormEditTaxRatesAutocomplite';
import { DocumentFormEditListAdditionalyFieldsDec } from 'features/document/document-form-edit/DocumentFormEditAdditionalyDec';
import { DocumentFormEditListAdditionalyFieldsInt } from 'features/document/document-form-edit/DocumentFormEditAdditionalyInt';
import { DocumentFormEditListAdditionalyFieldPeriod } from 'features/document/document-form-edit/DocumentFormEditAdditionalyPeriod';
import { DocumentFormEditListAdditionalyFieldsString } from 'features/document/document-form-edit/DocumentFormEditAdditionalyString';
import { DocumentFormEditArticlesAutocomplite } from 'features/document/document-form-edit/DocumentFormEditArticlesAutocomplite';
import { DocumentFormEditOrgAutocomplite } from 'features/document/document-form-edit/DocumentFormEditOrgAutocomplite';

import { DocumentFormProcessPutDocument } from 'features/document/document-form-edit/DocumentFormProcessPutDocument';
import { DocumentFormEditErrorSecondValidation } from 'features/document/document-form-edit/form-edit-expenses/DocumentFormEditErrorSecondValidation';
import {
  DocumentFormEditExpenses,
  modalFormEditExpenses,
} from 'features/document/document-form-edit/form-edit-expenses/DocumentFormEditExpense';
import { ExpensesFormEditPeriodPicker } from 'features/document/document-form-edit/form-edit-expenses/DocumentFormEditPeriod';
import { ExpensesFormEditListArticles } from 'features/document/document-form-edit/form-edit-expenses/ExpensesFormEditListArticles';
import { useInitDocumentFormModelEdit } from 'features/document/document-form-edit/hook';
import { documentFormEdit } from 'features/document/document-form-edit/model';
import { fetchTypeReasonDocumentFx } from 'features/document/document-form-reason-type-autocomplite/api';
import { $optionsDocumentReasonType } from 'features/document/document-form-reason-type-autocomplite/model';
import { useNavigate, useParams } from 'react-router-dom';

import { theme } from 'shared/theme/theme';

import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';
import { InputFileUi } from 'shared/ui/file-input-ui-DEPRECETED/FileInputUi';
import { DateFildAdaptive, TextFieldAdaptive } from 'shared/ui/ui';
import { UiDocumentFormFiles } from 'shared/ui/UiDocumentFormFiles';
import { UiDocumentFormReasonDate } from 'shared/ui/UiDocumentFormReasonDate';
import { UiDocumentFormReasonNumber } from 'shared/ui/UiDocumentFormReasonNumber';
import { UiDocumentFormReasonTypeAutocomplite } from 'shared/ui/UiDocumentFormReasonTypeAutocomplite';

import { ExpensesForm } from 'entities/document-form';

import {
  getOptionLabelServiceType,
  isOptionEqualToValueServiceType,
  renderOptionServiceType,
} from 'entities/document/inputs/service-types-autocomplite';
import {
  DocumentFormCloseTaxRatesAutocomplite,
  fetchCloseTaxRatesEditOptionsFx,
} from 'features/document/document-form-edit/document-form-close-tax-rates-autocomplite/DocumentFormTaxRatesAutocomplite';
import { DocumentFormTotalSum } from 'features/document/document-form-total-sum/DocumentFormTotalSum';
import { useGetDocumentServiceTypesQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';

import { DocumentFormEditCounterpartyTaxNumber } from 'features/document/document-form-edit/counterparty/DocumentFormEditCounterpartyTaxNumber';
import { DocumentFormEditPayBeforDate } from 'features/document/document-form-edit/DocumentFormEditPayBeforDate';
import { useEffect } from 'react';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import {
  CustomAutocomplete,
  CustomDatePicker,
  CustomTextField,
} from 'shared/ui/base-ui';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import {
  ListArticlesExpenseCardUi,
  prepareDocumentArticlesModelToArticlesView,
} from 'widgets/document-create/expenses-for-articles-viwe';
import { EMPT_ARR } from 'widgets/training/filter/constants';

export const DocumentEdit = () => {
  const { id } = useParams();
  const loading = useStore(documentFormEditApi.getDocument.pending);
  const navigate = useNavigate();
  const processId =
    documentFormEdit.formSelects.useSelectProcessValueOfKey('id');
  const documentTitle =
    documentFormEdit.formSelects.useSelectValueOfKey('title');
  const documentStatus =
    documentFormEdit.formSelects.useSelectValueOfKey('documentStatus');
  const creator = documentFormEdit.formSelects.useSelectValueOfKey('creator');
  const dateCreated =
    documentFormEdit.formSelects.useSelectValueOfKey('dateCreated');
  const datePaid = documentFormEdit.formSelects.useSelectValueOfKey('datePaid');
  const dateCompleted =
    documentFormEdit.formSelects.useSelectValueOfKey('dateCompleted');
  const isCounterpartyRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isCounterpartyRequired'
    );
  const isTransferToAccountablePerson =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isTransferToAccountablePerson'
    );
  const isAccountantPayment =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isAccountantPayment'
    );

  const isServiceTypeRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isServiceTypeRequired'
    );

  const { data: optionsServiseType, isLoading: isLoadingServiceType } =
    useGetDocumentServiceTypesQuery(undefined, {
      skip: !isServiceTypeRequired,
    });

  const serviceType =
    documentFormEdit.formSelects.useSelectValueOfKey('serviceType');

  const canEdit = documentFormEdit.formSelects.useSelectValueOfKey('canEdit');

  useEffect(() => {
    /* получение доп полей для процесса */
    if (processId) documentFormEditApi.getAdditionallyFields(processId);
  }, [processId]);

  useEffect(() => {
    fetchConterpartyOptionsFx();
  }, []);
  useInitDocumentFormModelEdit(Number(id));

  if (loading)
    return (
      <Grid
        container
        height={'80vh'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <CircularProgress />
      </Grid>
    );

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h1'
          fontWeight={700}
          fontSize={[18, 20]}
          width='100%'
          marginBottom={[1, 5]}
        >
          {documentTitle ? documentTitle : '-'}
        </Typography>
      </LayoutPage.Bar>

      <Grid
        paddingX={[0, 2]}
        flexDirection={'column'}
      >
        <Grid
          item
          xs={12}
          lg={7}
          flexDirection={'column'}
          gap={2}
          display={['block', 'flex']}
          paddingX={[0, 2]}
          height={'100%'}
        >
          <TextFieldAdaptive
            title='Текущий статус'
            body={documentStatus ? documentStatus.title || '' : ''}
          />
          <TextFieldAdaptive
            title='Номер документа'
            body={String(id)}
          />
          <TextFieldAdaptive
            title='Отправитель'
            body={creator ? creator.employeeName || '' : ''}
          />

          <DateFildAdaptive
            title='Дата создания'
            date={dateCreated || undefined}
          />
          <DateFildAdaptive
            title='Дата оплаты'
            date={datePaid || undefined}
          />
          <DateFildAdaptive
            title='Дата завершения'
            date={dateCompleted || undefined}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={'space-between'}
        component={'form'}
        padding={[0, 2]}
        onSubmit={(e: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
          e.preventDefault();

          const isSend =
            e.nativeEvent.submitter &&
            e.nativeEvent.submitter!.id === 'sendDoAction';

          documentFormEdit.putDocument(Boolean(isSend));
        }}
      >
        <DocumentSectionTitle
          text={'Информация о документе'}
          sx={{ marginTop: [2, 4] }}
        />
        <DocumentFormEditOrgAutocomplite />
        <DocumentFormEditArticlesAutocomplite />
        <SectionArticlesExpenses />
        <DocumentFormEditExpenses>
          <ExpensesForm
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              documentFormEdit.onSecondValidation('sd');
            }}
            error={<DocumentFormEditErrorSecondValidation />}
            bottomActions={[
              <Button
                size='large'
                variant='contained'
                type='submit'
                color={'secondary'}
                key={'saveButton'}
              >
                Сохранить
              </Button>,
              <Button
                size='large'
                variant='contained'
                color={'customGrey'}
                key={'buttonClose'}
                onClick={() => {
                  modalFormEditExpenses.events.close();
                  documentFormEdit.api.removeArticleidExpenseZero();
                }}
              >
                Закрыть
              </Button>,
            ]}
          >
            <ExpensesFormEditPeriodPicker />
            <ExpensesFormEditListArticles />
          </ExpensesForm>
        </DocumentFormEditExpenses>
        <SectionDopInformation />
        {isCounterpartyRequired && !isTransferToAccountablePerson && (
          <Grid
            container
            justifyContent={'space-between'}
            gap={2}
          >
            <DocumentSectionTitle
              text={'Контрагент'}
              sx={{ marginTop: [2, 4] }}
            />
            <DocumentFormEditCounterpartyTaxNumber />
            <DocumentFormEditCounterPartyReasonCode />
            <DocumentFormEditCounterpartyTitle />
            <DocumentFormEditCounterpartyContractNumber />
            <DocumentFormEditCounterpartyContractLink />
          </Grid>
        )}
        {/* Документ - основание' */}
        <Grid
          container
          gap={3}
          justifyContent={'space-between'}
        >
          <DocumentSectionTitle
            text={'Документ - основание'}
            sx={{ marginTop: [2, 4] }}
          />
          <DocumentFormEditReasonTypeAutocomplite />
          <Grid
            width={['100%', '45%']}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <DocumentFormEditReasonNumber />
            <DocumentFormEditReasonDate />
          </Grid>
          <Grid container>
            <DocumentFormEditReasonFiles />
          </Grid>
          {isAccountantPayment && <DocumentFormEditPayBeforDate />}
          {isServiceTypeRequired && (
            <CustomAutocomplete
              sx={{
                flexBasis: ['100%', '45%'],
              }}
              fullWidth
              loading={isLoadingServiceType}
              options={optionsServiseType || EMPT_ARR}
              textFieldProps={{ label: 'Вид услуги' }}
              value={serviceType}
              renderOption={renderOptionServiceType}
              isOptionEqualToValue={isOptionEqualToValueServiceType}
              getOptionLabel={getOptionLabelServiceType}
              onChange={(_, value) => {
                documentFormEdit.api.setFiled({
                  key: 'serviceType',
                  value: value ? value : null,
                });
              }}
            />
          )}
          <DocumentFormEditTotalSum />
          <DocumentFormEditTaxRatesAutocomplite />
        </Grid>
        {/*Закрывающий документ */}
        <DocumentSectionTitle
          text={'Закрывающий документ'}
          sx={{ marginTop: [2, 4] }}
        />
        <DocumentFormEditCloseFiles />

        <DocumentSectionTitle
          text={'Цепочка согласования'}
          sx={{ marginTop: [2, 4] }}
        />
        <DocumentFormEditAgreeChain />
        <DocumentFormProcessPutDocument />
        <AlertErrorDocumentFormEdit />
        <Grid
          container
          marginTop={15}
        />
        <ContainerActionsForm>
          <Button
            variant='contained'
            color='secondary'
            size='large'
            type='submit'
            id='sendDoAction'
            disabled={!canEdit}
          >
            Отправить
          </Button>
          <Button
            variant='contained'
            size='large'
            color='customBlue'
            id='notSendDoAction'
            type='submit'
            disabled={!canEdit}
          >
            Сохранить
          </Button>

          <Button
            variant='contained'
            color='customGrey'
            size='large'
            onClick={() => navigate('/document-flow/created-document')}
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </LayoutPage.Page>
  );
};

const ButtonChangeArticles = () => {
  const articles =
    documentFormEdit.formSelects.useSelectValueOfKey('documentArticles');
  const firstdValid = useStore(documentFormEdit.firstdValid);
  const secondValid = useStore(documentFormEdit.secondValid);
  const isCanEdit =
    documentFormEdit.formSelects.useSelectValueOfKey('canEditArticles');

  if (firstdValid && secondValid && articles && articles.length > 0) {
    return (
      <Button
        variant='contained'
        color='secondary'
        size='large'
        disabled={!isCanEdit}
        sx={{ margin: '0 auto', marginTop: 2 }}
        onClick={() => modalFormEditExpenses.events.open()}
      >
        Редактировать
      </Button>
    );
  }

  return (
    <Button
      disabled={!articles || articles.length === 0 || !isCanEdit}
      variant='contained'
      color='secondary'
      size='large'
      sx={{ margin: '0 auto' }}
      onClick={() => modalFormEditExpenses.events.open()}
    >
      Заполнить
    </Button>
  );
};

const SectionArticlesExpenses = () => {
  const loadingFirstValid = useStore(
    documentFormEditApi.initialFirstValidation.pending
  );
  const loadingSecondValid = useStore(
    documentFormEditApi.initialSecondaryValidation.pending
  );
  const articles =
    documentFormEdit.formSelects.useSelectValueOfKey('documentArticles');

  if (loadingFirstValid || loadingSecondValid) {
    return (
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        height='300px'
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      flexDirection={'column'}
      marginTop={[2, 4]}
    >
      <ListArticlesExpenseCardUi
        articles={prepareDocumentArticlesModelToArticlesView(articles)}
        isDetailsVariant={false}
      />
      <ButtonChangeArticles />
    </Grid>
  );
};
const DocumentFormEditReasonTypeAutocomplite = () => {
  useEffect(() => {
    fetchTypeReasonDocumentFx();
  }, []);

  const options = useStore($optionsDocumentReasonType);
  const isReasonDocumentRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isReasonDocumentRequired'
    );
  const value =
    documentFormEdit.formSelects.useSelectValueOfKey('reasonDocumentType');
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditReasonDocument'
  );

  if (!isReasonDocumentRequired) return null;

  return (
    <UiDocumentFormReasonTypeAutocomplite
      disabled={!isReasonDocumentRequired || !isCanEdit}
      onChange={(event, value) => {
        documentFormEdit.api.setFiled({
          key: 'reasonDocumentType',
          value: value ? value : null,
        });
      }}
      value={value}
      options={options}
    />
  );
};

const SectionDopInformation = () => {
  const isManagerAgree =
    documentFormEdit.formSelects.useSelectProcessValueOfKey('isManagerAgree');
  const isArrayAdditionalFields = useStoreMap(
    documentFormEdit.form,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.stringAdditionallyFields &&
        processAdditionalFields.stringAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );
  const isArrayAdditionalPeriod = useStoreMap(
    documentFormEdit.form,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.periodAdditionallyFields &&
        processAdditionalFields.periodAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );
  const isArrayAdditionalDate = useStoreMap(
    documentFormEdit.form,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.dateTimeAdditionallyFields &&
        processAdditionalFields.dateTimeAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );
  const isArrayAdditionalInt = useStoreMap(
    documentFormEdit.form,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.intAdditionallyFields &&
        processAdditionalFields.intAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );
  const isArrayAdditionalDec = useStoreMap(
    documentFormEdit.form,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.decimalAdditionallyFields &&
        processAdditionalFields.decimalAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );

  if (
    !isManagerAgree &&
    !isArrayAdditionalFields &&
    !isArrayAdditionalDate &&
    !isArrayAdditionalPeriod &&
    !isArrayAdditionalInt &&
    !isArrayAdditionalDec
  ) {
    return null;
  }

  return (
    <Grid
      container
      justifyContent={'space-between'}
      gap={2}
      sx={{ marginTop: [2, 4] }}
    >
      <DocumentSectionTitle text={'Дополнительная информация'} />
      <DocumentFormEditListAdditionalyFieldsString />
      <DocumentFormEditListAdditionalyFieldsInt />
      <DocumentFormEditListAdditionalyFieldsDec />
      <DocumentFormEditListAdditionalyFieldPeriod />
    </Grid>
  );
};
const DocumentFormEditReasonNumber = () => {
  const value = documentFormEdit.formSelects.useSelectValueOfKey(
    'reasonDocumentNumber'
  );
  const isReasonDocumentRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isReasonDocumentRequired'
    );
  const reasonDocumentTypeId =
    documentFormEdit.formSelects.useSelectValueOfKey('reasonDocumentType');
  const required =
    reasonDocumentTypeId && reasonDocumentTypeId.isOtherInformationRequired;
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditCounterparty'
  );

  if (!isReasonDocumentRequired) return null;

  return (
    <UiDocumentFormReasonNumber
      value={value || undefined}
      required={Boolean(required)}
      onChange={(event) => {
        documentFormEdit.api.setFiled({
          key: 'reasonDocumentNumber',
          value: event.target.value,
        });
      }}
      disabled={!isReasonDocumentRequired || !isCanEdit}
    />
  );
};

const DocumentFormEditReasonDate = () => {
  const value =
    documentFormEdit.formSelects.useSelectValueOfKey('reasonDocumentDate');
  const isReasonDocumentRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isReasonDocumentRequired'
    );
  const reasonDocumentTypeId =
    documentFormEdit.formSelects.useSelectValueOfKey('reasonDocumentType');
  const required =
    reasonDocumentTypeId && reasonDocumentTypeId.isOtherInformationRequired;
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditCounterparty'
  );

  if (!isReasonDocumentRequired) return null;

  return (
    <UiDocumentFormReasonDate
      required={Boolean(required)}
      disabled={!isReasonDocumentRequired || !isCanEdit}
      value={value || undefined}
      onChange={(value) =>
        documentFormEdit.api.setFiled({
          key: 'reasonDocumentDate',
          value: dayjs(value).format('YYYY-MM-DDTHH:mm:ss') || '',
        })
      }
    />
  );
};
const DocumentFormEditTotalSum = () => {
  const value = useStoreMap(documentFormEdit.secondValid, (secondValid) =>
    secondValid ? secondValid.totalSum || '' : ''
  );

  return (
    <CustomTextField
      value={value}
      disabled
      required
      containerSx={{
        flexBasis: ['100%', '45%'],
      }}
      label='Общая сумма'
    />
  );
};

const DocumentFormEditReasonFiles = () => {
  const documentReasonInDocument =
    documentFormEdit.formSelects.useSelectValueOfKey('reasonDocumentFiles');
  const deletedFiles = useStore(documentFormEdit.deleteReasonFiles);
  const saveFiles = useStore(documentFormEdit.filesReason);
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditCounterparty'
  );

  return (
    <UiDocumentFormFiles
      Input={
        <InputFileUi
          disabled={!isCanEdit}
          onAddFile={documentFormEdit.ApiFilesReason.addFile}
          id={'fileReasonEditDocument'}
        />
      }
      List={
        <>
          {documentReasonInDocument &&
            documentReasonInDocument.map((file) => {
              const someInDeleteStore = deletedFiles.some(
                (deleteFile) => deleteFile === file.id
              );

              return (
                <Typography
                  sx={{
                    display: 'flex',
                    flexBasis: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: someInDeleteStore
                      ? theme.palette.customGrey.dark
                      : 'black',
                    textDecoration: someInDeleteStore ? 'line-through' : 'none',
                  }}
                  key={(file.fileTitle || '') + file.id}
                >
                  {file.fileTitle || 'no_name_back'}
                  {!someInDeleteStore ? (
                    <IconButton
                      disabled={!isCanEdit}
                      color='error'
                      onClick={() =>
                        documentFormEdit.addedFileToDeleteStore(file.id)
                      }
                    >
                      <Delete />
                    </IconButton>
                  ) : (
                    <IconButton
                      disabled={!isCanEdit}
                      onClick={() =>
                        documentFormEdit.removeFilesFromDeleteStore(file.id)
                      }
                    >
                      <RepeatRounded />
                    </IconButton>
                  )}
                </Typography>
              );
            })}
          {saveFiles.map((file) => {
            return (
              <Typography
                sx={{
                  display: 'flex',
                  flexBasis: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                key={file.name + file.size}
              >
                {file.name}
                <IconButton
                  color='error'
                  onClick={() =>
                    documentFormEdit.ApiFilesReason.deleteFile(file)
                  }
                >
                  <Delete />
                </IconButton>
              </Typography>
            );
          })}
          {(!documentReasonInDocument ||
            (documentReasonInDocument.length === 0 &&
              saveFiles.length === 0)) && (
            <Typography sx={{ display: ['none', 'flex'] }}>
              Файлов нет
            </Typography>
          )}
        </>
      }
    />
  );
};
const DocumentFormEditCloseFiles = () => {
  const documentCloseFilesInDocument =
    documentFormEdit.formSelects.useSelectValueOfKey('closeDocumentFiles');
  const deletedFiles = useStore(documentFormEdit.deleteCloseFiles);
  const saveFiles = useStore(documentFormEdit.filesClose);
  const link = useStoreMap(documentFormEdit.form, (state) =>
    state && state.closeDocumentDiadokLink ? state.closeDocumentDiadokLink : ''
  );
  const canEdit = documentFormEdit.formSelects.useSelectValueOfKey('canEdit');
  const checked = useStoreMap(documentFormEdit.form, (state) => {
    if (state && state.isCloseDocumentDiadok) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (checked) {
      documentFormEdit.resetFileClose();
    } else {
      documentFormEdit.api.setFiled({
        key: 'closeDocumentDiadokLink',
        value: undefined,
      });
    }
  }, [checked]);

  const closeDocumentDate =
    documentFormEdit.formSelects.useSelectValueOfKey('closeDocumentDate');

  /* close doc summ */
  const isCloseDocumentSumEquals =
    documentFormEdit.formSelects.useSelectValueOfKey(
      'isCloseDocumentSumEquals'
    );
  const closeDocumentSum =
    documentFormEdit.formSelects.useSelectValueOfKey('closeDocumentSum');
  const totalSum = documentFormEdit.formSelects.useSelectValueOfKey('totalSum');

  const closeDocumentSumValue = isCloseDocumentSumEquals
    ? totalSum
    : closeDocumentSum;

  const closeDocumentNumber = documentFormEdit.formSelects.useSelectValueOfKey(
    'closeDocumentNumber'
  );

  return (
    <Grid
      display={'flex'}
      flexDirection={['column', 'row']}
      flexBasis={'100%'}
      flexWrap={['nowrap', 'wrap']}
      paddingTop={2}
      justifyContent={'space-between'}
      gap={2}
    >
      <FormControlLabel
        sx={{ padding: '0px 8px', flexBasis: ['100%', '45%'], order: [1] }}
        control={
          <Switch
            checked={checked}
            disabled={!canEdit}
            onChange={(e) => {
              documentFormEdit.api.setFiled({
                key: 'isCloseDocumentDiadok',
                value: e.target.checked,
              });
            }}
          />
        }
        labelPlacement='end'
        label='Закрывающий документ в ЭДО'
      />
      <Grid
        display={'flex'}
        flexBasis={['100%', '45%']}
        justifyContent={'space-between'}
        order={[3, 2]}
        flexWrap={'wrap'}
      >
        <CustomTextField
          label={'№ документа'}
          value={closeDocumentNumber || null}
          containerSx={{ flexBasis: ['100%', '40%'] }}
          onChange={(e) => {
            documentFormEdit.api.setFiled({
              key: 'closeDocumentNumber',
              value: e.target.value,
            });
          }}
        />
        <CustomDatePicker
          label={'Дата документа'}
          containerSx={{ flexBasis: ['100%', '40%'] }}
          value={prepareDateToDateVal(closeDocumentDate)}
          sx={{ width: '100%' }}
          onChange={(value) => {
            documentFormEdit.api.setFiled({
              key: 'closeDocumentDate',
              value: dayjs(value).format(DATE_FORMATS.server),
            });
          }}
        />
      </Grid>
      {!checked && (
        <Box sx={{ flexBasis: ['100%', '45%'], order: [2, 3] }}>
          <UiDocumentFormFiles
            Input={
              <InputFileUi
                onAddFile={documentFormEdit.ApiFilesClose.addFile}
                disabled={!canEdit}
                id={'fileCloseEditDocument'}
              />
            }
            List={
              <>
                {documentCloseFilesInDocument &&
                  documentCloseFilesInDocument.map((file) => {
                    const someInDeleteStore = deletedFiles.some(
                      (deleteFile) => deleteFile === file.id
                    );

                    return (
                      <Typography
                        sx={{
                          display: 'flex',
                          flexBasis: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          color: someInDeleteStore
                            ? theme.palette.customGrey.dark
                            : 'black',
                          textDecoration: someInDeleteStore
                            ? 'line-through'
                            : 'none',
                        }}
                        key={(file.fileTitle || '') + file.id}
                      >
                        {file.fileTitle || 'no_name_back'}
                        {!someInDeleteStore ? (
                          <IconButton
                            disabled={!canEdit}
                            color='error'
                            onClick={() =>
                              documentFormEdit.addedFileToDeleteCloseStore(
                                file.id
                              )
                            }
                          >
                            <Delete />
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={!canEdit}
                            onClick={() =>
                              documentFormEdit.removeFilesFromDeletCloseStore(
                                file.id
                              )
                            }
                          >
                            <RepeatRounded />
                          </IconButton>
                        )}
                      </Typography>
                    );
                  })}
                {saveFiles.map((file) => {
                  return (
                    <Typography
                      sx={{
                        display: 'flex',
                        flexBasis: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      key={file.name + file.size}
                    >
                      {file.name}
                      <IconButton
                        color='error'
                        onClick={() =>
                          documentFormEdit.ApiFilesClose.deleteFile(file)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Typography>
                  );
                })}
                {(!documentCloseFilesInDocument ||
                  (documentCloseFilesInDocument.length === 0 &&
                    saveFiles.length === 0)) && (
                  <Typography sx={{ display: ['none', 'flex'] }}>
                    Файлов нет
                  </Typography>
                )}
              </>
            }
          />
        </Box>
      )}
      {checked && (
        <CustomTextField
          placeholder='Ссылка'
          containerSx={{ flexBasis: ['100%', '45%'], order: [2, 3] }}
          value={link || ''}
          onChange={(e) => {
            documentFormEdit.api.setFiled({
              key: 'closeDocumentDiadokLink',
              value: e.target.value,
            });
          }}
          sx={{ marginTop: [1, 0], marginBottom: [1, 0] }}
          label='Ссылка'
        />
      )}

      <FormControlLabel
        sx={{ padding: '0px 8px', flexBasis: ['100%'], order: [4] }}
        checked={Boolean(isCloseDocumentSumEquals)}
        label='Соответствует документу'
        onChange={(e, checked) =>
          documentFormEdit.api.setFiled({
            key: 'isCloseDocumentSumEquals',
            value: checked,
          })
        }
        control={<Switch />}
        labelPlacement='end'
      />
      {isCloseDocumentSumEquals ? (
        <Box sx={{ flexBasis: ['100%', '45%'], order: [5] }}>
          <DocumentFormTotalSum />
        </Box>
      ) : (
        <CustomTextField
          value={closeDocumentSumValue}
          label={'Общая сумма'}
          onBlur={() => {
            closeDocumentSum &&
              fetchCloseTaxRatesEditOptionsFx({ Sum: closeDocumentSum });
          }}
          containerSx={{ flexBasis: ['100%', '45%'], order: [5] }}
          disabled={Boolean(isCloseDocumentSumEquals)}
          type='number'
          onChange={(e) => {
            documentFormEdit.api.setFiled({
              key: 'closeDocumentSum',
              value: e.target.value,
            });
          }}
        />
      )}

      <Grid
        flexBasis={['100%', '45%']}
        sx={{ order: [6] }}
      >
        <DocumentFormCloseTaxRatesAutocomplite />
      </Grid>
    </Grid>
  );
};

export const prepareDateToDateVal = (date: string | undefined | null) => {
  if (!date) return null;

  return dayjs(date);
};
