import { Grid } from '@mui/material';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

import { SectionForMainPage } from 'shared/ui/section-for-main-page';
import { SectionMainPageRight } from 'shared/ui/section-main-page-right';

import { PhotoFaceList } from 'widgets/main/photo-face-list';
import { SiteGuide } from 'widgets/main/site-guide';

import { StackSocialLinks } from 'widgets/main/stack-social-links';
import { StackUsefulLinks } from 'widgets/main/stack-useful-links';

export const Main = () => {
  return (
    <LayoutPage.Page>
      <LayoutPage.Content>
        <Grid
          gap={3}
          container
          flexDirection={'column'}
        >
          <SectionForMainPage title='Путеводитель по сайту'>
            <SiteGuide />
          </SectionForMainPage>

          <SectionForMainPage title='Гармония здоровья в лицах'>
            <PhotoFaceList />
          </SectionForMainPage>

          <SectionForMainPage title='Конкурс "Лучший по профессии 2023"'>
            <Grid
              sx={{
                '& > iframe': {
                  height: {
                    xs: 400,
                    sm: 400,
                    md: 700,
                    lg: 700,
                    xl: 700,
                  },
                },
              }}
            >
              <iframe
                width='100%'
                src='https://www.youtube.com/embed/bju_ODuRXNk?si=cJnKW5a_6C9hr89S'
                title='Конкурс "Лучший по профессии 2023"'
                frameBorder='0'
                style={{
                  borderRadius: '10px',
                }}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
            </Grid>
          </SectionForMainPage>

          <Grid
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
              gap: 2,
              '& > *': {
                flexBasis: '50%',
              },
            }}
          >
            <SectionMainPageRight title='Полезные ссылки'>
              <StackUsefulLinks />
            </SectionMainPageRight>
            <SectionMainPageRight title='Мы в социальных сетях'>
              <StackSocialLinks />
            </SectionMainPageRight>
          </Grid>
        </Grid>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};

// TODO :  Время в Гармонии здоровья - Удалить?
{
  /*  <SectionForMainPage title='Время в Гармонии здоровья'>
            <StackMyClock />
          </SectionForMainPage> */
}
