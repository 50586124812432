import { APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS } from 'entities/apteka-legko/banners/sliders/links';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useCreateAptekaLegkoBannersSliderMutation } from 'shared/api/service-apteka-legko-banners/rtk/aptekaLegkoBannersSliderProviderApi';
import { SliderFormValue } from '../types';

export const useCreatedBannerSlider = () => {
  const [createSlider, { isLoading, error }] =
    useCreateAptekaLegkoBannersSliderMutation();
  const navigate = useNavigate();

  useShowFetchErrorInSnackbar(error);

  const handleCreateBannerSlider = useCallback(
    async ({
      imageDesktopData,
      imageMobileData,
      dateFinish,
      dateStart,
      erid,
      organization,
      link,
    }: SliderFormValue) => {
      if (!imageMobileData || !imageDesktopData || !dateStart || !dateFinish) {
        return null;
      }

      const response = await createSlider({
        slider: {
          imageMobile: imageMobileData?.data,
          imageSite: imageDesktopData?.data,
          periodStart: dateStart?.format(),
          periodEnd: dateFinish?.format(),
          url: link,
          erid: erid,
          organizationTitle: organization,
        },
      });

      if ('data' in response) {
        navigate(APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS.list);
      }
    },
    [createSlider, navigate]
  );

  return { handleCreateBannerSlider, isLoading };
};
