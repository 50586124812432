import { CreateBoardDialog } from 'features/boards/dialogs/CreateBoardDialog/CreateBoardDialog';
import { CreateBoardFormFields } from 'features/boards/forms/CreateBoardForm';
import { FC } from 'react';
import { useCreateBoardMutation } from 'shared/api/services/rtk/boardApi';
import { CreateBoardModalProps } from './type';

export const CreateBoardModal: FC<CreateBoardModalProps> = ({
  isOpen,
  onClose,
  onCreate,
  initValue,
}) => {
  const [createBoard, isCreatingBoard] = useCreateBoardMutation();

  const handleSubmit = (form: CreateBoardFormFields) => {
    createBoard({
      title: form.title,
      isArchive: false,
    }).then((ans) => {
      if ('data' in ans) {
        onCreate(ans.data);
      }
    });
  };

  return (
    <CreateBoardDialog
      isOpen={isOpen}
      onClose={onClose}
      initValue={initValue}
      onSubmit={handleSubmit}
      isLoading={isCreatingBoard.isLoading}
    />
  );
};
