import { FC, useMemo } from 'react';

import { UiExpensesFormPriceExpens } from 'shared/ui/article/UiExpensesFormPriceExpens';

import { documentFormModel } from 'entities/document-form';

import { textNormSumArticle } from '../expenses-form-total-sum-article/UiTotalSumArticle';

export const ExpensesFormPriceExpens: FC<{
  indexExpenses: number;
  idArticle: number;
}> = ({ indexExpenses, idArticle }) => {
  const sum = documentFormModel.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'sum'
  );
  const extendedConditionId = documentFormModel.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'extendedConditionId'
  );
  const count = documentFormModel.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'count'
  );
  const expenseId = documentFormModel.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'expenseId'
  );
  const expenses = documentFormModel.useSelectorArticleIdFirstValid(
    idArticle,
    'expenses'
  );
  const normSumArticle = documentFormModel.useSelectorArticleIdFirstValid(
    idArticle,
    'normSum'
  );
  const normSumExpense = expenses?.find((item) => item.id === expenseId)
    ?.normSum;
  const normSumExtendConditions = expenses
    ?.find((item) => item.id === expenseId)
    ?.extendedConditions?.find((item) => item.id === extendedConditionId)
    ?.normSum;

  const normSum = normSumExtendConditions || normSumExpense || normSumArticle;
  const notValid = useMemo(() => {
    if (!count || !sum || !normSum) return false;

    return Boolean(normSum * count < sum);
  }, [normSum, sum, count]);

  return (
    <UiExpensesFormPriceExpens
      value={sum}
      onChange={(event) => {
        documentFormModel.formEvent.setFieldExpense({
          index: indexExpenses,
          id: idArticle,
          fild: { key: 'sum', value: Number(event.target.value) },
        });
      }}
      error={notValid}
      helperText={textNormSumArticle(normSum)}
    />
  );
};
