import { ConvertMapWithType, ConvertTypes } from 'shared/url-helper/type';
import {
  DocumentFilterBaseQueryParams,
  DocumentFlowFilterBaseForm,
} from './types';

export const CONVERT_DOCUMENT_BASE_LIST_FILTER_MAP: ConvertMapWithType<DocumentFilterBaseQueryParams> =
  [
    { key: 'CurrentPage', type: ConvertTypes.number },
    { key: 'PageSize', type: ConvertTypes.number },
    { key: 'SearchText', type: ConvertTypes.string },
    { key: 'StatusIdFilters', type: ConvertTypes.masNumber },
    { key: 'ProcessIdFilters', type: ConvertTypes.masNumber },
    { key: 'OrganizationIdFilters', type: ConvertTypes.masNumber },
    { key: 'ArticleIdFilters', type: ConvertTypes.masNumber },
    { key: 'CounterpartyIdFilters', type: ConvertTypes.masNumber },
    { key: 'StartDateCreatedFilter', type: ConvertTypes.string },
    { key: 'FinishDateCreatedFilter', type: ConvertTypes.string },
    { key: 'TotalSumFrom', type: ConvertTypes.number },
    { key: 'TotalSumTo', type: ConvertTypes.number },
  ];

export const DEFAULT_BASE_FORM_VAUE: DocumentFlowFilterBaseForm = {
  statuses: [],
  organizations: [],
  articles: [],
  contractors: [],
  documentTypes: [],
  dateFrom: null,
  dateTo: null,
  sumFrom: '',
  sumTo: '',
};
