import { Box, Grid } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import { useMemo } from 'react';

import { isEqDay } from 'shared/date-helper/isEqDay';
import { theme } from 'shared/theme/theme';

import { VisitCalendarDayProps } from './type';

export const VisitCalendarDay = ({
  visits,
  ...props
}: VisitCalendarDayProps) => {
  const visitsOfSelectedDay = useMemo(() => {
    return visits?.filter((visit) => isEqDay(props.day, visit.visitingDate));
  }, [props.day, visits]);

  const hasMy = visitsOfSelectedDay?.find((visit) => visit.isCurrentEmployee);
  const hasOther = visitsOfSelectedDay?.find(
    (visit) => !visit.isCurrentEmployee
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid
        width={40}
        height={40}
        container
        alignItems={'center'}
        justifyContent={'center'}
      >
        <PickersDay
          {...props}
          outsideCurrentMonth={props.outsideCurrentMonth}
          day={props.day}
          sx={{
            width: 30,
            height: 30,
          }}
        />
      </Grid>

      <Grid
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '4px',
        }}
      >
        {hasMy && (
          <Box
            width={10}
            height={10}
            borderRadius={'50%'}
            sx={{
              border: '2px solid white',
              backgroundColor: theme.palette.primary.main,
            }}
          />
        )}

        {hasOther && (
          <Box
            width={10}
            height={10}
            borderRadius={'50%'}
            sx={{
              border: '2px solid white',
              backgroundColor: theme.palette.grey[700],
            }}
          />
        )}
      </Grid>
    </Box>
  );
};
