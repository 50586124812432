import { useState } from 'react';

import { postPlanningsDelete } from 'shared/api/visit/services';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useDeletePlanningRN = (
  afterDelete?: (planningId: number) => void
) => {
  const { open: openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const deletePlanning = async (planningId: number) => {
    setIsLoading(true);
    try {
      await postPlanningsDelete({ visitingPlanningsIds: [planningId] });
      afterDelete && afterDelete(planningId);
    } catch (e) {
      console.error('useDeletePlanningRN.deletePlanning >> ', e);
      setIsLoading(false);

      const error = e as { message: string };

      openSnackbar({
        duration: 6000,
        type: 'error',
        text: error.message,
      });
    }
    setIsLoading(false);
  };

  return { deletePlanning, isLoading };
};
