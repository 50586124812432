import { Button, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  useGetOpeningTemplatesProtocolQuery,
  useGetOpeningTemplatesQuery,
  useGetOpeningTemplatesStagesQuery,
} from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import {
  AutocompliteWithController,
  DatePikerWithController,
} from 'shared/fields-with-contoller';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { DEFAULT_VALUE } from './constants';
import {
  getOptionLabelProtocol,
  getOptionLabelStage,
  getOptionLabelTemplate,
  renderOptionProtocol,
  renderOptionStage,
  renderOptionTemplates,
} from './libs/helper-render-autocomplite';
import { SCHEME_RUN_OPENING_PROCESS } from './scheme';
import {
  DialogFormRunOpeningRetailProcessProps,
  FormRunOpeningRetailProcessValue,
} from './types';

export const DialogFormRunOpeningRetailProcess: FC<
  DialogFormRunOpeningRetailProcessProps
> = ({ isOpen, onClose, initialValue, onSubmite }) => {
  const { control, handleSubmit, reset, setValue, trigger } =
    useForm<FormRunOpeningRetailProcessValue>({
      defaultValues: initialValue || DEFAULT_VALUE,
      resolver: yupResolver(SCHEME_RUN_OPENING_PROCESS),
    });

  const templateValue = useWatch({ control, name: 'template' });
  const protocolValue = useWatch({ control, name: 'protocol' });
  const {
    data: templates,
    isFetching: isFetchingTemplates,
    isLoading: isLoadingTemplates,
  } = useGetOpeningTemplatesQuery({ Page: 1, Limit: 9999 }, { skip: !isOpen });

  const {
    data: protocols,
    isFetching: isFetchingProtocol,
    isLoading: isLoadingProtocol,
  } = useGetOpeningTemplatesProtocolQuery(
    { params: { OpeningTemplateGuid: templateValue?.guid } },
    { skip: !templateValue }
  );

  const {
    data: stages,
    isLoading: isLoadingStages,
    isFetching: isFetchingStages,
  } = useGetOpeningTemplatesStagesQuery(
    {
      templateGuid: templateValue?.guid as string,
    },
    {
      skip: !templateValue,
    }
  );

  useEffect(() => {
    setValue('protocol', null);
    setValue('skipStages', []);
  }, [templateValue]);

  useEffect(() => {
    if (!protocolValue) {
      setValue('date', null);
    } else if (protocolValue.countdownDate) {
      trigger();
      setValue('date', dayjs(protocolValue.countdownDate));
    }
  }, [protocolValue]);

  useEffect(() => {
    !isOpen && reset(DEFAULT_VALUE);
  }, [isOpen]);

  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Запустить процесс' />}
    >
      <Grid
        container
        flexDirection={'column'}
        component={'form'}
        onSubmit={handleSubmit(onSubmite)}
        gap={2}
      >
        {/* template */}
        <AutocompliteWithController
          loading={isLoadingTemplates || isFetchingTemplates}
          disabled={isLoadingTemplates || isFetchingTemplates}
          control={control}
          name={'template'}
          isOptionEqualToValue={(option, value) => option.guid === value.guid}
          renderOption={renderOptionTemplates}
          freeSolo={false}
          getOptionLabel={getOptionLabelTemplate}
          options={templates?.items || EMPT_ARR}
          textFieldProps={{
            label: 'Шаблон',
          }}
        />
        {/* protocol */}
        <AutocompliteWithController
          loading={isLoadingProtocol || isFetchingProtocol}
          disabled={isLoadingProtocol || isFetchingProtocol || !templateValue}
          control={control}
          isOptionEqualToValue={(option, value) => option.guid === value.guid}
          renderOption={renderOptionProtocol}
          getOptionLabel={getOptionLabelProtocol}
          name={'protocol'}
          options={protocols || EMPT_ARR}
          textFieldProps={{
            label: 'Протокол',
          }}
        />
        {/* skipStages */}
        <AutocompliteWithController
          loading={isLoadingStages || isFetchingStages}
          disabled={isLoadingStages || isFetchingStages || !templateValue}
          control={control}
          options={stages || EMPT_ARR}
          isOptionEqualToValue={(option, value) => option.guid === value.guid}
          renderOption={renderOptionStage}
          multiple
          getOptionLabel={getOptionLabelStage}
          name={'skipStages'}
          textFieldProps={{
            label: 'Пропустить Этапы:',
          }}
        />
        {/* date */}
        <Grid>
          <DatePikerWithController
            disabled={isLoadingProtocol || isFetchingProtocol || !templateValue}
            control={control}
            name={'date'}
            label='Отсчет дней от'
          />
        </Grid>
        <ContainerActionsForm>
          <Button
            variant='contained'
            type='submit'
          >
            Продолжить
          </Button>
          <Button
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
