import dayjs from 'dayjs';

import { DATE_FORMATS } from './constants';

export const genDaysElementForMonthCalendar = (date: string) => {
  const startDate = dayjs(date).startOf('month').startOf('week'); // получаем дату понедельника для календаря текущего месяца
  const endDate = dayjs(date).endOf('month').endOf('week'); // получаем дату воскресенья для календаря текущего месяца
  const periodDaysArray = createArrPeriodDate(
    startDate.format(DATE_FORMATS.server),
    endDate.format(DATE_FORMATS.server)
  );

  return periodDaysArray;
};

const createArrPeriodDate = (startDate: string, endDate: string): string[] => {
  const periodDaysArr: string[] = [];
  const daysInPeriod = dayjs(endDate).diff(startDate, 'day') + 1; // определяем сколько дней(элементов) нужно отобразить в календаре

  for (let index = 0; index < daysInPeriod; index++) {
    periodDaysArr.push(
      dayjs(startDate).add(index, 'day').format(DATE_FORMATS.server)
    );
  }

  return periodDaysArr; //возвращаем массив с датами формата YYYY-MM-DD
};
