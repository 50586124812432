import { FC } from 'react';

import { useMediaQuery } from '@mui/material';
import { theme } from 'shared/theme/theme';
import { ProtocolOpeningRunningTasksList } from './ProtocolOpeningRunningTasksList';
import { ProtocolOpeningRunningTasksTable } from './ProtocolOpeningRunningTasksTable';
import { ProtocolOpeningRunningTasksTableProps } from './types';

export const ProtocolOpeningRunningTasksAdaptiveTable: FC<
  ProtocolOpeningRunningTasksTableProps
> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) return <ProtocolOpeningRunningTasksList {...props} />;
  else return <ProtocolOpeningRunningTasksTable {...props} />;
};
