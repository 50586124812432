import { useCallback, useEffect, useState } from 'react';

import { getEvents } from 'shared/api/visit/services';
import { EventGetListResponse } from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useEventsOnPlanningRNPage = (year: number, search?: string) => {
  const { open: openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState<EventGetListResponse[]>([]);

  const removeFromEvents = useCallback((eventId: number) => {
    setEvents((prev) => [...prev].filter((el) => el.id !== eventId));
  }, []);

  const addToEvents = useCallback((newEvent: EventGetListResponse) => {
    setEvents((prev) => [...prev, newEvent]);
  }, []);

  const changeEvent = useCallback((newEvent: EventGetListResponse) => {
    setEvents((prev) => {
      const newPrev = [...prev];
      const index = newPrev.findIndex((el) => el.id === newEvent.id);

      if (index === -1) return prev;
      newPrev[index] = newEvent;

      return newPrev;
    });
  }, []);

  const fetchEvents = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data, status } = await getEvents({
        Year: year,
        SearchText: search,
      });

      if (!data || status !== 200) throw new Error('Упс, что-то пошло не так');
      setEvents(data);
    } catch (e) {
      console.error('useGetVisitsEvents.getEvents >> ', e);

      openSnackbar({
        duration: 3000,
        type: 'error',
        text: 'Упс, что-то пошло не так',
      });
    }
    setIsLoading(false);
  }, [year, search]);

  useEffect(() => {
    fetchEvents();
  }, [year, search]);

  return {
    isLoading,
    events,
    update: fetchEvents,
    addToEvents,
    removeFromEvents,
    changeEvent,
  };
};
