import { CustomTextField } from 'shared/ui/custom-text-field';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormCounterpartyContractNumber = () => {
  const counterpartyContractNumberValue =
    documentFormModel.useValueDocumentFormModel('counterpartyContractNumber');
  const isCounterpartyRequired = documentFormModel.useValueProcess(
    'isCounterpartyRequired'
  );

  return (
    <CustomTextField
      propsTextField={{
        disabled: !isCounterpartyRequired,
        placeholder: 'Введите № договора',
        onChange: (event) => {
          documentFormModel.formEvent.setField({
            key: 'counterpartyContractNumber',
            value: event.target.value,
          });
        },
        value: counterpartyContractNumberValue || '',
      }}
      sx={{
        flexBasis: ['100%', '45%'],
      }}
      label='№ договора'
    />
  );
};
