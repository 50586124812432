import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import { TableInfo, Tables } from './type';

// TODO: Мб можно сделать это через контекст
// и регистрировать таблицы прям в компонентах
export class TablesController {
  private tablesInfo: TableInfo[] = [];

  register(table: Tables, version: number, map: FieldMapType<never>[]) {
    const candidate = this.getTableInfo(table);

    if (candidate) throw new Error(`Таблица ${table} уже зарегистрирована`);

    this.tablesInfo.push({ map, table, version });
  }

  getTableInfo(table: Tables) {
    return this.tablesInfo.find((tableInfo) => tableInfo.table === table);
  }
}

export const tablesController = new TablesController();
