/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const mergeTableSettings = (
  baseSettings: FieldMapType<never>[],
  settings: FieldMapType<never>[]
) => {
  const newSettings = [...settings];

  return newSettings.map((setting) => {
    const currentMapItem = baseSettings.find(
      (el) => el.field === setting.field
    )!;

    return {
      ...setting,
      getTableCellProps: currentMapItem.getTableCellProps,
      renderComponent: currentMapItem.renderComponent,
    };
  });
};
