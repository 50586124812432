// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { DateCalendar } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { FC } from 'react';

import { VisitCalendarDay } from './slots/visit-calendar-day';
import { MobileVisitCalendarProps } from './type';

export const MobileVisitCalendar: FC<MobileVisitCalendarProps> = ({
  visits,
  onChangeDay,
  onChangeMonth,
  initDay,
}) => {
  return (
    <DateCalendar
      value={initDay}
      onMonthChange={(newMonth: Dayjs) => {
        onChangeMonth && onChangeMonth(newMonth);
      }}
      onChange={(value) => {
        value && onChangeDay && onChangeDay(value);
      }}
      slots={{
        day: VisitCalendarDay,
      }}
      slotProps={{
        day: {
          visits,
        } as any,
      }}
    />
  );
};
