import { arrayBufferToBase64 } from './arrayBufferToBase64';

export interface Base64File {
  title: string;
  data: string;
}

export const fileListToArrayBase64 = async (
  fileList: FileList
): Promise<Base64File[]> => {
  const newFiles: Base64File[] = [];

  await Promise.all(
    Array.from(fileList).map(async (file) => {
      if (!file) return null;

      const buf = await file.arrayBuffer();

      const isSomeToNewFiles = newFiles.some(
        (item) => item.title === file.name
      );

      return newFiles.push({
        title: isSomeToNewFiles
          ? `${file.name}(Копия_${(Math.random() * 10000).toFixed()})`
          : file.name,
        data: arrayBufferToBase64(buf),
      });
    })
  );

  return newFiles;
};
