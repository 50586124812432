import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { DATE_FORMATS } from 'shared/date-helper/constants';
import { hasNotDateInMonth } from 'shared/date-helper/hasNotDateInMonth';
import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import { findElementById } from 'shared/tarnsform-helper/findElementById';
import { findElementsByIds } from 'shared/tarnsform-helper/findElementsByIds';
import { getDayjs } from 'shared/tarnsform-helper/getDayjs';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { isOptionEqualToValueById } from 'shared/tarnsform-helper/isOptionEqualToValueById';
import { CustomAutocomplete, CustomDatePicker } from 'shared/ui/base-ui';
import { FormLayout } from 'shared/ui/form';

import { useGetDepartments } from 'entities/visit/options/useGetDepartments';
import { useGetEventHomies } from 'entities/visit/options/useGetEventHomies';
import { useGetVisitDirections } from 'entities/visit/options/useGetVisitDirections';

import { createConferenceEventFormSchema } from './schema/validate';
import {
  CreateConferenceEventFormFields,
  CreateConferenceEventFormProps,
} from './type';

export const CreateConferenceEventForm: FC<CreateConferenceEventFormProps> = ({
  actions,
  onSubmit,
  initValue,
}) => {
  const { departments, isLoading: isLoadingDepartments } = useGetDepartments();
  const { directions, isLoading: isLoadingDirections } =
    useGetVisitDirections();
  const { homies, isLoading: isLoadingHomies } = useGetEventHomies();

  const { control, handleSubmit, setValue } =
    useForm<CreateConferenceEventFormFields>({
      defaultValues: initValue,
      resolver: yupResolver(createConferenceEventFormSchema),
    });

  /* Watch */
  const selectedDirectionId = useWatch({
    control: control,
    name: 'directionId',
  });

  const selectedStartDate = useWatch({
    control: control,
    name: 'dateStart',
  });

  const selectedEndDate = useWatch({
    control: control,
    name: 'dateFinish',
  });
  /* ===== */

  const shouldDisableDate = useCallback(
    (date: Dayjs) => {
      return hasNotDateInMonth(date, selectedStartDate || selectedEndDate);
    },
    [selectedStartDate, selectedEndDate]
  );

  const referenceDate = useMemo(() => {
    if (!selectedEndDate && !selectedStartDate) return dayjs();
    if (selectedStartDate) return dayjs(selectedStartDate);
    else return dayjs(selectedEndDate);
  }, [selectedEndDate, selectedStartDate]);

  const avaliebleHomies = useMemo(() => {
    if (!selectedDirectionId) return [];

    return homies.filter((homie) => homie.directionId !== selectedDirectionId);
  }, [selectedDirectionId, homies]);

  const changeDepartmentHandler = () => {
    setValue('homiesIds', []);
  };

  return (
    <FormLayout
      actions={actions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit)}
      container
      flexDirection={'column'}
      gap={2}
    >
      <Controller
        control={control}
        name='departmentId'
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Сеть',
              placeholder: 'Выберите сеть',
              required: true,
            }}
            loading={isLoadingDepartments}
            value={findElementById(departments, value)}
            onChange={(_, val) => {
              onChange(val?.id);
            }}
            isOptionEqualToValue={isOptionEqualToValueById}
            getOptionLabel={(dep) => dep.title || '~~ERROR~~'}
            options={departments || []}
          />
        )}
      />

      <Controller
        control={control}
        name='directionId'
        render={({ field: { onChange, value } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Направление',
              placeholder: 'Выберите напрввление',
              required: true,
            }}
            loading={isLoadingDirections}
            isOptionEqualToValue={isOptionEqualToValueById}
            getOptionLabel={(val) => val.title || '~~ERROR~~'}
            onChange={(_, val) => {
              onChange(val?.id);
              changeDepartmentHandler();
            }}
            value={findElementById(directions, value)}
            options={directions}
          />
        )}
      />

      <Grid
        container
        gap={2}
      >
        <Controller
          control={control}
          name='dateStart'
          render={({ field: { onChange, value } }) => (
            <CustomDatePicker
              value={getDayjs(value)}
              onChange={(val) => {
                onChange(val?.format(DATE_FORMATS.server));
              }}
              slotProps={{ textField: { required: true } }}
              containerSx={{ flex: 1 }}
              sx={{ width: '100%' }}
              label={'Дата начала'}
              views={['year', 'month', 'day']}
              shouldDisableDate={shouldDisableDate}
              referenceDate={referenceDate}
            />
          )}
        />

        <Controller
          control={control}
          name='dateFinish'
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <CustomDatePicker
              value={getDayjs(value)}
              onChange={(val) => {
                onChange(val?.format(DATE_FORMATS.server));
              }}
              slotProps={{
                textField: {
                  required: true,
                  helperText: error?.message,
                  error: invalid,
                },
              }}
              containerSx={{ flex: 1 }}
              sx={{ width: '100%' }}
              label={'Дата окончания'}
              views={['year', 'month', 'day']}
              shouldDisableDate={shouldDisableDate}
              referenceDate={referenceDate}
            />
          )}
        />
      </Grid>

      <Controller
        control={control}
        name='homiesIds'
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Доп. участники',
              placeholder: 'Выберите участников',
            }}
            multiple
            loading={isLoadingHomies}
            value={findElementsByIds(avaliebleHomies, value || [])}
            onChange={(_, val) => {
              onChange(getIdsFromArray(val));
            }}
            options={avaliebleHomies}
            noOptionsText={
              avaliebleHomies.length === 0
                ? 'Направление не указано'
                : 'Нет доступных вариантов'
            }
            isOptionEqualToValue={isOptionEqualToValueById}
            getOptionLabel={getEmployeTitle}
          />
        )}
      />
    </FormLayout>
  );
};
