// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { arrayStringToNumber } from 'shared/utils/arrayStringToNumber';

import { delEmpty } from '../utils/delEmpty';
import { ConvertTypes, URLToObjConverterByMap } from './type';

export const convertURLToObject: URLToObjConverterByMap = (
  searchParams,
  convertMap
) => {
  const newObj: any = {};

  convertMap.forEach((el) => {
    switch (el.type) {
      case ConvertTypes.string:
        newObj[el.key] = searchParams.get(el.key) || undefined;
        break;

      case ConvertTypes.number:
        newObj[el.key] = searchParams.get(el.key)
          ? Number(searchParams.get(el.key))
          : undefined;
        break;

      case ConvertTypes.masNumber:
        newObj[el.key] = searchParams.getAll(el.key)
          ? arrayStringToNumber(searchParams.getAll(el.key))
          : undefined;
        break;

      case ConvertTypes.boolean:
        newObj[el.key] =
          searchParams.get(el.key) === 'true'
            ? true
            : searchParams.get(el.key) === 'false'
            ? false
            : undefined;
        break;

      case ConvertTypes.masString:
        newObj[el.key] = searchParams.getAll(el.key) || undefined;
        break;
    }
  });

  return delEmpty(newObj);
};
