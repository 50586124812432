// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { FC } from 'react';

import { UiTableCard, UiTableCardList } from 'shared/ui/table';

import { ActionsProcessOpeningRetailsTable } from './ActionsProcessOpeningRetailsTable';
import { ProcessOpeningRetailsTableProps } from './types';

export const ProcessOpeningRetailsList: FC<ProcessOpeningRetailsTableProps> = ({
  isUpdate,
  isLoading = false,
  onClick,
  fields,
  onChangeFields,
  visibleFields,
  data,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading || isUpdate}
    >
      {data.map((process) => (
        <UiTableCard
          data={process}
          id={String(process.guid)}
          visibleFields={visibleFields}
          key={process.guid}
          onClick={() => {
            onClick && onClick(process);
          }}
          renderActions={() => (
            <ActionsProcessOpeningRetailsTable
              openingProcessGuid={process.processGuid}
            />
          )}
        />
      ))}
    </UiTableCardList>
  );
};
