import { FC } from 'react';

import { CustomTextField } from 'shared/ui/custom-text-field';

export const UiDocumentFormReasonNumber: FC<{
  value: string | undefined;
  required: boolean;
  disabled: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}> = ({ value, required, disabled, onChange }) => {
  return (
    <CustomTextField
      propsTextField={{
        value: value || '',
        disabled,
        required,
        placeholder: 'Введите № документа',
        onChange,
      }}
      sx={{
        flexBasis: ['100%', '35%'],
      }}
      label='№ документа'
    />
  );
};
