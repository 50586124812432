import {
  useLazyGetDocumentFlowArticlesQuery,
  useLazyGetDocumentFlowCounterpartyQuery,
  useLazyGetDocumentFlowEmployeeAllQuery,
  useLazyGetDocumentFlowOrganizationsQuery,
  useLazyGetDocumentFlowProcessListQuery,
  useLazyGetDocumentFlowStatusesQuery,
} from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';

export const useLazyGetDocumentFlowOptionsOptionsBaseFilterForm = () => {
  const [fetchStatuses, { isLoading: isLoadingOptionsStatus }] =
    useLazyGetDocumentFlowStatusesQuery();

  const [fetchOrganizations, { isLoading: isLoadingOptionsOrganization }] =
    useLazyGetDocumentFlowOrganizationsQuery();

  const [fetchArticles, { isLoading: isLoadingOptionsArticle }] =
    useLazyGetDocumentFlowArticlesQuery();

  const [fetchCounterparty, { isLoading: isLoadingOptionsCounterparty }] =
    useLazyGetDocumentFlowCounterpartyQuery();

  const [fetchEmployee, { isLoading: isLoadingOptionsEmployeeAll }] =
    useLazyGetDocumentFlowEmployeeAllQuery();

  const [fetchDocumentTypes, { isLoading: isLoadingOptionsDocumentType }] =
    useLazyGetDocumentFlowProcessListQuery();

  const isLoadingAnyLaziOptionsDocumentFlow =
    isLoadingOptionsStatus ||
    isLoadingOptionsOrganization ||
    isLoadingOptionsArticle ||
    isLoadingOptionsCounterparty ||
    isLoadingOptionsEmployeeAll ||
    isLoadingOptionsDocumentType;

  return {
    fetchStatuses,
    fetchOrganizations,
    fetchArticles,
    fetchCounterparty,
    fetchEmployee,
    fetchDocumentTypes,
    isLoadingOptionsStatus,
    isLoadingOptionsOrganization,
    isLoadingOptionsArticle,
    isLoadingOptionsCounterparty,
    isLoadingOptionsEmployeeAll,
    isLoadingOptionsDocumentType,
    /** true - если делается хотя бы один запрос  */
    isLoadingAnyLaziOptionsDocumentFlow,
  };
};
