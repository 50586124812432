import { createEffect, createStore } from 'effector';

import { getEmployeesPlanningHomies } from 'shared/api/visit/services';
import { EmployeePlanningHomiesGetListResponse } from 'shared/api/visit/types';

export const fetchPlanningRNHomiesFX = createEffect({
  handler: async () => {
    return getEmployeesPlanningHomies();
  },
});

export const $planningRNHomies = createStore<
  EmployeePlanningHomiesGetListResponse[]
>([]).on(fetchPlanningRNHomiesFX.doneData, (_, res) => res.data);
