import { useEffect } from 'react';

import { documentFormEditApi } from './api';
import { documentFormEdit } from './model';

export const useInitDocumentFormModelEdit = (id: number | undefined) => {
  useEffect(() => {
    if (id) {
      documentFormEditApi.getDocument(id);
      documentFormEditApi.getAgreeChain(id);
    }

    return () => {
      return documentFormEdit.formReset();
    };
  }, [id]);
};
