import { Autocomplete, CircularProgress } from '@mui/material';

import { CustomTextField } from '../custom-text-field';
import { CustomAutocompleteFunctionType } from './type';

/**
 * @description
 * Для работы с react-hook-form использовать
 * AutocompliteWithController / DatePikerWithController / TextFieldWithController / SwitchWithController.
 * @see {@link /shared/fields-with-contoller/ }
 */
export const CustomAutocomplete: CustomAutocompleteFunctionType = ({
  textFieldProps,
  loading,
  ...props
}) => {
  return (
    <Autocomplete
      {...props}
      size='small'
      loading={loading}
      loadingText={'Загрузка...'}
      renderInput={(inputProps) => (
        <CustomTextField
          {...inputProps}
          {...textFieldProps}
          InputProps={{
            ...inputProps.InputProps,
            ...textFieldProps.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    color='primary'
                    size={20}
                  />
                ) : null}
                {inputProps.InputProps.endAdornment}
                {textFieldProps.InputProps?.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
