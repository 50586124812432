import { createSearchParams } from 'react-router-dom';
import { ALL_ROUTES } from 'shared/routes/constants/types';

type CreateRunningProcessLinkParams = {
  protocolGuid: string;
  processGuid: string;
  openingTemplateGuid: string;
};

export const RETAIL_OPENING_PROCESS_HELPER_LINKS = {
  createLinkRunningProcess: ({
    openingTemplateGuid,
    processGuid,
    protocolGuid,
  }: CreateRunningProcessLinkParams) => {
    return {
      pathname: `/${
        ALL_ROUTES.retailOpeningProcess
      }/${ALL_ROUTES.retailOpeningProcessProtocolGuid.replace(
        ':guid',
        protocolGuid
      )}`,
      search: createSearchParams({
        processGuid,
        openingTemplateGuid,
      }).toString(),
    };
  },
};
