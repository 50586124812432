import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { transformBaseFormFilterToUrlParams } from '../../libs';
import {
  DocumentFlowFilterObserveForm,
  GetDocumentsObserveQueryParamsFromFilterActionForm,
} from '../type';
import {
  filterByFilterItemTypeByDepartments,
  filterByFilterItemTypeByDirections,
  filterByFilterItemTypeByOrgDepartments,
  filterByFilterItemTypeByRegions,
} from './filterByFilterItemType';

export const transformFilterObserveToUrlParams = (
  formFilter: DocumentFlowFilterObserveForm
): GetDocumentsObserveQueryParamsFromFilterActionForm => {
  const urlParams: GetDocumentsObserveQueryParamsFromFilterActionForm = {
    ...transformBaseFormFilterToUrlParams(formFilter),
    OrgDirectionIds: getIdsFromArray(
      filterByFilterItemTypeByDirections(formFilter.directionsAndRegions)
    ),
    RegionIds: getIdsFromArray(
      filterByFilterItemTypeByRegions(formFilter.directionsAndRegions)
    ),
    OrgDepartmentIds: getIdsFromArray(
      filterByFilterItemTypeByOrgDepartments(formFilter.departmentsAndDepts)
    ),
    DepartmentIds: getIdsFromArray(
      filterByFilterItemTypeByDepartments(formFilter.departmentsAndDepts)
    ),
    OrgOfficeIds: getIdsFromArray(formFilter.offices),
    EmployeeIds: getIdsFromArray(formFilter.employees),
    OrgPositionIds: getIdsFromArray(formFilter.positions),
  };

  return urlParams;
};
