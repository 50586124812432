import { useURLState } from 'shared/url-helper/useURLState';

import { useCallback } from 'react';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';
import {
  onChangeLimitFn,
  onChangePageFn,
  TrainingProcessAllQueryParams,
  useUrlStateTrainingFilterResult,
} from './types';

const convertorURLToCurrentTreningList: URLToObjConverter<
  TrainingProcessAllQueryParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_CURRENT_TRAINING_LIST_FILTER_MAP
  ) as TrainingProcessAllQueryParams;
};

const CONVERT_CURRENT_TRAINING_LIST_FILTER_MAP: ConvertMapWithType<TrainingProcessAllQueryParams> =
  [
    { key: 'Page', type: ConvertTypes.number },
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'EmployeeGuid', type: ConvertTypes.string },
    { key: 'TrainerGuid', type: ConvertTypes.string },
    { key: 'DateFrom', type: ConvertTypes.string },
    { key: 'DateTo', type: ConvertTypes.string },
    { key: 'OrgGuid', type: ConvertTypes.string },
    { key: 'Statuses', type: ConvertTypes.masString },
    { key: 'SearchText', type: ConvertTypes.string },
    { key: 'TrainingGuid', type: ConvertTypes.string },
  ];

export const useUrlStateTrainingFilter =
  (): useUrlStateTrainingFilterResult => {
    const [filter, setFilter] = useURLState(
      convertorURLToCurrentTreningList,
      true
    );

    const onChangeLimit: onChangeLimitFn = useCallback(
      (limit) => {
        setFilter({ ...filter, Limit: limit });
      },
      [filter]
    );
    const onChangePage: onChangePageFn = useCallback(
      (page) => {
        setFilter({ ...filter, Page: page });
      },
      [filter]
    );

    return {
      filter,
      setFilter,
      onChangeLimit,
      onChangePage,
    };
  };
