import {
  createSearchParams,
  generatePath,
  URLSearchParamsInit,
} from 'react-router-dom';
import { BannersApiSliderListParams } from 'shared/api/service-apteka-legko-banners/aptekaLegoBannersAutogenerateApi';
import { creatRoutePathFromArray } from 'shared/routes';
import { ALL_ROUTES } from 'shared/routes/constants/types';

const BANNERS_SLIDER_LIST = creatRoutePathFromArray([
  ALL_ROUTES.aptekaLegko,
  ALL_ROUTES.aptekaLegkoBanners,
  ALL_ROUTES.aptekaLegkoBannersSliders,
  ALL_ROUTES.aptekaLegkoBannersSlidersList,
]);

const BANNERS_SLIDER_CREATED = creatRoutePathFromArray([
  ALL_ROUTES.aptekaLegko,
  ALL_ROUTES.aptekaLegkoBanners,
  ALL_ROUTES.aptekaLegkoBannersSliders,
  ALL_ROUTES.aptekaLegkoBannersSlidersCreated,
]);
const BANNERS_SLIDER_EDIT = creatRoutePathFromArray([
  ALL_ROUTES.aptekaLegko,
  ALL_ROUTES.aptekaLegkoBanners,
  ALL_ROUTES.aptekaLegkoBannersSliders,
  ALL_ROUTES.aptekaLegkoBannersSlidersEdit,
]);

export const APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS = {
  createLinkEdit: (guid: string) => {
    return generatePath(BANNERS_SLIDER_EDIT, { guid });
  },
  list: BANNERS_SLIDER_LIST,
  createListWithParams: ({
    DateEnd,
    DateStart,
  }: BannersApiSliderListParams) => {
    let baseParams: URLSearchParamsInit = {};

    if (DateEnd) {
      baseParams = { ...baseParams, DateEnd };
    }

    if (DateStart) {
      baseParams = { ...baseParams, DateStart };
    }

    return {
      pathname: BANNERS_SLIDER_LIST,
      search: createSearchParams(baseParams).toString(),
    };
  },
  created: BANNERS_SLIDER_CREATED,
};
