import { Grid, Typography } from '@mui/material';
import { BANNERS_SLIDER_ASPECT_RATIO } from 'entities/apteka-legko/banners/sliders/constants';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';

export const SliderItemUi: FC<
  PropsWithChildren<{
    skipAfterSx?: boolean;
    Actions: ReactNode;
    AfterPeriod: ReactNode;
  }>
> = ({ skipAfterSx = false, children, Actions, AfterPeriod }) => {
  const [isMobile] = useDevice();

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={2}
    >
      <Grid
        sx={{
          display: 'flex',
          boxSizing: 'border-box',
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          '&::after': skipAfterSx
            ? {}
            : {
                content: '""',
                display: 'block',
                paddingTop: `${
                  100 /
                  (isMobile
                    ? BANNERS_SLIDER_ASPECT_RATIO.mobile
                    : BANNERS_SLIDER_ASPECT_RATIO.desktop)
                }%`,
              },
        }}
      >
        {children}
      </Grid>

      <Grid
        display={'flex'}
        gap={1}
      >
        <Typography fontWeight={'bold'}>Период размещения</Typography>
        {AfterPeriod}
      </Grid>
      <Grid
        display={'flex'}
        gap={1}
      >
        {Actions}
      </Grid>
    </Grid>
  );
};
