import { Theme } from '@mui/material';
import { theme as constTheme } from 'shared/theme/theme';
import { ID_ACTION } from '../types';

export const getDocumentFlowColorAction = (
  id: ID_ACTION,
  theme: Theme = constTheme
) => {
  switch (id) {
    case 'delete':
      return theme.palette.error.main;
    case 'edit':
      return theme.palette.customBlue.main;
    case 2:
      return theme.palette.primary.main;

    case 8:
      return theme.palette.error.main;
    case 11:
      return theme.palette.error.main;

    case 14:
      return theme.palette.customBlue.main;

    case 16:
      return theme.palette.error.main;
    default:
      return theme.palette.primary.main;
  }
};
