import { FC } from 'react';
import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTableRowWithActions,
} from 'shared/ui/table';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';
import { AdaptationStageTasksTableProps } from './type';

export const AdaptationStageTasksTable: FC<AdaptationStageTasksTableProps> = ({
  data: tasks,
  isLoading = false,
  isUpdate = false,
  onClick,
  renderActions,
  fields,
  onChangeFields,
  visibleFields,
}) => {
  return (
    <UiTable
      isLoading={isLoading}
      isUpdating={isUpdate}
      skeleton={<UiTableSkeleton limit={5} />}
      header={
        <UiTableHeader
          allCells={fields}
          viewCells={visibleFields}
          setViewCells={onChangeFields}
        />
      }
      body={
        <>
          {tasks &&
            tasks.length !== 0 &&
            tasks.map((task) => (
              <UiTableRowWithActions
                key={task.guid}
                hover
                onClick={() => {
                  onClick?.(task);
                }}
                renderActions={(isOpen, setIsLoading) => {
                  return renderActions(task, { isOpen, setIsLoading });
                }}
                sx={{ cursor: 'pointer' }}
              >
                {visibleFields.map((el) => (
                  <UiTableCell
                    key={el.field + task.guid}
                    {...el.getTableCellProps()}
                  >
                    {el.renderComponent(task) || 'Error'}
                  </UiTableCell>
                ))}
              </UiTableRowWithActions>
            ))}
        </>
      }
    />
  );
};
