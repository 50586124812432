import {
  AdaptationManagerResponsesGetListPosition,
  PaginatedAdaptationManagerResponsesGetListBannedEmployee,
} from '../adaptationAutogenerateApi';

export interface UserNoAdaptationRequiredItem {
  guid: string;
  name: string;
  position: AdaptationManagerResponsesGetListPosition;
  isVacation: boolean;
  retailTitle?: string | null;
  comment?: string | null;
}

export interface UserNoAdaptationRequiredWithPagination {
  count: number;

  items: UserNoAdaptationRequiredItem[];
}

export const transformResponseBackendUserNoAdaptationRequiredToViewType = (
  response: PaginatedAdaptationManagerResponsesGetListBannedEmployee
): UserNoAdaptationRequiredWithPagination => {
  return {
    ...response,
    items: response.items.map<UserNoAdaptationRequiredItem>((responseItem) => {
      return {
        ...responseItem.employee,
        comment: responseItem.comment,
        retailTitle: responseItem.retailTitle,
      };
    }),
  };
};
