import { VisitingChecklistStageOneRequest } from 'shared/api/visit/types';

import { InformationAboutCompetitorsFormI } from '../type';

type PrepareDataToFetchStage2Type = (
  data: InformationAboutCompetitorsFormI
) => VisitingChecklistStageOneRequest;

export const prepareDataToFetchStage2: PrepareDataToFetchStage2Type = (
  data
) => {
  const comp = data.competitors.filter((el) => el.competitor !== null);

  if (comp.length !== 0)
    return {
      competitors: data.competitors
        .filter((el) => el !== null)
        .map((comp) => {
          return {
            competitorId: comp.competitor ? comp.competitor.id : 0,
            checksCount: comp.checksCount || undefined,
            products: data.product
              .filter((pr) => pr !== null)
              .map((prod, i) => {
                return {
                  productId: prod ? prod.id : 0,
                  price: comp.productPrice[i] || 0,
                };
              }),
          };
        }),
    };
  else return { competitors: undefined };
};
