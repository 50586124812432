import { Delete, NotificationAdd, Settings } from '@mui/icons-material';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { TaskInformation } from 'features/task/task-information/TaskInformation';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { GetTask } from 'shared/api/services/types';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { CustomTextField } from 'shared/ui/base-ui';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { useDevice } from 'shared/utils/hook-type-device';

import { ModalEditTask } from 'widgets/tasks/modal-form-create-task/ModalEditTask';
import { RemindeFormModal } from 'widgets/tasks/reminde-form/RemindeFormModal';

import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import {
  useGetTaskQuery,
  useUpdateTaskStatusMutation,
} from 'shared/api/services/rtk/taskApi';
import { Tables } from 'shared/utils/controllers';
import { SubTasksTab } from 'widgets/tasks/SubTasksTab';
import { TaskCommentTab } from 'widgets/tasks/TaskCommentTab/TaskCommentTab';
import { TaskFilesTab } from 'widgets/tasks/TaskFilesTab';
import { useDeleteTask } from '../hooks/useDeleteTask';
import { Task404 } from './components/task-404/Task404';
import { TaskPageSpeedDial } from './components/task-page-speed-dial/TaskPageSpeedDial';
import { checkCanEditTask } from './libs/checkCanEditTask';
import { TaskIdMainComponentProps } from './type';

const WRAPPER_SX = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  gap: 2,
  '.rel_pos': {
    position: 'relative',
  },
  '@container (min-width:800px)': {
    display: 'grid',
    columnGap: 3,
    rowGap: 2,
    gridTemplateColumns: 'calc(100% - 466px) 450px',
    '.rel_pos': {
      position: 'absolute',
    },
  },
};

export const TaskIdMainComponent: FC<TaskIdMainComponentProps> = ({
  taskGuid,
  isDrawer,
  onClose,
}) => {
  /* Snackbar */
  const { open: openSnackbar } = useSnackbar();
  /* ==== */

  /* Def taskGuid */
  const { guid } = useParams();
  const currentTaskGuid = taskGuid || guid || '';
  /* ===== */

  /* Other */
  const [isMobile] = useDevice();
  const [tabs, setTabs] = useState(isMobile ? 0 : 1);
  /* ===== */

  /* Actions */
  const deleteTask = useDeleteTask(onClose);
  const [updateStatus, { error: error2 }] = useUpdateTaskStatusMutation();

  useShowFetchErrorInSnackbar(error2);
  /* ===== */

  /* Modals stats */
  const { close, isOpen, open } = useViewModal();
  const {
    close: closeEdit,
    isOpen: isOpenEdit,
    open: openEdit,
  } = useViewModal();
  /* ===== */

  /* Getting full task info */
  const {
    data: task,
    isLoading: isFetchingTask,
    error: error,
  } = useGetTaskQuery(currentTaskGuid);

  useShowFetchErrorInSnackbar(error);
  /* ===== */

  /* Getting tables setting */
  const [fieldsInfo, setFieldsInfo, visibleFieldsInfo] =
    useTableCellsFromLocalStorage<GetTask>(Tables.TASK_INFO);
  /* ===== */

  const canEdit = useMemo(() => {
    if (!task) return false;
    else return checkCanEditTask(task.accessActions);
  }, [task]);

  if (isFetchingTask)
    return (
      <CustomBackdrop
        isLoading={isFetchingTask}
        sx={isDrawer ? { position: 'relative', height: '100vh' } : undefined}
      />
    );
  if (!task) return <Task404 />;

  return (
    <>
      {/* Редактирование напоминаний */}
      <RemindeFormModal
        isOpen={isOpen}
        onClose={close}
        task={task}
      />

      {/* Редактирование задачи */}
      <ModalEditTask
        isOpen={isOpenEdit}
        onClose={closeEdit}
        task={task}
      />

      {isMobile && (
        <TaskPageSpeedDial
          onDelete={() => {
            deleteTask(currentTaskGuid);
          }}
          onAddFile={() => {
            document.getElementById('upload_task_files')?.click();
          }}
          onEdit={openEdit}
          onNotify={open}
        />
      )}

      {/* ===== */}

      <Grid
        minHeight={'100%'}
        width={'100%'}
        margin='0 auto'
        sx={{
          pt: [2, 4],
          pb: [14, 4],
          px: [0, 2],
          containerType: 'inline-size',
        }}
      >
        <Grid sx={WRAPPER_SX}>
          {/* Title */}
          <Grid
            sx={{
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }}
          >
            <Typography
              fontSize={22}
              fontWeight={700}
              px={[1, 0]}
              sx={{ wordBreak: 'break-word' }}
            >
              {task.title}
            </Typography>
          </Grid>

          {/* Описание */}
          <Grid
            sx={{
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }}
          >
            <CustomTextField
              disabled={!task.description}
              InputProps={{ readOnly: true }}
              label={'Описание'}
              placeholder='Нет описания'
              multiline
              value={task.description || 'Нет описания'}
              variant='standard'
            />
          </Grid>
          {/* ===== */}

          {/* Ations */}
          <Grid
            container
            flexDirection={['column', 'row']}
            alignItems={'center'}
            gap={[1, 2]}
            display={['none', 'flex']}
            sx={{
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }}
          >
            <Button
              variant='contained'
              startIcon={<Settings />}
              disabled={!canEdit}
              onClick={openEdit}
            >
              Редактировать
            </Button>

            <Button
              onClick={open}
              startIcon={<NotificationAdd />}
              variant='outlined'
              disabled={
                !task.accessActions.canModifyRemind || task.status.id === 5
              }
            >
              Напоминания
            </Button>

            <Button
              variant='outlined'
              startIcon={<Delete />}
              color='error'
              onClick={() => {
                deleteTask(currentTaskGuid);
              }}
              disabled={!task.accessActions.canDelete}
            >
              Удалить
            </Button>
          </Grid>
          {/* ===== */}

          {/* Информация */}
          <Grid
            display={['none', 'flex']}
            sx={{
              gridColumnStart: 2,
              gridColumnEnd: 3,
              gridRowStart: 1,
              gridRowEnd: 5,
              position: 'relative',
            }}
          >
            <Grid
              className='rel_pos'
              flex={1}
              width={'100%'}
            >
              <TaskInformation
                task={task}
                fields={fieldsInfo}
                visibleFields={visibleFieldsInfo}
                onChangeFields={setFieldsInfo}
                changeStatus={(newStatus) => {
                  updateStatus({
                    taskGuid: currentTaskGuid,
                    status: newStatus,
                  }).then(() => {
                    openSnackbar({
                      text: <strong>Статус обновлён</strong>,
                      type: 'success',
                      duration: 3000,
                    });
                  });
                }}
              />
            </Grid>
          </Grid>
          {/* ===== */}

          {/* Tabs */}
          <Grid
            container
            flexDirection={'column'}
            gap={1}
            sx={{ gridColumnStart: 1, gridColumnEnd: 2 }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
            >
              <Tabs
                value={tabs}
                onChange={(_, value) => {
                  setTabs(value);
                }}
                variant='scrollable'
              >
                {isMobile && (
                  <Tab
                    label='Описание'
                    value={0}
                  />
                )}

                <Tab
                  label={'Подзадачи'}
                  value={1}
                />
                <Tab
                  label={'Файлы'}
                  value={2}
                />
                <Tab
                  label={'Комментарии'}
                  value={3}
                />
              </Tabs>
            </Box>

            {/* Описание */}
            {tabs === 0 && (
              <Grid>
                <TaskInformation
                  changeStatus={(newStatus) => {
                    updateStatus({
                      taskGuid: currentTaskGuid,
                      status: newStatus,
                    }).then(() => {
                      openSnackbar({
                        text: <strong>Статус обнавлён</strong>,
                        type: 'success',
                        duration: 3000,
                      });
                    });
                  }}
                  task={task}
                  fields={fieldsInfo}
                  visibleFields={visibleFieldsInfo}
                  onChangeFields={setFieldsInfo}
                />
              </Grid>
            )}
            {/* ===== */}

            {/* Комментарии */}
            {tabs === 3 && (
              <TaskCommentTab
                canAddComment={task.accessActions.canAddComment}
                taskGuid={currentTaskGuid}
              />
            )}
            {/* ===== */}

            {/* Файлы */}
            {tabs === 2 && (
              <TaskFilesTab
                canAddFile={task.accessActions.canAddFile}
                canEdit={canEdit}
                taskGuid={currentTaskGuid}
              />
            )}
            {/* ===== */}

            {/* Подзадачи */}
            {tabs === 1 && (
              <SubTasksTab
                canEdit={canEdit}
                taskGuid={currentTaskGuid}
              />
            )}
            {/* ===== */}
          </Grid>
        </Grid>
        {/* ===== */}
      </Grid>
    </>
  );
};
