import Grid from '@mui/material/Grid/Grid';
import { useStore } from 'effector-react';
import { DocumentFormCounterpartyContractLink } from 'features/document/document-form-counterparty-contract-link/DocumentFormCounterpartyContractLink';
import { DocumentFormCounterpartyContractNumber } from 'features/document/document-form-counterparty-contract-number/DocumentFormCounterPartyContractNumber';
import { DocumentFormCounterPartyReasonCode } from 'features/document/document-form-counterparty-reason-code/DocumentFormCounterPartyReasonCode';
import { DocumentFormCounterpartyTaxNumber } from 'features/document/document-form-counterparty-tax-number/DocumentFormCounterpartyTaxNumber';
import { DocumentFormCounterpartyTitle } from 'features/document/document-form-counterparty-title/DocumentFormCounterPartyTitle';
import { useEffect } from 'react';

import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';

import { documentFormModel } from 'entities/document-form';

import { fetchConterpartyOptionsFx } from './fetch-effector';
import { $counterpartyOptions } from './model';

export const SectionCounterParty = () => {
  useEffect(() => {
    fetchConterpartyOptionsFx();
  }, []);

  const options = useStore($counterpartyOptions);
  const isCounterpartyRequired = documentFormModel.useValueProcess(
    'isCounterpartyRequired'
  );
  const isTransferToAccountablePerson = documentFormModel.useValueProcess(
    'isTransferToAccountablePerson'
  );

  if (!isCounterpartyRequired || isTransferToAccountablePerson) return null;

  return (
    <Grid
      container
      justifyContent={'space-between'}
      gap={2}
    >
      {/* counterparty */}
      <DocumentSectionTitle
        text={'Контрагент'}
        sx={{ marginTop: [2, 4] }}
      />
      <DocumentFormCounterpartyTaxNumber options={options} />
      <DocumentFormCounterPartyReasonCode options={options} />
      <DocumentFormCounterpartyTitle options={options} />
      <DocumentFormCounterpartyContractNumber />
      <DocumentFormCounterpartyContractLink />
    </Grid>
  );
};
