import { useEffect, useState } from 'react';

import { getDocumentsDocumentIdCurrentActions } from 'shared/api/services-document-flow/services';
import { DocumentCurrentActionsResponse } from 'shared/api/services-document-flow/types';

export const useGetDocumentIdActions = (id?: number) => {
  const [actions, setActions] = useState<DocumentCurrentActionsResponse | null>(
    null
  );
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsloading(true);
      getDocumentsDocumentIdCurrentActions(id)
        .then((response) => {
          setActions(response.data);
          setIsloading(false);
        })
        .catch(() => {
          setIsloading(false);
        });
    }
  }, [id]);

  return [actions, isLoading] as const;
};
