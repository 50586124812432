import { LoadingButton } from '@mui/lab';
import { Grid, TextareaAutosize } from '@mui/material';
import { FC, useState } from 'react';

import { CommentReply } from '../comment-reply/CommentReply';
import { CommentInputProps } from './type';

const pressed = new Set();

export const CommentInput: FC<CommentInputProps> = ({
  onSend,
  isLoading = false,
  replyComment,
  onCloseReply,
}) => {
  const [text, setText] = useState('');

  const sendHandler = () => {
    if (!text || text.length < 1) return;
    onCloseReply && onCloseReply();
    onSend && onSend(text, replyComment ? replyComment.guid : undefined);
    setText('');
  };

  return (
    <Grid>
      {replyComment && (
        <CommentReply
          commentReply={replyComment}
          close={onCloseReply}
        />
      )}

      <Grid
        container
        alignItems={'flex-start'}
        gap={1}
        width={'100%'}
      >
        <TextareaAutosize
          id='CommentField'
          value={text}
          onChange={(e) => {
            const inputEvent = e.nativeEvent as InputEvent;
            const inputType = inputEvent.inputType as string;

            if (!pressed.has('Shift') && inputType === 'insertLineBreak')
              return;
            setText(e.target.value || '');
          }}
          maxRows={6}
          minRows={1}
          placeholder='Введите сообщение'
          style={{
            maxHeight: 150,
            minHeight: 20,
            flex: 1,
            fontSize: '15px',
            lineHeight: 1.5,
            padding: '8px 12px',
            borderRadius: 8,
            fontFamily:
              // eslint-disable-next-line quotes
              "Evolventa, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          }}
          onKeyUp={(e) => {
            pressed.delete(e.key);
          }}
          onKeyDown={(e) => {
            pressed.add(e.key);
            if (pressed.has('Enter') && !pressed.has('Shift')) sendHandler();
          }}
        />
        <LoadingButton
          loading={isLoading}
          variant='contained'
          onClick={sendHandler}
        >
          Отправить
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
