import { useStoreMap } from 'effector-react';

import { documentFormEdit } from './model';
import {
  SetFieldDocumentFormEdit,
  UseSelectArticleExpenseValueFormEdit,
  UseSelectorDocumentFormModel,
  UseSelectorDocumentFormModelProcess,
  UseSelectoreArticleFormModel,
} from './type';

export const useSelectValueOfKey: UseSelectorDocumentFormModel = (key) => {
  return useStoreMap(
    documentFormEdit.form,
    (document) => document && document[key]
  );
};
export const useSelectProcessValueOfKey: UseSelectorDocumentFormModelProcess = (
  key
) => {
  return useStoreMap(
    documentFormEdit.form,
    (document) => document && document.process[key]
  );
};
export const useSelectArticleIdFormEdit: UseSelectoreArticleFormModel = (
  id,
  key
) => {
  return useStoreMap({
    store: documentFormEdit.form,
    keys: [documentFormEdit.form],
    fn: (document) => {
      if (document) {
        const article = document.documentArticles?.find(
          (item) => item.articleId === id
        );

        return (article && article[key]) || null;
      }

      return null;
    },
  });
};
export const useSelectArticleExpenseValue: UseSelectArticleExpenseValueFormEdit =
  (id, index, key) => {
    return useStoreMap(documentFormEdit.form, (document) => {
      if (!document) return undefined;

      const value = document.documentArticles?.find(
        (art) => art.articleId === id
      )?.documentArticleExpenses?.[index]?.[key];

      return value || null;
    });
  };

export const setFiled: SetFieldDocumentFormEdit = (document, payload) => {
  const { key, value } = payload;

  if (!document) return document;

  return {
    ...document,
    [key]: value,
  };
};
