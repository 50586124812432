import { Box, Grid, Typography } from '@mui/material';

import not_found_task from 'shared/img/not_task.png';
import { theme } from 'shared/theme/theme';

export const Task404 = () => {
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent='center'
      flexDirection={['column', 'row']}
      sx={{
        color: theme.palette.primary.main,
        paddingTop: [3, 20],
        '& > img': {
          width: [320, 423],
          height: [330, 436],
          marginRight: [0, 4],
        },
      }}
    >
      <img
        src={not_found_task}
        alt='not found'
      />
      <Box padding={['8px']}>
        <Typography
          fontSize={[16, 20]}
          fontWeight={700}
        >
          Задача не найдена
        </Typography>
        <Typography fontSize={[16, 20]}>
          Выбранная задача была удалена или не существует
        </Typography>
      </Box>
    </Grid>
  );
};
