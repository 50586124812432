import { Grid, Skeleton } from '@mui/material';

export const CompetitiveAdvantagesFormSkeleton = () => {
  return (
    <Grid
      container
      flexDirection={'column'}
      gap={3}
    >
      <Skeleton
        height={35}
        variant='rounded'
      />

      <Skeleton
        width={100}
        variant='rounded'
      />

      <Skeleton
        height={152}
        variant='rounded'
      />
      <Skeleton
        height={152}
        variant='rounded'
      />

      <Skeleton
        width={100}
        variant='rounded'
      />

      <Skeleton
        height={152}
        variant='rounded'
      />
      <Skeleton
        height={152}
        variant='rounded'
      />

      <Skeleton
        width={100}
        variant='rounded'
      />

      <Skeleton
        height={152}
        variant='rounded'
      />
      <Skeleton
        height={152}
        variant='rounded'
      />
    </Grid>
  );
};
