import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack, Typography } from '@mui/material';
import { useStageDraftConfirm } from 'features/visit/use-stage-draft-confirm/useStageDraftConfirm';
import React, { FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { CustomTextField } from 'shared/ui/custom-text-field';
import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';

import { ConfirmStageDialog } from '../../ConfirmDialog/ConfirmStageDialog';
import { NavButtons } from '../../NavButtons';
import { EvaluationPaperFormSchema } from '../schems';
import { EvaluationPaperFormI, EvaluationPaperFormProps } from '../type';
import { Line } from './Line/Line';
import { ListItem } from './ListItem/ListItem';

export const EvaluationPaperForm: FC<EvaluationPaperFormProps> = ({
  initValue,
  onSubmit,
  disableAll,
  onDraftSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting },
    getValues,
  } = useForm<EvaluationPaperFormI>({
    resolver: yupResolver(EvaluationPaperFormSchema),
    values: initValue,
  });

  const { fields } = useFieldArray({
    name: 'blocks',
    control,
  });

  const {
    handleClose,
    handleConfirm,
    handleNext,
    onSubmit: onSub,
    isLoading,
    isDialogOpen,
  } = useStageDraftConfirm(isDirty, onSubmit, onDraftSubmit);

  return (
    <Grid
      container
      component={'form'}
      onSubmit={handleSubmit(onSub)}
      gap={5}
    >
      <ConfirmStageDialog
        handleClose={handleClose}
        handleConfirm={() => {
          handleConfirm(getValues());
        }}
        handleNext={handleNext}
        isLoading={isLoading}
        isOpen={isDialogOpen}
      />

      <DocumentSectionTitle
        text='Оценочный лист'
        sx={{ marginTop: 0, margin: 0 }}
      />

      {fields?.map((el, index) => (
        <Stack
          key={el.id}
          divider={<Line />}
          gap={2}
          width={'100%'}
        >
          <Typography
            fontWeight={700}
            fontSize={18}
            ml={[1, 0]}
          >
            {el.blockTitle}
          </Typography>
          {el.items?.map((el2, index2) => (
            <React.Fragment key={el2.itemId}>
              <Controller
                name={`blocks.${index}.items.${index2}.value`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ListItem
                    disabled={disableAll}
                    title={el2.itemTitle || ''}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />

              {el2.isCommentAllowed && (
                <Controller
                  name={`blocks.${index}.items.${index2}.comment`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomTextField
                      label='Коментарий'
                      propsTextField={{
                        disabled: disableAll,
                        onChange: (e) => {
                          onChange(e.target.value);
                        },
                        value: value || '',
                        placeholder: 'Введите коментарий',
                        multiline: true,
                        maxRows: 4,
                      }}
                    />
                  )}
                />
              )}
            </React.Fragment>
          ))}
          <div />
        </Stack>
      ))}
      <NavButtons isLoading={isSubmitting} />
    </Grid>
  );
};
