export const USEFUL_LINKS = [
  {
    title: 'Портал бизнес-аналитики Power BI',
    description: 'Форма 2, Форма 3 и другие отчёты по направлениям',
    href: 'http://bi.garzdrav.ru:3000/BIReports/browse',
  },
  {
    title: 'Корпоративный портал обучения',
    description:
      'Обучения по фармакологии, акции, треннинги от производителей и многое другое',
    href: 'https://moodle.garzdrav.ru/',
  },
  {
    title: 'Официальный сайт «Гармония здоровья»',
    description: 'Корпоративный сайт аптечной сети',
    href: 'https://garzdrav.ru/',
  },
  {
    title: 'Официальный сайт «Аптека Легко»',
    description: 'Интернет-аптека ',
    href: 'https://aptekalegko.ru/',
  },
  {
    title: 'Официальный сайт «Карта Легко»',
    description: 'Бонусная программа «Легко»',
    href: 'http://kartalegko.ru/',
  },
];
