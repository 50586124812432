import { VisitingChecklistStageFiveRequest } from 'shared/api/visit/types';

import { EventsFormI } from '../type';

type prepareDataToFetchStage6Type = (
  data: EventsFormI
) => VisitingChecklistStageFiveRequest;
export const prepareDataToFetchStage6: prepareDataToFetchStage6Type = (
  data
) => {
  return {
    problems: data.problems.map((problem) => ({
      problemId: problem.idBackend,
      tasksIds: problem.tasks.map((task) => task.id),
    })),
  };
};
