import { StatusModel } from 'shared/api/services/types';
import { TaskCardStatuses } from '../type';

export const getTaskCardStatus = (status: StatusModel): TaskCardStatuses => {
  switch (status.id) {
    case 4:
      return TaskCardStatuses.check;

    case 5:
      return TaskCardStatuses.complete;

    default:
      return TaskCardStatuses.new;
  }
};
