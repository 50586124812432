import { useGetWaitingProcessesQuery } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';

import { renderActionsWaitingAdaptations } from 'widgets/adaptation/CurrentAdaptationProcessesTable/render-actions/renderActionsWaitingAdaptations';
import { AdaptationProcessesPage } from './AdaptationProcessesPage';

export const WaitingAdaptationProcessesPage = () => {
  return (
    <AdaptationProcessesPage
      useGetProcesses={useGetWaitingProcessesQuery}
      renderActions={renderActionsWaitingAdaptations}
    />
  );
};
