import { Grid } from '@mui/material';
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FC } from 'react';

import { DATE_FORMATS } from 'shared/date-helper/constants';

import { CustomDatePicker } from './base-ui';

export type onChangeTypePayBeforDate = (
  value: string | null,
  context: PickerChangeHandlerContext<DateValidationError>
) => void;

export const UiDocumentFormPayBeforDate: FC<{
  value: string | null;
  onChange: onChangeTypePayBeforDate;
  disabled?: boolean;
}> = ({ value, onChange, disabled = false }) => {
  return (
    <Grid sx={{ flexBasis: ['100%', '45%'] }}>
      <CustomDatePicker
        label={'Оплатить до'}
        value={value ? dayjs(value) : null}
        onChange={(value, context) => {
          onChange(value?.format(DATE_FORMATS.server) || null, context);
        }}
        disabled={disabled}
        slotProps={{
          textField: {
            disabled,
            margin: 'none',
            required: true,
            inputProps: {
              placeholder: 'Оплатить до',
            },
          },
        }}
      />
    </Grid>
  );
};
