import { useState } from 'react';

import { putPlanningsPlanningId } from 'shared/api/visit/services';
import {
  VisitingPlanningGetListResponse,
  VisitingPlanningUpdateRequest,
} from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useEditPlanningRN = (
  afterEdit?: (planning: VisitingPlanningGetListResponse) => void
) => {
  const { open: openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const edit = async (
    planningId: number,
    fetchData: VisitingPlanningUpdateRequest
  ) => {
    setIsLoading(true);
    try {
      const { data, status } = await putPlanningsPlanningId(
        planningId,
        fetchData
      );

      if (!data || status !== 200) throw new Error('Упс, что-то пошло не так');

      setIsLoading(false);
      afterEdit && afterEdit(data);

      return data;
    } catch (e) {
      console.error('useEditPlanningRN.edit >> ', e);
      setIsLoading(false);

      const error = e as { message: string };

      openSnackbar({
        duration: 6000,
        type: 'error',
        text: error.message,
      });

      return Promise.reject(e);
    }
  };

  return { isLoading, edit };
};
