// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { combine, createEffect, createStore, sample } from 'effector';

import {
  getArticles,
  getArticleTypes,
} from 'shared/api/services-document-flow/services';
import {
  ArticleResponse,
  ArticleTypeModel,
} from 'shared/api/services-document-flow/types';

import { documentFormModel } from 'entities/document-form/model';

import { CombineArticle } from './types';

const fetchTypeArticleFx = createEffect({
  name: 'fetchTypeArticleFx',
  handler: getArticleTypes,
});

const fetchArticlesFx = createEffect({
  name: 'fetchArticlesFx',
  handler: getArticles,
});

const $articlesDDS = createStore<ArticleResponse[]>([], {
  name: '$articlesDDS ',
}).on(fetchArticlesFx.doneData, (state, response) => response.data);

const $typesArticle = createStore<ArticleTypeModel[]>([], {
  name: '$typesArticle ',
}).on(fetchTypeArticleFx.doneData, (_, response) => response.data);

const options = createStore<CombineArticle[]>([], {
  name: 'options-article-autocomplite',
}).on(
  sample({
    clock: [$articlesDDS, $typesArticle],
    source: { article: $articlesDDS, typeArticle: $typesArticle },
    fn: ({ article, typeArticle }) =>
      article
        .map((item) => ({
          ...item,
          typeTitle:
            typeArticle.find((type) => type.id === item.typeId)?.title ||
            'Прочее',
        }))
        .sort((a, b) =>
          b.typeTitle && a.typeTitle && a.typeTitle > b.typeTitle ? 1 : -1
        ),
  }),
  (_, res) => res
);

const value = combine(
  options,
  documentFormModel.form.map((item) => (item && item.articles) || []),
  (options, articles): CombineArticle[] => {
    if (!document) return [];

    return articles.map((art) => {
      const findArtInOpt = options.find((item) => item.id === art.id);

      if (findArtInOpt) {
        return { ...findArtInOpt };
      } else {
        return {
          id: art.id,
          title: '',
          typeTitle: 'Прочее',
          typeId: 0,
        };
      }
    });
  }
);
const onChange = documentFormModel.formEvent.setArticles.prepend(
  (payload: CombineArticle[] | CombineArticle | null): number[] => {
    if (!payload) {
      return [];
    }
    if (Array.isArray(payload)) {
      return payload.map((item) => item.id);
    } else {
      return [payload.id];
    }
  }
);

export const articlesAutocomplite = {
  options,
  value,
  events: { onChange },
};
export const fetchArticles = {
  fetchTypeArticleFx,
  fetchArticlesFx,
};
