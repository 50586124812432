import { AuthServiceResponsesOnlyPermission } from 'shared/api/services-auth/types';

export const createPermissionDeniedObj = (
  guid: string
): AuthServiceResponsesOnlyPermission => {
  return {
    isDeletable: false,
    isReadable: false,
    isWritable: false,
    moduleGuid: guid,
  };
};
