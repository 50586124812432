import { TextField } from '@mui/material';
import { useStoreMap } from 'effector-react';
import { FC } from 'react';

import { CounterpartyModel } from 'shared/api/services-document-flow/types';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormCounterPartyReasonCode: FC<{
  options: CounterpartyModel[];
}> = ({ options }) => {
  const [isMobile] = useDevice();
  const value = useStoreMap({
    store: documentFormModel.form,
    keys: options,
    fn: (document, options) => {
      if (document) {
        return (
          options.find((item) => item.id === document.counterpartyId) || null
        );
      }

      return null;
    },
  });
  const inputValue = documentFormModel.useValueDocumentFormModel(
    'counterpartyRegReasonCode'
  );
  const inputValueReg = documentFormModel.useValueDocumentFormModel(
    'counterpartyTaxNumber'
  );
  const onChangeCounterParty = (
    event: React.SyntheticEvent<Element, Event>,
    value: CounterpartyModel | string | null
  ) => {
    if (typeof value === 'string') {
      /* documentFormModel.formEvent.setField({ key: "counterpartyRegReasonCode", value: value }); */
    } else {
      documentFormModel.formEvent.setField({
        key: 'counterpartyId',
        value: value ? value.id : null,
      });
      documentFormModel.formEvent.setField({
        key: 'counterpartyTaxNumber',
        value: value ? value.taxNumber : null,
      });
      documentFormModel.formEvent.setField({
        key: 'counterpartyRegReasonCode',
        value: value ? value.regReasonCode : null,
      });
    }
  };

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        /*  disabled: !isCounterpartyRequired, */
        freeSolo: true,
        onChange: onChangeCounterParty,
        value: value,
        options: options.filter((item) => item.regReasonCode),
        inputValue: inputValue || '',
        onInputChange: (event, value) => {
          documentFormModel.formEvent.setField({
            key: 'counterpartyRegReasonCode',
            value: value,
          });
        },
        renderInput: (params) => (
          <TextField
            {...params}
            /* required={true} */
            margin={'none'}
            size='small'
            label={isMobile ? '' : 'КПП'}
            placeholder='Введите КПП контрагента'
          />
        ),
        filterOptions: (options) => {
          return options.filter(
            (option) => option.taxNumber?.includes(inputValueReg || '')
          );
        },
        getOptionLabel: (option) => {
          if (typeof option === 'string') {
            return option;
          } else {
            return option.regReasonCode || '';
          }
        },
        renderOption: (props, option) => (
          <li
            {...props}
            key={option.id}
          >
            {option.regReasonCode}
          </li>
        ),
        isOptionEqualToValue: (option, value) => option.id === value.id,
      }}
      sx={{ flexBasis: ['100%', '45%'] }}
      label='КПП'
    />
  );
};
