import {
  EvaluationPaperForm,
  EvaluationPaperFormI,
  EvaluationPaperFormSkeleton,
  prepareDataToFetchStage4,
} from 'features/visit/Stage 4';
import { useMemo } from 'react';

import { postChecklistsVisitingChecklistIdStages3 } from 'shared/api/visit/services';

import { useChecklist } from 'entities/visit/checkList/context/ChecklistContext';

import { STAGES } from './VisitLayout';

export const Stage4Page = () => {
  const { checkList, getCheckList, isLoading, stageNavigate, status } =
    useChecklist();

  const onSubmit = async (data: EvaluationPaperFormI) => {
    if (checkList) {
      if (!checkList.canEdit) {
        stageNavigate(STAGES.stage5);

        return;
      }

      try {
        const fetchData = prepareDataToFetchStage4(data);

        await postChecklistsVisitingChecklistIdStages3(checkList.id, fetchData);
        await getCheckList();

        stageNavigate(STAGES.stage5);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const initVal = useMemo(() => {
    if (checkList && checkList.blocks)
      return {
        blocks: checkList.blocks.map((block) => ({
          ...block,
          items: block.items?.map((item) => ({
            ...item,
            comment: item.comment || undefined,
          })),
        })),
      };
    else return { blocks: [] };
  }, [checkList]);

  if (isLoading && !checkList) return <EvaluationPaperFormSkeleton />;
  if (!checkList || status === 400) return <h1>Такого чеклиста нет</h1>;

  return (
    <EvaluationPaperForm
      onSubmit={onSubmit}
      onDraftSubmit={onSubmit}
      initValue={initVal}
      disableAll={!checkList.canEdit}
    />
  );
};
