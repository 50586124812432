import { PageOpeningTemplateGuid } from 'pages/retail-opening-process/PageOpeningTemplateGuid';

import { PageOpeningProcessProtocol } from 'pages/retail-opening-process/PageOpeningProcessProtocol';
import { PageRetailOpeningTemplates } from 'pages/retail-opening-process/PageRetailOpeningTemplates';
import { PageRunningProcesses } from 'pages/retail-opening-process/PageRunningProcesses';
import { PageRunProcessForTemplate } from 'pages/retail-opening-process/PageRunProcessForTemplate';
import { RouteObject } from 'react-router-dom';
import { ALL_ROUTES } from './constants/types';

export const ROUTES_RETAIL_OPENING_PROCESS: RouteObject[] = [
  {
    path: ALL_ROUTES.retailOpeningProcess,

    children: [
      /* шаблоны и запуск процесса */
      {
        path: ALL_ROUTES.openingTemplates,
        element: <PageRetailOpeningTemplates />,
      },
      {
        path: ALL_ROUTES.openingTemplate,
        element: <PageOpeningTemplateGuid />,
      },
      {
        path: ALL_ROUTES.openingTemplateRunProcess,
        element: <PageRunProcessForTemplate />,
      },
      /* список протоколов */
      {
        path: ALL_ROUTES.retailOpeningProcessProtocol,
        element: <PageRunningProcesses />,
      },
      {
        path: ALL_ROUTES.retailOpeningProcessProtocolGuid,
        element: <PageOpeningProcessProtocol />,
      },
    ],
  },
];
