import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { SwaggerResponse } from 'shared/api/visit/config';
import { DepartmentGetListResponse } from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

import {
  $planningRNDepartments,
  fetchPlanningRNDepartmentsFX,
} from './effector/planningRNDepartments';

let fetching: Promise<SwaggerResponse<DepartmentGetListResponse[]>> | null =
  null;

export const useGetDepartments = () => {
  const { open: openSnackbar } = useSnackbar();

  const isLoading = useStore(fetchPlanningRNDepartmentsFX.pending);
  const departments = useStore($planningRNDepartments);

  const getAllDepartments = async () => {
    try {
      if (departments && departments.length > 0) return;
      if (!fetching) fetching = fetchPlanningRNDepartmentsFX();

      const { data, status } = await fetching;

      fetching = null;

      if (!data || status !== 200) throw new Error('Упс, что-то пошло не так');
    } catch (e) {
      console.log('useGetDepartments.getAllDepartments >> ', e);
      openSnackbar({
        duration: 3000,
        type: 'error',
        text: 'Упс, что-то пошло не так',
      });
    }
  };

  useEffect(() => {
    getAllDepartments();
  }, []);

  return { isLoading, departments };
};
