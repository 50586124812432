import { SvgIconProps } from '@mui/material';

export enum TaskCardStatuses {
  /**
   * @description Новая
   */
  new,

  /**
   * @description На проверке
   */
  check,

  /**
   * @description Завершена
   */
  complete,
}

export interface TaskCardStatusIconProps {
  status: TaskCardStatuses;
  iconProps?: SvgIconProps;
  disable?: boolean;
}
