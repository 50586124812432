import { Grid, Typography } from '@mui/material';
import { ExpensesFormButtonDeleteExpense } from 'features/document/expenses-form-button-delete-expense/ExpensesFormButtonDeleteExpense';
import { ExpensesFormCountExpense } from 'features/document/expenses-form-count-expense/ExpensesFormCountExpense';
import { ExpenseFormExpendetCondition } from 'features/document/expenses-form-expendet-condition/ExpenseFormExpendetCondition';
import { ExpensesFormPriceExpens } from 'features/document/expenses-form-price-expens/PriceTextFieldExpenses';
import { ExpenseTypeAutocomplite } from 'features/document/expenses-form-type-expense-autocomplite/ExpenseTypeAutocomplite';
import { FC, memo } from 'react';

import { DocumentExpenseSecondValidationRequest } from 'shared/api/services-document-flow/types';

export const ExpensesFormExpense: FC<{
  indexExpenses: number;
  idArticle: number;
  expenses: DocumentExpenseSecondValidationRequest[];
}> = memo(({ indexExpenses, idArticle, expenses }) => {
  return (
    <Grid
      container
      sx={{ display: 'flex' }}
      justifyContent={'space-between'}
      paddingTop={3}
    >
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography>{`Расход №${indexExpenses + 1}`}</Typography>
        <ExpensesFormButtonDeleteExpense
          indexExpenses={indexExpenses}
          idArticle={idArticle}
        />
      </Grid>
      <ExpenseTypeAutocomplite
        indexExpenses={indexExpenses}
        idArticle={idArticle}
        expenses={expenses}
      />
      <ExpenseFormExpendetCondition
        indexExpense={indexExpenses}
        idArticle={idArticle}
      />

      <ExpensesFormCountExpense
        idArticle={idArticle}
        indexExpenses={indexExpenses}
      />
      <ExpensesFormPriceExpens
        idArticle={idArticle}
        indexExpenses={indexExpenses}
      />
    </Grid>
  );
});
