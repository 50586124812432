import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useViewModal } from 'shared/hooks/useViewModal';

import { ModalFormCreatedTask } from 'widgets/tasks/modal-form-create-task/ModalFormCreatedTask';
import { ModalFormFastCreateTask } from 'widgets/tasks/modal-form-create-task/ModalFormFastCreateTask';

import { Add, Bolt } from '@mui/icons-material';
import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';
import { SearchBarWithActions } from 'shared/ui/SearchBarWithActions';
import { ManagerBarTasksProps } from './type';

export const ManagerBarTasks: FC<ManagerBarTasksProps> = ({
  openSettings,
  onCompliteInput,
  initSearch = '',
}) => {
  /* Search */
  const [search, setSearch] = useState(initSearch);
  /* ===== */

  useEffect(() => {
    setSearch(initSearch);
  }, [initSearch]);

  const {
    open: openTaskModal,
    close: closeTaskModal,
    isOpen: isOpenTaskModal,
  } = useViewModal();
  const {
    open: openFastTaskModal,
    close: closeFastTaskModal,
    isOpen: isOpenFastTaskModal,
  } = useViewModal();

  const handleClear = useCallback(() => {
    setSearch('');
    onCompliteInput('');
  }, [onCompliteInput]);

  const actions = useMemo(() => {
    return [
      {
        tooltip: 'Новая задача',
        icon: <Add />,
        action: openTaskModal,
      },
      {
        tooltip: 'Быстрая задача',
        icon: <Bolt />,
        action: openFastTaskModal,
      },
    ];
  }, [openTaskModal, openFastTaskModal]);

  return (
    <SearchBarWithActions
      actions={actions}
      onCompliteInput={onCompliteInput}
      textValue={search}
      changeValue={setSearch}
      onClear={handleClear}
    >
      <FilterButton onClick={openSettings} />

      <ModalFormCreatedTask
        isOpen={isOpenTaskModal}
        onClose={closeTaskModal}
      />
      <ModalFormFastCreateTask
        isOpen={isOpenFastTaskModal}
        onClose={closeFastTaskModal}
      />
    </SearchBarWithActions>
  );
};
