import { VisitingChecklistGetResponse } from 'shared/api/visit/types';

import { EventsFormI } from '../type';

type PrepareDataToInitStage6Type = (
  data: VisitingChecklistGetResponse
) => EventsFormI;

export const prepareDataToInitStage6: PrepareDataToInitStage6Type = (data) => {
  const { id } = data;

  if (data.problems) {
    return {
      checklistId: id,
      problems: data.problems.map((problem) => ({
        id: problem.problemId,
        idBackend: problem.problemId,
        title: problem.problemTitle || '',
        tasks: problem.problemTasks
          ? problem.problemTasks.map((task) => ({
              id: task.id,
              title: task.title || '',
              status: task.status || '',
              executor: task.executor || '',
              dateFinish: task.dateFinish || '',
            }))
          : [],
      })),
    };
  } else return { problems: [], checklistId: id };
};
