import { useEffect, useState } from 'react';

import { getEmployeesVisitingHomies } from 'shared/api/visit/services';
import { EmployeeVisitingHomiesGetListResponse } from 'shared/api/visit/types';

export const useGetEmployees = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<
    EmployeeVisitingHomiesGetListResponse[]
  >([]);

  const getEmployee = async () => {
    setIsLoading(true);

    const { data, status } = await getEmployeesVisitingHomies();

    if (status === 200 && data) setEmployees(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return { employees, isLoading };
};
