// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { createStore } from 'effector';
import { createEvent } from 'effector-logger';
import { useEffect } from 'react';

import { DocumentCurrentActionsResponse } from 'shared/api/services-document-flow/types';

import { getDocumentIdCurrentActionsFx } from '../api/document-actions';

const reset = createEvent();
const $documentIdActions = createStore<null | DocumentCurrentActionsResponse>(
  null
);

$documentIdActions.on(
  getDocumentIdCurrentActionsFx.doneData,
  (_, response) => response.data
);
$documentIdActions.reset(reset);

const useInitDocumentActions = (id: number | undefined) => {
  useEffect(() => {
    id && getDocumentIdCurrentActionsFx(id);

    return () => {
      reset();
    };
  }, [id]);
};

export const documentIdActions = {
  actions: $documentIdActions,
  useInit: useInitDocumentActions,
};
