import { IcecreamOutlined } from '@mui/icons-material';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemButton from '@mui/material/ListItemButton/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { NavLink } from 'react-router-dom';
import { theme } from 'shared/theme/theme';
import { LIST_ITEM_BUTTON_SX, LIST_ITEM_ICON_SX } from './constants';
import { CustomListItemLinkDrawerComponent } from './types';

export const CustomListItemLinkDrawer: CustomListItemLinkDrawerComponent = ({
  to,
  textPrimary,
  Icon,
  primaryTypographyProps,
  onClick,
  isOpenDrawer = true,
  target,
  disableBackground = false,
}) => {
  return (
    <NavLink
      to={to}
      onClick={onClick}
      target={target}
    >
      {({ isActive }) => {
        const bgCalor =
          isActive && !disableBackground
            ? theme.palette.primary.light
            : undefined;

        return (
          <ListItem
            disablePadding
            sx={{
              display: 'block',
            }}
          >
            <ListItemButton
              sx={{
                ...LIST_ITEM_BUTTON_SX,
                backgroundColor: bgCalor,
              }}
            >
              <ListItemIcon sx={LIST_ITEM_ICON_SX}>
                {Icon || <IcecreamOutlined sx={{ opacity: 0 }} />}
              </ListItemIcon>
              <ListItemText
                sx={{ opacity: isOpenDrawer ? 1 : 0 }}
                primary={textPrimary}
                primaryTypographyProps={{
                  ...primaryTypographyProps,
                  sx: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      }}
    </NavLink>
  );
};
