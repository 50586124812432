import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC } from 'react';

import { ALL_YEAR_MONTH } from '../planning-rn-table-components';
import { ReportPlanningRNTableProps } from './type';

export const ReportPlanningRNTable: FC<ReportPlanningRNTableProps> = ({
  info,
  visitors,
}) => {
  return (
    <TableContainer
      component={Paper}
      variant='outlined'
    >
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {ALL_YEAR_MONTH.map((month) => (
              <TableCell
                key={month}
                align='center'
                sx={{ fontWeight: 'bold' }}
              >
                {month}
              </TableCell>
            ))}
            <TableCell
              align='right'
              sx={{ fontWeight: 'bold' }}
            >
              Итог
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitors.map((visitor) => {
            const allVisits = info.filter(
              (el) => el.visitor.id === visitor.id
            ).length;

            return (
              <TableRow
                hover
                key={visitor.id}
              >
                <TableCell>{visitor.employeeName}</TableCell>
                {ALL_YEAR_MONTH.map((monthName, i) => {
                  const month = i + 1;
                  const count = info.filter(
                    (el) => el.visitor.id === visitor.id && el.month === month
                  ).length;

                  return (
                    <TableCell
                      align='center'
                      key={monthName}
                    >
                      {count || '-'}
                    </TableCell>
                  );
                })}
                <TableCell align='right'>{allVisits}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
