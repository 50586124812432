import { Tune } from '@mui/icons-material';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import React, { useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

import { DnDItem, DnDList, reorder } from 'shared/dnd';
import { theme } from 'shared/theme/theme';

import { FieldMapType } from './constants';

type UiMenuTablePropsType = <T>(props: {
  fields: FieldMapType<T>[];
  setFields: (value: FieldMapType<T>[]) => void;
}) => JSX.Element;

export const UiMenuTable: UiMenuTablePropsType = ({ fields, setFields }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const close = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const changeHandler = (field: string) => {
    setFields(
      fields.map((el2) =>
        el2.field === field ? { ...el2, isShow: !el2.isShow } : el2
      )
    );
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newItems = reorder(fields, source.index, destination.index).map(
      (el, i) => ({ ...el, order: i })
    );

    setFields(newItems);
  };

  return (
    <Grid>
      <IconButton onClick={open}>
        <Tune />
      </IconButton>

      <Menu
        open={isOpen}
        onClose={close}
        anchorEl={anchorEl}
      >
        <DnDList
          onDragEnd={onDragEnd}
          droppableId='123'
        >
          {fields.map((el) => (
            <DnDItem
              draggableId={String(el.field)}
              index={el.order}
              key={String(el.field)}
            >
              <MenuItem
                onClick={() => {
                  changeHandler(String(el.field));
                }}
                sx={{
                  width: '100%',
                  background: theme.palette.background.default,
                }}
              >
                <FormControlLabel
                  control={<Checkbox id={String(el.field)} />}
                  checked={el.isShow}
                  label={el.title}
                  sx={{ width: '100%' }}
                  onChange={() => {
                    changeHandler(String(el.field));
                  }}
                  onClick={(e) => e.stopPropagation()}
                />
              </MenuItem>
            </DnDItem>
          ))}
        </DnDList>
      </Menu>
    </Grid>
  );
};
