import { Grid } from '@mui/material';
import { TaskStatusChip } from 'features/task/task-status-chip/TaskStatusChip';
import { TaskStatuses } from 'features/task/task-status-chip/type';
import { FC } from 'react';

import { UiTableCard, UiTableCardList } from 'shared/ui/table';

import { ProblemTaskTableProps } from './type';

export const ProblemTaskList: FC<ProblemTaskTableProps> = ({
  data,
  fields,
  onChangeFields,
  visibleFields,
  renderActions,
  onClick,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
    >
      {data.map((task) => (
        <UiTableCard
          key={task.id}
          data={task}
          id={String(task.id)}
          visibleFields={visibleFields.filter((el) => el.field !== 'status')}
          renderHeaderChildren={() => (
            <Grid
              ml={1}
              py={1}
            >
              <TaskStatusChip
                status={(task.status as TaskStatuses) || TaskStatuses['Новая']}
              />
            </Grid>
          )}
          onClick={() => {
            onClick && onClick(task);
          }}
          renderActions={(isOpen, setIsLoading) => {
            return renderActions(task, { isOpen, setIsLoading });
          }}
        />
      ))}
    </UiTableCardList>
  );
};
