import { Typography } from '@mui/material';
import {
  RetailOpeningProcessResponsesGetListProtocol,
  RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate,
  RetailOpeningProcessResponsesOpeningTemplateGetListStage,
} from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
/* template */
export const getOptionLabelTemplate = (
  option: RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate
) => `${option.title} ${option.isAutorun ? '(Автоматический запуск)' : ''}`;

export const renderOptionTemplates = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate
) => (
  <Typography
    {...props}
    key={option.guid}
  >
    {getOptionLabelTemplate(option)}
  </Typography>
);
/* Protocol */
export const getOptionLabelProtocol = (
  option: RetailOpeningProcessResponsesGetListProtocol
) => `${option.title} / Аптека : ${option.retail ? option.retail?.title : '-'}`;

export const renderOptionProtocol = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: RetailOpeningProcessResponsesGetListProtocol
) => (
  <Typography
    {...props}
    key={option.guid}
  >
    {getOptionLabelProtocol(option)}
  </Typography>
);

/* Stages */
export const getOptionLabelStage = (
  option: RetailOpeningProcessResponsesOpeningTemplateGetListStage
) => `${option.stageNumber} Этап`;

export const renderOptionStage = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: RetailOpeningProcessResponsesOpeningTemplateGetListStage
) => (
  <Typography
    {...props}
    key={option.guid}
  >
    {getOptionLabelStage(option)}
  </Typography>
);
