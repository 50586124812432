import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { TextFieldWithController } from 'shared/fields-with-contoller';
import { ContainerActionsForm } from 'shared/ui/base-ui';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import { yupResolver } from '@hookform/resolvers/yup';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { object } from 'yup';
import { CreateFilterModalProps } from './type';

export const CreateFilterModal: FC<CreateFilterModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      title: '',
    },
    resolver: yupResolver(object().shape({ title: SCHEMA.STRING_REQUIRED })),
    shouldUnregister: true,
  });

  return (
    <DialogForForm
      onClose={onClose}
      open={isOpen}
      head={<DialogTitleForForm title='Создание закладки' />}
    >
      <Grid
        container
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
        gap={2}
      >
        <TextFieldWithController
          control={control}
          name={'title'}
          label={'Название'}
          placeholder='Введите название закладки'
          autoFocus
        />
        <ContainerActionsForm>
          <Button
            variant='contained'
            type='submit'
          >
            Создать
          </Button>
          <Button
            variant='contained'
            onClick={onClose}
            color='customGrey'
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
