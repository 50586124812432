import { Paper, TablePagination } from '@mui/material';
import { FC } from 'react';
import { BoardListPaginationProps } from './type';

export const BoardListPagination: FC<BoardListPaginationProps> = ({
  count,
  limit,
  onChangeLimit,
  onChangePage,
  page,
}) => {
  return (
    <TablePagination
      component={Paper}
      count={count}
      rowsPerPage={limit}
      rowsPerPageOptions={[10, 20, 50, 100]}
      onRowsPerPageChange={(e) => {
        onChangeLimit(Number(e.target.value));
      }}
      labelRowsPerPage={'Досок на странице'}
      onPageChange={(_, page) => {
        onChangePage(page + 1);
      }}
      page={page - 1}
    />
  );
};
