import { Place } from '@mui/icons-material';

export const getPointIcon = (color: string) => (
  <Place
    style={{
      fill: color,
      width: 50,
      height: 50,
      position: 'relative',
      left: '-150%',
      top: '-350%',
    }}
  />
);
