import { Typography } from '@mui/material';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

export const PageBannersMainSlider = () => {
  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography>PageBannersMainSlider</Typography>
      </LayoutPage.Bar>
    </LayoutPage.Page>
  );
};
