import { useCallback, useMemo, useState } from 'react';
import { useUrlStateRetailOpeningTemplatesFilter } from './use-url-state-retail-opening-templates';

export const useFilterOpeningRetailTemplates = () => {
  const { filter, setFilter } = useUrlStateRetailOpeningTemplatesFilter();
  const [searchText, setSearchText] = useState(filter?.SearchText || '');

  const Page = filter?.Page || 1;
  const Limit = filter?.Limit || 20;
  const SearchText = filter?.SearchText || '';
  const filterParams = useMemo(() => {
    return {
      Page,
      Limit,
      SearchText,
    };
  }, [Page, Limit, SearchText]);

  const onClearSearchText = useCallback(() => {
    setFilter({ ...filterParams, SearchText: '' });
    setSearchText('');
  }, [filterParams]);

  const onCompliteInput = useCallback(
    (value: string) => {
      setSearchText(value);

      setFilter({ ...filterParams, SearchText: value });
    },
    [filterParams]
  );

  const onChangeLimit = useCallback(
    (Limit: number) => {
      setFilter({ ...filterParams, Limit });
    },
    [filterParams]
  );
  const onChangePage = useCallback(
    (Page: number) => {
      setFilter({ ...filterParams, Page });
    },
    [filterParams]
  );

  return {
    onClearSearchText,
    setSearchText,
    searchText,
    onCompliteInput,
    filterParams,
    onChangeLimit,
    onChangePage,
  };
};
