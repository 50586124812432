import {
  Button as ButtonMui,
  ButtonProps as ButtonPropsMui,
} from '@mui/material';
import { FC } from 'react';

import {
  Link as LinkRouter,
  LinkProps as LinkPropsRouter,
} from 'react-router-dom';

export const LinkButton: FC<LinkPropsRouter & Omit<ButtonPropsMui, 'href'>> = ({
  children,
  ...props
}) => {
  return (
    <ButtonMui
      LinkComponent={LinkRouter}
      {...props}
    >
      {children}
    </ButtonMui>
  );
};
