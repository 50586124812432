//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

export interface AdditionallyFieldDateTimeModel {
  /**
   *
   * Id записи дополнительного поля<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Информация о дополнительном поле процесса
   */
  processAdditionallyField: ProcessAdditionallyFieldModel;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в ProcessAdditionallyField или api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: date-time
   */
  value?: string;
}

export interface AdditionallyFieldDecimalModel {
  /**
   *
   * Id записи дополнительного поля<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Информация о дополнительном поле процесса
   */
  processAdditionallyField: ProcessAdditionallyFieldModel;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в ProcessAdditionallyField или api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: double
   */
  value?: number;
}

export interface AdditionallyFieldIntModel {
  /**
   *
   * Id записи дополнительного поля<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Информация о дополнительном поле процесса
   */
  processAdditionallyField: ProcessAdditionallyFieldModel;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в ProcessAdditionallyField или api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: int32
   */
  value?: number;
}

export interface AdditionallyFieldPeriodModel {
  /**
   *
   * Id записи дополнительного поля<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Информация о дополнительном поле процесса
   */
  processAdditionallyField: ProcessAdditionallyFieldModel;
  /**
   *
   * Значение начала периода дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в ProcessAdditionallyField или api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: date-time
   */
  begValue?: string;
  /**
   *
   * Значение конца периода дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в ProcessAdditionallyField или api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: date-time
   */
  endValue?: string;
}

export interface AdditionallyFieldStringModel {
  /**
   *
   * Id записи дополнительного поля<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Информация о дополнительном поле процесса
   */
  processAdditionallyField: ProcessAdditionallyFieldModel;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в ProcessAdditionallyField или api/Processes/{processId:int}/AdditionallyFields<br />
   */
  value?: string;
}

export interface AgreeGroup {
  /**
   *
   * - Format: int32
   */
  agreeGroupId: number;
  /**
   *
   * - Format: int32
   */
  orderNumber: number;
}

export interface AgreeGroupCreate {
  isSubjectPositionsFixed: boolean;
  title?: string;
}

export interface AgreeGroupDelete {
  ids?: number[];
}

export interface AgreeGroupUpdate {
  /**
   *
   * - Format: int32
   */
  id: number;
  isSubjectPositionsFixed: boolean;
  title?: string;
}

export interface ArticleModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  articleType?: ArticleTypeModel;
  hasExpenses?: boolean;
  title?: string;
}

/**
 *
 * Статья
 */

export interface ArticleResponse {
  /**
   *
   * Id статьи
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование статьи
   * - minLength: 1
   */
  title: string;
  /**
 * 
 * Тип статьи:<br />
1 - Группа инвестиционных расходов;<br />
2 - Группа операционных расходов;<br />
 * - Format: int32
 */
  typeId: number;
}

export interface ArticleTypeModel {
  /**
   *
   * Id типа статьи
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование типа статьи
   * - minLength: 1
   */
  title: string;
}

export interface CloseDocumentRequest {
  /**
   *
   * Признак закрывающего документа в диадок
   */
  isCloseDocumentDiadok: boolean;
  /**
   *
   * Признак, определяющий, равны ли суммы закрывающего документа суммам документа-основания
   */
  isCloseDocumentSumEquals: boolean;
  /**
   *
   * Дата закрывающего документа
   * - Format: date-time
   */
  closeDocumentDate?: string;
  /**
   *
   * Ссылка на закрывающий документ в диадок (необходима, если IsCloseDocumentDiadok == true)
   */
  closeDocumentDiadokLink?: string;
  /**
   *
   * Номер закрывающего документа
   */
  closeDocumentNumber?: string;
  /**
   *
   * Сумма акта
   * - Format: double
   */
  closeDocumentSum?: number;
  /**
 * 
 * Id ставки НДС<br />
Используется для рассчета суммы НДС (можно не указывать, если указано CloseDocumentTaxSum)<br />
Options: api/TaxRates/Calculated<br />
 * - Format: int32
 */
  closeDocumentTaxRateId?: number;
  /**
   *
   * Сумма НДС (можно не указывать, если указано CloseDocumentTaxRateId)<br />
   * - Format: double
   */
  closeDocumentTaxSum?: number;
}

export interface ConstructorAgreeProcessModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  mainOfficePositionCategories?: ConstructorAgreeProcessPositionCategoryModel[];
  positionCategories?: ConstructorAgreeProcessPositionCategoryModel[];
  title?: string;
}

export interface ConstructorAgreeProcessPositionCategoryModel {
  /**
   *
   * - Format: int32
   */
  orderNumber: number;
  positionCategory: PositionCategoryModel;
}

export interface ConstructorArticleAgreeProcessConditionModel {
  isPercent: boolean;
  agreeProcess?: ConstructorAgreeProcessModel;
  /**
   *
   * - Format: double
   */
  conditionValue?: number;
}

export interface ConstructorArticleAgreeProcessModel {
  article: ArticleModel;
  conditions: ConstructorArticleAgreeProcessConditionModel[];
  isPercent: boolean;
  /**
   *
   * - Format: int32
   */
  processId: number;
  structPart: OrgStructPartModel;
}

export interface ConstructorOrgStructPartAgreeLinkModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  articles?: ArticleModel[];
  title?: string;
}

export interface ConstructorOrgStructPartAllowedLinkModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  articles?: ArticleModel[];
  title?: string;
}

export interface ConstructorPositionModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  title?: string;
}

export interface ConstructorPositionsLinkItemModel {
  creatorPosition: ConstructorPositionModel;
  /**
   *
   * - Format: int32
   */
  id: number;
}

export interface ConstructorProcessAdditionallyFieldModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  isArray: boolean;
  isRequired: boolean;
  processAdditionallyFieldType?: ProcessAdditionallyFieldTypeModel;
  title?: string;
}

export interface ConstructorProcessLinkModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  articles?: ArticleModel[];
  title?: string;
}

export interface ConstructorProcessModel {
  creator: EmployeeModel;
  /**
   *
   * - Format: int32
   */
  id: number;
  isAccountantPayment: boolean;
  isAllArticlesRequired: boolean;
  isArticleRetailRequired: boolean;
  isBossAgree: boolean;
  isCloseDocumentRequired: boolean;
  isCounterpartyRequired: boolean;
  isEstimate: boolean;
  isExpenseByPeriod: boolean;
  isExpenseRetailRequired: boolean;
  isExtendedAgree: boolean;
  isFinancesCrapAgreeRequired: boolean;
  isManagerAgree: boolean;
  isManualAgree: boolean;
  isMultiArticles: boolean;
  isOrganizationAgreeRequired: boolean;
  isOrganizationOnlyForMyOffice: boolean;
  isPaidByCashbox: boolean;
  isPaidBySecondCashbox: boolean;
  isParallelAgree: boolean;
  isReasonDocumentRequired: boolean;
  isRegisterPayment: boolean;
  isSecondFinancesCrapAgreeRequired: boolean;
  isServiceTypeRequired: boolean;
  isTaxAccountantAgreeRequired: boolean;
  isTaxCalculateRequired: boolean;
  isTransferToAccountablePerson: boolean;
  positions: ConstructorSecondPositionModel[];
  /**
   *
   * - Format: date-time
   */
  dateCreated?: string;
  periodTitle?: string;
  title?: string;
}

export interface ConstructorSecondPositionModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  /**
   *
   * - Format: int32
   */
  orderNumber: number;
  /**
   *
   * - minLength: 1
   */
  title: string;
}

export interface CounterpartyModel {
  /**
   *
   * Id контрагента
   * - Format: int32
   */
  id: number;
  /**
   *
   * КПП контрагента
   * - minLength: 1
   */
  regReasonCode: string;
  /**
   *
   * ИНН контрагента
   * - minLength: 1
   */
  taxNumber: string;
  /**
   *
   * Наименование контрагента
   * - minLength: 1
   */
  title: string;
}

export interface DelegateActionRequest {
  /**
 * 
 * Id делегируемого действия<br />
Options: api/Documents/{documentId}/DelegatedActions<br />
 * - Format: int32
 */
  actionTaskId: number;
  /**
 * 
 * Id сотрудника, которому делегируется действие<br />
Options: api/Employees/OfficeAll<br />
 * - Format: int32
 */
  employeeId: number;
}

export interface DeleteConstructorServiceTypesArchiveQueryParams {
  /**
   *
   * Список Id видов услуг
   */
  serviceTypesIds?: number[];
}

export interface DeleteConstructorServiceTypesQueryParams {
  /**
   *
   * Список Id видов услуг
   */
  serviceTypesIds?: number[];
}

export interface DepartmentModel {
  /**
   *
   * Id сети
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование сети
   * - minLength: 1
   */
  title: string;
}

export interface Document1CReasonDocumentFile {
  /**
   *
   * - Format: int32
   */
  documentId: number;
  /**
   *
   * - Format: int32
   */
  fileId: number;
  fileTitle?: string;
}

export interface DocumentAction {
  /**
   *
   * Id действия
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование действия
   * - minLength: 1
   */
  name: string;
}

export interface DocumentAdditionallyFieldDateTimeCreateRequest {
  /**
 * 
 * Id дополнительного поля процесса<br />
Options: api/Processes/{processId:int}/AdditionallyFields<br />
 * - Format: int32
 */
  id: number;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: date-time
   */
  value?: string;
}

export interface DocumentAdditionallyFieldDecimalCreateRequest {
  /**
 * 
 * Id дополнительного поля процесса<br />
Options: api/Processes/{processId:int}/AdditionallyFields<br />
 * - Format: int32
 */
  id: number;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: double
   */
  value?: number;
}

export interface DocumentAdditionallyFieldIntCreateRequest {
  /**
 * 
 * Id дополнительного поля процесса<br />
Options: api/Processes/{processId:int}/AdditionallyFields<br />
 * - Format: int32
 */
  id: number;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: int32
   */
  value?: number;
}

export interface DocumentAdditionallyFieldPeriodCreateRequest {
  /**
 * 
 * Id дополнительного поля процесса<br />
Options: api/Processes/{processId:int}/AdditionallyFields<br />
 * - Format: int32
 */
  id: number;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: date-time
   */
  beginValue?: string;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в api/Processes/{processId:int}/AdditionallyFields<br />
   * - Format: date-time
   */
  endValue?: string;
}

export interface DocumentAdditionallyFieldStringCreateRequest {
  /**
 * 
 * Id дополнительного поля процесса<br />
Options: api/Processes/{processId:int}/AdditionallyFields<br />
 * - Format: int32
 */
  id: number;
  /**
   *
   * Значение дополнительного поля процесса<br />
   *Необходимость заполнения определяется признаком IsRequired, указанном в api/Processes/{processId:int}/AdditionallyFields<br />
   */
  value?: string;
}

export interface DocumentAgreeProcessActionResponse {
  /**
   *
   * Id сотрудника
   * - Format: int32
   */
  employeeId: number;
  /**
   *
   * ФИО сотрудника
   * - minLength: 1
   */
  employeeName: string;
  /**
   *
   * Должность сотрудника
   * - minLength: 1
   */
  employeePosition: string;
  /**
   *
   * Признак, показывающий, что участник добавлен автоматически согласно настройкам цепочек
   */
  isProcessAgreeChain: boolean;
  /**
   *
   * Порядок сотрудника в цепочке согласования
   * - Format: int32
   */
  orderNumber: number;
  /**
   *
   * Id действия
   * - Format: int32
   */
  taskId: number;
  /**
   *
   * Наименование действия
   * - minLength: 1
   */
  taskTitle: string;
  /**
   *
   * Комментарий к действию
   */
  actionComment?: string;
  /**
   *
   * Дата совершения действия
   * - Format: date-time
   */
  actionDate?: string;
  /**
 * 
 * Признак действия:<br />
null - еще не совершено;<br />
true - отклонено;<br />
false - согласовано;<br />
 */
  isReject?: boolean;
}

export interface DocumentAgreeProcessRequest {
  /**
 * 
 * Id сотрудника<br />
Options: api/Employees/OfficeAll
 * - Format: int32
 */
  employeeId: number;
  /**
   *
   * Признак, показывающий, что участник был добавлен автоматически согласно настройкам цепочек
   */
  isProcessAgreeChain: boolean;
  /**
   *
   * Порядковый номер сотрудника в цепочке
   * - Format: int32
   */
  orderNumber: number;
}

export interface DocumentArticleExpenseExtenedConditionModel {
  /**
   *
   * Id условия
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование
   * - minLength: 1
   */
  title: string;
}

export interface DocumentArticleExpenseModel {
  /**
   *
   * Количество
   * - Format: double
   */
  count: number;
  /**
 * 
 * Id вида расхода<br />
Options: DocumentFirstValidationResponse.DocumentArticles[i].Expenses<br />
 * - Format: int32
 */
  expenseId: number;
  /**
   *
   * Id строки вида расхода<br />
   *Используется для возможности указывать несколько одинаковых видов расходов у статьи документа<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Рассчет количества производится из дней периода<br />
   *имеет значение, когда у процесса установлен признак IsExpenseByPeriod == true
   */
  isCountFromPeriod: boolean;
  /**
   *
   * Норматив
   * - Format: double
   */
  normSum: number;
  /**
   *
   * Стоимость ед.<br />
   *не используется, рассчитывается из Count и Sum<br />
   * - Format: double
   */
  price: number;
  /**
   *
   * Сумма расхода
   * - Format: double
   */
  sum: number;
  /**
   *
   * Наименование вида расхода
   * - minLength: 1
   */
  title: string;
  /**
   *
   * Описание
   */
  description?: string;
  /**
   *
   * Выбранное условие
   */
  extendedCondition?: DocumentArticleExpenseExtenedConditionModel;
  /**
   *
   * Выбранное подразделение
   */
  retail?: RetailModel;
}

export interface DocumentArticleFirstValidateResponse {
  /**
   *
   * Виды расходов статьи
   */
  expenses: DocumentExpenseFirstValidationResponse[];
  /**
   *
   * Есть виды расходов
   */
  hasExpenses: boolean;
  /**
   *
   * Id статьи
   * - Format: int32
   */
  id: number;
  /**
   *
   * Нименование статьи
   * - minLength: 1
   */
  title: string;
  /**
   *
   * Норматив по статье
   * - Format: double
   */
  normSum?: number;
}

export interface DocumentArticleModel {
  /**
   *
   * Id статьи
   * - Format: int32
   */
  articleId: number;
  /**
   *
   * Наименование статьи
   * - minLength: 1
   */
  articleTitle: string;
  /**
   *
   * Выбранные виды расходов статьи
   */
  documentArticleExpenses: DocumentArticleExpenseModel[];
  /**
   *
   * Сумма по статье
   * - Format: double
   */
  sum: number;
  /**
   *
   * Выбранная статья сметы
   */
  estimateDocumentArticle?: EstimateDocumentArticleModel;
  /**
   *
   * Норматив по статье<br />
   *Если не указан, то имеются виды расходов со своими нормативами
   * - Format: double
   */
  normSum?: number;
  /**
   *
   * Выбранное подразделение
   */
  retail?: RetailModel;
}

export interface DocumentArticleSecondValidateRequest {
  /**
 * 
 * Id статьи<br />
Options: api/Articles
 * - Format: int32
 */
  id: number;
  /**
 * 
 * Id статьи сметы<br />
Options: api/Documents/EstimateArticles<br />
 * - Format: int32
 */
  estimateDocumentArticleId?: number;
  /**
   *
   * Виды расходов
   */
  expenses?: DocumentExpenseSecondValidationRequest[];
  /**
 * 
 * Id подразделения<br />
*необходимо указывать, если у процесса установлен признак IsArticleRetailRequired == true или указана статья сметы (EstimateDocumentArticleId)<br />
Options: api/Retails<br />
 * - Format: int32
 */
  retailId?: number;
  /**
   *
   * Сумма статьи<br />
   *необходимо указывать, если Expenses не заполнен
   * - Format: double
   */
  sum?: number;
}

export interface DocumentArticleSecondValidateResponse {
  /**
   *
   * Виды расходов статьи
   */
  expenses: DocumentExpenseSecondValidationResponse[];
  /**
   *
   * Id статьи
   * - Format: int32
   */
  id: number;
  /**
   *
   * Сумма по статье
   * - Format: double
   */
  sum: number;
  /**
   *
   * Нименование статьи
   * - minLength: 1
   */
  title: string;
  /**
   *
   * Выбранная статья сметы
   */
  estimateDocumentArticle?: EstimateDocumentArticleModel;
  /**
   *
   * Норматив по статье<br />
   *Если не указан, то имеются виды расходов со своими нормативами
   * - Format: double
   */
  normSum?: number;
  /**
   *
   * Выбранное подразделение
   */
  retail?: RetailModel;
}

export interface DocumentCloseDocumentFileModel {
  /**
   *
   * Наименование файла
   * - minLength: 1
   */
  fileTitle: string;
  /**
   *
   * Id файла
   * - Format: int32
   */
  id: number;
}

export interface DocumentCommentCreateRequest {
  /**
   *
   * Текст нового комментария
   * - minLength: 1
   */
  comment: string;
  /**
   *
   * Id комментария, на который дается ответ
   * - Format: int32
   */
  parentId?: number;
}

export interface DocumentCommentResponse {
  /**
   *
   * Признак возможности удаления
   */
  canDelete: boolean;
  /**
   *
   * Текст комментария
   * - minLength: 1
   */
  comment: string;
  /**
   *
   * Автор комментария
   */
  creator: EmployeeModel;
  /**
   *
   * Дата комментария
   * - Format: date-time
   */
  dateCreated: string;
  /**
   *
   * Id комментария
   * - Format: int32
   */
  id: number;
  /**
   *
   * Id родительского комментария (если текущий комментарий является ответом на другой комментарий)
   * - Format: int32
   */
  parentId?: number;
}

export interface DocumentCreateRequest {
  /**
   *
   * Список заполненных статей
   */
  articles: DocumentArticleSecondValidateRequest[];
  /**
   *
   * Признак, определяющий, равны ли суммы закрывающего документа суммам документа основания
   */
  isCloseDocumentSumEquals: boolean;
  /**
   *
   * Id ЮЛ
   * - Format: int32
   */
  organizationId: number;
  /**
   *
   * Id процесса
   * - Format: int32
   */
  processId: number;
  /**
   *
   * Цепочка согласования
   */
  agreeChain?: DocumentAgreeProcessRequest[];
  /**
   *
   * Дата начала периода расчета - используется для рассчета поля "Количество" у вида расхода<br />
   *Информация о периоде необходима, если у процесса установлен признак IsExpenseByPeriod == true<br />
   * - Format: date-time
   */
  beginPeriod?: string;
  /**
   *
   * Дата Закрывающего документа<br />
   *Обязательна, если IsCloseDocumentDiadok == false<br />
   * - Format: date-time
   */
  closeDocumentDate?: string;
  /**
   *
   * Ссылка на закрывающий документ в Диадок<br />
   *Обязательна, если IsCloseDocumentDiadok == true<br />
   */
  closeDocumentDiadokLink?: string;
  /**
   *
   * Номер закрывающего документа<br />
   *Обязателен, если IsCloseDocumentDiadok == false<br />
   */
  closeDocumentNumber?: string;
  /**
   *
   * Сумма акта Закрывающего документа
   * - Format: double
   */
  closeDocumentSum?: number;
  /**
 * 
 * Id ставки НДС для Закрывающего документа<br />
Используется для рассчета суммы НДС<br />
*Можно не указывать, если указано CloseDocumentTaxSum<br />
Options: api/TaxRates/Calculated<br />
 * - Format: int32
 */
  closeDocumentTaxRateId?: number;
  /**
   *
   * Сумма НДС Закрывающего документа<br />
   *Можно не указывать, если указано CloseDocumentTaxRateId<br />
   * - Format: double
   */
  closeDocumentTaxSum?: number;
  /**
   *
   * Ссылка на договор с контрагентом
   */
  counterpartyContractLink?: string;
  /**
   *
   * Номер договора с контрагентом
   */
  counterpartyContractNumber?: string;
  /**
 * 
 * Id контрагента<br />
*Информация о контрагенте необходима, если у процесса установлен признак IsTransferToAccountablePerson == true, а признак IsTransferToAccountablePerson == false.<br />
Может быть null, если указан CounterpartyTaxNumber.<br />
Options: api/Counterparties<br />
 * - Format: int32
 */
  counterpartyId?: number;
  /**
   *
   * КПП контрагента<br />
   *Информация о контрагенте необходима, если у процесса установлен признак IsTransferToAccountablePerson == true, а признак IsTransferToAccountablePerson == false.<br />
   */
  counterpartyRegReasonCode?: string;
  /**
 * 
 * ИНН контрагента<br />
*Информация о контрагенте необходима, если у процесса установлен признак IsTransferToAccountablePerson == true, а признак IsTransferToAccountablePerson == false.<br />
Может быть null, если указан CounterpartyId.<br />
 */
  counterpartyTaxNumber?: string;
  /**
   *
   * Дополнительные поля типа дата-время<br />
   */
  dateTimeAdditionallyFields?: DocumentAdditionallyFieldDateTimeCreateRequest[];
  /**
   *
   * Дополнительные поля типа число с плавающей запятой<br />
   */
  decimalAdditionallyFields?: DocumentAdditionallyFieldDecimalCreateRequest[];
  /**
   *
   * Дата окончания периода расчета
   * - Format: date-time
   */
  endPeriod?: string;
  /**
   *
   * Дополнительные поля типа целое число<br />
   */
  intAdditionallyFields?: DocumentAdditionallyFieldIntCreateRequest[];
  /**
   *
   * Признак закрывающего документа в Диадок<br />
   *Информация по закрывающему документу необходима, если у процесса установлен признак IsCloseDocumentRequired == true<br />
   */
  isCloseDocumentDiadok?: boolean;
  /**
   *
   * Оплатить до
   * - Format: date-time
   */
  payBeforeDate?: string;
  /**
   *
   * Дополнительные поля типа период<br />
   */
  periodAdditionallyFields?: DocumentAdditionallyFieldPeriodCreateRequest[];
  /**
   *
   * Дата документа основания
   *Необходима, если у типа документа основания установлен признак IsOtherInformationRequired == true<br />
   * - Format: date-time
   */
  reasonDocumentDate?: string;
  /**
   *
   * Номер документа основания
   *Необходим, если у типа документа основания установлен признак IsOtherInformationRequired == true<br />
   */
  reasonDocumentNumber?: string;
  /**
 * 
 * Тип документа основания<br />
*Необходим, если у процесса установлен признак IsReasonDocumentRequired == true<br />
Options: api/ReasonDocumentTypes<br />
 * - Format: int32
 */
  reasonDocumentTypeId?: number;
  /**
   *
   * Id вида услуги.<br />
   *Обязателен и сохраняется, если у процесса IsServiceTypeRequired == true
   * - Format: int32
   */
  serviceTypeId?: number;
  /**
   *
   * Дополнительные поля типа строка<br />
   */
  stringAdditionallyFields?: DocumentAdditionallyFieldStringCreateRequest[];
  /**
 * 
 * Id ставки НДС<br />
Используется для рассчета суммы НДС<br />
*Информация о НДС необходима, если у процесса установлен признак IsTaxCalculateRequired == true<br />
*Можно не указывать, если указано TaxSum<br />
Options: api/TaxRates/Calculated<br />
 * - Format: int32
 */
  taxRateId?: number;
  /**
   *
   * Сумма НДС документа<br />
   *Информация о НДС необходима, если у процесса установлен признак IsTaxCalculateRequired == true<br />
   *Можно не указывать, если указано TaxRateId<br />
   * - Format: double
   */
  taxSum?: number;
}

export interface DocumentCurrentActionsResponse {
  /**
   *
   * Признак возможности удаления документа
   */
  canDelete: boolean;
  /**
   *
   * Признак возможности редактирования документа
   */
  canEdit: boolean;
  /**
   *
   * Список доступных действий
   */
  documentActions: DocumentAction[];
}

export interface DocumentEstimatedArticleModel {
  /**
   *
   * Сумма по статье
   * - Format: double
   */
  documentArticleSum: number;
  /**
   *
   * Наименование статьи
   * - minLength: 1
   */
  documentArticleTitle: string;
  /**
   *
   * Инициатор
   * - minLength: 1
   */
  documentCreator: string;
  /**
   *
   * Id документа, где была применена смета
   * - Format: int32
   */
  documentId: number;
  /**
   *
   * Статус документа
   * - minLength: 1
   */
  documentStatusTitle: string;
  /**
   *
   * Заголовок документа
   * - minLength: 1
   */
  documentTitle: string;
}

export interface DocumentExpenseFirstValidationResponse {
  /**
   *
   * Список доступных условий
   */
  extendedConditions: DocumentExtendedConditionFirstValidationResponse[];
  /**
   *
   * Имеются условия
   */
  hasExtendedConditions: boolean;
  /**
   *
   * Id вида расхода
   * - Format: int32
   */
  id: number;
  /**
   *
   * Рассчет количества производится из дней периода<br />
   *имеет значение, когда у процесса установлен признак IsExpenseByPeriod == true
   */
  isCountFromPeriod: boolean;
  /**
   *
   * Для вида расхода необходимо указать смету
   */
  isEstimateRequered: boolean;
  /**
   *
   * Норматив
   * - Format: double
   */
  normSum: number;
  /**
   *
   * Наименование вида расхода
   * - minLength: 1
   */
  title: string;
  /**
   *
   * Описание
   */
  description?: string;
}

export interface DocumentExpenseSecondValidationRequest {
  /**
   *
   * Количество
   * - Format: double
   */
  count: number;
  /**
 * 
 * Id вида расхода<br />
Options: DocumentFirstValidationResponse.DocumentArticles[i].Expenses<br />
 * - Format: int32
 */
  expenseId: number;
  /**
   *
   * Id строки вида расхода<br />
   *Используется для возможности указывать несколько одинаковых видов расходов у статьи документа<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Сумма расхода
   * - Format: double
   */
  sum: number;
  /**
 * 
 * Id условия вида расхода<br />
Options: DocumentFirstValidationResponse.DocumentArticles[i].Expenses[j].ExtendedConditions
 * - Format: int32
 */
  extendedConditionId?: number;
  /**
   *
   * Стоимость ед.<br />
   *не используется, рассчитывается из Count и Sum<br />
   * - Format: double
   */
  price?: number;
  /**
 * 
 * Id подразделения<br />
*Используется, если не указано подразделение у статьи (DocumentSecondValidateRequest.Articles[i].RetailId)<br />
Options: api/Retails<br />
 * - Format: int32
 */
  retailId?: number;
}

export interface DocumentExpenseSecondValidationResponse {
  /**
   *
   * Количество
   * - Format: double
   */
  count: number;
  /**
 * 
 * Id вида расхода<br />
Options: DocumentFirstValidationResponse.DocumentArticles[i].Expenses<br />
 * - Format: int32
 */
  expenseId: number;
  /**
   *
   * Имеются условия
   */
  hasExtendedConditions: boolean;
  /**
   *
   * Id строки вида расхода<br />
   *Используется для возможности указывать несколько одинаковых видов расходов у статьи документа<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Рассчет количества производится из дней периода<br />
   *имеет значение, когда у процесса установлен признак IsExpenseByPeriod == true
   */
  isCountFromPeriod: boolean;
  /**
   *
   * Для вида расхода необходимо указать смету
   */
  isEstimateRequered: boolean;
  /**
   *
   * Норматив
   * - Format: double
   */
  normSum: number;
  /**
   *
   * Стоимость ед.<br />
   *не используется, рассчитывается из Count и Sum<br />
   * - Format: double
   */
  price: number;
  /**
   *
   * Сумма расхода
   * - Format: double
   */
  sum: number;
  /**
   *
   * Наименование вида расхода
   * - minLength: 1
   */
  title: string;
  /**
   *
   * Описание
   */
  description?: string;
  /**
   *
   * Выбранное условие
   */
  extendedCondition?: DocumentExtendedConditionSecondValidationResponse;
  /**
   *
   * Выбранное подразделение
   */
  retail?: RetailModel;
}

export interface DocumentExtendedConditionFirstValidationResponse {
  /**
   *
   * Id условия
   * - Format: int32
   */
  id: number;
  /**
   *
   * Норматив
   * - Format: double
   */
  normSum: number;
  /**
   *
   * Наименование
   * - minLength: 1
   */
  title: string;
}

export interface DocumentExtendedConditionSecondValidationResponse {
  /**
   *
   * Id условия
   * - Format: int32
   */
  id: number;
  /**
   *
   * Норматив
   * - Format: double
   */
  normSum: number;
  /**
   *
   * Наименование
   * - minLength: 1
   */
  title: string;
}

export interface DocumentFirstValidateRequest {
  /**
   *
   * Список Id выбранных статей
   */
  documentArticleIds: number[];
  /**
   *
   * Id ЮЛ
   * - Format: int32
   */
  organizationId: number;
  /**
   *
   * Id запускаемого процесса
   * - Format: int32
   */
  processId: number;
}

export interface DocumentFirstValidationResponse {
  /**
   *
   * Список доступных статей
   */
  documentArticles: DocumentArticleFirstValidateResponse[];
}

export interface DocumentListItemAgreeProcessResponse {
  /**
   *
   * Id сотрудника
   * - Format: int32
   */
  employeeId: number;
  /**
   *
   * ФИО сотрудника
   * - minLength: 1
   */
  employeeName: string;
  /**
   *
   * Порядок сотрудника в цепочке согласования
   * - Format: int32
   */
  orderNumber: number;
  /**
 * 
 * Признак действия:<br />
null - еще не совершено;<br />
true - отклонено;<br />
false - согласовано;<br />
 */
  isReject?: boolean;
}

export interface DocumentListItemPaidByCashboxResponse {
  /**
   *
   * Guid статьи
   * - Format: uuid
   */
  articleGuid: string;
  /**
   *
   * Сумма по статье
   * - Format: double
   */
  articleSum: number;
  /**
   *
   * Дата согласования
   * - Format: date-time
   */
  dateAgree: string;
  /**
   *
   * Id документа
   * - Format: int32
   */
  documentId: number;
  /**
   *
   * Guid подразделения
   * - Format: uuid
   */
  retailGuid: string;
}

export interface DocumentListItemResponse {
  /**
   *
   * Задание
   * - minLength: 1
   */
  action: string;
  /**
   *
   * Цепочка согласования
   */
  agreeProcess: DocumentListItemAgreeProcessResponse[];
  /**
   *
   * Кол-во комментариев
   * - Format: int32
   */
  commentCount: number;
  /**
   *
   * Инициатор
   * - minLength: 1
   */
  creator: string;
  /**
   *
   * Дата создания
   * - Format: date-time
   */
  dateCreated: string;
  /**
   *
   * Есть непрочитанные комментарии
   */
  hasUnreadComment: boolean;
  /**
   *
   * Id документа
   * - Format: int32
   */
  id: number;
  /**
   *
   * ЮЛ
   * - minLength: 1
   */
  organization: string;
  /**
   *
   * Файлы документа основания
   */
  reasonDocumentFiles: DocumentReasonDocumentFileModel[];
  /**
   *
   * Статус
   * - minLength: 1
   */
  status: string;
  /**
   *
   * Наименование документа
   * - minLength: 1
   */
  title: string;
  /**
   *
   * Контрагент
   */
  counterparty?: string;
  /**
   *
   * Номер договора
   */
  counterpartyContractNumber?: string;
  /**
   *
   * Вид операции
   */
  operation?: string;
  /**
   *
   * Оплатить до
   * - Format: date-time
   */
  payBeforeDate?: string;
}

export interface DocumentModel {
  /**
   *
   * Возможность редактирования документа
   */
  canEdit: boolean;
  /**
   *
   * Возможность редактирования дополнительных полей
   */
  canEditAdditionallyFields: boolean;
  /**
   *
   * Возможность редактирования цепочки согласования
   */
  canEditAgreeChain: boolean;
  /**
   *
   * Возможность редактирования информации по статьям документа
   */
  canEditArticles: boolean;
  /**
   *
   * Возможность редактирования контрагента
   */
  canEditCounterparty: boolean;
  /**
   *
   * Возможность редактирования ЮЛ
   */
  canEditOrganization: boolean;
  /**
   *
   * Возможность редактирования дату оплаты
   */
  canEditPayBeforeDate: boolean;
  /**
   *
   * Возможность редактирования информации по документу основания
   */
  canEditReasonDocument: boolean;
  /**
   *
   * Возможность редактирования информации по НДС
   */
  canEditTax: boolean;
  /**
   *
   * Прикрепленные файлы Закрывающего документа
   */
  closeDocumentFiles: DocumentCloseDocumentFileModel[];
  /**
   *
   * Создатель
   */
  creator: EmployeeModel;
  /**
   *
   * Дата создания
   * - Format: date-time
   */
  dateCreated: string;
  /**
   *
   * Дополнительные поля типа дата-время<br />
   */
  dateTimeAdditionallyFields: AdditionallyFieldDateTimeModel[];
  /**
   *
   * Дополнительные поля типа число с плавающей запятой<br />
   */
  decimalAdditionallyFields: AdditionallyFieldDecimalModel[];
  /**
   *
   * Список заполненных статей
   */
  documentArticles: DocumentArticleModel[];
  /**
   *
   * Статус документа
   */
  documentStatus: DocumentStatusModel;
  /**
   *
   * Id документа
   * - Format: int32
   */
  id: number;
  /**
   *
   * Дополнительные поля типа целое число<br />
   */
  intAdditionallyFields: AdditionallyFieldIntModel[];
  /**
   *
   * Признак закрывающего документа в Диадок<br />
   *Информация по закрывающему документу необходима, если у процесса установлен признак IsCloseDocumentRequired == true<br />
   */
  isCloseDocumentDiadok: boolean;
  /**
   *
   * Признак, определяющий, равны ли суммы закрывающего документа суммам документа основания
   */
  isCloseDocumentSumEquals: boolean;
  /**
   *
   * ЮЛ
   */
  organization: OrganizationModel;
  /**
   *
   * Дополнительные поля типа период<br />
   */
  periodAdditionallyFields: AdditionallyFieldPeriodModel[];
  /**
   *
   * Процесс
   */
  process: ProcessItemModel;
  /**
   *
   * Прикрепленные файлы документа основания
   */
  reasonDocumentFiles: DocumentReasonDocumentFileModel[];
  /**
   *
   * Дополнительные поля типа строка<br />
   */
  stringAdditionallyFields: AdditionallyFieldStringModel[];
  /**
   *
   * Заголовок документа
   * - minLength: 1
   */
  title: string;
  /**
   *
   * Дата начала периода расчета - используется для рассчета поля "Количество" у вида расхода<br />
   *Информация о периоде необходима, если у процесса установлен признак IsExpenseByPeriod == true<br />
   * - Format: date-time
   */
  beginPeriod?: string;
  /**
   *
   * Дата Закрывающего документа<br />
   *Обязательна, если IsCloseDocumentDiadok == false<br />
   * - Format: date-time
   */
  closeDocumentDate?: string;
  /**
   *
   * Ссылка на закрывающий документ в Диадок<br />
   *Обязательна, если IsCloseDocumentDiadok == true<br />
   */
  closeDocumentDiadokLink?: string;
  /**
   *
   * Номер закрывающего документа<br />
   *Обязателен, если IsCloseDocumentDiadok == false<br />
   */
  closeDocumentNumber?: string;
  /**
   *
   * Сумма акта Закрывающего документа
   * - Format: double
   */
  closeDocumentSum?: number;
  /**
 * 
 * Ставка НДС для Закрывающего документа<br />
Используется для рассчета суммы НДС<br />
*Можно не указывать, если указано CloseDocumentTaxSum<br />
Options: api/TaxRates/Calculated<br />
 */
  closeDocumentTaxRate?: TaxRateModel;
  /**
   *
   * Сумма НДС Закрывающего документа<br />
   *Можно не указывать, если указано CloseDocumentTaxRateId<br />
   * - Format: double
   */
  closeDocumentTaxSum?: number;
  /**
 * 
 * Контрагент<br />
*Информация о контрагенте необходима, если у процесса установлен признак IsTransferToAccountablePerson == true, а признак IsTransferToAccountablePerson == false.<br />
Может быть null, если указан CounterpartyTaxNumber.<br />
Options: api/Counterparties<br />
 */
  counterparty?: CounterpartyModel;
  /**
   *
   * Ссылка на договор с контрагентом
   */
  counterpartyContractLink?: string;
  /**
   *
   * Номер договора с контрагентом
   */
  counterpartyContractNumber?: string;
  /**
   *
   * КПП контрагента<br />
   *Информация о контрагенте необходима, если у процесса установлен признак IsTransferToAccountablePerson == true, а признак IsTransferToAccountablePerson == false.<br />
   */
  counterpartyRegReasonCode?: string;
  /**
 * 
 * ИНН контрагента<br />
*Информация о контрагенте необходима, если у процесса установлен признак IsTransferToAccountablePerson == true, а признак IsTransferToAccountablePerson == false.<br />
Может быть null, если указан CounterpartyId.<br />
 */
  counterpartyTaxNumber?: string;
  /**
   *
   * Дата согласования
   * - Format: date-time
   */
  dateAgree?: string;
  /**
   *
   * Дата завершения
   * - Format: date-time
   */
  dateCompleted?: string;
  /**
   *
   * Дата оплаты
   * - Format: date-time
   */
  datePaid?: string;
  /**
   *
   * Список статей документов, где была использована данная смета<br />
   *используется в документах-сметах, у процесса которых указан признак IsEstimate == true<br />
   */
  documentEstimatedArticles?: DocumentEstimatedArticleModel[];
  /**
   *
   * Дата окончания периода расчета
   * - Format: date-time
   */
  endPeriod?: string;
  /**
   *
   * Остаток по смете<br />
   *используется в документах-сметах, у процесса которых указан признак IsEstimate == true<br />
   * - Format: double
   */
  estimateBalance?: number;
  /**
   *
   * Дата приказа<br />
   *Заполняется при согласовании МП для документов процесса с признаком IsManagerAgree == true<br />
   * - Format: date-time
   */
  orderDate?: string;
  /**
   *
   * Номер приказа<br />
   *Заполняется при согласовании МП для документов процесса с признаком IsManagerAgree == true<br />
   */
  orderNumber?: string;
  /**
   *
   * Оплатить до
   * - Format: date-time
   */
  payBeforeDate?: string;
  /**
   *
   * Дата документа основания
   *Необходима, если у типа документа основания установлен признак IsOtherInformationRequired == true<br />
   * - Format: date-time
   */
  reasonDocumentDate?: string;
  /**
   *
   * Номер документа основания
   *Необходим, если у типа документа основания установлен признак IsOtherInformationRequired == true<br />
   */
  reasonDocumentNumber?: string;
  /**
 * 
 * Тип документа основания<br />
*Необходим, если у процесса установлен признак IsReasonDocumentRequired == true<br />
Options: api/ReasonDocumentTypes<br />
 */
  reasonDocumentType?: ReasonDocumentTypeModel;
  /**
   *
   * Вид услуги.<br />
   *Обязателен и сохраняется, если у процесса IsServiceTypeRequired == true
   */
  serviceType?: GetListServiceType;
  /**
 * 
 * Ставка НДС<br />
Используется для рассчета суммы НДС<br />
*Информация о НДС необходима, если у процесса установлен признак IsTaxCalculateRequired == true<br />
*Можно не указывать, если указано TaxSum<br />
Options: api/TaxRates/Calculated<br />
 */
  taxRate?: TaxRateModel;
  /**
   *
   * Сумма НДС документа<br />
   *Информация о НДС необходима, если у процесса установлен признак IsTaxCalculateRequired == true<br />
   *Можно не указывать, если указано TaxRate<br />
   * - Format: double
   */
  taxSum?: number;
  /**
   *
   * Общая сумма по документу
   * - Format: double
   */
  totalSum?: number;
}

export interface DocumentReasonDocumentFileModel {
  /**
   *
   * Наименование файла
   * - minLength: 1
   */
  fileTitle: string;
  /**
   *
   * Id файла
   * - Format: int32
   */
  id: number;
}

export interface DocumentSecondValidateRequest {
  /**
   *
   * Список заполненных статей
   */
  articles: DocumentArticleSecondValidateRequest[];
  /**
   *
   * Id ЮЛ
   * - Format: int32
   */
  organizationId: number;
  /**
   *
   * Id запускаемого процесса
   * - Format: int32
   */
  processId: number;
  /**
   *
   * Дата начала периода расчета - используется для рассчета поля "Количество" у вида расхода<br />
   *Информация о периоде необходима, если у процесса установлен признак IsExpenseByPeriod == true<br />
   * - Format: date-time
   */
  beginPeriod?: string;
  /**
   *
   * Id редактируемого документа<br />
   *Необходиом указывать, если валидация происходит по существующему документу
   * - Format: int32
   */
  documentId?: number;
  /**
   *
   * Дата окончания периода расчета
   * - Format: date-time
   */
  endPeriod?: string;
}

export interface DocumentSecondValidationResponse {
  /**
   *
   * Цепочка согласования
   */
  agreeChain: DocumentAgreeProcessActionResponse[];
  /**
   *
   * Список выбранных статей
   */
  documentArticles: DocumentArticleSecondValidateResponse[];
  /**
   *
   * Общая сумма
   * - Format: double
   */
  totalSum: number;
}

export interface DocumentStatusModel {
  /**
   *
   * Id статуса
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование статуса
   * - minLength: 1
   */
  title: string;
}

export interface DocumentUpdateRequest {
  /**
   *
   * Список заполненных статей
   */
  articles: DocumentArticleSecondValidateRequest[];
  /**
   *
   * Id ЮЛ
   * - Format: int32
   */
  organizationId: number;
  /**
   *
   * Id процесса
   * - Format: int32
   */
  processId: number;
  /**
   *
   * Цепочка согласования
   */
  agreeChain?: DocumentAgreeProcessRequest[];
  /**
   *
   * Дата начала периода расчета - используется для рассчета поля "Количество" у вида расхода<br />
   *Информация о периоде необходима, если у процесса установлен признак IsExpenseByPeriod == true<br />
   * - Format: date-time
   */
  beginPeriod?: string;
  /**
   *
   * Дата Закрывающего документа<br />
   *Обязательна, если IsCloseDocumentDiadok == false<br />
   * - Format: date-time
   */
  closeDocumentDate?: string;
  /**
   *
   * Ссылка на закрывающий документ в Диадок<br />
   *Обязательна, если IsCloseDocumentDiadok == true<br />
   */
  closeDocumentDiadokLink?: string;
  /**
   *
   * Номер закрывающего документа<br />
   *Обязателен, если IsCloseDocumentDiadok == false<br />
   */
  closeDocumentNumber?: string;
  /**
   *
   * Сумма акта Закрывающего документа
   * - Format: double
   */
  closeDocumentSum?: number;
  /**
 * 
 * Id ставки НДС для Закрывающего документа<br />
Используется для рассчета суммы НДС<br />
*Можно не указывать, если указано CloseDocumentTaxSum<br />
Options: api/TaxRates/Calculated<br />
 * - Format: int32
 */
  closeDocumentTaxRateId?: number;
  /**
   *
   * Сумма НДС Закрывающего документа<br />
   *Можно не указывать, если указано CloseDocumentTaxRateId<br />
   * - Format: double
   */
  closeDocumentTaxSum?: number;
  /**
   *
   * Ссылка на договор с контрагентом
   */
  counterpartyContractLink?: string;
  /**
   *
   * Номер договора с контрагентом
   */
  counterpartyContractNumber?: string;
  /**
 * 
 * Id контрагента<br />
*Информация о контрагенте необходима, если у процесса установлен признак IsTransferToAccountablePerson == true, а признак IsTransferToAccountablePerson == false.<br />
Может быть null, если указан CounterpartyTaxNumber.<br />
Options: api/Counterparties<br />
 * - Format: int32
 */
  counterpartyId?: number;
  /**
   *
   * КПП контрагента<br />
   *Информация о контрагенте необходима, если у процесса установлен признак IsTransferToAccountablePerson == true, а признак IsTransferToAccountablePerson == false.<br />
   */
  counterpartyRegReasonCode?: string;
  /**
 * 
 * ИНН контрагента<br />
*Информация о контрагенте необходима, если у процесса установлен признак IsTransferToAccountablePerson == true, а признак IsTransferToAccountablePerson == false.<br />
Может быть null, если указан CounterpartyId.<br />
 */
  counterpartyTaxNumber?: string;
  /**
   *
   * Дополнительные поля типа дата-время<br />
   */
  dateTimeAdditionallyFields?: DocumentAdditionallyFieldDateTimeCreateRequest[];
  /**
   *
   * Дополнительные поля типа число с плавающей запятой<br />
   */
  decimalAdditionallyFields?: DocumentAdditionallyFieldDecimalCreateRequest[];
  /**
   *
   * Дата окончания периода расчета
   * - Format: date-time
   */
  endPeriod?: string;
  /**
   *
   * Дополнительные поля типа целое число<br />
   */
  intAdditionallyFields?: DocumentAdditionallyFieldIntCreateRequest[];
  /**
   *
   * Признак закрывающего документа в Диадок<br />
   *Информация по закрывающему документу необходима, если у процесса установлен признак IsCloseDocumentRequired == true<br />
   */
  isCloseDocumentDiadok?: boolean;
  /**
   *
   * Признак, определяющий, равны ли суммы закрывающего документа суммам документа основания
   */
  isCloseDocumentSumEquals?: boolean;
  /**
   *
   * Оплатить до
   * - Format: date-time
   */
  payBeforeDate?: string;
  /**
   *
   * Дополнительные поля типа период<br />
   */
  periodAdditionallyFields?: DocumentAdditionallyFieldPeriodCreateRequest[];
  /**
   *
   * Дата документа основания
   *Необходима, если у типа документа основания установлен признак IsOtherInformationRequired == true<br />
   * - Format: date-time
   */
  reasonDocumentDate?: string;
  /**
   *
   * Номер документа основания
   *Необходим, если у типа документа основания установлен признак IsOtherInformationRequired == true<br />
   */
  reasonDocumentNumber?: string;
  /**
 * 
 * Тип документа основания<br />
*Необходим, если у процесса установлен признак IsReasonDocumentRequired == true<br />
Options: api/ReasonDocumentTypes<br />
 * - Format: int32
 */
  reasonDocumentTypeId?: number;
  /**
 * 
 * Id вида услуги.<br />
Обязателен и сохраняется, если у процесса IsServiceTypeRequired == true
 * - Format: int32
 */
  serviceTypeId?: number;
  /**
   *
   * Дополнительные поля типа строка<br />
   */
  stringAdditionallyFields?: DocumentAdditionallyFieldStringCreateRequest[];
  /**
 * 
 * Id ставки НДС<br />
Используется для рассчета суммы НДС<br />
*Информация о НДС необходима, если у процесса установлен признак IsTaxCalculateRequired == true<br />
*Можно не указывать, если указано TaxSum<br />
Options: api/TaxRates/Calculated<br />
 * - Format: int32
 */
  taxRateId?: number;
  /**
   *
   * Сумма НДС Закрывающего документа<br />
   *Информация о НДС необходима, если у процесса установлен признак IsTaxCalculateRequired == true<br />
   *Можно не указывать, если указано CloseDocumentTaxRateId<br />
   * - Format: double
   */
  taxSum?: number;
}

export interface DocumetnDoActionRequest {
  /**
   *
   * Id действия
   * - Format: int32
   */
  actionTaskId: number;
  /**
   *
   * Дополнительная информация к действию по прикреплению закрывающего документа и Завершить процесс
   */
  closeDocumentRequest?: CloseDocumentRequest;
  /**
   *
   * Комментарий к действию
   */
  comment?: string;
  /**
   *
   * Дополнительная информация к действию Делегировать
   */
  delegateActionsRequest?: DelegateActionRequest[];
  /**
   *
   * Дополнительная информация к действию МП
   */
  managerAgreeRequest?: ManagerAgreeRequest;
  /**
   *
   * Дополнительная информация к действию по согласованию ЮЛ
   */
  organizationRequest?: OrganizationRequest;
}

export interface EmployeeModel {
  /**
   *
   * ФИО сотрудника
   * - minLength: 1
   */
  employeeName: string;
  /**
   *
   * Должность сотрудника
   * - minLength: 1
   */
  employeePosition: string;
  /**
   *
   * Id сотрудника
   * - Format: int32
   */
  id: number;
  /**
   *
   * Признак нахождения сотрудника в отпуске
   */
  isVacation: boolean;
}

export interface ErrorResponse {
  /**
   *
   * Код ошибки
   * - Format: int32
   */
  code: number;
  /**
   *
   * Заголовок ошибки
   * - minLength: 1
   */
  message: string;
  /**
   *
   * Детальная информация по ошибке
   */
  additionally?: string;
}

export interface EstimateDocumentArticleModel {
  /**
   *
   * Остаток по статье документа-сметы
   * - Format: double
   */
  estimateDocumentArticleBalance: number;
  /**
   *
   * Id статьи документа-сметы
   * - Format: int32
   */
  estimateDocumentArticleId: number;
  /**
   *
   * Сумма статьи документа-сметы
   * - Format: double
   */
  estimateDocumentArticleSum: number;
  /**
   *
   * Id документа-сметы
   * - Format: int32
   */
  estimateDocumentId: number;
  /**
   *
   * Наименование документа-сметы
   * - minLength: 1
   */
  estimateDocumentTitle: string;
}

export interface ExpenseExtendedConditionModel {
  /**
   *
   * - Format: double
   */
  extendedValue: number;
  /**
   *
   * - Format: int32
   */
  id: number;
  /**
   *
   * - minLength: 1
   */
  title: string;
  creator?: EmployeeModel;
  /**
   *
   * - Format: date-time
   */
  dateCreated?: string;
  department?: DepartmentModel;
  organizationBrand?: OrganizationBrandModel;
  retailLocation?: RetailLocationModel;
  retailType?: RetailTypeModel;
}

export interface ExpenseModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  /**
   *
   * - minLength: 1
   */
  title: string;
  creator?: EmployeeModel;
  /**
   *
   * - Format: date-time
   */
  dateCreated?: string;
  description?: string;
  hasExtendedConditions?: boolean;
  isCountFromPeriod?: boolean;
  isEstimateRequered?: boolean;
  /**
   *
   * - Format: double
   */
  normValue?: number;
}

/**
 *
 * - Format: int32
 */
/* какая то магия TS -- нужно править пр каждой генерации API */
export enum FilterItemType {
  '0' = 0,
  '1' = 1,
  '2' = 2,
  '3' = 3,
  '4' = 4,
  '5' = 5,
  '6' = 6,
}

export interface GetAction {
  /**
   *
   * - Format: int32
   */
  id: number;
  title?: string;
}

export interface GetAgreeGroup {
  /**
   *
   * - Format: int32
   */
  id: number;
  isSubjectPositionsFixed: boolean;
  title?: string;
}

export interface GetArticlesQueryParams {
  /**
 * 
 * Id процесса:<br />
- если НЕ указан, то возвращается весть список статей;<br />
- если указан, то возвращаются статьи доступные для указанного процесса;<br />
 * - Format: int32
 */
  ProcessId?: number;
}

export interface GetConstructorArticlesQueryParams {
  /**
 * 
 * Id процесса:<br />
- если НЕ указан, то возвращается весть список статей;<br />
- если указан, то возвращаются статьи доступные для указанного процесса;<br />
 * - Format: int32
 */
  ProcessId?: number;
}

export interface GetDocumentsActionExcelQueryParams {
  /**
   *
   * Номер текущей страницы
   * - Format: int32
   */
  CurrentPage: number;
  /**
   *
   * Кол-во элементов на странице
   * - Format: int32
   */
  PageSize: number;
  /**
   *
   * Фильтр по статьям
   */
  ArticleIdFilters?: number[];
  /**
   *
   * Фильтр по контрагентам
   */
  CounterpartyIdFilters?: number[];
  /**
   *
   * Фильтр по инициатору
   */
  CreatorIdFilters?: number[];
  /**
   *
   * Верхней границе даты создания документа
   * - Format: date-time
   */
  FinishDateCreatedFilter?: string;
  /**
 * 
 * Сортировать по:<br />
title - заголовок;<br />
creator - инициатор;<br />
datecreated - дата создания;<br />
status - статус;<br />
organization - ЮЛ;<br />
counterparty - контрагент;<br />
paybeforedate - оплатить до;<br />
 */
  OrderBy?: string;
  /**
   *
   * Фильтр по ЮЛ
   */
  OrganizationIdFilters?: number[];
  /**
   *
   * Фильтр по процессам
   */
  ProcessIdFilters?: number[];
  /**
   *
   * Поиск по заголовку документа/ИНН контрагента
   */
  SearchText?: string;
  /**
   *
   * Нижней границе даты создания документа
   * - Format: date-time
   */
  StartDateCreatedFilter?: string;
  /**
   *
   * Фильтр по статусам
   */
  StatusIdFilters?: number[];
  /**
   *
   * Нижняя граница суммы документа
   * - Format: double
   */
  TotalSumFrom?: number;
  /**
   *
   * Верхняя граница суммы документа
   * - Format: double
   */
  TotalSumTo?: number;
}

export interface GetDocumentsActionQueryParams {
  /**
   *
   * Номер текущей страницы
   * - Format: int32
   */
  CurrentPage: number;
  /**
   *
   * Кол-во элементов на странице
   * - Format: int32
   */
  PageSize: number;
  /**
   *
   * Фильтр по статьям
   */
  ArticleIdFilters?: number[];
  /**
   *
   * Фильтр по контрагентам
   */
  CounterpartyIdFilters?: number[];
  /**
   *
   * Фильтр по инициатору
   */
  CreatorIdFilters?: number[];
  /**
   *
   * Верхней границе даты создания документа
   * - Format: date-time
   */
  FinishDateCreatedFilter?: string;
  /**
 * 
 * Сортировать по:<br />
title - заголовок;<br />
creator - инициатор;<br />
datecreated - дата создания;<br />
status - статус;<br />
organization - ЮЛ;<br />
counterparty - контрагент;<br />
paybeforedate - оплатить до;<br />
 */
  OrderBy?: string;
  /**
   *
   * Фильтр по ЮЛ
   */
  OrganizationIdFilters?: number[];
  /**
   *
   * Фильтр по процессам
   */
  ProcessIdFilters?: number[];
  /**
   *
   * Поиск по заголовку документа/ИНН контрагента
   */
  SearchText?: string;
  /**
   *
   * Нижней границе даты создания документа
   * - Format: date-time
   */
  StartDateCreatedFilter?: string;
  /**
   *
   * Фильтр по статусам
   */
  StatusIdFilters?: number[];
  /**
   *
   * Нижняя граница суммы документа
   * - Format: double
   */
  TotalSumFrom?: number;
  /**
   *
   * Верхняя граница суммы документа
   * - Format: double
   */
  TotalSumTo?: number;
}

export interface GetDocumentsControlExcelQueryParams {
  /**
   *
   * Номер текущей страницы
   * - Format: int32
   */
  CurrentPage: number;
  /**
   *
   * Кол-во элементов на странице
   * - Format: int32
   */
  PageSize: number;
  /**
   *
   * Фильтр по статьям
   */
  ArticleIdFilters?: number[];
  /**
   *
   * Фильтр по контрагентам
   */
  CounterpartyIdFilters?: number[];
  /**
   *
   * Верхней границе даты создания документа
   * - Format: date-time
   */
  FinishDateCreatedFilter?: string;
  /**
 * 
 * Сортировать по:<br />
title - заголовок;<br />
creator - инициатор;<br />
datecreated - дата создания;<br />
status - статус;<br />
organization - ЮЛ;<br />
counterparty - контрагент;<br />
paybeforedate - оплатить до;<br />
 */
  OrderBy?: string;
  /**
   *
   * Фильтр по ЮЛ
   */
  OrganizationIdFilters?: number[];
  /**
   *
   * Фильтр по процессам
   */
  ProcessIdFilters?: number[];
  /**
   *
   * Поиск по заголовку документа/ИНН контрагента
   */
  SearchText?: string;
  /**
   *
   * Нижней границе даты создания документа
   * - Format: date-time
   */
  StartDateCreatedFilter?: string;
  /**
   *
   * Фильтр по статусам
   */
  StatusIdFilters?: number[];
  /**
   *
   * Нижняя граница суммы документа
   * - Format: double
   */
  TotalSumFrom?: number;
  /**
   *
   * Верхняя граница суммы документа
   * - Format: double
   */
  TotalSumTo?: number;
}

export interface GetDocumentsControlQueryParams {
  /**
   *
   * Номер текущей страницы
   * - Format: int32
   */
  CurrentPage: number;
  /**
   *
   * Кол-во элементов на странице
   * - Format: int32
   */
  PageSize: number;
  /**
   *
   * Фильтр по статьям
   */
  ArticleIdFilters?: number[];
  /**
   *
   * Фильтр по контрагентам
   */
  CounterpartyIdFilters?: number[];
  /**
   *
   * Верхней границе даты создания документа
   * - Format: date-time
   */
  FinishDateCreatedFilter?: string;
  /**
 * 
 * Сортировать по:<br />
title - заголовок;<br />
creator - инициатор;<br />
datecreated - дата создания;<br />
status - статус;<br />
organization - ЮЛ;<br />
counterparty - контрагент;<br />
paybeforedate - оплатить до;<br />
 */
  OrderBy?: string;
  /**
   *
   * Фильтр по ЮЛ
   */
  OrganizationIdFilters?: number[];
  /**
   *
   * Фильтр по процессам
   */
  ProcessIdFilters?: number[];
  /**
   *
   * Поиск по заголовку документа/ИНН контрагента
   */
  SearchText?: string;
  /**
   *
   * Нижней границе даты создания документа
   * - Format: date-time
   */
  StartDateCreatedFilter?: string;
  /**
   *
   * Фильтр по статусам
   */
  StatusIdFilters?: number[];
  /**
   *
   * Нижняя граница суммы документа
   * - Format: double
   */
  TotalSumFrom?: number;
  /**
   *
   * Верхняя граница суммы документа
   * - Format: double
   */
  TotalSumTo?: number;
}

export interface GetDocumentsEstimateArticlesQueryParams {
  /**
   *
   * Id статьи
   * - Format: int32
   */
  ArticleId: number;
  /**
   *
   * Id процесса
   * - Format: int32
   */
  ProcessId: number;
  /**
   *
   * Id подразделения
   * - Format: int32
   */
  RetailId: number;
  /**
   *
   * Id документа<br />
   *Необходимо указывать при получении данных по редактируемому документу, чтобы он не учитывался в остатке по смете<br />
   * - Format: int32
   */
  DocumentId?: number;
}

export interface GetDocumentsObserveExcelQueryParams {
  /**
   *
   * Номер текущей страницы<br />
   *Не используется в выгрузках EXCEL<br />
   * - Format: int32
   */
  CurrentPage: number;
  /**
   *
   * Кол-во элементов на странице<br />
   *Не используется в выгрузках EXCEL<br />
   * - Format: int32
   */
  PageSize: number;
  /**
   *
   * Фильтр по статьям
   */
  ArticleIdFilters?: number[];
  /**
   *
   * Фильтр по контрагентам
   */
  CounterpartyIdFilters?: number[];
  /**
   *
   * Фильтр по сетям инициаторов
   */
  DepartmentIds?: number[];
  /**
   *
   * Фильтр по инициаторам
   */
  EmployeeIds?: number[];
  /**
   *
   * Верхней границе даты создания документа
   * - Format: date-time
   */
  FinishDateCreatedFilter?: string;
  /**
 * 
 * Сортировать по:<br />
title - заголовок;<br />
creator - инициатор;<br />
datecreated - дата создания;<br />
status - статус;<br />
organization - ЮЛ;<br />
counterparty - контрагент;<br />
paybeforedate - оплатить до;<br />
 */
  OrderBy?: string;
  /**
   *
   * Фильтр по службам инициаторов
   */
  OrgDepartmentIds?: number[];
  /**
   *
   * Фильтр по направлениям инициаторов
   */
  OrgDirectionIds?: number[];
  /**
   *
   * Фильтр по офисам инициаторов
   */
  OrgOfficeIds?: number[];
  /**
   *
   * Фильтр по должностям инициаторов
   */
  OrgPositionIds?: number[];
  /**
   *
   * Фильтр по ЮЛ
   */
  OrganizationIdFilters?: number[];
  /**
   *
   * Фильтр по процессам
   */
  ProcessIdFilters?: number[];
  /**
   *
   * Фильтр по регионам инициаторов
   */
  RegionIds?: number[];
  /**
   *
   * Поиск по заголовку документа/ИНН контрагента
   */
  SearchText?: string;
  /**
   *
   * Нижней границе даты создания документа
   * - Format: date-time
   */
  StartDateCreatedFilter?: string;
  /**
   *
   * Фильтр по статусам
   */
  StatusIdFilters?: number[];
  /**
   *
   * Нижняя граница суммы документа
   * - Format: double
   */
  TotalSumFrom?: number;
  /**
   *
   * Верхняя граница суммы документа
   * - Format: double
   */
  TotalSumTo?: number;
}

export interface GetDocumentsObserveQueryParams {
  /**
   *
   * Номер текущей страницы<br />
   *Не используется в выгрузках EXCEL<br />
   * - Format: int32
   */
  CurrentPage: number;
  /**
   *
   * Кол-во элементов на странице<br />
   *Не используется в выгрузках EXCEL<br />
   * - Format: int32
   */
  PageSize: number;
  /**
   *
   * Фильтр по статьям
   */
  ArticleIdFilters?: number[];
  /**
   *
   * Фильтр по контрагентам
   */
  CounterpartyIdFilters?: number[];
  /**
   *
   * Фильтр по сетям инициаторов
   */
  DepartmentIds?: number[];
  /**
   *
   * Фильтр по инициаторам
   */
  EmployeeIds?: number[];
  /**
   *
   * Верхней границе даты создания документа
   * - Format: date-time
   */
  FinishDateCreatedFilter?: string;
  /**
 * 
 * Сортировать по:<br />
title - заголовок;<br />
creator - инициатор;<br />
datecreated - дата создания;<br />
status - статус;<br />
organization - ЮЛ;<br />
counterparty - контрагент;<br />
paybeforedate - оплатить до;<br />
 */
  OrderBy?: string;
  /**
   *
   * Фильтр по службам инициаторов
   */
  OrgDepartmentIds?: number[];
  /**
   *
   * Фильтр по направлениям инициаторов
   */
  OrgDirectionIds?: number[];
  /**
   *
   * Фильтр по офисам инициаторов
   */
  OrgOfficeIds?: number[];
  /**
   *
   * Фильтр по должностям инициаторов
   */
  OrgPositionIds?: number[];
  /**
   *
   * Фильтр по ЮЛ
   */
  OrganizationIdFilters?: number[];
  /**
   *
   * Фильтр по процессам
   */
  ProcessIdFilters?: number[];
  /**
   *
   * Фильтр по регионам инициаторов
   */
  RegionIds?: number[];
  /**
   *
   * Поиск по заголовку документа/ИНН контрагента
   */
  SearchText?: string;
  /**
   *
   * Нижней границе даты создания документа
   * - Format: date-time
   */
  StartDateCreatedFilter?: string;
  /**
   *
   * Фильтр по статусам
   */
  StatusIdFilters?: number[];
  /**
   *
   * Нижняя граница суммы документа
   * - Format: double
   */
  TotalSumFrom?: number;
  /**
   *
   * Верхняя граница суммы документа
   * - Format: double
   */
  TotalSumTo?: number;
}

export interface GetDocumentsPaidByCashboxQueryParams {
  /**
   *
   * Дата согласования документа
   * - Format: date-time
   */
  DateAgree: string;
}

export interface GetDocumentsPaidBySecondCashboxQueryParams {
  /**
   *
   * Дата согласования документа
   * - Format: date-time
   */
  DateAgree: string;
}

export interface GetEmployee {
  /**
   *
   * - Format: int32
   */
  id: number;
  name?: string;
  position?: string;
}

export interface GetEmployeesManagersQueryParams {
  /**
   *
   * - Format: int32
   */
  OrganizationId?: number;
}

export interface GetEmployeesMyBossQueryParams {
  /**
   *
   * - Format: int32
   */
  OrganizationId?: number;
}

export interface GetEmployeesMyFinancesCrapQueryParams {
  /**
   *
   * - Format: int32
   */
  OrganizationId?: number;
}

export interface GetEstimatedProcessesResponse {
  estimatedProcesses?: ProcessListItemModel[];
  nonEstimatedProcesses?: ProcessListItemModel[];
}

export interface GetListAgreeGroup {
  /**
   *
   * - Format: int32
   */
  id: number;
  isCore: boolean;
  title?: string;
}

export interface GetListConstructorServiceType {
  /**
   *
   * Id вида услуги
   * - Format: int32
   */
  id: number;
  /**
   *
   * Архивная запись
   */
  isArchive: boolean;
  /**
   *
   * Запись была использована в документах
   */
  isUsed: boolean;
  /**
   *
   * Уникальный идентификатор номенклатуры
   * - minLength: 1
   */
  serviceTypeExternalId: string;
  /**
   *
   * Наименование вида услуги
   * - minLength: 1
   */
  title: string;
}

export interface GetListDelegatedAction {
  /**
   *
   * Id действия
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование действия
   * - minLength: 1
   */
  title: string;
}

export interface GetListOrganization {
  /**
   *
   * - Format: int32
   */
  id: number;
  title?: string;
}

export interface GetListPositionCategory {
  /**
   *
   * - Format: int32
   */
  id: number;
  positions?: GetPosition[];
  title?: string;
}

export interface GetListProcess {
  /**
   *
   * - Format: int32
   */
  id: number;
  title?: string;
}

export interface GetListProcessAction {
  action: GetAction;
  /**
   *
   * - Format: int32
   */
  id: number;
  isEstimated: boolean;
  process: GetListProcess;
}

export interface GetListProcessActionEmployee {
  employee: GetEmployee;
  /**
   *
   * - Format: int32
   */
  id: number;
}

export interface GetListProcessActionEmployeeOrganization {
  /**
   *
   * - Format: int32
   */
  id: number;
  isOnlyOnVacation: boolean;
  organization: GetListOrganization;
}

export interface GetListProcessToAgreeGroup {
  agreeGroup: GetListAgreeGroup;
  /**
   *
   * - Format: int32
   */
  orderNumber: number;
}

export interface GetListServiceType {
  /**
   *
   * Id вида услуги
   * - Format: int32
   */
  id: number;
  /**
   *
   * Архивная запись
   */
  isArchive: boolean;
  /**
   *
   * Наименование вида услуги
   * - minLength: 1
   */
  title: string;
}

export interface GetListSubjectPosition {
  /**
   *
   * - Format: int32
   */
  agreeGroupId: number;
  /**
   *
   * - Format: double
   */
  documentSumFrom: number;
  /**
   *
   * - Format: double
   */
  documentSumTo: number;
  employeePosition: GetPosition;
  isAllObjectArticles: boolean;
  isAllObjectPositions: boolean;
  objectArticlesIds: number[];
  objectPositionsIds: number[];
  /**
   *
   * - Format: int32
   */
  orderNumber: number;
  substitutePositions: GetSubstitutePosition[];
}

export interface GetOrganizationsAllowedQueryParams {
  /**
   *
   * Id процесса
   * - Format: int32
   */
  processId: number;
}

export interface GetPosition {
  /**
   *
   * - Format: int32
   */
  id: number;
  /**
   *
   * - Format: int32
   */
  categoryId?: number;
  title?: string;
}

export interface GetProcessAction {
  action: GetAction;
  /**
   *
   * - Format: int32
   */
  id: number;
  isEstimated: boolean;
  process: GetListProcess;
}

export interface GetSubstitutePosition {
  /**
   *
   * - Format: int32
   */
  positionId: number;
  /**
   *
   * - Format: int32
   */
  priority: number;
}

export interface GetTaxRatesCalculatedQueryParams {
  /**
   *
   * Сумма, для которой необходимо произвести расчет НДС для каждой ставки
   * - Format: double
   */
  Sum: number;
}

export interface ManagerAgreeRequest {
  /**
   *
   * Дата приказа
   * - Format: date-time
   */
  orderDate: string;
  /**
   *
   * Номер приказа
   * - minLength: 1
   */
  orderNumber: string;
}

export interface ObserveFilterItemModel {
  /**
   *
   * Id элемента
   * - Format: int32
   */
  id: number;
  /**
   *
   * "Родительские" Аптечные сети<br />
   */
  parentDepartments: number[];
  /**
   *
   * "Родительские" Службы<br />
   */
  parentOrgDepartments: number[];
  /**
 * 
 * "Родительские" Направления<br />
*Список Id "родительских" элементов, от которых зависит доступность выбора текущего (в примичании ниже указана схема зависимости элементов, она транзитивна):<br />
    - если в фильтрах уже выбран "родительский" элемент с Id, которой содержится в данном списке, то текущий элемент доступен для выбора;<br />
    - иначе текущий элемент не доступен для выбора;<br />
**Схема зависимости элементов:<br />
[Напр-я] -> [Службы] --> [Офисы] -> [Должности] -> [Сотрудники]<br />
[Регионы] -> [Сети] _/<br />
 */
  parentOrgDirections: number[];
  /**
   *
   * "Родительские" АУП'ы<br />
   */
  parentOrgOffices: number[];
  /**
   *
   * "Родительские" Должности<br />
   */
  parentOrgPositions: number[];
  /**
   *
   * "Родительские" Регионы<br />
   */
  parentRegions: number[];
  /**
   *
   * Наименование элемента
   * - minLength: 1
   */
  title: string;
  /**
 * 
 * Тип элемента:<br />
 0 - направление;<br />
 1 - служба;<br />
 2 - АУП;<br />
 3 - должность;<br />
 4 - регион;<br />
 5 - аптечная сеть;<br />
 6 - сотрудник;<br />
 */
  type: FilterItemType;
}

export interface ObserveFilterModel {
  /**
   *
   * Службы и Аптечные сети
   */
  departmentsAndDepts: ObserveFilterItemModel[];
  /**
   *
   * Направления и Регионы
   */
  directionsAndRegions: ObserveFilterItemModel[];
  /**
   *
   * Сотрудники
   */
  employees: ObserveFilterItemModel[];
  /**
   *
   * АУП'ы
   */
  offices: ObserveFilterItemModel[];
  /**
   *
   * Должности
   */
  positions: ObserveFilterItemModel[];
}

export interface OrgStructPartModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  orgStructPartType?: OrgStructPartTypeModel;
  title?: string;
}

export interface OrgStructPartTypeModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  title?: string;
}

export interface OrganizationBrandModel {
  /**
   *
   * Id бренда
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование бренда
   * - minLength: 1
   */
  title: string;
}

export interface OrganizationCreate {
  /**
   *
   * - Format: int32
   */
  id: number;
  isOnlyOnVacation: boolean;
}

export interface OrganizationModel {
  /**
   *
   * Id ЮЛ
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование ЮЛ
   * - minLength: 1
   */
  title: string;
  /**
   *
   * ЮЛ принадлежит подразделению, в котором трудоустройство текущего пользователя является основным<br />
   *Используется для определения ЮЛ по-умолчанию при создании документа
   */
  isMainCurrentEmployment?: boolean;
}

export interface OrganizationRequest {
  /**
 * 
 * Id согласованного ЮЛ<br />
Options: api/Organizations/CheckTaskByDocument/{documentId:int}<br />
 * - Format: int32
 */
  organizationId: number;
}

export interface PositionCategoryModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  title?: string;
}

export interface ProblemDetails {
  detail?: string;
  instance?: string;
  /**
   *
   * - Format: int32
   */
  status?: number;
  title?: string;
  type?: string;
}

export interface ProcessActionCreate {
  /**
   *
   * - Format: int32
   */
  actionId: number;
  isEstimated: boolean;
  /**
   *
   * - Format: int32
   */
  processId: number;
}

export interface ProcessActionDelete {
  ids: number[];
}

export interface ProcessActionEmployeeCreate {
  employeesIds?: number[];
}

export interface ProcessActionEmployeeOrganizationDelete {
  ids: number[];
}

export interface ProcessActionEmployeeOrganizationsCreate {
  organizations?: OrganizationCreate[];
}

export interface ProcessActionEmployeesDelete {
  ids: number[];
}

export interface ProcessActionUpdate {
  /**
   *
   * - Format: int32
   */
  actionId: number;
  /**
   *
   * - Format: int32
   */
  id: number;
  isEstimated: boolean;
  /**
   *
   * - Format: int32
   */
  processId: number;
}

export interface ProcessAdditionallyFieldModel {
  /**
   *
   * Id дополнительного поля процесса<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Является массивом (если true - возможно указать несколько значений для одного дополнительного поля)
   */
  isArray: boolean;
  /**
   *
   * Признак необходимости заполнения значения
   */
  isRequired: boolean;
  /**
   *
   * Наименование дополнительного поля
   * - minLength: 1
   */
  title: string;
}

export interface ProcessAdditionallyFieldResponse {
  /**
   *
   * Id дополнительного поля процесса<br />
   * - Format: int32
   */
  id: number;
  /**
   *
   * Является массивом (если true - возможно указать несколько значений для одного дополнительного поля)
   */
  isArray: boolean;
  /**
   *
   * Признак необходимости заполнения значения
   */
  isRequired: boolean;
  /**
   *
   * Наименование дополнительного поля
   * - minLength: 1
   */
  title: string;
}

export interface ProcessAdditionallyFieldTypeModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  title?: string;
}

export interface ProcessAdditionallyFieldsResponse {
  /**
   *
   * Дополнительные поля типа дата-время<br />
   */
  dateTimeAdditionallyFields: ProcessAdditionallyFieldResponse[];
  /**
   *
   * Дополнительные поля типа число с плавающей запятой<br />
   */
  decimalAdditionallyFields: ProcessAdditionallyFieldResponse[];
  /**
   *
   * Дополнительные поля типа целое число<br />
   */
  intAdditionallyFields: ProcessAdditionallyFieldResponse[];
  /**
   *
   * Дополнительные поля типа период<br />
   */
  periodAdditionallyFields: ProcessAdditionallyFieldResponse[];
  /**
   *
   * Дополнительные поля типа строка<br />
   */
  stringAdditionallyFields: ProcessAdditionallyFieldResponse[];
}

export interface ProcessItemModel {
  /**
   *
   * Id процесса
   * - Format: int32
   */
  id: number;
  /**
   *
   * Оплата бухгалтерией
   */
  isAccountantPayment: boolean;
  /**
   *
   * Все статьи обязательные
   */
  isAllArticlesRequired: boolean;
  /**
   *
   * Необходимость отображать инпут выбора подразделения
   */
  isArticleRetailDisplayed: boolean;
  /**
   *
   * Подразделения для статей обязательны
   */
  isArticleRetailRequired: boolean;
  /**
   *
   * Необходим закрывающий документ
   */
  isCloseDocumentRequired: boolean;
  /**
   *
   * Необходима информация по контрагенту
   */
  isCounterpartyRequired: boolean;
  /**
   *
   * Является сметой
   */
  isEstimate: boolean;
  /**
   *
   * Поле "Количество" при заполнении вида расхода расчитывается из количества дней в периоде<br />
   *Влияет на необходимость заполнения полей BeginPeriod и EndPeriod документа<br />
   */
  isExpenseByPeriod: boolean;
  /**
   *
   * Можно добавлять участников согласования
   */
  isExtendedAgree: boolean;
  /**
   *
   * Согласование менеджера по персоналу
   */
  isManagerAgree: boolean;
  /**
   *
   * Процесс согласования формируется вручную
   */
  isManualAgree: boolean;
  /**
   *
   * Возможность добавлять несколько статей
   */
  isMultiArticles: boolean;
  /**
   *
   * Согласование юридического лица
   */
  isOrganizationAgreeRequired: boolean;
  /**
   *
   * Перечень ЮЛ ограничен трудоутройством инициатора
   */
  isOrganizationOnlyForMyOffice: boolean;
  /**
   *
   * Оплата через кассу
   */
  isPaidByCashbox: boolean;
  /**
   *
   * Оплата через "вторую" кассу
   */
  isPaidBySecondCashbox: boolean;
  /**
   *
   * Параллельное согласование
   */
  isParallelAgree: boolean;
  /**
   *
   * Необходим документ основание
   */
  isReasonDocumentRequired: boolean;
  /**
   *
   * Реестровый платеж
   */
  isRegisterPayment: boolean;
  /**
   *
   * Вид услуги обязателен
   */
  isServiceTypeRequired: boolean;
  /**
   *
   * Согласование бухгалтера по налогам
   */
  isTaxAccountantAgreeRequired: boolean;
  /**
   *
   * Необходимость заполнения НДС
   */
  isTaxCalculateRequired: boolean;
  /**
   *
   * Перечисление подотчетному лицу
   */
  isTransferToAccountablePerson: boolean;
  /**
   *
   * Наименование процесса
   * - minLength: 1
   */
  title: string;
  /**
   *
   * Возможно выбрать смету
   */
  isEstimated?: boolean;
  /**
   *
   * Наименование блока с периодами
   */
  periodTitle?: string;
}

export interface ProcessListItemModel {
  /**
   *
   * Id процесса
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование процесса
   * - minLength: 1
   */
  title: string;
}

export interface ProcessToAgreeGroupUpdate {
  agreeGroups?: AgreeGroup[];
}

export interface ReasonDocumentTypeModel {
  /**
   *
   * Id типа документа основания
   * - Format: int32
   */
  id: number;
  /**
   *
   * Необходимость заполнения дополнительной информации по документу основания<br />
   *Если true, то у документа необходимо указать ReasonDocumentDate и ReasonDocumentNumber
   */
  isOtherInformationRequired: boolean;
  /**
   *
   * Наименование типа документа основания
   * - minLength: 1
   */
  title: string;
}

export interface RetailLocationModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  title?: string;
}

export interface RetailModel {
  /**
   *
   * Id подразделения
   * - Format: int32
   */
  id: number;
  /**
   *
   * Признак главной для авторизованного пользователя группы
   */
  isMainGroup: boolean;
  /**
   *
   * Наименование подразделения
   * - minLength: 1
   */
  title: string;
  /**
   *
   * Наименование сети для группировки на клиенте
   */
  groupTitle?: string;
}

export interface RetailTypeModel {
  /**
   *
   * - Format: int32
   */
  id: number;
  title?: string;
}

export interface ServiceTypeCreate {
  /**
   *
   * Уникальный идентификатор номенклатуры
   * - minLength: 1
   */
  serviceTypeExternalId: string;
  /**
   *
   * Наименование вида услуги
   * - minLength: 1
   */
  title: string;
}

export interface SubjectPosition {
  /**
   *
   * - Format: double
   */
  documentSumFrom: number;
  /**
   *
   * - Format: double
   */
  documentSumTo: number;
  /**
   *
   * - Format: int32
   */
  employeePositionId: number;
  isAllObjectArticles: boolean;
  isAllObjectPositions: boolean;
  /**
   *
   * - Format: int32
   */
  orderNumber: number;
  objectArticlesIds?: number[];
  objectPositionsIds?: number[];
  substitutePositions?: SubstitutePositionUpdate[];
}

export interface SubjectPositonUpdateList {
  subjectPositions?: SubjectPosition[];
}

export interface SubstitutePositionUpdate {
  /**
   *
   * - Format: int32
   */
  positionId: number;
  /**
   *
   * - Format: int32
   */
  priority: number;
}

export interface TaxRateCalculatedResponse {
  /**
   *
   * - Format: int32
   */
  id: number;
  /**
   *
   * - Format: double
   */
  sum: number;
  title?: string;
}

export interface TaxRateModel {
  /**
   *
   * Id ставки
   * - Format: int32
   */
  id: number;
  /**
   *
   * Наименование ставки
   * - minLength: 1
   */
  title: string;
}

export interface UpdateEstimatedProcessesRequest {
  estimatedProcessesIds?: number[];
}
