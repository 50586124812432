import { Button, Grid } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  useLazyGetTrainingEmployesQuery,
  useLazyGetTrainingRetailsTreeQuery,
  useLazyGetTrainingStatusesQuery,
  useLazyGetTrainingThemesQuery,
  useLazyGetTrainingTrainerQuery,
} from 'shared/api/traning/rtk/trainingProviderApi';

import dayjs from 'dayjs';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import {
  ContainerActionsForm,
  CustomAutocomplete,
  CustomDatePicker,
} from 'shared/ui/base-ui';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import {
  getOptionLabelRetail,
  isOptionEqualToValueRetail,
  renderOptionRetail,
  treeToArray,
} from '../libs';
import { EMPT_ARR } from './constants';
import {
  getOptionLabelEmploye,
  getOptionLabelStatuses,
  getOptionLabelThemeTreining,
  getOptionLabelTrainer,
  isOptionEqualToValueEmploye,
  isOptionEqualToValueStatuses,
  isOptionEqualToValueThemeTreining,
  renderOptionEmploye,
  renderOptionStatuses,
  renderOptionThemeTreining,
  renderOptionTrainer,
} from './libs';
import { FilterTrainingProps, I_FormFilterTraining } from './types';
import { useUrlStateTrainingFilter } from './use-url-state-training-filter';

export const FilterTraining: FC<FilterTrainingProps> = ({
  open,
  onClose,
  onSubmite,
  settingsFields,
}) => {
  /* OPTIONS */

  const [fetchEmployes, { data: employes, isFetching }] =
    useLazyGetTrainingEmployesQuery();

  const [
    fetchRetails,
    { data: retailsTree, isFetching: isFetchingRetailsTree },
  ] = useLazyGetTrainingRetailsTreeQuery();

  const retailsOptions = useMemo(() => {
    if (retailsTree) {
      return treeToArray(retailsTree);
    }

    return [];
  }, [retailsTree]);

  const [fetchTrainer, { data: trainers, isFetching: isFetchingTrainers }] =
    useLazyGetTrainingTrainerQuery();

  const [fetchStatuses, { data: statuses, isFetching: isFetchingStatuses }] =
    useLazyGetTrainingStatusesQuery();

  const [
    fetchThemesTraining,
    { data: themesTraining, isFetching: isFetchingThemesTraining },
  ] = useLazyGetTrainingThemesQuery();

  /* DEFAULT VALUE FROM URL */
  const {
    showDate,
    showEmploye,
    showRetails,
    showStatuses,
    showTrainer,
    showThemeTraining,
  } = settingsFields;
  const { filter } = useUrlStateTrainingFilter();
  const asyncDefaultValue = useCallback(async () => {
    const {
      TrainerGuid,
      Statuses,
      OrgGuid,
      EmployeeGuid,
      DateFrom,
      DateTo,
      TrainingGuid,
    } = filter || {};

    const DEFAULT: I_FormFilterTraining = {
      employe: null,
      trainer: null,
      statuses: [],
      dateFrom: null,
      dateTo: null,
      org: null,
      themeTraining: null,
    };

    if (!open) {
      return DEFAULT;
    }

    const defaultOptionsResponse = { data: undefined };
    const [
      { data: employes },
      { data: trainers },
      { data: statuses },
      { data: retails },
      { data: themes },
    ] = await Promise.all([
      showEmploye ? fetchEmployes(undefined, true) : defaultOptionsResponse,
      showTrainer ? fetchTrainer(undefined, true) : defaultOptionsResponse,
      showStatuses ? fetchStatuses(undefined, true) : defaultOptionsResponse,
      showRetails ? fetchRetails(undefined, true) : defaultOptionsResponse,
      showThemeTraining
        ? fetchThemesTraining(undefined, true)
        : defaultOptionsResponse,
    ]);

    /* EMPLOYES */

    if (showEmploye && EmployeeGuid && employes) {
      DEFAULT.employe =
        employes.find((employe) => employe.guid === EmployeeGuid) || null;
    }

    /* TRAINERS */

    if (showTrainer && TrainerGuid && trainers) {
      DEFAULT.trainer =
        trainers.find((trainer) => trainer.guid === TrainerGuid) || null;
    }
    /* TrainingGuid */

    if (showThemeTraining && TrainingGuid && themes) {
      DEFAULT.themeTraining =
        themes.find((theme) => theme.guid === TrainingGuid) || null;
    }

    /* STATUSES */

    if (showStatuses && Statuses && Statuses.length > 0 && statuses) {
      DEFAULT.statuses = statuses.filter((status) =>
        Statuses.includes(status.guid)
      );
    }

    /* RETAILS */

    if (showRetails && OrgGuid && retails) {
      DEFAULT.org =
        treeToArray(retails).find((retail) => retail.guid === OrgGuid) || null;
    }

    /* DATE_FROM */
    if (DateFrom && showDate) {
      DEFAULT.dateFrom = dayjs(DateFrom, DATE_FORMATS.MM_DD_YYYY_DOT);
    }

    /* DATA_TO */
    if (DateTo && showDate) {
      DEFAULT.dateTo = dayjs(DateTo, DATE_FORMATS.MM_DD_YYYY_DOT);
    }

    return DEFAULT;
  }, [
    open,
    showDate,
    showEmploye,
    showRetails,
    showStatuses,
    showTrainer,
    filter,
  ]);

  /* FORM */

  const { control, handleSubmit, reset } = useForm<I_FormFilterTraining>({
    defaultValues: asyncDefaultValue,
  });

  useEffect(() => {
    asyncDefaultValue().then(reset);
  }, [open]);

  return (
    <DialogForForm
      open={open}
      onClose={onClose}
      head={<DialogTitleForForm title={'Фильтр'} />}
      sx={{
        flexDirection: 'column',
      }}
    >
      <Grid
        gap={2}
        container
        flexDirection={'column'}
        component={'form'}
        onSubmit={handleSubmit(onSubmite)}
      >
        {showThemeTraining && (
          <Controller
            control={control}
            name='themeTraining'
            render={({ field: { value, onChange } }) => (
              <CustomAutocomplete
                options={themesTraining || EMPT_ARR}
                getOptionLabel={getOptionLabelThemeTreining}
                value={value}
                onChange={(event, value) => onChange(value)}
                renderOption={renderOptionThemeTreining}
                isOptionEqualToValue={isOptionEqualToValueThemeTreining}
                loading={isFetchingThemesTraining}
                textFieldProps={{ label: 'Тема тренинга' }}
              />
            )}
          />
        )}
        {showEmploye && (
          <Controller
            control={control}
            name='employe'
            render={({ field: { value, onChange } }) => (
              <CustomAutocomplete
                options={employes || EMPT_ARR}
                getOptionLabel={getOptionLabelEmploye}
                value={value}
                onChange={(event, value) => onChange(value)}
                renderOption={renderOptionEmploye}
                isOptionEqualToValue={isOptionEqualToValueEmploye}
                loading={isFetching}
                textFieldProps={{ label: 'Сотрудник' }}
              />
            )}
          />
        )}

        {showTrainer && (
          <Controller
            control={control}
            name='trainer'
            render={({ field: { value, onChange } }) => (
              <CustomAutocomplete
                value={value}
                onChange={(event, value) => onChange(value)}
                options={trainers || EMPT_ARR}
                isOptionEqualToValue={isOptionEqualToValueEmploye}
                getOptionLabel={getOptionLabelTrainer}
                renderOption={renderOptionTrainer}
                loading={isFetchingTrainers}
                textFieldProps={{ label: 'Тренер' }}
              />
            )}
          />
        )}

        {showStatuses && (
          <Controller
            control={control}
            name='statuses'
            render={({ field: { value, onChange } }) => (
              <CustomAutocomplete
                value={value}
                onChange={(event, value) => onChange(value)}
                options={statuses || EMPT_ARR}
                getOptionLabel={getOptionLabelStatuses}
                renderOption={renderOptionStatuses}
                isOptionEqualToValue={isOptionEqualToValueStatuses}
                multiple
                disableCloseOnSelect
                loading={isFetchingStatuses}
                textFieldProps={{ label: 'Статус' }}
              />
            )}
          />
        )}
        {showRetails && (
          <Controller
            control={control}
            name='org'
            render={({ field: { value, onChange } }) => (
              <CustomAutocomplete
                value={value}
                onChange={(event, value) => onChange(value)}
                options={retailsOptions}
                getOptionLabel={getOptionLabelRetail}
                renderOption={renderOptionRetail}
                isOptionEqualToValue={isOptionEqualToValueRetail}
                loading={isFetchingRetailsTree}
                textFieldProps={{ label: 'Сети' }}
              />
            )}
          />
        )}
        {showDate && (
          <Grid
            container
            flexWrap={['wrap', 'nowrap']}
            gap={2}
          >
            <Controller
              control={control}
              name='dateFrom'
              render={({ field: { value, onChange } }) => (
                <CustomDatePicker
                  label={'Перод c'}
                  value={value}
                  onChange={(value) => onChange(value)}
                  containerSx={{
                    flexBasis: ['100%', '50%'],
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name='dateTo'
              render={({ field: { value, onChange } }) => (
                <CustomDatePicker
                  label={'по'}
                  value={value}
                  onChange={(value) => onChange(value)}
                  containerSx={{
                    flexBasis: ['100%', '50%'],
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              )}
            />
          </Grid>
        )}
        <ContainerActionsForm>
          <Button
            type='submit'
            variant='contained'
          >
            Сохранить
          </Button>
          <Button
            variant='contained'
            onClick={onClose}
            color='customGrey'
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
