import { Delete } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { FC } from 'react';

import { ComponentWithUserPermission } from 'entities/user';
import { E_ModuleGuids } from 'shared/moduleGuids';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { useConfirmDeleteAdaptationProcess } from './use-confirm-delete-adaptation-process';

export const DeleteAdaptationProcess: FC<{ adaptationProcessGuid: string }> = ({
  adaptationProcessGuid,
}) => {
  const theme = useTheme();

  const { handleOpenConfirmDeleteAdaptationProcess } =
    useConfirmDeleteAdaptationProcess({
      adaptationProcessGuid,
    });

  return (
    <ComponentWithUserPermission moduleGuid={E_ModuleGuids.adaptationProcesses}>
      {({ isDeletable }) => {
        return (
          <UiButtonAction
            disable={!isDeletable}
            text='Удалить адаптацию'
            onClick={handleOpenConfirmDeleteAdaptationProcess}
            color={theme.palette.error.main}
            icon={<Delete />}
          />
        );
      }}
    </ComponentWithUserPermission>
  );
};
