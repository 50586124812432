import { BoardTaskFormCardFields } from 'features/boards/BoardTaskFormCard';
import { useBoardPage } from 'pages/boards/context/BoardPageContext';
import { useCallback, useMemo } from 'react';
import { useUpdateBoardTaskMutation } from 'shared/api/services/rtk/boardApi';
import { useUpdateTaskStatusMutation } from 'shared/api/services/rtk/taskApi';
import { GetListBoardTask } from 'shared/api/services/types';

export const useBoardTaskCardForBoardPage = (task: GetListBoardTask) => {
  const { boardGuid } = useBoardPage();
  const [updateTask, { isLoading: isUpdatingTask }] =
    useUpdateBoardTaskMutation();
  const [updateStatus, { isLoading: isUpdatingStatus }] =
    useUpdateTaskStatusMutation();

  const handleUpdateTask = useCallback(
    async (form: BoardTaskFormCardFields) => {
      await updateTask({
        taskGuid: task.taskGuid,
        boardGuid,
        args: {
          executorGuid: form.executor.guid,
          title: form.title,
        },
      });
    },
    [boardGuid, task.taskGuid]
  );

  const handleUpdateStatus = useCallback(async () => {
    if (task.accessActions.nextStatus) {
      await updateStatus({
        taskGuid: task.taskGuid,
        status: task.accessActions.nextStatus,
      });
    }
  }, [task.accessActions.nextStatus, task.taskGuid]);

  const isLoading = useMemo(() => {
    return isUpdatingStatus || isUpdatingTask;
  }, [isUpdatingStatus, isUpdatingTask]);

  return { handleUpdateTask, handleUpdateStatus, isLoading };
};
