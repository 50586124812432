import { alpha } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTableRowWithActions,
} from 'shared/ui/table';

import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';
import { MarketingSurfaceActions } from './actions';

import { MarketingSurfaceTableProps } from './types';

export const MarketingSurfaceTable: FC<MarketingSurfaceTableProps> = ({
  isLoading,
  isUpdate,
  visibleFields,
  fields,
  onChangeFields,
  onClick,
  data,
}) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={7} />}
      header={
        <UiTableHeader
          viewCells={visibleFields}
          allCells={fields}
          setViewCells={onChangeFields}
        />
      }
      body={data.map((surface) => (
        <UiTableRowWithActions
          key={surface.guid || Math.random()}
          sx={{
            ...(surface.surfaceUpdateDaysExpired && {
              backgroundColor: alpha(theme.palette.error.main, 0.4),
            }),
            cursor: 'pointer',
          }}
          onClick={() => {
            onClick?.(surface);
          }}
          hover
          renderActions={() => {
            return <MarketingSurfaceActions surface={surface} />;
          }}
        >
          {visibleFields.map((el) => (
            <UiTableCell
              key={el.field}
              {...el.getTableCellProps()}
            >
              {el.renderComponent(surface) || 'Error'}
            </UiTableCell>
          ))}
        </UiTableRowWithActions>
      ))}
    />
  );
};
