import { AddCircle, Delete, OpenInNew } from '@mui/icons-material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';

import { VisitTableActionsProps } from './type';

export const VisitTableActions: FC<VisitTableActionsProps> = ({
  visit,
  onDelete,
  onCreateChecklist,
  onOpen,
}) => {
  return (
    <UiTableActions>
      {visit.canDelete && (
        <UiButtonAction
          text='Удалить'
          color={theme.palette.error.main}
          disable={!visit.canDelete}
          icon={<Delete />}
          onClick={() => {
            onDelete && onDelete(visit.id);
          }}
        />
      )}

      {!visit.visitingChecklistId && visit.canEdit && (
        <UiButtonAction
          text='Создать чек-лист'
          color={theme.palette.primary.main}
          disable={!visit.canEdit}
          icon={<AddCircle />}
          onClick={() => {
            onCreateChecklist && onCreateChecklist(visit.id);
          }}
        />
      )}

      {visit.visitingChecklistId && (
        <UiButtonAction
          text='Открыть чек-лист'
          color={theme.palette.customBlue.main}
          disable={!visit.visitingChecklistId}
          icon={<OpenInNew />}
          onClick={() => {
            if (visit.visitingChecklistId && onOpen) {
              onOpen(visit.visitingChecklistId);
            }
          }}
        />
      )}
    </UiTableActions>
  );
};
