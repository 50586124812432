import { useEffect, useState } from 'react';

import { SwaggerResponse } from 'shared/api/services-auth/config';

export type UseFetchOptionsType = <T>(
  fetchFun: () => Promise<SwaggerResponse<T[]>>
) => { data: T[]; isLoading: boolean };

export const useFetchOptions: UseFetchOptionsType = (fetch) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Awaited<ReturnType<typeof fetch>>['data']>(
    []
  );

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data, status } = await fetch();

      if (!data || status !== 200) throw new Error('Упс, что-то пошло не так');
      setData(data);
    } catch (e) {
      console.error('useFetchOptions.fetchData >> ', e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, isLoading };
};
