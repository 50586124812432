import { FORMAT_DATE_FOR_BACKEND } from 'features/task/form-task/constants';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { DocumentFlowFilterBaseForm } from '../types';

export const transformBaseFormFilterToUrlParams = (
  formFilter: DocumentFlowFilterBaseForm
) => {
  const urlParams = {
    ArticleIdFilters: getIdsFromArray(formFilter.articles),
    CounterpartyIdFilters: getIdsFromArray(formFilter.contractors),
    StatusIdFilters: getIdsFromArray(formFilter.statuses),
    OrganizationIdFilters: getIdsFromArray(formFilter.organizations),
    ProcessIdFilters: getIdsFromArray(formFilter.documentTypes),
    StartDateCreatedFilter:
      formFilter.dateFrom && formFilter.dateFrom.isValid()
        ? formFilter.dateFrom.format(FORMAT_DATE_FOR_BACKEND)
        : undefined,
    FinishDateCreatedFilter:
      formFilter.dateTo && formFilter.dateTo.isValid()
        ? formFilter.dateTo.format(FORMAT_DATE_FOR_BACKEND)
        : undefined,
    TotalSumFrom:
      (formFilter.sumFrom && Number(formFilter.sumFrom)) || undefined,
    TotalSumTo: (formFilter.sumTo && Number(formFilter.sumTo)) || undefined,
  };

  return urlParams;
};
