import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { ContainerWithLableProps } from './type';

export const ContainerWithLabel: FC<ContainerWithLableProps> = ({
  children,
  label,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: ['wrap', 'nowrap'],
        gap: 1,
      }}
    >
      <Typography
        sx={{
          flexBasis: ['100%', '40%'],
          fontSize: 16,
          color: 'black',
          backgroundColor: [theme.palette.customGrey.main, 'inherit'],
          flexShrink: 0,
          fontWeight: 700,
          pl: [1, 0],
          boxSizing: 'border-box',
        }}
      >
        {label}
      </Typography>

      <Box
        sx={{
          flexBasis: ['100%', '60%'],
          flex: 0,
          overflow: 'hidden',
          pl: [1, 0],
          boxSizing: 'border-box',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
