import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';

import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import { CreateVisitForm } from 'widgets/visits/forms/create-visit-from/CreateVisitForm';

import { CreateVisitDialogProps } from './type';

export const CreateVisitDialog: FC<CreateVisitDialogProps> = ({
  isOpen,
  onClose,
  onSubmitCreateChecklist,
  onSubmitPlanningVisit,
  initValue,
  isLoading = false,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Создание посещений' />}
    >
      <CreateVisitForm
        initValue={initValue}
        onSubmit={onSubmitPlanningVisit}
        actions={(handleSubmit) => [
          <Button
            key={1}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
          <LoadingButton
            key={2}
            loading={isLoading}
            variant='contained'
            color='customBlue'
            type='submit'
          >
            Запланировать
          </LoadingButton>,
          <LoadingButton
            key={3}
            loading={isLoading}
            variant='contained'
            onClick={handleSubmit(onSubmitCreateChecklist)}
          >
            Создать чек-лист
          </LoadingButton>,
        ]}
      />
    </DialogForForm>
  );
};
