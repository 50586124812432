import { Chip } from '@mui/material';
import { FC } from 'react';

import { TASK_STATUS_ICONS } from './constants';
import { TaskStatusChipProps, TaskStatuses } from './type';

export const TaskStatusChip: FC<TaskStatusChipProps> = ({
  status,
  onClick,
}) => {
  const map =
    TASK_STATUS_ICONS.find((el) => el.status === status) ||
    TASK_STATUS_ICONS[1];

  return (
    <Chip
      label={status}
      color={map.color}
      onClick={onClick}
      variant={status === TaskStatuses.Новая ? 'outlined' : 'filled'}
      sx={{ width: '100%', borderWidth: 3 }}
    />
  );
};
