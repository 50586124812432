import { AdaptationTemplateTaskFormFields } from '../type';

export const transformRemind = (
  initValue: Partial<AdaptationTemplateTaskFormFields> | undefined
): Partial<AdaptationTemplateTaskFormFields> | undefined => {
  if (!initValue) return undefined;

  return {
    ...initValue,
    reminderPeriodCoExecutor: initValue.reminderPeriodCoExecutor || '',
    reminderPeriodCreator: initValue.reminderPeriodCreator || '',
    reminderPeriodExecutor: initValue.reminderPeriodExecutor || '',
    reminderPeriodObserver: initValue.reminderPeriodObserver || '',
  };
};
