// eslint-disable-next-line budapestian/global-constant-pattern
import { createContext, FC, ReactNode, useContext } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';

const NavBarContextIsOpen = createContext(false);

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NavBarContextCloseFn = createContext(() => {});

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NavBarContextOpenFn = createContext(() => {});

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NavBarContextToggleFn = createContext(() => {});

const useNavBarContextToggleFn = () => useContext(NavBarContextToggleFn);
const useNavBarContextIsOpen = () => useContext(NavBarContextIsOpen);
const useNavBarContextOpenFn = () => useContext(NavBarContextOpenFn);
const useNavBarContextCloseFn = () => useContext(NavBarContextCloseFn);

const NavBarContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { open, close, isOpen, onToggle } = useViewModal(false);

  return (
    <NavBarContextIsOpen.Provider value={isOpen}>
      <NavBarContextCloseFn.Provider value={close}>
        <NavBarContextOpenFn.Provider value={open}>
          <NavBarContextToggleFn.Provider value={onToggle}>
            {children}
          </NavBarContextToggleFn.Provider>
        </NavBarContextOpenFn.Provider>
      </NavBarContextCloseFn.Provider>
    </NavBarContextIsOpen.Provider>
  );
};

const WithNavBarContextProvider = function <T extends object>(
  Component: FC<T>
) {
  const ComponentWithContext = (props: T) => {
    return (
      <NavBarContextProvider>
        <Component {...props} />
      </NavBarContextProvider>
    );
  };

  return ComponentWithContext;
};

export const NavBarContext = {
  Provider: NavBarContextProvider,
  useIsOpen: useNavBarContextIsOpen,
  useCloseFn: useNavBarContextCloseFn,
  useOpenFn: useNavBarContextOpenFn,
  useToggleFn: useNavBarContextToggleFn,
  buildWithContextProvider: WithNavBarContextProvider,
};
