import { FormTemplateTaskValue } from './types';

export const NUMBER_INPUT_PROPS = {
  inputProps: {
    min: 0,
  },
};

export const DEFAULT_FORM_VALUE: FormTemplateTaskValue = {
  title: '',
  description: '',
  startDaysCount: '',
  finishDaysCount: '',
  creatorPosition: null,
  executorPosition: null,
  coExecutorsPositions: [],
  observersPositions: [],
  isReminderCreator: false,
  isReminderExecutor: false,
  isReminderCoExecutor: false,
  isReminderObserver: false,
  reminderPeriodCreator: '',
  reminderPeriodExecutor: '',
  reminderPeriodCoExecutor: '',
  reminderPeriodObserver: '',
  addingFiles: [],
  deletingFiles: [],
  files: [],
};
