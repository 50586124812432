import { transformResponseError } from 'shared/api/libs';
import { getGuidsFromArray } from 'shared/tarnsform-helper/getGuidsFromArray';
import { changeElementPositionInArray } from 'shared/utils/changeElementPositionInArray';
import {
  deleteBoardsBoardGuid,
  deleteBoardsBoardGuidColumnsBoardColumnGuid,
  getBoards,
  getBoardsBoardGuid,
  getBoardsBoardGuidColumns,
  getBoardsTasksBoardGuid,
  postBoards,
  postBoardsBoardGuidColumns,
  putBoardsBoardGuid,
  putBoardsBoardGuidColumnsBoardColumnGuid,
  putBoardsBoardGuidColumnsBoardColumnGuidChangeBoard,
  putBoardsBoardGuidEmployees,
  putBoardsBoardGuidIsArchive,
  putBoardsTasksBoardGuidUpdateTaskGuid,
  putBoardsTasksChangeColumn,
} from '../services';
import {
  BoardColumnCreate,
  BoardColumnUpdate,
  BoardCreate,
  BoardTasksChangeBoard,
  BoardTaskUpdate,
  BoardUpdate,
  GetBoardsQueryParams,
  GetBoardsTasksBoardGuidQueryParams,
  GetListBoard,
  GetListBoardColumn,
  GetListBoardPaginated,
  GetListBoardTask,
  GetListEmployee,
} from '../types';
import {
  findFromColumnGuid,
  findIndexTo,
  findTaskIndexInColumn,
  IDropParamsTasks,
  updateTasksListForBoardOnChangeTasksOrder,
} from './libs/updateTasksListForBoardOnChangeTasksOrder';
import { taskProviderApi } from './taskProviderApi';

const boardApi = taskProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /* ======================================= */
    /* == Получение всех досок пользователя == */
    /* ======================================= */
    getBoards: build.query<GetListBoardPaginated, GetBoardsQueryParams>({
      queryFn: async (arg: GetBoardsQueryParams) => {
        try {
          const response = await getBoards(arg);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];
        if (!res.items)
          return [{ type: 'board/boardList', id: 'BOARD_LIST_PAGE' }];

        return [
          ...res.items.map((item) => ({
            type: 'board/boardList' as const,
            id: item.guid,
          })),
          { type: 'board/boardList', id: 'BOARD_LIST_PAGE' },
        ];
      },
    }),
    /* ======================================= */
    /* ======================================= */
    /* ======================================= */

    /* ========================== */
    /* == Создание новой доски == */
    /* ========================== */
    createBoard: build.mutation<GetListBoard, BoardCreate>({
      queryFn: async (arg) => {
        try {
          const response = await postBoards(arg);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res, error, arg) => {
        if (error) return [];
        return [{ type: 'board/boardList', id: 'BOARD_LIST_PAGE' }];
      },
    }),
    /* ========================== */
    /* ========================== */
    /* ========================== */

    /* ===================== */
    /* == Изменение доски == */
    /* ===================== */
    updateBoard: build.mutation<
      GetListBoard,
      { boardGuid: string; requestBody: BoardUpdate }
    >({
      queryFn: async (arg) => {
        try {
          const response = await putBoardsBoardGuid(
            arg.boardGuid,
            arg.requestBody
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res, error, arg) => {
        if (error || !res) return [];
        return [
          { type: 'board/boardList', id: res.guid },
          { type: 'board/boardList', id: 'BOARD_LIST_PAGE' },
        ];
      },
    }),

    /* ===================== */
    /* ===================== */
    /* ===================== */

    /* ==================== */
    /* == Удаление доски == */
    /* ==================== */
    deleteBoard: build.mutation<undefined, string>({
      queryFn: async (boardGuid) => {
        try {
          const response = await deleteBoardsBoardGuid(boardGuid);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res, error, arg) => {
        if (error) return [];
        return [
          { type: 'board/boardList', id: arg },
          { type: 'board/boardList', id: 'BOARD_LIST_PAGE' },
        ];
      },
    }),
    /* ==================== */
    /* ==================== */
    /* ==================== */

    /* ================================== */
    /* == Обновление списка участников == */
    /* ================================== */
    updateEmployeesOnBoard: build.mutation<
      undefined,
      {
        boardGuid: string;
        employees: GetListEmployee[];
      }
    >({
      queryFn: async (arg) => {
        try {
          const response = await putBoardsBoardGuidEmployees(arg.boardGuid, {
            employeesGuids: getGuidsFromArray(arg.employees),
          });
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res, error, arg) => {
        if (error) return [];
        return [
          { type: 'board/boardList', id: arg.boardGuid },
          // { type: 'board/boardList', id: 'BOARD_LIST_PAGE' },
        ];
      },
    }),

    /* ================================== */
    /* ================================== */
    /* ================================== */

    /* ========================= */
    /* == Архивирование досок == */
    /* ========================= */
    isArchiveBoard: build.mutation<
      GetListBoard,
      { boardGuid: string; isArchive: boolean }
    >({
      queryFn: async (arg) => {
        try {
          const response = await putBoardsBoardGuidIsArchive(arg.boardGuid, {
            isArchive: arg.isArchive,
          });
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res, error, arg) => {
        if (error) return [];
        return [
          { type: 'board/boardList', id: arg.boardGuid },
          { type: 'board/boardList', id: 'BOARD_LIST_PAGE' },
        ];
      },
    }),
    /* ========================= */
    /* ========================= */
    /* ========================= */

    /* ======================= */
    /* == Получение колонок == */
    /* ======================= */
    getColumns: build.query<GetListBoardColumn[], string>({
      queryFn: async (boardGuid) => {
        try {
          const response = await getBoardsBoardGuidColumns(boardGuid);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];

        return [
          ...res.map((item) => ({
            type: 'board/boardColumns' as const,
            id: item.guid,
          })),
          'board/boardColumns',
        ];
      },
    }),
    /* ======================= */
    /* ======================= */
    /* ======================= */

    /* ======================== */
    /* == Добавление колонок == */
    /* ======================== */
    createColumn: build.mutation<
      GetListBoardColumn,
      { boardGuid: string; queryArg: BoardColumnCreate }
    >({
      queryFn: async (arg) => {
        try {
          const response = await postBoardsBoardGuidColumns(
            arg.boardGuid,
            arg.queryArg
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async ({ boardGuid }, { dispatch, queryFulfilled }) => {
        try {
          const { data: createdPost } = await queryFulfilled;
          const patchResult = dispatch(
            boardApi.util.updateQueryData('getColumns', boardGuid, (draft) => {
              draft.push(createdPost);
            })
          );
        } catch {}
      },
    }),

    /* ======================== */
    /* ======================== */
    /* ======================== */

    /* ================================= */
    /* == Изменение положения колонки == */
    /* ================================= */
    changeColumnOrder: build.mutation<
      GetListBoardColumn,
      { boardGuid: string; columnGuid: string; order: number }
    >({
      queryFn: async (arg) => {
        try {
          const response =
            await putBoardsBoardGuidColumnsBoardColumnGuidChangeBoard(
              arg.boardGuid,
              arg.columnGuid,
              {
                boardGuid: arg.boardGuid,
                boardOrderNumber: arg.order,
              }
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async (
        { boardGuid, order, columnGuid },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          boardApi.util.updateQueryData('getColumns', boardGuid, (draft) => {
            const elIndex = draft.findIndex((el) => el.guid === columnGuid);

            // Если такого элемента нет, то перезапрашиваем столбцы (мистика)
            if (elIndex === -1) {
              boardApi.util.invalidateTags([
                { type: 'board/boardColumns', id: boardGuid },
              ]);
              return draft;
            }

            const newArray = changeElementPositionInArray(
              draft,
              elIndex,
              order
            ).map((el, i) => ({ ...el, boardOrderNumber: i }));
            return newArray;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    /* ================================= */
    /* ================================= */
    /* ================================= */

    /* ====================== */
    /* == Удаление колонки == */
    /* ====================== */
    deleteBoardColumn: build.mutation<
      undefined,
      { boardGuid: string; boardColumnGuid: string }
    >({
      queryFn: async (arg) => {
        try {
          const response = await deleteBoardsBoardGuidColumnsBoardColumnGuid(
            arg.boardGuid,
            arg.boardColumnGuid
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async (
        { boardGuid, boardColumnGuid },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          boardApi.util.updateQueryData('getColumns', boardGuid, (draft) => {
            return draft.filter((el) => el.guid !== boardColumnGuid);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    /* ====================== */
    /* ====================== */
    /* ====================== */

    /* ============================= */
    /* == Получение задач колонок == */
    /* ============================= */
    getColumnsTasks: build.query<
      GetListBoardTask[],
      { qParams: GetBoardsTasksBoardGuidQueryParams; boardGuid: string }
    >({
      queryFn: async (arg) => {
        try {
          const response = await getBoardsTasksBoardGuid(
            arg.boardGuid,
            arg.qParams
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];
        if (res.length === 0) return ['board/boardTask'];

        return [
          ...res.map((task) => ({
            type: 'board/boardTask' as const,
            id: task.taskGuid,
          })),
          'board/boardTask',
        ];
      },
    }),
    /* ============================= */
    /* ============================= */
    /* ============================= */

    /* ======================== */
    /* == Перемещение задачи == */
    /* ======================== */
    updateTaskPositionInSameBoard: build.mutation<
      GetListBoardTask[],
      {
        boardGuid: string;
        args: Required<BoardTasksChangeBoard>;
        dropParams: IDropParamsTasks;
        qParams: GetBoardsTasksBoardGuidQueryParams;
      }
    >({
      queryFn: async (arg) => {
        try {
          const response = await putBoardsTasksChangeColumn(arg.args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async (
        { boardGuid, dropParams, qParams, args: { tasksGuids } },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          boardApi.util.updateQueryData(
            'getColumnsTasks',
            { boardGuid, qParams },
            (oldTasks) => {
              let newTasks = oldTasks.map((t) => ({ ...t }));

              tasksGuids.forEach((taskGuid, i) => {
                const dp: IDropParamsTasks = {
                  fromColumnGuid: findFromColumnGuid(newTasks, taskGuid),
                  fromIndex: findTaskIndexInColumn(newTasks, taskGuid),
                  taskGuid,
                  toColumnGuid: dropParams.toColumnGuid,
                  toIndex:
                    i === 0
                      ? dropParams.toIndex
                      : findIndexTo(newTasks, tasksGuids[i - 1], taskGuid),
                };

                newTasks =
                  updateTasksListForBoardOnChangeTasksOrder(dp)(newTasks);
              });
              return newTasks;
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    moveTaskToAnotherBoard: build.mutation<
      GetListBoardTask[],
      BoardTasksChangeBoard
    >({
      queryFn: async (arg) => {
        try {
          const response = await putBoardsTasksChangeColumn(arg);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res, _, req) => {
        // if (!res) return [];
        return ['board/boardTask'];
      },
    }),
    /* ======================== */
    /* ======================== */
    /* ======================== */

    /* ================================== */
    /* == Получение информации о доске == */
    /* ================================== */
    getBoard: build.query<GetListBoard, string>({
      queryFn: async (arg) => {
        try {
          const response = await getBoardsBoardGuid(arg);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];

        return [{ type: 'board/boardList', id: res.guid }];
      },
    }),
    /* ================================== */
    /* ================================== */
    /* ================================== */

    /* ======================= */
    /* == Изменение столбца == */
    /* ======================= */
    updateColumn: build.mutation<
      GetListBoardColumn,
      { boardGuid: string; boardColumnGuid: string; args: BoardColumnUpdate }
    >({
      queryFn: async (arg) => {
        try {
          const response = await putBoardsBoardGuidColumnsBoardColumnGuid(
            arg.boardGuid,
            arg.boardColumnGuid,
            arg.args
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async (
        { boardGuid, boardColumnGuid, args },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          boardApi.util.updateQueryData('getColumns', boardGuid, (draft) => {
            const column = draft.find((el) => el.guid === boardColumnGuid);
            if (column) {
              column.color = args.color;
              column.title = args.title;
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    /* ======================= */
    /* ======================= */
    /* ======================= */

    /* =============================== */
    /* == Изменение задачи на доске == */
    /* =============================== */
    updateBoardTask: build.mutation<
      GetListBoardTask,
      {
        boardGuid: string;
        taskGuid: string;
        args: BoardTaskUpdate;
      }
    >({
      queryFn: async (arg) => {
        try {
          const response = await putBoardsTasksBoardGuidUpdateTaskGuid(
            arg.boardGuid,
            arg.taskGuid,
            arg.args
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res) => {
        if (!res) return [];

        return [{ type: 'board/boardTask', id: res.taskGuid }];
      },
    }),
    /* =============================== */
    /* =============================== */
    /* =============================== */
  }),
});

export const {
  useCreateBoardMutation,
  useDeleteBoardMutation,
  useGetBoardsQuery,
  useUpdateBoardMutation,
  useUpdateEmployeesOnBoardMutation,
  useIsArchiveBoardMutation,
  useChangeColumnOrderMutation,
  useCreateColumnMutation,
  useDeleteBoardColumnMutation,
  useGetColumnsQuery,
  useGetColumnsTasksQuery,
  useUpdateTaskPositionInSameBoardMutation,
  useGetBoardQuery,
  useMoveTaskToAnotherBoardMutation,
  useUpdateColumnMutation,
  useUpdateBoardTaskMutation,
} = boardApi;
