import { createEffect, sample } from 'effector';

import { getToken } from 'shared/utils/getToken';
import { token } from 'shared/utils/token';

import { changeIsAuthUser } from './is-auth-user';

/* Effect проверки валидности токена(Проверяем наличие токена в localStorage и делаем тестовый запрос на обновление токена) */
export const chekcIsValidTokenFx = createEffect({
  name: 'chekcIsValidTokenFx',
  async handler() {
    return await getToken();
  },
});

/* Если chekcIsValidTokenFx успешен, то меняем $isAuth на true */
sample({
  source: chekcIsValidTokenFx.doneData,
  fn: () => {
    if (token.get()) return true;

    return false;
  },
  target: changeIsAuthUser,
});
