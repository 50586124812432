// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dayjs } from 'dayjs';
import {
  boolean,
  date,
  mixed,
  number,
  object,
  ObjectSchema,
  string,
} from 'yup';

import { GetListEmployee } from 'shared/api/services/types';

/* NUMBER */
const NUMBER = number();
const NUMBER_POSITIVE = NUMBER.positive(
  'Не может быть отрицательным числом'
).transform((value) => (Number.isNaN(value) || value === 0 ? null : value));

/* BOOLEAN */
const BOOLEAN = boolean().required();

/* STRING */

const STRING = string();
const STRING_REQUIRED = STRING.required('Обязательное поле');

/* DATE */

const DATE = date()
  .nullable()
  .typeError('Не верный формат')
  .transform((curr, orig) => {
    return orig === '' || curr === 'Invalid Date' ? null : curr;
  });

const DATEJS = mixed<Dayjs>();

const builderDateEnd = (dateStartkeyName: string) =>
  DATEJS.when([dateStartkeyName], ([dateStart], schema) => {
    if (dateStart?.isValid()) {
      return schema.test(
        'dateFinish',
        'Дата окончания не может быть меньше даты начала',
        (dateFinith) => {
          return !dateFinith?.isBefore(dateStart) || false;
        }
      );
    }

    return schema;
  });

/* EMPLOYEE */
const EMPLOYEE: ObjectSchema<GetListEmployee> = object().shape({
  guid: STRING_REQUIRED,
  name: STRING_REQUIRED,
  isVacation: BOOLEAN.required(),
  position: object().shape({
    guid: STRING_REQUIRED,
    title: STRING_REQUIRED,
    category: object().shape({
      guid: STRING_REQUIRED,
      title: STRING_REQUIRED,
      isHead: BOOLEAN.required(),
    }),
  }),
});

const EMPLOYEE_REQUIRED: ObjectSchema<GetListEmployee> =
  EMPLOYEE.required('Обязательное поле');

/* FILE */
const FILE: ObjectSchema<{ guid: string; file: any }> = object().shape({
  guid: string().required(),
  file: mixed().test('fileSize', 'The file is too large', () => {
    return true;
  }),
});

export const SCHEMA = {
  NUMBER,
  NUMBER_POSITIVE,
  STRING_REQUIRED,
  DATE,
  EMPLOYEE,
  STRING,
  EMPLOYEE_REQUIRED,
  BOOLEAN,
  FILE,
  builderDateEnd,
  DATEJS,
};
