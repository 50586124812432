import { StatusModel } from '../api/services/types';

const NEW_COLOR = '#116E6B';
const NEW_COLOR_BACKGROUNG = 'white';
const COMPLITE_COLOR = '#46A818';
const COMPLITE_COLOR_BACKGROUND = '#white';
const FINISHED_COLOR = '#46A818';
const FINISHED_COLOR_BACKGROUND = '#DAF9CB';
const PENDING_COLOR = '#E7C34D';
const PENDING_COLOR_BACKGROUNG = '#FFF4D2';
const DEFERRED_COLOR = '#686868';
const DEFERRED_COLOR_BACKGROUND = '#D9D9D9';
const IN_WORK_COLOR = '#116E6B';
const IN_WORK_COLOR_BACKGROUND = '#C9E9E8';

export const switchColor = (status: StatusModel['title']) => {
  switch (status) {
    case 'Новая':
      return NEW_COLOR;
    case 'Выполнена':
      return COMPLITE_COLOR;
    case 'Завершена':
      return FINISHED_COLOR;
    case 'Ожидание':
      return PENDING_COLOR;
    case 'Отложена':
      return DEFERRED_COLOR;
    case 'В работе':
      return IN_WORK_COLOR;
    default:
      break;
  }
};

export const switchColorBackground = (status: StatusModel['title']) => {
  switch (status) {
    case 'Новая':
      return NEW_COLOR_BACKGROUNG;
    case 'Выполнена':
      return COMPLITE_COLOR_BACKGROUND;
    case 'Завершена':
      return FINISHED_COLOR_BACKGROUND;
    case 'Ожидание':
      return PENDING_COLOR_BACKGROUNG;
    case 'Отложена':
      return DEFERRED_COLOR_BACKGROUND;
    case 'В работе':
      return IN_WORK_COLOR_BACKGROUND;
    default:
      break;
  }
};
