import { Divider, Grid, Paper, Stack } from '@mui/material';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ContainerWithLabel } from 'shared/ui/container-with-label';
import { UiMenuTable } from 'shared/ui/table/ui-menu-table';
import { useDevice } from 'shared/utils/hook-type-device';

import { TaskStatus } from '../task-status/TaskStatus';
import { TaskInformationProps } from './type';

export const TaskInformation: FC<TaskInformationProps> = ({
  task,
  fields,
  onChangeFields,
  visibleFields,
  changeStatus,
}) => {
  const [isMobile] = useDevice();

  const Wrapper = useCallback(
    ({ children }: PropsWithChildren) => {
      if (isMobile) return <>{children}</>;
      else
        return (
          <Paper
            sx={{ p: [0, 2], display: 'flex' }}
            variant='outlined'
          >
            {children}
          </Paper>
        );
    },
    [isMobile]
  );

  return (
    <Grid
      container
      flexDirection={'column'}
    >
      <UiMenuTable
        fields={fields}
        setFields={onChangeFields}
      />
      <Wrapper>
        <Stack
          divider={<Divider sx={{ display: ['none', 'block'], m: 0 }} />}
          spacing={1}
          useFlexGap
          flexGrow={1}
          justifyContent={'space-between'}
        >
          <TaskStatus
            task={task}
            onChangeStatus={changeStatus}
            key={'TaskStatus'}
          />

          {visibleFields.map((info) => (
            <ContainerWithLabel
              label={info.title}
              key={info.field}
            >
              {info.renderComponent(task)}
            </ContainerWithLabel>
          ))}
        </Stack>
      </Wrapper>
    </Grid>
  );
};
