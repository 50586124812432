// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { createApi, createEvent, createStore, sample } from 'effector';

import { GetDocumentsControlQueryParams } from 'shared/api/services-document-flow/types';
import { paginationTasks } from 'shared/types/pagination-type';

import { getDocumentsControlFx } from 'entities/document/api/document-list-effector';

import { defaultFilter } from './constant';

const $documentControlPaginations = createStore<paginationTasks>(
  {
    CurrentPage: 0,
    HasNext: false,
    HasPrevious: false,
    PageSize: 20,
    TotalCount: 0,
    TotalPages: 0,
  },
  { name: 'document-control-paginations' }
);

$documentControlPaginations.on(getDocumentsControlFx.done, (_, { result }) => {
  return JSON.parse(result.headers['x-pagination']) as paginationTasks;
});

const $documentControlFilter = createStore<GetDocumentsControlQueryParams>(
  defaultFilter,
  { name: 'document-control-filter' }
);
const apiFilter = createApi($documentControlFilter, {
  setAll: (filter, payload: GetDocumentsControlQueryParams) => {
    return payload;
  },
});
const upDateList = createEvent();

sample({ clock: $documentControlFilter, target: getDocumentsControlFx });
sample({
  clock: upDateList,
  source: $documentControlFilter,
  target: getDocumentsControlFx,
});
export const documentControlFilter = {
  filter: $documentControlFilter,
  events: apiFilter,
  upDateList,
  headerPaginations: $documentControlPaginations,
};
