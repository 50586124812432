import { useGetTrainingProcessCompliteTrainingQuery } from 'shared/api/traning/rtk/trainingProviderApi';
import {
  SettingsFieldsFilter,
  useUrlStateTrainingFilter,
} from 'widgets/training/filter';
import { useTableCellsComplitedTreiningFromLocalStorage } from 'widgets/training/training-table/complited-training-table';
import { TrainingAdaptiveTable } from 'widgets/training/training-table/training-table-with-sub-table';
import { LayoutWithManagerBarTraining } from './layout';

const SETTINGS_FIELDS_FILTER: SettingsFieldsFilter = {
  showEmploye: true,
  showTrainer: true,
  showDate: true,
  showRetails: true,
  showThemeTraining: true,
};

export const CompliteTrainingPage = () => {
  /* Table fields */

  const [fields, setFields, visibleFields] =
    useTableCellsComplitedTreiningFromLocalStorage();

  const { filter, onChangeLimit, onChangePage } = useUrlStateTrainingFilter();
  const { data, isLoading, isFetching } =
    useGetTrainingProcessCompliteTrainingQuery({
      ...filter,
      Limit: filter?.Limit || 20,
      Page: filter?.Page || 1,
    });

  if (!isLoading && !isFetching && (!data || !data.data)) {
    return <></>;
  }

  return (
    <LayoutWithManagerBarTraining settingsFilter={SETTINGS_FIELDS_FILTER}>
      <TrainingAdaptiveTable
        isLoading={isLoading}
        isUpdate={isFetching}
        data={data?.data || []}
        fields={fields}
        visibleFields={visibleFields}
        onChangeFields={setFields}
        count={data?.count || 1000}
        page={filter?.Page || 1}
        limit={filter?.Limit || 20}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
      />
    </LayoutWithManagerBarTraining>
  );
};
