import { FC } from 'react';
import {
  UiTable,
  UiTableCell,
  UiTableHeaderWithSort,
  UiTablePagination,
  UiTableRowWithActions,
} from 'shared/ui/table';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';
import { UserNoAdaptationProcessType } from './type';

export const UserNoAdaptationRequiredTable: FC<UserNoAdaptationProcessType> = ({
  data: users,
  isLoading = false,
  isUpdate,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  count,
  order,
  onChangeOrder,
  renderActions,
  fields,
  onChangeFields,
  visibleFields,
}) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading && !users.length}
      skeleton={<UiTableSkeleton limit={limit} />}
      header={
        <UiTableHeaderWithSort
          viewCells={visibleFields}
          setViewCells={onChangeFields}
          allCells={fields}
          order={order.order}
          orderBy={order.orderBy}
          onChangeOrder={onChangeOrder}
          orderByList={['name', 'retailTitle']}
        />
      }
      body={users.map((user) => (
        <UiTableRowWithActions
          key={user.guid}
          renderActions={(isOpen, setIsLoading) =>
            renderActions(user, { isOpen, setIsLoading })
          }
        >
          {visibleFields.map((el) => (
            <UiTableCell
              key={el.field + user.guid}
              {...el.getTableCellProps()}
            >
              {el.renderComponent(user) || 'Error'}
            </UiTableCell>
          ))}
        </UiTableRowWithActions>
      ))}
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage + 1);
          }}
          page={page === 0 ? 0 : page - 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};
