import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { getReasonDocumentTypes } from 'shared/api/services-document-flow/services';
import { ErrorResponse } from 'shared/api/services-document-flow/types';

export const fetchTypeReasonDocumentFx = createEffect<
  typeof getReasonDocumentTypes,
  AxiosError<ErrorResponse>
>(getReasonDocumentTypes);
