import { useEffect, useMemo, useState } from 'react';
import { useAppSettings } from 'shared/user-app-settings/user-app-settings-context';
import { Tables, TablesController } from 'shared/utils/controllers';

export const useTablesSettingVersionControl = (
  tablesController: TablesController
) => {
  const { appSettings, updateAppSettings } = useAppSettings();
  const [isVersionChecked, setIsVersionChecked] = useState(false);

  useEffect(() => {
    if (!appSettings || isVersionChecked) return;
    if (
      !appSettings.tableRowSettings ||
      appSettings.tableRowSettings.length === 0
    ) {
      setIsVersionChecked(true);

      return;
    }

    const wrongTables = findVersionMismatch();

    if (wrongTables.length !== 0) updateSettingsOfWrongTables(wrongTables);

    setIsVersionChecked(true);
  }, [appSettings]);

  const findVersionMismatch = () => {
    if (!appSettings || !appSettings.tableRowSettings) return [];

    const wrongTables: Tables[] = [];

    appSettings.tableRowSettings.forEach((tableSetting) => {
      const table = tablesController.getTableInfo(tableSetting.table);

      if (!table) return;

      if (table.version !== tableSetting.version) {
        wrongTables.push(tableSetting.table);
      }
    });

    return wrongTables;
  };

  const updateSettingsOfWrongTables = (wrongTables: Tables[]) => {
    if (!appSettings || !appSettings.tableRowSettings) return;

    const newSettings = [...appSettings.tableRowSettings];

    wrongTables.forEach((wrongTable) => {
      const clientTable = tablesController.getTableInfo(wrongTable);

      if (!clientTable)
        throw new Error(`Таблица ${wrongTable} не зарегистрирована`);

      const settingsTableIndex = newSettings.findIndex(
        (el) => el.table === wrongTable
      );
      const settingsTable = newSettings[settingsTableIndex];

      let newRowSettings = [...clientTable.map];

      newRowSettings = newRowSettings.map((rowSetting) => {
        const rowInSettings = settingsTable.settings.find(
          (el) => el.field === rowSetting.field
        );

        if (!rowInSettings) {
          return { ...rowSetting };
        } else {
          return {
            ...rowSetting,
            isShow: rowInSettings.isShow,
            order: rowInSettings.order,
          };
        }
      });

      const newSetting = {
        version: clientTable.version,
        settings: newRowSettings,
        table: wrongTable,
      };

      newSettings[settingsTableIndex] = newSetting;
    });

    updateAppSettings({ ...appSettings, tableRowSettings: newSettings });
  };

  const currentAppSettings = useMemo(() => {
    return isVersionChecked ? appSettings : undefined;
  }, [isVersionChecked, appSettings]);

  return {
    appSettings: currentAppSettings,
    updateAppSettings,
  };
};
