import { useTransfromUrlStateToBaseFormFilter } from '../../hooks';
import { useUrlStateDocumentControlFilter } from '../use-url-state-document-control-filter';

import { DocumentFlowFilterBaseForm } from '../../types';

export const useInitialControlFilterFormFromUrlState = () => {
  const { filter } = useUrlStateDocumentControlFilter();
  const { initBaseFilter, isLoadingAnyLaziOptionsDocumentFlow } =
    useTransfromUrlStateToBaseFormFilter();

  const onInitial = async (): Promise<DocumentFlowFilterBaseForm> => {
    const [baseFilters] = await Promise.all([initBaseFilter(filter)]);

    return baseFilters;
  };

  return [onInitial, isLoadingAnyLaziOptionsDocumentFlow] as const;
};
