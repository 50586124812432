import { Typography } from '@mui/material';
import { AdaptationManagerResponsesGetListStageTask } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const ADAPTATION_STAGE_TASKS_MAP: FieldMapType<AdaptationManagerResponsesGetListStageTask>[] =
  [
    {
      field: 'title',
      title: 'Задача',
      isShow: true,
      order: 1,
      getTableCellProps: () => ({}),
      renderComponent: (task) => {
        return <Typography>{task.title}</Typography>;
      },
    },
    {
      field: 'creatorPositionTitle',
      title: 'Постановщик',
      order: 2,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => {
        return <Typography>{task.creatorPositionTitle}</Typography>;
      },
    },
    {
      field: 'executorPositionTitle',
      title: 'Исполнитель',
      order: 3,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => {
        return <Typography>{task.executorPositionTitle}</Typography>;
      },
    },
    {
      field: 'startDaysCount',
      title: 'Дата начала',
      order: 4,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => {
        return <Typography>{task.startDaysCount}</Typography>;
      },
    },
    {
      field: 'finishDaysCount',
      title: 'Дата завершения',
      order: 5,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => {
        return <Typography>{task.finishDaysCount}</Typography>;
      },
    },
  ];
