import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useGetAllowedExecutersQuery } from 'shared/api/services/rtk/optionApi';
import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import { CustomAutocomplete } from 'shared/ui/base-ui';
import { DialogForForm, FormLayout } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { ChangeExecuterFormFields, ChangeExecuterFormProps } from './type';

export const ChangeExecuterForm: FC<ChangeExecuterFormProps> = ({
  onSubmit,
  renderFormActions,
  initValue,
}) => {
  const { data: executers, isLoading: isLoadingExecuters } =
    useGetAllowedExecutersQuery();
  const { control, handleSubmit } = useForm<ChangeExecuterFormFields>({
    defaultValues: initValue,
  });

  return (
    <FormLayout
      actions={renderFormActions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit, console.error)}
    >
      <Controller
        control={control}
        name='executer'
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            value={value || null}
            loading={isLoadingExecuters}
            onChange={(_, newValue) => {
              onChange(newValue);
            }}
            isOptionEqualToValue={(eq, val) => eq.guid === val.guid}
            getOptionLabel={(emp) =>
              getEmployeTitle({
                employeeName: emp.name,
                employeePosition: emp.position?.title,
              })
            }
            renderOption={(props, option) => (
              <Typography
                {...props}
                key={option.guid}
              >
                {getEmployeTitle({
                  employeeName: option.name,
                  employeePosition: option.position?.title,
                })}
              </Typography>
            )}
            options={executers || []}
            textFieldProps={{
              required: true,
              label: 'Исполнитель',
              placeholder: 'Выберите исполнителя',
            }}
          />
        )}
      />
    </FormLayout>
  );
};

interface ChangeExecuterDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: ChangeExecuterFormFields) => void;
  isLoading?: boolean;
  initValue?: ChangeExecuterFormFields;
}
export const ChangeExecuterDialog: FC<ChangeExecuterDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  initValue,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Изменение исполнителя' />}
    >
      <ChangeExecuterForm
        onSubmit={onSubmit}
        initValue={initValue}
        renderFormActions={() => [
          <LoadingButton
            loading={isLoading}
            key={1}
            variant='contained'
            type='submit'
          >
            Сохранить
          </LoadingButton>,
          <Button
            key={2}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
