import { transformResponseError } from 'shared/api/libs';
import {
  deleteTasks,
  getTasksMeControllerWeb,
  getTasksMeCreator,
  getTasksMeExecutor,
  getTasksTaskGuid,
  getTasksTaskGuidSubTasks,
  postTasks,
  putTasksChangeCoExecutorsAndObservers,
  putTasksChangeExecutor,
  putTasksChangeRemind,
  putTasksChangeStartFinishDates,
  putTasksChangeStatus,
  putTasksChangeStatusToLast,
  putTasksTaskGuid,
  putTasksTaskGuidChangeStatus,
} from '../services';
import {
  GetListTask,
  GetListTaskPaginated,
  GetTask,
  GetTasksMeControllerWebQueryParams,
  GetTasksMeCreatorQueryParams,
  GetTasksMeExecutorQueryParams,
  StatusModel,
  TaskCreate,
  TasksChangeCoExecutorsAndObservers,
  TasksChangeExecutor,
  TasksChangeRemind,
  TasksChangeStartFinishDates,
  TasksChangeStatus,
  TaskUpdate,
} from '../types';
import { taskProviderApi } from './taskProviderApi';

const taskApi = taskProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /* ===================== */
    /* == Создание задачи == */
    /* ===================== */
    createTask: build.mutation<GetTask, TaskCreate>({
      queryFn: async (arg) => {
        try {
          const response = await postTasks(arg);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res) => {
        if (!res) return [];
        return ['board/boardTask', 'task/taskList'];
      },
    }),
    /* ===================== */
    /* ===================== */
    /* ===================== */

    /* ============================== */
    /* == Изменение статуса задачи == */
    /* ============================== */
    updateTaskStatus: build.mutation<
      GetListTask,
      { taskGuid: string; status: StatusModel }
    >({
      queryFn: async (arg) => {
        try {
          const response = await putTasksTaskGuidChangeStatus(
            arg.taskGuid,
            arg.status
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res) => {
        if (!res) return [];

        return [
          { type: 'task/task', id: res.guid },
          { type: 'board/boardTask', id: res.guid },
        ];
      },
    }),
    /* ============================== */
    /* ============================== */
    /* ============================== */

    /* ====================== */
    /* == Изменение задачи == */
    /* ====================== */
    updateTask: build.mutation<GetTask, { taskGuid: string; args: TaskUpdate }>(
      {
        queryFn: async (arg) => {
          try {
            const response = await putTasksTaskGuid(arg.taskGuid, arg.args);
            return { data: response.data };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        invalidatesTags: (res) => {
          if (!res) return [];

          return [
            { type: 'task/task', id: res.guid },
            { type: 'board/boardTask', id: res.guid },
          ];
        },
      }
    ),
    /* ====================== */
    /* ====================== */
    /* ====================== */

    /* ================== */
    /* == Список задач == */
    /* ================== */
    getTaskListMeExecutor: build.query<
      GetListTaskPaginated,
      GetTasksMeExecutorQueryParams
    >({
      queryFn: async (args) => {
        try {
          const response = await getTasksMeExecutor(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];
        if (!res.items) return [];

        return [
          ...res.items.map((task) => ({
            type: 'task/task' as const,
            id: task.guid,
          })),
          'task/taskList',
        ];
      },
    }),
    getTaskListMeCreator: build.query<
      GetListTaskPaginated,
      GetTasksMeCreatorQueryParams
    >({
      queryFn: async (args) => {
        try {
          const response = await getTasksMeCreator(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];
        if (!res.items) return [];

        return [
          ...res.items.map((task) => ({
            type: 'task/task' as const,
            id: task.guid,
          })),
          'task/taskList',
        ];
      },
    }),
    getTaskListMeController: build.query<
      GetListTaskPaginated,
      GetTasksMeControllerWebQueryParams
    >({
      queryFn: async (args) => {
        try {
          const response = await getTasksMeControllerWeb(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];
        if (!res.items) return [];

        return [
          ...res.items.map((task) => ({
            type: 'task/task' as const,
            id: task.guid,
          })),
          'task/taskList',
        ];
      },
    }),
    /* ================== */
    /* ================== */
    /* ================== */

    /* ==================== */
    /* == Удаление задач == */
    /* ==================== */
    deleteTask: build.mutation<undefined, string>({
      queryFn: async (taskGuid) => {
        try {
          const response = await deleteTasks({ tasksGuids: [taskGuid] });
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (_, __, taskGuid) => {
        return [
          { type: 'task/task', id: taskGuid },
          { type: 'board/boardTask', id: taskGuid },
        ];
      },
    }),
    deleteTasks: build.mutation<undefined, string[]>({
      queryFn: async (taskGuids) => {
        try {
          const response = await deleteTasks({ tasksGuids: taskGuids });
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (_, __, taskGuids) => {
        return [
          ...taskGuids.map((taskGuid) => ({
            type: 'task/task' as const,
            id: taskGuid,
          })),
          ...taskGuids.map((taskGuid) => ({
            type: 'board/boardTask' as const,
            id: taskGuid,
          })),
        ];
      },
    }),
    /* ==================== */
    /* ==================== */
    /* ==================== */

    /* ====================== */
    /* == Получение задачи == */
    /* ====================== */
    getTask: build.query<GetTask, string>({
      queryFn: async (taskGuid: string) => {
        try {
          const response = await getTasksTaskGuid(taskGuid);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];

        return [{ type: 'task/task', id: res.guid }];
      },
    }),
    /* ====================== */
    /* ====================== */
    /* ====================== */

    /* ======================== */
    /* == Получение подзадач == */
    /* ======================== */
    getSubTasks: build.query<GetListTask[], string>({
      queryFn: async (taskGuid) => {
        try {
          const response = await getTasksTaskGuidSubTasks(taskGuid);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];

        return [
          ...res.map((task) => ({ type: 'task/task' as const, id: task.guid })),
          'task/taskList',
        ];
      },
    }),
    /* ======================== */
    /* ======================== */
    /* ======================== */

    /* =========================== */
    /* == Изменение напоминаний == */
    /* =========================== */
    changeRemind: build.mutation<undefined, TasksChangeRemind>({
      queryFn: async (args) => {
        try {
          const response = await putTasksChangeRemind(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (_, __, { tasksGuids }) => {
        return tasksGuids.map((taskGuid) => ({
          type: 'task/task',
          id: taskGuid,
        }));
      },
    }),
    /* =========================== */
    /* =========================== */
    /* =========================== */

    changeExecutors: build.mutation<undefined, TasksChangeExecutor>({
      queryFn: async (args) => {
        try {
          const response = await putTasksChangeExecutor(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: () => {
        return ['task/task', 'board/boardTask'];
      },
    }),
    //
    //
    changeCoExecutorsAnsObservers: build.mutation<
      undefined,
      TasksChangeCoExecutorsAndObservers
    >({
      queryFn: async (args) => {
        try {
          const response = await putTasksChangeCoExecutorsAndObservers(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (_, __, args) => {
        return ['task/task', 'board/boardTask'];
      },
    }),
    //
    //
    changeStartFinishDates: build.mutation<
      undefined,
      TasksChangeStartFinishDates
    >({
      queryFn: async (args) => {
        try {
          const response = await putTasksChangeStartFinishDates(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (_, __, args) => {
        return ['task/task', 'board/boardTask'];
      },
    }),
    //
    //
    changeStatuses: build.mutation<undefined, TasksChangeStatus>({
      queryFn: async (args) => {
        try {
          const response = await putTasksChangeStatus(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (_, __, args) => {
        return ['task/task', 'board/boardTask'];
      },
    }),
    changeStatusesToNext: build.mutation<undefined, string[]>({
      queryFn: async (args) => {
        try {
          const response = await putTasksChangeStatusToLast({
            tasksGuids: args,
          });
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (_, __, req) => {
        if (req.length === 0) return [];
        return [
          ...req.map((taskGuid) => ({
            type: 'board/boardTask' as const,
            id: taskGuid,
          })),
          'task/taskList',
        ];
      },
    }),
  }),
});

export const {
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useUpdateTaskStatusMutation,
  useGetTaskListMeControllerQuery,
  useGetTaskListMeCreatorQuery,
  useGetTaskListMeExecutorQuery,
  useLazyGetTaskListMeExecutorQuery,
  useLazyGetTaskListMeCreatorQuery,
  useLazyGetTaskListMeControllerQuery,
  useDeleteTaskMutation,
  useGetSubTasksQuery,
  useGetTaskQuery,
  useChangeRemindMutation,

  useChangeCoExecutorsAnsObserversMutation,
  useChangeExecutorsMutation,
  useChangeStartFinishDatesMutation,
  useDeleteTasksMutation,
  useChangeStatusesMutation,
  useChangeStatusesToNextMutation,
} = taskApi;
