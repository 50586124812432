// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

const DEVISE_ID_KEY_LOCAL_STORAGE = 'deviceId';

export const deviceIdLocalStorage = {
  save: async (deviceId: string) => {
    localStorage.setItem(DEVISE_ID_KEY_LOCAL_STORAGE, JSON.stringify(deviceId));

    return deviceId;
  },
  get: () => {
    const deviseIdLocalStorage = localStorage.getItem(
      DEVISE_ID_KEY_LOCAL_STORAGE
    );

    if (deviseIdLocalStorage) {
      const deviceIdFromLocalStorage = JSON.parse(deviseIdLocalStorage);

      return deviceIdFromLocalStorage;
    } else {
      const string = Math.random().toString();

      localStorage.setItem(DEVISE_ID_KEY_LOCAL_STORAGE, JSON.stringify(string));

      return string;
    }
  },
  clear: () => {
    localStorage.removeItem(DEVISE_ID_KEY_LOCAL_STORAGE);
  },
};
