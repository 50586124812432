export const InstagramSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      fill='none'
      viewBox='0 0 26 26'
    >
      <path
        fill='url(#paint0_linear_369_1066)'
        d='M18.006 25.024H8.151c-4.004 0-7.238-3.224-7.29-7.268V7.93c0-3.992 3.235-7.216 7.29-7.267h9.855c4.004 0 7.238 3.224 7.29 7.267v9.827c-.052 4.044-3.286 7.268-7.29 7.268zM8.151 3.17a4.813 4.813 0 00-4.825 4.81v9.828a4.813 4.813 0 004.825 4.81h9.855c2.67 0 4.826-2.149 4.826-4.81V7.98a4.813 4.813 0 00-4.826-4.811H8.151zm4.928 16.02c-3.491 0-6.314-2.816-6.314-6.296 0-3.48 2.823-6.295 6.314-6.295 3.49 0 6.313 2.815 6.313 6.295S16.57 19.19 13.08 19.19zm0-10.135c-2.105 0-3.85 1.74-3.85 3.84 0 2.098 1.745 3.838 3.85 3.838 2.104 0 3.85-1.74 3.85-3.839a3.84 3.84 0 00-3.85-3.839zm6.313-.92a1.488 1.488 0 01-1.488-1.485 1.488 1.488 0 012.977 0c0 .819-.667 1.484-1.489 1.484z'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_369_1066'
          x1='13.105'
          x2='13.032'
          y1='0.711'
          y2='25.03'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6A479B'></stop>
          <stop
            offset='0.415'
            stopColor='#C11C76'
          ></stop>
          <stop
            offset='0.702'
            stopColor='#C84C4E'
          ></stop>
          <stop
            offset='1'
            stopColor='#E09B3C'
          ></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
