import { EventCreateRequest } from 'shared/api/visit/types';

import { CreateConferenceEventFormFields } from '../type';

export const convertCreateConferenceEventFormToFetch = (
  form: CreateConferenceEventFormFields
): EventCreateRequest => {
  return {
    directionId: form.directionId,
    departmentId: form.departmentId,
    members: form.homiesIds,
    startDate: form.dateStart,
    endDate: form.dateFinish,
    isVisitingBlocked: false,
    isConference: true,
  };
};
