import { GetListBoardTask } from 'shared/api/services/types';

export const addOnIndexAndReorderTasks = (params: {
  arr: GetListBoardTask[];
  addToIndex: number;
  item: GetListBoardTask;
}) => {
  const { arr, addToIndex, item } = params;

  if (arr.length === 0) {
    return [{ ...item, boardColumnOrderNumber: 0 }];
  }
  if (!arr[addToIndex] && arr[addToIndex - 1]) {
    return [
      ...arr,
      {
        ...item,
        boardColumnOrderNumber: arr[addToIndex - 1].boardColumnOrderNumber + 1,
      },
    ];
  }

  return arr.reduce<GetListBoardTask[]>((accum, current, index) => {
    if (addToIndex === index) {
      accum.push({
        ...item,
        boardColumnOrderNumber: current.boardColumnOrderNumber,
      });
    }
    if (index > addToIndex || index === addToIndex) {
      accum.push({
        ...current,
        boardColumnOrderNumber: current.boardColumnOrderNumber + 1,
      });
    } else {
      accum.push(current);
    }

    return accum;
  }, []);
};
