import { Grid, Typography } from '@mui/material';
import { VisitStager } from 'features/visit/Stager/ui/Stager';
import { FC, ReactNode } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { ChecklistProvider } from 'entities/visit/checkList/context/ChecklistContext';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

export enum STAGES {
  stage1 = 'stage1',
  stage2 = 'stage2',
  stage3 = 'stage3',
  stage4 = 'stage4',
  stage5 = 'stage5',
  stage6 = 'stage6',
}

interface VisitLayoutProps {
  children: ReactNode;
}
export const VisitLayout: FC<VisitLayoutProps> = ({ children }) => {
  const [isMob] = useDevice();

  return (
    <ChecklistProvider>
      <LayoutPage.Page>
        <LayoutPage.Bar>
          {!isMob && (
            <Typography
              //marginTop={4}
              //marginBottom={2}
              variant='h1'
              fontWeight={700}
              fontSize={[18, 20]}
              width='100%'
              px={[2, 0]}
            >
              Заполнить чек-лист
            </Typography>
          )}
        </LayoutPage.Bar>

        <Grid
          container
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
          paddingX={[0, 2]}
          justifyContent={'space-between'}
          gap={[0, 2]}
        >
          {!isMob && (
            <Grid
              width={{
                xs: '100%',
                lg: '20%',
              }}
              px={[2, 0]}
            >
              <VisitStager />
            </Grid>
          )}

          <Grid flexGrow={1}>{children}</Grid>
        </Grid>
      </LayoutPage.Page>
    </ChecklistProvider>
  );
};
