// eslint-disable-next-line budapestian/global-constant-pattern
import { prepareResponseRunnungTasksToTasksStages } from './libs/prepareResponseRunnungTasksToTasksStages';
import { ProtocolOpeningRunningTasksAdaptiveTable } from './ProtocolOpeningRunningTasksAdaptiveTable';
import { useTableCellsRunningTaskFromLocalStorage } from './use-table-from-local-storage';

export const ProtocolsOpeningRetailsRunningTasks = {
  useCellFromLocalStorage: useTableCellsRunningTaskFromLocalStorage,
  Table: ProtocolOpeningRunningTasksAdaptiveTable,
  prepareResponseTasksToTasksOnStages: prepareResponseRunnungTasksToTasksStages,
};
