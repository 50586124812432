import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useDeleteOpeningTemplatesProcessMutation } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useConfirmDeleteRunningProcess = (openingProcessGuid?: string) => {
  const [deleteRunningProcess, { isLoading: isLoadingDeleteRunningProcess }] =
    useDeleteOpeningTemplatesProcessMutation();
  const { open: openSnackBar } = useSnackbar();
  const {
    isOpen: isOpenConfirmDialogDeleteRunningProcess,
    close: onCancelDeleteRunningProcess,
    open: onOpenDialogConfirmDeleteRunningProcess,
  } = useViewModal();

  const onConfirmDeleteRunningProcess = useCallback(async () => {
    if (openingProcessGuid) {
      await deleteRunningProcess({
        openingProcessGuid,
      }).then((response) => {
        if ('error' in response) {
          openSnackBar({
            text: getMessageFromFetchBaseQueryError(response.error),
            type: 'error',
            duration: 3000,
          });
        } else {
          openSnackBar({
            text: 'Шаблон успешно удален',
            type: 'success',
            duration: 3000,
          });
          onCancelDeleteRunningProcess();
        }
      });
    }
  }, [openingProcessGuid]);

  return {
    onCancelDeleteRunningProcess,
    onConfirmDeleteRunningProcess,
    onOpenDialogConfirmDeleteRunningProcess,
    isOpenConfirmDialogDeleteRunningProcess,
    isLoadingDeleteRunningProcess,
  };
};
