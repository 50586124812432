import { useURLState } from 'shared/url-helper/useURLState';

import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';
import { OpeningProcessesPreparedStageTasksListQueryParams } from './types';

const convertorURLToRetailOpeningTemplatesRunProcess: URLToObjConverter<
  OpeningProcessesPreparedStageTasksListQueryParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_RETAIL_OPENING_TEMPLATE_RUN_PROCESS_MAP
  ) as OpeningProcessesPreparedStageTasksListQueryParams;
};

const CONVERT_RETAIL_OPENING_TEMPLATE_RUN_PROCESS_MAP: ConvertMapWithType<OpeningProcessesPreparedStageTasksListQueryParams> =
  [
    { key: 'ProtocolGuid', type: ConvertTypes.string },
    { key: 'StagesToSkipGuids', type: ConvertTypes.masString },
    { key: 'countdownDate', type: ConvertTypes.string },
  ];

export const useUrlStateRetailOpeningTemplatesRunProcessQueryParams = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToRetailOpeningTemplatesRunProcess,
    true
  );

  return {
    filter,
    setFilter,
  };
};
