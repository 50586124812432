import { useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import {
  useCreateOpeningTemplatesMutation,
  useGetOpeningTemplateDetailQuery,
  useUpdateOpeningTemplatesMutation,
} from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { FormOpeningTemplatesValue } from '../types';

export const useDialogFormCreateOpeningTemplates = (templateGuid?: string) => {
  const { open: openSnackBar } = useSnackbar();
  const { open, isOpen, close } = useViewModal();
  const [createOpeningTemplate, { isLoading: isLoadingCreate }] =
    useCreateOpeningTemplatesMutation();
  const [updateOpeningTemplate, { isLoading: isLoadingUpdate }] =
    useUpdateOpeningTemplatesMutation();

  const { data, isLoading } = useGetOpeningTemplateDetailQuery(
    { guid: templateGuid as string },
    {
      skip: !templateGuid || !isOpen,
      selectFromResult: ({ data: initialValue, ...oter }) => {
        return {
          ...oter,
          data: {
            ...initialValue,
            title: initialValue?.title || '',
            isAutorun: initialValue?.isAutorun || false,
            countdownField: initialValue?.countdownField || null,
            autorunTrigger: initialValue?.autorunTrigger || null,
          },
        };
      },
    }
  );

  const onSubmit: SubmitHandler<FormOpeningTemplatesValue> = useCallback(
    async (form) => {
      const { title, isAutorun, autorunTrigger, countdownField } = form;

      if (!countdownField) return;

      const newTemplateField = {
        title,
        isAutorun,
        autorunTriggerGuid: autorunTrigger ? autorunTrigger.guid : null,
        countdownFieldGuid: countdownField.guid,
      };

      const createOrUpdateTenplate = templateGuid
        ? updateOpeningTemplate({
            guid: templateGuid,
            params: newTemplateField,
          })
        : createOpeningTemplate(newTemplateField);

      await createOrUpdateTenplate.then((response) => {
        if ('error' in response) {
          openSnackBar({
            text: getMessageFromFetchBaseQueryError(response.error),
            type: 'error',
            duration: 3000,
          });
        } else {
          openSnackBar({
            text: `Шаблон успешно ${templateGuid ? 'сохранен' : 'создан'}`,
            type: 'success',
            duration: 3000,
          });
          close();
        }
      });
    },
    [close, createOpeningTemplate, updateOpeningTemplate, templateGuid]
  );

  return {
    onSubmit,
    isOpen,
    close,
    open,
    initialValue: data,
    isLoadingInitialValue: isLoading,
    isLoadingSubmit: isLoadingCreate || isLoadingUpdate,
  };
};
