import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

export const UIArticleDescription: FC<{
  description: { id: number; description: string | undefined }[];
}> = ({ description }) => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.customGrey.main,
        border: `solid 1px ${theme.palette.customGrey.dark}`,
        padding: '10px 24px',
        borderRadius: '10px',
      }}
    >
      <Typography
        variant='h3'
        fontSize={14}
        fontWeight={700}
        sx={{ width: '100%' }}
        paddingBottom='8px'
      >
        Информация по нормативу
      </Typography>
      <Grid>
        {description.map((item) => (
          <Typography
            key={item.id}
            fontSize={12}
          >
            {item.description}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};
