import { useStore } from 'effector-react';
import { deleteDocumentIdFx } from 'entities/document/api/document-id-effector';
import { useCallback } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';

export const useDeleteDocumentIdActions = (documentId: number) => {
  const prendingDel = useStore(deleteDocumentIdFx.pending);
  const {
    isOpen: isOpenDealogConfirmDelete,
    close: onCloseDialogConfirmDelete,
    open: onOpenDialogConfirmDelete,
  } = useViewModal();

  const handleConfirmDelDocument = useCallback(async () => {
    if (prendingDel) return null;

    await deleteDocumentIdFx(documentId);

    onCloseDialogConfirmDelete();
  }, [documentId, onCloseDialogConfirmDelete]);

  return {
    handleConfirmDelDocument,
    onOpenDialogConfirmDelete,
    onCloseDialogConfirmDelete,
    isOpenDealogConfirmDelete,
  };
};
