import { AppBar, Box, Grid, IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import { UserDataForHeader } from 'entities/user/user-data-for-header';

import { HideOnScroll, Logo, LogOutButton } from './libs';

import { Close } from '@mui/icons-material';

import { NavBarContext } from 'widgets/main-layout-app/navBarContext';

import { Link } from 'shared/ui/base-ui';

import { theme } from 'shared/theme/theme';
import { APP_BAR_SX } from './constants';

export const Header = () => {
  const isOpenMenuApp = NavBarContext.useIsOpen();
  const handleToggleMenuApp = NavBarContext.useToggleFn();

  return (
    <>
      <HideOnScroll>
        <AppBar sx={APP_BAR_SX}>
          <Grid
            gap={2}
            display={'flex'}
            alignItems={'center'}
          >
            <IconButton
              color='primary'
              sx={{ borderRadius: 0, fontSize: 28 }}
              onClick={handleToggleMenuApp}
            >
              {isOpenMenuApp ? (
                <Close fontSize='inherit' />
              ) : (
                <MenuIcon fontSize='inherit' />
              )}
            </IconButton>
            <Link
              to={'/'}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              <Logo />
            </Link>
          </Grid>

          <Grid
            gap={2}
            display={'flex'}
            alignItems={'center'}
          >
            <UserDataForHeader />
            <Box
              sx={{
                display: {
                  xs: 'none',
                  lg: 'block',
                },
              }}
            >
              <LogOutButton />
            </Box>
          </Grid>
        </AppBar>
      </HideOnScroll>
      <Box
        sx={{
          ...theme.mixins.heightAppBar,
        }}
      ></Box>
    </>
  );
};
