import dayjs from 'dayjs';
import { array, object, ObjectSchema } from 'yup';

import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';

import { CreateConferenceEventFormFields } from '../type';

export const createConferenceEventFormSchema: ObjectSchema<CreateConferenceEventFormFields> =
  object().shape({
    departmentId: SCHEMA.NUMBER.required(),
    directionId: SCHEMA.NUMBER.required(),
    dateStart: SCHEMA.STRING_REQUIRED,
    dateFinish: SCHEMA.STRING_REQUIRED.when(
      'dateStart',
      ([dateStart], schema) => {
        return schema.test(
          'dateFinish',
          'Дата окончания не может быть раньше даты начала',
          (dateFinish) => {
            return dayjs(dateFinish).isAfter(dayjs(dateStart));
          }
        );
      }
    ),
    homiesIds: array(SCHEMA.NUMBER.required()).min(0).required(),
  });
