import { GetTasksMeExecutorQueryParams } from 'shared/api/services/types';

import { CONVERT_TASK_LIST_FILTER_MAP } from '../convertMaps';
import { convertURLToObject } from '../convertURLToObject';
import { URLToObjConverter } from '../type';

export const convertURLToTaskList: URLToObjConverter<
  GetTasksMeExecutorQueryParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_TASK_LIST_FILTER_MAP
  ) as GetTasksMeExecutorQueryParams;
};
