import { filterUndefinedAndNullItemFromArray } from 'shared/utils/filterUndefinedAndNullItemFromArray';
import {
  PreparedStageTask,
  PreparedStageTasksValue,
  RunProcessRequest,
} from '../types';

export const preparedFormValueStagesToSubmitStageTasks = (
  stages: PreparedStageTasksValue[]
): RunProcessRequest[] => {
  return stages.reduce<RunProcessRequest[]>((accum, current) => {
    return [
      ...accum,
      ...filterUndefinedAndNullItemFromArray(
        current.tasks.map(mapFromValueTaskToSubminTask)
      ),
    ];
  }, []);
};

const mapFromValueTaskToSubminTask = (
  task: PreparedStageTask
): RunProcessRequest | undefined => {
  const { stageTaskGuid, creator, coExecutors, observers, executor } = task;

  if (!creator.employee || !executor.employee) return undefined;

  return {
    stageTaskGuid,

    creatorGuid: creator.employee.guid,

    executorGuid: executor.employee.guid,

    coExecutorsGuids: filterUndefinedAndNullItemFromArray(
      coExecutors.map((observ) => observ.employee?.guid)
    ),

    observersGuids: filterUndefinedAndNullItemFromArray(
      observers.map((observ) => observ.employee?.guid)
    ),
  };
};
