import { Paper, Typography } from '@mui/material';
import { FC } from 'react';

export const NotStagesInfo: FC = () => {
  return (
    <Paper
      sx={{
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography fontWeight={'bold'}>У шаблона нет этапов</Typography>
    </Paper>
  );
};
