import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { VisitingGetListResponse } from 'shared/api/visit/types';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const VISIT_FIELD_MAP: FieldMapType<VisitingGetListResponse>[] = [
  {
    field: 'id',
    title: 'ID посещения',
    renderComponent: (value) => <Typography>{value.id}</Typography>,
    order: 0,
    isShow: false,
    getTableCellProps: () => ({}),
  },
  {
    field: 'title',
    title: 'Название',
    renderComponent: (value) => <Typography>{value.title}</Typography>,
    order: 1,
    isShow: true,
    getTableCellProps: () => ({}),
  },
  {
    field: 'visitingDate',
    title: 'Дата посещения',
    renderComponent: (value) => (
      <Typography>{dayjs(value.visitingDate).format('DD.MM.YYYY')}</Typography>
    ),
    order: 2,
    isShow: true,
    getTableCellProps: () => ({ align: 'center' }),
  },
  {
    field: 'visitingChecklistId',
    title: 'ID чек-листа',
    renderComponent: (value) => (
      <Typography>{value.visitingChecklistId}</Typography>
    ),
    order: 3,
    isShow: false,
    getTableCellProps: () => ({}),
  },
  {
    field: 'visitingChecklistDateComplete',
    title: 'Дата завершения чек-листа',
    renderComponent: (value) => (
      <Typography>
        {value.visitingChecklistDateComplete
          ? dayjs(value.visitingChecklistDateComplete).format(
              'DD.MM.YYYY HH:mm'
            )
          : 'Не завершено'}
      </Typography>
    ),
    order: 4,
    isShow: true,
    getTableCellProps: () => ({ align: 'center' }),
  },
  {
    field: 'visitor',
    title: 'Инициатор',
    renderComponent: (value) => (
      <Typography>
        {value.visitor.employeeName} {value.visitor.employeePosition}
      </Typography>
    ),
    order: 5,
    isShow: true,
    getTableCellProps: () => ({}),
  },
  {
    field: 'retailTitle',
    title: 'Название аптеки',
    renderComponent: (value) => <Typography>{value.retailTitle}</Typography>,
    order: 6,
    isShow: true,
    getTableCellProps: () => ({}),
  },
  {
    field: 'retailManager',
    title: 'ЗА аптеки',
    renderComponent: (value) => <Typography>{value.retailManager}</Typography>,
    order: 7,
    isShow: false,
    getTableCellProps: () => ({}),
  },
  {
    field: 'retailAddress',
    title: 'Адрес аптеки',
    renderComponent: (value) => <Typography>{value.retailAddress}</Typography>,
    order: 8,
    isShow: false,
    getTableCellProps: () => ({}),
  },
  {
    field: 'phone',
    title: 'Номер телефона',
    renderComponent: (value) => <Typography>{value.phone}</Typography>,
    order: 9,
    isShow: false,
    getTableCellProps: () => ({}),
  },
  {
    field: 'canEdit',
    title: 'Возможность редактировать',
    renderComponent: (value) => (
      <Typography>{value.canEdit ? 'Да' : 'Нет'}</Typography>
    ),
    order: 10,
    isShow: false,
    getTableCellProps: () => ({}),
  },
  {
    field: 'canDelete',
    title: 'Возможность удалить',
    renderComponent: (value) => (
      <Typography>{value.canDelete ? 'Да' : 'Нет'}</Typography>
    ),
    order: 11,
    isShow: false,
    getTableCellProps: () => ({}),
  },
  {
    field: 'members',
    title: 'Участники посещения',
    isShow: true,
    order: 12,
    getTableCellProps: () => ({}),
    renderComponent: (value) => {
      if (!value.members || value.members.length === 0)
        return <Typography>Нет</Typography>;
      else
        return (
          <Stack>
            {value.members.map((member) => (
              <Typography key={member.id}>
                {member.employeeName} {member.employeePosition}
              </Typography>
            ))}
          </Stack>
        );
    },
  },
];
