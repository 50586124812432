import { AdaptationTemplateTaskFormFields } from './type';

export const INIT_VALUE: Partial<AdaptationTemplateTaskFormFields> = {
  title: '',
  isReminderObserver: false,
  isReminderCoExecutor: false,
  isReminderCreator: false,
  isReminderExecutor: false,
  coExecutorsPositions: [],
  observersPositions: [],
  addingFiles: [],
  creatorPosition: null,
  deletingFiles: [],
  files: [],
  description: '',
  reminderPeriodCoExecutor: '',
  reminderPeriodCreator: '',
  reminderPeriodExecutor: '',
  reminderPeriodObserver: '',
  finishDaysCount: '',
  startDaysCount: '',
  executorPosition: null,
};
