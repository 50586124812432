// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';

import { useGetFiltersVisiting } from 'entities/visit/visit/hooks/useGetFiltersVisiting';
import { useGetFiltersVisitor } from 'entities/visit/visit/hooks/useGetFiltersVisitor';

import { VisitFilterFormFields } from '../type';

export const useVisitFilterFormOptions = (
  control: Control<VisitFilterFormFields, any>
) => {
  /* Options */
  const { filters: visitingFilters, isLoading: isLoadingVisitingFilters } =
    useGetFiltersVisiting();
  const { filters: visitorFilters, isLoading: isLoadingVisitorFilters } =
    useGetFiltersVisitor();
  /* ===== */

  /* Watch */
  const selectedVisitingRegions = useWatch({ control, name: 'regionsIds' });
  const selectedVisitingDepartments = useWatch({
    control,
    name: 'departmentsIds',
  });
  const selectedVisitingDivisions = useWatch({ control, name: 'divisionsIds' });

  const selectedVisitorsRegions = useWatch({
    control,
    name: 'visitorRegionsIds',
  });
  const selectedVisitorsDepartments = useWatch({
    control,
    name: 'visitorDepartmentsIds',
  });
  const selectedVisitorOffices = useWatch({
    control,
    name: 'visitorOfficesIds',
  });
  const selectedVisitorPositions = useWatch({
    control,
    name: 'visitorPositionsIds',
  });
  /* ===== */

  /* options */
  /* Visiting */
  const visitingRegionsOptions = useMemo(() => {
    if (!visitingFilters) return [];

    return visitingFilters.visitingRegions || [];
  }, [visitingFilters]);

  const visitingDepartmentsOptions = useMemo(() => {
    if (!visitingFilters) return [];

    return (
      visitingFilters.visitingDepartments?.filter((el) =>
        selectedVisitingRegions.length !== 0
          ? selectedVisitingRegions.some(
              (el2) => el2 === el.parentVisitingRegion
            )
          : true
      ) || []
    );
  }, [selectedVisitingRegions, visitingFilters]);

  const visitingDivisionsOptions = useMemo(() => {
    if (!visitingFilters) return [];

    return (
      visitingFilters.visitingDivisions?.filter((el) => {
        return selectedVisitingDepartments.length === 0 &&
          selectedVisitingRegions.length === 0
          ? // Если фильтры пустые, возвращаем всё
            true
          : // Если departments фильтр не пустой
          selectedVisitingDepartments.length !== 0
          ? selectedVisitingDepartments.some(
              (el2) => el2 === el.parentVisitingDepartment
            )
          : true &&
            // Если regions фильтр не пустой
            selectedVisitingRegions.length !== 0
          ? selectedVisitingRegions.some(
              (el2) => el2 === el.parentVisitingRegion
            )
          : true;
      }) || []
    );
  }, [visitingFilters, selectedVisitingRegions, selectedVisitingDepartments]);

  const visitingRetailsOptions = useMemo(() => {
    if (!visitingFilters) return [];

    return (
      visitingFilters.visitingRetails?.filter((el) => {
        return selectedVisitingDivisions.length === 0 &&
          selectedVisitingDepartments.length === 0 &&
          selectedVisitingRegions.length === 0
          ? true
          : selectedVisitingDivisions.length !== 0
          ? selectedVisitingDivisions.some(
              (el2) => el2 === el.parentVisitingDivision
            )
          : true && selectedVisitingDepartments.length !== 0
          ? selectedVisitingDepartments.some(
              (el2) => el2 === el.parentVisitingDepartment
            )
          : true && selectedVisitingRegions.length !== 0
          ? selectedVisitingRegions.some(
              (el2) => el2 === el.parentVisitingRegion
            )
          : true;
      }) || []
    );
  }, [
    visitingFilters,
    selectedVisitingDivisions,
    selectedVisitingDepartments,
    selectedVisitingRegions,
  ]);

  /* Visitor */
  const visitorRegionsOptions = useMemo(() => {
    return visitorFilters?.visitorRegions || [];
  }, [visitorFilters]);

  const visitorDepartmentsOptions = useMemo(() => {
    return (
      visitorFilters?.visitorDepartments?.filter((el) =>
        selectedVisitorsRegions.length !== 0
          ? selectedVisitorsRegions.some(
              (el2) => el.parentVisitorRegion?.some((el3) => el3 === el2)
            )
          : true
      ) || []
    );
  }, [visitorFilters, selectedVisitorsRegions]);

  const visitorOfficesOptions = useMemo(
    () =>
      visitorFilters?.visitorOffices?.filter((visitorOffice) => {
        let s1 = true;
        let s2 = true;

        if (selectedVisitorsDepartments.length !== 0) {
          s1 = selectedVisitorsDepartments.some(
            (visitorDepartment) =>
              visitorOffice.parentVisitorDepartment?.some(
                (parentVisitorDepartmentId) =>
                  visitorDepartment === parentVisitorDepartmentId
              )
          );
        }

        if (selectedVisitorsRegions.length !== 0) {
          s2 = selectedVisitorsRegions.some(
            (visitorRegion) =>
              visitorOffice.parentVisitorRegion?.some(
                (parentVisitorRegionId) =>
                  visitorRegion === parentVisitorRegionId
              )
          );
        }

        return s1 && s2;
      }) || [],
    [visitorFilters, selectedVisitorsDepartments, selectedVisitorsRegions]
  );

  const visitorPositionsOptions = useMemo(
    () =>
      visitorFilters?.visitorPositions?.filter((visitorPosition) => {
        let s1 = true;
        let s2 = true;
        let s3 = true;

        if (selectedVisitorOffices.length !== 0) {
          s1 = selectedVisitorOffices.some(
            (visitorOffice) =>
              visitorPosition.parentVisitorOffice?.some(
                (parentVisitorOfficeId) =>
                  visitorOffice === parentVisitorOfficeId
              )
          );
        }

        if (selectedVisitorsDepartments.length !== 0) {
          s2 = selectedVisitorsDepartments.some(
            (visitorDepartment) =>
              visitorPosition.parentVisitorDepartment?.some(
                (parentVisitorDepartmentId) =>
                  visitorDepartment === parentVisitorDepartmentId
              )
          );
        }

        if (selectedVisitorsRegions.length !== 0) {
          s3 = selectedVisitorsRegions.some(
            (visitorRegion) =>
              visitorPosition.parentVisitorRegion?.some(
                (parentVisitorRegionId) =>
                  visitorRegion === parentVisitorRegionId
              )
          );
        }

        return s1 && s2 && s3;
      }) || [],
    [
      visitorFilters,
      selectedVisitorOffices,
      selectedVisitorsDepartments,
      selectedVisitorsRegions,
    ]
  );

  const visitorsOptions = useMemo(() => {
    return (
      visitorFilters?.visitors?.filter((visitor) => {
        let s1 = true;
        let s2 = true;
        let s3 = true;
        let s4 = true;

        if (selectedVisitorOffices.length !== 0) {
          s1 = selectedVisitorOffices.some(
            (visitorOffice) =>
              visitor.parentVisitorOffice?.some(
                (parentVisitorOfficeId) =>
                  visitorOffice === parentVisitorOfficeId
              )
          );
        }

        if (selectedVisitorsDepartments.length !== 0) {
          s2 = selectedVisitorsDepartments.some(
            (visitorDepartment) =>
              visitor.parentVisitorDepartment?.some(
                (parentVisitorDepartmentId) =>
                  visitorDepartment === parentVisitorDepartmentId
              )
          );
        }

        if (selectedVisitorsRegions.length !== 0) {
          s3 = selectedVisitorsRegions.some(
            (visitorRegion) =>
              visitor.parentVisitorRegion?.some(
                (parentVisitorRegionId) =>
                  visitorRegion === parentVisitorRegionId
              )
          );
        }

        if (selectedVisitorPositions.length !== 0) {
          s4 = selectedVisitorPositions.some(
            (visitorPosition) =>
              visitor.parentVisitorPosition?.some(
                (parentVisitorPositionId) =>
                  visitorPosition === parentVisitorPositionId
              )
          );
        }

        return s1 && s2 && s3 && s4;
      }) || []
    );
  }, [
    visitorFilters,
    selectedVisitorOffices,
    selectedVisitorsDepartments,
    selectedVisitorsRegions,
    selectedVisitorPositions,
  ]);
  /* ===== */

  return {
    visitingRegionsOptions,
    visitingDepartmentsOptions,
    visitingDivisionsOptions,
    visitingRetailsOptions,
    visitorRegionsOptions,
    visitorDepartmentsOptions,
    visitorOfficesOptions,
    visitorPositionsOptions,
    visitorsOptions,
    isLoadingVisitingFilters,
    isLoadingVisitorFilters,
  };
};
