import { MutableBoardColumnHeader } from 'features/boards/MutableBoardColumnHeader';
import { FC } from 'react';
import { useBoardColumnHeaderForBoardPage } from './hook';
import { BoardColumnHeaderForBoardPageProps } from './type';

export const BoardColumnHeaderForBoardPage: FC<
  BoardColumnHeaderForBoardPageProps
> = ({ column, actions, cardProps, canEdit }) => {
  const { handleUpdate, isLoading } = useBoardColumnHeaderForBoardPage(column);

  return (
    <MutableBoardColumnHeader
      column={column}
      onUpdateSubmit={handleUpdate}
      actions={actions}
      cardProps={cardProps}
      isLoading={isLoading}
      canEdit={canEdit}
    />
  );
};
