import { Grid } from '@mui/material';
import { useBoardDragDrop } from 'pages/boards/context/BoardDragDropContext';
import { useBoardPage } from 'pages/boards/context/BoardPageContext';
import { FC } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { theme } from 'shared/theme/theme';
import { BoardColumn } from '../BoardColumn';
import { CreateBoardColumnButton } from '../CreateBoardColumnButton';
import { ColumnListProps } from './type';

export const ColumnList: FC<ColumnListProps> = ({ columns }) => {
  const { board } = useBoardPage();
  const { dropHandler, onDragStart, unSelectAll } = useBoardDragDrop();

  return (
    <Grid
      onClick={(e) => {
        if (e.defaultPrevented) {
          return;
        }
        unSelectAll();
      }}
      display={'flex'}
      flexDirection={'row'}
      gap={1}
      flexWrap={'nowrap'}
      sx={{
        overflowX: 'auto',
        height: 'calc(100vh - 250px)',
        ...theme.mixins.customScrollBar('light'),
      }}
    >
      <DragDropContext
        onDragStart={onDragStart}
        onDragEnd={dropHandler}
      >
        <Droppable
          droppableId='board'
          type='COLUMN'
          direction='horizontal'
        >
          {(provided) => (
            <Grid
              display={'flex'}
              flexDirection={'row'}
              flexWrap={'nowrap'}
              flexShrink={0}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {columns.map((column) => (
                <BoardColumn
                  column={column}
                  key={column.guid}
                />
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

      {board?.canEdit && <CreateBoardColumnButton />}
    </Grid>
  );
};
