// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import { Fragment, useCallback, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

import { DocumentAgreeProcessActionResponse } from 'shared/api/services-document-flow/types';
import { theme } from 'shared/theme/theme';

import { TEXT_FIELD_SX } from './constants';
import {
  FormAgreeСhainComponent,
  getOptionLabelFn,
  getOptionsDisabledFn,
  renderOptionFn,
} from './type';

export const FormAgreeСhain: FormAgreeСhainComponent = ({
  initValue,
  onClose,
  backendValue,
  setDocumentAgreeChain,
  options,
}) => {
  const [form, setForm] = useState(initValue);
  const [ref1, setRef1] = useState<null | HTMLElement>(null);
  const [ref, setRef] = useState<null | HTMLElement>(null);
  const [isDragg, setIsDragg] = useState(false);
  const indexFirst = form.findIndex((item) =>
    backendValue.some((back) => back.employeeId === item.employeeId)
  );
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  /* const optionsPrepForTest = options?.map((item) => {
    return { ...item, isVacation: item.id === 8374 ? true : item.isVacation };
  }); */

  /* settings autocomplite */
  const getOptionDisabled: getOptionsDisabledFn = (option) =>
    form.some((item) => item.employeeId === option.id || option.isVacation);

  const getOptionLabel: getOptionLabelFn = useCallback((option) => {
    if (option.isVacation)
      return `${option.employeeName}, ${option.employeePosition} (В отпуске!)`;

    return `${option.employeeName}, ${option.employeePosition}`;
  }, []);

  const renderOption: renderOptionFn = useCallback((props, option) => {
    return (
      <li
        {...props}
        key={option.id}
      >
        <Typography>
          {`${option.employeeName}, ${option.employeePosition} ${
            option.isVacation ? ' (В отпуске)' : ''
          } `}
        </Typography>
      </li>
    );
  }, []);

  return (
    <Grid
      component={'form'}
      onContextMenu={handleContextMenu}
      onClick={(e: React.MouseEvent<HTMLFormElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      container
      flexDirection={'column'}
      padding={2}
      alignItems={'center'}
      justifyContent={'space-between'}
      height={'100%'}
      minHeight={['100vh', '60vh']}
      sx={{ position: 'relative', paddingBottom: [7, 0] }}
    >
      <Typography
        textAlign={'center'}
        fontWeight='bold'
        marginBottom={2}
      >
        Цепочка согласования
      </Typography>
      {!isDragg && (
        <Grid>
          <Autocomplete
            size={'small'}
            value={null}
            onChange={(e, value) => {
              ref1 && ref1?.blur();
              if (value) {
                setForm((state) => {
                  const findIndex = 1;
                  const newArr: DocumentAgreeProcessActionResponse[] = [
                    {
                      employeeId: value.id,
                      employeeName: value.employeeName,
                      employeePosition: value.employeePosition,
                      orderNumber: findIndex,
                      taskId: 2,
                      isProcessAgreeChain: false,
                    } as DocumentAgreeProcessActionResponse,
                    ...state.map((item, index) => ({
                      ...item,
                      orderNumber: index + findIndex + 1,
                    })),
                  ];

                  return newArr;
                });
              }

              return null;
            }}
            renderInput={(props) => (
              <TextField
                sx={TEXT_FIELD_SX}
                {...props}
                inputRef={setRef1}
                value={''}
              />
            )}
            selectOnFocus={false}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            renderOption={renderOption}
            options={options || []}
          />
          {form
            .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
            .map((item, index) => {
              const someItemForBackend = backendValue.some(
                (some) => some.employeeId === item.employeeId
              );
              const currentValue = options?.find(
                (find) => find.id === item.employeeId
              );

              return (
                <Fragment key={`new_${index}`}>
                  {someItemForBackend && index !== indexFirst && (
                    <Autocomplete
                      size={'small'}
                      value={null}
                      onChange={(e, value) => {
                        if (value) {
                          setForm((state) => {
                            const arr = state.sort((a, b) =>
                              a.orderNumber > b.orderNumber ? 1 : -1
                            );
                            const findIndex = arr.findIndex(
                              (findIndex) =>
                                item.employeeId === findIndex.employeeId
                            );
                            const leftArr = state.slice(0, findIndex);
                            const rightArr = state.slice(
                              findIndex,
                              state.length
                            );
                            const newArr: DocumentAgreeProcessActionResponse[] =
                              [
                                ...leftArr,
                                {
                                  employeeId: value.id,
                                  employeeName: value.employeeName,
                                  employeePosition: value.employeePosition,
                                  orderNumber: rightArr[0].orderNumber,
                                  taskId: 2,
                                  isProcessAgreeChain: false,
                                } as DocumentAgreeProcessActionResponse,
                                ...rightArr.map((item) => ({
                                  ...item,
                                  orderNumber: item.orderNumber + 1,
                                })),
                              ];

                            return newArr;
                          });
                        }
                      }}
                      renderInput={(props) => (
                        <TextField
                          sx={TEXT_FIELD_SX}
                          {...props}
                        />
                      )}
                      getOptionDisabled={getOptionDisabled}
                      getOptionLabel={getOptionLabel}
                      renderOption={renderOption}
                      options={options || []}
                    />
                  )}
                  <Autocomplete
                    key={item.orderNumber}
                    disabled={someItemForBackend}
                    value={currentValue || null}
                    size={'small'}
                    onChange={(e, value, reason) => {
                      if (reason === 'selectOption' && value) {
                        setForm((state) => {
                          return state
                            .sort((a, b) =>
                              a.orderNumber > b.orderNumber ? 1 : -1
                            )
                            .map((item) => {
                              if (item.employeeId === currentValue?.id) {
                                return {
                                  orderNumber: item.orderNumber,
                                  employeeId: value.id,
                                  employeeName: value.employeeName,
                                  employeePosition: value.employeePosition,
                                  taskId: 2,
                                  isProcessAgreeChain: false,
                                } as DocumentAgreeProcessActionResponse;
                              }

                              return item;
                            });
                        });
                      }
                      if (reason === 'clear') {
                        let firstOrder = 0;

                        setForm((state) => {
                          return state
                            .sort((a, b) =>
                              a.orderNumber > b.orderNumber ? 1 : -1
                            )
                            .filter(
                              (item) => item.employeeId !== currentValue?.id
                            )
                            .map((item, index) => {
                              if (index === 0) {
                                firstOrder = item.orderNumber;

                                return item;
                              } else {
                                return {
                                  ...item,
                                  orderNumber: firstOrder + index,
                                };
                              }
                            });
                        });
                      }
                    }}
                    renderInput={(props) => (
                      <TextField
                        sx={TEXT_FIELD_SX}
                        {...props}
                      />
                    )}
                    getOptionDisabled={getOptionDisabled}
                    getOptionLabel={getOptionLabel}
                    renderOption={renderOption}
                    options={options || []}
                  />
                </Fragment>
              );
            })}
          {form.length > 0 && (
            <Autocomplete
              size={'small'}
              value={null}
              onChange={(e, value) => {
                ref && ref?.blur();
                if (value) {
                  setForm((state) => {
                    const findIndex = state.length;
                    const newArr: DocumentAgreeProcessActionResponse[] = [
                      ...state,
                      {
                        employeeId: value.id,
                        employeeName: value.employeeName,
                        employeePosition: value.employeePosition,
                        orderNumber: state[findIndex - 1].orderNumber + 1,
                        taskId: 2,
                        isProcessAgreeChain: false,
                      } as DocumentAgreeProcessActionResponse,
                    ];

                    return newArr;
                  });
                }

                return null;
              }}
              renderInput={(props) => (
                <TextField
                  sx={TEXT_FIELD_SX}
                  {...props}
                  inputRef={setRef}
                  value={''}
                />
              )}
              selectOnFocus={false}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={getOptionDisabled}
              renderOption={renderOption}
              options={options || []}
            />
          )}
        </Grid>
      )}

      {isDragg && (
        <Grid>
          <DragDropContext
            onDragEnd={(response) => {
              if (response && response.destination) {
                setForm((state) => {
                  const sortState = state.sort((a, b) =>
                    a.orderNumber > b.orderNumber ? 1 : -1
                  );

                  return reorder(
                    sortState,
                    response.source.index,
                    response.destination!.index
                  ).map((item, index) => ({
                    ...item,
                    orderNumber: index,
                  }));
                });
              }
            }}
          >
            <Droppable droppableId='agreeDrag'>
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {form
                    .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
                    .map((item, index) => {
                      const isDragDisabled = backendValue.some(
                        (back) => back.employeeId === item.employeeId
                      );

                      return (
                        <Draggable
                          key={item.employeeId}
                          isDragDisabled={isDragDisabled}
                          draggableId={String(item.employeeId)}
                          index={index}
                        >
                          {(provided) => (
                            <Typography
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={
                                getItemStyle(
                                  !isDragDisabled,
                                  provided.draggableProps.style
                                ) as React.CSSProperties | undefined
                              }
                            >{`${item.employeeName} ${item.employeePosition}`}</Typography>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      )}
      <Grid></Grid>
      <Grid
        container
        sx={{
          marginTop: [2],
          position: ['fixed', 'relative'],
          bottom: [0, 16],
        }}
        justifyContent={'space-between'}
      >
        <Button
          sx={{ flexBasis: ['50%', '48%'], borderRadius: [0, 1] }}
          variant={'contained'}
          color={'secondary'}
          size={'large'}
          type='submit'
          onClick={(e) => {
            e.preventDefault();
            setDocumentAgreeChain(form);
            onClose();
          }}
        >
          Сохранить
        </Button>
        <Button
          sx={{ flexBasis: ['50%', '48%'], borderRadius: [0, 1] }}
          variant={'contained'}
          color={'customGrey'}
          size={'large'}
          onClick={onClose}
        >
          Закрыть
        </Button>
      </Grid>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setIsDragg((state) => !state);
          }}
        >
          {isDragg ? 'Добавлени' : 'DnD'}
        </MenuItem>
      </Menu>
    </Grid>
  );
};

// a little function to help us with reordering the result
export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  // some basic styles to make the items look a bit nicer

  userSelect: 'none',
  cursor: isDragging ? 'grab' : 'not-allowed',
  marginBottom: '8px',
  /* padding: grid * 2,
    margin: `0 0 ${grid}px 0`, */

  // change background colour if dragging
  background: isDragging ? 'white' : theme.palette.customGrey.main,

  // styles we need to apply on draggables
  ...draggableStyle,
});
