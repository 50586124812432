import { useTaskURLState } from 'features/task/hooks/useTaskURLState';
import { countNotUndef } from 'pages/tasks/libs/countNotUndef';
import { orderToTaskFilter } from 'pages/tasks/libs/orderConvFun';
import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useGetTaskListMeExecutorQuery } from 'shared/api/services/rtk/taskApi';

import {
  GetListTask,
  GetTasksMeExecutorQueryParams,
} from 'shared/api/services/types';
import { OrderFilter } from 'shared/ui/table/types/order-props';
import { convertURLToTaskList } from 'shared/url-helper/conventors/convertURLToTaskList';

export interface MyTaskContextValue {
  tasks: GetListTask[];
  count: number;
  filter: GetTasksMeExecutorQueryParams | undefined;
  setFilter: (filter: GetTasksMeExecutorQueryParams | undefined) => void;
  changeLimit: (limit: number) => void;
  changePage: (page: number) => void;
  changeSearch: (search: string) => void;
  changeOrder: (order: OrderFilter<GetListTask>) => void;
  isLoading: boolean;
  isFetching: boolean;
}

export const MY_TASK_CONTEXT_INIT_VALUE: MyTaskContextValue = {
  tasks: [],
  count: 0,
  filter: undefined,
  setFilter: () => {
    //
  },
  changeLimit: () => {
    //
  },
  changePage: () => {
    //
  },
  changeOrder: () => {
    //
  },
  changeSearch: () => {
    //
  },
  isLoading: false,
  isFetching: false,
};

export const MyTaskContext = createContext<MyTaskContextValue>(
  MY_TASK_CONTEXT_INIT_VALUE
);
export const useMyTasks = () => useContext(MyTaskContext);

export const MyTaskProvider: FC<PropsWithChildren> = ({ children }) => {
  const [filter, setFilter] =
    useTaskURLState<GetTasksMeExecutorQueryParams>(convertURLToTaskList);
  const { data, isFetching, isLoading, error } = useGetTaskListMeExecutorQuery(
    filter,
    { skip: countNotUndef(filter) < 3 }
  );

  useShowFetchErrorInSnackbar(error);

  const changeLimit = (limit: number) => {
    setFilter({ ...filter, Limit: limit });
  };

  const changePage = (page: number) => {
    setFilter({ ...filter, Page: page });
  };

  const changeOrder = (order: OrderFilter<GetListTask>) => {
    setFilter({ ...filter, ...orderToTaskFilter(order) });
  };

  const changeSearch = (search: string) => {
    setFilter({ ...filter, SearchText: search });
  };

  const updateFilter = (filter: GetTasksMeExecutorQueryParams | undefined) => {
    if (!filter) setFilter({ Page: 1, Limit: 10 });
    else setFilter({ ...filter, Page: 1 });
  };

  return (
    <MyTaskContext.Provider
      value={{
        filter,
        setFilter: updateFilter,
        tasks: data?.items || [],
        count: data?.count || 0,
        changeLimit,
        changePage,
        changeOrder,
        changeSearch,
        isLoading,
        isFetching,
      }}
    >
      {children}
    </MyTaskContext.Provider>
  );
};
