import { Typography } from '@mui/material';
import { FC } from 'react';

export const DocumentOperationTypeUi: FC<{ operation?: string }> = ({
  operation,
}) => {
  return (
    <Typography sx={{ minWidth: ['auto', '130px'] }}>
      {operation ? operation : '-'}
    </Typography>
  );
};
