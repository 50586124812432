import {
  preparedProtocolResponseToViewDataTableFn,
  ProtocolOpeningRetailsItem,
} from '../types';

export const preparedResponseProtocolsToTableData: preparedProtocolResponseToViewDataTableFn =
  (response) => {
    return response.reduce<ProtocolOpeningRetailsItem[]>(
      (accum, { protocol, openingProcesses, status }) => {
        const newObj: ProtocolOpeningRetailsItem = {
          ...protocol,
          protocolStatus: status,
          openingProcesses: openingProcesses
            ? openingProcesses.map(({ guid, openingTemplate }) => ({
                processGuid: guid,
                protocolGuid: protocol.guid,
                ...openingTemplate,
              }))
            : [],
        };

        accum.push(newObj);

        return accum;
      },
      []
    );
  };
