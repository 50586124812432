import {
  VisitingChecklistItemStageThreeRequest,
  VisitingChecklistStageThreeRequest,
} from 'shared/api/visit/types';

import { EvaluationPaperFormI } from '../type';

type PrepareDataToFetchStage4Type = (
  data: EvaluationPaperFormI
) => VisitingChecklistStageThreeRequest;

export const prepareDataToFetchStage4: PrepareDataToFetchStage4Type = (
  data
) => {
  const all: VisitingChecklistItemStageThreeRequest[] = [];

  data.blocks.forEach((el) => {
    el.items?.forEach((el2) => {
      all.push({
        itemId: el2.itemId,
        value: el2.value,
        comment: el2.comment || undefined,
      });
    });
  });

  return {
    items: all,
  };
};
