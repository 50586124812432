import { useState } from 'react';

import { postPlannings } from 'shared/api/visit/services';
import {
  VisitingPlanningCreateRequest,
  VisitingPlanningGetListResponse,
} from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useCreatePlanningRN = (
  afterCreate?: (planningItem: VisitingPlanningGetListResponse) => void
) => {
  const { open: openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const createPlanning = async (fetchData: VisitingPlanningCreateRequest) => {
    setIsLoading(true);
    try {
      const { data, status } = await postPlannings(fetchData);

      if (status !== 200 || !data) throw new Error('Упс, что-то пошло не так');

      afterCreate && afterCreate(data);
      setIsLoading(false);

      return data;
    } catch (e) {
      console.error('useCreatePlanningRN.createPlanning >> ', e);

      const error = e as { message: string };

      openSnackbar({
        duration: 6000,
        type: 'error',
        text: error.message,
      });

      setIsLoading(false);

      return Promise.reject(e);
    }
  };

  return { isLoading, createPlanning };
};
