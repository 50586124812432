import { Grid, Skeleton } from '@mui/material';

export const ProblemsFormSkeleton = () => {
  return (
    <Grid
      container
      flexDirection={'column'}
      gap={3}
    >
      <Skeleton
        variant='rounded'
        height={35}
      />

      <Grid
        container
        justifyContent={'space-between'}
      >
        <Skeleton
          variant='rounded'
          height={24}
          width={100}
        />
        <Skeleton
          variant='rounded'
          height={24}
          width={80}
        />
      </Grid>

      <Grid
        container
        gap={1}
        flexDirection={'column'}
      >
        <Skeleton
          variant='rounded'
          height={40}
        />

        <Skeleton
          variant='rounded'
          width={120}
          height={30}
        />

        <Skeleton
          variant='rounded'
          height={40}
        />
        <Skeleton
          variant='rounded'
          height={40}
        />
        <Skeleton
          variant='rounded'
          height={40}
        />
      </Grid>

      <Skeleton
        variant='rounded'
        width={200}
        height={36}
      />
    </Grid>
  );
};
