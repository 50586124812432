import { Logout } from '@mui/icons-material';
import {
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material';
import { useStore } from 'effector-react';

import { $userData, fetchUserDataFx } from 'entities/user/is-auth-user';
import { useState } from 'react';

import { ProfileSvg } from 'shared/svg/ProfileSvg';
import { theme } from 'shared/theme/theme';

import { useLogOutUser } from 'widgets/main-layout-app/ui/header/libs/LogOutButton';

export const UserDataForHeader = () => {
  const userData = useStore($userData);
  const isLoadingUserData = useStore(fetchUserDataFx.pending);

  const userFullName = userData && `${userData.lastName} ${userData.firstName}`;
  const onLogOut = useLogOutUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      item
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {isLoadingUserData ? (
        <Skeleton
          variant='rectangular'
          height={'1.6em'}
          width={'12em'}
          sx={{
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
        />
      ) : (
        <Typography
          fontWeight={700}
          color={theme.palette.primary.main}
          fontSize={[14, 18]}
          sx={{
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
        >
          {userFullName}
        </Typography>
      )}
      <Divider
        orientation='vertical'
        flexItem
        sx={{
          border: 'none',
          width: ['1px', 3],
          backgroundColor: theme.palette.primary.main,
          margin: '0px 10px',
          position: ['relative', 'inherit'],
          top: ['50%', 0],
          transform: ['translate(0,-50%)', 'none'],
          height: [16, 'auto'],
          display: {
            xs: 'none',
            lg: 'block',
          },
        }}
      />
      {isLoadingUserData ? (
        <Skeleton
          variant='rectangular'
          height={'1.6em'}
          width={'6em'}
          sx={{
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
        />
      ) : (
        <Typography
          fontSize={18}
          sx={{
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
          color={theme.palette.primary.main}
        >
          {userData && userData.positionShortTitle}
        </Typography>
      )}
      {/* mobile */}

      <IconButton
        id='user-button'
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          display: {
            xs: 'block',
            lg: 'none',
          },
        }}
      >
        <ProfileSvg />
      </IconButton>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-button',
        }}
      >
        <MenuItem>
          <ListItemText
            primaryTypographyProps={{
              color: 'primary',
              fontWeight: 'bold',
            }}
            secondaryTypographyProps={{
              color: 'primary',
            }}
            primary={userFullName}
            secondary={userData ? userData.positionShortTitle : undefined}
          ></ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            onLogOut();
            handleClose();
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText
              primary='Выход'
              primaryTypographyProps={{
                color: theme.palette.customGrey.dark,
                fontWeight: 'bold',
              }}
            />
          </ListItemButton>
        </MenuItem>
      </Menu>
    </Grid>
  );
};
