import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';

import { documentFormModel } from 'entities/document-form';

import { $retailsOptions, fetchRetailFx } from './model';
import { UiRetailAutocomplite } from './ui/UiRetailAutocomplite';

export const RetailAutocomplite: FC<{
  //indexExpenses: number;
  idArticle: number;
  required?: boolean;
}> = ({ idArticle, required }) => {
  const retailsOptions = useStore($retailsOptions);
  const value = documentFormModel.useSelectArticleIdFormModel(
    idArticle,
    'retailId'
  );
  const loadingRetailsOptions = useStore(fetchRetailFx.pending);

  useEffect(() => {
    if (retailsOptions.length === 0 && !loadingRetailsOptions) {
      fetchRetailFx();
    }
  }, [retailsOptions, loadingRetailsOptions]);

  return (
    <UiRetailAutocomplite
      onChange={(e, value) =>
        documentFormModel.formEvent.setRetailIdArticleId({
          id: idArticle,
          retailId: value?.id,
        })
      }
      required={required}
      options={retailsOptions}
      value={retailsOptions.find((item) => item.id === value) || null}
    />
  );
};
