import qs from 'qs';
import { BASE_API_URL } from 'shared/api/constants';
import { getToken } from 'shared/utils/getToken';
import { Api, RequestParams } from './autogenerate/onlineStoreServiceApi';

export const createApi = new Api<typeof AxiosRequestConfigForSecurityWorker>({
  baseURL: BASE_API_URL,

  paramsSerializer: (param) => qs.stringify(param, { indices: false }),
  securityWorker(getT) {
    if (getT) {
      return getT();
    }
  },
});

const AxiosRequestConfigForSecurityWorker =
  async (): Promise<void | RequestParams> => {
    const accesToken = await getToken();
    if (accesToken) {
      return { headers: { Authorization: `Bearer ${accesToken}` } };
    }
  };

createApi.setSecurityData(AxiosRequestConfigForSecurityWorker);

export const firebaseApi = createApi;
