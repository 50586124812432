import { Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useConfirmDeleteRunningProcess } from 'features/retail-opeting-process/delete-running-process';
import { FC } from 'react';
import { theme } from 'shared/theme/theme';
import { DialogConfirmForm } from 'shared/ui/form';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';

export const ActionsProcessOpeningRetailsTable: FC<{
  openingProcessGuid: string;
}> = ({ openingProcessGuid }) => {
  const {
    onOpenDialogConfirmDeleteRunningProcess,
    onCancelDeleteRunningProcess,
    onConfirmDeleteRunningProcess,
    isLoadingDeleteRunningProcess,
    isOpenConfirmDialogDeleteRunningProcess,
  } = useConfirmDeleteRunningProcess(openingProcessGuid);

  return (
    <UiTableActions>
      <UiButtonAction
        text='Удалить'
        onClick={onOpenDialogConfirmDeleteRunningProcess}
        icon={<Delete />}
        color={theme.palette.error.main}
      />
      <DialogConfirmForm
        title='Удалить процесс'
        open={isOpenConfirmDialogDeleteRunningProcess}
        onClose={onCancelDeleteRunningProcess}
        isLoading={isLoadingDeleteRunningProcess}
        onConfirmation={onConfirmDeleteRunningProcess}
      >
        <Typography>{'Вы уверены что хотите удалить процесс ?'}</Typography>
      </DialogConfirmForm>
    </UiTableActions>
  );
};
