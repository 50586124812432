import { useStoreMap } from 'effector-react';
import { FC } from 'react';

import { CustomTextField } from 'shared/ui/custom-text-field';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormEdit } from './model';

export const DocumentFormEditListAdditionalyFieldsString: FC = () => {
  const arrayAdditionalFields = useStoreMap(
    documentFormEdit.form,
    (document) => {
      return document && document.stringAdditionallyFields;
    }
  );
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditAdditionallyFields'
  );

  if (!arrayAdditionalFields) return <></>;

  return (
    <>
      {arrayAdditionalFields.map(
        ({ processAdditionallyField: { title, isRequired, id } }) => {
          return (
            <DocumentFormAdditionalyFieldString
              key={id}
              required={isRequired}
              label={title}
              id={id}
              disabled={!isCanEdit}
            />
          );
        }
      )}
    </>
  );
};

const DocumentFormAdditionalyFieldString: FC<{
  id: number;
  required?: boolean;
  label?: string;
  disabled?: boolean;
}> = ({ id, required, label = '-', disabled }) => {
  const value = useStoreMap(documentFormEdit.form, (form) => {
    if (form && form.stringAdditionallyFields) {
      return (
        form.stringAdditionallyFields.find(
          (item) => item.processAdditionallyField.id === id
        )?.value || ''
      );
    } else {
      return '';
    }
  });
  const [isMobile] = useDevice();

  return (
    <CustomTextField
      key={id}
      propsTextField={{
        disabled,
        required: required,
        value,
        onChange: (e) => {
          documentFormEdit.api.setAdditionallyFieldString({
            id,
            value: e.target.value,
          });
        },
        placeholder: isMobile ? label : '',
      }}
      label={label}
      sx={{ flexBasis: ['100%', '45%'] }}
    />
  );
};
