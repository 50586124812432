import { useCallback } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useAddEmployeeToBanListMutation } from 'shared/api/service-adaptation/rtk/adaptationBanProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { BanEmployeForAdaptationFormValue } from './types';

/* HOOK FOR DIALOG */
export const useDialogBanEmployeeForAdaptationProcess = ({
  employeeGuid,
}: {
  employeeGuid: string;
}) => {
  const [toBanList, { isLoading: isLoadingToBanList, error }] =
    useAddEmployeeToBanListMutation();

  const {
    open: openModalToBanList,
    close: closeModalToBanList,
    isOpen: isOpenModalToBanList,
  } = useViewModal();

  useShowFetchErrorInSnackbar(error);

  const { open: openSnackbar } = useSnackbar();

  const handleSubminToBunForm = useCallback(
    async (form: BanEmployeForAdaptationFormValue) => {
      const response = await toBanList({ employeeGuid, comment: form.comment });

      if ('data' in response) {
        openSnackbar({
          title: 'Адаптация не требюуется',
          text: `Сотрудник ${response.data.employee?.name}, добавлен в список "Адаптация не требюуется"`,
          duration: 2000,
          type: 'success',
        });
      }
      closeModalToBanList();
    },
    [employeeGuid]
  );

  return {
    closeModalToBanList,
    openModalToBanList,
    isLoadingToBanList,
    isOpenModalToBanList,
    handleSubminToBunForm,
  };
};
