import { useGetCurrentProcessesQuery } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { renderActionsCurrentAdaptations } from 'widgets/adaptation/CurrentAdaptationProcessesTable/render-actions/renderActionsCurrentAdaptations';
import { AdaptationProcessesPage } from './AdaptationProcessesPage';

export const CurrentAdaptationProcessesPage = () => {
  return (
    <AdaptationProcessesPage
      useGetProcesses={useGetCurrentProcessesQuery}
      renderActions={renderActionsCurrentAdaptations}
    />
  );
};
