import { Dayjs } from 'dayjs';

export const generateDaysOfMonth = (selectedMonth: Dayjs) => {
  const date = selectedMonth.daysInMonth();
  const days: number[] = [];

  for (let i = 1; i <= date; i++) days.push(i);

  return days;
};
