import { Typography } from '@mui/material';
import { FilterItemType } from 'shared/api/services-document-flow/types';

export const renderOptionByObserveDocumentFilterInputs = <
  T extends { title: string; id: number; type: FilterItemType },
>(
  params: React.HTMLAttributes<HTMLLIElement>,
  { id, title, type }: T
) => {
  return (
    <Typography
      {...params}
      key={`${id}-${Number(type)}`}
    >
      {title}
    </Typography>
  );
};

type IsOptionEqualToValueByIdAndTypeFun = <
  T extends { id: number; type: FilterItemType },
>(
  val: T,
  eq: T
) => boolean;
export const isOptionEqualToValueByIdAndType: IsOptionEqualToValueByIdAndTypeFun =
  (val, eq) => {
    return eq.id === val.id && eq.type === val.type;
  };
