import { generatePath } from 'react-router-dom';
import { ALL_ROUTES } from 'shared/routes/constants/types';

export const DOCUMENT_LINKS = {
  createDocLink(id: number | string) {
    return generatePath('/' + ALL_ROUTES.documentFlowId, {
      id: id.toString(),
    });
  },
};
