import { Typography } from '@mui/material';
import {
  SliderForm,
  useCreatedBannerSlider,
} from 'features/apteka-legko/banners/sliders/form-slider';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

export const PageBannersSliderCreated = () => {
  const { isLoading, handleCreateBannerSlider } = useCreatedBannerSlider();

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h5'
          fontWeight={'bold'}
        >
          Добавить слайд
        </Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content disabledPaddingXs>
        <SliderForm
          isLoading={isLoading}
          onSubmit={handleCreateBannerSlider}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
