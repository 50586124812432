import { Alert, AlertTitle } from '@mui/material';
import { createEvent } from 'effector';
import { useStore } from 'effector-react';

import { documentFormModel } from 'entities/document-form';

const deleteError = createEvent();

documentFormModel.errorSecondValid.on(deleteError, () => null);

export const ExpensesFormAlertSecondValid = () => {
  const error = useStore(documentFormModel.errorSecondValid);

  if (!error) return null;

  return (
    <Alert
      severity='error'
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        transitionDuration: 2,
      }}
      onClose={() => deleteError()}
    >
      <AlertTitle>{error?.message}</AlertTitle>
      {error.additionally}
    </Alert>
  );
};
