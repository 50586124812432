import { ImageList, ImageListItem, useMediaQuery } from '@mui/material';

import { theme } from 'shared/theme/theme';
import { GZ_IN_FACE_IMAGES } from './constants';

const RESPONSIVE_PARAMS = {
  xs: {
    colsList: 2,
  },
  sm: {
    colsList: 3,
  },
  md: {
    colsList: 4,
  },
  lg: {
    colsList: 6,
  },
  xl: {
    colsList: 8,
  },
};

/* TODO : useCustomMediaQueryHook  -  потестить бы */
type useCustomMediaQueryHook = <T>(params: {
  xs: T;
  sm: T;
  md: T;
  lg: T;
  xl: T;
}) => T;

const useCustomMediaQuery: useCustomMediaQueryHook = (params) => {
  const isXsUp = useMediaQuery(theme.breakpoints.up('xs'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));

  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlDown = useMediaQuery(theme.breakpoints.down('xl'));

  const paramsUp =
    (isXlUp && params.xl) ||
    (isLgUp && params.lg) ||
    (isMdUp && params.md) ||
    (isSmUp && params.sm) ||
    ((isXsUp || true) && params.xs);

  const paramsDown =
    (isXsDown && params.xs) ||
    (isSmDown && params.sm) ||
    (isMdDown && params.md) ||
    (isLgDown && params.md) ||
    ((isXlDown || true) && params.xl);

  return paramsUp || paramsDown;
};

export const PhotoFaceList = () => {
  const params = useCustomMediaQuery(RESPONSIVE_PARAMS);

  return (
    <ImageList
      cols={params?.colsList}
      variant='quilted'
      rowHeight={219}
    >
      {GZ_IN_FACE_IMAGES.map((img) => (
        <ImageListItem
          key={img.src}
          sx={{
            '& > img': {
              borderRadius: '10px',
              overflow: 'hidden',
              objectPosition: 'top',
            },
          }}
        >
          <img {...img}></img>
        </ImageListItem>
      ))}
    </ImageList>
  );
};
