import { GetBoardsTasksBoardGuidQueryParams } from 'shared/api/services/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { getGuidsFromArray } from 'shared/tarnsform-helper/getGuidsFromArray';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { BoardTaskFilterFormFields } from '../type';

export const prepareBoardTaskFilterForFetch = (
  form: BoardTaskFilterFormFields
): Omit<GetBoardsTasksBoardGuidQueryParams, 'SearchText'> => {
  return {
    CoExecutorsGuids: getGuidsFromArray(form.coExecutors),
    CreatorsGuids: getGuidsFromArray(form.creators),
    ExecutorsGuids: getGuidsFromArray(form.executors),
    StartDate:
      form.startDate && form.startDate.isValid()
        ? form.startDate.format(DATE_FORMATS.server)
        : undefined,
    FinishDate:
      form.finishDate && form.finishDate.isValid()
        ? form.finishDate.format(DATE_FORMATS.server)
        : undefined,
    IsTarget: form.isTarget || undefined,
    OriginsIds: getIdsFromArray(form.origins),
    StatusIds: getIdsFromArray(form.statuses.filter((el) => el.selected)),
  };
};
