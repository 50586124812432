import { AttachFile } from '@mui/icons-material';
import { Fade, Grid, LinearProgress, Stack } from '@mui/material';
import { usePostTaskFiles } from 'entities/task/hooks/usePostTaskFiles';
import { downloadTaskFile } from 'entities/task/libs/downloadTaskFile';
import { useDeleteTaskFile } from 'features/task/hooks/useDeleteTaskFile';
import { FC, useMemo } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useDownloadFileFromServiceFile } from 'shared/api/service-files/rtk/hooks/use-download-file-from-service-file';
import {
  useGetExternalTaskFilesQuery,
  useGetTaskFilesQuery,
} from 'shared/api/services/rtk/fileApi';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { FileListItem } from 'shared/ui/file-list-item';
import { UploadButton } from 'shared/ui/upload-button/UploadButton';
import { TaskFilesTabProps } from './type';

export const TaskFilesTab: FC<TaskFilesTabProps> = ({
  canAddFile,
  taskGuid,
  canEdit,
}) => {
  const { open: openSnackbar } = useSnackbar();

  const {
    data: files,
    isFetching: isFetchingFiles,
    error: error1,
  } = useGetTaskFilesQuery(taskGuid);
  const {
    data: externalFiles,
    isFetching: isFetchingExternalFiles,
    error: error2,
  } = useGetExternalTaskFilesQuery(taskGuid);

  const isAnyFetching = useMemo(() => {
    return isFetchingFiles || isFetchingExternalFiles;
  }, [isFetchingFiles, isFetchingExternalFiles]);

  const { deleteFile, isDeletingFile } = useDeleteTaskFile(taskGuid);
  const { addFiles, isLoading: isUploadingFiles } = usePostTaskFiles(taskGuid);
  const [downloadFile, { error: error3 }] = useDownloadFileFromServiceFile();

  useShowFetchErrorInSnackbar(error1);
  useShowFetchErrorInSnackbar(error2);
  useShowFetchErrorInSnackbar(error3);

  return (
    <>
      <Grid
        container
        gap={1}
        position={'relative'}
      >
        <UploadButton
          variant='outlined'
          startIcon={<AttachFile />}
          disabled={!canAddFile}
          onChangeInput={addFiles}
          id='upload_task_files'
        >
          Прикрепить файл
        </UploadButton>

        <Fade in={isUploadingFiles}>
          <LinearProgress sx={{ width: '100%' }} />
        </Fade>

        <Stack
          gap={1}
          px={[1, 0]}
          pb={[1, 0]}
          width={'100%'}
          sx={{ boxSizing: 'border-box' }}
        >
          {files &&
            files.map((file) => (
              <FileListItem
                key={file.guid}
                disableDelete={isDeletingFile || isAnyFetching || !canEdit}
                fileName={file.fileName || '~~Без названия~~'}
                onDelete={() => {
                  !!file.guid && deleteFile(file.guid);
                }}
                onDownload={() => {
                  downloadTaskFile({
                    taskGuid,
                    fileGuid: file.guid,
                    fileName: file.fileName || 'x-file',
                  });

                  openSnackbar({
                    text: <strong>Файл скачивается</strong>,
                    type: 'info',
                    duration: 3000,
                  });
                }}
              />
            ))}

          {externalFiles &&
            externalFiles.map((file) => (
              <FileListItem
                key={file.guid}
                disableDelete={
                  isDeletingFile || isAnyFetching || !canEdit || !file.canDelete
                }
                fileName={file.fileTitle || '~~Без названия~~'}
                onDelete={() => {
                  !!file.guid && deleteFile(file.guid);
                }}
                onDownload={() => {
                  if (!file.filePath) return;
                  downloadFile({ path: file.filePath });

                  openSnackbar({
                    text: <strong>Файл скачивается</strong>,
                    type: 'info',
                    duration: 3000,
                  });
                }}
              />
            ))}
        </Stack>
      </Grid>
    </>
  );
};
