import { createTheme } from '@mui/material';
import { ruRU } from '@mui/material/locale';

import { createBreakpoints } from '@mui/system';
import { ALERT_ICON_MAP } from './alertIconMapping';

const GREY_COLO2 = '#686868';
const GREY_COLOR = '#D9D9D9';
const CONTRAST_TEXT_GREY_COLOR = '#686868';
const MAIN_COLOR = '#2B9793';
const MAIN_COLOR_TWO = '#116E6B';
const MAIN_CUSTOM_GREEN = '#9BD3CF';
const BLUE_COLOR = { main: '#2B7097', dark: '#256285', contrastText: 'white' };
const APTEKA_LEGKO_BLUE = {
  main: '#215378',
  dark: '#256285',
  light: '#256285',
  contrastText: 'white',
};
const ERROR = '#BF0000';
const LIGHT_ERROR = '#FFD3D3';

export const HEIGHT_APP_BAR_MOBILE = 55;
export const HEIGHT_APP_BAR_DESCTOP = 62;

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
});

export const theme = createTheme(
  {
    breakpoints,
    components: {
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            '&.subvariant-adept': {
              '& fieldset': {
                [breakpoints.down('sm')]: {
                  border: 'none',
                },
              },
              '& label': {
                [breakpoints.down('sm')]: {
                  display: 'none',
                },
              },
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '10px',

            '& input:valid + fieldset': {
              borderColor: GREY_COLO2,
            },
            '& input:valid:hover + fieldset': {
              borderColor: GREY_COLO2,
            },
            '& input:valid + div + fieldset': {
              borderColor: GREY_COLO2,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: GREY_COLO2,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: GREY_COLO2,
              borderWidth: '1px',
            },

            '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: ERROR,
            },
          },
          sizeSmall: {
            borderRadius: '10px',
          },
          notchedOutline: {
            borderWidth: '1px',
            borderColor: GREY_COLO2,
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: GREY_COLO2,
            },
          },
        },
      },

      MuiAlert: {
        defaultProps: {
          iconMapping: ALERT_ICON_MAP,
        },
      },
      MuiToggleButton: {
        variants: [
          {
            props: { color: 'customGrey' },
            style: {
              textTransform: 'none',
              textDecoration: 'none',
              height: '100%',
              fontSize: 12,
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: GREY_COLOR,
                color: CONTRAST_TEXT_GREY_COLOR,
                borderColor: GREY_COLOR,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 700,
          },
          sizeSmall: {
            borderRadius: '10px',
            textTransform: 'none',
          },
          sizeLarge: {
            borderRadius: '8px',
            textTransform: 'none',
            height: 40,
            [breakpoints.up('xs')]: {
              fontSize: 14,
              padding: '8px',
            },
            [breakpoints.up('lg')]: {
              fontSize: 18,
              padding: '8px 16px',
            },
          },
          containedCustomGrey: {
            '&:hover': {
              color: 'white',
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'action' },
            style: {
              textTransform: 'none',
              borderRadius: 0,
              textDecoration: 'none',
              height: '100%',
              fontSize: 12,
              '&.Mui-disabled': {
                backgroundColor: GREY_COLOR,
                color: GREY_COLO2,
              },
            },
          },
        ],

        styleOverrides: {
          root: {
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 700,
          },
          sizeSmall: {
            borderRadius: '10px',
            textTransform: 'none',
          },
          sizeLarge: {
            borderRadius: '8px',
            textTransform: 'none',
            height: 40,
            [breakpoints.up('xs')]: {
              fontSize: 14,
              padding: '8px',
            },
            [breakpoints.up('lg')]: {
              fontSize: 18,
              padding: '8px 16px',
            },
          },
          containedCustomGrey: {
            '&:hover': {
              color: 'white',
            },
          },
          outlinedCustomGrey: {
            borderColor: GREY_COLO2,
            color: GREY_COLO2,
          },
        },
      },
    },
    palette: {
      primary: {
        main: MAIN_COLOR_TWO,
        light: MAIN_COLOR,
      },
      secondary: {
        main: MAIN_COLOR,
      },
      error: {
        main: ERROR,
        light: LIGHT_ERROR,
        contrastText: LIGHT_ERROR,
      },
      customGrey: {
        main: GREY_COLOR,
        dark: GREY_COLO2,
        contrastText: CONTRAST_TEXT_GREY_COLOR,
      },
      customBlue: BLUE_COLOR,
      customGreen: {
        main: MAIN_CUSTOM_GREEN,
      },
      alBlue: APTEKA_LEGKO_BLUE,
    },
    mixins: {
      customScrollBar: (color: 'light' | 'primary') => ({
        /* width */
        '&::-webkit-scrollbar': {
          width: 8,
          height: 8,
        },

        /* Track */
        '&::-webkit-scrollbar-track': {
          //background: 'white',
        },

        /* Handle */
        '&::-webkit-scrollbar-thumb': {
          background: color === 'light' ? GREY_COLOR : MAIN_COLOR,
          borderRadius: 5,
        },

        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover ': {
          background: color === 'light' ? GREY_COLO2 : MAIN_CUSTOM_GREEN,
        },
      }),
      scrollBar: {
        /* width */
        '&::-webkit-scrollbar': {
          width: 8,
        },

        /* Track */
        '&::-webkit-scrollbar-track': {
          //background: 'white',
        },

        /* Handle */
        '&::-webkit-scrollbar-thumb': {
          background: GREY_COLOR,
          borderRadius: 5,
        },

        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover ': {
          background: GREY_COLO2,
        },
      },
      stickyTopFromAppBar: (increaseBy, positions = 'sticky') => ({
        position: positions,
        [breakpoints.up('xs')]: { top: HEIGHT_APP_BAR_MOBILE + increaseBy },
        [breakpoints.up('lg')]: { top: HEIGHT_APP_BAR_DESCTOP + increaseBy },
      }),
      heightAppBar: {
        [breakpoints.up('xs')]: { height: HEIGHT_APP_BAR_MOBILE },
        [breakpoints.up('lg')]: { height: HEIGHT_APP_BAR_DESCTOP },
      },
    },
    typography: {
      fontFamily: [
        'Evolventa',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  },
  ruRU
);

declare module '@mui/material/styles' {
  interface Palette {
    customGrey: Palette['primary'];
    customBlue: Palette['primary'];
    customError: Palette['primary'];
    customGreen: Palette['primary'];
    alBlue: Palette['primary'];
  }

  interface PaletteOptions {
    customGrey?: PaletteOptions['primary'];
    customBlue?: PaletteOptions['primary'];
    customError?: PaletteOptions['primary'];
    customGreen?: PaletteOptions['primary'];
    alBlue?: Palette['primary'];
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    action: true;
  }
  interface ButtonPropsColorOverrides {
    customBlue: true;
    customGreen: true;
    customGrey: true;
    alBlue: true;
  }
  interface ButtonClasses {
    containedCustomGrey: true;
    outlinedCustomGrey: true;
  }
}
declare module '@mui/material/ToggleButton' {
  interface ToggleButtonPropsVariantOverrides {
    action: true;
  }
  interface ToggleButtonPropsColorOverrides {
    customBlue: true;
    customGreen: true;
    customGrey: true;
  }
  interface ToggleButtonClasses {
    containedCustomGrey: true;
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    scrollBar?: CSSProperties;
    stickyTopFromAppBar: (
      increaseBy: number,
      positions?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed'
    ) => CSSProperties;
    customScrollBar: (color: 'light' | 'primary') => CSSProperties;
    heightAppBar: CSSProperties;
  }
}
