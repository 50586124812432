import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useDeleteMarketingSurfaceImageMutation } from 'shared/api/marketing-surface/rtk/marketingSurfaceProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { useConfirmDeleteMarketingSurfaceImageParams } from './types';

export const useConfirmDeleteMarketingSurfaceImage = (
  params: useConfirmDeleteMarketingSurfaceImageParams
) => {
  const [deleteImage, { isLoading: isLoadingDeleteImage }] =
    useDeleteMarketingSurfaceImageMutation();
  const { open: openSnackBar } = useSnackbar();
  const {
    isOpen: isOpenDialogConfirmationDeleteSutfaceImage,
    close: onCancelDeleteSurfaceImage,
    open: onOpenDialogConfirmationDeleteSurfaceImage,
  } = useViewModal();

  const onConfirmationDeleteSurfaceImage = useCallback(async () => {
    await deleteImage(params).then((response) => {
      if ('error' in response) {
        openSnackBar({
          text: getMessageFromFetchBaseQueryError(response.error),
          type: 'error',
          duration: 3000,
        });
      } else {
        openSnackBar({
          text: 'Изображение успешно удалено',
          type: 'success',
          duration: 3000,
        });
        onCancelDeleteSurfaceImage();
      }
    });
  }, [params.imageName, params.surfaceGuid]);

  return {
    isOpenDialogConfirmationDeleteSutfaceImage,
    onCancelDeleteSurfaceImage,
    onConfirmationDeleteSurfaceImage,
    onOpenDialogConfirmationDeleteSurfaceImage,
    isLoadingDeleteImage,
  };
};
