import { EventGetListResponse } from 'shared/api/visit/types';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';

import { CreateOtherEventFormFields } from '../type';

export const convertEventToOtherEventForm = (
  data?: EventGetListResponse
): CreateOtherEventFormFields | undefined => {
  if (!data) return undefined;

  return {
    dateEnd: data.endDate,
    dateStart: data.startDate,
    directionId: data.direction.id,
    isVisitingBlocked: data.isVisitingBlocked,
    homiesIds: getIdsFromArray(data.members) || [],
    title: data.title || '',
  };
};
