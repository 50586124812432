import { Button } from '@mui/material';
import { TaskWatchingFilterForm } from 'features/task/task-watch-filter-form/TaskWatchingFilterForm';
import { FC } from 'react';

import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { ModalFilterWatchingTasksProps } from './type';

export const ModalFilterWatchingTasks: FC<ModalFilterWatchingTasksProps> = ({
  isOpen,
  onClose,
  onSubmit,
  defaultValue,
}) => {
  return (
    <DialogForForm
      head={<DialogTitleForForm title='Фильтры' />}
      onClose={onClose}
      open={isOpen}
    >
      <TaskWatchingFilterForm
        onSubmit={onSubmit}
        defaultValue={defaultValue}
        actions={() => [
          <Button
            key={1}
            variant='contained'
            type='submit'
          >
            Применить
          </Button>,
          <Button
            key={2}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
