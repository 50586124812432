import { Button, Grid } from '@mui/material';
import { FC, useLayoutEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { DatePikerWithController } from 'shared/fields-with-contoller';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { DEFAULT_VALUE } from './constants';
import {
  AptekaLegkoSliderFilterFormValue,
  AptekaLegkoSliderFilterModalProps,
} from './types';

export const AptekaLegkoSliderFilterModal: FC<
  AptekaLegkoSliderFilterModalProps
> = ({ isOpen, initialValue, onClose, onSubmit }) => {
  const { control, reset, handleSubmit } =
    useForm<AptekaLegkoSliderFilterFormValue>({
      defaultValues: initialValue || DEFAULT_VALUE,
    });

  const dateStart = useWatch({ control, name: 'dateStart' });
  const dateEnd = useWatch({ control, name: 'dateEnd' });

  useLayoutEffect(() => {
    reset(initialValue || DEFAULT_VALUE);
  }, [reset, initialValue, isOpen]);

  return (
    <DialogForForm
      open={isOpen}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      head={<DialogTitleForForm title={'Фильтр слайдеры'} />}
    >
      <Grid
        display={'flex'}
        gap={2}
        flexDirection={['column', 'row']}
      >
        <DatePikerWithController
          control={control}
          fullWidth
          name={'dateStart'}
          label={'Период с'}
          shouldDisableDate={(dateStart) => dateStart.isAfter(dateEnd)}
        />
        <DatePikerWithController
          fullWidth
          control={control}
          name={'dateEnd'}
          label={'Период по'}
          shouldDisableDate={(dateEnd) => dateEnd.isBefore(dateStart)}
        />
      </Grid>
      <ContainerActionsForm>
        <Button
          variant={'contained'}
          type='submit'
        >
          Применить
        </Button>
        <Button
          variant={'contained'}
          color='customGrey'
          onClick={onClose}
        >
          Закрыть
        </Button>
      </ContainerActionsForm>
    </DialogForForm>
  );
};
