import { FC, useMemo } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTablePagination,
  UiTableRowWithActions,
} from 'shared/ui/table';
import { UiTableHeaderWithSort } from 'shared/ui/table/ui-table-header-with-sort';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';

import { GetListTask } from 'shared/api/services/types';
import { useTableCheck } from 'shared/ui/table/contexts/TableCheckContext';
import { ItemCheckProvider } from 'shared/ui/table/item-check-provider';
import {
  UiTableAllRowCheckBox,
  UiTableSingleRowCheckBox,
} from 'shared/ui/table/ui-table-checkbox/UiTableCheckbox';
import { TaskMultiActions } from '../TaskMultiActions/TaskMultiActions';
import { TaskTableProps } from './type';

export const TaskTable: FC<TaskTableProps> = ({
  data: tasks,
  isLoading = false,
  isUpdate = false,
  onClick,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  count,
  order,
  onChangeOrder,
  renderActions,
  fields,
  onChangeFields,
  visibleFields,
}) => {
  return (
    <UiTable
      getIsEqual={(eq: GetListTask, item: GetListTask) => {
        return eq.guid === item.guid;
      }}
      renderSelectList={() => {
        return <TableMultiActions />;
      }}
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={limit} />}
      header={
        <UiTableHeaderWithSort
          viewCells={visibleFields}
          setViewCells={onChangeFields}
          allCells={fields}
          order={order.order}
          orderBy={order.orderBy}
          onChangeOrder={onChangeOrder}
          additional={
            <UiTableCell>
              <UiTableAllRowCheckBox allData={tasks} />
            </UiTableCell>
          }
          orderByList={[
            'title',
            'dateStart',
            'dateFinish',
            'status',
            'creatorName',
            'executorName',
            'units',
          ]}
        />
      }
      body={tasks.map((task) => (
        <ItemCheckProvider
          item={task}
          key={task.guid}
        >
          {(isChecked, check) => (
            <UiTableRowWithActions
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  e.stopPropagation();
                  check(task);
                } else {
                  onClick && onClick(task);
                }
              }}
              hover
              renderActions={(isOpen, setIsLoading) => {
                return renderActions(task, { isOpen, setIsLoading });
              }}
              sx={{
                cursor: 'pointer',
                backgroundColor: task.isTarget
                  ? 'rgba(17, 110, 107, 0.3)'
                  : isChecked
                    ? 'rgba(120, 120, 120, 0.2)'
                    : undefined,
                '&:hover': {
                  backgroundColor: task.isTarget
                    ? 'rgba(17, 110, 107, 0.15) !important'
                    : isChecked
                      ? 'rgba(50, 50, 50, 0.2) !important'
                      : undefined,
                },
              }}
            >
              {/* Checkbox */}
              <UiTableCell>
                <UiTableSingleRowCheckBox data={task} />
              </UiTableCell>
              {/* ===== */}

              {visibleFields.map((el) => (
                <UiTableCell
                  key={el.field + task.guid}
                  {...el.getTableCellProps()}
                >
                  {el.renderComponent(task) || 'Error'}
                </UiTableCell>
              ))}
            </UiTableRowWithActions>
          )}
        </ItemCheckProvider>
      ))}
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage + 1);
          }}
          page={page - 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};

export const TableMultiActions = () => {
  const { checkedItems, unselectAll } = useTableCheck<GetListTask>();

  const guids = useMemo(() => {
    return checkedItems.map((el) => el.guid);
  }, [checkedItems]);

  return (
    <TaskMultiActions
      taskGuids={guids}
      unselectAll={unselectAll}
    />
  );
};
