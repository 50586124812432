import { Button } from '@mui/material';
import { FC } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';
import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import {
  DialogFormRunOpeningRetailProcess,
  useCreateDialogFormRunOpeningRetailProcess,
} from '../form-run-opening-retail-process';

import { DialogFormFilterProtocol } from './dialog-filter-opening-retail-protocol';

import { useFilterOpeningRetailProtocol } from './useFilterOpeningRetailProtocol';

export const SearchBarOpeningRetailProtocol: FC = () => {
  const {
    searchText,
    onClearSearchText,
    onCompliteInput,
    setSearchText,
    filterParams,
    setFilter,
  } = useFilterOpeningRetailProtocol();

  const {
    isOpen: isOpenDialogFilter,
    open: onOpenDialogFilter,
    close: onCloseDialogFilter,
  } = useViewModal();

  const {
    isOpenDialogRunProcess,
    onCloseDialogRunProcess,
    onOpenDialogRunProcess,
    handleSubmit,
  } = useCreateDialogFormRunOpeningRetailProcess();

  return (
    <UiSearchBar
      textValue={searchText}
      changeValue={setSearchText}
      onClear={onClearSearchText}
      onCompliteInput={onCompliteInput}
    >
      <FilterButton onClick={onOpenDialogFilter} />

      <Button
        variant='contained'
        color='customBlue'
        onClick={onOpenDialogRunProcess}
      >
        Запустить процесс
      </Button>
      <DialogFormRunOpeningRetailProcess
        onSubmite={handleSubmit}
        isOpen={isOpenDialogRunProcess}
        onClose={onCloseDialogRunProcess}
      />
      <DialogFormFilterProtocol
        isOpen={isOpenDialogFilter}
        onClose={onCloseDialogFilter}
        onSubmit={(form) => {
          const { templates, protocols, statuses } = form;

          setFilter({
            ...filterParams,
            OpeningTemplatesGuids: templates.map((item) => item.guid),
            ProtocolsGuids: protocols.map((item) => item.guid),
            Statuses: statuses.map((item) => item.id),
            Page: 1,
          });
          onCloseDialogFilter();
        }}
      />
    </UiSearchBar>
  );
};
