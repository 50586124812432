import { Alert, AlertTitle } from '@mui/material';
import { useStore } from 'effector-react';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormAlertFirstValid = () => {
  const error = useStore(documentFormModel.errorFirstValid);

  if (!error) return null;

  return (
    <Alert
      severity='error'
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        transitionDuration: 2,
      }}
    >
      <AlertTitle>{error?.message}</AlertTitle>
      {error.additionally}
    </Alert>
  );
};
