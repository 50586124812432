import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { FC } from 'react';
import { TaskCardStatuses, TaskCardStatusIconProps } from './type';

export const TaskCardStatusIcon: FC<TaskCardStatusIconProps> = ({
  status,
  disable,
  iconProps,
}) => {
  const sxIcon = {
    height: 30,
    width: 30,
    cursor: disable ? undefined : 'pointer',
    ...iconProps?.sx,
  };

  switch (status) {
    case TaskCardStatuses.new:
      return (
        <CheckCircleOutline
          color='disabled'
          {...iconProps}
          sx={sxIcon}
        />
      );

    case TaskCardStatuses.check:
      return (
        <CheckCircleOutline
          color='primary'
          {...iconProps}
          sx={sxIcon}
        />
      );
    case TaskCardStatuses.complete:
      return (
        <CheckCircle
          color='primary'
          {...iconProps}
          sx={sxIcon}
        />
      );
  }
};
