import { FC } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';
import { AdaptationTemplatesList } from './AdaptationTemplatesList';
import { AdaptationTemplatesTable } from './AdaptationTemplatesTable';
import { AdaptationTemplatesTableProps } from './type';

export const AdeptiveAdaptationTemplatesTable: FC<
  AdaptationTemplatesTableProps
> = (props) => {
  const [isMobile] = useDevice();

  if (isMobile) return <AdaptationTemplatesList {...props} />;
  else return <AdaptationTemplatesTable {...props} />;
};
