import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const getMessageFromFetchBaseQueryError = (
  error: FetchBaseQueryError | SerializedError
): string => {
  return (
    ('data' in error &&
      typeof error.data === 'object' &&
      error.data &&
      'message' in error.data &&
      typeof error.data.message === 'string' &&
      error.data.message) ||
    'Не известная ошибка'
  );
};
export const transformResponseError = (
  error: any
): { error: FetchBaseQueryError } => {
  console.log('transformResponse : ', JSON.stringify(error));

  const PARSE_ERROR = {
    error: {
      status: 'status' in error ? Number(error.status) : 999,
      data: {
        message: 'Не известная ошибка',
      },
    },
  };

  if ('error' in error && error.error && 'message' in error.error) {
    PARSE_ERROR.error.data.message = error.error.message;
  } else if (
    'response' in error &&
    'data' in error.response &&
    'message' in error.response.data
  ) {
    PARSE_ERROR.error.data.message = error.response.data.message;
  }

  return PARSE_ERROR;
};
