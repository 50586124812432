import { FC } from 'react';
import { Container } from './Container';

import { SliderItemSkeleton } from './SliderItemSkeleton';

export const SliderListSkeleton: FC = () => {
  return (
    <Container>
      {new Array(7).fill(1).map((item, index) => {
        const newGuid = Number(item) + index;

        return <SliderItemSkeleton key={newGuid.toString()} />;
      })}
    </Container>
  );
};
