import { MoreVert } from '@mui/icons-material';
import { Card, Grid } from '@mui/material';
import { FC } from 'react';
import { theme } from 'shared/theme/theme';
import { IconWithMenu } from '../icon-with-menu';
import { ColumnHeaderProps } from './type';

export const ColumnHeader: FC<ColumnHeaderProps> = ({
  color,
  actions,
  cardProps,
  children,
}) => {
  return (
    <Card
      elevation={2}
      sx={{ p: 2, position: 'relative', overflow: 'hidden' }}
      component={Grid}
      {...cardProps}
    >
      <Grid
        sx={{
          width: '100%',
          height: 4,
          backgroundColor: color || theme.palette.primary.main,
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      />

      <Grid
        container
        flexDirection={'row'}
        gap={2}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'nowrap'}
      >
        {children}
        {/* <Tooltip title={title.length > 20 ? title : ''}>
           <Typography
            fontWeight={'bold'}
            fontSize={20}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            width={'100%'}
          >
            {title}
          </Typography> 
        </Tooltip> */}

        {actions && (
          <IconWithMenu
            icon={<MoreVert />}
            options={actions}
          />
        )}
      </Grid>
    </Card>
  );
};
