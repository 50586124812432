import { Grid, Paper, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { useFormFetchOptionsContext } from '../../form-fetch-options-context';
import { useFormPreparedStagesTasksContext } from '../../hooks';
import { EmployeeAutocomplite } from '../employee-autocomplite';
import { PreparedTaskProps } from './types';

export const PreparedTask: FC<PreparedTaskProps> = ({
  indexTask,
  indexStage,
}) => {
  const { control } = useFormPreparedStagesTasksContext();

  /* coExecutors fields */
  const { fields: coExecutors } = useFieldArray({
    control,
    name: `stages.${indexStage}.tasks.${indexTask}.coExecutors`,
  });

  /* observers fields */
  const { fields: observers } = useFieldArray({
    control,
    name: `stages.${indexStage}.tasks.${indexTask}.observers`,
  });

  /* task title */
  const stageTaskTitle = useWatch({
    control,
    name: `stages.${indexStage}.tasks.${indexTask}.stageTaskTitle`,
  });

  /* employessOptions */
  const { useGetEmployeeOptions } = useFormFetchOptionsContext();
  const { data: employees, isFetching: isFetchingEmployees } =
    useGetEmployeeOptions();

  const employessOptions = useMemo(() => {
    if (!employees) return EMPT_ARR;

    return [...employees].sort((a, b) =>
      a.position.title > b.position.title ? 1 : -1
    );
  }, [employees]);

  return (
    <Paper>
      <Grid
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        padding={[0, 2]}
      >
        <Typography
          flexBasis={'100%'}
          fontWeight={'bold'}
          textAlign={['center', 'start']}
        >{`Задача: ${stageTaskTitle}`}</Typography>
        <Grid
          display={'grid'}
          gridTemplateColumns={['1fr', '1fr 1fr']}
          gap={2}
        >
          <EmployeeAutocomplite
            name={`stages.${indexStage}.tasks.${indexTask}.creator.employee`}
            namePositions={`stages.${indexStage}.tasks.${indexTask}.creator.requeredPosition`}
            isLoading={isFetchingEmployees}
            employeeOptions={employessOptions}
            label='Постановщик'
          />
          <EmployeeAutocomplite
            name={`stages.${indexStage}.tasks.${indexTask}.executor.employee`}
            namePositions={`stages.${indexStage}.tasks.${indexTask}.executor.requeredPosition`}
            isLoading={isFetchingEmployees}
            employeeOptions={employessOptions}
            label='Исполнитель'
          />

          {coExecutors.map((coExecutor, index) => (
            <EmployeeAutocomplite
              key={coExecutor.id}
              name={`stages.${indexStage}.tasks.${indexTask}.coExecutors.${index}.employee`}
              namePositions={`stages.${indexStage}.tasks.${indexTask}.coExecutors.${index}.requeredPosition`}
              isLoading={isFetchingEmployees}
              employeeOptions={employessOptions}
              label='Соисполнитель'
            />
          ))}
          {observers.map((coExecutor, index) => (
            <EmployeeAutocomplite
              key={coExecutor.id}
              name={`stages.${indexStage}.tasks.${indexTask}.observers.${index}.employee`}
              namePositions={`stages.${indexStage}.tasks.${indexTask}.observers.${index}.requeredPosition`}
              isLoading={isFetchingEmployees}
              employeeOptions={employessOptions}
              label='Наблюдатель'
            />
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};
