import { FC } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';

import { TrainingList } from './TrainingList';
import { TrainingTableWithSubTable } from './TrainingTableWithSubTable';
import { TrainingTableProps } from './types';

export const TrainingAdaptiveTable: FC<TrainingTableProps> = (props) => {
  const [isMobile] = useDevice();

  if (isMobile) {
    return <TrainingList {...props} />;
  }

  return <TrainingTableWithSubTable {...props} />;
};
