import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { getMessageFromFetchBaseQueryError } from '../libs';

export const useShowFetchErrorInSnackbar = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  const { open: openSnackbar } = useSnackbar();

  useEffect(() => {
    if (!error) return;

    openSnackbar({
      type: 'error',
      title: getMessageFromFetchBaseQueryError(error),
      duration: 3000,
    });
  }, [error]);
};
