import { Skeleton, TableRow } from '@mui/material';
import { FC } from 'react';

import { UiTableCell } from '..';

interface UiTableSkeletonProps {
  limit: number;
}
export const UiTableSkeleton: FC<UiTableSkeletonProps> = ({ limit }) => {
  return (
    <>
      {new Array(limit).fill(1).map((_, i) => (
        <TableRow key={i}>
          <UiTableCell colSpan={100}>
            <Skeleton
              variant='rounded'
              height={24}
            />
          </UiTableCell>
        </TableRow>
      ))}
    </>
  );
};
