import { Grid, ImageList, Typography } from '@mui/material';
import { ImageSurfaceWithFetch } from 'entities/marketing-surface/libs/Image-surface-with-fetch';

import { FC } from 'react';

import { ActionDeleteMarketingSurfaceImage } from '../action-delete-marketing-surface-image';
import { ImageSurfaceItemUi } from './ImageSurfaceItemUi';
import { ImageSurfaceListUiProps } from './types';

export const ImageSurfaceListUi: FC<ImageSurfaceListUiProps> = ({
  imagesList,
  cols,
  variant,
  isLoading,
  surfaceGuid,
}) => {
  if ((!imagesList || imagesList.length === 0) && !isLoading) {
    return (
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        height={'100%'}
      >
        <Typography variant='h4'>Изображений нет</Typography>
      </Grid>
    );
  }
  if (!imagesList) return null;
  if (isLoading && !imagesList) {
    return (
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        height={'100%'}
      >
        <Typography variant='h4'>Загрузка изображений</Typography>
      </Grid>
    );
  }

  return (
    <ImageList
      cols={cols}
      variant={variant}
      sx={{ padding: 2, paddingBottom: 4 }}
      gap={18}
    >
      {imagesList.map((item, index) => {
        return (
          <ImageSurfaceWithFetch
            image={item}
            key={item.fileName + index}
          >
            {(props) => {
              return (
                <ImageSurfaceItemUi
                  {...props}
                  createdAt={item.createdAt}
                  action={
                    surfaceGuid && (
                      <ActionDeleteMarketingSurfaceImage
                        hookParams={{
                          imageName: item.fileName,
                          surfaceGuid: surfaceGuid,
                        }}
                      />
                    )
                  }
                />
              );
            }}
          </ImageSurfaceWithFetch>
        );
      })}
    </ImageList>
  );
};
