import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';

import { isEqDay } from 'shared/date-helper/isEqDay';

import { CustomDatePicker } from '../base-ui';
import { DatePickerWithSelectedDatesProps } from './type';

/**
 * @deprecated use CustomDatePicker from shared/ui/base-ui/CustomDatePicker & date-helper
 * */
export const DatePickerWithAllowedDates: FC<
  DatePickerWithSelectedDatesProps
> = ({ allowedDates, ...props }) => {
  return (
    <CustomDatePicker
      shouldDisableDate={(day: Dayjs) => {
        if (!allowedDates) return false;

        return !allowedDates.find((date) => {
          const dayInDayJs = dayjs(date);

          return isEqDay(dayInDayJs, day);
        });
      }}
      {...props}
    />
  );
};
