export type IsOptionEqualToValueByIdFun = <T extends { id: number }>(
  val: T,
  eq: T
) => boolean;
export const isOptionEqualToValueById: IsOptionEqualToValueByIdFun = (
  val,
  eq
) => {
  return eq.id === val.id;
};
