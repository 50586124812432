import Grid from '@mui/material/Grid/Grid';
import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './styles.css';

const ContextDrawerWidth = createContext(0);
const ContextDrawerSetWidth = createContext<(arg: number) => void>(() => {
  return 20;
});

export const useDrawerWidth = () => useContext(ContextDrawerSetWidth);

export const useDrawerSetWidth = () => useContext(ContextDrawerSetWidth);

export const CustomDrawerLayout: FC<{
  children: ReactNode;
  Drawer: ReactNode;
}> = ({ Drawer, children }) => {
  const [wid, setWid] = useState(0);
  const currentOutlet = useOutlet();
  const location = useLocation();

  return (
    <ContextDrawerWidth.Provider value={wid}>
      <ContextDrawerSetWidth.Provider value={setWid}>
        <Grid
          container
          flexDirection={'row'}
          flexWrap={'nowrap'}
        >
          {Drawer}
          <Grid
            item
            sx={{
              flexGrow: 1,
              width: `calc(100% - ${wid}px)`,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
            {/*      {currentOutlet} */}
            <SwitchTransition>
              <CSSTransition
                key={location.pathname}
                timeout={300}
                classNames='opacity'
              >
                {() => (
                  <div
                    className='opacity'
                    style={{ display: 'flex', flexGrow: 1, width: '100%' }}
                  >
                    {currentOutlet}
                  </div>
                )}
              </CSSTransition>
            </SwitchTransition>
          </Grid>
        </Grid>
      </ContextDrawerSetWidth.Provider>
    </ContextDrawerWidth.Provider>
  );
};
