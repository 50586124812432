// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { createApi, createEvent, createStore, sample } from 'effector';

import { GetDocumentsActionQueryParams } from 'shared/api/services-document-flow/types';
import { paginationTasks } from 'shared/types/pagination-type';

import { getDocumentsActionFx } from 'entities/document/api/document-list-effector';

import { defaultFilter } from './constant';

const $documentActionPaginations = createStore<paginationTasks>(
  {
    CurrentPage: 1,
    HasNext: false,
    HasPrevious: false,
    PageSize: 20,
    TotalCount: 0,
    TotalPages: 0,
  },
  { name: 'document-action-filter' }
);

$documentActionPaginations.on(getDocumentsActionFx.done, (_, { result }) => {
  const paginations = JSON.parse(
    result.headers['x-pagination']
  ) as paginationTasks;

  return {
    ...paginations,
    //CurrentPage: paginations.CurrentPage ? paginations.CurrentPage - 1 : 1,
  };
});

const $documentActionFilter = createStore<GetDocumentsActionQueryParams>(
  defaultFilter,
  {
    name: 'document-action-filter',
  }
);
const apiFilter = createApi($documentActionFilter, {
  setAll: (filter, payload: GetDocumentsActionQueryParams) => {
    return payload;
  },
});
const upDateList = createEvent();

sample({
  clock: $documentActionFilter,
  fn: (filter) => ({
    ...filter,
    //CurrentPage: filter.CurrentPage ? filter.CurrentPage + 1 : 1,
  }),
  target: getDocumentsActionFx,
});
sample({
  clock: upDateList,
  source: $documentActionFilter,
  fn: (filter) => ({
    ...filter,
    //CurrentPage: filter.CurrentPage ? filter.CurrentPage + 1 : 1,
  }),
  target: getDocumentsActionFx,
});

export const documentActionFilter = {
  filter: $documentActionFilter,
  events: apiFilter,
  upDateList,
  headerPaginations: $documentActionPaginations,
};
