import { Box, Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { DocumentListItemAgreeProcessResponse } from 'shared/api/services-document-flow/types';
import background from 'shared/img/document-flow/arrow.png';
import backgroundError from 'shared/img/document-flow/arrowError.png';
import backgroundSucceed from 'shared/img/document-flow/arrowSucceed.png';
import { theme } from 'shared/theme/theme';

export const DocumentProcessAgreeUi: FC<{
  processAgree: DocumentListItemAgreeProcessResponse[] | undefined;
}> = ({ processAgree }) => {
  const sliceProcessAgree = useMemo(() => {
    if (!processAgree) return undefined;
    if (!processAgree.some((item) => item.isReject !== null)) {
      return processAgree.slice(0, 3);
    }
    if (processAgree.some((item) => item.isReject !== null)) {
      let lastIndex = 0;

      processAgree.forEach((item, index) => {
        if (item.isReject === true || item.isReject === false) {
          lastIndex = index;
        }
      });
      if (lastIndex === 0) {
        return processAgree.slice(0, 3);
      } else if (lastIndex === processAgree.length - 1) {
        return processAgree.slice(lastIndex - 2, lastIndex + 1);
      }

      return processAgree.slice(lastIndex - 1, lastIndex + 2);
    }
  }, [processAgree]);

  return (
    <Grid
      sx={{
        flexGrow: 2,
        flexBasis: '15%',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {sliceProcessAgree?.map((item, index) => {
        const backgroundUrl =
          (item.isReject === null && background) ||
          (item.isReject === true && backgroundError) ||
          (item.isReject === false && backgroundSucceed);

        return (
          <Box
            key={`${item.employeeName}_${index}`}
            sx={{
              backgroundImage: `url(${backgroundUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: '100%',
              backgroundSize: '110px 70px',
              minWidth: 125,
              width: 'max-content',
              height: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginRight: 2,
            }}
          >
            <Typography
              sx={{ marginRight: '30px' }}
              fontSize={'14px'}
              color={getColor(item.isReject)}
            >
              {item.employeeName}
            </Typography>
          </Box>
        );
      })}
    </Grid>
  );
};

const getColor = (isRegect: null | undefined | boolean) => {
  switch (isRegect) {
    case null:
      return theme.palette.customGrey.dark;
    case false:
      return 'secondary';
    case true:
      return 'error';

    default:
      break;
  }
};
