import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';

import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import { CreateConferenceEventForm } from 'widgets/visits/forms/create-conference-event-form';

import { EditConferenceEventDialogProps } from './type';

export const EditConferenceEventDialog: FC<EditConferenceEventDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initForm,
  isLoading,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Редактирование мероприятия "Сборы"' />}
    >
      <CreateConferenceEventForm
        onSubmit={onSubmit}
        initValue={initForm}
        actions={() => [
          <Button
            key={1}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
          <LoadingButton
            key={2}
            variant='contained'
            type='submit'
            loading={isLoading}
          >
            Создать
          </LoadingButton>,
        ]}
      />
    </DialogForForm>
  );
};
