import { Typography } from '@mui/material';
import { EmployeeModel } from 'shared/api/services/types';

export const renderOptionEmployeNameWithPosition = (
  params: React.HTMLAttributes<HTMLLIElement>,
  { id, employeeName, employeePosition }: EmployeeModel
) => (
  <Typography
    {...params}
    key={id}
  >{`${employeeName} (${employeePosition})`}</Typography>
);
