import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { DocumentAgreeProcessActionResponse } from 'shared/api/services-document-flow/types';
import { theme } from 'shared/theme/theme';
import { DetailsUi } from 'shared/ui/details-ui';
import { CustomTitleSectionDetailsUi, SX_TITLE } from 'shared/ui/ui';
import { formatingDate } from 'shared/utils/formating-date';
import { useDevice } from 'shared/utils/hook-type-device';

export const UiDocumentIdProcessAgreeChain: FC<{
  agreeProcess: DocumentAgreeProcessActionResponse[] | null;
}> = ({ agreeProcess }) => {
  const [isMobile] = useDevice();

  if (!agreeProcess) return null;

  return (
    <DetailsUi
      textSummary={'Цепочка согласования'}
      defaultOpen={true}
    >
      <Grid
        container
        flexDirection={['column', 'row']}
      >
        <CustomTitleSectionDetailsUi title='Цепочка согласования' />
        <Grid
          container
          justifyContent={'space-around'}
          display={['none', 'flex']}
          marginBottom={1}
          sx={{
            '& > p': {
              fontWeight: 'bold',
              color: theme.palette.customGrey.dark,
            },
            padding: '0px 0px 8px 0px',
            borderBottom: `solid 2px ${theme.palette.customGrey.main}`,
          }}
        >
          <Typography
            flexBasis={'20%'}
            textAlign={'center'}
          >
            Должность
          </Typography>
          <Typography
            flexBasis={'20%'}
            textAlign={'center'}
          >
            Пользователь
          </Typography>
          <Typography
            flexBasis={'20%'}
            textAlign={'center'}
          >
            Статус
          </Typography>
          <Typography
            flexBasis={'20%'}
            textAlign={'center'}
          >
            Действие
          </Typography>
          <Typography
            flexBasis={'20%'}
            textAlign={'center'}
          >
            Дата операции
          </Typography>
        </Grid>
        <Typography
          sx={{ ...SX_TITLE, display: ['flex', 'none'], marginBottom: 1 }}
        >
          {'Процесс согласования'}
        </Typography>
        {agreeProcess &&
          agreeProcess
            .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
            .map((item, index) => {
              return (
                <Grid
                  key={`${item.employeeId}_${index}`}
                  container
                  justifyContent={['space-around']}
                  marginBottom={1}
                  flexDirection={['column', 'row']}
                  padding={'8px 16px'}
                  sx={{
                    backgroundColor:
                      (item.isReject === true && theme.palette.error.light) ||
                      (item.isReject === false &&
                        theme.palette.customGreen.main) ||
                      ((item.isReject === undefined ||
                        item.isReject === null) &&
                        theme.palette.customGrey.main) ||
                      '',
                  }}
                >
                  <Typography
                    flexBasis={'20%'}
                    textAlign={['start', 'center']}
                  >
                    {item.employeePosition}
                  </Typography>
                  <Typography
                    flexBasis={'20%'}
                    textAlign={['start', 'center']}
                  >
                    {item.employeeName}
                  </Typography>
                  <Grid
                    flexBasis={'20%'}
                    container
                    sx={{ display: ['flex'], justifyContent: ['center'] }}
                  >
                    <Typography
                      flexBasis={'50%'}
                      textAlign={['start', 'center']}
                    >
                      {(item.isReject === undefined ||
                        item.isReject === null) &&
                        (isMobile ? 'Статус: -' : '-')}
                      {item.isReject === false &&
                        (isMobile ? 'Статус: согласован' : 'Cогласован')}
                      {item.isReject === true &&
                        (isMobile ? 'Статус: oтклонен' : 'Отклонен')}
                    </Typography>

                    <Typography
                      flexBasis={'50%'}
                      textAlign={'end'}
                      sx={{ display: ['block', 'none'] }}
                    >
                      {item.actionDate ? formatingDate(item.actionDate) : ''}
                    </Typography>
                  </Grid>
                  <Typography
                    flexBasis={'20%'}
                    textAlign={['start', 'center']}
                    sx={{ display: ['block'] }}
                  >
                    {item.taskTitle
                      ? isMobile
                        ? `Действие: ${item.taskTitle}`
                        : item.taskTitle
                      : 'Действие: -'}
                  </Typography>
                  <Typography
                    flexBasis={'20%'}
                    textAlign={'center'}
                    sx={{ display: ['none', 'block'] }}
                  >
                    {item.actionDate ? formatingDate(item.actionDate) : ''}
                  </Typography>
                </Grid>
              );
            })}
      </Grid>
    </DetailsUi>
  );
};
