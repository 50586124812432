import {
  AdvertisingSurface,
  AdvertisingSurfacePost,
} from 'shared/api/marketing-surface/marketingSurfaceAutogenerateApi';
import { FormMarketingSurfaceValue } from '.';

export const prepareSurfaceFromBackendToInitialFormValue = (
  surface: AdvertisingSurface
): FormMarketingSurfaceValue => {
  const { advertisingType, surfaceType, width, height, description } = surface;

  return {
    advertisingType: advertisingType ? advertisingType : null,
    surfaceType: surfaceType ? surfaceType : null,
    width: width ? width : '',
    height: height ? height : '',
    description: description ? description : '',
  };
};
export const preparedFormValueToPostBody = (
  form: FormMarketingSurfaceValue
): Omit<AdvertisingSurfacePost, 'retailGuid'> | null => {
  const { advertisingType, surfaceType, width, height, description } = form;

  if (!advertisingType || !surfaceType || !width || !height) {
    return null;
  }

  return {
    advertisingTypeGuid: advertisingType.guid,
    surfaceTypeGuid: surfaceType.guid,
    width: Number(width),
    height: Number(height),
    description: description ? description : (undefined as unknown as null),
    guid: undefined as unknown as null,
  };
};
