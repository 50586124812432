function format(num: number) {
  return ('' + num).replace(
    /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
    function (m, s1, s2) {
      return s2 || s1 + ' ';
    }
  );
}

export const transformNumberSummInText = (num: number) => {
  if (num === null || num === undefined) return null;

  const ceilNum = Number(num.toFixed(2));
  const formatNum = format(ceilNum);
  let newNum =
    formatNum.split('.')[1] && formatNum.split('.')[1].length === 2
      ? formatNum
      : formatNum.split('.')[1] && `${formatNum}0`;

  if (formatNum.split('.')[1] && formatNum.split('.')[1].length === 2) {
    newNum = formatNum;
  } else if (formatNum.split('.')[1] && formatNum.split('.')[1].length === 1) {
    newNum = `${formatNum}0`;
  } else if (!formatNum.split('.')[1]) {
    newNum = `${formatNum}.00`;
  }

  return `${newNum} ₽`;
};
