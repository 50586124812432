import { divIcon } from 'leaflet';
import { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';

export const getMapIcon = (Icon: ReactElement) => {
  return divIcon({
    className: '123',
    html: ReactDOMServer.renderToString(Icon),
  });
};
