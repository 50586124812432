import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DATE_FORMATS } from 'shared/date-helper/constants';
import { getDayjs } from 'shared/tarnsform-helper/getDayjs';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { getObjByIds } from 'shared/tarnsform-helper/getObjByIds';
import { isDisabledField } from 'shared/tarnsform-helper/isDisabledField';
import { isOptionEqualToValueById } from 'shared/tarnsform-helper/isOptionEqualToValueById';
import { CustomAutocomplete, CustomDatePicker } from 'shared/ui/base-ui';
import { FormLayout } from 'shared/ui/form';

import { VisitingInput, VisitorInput } from './components';
import { INIT_VISIT_FILTER_FORM_VALUE, VISIT_STATUSES } from './constants';
import { useVisitFilterFormOptions } from './libs/useVisitFilterFormOptions';
import { VisitFilterFormFields, VisitFilterFormProps } from './type';

export const VisitFilterForm: FC<VisitFilterFormProps> = ({
  actions,
  onSubmit,
  initValue,
  disabledFields,
}) => {
  const { control, handleSubmit } = useForm<VisitFilterFormFields>({
    defaultValues: {
      ...INIT_VISIT_FILTER_FORM_VALUE,
      ...initValue,
    },
  });

  /* Options */
  const {
    visitingDepartmentsOptions,
    visitingRegionsOptions,
    visitingDivisionsOptions,
    visitingRetailsOptions,
    visitorRegionsOptions,
    visitorDepartmentsOptions,
    visitorOfficesOptions,
    visitorPositionsOptions,
    visitorsOptions,
    isLoadingVisitingFilters,
    isLoadingVisitorFilters,
  } = useVisitFilterFormOptions(control);
  /* ===== */

  return (
    <FormLayout
      actions={actions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* По местам посещения */}
      <Typography fontWeight={'bold'}>По местам посещения</Typography>
      <Controller
        control={control}
        name='regionsIds'
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <VisitingInput
            disable={isDisabledField(disabledFields, 'regionsIds')}
            label='Регионы'
            onChange={onChange}
            value={value}
            options={visitingRegionsOptions}
            isLoading={isLoadingVisitingFilters}
          />
        )}
      />
      <Controller
        control={control}
        name='departmentsIds'
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <VisitingInput
            disable={isDisabledField(disabledFields, 'departmentsIds')}
            label='Сети'
            onChange={onChange}
            value={value}
            options={visitingDepartmentsOptions}
            isLoading={isLoadingVisitingFilters}
          />
        )}
      />
      <Controller
        control={control}
        name='divisionsIds'
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <VisitingInput
            disable={isDisabledField(disabledFields, 'divisionsIds')}
            label='Дивизионы'
            onChange={onChange}
            value={value}
            options={visitingDivisionsOptions}
            isLoading={isLoadingVisitingFilters}
          />
        )}
      />
      <Controller
        control={control}
        name='retailsIds'
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <VisitingInput
            disable={isDisabledField(disabledFields, 'retailsIds')}
            label='Аптеки'
            onChange={onChange}
            value={value}
            options={visitingRetailsOptions}
            isLoading={isLoadingVisitingFilters}
          />
        )}
      />
      {/* ===== */}

      {/* По посетителям */}
      <Typography fontWeight={'bold'}>По посетителям</Typography>
      <Controller
        control={control}
        defaultValue={[]}
        name='visitorRegionsIds'
        render={({ field: { onChange, value } }) => (
          <VisitorInput
            disable={isDisabledField(disabledFields, 'visitorRegionsIds')}
            label='Регионы'
            onChange={onChange}
            value={value}
            options={visitorRegionsOptions}
            isLoading={isLoadingVisitorFilters}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={[]}
        name='visitorDepartmentsIds'
        render={({ field: { onChange, value } }) => (
          <VisitorInput
            disable={isDisabledField(disabledFields, 'visitorDepartmentsIds')}
            label='Сети'
            onChange={onChange}
            value={value}
            options={visitorDepartmentsOptions}
            isLoading={isLoadingVisitorFilters}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={[]}
        name='visitorOfficesIds'
        render={({ field: { onChange, value } }) => (
          <VisitorInput
            disable={isDisabledField(disabledFields, 'visitorOfficesIds')}
            label='Офисы'
            onChange={onChange}
            value={value}
            options={visitorOfficesOptions}
            isLoading={isLoadingVisitorFilters}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={[]}
        name='visitorPositionsIds'
        render={({ field: { onChange, value } }) => (
          <VisitorInput
            disable={isDisabledField(disabledFields, 'visitorPositionsIds')}
            label='Должности'
            onChange={onChange}
            value={value}
            options={visitorPositionsOptions}
            isLoading={isLoadingVisitorFilters}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={[]}
        name='visitorsIds'
        render={({ field: { onChange, value } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Посетители',
              placeholder: 'Выберите посетителей',
            }}
            disabled={isDisabledField(disabledFields, 'visitorsIds')}
            multiple
            value={getObjByIds(visitorsOptions, value) || []}
            onChange={(_, val) => {
              onChange(getIdsFromArray(val) || []);
            }}
            options={visitorsOptions}
            getOptionLabel={(op) => op.title || ''}
            isOptionEqualToValue={isOptionEqualToValueById}
            loading={isLoadingVisitorFilters}
          />
        )}
      />
      {/* ===== */}

      {/* ===== */}
      <Typography fontWeight={'bold'}>По датам</Typography>
      <Grid
        container
        flexDirection={'row'}
        gap={2}
      >
        <Controller
          control={control}
          name='dateStart'
          render={({ field: { value, onChange } }) => (
            <CustomDatePicker
              containerSx={{ flex: 1 }}
              sx={{ width: '100%' }}
              disabled={isDisabledField(disabledFields, 'dateStart')}
              label='Дата посещения'
              value={getDayjs(value)}
              onChange={(val) => {
                onChange(val?.format(DATE_FORMATS.server));
              }}
            />
          )}
        />
        <Controller
          control={control}
          name='dateEnd'
          render={({ field: { value, onChange } }) => (
            <CustomDatePicker
              containerSx={{ flex: 1 }}
              sx={{ width: '100%' }}
              disabled={isDisabledField(disabledFields, 'dateEnd')}
              label='Дата завершения'
              value={getDayjs(value)}
              onChange={(val) => {
                onChange(val?.format(DATE_FORMATS.server));
              }}
            />
          )}
        />
      </Grid>

      {/* ===== */}

      {/* ===== */}
      <Typography fontWeight={'bold'}>По статусу посещения</Typography>
      <Controller
        control={control}
        name='status'
        render={({ field: { onChange, value } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Статус',
              placeholder: 'Выберите статус',
            }}
            value={VISIT_STATUSES.find((el) => el.value === value)}
            onChange={(_, val) => {
              onChange(val?.value);
            }}
            disabled={isDisabledField(disabledFields, 'status')}
            options={VISIT_STATUSES}
            getOptionLabel={(op) => op.title}
            isOptionEqualToValue={isOptionEqualToValueById}
          />
        )}
      />
      {/* ===== */}
    </FormLayout>
  );
};
