// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { CELL_SX, DEPARTMENT_CELL_SX } from '../style';
import { LEFT_STIKY_CELL_SX } from './../style';
import { TableRowMapType } from './type';

export const ALL_YEAR_MONTH = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const TABLE_HEAD_RAWS: TableRowMapType = [
  [
    {
      id: 1,
      renderCell: () => 'Регион',
      cellProps: {
        colSpan: 1,
        sx: { fontWeight: 'bold', minWidth: 110 },
      },
    },
    {
      id: 2,
      renderCell: () => 'Сеть',
      cellProps: {
        colSpan: 1,
        sx: {
          minWidth: 200,
          fontWeight: 'bold',
          borderRight: CELL_SX.borderRight,
          borderColor: CELL_SX.borderColor,
          position: 'sticky',
          left: 0,
          background: 'white',
          zIndex: 2,
        },
      },
    },
    // @ts-ignore
    ...ALL_YEAR_MONTH.map((month, i) => ({
      id: i + 3,
      renderCell: () => month,
      cellProps: {
        colSpan: 2,
        align: 'center',
        sx: {
          fontWeight: 'bold',
          minWidth: 185,
          ...CELL_SX,
        },
      },
    })),
    {
      id: 100,
      renderCell: () => 'Итог',
      cellProps: {
        // @ts-ignore
        align: 'right',
        sx: {
          fontWeight: 'bold',
          ...LEFT_STIKY_CELL_SX,
        },
      },
    },
  ],
  [
    {
      id: 1,
      renderCell: () => null,
      cellProps: {
        colSpan: 1,
        align: 'center',
      },
    },
    {
      id: 2,
      renderCell: () => null,
      cellProps: {
        colSpan: 1,
        align: 'center',
        sx: DEPARTMENT_CELL_SX,
      },
    },
    // @ts-ignore
    ...[...ALL_YEAR_MONTH, ...ALL_YEAR_MONTH].map((_, i) => ({
      id: i + 3,
      renderCell: () => (i % 2 === 0 ? 'ФИО' : 'Дата'),
      cellProps: {
        colSpan: 1,
        align: 'center',
        sx: {
          borderLeft: i % 2 === 0 ? '1px solid grey' : undefined,
          borderRight: i % 2 !== 0 ? '1px solid grey' : undefined,
          minWidth: i % 2 !== 0 ? 40 : undefined,
          borderColor: CELL_SX.borderColor,
        },
      },
    })),
    {
      id: 100,
      renderCell: () => null,
      // @ts-ignore
      cellProps: {
        sx: LEFT_STIKY_CELL_SX,
      },
    },
  ],
];
