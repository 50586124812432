import { E_ModuleGuids } from 'shared/moduleGuids';
import { ALL_ROUTES, Routes } from './types';
export const MARKETING_SURFACE: Routes = {
  title: 'Рекламные поверхности',
  path: ALL_ROUTES.marketingSurface,
  isWork: true,
  children: null,
  description: '',
  icon: null,
  fullPath: '',
  premission: {
    permissionGuid: E_ModuleGuids.marketingSurface,
    fields: ['isReadable'],
  },
};
