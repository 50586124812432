import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getTaskLink } from 'entities/task/libs/getTaskLink';
import { TaskStatusChip } from 'features/task/task-status-chip/TaskStatusChip';
import { TaskStatuses } from 'features/task/task-status-chip/type';
import { FC, ReactNode } from 'react';
import { RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcessTask } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { Link } from 'shared/ui/base-ui';

import { FieldMapType } from 'shared/ui/table/ui-menu-table';

const TypographyWithStyle: FC<{ children: ReactNode }> = ({ children }) => {
  return <Typography fontSize={14}>{children}</Typography>;
};

export const RUNNING_PROTOCOLS_FIELD_MAP: FieldMapType<RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcessTask>[] =
  [
    {
      field: 'title',
      title: 'Задача',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return <Link to={getTaskLink(value.guid)}>{value.title}</Link>;
      },
    },
    {
      field: 'stageNumber',
      title: 'Номер этапа',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 2,
      renderComponent: (value) => {
        return <TypographyWithStyle>{value.stageNumber}</TypographyWithStyle>;
      },
    },
    {
      field: 'creatorName',
      title: 'Постановщик',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 3,
      renderComponent: (value) => {
        return <TypographyWithStyle>{value.creatorName}</TypographyWithStyle>;
      },
    },
    {
      field: 'executorName',
      title: 'Исполнитель',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 4,
      renderComponent: (value) => {
        return <TypographyWithStyle>{value.executorName}</TypographyWithStyle>;
      },
    },
    {
      field: 'status',
      title: 'Cтатус',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 5,
      renderComponent: (value) => {
        return <TaskStatusChip status={value.status as TaskStatuses} />;
      },
    },
    {
      field: 'units',
      title: 'Юниты',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 6,
      renderComponent: (value) => {
        return <TypographyWithStyle>{value.units}</TypographyWithStyle>;
      },
    },
    {
      field: 'dateStart',
      title: 'Дата начала',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 7,
      renderComponent: (value) => {
        const date = value.dateStart;

        return (
          <TypographyWithStyle>
            {date ? dayjs(date).format('DD.MM.YYYY') : '-'}
          </TypographyWithStyle>
        );
      },
    },
    {
      field: 'dateFinish',
      title: 'Дата окончания',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 8,
      renderComponent: (value) => {
        const date = value.dateFinish;

        return (
          <TypographyWithStyle>
            {date ? dayjs(date).format('DD.MM.YYYY') : '-'}
          </TypographyWithStyle>
        );
      },
    },
    {
      field: 'isOverdue',
      title: 'Просрочена',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 9,
      renderComponent: (value) => {
        return (
          <TypographyWithStyle>
            {value.isOverdue ? 'Да' : 'Нет'}
          </TypographyWithStyle>
        );
      },
    },
  ];
