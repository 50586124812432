import { TemplateInformationView } from 'entities/adaptation/template-information-view';
import { FC } from 'react';
import { useGetAdaptationTemplateQuery } from 'shared/api/service-adaptation/rtk/adaptationTemplatesProviderApi';
import { TemplateInformationViewWithFetchDataProps } from './types';

export const TemplateInformationViewWithFetchData: FC<
  TemplateInformationViewWithFetchDataProps
> = ({ adaptationTemplateGuid }) => {
  const { data: adaptationTemplate, isLoading: isLoadingAdaptationTemplate } =
    useGetAdaptationTemplateQuery(adaptationTemplateGuid as string, {
      skip: !adaptationTemplateGuid,
    });

  return (
    <TemplateInformationView
      isLoading={isLoadingAdaptationTemplate}
      template={adaptationTemplate}
    />
  );
};
