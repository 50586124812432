import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button, SxProps, Theme } from '@mui/material';
import { Event } from 'effector';
import { FC, useCallback } from 'react';

import { theme } from 'shared/theme/theme';

/**
 * @deprecated use UploadButton from shared/ui/UploadButton
 * */
export const InputFileUiUi: FC<{
  onAddFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  id?: string;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
}> = ({ onAddFile, disabled = false, id = 'upload_file', required, sx }) => {
  return (
    <Button
      disabled={disabled}
      variant='outlined'
      size='small'
      startIcon={<AttachFileIcon />}
      component='label'
      htmlFor={id}
      sx={{
        // marginRight: '16px',
        borderStyle: ['none', 'solid'],
        borderColor: theme.palette.customGrey.dark,
        color: theme.palette.customGrey.dark,
        '&:hover': {
          borderColor: theme.palette.customGrey.dark,
          color: theme.palette.customGrey.dark,
          borderStyle: ['none', 'solid'],
        },
        '& > input': {
          zIndex: -10,
          position: 'absolute',
          opacity: 0,
        },
        ...sx,
      }}
    >
      Прикрепить
      <input
        accept='*'
        multiple
        type='file'
        onChange={onAddFile}
        value={''}
        id={id}
        name={'12'}
        required={required}
      />
    </Button>
  );
};

/**
 * @deprecated use UploadButton from shared/ui/UploadButton
 * */
export const InputFileUi: FC<{
  onAddFile: Event<File>;
  disabled?: boolean;
  id?: string;
  required?: boolean;
}> = ({ onAddFile, disabled = false, id = 'upload_file', required }) => {
  const onAddedFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      for (let index = 0; index < e.currentTarget.files.length; index++) {
        const element = e.currentTarget.files[index];

        onAddFile(element);
      }
    }
  }, []);

  return (
    <InputFileUiUi
      onAddFile={onAddedFile}
      disabled={disabled}
      id={id}
      required={required}
    />
  );
};
