//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from 'axios';
import type { SwaggerResponse } from './config';
import { Http } from './httpRequest';
//@ts-ignore
import qs from 'qs';
import type {
  CollectionForDelete,
  File,
  GetEmployeesGetEmployeesQueryParams,
  GetTrainingProcessesCompletedTrainingQueryParams,
  GetTrainingProcessesCurrentTrainingQueryParams,
  GetTrainingProcessesMyTrainingQueryParams,
  GetTrainingProcessesUnderControlQueryParams,
  ModelsEmployeeSingUpData,
  ModelsTrainingCompleteonModel,
  ModelsTrainingEmployeeCancelData,
  ModelsTrainingView,
  PagedDataModelsMyTrainingModel,
  PagedDataModelsTrainingEmployeeUnderControl,
  PagedDataResponsesTrainingProcessModel,
  PositionListModel,
  RequestsEditTrainingEmployeeRequests,
  RequestsEmpGuids,
  RequestsEmployeePositionRequests,
  RequestsIndicatorRequests,
  RequestsIndicatorToTrainingLinks,
  RequestsLinkIndicatorUpdateRequests,
  RequestsLinkIndUpdateRequests,
  RequestsLinkPositionUpdateRequests,
  RequestsLinkPosUpdateRequests,
  RequestsTrainingEventRequests,
  RequestsTrainingRequests,
  ResponsesEmployeePositionResponses,
  ResponsesEmployeeResponses,
  ResponsesIndicatorResponses,
  ResponsesLinkIndicatorResponses,
  ResponsesLinkPositionResponses,
  ResponsesTrainingEventEmployeeResponses,
  ResponsesTrainingEventGetResponse,
  ResponsesTrainingResponses,
  RetailTreeNodeModel,
  StatusModel,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, 'i');
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

/**
 *
 * Удаление эвента тренинга
 */
export const deleteTrainingProcessesProcessGuid = (
  /**
   *
   * Гуид тренинг эвента
   */
  processGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteTrainingProcessesProcessGuid.key, { processGuid }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
deleteTrainingProcessesProcessGuid.key =
  '/v1/training/api/TrainingProcesses/{processGuid}';

/**
 *
 * Удаляет связку [тренинг - работник] (TrainingEmployees)
 */
export const deleteTrainingSingUpRemoveFromTrainingTrainingEventEmployee = (
  /**
   *
   * Гуид указывающий на таблицу связку [тренинг - работник] (TrainingEmployees)
   */
  trainingEventEmployee: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteTrainingSingUpRemoveFromTrainingTrainingEventEmployee.key, {
      trainingEventEmployee,
    }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
deleteTrainingSingUpRemoveFromTrainingTrainingEventEmployee.key =
  '/v1/training/api/TrainingSingUp/RemoveFromTraining/{trainingEventEmployee}';

export const deleteTrainingsTrainingGuidFile = (
  trainingGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<File>> => {
  return Http.deleteRequest(
    template(deleteTrainingsTrainingGuidFile.key, { trainingGuid }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
deleteTrainingsTrainingGuidFile.key =
  '/v1/training/api/Trainings/{trainingGuid}/File';

/**
 *
 * Возвращает список тренеров
 */
export const getEmployees = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesEmployeeResponses[]>> => {
  return Http.getRequest(
    getEmployees.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployees.key = '/v1/training/api/Employees';

export const getEmployeesGetEmployees = (
  queryParams?: GetEmployeesGetEmployeesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesEmployeeResponses[]>> => {
  return Http.getRequest(
    getEmployeesGetEmployees.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesGetEmployees.key = '/v1/training/api/Employees/GetEmployees';

/**
 *
 * Возвращает список сотрудников
 */
export const getEmployeesGetEmployeesList = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesEmployeeResponses[]>> => {
  return Http.getRequest(
    getEmployeesGetEmployeesList.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesGetEmployeesList.key =
  '/v1/training/api/Employees/GetEmployeesList';

export const getIndicators = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RequestsIndicatorRequests[]>> => {
  return Http.getRequest(
    getIndicators.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getIndicators.key = '/v1/training/api/Indicators';

export const getIndicatorsId = (
  id: string,
  requestBody: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RequestsIndicatorRequests[]>> => {
  return Http.getRequest(
    template(getIndicatorsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getIndicatorsId.key = '/v1/training/api/Indicators/{id}';

export const getPositionList = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionListModel[]>> => {
  return Http.getRequest(
    getPositionList.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPositionList.key = '/v1/training/api/PositionList';

export const getPositions = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesEmployeePositionResponses[]>> => {
  return Http.getRequest(
    getPositions.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPositions.key = '/v1/training/api/Positions';

export const getPositionsGetTrainerPosition = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesEmployeePositionResponses[]>> => {
  return Http.getRequest(
    getPositionsGetTrainerPosition.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPositionsGetTrainerPosition.key =
  '/v1/training/api/Positions/GetTrainerPosition';

export const getRetailsTree = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RetailTreeNodeModel[]>> => {
  return Http.getRequest(
    getRetailsTree.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRetailsTree.key = '/v1/training/api/RetailsTree';

/**
 *
 * Возвращает статусы тренингов
 */
export const getStatuses = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<StatusModel[]>> => {
  return Http.getRequest(
    getStatuses.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getStatuses.key = '/v1/training/api/Statuses';

export const getTrainingEventTrainingEventGuidLinkEmployees = (
  trainingEventGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesLinkPositionResponses[]>> => {
  return Http.getRequest(
    template(getTrainingEventTrainingEventGuidLinkEmployees.key, {
      trainingEventGuid,
    }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingEventTrainingEventGuidLinkEmployees.key =
  '/v1/training/api/TrainingEvent/{trainingEventGuid}/LinkEmployees';

export const getTrainingEventsGuid = (
  guid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesTrainingEventGetResponse>> => {
  return Http.getRequest(
    template(getTrainingEventsGuid.key, { guid }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingEventsGuid.key = '/v1/training/api/TrainingEvents/{guid}';

/**
 *
 * Возвращает завершенные тренинги
 */
export const getTrainingProcessesCompletedTraining = (
  queryParams?: GetTrainingProcessesCompletedTrainingQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PagedDataResponsesTrainingProcessModel>> => {
  return Http.getRequest(
    getTrainingProcessesCompletedTraining.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingProcessesCompletedTraining.key =
  '/v1/training/api/TrainingProcesses/CompletedTraining';

/**
 *
 * Возвращает текущие тренинги
 */
export const getTrainingProcessesCurrentTraining = (
  queryParams?: GetTrainingProcessesCurrentTrainingQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PagedDataResponsesTrainingProcessModel>> => {
  return Http.getRequest(
    getTrainingProcessesCurrentTraining.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingProcessesCurrentTraining.key =
  '/v1/training/api/TrainingProcesses/CurrentTraining';

/**
 *
 * Возвращает мои тренинги
 */
export const getTrainingProcessesMyTraining = (
  queryParams?: GetTrainingProcessesMyTrainingQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PagedDataModelsMyTrainingModel>> => {
  return Http.getRequest(
    getTrainingProcessesMyTraining.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingProcessesMyTraining.key =
  '/v1/training/api/TrainingProcesses/MyTraining';

/**
 *
 * Возвращает список пользователей записанных на тренинг
 */
export const getTrainingProcessesTrainingEventEmployees = (
  /**
   *
   * эвент тренинга
   */
  trainingEvent: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesTrainingEventEmployeeResponses[]>> => {
  return Http.getRequest(
    template(getTrainingProcessesTrainingEventEmployees.key, { trainingEvent }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingProcessesTrainingEventEmployees.key =
  '/v1/training/api/TrainingProcesses/{trainingEvent}/Employees';

export const getTrainingProcessesTrainingEventEmployeesWithScores = (
  trainingEvent: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesTrainingEventEmployeeResponses[]>> => {
  return Http.getRequest(
    template(getTrainingProcessesTrainingEventEmployeesWithScores.key, {
      trainingEvent,
    }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingProcessesTrainingEventEmployeesWithScores.key =
  '/v1/training/api/TrainingProcesses/{trainingEvent}/Employees/WithScores';

/**
 *
 * Возвращает контролируемые тренинги
 */
export const getTrainingProcessesUnderControl = (
  queryParams?: GetTrainingProcessesUnderControlQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PagedDataModelsTrainingEmployeeUnderControl>> => {
  return Http.getRequest(
    getTrainingProcessesUnderControl.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingProcessesUnderControl.key =
  '/v1/training/api/TrainingProcesses/UnderControl';

/**
 *
 * [Старая врсия] Возвращает пользователю список тренингов, на которые он может записаться
 */
export const getTrainingSingUpGetAvalableTrainingsEmployeeGuidOrgDepthGuid = (
  /**
   *
   * Гуид работника
   */
  employeeGuid: string,
  /**
   *
   * Гуид сети
   */
  orgDepthGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ModelsTrainingView[]>> => {
  return Http.getRequest(
    template(
      getTrainingSingUpGetAvalableTrainingsEmployeeGuidOrgDepthGuid.key,
      { employeeGuid, orgDepthGuid }
    ),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingSingUpGetAvalableTrainingsEmployeeGuidOrgDepthGuid.key =
  '/v1/training/api/TrainingSingUp/GetAvalableTrainings/{employeeGuid}/{orgDepthGuid}';

/**
 *
 * Возвращает пользователю список тренингов, на которые он может записаться
 */
export const getTrainingSingUpGetAvalableTrainingsOrgDepthGuid = (
  /**
   *
   * Гуид сети
   */
  orgDepthGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ModelsTrainingView[]>> => {
  return Http.getRequest(
    template(getTrainingSingUpGetAvalableTrainingsOrgDepthGuid.key, {
      orgDepthGuid,
    }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingSingUpGetAvalableTrainingsOrgDepthGuid.key =
  '/v1/training/api/TrainingSingUp/GetAvalableTrainings/{orgDepthGuid}';

/**
 *
 * Возвращает список тренингов
 */
export const getTrainings = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesTrainingResponses[]>> => {
  return Http.getRequest(
    getTrainings.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainings.key = '/v1/training/api/Trainings';

export const getTrainingsGuid = (
  guid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesTrainingResponses[]>> => {
  return Http.getRequest(
    template(getTrainingsGuid.key, { guid }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingsGuid.key = '/v1/training/api/Trainings/{guid}';

/**
 *
 * Тестовый метод проверки записи файла
 */
export const getTrainingsTrainingGuidFile = (
  trainingGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<File>> => {
  return Http.getRequest(
    template(getTrainingsTrainingGuidFile.key, { trainingGuid }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingsTrainingGuidFile.key =
  '/v1/training/api/Trainings/{trainingGuid}/File';

export const getTrainingsTrainingGuidFileGet = (
  trainingGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<boolean>> => {
  return Http.getRequest(
    template(getTrainingsTrainingGuidFileGet.key, { trainingGuid }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingsTrainingGuidFileGet.key =
  '/v1/training/api/Trainings/{trainingGuid}/File/Get';

export const getTrainingsTrainingGuidLinkIndicators = (
  trainingGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesLinkIndicatorResponses[]>> => {
  return Http.getRequest(
    template(getTrainingsTrainingGuidLinkIndicators.key, { trainingGuid }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingsTrainingGuidLinkIndicators.key =
  '/v1/training/api/Trainings/{trainingGuid}/LinkIndicators';

export const getTrainingsTrainingGuidLinkPositions = (
  trainingGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesLinkPositionResponses[]>> => {
  return Http.getRequest(
    template(getTrainingsTrainingGuidLinkPositions.key, { trainingGuid }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTrainingsTrainingGuidLinkPositions.key =
  '/v1/training/api/Trainings/{trainingGuid}/LinkPositions';

export const postEmployees = (
  requestBody: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesEmployeeResponses[]>> => {
  return Http.postRequest(
    postEmployees.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployees.key = '/v1/training/api/Employees';

export const postIndicators = (
  requestBody: ResponsesIndicatorResponses,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RequestsIndicatorRequests>> => {
  return Http.postRequest(
    postIndicators.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postIndicators.key = '/v1/training/api/Indicators';

export const postIndicatorsPostDelete = (
  requestBody: CollectionForDelete,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesIndicatorResponses[]>> => {
  return Http.postRequest(
    postIndicatorsPostDelete.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postIndicatorsPostDelete.key = '/v1/training/api/Indicators/PostDelete';

export const postPositions = (
  requestBody: RequestsEmployeePositionRequests,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesEmployeePositionResponses>> => {
  return Http.postRequest(
    postPositions.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPositions.key = '/v1/training/api/Positions';

export const postTrainingEvents = (
  requestBody: RequestsTrainingEventRequests,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string>> => {
  return Http.postRequest(
    postTrainingEvents.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTrainingEvents.key = '/v1/training/api/TrainingEvents';

export const postTrainingProcessesTrainingEventEmployeesGetIndicators = (
  trainingEvent: string,
  requestBody: RequestsEmpGuids,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesTrainingEventEmployeeResponses[]>> => {
  return Http.postRequest(
    template(postTrainingProcessesTrainingEventEmployeesGetIndicators.key, {
      trainingEvent,
    }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTrainingProcessesTrainingEventEmployeesGetIndicators.key =
  '/v1/training/api/TrainingProcesses/{trainingEvent}/Employees/GetIndicators';

/**
 *
 * Метод записи сотрудника на тренинг: 0 - сотрудник записываеться на тренинг; 1 - сотрудник подтверждает запись на тренинг; 2 - непосредственный рукаводитель подтверждает запись на тренинг
 */
export const postTrainingSingUpSingUpEmployee = (
  requestBody: ModelsEmployeeSingUpData,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postTrainingSingUpSingUpEmployee.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postTrainingSingUpSingUpEmployee.key =
  '/v1/training/api/TrainingSingUp/SingUpEmployee';

export const postTrainings = (
  requestBody: RequestsTrainingRequests,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesTrainingResponses>> => {
  return Http.postRequest(
    postTrainings.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTrainings.key = '/v1/training/api/Trainings';

export const postTrainingsPostDelete = (
  requestBody: CollectionForDelete,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postTrainingsPostDelete.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postTrainingsPostDelete.key = '/v1/training/api/Trainings/PostDelete';

/**
 *
 * Тестовый метод проверки записи файла
 */
export const postTrainingsTrainingGuidFile = (
  trainingGuid: string,
  requestBody: File,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<File>> => {
  return Http.postRequest(
    template(postTrainingsTrainingGuidFile.key, { trainingGuid }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postTrainingsTrainingGuidFile.key =
  '/v1/training/api/Trainings/{trainingGuid}/File';

export const putIndicatorsGuid = (
  guid: string,
  requestBody: RequestsIndicatorRequests,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesIndicatorResponses>> => {
  return Http.putRequest(
    template(putIndicatorsGuid.key, { guid }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putIndicatorsGuid.key = '/v1/training/api/Indicators/{guid}';

export const putTrainingEvents = (
  requestBody: RequestsEditTrainingEmployeeRequests,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RequestsEditTrainingEmployeeRequests>> => {
  return Http.putRequest(
    putTrainingEvents.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTrainingEvents.key = '/v1/training/api/TrainingEvents';

export const putTrainingEventsCompleteTrainingEvent = (
  requestBody: ModelsTrainingCompleteonModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putTrainingEventsCompleteTrainingEvent.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
putTrainingEventsCompleteTrainingEvent.key =
  '/v1/training/api/TrainingEvents/CompleteTrainingEvent';

/**
 * 
 * Отмена записи сотрудника на тренинг
Возвращает коментарий
 */
export const putTrainingSingUpCancelSingUpEmployee = (
  requestBody: ModelsTrainingEmployeeCancelData,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putTrainingSingUpCancelSingUpEmployee.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
putTrainingSingUpCancelSingUpEmployee.key =
  '/v1/training/api/TrainingSingUp/CancelSingUpEmployee';

/**
 *
 * Отменить отмену записи на тренинг
 */
export const putTrainingSingUpUnCancelSingUpEmployeeTrainingEventEmployee = (
  /**
   *
   * [тренинг - работник] (TrainingEmployees)
   */
  trainingEventEmployee: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putTrainingSingUpUnCancelSingUpEmployeeTrainingEventEmployee.key, {
      trainingEventEmployee,
    }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
putTrainingSingUpUnCancelSingUpEmployeeTrainingEventEmployee.key =
  '/v1/training/api/TrainingSingUp/UnCancelSingUpEmployee/{trainingEventEmployee}';

export const putTrainingsGuid = (
  guid: string,
  requestBody: RequestsTrainingRequests,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResponsesTrainingResponses>> => {
  return Http.putRequest(
    template(putTrainingsGuid.key, { guid }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTrainingsGuid.key = '/v1/training/api/Trainings/{guid}';

export const putTrainingsTrainingGuidLinkIndicators = (
  trainingGuid: string,
  requestBody: RequestsLinkIndUpdateRequests,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putTrainingsTrainingGuidLinkIndicators.key, { trainingGuid }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
putTrainingsTrainingGuidLinkIndicators.key =
  '/v1/training/api/Trainings/{trainingGuid}/LinkIndicators';

export const putTrainingsTrainingGuidLinkIndicatorsUnselectIndicatorLinksUpdate =
  (
    trainingGuid: string,
    requestBody: RequestsLinkIndicatorUpdateRequests,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putTrainingsTrainingGuidLinkIndicatorsUnselectIndicatorLinksUpdate.key,
        { trainingGuid }
      ),
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(_CONSTANT2, configOverride)
    );
  };

/** Key is end point string without base url */
putTrainingsTrainingGuidLinkIndicatorsUnselectIndicatorLinksUpdate.key =
  '/v1/training/api/Trainings/{trainingGuid}/LinkIndicators/UnselectIndicatorLinksUpdate';

export const putTrainingsTrainingGuidLinkIndicatorsUpdateIndicatorToTrainingLinks =
  (
    trainingGuid: string,
    requestBody: RequestsIndicatorToTrainingLinks,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putTrainingsTrainingGuidLinkIndicatorsUpdateIndicatorToTrainingLinks.key,
        { trainingGuid }
      ),
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(_CONSTANT2, configOverride)
    );
  };

/** Key is end point string without base url */
putTrainingsTrainingGuidLinkIndicatorsUpdateIndicatorToTrainingLinks.key =
  '/v1/training/api/Trainings/{trainingGuid}/LinkIndicators/UpdateIndicatorToTrainingLinks';

export const putTrainingsTrainingGuidLinkPositions = (
  trainingGuid: string,
  requestBody: RequestsLinkPosUpdateRequests,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putTrainingsTrainingGuidLinkPositions.key, { trainingGuid }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
putTrainingsTrainingGuidLinkPositions.key =
  '/v1/training/api/Trainings/{trainingGuid}/LinkPositions';

export const putTrainingsTrainingGuidLinkPositionsUnselectPositionLinksUpdate =
  (
    trainingGuid: string,
    requestBody: RequestsLinkPositionUpdateRequests,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putTrainingsTrainingGuidLinkPositionsUnselectPositionLinksUpdate.key,
        { trainingGuid }
      ),
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(_CONSTANT2, configOverride)
    );
  };

/** Key is end point string without base url */
putTrainingsTrainingGuidLinkPositionsUnselectPositionLinksUpdate.key =
  '/v1/training/api/Trainings/{trainingGuid}/LinkPositions/UnselectPositionLinksUpdate';
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/plain',
  },
};
export const _CONSTANT1 = [{ Bearer: [] }];
export const _CONSTANT2 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};
