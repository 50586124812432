import { NotInterested } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { ComponentWithUserPermission } from 'entities/user';
import { FC } from 'react';

import { E_ModuleGuids } from 'shared/moduleGuids';

import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { DialogBanEmployeeForAdaptationProcess } from './DialogBanEmployeeForAdaptationProcess';
import { useDialogBanEmployeeForAdaptationProcess } from './useDialogBanEmployeeForAdaptationProcess';

export const BanEmployeForAdaptationProcess: FC<{
  employeeGuid: string;
}> = ({ employeeGuid }) => {
  const theme = useTheme();

  const {
    isLoadingToBanList,
    isOpenModalToBanList,
    closeModalToBanList,
    openModalToBanList,
    handleSubminToBunForm,
  } = useDialogBanEmployeeForAdaptationProcess({ employeeGuid });

  return (
    <>
      <ComponentWithUserPermission
        moduleGuid={E_ModuleGuids.adaptationProcesses}
      >
        {({ isWritable }) => {
          return (
            <>
              <UiButtonAction
                text='Адаптация не требуется'
                icon={<NotInterested />}
                disable={!isWritable}
                color={theme.palette.error.main}
                onClick={openModalToBanList}
              />
              <DialogBanEmployeeForAdaptationProcess
                isOpen={isOpenModalToBanList}
                onClose={closeModalToBanList}
                onSubmit={handleSubminToBunForm}
                isLoading={isLoadingToBanList}
              />
            </>
          );
        }}
      </ComponentWithUserPermission>
    </>
  );
};
