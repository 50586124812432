import { useURLState } from 'shared/url-helper/useURLState';

import { useMemo } from 'react';
import { GetDocumentsObserveQueryParams } from 'shared/api/services-document-flow/types';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';
import { CONVERT_DOCUMENT_BASE_LIST_FILTER_MAP } from '../constants';

const convertorURLToDocumentObserveFilter: URLToObjConverter<
  GetDocumentsObserveQueryParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_DOCUMENT_OBSERVE_LIST_FILTER_MAP
  ) as GetDocumentsObserveQueryParams;
};

const CONVERT_DOCUMENT_OBSERVE_LIST_FILTER_MAP: ConvertMapWithType<GetDocumentsObserveQueryParams> =
  [
    ...CONVERT_DOCUMENT_BASE_LIST_FILTER_MAP,
    { key: 'DepartmentIds', type: ConvertTypes.masNumber },
    { key: 'OrgDepartmentIds', type: ConvertTypes.masNumber },
    { key: 'OrgDirectionIds', type: ConvertTypes.masNumber },
    { key: 'OrgOfficeIds', type: ConvertTypes.masNumber },
    { key: 'OrgPositionIds', type: ConvertTypes.masNumber },
    { key: 'EmployeeIds', type: ConvertTypes.masNumber },
    { key: 'RegionIds', type: ConvertTypes.masNumber },
  ];

const DEFAULT: GetDocumentsObserveQueryParams = {
  CurrentPage: 1,
  PageSize: 20,
};

export const useUrlStateDocumentObserveFilter = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToDocumentObserveFilter,
    true
  );
  const filterWithDefault = useMemo(() => {
    return Object.assign({ ...DEFAULT }, filter);
  }, [filter]);

  return {
    filter: filterWithDefault,
    setFilter,
  };
};
