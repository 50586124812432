import { Grid, Typography } from '@mui/material';

import { APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS } from 'entities/apteka-legko/banners/sliders/links';
import { FilterButtonWithAptekalegkoSliderFilterModal } from 'features/apteka-legko/banners/sliders/filter';
import { useUrlStateAptekaLegkoBannerSlider } from 'features/apteka-legko/banners/sliders/filter/hooks';

import {
  normolizeGetAptekaLegkoBannersSlider,
  SliderListOnMonths,
} from 'features/apteka-legko/banners/sliders/slider-list-on-months';

import { useGetAptekaLegkoBannersSliderQuery } from 'shared/api/service-apteka-legko-banners/rtk/aptekaLegkoBannersSliderProviderApi';

import { LinkButton } from 'shared/ui/base-ui/link';

import { LayoutPage } from 'shared/ui/layouts/layout-page';

export const PageBannersSliderList = () => {
  const { filter } = useUrlStateAptekaLegkoBannerSlider();
  const { data, isLoading, isFetching } = useGetAptekaLegkoBannersSliderQuery(
    {
      params: filter,
    },
    {
      selectFromResult: ({ data, ...other }) => {
        return {
          data: data ? normolizeGetAptekaLegkoBannersSlider(data) : undefined,
          ...other,
        };
      },
    }
  );

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Typography
            variant='h5'
            fontWeight={'bold'}
          >
            Слайдер
          </Typography>
          <Grid
            display={'flex'}
            gap={2}
          >
            <LinkButton
              variant='contained'
              color='customBlue'
              to={APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS.created}
            >
              Добавить слайдер
            </LinkButton>
            <FilterButtonWithAptekalegkoSliderFilterModal />
          </Grid>
        </Grid>
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <SliderListOnMonths
          isLoading={isLoading}
          isUpdate={!isLoading && isFetching}
          data={data}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
