import { Button, Grid } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { DocumentFlowContainerBaseFormFilterModalProps } from './types';

export const DocumentFlowContainerBaseFormFilterModal: FC<
  PropsWithChildren<DocumentFlowContainerBaseFormFilterModalProps>
> = ({ children, isOpen, onClose, isLoading, onSubmit }) => {
  return (
    <DialogForForm
      open={isOpen}
      disabledConteiner
    >
      <Grid
        component={'form'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        gap={2}
        onSubmit={onSubmit}
      >
        <DialogTitleForForm title={'Показать документы'} />
        {children}
        <ContainerActionsForm>
          <Button
            disabled={isLoading}
            variant='contained'
            type='submit'
          >
            Применить
          </Button>
          <Button
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
