import { getToken } from 'shared/utils/getToken';
import { RequestParams } from './service-files/filesAutogenerateApi';

export const BASE_API_URL = 'https://api.garzdrav.ru:7090';
export const AxiosRequestConfigForSecurityWorker =
  async (): Promise<void | RequestParams> => {
    const accesToken = await getToken();
    if (accesToken) {
      return { headers: { Authorization: `Bearer ${accesToken}` } };
    }
  };
