import { Grid } from '@mui/material';
import { ActionVisitsPage } from 'pages/visit/ActionVisitsPage';
import { ComplitePage } from 'pages/visit/ComplitePage';
import { ObserveVisitsPage } from 'pages/visit/ObserveVisitsPage';
import { PlaningRNPage } from 'pages/visit/PlaningRNPage';
import { PlanningPage } from 'pages/visit/PlanningPage';
import { Stage1Page } from 'pages/visit/Stage1Page';
import { Stage2Page } from 'pages/visit/Stage2Page';
import { Stage3Page } from 'pages/visit/Stage3Page';
import { Stage4Page } from 'pages/visit/Stage4Page';
import { Stage5Page } from 'pages/visit/Stage5Page';
import { Stage6Page } from 'pages/visit/Stage6Page';
import { VisitInformationPage } from 'pages/visit/VisitInformationPage';
import { VisitLayout } from 'pages/visit/VisitLayout';
import { Outlet, RouteObject } from 'react-router-dom';

import { MAX_WIDTH } from 'shared/constant/size';
import { ALL_ROUTES } from 'shared/routes/constants/types';

export const ROUTES_VIST: RouteObject[] = [
  {
    path: ALL_ROUTES.visitingPharmacies,
    element: (
      <Grid
        maxWidth={MAX_WIDTH}
        sx={{ margin: '0px auto', width: '100%' }}
        item
      >
        <Outlet />
      </Grid>
    ),
    children: [
      {
        path: ALL_ROUTES.new,
        element: (
          <VisitLayout>
            <Outlet />
          </VisitLayout>
        ),
        children: [
          { path: ALL_ROUTES.stage1, element: <Stage1Page /> },
          { path: ALL_ROUTES.stage2, element: <Stage2Page /> },
          { path: ALL_ROUTES.stage3, element: <Stage3Page /> },
          { path: ALL_ROUTES.stage4, element: <Stage4Page /> },
          { path: ALL_ROUTES.stage5, element: <Stage5Page /> },
          { path: ALL_ROUTES.stage6, element: <Stage6Page /> },
        ],
      },
      { path: ALL_ROUTES.my, element: <ActionVisitsPage /> },
      { path: ALL_ROUTES.whatch, element: <ObserveVisitsPage /> },
      { path: ALL_ROUTES.complite, element: <ComplitePage /> },
      { path: ALL_ROUTES.planning, element: <PlanningPage /> },
      { path: ALL_ROUTES.visitInfo, element: <VisitInformationPage /> },
      { path: ALL_ROUTES.planingRN, element: <PlaningRNPage /> },
    ],
  },
];
