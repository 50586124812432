import {
  Box,
  ImageListItem,
  ImageListItemBar,
  Skeleton,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { ImageSurfaceItemUiProps } from './types';

export const ImageSurfaceItemUi: FC<ImageSurfaceItemUiProps> = ({
  src,
  isLoading,
  createdAt,
  action,
}) => {
  return (
    <ImageListItem>
      <ImageListItemBar
        position='top'
        actionIcon={action}
      />
      {isLoading ? (
        <Skeleton
          variant='rounded'
          width={'100%'}
          height={400}
        />
      ) : (
        <Box
          component={'img'}
          src={src}
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            verticalAlign: 'middle',
          }}
        />
      )}

      <ImageListItemBar
        sx={{
          '&  .MuiImageListItemBar-title': {
            whiteSpace: 'break-spaces',
          },
        }}
        title={
          <Typography
            fontWeight={'bold'}
            sx={{ wordWrap: 'break-word' }}
          >{`Дата добавления: ${dayjs(createdAt).format(
            DATE_FORMATS.clientWithTime
          )}`}</Typography>
        }
      />
    </ImageListItem>
  );
};
