// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { sample } from 'effector';
import { useStore } from 'effector-react';

import { postDocumentsDocumentIdDoActionFx } from 'features/document/document-actions/api/document-actions';
import {
  DocumentActionFilterModal,
  useDocumentActionFilterModal,
} from 'features/document/filters/document-action-filter';
import { documentActionFilter } from 'features/document/filters/document-action-filter/models/model-filter';
import { useEffect } from 'react';

import { useTableCellsFromLocalStorage } from 'shared/ui/table';

import { deleteDocumentIdFx } from 'entities/document/api/document-id-effector';
import { getDocumentsActionFx } from 'entities/document/api/document-list-effector';
import { $documentActionList } from 'entities/document/model/document-action-list';

import { AdeptiveDocumentTable } from 'widgets/document/document-table/AdeptiveDocumentTable';

import { compliteActiconCloseDocument } from 'features/document/document-actions/deprecated-effector';
import { DocumentListItemResponse } from 'shared/api/services-document-flow/types';

import { useFilterDocumentFlowActionPage } from 'features/document/filters/document-action-filter/hooks/useFilterDocumentFlowActionPage';
import { useUrlStateDocumentActionFilter } from 'features/document/filters/document-action-filter/use-url-state-document-action-filter';

import dayjs from 'dayjs';

import { DownloadExcelFileFromListWithHook } from 'features/download-file-excel-from-list/DownloadExcelFileFromListWithHook';
import { useLazyDownloadFileFromListDocumentActionsQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';
import { DATE_FORMATS } from 'shared/date-helper/constants';

import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';

import { LayoutWithDocumentActionsModal } from 'features/document/document-actions';
import { Tables } from 'shared/utils/controllers';
import { renderDocumentActionsContextMenu } from 'widgets/document/document-table';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { LayoutPageDocumentsListWithFilterBar } from './layouts/LayoutPageDocumentsListWithFilterBar';

sample({
  clock: [
    deleteDocumentIdFx.done,
    postDocumentsDocumentIdDoActionFx.done,
    compliteActiconCloseDocument,
  ],
  target: documentActionFilter.upDateList,
});

const usePaginationDocumentRowActions = () => {
  return useStore(documentActionFilter.headerPaginations);
};

const createFileName = () =>
  `Документооборот(делаю) от ${dayjs().format(
    DATE_FORMATS.clientWithTime
  )}.xlsx`;

export const PageDocumentAction = () => {
  const [allFields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<DocumentListItemResponse>(Tables.ACTION_DOC);

  const { filter } = useUrlStateDocumentActionFilter();

  const {
    handleChangePage,
    handleChangePageSize,
    handleChangeSearchText,
    handleCompliteChangeSearchText,
    searchInput,
    pageSize,
    currentPage,
  } = useFilterDocumentFlowActionPage();

  const isLoading = useStore(getDocumentsActionFx.pending);
  const documentsList = useStore($documentActionList);

  const pagination = usePaginationDocumentRowActions();

  useEffect(() => {
    documentActionFilter.events.setAll(filter);
  }, [filter]);

  const {
    isOpenDocumentActionFilterModalForm,
    isLoadingInitDocumentActionFilterModalForm,
    onCloseDocumentActionFilterModalForm,
    onOpenDocumentActionFilterModalForm,
    onSubmitDocumentActionFilterModal,
    initialValueDocumentActionFilterModalForm,
  } = useDocumentActionFilterModal();

  return (
    <LayoutPageDocumentsListWithFilterBar
      inputSearch={searchInput}
      onChangeInputSearch={handleChangeSearchText}
      onCompliteInput={handleCompliteChangeSearchText}
      FilterAction={
        <>
          <DownloadExcelFileFromListWithHook
            filter={filter}
            useLazyDownloadFileFromList={
              useLazyDownloadFileFromListDocumentActionsQuery
            }
            createFileName={createFileName}
          />
          <FilterButton onClick={onOpenDocumentActionFilterModalForm} />
          <DocumentActionFilterModal
            initialValue={initialValueDocumentActionFilterModalForm}
            onSubmit={onSubmitDocumentActionFilterModal}
            isLoading={isLoadingInitDocumentActionFilterModalForm}
            isOpen={isOpenDocumentActionFilterModalForm}
            onClose={onCloseDocumentActionFilterModalForm}
          />
        </>
      }
    >
      <LayoutWithDocumentActionsModal>
        <AdeptiveDocumentTable
          data={documentsList || EMPT_ARR}
          isLoading={isLoading}
          page={currentPage}
          onChangePage={handleChangePage}
          count={pagination.TotalCount}
          limit={pageSize}
          onChangeLimit={handleChangePageSize}
          fields={allFields}
          visibleFields={visibleFields}
          onChangeFields={setFields}
          renderActionsContextMenu={renderDocumentActionsContextMenu}
        />
      </LayoutWithDocumentActionsModal>
    </LayoutPageDocumentsListWithFilterBar>
  );
};
