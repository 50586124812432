import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { VisitingChecklistGetResponse } from 'shared/api/visit/types';

import { FAIK_VISIT_LIST, INITIAL_STAGES, STAGES } from '../constants';
import {
  checkCompliteStatus,
  checkIsNeedAppendIdToRoutStage,
  checkStatusWithSelected,
} from '../libs';

export const useStager = (checkList: VisitingChecklistGetResponse | null) => {
  const { pathname } = useLocation();

  const {
    isCompetitorInformation,
    isStageOneComplete,
    isCompetitorComparison,
    isStageTwoComplete,
    isStageThreeComplete,
    isStageFourComplete,
    isStageFiveComplete,
    problems,
    id,
  } = checkList || FAIK_VISIT_LIST;

  const stageData = useMemo(() => {
    const path = pathname.split('/');
    const curPath = path[path.length - 2];

    if (curPath === 'visiting-pharmacies' || !id) return INITIAL_STAGES;

    let newStages = INITIAL_STAGES.map((stage) => {
      const stagePath = stage.route.split('/')[0];

      switch (stagePath) {
        case STAGES.stage1:
          return {
            ...stage,
            status: checkStatusWithSelected(curPath, STAGES.stage1, true),
            compliteStatus: checkCompliteStatus(true),
            isView: true,
          };
        case STAGES.stage2:
          return {
            ...stage,
            status: checkStatusWithSelected(
              curPath,
              STAGES.stage2,
              isCompetitorInformation
            ),
            compliteStatus: checkCompliteStatus(isStageOneComplete),
            isView: isCompetitorInformation,
          };
        case STAGES.stage3:
          return {
            ...stage,
            status: checkStatusWithSelected(
              curPath,
              STAGES.stage3,
              isCompetitorComparison && isStageOneComplete
            ),
            compliteStatus: checkCompliteStatus(isStageTwoComplete),
            isView: isCompetitorComparison,
          };
        case STAGES.stage4:
          return {
            ...stage,
            status: checkStatusWithSelected(curPath, STAGES.stage4, true),
            compliteStatus: checkCompliteStatus(isStageThreeComplete),
            isView: true,
          };
        case STAGES.stage5:
          return {
            ...stage,
            status: checkStatusWithSelected(curPath, STAGES.stage5, true),
            compliteStatus: checkCompliteStatus(isStageFourComplete),
            isView: true,
          };
        case STAGES.stage6:
          return {
            ...stage,
            status: checkStatusWithSelected(
              curPath,
              STAGES.stage6,
              Boolean(problems && problems.length > 0)
            ),
            compliteStatus: checkCompliteStatus(isStageFiveComplete),
            isView: true,
          };
        default:
          return stage;
      }
    });

    if (checkIsNeedAppendIdToRoutStage(newStages) && id) {
      newStages = newStages.map((stage) => {
        return {
          ...stage,
          route: stage.route + '/' + id,
        };
      });
    }

    return newStages;
  }, [
    pathname,
    id,
    isCompetitorInformation,
    isStageOneComplete,
    isCompetitorComparison,
    isStageTwoComplete,
    isStageThreeComplete,
    isStageFourComplete,
    isStageFiveComplete,
    problems,
  ]);

  return stageData;
};
