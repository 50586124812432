import { GetBoardsQueryParams } from 'shared/api/services/types';
import { CONVERT_BOARD_LIST_MAP } from '../convertMaps';
import { convertURLToObject } from '../convertURLToObject';
import { URLToObjConverter } from '../type';

export const convertURLToBoardList: URLToObjConverter<GetBoardsQueryParams> = (
  searchParams
) => {
  return convertURLToObject(
    searchParams,
    CONVERT_BOARD_LIST_MAP
  ) as GetBoardsQueryParams;
};
