//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from 'axios';
import type { SwaggerResponse } from './config';
import { Http } from './httpRequest';
//@ts-ignore
import qs from 'qs';
import type {
  AgreeGroupCreate,
  AgreeGroupDelete,
  AgreeGroupUpdate,
  ArticleModel,
  ArticleResponse,
  ArticleTypeModel,
  ConstructorAgreeProcessModel,
  ConstructorArticleAgreeProcessModel,
  ConstructorOrgStructPartAgreeLinkModel,
  ConstructorOrgStructPartAllowedLinkModel,
  ConstructorPositionModel,
  ConstructorPositionsLinkItemModel,
  ConstructorProcessAdditionallyFieldModel,
  ConstructorProcessLinkModel,
  ConstructorProcessModel,
  CounterpartyModel,
  DeleteConstructorServiceTypesArchiveQueryParams,
  DeleteConstructorServiceTypesQueryParams,
  DepartmentModel,
  Document1CReasonDocumentFile,
  DocumentAgreeProcessActionResponse,
  DocumentCloseDocumentFileModel,
  DocumentCommentCreateRequest,
  DocumentCommentResponse,
  DocumentCreateRequest,
  DocumentCurrentActionsResponse,
  DocumentFirstValidateRequest,
  DocumentFirstValidationResponse,
  DocumentListItemPaidByCashboxResponse,
  DocumentListItemResponse,
  DocumentModel,
  DocumentReasonDocumentFileModel,
  DocumentSecondValidateRequest,
  DocumentSecondValidationResponse,
  DocumentStatusModel,
  DocumentUpdateRequest,
  DocumetnDoActionRequest,
  EmployeeModel,
  EstimateDocumentArticleModel,
  ExpenseExtendedConditionModel,
  ExpenseModel,
  GetAction,
  GetAgreeGroup,
  GetArticlesQueryParams,
  GetConstructorArticlesQueryParams,
  GetDocumentsActionExcelQueryParams,
  GetDocumentsActionQueryParams,
  GetDocumentsControlExcelQueryParams,
  GetDocumentsControlQueryParams,
  GetDocumentsEstimateArticlesQueryParams,
  GetDocumentsObserveExcelQueryParams,
  GetDocumentsObserveQueryParams,
  GetDocumentsPaidByCashboxQueryParams,
  GetDocumentsPaidBySecondCashboxQueryParams,
  GetEmployee,
  GetEmployeesManagersQueryParams,
  GetEmployeesMyBossQueryParams,
  GetEmployeesMyFinancesCrapQueryParams,
  GetEstimatedProcessesResponse,
  GetListAgreeGroup,
  GetListConstructorServiceType,
  GetListDelegatedAction,
  GetListOrganization,
  GetListPositionCategory,
  GetListProcessAction,
  GetListProcessActionEmployee,
  GetListProcessActionEmployeeOrganization,
  GetListProcessToAgreeGroup,
  GetListServiceType,
  GetListSubjectPosition,
  GetOrganizationsAllowedQueryParams,
  GetProcessAction,
  GetTaxRatesCalculatedQueryParams,
  ObserveFilterModel,
  OrganizationBrandModel,
  OrganizationModel,
  OrgStructPartModel,
  PositionCategoryModel,
  ProcessActionCreate,
  ProcessActionDelete,
  ProcessActionEmployeeCreate,
  ProcessActionEmployeeOrganizationDelete,
  ProcessActionEmployeeOrganizationsCreate,
  ProcessActionEmployeesDelete,
  ProcessActionUpdate,
  ProcessAdditionallyFieldsResponse,
  ProcessAdditionallyFieldTypeModel,
  ProcessItemModel,
  ProcessListItemModel,
  ProcessToAgreeGroupUpdate,
  ReasonDocumentTypeModel,
  RetailLocationModel,
  RetailModel,
  RetailTypeModel,
  ServiceTypeCreate,
  SubjectPositonUpdateList,
  TaxRateCalculatedResponse,
  UpdateEstimatedProcessesRequest,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, 'i');
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

export const deleteConstructorArticlesArticleIdExpensesExpenseId = (
  articleId: number,
  expenseId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteConstructorArticlesArticleIdExpensesExpenseId.key, {
      articleId,
      expenseId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteConstructorArticlesArticleIdExpensesExpenseId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses/{expenseId}';

export const deleteConstructorArticlesArticleIdExpensesExpenseIdExtendedConditionsExtendedConditionId =
  (
    articleId: number,
    expenseId: number,
    extendedConditionId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteConstructorArticlesArticleIdExpensesExpenseIdExtendedConditionsExtendedConditionId.key,
        { articleId, expenseId, extendedConditionId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
deleteConstructorArticlesArticleIdExpensesExpenseIdExtendedConditionsExtendedConditionId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses/{expenseId}/ExtendedConditions/{extendedConditionId}';

export const deleteConstructorPositionsPositionIdObserverPositionsLinksLinkId =
  (
    positionId: number,
    linkId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteConstructorPositionsPositionIdObserverPositionsLinksLinkId.key,
        { positionId, linkId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
deleteConstructorPositionsPositionIdObserverPositionsLinksLinkId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Positions/{positionId}/ObserverPositionsLinks/{linkId}';

export const deleteConstructorProcessesProcessId = (
  processId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteConstructorProcessesProcessId.key, { processId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteConstructorProcessesProcessId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}';

export const deleteConstructorProcessesProcessIdAdditionallyFieldsAdditionallyFieldId =
  (
    processId: number,
    additionallyFieldId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteConstructorProcessesProcessIdAdditionallyFieldsAdditionallyFieldId.key,
        { processId, additionallyFieldId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
deleteConstructorProcessesProcessIdAdditionallyFieldsAdditionallyFieldId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AdditionallyFields/{additionallyFieldId}';

export const deleteConstructorProcessesProcessIdAgreeProcessesAgreeProcessId = (
  processId: number,
  agreeProcessId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(
      deleteConstructorProcessesProcessIdAgreeProcessesAgreeProcessId.key,
      { processId, agreeProcessId }
    ),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteConstructorProcessesProcessIdAgreeProcessesAgreeProcessId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AgreeProcesses/{agreeProcessId}';

/**
 *
 * Перемещение в архив
 */
export const deleteConstructorServiceTypes = (
  queryParams?: DeleteConstructorServiceTypesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteConstructorServiceTypes.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteConstructorServiceTypes.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ServiceTypes';

/**
 *
 * Удаление неиспользуемых архивных видов услуг
 */
export const deleteConstructorServiceTypesArchive = (
  queryParams?: DeleteConstructorServiceTypesArchiveQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteConstructorServiceTypesArchive.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteConstructorServiceTypesArchive.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ServiceTypes/Archive';

/**
 *
 * Удаляет документ
 */
export const deleteDocumentsDocumentId = (
  documentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteDocumentsDocumentId.key, { documentId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteDocumentsDocumentId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}';

/**
 *
 * Удаляет комментарий
 */
export const deleteDocumentsDocumentIdDocumentCommentsCommentId = (
  /**
   *
   * Id документа
   */
  documentId: number,
  /**
   *
   * Id комментария
   */
  commentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteDocumentsDocumentIdDocumentCommentsCommentId.key, {
      documentId,
      commentId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteDocumentsDocumentIdDocumentCommentsCommentId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/DocumentComments/{commentId}';

/**
 *
 * Удаляет файл для закрывающего документа
 */
export const deleteDocumentsDocumentIdFilesCloseDocumentsFileId = (
  /**
   *
   * Id документа
   */
  documentId: number,
  /**
   *
   * Id файла
   */
  fileId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteDocumentsDocumentIdFilesCloseDocumentsFileId.key, {
      documentId,
      fileId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteDocumentsDocumentIdFilesCloseDocumentsFileId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/CloseDocuments/{fileId}';

/**
 *
 * Удаляет файл для документа основания
 */
export const deleteDocumentsDocumentIdFilesReasonDocumentsFileId = (
  /**
   *
   * Id документа
   */
  documentId: number,
  /**
   *
   * Id файла
   */
  fileId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteDocumentsDocumentIdFilesReasonDocumentsFileId.key, {
      documentId,
      fileId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteDocumentsDocumentIdFilesReasonDocumentsFileId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/ReasonDocuments/{fileId}';

/**
 *
 * Cписок типов статей
 */
export const getArticleTypes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleTypeModel[]>> => {
  return Http.getRequest(
    getArticleTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticleTypes.key = '/v1/gzinnerdocumentservice/api/ArticleTypes';

/**
 *
 * Возвращает список статей
 */
export const getArticles = (
  queryParams?: GetArticlesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleResponse[]>> => {
  return Http.getRequest(
    getArticles.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticles.key = '/v1/gzinnerdocumentservice/api/Articles';

/**
 *
 * Возвращает использованные в документах статьи для фильтра
 */
export const getArticlesForFilter = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleResponse[]>> => {
  return Http.getRequest(
    getArticlesForFilter.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticlesForFilter.key = '/v1/gzinnerdocumentservice/api/Articles/ForFilter';

export const getConstructorActions = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetAction[]>> => {
  return Http.getRequest(
    getConstructorActions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorActions.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Actions';

export const getConstructorAdditionallyFieldTypes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProcessAdditionallyFieldTypeModel[]>> => {
  return Http.getRequest(
    getConstructorAdditionallyFieldTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorAdditionallyFieldTypes.key =
  '/v1/gzinnerdocumentservice/api/Constructor/AdditionallyFieldTypes';

export const getConstructorAgreeGroups = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListAgreeGroup[]>> => {
  return Http.getRequest(
    getConstructorAgreeGroups.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorAgreeGroups.key =
  '/v1/gzinnerdocumentservice/api/Constructor/AgreeGroups';

export const getConstructorAgreeGroupsAgreeGroupId = (
  agreeGroupId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetAgreeGroup>> => {
  return Http.getRequest(
    template(getConstructorAgreeGroupsAgreeGroupId.key, { agreeGroupId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorAgreeGroupsAgreeGroupId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/AgreeGroups/{agreeGroupId}';

export const getConstructorAgreeGroupsAgreeGroupIdSubjectPositions = (
  agreeGroupId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListSubjectPosition[]>> => {
  return Http.getRequest(
    template(getConstructorAgreeGroupsAgreeGroupIdSubjectPositions.key, {
      agreeGroupId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorAgreeGroupsAgreeGroupIdSubjectPositions.key =
  '/v1/gzinnerdocumentservice/api/Constructor/AgreeGroups/{agreeGroupId}/SubjectPositions';

/**
 *
 * Возвращает список статей для конструктора
 */
export const getConstructorArticles = (
  queryParams?: GetConstructorArticlesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleModel[]>> => {
  return Http.getRequest(
    getConstructorArticles.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorArticles.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles';

export const getConstructorArticlesArticleIdExpenses = (
  articleId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ExpenseModel[]>> => {
  return Http.getRequest(
    template(getConstructorArticlesArticleIdExpenses.key, { articleId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorArticlesArticleIdExpenses.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses';

export const getConstructorArticlesArticleIdExpensesExpenseId = (
  articleId: number,
  expenseId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ExpenseModel>> => {
  return Http.getRequest(
    template(getConstructorArticlesArticleIdExpensesExpenseId.key, {
      articleId,
      expenseId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorArticlesArticleIdExpensesExpenseId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses/{expenseId}';

export const getConstructorArticlesArticleIdExpensesExpenseIdExtendedConditions =
  (
    articleId: number,
    expenseId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<ExpenseExtendedConditionModel[]>> => {
    return Http.getRequest(
      template(
        getConstructorArticlesArticleIdExpensesExpenseIdExtendedConditions.key,
        { articleId, expenseId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
getConstructorArticlesArticleIdExpensesExpenseIdExtendedConditions.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses/{expenseId}/ExtendedConditions';

export const getConstructorArticlesArticleIdExpensesExpenseIdExtendedConditionsExtendedConditionId =
  (
    articleId: number,
    expenseId: number,
    extendedConditionId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<ExpenseExtendedConditionModel>> => {
    return Http.getRequest(
      template(
        getConstructorArticlesArticleIdExpensesExpenseIdExtendedConditionsExtendedConditionId.key,
        { articleId, expenseId, extendedConditionId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
getConstructorArticlesArticleIdExpensesExpenseIdExtendedConditionsExtendedConditionId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses/{expenseId}/ExtendedConditions/{extendedConditionId}';

/**
 *
 * Список сетей
 */
export const getConstructorDepartments = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DepartmentModel[]>> => {
  return Http.getRequest(
    getConstructorDepartments.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorDepartments.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Departments';

export const getConstructorEmployeesOffice = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetEmployee[]>> => {
  return Http.getRequest(
    getConstructorEmployeesOffice.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorEmployeesOffice.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Employees/Office';

export const getConstructorOrgStructPartAgreeLinks = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorOrgStructPartAgreeLinkModel[]>> => {
  return Http.getRequest(
    getConstructorOrgStructPartAgreeLinks.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorOrgStructPartAgreeLinks.key =
  '/v1/gzinnerdocumentservice/api/Constructor/OrgStructPartAgreeLinks';

export const getConstructorOrgStructPartAllowedLinks = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorOrgStructPartAllowedLinkModel[]>> => {
  return Http.getRequest(
    getConstructorOrgStructPartAllowedLinks.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorOrgStructPartAllowedLinks.key =
  '/v1/gzinnerdocumentservice/api/Constructor/OrgStructPartAllowedLinks';

export const getConstructorOrganizationBrands = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OrganizationBrandModel[]>> => {
  return Http.getRequest(
    getConstructorOrganizationBrands.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorOrganizationBrands.key =
  '/v1/gzinnerdocumentservice/api/Constructor/OrganizationBrands';

export const getConstructorOrganizationsOffice = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListOrganization[]>> => {
  return Http.getRequest(
    getConstructorOrganizationsOffice.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorOrganizationsOffice.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Organizations/Office';

export const getConstructorPositionCategories = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionCategoryModel[]>> => {
  return Http.getRequest(
    getConstructorPositionCategories.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPositionCategories.key =
  '/v1/gzinnerdocumentservice/api/Constructor/PositionCategories';

export const getConstructorPositionCategoriesExtended = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListPositionCategory[]>> => {
  return Http.getRequest(
    getConstructorPositionCategoriesExtended.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPositionCategoriesExtended.key =
  '/v1/gzinnerdocumentservice/api/Constructor/PositionCategories/Extended';

export const getConstructorPositions = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorPositionModel[]>> => {
  return Http.getRequest(
    getConstructorPositions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPositions.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Positions';

export const getConstructorPositionsPositionIdObserverPositionsLinks = (
  positionId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorPositionsLinkItemModel[]>> => {
  return Http.getRequest(
    template(getConstructorPositionsPositionIdObserverPositionsLinks.key, {
      positionId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPositionsPositionIdObserverPositionsLinks.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Positions/{positionId}/ObserverPositionsLinks';

export const getConstructorPositionsPositionIdObserverPositionsLinksLinkId = (
  positionId: number,
  linkId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorPositionsLinkItemModel>> => {
  return Http.getRequest(
    template(
      getConstructorPositionsPositionIdObserverPositionsLinksLinkId.key,
      { positionId, linkId }
    ),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPositionsPositionIdObserverPositionsLinksLinkId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Positions/{positionId}/ObserverPositionsLinks/{linkId}';

export const getConstructorProcessActions = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListProcessAction[]>> => {
  return Http.getRequest(
    getConstructorProcessActions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessActions.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions';

export const getConstructorProcessActionsProcessActionId = (
  processActionId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetProcessAction>> => {
  return Http.getRequest(
    template(getConstructorProcessActionsProcessActionId.key, {
      processActionId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessActionsProcessActionId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/{processActionId}';

export const getConstructorProcessActionsProcessActionIdEmployees = (
  processActionId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListProcessActionEmployee[]>> => {
  return Http.getRequest(
    template(getConstructorProcessActionsProcessActionIdEmployees.key, {
      processActionId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessActionsProcessActionIdEmployees.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/{processActionId}/Employees';

export const getConstructorProcessActionsProcessActionIdEmployeesProcessActionEmployeeIdOrganizations =
  (
    processActionId: number,
    processActionEmployeeId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<GetListProcessActionEmployeeOrganization[]>> => {
    return Http.getRequest(
      template(
        getConstructorProcessActionsProcessActionIdEmployeesProcessActionEmployeeIdOrganizations.key,
        { processActionId, processActionEmployeeId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
getConstructorProcessActionsProcessActionIdEmployeesProcessActionEmployeeIdOrganizations.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/{processActionId}/Employees/{processActionEmployeeId}/Organizations';

export const getConstructorProcessLinks = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorProcessLinkModel[]>> => {
  return Http.getRequest(
    getConstructorProcessLinks.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessLinks.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessLinks';

export const getConstructorProcesses = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorProcessModel[]>> => {
  return Http.getRequest(
    getConstructorProcesses.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcesses.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes';

export const getConstructorProcessesProcessId = (
  processId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorProcessModel>> => {
  return Http.getRequest(
    template(getConstructorProcessesProcessId.key, { processId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessesProcessId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}';

export const getConstructorProcessesProcessIdAdditionallyFields = (
  processId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorProcessAdditionallyFieldModel[]>> => {
  return Http.getRequest(
    template(getConstructorProcessesProcessIdAdditionallyFields.key, {
      processId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessesProcessIdAdditionallyFields.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AdditionallyFields';

export const getConstructorProcessesProcessIdAdditionallyFieldsAdditionallyFieldId =
  (
    processId: number,
    additionallyFieldId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<ConstructorProcessAdditionallyFieldModel>> => {
    return Http.getRequest(
      template(
        getConstructorProcessesProcessIdAdditionallyFieldsAdditionallyFieldId.key,
        { processId, additionallyFieldId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
getConstructorProcessesProcessIdAdditionallyFieldsAdditionallyFieldId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AdditionallyFields/{additionallyFieldId}';

export const getConstructorProcessesProcessIdAgreeGroups = (
  processId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListProcessToAgreeGroup[]>> => {
  return Http.getRequest(
    template(getConstructorProcessesProcessIdAgreeGroups.key, { processId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessesProcessIdAgreeGroups.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AgreeGroups';

export const getConstructorProcessesProcessIdAgreeProcessConditions = (
  processId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorArticleAgreeProcessModel[]>> => {
  return Http.getRequest(
    template(getConstructorProcessesProcessIdAgreeProcessConditions.key, {
      processId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessesProcessIdAgreeProcessConditions.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AgreeProcessConditions';

export const getConstructorProcessesProcessIdAgreeProcesses = (
  processId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorAgreeProcessModel[]>> => {
  return Http.getRequest(
    template(getConstructorProcessesProcessIdAgreeProcesses.key, { processId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessesProcessIdAgreeProcesses.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AgreeProcesses';

export const getConstructorProcessesProcessIdAgreeProcessesAgreeProcessId = (
  processId: number,
  agreeProcessId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorAgreeProcessModel>> => {
  return Http.getRequest(
    template(getConstructorProcessesProcessIdAgreeProcessesAgreeProcessId.key, {
      processId,
      agreeProcessId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessesProcessIdAgreeProcessesAgreeProcessId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AgreeProcesses/{agreeProcessId}';

export const getConstructorProcessesProcessIdEstimatedProcesses = (
  processId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetEstimatedProcessesResponse>> => {
  return Http.getRequest(
    template(getConstructorProcessesProcessIdEstimatedProcesses.key, {
      processId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorProcessesProcessIdEstimatedProcesses.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/EstimatedProcesses';

export const getConstructorRetailLocations = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RetailLocationModel[]>> => {
  return Http.getRequest(
    getConstructorRetailLocations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorRetailLocations.key =
  '/v1/gzinnerdocumentservice/api/Constructor/RetailLocations';

export const getConstructorRetailTypes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RetailTypeModel[]>> => {
  return Http.getRequest(
    getConstructorRetailTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorRetailTypes.key =
  '/v1/gzinnerdocumentservice/api/Constructor/RetailTypes';

/**
 *
 * Возвращает список видов услуг
 */
export const getConstructorServiceTypes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListConstructorServiceType[]>> => {
  return Http.getRequest(
    getConstructorServiceTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorServiceTypes.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ServiceTypes';

/**
 *
 * Список контрагентов
 */
export const getCounterparties = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CounterpartyModel[]>> => {
  return Http.getRequest(
    getCounterparties.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCounterparties.key = '/v1/gzinnerdocumentservice/api/Counterparties';

/**
 *
 * Список сетей
 */
export const getDepartments = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DepartmentModel[]>> => {
  return Http.getRequest(
    getDepartments.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDepartments.key = '/v1/gzinnerdocumentservice/api/Departments';

/**
 *
 * Возвращает список файлов для документа основания по всем документам, попавшим в витрину 1С
 */
export const getDocuments1CFilesReasonDocuments = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Document1CReasonDocumentFile[]>> => {
  return Http.getRequest(
    getDocuments1CFilesReasonDocuments.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocuments1CFilesReasonDocuments.key =
  '/v1/gzinnerdocumentservice/api/Documents/1C/Files/ReasonDocuments';

/**
 *
 * Возвращает список документов для вкладки "Делаю"
 */
export const getDocumentsAction = (
  queryParams: GetDocumentsActionQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentListItemResponse[]>> => {
  return Http.getRequest(
    getDocumentsAction.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsAction.key = '/v1/gzinnerdocumentservice/api/Documents/Action';

/**
 *
 * Возвращает EXCEL список документов для вкладки "Делаю"
 */
export const getDocumentsActionExcel = (
  queryParams: GetDocumentsActionExcelQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getDocumentsActionExcel.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsActionExcel.key =
  '/v1/gzinnerdocumentservice/api/Documents/Action/Excel';

/**
 *
 * Возвращает список документов для вкладки "Контролирую"
 */
export const getDocumentsControl = (
  queryParams: GetDocumentsControlQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentListItemResponse[]>> => {
  return Http.getRequest(
    getDocumentsControl.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsControl.key = '/v1/gzinnerdocumentservice/api/Documents/Control';

/**
 *
 * Возвращает EXCEL список документов для вкладки "Контролирую"
 */
export const getDocumentsControlExcel = (
  queryParams: GetDocumentsControlExcelQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getDocumentsControlExcel.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsControlExcel.key =
  '/v1/gzinnerdocumentservice/api/Documents/Control/Excel';

/**
 *
 * Отдает документ
 */
export const getDocumentsDocumentId = (
  documentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentModel>> => {
  return Http.getRequest(
    template(getDocumentsDocumentId.key, { documentId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}';

/**
 *
 * Возвращает текущую цепочку согласования для документа
 */
export const getDocumentsDocumentIdAgreeProcesses = (
  /**
   *
   * Id документа
   */
  documentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentAgreeProcessActionResponse[]>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdAgreeProcesses.key, { documentId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdAgreeProcesses.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/AgreeProcesses';

/**
 *
 * Возвращает доступные действия авторизованного сотрудника для документа
 */
export const getDocumentsDocumentIdCurrentActions = (
  /**
   *
   * Id документа
   */
  documentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentCurrentActionsResponse>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdCurrentActions.key, { documentId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdCurrentActions.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/CurrentActions';

/**
 *
 * Возвращает список действий, доступных для делегирования
 */
export const getDocumentsDocumentIdDelegatedActions = (
  documentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListDelegatedAction[]>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdDelegatedActions.key, { documentId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdDelegatedActions.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/DelegatedActions';

/**
 *
 * Возвращает комментарии к документу
 */
export const getDocumentsDocumentIdDocumentComments = (
  /**
   *
   * Id документа
   */
  documentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentCommentResponse[]>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdDocumentComments.key, { documentId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdDocumentComments.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/DocumentComments';

/**
 *
 * Возвращает комментарий к документу
 */
export const getDocumentsDocumentIdDocumentCommentsCommentId = (
  /**
   *
   * Id документа
   */
  documentId: number,
  /**
   *
   * Id комментария
   */
  commentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentCommentResponse>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdDocumentCommentsCommentId.key, {
      documentId,
      commentId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdDocumentCommentsCommentId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/DocumentComments/{commentId}';

/**
 *
 * Возвращает список файлов для закрывающего документа
 */
export const getDocumentsDocumentIdFilesCloseDocuments = (
  /**
   *
   * Id документа
   */
  documentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentCloseDocumentFileModel[]>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdFilesCloseDocuments.key, { documentId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdFilesCloseDocuments.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/CloseDocuments';

/**
 *
 * Скачать файл для закрывающего документа
 */
export const getDocumentsDocumentIdFilesCloseDocumentsDownloadFileId = (
  /**
   *
   * Id документа
   */
  documentId: number,
  /**
   *
   * Id файла
   */
  fileId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdFilesCloseDocumentsDownloadFileId.key, {
      documentId,
      fileId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdFilesCloseDocumentsDownloadFileId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/CloseDocuments/Download/{fileId}';

/**
 *
 * Возвращает информацию по файлу для закрывающего документа
 */
export const getDocumentsDocumentIdFilesCloseDocumentsFileId = (
  /**
   *
   * Id документа
   */
  documentId: number,
  /**
   *
   * Id файла
   */
  fileId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentCloseDocumentFileModel>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdFilesCloseDocumentsFileId.key, {
      documentId,
      fileId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdFilesCloseDocumentsFileId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/CloseDocuments/{fileId}';

/**
 *
 * Возвращает список файлов для документа основания
 */
export const getDocumentsDocumentIdFilesReasonDocuments = (
  /**
   *
   * Id документа
   */
  documentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentReasonDocumentFileModel[]>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdFilesReasonDocuments.key, { documentId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdFilesReasonDocuments.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/ReasonDocuments';

/**
 *
 * Скачать файл для документа основания
 */
export const getDocumentsDocumentIdFilesReasonDocumentsDownloadFileId = (
  /**
   *
   * Id документа
   */
  documentId: number,
  /**
   *
   * Id файла
   */
  fileId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdFilesReasonDocumentsDownloadFileId.key, {
      documentId,
      fileId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdFilesReasonDocumentsDownloadFileId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/ReasonDocuments/Download/{fileId}';

/**
 *
 * Возвращает информацию по файлу для документа основания
 */
export const getDocumentsDocumentIdFilesReasonDocumentsFileId = (
  documentId: number,
  fileId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentReasonDocumentFileModel>> => {
  return Http.getRequest(
    template(getDocumentsDocumentIdFilesReasonDocumentsFileId.key, {
      documentId,
      fileId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsDocumentIdFilesReasonDocumentsFileId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/ReasonDocuments/{fileId}';

/**
 *
 * Отдает список доступных статей сметы на основе подразделения и статьи
 */
export const getDocumentsEstimateArticles = (
  queryParams: GetDocumentsEstimateArticlesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EstimateDocumentArticleModel[]>> => {
  return Http.getRequest(
    getDocumentsEstimateArticles.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsEstimateArticles.key =
  '/v1/gzinnerdocumentservice/api/Documents/EstimateArticles';

/**
 *
 * Возвращает список документов для вкладки "Наблюдаю"
 */
export const getDocumentsObserve = (
  queryParams: GetDocumentsObserveQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentListItemResponse[]>> => {
  return Http.getRequest(
    getDocumentsObserve.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsObserve.key = '/v1/gzinnerdocumentservice/api/Documents/Observe';

/**
 *
 * Возвращает EXCEL список документов для вкладки "Наблюдаю"
 */
export const getDocumentsObserveExcel = (
  queryParams: GetDocumentsObserveExcelQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getDocumentsObserveExcel.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsObserveExcel.key =
  '/v1/gzinnerdocumentservice/api/Documents/Observe/Excel';

/**
 *
 * Возвращает список статей согласованных документов, оплачиваемых через кассу
 */
export const getDocumentsPaidByCashbox = (
  queryParams: GetDocumentsPaidByCashboxQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentListItemPaidByCashboxResponse[]>> => {
  return Http.getRequest(
    getDocumentsPaidByCashbox.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsPaidByCashbox.key =
  '/v1/gzinnerdocumentservice/api/Documents/PaidByCashbox';

/**
 *
 * Возвращает список статей согласованных документов, оплачиваемых через "Вторую" кассу
 */
export const getDocumentsPaidBySecondCashbox = (
  queryParams: GetDocumentsPaidBySecondCashboxQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentListItemPaidByCashboxResponse[]>> => {
  return Http.getRequest(
    getDocumentsPaidBySecondCashbox.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDocumentsPaidBySecondCashbox.key =
  '/v1/gzinnerdocumentservice/api/Documents/PaidBySecondCashbox';

export const getEmployeesManagers = (
  queryParams?: GetEmployeesManagersQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeModel[]>> => {
  return Http.getRequest(
    getEmployeesManagers.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesManagers.key = '/v1/gzinnerdocumentservice/api/Employees/Managers';

export const getEmployeesMyBoss = (
  queryParams?: GetEmployeesMyBossQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeModel>> => {
  return Http.getRequest(
    getEmployeesMyBoss.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesMyBoss.key = '/v1/gzinnerdocumentservice/api/Employees/MyBoss';

export const getEmployeesMyFinancesCrap = (
  queryParams?: GetEmployeesMyFinancesCrapQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeModel>> => {
  return Http.getRequest(
    getEmployeesMyFinancesCrap.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesMyFinancesCrap.key =
  '/v1/gzinnerdocumentservice/api/Employees/MyFinancesCrap';

export const getEmployeesOfficeAll = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeModel[]>> => {
  return Http.getRequest(
    getEmployeesOfficeAll.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesOfficeAll.key =
  '/v1/gzinnerdocumentservice/api/Employees/OfficeAll';

/**
 *
 * Возвращает доступные для текущего пользователя элементы фильтра для списка "Наблюдаю"
 */
export const getObserveFilters = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ObserveFilterModel>> => {
  return Http.getRequest(
    getObserveFilters.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getObserveFilters.key = '/v1/gzinnerdocumentservice/api/ObserveFilters';

export const getOrgStructParts = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OrgStructPartModel[]>> => {
  return Http.getRequest(
    getOrgStructParts.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrgStructParts.key = '/v1/gzinnerdocumentservice/api/OrgStructParts';

export const getOrgStructPartsDepartments = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OrgStructPartModel[]>> => {
  return Http.getRequest(
    getOrgStructPartsDepartments.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrgStructPartsDepartments.key =
  '/v1/gzinnerdocumentservice/api/OrgStructParts/Departments';

export const getOrgStructPartsDepartmentsAllowed = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OrgStructPartModel[]>> => {
  return Http.getRequest(
    getOrgStructPartsDepartmentsAllowed.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrgStructPartsDepartmentsAllowed.key =
  '/v1/gzinnerdocumentservice/api/OrgStructParts/Departments/Allowed';

/**
 *
 * Список ЮЛ всех АУП'ов
 */
export const getOrganizations = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OrganizationModel[]>> => {
  return Http.getRequest(
    getOrganizations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrganizations.key = '/v1/gzinnerdocumentservice/api/Organizations';

/**
 *
 * Список доступных ЮЛ АУП'ов (ограничен трудоустройством текущего пользователя)
 */
export const getOrganizationsAllowed = (
  queryParams: GetOrganizationsAllowedQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OrganizationModel[]>> => {
  return Http.getRequest(
    getOrganizationsAllowed.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrganizationsAllowed.key =
  '/v1/gzinnerdocumentservice/api/Organizations/Allowed';

/**
 *
 * Список юрлиц для задания согласования юрлица документа
 */
export const getOrganizationsCheckTaskByDocumentDocumentId = (
  documentId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OrganizationModel[]>> => {
  return Http.getRequest(
    template(getOrganizationsCheckTaskByDocumentDocumentId.key, { documentId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getOrganizationsCheckTaskByDocumentDocumentId.key =
  '/v1/gzinnerdocumentservice/api/Organizations/CheckTaskByDocument/{documentId}';

export const getPositionCategories = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionCategoryModel[]>> => {
  return Http.getRequest(
    getPositionCategories.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPositionCategories.key = '/v1/gzinnerdocumentservice/api/PositionCategories';

/**
 *
 * Возвращает список процессов
 */
export const getProcesses = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProcessListItemModel[]>> => {
  return Http.getRequest(
    getProcesses.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProcesses.key = '/v1/gzinnerdocumentservice/api/Processes';

/**
 *
 * Возвращает детальную информацию по указанному процессу
 */
export const getProcessesProcessId = (
  /**
   *
   * Id процесса
   */
  processId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProcessItemModel>> => {
  return Http.getRequest(
    template(getProcessesProcessId.key, { processId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProcessesProcessId.key =
  '/v1/gzinnerdocumentservice/api/Processes/{processId}';

/**
 *
 * Список дополнительных полей процесса, используемых в документах
 */
export const getProcessesProcessIdAdditionallyFields = (
  /**
   *
   * Id процесса
   */
  processId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProcessAdditionallyFieldsResponse>> => {
  return Http.getRequest(
    template(getProcessesProcessIdAdditionallyFields.key, { processId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProcessesProcessIdAdditionallyFields.key =
  '/v1/gzinnerdocumentservice/api/Processes/{processId}/AdditionallyFields';

/**
 *
 * Справочник типов документа основания
 */
export const getReasonDocumentTypes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ReasonDocumentTypeModel[]>> => {
  return Http.getRequest(
    getReasonDocumentTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getReasonDocumentTypes.key =
  '/v1/gzinnerdocumentservice/api/ReasonDocumentTypes';

export const getRetailLocations = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RetailLocationModel[]>> => {
  return Http.getRequest(
    getRetailLocations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRetailLocations.key = '/v1/gzinnerdocumentservice/api/RetailLocations';

export const getRetailTypes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RetailTypeModel[]>> => {
  return Http.getRequest(
    getRetailTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRetailTypes.key = '/v1/gzinnerdocumentservice/api/RetailTypes';

/**
 *
 * Возвращает список подразделений
 */
export const getRetails = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RetailModel[]>> => {
  return Http.getRequest(
    getRetails.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRetails.key = '/v1/gzinnerdocumentservice/api/Retails';

/**
 *
 * Возвращает список видов услуг
 */
export const getServiceTypes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListServiceType[]>> => {
  return Http.getRequest(
    getServiceTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getServiceTypes.key = '/v1/gzinnerdocumentservice/api/ServiceTypes';

/**
 *
 * Список статусов документов
 */
export const getStatuses = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentStatusModel[]>> => {
  return Http.getRequest(
    getStatuses.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getStatuses.key = '/v1/gzinnerdocumentservice/api/Statuses';

/**
 *
 * Возвращает список ставок НДС с рассчитанной суммой НДС для указанной суммы
 */
export const getTaxRatesCalculated = (
  queryParams: GetTaxRatesCalculatedQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TaxRateCalculatedResponse[]>> => {
  return Http.getRequest(
    getTaxRatesCalculated.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTaxRatesCalculated.key =
  '/v1/gzinnerdocumentservice/api/TaxRates/Calculated';

export const postConstructorAgreeGroupsAgreeGroupIdSubjectPositionsUpdate = (
  agreeGroupId: number,
  requestBody: SubjectPositonUpdateList,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postConstructorAgreeGroupsAgreeGroupIdSubjectPositionsUpdate.key, {
      agreeGroupId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorAgreeGroupsAgreeGroupIdSubjectPositionsUpdate.key =
  '/v1/gzinnerdocumentservice/api/Constructor/AgreeGroups/{agreeGroupId}/SubjectPositions/Update';

export const postConstructorAgreeGroupsCreate = (
  requestBody: AgreeGroupCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetAgreeGroup>> => {
  return Http.postRequest(
    postConstructorAgreeGroupsCreate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorAgreeGroupsCreate.key =
  '/v1/gzinnerdocumentservice/api/Constructor/AgreeGroups/Create';

export const postConstructorAgreeGroupsDelete = (
  requestBody: AgreeGroupDelete,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetAgreeGroup>> => {
  return Http.postRequest(
    postConstructorAgreeGroupsDelete.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorAgreeGroupsDelete.key =
  '/v1/gzinnerdocumentservice/api/Constructor/AgreeGroups/Delete';

export const postConstructorAgreeGroupsUpdate = (
  requestBody: AgreeGroupUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetAgreeGroup>> => {
  return Http.postRequest(
    postConstructorAgreeGroupsUpdate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorAgreeGroupsUpdate.key =
  '/v1/gzinnerdocumentservice/api/Constructor/AgreeGroups/Update';

export const postConstructorArticlesArticleIdExpenses = (
  articleId: number,
  requestBody: ExpenseModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ExpenseModel>> => {
  return Http.postRequest(
    template(postConstructorArticlesArticleIdExpenses.key, { articleId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorArticlesArticleIdExpenses.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses';

export const postConstructorArticlesArticleIdExpensesExpenseIdExtendedConditions =
  (
    articleId: number,
    expenseId: number,
    requestBody: ExpenseExtendedConditionModel,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<ExpenseExtendedConditionModel>> => {
    return Http.postRequest(
      template(
        postConstructorArticlesArticleIdExpensesExpenseIdExtendedConditions.key,
        { articleId, expenseId }
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
postConstructorArticlesArticleIdExpensesExpenseIdExtendedConditions.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses/{expenseId}/ExtendedConditions';

export const postConstructorPositionsPositionIdObserverPositionsLinks = (
  positionId: number,
  requestBody: ConstructorPositionsLinkItemModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorPositionsLinkItemModel>> => {
  return Http.postRequest(
    template(postConstructorPositionsPositionIdObserverPositionsLinks.key, {
      positionId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorPositionsPositionIdObserverPositionsLinks.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Positions/{positionId}/ObserverPositionsLinks';

export const postConstructorProcessActionsCreate = (
  requestBody: ProcessActionCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetProcessAction>> => {
  return Http.postRequest(
    postConstructorProcessActionsCreate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorProcessActionsCreate.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/Create';

export const postConstructorProcessActionsDelete = (
  requestBody: ProcessActionDelete,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postConstructorProcessActionsDelete.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorProcessActionsDelete.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/Delete';

export const postConstructorProcessActionsProcessActionIdEmployeesCreate = (
  processActionId: number,
  requestBody: ProcessActionEmployeeCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListProcessActionEmployee[]>> => {
  return Http.postRequest(
    template(postConstructorProcessActionsProcessActionIdEmployeesCreate.key, {
      processActionId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorProcessActionsProcessActionIdEmployeesCreate.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/{processActionId}/Employees/Create';

export const postConstructorProcessActionsProcessActionIdEmployeesDelete = (
  processActionId: number,
  requestBody: ProcessActionEmployeesDelete,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postConstructorProcessActionsProcessActionIdEmployeesDelete.key, {
      processActionId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorProcessActionsProcessActionIdEmployeesDelete.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/{processActionId}/Employees/Delete';

export const postConstructorProcessActionsProcessActionIdEmployeesProcessActionEmployeeIdOrganizationsCreate =
  (
    processActionId: number,
    processActionEmployeeId: number,
    requestBody: ProcessActionEmployeeOrganizationsCreate,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<GetListProcessActionEmployeeOrganization>> => {
    return Http.postRequest(
      template(
        postConstructorProcessActionsProcessActionIdEmployeesProcessActionEmployeeIdOrganizationsCreate.key,
        { processActionId, processActionEmployeeId }
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
postConstructorProcessActionsProcessActionIdEmployeesProcessActionEmployeeIdOrganizationsCreate.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/{processActionId}/Employees/{processActionEmployeeId}/Organizations/Create';

export const postConstructorProcessActionsProcessActionIdEmployeesProcessActionEmployeeIdOrganizationsDelete =
  (
    processActionId: number,
    processActionEmployeeId: number,
    requestBody: ProcessActionEmployeeOrganizationDelete,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
      template(
        postConstructorProcessActionsProcessActionIdEmployeesProcessActionEmployeeIdOrganizationsDelete.key,
        { processActionId, processActionEmployeeId }
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
postConstructorProcessActionsProcessActionIdEmployeesProcessActionEmployeeIdOrganizationsDelete.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/{processActionId}/Employees/{processActionEmployeeId}/Organizations/Delete';

export const postConstructorProcessActionsUpdate = (
  requestBody: ProcessActionUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postConstructorProcessActionsUpdate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorProcessActionsUpdate.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessActions/Update';

export const postConstructorProcesses = (
  requestBody: ConstructorProcessModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorProcessModel>> => {
  return Http.postRequest(
    postConstructorProcesses.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorProcesses.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes';

export const postConstructorProcessesProcessIdAdditionallyFields = (
  processId: number,
  requestBody: ConstructorProcessAdditionallyFieldModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorProcessAdditionallyFieldModel>> => {
  return Http.postRequest(
    template(postConstructorProcessesProcessIdAdditionallyFields.key, {
      processId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorProcessesProcessIdAdditionallyFields.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AdditionallyFields';

export const postConstructorProcessesProcessIdAgreeGroups = (
  processId: number,
  requestBody: ProcessToAgreeGroupUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetAgreeGroup>> => {
  return Http.postRequest(
    template(postConstructorProcessesProcessIdAgreeGroups.key, { processId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorProcessesProcessIdAgreeGroups.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AgreeGroups';

export const postConstructorProcessesProcessIdAgreeProcesses = (
  processId: number,
  requestBody: ConstructorAgreeProcessModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConstructorAgreeProcessModel>> => {
  return Http.postRequest(
    template(postConstructorProcessesProcessIdAgreeProcesses.key, {
      processId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorProcessesProcessIdAgreeProcesses.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AgreeProcesses';

/**
 *
 * Создать вид услуги
 */
export const postConstructorServiceTypes = (
  requestBody: ServiceTypeCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListConstructorServiceType>> => {
  return Http.postRequest(
    postConstructorServiceTypes.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorServiceTypes.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ServiceTypes';

/**
 *
 * Актуализировать список видов услуг, используя файл Excel
 */
export const postConstructorServiceTypesExcel = (
  requestBody: {
    /**
     *
     * - Format: binary
     */
    excelFile: string;
  },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListConstructorServiceType[]>> => {
  return Http.postRequest(
    postConstructorServiceTypesExcel.key,
    undefined,
    objToForm(requestBody),
    undefined,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorServiceTypesExcel.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ServiceTypes/Excel';

/**
 *
 * Создаёт документ
 */
export const postDocuments = (
  requestBody: DocumentCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentModel>> => {
  return Http.postRequest(
    postDocuments.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postDocuments.key = '/v1/gzinnerdocumentservice/api/Documents';

/**
 *
 * Совершает действие с документом, определенное алгоритмом документооборота
 */
export const postDocumentsDocumentIdDoAction = (
  documentId: number,
  requestBody: DocumetnDoActionRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postDocumentsDocumentIdDoAction.key, { documentId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postDocumentsDocumentIdDoAction.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/DoAction';

/**
 *
 * Добавление комментария
 */
export const postDocumentsDocumentIdDocumentComments = (
  /**
   *
   * Id документа
   */
  documentId: number,
  requestBody: DocumentCommentCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentCommentResponse>> => {
  return Http.postRequest(
    template(postDocumentsDocumentIdDocumentComments.key, { documentId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postDocumentsDocumentIdDocumentComments.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/DocumentComments';

/**
 *
 * Загрузить файл закрывающего документа
 */
export const postDocumentsDocumentIdFilesCloseDocuments = (
  /**
   *
   * Id документа
   */
  documentId: number,
  requestBody: {
    /**
     *
     * - Format: binary
     */
    formFile: string;
  },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentCloseDocumentFileModel>> => {
  return Http.postRequest(
    template(postDocumentsDocumentIdFilesCloseDocuments.key, { documentId }),
    undefined,
    objToForm(requestBody),
    undefined,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postDocumentsDocumentIdFilesCloseDocuments.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/CloseDocuments';

/**
 *
 * Загрузить файл документа основания
 */
export const postDocumentsDocumentIdFilesReasonDocuments = (
  /**
   *
   * Id документа
   */
  documentId: number,
  requestBody: {
    /**
     *
     * - Format: binary
     */
    formFile: string;
  },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentReasonDocumentFileModel>> => {
  return Http.postRequest(
    template(postDocumentsDocumentIdFilesReasonDocuments.key, { documentId }),
    undefined,
    objToForm(requestBody),
    undefined,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postDocumentsDocumentIdFilesReasonDocuments.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}/Files/ReasonDocuments';

/**
 *
 * Создаёт документ без проверок
 */
export const postDocumentsDraft = (
  requestBody: DocumentCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentModel>> => {
  return Http.postRequest(
    postDocumentsDraft.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postDocumentsDraft.key = '/v1/gzinnerdocumentservice/api/Documents/Draft';

/**
 *
 * Валидация первого этапа создания/редактирования документа
 */
export const postDocumentsFirstStageValidate = (
  requestBody: DocumentFirstValidateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentFirstValidationResponse>> => {
  return Http.postRequest(
    postDocumentsFirstStageValidate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postDocumentsFirstStageValidate.key =
  '/v1/gzinnerdocumentservice/api/Documents/FirstStageValidate';

/**
 *
 * Валидация второго этапа создания/редактирования документа
 */
export const postDocumentsSecondStageValidate = (
  requestBody: DocumentSecondValidateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentSecondValidationResponse>> => {
  return Http.postRequest(
    postDocumentsSecondStageValidate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postDocumentsSecondStageValidate.key =
  '/v1/gzinnerdocumentservice/api/Documents/SecondStageValidate';

export const putConstructorArticlesArticleIdExpensesExpenseId = (
  articleId: number,
  expenseId: number,
  requestBody: ExpenseModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorArticlesArticleIdExpensesExpenseId.key, {
      articleId,
      expenseId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorArticlesArticleIdExpensesExpenseId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses/{expenseId}';

export const putConstructorArticlesArticleIdExpensesExpenseIdExtendedConditionsExtendedConditionId =
  (
    articleId: number,
    expenseId: number,
    extendedConditionId: number,
    requestBody: ExpenseExtendedConditionModel,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putConstructorArticlesArticleIdExpensesExpenseIdExtendedConditionsExtendedConditionId.key,
        { articleId, expenseId, extendedConditionId }
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
putConstructorArticlesArticleIdExpensesExpenseIdExtendedConditionsExtendedConditionId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Articles/{articleId}/Expenses/{expenseId}/ExtendedConditions/{extendedConditionId}';

export const putConstructorOrgStructPartAgreeLinksOrgStructPartId = (
  orgStructPartId: number,
  requestBody: ConstructorOrgStructPartAgreeLinkModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorOrgStructPartAgreeLinksOrgStructPartId.key, {
      orgStructPartId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorOrgStructPartAgreeLinksOrgStructPartId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/OrgStructPartAgreeLinks/{orgStructPartId}';

export const putConstructorOrgStructPartAllowedLinksOrgStructPartId = (
  orgStructPartId: number,
  requestBody: ConstructorOrgStructPartAllowedLinkModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorOrgStructPartAllowedLinksOrgStructPartId.key, {
      orgStructPartId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorOrgStructPartAllowedLinksOrgStructPartId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/OrgStructPartAllowedLinks/{orgStructPartId}';

export const putConstructorProcessLinksProcessId = (
  processId: number,
  requestBody: ConstructorProcessLinkModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorProcessLinksProcessId.key, { processId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorProcessLinksProcessId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ProcessLinks/{processId}';

export const putConstructorProcessesProcessId = (
  processId: number,
  requestBody: ConstructorProcessModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorProcessesProcessId.key, { processId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorProcessesProcessId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}';

export const putConstructorProcessesProcessIdAdditionallyFieldsAdditionallyFieldId =
  (
    processId: number,
    additionallyFieldId: number,
    requestBody: ConstructorProcessAdditionallyFieldModel,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putConstructorProcessesProcessIdAdditionallyFieldsAdditionallyFieldId.key,
        { processId, additionallyFieldId }
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
putConstructorProcessesProcessIdAdditionallyFieldsAdditionallyFieldId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AdditionallyFields/{additionallyFieldId}';

export const putConstructorProcessesProcessIdAgreeProcessConditionsArticleId = (
  processId: number,
  articleId: number,
  requestBody: ConstructorArticleAgreeProcessModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(
      putConstructorProcessesProcessIdAgreeProcessConditionsArticleId.key,
      { processId, articleId }
    ),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorProcessesProcessIdAgreeProcessConditionsArticleId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AgreeProcessConditions/{articleId}';

export const putConstructorProcessesProcessIdAgreeProcessesAgreeProcessId = (
  processId: number,
  agreeProcessId: number,
  requestBody: ConstructorAgreeProcessModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorProcessesProcessIdAgreeProcessesAgreeProcessId.key, {
      processId,
      agreeProcessId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorProcessesProcessIdAgreeProcessesAgreeProcessId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/AgreeProcesses/{agreeProcessId}';

export const putConstructorProcessesProcessIdEstimatedProcesses = (
  processId: number,
  requestBody: UpdateEstimatedProcessesRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetEstimatedProcessesResponse>> => {
  return Http.putRequest(
    template(putConstructorProcessesProcessIdEstimatedProcesses.key, {
      processId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorProcessesProcessIdEstimatedProcesses.key =
  '/v1/gzinnerdocumentservice/api/Constructor/Processes/{processId}/EstimatedProcesses';

/**
 *
 * Изменить вид услуги
 */
export const putConstructorServiceTypesServiceTypeId = (
  /**
   *
   * Id вида услуги
   */
  serviceTypeId: number,
  requestBody: ServiceTypeCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorServiceTypesServiceTypeId.key, { serviceTypeId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorServiceTypesServiceTypeId.key =
  '/v1/gzinnerdocumentservice/api/Constructor/ServiceTypes/{serviceTypeId}';

/**
 *
 * Обновляет информацию по документу
 */
export const putDocumentsDocumentId = (
  documentId: number,
  requestBody: DocumentUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DocumentModel>> => {
  return Http.putRequest(
    template(putDocumentsDocumentId.key, { documentId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putDocumentsDocumentId.key =
  '/v1/gzinnerdocumentservice/api/Documents/{documentId}';
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/plain',
  },
};
export const _CONSTANT1 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};
export const _CONSTANT2 = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'text/plain',
  },
};
