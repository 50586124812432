import { useStore } from 'effector-react';
import { useCallback, useMemo } from 'react';
import { useGetDocumentByIdQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { postDocumentsDocumentIdDoActionFx } from '../api/document-actions';
import { FormAgreeOrganizationValue } from './types';

export const useDialogFormAgreeOrganization = (documentId: number) => {
  const {
    isOpen: isOpenDialogFormAgreeOrganization,
    close: onCloseDialogFormAgreeOrganization,
    open: onOpenDialogFormAgreeOrganization,
  } = useViewModal();

  const { data: document, isLoading: isLoadingDocument } =
    useGetDocumentByIdQuery(
      { documentId },
      { skip: !isOpenDialogFormAgreeOrganization }
    );

  const isLoadingSubmitAgreeOrganizationForm = useStore(
    postDocumentsDocumentIdDoActionFx.pending
  );
  const { open: openSnackbar } = useSnackbar();

  const onSubmitAgreeOrganizationForm = useCallback(
    async (form: FormAgreeOrganizationValue) => {
      if (!form.organization) {
        return;
      }
      postDocumentsDocumentIdDoActionFx({
        documentId,
        requestBody: {
          actionTaskId: 7,
          organizationRequest: {
            organizationId: form.organization.id,
          },
        },
      })
        .then(() => {
          close();
        })
        .catch((error) => {
          if ('message' in error) {
            openSnackbar({
              type: 'error',
              text: error.message,
              duration: 3000,
            });
          } else {
            openSnackbar({
              type: 'error',
              text: 'Упс, что-то пошло не так',
              duration: 3000,
            });
          }
        });
    },
    [documentId]
  );

  const initialValue = useMemo(() => {
    return { organization: document?.organization || null };
  }, [document]);

  return {
    onSubmitAgreeOrganizationForm,
    isOpenDialogFormAgreeOrganization,
    onCloseDialogFormAgreeOrganization,
    onOpenDialogFormAgreeOrganization,
    isLoadingSubmitAgreeOrganizationForm,
    isLoadingInitialValueAgreeOrganizationForm: isLoadingDocument,
    initialValueAgreeOrganizationForm: initialValue,
  };
};
