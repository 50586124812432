import { useMemo } from 'react';
import { convertURLToBoardTaskList } from 'shared/url-helper/conventors/convertURLToBoardTaskList';
import { useURLState } from 'shared/url-helper/useURLState';

export const useURLBoard = () => {
  const [filter, setFilter] = useURLState(convertURLToBoardTaskList, true);

  const memoQ = useMemo(() => {
    if (!filter || Object.keys(filter).length === 0)
      return {
        SearchText: '',
      };
    else return filter;
  }, [filter]);

  return [memoQ, setFilter] as const;
};
