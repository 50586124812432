import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { DATE_FORMATS } from 'shared/date-helper/constants';
import { hasNotDateInMonth } from 'shared/date-helper/hasNotDateInMonth';
import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import { findElementById } from 'shared/tarnsform-helper/findElementById';
import { findElementsByIds } from 'shared/tarnsform-helper/findElementsByIds';
import { getDayjs } from 'shared/tarnsform-helper/getDayjs';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { isOptionEqualToValueById } from 'shared/tarnsform-helper/isOptionEqualToValueById';
import {
  CustomAutocomplete,
  CustomDatePicker,
  CustomTextField,
} from 'shared/ui/base-ui';
import { FormLayout } from 'shared/ui/form';

import { useGetEventHomies } from 'entities/visit/options/useGetEventHomies';
import { useGetVisitDirections } from 'entities/visit/options/useGetVisitDirections';

import { createOtherEventFormSchema } from './schema/validate';
import { CreateOtherEventFormFields, CreateOtherEventFormProps } from './type';

export const CreateOtherEventForm: FC<CreateOtherEventFormProps> = ({
  actions,
  onSubmit,
  initValue,
}) => {
  const { directions, isLoading: isLoadingDirections } =
    useGetVisitDirections();
  const { homies, isLoading: isLoadingHomies } = useGetEventHomies();
  const { control, handleSubmit } = useForm<CreateOtherEventFormFields>({
    defaultValues: initValue,
    resolver: yupResolver(createOtherEventFormSchema),
  });

  const selectedDirId = useWatch({ control: control, name: 'directionId' });
  const selectedStartDate = useWatch({ control: control, name: 'dateStart' });
  const selectedEndDate = useWatch({ control: control, name: 'dateEnd' });

  const shouldDisableDate = useCallback(
    (date: Dayjs) => {
      return hasNotDateInMonth(date, selectedStartDate || selectedEndDate);
    },
    [selectedStartDate, selectedEndDate]
  );

  const referenceDate = useMemo(() => {
    if (!selectedEndDate && !selectedStartDate) return dayjs();
    if (selectedStartDate) return dayjs(selectedStartDate);
    else return dayjs(selectedEndDate);
  }, [selectedEndDate, selectedStartDate]);

  const avalHomies = useMemo(() => {
    if (!selectedDirId) return [];

    return homies.filter((homi) => homi.directionId !== selectedDirId);
  }, [homies, selectedDirId]);

  return (
    <FormLayout
      actions={actions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit, console.log)}
    >
      <Controller
        control={control}
        name='title'
        render={({ field: { value, onChange } }) => (
          <CustomTextField
            value={value || ''}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            label='Наименование'
            placeholder='Введите наименоване'
            required
          />
        )}
      />

      <Controller
        control={control}
        name='directionId'
        render={({ field: { onChange, value } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Направление',
              placeholder: 'Выберите направление',
              required: true,
            }}
            value={findElementById(directions, value)}
            onChange={(_, val) => {
              onChange(val?.id);
            }}
            loading={isLoadingDirections}
            options={directions}
            getOptionLabel={(val) => val.title || ''}
            isOptionEqualToValue={isOptionEqualToValueById}
          />
        )}
      />

      <Grid
        container
        flexDirection={'row'}
        gap={2}
      >
        <Controller
          control={control}
          name='dateStart'
          render={({ field: { onChange, value } }) => (
            <CustomDatePicker
              slotProps={{ textField: { required: true } }}
              value={getDayjs(value)}
              onChange={(val) => {
                onChange(val?.format(DATE_FORMATS.server));
              }}
              label='Дата начала'
              sx={{ width: '100%' }}
              containerSx={{ flex: 1 }}
              views={['year', 'month', 'day']}
              shouldDisableDate={shouldDisableDate}
              referenceDate={referenceDate}
            />
          )}
        />

        <Controller
          control={control}
          name='dateEnd'
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <CustomDatePicker
              slotProps={{
                textField: {
                  required: true,
                  helperText: error?.message,
                  error: invalid,
                },
              }}
              value={getDayjs(value)}
              onChange={(val) => {
                onChange(val?.format(DATE_FORMATS.server));
              }}
              label='Дата окончания'
              sx={{ width: '100%' }}
              containerSx={{ flex: 1 }}
              views={['year', 'month', 'day']}
              shouldDisableDate={shouldDisableDate}
              referenceDate={referenceDate}
            />
          )}
        />
      </Grid>

      <Controller
        control={control}
        name='homiesIds'
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Доп. участники',
              placeholder: 'Выберите доп. участников',
            }}
            multiple
            value={findElementsByIds(avalHomies, value || [])}
            getOptionLabel={getEmployeTitle}
            isOptionEqualToValue={isOptionEqualToValueById}
            noOptionsText={
              avalHomies.length === 0
                ? 'Направление не указано'
                : 'Нет доступных вариантов'
            }
            onChange={(_, val) => {
              onChange(getIdsFromArray(val));
            }}
            loading={isLoadingHomies}
            options={avalHomies}
          />
        )}
      />

      <Controller
        control={control}
        name='isVisitingBlocked'
        defaultValue={false}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            checked={value}
            onChange={(_, val) => {
              onChange(val);
            }}
            sx={{ pl: 2 }}
            control={<Switch />}
            label='Исключить посещения'
          />
        )}
      />
    </FormLayout>
  );
};
