import { BannerBrandsItemUi } from 'entities/apteka-legko/banners/brands/ui/banner-brands-item-ui';

import { FC, useState } from 'react';
import { useIsComliteLoadingImage } from 'shared/hooks/use-is-comlite-loading-image';
import { BannerBrandsItemProps } from './types';

export const BannerBrandsItem: FC<BannerBrandsItemProps> = ({
  imageUrl,
  Action,
}) => {
  const [ref, setRef] = useState<HTMLImageElement | null>(null);
  const { isCompliteLoadImage } = useIsComliteLoadingImage(ref);

  return (
    <BannerBrandsItemUi
      skipAfterSxBlock={isCompliteLoadImage}
      Action={Action}
    >
      <img
        ref={setRef}
        style={{ width: '100%', height: 'auto' }}
        src={imageUrl}
      />
    </BannerBrandsItemUi>
  );
};
