import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { marketingSurfaceProviderApi } from 'shared/api/marketing-surface/rtk/marketingSurfaceProviderApi';
import { firebaseProviderApi } from 'shared/api/online-store-service/firebase/rtk/firebaseProviderApi';
import { retailOpeningProcessProviderApi } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { adaptationProviderApi } from 'shared/api/service-adaptation/rtk/adaptationProviderApi';
import { aptekaLegkoBannersProviderApi } from 'shared/api/service-apteka-legko-banners/rtk/aptekaLegkoBannersProviderApi';
import { filesProviderApi } from 'shared/api/service-files/rtk/filesProviderApi';
import { servicesUserProviderApi } from 'shared/api/services-auth/rtk/servicesUserProviderApi';
import { documentFlowProviderApi } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';
import { taskProviderApi } from 'shared/api/services/rtk/taskProviderApi';
import { trainingProviderApi } from 'shared/api/traning/rtk/trainingProviderApi';

export const store = configureStore({
  reducer: {
    [servicesUserProviderApi.reducerPath]: servicesUserProviderApi.reducer,
    [trainingProviderApi.reducerPath]: trainingProviderApi.reducer,
    [retailOpeningProcessProviderApi.reducerPath]:
      retailOpeningProcessProviderApi.reducer,
    [filesProviderApi.reducerPath]: filesProviderApi.reducer,
    [documentFlowProviderApi.reducerPath]: documentFlowProviderApi.reducer,
    [marketingSurfaceProviderApi.reducerPath]:
      marketingSurfaceProviderApi.reducer,
    [taskProviderApi.reducerPath]: taskProviderApi.reducer,
    [firebaseProviderApi.reducerPath]: firebaseProviderApi.reducer,
    [adaptationProviderApi.reducerPath]: adaptationProviderApi.reducer,
    [aptekaLegkoBannersProviderApi.reducerPath]:
      aptekaLegkoBannersProviderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(servicesUserProviderApi.middleware)
      .concat(trainingProviderApi.middleware)
      .concat(retailOpeningProcessProviderApi.middleware)
      .concat(filesProviderApi.middleware)
      .concat(taskProviderApi.middleware)
      .concat(marketingSurfaceProviderApi.middleware)
      .concat(documentFlowProviderApi.middleware)
      .concat(firebaseProviderApi.middleware)
      .concat(adaptationProviderApi.middleware)
      .concat(aptekaLegkoBannersProviderApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
