import { useURLState } from 'shared/url-helper/useURLState';

import { useMemo } from 'react';

import { AdaptationProcessesBannedListParams } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { UserNoAdaptationRequiredItem } from 'shared/api/service-adaptation/normolize-data/transformResponseBackendUserNoAdaptationRequiredToViewType';
import { OrderFilter } from 'shared/ui/table/types/order-props';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';

/* EmployeeNameAsc - имя сотрудника по возрастанию;
EmployeeNameDesc - имя сотрудника по убыванию;
RetailTitleAsc - наименование подразделения по возрастанию;
RetailTitleDesc - наименование подразделения по убыванию; */

type UserNoAdaptationRequiredOrderBy =
  | 'EmployeeNameAsc'
  | 'EmployeeNameDesc'
  | 'RetailTitleAsc'
  | 'RetailTitleDesc'
  | undefined;

export type AdaptationProcessesBannedListParamsWithOrderBy = Omit<
  AdaptationProcessesBannedListParams,
  'OrderBy'
> & {
  OrderBy: UserNoAdaptationRequiredOrderBy | undefined;
};
export const userNoAdaptationRequiredQueryOrderByToOrderTable = (
  queryString: UserNoAdaptationRequiredOrderBy
): OrderFilter<UserNoAdaptationRequiredItem> => {
  switch (queryString) {
    case 'EmployeeNameAsc':
      return {
        order: 'asc',
        orderBy: 'name',
      };
    case 'EmployeeNameDesc':
      return {
        order: 'desc',
        orderBy: 'name',
      };
    case 'RetailTitleAsc':
      return {
        order: 'asc',
        orderBy: 'retailTitle',
      };
    case 'RetailTitleDesc':
      return {
        order: 'desc',
        orderBy: 'retailTitle',
      };
    default:
      return {
        order: 'desc',
        orderBy: null,
      };
  }
};

export const userNoAdaptationRequiredOrderTableToQueryOrderBy = (
  order: OrderFilter<UserNoAdaptationRequiredItem> | undefined
): UserNoAdaptationRequiredOrderBy | undefined => {
  if (!order) return undefined;
  if (order.order === 'asc') {
    switch (order?.orderBy) {
      case 'name':
        return 'EmployeeNameAsc';
      case 'retailTitle':
        return 'RetailTitleAsc';
      default:
        return undefined;
    }
  } else {
    switch (order?.orderBy) {
      case 'name':
        return 'EmployeeNameDesc';
      case 'retailTitle':
        return 'RetailTitleDesc';
      default:
        return undefined;
    }
  }
};

const CONVERT_USER_NO_ADAPTATIONS_REQUIRED_LIST_FILTER_MAP: ConvertMapWithType<AdaptationProcessesBannedListParamsWithOrderBy> =
  [
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'Page', type: ConvertTypes.number },
    { key: 'SearchText', type: ConvertTypes.string },
    { key: 'OrderBy', type: ConvertTypes.string },
  ];

const convertorURLToUserNoAdaptationsRequiredFilter: URLToObjConverter<
  AdaptationProcessesBannedListParamsWithOrderBy
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_USER_NO_ADAPTATIONS_REQUIRED_LIST_FILTER_MAP
  ) as AdaptationProcessesBannedListParamsWithOrderBy;
};

const DEFAULT: AdaptationProcessesBannedListParams = {
  Page: 1,
  Limit: 20,
};

export const useUrlStateUserNoAdaptationsRequiredFilter = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToUserNoAdaptationsRequiredFilter,
    true
  );
  const filterWithDefault = useMemo(() => {
    return Object.assign({ ...DEFAULT }, filter);
  }, [filter]);

  return {
    filter: filterWithDefault,
    setFilter,
  };
};
