import { MenuItem, Select } from '@mui/material';
import { FC } from 'react';

import { DepartmentSelectProps } from './type';

export const DepartmentSelect: FC<DepartmentSelectProps> = ({
  departments,
  onSelectDepartment,
  selectedDepartmentId,
  selectProps,
}) => {
  return (
    <Select
      label={'Сеть'}
      placeholder='Сеть'
      variant='standard'
      size='small'
      color='info'
      value={selectedDepartmentId}
      onChange={(e) => {
        onSelectDepartment(Number(e.target.value));
      }}
      SelectDisplayProps={{
        style: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      {...selectProps}
      sx={{
        minWidth: '250px',
        ...selectProps?.sx,
      }}
    >
      <MenuItem value={0}>Все регионы</MenuItem>
      {departments.map((department) => (
        <MenuItem
          key={department.id}
          value={department.id}
        >
          {department.title}
        </MenuItem>
      ))}
    </Select>
  );
};
