import { SxProps, Theme } from '@mui/material';
import { theme } from 'shared/theme/theme';

export const APP_BAR_SX: SxProps<Theme> = {
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'row',
  zIndex: 10,
  width: '100%',
  justifyContent: 'space-between',
  padding: 1,
  ...theme.mixins.heightAppBar,
};
