import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC, memo } from 'react';

import { theme } from 'shared/theme/theme';

import { MonthSelectorProps } from './type';

const grey = theme.palette.grey[700];

export const MonthSelector: FC<MonthSelectorProps> = memo(
  ({ onChange, selectedDate }) => {
    const setNextMonth = () => {
      onChange(selectedDate.add(1, 'month'));
    };
    const setPrevMonth = () => {
      onChange(selectedDate.subtract(1, 'month'));
    };

    return (
      <Grid
        container
        alignItems={'center'}
        gap={3}
      >
        <ArrowBackIos
          onClick={setPrevMonth}
          sx={{ cursor: 'pointer', color: grey }}
        />
        <Typography
          fontWeight={700}
          fontSize={20}
          color={grey}
          width={150}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {dayjs(selectedDate).format('MMMM YYYY')}
        </Typography>
        <ArrowForwardIos
          onClick={setNextMonth}
          sx={{ cursor: 'pointer', color: grey }}
        />
      </Grid>
    );
  }
);
