import { PlayCircle } from '@mui/icons-material';
import { ADAPTATION_PROCESS_HELPER_LINKS } from 'entities/adaptation/links';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useViewModal } from 'shared/hooks/useViewModal';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { DialogPreparatoryRunAdaptationProcessForm } from './DialogPreparatoryRunAdaptationProcessForm';

export const PreparatoryRunAdaptationProcessAction: FC<{
  employeeGuid: string;
  employeeName: string;
}> = ({ employeeGuid, employeeName }) => {
  const navigate = useNavigate();
  const {
    open: openDialogPreparatoryRunAdaptationProcessForm,
    close: closeDialogPreparatoryRunAdaptationProcessForm,
    isOpen: isOpenDialogPreparatoryRunAdaptationProcessForm,
  } = useViewModal();

  return (
    <>
      <UiButtonAction
        text='Запустить процесс адаптации'
        icon={<PlayCircle />}
        onClick={openDialogPreparatoryRunAdaptationProcessForm}
      />
      <DialogPreparatoryRunAdaptationProcessForm
        isOpen={isOpenDialogPreparatoryRunAdaptationProcessForm}
        onClose={closeDialogPreparatoryRunAdaptationProcessForm}
        employeeGuid={employeeGuid}
        employeeName={employeeName}
        onSubmit={(form) => {
          const { mentor, adaptation, skipStages, countdownDate } = form;

          if (!mentor || !adaptation) return null;

          navigate(
            ADAPTATION_PROCESS_HELPER_LINKS.createLinkRunningProcess({
              NewEmployeeGuid: employeeGuid,
              MentorGuid: mentor.guid,
              AdaptationGuid: adaptation.guid,
              SkipStage: skipStages.map((item) => item.guid),
              countdownDate: countdownDate ? countdownDate.format() : undefined,
            })
          );
        }}
      />
    </>
  );
};
