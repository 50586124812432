import { useChangeTaskStatusModal } from 'features/task/hooks/useChangeTaskStatusModal';
import { useConvertTaskFilter } from 'features/task/hooks/useConvertTaskFilter';
import { TaskDrawer, useTaskDrawer } from 'features/task/task-drawer';
import { useCallback, useMemo } from 'react';

import { GetListTask } from 'shared/api/services/types';
import { useDevice } from 'shared/utils/hook-type-device';

import { TaskTableActions } from 'widgets/tasks/task-table/actions/TaskTableActions';

import { AddTaskToBoardDialog } from 'features/boards/dialogs/AddTaskToBoardDialog';
import { AddTaskToBoardFormFields } from 'features/boards/forms/AddTaskToBoardForm';
import { useDeleteTask } from 'features/task/hooks/useDeleteTask';
import {
  INIT_TASK_PAGINATION,
  useTaskFilterModal,
} from 'features/task/hooks/useTaskFilterModal';
import {
  MemoizedManagerBarTasks,
  MemoizedTaskTable,
} from 'features/task/memoComponents';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useMoveTaskToAnotherBoardMutation } from 'shared/api/services/rtk/boardApi';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { useTableCellsFromLocalStorage } from 'shared/ui/table/libs/useTableCellsFromLocalStorage';
import { Tables } from 'shared/utils/controllers';
import { useMyTasks } from './contexts/my-tasks-context/MyTaskContext';
import { taskFilterToOrder } from './libs/orderConvFun';

export const MyTasks = () => {
  const { isLoading } = useConvertTaskFilter();

  if (isLoading) return <CustomBackdrop isLoading />;
  else return <MyTasksPage />;
};

const MyTasksPage = () => {
  const [isMobile] = useDevice();

  /* Delete task */
  const deleteTask = useDeleteTask();
  /* ===== */

  /* Table fields */
  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<GetListTask>(Tables.MY_TASKS);
  /* ===== */

  /* Tasks */
  const {
    isLoading,
    isFetching,
    tasks,
    changeLimit,
    changePage,
    changeOrder,
    count,
    filter,
    setFilter,
    changeSearch,
  } = useMyTasks();
  /* ===== */

  /* Changing statuses */
  const { modal: changeTaskStatusModal, open: openStatusChangeModal } =
    useChangeTaskStatusModal();
  /* ===== */

  /* Task Drawer */
  const { isOpenDrawer, selectedTaskGuid, closeDrawer, tableClickHandler } =
    useTaskDrawer();
  /* ===== */

  /* Filter modal */
  const { taskFilterModal, open: openTaskFilterDialog } = useTaskFilterModal(
    'forMyTasks',
    filter,
    setFilter
  );
  /* ===== */

  /* ===== */
  const {
    close: closeMove,
    isOpen: isOpenMove,
    initValue: moveTaskGuid,
    open: openMove,
  } = useViewModalWithInitFields<string>();
  const [moveTask, { isLoading: isMovingTask, error }] =
    useMoveTaskToAnotherBoardMutation();

  useShowFetchErrorInSnackbar(error);

  const handleSubmitMove = useCallback(
    (form: AddTaskToBoardFormFields) => {
      if (moveTaskGuid)
        moveTask({
          tasksGuids: [moveTaskGuid],
          boardColumnGuid: form.columnGuid,
        }).then(closeMove);
    },
    [moveTaskGuid]
  );
  /* ===== */

  const order = useMemo(() => {
    return taskFilterToOrder(filter);
  }, [filter]);

  const renderActions = useCallback((task: GetListTask) => {
    return (
      <TaskTableActions
        onChangeStatus={() => {
          openStatusChangeModal(task);
        }}
        onDelete={deleteTask}
        onMoveToBoard={openMove}
        task={task}
      />
    );
  }, []);

  return (
    <>
      {changeTaskStatusModal}
      {taskFilterModal}
      <AddTaskToBoardDialog
        isOpen={isOpenMove}
        onClose={closeMove}
        isLoading={isMovingTask}
        onSubmit={handleSubmitMove}
      />
      <LayoutPage.Page>
        {!isMobile && (
          <TaskDrawer
            isOpen={isOpenDrawer}
            onClose={closeDrawer}
            taskGuid={selectedTaskGuid}
          />
        )}
        <LayoutPage.Bar>
          <MemoizedManagerBarTasks
            openSettings={openTaskFilterDialog}
            onCompliteInput={changeSearch}
            initSearch={filter?.SearchText || ''}
          />
        </LayoutPage.Bar>
        <LayoutPage.Content>
          <MemoizedTaskTable
            fields={fields}
            visibleFields={visibleFields}
            onChangeFields={setFields}
            data={tasks}
            isLoading={isLoading}
            isUpdate={isFetching}
            count={count}
            limit={filter?.Limit || INIT_TASK_PAGINATION.Limit}
            page={filter?.Page || INIT_TASK_PAGINATION.Page}
            onChangeLimit={changeLimit}
            onChangePage={changePage}
            order={order}
            onChangeOrder={changeOrder}
            onClick={tableClickHandler}
            renderActions={renderActions}
          />
        </LayoutPage.Content>
      </LayoutPage.Page>
    </>
  );
};
