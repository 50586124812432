import { useCallback, useState } from 'react';

interface useSearchStateHendlerWithFilterParams<T, KeySearch, KeyPage> {
  filter: T;
  setFilter: (filter: T) => void;
  /** ключь для текстового поля фильтра */
  keySearch: KeySearch;
  /**  ключь для сброса текущей страницы на 1 */
  keyPage: KeyPage;
}

export const useSearchStateHendlerWithFilter = <
  T,
  KeySearch extends keyof T,
  KeyPage extends keyof T,
>(
  params: useSearchStateHendlerWithFilterParams<T, KeySearch, KeyPage>
) => {
  const { filter, setFilter, keyPage, keySearch } = params;
  const [searchInput, setSearchInput] = useState(filter[keySearch] || '');
  const handleChangeSearchText = useCallback(
    (search: string) => {
      setSearchInput(search);
    },
    [setSearchInput]
  );

  const handleCompliteChangeSearchText = useCallback(
    (SearchText: string) => {
      setFilter({ ...filter, [keyPage]: 1, [keySearch]: SearchText });
    },
    [setFilter, filter, keySearch, keyPage]
  );

  return {
    searchInput,
    handleChangeSearchText,
    handleCompliteChangeSearchText,
  };
};
