import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useGetAllEmployeesQuery } from 'shared/api/services/rtk/optionApi';
import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import { CustomAutocomplete, CustomTextField } from 'shared/ui/base-ui';
import { FormLayout } from 'shared/ui/form';
import { getOptionLabel } from './libs/getOptionLabel';
import { isOptionEqualToValue } from './libs/isOptionEqualToValue';
import {
  AddEmployeeToBoardFormFields,
  AddEmployeeToBoardFormProps,
} from './type';

export const AddEmployeeToBoardForm: FC<AddEmployeeToBoardFormProps> = ({
  onSubmit,
  renderFormActions,
  initForm,
  creator,
}) => {
  const { data: employees, isLoading } = useGetAllEmployeesQuery();
  const employeesOptions = useMemo(() => {
    if (!employees) return [];
    if (!creator) return employees;

    return employees.filter((em) => em.guid !== creator.guid);
  }, [employees, creator]);

  const { handleSubmit, control } = useForm<AddEmployeeToBoardFormFields>({
    defaultValues: initForm,
  });

  return (
    <FormLayout
      onSubmit={handleSubmit(onSubmit)}
      actions={renderFormActions(handleSubmit)}
    >
      {creator && (
        <CustomTextField
          value={getEmployeTitle({
            employeeName: creator.name,
            employeePosition: creator.position?.title,
          })}
          label='Администратор'
          InputProps={{ readOnly: true }}
        />
      )}
      <Controller
        control={control}
        name='employees'
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Участники',
              placeholder: 'Выберите участников',
            }}
            multiple
            loading={isLoading}
            onChange={(_, employee) => {
              onChange(employee);
            }}
            isOptionEqualToValue={isOptionEqualToValue}
            value={value}
            getOptionLabel={getOptionLabel}
            options={employeesOptions}
            renderOption={(props, option) => (
              <Typography
                {...props}
                key={option.guid}
              >
                {getOptionLabel(option)}
              </Typography>
            )}
          />
        )}
      />
    </FormLayout>
  );
};
