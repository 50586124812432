import { CoreSystemEnumsObjectActions } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { NotificationCreateFormValue, RecipientsFromView } from './types';

export const FORM_ID = 'created_notification';

export const VARIANT_RECIPIENTS_FROM: RecipientsFromView[] = [
  {
    title: 'Темы',
    value: 'topics',
  },
  {
    title: 'Файл',
    value: 'file',
  },
];
export const SX_DISABLED_MARGIN = { marginTop: 0, marginBottom: 0 };
export const DEFAULT_NOTIFICATION_CREATE_FORM_VALUE: NotificationCreateFormValue =
  {
    distributionTitle: '',
    message: '',
    url: '',
    topics: [],
    isSendingNow: false,
    recipientsFrom: 'topics',
    imageAction: CoreSystemEnumsObjectActions.None,
    imageData: null,
    imageUrl: null,
    fileAction: CoreSystemEnumsObjectActions.None,
    dateTimeSending: null,
    fileData: null,
    fileUrl: null,
    notificationType: null,
    notificationTypeValue: '',
    isSaveForUser: false,
  };
