import { FC, memo } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { DocumentList } from './DocumentList';
import { DocumentsTable } from './DocumentsTable';
import { DocumentTableProps } from './type';

export const AdeptiveDocumentTable: FC<DocumentTableProps> = memo((props) => {
  const [isMobile] = useDevice();

  if (isMobile) return <DocumentList {...props} />;
  else return <DocumentsTable {...props} />;
});
