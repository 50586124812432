import { Typography } from '@mui/material';
import {
  getOptionLabelPositionFn,
  groupByPositionFn,
  isOptionEqualToValuePositionFn,
  renderOptionPositionFn,
} from './types';

const getOptionLabelPositions: getOptionLabelPositionFn = (option) => {
  return `${option.title}`;
};

const renderOptionPosition: renderOptionPositionFn = (props, option) => {
  return (
    <Typography
      {...props}
      key={option.guid}
    >
      {option.title}{' '}
    </Typography>
  );
};

const isOptionEqualToValuePosition: isOptionEqualToValuePositionFn = (
  option,
  value
) => option.guid === value.guid;

const groupByPosition: groupByPositionFn = (option) => option.category.title;

export const HELPER_RENDER_PROPS_AUTOCOMPLITE_POSITIONS = {
  groupBy: groupByPosition,
  isOptionEqualToValue: isOptionEqualToValuePosition,
  renderOption: renderOptionPosition,
  getOptionLabel: getOptionLabelPositions,
};
