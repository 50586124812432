import { FC } from 'react';

import {
  UiTableCardList,
  UiTableCardPagination,
  UiTableCardWithContextMenu,
} from 'shared/ui/table';

import { DocumentTableProps } from './type';

export const DocumentList: FC<DocumentTableProps> = ({
  count,
  data,
  fields,
  limit,
  onChangeFields,
  onChangeLimit,
  onChangePage,
  page,
  visibleFields,
  isLoading = false,
  onClick,
  renderActionsContextMenu,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading}
      pagination={
        <UiTableCardPagination
          count={count}
          onPageChange={onChangePage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowsPerPageChange={onChangeLimit}
        />
      }
    >
      {data.map((document) => {
        return (
          <UiTableCardWithContextMenu
            key={document.id}
            ActionsContextMenu={renderActionsContextMenu(document)}
            data={document}
            paperProps={{
              onClick: () => {
                onClick?.(document);
              },
            }}
            visibleFields={visibleFields.filter(
              (el) => el.field !== 'agreeProcess' && el.field !== 'commentCount'
            )}
          />
        );
      })}
    </UiTableCardList>
  );
};
