import { FC } from 'react';
import { UiTableCard, UiTableCardList } from 'shared/ui/table';

import { alpha, Chip } from '@mui/material';

import { MarketingSurfaceActions } from './actions';
import { MarketingSurfaceTableProps } from './types';

export const MarketingSurfaceList: FC<MarketingSurfaceTableProps> = ({
  data,
  fields,
  onChangeFields,
  visibleFields,
  isLoading,
  isUpdate,
  onClick,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading || isUpdate}
    >
      {data.map((surface) => (
        <UiTableCard
          key={surface.guid || Math.random()}
          data={surface}
          id={surface.guid || Math.random().toString()}
          renderHeaderChildren={() => {
            if (surface.guid) return null;

            return (
              <Chip
                sx={(theme) => ({
                  backgroundColor: alpha(theme.palette.error.main, 0.3),
                  marginLeft: 1,
                })}
                label={'Необходимо заполнить'}
              />
            );
          }}
          visibleFields={visibleFields}
          paperProps={{
            sx: (theme) => ({
              ...(surface.surfaceUpdateDaysExpired && {
                border: `solid 2px ${alpha(theme.palette.error.main, 0.4)}`,
              }),
            }),
          }}
          onClick={() => {
            onClick?.(surface);
          }}
          renderActions={() => <MarketingSurfaceActions surface={surface} />}
        />
      ))}
    </UiTableCardList>
  );
};
