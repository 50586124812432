import { useCallback, useMemo } from 'react';
import {
  useDeleteCloseDocumentFileActionsMutation,
  useGetDocumentByIdQuery,
  usePostCloseDocumentFileActionsMutation,
  usePostDoActionsMutation,
} from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';

import { SubmitHandler } from 'react-hook-form';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { compliteActiconCloseDocument } from '../deprecated-effector';
import { DEFAULT_VALUE } from './constants';
import { preparaDocumentToCloseDocumentFormValue } from './libs/preparaDocumentToCloseDocumentFormValue';
import { prepareFormValueToRequestBodyActionCloseDocument } from './libs/prepareFormValueToRequestBodyActionCloseDocument';
import { FormCloseDocumentValue } from './types';

export const useDialogFormCloseDocument = (params: { documentId: number }) => {
  const { documentId } = params;

  const { open: openSnackBar } = useSnackbar();

  const {
    isOpen: isOpenDialogCloseDocument,
    open: onOpenDialogCloseDocument,
    close: onCloseDialogCloseDocument,
  } = useViewModal();

  const [postDocumentDoAction, { isLoading: isLoadingPostDocumentDoAction }] =
    usePostDoActionsMutation();

  const [postCloseDocumentFile, { isLoading: isLodingPostCloseDocumentFile }] =
    usePostCloseDocumentFileActionsMutation();

  const [
    deleteCloseDocumentFile,
    { isLoading: isLodingDeleteCloseDocumentFile },
  ] = useDeleteCloseDocumentFileActionsMutation();

  const { data: document, isLoading: isLoadingDocument } =
    useGetDocumentByIdQuery(
      { documentId: params.documentId },
      { skip: !isOpenDialogCloseDocument }
    );

  const initialValueFormCloseDocument: FormCloseDocumentValue = useMemo(() => {
    if (!document) return DEFAULT_VALUE;

    return preparaDocumentToCloseDocumentFormValue(document);
  }, [document]);

  const onSubmitCloseDocumentForm: SubmitHandler<FormCloseDocumentValue> =
    useCallback(
      async (form) => {
        let isErrorDelOrPostFile = false;

        /* post files */
        !form.isLink &&
          (await Promise.all(
            form.addedFiles.map(async (file) => {
              return postCloseDocumentFile({
                params: {
                  documentId,
                  requestBody: {
                    formFile: file.formFile as unknown as string,
                  },
                },
              }).then((response) => {
                if ('error' in response) {
                  isErrorDelOrPostFile = true;
                  openSnackBar({
                    text: getMessageFromFetchBaseQueryError(response.error),
                    type: 'error',
                    duration: 3000,
                  });
                }

                return response;
              });
            })
          ));
        /* delete Files */
        !form.isLink &&
          (await Promise.all(
            form.filesDeleteFromBackend.map((file) => {
              return deleteCloseDocumentFile({
                params: {
                  documentId,
                  fileId: file.file.id,
                },
              }).then((response) => {
                if ('error' in response) {
                  isErrorDelOrPostFile = true;
                  openSnackBar({
                    text: getMessageFromFetchBaseQueryError(response.error),
                    type: 'error',
                    duration: 3000,
                  });
                }

                return response;
              });
            })
          ));

        /* отправка формы */
        !isErrorDelOrPostFile &&
          (await postDocumentDoAction({
            params: {
              documentId,
              requestBody: {
                actionTaskId: 5,
                closeDocumentRequest:
                  prepareFormValueToRequestBodyActionCloseDocument(form),
              },
            },
          }).then((response) => {
            if ('error' in response) {
              openSnackBar({
                text: getMessageFromFetchBaseQueryError(response.error),
                type: 'error',
                duration: 3000,
              });
            } else {
              openSnackBar({
                text: 'Закрывающий документ  - успешно прикреплен',
                type: 'success',
                duration: 3000,
              });
              onCloseDialogCloseDocument();
              compliteActiconCloseDocument({ params: { documentId } });
            }
          }));
      },
      [documentId]
    );

  return {
    isOpenDialogCloseDocument,
    onOpenDialogCloseDocument,
    onCloseDialogCloseDocument,
    initialValueFormCloseDocument,
    loadingInitialValue: isLoadingDocument,
    isLodingDeleteCloseDocumentFile,
    isLoadingPostDocumentDoAction,
    isLodingPostCloseDocumentFile,
    onSubmitCloseDocumentForm,
  };
};
