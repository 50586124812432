import {
  FilterItemType,
  ObserveFilterItemModel,
  ObserveFilterModel,
} from 'shared/api/services-document-flow/types';
import {
  ObserveFilterItemModelWithMyKeys,
  ObserveFilterModelWithKeys,
} from '../type';

type KeysOfFilterItemTypeResult = Extract<
  keyof ObserveFilterItemModel,
  | 'parentDepartments'
  | 'parentOrgDepartments'
  | 'parentOrgDirections'
  | 'parentOrgOffices'
  | 'parentOrgPositions'
  | 'parentRegions'
>;

const getFilterItemTypeByKeyParent = (
  key: KeysOfFilterItemTypeResult
): FilterItemType => {
  switch (key) {
    case 'parentOrgDirections': // 0 - направление - parentOrgDirections;
      return FilterItemType[0];

    case 'parentOrgDepartments': // 1 - служба - parentOrgDepartments
      return FilterItemType[1];

    case 'parentOrgOffices': // 2 - АУП - parentOrgOffices
      return FilterItemType[2];

    case 'parentOrgPositions': // 3 - должность - parentOrgPositions
      return FilterItemType[3];

    case 'parentRegions': // 4 - регион - parentRegions
      return FilterItemType[4];

    case 'parentDepartments': //  5 - аптечная сеть - parentDepartments
      return FilterItemType[5];
  }
};

const createdUniquemKeyByFilterItemTypeAndId = (
  type: FilterItemType,
  id: number
): string => {
  switch (Number(type)) {
    case 0: // 0 - направление - parentOrgDirections;
      return `orgDirections_${id}`;

    case 1: // 1 - служба - parentOrgDepartments
      return `orgDepartments_${id}`;

    case 2: // 2 - АУП - parentOrgOffices
      return `orgOffices_${id}`;

    case 3: // 3 - должность - parentOrgPositions
      return `orgPositions_${id}`;

    case 4: // 4 - регион - parentRegions
      return `regions_${id}`;

    case 5: //  5 - аптечная сеть - parentDepartments
      return `departments_${id}`;
    default:
      return `not_kategory_${id}`;
  }
};

const createdUniquemKeyByFilterItemTypeAndIdOnKeyParentsIds = (
  ids: number[],
  key: KeysOfFilterItemTypeResult
) =>
  ids.map((id) =>
    createdUniquemKeyByFilterItemTypeAndId(
      getFilterItemTypeByKeyParent(key),
      id
    )
  );

const ctreateParentMyKeys = (item: ObserveFilterItemModel) => {
  return [
    ...createdUniquemKeyByFilterItemTypeAndIdOnKeyParentsIds(
      item.parentDepartments,
      'parentDepartments'
    ),
    ...createdUniquemKeyByFilterItemTypeAndIdOnKeyParentsIds(
      item.parentOrgDepartments,
      'parentOrgDepartments'
    ),
    ...createdUniquemKeyByFilterItemTypeAndIdOnKeyParentsIds(
      item.parentOrgDirections,
      'parentOrgDirections'
    ),
    ...createdUniquemKeyByFilterItemTypeAndIdOnKeyParentsIds(
      item.parentOrgOffices,
      'parentOrgOffices'
    ),
    ...createdUniquemKeyByFilterItemTypeAndIdOnKeyParentsIds(
      item.parentOrgPositions,
      'parentOrgPositions'
    ),
    ...createdUniquemKeyByFilterItemTypeAndIdOnKeyParentsIds(
      item.parentRegions,
      'parentRegions'
    ),
  ];
};

export const prepareObserveFilterItemModalWithMyKeys = (
  item: ObserveFilterItemModel
): ObserveFilterItemModelWithMyKeys => {
  return {
    ...item,
    myKey: createdUniquemKeyByFilterItemTypeAndId(item.type, item.id),
    parentMyKeys: ctreateParentMyKeys(item),
  };
};

export const prepareObserveFilterModalWithMyKeys = (
  filterModel: ObserveFilterModel
): ObserveFilterModelWithKeys => {
  const newFilter = {
    ...filterModel,
  };
  const keys = Object.keys(newFilter) as (keyof ObserveFilterModel)[];

  keys.forEach((key) => {
    newFilter[key] = newFilter[key].map((item) => {
      return prepareObserveFilterItemModalWithMyKeys(item);
    });
  });

  return newFilter as ObserveFilterModelWithKeys;
};
