import { Add } from '@mui/icons-material';
import { Button, FormControlLabel, Switch } from '@mui/material';
import { FC, useState } from 'react';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import { BoardListSearchBarProps } from './type';

export const BoardListSearchBar: FC<BoardListSearchBarProps> = ({
  isArchiveChecked,
  onSearchApply,
  onToggleIsArchive,
  onPressCreate,
}) => {
  const [search, setSearch] = useState('');

  const clearSearch = () => {
    setSearch('');
  };

  return (
    <UiSearchBar
      textValue={search}
      changeValue={setSearch}
      onClear={clearSearch}
      onCompliteInput={onSearchApply}
    >
      <FormControlLabel
        control={
          <Switch
            checked={isArchiveChecked}
            onClick={onToggleIsArchive}
          />
        }
        label='Архив'
      />

      <Button
        variant='contained'
        startIcon={<Add />}
        onClick={onPressCreate}
      >
        Добавить доску
      </Button>
    </UiSearchBar>
  );
};
