import { Button, Skeleton } from '@mui/material';

import { SliderItemUi } from './SliderItemUi';

export const SliderItemSkeleton = () => {
  return (
    <SliderItemUi
      Actions={
        <>
          <Button
            disabled
            variant='contained'
            size='small'
            fullWidth
          >
            Изменить
          </Button>
          <Button
            variant='contained'
            color='customGrey'
            size='small'
            fullWidth
            disabled
          >
            Удалить
          </Button>
        </>
      }
      AfterPeriod={<Skeleton sx={{ flex: 1, height: '1.6em' }}></Skeleton>}
    >
      <Skeleton
        variant='rounded'
        style={{ width: '100%', height: '100%', borderRadius: 10 }}
      />
    </SliderItemUi>
  );
};
