import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useDeleteOpeningTemplatesMutation } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useConfirmDeleteOpeningTemplate = (templateGuid?: string) => {
  const [deleteTemplates, { isLoading: isLoadingDeleteTemplate }] =
    useDeleteOpeningTemplatesMutation();
  const { open: openSnackBar } = useSnackbar();
  const {
    isOpen: isOpenConfirmDialog,
    close: onCancelConfirmation,
    open: onOpenDialogConfirm,
  } = useViewModal();

  const onConfirmDelete = useCallback(async () => {
    if (templateGuid) {
      await deleteTemplates({
        guids: {
          openingTemplateGuids: [templateGuid],
        },
      }).then((response) => {
        if ('error' in response) {
          openSnackBar({
            text: getMessageFromFetchBaseQueryError(response.error),
            type: 'error',
            duration: 3000,
          });
        } else {
          openSnackBar({
            text: 'Шаблон успешно удален',
            type: 'success',
            duration: 3000,
          });
          close();
        }
      });
    }
  }, []);

  return {
    onCancelConfirmation,
    onConfirmDelete,
    onOpenDialogConfirm,
    isOpenConfirmDialog,
    isLoadingDeleteTemplate,
  };
};
