import {
  AxiosRequestConfigForSecurityWorker,
  BASE_API_URL,
} from '../constants';
import { Api } from './filesAutogenerateApi';

export const createApi = new Api<typeof AxiosRequestConfigForSecurityWorker>({
  baseUrl: BASE_API_URL,
  securityWorker(getT) {
    if (getT) {
      return getT();
    }
  },
});

createApi.setSecurityData(AxiosRequestConfigForSecurityWorker);

export const filesApi = createApi;
