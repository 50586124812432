import { Grid, Stack, Typography } from '@mui/material';

import { theme } from 'shared/theme/theme';

import { Link } from 'shared/ui/base-ui';
import { SOCIAL_LINKS } from './constants';

export const StackSocialLinks = () => {
  return (
    <Stack
      sx={{
        borderLeft: 'solid 1px',
        borderRight: 'solid 1px',
        borderBottom: 'solid 1px',
        borderRadius: '0px 0px 10px 10px ',
        borderColor: theme.palette.primary.main,
      }}
      paddingX={[2, 2]}
      paddingY={[2]}
      spacing={2}
    >
      {SOCIAL_LINKS.map((link) => {
        const { title, description, href, background, componentSvg } = link;

        return (
          <Grid
            container
            flexWrap={'nowrap'}
            key={description}
            gap={[2, 2]}
          >
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                borderStyle: 'solid',
                borderColor: 'transparent',
                background: background,
                width: [40, 55],
                minWidth: [40, 55],
                height: [40, 55],
                minHeight: [40, 55],
                backgroundClip: ' padding-box, border-box',
                backgroundOrigin: 'padding-box, border-box',
              }}
            >
              {componentSvg}
            </Grid>
            <Grid>
              <Link
                fontSize={[14, 20]}
                fontWeight={'bold'}
                target='_blank'
                to={href}
              >
                {title}
              </Link>
              <Typography>{description}</Typography>
            </Grid>
          </Grid>
        );
      })}
    </Stack>
  );
};
