import { SxProps, Theme } from '@mui/material';
import { theme } from 'shared/theme/theme';

export const CELL_SX = {
  borderLeft: '1px solid grey',
  borderRight: '1px solid grey',
  borderColor: theme.palette.customGrey.main,
  minHeight: 35,
};

export const DEPARTMENT_CELL_SX: SxProps<Theme> = {
  borderRight: CELL_SX.borderRight,
  borderColor: CELL_SX.borderColor,
  position: 'sticky',
  left: 0,
  background: 'white',
  zIndex: 2,
};

export const LEFT_STIKY_CELL_SX = {
  BorderLeft: CELL_SX.borderRight,
  borderColor: CELL_SX.borderColor,
  position: 'sticky',
  right: 0,
  background: 'white',
  zIndex: 2,
};

export const SIZE_CELL_SX = {
  height: '90px',
};
