import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Grid, Typography, TypographyTypeMap } from '@mui/material';
import { FC, ReactNode } from 'react';
import not_found_task from 'shared/img/not_task.png';
import { theme } from 'shared/theme/theme';
import { formatingDate } from 'shared/utils/formating-date';
import { DetailsUi } from './details-ui';

export const SX_TITLE = {
  padding: ['0px 16px 0px', 0],
  backgroundColor: [theme.palette.customGrey.main, 'white'],
  fontWeight: '700',
};
export const SX_BODY = {
  paddingTop: [2, 0],
  paddingLeft: [2, 0],
  paddingBottom: [1, 0],
};
export const DateFildAdaptive: FC<{ title: string; date?: string }> = ({
  title,
  date,
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      //lg={8}
      sx={{ flexDirection: ['column', 'row'] }}
    >
      <Typography
        flexBasis={['100%', '35%']}
        sx={SX_TITLE}
      >
        {title}
      </Typography>
      <Grid
        item
        container
        flexBasis={['100%', '30%']}
        alignItems={'center'}
        sx={SX_BODY}
      >
        <CalendarMonthIcon
          sx={{ marginBottom: [0, 1], marginRight: [1, 0] }}
          color={'secondary'}
        />
        <Typography>{date ? formatingDate(date) : '-'}</Typography>
      </Grid>
    </Grid>
  );
};
export const TextFieldAdaptive: FC<{
  title: string;
  body: string;
  propsTitle?: TypographyTypeMap<object, 'span'>;
}> = ({ title, body, propsTitle = {} }) => {
  return (
    <Grid
      item
      xs={12}
      display={'flex'}
      flexBasis={['100%', '50%']}
      sx={{ flexDirection: ['column', 'row'] }}
    >
      <Typography
        flexBasis={['100%', '35%']}
        sx={SX_TITLE}
        {...propsTitle.props}
      >
        {title}
      </Typography>
      <Typography sx={SX_BODY}>{body}</Typography>
    </Grid>
  );
};

export const CustomTitleSectionDetailsUi: FC<{ title: string }> = ({
  title,
}) => {
  return (
    <Typography
      fontWeight='bold'
      textAlign={'center'}
      sx={{
        marginTop: [0, 0],
        marginBottom: [2, 0],
        display: ['flex', 'none'],
        justifyContent: 'center',
        padding: ['0px 16px'],
      }}
    >
      {title}
    </Typography>
  );
};

export const RowFieldView: FC<{ children: ReactNode; title: string }> = ({
  children,
  title,
}) => {
  return (
    <Grid
      container
      item
      marginBottom={3}
      flexBasis={['100%', '50%']}
      flexDirection={['column', 'row']}
    >
      <Typography
        sx={{ flexBasis: '40%', ...SX_TITLE }}
      >{`${title} : `}</Typography>
      <Typography sx={{ ...SX_BODY }}>{children}</Typography>
    </Grid>
  );
};

export const DocumentNotFound = () => {
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent='center'
      flexDirection={['column', 'row']}
      sx={{
        color: theme.palette.primary.main,
        paddingTop: [3, 20],
        '& > img': {
          width: [320, 423],
          height: [330, 436],
          marginRight: [0, 4],
        },
      }}
    >
      <img src={not_found_task}></img>
      <Box padding={['8px']}>
        <Typography
          fontSize={[16, 20]}
          fontWeight={700}
        >
          Документ не найден
        </Typography>
        <Typography fontSize={[16, 20]}>
          Выбранный документ был удален или не существует
        </Typography>
      </Box>
    </Grid>
  );
};

export const LayoutSection: FC<{
  children: ReactNode;
  title: string;
  defaultOpen?: boolean;
}> = ({ title, children, defaultOpen = false }) => {
  return (
    <DetailsUi
      textSummary={title}
      defaultOpen={defaultOpen}
    >
      <Grid
        container
        flexDirection={['column', 'row']}
      >
        <CustomTitleSectionDetailsUi title={title} />

        {children}
      </Grid>
    </DetailsUi>
  );
};
