import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { useStoreMap } from 'effector-react';
import { FC } from 'react';

import { CustomDatePicker } from 'shared/ui/base-ui';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormListAddiitionallyDate = () => {
  const listAdditionalyDate = useStoreMap(
    documentFormModel.processAdditionalFields,
    (document) => (document && document.dateTimeAdditionallyFields) || []
  );

  return (
    <Grid
      container
      sx={{ '&> *:nth-of-type(1)': { marginRight: [0, 4] } }}
    >
      {listAdditionalyDate.map(({ id, title, isRequired }) => (
        <DocumentFormAddiitionallyDate
          key={id}
          id={id}
          label={title || ''}
          required={isRequired}
        />
      ))}
    </Grid>
  );
};

const DocumentFormAddiitionallyDate: FC<{
  id: number;
  label: string;
  required?: boolean;
}> = ({ id, label }) => {
  const value = useStoreMap(
    documentFormModel.form,
    (form) =>
      (form &&
        form.dateTimeAdditionallyFields?.find((item) => item.id === id)
          ?.value) ||
      null
  );

  return (
    <Grid sx={{ flexBasis: ['100%', '15%'], display: 'flex' }}>
      <CustomDatePicker
        format='DD.MM.YYYY'
        label={label}
        value={value ? dayjs(value) : null}
        onChange={(value) =>
          documentFormModel.formEvent.setAdditionallyFieldDate({
            id,
            value:
              value && value.isValid()
                ? value.format('YYYY-MM-DDTHH:mm:ss')
                : undefined,
          })
        }
        slotProps={{
          textField: {
            required: true,
            margin: 'none',
            inputProps: {
              placeholder: label,
            },
          },
        }}
      />
    </Grid>
  );
};
