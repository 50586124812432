import { Done } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { useChecklist } from 'entities/visit/checkList/context/ChecklistContext';

import { useStager } from '../hooks/useStager';
import { Stage, StageStatuses, STAGE_STATUSES } from '../type';
import { StagerSkeleton } from './StagerSkeleton';

export const VisitStager = () => {
  const { checkList, isLoading } = useChecklist();
  const stageData = useStager(checkList);

  if (isLoading && !checkList) return <StagerSkeleton />;

  return (
    <StagerList>
      {stageData
        .filter((el) => el.isView)
        .map((el, i) => (
          <StagerItem
            stageData={{ ...el, title: `${i + 1}. ${el.title}` }}
            key={el.route}
          />
        ))}
    </StagerList>
  );
};

interface StagerListProps {
  children: ReactNode;
}
export const StagerList: FC<StagerListProps> = ({ children }) => {
  return (
    <Paper>
      <List
        sx={{
          padding: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {children}
      </List>
    </Paper>
  );
};

interface StagerItemProps {
  stageData: Stage;
}
export const StagerItem: FC<StagerItemProps> = ({ stageData }) => {
  const navigate = useNavigate();

  const getIcon = () => {
    switch (stageData.compliteStatus) {
      case STAGE_STATUSES.COMPLITE:
        return <Done color='success' />;
      case STAGE_STATUSES.UNTOUCHED:
        return <></>;
    }
  };

  return (
    <ListItem
      sx={{
        padding: 0,
        cursor:
          stageData.status === StageStatuses.available ? 'pointer' : 'auto',
      }}
      onClick={() => {
        if (stageData.status === StageStatuses.available)
          navigate(stageData.route);
      }}
    >
      <ListItemIcon>{getIcon()}</ListItemIcon>
      <ListItemText>
        <Typography
          fontWeight={stageData.status === 'selected' ? 700 : 400}
          color={stageData.status === 'disable' ? '#D9D9D9' : 'black'}
        >
          {stageData.title}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};
