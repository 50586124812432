import { Controller } from 'react-hook-form';

import { CustomTimePicker } from 'shared/ui/base-ui';
import { TimePikerWithControllerComponent } from './types';

/**
 * @description связка Controller из react-hook-form и CustomDatePicker / CustomTextField / Autocomplete / Switch.
 * @see {@linkcode shared/ui/base-ui}
 */
export const TimePikerWithController: TimePikerWithControllerComponent = ({
  control,
  name,
  fullWidth,
  containerSx,
  ...propsDatePiker
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <CustomTimePicker
            value={value}
            onChange={onChange}
            slotProps={{
              textField: {
                size: 'small',
                error: Boolean(error),
                helperText: error?.message,
                fullWidth: fullWidth,
              },
            }}
            containerSx={
              fullWidth ? { ...containerSx, width: '100%' } : containerSx
            }
            {...propsDatePiker}
          />
        );
      }}
    />
  );
};
