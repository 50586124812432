// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { OrganizationModel } from 'shared/api/services-document-flow/types';

import { documentFormModel } from 'entities/document-form/model';

import { $options, fetchOrganizationsFx } from './model';
import { UiOrganizationsAutocomplite } from './ui';

export const DocumentFormOrgAutocomplite = () => {
  const handleCangeOrganizationId = (
    e: any,
    value: OrganizationModel | null
  ) => {
    documentFormModel.formEvent.setField({
      key: 'organizationId',
      value: value ? value.id : null,
    });
  };

  const id = documentFormModel.useValueDocumentFormModel('organizationId');
  const isOrganizationOnlyForMyOffice = documentFormModel.useValueProcess(
    'isOrganizationOnlyForMyOffice'
  );
  const processId = documentFormModel.useValueProcess('id');
  const options = useStore($options);

  useEffect(() => {
    if (processId) {
      fetchOrganizationsFx({
        isOrganizationOnlyForMyOffice: Boolean(isOrganizationOnlyForMyOffice),
        processId,
      });
    }
  }, [isOrganizationOnlyForMyOffice, processId]);

  useEffect(() => {
    if (options.length > 0 && id) {
      if (!options.find((item) => item.id === id)) {
        handleCangeOrganizationId(null, null);
      }
    }
  }, [options, id]);

  useEffect(() => {
    if (!id && options.length > 0) {
      const findDefaultState = options.find(
        (item) => item.isMainCurrentEmployment && item.isMainCurrentEmployment
      );

      handleCangeOrganizationId(null, findDefaultState || null);
    }
  }, [options]);

  return (
    <UiOrganizationsAutocomplite
      onChange={handleCangeOrganizationId}
      options={options}
      value={options.find((item) => item.id === id) || null}
    />
  );
};
