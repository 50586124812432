import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

export const PageByGuidNotification = () => {
  const { guid } = useParams();

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography>PageByGuidNotification</Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content>
        PageByGuidNotification (CONTENT)
        <Typography>{`guid: ${guid}`}</Typography>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
