import { Grid, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import inDevelopPng from 'shared/img/site-guide/inDevelop.png';
import { Routes } from 'shared/routes/constants';
import { useRouteContext } from 'shared/routes/context/useRouteContext';
import { theme } from 'shared/theme/theme';

export const SiteGuide = () => {
  const { routes, isLoading } = useRouteContext();

  return (
    <Grid
      container
      spacing={2}
      columns={24}
    >
      {isLoading &&
        new Array(7).fill(0).map((_, index) => (
          <Grid
            key={index}
            item
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={6}
          >
            <Skeleton
              sx={{ borderRadius: '10px' }}
              variant='rectangular'
              height={260}
            />
          </Grid>
        ))}
      {routes.map((rout) => {
        const { title, description, children, icon, path, isWork } = rout;

        if (!description) return null;

        return (
          <Grid
            key={path}
            item
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={6}
          >
            <CiteGuideCard
              title={title}
              description={description}
              childrenRouts={children}
              isWork={isWork}
              path={path}
              icon={icon}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const CiteGuideCard: FC<{
  title: string;
  description: string;
  childrenRouts: Routes[] | null;
  isWork: boolean;
  path: string;
  icon: string | null;
}> = ({ title, description, childrenRouts, isWork, path, icon }) => {
  return (
    <Grid
      sx={{
        width: '100%',
        borderRadius: '10px',
        border: `solid 1px ${theme.palette.primary.main}`,
        height: '100%',
        padding: { xs: 1, sm: 2 },
        paddingBottom: { xs: 1, sm: 2 },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 1,
      }}
    >
      {/* TITLE & DESCRIPTION */}
      <Grid>
        <Typography sx={{ fontSize: [16, 20], fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography fontSize={[14, 18]}>{description}</Typography>
      </Grid>
      {/* CONTENT */}
      <Grid
        container
        justifyContent={'space-between'}
        alignItems='center'
        flexWrap={'nowrap'}
        gap={1}
      >
        <Grid
          item
          flexBasis={'50%'}
        >
          {childrenRouts &&
            childrenRouts.map((child) => {
              const To = child.target
                ? child.fullPath
                : child.isWork
                ? child.fullPath
                : '#';

              return (
                <Link
                  key={path + '/' + child.path}
                  to={To}
                  target={child.target}
                >
                  <Typography
                    fontSize={[14, 16]}
                    sx={{
                      color: child.isWork
                        ? theme.palette.primary.light
                        : theme.palette.customGrey.main,
                      ':hover': {
                        textDecoration: child.isWork ? 'underline' : 'none',
                      },
                    }}
                    key={child.title}
                  >
                    {child.title}
                  </Typography>
                </Link>
              );
            })}
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: `${isWork ? 'end' : 'start'}`,
            '& > img': {
              objectFit: 'contain',
              heightL: 'auto',
              width: '100%',

              //width: [76, 'auto'],
            },
            /* minHeight: 140, */
          }}
        >
          {icon && <img src={isWork ? icon : inDevelopPng}></img>}
        </Grid>
      </Grid>
    </Grid>
  );
};
