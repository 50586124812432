import { FC } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTableRowWithActions,
} from 'shared/ui/table';

import { SubTaskTableProps } from './type';

export const SubTaskTable: FC<SubTaskTableProps> = ({
  data,
  fields,
  onChangeFields,
  renderActions,
  visibleFields,
  onClick,
}) => {
  return (
    <UiTable
      header={
        <UiTableHeader
          allCells={fields}
          viewCells={visibleFields}
          setViewCells={onChangeFields}
        />
      }
      body={data.map((task) => (
        <UiTableRowWithActions
          key={task.guid}
          onClick={() => {
            onClick && onClick(task);
          }}
          hover
          renderActions={(isOpen, setIsLoading) => {
            return renderActions(task, { isOpen, setIsLoading });
          }}
          sx={{
            cursor: 'pointer',
            backgroundColor: task.isTarget
              ? 'rgba(17, 110, 107, 0.3)'
              : undefined,
            '&:hover': {
              backgroundColor: task.isTarget
                ? 'rgba(17, 110, 107, 0.15) !important'
                : undefined,
            },
          }}
        >
          {visibleFields.map((el) => (
            <UiTableCell
              key={el.field + task.guid}
              {...el.getTableCellProps()}
            >
              {el.renderComponent(task) || 'Error'}
            </UiTableCell>
          ))}
        </UiTableRowWithActions>
      ))}
    />
  );
};
