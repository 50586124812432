import { Box, Modal, Paper, Stack, Typography } from '@mui/material';
import { useStore } from 'effector-react';
import { FC } from 'react';

import { switchColor } from 'shared/utils/switchColor';

import {
  $isOpenManual,
  closeManual,
} from 'widgets/main-layout-app/ui/manual-modal/model-manual';

import { MANUAL } from './constants';

export const ManualModal: FC = () => {
  const isOpen = useStore($isOpenManual);

  return (
    <Modal
      open={isOpen}
      onClose={closeManual}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Paper sx={{ maxWidth: '450px', padding: 3 }}>
        {isOpen && (
          <Stack spacing={2}>
            {MANUAL.status.map((status) => (
              <Box key={status.title}>
                <Typography
                  fontWeight={'bold'}
                  color={switchColor(status.title)}
                >
                  {status.title}
                </Typography>
                <Typography>{status.description}</Typography>
              </Box>
            ))}
          </Stack>
        )}
      </Paper>
    </Modal>
  );
};
