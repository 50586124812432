import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { AdaptationManagerResponsesGetAdaptation } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import {
  useLazyGetAdaptationTemplateQuery,
  useUpdateAdaptationTemplateMutation,
} from 'shared/api/service-adaptation/rtk/adaptationTemplatesProviderApi';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { getGuidsFromArray } from 'shared/tarnsform-helper/getGuidsFromArray';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { CreateAdaptationTemplateFormFields } from '../forms/CreateAdaptationTemplateForm';
import { EditAdaptationTemplateDialog } from '../forms/CreateAdaptationTemplateForm/EditAdaptationTemplateDialog';

export const useUpdateAdaptationTemplate = () => {
  const { open: openSnackbar } = useSnackbar();

  const { initValue, close, isOpen, open } =
    useViewModalWithInitFields<AdaptationManagerResponsesGetAdaptation>();

  const [getTemplate, { isLoading: isGettingTemplate }] =
    useLazyGetAdaptationTemplateQuery();

  const [updateTemplate, { isLoading: isUpdatingTemplate }] =
    useUpdateAdaptationTemplateMutation();

  const handleUpdateTemplate = useCallback(
    (form: CreateAdaptationTemplateFormFields, templateGuid: string) => {
      if (initValue) {
        updateTemplate({
          adaptationGuid: templateGuid,
          data: {
            isAutorun: form.isAutorun,
            title: form.title,
            eventGuids: getGuidsFromArray(form.events),
          },
        }).then((res) => {
          if ('error' in res) return;

          openSnackbar({
            duration: 3000,
            type: 'success',
            title: 'Шаблон изменён',
          });

          close();
        });
      }
    },
    [updateTemplate, close, openSnackbar, initValue]
  );

  const handleOpen = useCallback(
    (templateGuid: string) => {
      getTemplate(templateGuid).then((res) => {
        if ('error' in res && res.error)
          openSnackbar({
            duration: 5000,
            type: 'error',
            title: getMessageFromFetchBaseQueryError(res.error),
          });
        else if (!res.data)
          openSnackbar({
            duration: 5000,
            type: 'error',
            title: 'Что-то пошло не так',
            text: 'Не удалось загрузить данные для формы шаблона задачи',
          });
        else open(res.data);
      });
    },
    [getTemplate, open, openSnackbar]
  );

  return {
    CreateTemplateDialog: (
      <>
        {isGettingTemplate ? (
          <CustomBackdrop isLoading />
        ) : (
          <EditAdaptationTemplateDialog
            isOpen={isOpen}
            onClose={close}
            initValue={initValue}
            isLoading={isUpdatingTemplate}
            onSubmit={(form) => {
              if (initValue) handleUpdateTemplate(form, initValue.guid);
            }}
          />
        )}
      </>
    ),
    isUpdatingTemplate,
    openUpdateTemplate: handleOpen,
  };
};
