import {
  EmployeeModel,
  GetListDelegatedAction,
} from 'shared/api/services-document-flow/types';

import { array, mixed, object, ObjectSchema } from 'yup';
import { FormDocumentDelegateActionValue } from './types';

export const VALIDATE_SCHEME: ObjectSchema<FormDocumentDelegateActionValue> =
  object().shape({
    delegateActions: array(
      object()
        .shape({
          action: mixed<GetListDelegatedAction>().required().nullable(),
          employee: mixed<EmployeeModel>()
            .required()
            .nullable()
            .when('action', ([action], scheme) => {
              return scheme.test(
                'employee',
                'Выберите сотрудника',
                (employee) => {
                  if (!action) return true;
                  if (action && employee) return true;

                  return false;
                }
              );
            }),
        })
        .required()
    )
      .required()
      .test(
        'delegateActions',
        'Выбирите Действие и Сотрудника',
        (delegateActions) => {
          return (
            delegateActions.filter(({ action }) => Boolean(action)).length > 0
          );
        }
      ),
  });
