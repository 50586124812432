import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack } from '@mui/material';
import { useStageDraftConfirm } from 'features/visit/use-stage-draft-confirm/useStageDraftConfirm';
import { FC, useMemo } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';

import { CustomTextField } from 'shared/ui/custom-text-field';
import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';
import { InputFileUiUi } from 'shared/ui/file-input-ui-DEPRECETED/FileInputUi';
import { FileListItem } from 'shared/ui/file-list-item/FileListItem';
import { UiAddButton } from 'shared/ui/UiAddButton';
import { UiHeadBlock } from 'shared/ui/UiHeadBlock';
import { useDevice } from 'shared/utils/hook-type-device';

import { ConfirmStageDialog } from '../../ConfirmDialog/ConfirmStageDialog';
import { NavButtons } from '../../NavButtons';
import { INIT_PROBLEM } from '../constants';
import { downloadFile } from '../libs/downloadFile';
import { ProblemsFormSchema } from '../schems';
import { MyFile, ProblemsFormI, ProblemsFormProps } from '../type';

export const ProblemsForm: FC<ProblemsFormProps> = ({
  initValue,
  onSubmit,
  onComplite,
  canComplete,
  disableAll,
  onDraftSubmit,
  isReq,
}) => {
  /* Form */
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting },
    getValues,
  } = useForm<ProblemsFormI>({
    resolver: yupResolver(ProblemsFormSchema),
    values: initValue,
  });

  const problems = useWatch({ control, name: 'problems' });

  const hasProblem = useMemo(() => {
    return problems.some((el) => el.title !== '');
  }, [problems]);

  const { fields, append, remove } = useFieldArray({
    name: 'problems',
    control,
  });

  const formSubmit = async (data: ProblemsFormI) => {
    if (!isReq && !hasProblem && canComplete) {
      onComplite && (await onComplite());
    } else {
      await onSubmit(data);
    }
  };
  /* ==== */

  /* Draft */
  const {
    handleClose,
    handleConfirm,
    handleNext,
    onSubmit: onSub,
    isLoading: isDLoading,
    isDialogOpen,
  } = useStageDraftConfirm(isDirty, formSubmit, onDraftSubmit);
  /* ==== */

  /* Adept */
  const [isMob] = useDevice();
  /* ==== */

  return (
    <Grid
      container
      component={'form'}
      flexDirection={'column'}
      onSubmit={handleSubmit(onSub, () => {
        if (!isReq && !hasProblem && canComplete) onComplite && onComplite();
      })}
      gap={1}
    >
      <ConfirmStageDialog
        handleClose={handleClose}
        handleConfirm={() => {
          handleConfirm(getValues());
        }}
        handleNext={handleNext}
        isLoading={isDLoading}
        isOpen={isDialogOpen}
      />

      <DocumentSectionTitle
        text='Проблемы'
        sx={{ marginTop: 0, margin: 0 }}
      />

      {/* <TransitionGroup> */}
      {fields.map((el, index) => (
        // <Collapse key={el.id}>
        <Grid
          key={el.id}
          container
          gap={1}
          marginBottom={1}
        >
          <Grid
            container
            ml={[1, 0]}
          >
            <UiHeadBlock
              disable={fields.length === 1 || disableAll}
              onClick={() => remove(index)}
              title={`Проблема №${index + 1}`}
            />
          </Grid>

          <Controller
            name={`problems.${index}.title`}
            control={control}
            rules={{ required: isReq }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CustomTextField
                label='Проблема аптеки'
                propsTextField={{
                  disabled: disableAll,
                  error: !!error,
                  margin: 'none',
                  value,
                  onChange: onChange,
                  label: isMob ? '' : 'Проблема аптеки',
                  placeholder: 'Введите проблему',
                }}
              />
            )}
          />

          <Controller
            name={`problems.${index}.files`}
            control={control}
            render={({ field: { value, onChange } }) => {
              const toRender = [...value.oldFiles, ...value.newFiles];

              return (
                <Grid
                  container
                  flexDirection={
                    toRender.length === 0 ? 'row-reverse' : 'column'
                  }
                  justifyContent={'start'}
                  alignItems={toRender.length === 0 ? 'center' : 'flex-start'}
                  gap={1}
                >
                  <Stack
                    width={'100%'}
                    gap={1}
                  >
                    {value.oldFiles.map((file) => (
                      <FileListItem
                        key={file.name}
                        fileName={file.name}
                        disableDelete={disableAll}
                        onDelete={() => {
                          // Удаляем файл из формы
                          // Добавляем файл для удаления с сервака
                          const toDel = value.oldFiles.find(
                            (f) => f.name === file.name
                          ) as MyFile;

                          onChange({
                            ...value,
                            oldFiles: value.oldFiles.filter(
                              (f) => f.name !== file.name
                            ),
                            toDelete: [...value.toDelete, toDel.id],
                          });
                        }}
                        onDownload={() => {
                          if (file.id) downloadFile(file.id, file.name);
                        }}
                      />
                    ))}
                    {value.newFiles.map((file) => (
                      <FileListItem
                        key={file.name}
                        fileName={file.name}
                        disableDownload
                        disableDelete={disableAll}
                        onDelete={() => {
                          // Удялем файл из формы
                          // Удаляем из исписка на загрузку
                          onChange({
                            ...value,
                            newFiles: value.newFiles.filter(
                              (f) => f.name !== file.name
                            ),
                            toUpload: value.toUpload.filter((f) => {
                              const file2 = f as unknown as File;

                              return file2.name !== file.name;
                            }),
                          });
                        }}
                      />
                    ))}
                  </Stack>

                  <InputFileUiUi
                    disabled={disableAll}
                    id={String(index)}
                    onAddFile={(e) => {
                      const files = e.target.files;

                      if (files) {
                        // Достаём файлы из объекта
                        const newFiles: File[] = [];

                        for (let i = 0; i < files.length; i++) {
                          const file = files[i];

                          if (!toRender.some((el) => el.name === file.name)) {
                            newFiles.push(file);
                          }
                        }

                        // Добавляем файлы в форму
                        // Добаляем файля в список загрузок на сервер
                        if (newFiles.length > 0)
                          onChange({
                            ...value,
                            newFiles: [
                              ...value.newFiles,
                              ...newFiles.map((file) => ({
                                id: null,
                                name: file.name,
                              })),
                            ],
                            toUpload: [...value.toUpload, ...newFiles],
                          });
                      }
                    }}
                  />
                </Grid>
              );
            }}
          />
        </Grid>
        // </Collapse>
      ))}
      {/* </TransitionGroup> */}

      <UiAddButton
        disabled={disableAll}
        sx={{
          width: 'fit-content',
        }}
        onClick={() => {
          append(INIT_PROBLEM);
        }}
      >
        Добавить проблему
      </UiAddButton>

      <NavButtons
        disable={!isReq && !hasProblem && !canComplete}
        complite={!isReq && !hasProblem}
        isLoading={isSubmitting}
      />
    </Grid>
  );
};
