import { useDowloadFileBlob } from './hooks';
import { DownloadExcelFileFromListPropsWithFilter } from './types';
import { DownloadExcelFileFromList } from './ui';

export const DownloadExcelFileFromListWithHook: DownloadExcelFileFromListPropsWithFilter =
  ({ filter, useLazyDownloadFileFromList, createFileName }) => {
    const { handleDownloadFiles, isLoading } = useDowloadFileBlob({
      filter,
      useLazyDownloadFileFromList,
      createFileName,
    });

    return (
      <DownloadExcelFileFromList
        onClick={handleDownloadFiles}
        loading={isLoading}
      />
    );
  };
