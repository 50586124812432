import { MoreVert } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import { FC, useMemo } from 'react';

import dayjs from 'dayjs';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { IconWithMenu } from 'shared/ui/icon-with-menu';
import { getAvatarName } from 'shared/utils/getAvatarName';
import { getColorByGuid } from 'shared/utils/getColorByGuid';
import { AVATAR_SX } from './constants';
import { getAvatarStyle } from './libs/getAvatarStyle';
import { BoardListItemCardProps } from './type';

export const BoardListItemCard: FC<BoardListItemCardProps> = ({
  board,
  menuOptions,
  onClick,
}) => {
  const names = useMemo(() => {
    return [board.creator.name, ...board.employees.map((el) => el.name)].join(
      '  '
    );
  }, [board]);

  const cardClickHandler = () => {
    onClick?.(board.guid);
  };

  return (
    <Card>
      <CardActionArea
        component={'div'}
        onClick={cardClickHandler}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: getColorByGuid(board.creator.guid) }}>
              {getAvatarName(board.creator.name)}
            </Avatar>
          }
          action={
            <IconWithMenu
              icon={<MoreVert />}
              options={menuOptions}
            />
          }
          sx={{
            '& .MuiCardHeader-content': {
              display: 'block',
              overflow: 'hidden',
            },
          }}
          disableTypography
          title={
            <Tooltip title={board.title}>
              <Typography
                fontWeight={'bold'}
                fontSize={16}
                noWrap
                sx={{
                  wordBreak: 'break-all',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {board.title}
              </Typography>
            </Tooltip>
          }
          subheader={
            <Typography
              fontSize={14}
              color={'grey'}
            >
              {dayjs(board.dateCreated).format(DATE_FORMATS.client)}
            </Typography>
          }
        />
        <CardContent sx={{ pt: 0 }}>
          <Tooltip title={names}>
            <AvatarGroup
              max={5}
              slotProps={{ additionalAvatar: { sx: AVATAR_SX } }}
            >
              <Avatar sx={getAvatarStyle(board.creator.guid)}>
                {getAvatarName(board.creator.name)}
              </Avatar>

              {board.employees.map((employee) => (
                <Avatar
                  key={employee.guid}
                  sx={getAvatarStyle(employee.guid)}
                >
                  {getAvatarName(employee.name)}
                </Avatar>
              ))}
            </AvatarGroup>
          </Tooltip>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
