import { UserNoAdaptationRequiredItem } from 'shared/api/service-adaptation/normolize-data/transformResponseBackendUserNoAdaptationRequiredToViewType';
import { useGetBannedEmployeesQuery } from 'shared/api/service-adaptation/rtk/adaptationBanProviderApi';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import { Tables } from 'shared/utils/controllers';
import {
  renderActions,
  UserNoAdaptationRequiredAdaptiveTable,
} from 'widgets/adaptation/UserNoAdaptationRequiredTable';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { useFilterUserNoAdaptationsRequiredPage } from '../../features/adaptation/hooks/useFilterUserNoAdaptationsRequiredPage';

export const UserNotAdaptationRequiredPage = () => {
  const {
    allFilter,
    handleChangeSearchText,
    handleCompliteChangeSearchText,
    searchInput,
    handleChangePage,
    handleChangePageSize,
    pageSize,
    currentPage,
    handleChangeOrderBy,
    handleClearSearchText,
    order,
  } = useFilterUserNoAdaptationsRequiredPage();

  const { data, isFetching, isLoading } = useGetBannedEmployeesQuery(allFilter);

  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<UserNoAdaptationRequiredItem>(
      Tables.USER_NO_ADAPTATION_REQUIRED_TABLE
    );

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <UiSearchBar
          textValue={searchInput}
          changeValue={handleChangeSearchText}
          onClear={handleClearSearchText}
          onCompliteInput={handleCompliteChangeSearchText}
        />
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <UserNoAdaptationRequiredAdaptiveTable
          data={data?.items || EMPT_ARR}
          fields={fields}
          isUpdate={isFetching}
          isLoading={isLoading}
          visibleFields={visibleFields}
          onChangeFields={setFields}
          renderActions={renderActions}
          order={order}
          onChangeOrder={handleChangeOrderBy}
          count={data?.count || 0}
          page={currentPage}
          limit={pageSize}
          onChangeLimit={handleChangePageSize}
          onChangePage={handleChangePage}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
