// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

const PromptContext = createContext<{
  changeIsBlock: (is: boolean) => void;
  isBlock: boolean;
  value: ReturnType<typeof useBlocker> | null;
}>({
  isBlock: false,
  changeIsBlock: () => {},
  value: null,
});

export const PromptContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isBlock, setIsBlock] = useState(false);
  const blocker = useBlocker(isBlock);

  return (
    <PromptContext.Provider
      value={{ isBlock, changeIsBlock: setIsBlock, value: blocker }}
    >
      {children}
    </PromptContext.Provider>
  );
};

export const usePromPtContextProvider = () => useContext(PromptContext);
