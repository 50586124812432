import {
  DocumentArticleModel,
  DocumentArticleSecondValidateResponse,
  DocumentExtendedConditionSecondValidationResponse,
  EstimateDocumentArticleModel,
  RetailModel,
} from 'shared/api/services-document-flow/types';

import { ArticlesView } from './types';

export const prepareDocumentArticleSecondValidateResponseToArticlesView = (
  ariclesResponse?: DocumentArticleSecondValidateResponse[] | null
): ArticlesView[] | null => {
  if (!ariclesResponse) return null;

  return ariclesResponse.map((item) => {
    return {
      ...item,
      title: item.title || 'not_backend_title',
      articleId: item.id,
      expenses: item.expenses?.map((item) => {
        return { ...item, expenseExtendedCondition: item.extendedCondition };
      }),
      estimateDocumentArticle:
        item.estimateDocumentArticle as EstimateDocumentArticleModel,
      retail: item.retail as RetailModel,
    };
  });
};

export const prepareDocumentArticlesModelToArticlesView = (
  articles?: DocumentArticleModel[] | null
): ArticlesView[] | null => {
  if (!articles) return null;

  return articles.map((item) => {
    return {
      ...item,
      normSum: item.normSum as number,
      id: item.articleId,
      title: item.articleTitle || 'not_backend_title',
      expenses: item.documentArticleExpenses?.map((item) => {
        return {
          ...item,
          extendedCondition:
            item.extendedCondition as DocumentExtendedConditionSecondValidationResponse,
          title: item.title || 'not_backend_expenses_title',
          retail: item.retail as RetailModel,
          normSum: item.normSum as number,
        };
      }),
      retail: item.retail as RetailModel,
      estimateDocumentArticle:
        item.estimateDocumentArticle as EstimateDocumentArticleModel,
    };
  });
};
