import { ModalChangeStatusTask } from 'features/task/modal-change-status/ModalChangeStatus';
import { useMemo } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useUpdateTaskStatusMutation } from 'shared/api/services/rtk/taskApi';
import { GetListTask } from 'shared/api/services/types';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useChangeTaskStatusModal = () => {
  const { open: openSnackbar } = useSnackbar();
  const { close, initValue, isOpen, open } =
    useViewModalWithInitFields<GetListTask>();
  const [changeStatus, { isLoading, error }] = useUpdateTaskStatusMutation();

  useShowFetchErrorInSnackbar(error);

  const modal = useMemo(() => {
    return (
      <ModalChangeStatusTask
        isOpen={isOpen}
        onClose={close}
        task={initValue}
        isLoading={isLoading}
        onChange={(guid, args) => {
          changeStatus({ taskGuid: guid, status: args }).then((newTask) => {
            if (newTask === null) return;
            openSnackbar({
              duration: 3000,
              type: 'success',
              title: 'Статус обновлён',
            });
            close();
          });
        }}
      />
    );
  }, [isOpen, initValue, isLoading, close]);

  return { open, modal };
};
