import { useEffect, useState } from 'react';

export const useIsComliteLoadingImage = (ref: HTMLImageElement | null) => {
  const [isCompliteLoadImage, setIsCompliteLoadingImage] = useState(false);

  useEffect(() => {
    const changeLoading = () =>
      setIsCompliteLoadingImage(Boolean(ref?.complete));

    ref?.addEventListener('load', changeLoading);

    return () => ref?.removeEventListener('load', changeLoading);
  }, [ref, setIsCompliteLoadingImage]);

  return { isCompliteLoadImage };
};
