import {
  RetailOpeningProcessResponsesGetListProtocol,
  RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate,
  RetailOpeningProcessResponsesOpeningTemplateGetListStage,
} from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { array, mixed, object, ObjectSchema } from 'yup';
import { FormRunOpeningRetailProcessValue } from './types';

export const SCHEME_RUN_OPENING_PROCESS: ObjectSchema<FormRunOpeningRetailProcessValue> =
  object().shape({
    template:
      mixed<RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate>().required(
        'Обязательное поле'
      ),
    protocol:
      mixed<RetailOpeningProcessResponsesGetListProtocol>().required(
        'Обязательное поле'
      ),
    skipStages: array(
      mixed<RetailOpeningProcessResponsesOpeningTemplateGetListStage>().required(
        'Обязательное поле'
      )
    ).required('Обязательное поле'),
    date: SCHEMA.DATEJS.required('Обязательное поле'),
  });
