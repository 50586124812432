import { GetTasksMeControllerWebQueryParams } from 'shared/api/services/types';

import { CONVERT_WATCH_TASK_LIST_FILTER_MAP } from '../convertMaps';
import { convertURLToObject } from '../convertURLToObject';
import { URLToObjConverter } from '../type';

export const convertURLToWatchTaskList: URLToObjConverter<
  GetTasksMeControllerWebQueryParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_WATCH_TASK_LIST_FILTER_MAP
  ) as GetTasksMeControllerWebQueryParams;
};
