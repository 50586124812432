import { BoardDragDropContextFields } from './type';

export const BOARD_DRAG_DROP_CONTEXT_INIT: BoardDragDropContextFields = {
  selectedTasks: [],
  dragTask: null,
  unSelectAll: () => {
    //
  },
  selectTask: () => {
    //
  },
  unSelectTask: () => {
    //
  },
  dropHandler: () => {
    //
  },
  onDragStart: () => {
    //
  },
};
