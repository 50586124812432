import dayjs from 'dayjs';
import { useStoreMap } from 'effector-react';
import { FC, useEffect } from 'react';

import { CustomTextField } from 'shared/ui/custom-text-field';

import { documentFormModel } from 'entities/document-form';

export const ExpensesFormCountExpense: FC<{
  indexExpenses: number;
  idArticle: number;
}> = ({ indexExpenses, idArticle }) => {
  const count = documentFormModel.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'count'
  );
  const idTypeExpense = documentFormModel.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'id'
  );

  const isCountFromPeriod = useStoreMap({
    store: documentFormModel.firstValid,
    keys: [documentFormModel.firstValid, idTypeExpense],
    fn: (state) => {
      return (
        state?.documentArticles
          ?.find((state) => state.id === idArticle)
          ?.expenses?.find((expens) => expens.id === idTypeExpense)
          ?.isCountFromPeriod || false
      );
    },
  });

  const periodCount = useStoreMap({
    store: documentFormModel.form,
    keys: [documentFormModel.form, isCountFromPeriod],
    fn: (document) => {
      if (!isCountFromPeriod) return null;
      if (document && document.beginPeriod && document.endPeriod) {
        return dayjs(document.endPeriod).diff(
          dayjs(document.beginPeriod),
          'day',
          true
        );
      }

      return null;
    },
  });

  useEffect(() => {
    if (isCountFromPeriod && periodCount)
      documentFormModel.formEvent.setFieldExpense({
        id: idArticle,
        index: indexExpenses,
        fild: { key: 'count', value: periodCount },
      });
  }, [isCountFromPeriod, periodCount]);

  return (
    <CustomTextField
      propsTextField={{
        disabled: isCountFromPeriod,
        required: true,
        value: periodCount || count || '',
        onChange: (event) => {
          documentFormModel.formEvent.setFieldExpense({
            index: indexExpenses,
            id: idArticle,
            fild: { key: 'count', value: Number(event.target.value) },
          });
        },
        placeholder: 'Введите количество',
        type: 'number',
      }}
      sx={{
        flexBasis: ['100%', '45%'],
      }}
      label='Количество'
    />
  );
};
