import { VisitFilterFormFields } from './type';

export const VISIT_STATUSES = [
  {
    id: 1,
    title: 'Завершён',
    value: 2,
  },
  {
    id: 2,
    title: 'Не завершён',
    value: 1,
  },
];

export const INIT_VISIT_FILTER_FORM_VALUE: Partial<VisitFilterFormFields> = {
  departmentsIds: [],
  divisionsIds: [],
  regionsIds: [],
  retailsIds: [],
  visitorDepartmentsIds: [],
  visitorOfficesIds: [],
  visitorPositionsIds: [],
  visitorRegionsIds: [],
  visitorsIds: [],
};
