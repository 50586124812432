import { Button } from '@mui/material';

import { documentFormModel } from 'entities/document-form';

import { modal } from './model';

export const ExpensesFormModalButtonClose = () => {
  return (
    <Button
      size='large'
      variant='contained'
      color={'customGrey'}
      onClick={() => {
        /* refArticlesInput.current.focus(); */
        documentFormModel.formEvent.removeArticleidExpenseZero();
        modal.events.close();
      }}
    >
      Закрыть
    </Button>
  );
};
