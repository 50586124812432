// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { theme } from 'shared/theme/theme';

import { getMapIcon } from './getMapIcon';

// @ts-ignore
export const getClusterIcon = (cluster: MarkerCluster) => {
  return getMapIcon(
    <div
      style={{
        width: 30,
        height: 30,
        background: theme.palette.primary.main,
        opacity: 0.9,
        borderRadius: '50%',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
      }}
    >
      {cluster._childCount}
    </div>
  );
};
