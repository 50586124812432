import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { SwaggerResponse } from 'shared/api/services-document-flow/config';
import {
  deleteDocumentsDocumentIdDocumentCommentsCommentId,
  getDocumentsDocumentIdDocumentComments,
  postDocumentsDocumentIdDocumentComments,
} from 'shared/api/services-document-flow/services';
import {
  DocumentCommentCreateRequest,
  DocumentCommentResponse,
  ErrorResponse,
} from 'shared/api/services-document-flow/types';

export const getDocumentsDocumentIdDocumentCommentsFx = createEffect<
  typeof getDocumentsDocumentIdDocumentComments,
  AxiosError<ErrorResponse>
>(getDocumentsDocumentIdDocumentComments);

export const postDocumentsDocumentIdDocumentCommentsFx = createEffect<
  ParamsPost,
  Promise<SwaggerResponse<DocumentCommentResponse>>,
  AxiosError<ErrorResponse>
>(async (params) => {
  return postDocumentsDocumentIdDocumentComments(params.documentId, {
    comment: params.comment.comment,
    parentId: params.comment.parentId,
  });
});

export const deleteDocumentsDocumentIdDocumentCommentsCommentIdFx =
  createEffect<
    ParamsDelete,
    Promise<SwaggerResponse<DocumentCommentResponse>>,
    AxiosError<ErrorResponse>
  >(async (params) => {
    return deleteDocumentsDocumentIdDocumentCommentsCommentId(
      params.documentId,
      params.commentId
    );
  });
type ParamsDelete = { documentId: number; commentId: number };
type ParamsPost = { documentId: number; comment: DocumentCommentCreateRequest };
