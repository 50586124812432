import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { usePostMarketingSurfaceMutation } from 'shared/api/marketing-surface/rtk/marketingSurfaceProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { preparedFormValueToPostBody } from './prepared-helper';
import { FormMarketingSurfaceValue } from './types';

export const useDialogMarketingSurfaceFormCreate = (params: {
  retailGuid: string;
}) => {
  const { open: openSnackBar } = useSnackbar();
  const {
    open: onOpenDialogFormCreateMarketingSurface,
    isOpen: isOpenDialogFormCreateMarketingSurface,
    close: onCloseDialogFormCreateMarketingSurface,
  } = useViewModal();
  const [postMarketingSurface, { isLoading: isLoadingPostMarketingSurface }] =
    usePostMarketingSurfaceMutation();

  const onSubmitCreateMarketingSurface = useCallback(
    async (form: FormMarketingSurfaceValue) => {
      const { advertisingType, surfaceType, width, height } = form;

      if (!advertisingType || !surfaceType || !width || !height) {
        openSnackBar({
          title: 'Ошибка валидации',
          text: 'Не указаны обязательные поля',
          type: 'error',
          duration: 3000,
        });

        return;
      }

      const preparedData = preparedFormValueToPostBody(form);

      if (!preparedData) {
        return;
      }

      postMarketingSurface({
        data: {
          ...preparedData,
          retailGuid: params.retailGuid,
        },
      }).then((response) => {
        if ('error' in response) {
          openSnackBar({
            text: getMessageFromFetchBaseQueryError(response.error),
            type: 'error',
            duration: 3000,
          });
        } else {
          openSnackBar({
            title: 'Рекламная поверхность',
            text: 'Успешно добавлена!',
            type: 'success',
            duration: 3000,
          });
          onCloseDialogFormCreateMarketingSurface();
        }
      });
    },
    []
  );

  return {
    onOpenDialogFormCreateMarketingSurface,
    isOpenDialogFormCreateMarketingSurface,
    onCloseDialogFormCreateMarketingSurface,
    isLoadingPostMarketingSurface,
    onSubmitCreateMarketingSurface,
  };
};
