import { Grid } from '@mui/material';

import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { UiTableCard, UiTableCardList } from 'shared/ui/table';

import { GetListTask } from 'shared/api/services/types';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import { SubTaskTableProps } from './type';

export const SubTaskList: FC<SubTaskTableProps> = ({
  data,
  fields,
  onChangeFields,
  renderActions,
  visibleFields,
  onClick,
}) => {
  const statusField = fields.find(
    (el) => el.field === 'status'
  ) as FieldMapType<GetListTask>;

  return (
    <UiTableCardList
      stackContainerProps={{ sx: { px: 1 } }}
      fields={fields}
      onChangeFields={onChangeFields}
    >
      {data.map((task) => (
        <UiTableCard
          data={task}
          id={String(task.guid)}
          visibleFields={visibleFields.filter((el) => el.field !== 'status')}
          key={task.guid}
          renderHeaderChildren={() => {
            const comp = statusField.renderComponent(task);

            return <Grid pl={0.5}>{comp}</Grid>;
          }}
          onClick={() => {
            onClick && onClick(task);
          }}
          renderActions={(isOpen, setIsLoading) => {
            return renderActions(task, { isOpen, setIsLoading });
          }}
          paperProps={{
            sx: {
              border: task.isTarget
                ? `2px solid ${theme.palette.primary.main}`
                : undefined,
            },
          }}
        />
      ))}
    </UiTableCardList>
  );
};
