import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { DnDItem, DnDList } from 'shared/dnd';
import { TemplateStagesListProps } from './type';
import { useTemplateStagesList } from './useTemplateStagesList';

export const TemplateStagesList: FC<TemplateStagesListProps> = ({
  templateGuid,
  onSelectStage,
  selectedStageGuid,
}) => {
  const {
    handleCreateStage,
    handleDeleteStage,
    handleDrag,
    isLoading,
    isLoadingStages,
    stages,
  } = useTemplateStagesList(templateGuid);

  return (
    <Grid
      container
      flexDirection={'column'}
      gap={1}
    >
      <Typography
        fontWeight={'bold'}
        fontSize={20}
      >
        Этапы
      </Typography>

      {isLoadingStages && <CircularProgress />}

      <List dense>
        <DnDList
          onDragEnd={handleDrag}
          droppableId='LIST'
        >
          {stages.map((stage, index) => (
            <DnDItem
              key={stage.guid}
              index={index}
              draggableId={stage.guid}
              isDragDisabled={isLoading}
            >
              <ListItemButton
                selected={stage.guid === selectedStageGuid}
                onClick={() => onSelectStage(stage.guid)}
                sx={{ mr: 1 }}
              >
                <ListItemIcon>
                  <IconButton
                    disabled={isLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteStage(stage.guid);
                      if (selectedStageGuid === stage.guid) onSelectStage(null);
                    }}
                  >
                    <Delete color={isLoading ? 'disabled' : 'error'} />
                  </IconButton>
                </ListItemIcon>

                <ListItemText
                  primary={`${stage.stageNumber} Этап`}
                  primaryTypographyProps={{ fontWeight: '600' }}
                />
              </ListItemButton>
            </DnDItem>
          ))}
        </DnDList>
      </List>

      <LoadingButton
        loading={isLoading}
        variant='outlined'
        startIcon={<Add />}
        onClick={handleCreateStage}
      >
        Добавить этап
      </LoadingButton>
    </Grid>
  );
};
