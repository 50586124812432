import { CopyAll } from '@mui/icons-material';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import podborkaPng from './images/cart-notify.png';
import promocodePng from './images/promocode-notify.png';
import {
  PreviewSavedNotificationOnLkUserBaseUiProps,
  PreviewSavedNotificationOnLkUserProps,
} from './types';

const PreviewSavedNotificationOnLkUserBaseUi: FC<
  PreviewSavedNotificationOnLkUserBaseUiProps
> = ({ title, message, imageSrc, Button, backgroundColor }) => {
  const theme = useTheme();

  return (
    <Grid
      display={'flex'}
      alignItems={'center'}
      gap={1}
      sx={{
        backgroundColor: backgroundColor,
        borderColor: theme.palette.alBlue.main,
        borderRadius: 2,
        padding: 2,
      }}
    >
      <Grid
        sx={{ flexGrow: 2 }}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
      >
        <Typography fontWeight={'bold'}>{title}</Typography>
        {message && <Typography>{message}</Typography>}
        {Button}
      </Grid>
      <Grid>
        <img
          src={imageSrc}
          style={{
            objectFit: 'contain',
            display: 'flex',
            maxWidth: '100%',
            maxHeight: 90,
          }}
        />
      </Grid>
    </Grid>
  );
};

export const PreviewSavedNotificationOnLkUser: FC<
  PreviewSavedNotificationOnLkUserProps
> = ({ title, message, type, buttonValue }) => {
  const imageSrc = type === 'podborka' ? podborkaPng : promocodePng;

  const ButtonActions = useMemo(() => {
    if (type === 'podborka') {
      return (
        <Button
          variant='contained'
          color='alBlue'
          sx={{ width: 'fit-content' }}
        >
          {buttonValue}
        </Button>
      );
    } else {
      return (
        <Button
          variant='outlined'
          color='alBlue'
          sx={{ backgroundColor: 'white', width: 'fit-content' }}
          endIcon={<CopyAll />}
        >
          Промокод
        </Button>
      );
    }
  }, [type, buttonValue]);

  return (
    <PreviewSavedNotificationOnLkUserBaseUi
      title={title}
      message={message}
      imageSrc={imageSrc}
      Button={ButtonActions}
      backgroundColor={type === 'podborka' ? '#E4EFC7' : '#b2d6f0'}
    />
  );
};
