import { Problem } from './type';

export const INIT_PROBLEM: Problem = {
  id: 0,
  title: '',
  files: {
    newFiles: [],
    oldFiles: [],
    toDelete: [],
    toUpload: [],
  },
};
