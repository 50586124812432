import { MarketingSurfacePage } from 'pages/marketing-surface/MarketingSurfacePage';
import { RouteObject } from 'react-router-dom';
import { ALL_ROUTES } from './constants/types';

export const ROUTES_MARKETING_SURFACE: RouteObject[] = [
  {
    path: ALL_ROUTES.marketingSurface,
    element: <MarketingSurfacePage />,
  },
];
