import { createStore } from 'effector';

import { DocumentListItemResponse } from 'shared/api/services-document-flow/types';

import { getDocumentsActionFx } from '../api/document-list-effector';

export const $documentActionList = createStore<
  DocumentListItemResponse[] | null
>([], {
  name: '$documentControlList',
});
$documentActionList.on(
  getDocumentsActionFx.doneData,
  (state, response) => response.data
);
