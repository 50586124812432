export interface StagerProps {
  stages: Stage[];
}

export enum StageStatuses {
  available = 'available',
  selected = 'selected',
  disable = 'disable',
}

export enum STAGE_STATUSES {
  COMPLITE,
  UNTOUCHED,
}

export interface Stage {
  title: string;
  status: StageStatuses;
  route: string;
  isView: boolean;
  compliteStatus: STAGE_STATUSES;
}
