import { firebaseProviderApi } from './firebaseProviderApi';

export const {
  /* get */
  useGetFirebaseDistributionsQuery,
  useGetFirebaseDistributionsUserCountQuery,
  useGetFirebaseNotificationTypesQuery,
  useGetFirebaseTopicsQuery,
  useGetFirebaseDistributionBuyGuidQuery,
  /* lazy get */
  useLazyGetFirebaseDistributionsQuery,
  useLazyGetFirebaseDistributionsUserCountQuery,
  useLazyGetFirebaseNotificationTypesQuery,
  useLazyGetFirebaseTopicsQuery,
  useLazyGetFirebaseDistributionBuyGuidQuery,
  /* mutations */
  useDeleteFirebaseDistributionsMutation,
  usePostFirebaseDistributionTestMutation,
  usePostFirebaseDistributionMutation,
} = firebaseProviderApi;
