import { createStore } from 'effector';

import { DocumentListItemResponse } from 'shared/api/services-document-flow/types';

import { getDocumentsControlFx } from '../api/document-list-effector';

export type keys = keyof DocumentListItemResponse;
export const $documentControlList = createStore<
  DocumentListItemResponse[] | null
>([], {
  name: '$documentControlList',
});
$documentControlList.on(
  getDocumentsControlFx.doneData,
  (state, response) => response.data
);
