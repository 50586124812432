import { FC } from 'react';

import { DocumentExpenseSecondValidationRequest } from 'shared/api/services-document-flow/types';
import { UiExpenseTypeAutocomplite } from 'shared/ui/article/UiExpenseTypeAutocomplite';

import { documentFormModel } from 'entities/document-form';

export const ExpenseTypeAutocomplite: FC<{
  indexExpenses: number;
  idArticle: number;
  expenses: DocumentExpenseSecondValidationRequest[];
}> = ({ indexExpenses, idArticle, expenses }) => {
  const id = documentFormModel.useSelectArticleExpenseValue(
    idArticle,
    indexExpenses,
    'expenseId'
  );
  const options = documentFormModel.useSelectorArticleIdFirstValid(
    idArticle,
    'expenses'
  );
  const value = (options && options.find((item) => item.id === id)) || null;
  const selectedExpenseIds = expenses.map((item) => item.expenseId);

  return (
    <UiExpenseTypeAutocomplite
      options={options || []}
      value={value}
      selectedExpenseIds={selectedExpenseIds}
      onChange={(e, value) => {
        documentFormModel.formEvent.setFieldExpense({
          id: idArticle,
          index: indexExpenses,
          fild: { key: 'expenseId', value: value?.id || 0 },
        });
      }}
    />
  );
};
