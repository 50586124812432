import { Collapse, List, useMediaQuery } from '@mui/material';
import { FC, Fragment, useState } from 'react';

import { Routes } from 'shared/routes/constants/types';
import { theme } from 'shared/theme/theme';
import { ICONS_FOR_PATH } from './Icons';
import { CustomDrawer } from './ui/custom-drawer';

interface ButtonNavigationDrawerProps {
  route: Routes;
  isOpenDrawer: boolean;
  handleCloseDrawer: () => void;
  handleOpenDrawer: () => void;
}

const chickIsRenderRoute = (route: Routes): boolean => {
  if (route.path === 'user' || !route.isWork || route.title.length === 0) {
    return false;
  }

  return true;
};

export const ButtonNavigationDrawer: FC<ButtonNavigationDrawerProps> = ({
  route,
  isOpenDrawer,
  handleCloseDrawer,
  handleOpenDrawer,
}) => {
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [isShowMore, setIsShowMore] = useState(false);

  if (!chickIsRenderRoute(route)) {
    return null;
  }
  if (!route.children) {
    return (
      <CustomDrawer.ListItemLink
        key={route.fullPath}
        to={route.fullPath}
        textPrimary={route.title}
        primaryTypographyProps={{
          fontWeight: 'bold',
        }}
        isOpenDrawer={isOpenDrawer}
        onClick={isDownSm ? handleCloseDrawer : undefined}
        Icon={ICONS_FOR_PATH[route.path]}
      />
    );
  }

  return (
    <Fragment key={route.fullPath}>
      <CustomDrawer.ListItemButtonMore
        isOpen={isShowMore}
        Icon={ICONS_FOR_PATH[route.path]}
        textPrimary={route.title}
        onClick={() => {
          handleOpenDrawer();
          setIsShowMore((prev) => {
            if (prev && isOpenDrawer) {
              return false;
            } else {
              return true;
            }
          });
        }}
      />

      <Collapse
        in={isShowMore && isOpenDrawer}
        timeout='auto'
        unmountOnExit
      >
        <List
          component='div'
          disablePadding
        >
          {route.children &&
            route.children.map((route) => {
              if (!route.isWork) return null;

              return (
                <CustomDrawer.ListItemLink
                  onClick={isDownSm ? handleCloseDrawer : undefined}
                  key={route.fullPath}
                  to={route.fullPath + (route.defaultQueryParams || '')}
                  textPrimary={route.title}
                  target={route.target}
                />
              );
            })}
        </List>
      </Collapse>
    </Fragment>
  );
};
