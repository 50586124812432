import { Skeleton } from '@mui/material';

import { StagerList } from './Stager';

export const StagerSkeleton = () => {
  return (
    <StagerList>
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
      <Skeleton variant='rounded' />
    </StagerList>
  );
};
