import { createStore } from 'effector';
import { useStore } from 'effector-react';
import { UiOrganizationsAutocomplite } from 'features/document/document-form-oganizations-autocomplite/ui';
import { useEffect } from 'react';

import { OrganizationModel } from 'shared/api/services-document-flow/types';

import { documentFormEditApi } from './api';
import { documentFormEdit } from './model';

const $options = createStore<OrganizationModel[]>([], { name: '' });

$options.on(
  documentFormEditApi.getOrgOptions.doneData,
  (_, response) => response.data
);
/* sample({
  clock: documentFormEdit.form,
  fn: (clock) => {
    return {
      isOrganizationOnlyForMyOffice: clock?.process.isOrganizationOnlyForMyOffice as boolean,
      processId: clock?.process.id as number,
    };
  },
  target: documentFormEditApi.getOrgOptions,
}); */

export const DocumentFormEditOrgAutocomplite = () => {
  const options = useStore($options);
  const processId =
    documentFormEdit.formSelects.useSelectProcessValueOfKey('id');
  const isOrganizationOnlyForMyOffice =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isOrganizationOnlyForMyOffice'
    );
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditOrganization'
  );

  useEffect(() => {
    if (processId) {
      documentFormEditApi.getOrgOptions({
        isOrganizationOnlyForMyOffice: Boolean(isOrganizationOnlyForMyOffice),
        processId,
      });
    }
  }, [isOrganizationOnlyForMyOffice, processId]);

  const value =
    documentFormEdit.formSelects.useSelectValueOfKey('organization');

  return (
    <UiOrganizationsAutocomplite
      onChange={(e, value) => {
        documentFormEdit.api.setFiled({ key: 'organization', value });
      }}
      disabled={!isCanEdit}
      value={value}
      options={options}
    />
  );
};
