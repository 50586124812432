import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { postEmployeeOnlypermissions } from 'shared/api/services-auth/services';

import { useUserDataFetch } from 'entities/user/useUserDataFetch';

import { E_ModuleGuids } from 'shared/moduleGuids';
import { INITIAL_ROUTES, Routes } from '../constants';
import {
  createPermissionDeniedObj,
  getFullPathRoute,
  getRoutesWithAccess,
} from '../libs';
import { RouteContext } from './RouteContext';

export const RouteProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useUserDataFetch();

  const [isChected, setIsChected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState<Routes[]>([]);

  const getAccessRoutes = async () => {
    if (!user?.userGuid) {
      setIsChected(true);

      return [];
    }

    const modulesGuids = Object.values(E_ModuleGuids);

    setIsLoading(true);

    const { data: myPermissions } =
      await postEmployeeOnlypermissions(modulesGuids);

    setIsLoading(false);

    const modPer = modulesGuids.map((guid) => {
      const prem = myPermissions.find((perm) => perm.moduleGuid === guid);

      if (prem) return prem;
      else return createPermissionDeniedObj(guid);
    });

    setRoutes(
      getFullPathRoute(getRoutesWithAccess(modPer, INITIAL_ROUTES), '')
    );
    setIsChected(true);
  };

  useEffect(() => {
    getAccessRoutes();
  }, [user?.userGuid]);

  return (
    <RouteContext.Provider value={{ isLoading, routes, isChected }}>
      {children}
    </RouteContext.Provider>
  );
};
