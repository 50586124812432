import { Delete } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CreateIcon from '@mui/icons-material/Create';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import RedoIcon from '@mui/icons-material/Redo';
import { ID_ACTION } from '../types';

export const getActionsIcon = (id: ID_ACTION) => {
  switch (id) {
    case 'delete':
      return <Delete />;
    case 'edit':
      return <CreateIcon />;
    case 1:
      return <CheckIcon />;
    case 2:
      return <CheckIcon />;
    case 3:
      return <CheckIcon />;
    case 4:
      return <CheckIcon />;
    case 5:
      return <CheckIcon />;
    case 6:
      return <CheckIcon />;
    case 7:
      return <CheckIcon />;
    case 8:
      return <CloseIcon />;
    case 11:
      return <KeyboardReturnIcon />;
    case 14:
      return <RedoIcon />;
    case 16:
      return <KeyboardReturnIcon />;
    default:
      return <ContactSupportIcon />;
  }
};
