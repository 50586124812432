import { useMemo } from 'react';
import { AdaptationProcessesCurrentListParams } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { convertURLToCurrentAdaptationProcessList } from 'shared/url-helper/conventors/convertURLToCurrentAdaptationProcessList';
import { useURLState } from 'shared/url-helper/useURLState';

const DEFAULT_VALUES = {
  Limit: 10,
  Page: 1,
  SearchText: '',
};

export const useAdaptationProcessesURLState = () => {
  const [filter, setFilter] = useURLState<AdaptationProcessesCurrentListParams>(
    convertURLToCurrentAdaptationProcessList,
    true
  );

  const memFilter = useMemo(() => {
    return {
      ...filter,
      Limit: filter?.Limit || DEFAULT_VALUES.Limit,
      Page: filter?.Page || DEFAULT_VALUES.Page,
      SearchText: filter?.SearchText || DEFAULT_VALUES.SearchText,
    } as AdaptationProcessesCurrentListParams;
  }, [filter]);

  return [memFilter, setFilter] as const;
};
