import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { FC } from 'react';

import { ArrowSelectorProps } from './type';

export const ArrowSelector: FC<ArrowSelectorProps> = ({
  onBack,
  onNext,
  children,
  disableNext = false,
  disableBack = false,
  containerProps,
}) => {
  return (
    <Grid
      container
      flexDirection={'row'}
      alignContent={'center'}
      gap={1}
      {...containerProps}
    >
      <IconButton
        disabled={disableBack}
        onClick={onBack}
      >
        <ArrowBackIos />
      </IconButton>
      {children}
      <IconButton
        disabled={disableNext}
        onClick={onNext}
      >
        <ArrowForwardIos />
      </IconButton>
    </Grid>
  );
};
