import dayjs from 'dayjs';
import { CalendarDay } from 'features/calendar/calendar-day';
import { FC, memo } from 'react';

import { theme } from 'shared/theme/theme';
import { UiCalendarCellContainer } from 'shared/ui/calendar/ui-calendar-cell-container';

import { OtherMonthColendarCellProps } from './type';

export const OtherMonthColendarCell: FC<OtherMonthColendarCellProps> = memo(
  ({ day }) => {
    return (
      <UiCalendarCellContainer
        key={day}
        containerProps={{
          sx: {
            backgroundColor: theme.palette.grey[200],
          },
        }}
      >
        <CalendarDay
          containerProps={{
            sx: { backgroundColor: theme.palette.grey[200] },
          }}
          day={dayjs(day).date()}
        />
      </UiCalendarCellContainer>
    );
  }
);
