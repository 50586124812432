import { useCallback, useState } from 'react';

import { postChecklists } from 'shared/api/visit/services';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useCreateChecklist = () => {
  const { open: openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const createChecklist = useCallback(async (visitId: number) => {
    setIsLoading(true);
    try {
      const { data, status } = await postChecklists({ visitingId: visitId });

      setIsLoading(false);
      if (status === 200) return data;
    } catch (e) {
      console.error('useCreateChecklist.createChecklist >> ', e);
      setIsLoading(false);

      const error = e as { message: string };

      openSnackbar({
        duration: 6000,
        type: 'error',
        text: error.message,
      });

      return Promise.reject(e);
    }
  }, []);

  return { createChecklist, isLoading };
};
