import { combine, createEffect, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { CombineArticle } from 'features/document/document-form-articles-autocomplite';
import {
  ArticlesAutocomplite,
  MultiArticlesAutocomplite,
} from 'features/document/document-form-articles-autocomplite/ui';
import { useEffect } from 'react';

import {
  getArticles,
  getArticleTypes,
} from 'shared/api/services-document-flow/services';
import {
  ArticleResponse,
  ArticleTypeModel,
} from 'shared/api/services-document-flow/types';
import { PropsAutocompliteDocumentForm } from 'shared/types/type';

import { documentFormEdit } from './model';

const fetchTypeArticleFx = createEffect({
  name: 'fetchTypeArticleFx',
  handler: getArticleTypes,
});

const fetchArticlesFx = createEffect({
  name: 'fetchArticlesFx',
  handler: getArticles,
});

const $articlesDDS = createStore<ArticleResponse[]>([], {
  name: '$articlesDDS ',
}).on(fetchArticlesFx.doneData, (state, response) => response.data);

const $typesArticle = createStore<ArticleTypeModel[]>([], {
  name: '$typesArticle ',
}).on(fetchTypeArticleFx.doneData, (_, response) => response.data);

const $options = createStore<CombineArticle[]>([], {
  name: 'options-edit-article-autocomplite',
}).on(
  sample({
    clock: [$articlesDDS, $typesArticle],
    source: { article: $articlesDDS, typeArticle: $typesArticle },
    fn: ({ article, typeArticle }) =>
      article
        .map((item) => ({
          ...item,
          typeTitle:
            typeArticle.find((type) => type.id === item.typeId)?.title ||
            'Прочее',
        }))
        .sort((a, b) =>
          b.typeTitle && a.typeTitle && a.typeTitle > b.typeTitle ? 1 : -1
        ),
  }),
  (_, res) => res
);

const $value = combine(
  $options,
  documentFormEdit.form.map((item) => (item && item.documentArticles) || []),
  (options, articles): CombineArticle[] => {
    if (!document) return [];

    return articles.map((art) => {
      const findArtInOpt = options.find((item) => item.id === art.articleId);

      if (findArtInOpt) {
        return { ...findArtInOpt };
      } else {
        return {
          id: art.articleId,
          title: '',
          typeTitle: 'Прочее',
          typeId: 0,
        };
      }
    });
  }
);
const onChange = documentFormEdit.api.setArticles.prepend(
  (payload: CombineArticle[] | CombineArticle | null): CombineArticle[] => {
    if (!payload) {
      return [];
    }
    if (Array.isArray(payload)) {
      return payload;
    } else {
      return [payload];
    }
  }
);

export const DocumentFormEditArticlesAutocomplite = () => {
  const value = useStore($value);
  const processId =
    documentFormEdit.formSelects.useSelectProcessValueOfKey('id');
  const isAllArticlesRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isAllArticlesRequired'
    );
  const isMultiArticles =
    documentFormEdit.formSelects.useSelectProcessValueOfKey('isMultiArticles');
  const options = useStore($options);
  const isCanEdit =
    documentFormEdit.formSelects.useSelectValueOfKey('canEditArticles');
  const onChangeArticles: PropsAutocompliteDocumentForm<
    CombineArticle[] | CombineArticle
  >['onChange'] = (e, value) => {
    onChange(value);
  };

  useEffect(() => {
    if (processId) {
      fetchArticlesFx({ ProcessId: processId });
      fetchTypeArticleFx();
    }
  }, [processId]);
  if (isMultiArticles || (options.length > 0 && isAllArticlesRequired)) {
    return (
      <MultiArticlesAutocomplite
        disabled={Boolean(isAllArticlesRequired || !isCanEdit)}
        value={value}
        options={options}
        onChange={onChangeArticles}
      />
    );
  }

  return (
    <ArticlesAutocomplite
      disabled={Boolean(isAllArticlesRequired || !isCanEdit)}
      value={(value && value[0]) || null}
      options={options}
      onChange={onChangeArticles}
    />
  );
};
