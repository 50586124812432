import dayjs from 'dayjs';
import { useStore } from 'effector-react';
import { $optionsDocumentReasonType } from 'features/document/document-form-reason-type-autocomplite/model';

import { UiDocumentFormReasonDate } from 'shared/ui/UiDocumentFormReasonDate';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormReasonDate = () => {
  const value =
    documentFormModel.useValueDocumentFormModel('reasonDocumentDate');
  const isReasonDocumentRequired = documentFormModel.useValueProcess(
    'isReasonDocumentRequired'
  );
  const reasonDocumentTypeId = documentFormModel.useValueDocumentFormModel(
    'reasonDocumentTypeId'
  );
  const documentTypeOptions = useStore($optionsDocumentReasonType);
  const required = documentTypeOptions.find(
    (item) => reasonDocumentTypeId && item.id === reasonDocumentTypeId
  )?.isOtherInformationRequired;

  if (!isReasonDocumentRequired) return null;

  return (
    <UiDocumentFormReasonDate
      required={Boolean(required)}
      disabled={!isReasonDocumentRequired}
      value={value}
      onChange={(value) =>
        documentFormModel.formEvent.setField({
          key: 'reasonDocumentDate',
          value: dayjs(value).format('YYYY-MM-DDTHH:mm:ss') || '',
        })
      }
    />
  );
};
