import { Add, Delete, Edit } from '@mui/icons-material';
import dayjs from 'dayjs';
import React, { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { UiTableCellWithActions } from 'shared/ui/table/ui-table-cell-with-actions';

import { CELL_SX } from '../style';
import { InfoPlanningRNCellProps } from './type';

export const InfoPlanningRNCell: FC<InfoPlanningRNCellProps> = ({
  onDelete,
  onEdit,
  onCreateVisit,
  info,
  visitor,
}) => {
  const dateStart = dayjs(info.periodStart).get('D');
  const dateEnd = dayjs(info.periodFinish).get('D');
  const actions = [
    {
      onClick: onEdit,
      text: 'Редактировать',
      icon: <Edit sx={{ mr: 2 }} />,
    },
    {
      onClick: onCreateVisit,
      text: 'Создать посещение',
      icon: <Add sx={{ mr: 2 }} />,
      disabled: !info.canCreateVisiting,
    },
    {
      onClick: onDelete,
      text: 'Удалить',
      icon: <Delete sx={{ mr: 2 }} />,
      disabled: !info.canDelete,
    },
  ];

  const backgroundColor =
    visitor.employeePosition !== 'ДР'
      ? `${theme.palette.primary.light}44`
      : undefined;

  return (
    <React.Fragment>
      <UiTableCellWithActions
        colSpan={1}
        sx={{
          borderLeft: CELL_SX.borderLeft,
          borderColor: CELL_SX.borderColor,
          background: backgroundColor,
        }}
        actions={actions}
      >
        {visitor.employeeName}
      </UiTableCellWithActions>
      <UiTableCellWithActions
        colSpan={1}
        align='center'
        sx={{
          borderRight: CELL_SX.borderRight,
          borderColor: CELL_SX.borderColor,
          background: backgroundColor,
        }}
        actions={actions}
      >
        {dateStart}-{dateEnd}
      </UiTableCellWithActions>
    </React.Fragment>
  );
};
