import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { boolean, object, ObjectSchema } from 'yup';
import { ChangeStartFinishDateFormFields } from './type';

export const VALIDATION_SCHEMA: ObjectSchema<ChangeStartFinishDateFormFields> =
  object().shape({
    startDate: SCHEMA.DATEJS,
    finisDate: SCHEMA.builderDateEnd('startDate').required(),
    isDateStartChanged: boolean(),
  });
