import { useEffect, useState } from 'react';

import { usePromPtContextProvider } from 'entities/visit/promt/PromptContext';

export const useStageDraftConfirm = <T>(
  isDirty: boolean,
  onSubmit: (formData: T) => void | Promise<void>,
  onDraftSubmit?: (formData: T) => void | Promise<void>
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { changeIsBlock, value } = usePromPtContextProvider();

  useEffect(() => {
    changeIsBlock(isDirty);
  }, [isDirty]);

  const handleClose = () => value && value.reset && value.reset();
  const handleConfirm = async (formData?: T) => {
    if (onDraftSubmit && formData) {
      setIsLoading(true);
      await onDraftSubmit(formData);
      changeIsBlock(false);
      setIsLoading(false);
    }
    value && value.proceed && value.proceed();
  };
  const handleNext = () => {
    changeIsBlock(false);
    value && value.proceed && value.proceed();
  };

  const onSub = async (formData: T) => {
    changeIsBlock(false);
    await onSubmit(formData);
  };

  return {
    handleClose,
    handleConfirm,
    handleNext,
    isLoading,
    onSubmit: onSub,
    isDialogOpen: value ? value.state === 'blocked' : false,
  };
};
