import { Alert, AlertTitle } from '@mui/material';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';

import { ErrorResponse } from 'shared/api/services-document-flow/types';

import { documentFormEditApi } from '../api';

const deleteError = createEvent();
const errorEditDocument = createStore<ErrorResponse | null>(null)
  .on(documentFormEditApi.putDocument.failData, (_, err) => err.response?.data)
  .on(
    documentFormEditApi.postDocumentsSend.failData,
    (_, err) => err.response?.data
  )
  .on(
    documentFormEditApi.firstValidation.failData,
    (_, err) => err.response?.data
  )
  .on(deleteError, () => null);

export const AlertErrorDocumentFormEdit = () => {
  const error = useStore(errorEditDocument);

  if (!error) return null;

  return (
    <Alert
      severity='error'
      sx={{
        position: 'fixed',
        zIndex: 99999,
        bottom: '10%',
        left: 0,
        transitionDuration: 2,
      }}
      onClose={() => deleteError()}
    >
      <AlertTitle>{error?.message}</AlertTitle>
      {error.additionally}
    </Alert>
  );
};
