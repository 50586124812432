import { Grid } from '@mui/material';
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FC } from 'react';

import { DATE_FORMATS } from 'shared/date-helper/constants';

import { CustomDatePicker } from './base-ui';

export const UiDocumentFormReasonDate: FC<{
  disabled: boolean;
  required: boolean;
  value: string | undefined;
  onChange: (
    value: string | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => void;
}> = ({ disabled, value, onChange, required }) => {
  return (
    <Grid sx={{ flexBasis: ['100%', '35%'] }}>
      <CustomDatePicker
        // format='DD.MM.YYYY'
        disabled={disabled}
        label={'Дата документа'}
        value={value ? dayjs(value) : null}
        onChange={(value, context) => {
          onChange(value?.format(DATE_FORMATS.server) || null, context);
        }}
        slotProps={{
          textField: {
            disabled,
            required: required,
            inputProps: {
              placeholder: 'Введите дату документа',
            },
          },
        }}
      />
    </Grid>
  );
};
