import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useChangeRemindMutation } from 'shared/api/services/rtk/taskApi';
import { GetTask } from 'shared/api/services/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { ContainerActionsForm } from 'shared/ui/base-ui';
import { useDevice } from 'shared/utils/hook-type-device';
import { getRemindeFormInitValue } from './libs/getRemindeFormInitValue';
import { prepareRemideFormDataToFetch } from './libs/prepareRemideFormDataToFetch';
import { RemindeForm } from './RemindeForm';

export interface RemindeFormModalProps {
  onClose: () => void;
  isOpen: boolean;
  task: GetTask;
}
export const RemindeFormModal: FC<RemindeFormModalProps> = ({
  isOpen,
  onClose,
  task,
}) => {
  const { open: openSnackbar } = useSnackbar();
  const [isMobile] = useDevice();
  const [updateRemind, { isLoading: isUpdating, error }] =
    useChangeRemindMutation();

  useShowFetchErrorInSnackbar(error);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      PaperProps={{ sx: { p: [0, 3], maxWidth: 700, width: '100%' } }}
    >
      <Typography
        fontSize={26}
        fontWeight={700}
        mb={2}
        mt={[2, 0]}
        textAlign={['center', 'start']}
      >
        Напоминания
      </Typography>
      <RemindeForm
        initValue={getRemindeFormInitValue(task)}
        onSubmit={(data) => {
          const prepData = prepareRemideFormDataToFetch(data);

          updateRemind({ ...prepData, tasksGuids: [task.guid] }).then(() => {
            onClose();
            openSnackbar({
              text: <strong>Напоминание создано</strong>,
              type: 'success',
              duration: 3000,
            });
          });
        }}
        actions={() => (
          <Grid
            container
            justifyContent={'space-between'}
            sx={{
              position: ['fixed', 'relative'],
              bottom: 0,
              backgroundColor: 'white',
              zIndex: 10,
            }}
          >
            <ContainerActionsForm>
              <LoadingButton
                loading={isUpdating}
                variant='contained'
                type='submit'
              >
                Сохранить
              </LoadingButton>
              <Button
                variant='contained'
                color='customGrey'
                onClick={onClose}
              >
                Закрыть
              </Button>
            </ContainerActionsForm>
          </Grid>
        )}
        taskInfo={
          task.accessActions.canModifyRemind
            ? {
                creator: !!task.creator,
                executor: !!task.executor,
                coExecutor:
                  (task.coExecutors && task.coExecutors.length > 0) || false,
                observer:
                  (task.observers && task.observers.length > 0) || false,
              }
            : {
                coExecutor: false,
                creator: false,
                executor: false,
                observer: false,
              }
        }
      />
    </Dialog>
  );
};
