import { Typography } from '@mui/material';
import { FC } from 'react';
import { checkIsNotEmptyArray } from 'shared/utils/checkIsNotEmptyArray';
import { FormPreparedStagesTasks } from './FormPreparedStagesTasks';
import { FormPreparedStagesTasksWithLoadAndEmptyStageProps } from './types';
import { NotStagesInfo } from './ui/not-stages-info';
import { SkeletonForm } from './ui/skeleton-form';

export const FormPreparedStagesTasksWithLoadAndEmptyStage: FC<
  FormPreparedStagesTasksWithLoadAndEmptyStageProps
> = ({
  isLoadinInitialState,
  isLoadingSubmite,
  onSubmite,
  initialValue,
  formFetchOptionsContextValue,
}) => {
  if (!initialValue && isLoadinInitialState) {
    return <SkeletonForm />;
  }
  if (!isLoadinInitialState && !checkIsNotEmptyArray(initialValue?.stages)) {
    return <NotStagesInfo />;
  }

  if (initialValue && checkIsNotEmptyArray(initialValue?.stages)) {
    return (
      <FormPreparedStagesTasks
        isLoadingSubmite={isLoadingSubmite}
        initialValue={initialValue}
        onSubmite={onSubmite}
        formFetchOptionsContextValue={formFetchOptionsContextValue}
      />
    );
  }

  return <Typography>Что то пошло не так</Typography>;
};
