import { Dayjs } from 'dayjs';
import { CoreSystemEnumsObjectActions } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { Base64File } from 'shared/utils/files/fileListToArrayBase64';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { mixed, object, ObjectSchema } from 'yup';
import { SliderFormValue } from './types';

const builderRequiredImageDataWithoutUrl = (params: {
  keyData: string;
  keyUrl: string;
}) => {
  const { keyData, keyUrl } = params;

  return mixed<Base64File>()
    .required()
    .nullable()
    .when([keyUrl], ([imageUrl], scheme) => {
      return scheme.test(keyData, 'Загрузите изображение', (imageData) => {
        if (!imageUrl && !imageData) return false;

        return true;
      });
    });
};

export const SCHEME_VALIDATE_SLIDER_FORM: ObjectSchema<SliderFormValue> =
  object({}).shape({
    dateStart: mixed<Dayjs>().required('Обязательное поле'),
    dateFinish:
      SCHEMA.builderDateEnd('dateStart').required('Обязательное поле'),
    link: SCHEMA.STRING,
    organization: SCHEMA.STRING,
    erid: SCHEMA.STRING,
    imageDesktopData: builderRequiredImageDataWithoutUrl({
      keyData: 'imageDesktopData',
      keyUrl: 'imageDesktopUrl',
    }),
    imageMobileData: builderRequiredImageDataWithoutUrl({
      keyData: 'imageMobileData',
      keyUrl: 'imageMobileUrl',
    }),
    imageDesktopUrl: SCHEMA.STRING_REQUIRED.nullable(),
    imageMobileUrl: SCHEMA.STRING_REQUIRED.nullable(),
    imageDesktopDataAction: mixed<CoreSystemEnumsObjectActions>().required(
      'field - imageDesktopDataAction, required'
    ),
    imageMobileDataAction: mixed<CoreSystemEnumsObjectActions>().required(
      'field - imageMobileDataAction, required'
    ),
  });
