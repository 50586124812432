import { createSvgIcon } from '@mui/material';

export const AptekaLegkoSvg = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 27 35'
  >
    <path
      fill='currentColor'
      d='M26.923 12.137a12.99 12.99 0 00-.32-1.86c-.086-.32-.171-.662-.278-.96a16.472 16.472 0 00-.192-.556 13.122 13.122 0 00-1.945-3.462A13.388 13.388 0 0021.282 2.5l-.577-.385A13.235 13.235 0 0017.5.641a13.191 13.191 0 00-1.816-.427A10.475 10.475 0 0013.504 0a13.409 13.409 0 00-7.842 2.521A13.386 13.386 0 001.56 7.222a13.367 13.367 0 00-1.475 4.744A17.574 17.574 0 000 13.504c0 .15.128 3.248 3.397 8.804.086.128.171.277.257.427a34.63 34.63 0 001.132 1.774 60.896 60.896 0 002.543 3.504c.598.77 1.24 1.56 1.945 2.393a90.062 90.062 0 003.247 3.568c.235.235.45.47.684.727.107.107.192.213.3.299.127-.128.234-.235.341-.363 2.479-2.543 4.487-4.872 6.111-7.009.171-.213.32-.427.47-.64.257-.343.513-.685.748-1.005.15-.214.3-.427.449-.62a40.047 40.047 0 001.73-2.65c1.133-1.88 1.924-3.504 2.458-4.828.17-.45.342-.855.47-1.24.684-1.965.727-3.055.727-3.14 0-.47-.022-.92-.086-1.368zm-8.14 8.632a9.726 9.726 0 01-2.416 1.175 9.244 9.244 0 01-2.799.428h-.064a9.274 9.274 0 01-7.286-3.611C5 17.2 4.295 15.235 4.295 13.098c0-1.004.17-1.987.47-2.906a9.266 9.266 0 018.654-6.367H13.568a9.13 9.13 0 014.103.961 9.26 9.26 0 014.487 4.83c.385.94.62 1.965.662 3.034 0 .15.022.32.022.47.021 3.162-1.603 5.983-4.06 7.65z'
    ></path>
    <path
      fill='currentColor'
      d='M14.835 20H12.42c-.577 0-1.068-.47-1.068-1.068V7.115c0-.576.47-1.068 1.068-1.068h2.415c.576 0 1.068.47 1.068 1.068v11.817c0 .577-.47 1.068-1.069 1.068z'
    ></path>
    <path
      fill='currentColor'
      d='M6.664 14.23v-2.414c0-.577.47-1.068 1.068-1.068H19.55c.577 0 1.068.47 1.068 1.068v2.415c0 .577-.47 1.068-1.068 1.068H7.71c-.577 0-1.047-.47-1.047-1.068z'
    ></path>
  </svg>,
  'AptekaLegko'
);
