// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  DocumentArticleModel,
  DocumentArticleSecondValidateRequest,
  DocumentArticleSecondValidateResponse,
  DocumentFirstValidateRequest,
  DocumentModel,
  DocumentUpdateRequest,
} from 'shared/api/services-document-flow/types';

import { SetFieldExpense } from './type';

export const getQueryForFirstValidFromDocumentModel = (
  document: DocumentModel
): DocumentFirstValidateRequest => {
  return {
    organizationId: document.organization.id,
    processId: document.process.id,
    documentArticleIds: document.documentArticles?.map(
      (item) => item.articleId
    ),
  };
};

export const prependDocumentArticlesModelToDocumentArticleSecondValidateRequest =
  (
    articles?: DocumentArticleModel[]
  ): DocumentArticleSecondValidateRequest[] | undefined => {
    if (!articles || articles.length === 0) return undefined;

    return articles.map((article) => {
      return {
        id: article.articleId,
        sum: article.sum,
        estimateDocumentArticleId: article.estimateDocumentArticle
          ? article.estimateDocumentArticle.estimateDocumentArticleId
          : undefined,
        retailId: article.retail ? article.retail.id : undefined,
        expenses: article.documentArticleExpenses?.map((expense) => {
          return {
            count: expense.count,
            expenseId: expense.expenseId,
            sum: expense.sum,
            price: expense.price,
            extendedConditionId: expense.extendedCondition
              ? expense.extendedCondition.id
              : undefined,
            id: expense.id || 0,
          };
        }),
      };
    });
  };

export const setFieldExpense: SetFieldExpense = (
  document,
  { id, index, fild }
) => {
  if (!document) return document;

  const { key, value } = fild;

  return {
    ...document,
    documentArticles: document.documentArticles?.map((article) => {
      if (article.articleId === id) {
        if (article.documentArticleExpenses) {
          article.documentArticleExpenses.forEach((item, ind) => {
            if (index === ind) {
              item[key] = value;
            }
          });
        } else {
          article.documentArticleExpenses = [
            //@ts-ignore
            {
              [key]: value,
            },
          ];
        }
      }

      return article;
    }),
  };
};

export const mapDocumentSecondValidationResponseToDocumentArticleModel = (
  response: DocumentArticleSecondValidateResponse[]
): DocumentArticleModel[] => {
  return response.map((item) => ({
    ...item,
    articleId: item.id,
    articleTitle: item.title,
    documentArticleExpenses: item.expenses?.map((expense) => ({
      ...expense,
      expenseId: expense.expenseId,
      count: expense.count,
      price: expense.price,
      sum: expense.sum,
      extendedCondition: expense.extendedCondition,
      retail: expense.retail,
      isCountFromPeriod: expense.isCountFromPeriod,
      expenseTitle: expense.title,
      expenseDescription: expense.description,
      id: expense.id,
      normSum: expense.normSum,
    })),
  }));
};

const isEmptyArray = <T>(arr: T[] | undefined): T[] | undefined => {
  if (!arr) return undefined;

  return arr.length > 0 ? arr : undefined;
};

export const prependDocumentModelToPutDocumentBody = (
  document: DocumentModel
): DocumentUpdateRequest => {
  return {
    articles:
      prependDocumentArticlesModelToDocumentArticleSecondValidateRequest(
        document.documentArticles
      ) || [],
    processId: document.process.id,
    organizationId: document.organization.id,

    beginPeriod: document.beginPeriod,
    closeDocumentDiadokLink: document.closeDocumentDiadokLink,
    counterpartyContractLink: document.counterpartyContractLink,
    counterpartyContractNumber: document.counterpartyContractNumber,
    counterpartyId: document.counterparty
      ? document.counterparty.id
      : undefined,
    counterpartyRegReasonCode: document.counterpartyRegReasonCode,
    counterpartyTaxNumber: document.counterpartyTaxNumber,
    dateTimeAdditionallyFields: isEmptyArray(
      document.dateTimeAdditionallyFields?.map((item) => ({
        id: item.processAdditionallyField.id,
        value: item.value,
      }))
    ),
    decimalAdditionallyFields: isEmptyArray(
      document.decimalAdditionallyFields?.map((item) => ({
        id: item.processAdditionallyField.id,
        value: item.value,
      }))
    ),
    periodAdditionallyFields: isEmptyArray(
      document.periodAdditionallyFields?.map((item) => ({
        id: item.processAdditionallyField.id,
        beginValue: item.begValue,
        endValue: item.endValue,
      }))
    ),
    stringAdditionallyFields: isEmptyArray(
      document.stringAdditionallyFields?.map((item) => ({
        id: item.processAdditionallyField.id,
        value: item.value,
      }))
    ),
    intAdditionallyFields: isEmptyArray(
      document.intAdditionallyFields?.map((item) => ({
        id: item.processAdditionallyField.id,
        value: item.value,
      }))
    ),
    endPeriod: document.endPeriod,
    isCloseDocumentDiadok: document.isCloseDocumentDiadok,
    payBeforeDate: document.payBeforeDate,
    reasonDocumentDate: document.reasonDocumentDate,
    reasonDocumentNumber: document.reasonDocumentNumber,
    reasonDocumentTypeId: document.reasonDocumentType
      ? document.reasonDocumentType.id
      : undefined,
    taxRateId: document.taxRate ? document.taxRate.id : undefined,
    taxSum: !document.taxRate ? document.taxSum : undefined,
    isCloseDocumentSumEquals: Boolean(document.isCloseDocumentSumEquals),
    closeDocumentDate: document.closeDocumentDate,
    closeDocumentSum: document.isCloseDocumentSumEquals
      ? undefined
      : String(document.closeDocumentSum) !== ''
      ? document.closeDocumentSum
      : undefined,
    closeDocumentNumber: document.closeDocumentNumber,
    closeDocumentTaxRateId: document.closeDocumentTaxRate?.id,
    closeDocumentTaxSum: !document.closeDocumentTaxRate
      ? document.closeDocumentTaxSum
      : undefined,
    serviceTypeId: document.serviceType ? document.serviceType?.id : undefined,
  };
};
