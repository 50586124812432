import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextareaAutosize } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import {
  DialigFormCancelTrainingCommentProps,
  I_FormCancelTrainingComment,
} from './types';

export const DialigFormCancelTrainingComment: FC<
  DialigFormCancelTrainingCommentProps
> = ({ isOpen, onClose, onSubmite, loadingSubmite }) => {
  const { control, handleSubmit } = useForm<I_FormCancelTrainingComment>({
    defaultValues: {
      comment: '',
    },
  });

  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title={'Отмена тренинга'} />}
    >
      <Grid
        container
        flexDirection={'column'}
        component='form'
        onSubmit={handleSubmit(onSubmite)}
      >
        <Grid
          container
          paddingX={1}
        >
          <Controller
            control={control}
            name='comment'
            render={({ field: { value, onChange } }) => {
              return (
                <TextareaAutosize
                  tabIndex={99}
                  required={true}
                  //className={'TextareaAutosize_comment'}
                  style={{
                    width: '100%',
                    minHeight: '100px',
                    outline: 'none',
                    marginBottom: '16px',
                    resize: 'none',
                  }}
                  placeholder='Введите комментарий'
                  aria-label='empty textarea'
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                />
              );
            }}
          />
        </Grid>
        <ContainerActionsForm>
          <LoadingButton
            variant='contained'
            type='submit'
            disabled={loadingSubmite}
            loading={loadingSubmite}
          >
            Сохранить
          </LoadingButton>
          <Button
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
