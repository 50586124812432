import { filterNull } from 'shared/utils/filterNull';

import { getObjById } from './getObjById';

type GetObjByIdsFun = <T extends { id: number }>(
  obj: T[] | undefined | null,
  ids: number[] | undefined | null
) => T[] | null;

export const getObjByIds: GetObjByIdsFun = (obj, ids) => {
  if (!obj || !ids) return null;

  return filterNull(ids.map((id) => getObjById(obj, id)));
};
