//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from 'axios';
import type { SwaggerResponse } from './config';
import { Http } from './httpRequest';
//@ts-ignore
import qs from 'qs';
import type {
  BoardColumnChangeBoard,
  BoardColumnCreate,
  BoardColumnUpdate,
  BoardCreate,
  BoardEmployeeAdd,
  BoardEmployeeUpdate,
  BoardIsArchiveUpdate,
  BoardTasksChangeBoard,
  BoardTaskUpdate,
  BoardUpdate,
  ControllFilterModel,
  DeleteTasksExternalQueryParams,
  DeleteTasksMassQueryParams,
  DeleteTasksQueryParams,
  ExternalTaskIsFinished,
  ExternalTaskMassCreate,
  GetBoardsQueryParams,
  GetBoardsTasksBoardGuidQueryParams,
  GetListBoard,
  GetListBoardColumn,
  GetListBoardPaginated,
  GetListBoardTask,
  GetListEmployee,
  GetListExternalTask,
  GetListExternalTaskMassFile,
  GetListOrigin,
  GetListTask,
  GetListTaskComment,
  GetListTaskFile,
  GetListTaskPaginated,
  GetTask,
  GetTaskMassIsFinished,
  GetTasksExternalQueryParams,
  GetTasksMassIsFinishedQueryParams,
  GetTasksMassQueryParams,
  GetTasksMeControllerWebQueryParams,
  GetTasksMeCreatorQueryParams,
  GetTasksMeExecutorQueryParams,
  GetTasksQueryParams,
  HistoryItemModel,
  IsFinishedExternalTask,
  MassChangeStatusToNewRequest,
  MassCreateTaskModel,
  OrgTreeNodeModel,
  PositionModel,
  PositionsLinkItemModel,
  RegionTreeNodeModel,
  StatusModel,
  TaskCommentCreate,
  TaskCreate,
  TaskListItemModel,
  TaskMassIsFinishedRequest,
  TasksChangeCoExecutorsAndObservers,
  TasksChangeExecutor,
  TasksChangeRemind,
  TasksChangeStartFinishDates,
  TasksChangeStatus,
  TasksChangeStatusToLast,
  TaskUpdate,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, 'i');
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

/**
 *
 * Удаление доски
 */
export const deleteBoardsBoardGuid = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteBoardsBoardGuid.key, { boardGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteBoardsBoardGuid.key = '/v1/taskmanager/api/Boards/{boardGuid}';

/**
 *
 * Удаление колонки
 */
export const deleteBoardsBoardGuidColumnsBoardColumnGuid = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  /**
   *
   * Guid колонки
   */
  boardColumnGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteBoardsBoardGuidColumnsBoardColumnGuid.key, {
      boardGuid,
      boardColumnGuid,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteBoardsBoardGuidColumnsBoardColumnGuid.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/Columns/{boardColumnGuid}';

/**
 *
 * Исключает сотрудника из списка сотрудников, имеющих доступ к указанной доске
 */
export const deleteBoardsBoardGuidEmployeesRemoveEmployeeGuid = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  /**
   *
   * Guid исключаемого сотрудника
   */
  removeEmployeeGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteBoardsBoardGuidEmployeesRemoveEmployeeGuid.key, {
      boardGuid,
      removeEmployeeGuid,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteBoardsBoardGuidEmployeesRemoveEmployeeGuid.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/Employees/{removeEmployeeGuid}';

export const deletePositionsPositionIdCreatorPositionsLinksLinkId = (
  positionId: number,
  linkId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deletePositionsPositionIdCreatorPositionsLinksLinkId.key, {
      positionId,
      linkId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deletePositionsPositionIdCreatorPositionsLinksLinkId.key =
  '/v1/taskmanager/api/Positions/{positionId}/CreatorPositionsLinks/{linkId}';

export const deletePositionsPositionIdObserverPositionsLinksLinkId = (
  positionId: number,
  linkId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deletePositionsPositionIdObserverPositionsLinksLinkId.key, {
      positionId,
      linkId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deletePositionsPositionIdObserverPositionsLinksLinkId.key =
  '/v1/taskmanager/api/Positions/{positionId}/ObserverPositionsLinks/{linkId}';

/**
 *
 * Удаление указанных задач
 */
export const deleteTasks = (
  queryParams: DeleteTasksQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteTasks.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTasks.key = '/v1/taskmanager/api/Tasks';

export const deleteTasksExternal = (
  queryParams?: DeleteTasksExternalQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteTasksExternal.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTasksExternal.key = '/v1/taskmanager/api/Tasks/External';

export const deleteTasksMass = (
  queryParams?: DeleteTasksMassQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteTasksMass.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTasksMass.key = '/v1/taskmanager/api/Tasks/Mass';

/**
 *
 * Удаление комментария
 */
export const deleteTasksTaskGuidCommentsCommentGuid = (
  taskGuid: string,
  commentGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteTasksTaskGuidCommentsCommentGuid.key, {
      taskGuid,
      commentGuid,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTasksTaskGuidCommentsCommentGuid.key =
  '/v1/taskmanager/api/Tasks/{taskGuid}/Comments/{commentGuid}';

export const deleteTasksTaskGuidFilesFileGuid = (
  taskGuid: string,
  fileGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteTasksTaskGuidFilesFileGuid.key, { taskGuid, fileGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTasksTaskGuidFilesFileGuid.key =
  '/v1/taskmanager/api/Tasks/{taskGuid}/Files/{fileGuid}';

export const deleteTasksTaskId = (
  taskId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteTasksTaskId.key, { taskId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTasksTaskId.key = '/v1/taskmanager/api/Tasks/{taskId}';

/**
 *
 * Возвращает список досок, доступных текущему пользователю
 */
export const getBoards = (
  queryParams: GetBoardsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoardPaginated>> => {
  return Http.getRequest(
    getBoards.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getBoards.key = '/v1/taskmanager/api/Boards';

/**
 *
 * Возвращает информацию по доске
 */
export const getBoardsBoardGuid = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoard>> => {
  return Http.getRequest(
    template(getBoardsBoardGuid.key, { boardGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getBoardsBoardGuid.key = '/v1/taskmanager/api/Boards/{boardGuid}';

/**
 *
 * Возвращает список колонок для доски, доступной текущему пользователю
 */
export const getBoardsBoardGuidColumns = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoardColumn[]>> => {
  return Http.getRequest(
    template(getBoardsBoardGuidColumns.key, { boardGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getBoardsBoardGuidColumns.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/Columns';

/**
 *
 * Возвращает список сотрудников, имеющих доступ к указанной доске
 */
export const getBoardsBoardGuidEmployees = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListEmployee[]>> => {
  return Http.getRequest(
    template(getBoardsBoardGuidEmployees.key, { boardGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getBoardsBoardGuidEmployees.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/Employees';

/**
 *
 * Возвращает список задач для доски
 */
export const getBoardsTasksBoardGuid = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  queryParams?: GetBoardsTasksBoardGuidQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoardTask[]>> => {
  return Http.getRequest(
    template(getBoardsTasksBoardGuid.key, { boardGuid }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getBoardsTasksBoardGuid.key = '/v1/taskmanager/api/Boards/Tasks/{boardGuid}';

export const getControllFilters = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ControllFilterModel>> => {
  return Http.getRequest(
    getControllFilters.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getControllFilters.key = '/v1/taskmanager/api/ControllFilters';

export const getEmployees = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListEmployee[]>> => {
  return Http.getRequest(
    getEmployees.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getEmployees.key = '/v1/taskmanager/api/Employees';

export const getEmployeesAllowedCoExecuters = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListEmployee[]>> => {
  return Http.getRequest(
    getEmployeesAllowedCoExecuters.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesAllowedCoExecuters.key =
  '/v1/taskmanager/api/Employees/AllowedCoExecuters';

export const getEmployeesAllowedExecuters = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListEmployee[]>> => {
  return Http.getRequest(
    getEmployeesAllowedExecuters.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesAllowedExecuters.key =
  '/v1/taskmanager/api/Employees/AllowedExecuters';

export const getOrgTree = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OrgTreeNodeModel[]>> => {
  return Http.getRequest(
    getOrgTree.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getOrgTree.key = '/v1/taskmanager/api/OrgTree';

/**
 *
 * Возвращает список источников задач
 */
export const getOrigins = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListOrigin[]>> => {
  return Http.getRequest(
    getOrigins.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getOrigins.key = '/v1/taskmanager/api/Origins';

export const getPositions = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionModel[]>> => {
  return Http.getRequest(
    getPositions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getPositions.key = '/v1/taskmanager/api/Positions';

export const getPositionsPositionIdCreatorPositionsLinks = (
  positionId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionsLinkItemModel[]>> => {
  return Http.getRequest(
    template(getPositionsPositionIdCreatorPositionsLinks.key, { positionId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getPositionsPositionIdCreatorPositionsLinks.key =
  '/v1/taskmanager/api/Positions/{positionId}/CreatorPositionsLinks';

export const getPositionsPositionIdCreatorPositionsLinksLinkId = (
  positionId: number,
  linkId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionsLinkItemModel>> => {
  return Http.getRequest(
    template(getPositionsPositionIdCreatorPositionsLinksLinkId.key, {
      positionId,
      linkId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getPositionsPositionIdCreatorPositionsLinksLinkId.key =
  '/v1/taskmanager/api/Positions/{positionId}/CreatorPositionsLinks/{linkId}';

export const getPositionsPositionIdObserverPositionsLinks = (
  positionId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionsLinkItemModel[]>> => {
  return Http.getRequest(
    template(getPositionsPositionIdObserverPositionsLinks.key, { positionId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getPositionsPositionIdObserverPositionsLinks.key =
  '/v1/taskmanager/api/Positions/{positionId}/ObserverPositionsLinks';

export const getPositionsPositionIdObserverPositionsLinksLinkId = (
  positionId: number,
  linkId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionsLinkItemModel>> => {
  return Http.getRequest(
    template(getPositionsPositionIdObserverPositionsLinksLinkId.key, {
      positionId,
      linkId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getPositionsPositionIdObserverPositionsLinksLinkId.key =
  '/v1/taskmanager/api/Positions/{positionId}/ObserverPositionsLinks/{linkId}';

export const getRegionTree = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RegionTreeNodeModel[]>> => {
  return Http.getRequest(
    getRegionTree.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getRegionTree.key = '/v1/taskmanager/api/RegionTree';

export const getStatuses = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<StatusModel[]>> => {
  return Http.getRequest(
    getStatuses.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getStatuses.key = '/v1/taskmanager/api/Statuses';

export const getTasks = (
  queryParams?: GetTasksQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TaskListItemModel[]>> => {
  return Http.getRequest(
    getTasks.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasks.key = '/v1/taskmanager/api/Tasks';

export const getTasksExternal = (
  queryParams?: GetTasksExternalQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListExternalTask[]>> => {
  return Http.getRequest(
    getTasksExternal.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksExternal.key = '/v1/taskmanager/api/Tasks/External';

export const getTasksMass = (
  queryParams?: GetTasksMassQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TaskListItemModel[]>> => {
  return Http.getRequest(
    getTasksMass.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksMass.key = '/v1/taskmanager/api/Tasks/Mass';

export const getTasksMassIsFinished = (
  queryParams?: GetTasksMassIsFinishedQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<boolean>> => {
  return Http.getRequest(
    getTasksMassIsFinished.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksMassIsFinished.key = '/v1/taskmanager/api/Tasks/Mass/IsFinished';

export const getTasksMeControllerWeb = (
  queryParams: GetTasksMeControllerWebQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListTaskPaginated>> => {
  return Http.getRequest(
    getTasksMeControllerWeb.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksMeControllerWeb.key = '/v1/taskmanager/api/Tasks/MeControllerWeb';

export const getTasksMeCreator = (
  queryParams: GetTasksMeCreatorQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListTaskPaginated>> => {
  return Http.getRequest(
    getTasksMeCreator.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksMeCreator.key = '/v1/taskmanager/api/Tasks/MeCreator';

export const getTasksMeExecutor = (
  queryParams: GetTasksMeExecutorQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListTaskPaginated>> => {
  return Http.getRequest(
    getTasksMeExecutor.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksMeExecutor.key = '/v1/taskmanager/api/Tasks/MeExecutor';

export const getTasksTaskGuid = (
  taskGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetTask>> => {
  return Http.getRequest(
    template(getTasksTaskGuid.key, { taskGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksTaskGuid.key = '/v1/taskmanager/api/Tasks/{taskGuid}';

/**
 *
 * Возвращает список комментариев к задаче и помечает их как прочитанные для текущего пользователя
 */
export const getTasksTaskGuidComments = (
  taskGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListTaskComment[]>> => {
  return Http.getRequest(
    template(getTasksTaskGuidComments.key, { taskGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksTaskGuidComments.key = '/v1/taskmanager/api/Tasks/{taskGuid}/Comments';

/**
 *
 * Возвращает информацию по комментарию
 */
export const getTasksTaskGuidCommentsCommentGuid = (
  taskGuid: string,
  commentGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListTaskComment>> => {
  return Http.getRequest(
    template(getTasksTaskGuidCommentsCommentGuid.key, {
      taskGuid,
      commentGuid,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksTaskGuidCommentsCommentGuid.key =
  '/v1/taskmanager/api/Tasks/{taskGuid}/Comments/{commentGuid}';

export const getTasksTaskGuidFiles = (
  taskGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListTaskFile[]>> => {
  return Http.getRequest(
    template(getTasksTaskGuidFiles.key, { taskGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksTaskGuidFiles.key = '/v1/taskmanager/api/Tasks/{taskGuid}/Files';

export const getTasksTaskGuidFilesExternal = (
  taskGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListExternalTaskMassFile[]>> => {
  return Http.getRequest(
    template(getTasksTaskGuidFilesExternal.key, { taskGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksTaskGuidFilesExternal.key =
  '/v1/taskmanager/api/Tasks/{taskGuid}/Files/External';

export const getTasksTaskGuidFilesFileGuid = (
  taskGuid: string,
  fileGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getTasksTaskGuidFilesFileGuid.key, { taskGuid, fileGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTasksTaskGuidFilesFileGuid.key =
  '/v1/taskmanager/api/Tasks/{taskGuid}/Files/{fileGuid}';

export const getTasksTaskGuidSubTasks = (
  taskGuid: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListTask[]>> => {
  return Http.getRequest(
    template(getTasksTaskGuidSubTasks.key, { taskGuid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksTaskGuidSubTasks.key = '/v1/taskmanager/api/Tasks/{taskGuid}/SubTasks';

export const getTasksTaskId = (
  taskId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetTask>> => {
  return Http.getRequest(
    template(getTasksTaskId.key, { taskId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksTaskId.key = '/v1/taskmanager/api/Tasks/{taskId}';

export const getTasksTaskIdHistory = (
  taskId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<HistoryItemModel[]>> => {
  return Http.getRequest(
    template(getTasksTaskIdHistory.key, { taskId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getTasksTaskIdHistory.key = '/v1/taskmanager/api/Tasks/{taskId}/History';

/**
 *
 * Создание доски
 */
export const postBoards = (
  requestBody: BoardCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postBoards.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postBoards.key = '/v1/taskmanager/api/Boards';

/**
 *
 * Создание колонки для доски
 */
export const postBoardsBoardGuidColumns = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  requestBody: BoardColumnCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postBoardsBoardGuidColumns.key, { boardGuid }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postBoardsBoardGuidColumns.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/Columns';

/**
 *
 * Добавляет сотрудника к списку сотрудников, имеющих доступ к указанной доске
 */
export const postBoardsBoardGuidEmployees = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  requestBody: BoardEmployeeAdd,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postBoardsBoardGuidEmployees.key, { boardGuid }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postBoardsBoardGuidEmployees.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/Employees';

export const postPositionsPositionIdCreatorPositionsLinks = (
  positionId: number,
  requestBody: PositionsLinkItemModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionsLinkItemModel>> => {
  return Http.postRequest(
    template(postPositionsPositionIdCreatorPositionsLinks.key, { positionId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postPositionsPositionIdCreatorPositionsLinks.key =
  '/v1/taskmanager/api/Positions/{positionId}/CreatorPositionsLinks';

export const postPositionsPositionIdObserverPositionsLinks = (
  positionId: number,
  requestBody: PositionsLinkItemModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PositionsLinkItemModel>> => {
  return Http.postRequest(
    template(postPositionsPositionIdObserverPositionsLinks.key, { positionId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postPositionsPositionIdObserverPositionsLinks.key =
  '/v1/taskmanager/api/Positions/{positionId}/ObserverPositionsLinks';

/**
 *
 * Создание задачи
 */
export const postTasks = (
  requestBody: TaskCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetTask>> => {
  return Http.postRequest(
    postTasks.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTasks.key = '/v1/taskmanager/api/Tasks';

export const postTasksExternal = (
  requestBody: ExternalTaskMassCreate[],
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string[]>> => {
  return Http.postRequest(
    postTasksExternal.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postTasksExternal.key = '/v1/taskmanager/api/Tasks/External';

export const postTasksExternalIsFinished = (
  requestBody: ExternalTaskIsFinished,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<IsFinishedExternalTask>> => {
  return Http.postRequest(
    postTasksExternalIsFinished.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postTasksExternalIsFinished.key =
  '/v1/taskmanager/api/Tasks/External/IsFinished';

export const postTasksMass = (
  requestBody: MassCreateTaskModel[],
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MassCreateTaskModel[]>> => {
  return Http.postRequest(
    postTasksMass.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postTasksMass.key = '/v1/taskmanager/api/Tasks/Mass';

export const postTasksMassChangeStatuses = (
  requestBody: MassChangeStatusToNewRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postTasksMassChangeStatuses.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTasksMassChangeStatuses.key =
  '/v1/taskmanager/api/Tasks/Mass/ChangeStatuses';

export const postTasksMassIsFinished = (
  requestBody: TaskMassIsFinishedRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetTaskMassIsFinished>> => {
  return Http.postRequest(
    postTasksMassIsFinished.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postTasksMassIsFinished.key = '/v1/taskmanager/api/Tasks/Mass/IsFinished';

/**
 *
 * Создание комментария в задаче
 */
export const postTasksTaskGuidComments = (
  taskGuid: string,
  requestBody: TaskCommentCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postTasksTaskGuidComments.key, { taskGuid }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTasksTaskGuidComments.key = '/v1/taskmanager/api/Tasks/{taskGuid}/Comments';

/**
 *
 * Загрузка файла для задачи
 */
export const postTasksTaskGuidFiles = (
  /**
   *
   * Guid задачи
   */
  taskGuid: string,
  requestBody: {
    /**
     *
     * - Format: binary
     */
    file: string;
  },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListTaskFile>> => {
  return Http.postRequest(
    template(postTasksTaskGuidFiles.key, { taskGuid }),
    undefined,
    objToForm(requestBody),
    undefined,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postTasksTaskGuidFiles.key = '/v1/taskmanager/api/Tasks/{taskGuid}/Files';

/**
 *
 * Изменение наименования доски
 */
export const putBoardsBoardGuid = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  requestBody: BoardUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoard>> => {
  return Http.putRequest(
    template(putBoardsBoardGuid.key, { boardGuid }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putBoardsBoardGuid.key = '/v1/taskmanager/api/Boards/{boardGuid}';

/**
 *
 * Изменение колонки
 */
export const putBoardsBoardGuidColumnsBoardColumnGuid = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  /**
   *
   * Guid колонки
   */
  boardColumnGuid: string,
  requestBody: BoardColumnUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoardColumn>> => {
  return Http.putRequest(
    template(putBoardsBoardGuidColumnsBoardColumnGuid.key, {
      boardGuid,
      boardColumnGuid,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putBoardsBoardGuidColumnsBoardColumnGuid.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/Columns/{boardColumnGuid}';

/**
 *
 * Перемещение между досками
 */
export const putBoardsBoardGuidColumnsBoardColumnGuidChangeBoard = (
  /**
   *
   * Guid доски, с которой перемещают
   */
  boardGuid: string,
  /**
   *
   * Guid колонки, которую перемещают
   */
  boardColumnGuid: string,
  requestBody: BoardColumnChangeBoard,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoardColumn>> => {
  return Http.putRequest(
    template(putBoardsBoardGuidColumnsBoardColumnGuidChangeBoard.key, {
      boardGuid,
      boardColumnGuid,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putBoardsBoardGuidColumnsBoardColumnGuidChangeBoard.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/Columns/{boardColumnGuid}/ChangeBoard';

/**
 *
 * Изменение списка участников доски
 */
export const putBoardsBoardGuidEmployees = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  requestBody: BoardEmployeeUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putBoardsBoardGuidEmployees.key, { boardGuid }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putBoardsBoardGuidEmployees.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/Employees';

/**
 *
 * Изменение признака архива у доски
 */
export const putBoardsBoardGuidIsArchive = (
  /**
   *
   * Guid доски
   */
  boardGuid: string,
  requestBody: BoardIsArchiveUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoard>> => {
  return Http.putRequest(
    template(putBoardsBoardGuidIsArchive.key, { boardGuid }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putBoardsBoardGuidIsArchive.key =
  '/v1/taskmanager/api/Boards/{boardGuid}/IsArchive';

/**
 *
 * Обновление данных по задаче на доске
 */
export const putBoardsTasksBoardGuidUpdateTaskGuid = (
  boardGuid: string,
  taskGuid: string,
  requestBody: BoardTaskUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoardTask>> => {
  return Http.putRequest(
    template(putBoardsTasksBoardGuidUpdateTaskGuid.key, {
      boardGuid,
      taskGuid,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putBoardsTasksBoardGuidUpdateTaskGuid.key =
  '/v1/taskmanager/api/Boards/Tasks/{boardGuid}/Update/{taskGuid}';

/**
 *
 * Перемещение задач в указанную колонку
 */
export const putBoardsTasksChangeColumn = (
  requestBody: BoardTasksChangeBoard,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListBoardTask[]>> => {
  return Http.putRequest(
    putBoardsTasksChangeColumn.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putBoardsTasksChangeColumn.key =
  '/v1/taskmanager/api/Boards/Tasks/ChangeColumn';

/**
 *
 * Изменение у указанных задач соисполнителей
 */
export const putTasksChangeCoExecutorsAndObservers = (
  requestBody: TasksChangeCoExecutorsAndObservers,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putTasksChangeCoExecutorsAndObservers.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTasksChangeCoExecutorsAndObservers.key =
  '/v1/taskmanager/api/Tasks/ChangeCoExecutorsAndObservers';

/**
 *
 * Изменение у указанных задач исполнителя
 */
export const putTasksChangeExecutor = (
  requestBody: TasksChangeExecutor,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putTasksChangeExecutor.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTasksChangeExecutor.key = '/v1/taskmanager/api/Tasks/ChangeExecutor';

/**
 *
 * Изменение у указанных задач настроек напоминаний
 */
export const putTasksChangeRemind = (
  requestBody: TasksChangeRemind,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putTasksChangeRemind.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTasksChangeRemind.key = '/v1/taskmanager/api/Tasks/ChangeRemind';

/**
 *
 * Изменение у указанных задач дат начала/завершения
 */
export const putTasksChangeStartFinishDates = (
  requestBody: TasksChangeStartFinishDates,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putTasksChangeStartFinishDates.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTasksChangeStartFinishDates.key =
  '/v1/taskmanager/api/Tasks/ChangeStartFinishDates';

/**
 *
 * Изменение у указанных задач статуса
 */
export const putTasksChangeStatus = (
  requestBody: TasksChangeStatus,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putTasksChangeStatus.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTasksChangeStatus.key = '/v1/taskmanager/api/Tasks/ChangeStatus';

/**
 *
 * Перевод указанных задач в статус Завершена/На проверке
 */
export const putTasksChangeStatusToLast = (
  requestBody: TasksChangeStatusToLast,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putTasksChangeStatusToLast.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTasksChangeStatusToLast.key = '/v1/taskmanager/api/Tasks/ChangeStatusToLast';

/**
 *
 * Обновление задачи
 */
export const putTasksTaskGuid = (
  /**
   *
   * Guid задачи
   */
  taskGuid: string,
  requestBody: TaskUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetTask>> => {
  return Http.putRequest(
    template(putTasksTaskGuid.key, { taskGuid }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTasksTaskGuid.key = '/v1/taskmanager/api/Tasks/{taskGuid}';

export const putTasksTaskGuidChangeStatus = (
  taskGuid: string,
  requestBody: StatusModel,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetListTask>> => {
  return Http.putRequest(
    template(putTasksTaskGuidChangeStatus.key, { taskGuid }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putTasksTaskGuidChangeStatus.key =
  '/v1/taskmanager/api/Tasks/{taskGuid}/ChangeStatus';
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};
export const _CONSTANT1 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/plain',
  },
};
export const _CONSTANT2 = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'text/plain',
  },
};
