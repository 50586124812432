import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  useGetMarketingSurfaceTypeQuery,
  useGetMarketingTypeQuery,
} from 'shared/api/marketing-surface/rtk/marketingSurfaceProviderApi';
import {
  AutocompliteWithController,
  TextFieldWithController,
} from 'shared/fields-with-contoller';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { EMPT_ARR } from 'widgets/training/filter/constants';

import {
  DialogFormMarketingSurfaceProps,
  FormMarketingSurfaceValue,
} from './types';
import { SCHEME_VALIDATION_MARKETING_SURFACE_FORM } from './validation';

const INITIAL_VALUE_MARKETING_SURFACE_FORM: FormMarketingSurfaceValue = {
  advertisingType: null,
  surfaceType: null,
  description: '',
  width: '',
  height: '',
};

export const DialogFormMarketingSurface: FC<
  DialogFormMarketingSurfaceProps
> = ({
  isOpen,
  onClose,
  onSubmitForm,
  isLoading,
  initialValue = INITIAL_VALUE_MARKETING_SURFACE_FORM,
  variant = 'created',
}) => {
  const { control, handleSubmit, reset } = useForm<FormMarketingSurfaceValue>({
    defaultValues: initialValue,
    resolver: yupResolver(SCHEME_VALIDATION_MARKETING_SURFACE_FORM),
  });

  const {
    data: marketingSurfaceTypeOptions,
    isLoading: isLoadingMarketingSurfaceTypeOptions,
  } = useGetMarketingSurfaceTypeQuery();
  const {
    data: marketingAdvertinsingTypeOptions,
    isLoading: isLoadingMarketingTypeOptions,
  } = useGetMarketingTypeQuery();

  useEffect(() => {
    reset(initialValue);
  }, [initialValue]);

  return (
    <DialogForForm
      component={'form'}
      head={
        <DialogTitleForForm
          title={
            variant === 'created'
              ? 'Создать рекламную поверхность'
              : 'Редактировать рекламную поверхность'
          }
        />
      }
      onSubmit={handleSubmit(onSubmitForm)}
      open={isOpen}
    >
      <Grid
        display={'flex'}
        flexDirection={'column'}
        gap={2}
      >
        <AutocompliteWithController
          control={control}
          name={'advertisingType'}
          textFieldProps={{ label: 'Тип реклмы' }}
          loading={isLoadingMarketingTypeOptions || isLoading}
          disabled={isLoadingMarketingTypeOptions}
          options={marketingAdvertinsingTypeOptions || EMPT_ARR}
          renderOption={(params, option) => (
            <Typography
              {...params}
              key={option.guid}
            >
              {option.title}
            </Typography>
          )}
          isOptionEqualToValue={(option, value) => option.guid === value.guid}
          getOptionLabel={(option) => option.title}
        />
        <AutocompliteWithController
          control={control}
          name={'surfaceType'}
          disabled={isLoadingMarketingSurfaceTypeOptions || isLoading}
          loading={isLoadingMarketingSurfaceTypeOptions}
          options={marketingSurfaceTypeOptions || EMPT_ARR}
          textFieldProps={{ label: 'Тип поверхности' }}
          renderOption={(params, option) => (
            <Typography
              {...params}
              key={option.guid}
            >
              {option.title}
            </Typography>
          )}
          isOptionEqualToValue={(option, value) => option.guid === value.guid}
          getOptionLabel={(option) => option.title}
        />

        <Grid
          display={'flex'}
          flexDirection={['column', 'row']}
          justifyContent={'space-between'}
          gap={[2, 4]}
        >
          <TextFieldWithController
            control={control}
            disabled={isLoading}
            type='number'
            name='width'
            label={'Ширина'}
          />
          <TextFieldWithController
            disabled={isLoading}
            control={control}
            type='number'
            name='height'
            label={'Высота'}
          />
        </Grid>
        <TextFieldWithController
          disabled={isLoading}
          control={control}
          multiline
          maxRows={3}
          rows={3}
          minRows={3}
          name='description'
          label='Описание'
        />
      </Grid>
      <ContainerActionsForm>
        <Button
          disabled={isLoading}
          type='submit'
          variant='contained'
        >
          Сохранить
        </Button>
        <Button
          onClick={onClose}
          variant='contained'
          color='customGrey'
        >
          Закрыть
        </Button>
      </ContainerActionsForm>
    </DialogForForm>
  );
};
