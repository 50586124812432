import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

import { ContextMenuValue } from './types';

const ReactContextContextMenu = createContext<ContextMenuValue>(
  {} as ContextMenuValue
);

export const useContextMenu = () => useContext(ReactContextContextMenu);

export const ContextMenuProvider: FC<PropsWithChildren> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowAnchorEl, setArrowAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  const handleClickOnButton = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <ReactContextContextMenu.Provider
      value={{
        handleClose,
        handleClickOnButton,
        isOpen,
        anchorEl,
        arrowAnchorEl,
        setArrowAnchorEl,
      }}
    >
      {children}
    </ReactContextContextMenu.Provider>
  );
};
