import { useStore } from 'effector-react';

import {
  OnchangeTaxRate,
  UiDocumentFormTaxRatesAutocomplite,
} from 'shared/ui/UiDocumentFormTaxRatesAutocomplite';

import { AxiosError } from 'axios';
import { createEffect, createStore } from 'effector';
import { getTaxRatesCalculated } from 'shared/api/services-document-flow/services';
import { TaxRateCalculatedResponse } from 'shared/api/services-document-flow/types';

import { useEffect } from 'react';
import { DocumentFormEditTaxRatesAutocomplite } from '../document-form-edit-tax-rates-autocomplite/DocumentFormEditTaxRatesAutocomplite';
import { documentFormEdit } from '../model';

export const fetchCloseTaxRatesEditOptionsFx = createEffect<
  typeof getTaxRatesCalculated,
  AxiosError<Error>
>(getTaxRatesCalculated);

const $taxRateEditOptions = createStore<TaxRateCalculatedResponse[]>([]).on(
  fetchCloseTaxRatesEditOptionsFx.doneData,
  (_, response) => response.data
);

export const DocumentFormCloseTaxRatesAutocomplite = () => {
  const options = useStore($taxRateEditOptions);
  const valueTaxRate = documentFormEdit.formSelects.useSelectValueOfKey(
    'closeDocumentTaxRate'
  );

  const valueTaxSum = documentFormEdit.formSelects.useSelectValueOfKey(
    'closeDocumentTaxSum'
  );
  const closeDocumentSum =
    documentFormEdit.formSelects.useSelectValueOfKey('closeDocumentSum');
  const value =
    (valueTaxRate &&
      (valueTaxSum || valueTaxSum === 0) && {
        ...valueTaxRate,
        sum: valueTaxSum,
      }) ||
    null;

  const handleChangeTaxRate: OnchangeTaxRate = (event, value) => {
    if (typeof value === 'string' || typeof value === 'number') {
      documentFormEdit.api.setFiled({
        key: 'closeDocumentTaxSum',
        value: value ? value : null,
      });
      documentFormEdit.api.setFiled({
        key: 'closeDocumentTaxRate',
        value: null,
      });
    } else {
      documentFormEdit.api.setFiled({
        key: 'closeDocumentTaxRate',
        value: value ? { ...value, title: value.title || '' } : null,
      });
      documentFormEdit.api.setFiled({
        key: 'closeDocumentTaxSum',
        value: value?.sum,
      });
    }
  };

  useEffect(() => {
    if (options.length > 0 && value) {
      const find = options.find((item) => item.id === value.id);

      if (find?.sum !== value.sum) {
        documentFormEdit.api.setFiled({
          key: 'closeDocumentTaxSum',
          value: find ? find.sum : null,
        });
      }
    }
  }, [options]);

  const isTaxCalculateRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isTaxCalculateRequired'
    );
  const isCloseDocumentSumEquals =
    documentFormEdit.formSelects.useSelectValueOfKey(
      'isCloseDocumentSumEquals'
    );

  return !isCloseDocumentSumEquals ? (
    <UiDocumentFormTaxRatesAutocomplite
      disabled={!isTaxCalculateRequired}
      onChange={handleChangeTaxRate}
      onFocus={() => {
        closeDocumentSum &&
          fetchCloseTaxRatesEditOptionsFx({ Sum: closeDocumentSum });
      }}
      options={options}
      value={value || valueTaxSum}
    />
  ) : (
    <DocumentFormEditTaxRatesAutocomplite disabled />
  );
};
