import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  useLazyGetAutorunTriggersQuery,
  useLazyGetCountdownFieldsQuery,
} from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import {
  AutocompliteWithController,
  TextFieldWithController,
} from 'shared/fields-with-contoller';
import { SwitchWithController } from 'shared/fields-with-contoller/switch-with-controller';

import { LoadingButton } from '@mui/lab';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { DEFAULT_VALUES } from './constants';
import { OENING_TEMPLATE_VALIDATE } from './sheme';
import {
  DialogFormOpeningTemplatesProps,
  FormOpeningTemplatesValue,
} from './types';

const CREATED_TITLE = 'Создание шаблона';
const EDIT_TITLE = 'Редактирование шаблона';

export const DialogFormOpeningTemplates: FC<
  DialogFormOpeningTemplatesProps
> = ({
  isOpen,
  onClose,
  onSumbit,
  variant = 'created',
  initialValue,
  isLoadingInitialValue,
  isLoadingSubmit,
}) => {
  const [
    fetchOptionsCountdownFields,
    {
      data: optionsCountdownFields,
      isLoading: isLoadingOptionsCountdownFields,
    },
  ] = useLazyGetCountdownFieldsQuery();

  const [
    fetchOptionAutorunTriggers,
    {
      data: optionsAutorunTriggers,
      isLoading: isLoadingOptionsAutorunTriggers,
    },
  ] = useLazyGetAutorunTriggersQuery();

  const { control, handleSubmit, reset } = useForm<FormOpeningTemplatesValue>({
    defaultValues: initialValue || DEFAULT_VALUES,
    resolver: yupResolver(OENING_TEMPLATE_VALIDATE),
  });

  /* Запрашиваем опции при открытии модального ок на */
  /* Сбрасываем значение формы при закрытии */

  useEffect(() => {
    if (isOpen) {
      fetchOptionsCountdownFields(undefined, true);
      fetchOptionAutorunTriggers(undefined, true);
    }

    if (!isOpen) {
      reset(DEFAULT_VALUES);
    }
  }, [isOpen]);

  useEffect(() => {
    if (initialValue) {
      reset(initialValue);
    } else {
      reset(DEFAULT_VALUES);
    }
  }, [initialValue]);

  const isAutorun = useWatch({ control, name: 'isAutorun' });

  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      component={'form'}
      onSubmit={handleSubmit(onSumbit, (error) => {
        console.error('error :', error);
      })}
      head={
        <DialogTitleForForm
          title={variant === 'created' ? CREATED_TITLE : EDIT_TITLE}
        />
      }
    >
      <Grid
        display={'flex'}
        flexDirection={'column'}
        gap={2}
      >
        <TextFieldWithController
          disabled={isLoadingInitialValue}
          control={control}
          name='title'
          label='Название'
        />
        <AutocompliteWithController
          control={control}
          name='countdownField'
          isOptionEqualToValue={(option, value) => option.guid === value.guid}
          renderOption={(props, option) => {
            return (
              <Typography
                {...props}
                key={option.guid}
              >
                {option.title}
              </Typography>
            );
          }}
          getOptionLabel={(option) => option.title || 'без названия'}
          options={optionsCountdownFields || []}
          loading={isLoadingOptionsCountdownFields || isLoadingInitialValue}
          textFieldProps={{
            label: 'Отсчет дней от',
          }}
        />

        <SwitchWithController
          control={control}
          name='isAutorun'
          size='medium'
          formControlLabelProps={{
            label: 'Автоматический запуск',
            labelPlacement: 'end',
          }}
        />
        <AutocompliteWithController
          control={control}
          name='autorunTrigger'
          disabled={!isAutorun}
          isOptionEqualToValue={(option, value) => option.guid === value.guid}
          renderOption={(props, option) => {
            return (
              <Typography
                {...props}
                key={option.guid}
              >
                {option.title}
              </Typography>
            );
          }}
          getOptionLabel={(option) => option.title || 'без названия'}
          options={optionsAutorunTriggers || []}
          loading={isLoadingOptionsAutorunTriggers || isLoadingInitialValue}
          textFieldProps={{
            label: 'Триггер Автоматического запуска',
          }}
        />
      </Grid>
      <ContainerActionsForm>
        <LoadingButton
          type='submit'
          variant='contained'
          color='primary'
          disabled={isLoadingInitialValue || isLoadingSubmit}
          loading={isLoadingSubmit}
        >
          Сохранить
        </LoadingButton>
        <Button
          variant='contained'
          color='customGrey'
          onClick={onClose}
        >
          Закрыть
        </Button>
      </ContainerActionsForm>
    </DialogForForm>
  );
};
