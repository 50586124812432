import { EmployeeInfoView } from 'entities/adaptation/employee-info-view';
import { FC } from 'react';
import { useGetAdaptationProcessesEmployeByGuidQuery } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { EmployeeInfoViewWithFetchDataProps } from './types';

export const EmployeeInfoViewWithFetchData: FC<
  EmployeeInfoViewWithFetchDataProps
> = ({ employeeGuid }) => {
  const { data: employeeInfo, isLoading: isLoadingEmployeeInfo } =
    useGetAdaptationProcessesEmployeByGuidQuery(
      {
        params: {
          employeGuid: employeeGuid as string,
        },
      },
      { skip: !employeeGuid }
    );

  const { name, position, isVacation, retail } = employeeInfo || {};

  return (
    <EmployeeInfoView
      isLoading={isLoadingEmployeeInfo}
      name={name || '-'}
      positionTitle={position?.title || '-'}
      isVacation={isVacation}
      retailTitle={retail?.title}
    />
  );
};
