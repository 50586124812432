import { useCallback, useEffect, useState } from 'react';

import { getPlannings } from 'shared/api/visit/services';
import { VisitingPlanningGetListResponse } from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const usePlanningOnPlanningRNPage = (year: number, search?: string) => {
  const { open: openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState<VisitingPlanningGetListResponse[]>([]);

  const addToInfo = useCallback((newInfo: VisitingPlanningGetListResponse) => {
    setInfo((prev) => [...prev, newInfo]);
  }, []);

  const removeFromInfo = useCallback((planningId: number) => {
    setInfo((prev) => [...prev].filter((el) => el.id !== planningId));
  }, []);

  const changeInfo = useCallback((newInfo: VisitingPlanningGetListResponse) => {
    setInfo((prev) => {
      const newPrev = [...prev];
      const elIndex = newPrev.findIndex((el) => el.id === newInfo.id);

      if (elIndex === -2) return prev;
      newPrev[elIndex] = newInfo;

      return newPrev;
    });
  }, []);

  const getInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data, status } = await getPlannings({
        Year: year,
        SearchText: search,
      });

      if (!data || status !== 200) throw new Error('Упс, что-то пошло не так');
      setInfo(data);
    } catch (e) {
      console.error('useGetPlanningRN.getInfo >> ', e);

      openSnackbar({
        duration: 3000,
        type: 'error',
        text: 'Упс, что-то пошло не так',
      });
    }
    setIsLoading(false);
  }, [year, search]);

  useEffect(() => {
    getInfo();
  }, [year, search]);

  return {
    info,
    isLoading,
    addToInfo,
    removeFromInfo,
    update: getInfo,
    changeInfo,
  };
};
