import { useGetTrainingProcessMyTrainingQuery } from 'shared/api/traning/rtk/trainingProviderApi';
import {
  SettingsFieldsFilter,
  useUrlStateTrainingFilter,
} from 'widgets/training/filter';

import {
  MyTrainingAdaptiveTable,
  useTableCellsMyTreiningFromLocalStorage,
} from 'widgets/training/training-table/my-training-table';

import { LayoutWithManagerBarTraining } from './layout';

const SETTINGS_FIELDS_FILTER: SettingsFieldsFilter = {
  showTrainer: true,
  showDate: true,
  showRetails: false,
  showStatuses: true,
  showEmploye: true,
  showThemeTraining: true,
};

export const MyTrainingPage = () => {
  /* Table fields */

  const [fields, setFields, visibleFields] =
    useTableCellsMyTreiningFromLocalStorage();

  const { filter, onChangeLimit, onChangePage } = useUrlStateTrainingFilter();
  const { data, isLoading, isFetching } = useGetTrainingProcessMyTrainingQuery({
    ...filter,
    Limit: filter?.Limit || 20,
    Page: filter?.Page || 1,
  });

  if (!isLoading && !isFetching && (!data || !data.data)) {
    return <></>;
  }

  return (
    <LayoutWithManagerBarTraining settingsFilter={SETTINGS_FIELDS_FILTER}>
      <MyTrainingAdaptiveTable
        isLoading={isLoading}
        isUpdate={isFetching}
        data={data?.data || []}
        fields={fields}
        visibleFields={visibleFields}
        onChangeFields={setFields}
        count={data?.count || 1000}
        page={filter?.Page || 1}
        limit={filter?.Limit || 20}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
      />
    </LayoutWithManagerBarTraining>
  );
};
