import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { APTEKALEGKO_BANNERS_BRANDS_HELPER_LINKS } from 'entities/apteka-legko/banners/brands/links';
import { APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS } from 'entities/apteka-legko/banners/sliders/links';
import { ComponentWithUserPermission } from 'entities/user';
import { useMemo } from 'react';
import { E_ModuleGuids } from 'shared/moduleGuids';
import { ImagePrevieSvg } from 'shared/svg/ImagePrevieSvg';

import { LayoutPage } from 'shared/ui/layouts/layout-page';
import {
  NavigationCardProps,
  NavigationCardStack,
} from 'shared/ui/navigation-card';

type NavigationCardPropsWithPermisson = NavigationCardProps & {
  permissionGuid: E_ModuleGuids;
};

export const PageMainBanners = () => {
  const navItems: NavigationCardPropsWithPermisson[] = useMemo(() => {
    return [
      {
        to: APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS.createListWithParams({
          DateStart: dayjs().startOf('M').format(),
        }),
        title: 'Слайдер',
        Icon: <ImagePrevieSvg fontSize={'large'} />,
        permissionGuid: E_ModuleGuids.aptekaLegkoBannerSlider,
      },
      {
        to: APTEKALEGKO_BANNERS_BRANDS_HELPER_LINKS.bannersMain,
        title: 'Бренды',
        Icon: <ImagePrevieSvg fontSize={'large'} />,
        permissionGuid: E_ModuleGuids.aptekaLegkoBannerBrand,
      },
    ];
  }, []);

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h5'
          fontWeight={'bold'}
        >
          Баннеры
        </Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <NavigationCardStack.Container>
          {navItems.map((props, index) => {
            return (
              <ComponentWithUserPermission
                key={index}
                moduleGuid={props.permissionGuid}
              >
                {({ isWritable, isLoadingPermission }) => {
                  return (
                    <NavigationCardStack.Card
                      {...props}
                      disabledLink={!isWritable || isLoadingPermission}
                      title={
                        !isWritable && !isLoadingPermission
                          ? `${props.title}(нет доступа)`
                          : props.title
                      }
                    />
                  );
                }}
              </ComponentWithUserPermission>
            );
          })}
        </NavigationCardStack.Container>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
