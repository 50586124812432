import { useEffect, useState } from 'react';
import { createChecker, createFilterOnCheckers } from './utils';

export const useDependentFormFields = <
  O extends { guid: string; parentGuids: string[] },
  K extends string,
>(params: {
  /**
   *
   *  Обект со всеми options фильтра (с бэка)
   *
   *  Ключи: объекта с options(defaultFilter), значений формы(keysForm) и  состояния формы(selectedValues) - должны совпадать
   *
   *
   * defaultFilter[keysForm[1]] => O[];
   *
   * selectedValues[keysForm[1]] => O[]
   *
   * {
   *
   *    key1 : Record<K, O[]>,
   *
   *    key2 : Record<K, O[]>,
   *
   *    key3: ....
   *
   * }
   *
   */
  defaultFilter: Record<K, O[]> | undefined;
  /** Ключи: объекта с options(defaultFilter), значений формы(keysForm) и  состояния формы(selectedValues) - должны совпадать
   *
   *
   * defaultFilter[keysForm[1]] => O[];
   *
   * selectedValues[keysForm[1]] => O[]
   *
   *
   * Важен порядок ключей!
   *
   * [key1, key2, key3, key4, ...other]
   *
   * key1 - ни как не фильтруется
   *
   * key2 - зависит от key1
   *
   * key3 - зависит от key1, key2
   *
   * и т.д.
   *
   */
  keysForm: K[];
  /** Объект с текушим состоянием формы
   *
   *  Ключи: объекта с options(defaultFilter), значений формы(keysForm) и  состояния формы(selectedValues) - должны совпадать
   *
   *
   * defaultFilter[keysForm[1]] => O[];
   *
   * selectedValues[keysForm[1]] => O[]
   */
  selectedValues: Record<K, O[]>;
  /**
   * Функция изменения состояния
   */
  setValue: (key: K, value: O[]) => void;
}) => {
  const [filterState, setFilterState] = useState(params.defaultFilter);

  useEffect(() => {
    setFilterState(params.defaultFilter);
  }, [params.defaultFilter, setFilterState]);

  const createHandlerDependentChange =
    (key: K) => (e: React.SyntheticEvent<Element, Event>, value: O[]) => {
      if (!filterState || !params.defaultFilter) return;

      const valueKeysForm = params.keysForm;

      const selectedValues: Record<K, O[]> = {
        ...params.selectedValues,
        [key]: value,
      };
      const allowedNewFilterOptions = {
        ...params.defaultFilter,
      };

      const checkers: ReturnType<typeof createChecker>[] = [];

      for (let index = 1; index < valueKeysForm.length; index++) {
        const key = valueKeysForm[index];
        const keyPrev = valueKeysForm[index - 1];
        const checker = createChecker(selectedValues[keyPrev]);

        checkers.push(checker);

        allowedNewFilterOptions[key] = allowedNewFilterOptions[key].filter(
          createFilterOnCheckers(checkers)
        );

        selectedValues[key] = selectedValues[key].filter((value) =>
          allowedNewFilterOptions[key].some(
            (option) => option.guid === value.guid
          )
        );
      }
      /* Записываем новые доступные фильтры в стейт */
      setFilterState(allowedNewFilterOptions);
      /* Записываем обновленные выбранные значения(с учетом доступных) в стейт формы */
      for (let index = 0; index < valueKeysForm.length; index++) {
        const key = valueKeysForm[index];

        params.setValue(key, selectedValues[key]);
      }
    };

  return {
    createHandlerDependentChange,
    filterState,
  };
};
