import { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { Grid } from '@mui/material';
import { TextFieldWithController } from 'shared/fields-with-contoller';
import { SwitchWithController } from 'shared/fields-with-contoller/switch-with-controller';
import { NUMBER_INPUT_PROPS } from '../../constants';
import { ContainerTemplateTaskReminderRowInputsProps } from './types';

export const ContainerTemplateTaskReminderRowInputs: FC<
  ContainerTemplateTaskReminderRowInputsProps
> = ({ SwitchProps, TextFieldProps, control }) => {
  const isCheck = useWatch({ control, name: SwitchProps.name });

  return (
    <Grid
      display={'flex'}
      flexDirection={['column', 'row']}
      justifyContent={'space-between'}
      gap={4}
      sx={{
        '& > *:nth-of-type(1)': {
          flexBasis: ['100%', '50%'],
        },
      }}
    >
      <SwitchWithController
        control={control}
        name={SwitchProps.name}
        size='medium'
        formControlLabelProps={{
          label: SwitchProps.label,
        }}
      />
      <TextFieldWithController
        control={control}
        type='number'
        disabled={!isCheck}
        InputProps={NUMBER_INPUT_PROPS}
        name={TextFieldProps.name}
        label={TextFieldProps.label}
      />
    </Grid>
  );
};
