// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useMemo } from 'react';

import {
  DepartmentGetListResponse,
  EmployeePlanningVisitorsGetListResponse,
  EventGetListResponse,
  RegionGetListResponse,
  VisitingPlanningGetListResponse,
} from 'shared/api/visit/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';

import { CreateConferenceEventFormFields } from 'widgets/visits/forms/create-conference-event-form';
import { CreateOtherEventFormFields } from 'widgets/visits/forms/create-other-event-form';
import { CreatePlanningRNFormFields } from 'widgets/visits/forms/create-planning-rn-form';
import { EditPlanningRNFormFields } from 'widgets/visits/forms/edit-planning-rn-form';

import { theme } from 'shared/theme/theme';
import {
  ALL_YEAR_MONTH,
  CELL_SX,
  DEPARTMENT_CELL_SX,
  EmptyPlanningRNCell,
  InfoPlanningRNCell,
  PlanningRNTableHead,
} from '../planning-rn-table-components';
import { EventPlanningRNCell } from '../planning-rn-table-components/event-planning-rn-cell/EventPlanningRNCell';
import { LEFT_STIKY_CELL_SX } from '../planning-rn-table-components/style';

class CellInfoConventorForPlanningRN {
  private month: Dayjs;
  private departmentId: number;

  constructor(month: Dayjs, departmentId: number) {
    this.departmentId = departmentId;
    this.month = month;
  }

  createInitCreatePlanning(): Partial<CreatePlanningRNFormFields> {
    return {
      departmentId: this.departmentId,
      month: this.month,
    };
  }

  createInitCreateConferenceEvent(): Partial<CreateConferenceEventFormFields> {
    const startDate = dayjs(this.month).set('D', 1);
    const endDate = dayjs(this.month).set('D', this.month.daysInMonth());

    return {
      dateStart: startDate.format(DATE_FORMATS.server),
      dateFinish: endDate.format(DATE_FORMATS.server),
      departmentId: this.departmentId,
    };
  }

  createInitCreateOtherEvent(): Partial<CreateOtherEventFormFields> {
    const startDate = dayjs(this.month).set('D', 1);
    const endDate = dayjs(this.month).set('D', this.month.daysInMonth());

    return {
      dateStart: startDate.format(DATE_FORMATS.server),
      dateEnd: endDate.format(DATE_FORMATS.server),
    };
  }
}

const getMonthEvent = (
  events: EventGetListResponse[],
  currentDepartmentId: number,
  monthNumber: number
) => {
  const confEvent = events.find((event) => {
    const isCurMonth = dayjs(event.startDate).get('M') === monthNumber;

    const isCurDep =
      event.department && currentDepartmentId === event.department.id;

    return isCurDep && isCurMonth && event.isConference;
  });

  const allDepEvent = events.find((event) => {
    const isCurMonth = dayjs(event.startDate).get('M') === monthNumber;

    return isCurMonth && event.isVisitingBlocked;
  });

  const monthEvent = events.find((event) => {
    const isCurMonth = dayjs(event.startDate).get('M') === monthNumber;

    const isCurDep =
      event.department && currentDepartmentId === event.department.id;

    return isCurMonth && !event.isVisitingBlocked && isCurDep;
  });

  return confEvent || allDepEvent || monthEvent || null;
};

const getMonthPlanningInfo = (
  info: VisitingPlanningGetListResponse[],
  departmentId: number,
  currentMonthNumber: number
) => {
  const monthNum = currentMonthNumber + 1;

  return info.find(
    (el) => el.month === monthNum && el.department.id === departmentId
  );
};

const getRegionByDepartment = (
  regions: RegionGetListResponse[],
  department: DepartmentGetListResponse
): RegionGetListResponse | null => {
  return regions.find((el) => el.id === department.regionId) || null;
};

const getCountDepartmentsInRegion = (
  departments: DepartmentGetListResponse[],
  regionId?: number | null
): number => {
  return departments.filter((department) => department.regionId === regionId)
    .length;
};

const isNextRegionOther = (
  dep: DepartmentGetListResponse[],
  i: number
): boolean => {
  return dep[i].regionId !== dep[i + 1]?.regionId;
};

export interface PlanningRNTableProps {
  departments: DepartmentGetListResponse[];
  regions: RegionGetListResponse[];
  visitors: EmployeePlanningVisitorsGetListResponse[];

  plannings: VisitingPlanningGetListResponse[];
  events: EventGetListResponse[];

  selectedYear: number;

  onDeleteEvent: (event: EventGetListResponse) => void;
  onEditEvent: (event: EventGetListResponse) => void;
  onCreateOtherEvent: (initData: Partial<CreateOtherEventFormFields>) => void;
  onCreateConverenceEvent: (
    initData: Partial<CreateConferenceEventFormFields>
  ) => void;
  onCreatePlanning: (initData: Partial<CreatePlanningRNFormFields>) => void;
  onCreateVisit: (initData: VisitingPlanningGetListResponse) => void;
  onDeletePlanning: (planning: VisitingPlanningGetListResponse) => void;
  onEditPlanning: (initData: EditPlanningRNFormFields & { id: number }) => void;
}
export const PlanningRNTable: FC<PlanningRNTableProps> = ({
  departments,
  plannings,
  regions,
  selectedYear,
  events,
  onDeleteEvent,
  onEditEvent,
  visitors,
  onCreateOtherEvent,
  onCreateConverenceEvent,
  onCreatePlanning,
  onCreateVisit,
  onDeletePlanning,
  onEditPlanning,
}) => {
  /* Departments */
  const sortedDepartments = useMemo(() => {
    return departments.sort((a, b) => b.regionId! - a.regionId!);
  }, [departments]);
  /* ===== */

  /* Regions render */
  const renderedReg: number[] = [];
  const getHasRenderReg = (regId?: number) => {
    return renderedReg.some((renderedRegId) => renderedRegId === regId);
  };
  /* ===== */

  /* Event render */
  const renderedEvents: number[] = [];
  const hasEventRendered = (eventId?: number) => {
    return renderedEvents.some((el) => el === eventId);
  };
  /* ===== */

  return (
    <TableContainer
      sx={{ maxHeight: 'calc(100vh - 200px)', height: '100%' }}
      component={Paper}
      variant='outlined'
    >
      <Table size='small'>
        <PlanningRNTableHead />
        <TableBody>
          {sortedDepartments.map((dep, i) => {
            const countInfoInReg = plannings.filter(
              (el) => el.department.id === dep.id
            ).length;
            const hasRender = getHasRenderReg(dep.regionId);

            if (!hasRender) renderedReg.push(dep.regionId!);

            const region = getRegionByDepartment(regions, dep);

            return (
              <TableRow
                key={dep.id}
                sx={{
                  position: 'relative',
                  height: 34,
                  borderBottom: '1px solid black',
                  borderColor: theme.palette.customGrey.main,
                  borderBottomWidth: isNextRegionOther(sortedDepartments, i)
                    ? 3
                    : 1,
                }}
                hover
              >
                {/* Регион */}
                {!hasRender && (
                  <TableCell
                    sx={{ background: 'white' }}
                    rowSpan={getCountDepartmentsInRegion(
                      departments,
                      region?.id
                    )}
                  >
                    {region?.title}
                  </TableCell>
                )}
                {/* ===== */}

                {/* Сеть */}
                <TableCell sx={DEPARTMENT_CELL_SX}>{dep.title}</TableCell>
                {/* ===== */}

                {/* Посещения */}
                {ALL_YEAR_MONTH.map((month, i) => {
                  /* Event */
                  const even = getMonthEvent(events, dep.id, i);

                  if (even) {
                    if (even.isVisitingBlocked) {
                      const hasRendered = hasEventRendered(even.id);

                      if (!hasRendered) {
                        renderedEvents.push(even.id);

                        return (
                          <EventPlanningRNCell
                            rowSpan={departments.length + 1}
                            key={even.id}
                            event={even}
                            onDelete={() => {
                              onDeleteEvent(even);
                            }}
                            onEdit={() => {
                              onEditEvent(even);
                            }}
                          />
                        );
                      } else return null;
                    } else {
                      return (
                        <EventPlanningRNCell
                          key={even.id}
                          event={even}
                          onDelete={() => {
                            onDeleteEvent(even);
                          }}
                          onEdit={() => {
                            onEditEvent(even);
                          }}
                        />
                      );
                    }
                  }
                  /* ===== */

                  const monthInDayjs = dayjs()
                    .set('year', selectedYear)
                    .set('month', i);

                  const currentInfo = getMonthPlanningInfo(
                    plannings,
                    dep.id,
                    i
                  );

                  const currentVisitor =
                    (currentInfo &&
                      visitors.find(
                        (el) => el.id === currentInfo.visitor.id
                      )) ||
                    currentInfo?.visitor;

                  const initCreator = new CellInfoConventorForPlanningRN(
                    monthInDayjs,
                    dep.id
                  );

                  if (!currentInfo || !currentVisitor)
                    return (
                      <EmptyPlanningRNCell
                        key={month + dep.id}
                        onCreateOtherEvent={() => {
                          onCreateOtherEvent(
                            initCreator.createInitCreateOtherEvent()
                          );
                        }}
                        onCreateConverenceEvent={() => {
                          onCreateConverenceEvent(
                            initCreator.createInitCreateConferenceEvent()
                          );
                        }}
                        onCreateVisit={() => {
                          onCreatePlanning(
                            initCreator.createInitCreatePlanning()
                          );
                        }}
                      />
                    );
                  else
                    return (
                      <InfoPlanningRNCell
                        onCreateVisit={() => {
                          onCreateVisit(currentInfo);
                        }}
                        visitor={currentVisitor}
                        key={month + dep.id}
                        info={currentInfo}
                        onDelete={() => {
                          onDeletePlanning(currentInfo);
                        }}
                        onEdit={() => {
                          onEditPlanning({
                            id: currentInfo.id,
                            homiesIds: getIdsFromArray(currentInfo.members),
                            departmentId: currentInfo.department.id,
                            periodFinish: currentInfo.periodFinish,
                            periodStart: currentInfo.periodStart,
                            visitorId: currentInfo.visitor.id,
                          });
                        }}
                      />
                    );
                })}
                {/* ===== */}
                <TableCell
                  align='right'
                  sx={LEFT_STIKY_CELL_SX}
                >
                  {countInfoInReg}
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell />
            <TableCell sx={DEPARTMENT_CELL_SX}>Мероприятия</TableCell>
            {ALL_YEAR_MONTH.map((_, i) => {
              const event = events
                .filter((event) => !event.isConference)
                .find((event) => {
                  const monthNumber = dayjs(event.startDate).get('M');

                  return monthNumber === i;
                });

              if (event) {
                if (!hasEventRendered(event.id))
                  return (
                    <EventPlanningRNCell
                      key={event.id}
                      event={event}
                      onDelete={() => {
                        onDeleteEvent(event);
                      }}
                      onEdit={() => {
                        onEditEvent(event);
                      }}
                    />
                  );
                else return <></>;
              } else {
                return (
                  <TableCell
                    key={i}
                    sx={CELL_SX}
                    colSpan={2}
                  >
                    -
                  </TableCell>
                );
              }
            })}
            <TableCell
              sx={LEFT_STIKY_CELL_SX}
              align='right'
            >
              {events.length}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
