import { Alert, AlertTitle } from '@mui/material';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';

import { ErrorResponse } from 'shared/api/services-document-flow/types';

import { documentFormEditApi } from '../api';

const $error = createStore<ErrorResponse | null>(null);
const deleteError = createEvent();

$error.on(
  documentFormEditApi.secondValitation.failData,
  (_, response) => response.response?.data
);
$error.reset(deleteError);

export const DocumentFormEditErrorSecondValidation = () => {
  const error = useStore($error);

  if (!error) return null;

  return (
    <Alert
      severity='error'
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        transitionDuration: 2,
      }}
      onClose={() => deleteError()}
    >
      <AlertTitle>{error?.message}</AlertTitle>
      {error.additionally}
    </Alert>
  );
};
