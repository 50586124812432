import { useAdaptationTemplateURLState } from './useAdaptationTemplateURLState';

export const useAdaptationTemplatesPage = () => {
  const [filter, setFilter] = useAdaptationTemplateURLState();

  /* Search */
  const handleSearch = (search: string) => {
    setFilter({ ...filter, SearchText: search, Page: 1 });
  };
  const handleClearSearch = () => {
    setFilter({ ...filter, SearchText: '', Page: 1 });
  };
  /* ===== */

  /* Pagination */
  const handleChangeLimit = (val: number) => {
    setFilter({ ...filter, Limit: val, Page: 1 });
  };
  const handleChangePage = (val: number) => {
    setFilter({ ...filter, Page: val });
  };
  /* ===== */

  return {
    ...filter,
    handleSearch,
    handleClearSearch,
    handleChangeLimit,
    handleChangePage,
  };
};
