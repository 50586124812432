import { useTaskURLState } from 'features/task/hooks/useTaskURLState';
import { countNotUndef } from 'pages/tasks/libs/countNotUndef';
import { orderToTaskFilter } from 'pages/tasks/libs/orderConvFun';
import { FC, PropsWithChildren } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useGetTaskListMeControllerQuery } from 'shared/api/services/rtk/taskApi';
import {
  GetListTask,
  GetTasksMeControllerWebQueryParams,
} from 'shared/api/services/types';
import { usePaginationStateHandlerWithFilter } from 'shared/hooks/usePaginationStateHandlerWithFilter';
import { useSearchStateHendlerWithFilter } from 'shared/hooks/useSearchStateHendlerWithFilter';
import { OrderFilter } from 'shared/ui/table/types/order-props';
import { convertURLToWatchTaskList } from 'shared/url-helper/conventors/convertURLToWatchTaskList';
import { WatchTaskContext } from './WatchTaskContext';

export const WatchTaskProvider: FC<PropsWithChildren> = ({ children }) => {
  const [filter, setFilter] = useTaskURLState(convertURLToWatchTaskList);

  const { data, isFetching, error } = useGetTaskListMeControllerQuery(filter, {
    skip: countNotUndef(filter) < 3,
  });

  useShowFetchErrorInSnackbar(error);

  const { handleChangePage, handleChangePageSize } =
    usePaginationStateHandlerWithFilter({
      filter,
      setFilter,
      keyPage: 'Page',
      keyPageSize: 'Limit',
    });

  const { handleCompliteChangeSearchText } = useSearchStateHendlerWithFilter({
    filter,
    setFilter,
    keySearch: 'SearchText',
    keyPage: 'Page',
  });

  const changeOrder = (order: OrderFilter<GetListTask>) => {
    setFilter({ ...filter, ...orderToTaskFilter(order), Page: 1 });
  };

  const updateFilter = (
    newFilter: GetTasksMeControllerWebQueryParams | undefined
  ) => {
    setFilter({
      ...newFilter,
      Page: 1,
      Limit: newFilter?.Limit || filter?.Limit || 20,
    });
  };

  return (
    <WatchTaskContext.Provider
      value={{
        showMessage: countNotUndef(filter) < 3,
        filter,
        setFilter: updateFilter,
        tasks: data?.items || [],
        count: data?.count || 0,
        changeLimit: handleChangePageSize,
        changePage: handleChangePage,
        changeOrder,
        changeSearch: handleCompliteChangeSearchText,
        isLoading: isFetching,
      }}
    >
      {children}
    </WatchTaskContext.Provider>
  );
};
