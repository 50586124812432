import { GetListBoardTask } from 'shared/api/services/types';

export const deleteOnIndexAndReorderTasks = (params: {
  arr: GetListBoardTask[];
  deleteIndex: number;
}) => {
  const { arr, deleteIndex } = params;

  return arr.reduce<GetListBoardTask[]>((accum, current, index) => {
    if (index === deleteIndex) {
      return accum;
    }

    if (index > deleteIndex) {
      accum.push({
        ...current,
        boardColumnOrderNumber: current.boardColumnOrderNumber - 1,
      });
    } else {
      accum.push(current);
    }

    return accum;
  }, []);
};
