import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { FC, useRef } from 'react';

import { useViewModal } from 'shared/hooks/useViewModal';

import { ButtonWithActionsProps } from './type';

export const ButtonWithActions: FC<ButtonWithActionsProps> = ({
  actions,
  ...props
}) => {
  const { close, isOpen, open } = useViewModal();

  const anchor = useRef(null);

  return (
    <>
      <Button
        endIcon={<ArrowDropDown />}
        {...props}
        ref={anchor}
        onClick={(e) => {
          open();
          props.onClick && props.onClick(e);
        }}
      >
        {props.children}
      </Button>
      <Menu
        open={isOpen}
        anchorEl={anchor.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={close}
      >
        {actions.map((action, i) => (
          <MenuItem
            onClick={() => {
              close();
              action.onClick();
            }}
            disabled={action.disabled || false}
            key={i}
          >
            {action.icon} {action.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
