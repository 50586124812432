import { E_ModuleGuids } from 'shared/moduleGuids';
import { ALL_ROUTES, Routes } from './types';

export const VISIT: Routes[] = [
  {
    title: 'Планирование',
    path: ALL_ROUTES.planning,
    isWork: true,
    children: null,
    description: '123',
    icon: null,
    fullPath: '',
  },
  {
    title: 'Мои посещения',
    path: ALL_ROUTES.my,
    isWork: true,
    children: null,
    description: '123',
    icon: null,
    fullPath: '',
  },
  {
    title: 'Наблюдаю',
    path: ALL_ROUTES.whatch,
    isWork: true,
    children: null,
    description: '123',
    icon: null,
    fullPath: '',
  },
  {
    title: 'Планирование РН',
    path: ALL_ROUTES.planingRN,
    isWork: true,
    children: null,
    description: '123',
    icon: null,
    fullPath: '',
    premission: {
      permissionGuid: E_ModuleGuids.planningRN,
      fields: ['isDeletable', 'isReadable', 'isWritable'],
    },
  },
];
