import dayjs from 'dayjs';

import {
  onChangeTypePayBeforDate,
  UiDocumentFormPayBeforDate,
} from 'shared/ui/UiDocumentFormPayBeforDate';

import { documentFormEdit } from './model';

export const DocumentFormEditPayBeforDate = () => {
  const value =
    documentFormEdit.formSelects.useSelectValueOfKey('payBeforeDate');
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditPayBeforeDate'
  );
  const onChangePayBeforDate: onChangeTypePayBeforDate = (value) =>
    documentFormEdit.api.setFiled({
      key: 'payBeforeDate',
      value: dayjs(value).format('YYYY-MM-DDTHH:mm:ss') || '',
    });

  return (
    <UiDocumentFormPayBeforDate
      value={value || null}
      onChange={onChangePayBeforDate}
      disabled={!isCanEdit}
    />
  );
};
