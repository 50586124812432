// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import { ParamsPutDocument } from 'features/document/document-form-edit/type';

import { SwaggerResponse } from 'shared/api/services-document-flow/config';
import {
  getDocumentsDocumentId,
  getDocumentsDocumentIdAgreeProcesses,
  getDocumentsDocumentIdCurrentActions,
  postDocumentsDocumentIdDoAction,
  putDocumentsDocumentId,
} from 'shared/api/services-document-flow/services';
import {
  DocumetnDoActionRequest,
  ErrorResponse,
} from 'shared/api/services-document-flow/types';

export const getDocumentsDocumentIdCurrentActionsFx = createEffect<
  typeof getDocumentsDocumentIdCurrentActions,
  AxiosError<ErrorResponse>
>(getDocumentsDocumentIdCurrentActions);

export const postDocumentsDocumentIdDoActionFx = createEffect<
  { documentId: number; requestBody: DocumetnDoActionRequest },
  SwaggerResponse<any>,
  AxiosError<ErrorResponse>
>(
  async (params: {
    documentId: number;
    requestBody: DocumetnDoActionRequest;
  }) => {
    return postDocumentsDocumentIdDoAction(
      params.documentId,
      params.requestBody
    );
  }
);

export const getDocumentIdCurrentActionsFx = createEffect<
  typeof getDocumentsDocumentIdCurrentActions,
  AxiosError<ErrorResponse>
>(getDocumentsDocumentIdCurrentActions);
export const getDocumentIdFx = createEffect(getDocumentsDocumentId);
export const getAgreeChainFx = createEffect<
  typeof getDocumentsDocumentIdAgreeProcesses,
  AxiosError<ErrorResponse>
>(getDocumentsDocumentIdAgreeProcesses);
export const putDocumentFx = createEffect<
  ParamsPutDocument,
  SwaggerResponse<any>,
  AxiosError<ErrorResponse>
>({
  name: 'putDocumentFx-actions',
  async handler(params: ParamsPutDocument) {
    return putDocumentsDocumentId(params.documentId, params.body);
  },
});
