import { Grid } from '@mui/material';
import { FC } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';

import { RetailCard } from '../RetailCard';
import { RetailsListProps } from './type';

const cache = new CellMeasurerCache({
  fixedWidth: true,
});

let list: List | null;
let mostRecentWidth: number;

export const RetailsList: FC<RetailsListProps> = ({
  disabledRetailsId,
  selectedRetailsId,
  onUnSelect,
  retails,
  onSelect,
}) => {
  return (
    <Grid flexGrow={1}>
      <AutoSizer>
        {({ height, width }) => {
          if (mostRecentWidth && mostRecentWidth !== width) {
            cache.clearAll();
            list && list.recomputeRowHeights();
          }

          mostRecentWidth = width;

          return (
            <List
              ref={function (ref) {
                list = ref;
              }}
              height={height}
              width={width}
              rowCount={retails.length}
              rowHeight={cache.rowHeight}
              deferredMeasurementCache={cache}
              rowRenderer={({ index, style, parent }) => {
                const retail = retails[index];
                const isDesabled = disabledRetailsId?.some(
                  (id) => id === retail.id
                );
                const isSelected = selectedRetailsId?.some(
                  (id) => id === retail.id
                );

                return (
                  <CellMeasurer
                    key={retail.id}
                    cache={cache}
                    parent={parent}
                    rowIndex={index}
                    style={style}
                  >
                    <Grid
                      style={style}
                      pt={1}
                      px={1}
                    >
                      <RetailCard
                        onSelect={() => {
                          onSelect && onSelect(retail);
                        }}
                        onUnSelect={() => {
                          onUnSelect && onUnSelect(retail);
                        }}
                        isSelected={isSelected}
                        retail={retail}
                        disable={!!isDesabled}
                      />
                    </Grid>
                  </CellMeasurer>
                );
              }}
            />
          );
        }}
      </AutoSizer>
    </Grid>
  );

  return (
    <Grid
      container
      flexDirection={'column'}
      gap={2}
      p={1}
    >
      {retails.map((retail) => {
        const isDesabled = disabledRetailsId?.some((id) => id === retail.id);
        const isSelected = selectedRetailsId?.some((id) => id === retail.id);

        return (
          <RetailCard
            onSelect={() => {
              onSelect && onSelect(retail);
            }}
            onUnSelect={() => {
              onUnSelect && onUnSelect(retail);
            }}
            isSelected={isSelected}
            key={retail.id}
            retail={retail}
            disable={!!isDesabled}
          />
        );
      })}
    </Grid>
  );
};
