/* eslint-disable @typescript-eslint/no-empty-function */
import { WatchTaskContextValue } from './type';

export const WATCH_TASK_CONTEXT_INIT_VALUE: WatchTaskContextValue = {
  tasks: null,
  count: 0,
  filter: undefined,
  setFilter: () => {},
  changeLimit: () => {},
  changePage: () => {},
  changeOrder: () => {},
  changeSearch: () => {},
  isLoading: false,
  showMessage: true,
};
