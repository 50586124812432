import { Dashboard, Delete } from '@mui/icons-material';
import { FC } from 'react';

import { ChangeStatusSvg } from 'shared/svg/ChangeStatusSvg';
import { theme } from 'shared/theme/theme';
import { UiButtonAction } from 'shared/ui/table/ui-button-action/UiButtonAction';
import { UiTableActions } from 'shared/ui/table/ui-table-actions/UiTableActions';

import { TaskTableActionsProps } from './type';

export const TaskTableActions: FC<TaskTableActionsProps> = ({
  onChangeStatus,
  onDelete,
  onMoveToBoard,
  task,
}) => {
  return (
    <UiTableActions>
      {task.accessActions.canDelete && (
        <UiButtonAction
          text='Удалить'
          color={theme.palette.error.main}
          icon={<Delete />}
          onClick={() => {
            onDelete && onDelete(task.guid);
          }}
        />
      )}

      <UiButtonAction
        icon={<ChangeStatusSvg />}
        onClick={() => {
          onChangeStatus && onChangeStatus(task.guid);
        }}
        text='Изменить статус'
      />

      {task.accessActions.canMoveToBoard && (
        <UiButtonAction
          color={theme.palette.customBlue.main}
          icon={<Dashboard />}
          onClick={() => {
            onMoveToBoard && onMoveToBoard(task.guid);
          }}
          text='Перенести на доску'
        />
      )}
    </UiTableActions>
  );
};
