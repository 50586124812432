import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';

import { transformNumberSummInText } from 'shared/utils/transformSummInText';

import { ExpenseCardUiData } from './types';

export const ExpenseCardUi: FC<{
  sx?: SxProps<Theme> | undefined;
  // TODO: Вставил после енерации (Важно)
  data: ExpenseCardUiData;
  index: number;
}> = ({ sx = {}, data, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card
      sx={{
        ...sx,
        height: 'fit-content',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <CardContent
        sx={{
          minHeight: 120,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Collapse
          in={isOpen}
          collapsedSize={120}
          sx={{
            marginTop: 2,
            paddingLeft: 1,
            transition: '0.3s',
            boxShadow: isOpen
              ? ''
              : '0px -5px 5px -5px rgba(34, 60, 80, 0.6) inset',
            '& > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner': {
              display: 'flex',
              flexWrap: 'wrap',
              /* paddingRight: 4, */
            },
          }}
        >
          <Grid
            container
            sx={{
              borderBottom: 'solid 2px #116E6B',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 1,
            }}
          >
            <Typography
              fontWeight={700}
              display={'flex'}
            >
              {`Расход №${index + 1}`}
            </Typography>
            <Typography
              fontWeight={700}
              display={'flex'}
            >
              Сумма {transformNumberSummInText(data.sum)}
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent={'space-between'}
          >
            <Typography
              sx={{
                opacity: isOpen ? 1 : 0.5,
                translate: '0.3s',
                flexBasis: '50%',
              }}
              fontWeight={700}
            >
              Вид расхода
            </Typography>
            <Typography
              sx={{
                opacity: isOpen ? 1 : 0.5,
                translate: '0.3s',
                marginBottom: 1,
              }}
            >
              {data.title}
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              sx={{
                opacity: isOpen ? 1 : 0.5,
                translate: '0.3s',
                display: 'flex',
                flexBasis: '50%',
                marginBottom: 1,
              }}
              fontWeight={700}
            >
              Количество
            </Typography>
            <Typography
              sx={{
                opacity: isOpen ? 1 : 0.5,
                translate: '0.3s',
                display: 'flex',
                flexBasis: '50%',
                justifyContent: 'flex-end',
              }}
            >
              {data.count}
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              sx={{
                opacity: isOpen ? 1 : 0.5,
                translate: '0.3s',
                display: 'flex',
                flexBasis: '50%',
                marginBottom: 1,
              }}
              fontWeight={700}
            >
              Стоимость единицы
            </Typography>
            <Typography
              sx={{
                opacity: isOpen ? 1 : 0.5,
                translate: '0.3s',
                display: 'flex',
                flexBasis: '50%',
                justifyContent: 'flex-end',
              }}
            >
              {transformNumberSummInText(data.price)}
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent={'space-between'}
          >
            <Typography
              sx={{
                opacity: isOpen ? 1 : 0.5,
                translate: '0.3s',
                flexBasis: '50%',
              }}
              fontWeight={700}
            >
              Условие
            </Typography>
            <Typography
              sx={{
                opacity: isOpen ? 1 : 0.5,
                translate: '0.3s',
                marginBottom: 1,
              }}
            >
              {data.extendedCondition ? data.extendedCondition.title : '-'}
            </Typography>
          </Grid>
          {Boolean(data.normSum) && data.normSum > 0 && (
            <Grid
              container
              justifyContent={'space-between'}
            >
              <Typography
                sx={{
                  opacity: isOpen ? 1 : 0.5,
                  translate: '0.3s',
                  marginBottom: 1,
                }}
                fontWeight={700}
              >
                Норматив
              </Typography>
              <Typography sx={{ opacity: isOpen ? 1 : 0.5, translate: '0.3s' }}>
                {data.normSum + ' ' + '₽'}
              </Typography>
            </Grid>
          )}
          <Grid
            container
            justifyContent={'space-between'}
          >
            <Typography
              sx={{ opacity: isOpen ? 1 : 0.5, translate: '0.3s' }}
              fontWeight={700}
            >
              Подразделение
            </Typography>
            <Typography sx={{ opacity: isOpen ? 1 : 0.5, translate: '0.3s' }}>
              {data.retail ? data.retail.title : '-'}
            </Typography>
          </Grid>

          <IconButton
            sx={{
              position: 'absolute',
              right: 5,
              top: 0,
              transform: isOpen ? 'rotate(180deg)' : '',
              transition: '0.3s',
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Collapse>
      </CardContent>
    </Card>
  );
};
