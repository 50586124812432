import { useCallback, useMemo } from 'react';

import { FieldMapType } from 'shared/ui/table/ui-menu-table/constants';
import { useTableRawSettings } from 'shared/user-app-settings/table-raw-settings-context';
import { Tables } from 'shared/utils/controllers';

export const useTableCellsFromLocalStorage = <T>(table: Tables) => {
  const { allTablesInfo, getSettings, updateSettings } = useTableRawSettings();

  const currentTableInfo = useMemo(() => {
    return getSettings<T>(table);
  }, [allTablesInfo]);

  const onChange = useCallback(
    (fields: FieldMapType<T>[]) => {
      updateSettings(table, fields);
    },
    [table, updateSettings]
  );

  const visibleFields = useMemo(() => {
    return currentTableInfo.filter((el) => el.isShow);
  }, [currentTableInfo]);

  return [currentTableInfo, onChange, visibleFields] as const;
};
