import { Close } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { CommentReplyProps } from './type';

export const CommentReply: FC<CommentReplyProps> = ({
  commentReply,
  close,
}) => {
  if (!commentReply) return <></>;

  const { guid, comment, dateCreated, employee } = commentReply;

  const dateDayJs = dayjs(dateCreated as string)
    .locale('ru')
    .format('llll')
    .replace('г.,', '');

  const date = dateDayJs[0].toUpperCase() + dateDayJs.slice(1);

  return (
    <Grid
      container
      justifyContent={'space-between'}
      sx={{
        backgroundColor: close ? theme.palette.customGrey.main : 'white',
        borderLeft: `solid 6px ${theme.palette.secondary.main}`,
        borderRadius: '0px 10px 10px 0px',
        paddingLeft: 1,
        marginBottom: 1,
        color: theme.palette.customGrey.dark,
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        flexBasis={['85%', '90%']}
        position={'relative'}
        sx={{
          '& > a': {
            position: 'absolute',
            height: '100%',
            width: '100%',
          },
        }}
      >
        <Typography
          color={theme.palette.customGrey.dark}
          fontSize={12}
        >
          {date}
        </Typography>
        <Typography fontWeight={700}>{employee.name}</Typography>
        <Typography
          sx={{
            maxWidth: ['80%'],
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {comment}
        </Typography>

        <a href={`#${guid}`} />
      </Box>

      {close && (
        <IconButton onClick={close}>
          <Close sx={{ fontSize: [16, 20] }} />
        </IconButton>
      )}
    </Grid>
  );
};
