import { Button } from '@mui/material';
import { FC, useCallback } from 'react';

import { documentFormModel } from 'entities/document-form';

export const ExpensesFormButtonDeleteExpense: FC<{
  idArticle: number;
  indexExpenses: number;
}> = ({ idArticle, indexExpenses }) => {
  const lengthExpenses =
    documentFormModel.useSelectArticleIdFormModel(idArticle, 'expenses')
      ?.length || 0;
  const onDelet = useCallback(() => {
    return documentFormModel.formEvent.deleteTypeExpensions({
      idArticle,
      indexExpenses,
    });
  }, [idArticle, indexExpenses]);

  return (
    <Button
      disabled={lengthExpenses < 2}
      onClick={onDelet}
    >
      Удалить
    </Button>
  );
};
