import { Grid } from '@mui/material';
import { UserPage } from 'pages/user/UserPage';
import { Outlet, RouteObject } from 'react-router-dom';

import { MAX_WIDTH } from 'shared/constant/size';
import { ALL_ROUTES } from 'shared/routes/constants/types';

export const ROUTES_USER: RouteObject[] = [
  {
    path: ALL_ROUTES.user,
    element: (
      <Grid
        maxWidth={MAX_WIDTH}
        sx={{ margin: '0px auto', width: '100%' }}
        item
      >
        <Outlet />
      </Grid>
    ),
    children: [
      {
        path: '',
        element: <UserPage />,
      },
    ],
  },
];
