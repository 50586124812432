// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */
// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createEffect, createEvent, createStore, sample } from 'effector';
import { postDocumentsDocumentIdDoActionFx } from 'features/document/document-actions/api/document-actions';

import {
  getDocumentsDocumentIdAgreeProcesses,
  getDocumentsDocumentIdFilesCloseDocumentsDownloadFileId,
  getDocumentsDocumentIdFilesReasonDocumentsDownloadFileId,
} from 'shared/api/services-document-flow/services';
import {
  DocumentAgreeProcessActionResponse,
  DocumentModel,
} from 'shared/api/services-document-flow/types';

import { compliteActiconCloseDocument } from 'features/document/document-actions/deprecated-effector';
import {
  deleteDocumentIdFx,
  getDocumentIdFx,
} from '../api/document-id-effector';

const documentIdReset = createEvent();
const $documentId = createStore<DocumentModel | null>(null, {
  name: '$documentId',
});
const $notFound = createStore(false);

$documentId.on(getDocumentIdFx.doneData, (_, response) => response.data);
$notFound.on(getDocumentIdFx.fail, (_, response) => {
  console.log(response.error.response?.status);

  return response.error.response?.status === 404;
});
$notFound.on(getDocumentIdFx.doneData, () => {
  return false;
});

const downloadFileReasonDocumentId = createEffect(
  async (params: { id: number; fileId: number; fileName: string }) => {
    return await getDocumentsDocumentIdFilesReasonDocumentsDownloadFileId(
      params.id,
      params.fileId,
      {
        responseType: 'blob',
      }
    ).then((blob) => {
      /* console.log(blob); */
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${params.fileName}`);
      // 3. Append to html pageF
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      /* link!.parentNode!.removeChild(link); */
    });
  }
);
const downloadFileCloseDocumentId = createEffect(
  async (params: { id: number; fileId: number; fileName: string }) => {
    return await getDocumentsDocumentIdFilesCloseDocumentsDownloadFileId(
      params.id,
      params.fileId,
      {
        responseType: 'blob',
      }
    ).then((blob) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${params.fileName}`);
      // 3. Append to html pageF
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      /* link!.parentNode!.removeChild(link); */
    });
  }
);
const resetProcessAgree = createEvent<any>();
const getDocumentAgreeProcessFx = createEffect(
  getDocumentsDocumentIdAgreeProcesses
);
const $agreeProcess = createStore<DocumentAgreeProcessActionResponse[] | null>(
  null
).on(getDocumentAgreeProcessFx.doneData, (_, response) => {
  return response.data;
});

$agreeProcess.reset(resetProcessAgree);
sample({
  clock: $documentId,
  filter: (clock) => Boolean(clock),
  fn: (clock) => clock!.id,
  target: getDocumentAgreeProcessFx,
});
sample({
  clock: $documentId,
  filter: (clock) => !clock,
  target: resetProcessAgree,
});
sample({
  clock: [
    postDocumentsDocumentIdDoActionFx.done,
    deleteDocumentIdFx.done,
    compliteActiconCloseDocument,
  ],
  fn: (clock) => {
    if (typeof clock.params === 'number') {
      return clock.params;
    } else {
      return clock.params.documentId;
    }
  },
  target: getDocumentIdFx,
});
/* $documentId.on(putDocumentIdFx.doneData, (_, response) => response.data); */
$documentId.reset(documentIdReset);
$notFound.reset(documentIdReset);

export const documentGuid = {
  store: $documentId,
  agreeProcess: $agreeProcess,
  notFound: $notFound,
  reset: documentIdReset,
  downloadFileCloseDocumentId,
  downloadFileReasonDocumentId,
};
