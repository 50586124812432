//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from 'axios';
import type { SwaggerResponse } from './config';
import { Http } from './httpRequest';
//@ts-ignore
import qs from 'qs';
import type {
  ChecklistBlockCreateRequest,
  ChecklistBlockGetResponse,
  ChecklistBlockItemCreateRequest,
  ChecklistBlockItemGetResponse,
  ChecklistBlockItemUpdateRequest,
  ChecklistBlockUpdateRequest,
  ChecklistCreateRequest,
  ChecklistGetListResponse,
  ChecklistGetResponse,
  ChecklistPositionLinkResponse,
  ChecklistPositionLinkUpdateRequest,
  ChecklistUpdateRequest,
  CompetitorGetListResponse,
  CreatorToMemberPositionCategoryLinkResponse,
  CreatorToMemberPositionCategoryLinkUpdateRequest,
  DepartmentGetListResponse,
  EmployeeEventHomiesGetListResponse,
  EmployeePlanningHomiesGetListResponse,
  EmployeePlanningVisitorsGetListResponse,
  EmployeePositionCategoryGetListResponse,
  EmployeePositionGetListResponse,
  EmployeeVisitingHomiesGetListResponse,
  EventCreateRequest,
  EventDeleteRequest,
  EventGetListResponse,
  EventUpdateRequest,
  GetCompetitorsQueryParams,
  GetEmployeesEventHomiesQueryParams,
  GetEmployeesPlanningHomiesQueryParams,
  GetEmployeesVisitingHomiesQueryParams,
  GetEventsQueryParams,
  GetPlanningsBlockedDatesQueryParams,
  GetPlanningsQueryParams,
  GetProductsQueryParams,
  GetRetailsQueryParams,
  GetVisitingsActionQueryParams,
  GetVisitingsObserveQueryParams,
  OrgStructPartGetList,
  PeriodSettingGetListResponse,
  PeriodSettingItemGetListResponse,
  PeriodSettingUpdateListRequest,
  PeriodTypeGetListResponse,
  PlanningColumnSettingGetListResponse,
  PlanningColumnSettingUpdateRequest,
  ProductGetListResponse,
  RegionGetListResponse,
  RequiredChecklistStagesSettingGetResponse,
  RequiredChecklistStagesSettingUpdateRequest,
  RetailGetListResponse,
  VisitingChecklistCreateRequest,
  VisitingChecklistGetResponse,
  VisitingChecklistProblemGetResponse,
  VisitingChecklistStageFiveRequest,
  VisitingChecklistStageFourRequest,
  VisitingChecklistStageOneRequest,
  VisitingChecklistStageThreeRequest,
  VisitingChecklistStageTwoRequest,
  VisitingCreateRequest,
  VisitingDeleteRequest,
  VisitingFilterResponse,
  VisitingGetListResponse,
  VisitingGetListResponsePaginationResponse,
  VisitingGetResponse,
  VisitingPlanningBlockedDatesResponse,
  VisitingPlanningCreateRequest,
  VisitingPlanningDeleteRequest,
  VisitingPlanningGetListResponse,
  VisitingPlanningUpdateRequest,
  VisitingUpdateRequest,
  VisitorFilterResponse,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, 'i');
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

export const deleteConstructorChecklistsChecklistId = (
  checklistId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteConstructorChecklistsChecklistId.key, { checklistId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteConstructorChecklistsChecklistId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}';

export const deleteConstructorChecklistsChecklistIdBlocksBlockIdItemsItemId = (
  checklistId: number,
  blockId: number,
  itemId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(
      deleteConstructorChecklistsChecklistIdBlocksBlockIdItemsItemId.key,
      { checklistId, blockId, itemId }
    ),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteConstructorChecklistsChecklistIdBlocksBlockIdItemsItemId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks/{blockId}/Items/{itemId}';

export const deleteConstructorChecklistsChecklistIdBlocksChecklistBlockId = (
  checklistId: number,
  checklistBlockId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteConstructorChecklistsChecklistIdBlocksChecklistBlockId.key, {
      checklistId,
      checklistBlockId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
deleteConstructorChecklistsChecklistIdBlocksChecklistBlockId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks/{checklistBlockId}';

export const deleteConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItemsItemId =
  (
    checklistId: number,
    blockId: number,
    itemId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItemsItemId.key,
        { checklistId, blockId, itemId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
deleteConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItemsItemId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks/{blockId}/Items/{itemId}';

export const deleteConstructorChecklistsChecklistIdCompetitorComparisonBlocksChecklistBlockId =
  (
    checklistId: number,
    checklistBlockId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteConstructorChecklistsChecklistIdCompetitorComparisonBlocksChecklistBlockId.key,
        { checklistId, checklistBlockId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
deleteConstructorChecklistsChecklistIdCompetitorComparisonBlocksChecklistBlockId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks/{checklistBlockId}';

/**
 *
 * Возвращает перечень доступных шаблонов оценочных листов
 */
export const getChecklistsAllowed = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistGetListResponse[]>> => {
  return Http.getRequest(
    getChecklistsAllowed.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getChecklistsAllowed.key = '/v1/gzvisitingsystemservice/api/Checklists/Allowed';

/**
 *
 * Возвращает оценочный лист посещения
 */
export const getChecklistsVisitingChecklistId = (
  visitingChecklistId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingChecklistGetResponse>> => {
  return Http.getRequest(
    template(getChecklistsVisitingChecklistId.key, { visitingChecklistId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getChecklistsVisitingChecklistId.key =
  '/v1/gzvisitingsystemservice/api/Checklists/{visitingChecklistId}';

export const getCompetitors = (
  queryParams?: GetCompetitorsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CompetitorGetListResponse[]>> => {
  return Http.getRequest(
    getCompetitors.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCompetitors.key = '/v1/gzvisitingsystemservice/api/Competitors';

export const getConstructorChecklists = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistGetListResponse[]>> => {
  return Http.getRequest(
    getConstructorChecklists.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorChecklists.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists';

export const getConstructorChecklistsChecklistId = (
  checklistId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistGetResponse>> => {
  return Http.getRequest(
    template(getConstructorChecklistsChecklistId.key, { checklistId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorChecklistsChecklistId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}';

export const getConstructorChecklistsChecklistIdBlocks = (
  checklistId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistBlockGetResponse[]>> => {
  return Http.getRequest(
    template(getConstructorChecklistsChecklistIdBlocks.key, { checklistId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorChecklistsChecklistIdBlocks.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks';

export const getConstructorChecklistsChecklistIdBlocksBlockIdItems = (
  checklistId: number,
  blockId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistBlockItemGetResponse[]>> => {
  return Http.getRequest(
    template(getConstructorChecklistsChecklistIdBlocksBlockIdItems.key, {
      checklistId,
      blockId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorChecklistsChecklistIdBlocksBlockIdItems.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks/{blockId}/Items';

export const getConstructorChecklistsChecklistIdBlocksBlockIdItemsItemId = (
  checklistId: number,
  blockId: number,
  itemId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistBlockItemGetResponse>> => {
  return Http.getRequest(
    template(getConstructorChecklistsChecklistIdBlocksBlockIdItemsItemId.key, {
      checklistId,
      blockId,
      itemId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorChecklistsChecklistIdBlocksBlockIdItemsItemId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks/{blockId}/Items/{itemId}';

export const getConstructorChecklistsChecklistIdBlocksChecklistBlockId = (
  checklistId: number,
  checklistBlockId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistBlockGetResponse>> => {
  return Http.getRequest(
    template(getConstructorChecklistsChecklistIdBlocksChecklistBlockId.key, {
      checklistId,
      checklistBlockId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorChecklistsChecklistIdBlocksChecklistBlockId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks/{checklistBlockId}';

export const getConstructorChecklistsChecklistIdCompetitorComparisonBlocks = (
  checklistId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistBlockGetResponse[]>> => {
  return Http.getRequest(
    template(
      getConstructorChecklistsChecklistIdCompetitorComparisonBlocks.key,
      { checklistId }
    ),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorChecklistsChecklistIdCompetitorComparisonBlocks.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks';

export const getConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItems =
  (
    checklistId: number,
    blockId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<ChecklistBlockItemGetResponse[]>> => {
    return Http.getRequest(
      template(
        getConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItems.key,
        { checklistId, blockId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
getConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItems.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks/{blockId}/Items';

export const getConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItemsItemId =
  (
    checklistId: number,
    blockId: number,
    itemId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<ChecklistBlockItemGetResponse>> => {
    return Http.getRequest(
      template(
        getConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItemsItemId.key,
        { checklistId, blockId, itemId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
getConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItemsItemId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks/{blockId}/Items/{itemId}';

export const getConstructorChecklistsChecklistIdCompetitorComparisonBlocksChecklistBlockId =
  (
    checklistId: number,
    checklistBlockId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<ChecklistBlockGetResponse>> => {
    return Http.getRequest(
      template(
        getConstructorChecklistsChecklistIdCompetitorComparisonBlocksChecklistBlockId.key,
        { checklistId, checklistBlockId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
getConstructorChecklistsChecklistIdCompetitorComparisonBlocksChecklistBlockId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks/{checklistBlockId}';

export const getConstructorChecklistsChecklistIdLinks = (
  checklistId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistPositionLinkResponse[]>> => {
  return Http.getRequest(
    template(getConstructorChecklistsChecklistIdLinks.key, { checklistId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorChecklistsChecklistIdLinks.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Links';

export const getConstructorPeriodSettingItems = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PeriodSettingItemGetListResponse[]>> => {
  return Http.getRequest(
    getConstructorPeriodSettingItems.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPeriodSettingItems.key =
  '/v1/gzvisitingsystemservice/api/Constructor/PeriodSettingItems';

export const getConstructorPeriodSettings = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PeriodSettingGetListResponse[]>> => {
  return Http.getRequest(
    getConstructorPeriodSettings.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPeriodSettings.key =
  '/v1/gzvisitingsystemservice/api/Constructor/PeriodSettings';

export const getConstructorPeriodTypes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PeriodTypeGetListResponse[]>> => {
  return Http.getRequest(
    getConstructorPeriodTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPeriodTypes.key =
  '/v1/gzvisitingsystemservice/api/Constructor/PeriodTypes';

export const getConstructorPositionCategories = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeePositionCategoryGetListResponse[]>> => {
  return Http.getRequest(
    getConstructorPositionCategories.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPositionCategories.key =
  '/v1/gzvisitingsystemservice/api/Constructor/PositionCategories';

export const getConstructorPositionCategoriesCreatorPositionCategoryIdMemberLinks =
  (
    creatorPositionCategoryId: number,
    configOverride?: AxiosRequestConfig
  ): Promise<
    SwaggerResponse<CreatorToMemberPositionCategoryLinkResponse[]>
  > => {
    return Http.getRequest(
      template(
        getConstructorPositionCategoriesCreatorPositionCategoryIdMemberLinks.key,
        { creatorPositionCategoryId }
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
getConstructorPositionCategoriesCreatorPositionCategoryIdMemberLinks.key =
  '/v1/gzvisitingsystemservice/api/Constructor/PositionCategories/{creatorPositionCategoryId}/MemberLinks';

export const getConstructorPositions = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeePositionGetListResponse[]>> => {
  return Http.getRequest(
    getConstructorPositions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPositions.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Positions';

export const getConstructorPositionsObserverPositionIdColumnSettings = (
  observerPositionId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PlanningColumnSettingGetListResponse[]>> => {
  return Http.getRequest(
    template(getConstructorPositionsObserverPositionIdColumnSettings.key, {
      observerPositionId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorPositionsObserverPositionIdColumnSettings.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Positions/{observerPositionId}/ColumnSettings';

/**
 *
 * Возвращает настройки обязательности этапов заполнения чеклиста
 */
export const getConstructorRequiredChecklistStagesSettings = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RequiredChecklistStagesSettingGetResponse>> => {
  return Http.getRequest(
    getConstructorRequiredChecklistStagesSettings.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConstructorRequiredChecklistStagesSettings.key =
  '/v1/gzvisitingsystemservice/api/Constructor/RequiredChecklistStagesSettings';

export const getDepartments = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DepartmentGetListResponse[]>> => {
  return Http.getRequest(
    getDepartments.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDepartments.key = '/v1/gzvisitingsystemservice/api/Departments';

export const getDirections = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OrgStructPartGetList[]>> => {
  return Http.getRequest(
    getDirections.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getDirections.key = '/v1/gzvisitingsystemservice/api/Directions';

export const getEmployeesEventHomies = (
  queryParams?: GetEmployeesEventHomiesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeEventHomiesGetListResponse[]>> => {
  return Http.getRequest(
    getEmployeesEventHomies.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesEventHomies.key =
  '/v1/gzvisitingsystemservice/api/Employees/EventHomies';

export const getEmployeesPlanningHomies = (
  queryParams?: GetEmployeesPlanningHomiesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeePlanningHomiesGetListResponse[]>> => {
  return Http.getRequest(
    getEmployeesPlanningHomies.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesPlanningHomies.key =
  '/v1/gzvisitingsystemservice/api/Employees/PlanningHomies';

export const getEmployeesPlanningVisitors = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeePlanningVisitorsGetListResponse[]>> => {
  return Http.getRequest(
    getEmployeesPlanningVisitors.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesPlanningVisitors.key =
  '/v1/gzvisitingsystemservice/api/Employees/PlanningVisitors';

export const getEmployeesVisitingHomies = (
  queryParams?: GetEmployeesVisitingHomiesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmployeeVisitingHomiesGetListResponse[]>> => {
  return Http.getRequest(
    getEmployeesVisitingHomies.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeesVisitingHomies.key =
  '/v1/gzvisitingsystemservice/api/Employees/VisitingHomies';

/**
 *
 * Список записей мероприятия
 */
export const getEvents = (
  queryParams: GetEventsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EventGetListResponse[]>> => {
  return Http.getRequest(
    getEvents.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEvents.key = '/v1/gzvisitingsystemservice/api/Events';

/**
 *
 * Элементы фильтра для фильтрации по месту посещения
 */
export const getFiltersVisiting = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingFilterResponse>> => {
  return Http.getRequest(
    getFiltersVisiting.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getFiltersVisiting.key = '/v1/gzvisitingsystemservice/api/Filters/Visiting';

/**
 *
 * Элементы фильтра для фильтрации по посещаемому
 */
export const getFiltersVisitor = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitorFilterResponse>> => {
  return Http.getRequest(
    getFiltersVisitor.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getFiltersVisitor.key = '/v1/gzvisitingsystemservice/api/Filters/Visitor';

/**
 *
 * Список записей планирования РН
 */
export const getPlannings = (
  queryParams?: GetPlanningsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingPlanningGetListResponse[]>> => {
  return Http.getRequest(
    getPlannings.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPlannings.key = '/v1/gzvisitingsystemservice/api/Plannings';

/**
 *
 * Список недоступных для планирования дат
 */
export const getPlanningsBlockedDates = (
  queryParams?: GetPlanningsBlockedDatesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingPlanningBlockedDatesResponse>> => {
  return Http.getRequest(
    getPlanningsBlockedDates.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPlanningsBlockedDates.key =
  '/v1/gzvisitingsystemservice/api/Plannings/BlockedDates';

/**
 *
 * Скачать файл для проблемы
 */
export const getProblemFilesDownloadProblemFileId = (
  problemFileId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getProblemFilesDownloadProblemFileId.key, { problemFileId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
getProblemFilesDownloadProblemFileId.key =
  '/v1/gzvisitingsystemservice/api/ProblemFiles/Download/{problemFileId}';

export const getProducts = (
  queryParams?: GetProductsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductGetListResponse[]>> => {
  return Http.getRequest(
    getProducts.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProducts.key = '/v1/gzvisitingsystemservice/api/Products';

export const getRegions = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RegionGetListResponse[]>> => {
  return Http.getRequest(
    getRegions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRegions.key = '/v1/gzvisitingsystemservice/api/Regions';

export const getRetails = (
  queryParams?: GetRetailsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RetailGetListResponse[]>> => {
  return Http.getRequest(
    getRetails.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRetails.key = '/v1/gzvisitingsystemservice/api/Retails';

/**
 *
 * Список посещений "Делаю"
 */
export const getVisitingsAction = (
  queryParams?: GetVisitingsActionQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingGetListResponsePaginationResponse>> => {
  return Http.getRequest(
    getVisitingsAction.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getVisitingsAction.key = '/v1/gzvisitingsystemservice/api/Visitings/Action';

/**
 *
 * Список посещений "Наблюдаю"
 */
export const getVisitingsObserve = (
  queryParams?: GetVisitingsObserveQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingGetListResponsePaginationResponse>> => {
  return Http.getRequest(
    getVisitingsObserve.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getVisitingsObserve.key = '/v1/gzvisitingsystemservice/api/Visitings/Observe';

/**
 *
 * Возвращает запись посещения
 */
export const getVisitingsVisitingId = (
  visitingId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingGetResponse>> => {
  return Http.getRequest(
    template(getVisitingsVisitingId.key, { visitingId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getVisitingsVisitingId.key =
  '/v1/gzvisitingsystemservice/api/Visitings/{visitingId}';

/**
 *
 * Генерирует оценочный лист для посещения
 */
export const postChecklists = (
  requestBody: VisitingChecklistCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingChecklistGetResponse>> => {
  return Http.postRequest(
    postChecklists.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postChecklists.key = '/v1/gzvisitingsystemservice/api/Checklists';

/**
 *
 * Завершает заполнение оценочного листа посещения
 */
export const postChecklistsVisitingChecklistIdComplete = (
  visitingChecklistId: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postChecklistsVisitingChecklistIdComplete.key, {
      visitingChecklistId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postChecklistsVisitingChecklistIdComplete.key =
  '/v1/gzvisitingsystemservice/api/Checklists/{visitingChecklistId}/Complete';

/**
 *
 * Сохраняет информацию первого этапа оценочного листа посещения
 */
export const postChecklistsVisitingChecklistIdStages1 = (
  visitingChecklistId: number,
  requestBody: VisitingChecklistStageOneRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postChecklistsVisitingChecklistIdStages1.key, {
      visitingChecklistId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postChecklistsVisitingChecklistIdStages1.key =
  '/v1/gzvisitingsystemservice/api/Checklists/{visitingChecklistId}/Stages/1';

/**
 *
 * Сохраняет информацию второго этапа оценочного листа посещения
 */
export const postChecklistsVisitingChecklistIdStages2 = (
  visitingChecklistId: number,
  requestBody: VisitingChecklistStageTwoRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postChecklistsVisitingChecklistIdStages2.key, {
      visitingChecklistId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postChecklistsVisitingChecklistIdStages2.key =
  '/v1/gzvisitingsystemservice/api/Checklists/{visitingChecklistId}/Stages/2';

/**
 *
 * Сохраняет информацию третьего этапа оценочного листа посещения
 */
export const postChecklistsVisitingChecklistIdStages3 = (
  visitingChecklistId: number,
  requestBody: VisitingChecklistStageThreeRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postChecklistsVisitingChecklistIdStages3.key, {
      visitingChecklistId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postChecklistsVisitingChecklistIdStages3.key =
  '/v1/gzvisitingsystemservice/api/Checklists/{visitingChecklistId}/Stages/3';

/**
 *
 * Сохраняет информацию четвертого этапа оценочного листа посещения
 */
export const postChecklistsVisitingChecklistIdStages4 = (
  visitingChecklistId: number,
  requestBody: VisitingChecklistStageFourRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingChecklistProblemGetResponse[]>> => {
  return Http.postRequest(
    template(postChecklistsVisitingChecklistIdStages4.key, {
      visitingChecklistId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postChecklistsVisitingChecklistIdStages4.key =
  '/v1/gzvisitingsystemservice/api/Checklists/{visitingChecklistId}/Stages/4';

/**
 *
 * Сохраняет информацию пятого этапа оценочного листа посещения
 */
export const postChecklistsVisitingChecklistIdStages5 = (
  visitingChecklistId: number,
  requestBody: VisitingChecklistStageFiveRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingChecklistProblemGetResponse[]>> => {
  return Http.postRequest(
    template(postChecklistsVisitingChecklistIdStages5.key, {
      visitingChecklistId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postChecklistsVisitingChecklistIdStages5.key =
  '/v1/gzvisitingsystemservice/api/Checklists/{visitingChecklistId}/Stages/5';

export const postConstructorChecklists = (
  requestBody: ChecklistCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistGetResponse>> => {
  return Http.postRequest(
    postConstructorChecklists.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorChecklists.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists';

export const postConstructorChecklistsChecklistIdBlocks = (
  checklistId: number,
  requestBody: ChecklistBlockCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistBlockGetResponse>> => {
  return Http.postRequest(
    template(postConstructorChecklistsChecklistIdBlocks.key, { checklistId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorChecklistsChecklistIdBlocks.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks';

export const postConstructorChecklistsChecklistIdBlocksBlockIdItems = (
  checklistId: number,
  blockId: number,
  requestBody: ChecklistBlockItemCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistBlockItemGetResponse>> => {
  return Http.postRequest(
    template(postConstructorChecklistsChecklistIdBlocksBlockIdItems.key, {
      checklistId,
      blockId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorChecklistsChecklistIdBlocksBlockIdItems.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks/{blockId}/Items';

export const postConstructorChecklistsChecklistIdCompetitorComparisonBlocks = (
  checklistId: number,
  requestBody: ChecklistBlockCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChecklistBlockGetResponse>> => {
  return Http.postRequest(
    template(
      postConstructorChecklistsChecklistIdCompetitorComparisonBlocks.key,
      { checklistId }
    ),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConstructorChecklistsChecklistIdCompetitorComparisonBlocks.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks';

export const postConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItems =
  (
    checklistId: number,
    blockId: number,
    requestBody: ChecklistBlockItemCreateRequest,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<ChecklistBlockItemGetResponse>> => {
    return Http.postRequest(
      template(
        postConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItems.key,
        { checklistId, blockId }
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
postConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItems.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks/{blockId}/Items';

/**
 *
 * Создаёт запись мероприятия
 */
export const postEvents = (
  requestBody: EventCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EventGetListResponse>> => {
  return Http.postRequest(
    postEvents.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEvents.key = '/v1/gzvisitingsystemservice/api/Events';

/**
 *
 * Удаляет записи мероприятия
 */
export const postEventsDelete = (
  requestBody: EventDeleteRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postEventsDelete.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postEventsDelete.key = '/v1/gzvisitingsystemservice/api/Events/Delete';

/**
 *
 * Создаёт запись в планировании РН
 */
export const postPlannings = (
  requestBody: VisitingPlanningCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingPlanningGetListResponse>> => {
  return Http.postRequest(
    postPlannings.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPlannings.key = '/v1/gzvisitingsystemservice/api/Plannings';

/**
 *
 * Удаляет записи планировании РН
 */
export const postPlanningsDelete = (
  requestBody: VisitingPlanningDeleteRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postPlanningsDelete.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postPlanningsDelete.key = '/v1/gzvisitingsystemservice/api/Plannings/Delete';

/**
 *
 * Создаёт записи посещений
 */
export const postVisitings = (
  requestBody: VisitingCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingGetResponse[]>> => {
  return Http.postRequest(
    postVisitings.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postVisitings.key = '/v1/gzvisitingsystemservice/api/Visitings';

/**
 *
 * Удаляет записи посещений
 */
export const postVisitingsDelete = (
  requestBody: VisitingDeleteRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postVisitingsDelete.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
postVisitingsDelete.key = '/v1/gzvisitingsystemservice/api/Visitings/Delete';

export const putConstructorChecklistsChecklistId = (
  checklistId: number,
  requestBody: ChecklistUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorChecklistsChecklistId.key, { checklistId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorChecklistsChecklistId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}';

export const putConstructorChecklistsChecklistIdBlocksBlockIdItemsItemId = (
  checklistId: number,
  blockId: number,
  itemId: number,
  requestBody: ChecklistBlockItemUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorChecklistsChecklistIdBlocksBlockIdItemsItemId.key, {
      checklistId,
      blockId,
      itemId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorChecklistsChecklistIdBlocksBlockIdItemsItemId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks/{blockId}/Items/{itemId}';

export const putConstructorChecklistsChecklistIdBlocksChecklistBlockId = (
  checklistId: number,
  checklistBlockId: number,
  requestBody: ChecklistBlockUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorChecklistsChecklistIdBlocksChecklistBlockId.key, {
      checklistId,
      checklistBlockId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorChecklistsChecklistIdBlocksChecklistBlockId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Blocks/{checklistBlockId}';

export const putConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItemsItemId =
  (
    checklistId: number,
    blockId: number,
    itemId: number,
    requestBody: ChecklistBlockItemUpdateRequest,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItemsItemId.key,
        { checklistId, blockId, itemId }
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
putConstructorChecklistsChecklistIdCompetitorComparisonBlocksBlockIdItemsItemId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks/{blockId}/Items/{itemId}';

export const putConstructorChecklistsChecklistIdCompetitorComparisonBlocksChecklistBlockId =
  (
    checklistId: number,
    checklistBlockId: number,
    requestBody: ChecklistBlockUpdateRequest,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putConstructorChecklistsChecklistIdCompetitorComparisonBlocksChecklistBlockId.key,
        { checklistId, checklistBlockId }
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
putConstructorChecklistsChecklistIdCompetitorComparisonBlocksChecklistBlockId.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/CompetitorComparison/Blocks/{checklistBlockId}';

export const putConstructorChecklistsChecklistIdLinks = (
  checklistId: number,
  requestBody: ChecklistPositionLinkUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorChecklistsChecklistIdLinks.key, { checklistId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorChecklistsChecklistIdLinks.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Checklists/{checklistId}/Links';

export const putConstructorPeriodSettings = (
  requestBody: PeriodSettingUpdateListRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putConstructorPeriodSettings.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorPeriodSettings.key =
  '/v1/gzvisitingsystemservice/api/Constructor/PeriodSettings';

export const putConstructorPositionCategoriesCreatorPositionCategoryIdMemberLinks =
  (
    creatorPositionCategoryId: number,
    requestBody: CreatorToMemberPositionCategoryLinkUpdateRequest,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putConstructorPositionCategoriesCreatorPositionCategoryIdMemberLinks.key,
        { creatorPositionCategoryId }
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT1, configOverride)
    );
  };

/** Key is end point string without base url */
putConstructorPositionCategoriesCreatorPositionCategoryIdMemberLinks.key =
  '/v1/gzvisitingsystemservice/api/Constructor/PositionCategories/{creatorPositionCategoryId}/MemberLinks';

export const putConstructorPositionsObserverPositionIdColumnSettings = (
  observerPositionId: number,
  requestBody: PlanningColumnSettingUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putConstructorPositionsObserverPositionIdColumnSettings.key, {
      observerPositionId,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorPositionsObserverPositionIdColumnSettings.key =
  '/v1/gzvisitingsystemservice/api/Constructor/Positions/{observerPositionId}/ColumnSettings';

/**
 *
 * Обновляет настройки обязательности этапов заполнения чеклиста
 */
export const putConstructorRequiredChecklistStagesSettings = (
  requestBody: RequiredChecklistStagesSettingUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putConstructorRequiredChecklistStagesSettings.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride)
  );
};

/** Key is end point string without base url */
putConstructorRequiredChecklistStagesSettings.key =
  '/v1/gzvisitingsystemservice/api/Constructor/RequiredChecklistStagesSettings';

/**
 *
 * Обновляет информацию мероприятия
 */
export const putEventsPlanningId = (
  planningId: number,
  requestBody: EventUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EventGetListResponse>> => {
  return Http.putRequest(
    template(putEventsPlanningId.key, { planningId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putEventsPlanningId.key = '/v1/gzvisitingsystemservice/api/Events/{planningId}';

/**
 *
 * Обновляет информацию записи планирования посещения
 */
export const putPlanningsPlanningId = (
  planningId: number,
  requestBody: VisitingPlanningUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingPlanningGetListResponse>> => {
  return Http.putRequest(
    template(putPlanningsPlanningId.key, { planningId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putPlanningsPlanningId.key =
  '/v1/gzvisitingsystemservice/api/Plannings/{planningId}';

/**
 *
 * Обновляет информацию записи посещения
 */
export const putVisitingsVisitingId = (
  visitingId: number,
  requestBody: VisitingUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VisitingGetListResponse>> => {
  return Http.putRequest(
    template(putVisitingsVisitingId.key, { visitingId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putVisitingsVisitingId.key =
  '/v1/gzvisitingsystemservice/api/Visitings/{visitingId}';
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/plain',
  },
};
export const _CONSTANT1 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};
