import { Grid } from '@mui/material';
import { Counterparty } from 'pages/document/Counterparty';
import { CreatedDocument } from 'pages/document/CreatedDocument';
import { DocumentEdit } from 'pages/document/DocumentEdit';
import { DocumentGuid } from 'pages/document/DocumentGuid';
import { InvocePayment } from 'pages/document/InvocePayment';
import { PageDocumentAction } from 'pages/document/PageDocumentAction';
import { PageDocumentControls } from 'pages/document/PageDocumentControls';
import { PageDocumentObserve } from 'pages/document/PageDocumentObserve';
import { RouteObject } from 'react-router-dom';

import { MAX_WIDTH } from 'shared/constant/size';
import { ALL_ROUTES } from 'shared/routes/constants/types';

export const ROUTES_DOCUMENTS_FLOW: RouteObject[] = [
  {
    path: ALL_ROUTES.documentFlow,

    children: [
      {
        path: ALL_ROUTES.action,
        element: <PageDocumentAction />,
      },
      {
        path: ALL_ROUTES.controls,
        element: <PageDocumentControls />,
      },
      {
        path: ALL_ROUTES.observe,
        element: <PageDocumentObserve />,
      },
      {
        path: ALL_ROUTES.counterparties,
        element: <Counterparty />,
      },
      {
        path: ALL_ROUTES.createdDocument,
        children: [
          {
            index: true,
            element: (
              <Grid
                maxWidth={MAX_WIDTH}
                sx={{ margin: '0px auto', width: '100%' }}
                item
              >
                <CreatedDocument />
              </Grid>
            ),
          },
        ],
      },
      {
        path: ALL_ROUTES.createdDocumentId,
        element: <InvocePayment />,
      },
    ],
  },
  {
    path: '/' + ALL_ROUTES.documentFlowIdComments,
    element: (
      <Grid
        maxWidth={MAX_WIDTH}
        sx={{ margin: '0px auto', width: '100%' }}
        item
        container
      >
        <DocumentGuid />
      </Grid>
    ),
  },
  {
    path: '/' + ALL_ROUTES.documentFlowId,
    element: (
      <Grid
        maxWidth={MAX_WIDTH}
        sx={{ margin: '0px auto', width: '100%' }}
        item
        container
      >
        <DocumentGuid />
      </Grid>
    ),
  },
  {
    path: '/' + ALL_ROUTES.documentFlowIdEdit,
    element: (
      <Grid
        maxWidth={MAX_WIDTH}
        sx={{ margin: '0px auto', width: '100%' }}
        item
        container
      >
        <DocumentEdit />
      </Grid>
    ),
  },
];
