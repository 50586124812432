// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { array, object, ObjectSchema } from 'yup';

import {
  CompetitorGetListResponse,
  ProductGetListResponse,
} from 'shared/api/visit/types';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';

import { FormItem, InformationAboutCompetitorsFormI } from './type';

/* Form schems */
const productSchema: ObjectSchema<ProductGetListResponse> = object().shape({
  id: SCHEMA.NUMBER.required(),
  title: SCHEMA.STRING,
});

const competitorSchema: ObjectSchema<CompetitorGetListResponse> =
  object().shape({
    id: SCHEMA.NUMBER.required(),
    title: SCHEMA.STRING,
  });

const FormItemSchema: ObjectSchema<FormItem> = object().shape({
  competitor: competitorSchema.nullable(),
  checksCount: SCHEMA.NUMBER.required().min(0).nullable(),
  productPrice: array(SCHEMA.NUMBER.required().nullable())
    .required()
    .test('product', 'Error', (value, testContext) => {
      // @ts-ignore
      const formState = testContext.from[1]
        .value as InformationAboutCompetitorsFormI;

      return !formState.product.some((prod, i) =>
        formState.competitors.some((comp) => !!prod && !comp.productPrice[i])
      );
    }),
});

export const InformationAboutCompetitorsFormSchema: ObjectSchema<InformationAboutCompetitorsFormI> =
  object().shape({
    competitors: array(FormItemSchema.required()).required(),
    product: array(productSchema.required().nullable()).required(),
  });

/* ==== */
