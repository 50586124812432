import { Box, Collapse, Fab, Fade, Typography } from '@mui/material';
import { FC } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';
import { AnimatedFABProps } from './type';

export const AnimatedFAB: FC<AnimatedFABProps> = ({
  icon,
  text,
  typographyProps,
  ...props
}) => {
  const { close, isOpen, open } = useViewModal();

  return (
    <Fab
      onMouseEnter={open}
      onMouseLeave={close}
      {...props}
    >
      <Box
        sx={{
          mx: -0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon}
      </Box>

      <Collapse
        in={isOpen}
        orientation='horizontal'
      >
        <Fade in={isOpen}>
          <Typography
            fontWeight={'bold'}
            fontSize={15}
            whiteSpace={'nowrap'}
            pl={1}
            {...typographyProps}
          >
            {text}
          </Typography>
        </Fade>
      </Collapse>
    </Fab>
  );
};
