import { generatePath } from 'react-router-dom';
import { ALL_ROUTES } from 'shared/routes/constants/types';

export const getTaskLink = (guid: string) => {
  return generatePath('/' + ALL_ROUTES.tasks + '/' + ALL_ROUTES.tasksGuid, {
    guid,
  });
};

export const getRedirectTaskLink = (id: number) => {
  return generatePath('/' + ALL_ROUTES.tasksId, {
    id,
  });
};
