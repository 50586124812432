import { Button, CircularProgress, Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ALL_ROUTES } from 'shared/routes/constants/types';

import { useChecklist } from 'entities/visit/checkList/context/ChecklistContext';

import { useStager } from '../Stager/hooks/useStager';
import { StageStatuses, STAGE_STATUSES } from '../Stager/type';

interface VisitPaginationProps {
  isLoading?: boolean;
  nextDisable?: boolean;
  complite?: boolean;
}
export const VisitPagination: FC<VisitPaginationProps> = ({
  isLoading = false,
  nextDisable = false,
  complite = false,
}) => {
  const navigate = useNavigate();
  const { checkList } = useChecklist();
  const stageData = useStager(checkList);

  const indexOfPrevAccesStage = useMemo(() => {
    const iOfCur = stageData.findIndex(
      (el) => el.status === StageStatuses.selected
    );

    if (iOfCur !== -1 && iOfCur !== 0)
      for (let i = iOfCur - 1; i >= 0; i--) {
        const element = stageData[i];

        if (element.status !== StageStatuses.disable) return i;
      }

    return -1;
  }, [stageData]);

  return (
    <Grid
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <Button
        size='large'
        disabled={indexOfPrevAccesStage === -1}
        onClick={() => {
          navigate(
            '/' +
              ALL_ROUTES.visitingPharmacies +
              '/' +
              ALL_ROUTES.new +
              '/' +
              stageData[indexOfPrevAccesStage].route
          );
        }}
      >
        Назад
      </Button>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
        }}
      >
        {stageData
          .filter((stage) => stage.isView)
          .map((stage, i) => (
            <Button
              key={stage.route}
              variant='contained'
              color={
                stage.status === StageStatuses.selected
                  ? 'customBlue'
                  : stage.compliteStatus === STAGE_STATUSES.COMPLITE
                  ? 'primary'
                  : 'customGrey'
              }
              disabled={stage.status === StageStatuses.disable}
              onClick={() => {
                navigate(
                  '/' +
                    ALL_ROUTES.visitingPharmacies +
                    '/' +
                    ALL_ROUTES.new +
                    '/' +
                    stage.route
                );
              }}
              sx={{
                borderRadius: '0px',
                padding: 0,
                minWidth: '30px',
                width: '30px',
                height: '30px',
              }}
            >
              {i + 1}
            </Button>
          ))}
      </Grid>
      <Button
        disabled={nextDisable}
        size='large'
        type='submit'
      >
        {isLoading ? (
          <CircularProgress size={20} />
        ) : complite ? (
          'Завершить'
        ) : (
          'Далее'
        )}
      </Button>
    </Grid>
  );
};
