import { getToken } from 'shared/utils/getToken';
import { BASE_API_URL } from '../constants';
import { Api, RequestParams } from './marketingSurfaceAutogenerateApi';

export const createApi = new Api<typeof AxiosRequestConfigForSecurityWorker>({
  baseURL: BASE_API_URL,
  securityWorker(getT) {
    if (getT) {
      return getT();
    }
  },
});

const AxiosRequestConfigForSecurityWorker =
  async (): Promise<void | RequestParams> => {
    const accesToken = await getToken();
    if (accesToken) {
      return { headers: { Authorization: `Bearer ${accesToken}` } };
    }
  };

createApi.setSecurityData(AxiosRequestConfigForSecurityWorker);

export const marketingSurfaceApi = createApi;
