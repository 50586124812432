import { Grid, Typography } from '@mui/material';
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FC } from 'react';

import { DATE_FORMATS } from 'shared/date-helper/constants';

import { CustomDatePicker } from './base-ui';

export type onChangePeriod = (
  value: string | null,
  context: PickerChangeHandlerContext<DateValidationError>
) => void;
interface PropsUiExpensesFormPeriodPicker {
  isPeriod: boolean;
  periodTitle: string;
  beginPeriod: string | null;
  endPeriod: string | null;
  onChangeBegin: onChangePeriod;
  onChangeEnd: onChangePeriod;
}

export const UiExpensesFormPeriodPicker: FC<
  PropsUiExpensesFormPeriodPicker
> = ({
  isPeriod,
  periodTitle,
  beginPeriod,
  endPeriod,
  onChangeBegin,
  onChangeEnd,
}) => {
  if (!isPeriod) return null;

  return (
    <Grid
      container
      justifyContent={'space-between'}
      paddingTop={2}
    >
      <Typography>{periodTitle}</Typography>

      <Grid
        sx={{
          flexBasis: '45%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CustomDatePicker
          label={'Дата начала'}
          format='DD.MM.YYYY'
          value={beginPeriod ? dayjs(beginPeriod) : null}
          onChange={(value, context) => {
            onChangeBegin(value?.format(DATE_FORMATS.client) || null, context);
          }}
          slotProps={{
            textField: {
              inputProps: {
                placeholder: 'дд.мм.гггг',
              },
            },
          }}
        />

        <CustomDatePicker
          label={'Дата окончания'}
          format='DD.MM.YYYY'
          value={endPeriod ? dayjs(endPeriod) : null}
          onChange={(value, context) => {
            onChangeEnd(value?.format(DATE_FORMATS.client) || null, context);
          }}
          slotProps={{
            textField: {
              inputProps: {
                placeholder: 'дд.мм.гггг',
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
