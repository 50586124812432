import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, Typography } from '@mui/material';
import { AdaptationProcessTasks } from 'features/adaptation/AdaptationProcessTasks/AdaptationProcessTasks';
import { FC } from 'react';
import { AdaptationProcessListItem } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { theme } from 'shared/theme/theme';
import {
  UiTable,
  UiTableCell,
  UiTableHeaderWithSort,
  UiTablePagination,
  UiTableRow,
  UiTableRowWithActions,
} from 'shared/ui/table';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';
import { AdaptationProcessTableType } from './type';

export const CurrentAdaptationProcessesTable: FC<
  AdaptationProcessTableType
> = ({
  data: processes,
  isLoading = false,
  isUpdate,
  onClick,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  count,
  order,
  onChangeOrder,
  renderActions,
  fields,
  onChangeFields,
  visibleFields,
}) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading && !processes.length}
      skeleton={<UiTableSkeleton limit={limit} />}
      header={
        <UiTableHeaderWithSort
          viewCells={[
            {
              field: 'adaptationProcessGuid',
              title: '',
              getTableCellProps: () => {
                return {};
              },
              isShow: true,
              renderComponent: () => {
                return <></>;
              },
              order: 0,
            },
            ...visibleFields,
          ]}
          setViewCells={onChangeFields}
          allCells={fields}
          order={order.order}
          orderBy={order.orderBy}
          onChangeOrder={onChangeOrder}
          orderByList={[
            'adaptationTemplateTitle',
            'employeeName',
            'mentorName',
            'createdAt',
            'createdBy',
          ]}
        />
      }
      body={processes.map((process) => (
        <TableRowWithSubTable
          key={process.adaptationProcessGuid}
          process={process}
          renderActions={renderActions}
          visibleFields={visibleFields}
          onClick={onClick}
        />
      ))}
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage + 1);
          }}
          page={page === 0 ? 0 : page - 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};

interface TableRowWithSubTableProps
  extends Pick<
    AdaptationProcessTableType,
    'onClick' | 'renderActions' | 'visibleFields'
  > {
  process: AdaptationProcessListItem;
}

const TableRowWithSubTable: FC<TableRowWithSubTableProps> = ({
  process,
  visibleFields,
  onClick,
  renderActions,
}) => {
  const { isOpen, onToggle } = useViewModal();
  const isSowSubTable = !!process.adaptationProcessGuid;

  return (
    <>
      <UiTableRowWithActions
        key={process.employeeGuid}
        onClick={() => {
          onClick?.(process);
        }}
        hover
        renderActions={(isOpen, setIsLoading) => {
          return renderActions(process, { isOpen, setIsLoading });
        }}
        sx={{ cursor: 'pointer' }}
      >
        <UiTableCell
          key={'1'}
          sx={{
            borderBottom: 'unset',
            borderTop: 'solid 1px',
            borderColor: theme.palette.customGrey.main,
          }}
        >
          <IconButton
            disabled={!isSowSubTable}
            aria-label='expand row'
            size='small'
            onClick={onToggle}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </UiTableCell>

        {visibleFields.map((el) => (
          <UiTableCell
            key={el.field + process.employeeGuid}
            {...el.getTableCellProps()}
          >
            {el.renderComponent(process) || 'Error'}
          </UiTableCell>
        ))}
      </UiTableRowWithActions>

      {isSowSubTable && (
        <UiTableRow>
          <UiTableCell colSpan={visibleFields.length + 2}>
            {process.adaptationProcessGuid ? (
              <Collapse
                collapsedSize={0}
                in={isOpen}
                timeout={'auto'}
                unmountOnExit
              >
                <AdaptationProcessTasks
                  processGuid={process.adaptationProcessGuid}
                />
              </Collapse>
            ) : (
              <Typography
                color={'red'}
              >{`process.adaptationProcessGuid = ${process.adaptationProcessGuid}`}</Typography>
            )}
          </UiTableCell>
        </UiTableRow>
      )}
    </>
  );
};
