import { DragIndicator } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { DnDItemProps } from './type';
import { getItemStyle } from './utils';

export const DnDItem: FC<DnDItemProps> = ({
  children,
  showIconDraggable = true,
  dragEventOnlyIcon = false,
  ...props
}) => {
  return (
    <Draggable {...props}>
      {(provided, snapshot) => (
        <Grid
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...(dragEventOnlyIcon ? {} : provided.dragHandleProps)}
          style={
            getItemStyle(snapshot.isDragging, provided.draggableProps.style) as
              | React.CSSProperties
              | undefined
          }
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            pr: showIconDraggable ? 2 : 0,
          }}
        >
          {children}
          {showIconDraggable && (
            <IconButton
              {...(dragEventOnlyIcon ? provided.dragHandleProps : {})}
            >
              <DragIndicator />
            </IconButton>
          )}
        </Grid>
      )}
    </Draggable>
  );
};
