import { VisitFilterDialog } from 'features/visit/visit-filter-dialog/VisitFilterDialog';
import { memo, useCallback } from 'react';

import { getVisitingsAction } from 'shared/api/visit/services';
import {
  GetVisitingsActionQueryParams,
  VisitingGetListResponse,
} from 'shared/api/visit/types';
import { useViewModal } from 'shared/hooks/useViewModal';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { convertURLToVisitFilter } from 'shared/url-helper/conventors/convertURLToVisitFilter';
import { useURLState } from 'shared/url-helper/useURLState';

import { useCreateChecklist } from 'entities/visit/checkList/hooks/useCreateChecklist';
import { useConfirmModalVisitDelete } from 'entities/visit/visit/hooks/useConfirmModalVisitDelete';
import { useGetVisits } from 'entities/visit/visit/hooks/useGetVisits';
import { useVisitNavigation } from 'entities/visit/visit/hooks/useVisitNavigation';

import { ActionVisitSearchBar } from 'features/visit/ActionVisitSearchBar';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { Tables } from 'shared/utils/controllers';
import {
  convertFetchDataToInitVisitFilterForm,
  convertVisitFilterFormFieldsToFetchData,
  VisitFilterFormFields,
} from 'widgets/visits/forms/visit-filter-form';
import {
  AdeptiveVisitsTable,
  VisitTableActions,
} from 'widgets/visits/visits-table';

export const ActionVisitsPage = () => {
  const { navigateToCheckList, navigateToCreateChecklist } =
    useVisitNavigation();
  const { createChecklist, isLoading: isLoadingCreateChecklist } =
    useCreateChecklist();

  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<VisitingGetListResponse>(Tables.ACTION_VISIT);

  const [filters, setFilters] = useURLState<GetVisitingsActionQueryParams>(
    convertURLToVisitFilter,
    true
  );

  const { count, isLoading, visits, update } = useGetVisits(
    getVisitingsAction,
    filters
  );

  const { deleteVisit, modal } = useConfirmModalVisitDelete(update);

  const createChecklistHandler = useCallback(
    async (visitId: number) => {
      try {
        const data = await createChecklist(visitId);

        if (data) navigateToCheckList(data.id);
      } catch (e) {
        console.error(e);
      }
    },
    [createChecklist, navigateToCheckList]
  );

  const applySearch = useCallback(
    (search: string) => {
      setFilters({ ...filters, SearchText: search });
    },
    [filters]
  );

  /* Filter */
  const {
    close: closeFilterDialog,
    isOpen: isOpenFilterDialog,
    open: openFilterDialog,
  } = useViewModal();

  const handleSubmitFilter = (form: VisitFilterFormFields) => {
    setFilters(convertVisitFilterFormFieldsToFetchData(form));
    closeFilterDialog();
  };
  /* ===== */

  const changeLimitHandler = useCallback(
    (limit: number) => {
      setFilters({ ...filters, Limit: limit, Page: 0 });
    },
    [filters]
  );

  const changePageHandler = useCallback(
    (page: number) => {
      setFilters({ ...filters, Page: page });
    },
    [filters]
  );

  const renderActions = useCallback((visit: VisitingGetListResponse) => {
    return (
      <VisitTableActions
        visit={visit}
        onCreateChecklist={createChecklistHandler}
        onDelete={deleteVisit}
        onOpen={navigateToCheckList}
      />
    );
  }, []);

  const tableClickHandler = useCallback((visit: VisitingGetListResponse) => {
    if (visit.visitingChecklistId) {
      navigateToCheckList(visit.visitingChecklistId);
    } else {
      navigateToCreateChecklist(visit.id);
    }
  }, []);

  return (
    <LayoutPage.Page>
      {modal}
      <CustomBackdrop isLoading={isLoadingCreateChecklist} />
      <VisitFilterDialog
        isOpen={isOpenFilterDialog}
        onClose={closeFilterDialog}
        initValue={convertFetchDataToInitVisitFilterForm(filters)}
        onSubmit={handleSubmitFilter}
      />

      <LayoutPage.Bar>
        <MemoizedActionVisitSearchBar
          applySearch={applySearch}
          onOpenFilter={openFilterDialog}
          initSearch={filters?.SearchText || ''}
        />
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <MemoizedAdeptiveVisitsTable
          fields={fields}
          visibleFields={visibleFields}
          onChangeFields={setFields}
          data={visits}
          count={count}
          isLoading={isLoading}
          limit={filters?.Limit || 20}
          page={filters?.Page || 0}
          onChangeLimit={changeLimitHandler}
          onChangePage={changePageHandler}
          renderActions={renderActions}
          onClick={tableClickHandler}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};

const MemoizedActionVisitSearchBar = memo(ActionVisitSearchBar);
const MemoizedAdeptiveVisitsTable = memo(AdeptiveVisitsTable);
