import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
} from '@mui/material';
import { FC } from 'react';
import { LIST_ITEM_BUTTON_SX, LIST_ITEM_ICON_SX } from './constants';
import { useIsOpenDrawerContext } from './CustomDrawer';
import { CustomListItemButtonMoreDrawerComponent } from './types';

export const CustomListItemButtonMoreDrawer: CustomListItemButtonMoreDrawerComponent =
  ({ isOpen, Icon, textPrimary, onClick }) => {
    const isOpenDrawer = useIsOpenDrawerContext();

    const OPACITY = isOpenDrawer ? 1 : 0;

    return (
      <ListItem
        sx={{ display: 'block' }}
        disablePadding
      >
        <ListItemButton
          sx={{
            ...LIST_ITEM_BUTTON_SX,
            justifyContent: isOpenDrawer ? 'initial' : 'center',
          }}
          onClick={onClick}
        >
          <ListItemIcon
            sx={{
              ...LIST_ITEM_ICON_SX,
              mr: isOpenDrawer ? 2 : 'auto',
            }}
          >
            {Icon}
          </ListItemIcon>
          <ListItemText
            primary={textPrimary}
            primaryTypographyProps={{
              fontWeight: 'bold',
              mr: isOpenDrawer ? 2 : 'auto',
            }}
            sx={{ opacity: OPACITY }}
          />

          {isOpenDrawer && (
            <ExpandIcon
              isOpen={isOpen}
              sx={{ opacity: OPACITY }}
            />
          )}
        </ListItemButton>
      </ListItem>
    );
  };

const ExpandIcon: FC<{ isOpen: boolean; sx?: SxProps<Theme> }> = ({
  isOpen,
  sx,
}) => {
  if (isOpen) {
    return <ExpandLess sx={sx} />;
  }

  return <ExpandMore sx={sx} />;
};
