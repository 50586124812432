import { Grid } from '@mui/material';
import { FC } from 'react';

import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';
import { ContainerSectionProps } from './types';

export const ContainerSection: FC<ContainerSectionProps> = ({
  title,
  children,
}) => {
  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={2}
    >
      <DocumentSectionTitle text={title} />
      {children}
    </Grid>
  );
};
