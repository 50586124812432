import { useEffect, useState } from 'react';

import { getRegions } from 'shared/api/visit/services';
import { RegionGetListResponse } from 'shared/api/visit/types';

export const useGetVisitRegions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [regions, setRegions] = useState<RegionGetListResponse[]>([]);

  const getReg = async () => {
    setIsLoading(true);
    try {
      const { data, status } = await getRegions();

      if (!data || status !== 200) throw new Error('Упс, что-то пошло не так');
      setRegions(data);
    } catch (e) {
      console.error('useGetRegions.getReg >> ', e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getReg();
  }, []);

  return { regions, isLoading };
};
