// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { sample } from 'effector';
import { useStore } from 'effector-react';
import { LayoutWithDocumentActionsModal } from 'features/document/document-actions';
import { postDocumentsDocumentIdDoActionFx } from 'features/document/document-actions/api/document-actions';
import { DocumentControlFilterModal } from 'features/document/filters/document-control-filter/DocumentControlFilterModal';
import { documentControlFilter } from 'features/document/filters/document-control-filter/models/model-filter';
import { useEffect } from 'react';

import { useTableCellsFromLocalStorage } from 'shared/ui/table';

import { deleteDocumentIdFx } from 'entities/document/api/document-id-effector';
import { getDocumentsControlFx } from 'entities/document/api/document-list-effector';
import { $documentControlList } from 'entities/document/model/document-control-list';

import { renderDocumentActionsContextMenu } from 'widgets/document/document-table';
import { AdeptiveDocumentTable } from 'widgets/document/document-table/AdeptiveDocumentTable';

import dayjs from 'dayjs';
import { compliteActiconCloseDocument } from 'features/document/document-actions/deprecated-effector';
import {
  useDocumentControlFilterModal,
  useFilterDocumentFlowControlPage,
  useUrlStateDocumentControlFilter,
} from 'features/document/filters/document-control-filter';
import { DownloadExcelFileFromListWithHook } from 'features/download-file-excel-from-list/DownloadExcelFileFromListWithHook';
import { useLazyDownloadFileFromListDocumentControlQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';
import { DocumentListItemResponse } from 'shared/api/services-document-flow/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';

import { Tables } from 'shared/utils/controllers';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { LayoutPageDocumentsListWithFilterBar } from './layouts';

sample({
  clock: [
    deleteDocumentIdFx.done,
    postDocumentsDocumentIdDoActionFx.done,
    compliteActiconCloseDocument,
  ],
  target: documentControlFilter.upDateList,
});

const usePaginationDocumentRowControls = () => {
  return useStore(documentControlFilter.headerPaginations);
};

const createFileName = () =>
  `Документооборот(контролирую) от ${dayjs().format(
    DATE_FORMATS.clientWithTime
  )}.xlsx`;

export const PageDocumentControls = () => {
  const [allFields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<DocumentListItemResponse>(Tables.CONTROL_DOC);

  const isLoading = useStore(getDocumentsControlFx.pending);
  const documentsList = useStore($documentControlList);

  const pagination = usePaginationDocumentRowControls();
  const { filter } = useUrlStateDocumentControlFilter();
  const {
    searchInput,
    handleChangePage,
    handleChangePageSize,
    handleChangeSearchText,
    handleCompliteChangeSearchText,
    pageSize,
    currentPage,
  } = useFilterDocumentFlowControlPage();

  useEffect(() => {
    documentControlFilter.events.setAll(filter);
  }, [filter]);

  const {
    isLoadingInitDocumentControlFilterModalForm,
    isOpenDocumentControlFilterModalForm,
    onCloseDocumentControlFilterModalForm,
    onOpenDocumentControlFilterModalForm,
    onSubmitDocumentControlFilterModal,
    initialValueDocumentControlFilterModalForm,
  } = useDocumentControlFilterModal();

  return (
    <LayoutPageDocumentsListWithFilterBar
      inputSearch={searchInput}
      onChangeInputSearch={handleChangeSearchText}
      onCompliteInput={handleCompliteChangeSearchText}
      FilterAction={
        <>
          <DownloadExcelFileFromListWithHook
            filter={filter}
            useLazyDownloadFileFromList={
              useLazyDownloadFileFromListDocumentControlQuery
            }
            createFileName={createFileName}
          />
          <FilterButton onClick={onOpenDocumentControlFilterModalForm} />
          <DocumentControlFilterModal
            isOpen={isOpenDocumentControlFilterModalForm}
            onClose={onCloseDocumentControlFilterModalForm}
            isLoading={isLoadingInitDocumentControlFilterModalForm}
            onSubmit={onSubmitDocumentControlFilterModal}
            initialValue={initialValueDocumentControlFilterModalForm}
          />
        </>
      }
    >
      <LayoutWithDocumentActionsModal>
        <AdeptiveDocumentTable
          data={documentsList || EMPT_ARR}
          isLoading={isLoading}
          page={currentPage}
          onChangePage={handleChangePage}
          count={pagination.TotalCount}
          limit={pageSize}
          onChangeLimit={handleChangePageSize}
          fields={allFields}
          visibleFields={visibleFields}
          onChangeFields={setFields}
          renderActionsContextMenu={renderDocumentActionsContextMenu}
        />
      </LayoutWithDocumentActionsModal>
    </LayoutPageDocumentsListWithFilterBar>
  );
};
