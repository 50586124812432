import { FC, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  useGetBoardsQuery,
  useGetColumnsQuery,
} from 'shared/api/services/rtk/boardApi';
import { useDebounce } from 'shared/hooks/useDebounce';
import { getObjByGuid } from 'shared/tarnsform-helper/getObjByGuid';
import { CustomAutocomplete } from 'shared/ui/base-ui';
import { FormLayout } from 'shared/ui/form';
import { AddTaskToBoardFormFields, AddTaskToBoardFormProps } from './type';

export const AddTaskToBoardForm: FC<AddTaskToBoardFormProps> = ({
  onSubmit,
  renderFormActions,
  initForm,
}) => {
  const [search, setSearch] = useState('');
  const debSearch = useDebounce(search);
  const { data, isLoading } = useGetBoardsQuery({
    Limit: 20,
    Page: 1,
    IsArchive: false,
    SearchText: debSearch,
  });

  const { handleSubmit, control } = useForm<AddTaskToBoardFormFields>({
    defaultValues: initForm,
  });

  const boardGuid = useWatch({ control, name: 'boardGuid' });

  const { data: columns, isFetching: isLoadingColumns } = useGetColumnsQuery(
    boardGuid,
    { skip: !boardGuid }
  );

  return (
    <FormLayout
      onSubmit={handleSubmit(onSubmit)}
      actions={renderFormActions(handleSubmit)}
    >
      <Controller
        control={control}
        name='boardGuid'
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            textFieldProps={{
              required: true,
              label: 'Доска',
              placeholder: 'Выберите доску',
            }}
            loading={isLoading}
            options={data?.items || []}
            value={getObjByGuid(data?.items, value)}
            onInputChange={(_, value) => {
              setSearch(value);
            }}
            onChange={(_, value) => {
              onChange(value?.guid);
            }}
            getOptionLabel={(op) => op.title}
            isOptionEqualToValue={(op, eq) => op.guid === eq.guid}
          />
        )}
      />

      <Controller
        control={control}
        name='columnGuid'
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Столбец',
              placeholder: 'Выберите столбец',
              required: true,
            }}
            loading={isLoadingColumns}
            options={columns || []}
            value={getObjByGuid(columns, value)}
            onChange={(_, value) => {
              onChange(value?.guid);
            }}
            getOptionLabel={(op) => op.title}
            isOptionEqualToValue={(op, eq) => op.guid === eq.guid}
          />
        )}
      />
    </FormLayout>
  );
};
