import { Paper } from '@mui/material';
import { FC } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';
import { CardContext } from './context';
import { CardContainerProps } from './types';

export const CardContainer: FC<CardContainerProps> = ({
  children,
  collapsedVariant = false,
  paperProps,
}) => {
  const { isOpen: isCollapsed, onToggle: toggleCollapsed } = useViewModal();

  return (
    <CardContext.Provider
      value={{ collapsedVariant, isCollapsed, toggleCollapsed }}
    >
      <Paper
        {...paperProps}
        sx={{ paddingY: 1, ...paperProps.sx }}
      >
        {children}
      </Paper>
    </CardContext.Provider>
  );
};
