// eslint-disable-next-line budapestian/global-constant-pattern
import { BannerBrandsItem } from './BannerBrandsItem';
import { BannerBrandsItemSkeleton } from './BannerBrandsItemSkeleton';
import { ContainerBannerBrands } from './ContainerBannerBrands';

export type { BannerBrandsItemProps, BannerBrandsItemPropsBase } from './types';

export const BannerBrands = {
  Card: BannerBrandsItem,
  CardSkeleton: BannerBrandsItemSkeleton,
  Container: ContainerBannerBrands,
};
