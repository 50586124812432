import { useCallback, useMemo } from 'react';
import { UserNoAdaptationRequiredItem } from 'shared/api/service-adaptation/normolize-data/transformResponseBackendUserNoAdaptationRequiredToViewType';
import { usePaginationStateHandlerWithFilter } from 'shared/hooks/usePaginationStateHandlerWithFilter';
import { useSearchStateHendlerWithFilter } from 'shared/hooks/useSearchStateHendlerWithFilter';
import { OrderFilter } from 'shared/ui/table/types/order-props';
import {
  userNoAdaptationRequiredOrderTableToQueryOrderBy,
  userNoAdaptationRequiredQueryOrderByToOrderTable,
  useUrlStateUserNoAdaptationsRequiredFilter,
} from './use-url-state-user-no-adaptations-required-filter';

export const useFilterUserNoAdaptationsRequiredPage = () => {
  const { filter, setFilter } = useUrlStateUserNoAdaptationsRequiredFilter();

  const {
    handleChangeSearchText,
    handleCompliteChangeSearchText,
    searchInput,
  } = useSearchStateHendlerWithFilter({
    filter,
    setFilter,
    keySearch: 'SearchText',
    keyPage: 'Page',
  });

  const { handleChangePage, handleChangePageSize, currentPage, pageSize } =
    usePaginationStateHandlerWithFilter({
      filter,
      setFilter,
      keyPage: 'Page',
      keyPageSize: 'Limit',
    });

  const handleChangeOrderBy = useCallback(
    (params: OrderFilter<UserNoAdaptationRequiredItem>) => {
      setFilter({
        ...filter,
        OrderBy: userNoAdaptationRequiredOrderTableToQueryOrderBy(params),
      });
    },
    [filter]
  );

  const order = useMemo(() => {
    return userNoAdaptationRequiredQueryOrderByToOrderTable(filter.OrderBy);
  }, [filter.OrderBy]);

  const handleClearSearchText = useCallback(() => {
    handleChangeSearchText('');
    handleCompliteChangeSearchText('');
  }, [handleChangeSearchText, handleCompliteChangeSearchText]);

  return {
    searchInput,
    handleChangeSearchText,
    handleCompliteChangeSearchText,
    handleChangePage,
    handleChangePageSize,
    currentPage,
    pageSize,
    allFilter: filter,
    setAllFilter: setFilter,
    handleChangeOrderBy,
    handleClearSearchText,
    order,
  };
};
