import { FC } from 'react';

import {
  UiTableCard,
  UiTableCardList,
  UiTableCardPagination,
} from 'shared/ui/table';

import { SwipeableDrawerEmployesForTraining } from './SwipeableDrawerEmployesForTraining';
import { TrainingTableProps } from './types';

export const TrainingList: FC<TrainingTableProps> = ({
  count,
  data,
  fields,
  limit,
  onChangeFields,
  onChangeLimit,
  onChangePage,
  page,
  visibleFields,
  isLoading = false,
  onClick,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      isLoading={isLoading}
      onChangeFields={onChangeFields}
      pagination={
        <UiTableCardPagination
          count={count}
          onPageChange={onChangePage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowsPerPageChange={onChangeLimit}
        />
      }
    >
      {data.map(
        (training) =>
          training.guid && (
            <UiTableCard
              data={training}
              id={training.guid}
              visibleFields={[
                ...visibleFields,
                {
                  field: 'guid',
                  isShow: true,
                  getTableCellProps: () => {
                    return {};
                  },
                  order: 10,
                  title: '',
                  renderComponent(value) {
                    return (
                      <SwipeableDrawerEmployesForTraining
                        trainingGuid={value.guid || 'error'}
                      />
                    );
                  },
                },
              ]}
              key={training.guid}
              onClick={() => {
                onClick && onClick(training);
              }}
            />
          )
      )}
    </UiTableCardList>
  );
};
