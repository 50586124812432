import {
  IFormFastTaskUi,
  INIT_FORM,
  prepareDataToFetch,
} from 'features/task/form-fast-task';
import { FC } from 'react';

import { CreateFastTaskDialog } from 'features/task/form-fast-task/FormFastTaskUi';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useCreateTaskMutation } from 'shared/api/services/rtk/taskApi';
import { ModalProps } from './type';

export const ModalFormFastCreateTask: FC<ModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [createTask, { isLoading, error }] = useCreateTaskMutation();

  useShowFetchErrorInSnackbar(error);

  const postTask = (formData: IFormFastTaskUi) => {
    const fetchData = prepareDataToFetch({ ...formData, originId: 0 }, false);

    if (fetchData) createTask(fetchData).then(onClose);
  };

  const saveDraftTask = (formData: IFormFastTaskUi) => {
    const fetchData = prepareDataToFetch({ ...formData, originId: 0 }, true);

    if (fetchData) createTask(fetchData).then(onClose);
  };

  return (
    <CreateFastTaskDialog
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={postTask}
      onDraftSubmit={saveDraftTask}
      initValue={INIT_FORM}
      isLoading={isLoading}
    />
  );
};
