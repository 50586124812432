import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';
import { AptekaLegkoSliderFilterModal } from './AptekaLegkoSliderFilterModal';
import { useAptekaLegkoFilterModalWithUrlState } from './hooks';

export const FilterButtonWithAptekalegkoSliderFilterModal = () => {
  const {
    isOpenDialogFilterBannersSlider,
    initialValueDialogFilterBannersSlider,
    onOpenDialogFilterBannersSlider,
    onCloseDialogFilterBannersSlider,
    handleSubmitFormFilterBanersSlider,
  } = useAptekaLegkoFilterModalWithUrlState();

  return (
    <>
      <FilterButton onClick={onOpenDialogFilterBannersSlider} />
      <AptekaLegkoSliderFilterModal
        onSubmit={handleSubmitFormFilterBanersSlider}
        initialValue={initialValueDialogFilterBannersSlider}
        isOpen={isOpenDialogFilterBannersSlider}
        onClose={onCloseDialogFilterBannersSlider}
      />
    </>
  );
};
