import dayjs from 'dayjs';
import { useStoreMap } from 'effector-react';

import {
  onChangePeriod,
  UiExpensesFormPeriodPicker,
} from 'shared/ui/UiExpensesFormPeriodPicker';

import { documentFormModel } from 'entities/document-form';

export const ExpensesFormPeriodPicker = () => {
  const isPeriod = useStoreMap({
    store: documentFormModel.process,
    keys: [documentFormModel.process],
    fn: (document) => {
      return document ? document.isExpenseByPeriod : false;
    },
  });
  const periodTitle = useStoreMap({
    store: documentFormModel.process,
    keys: [documentFormModel.process],
    fn: (state) => {
      return state ? state.periodTitle || '' : '';
    },
  });
  const beginPeriod = useStoreMap({
    store: documentFormModel.form,
    keys: [documentFormModel.form],
    fn: (document) => {
      return (document && document.beginPeriod) || null;
    },
  });
  const endPeriod = useStoreMap({
    store: documentFormModel.form,
    keys: [documentFormModel.form],
    fn: (document) => {
      return (document && document.endPeriod) || null;
    },
  });
  const onChangeBegin: onChangePeriod = (value) =>
    documentFormModel.formEvent.setField({
      key: 'beginPeriod',
      value: dayjs(value).isValid()
        ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss')
        : null,
    });
  const onChangeEnd: onChangePeriod = (value) =>
    documentFormModel.formEvent.setField({
      key: 'endPeriod',
      value: dayjs(value).isValid()
        ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss')
        : null,
    });

  if (!isPeriod) return null;

  return (
    <UiExpensesFormPeriodPicker
      isPeriod={isPeriod}
      periodTitle={periodTitle}
      beginPeriod={beginPeriod}
      endPeriod={endPeriod}
      onChangeBegin={onChangeBegin}
      onChangeEnd={onChangeEnd}
    />
  );
};
