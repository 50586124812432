import { MyToggleButtonGroup } from 'shared/ui/my-toggle-button-groupe';
import { useDevice } from 'shared/utils/hook-type-device';

import { AdeptLayoutForUiComponent } from '../adept-layout-for-ui-component';
import { CustomToggleButtonGroupProps } from './type';

export const CustomToggleButtonGroup: CustomToggleButtonGroupProps = ({
  containerSx,
  ...props
}) => {
  const [isMob] = useDevice();

  return (
    <AdeptLayoutForUiComponent
      label={props.label || ''}
      containerSx={containerSx}
    >
      <MyToggleButtonGroup
        {...props}
        label={!isMob ? props.label : undefined}
      />
    </AdeptLayoutForUiComponent>
  );
};
