import aboutPng from 'shared/img/site-guide/about.png';
import documentFlowPng from 'shared/img/site-guide/document-flow.png';
import inDevelopPng from 'shared/img/site-guide/inDevelop.png';
import supportPng from 'shared/img/site-guide/support.png';
import tasksPng from 'shared/img/site-guide/tasks.png';
import trainingPng from 'shared/img/site-guide/training.png';
import visitsPng from 'shared/img/site-guide/visits.png';

import { ABOUT } from './about';
import { APTEK_ALEGKO } from './apteka-legko';
import { ADAPTATION } from './adaptation';
import { BOARD } from './board';
import { DOCUMENT_FLOW } from './document';
import { MARKETING_SURFACE } from './marketing-surface';
import { RETAIL_OPENING_PROCESS } from './retail-opening-process';
import { TASKS } from './task';
import { TRAINING } from './training';
import { ALL_ROUTES, Routes } from './types';
import { USER } from './user';
import { VISIT } from './visit';

export const INITIAL_ROUTES: Routes[] = [
  {
    title: 'Главная',
    path: ALL_ROUTES.main,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: ALL_ROUTES.main,
  },
  USER,
  {
    title: 'Задачник',
    path: ALL_ROUTES.tasks,
    isWork: true,
    description: 'Управление задачами, проектами и рабочими процессами',
    icon: tasksPng,
    fullPath: ALL_ROUTES.tasks,
    children: TASKS,
  },
  {
    title: 'Доски',
    path: ALL_ROUTES.boards,
    isWork: true,
    description: 'Управление задачами, проектами и рабочими процессами',
    icon: tasksPng,
    fullPath: ALL_ROUTES.boards,
    children: BOARD,
  },
  {
    title: '',
    path: ALL_ROUTES.tasksId,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Документооборот',
    path: ALL_ROUTES.documentFlow,
    isWork: true,
    children: DOCUMENT_FLOW,
    description:
      'Перемещение и согласование документов между отделами и сотрудниками',
    icon: documentFlowPng,
    fullPath: ALL_ROUTES.documentFlow,
  },
  {
    title: 'Посещение аптек',
    path: ALL_ROUTES.visitingPharmacies,
    isWork: true,
    children: VISIT,
    description: 'Контроль и управление системой посещения аптек',
    icon: visitsPng,
    fullPath: ALL_ROUTES.visitingPharmacies,
  },
  RETAIL_OPENING_PROCESS,
  ADAPTATION,
  {
    title: 'Тренинги',
    path: ALL_ROUTES.training,
    isWork: true,
    children: TRAINING,
    description: 'Тренинги',
    icon: trainingPng,
    fullPath: ALL_ROUTES.visitingPharmacies,
  },
  {
    title: 'Тех. поддержка',
    path: ALL_ROUTES.support,
    isWork: true,
    children: [
      {
        title: 'Тех. поддержка (intraDesk)',
        path: '' as ALL_ROUTES.support,
        isWork: true,
        description:
          'Заявки и обращения сотрудников по вопросам неисправностей',
        icon: inDevelopPng,
        fullPath: '',
        target: '_blank',
        children: null,
      },
    ],
    description: 'Заявки и обращения сотрудников по вопросам неисправностей',
    icon: supportPng,
    fullPath: '',
    target: '_blank',
  },
  {
    title: 'О компании',
    path: ALL_ROUTES.about,
    isWork: false,
    children: ABOUT,
    description:
      'Удобный поиск по аптекам, офисам и сотрудникам, актуальные новости компании',
    icon: aboutPng,
    fullPath: ALL_ROUTES.about,
  },
  {
    title: 'Календарь',
    path: ALL_ROUTES.calendar,
    isWork: false,
    children: null,
    description: 'Органайзер рабочего времени: встречи, посещения и собрания',
    icon: inDevelopPng,
    fullPath: ALL_ROUTES.calendar,
  },
  MARKETING_SURFACE,
  APTEK_ALEGKO,
];
