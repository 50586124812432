import { FC } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTableRowWithActions,
} from 'shared/ui/table';

import { ProblemTaskTableProps } from './type';

export const ProblemTaskTable: FC<ProblemTaskTableProps> = ({
  data,
  fields,
  onChangeFields,
  visibleFields,
  renderActions,
}) => {
  return (
    <UiTable
      header={
        <UiTableHeader
          allCells={fields}
          viewCells={visibleFields}
          setViewCells={onChangeFields}
        />
      }
      body={
        <>
          {data &&
            data.length !== 0 &&
            data.map((task) => (
              <UiTableRowWithActions
                key={task.id}
                hover
                renderActions={(isOpen, setIsLoading) => {
                  return renderActions(task, { isOpen, setIsLoading });
                }}
                sx={{ cursor: 'pointer' }}
              >
                {visibleFields.map((el) => (
                  <UiTableCell
                    key={el.field + task.id}
                    {...el.getTableCellProps()}
                  >
                    {el.renderComponent(task) || 'Error'}
                  </UiTableCell>
                ))}
              </UiTableRowWithActions>
            ))}
        </>
      }
    />
  );
};
