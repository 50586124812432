import { Divider, Typography } from '@mui/material';

import { RunProcessInfoView } from 'entities/adaptation/run-process-info-view';
import { EmployeeInfoViewWithFetchData } from 'features/adaptation/employee-info-view-with-fetch-data';
import { useRunAdaptationProcess } from 'features/adaptation/hooks/useRunAdaptationProcess';

import { useUrlStatePrepareAdaptationTasksParams } from 'features/adaptation/hooks/useUrlStatePrepareAdaptationTasksParams';
import { TemplateInformationViewWithFetchData } from 'features/adaptation/template-information-view-with-fetch-data';

import { useMemo } from 'react';

import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';

import {
  useGetAdaptationProcessesEmployeesQuery,
  useGetAdaptationProcessesPreparedStageTasksListQuery,
} from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { useGetAdaptationStagesQuery } from 'shared/api/service-adaptation/rtk/adaptationStagesProviderApi';

import {
  FormPreparedStagesTasksWithLoadAndEmptyStage,
  preparedResponseToFormValue,
} from 'shared/ui/form-run-process-prepared-stages-tasks';
import { GridLayoutPage } from 'shared/ui/grid-layout-page';

import { LayoutPage } from 'shared/ui/layouts/layout-page';

const FORM_FETCH_OPTIONS_CONTEXT_VALUE = {
  useGetEmployeeOptions: useGetAdaptationProcessesEmployeesQuery,
};

export const AdaptationProcessesRunPage = () => {
  const { filter } = useUrlStatePrepareAdaptationTasksParams();
  const {
    MentorGuid,
    NewEmployeeGuid,
    AdaptationGuid,
    SkipStage,
    countdownDate,
  } = filter || {};

  const { data: allStages, isLoading: isLoadingAllStages } =
    useGetAdaptationStagesQuery(AdaptationGuid as string, {
      skip: !SkipStage || SkipStage.length === 0 || !AdaptationGuid,
    });

  const skipStages = useMemo(() => {
    if (!allStages || !SkipStage) return undefined;

    return allStages.filter((allStage) => SkipStage.includes(allStage.guid));
  }, [allStages, SkipStage]);

  const {
    data: preparedStageTasks,
    isLoading: isLoadingPreparedStageTasks,
    error,
  } = useGetAdaptationProcessesPreparedStageTasksListQuery(
    {
      params: {
        MentorGuid: MentorGuid as string,
        NewEmployeeGuid: NewEmployeeGuid as string,
        AdaptationGuid: AdaptationGuid as string,
        StagesToSkipGuids: SkipStage,
      },
    },
    { skip: !MentorGuid || !NewEmployeeGuid || !AdaptationGuid }
  );

  useShowFetchErrorInSnackbar(error);

  const initFormValue = useMemo(() => {
    if (!preparedStageTasks) return undefined;

    const filteredPreparedStageTasks = preparedStageTasks;

    return preparedResponseToFormValue(filteredPreparedStageTasks);
  }, [preparedStageTasks]);

  const { handleRunAdaptationProcess, isLoadingSubmiteRunAdaptationProcess } =
    useRunAdaptationProcess({
      countdownDate,
      adaptationGuid: AdaptationGuid,
      mentorGuid: MentorGuid,
      newEmployeeGuid: NewEmployeeGuid,
    });

  return (
    <LayoutPage.Page>
      <LayoutPage.Content>
        <GridLayoutPage.Container>
          <GridLayoutPage.LeftStickySection>
            <Typography
              fontSize={20}
              fontWeight={'bold'}
            >
              Сотрудник
            </Typography>
            <EmployeeInfoViewWithFetchData employeeGuid={NewEmployeeGuid} />
            <Divider />
            <Typography
              fontSize={20}
              fontWeight={'bold'}
            >
              Процесс
            </Typography>
            <RunProcessInfoView
              skipStages={skipStages}
              isLoading={isLoadingAllStages}
              countdownDate={countdownDate}
            />
          </GridLayoutPage.LeftStickySection>
          <GridLayoutPage.RightTop>
            <TemplateInformationViewWithFetchData
              adaptationTemplateGuid={AdaptationGuid}
            />
          </GridLayoutPage.RightTop>
          <GridLayoutPage.RightMainContent>
            <FormPreparedStagesTasksWithLoadAndEmptyStage
              initialValue={initFormValue}
              onSubmite={handleRunAdaptationProcess}
              isLoadinInitialState={isLoadingPreparedStageTasks}
              isLoadingSubmite={isLoadingSubmiteRunAdaptationProcess}
              formFetchOptionsContextValue={FORM_FETCH_OPTIONS_CONTEXT_VALUE}
            />
          </GridLayoutPage.RightMainContent>
        </GridLayoutPage.Container>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
