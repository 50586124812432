import { FC } from 'react';

import { Grid } from '@mui/material';
import { LeftLabelWithChildren } from 'shared/ui/left-label-with-children';
import { EmployeeInfoViewProps } from './types';

export const EmployeeInfoView: FC<EmployeeInfoViewProps> = ({
  name,
  positionTitle,
  isVacation,
  retailTitle,
  isLoading,
}) => {
  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={1}
    >
      <LeftLabelWithChildren
        label='ФИО'
        isLoading={isLoading}
      >
        {name}
      </LeftLabelWithChildren>
      <LeftLabelWithChildren
        label='Должность'
        isLoading={isLoading}
      >
        {positionTitle}
      </LeftLabelWithChildren>
      <LeftLabelWithChildren
        label='Подразделение'
        isLoading={isLoading}
      >
        {retailTitle || '-'}
      </LeftLabelWithChildren>
      <LeftLabelWithChildren
        label='В отпуске'
        isLoading={isLoading}
      >
        {isVacation ? 'Да' : 'Нет'}
      </LeftLabelWithChildren>
    </Grid>
  );
};
