import { FC } from 'react';
import { Marker, Popup } from 'react-leaflet';

import { getMapIcon } from '../libs';
import { UiMapMarkerProps } from './type';

export const UiMapMarker: FC<UiMapMarkerProps> = ({
  location,
  popup,
  icon,
}) => {
  return (
    <Marker
      position={location}
      icon={getMapIcon(icon)}
    >
      <Popup>{popup}</Popup>
    </Marker>
  );
};
