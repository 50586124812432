import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';

export const UiAddButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      startIcon={<AddIcon />}
      variant='contained'
      color='secondary'
      size='medium'
      {...props}
      sx={{ borderRadius: ['0px', '8px'], ...props.sx }}
    />
  );
};
