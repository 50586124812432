import { FC } from 'react';
import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTablePagination,
  UiTableRowWithActions,
} from 'shared/ui/table';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';

import { TrainingTableActions } from '../actions';
import { ControlTrainingTableProps } from './types';

export const ControlTrainingTable: FC<ControlTrainingTableProps> = ({
  isLoading,
  isUpdate,
  limit,
  data,
  visibleFields,
  fields,
  onChangeFields,
  page,
  onChangePage,
  onChangeLimit,
  count,
}) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={limit} />}
      header={
        <UiTableHeader
          viewCells={visibleFields}
          allCells={fields}
          setViewCells={onChangeFields}
        />
      }
      body={data.map((training) => (
        <UiTableRowWithActions
          key={training.trainingEventGuid}
          renderActions={() => {
            return (
              <TrainingTableActions
                variant='controlTraining'
                training={{
                  statusGuid: training.statusGuid,
                  trainingEventGuid: training.trainingEventGuid,
                  employeeGuid: training.employeeGuid,
                  trainingEmployeeGuid: training.trainingEmployeeGuid,
                }}
              />
            );
          }}
        >
          {visibleFields.map((el) => (
            <UiTableCell
              key={el.field + training.trainingEventGuid}
              {...el.getTableCellProps()}
            >
              {el.renderComponent(training) || 'Error'}
            </UiTableCell>
          ))}
        </UiTableRowWithActions>
      ))}
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage + 1);
          }}
          page={page - 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};
