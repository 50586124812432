import { Theme } from '@emotion/react';
import { MenuItem, Select, SxProps } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { useDevice } from 'shared/utils/hook-type-device';

import { TaskStatusProps } from './type';

export const TaskStatus: FC<TaskStatusProps> = ({ task, onChangeStatus }) => {
  const [isMobile] = useDevice();

  return (
    <Select
      disabled={
        !task.accessActions.allowedStatuses ||
        task.accessActions.allowedStatuses?.length === 0
      }
      sx={{
        ...SELECT_STYLE,
        width: '100%',
        backgroundColor: [
          'white',
          task.accessActions.allowedStatuses?.length === 0
            ? theme.palette.customGrey.main
            : theme.palette.secondary.main,
        ],
      }}
      MenuProps={{ style: { left: isMobile ? '-16px' : '0px' } }}
      size='small'
      value={task.status.id}
      onChange={(e) => {
        const id = Number(e.target.value);

        if (id) {
          const status = task.accessActions.allowedStatuses?.find(
            (el) => el.id === id
          );

          if (status) onChangeStatus && onChangeStatus(status);
        }
      }}
    >
      {task.accessActions.allowedStatuses?.length === 0 ? (
        <MenuItem value={task.status.id}>{task.status.title}</MenuItem>
      ) : (
        task.accessActions.allowedStatuses?.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            {item.title}
          </MenuItem>
        ))
      )}
    </Select>
  );
};

const SELECT_STYLE: SxProps<Theme> = {
  boxSizing: 'border-box',
  flexBasis: ['100%', 'inherit'],
  maxWidth: '100%',
  fontWeight: ['400', '700'],
  color: ['rgba(0, 0, 0, 0.76)', 'white'],
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: ['rgba(0, 0, 0, 0.6)', 'white'],
  },
};
