import { Delete, Edit } from '@mui/icons-material';
import { FC } from 'react';
import { theme } from 'shared/theme/theme';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';
import { AdaptationTemplatesTableActionsProps } from './type';

export const AdaptationTemplatesTableActions: FC<
  AdaptationTemplatesTableActionsProps
> = ({ template, onDelete, onEdit }) => {
  return (
    <UiTableActions>
      <UiButtonAction
        text='Удалить'
        color={theme.palette.error.main}
        icon={<Delete />}
        onClick={() => {
          onDelete?.(template.guid);
        }}
      />
      <UiButtonAction
        text='Изменить'
        color={theme.palette.primary.main}
        icon={<Edit />}
        onClick={() => {
          onEdit?.(template.guid);
        }}
      />
    </UiTableActions>
  );
};
