// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */
// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createApi, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { UiExpensesFormModal } from 'features/document/expenses-form-modal/ui';
import { FC, ReactNode } from 'react';

import { DocumentModel } from 'shared/api/services-document-flow/types';

import { documentFormEditApi } from '../api';
import { getQueryForFirstValidFromDocumentModel } from '../lib';
import { documentFormEdit } from '../model';

const $isOpenModal = createStore(false).reset(documentFormEdit.formReset);

const events = createApi($isOpenModal, {
  open: () => true,
  close: () => false,
});

sample({
  clock: events.open,
  source: documentFormEdit.form,
  filter: (document): document is DocumentModel => Boolean(document),
  fn: (document) =>
    getQueryForFirstValidFromDocumentModel(document as DocumentModel),
  target: documentFormEditApi.firstValidation,
});

sample({
  clock: documentFormEditApi.secondValitation.doneData,
  target: events.close,
});
sample({
  clock: documentFormEditApi.firstValidation.fail,
  target: events.close,
});

export const modalFormEditExpenses = {
  isOpen: $isOpenModal,
  events,
};

export const DocumentFormEditExpenses: FC<{
  refArticlesInput?: React.MutableRefObject<any>;
  children: ReactNode | ReactNode[];
}> = ({ refArticlesInput, children }) => {
  const isOpenModal = useStore(modalFormEditExpenses.isOpen);

  return (
    <UiExpensesFormModal
      isOpenModal={isOpenModal}
      onClose={() => {
        modalFormEditExpenses.events.close();
        documentFormEdit.api.removeArticleidExpenseZero();
        refArticlesInput?.current.focus();
      }}
    >
      {children}
    </UiExpensesFormModal>
  );
};
