// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { Button } from '@mui/material';
import { createEvent, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { DocumetnDoActionRequest } from 'shared/api/services-document-flow/types';
import {
  UiDocumentFormProcessPostDocument,
  UiItemProcess,
} from 'shared/ui/UiDocumentFormProcessPostDocument';

import {
  documentFormModel,
  postAllDocumentsDocumentIdFilesReasonDocumentsFx,
  postDocumentsDocumentIdFilesCloseDocumentsFx,
  postDocumentsDocumentIdFilesReasonDocumentsFx,
  postDocumentsDraftFx,
  postDocumentsFx,
  postDocumentsSendFx,
} from 'entities/document-form';

const close = createEvent();
const $isDocumentFromComment = documentFormModel.commentForm.map((comment) =>
  Boolean(comment.comment && comment.comment?.length > 0)
);
const $isOpenBackdropProgress = createStore(false);

$isOpenBackdropProgress.on(
  documentFormModel.formEvent.postDocument,
  () => true
);
$isOpenBackdropProgress.on(
  documentFormModel.formEvent.postDocumentDraft,
  () => true
);
$isOpenBackdropProgress.on(close, () => false);

const $totalComplited = sample({
  source: {
    document: documentFormModel.formPostProgress,
    filesReason: documentFormModel.formProcessSaveReasonDocument,
    filesClose: documentFormModel.formProcessSaveReasonDocument,
  },
  fn: ({ document, filesReason, filesClose }) => {
    const compliteSaveDocument = document.document.complited;
    const compliteComments = document.comments
      ? document.comments.complited
      : true;
    const complitedDocumentSend = document.documentSend
      ? document.documentSend.complited
      : true;
    const complitedReasonFiles =
      filesReason.length === 0
        ? true
        : filesReason.filter((item) => !item.complited).length === 0;
    const complitedCloseFiles =
      filesClose.length === 0
        ? true
        : filesClose.filter((item) => !item.complited).length === 0;

    return Boolean(
      compliteComments &&
        complitedDocumentSend &&
        complitedCloseFiles &&
        compliteSaveDocument &&
        complitedReasonFiles
    );
  },
});

const $idResponsePostDocument = createStore<null | number>(null).reset(
  documentFormModel.resetFormModel
);
const $idResponsePostDocumentDraft = createStore<null | number>(null).reset(
  documentFormModel.resetFormModel
);

$idResponsePostDocument.on(
  postDocumentsFx.doneData,
  (_, response) => response.data.id
);
$idResponsePostDocumentDraft.on(
  postDocumentsDraftFx.doneData,
  (_, response) => response.data.id
);
sample({
  clock: documentFormModel.formEvent.postFiles,
  source: {
    files: documentFormModel.formFiles,
    idDocument: $idResponsePostDocument,
  },
  filter: (source, clock) =>
    Boolean(source.idDocument) &&
    Boolean(source.files.some((item) => item.name === clock)),
  fn: (source, clock) => {
    return {
      id: source.idDocument as number,
      file: source.files.find((item) => item.name === clock) as File,
    };
  },
  target: postDocumentsDocumentIdFilesReasonDocumentsFx,
});
sample({
  clock: documentFormModel.formEvent.postFilesClose,
  source: {
    files: documentFormModel.formFiles,
    idDocument: $idResponsePostDocument,
  },
  filter: (source, clock) =>
    Boolean(source.idDocument) &&
    Boolean(source.files.some((item) => item.name === clock)),
  fn: (source, clock) => {
    return {
      id: source.idDocument as number,
      file: source.files.find((item) => item.name === clock) as File,
    };
  },
  target: postDocumentsDocumentIdFilesCloseDocumentsFx,
});
sample({
  clock: postDocumentsFx.done,
  source: {
    documentId: $idResponsePostDocument,
    documentFormFiles: documentFormModel.formFiles,
  },
  filter: (source) =>
    Boolean(source.documentId && source.documentFormFiles.length === 0),
  fn: (source) => ({
    documentId: source.documentId as number,
    requestBody: { actionTaskId: 1 } as DocumetnDoActionRequest,
  }),
  target: postDocumentsSendFx,
});
sample({
  clock: postAllDocumentsDocumentIdFilesReasonDocumentsFx.done,
  source: {
    documentId: $idResponsePostDocument,
    documentFormFiles: documentFormModel.formFiles,
  },
  filter: (source) =>
    Boolean(source.documentId && source.documentFormFiles.length > 0),
  fn: (source) => ({
    documentId: source.documentId as number,
    requestBody: { actionTaskId: 1 } as DocumetnDoActionRequest,
  }),
  target: postDocumentsSendFx,
});
export const DocumentFormProcessPostDocument = () => {
  const isOpens = useStore($isOpenBackdropProgress);
  const progressDocument = useStore(documentFormModel.formPostProgress);
  const progressReasonDocument = useStore(
    documentFormModel.formProcessSaveReasonDocument
  );
  const progressCloseDocument = useStore(
    documentFormModel.formProcessSaveCloseDocument
  );
  const isDocumentFromComment = useStore($isDocumentFromComment);
  const isTotalComplite = useStore($totalComplited);
  const idResponsePostDocument = useStore($idResponsePostDocument);
  const idResponsePostDocumentDraft = useStore($idResponsePostDocumentDraft);
  const navigate = useNavigate();

  return (
    <UiDocumentFormProcessPostDocument
      isOpen={isOpens}
      ItemsProcess={
        <>
          <UiItemProcess
            {...progressDocument.document}
            onRepeat={() => documentFormModel.formEvent.postDocument()}
            title={'Сохранение документа'}
          />
          {idResponsePostDocument && progressDocument.documentSend && (
            <UiItemProcess
              {...progressDocument.documentSend}
              onRepeat={() => documentFormModel.formEvent.postDocumentSend()}
              title={'Отправка на согласование'}
            />
          )}
          {progressReasonDocument.map((item) => {
            return (
              <UiItemProcess
                key={item.file.name}
                {...item}
                onRepeat={() => {
                  documentFormModel.formEvent.postFiles(item.file.name);
                }}
                title={`Сохранение файла - ${item.file.name}`}
              />
            );
          })}
          {progressCloseDocument.map((item) => {
            return (
              <UiItemProcess
                key={item.file.name}
                {...item}
                onRepeat={() => {
                  documentFormModel.formEvent.postFilesClose(item.file.name);
                }}
                title={`Сохранение закрывающего документа - ${item.file.name}`}
              />
            );
          })}
          {isDocumentFromComment && progressDocument.comments && (
            <UiItemProcess
              {...progressDocument.comments}
              onRepeat={() => {}}
              title={'Сохранение комментария '}
            />
          )}
        </>
      }
      ButtonsBottom={
        <>
          <Button
            size='large'
            variant='contained'
            disabled={
              !isTotalComplite ||
              (!idResponsePostDocument && !idResponsePostDocumentDraft)
            }
            sx={{ flexBasis: ['50%', '48%'], borderRadius: [0, 1] }}
            onClick={() => {
              documentFormModel.resetFormModel();
              navigate(
                `/document-flow/${
                  idResponsePostDocument || idResponsePostDocumentDraft
                }`
              );
              close();
            }}
          >
            Перейти к документу
          </Button>
          <Button
            variant='contained'
            size='large'
            color='customGrey'
            sx={{ flexBasis: ['50%', '48%'], borderRadius: [0, 1] }}
            onClick={() => {
              if (isTotalComplite) {
                documentFormModel.resetFormModel();
                isTotalComplite && navigate('/document-flow/created-document');
              }
              close();
            }}
          >
            Закрыть
          </Button>
        </>
      }
    />
  );
};
