import { Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

import { GetTask } from 'shared/api/services/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { Link } from 'shared/ui/base-ui';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

import { getBoardLink } from 'entities/boards/libs/getBoardLink';
import { getTaskLink } from 'entities/task/libs/getTaskLink';
import { extractSourceTask } from './libs/extractSourceTask';

export const TASK_INFO_MAP: FieldMapType<GetTask>[] = [
  {
    field: 'originEntityTitle',
    title: 'Источник задачи',
    isShow: true,
    order: 0,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      const source = extractSourceTask(task);

      if (source?.link && source?.title)
        return (
          <Link to={source.link}>
            <Typography>{source?.title ? source.title : '-'}</Typography>
          </Link>
        );
      else return <Typography>{source?.title ? source.title : '-'}</Typography>;
    },
  },
  {
    field: 'executor',
    title: 'Исполнитель',
    isShow: true,
    order: 1,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      const executor = task.executor
        ? `${task.executor.name || ''} ${task.executor.position?.title || ''}`
        : '-';

      return <Typography>{executor}</Typography>;
    },
  },
  {
    field: 'creator',
    title: 'Постановщик',
    isShow: true,
    order: 2,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      const creator = task.creator
        ? `${task.creator.name || ''} ${task.creator.position?.title || ''}`
        : '-';

      return <Typography>{creator}</Typography>;
    },
  },
  {
    field: 'coExecutors',
    title: 'Соисполнитель',
    isShow: true,
    order: 3,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      return (
        <Typography>
          {task.coExecutors === undefined ||
          task.coExecutors === null ||
          task.coExecutors.length === 0
            ? '-'
            : task.coExecutors.map((person, i) => (
                <React.Fragment key={person.guid}>
                  {`${person.name} ${person.position?.title}`}{' '}
                  {i !== task.coExecutors.length - 1 && (
                    <Divider sx={{ my: 0.5 }} />
                  )}
                </React.Fragment>
              ))}
        </Typography>
      );
    },
  },
  {
    field: 'observers',
    title: 'Наблюдатели',
    isShow: true,
    order: 4,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      return (
        <Typography>
          {task.observers === undefined ||
          task.observers === null ||
          task.observers.length === 0
            ? '-'
            : task.observers.map((person, i) => (
                <React.Fragment key={person.guid}>
                  {`${person.name} ${person.position?.title}`}{' '}
                  {i !== task.observers.length - 1 && (
                    <Divider sx={{ my: 0.5 }} />
                  )}
                </React.Fragment>
              ))}
        </Typography>
      );
    },
  },
  {
    field: 'dateStart',
    title: 'Дата начала',
    isShow: true,
    order: 5,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      const date =
        task.dateStart && dayjs(task.dateStart).isValid()
          ? dayjs(task.dateStart)
          : null;

      if (date)
        return <Typography>{date.format(DATE_FORMATS.client)}</Typography>;
      else return <Typography>-</Typography>;
    },
  },
  {
    field: 'dateFinish',
    title: 'Дата завершения',
    isShow: true,
    order: 6,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      const date =
        task.dateFinish && dayjs(task.dateFinish).isValid()
          ? dayjs(task.dateFinish)
          : null;

      if (date)
        return <Typography>{date.format(DATE_FORMATS.client)}</Typography>;
      else return <Typography>-</Typography>;
    },
  },
  {
    field: 'dateFactFinish',
    title: 'Факт дата завершения',
    isShow: true,
    order: 7,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      const date =
        task.dateFactFinish && dayjs(task.dateFactFinish).isValid()
          ? dayjs(task.dateFactFinish)
          : null;

      if (date)
        return <Typography>{date.format(DATE_FORMATS.client)}</Typography>;
      else return <Typography>-</Typography>;
    },
  },
  {
    field: 'parentTitle',
    title: 'Родительская задача',
    isShow: true,
    order: 8,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      if (task.parentGuid && task.parentTitle)
        return (
          <Link to={getTaskLink(task.parentGuid)}>
            <Typography>{task.parentTitle}</Typography>
          </Link>
        );
      else return <Typography>-</Typography>;
    },
  },
  {
    field: 'units',
    title: 'Юниты',
    isShow: true,
    order: 9,
    getTableCellProps: () => ({}),
    renderComponent: (task) => <Typography>{task.units || '-'}</Typography>,
  },
  {
    field: 'boardTitle',
    title: 'Наименование доски',
    isShow: true,
    order: 10,
    getTableCellProps: () => ({}),
    renderComponent: (task) => {
      if (task.boardTitle && task.boardGuid && task.boardColumnTitle) {
        return (
          <Link to={getBoardLink(task.boardGuid)}>
            {task.boardTitle} - {task.boardColumnTitle}
          </Link>
        );
      } else {
        return <Typography>-</Typography>;
      }
    },
  },
  {
    field: 'isControl',
    title: 'Требуется проверка после завершения',
    isShow: true,
    order: 9,
    getTableCellProps: () => ({}),
    renderComponent: (task) => (
      <Typography>{task.isControl ? 'Да' : 'Нет'}</Typography>
    ),
  },
  // {
  //   field: 'status',
  //   title: 'Статус',
  //   isShow: true,
  //   order: 9,
  //   getTableCellProps: () => ({}),
  //   renderComponent: (task) => <Typography>{task.status.title}</Typography>,
  // },
];
