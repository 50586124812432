import { FormControlLabel, Grid, Switch } from '@mui/material';
import { FC } from 'react';

import { CustomAutocomplete, CustomTextField } from 'shared/ui/base-ui';

import { REMINDE_OPTIONS } from '../../constants';
import { RemindeFormRowProps } from './type';

export const RemindeFormRow: FC<RemindeFormRowProps> = ({
  lable,
  onChange,
  value,
}) => {
  return (
    <Grid
      container
      gap={1}
      flexDirection={['column', 'row']}
      flexWrap={'nowrap'}
    >
      <FormControlLabel
        sx={{ width: 170, ml: [1, 0] }}
        control={
          <Switch
            checked={value.isReminde}
            onChange={(e, val) =>
              onChange({
                ...value,
                isReminde: val,
              })
            }
          />
        }
        label={lable}
      />

      <CustomAutocomplete
        sx={{ flex: 1 }}
        disabled={!value.isReminde}
        textFieldProps={{
          label: 'Напоминание',
          required: value.isReminde,
        }}
        noOptionsText='Нет совпадений'
        options={REMINDE_OPTIONS}
        getOptionLabel={(option) => option.title}
        value={
          value.isCustom
            ? REMINDE_OPTIONS.at(-1)
            : value.ticks === null
              ? null
              : REMINDE_OPTIONS.find((el) => el.value === value.ticks)
        }
        onChange={(_, val) => {
          onChange({
            ticks: val?.value || null,
            isReminde: value.isReminde,
            isCustom: val?.id === 4 || false,
          });
        }}
      />

      {value.isCustom && (
        <CustomTextField
          required={value.isReminde}
          disabled={!value.isReminde}
          label='Напомнить за'
          placeholder='Введите кол-во дней'
          type='number'
          value={value.ticks || ''}
          containerSx={{ flex: 1 }}
          onChange={(e) => {
            if (!e.target.value) {
              onChange({
                ...value,
                ticks: null,
              });

              return;
            }

            const val = Number(e.target.value);

            onChange({
              ...value,
              ticks: val < 0 ? 0 : val,
            });
          }}
        />
      )}
    </Grid>
  );
};
