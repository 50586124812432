import { countNotUndef } from 'pages/tasks/libs/countNotUndef';
import { useMemo } from 'react';
import { URLToObjConverter } from 'shared/url-helper/type';
import { useURLState } from 'shared/url-helper/useURLState';

export const DEFAULT_PAGINATION = {
  Page: 1,
  Limit: 20,
};

export const useTaskURLState = <T extends { Page: number; Limit: number }>(
  convertFun: URLToObjConverter<T>
) => {
  const [filter, setFilter] = useURLState(convertFun, true);

  const mFilter = useMemo(() => {
    if (!filter || countNotUndef(filter) < 2) {
      return DEFAULT_PAGINATION as T;
    }

    return filter;
  }, [filter]);

  return [mFilter, setFilter] as const;
};
