import { ColumnFormCardFields } from 'features/boards/ColumnFormCard/type';
import { useBoardPage } from 'pages/boards/context/BoardPageContext';
import { useCallback } from 'react';
import { useUpdateColumnMutation } from 'shared/api/services/rtk/boardApi';
import { GetListBoardColumn } from 'shared/api/services/types';

export const useBoardColumnHeaderForBoardPage = (
  column: GetListBoardColumn
) => {
  const { boardGuid } = useBoardPage();
  const [updateColumn, { isLoading }] = useUpdateColumnMutation();

  const handleUpdate = useCallback(async (form: ColumnFormCardFields) => {
    await updateColumn({
      boardGuid,
      boardColumnGuid: column.guid,
      args: { title: form.title, color: form.color },
    });
  }, []);

  return { handleUpdate, isLoading };
};
