import { Chip, Grid, TextField } from '@mui/material';
import { FC } from 'react';

import { PropsAutocompliteDocumentForm } from 'shared/types/type';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

import { CombineArticle } from '../types';

export const MultiArticlesAutocomplite: FC<
  PropsAutocompliteDocumentForm<CombineArticle[]>
> = ({ value, onChange, options, disabled }) => {
  const [isMobile] = useDevice();

  return (
    <Grid container>
      <CustomAutoCompliteAdaptive
        propsAutocomplite={{
          sx: {
            width: '100%',
          },
          disabled,
          multiple: true,
          value: value || [],
          options: options,
          isOptionEqualToValue: (option, value) => {
            return Boolean(option && value && option.id === value.id);
          },
          getOptionLabel: (option) => option.title || '-',
          onChange: onChange,
          groupBy: (option) => String(option.typeTitle),
          disableCloseOnSelect: true,
          renderTags: (value, getTagProps) => {
            return value.map((chip, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                {...getTagProps({ index })}
                sx={{
                  borderRadius: '8px',
                }}
                onDelete={() => {
                  onChange(
                    {},
                    value.filter((item) => item.id !== chip.id)
                  );
                }}
                label={chip.title}
              />
            ));
          },
          renderInput: (params) => (
            <TextField
              {...params}
              placeholder={value && value.length > 0 ? '' : 'Выберите статью'}
              label={isMobile ? '' : 'Статья ДДС'}
              required={value && value.length > 0 ? false : true}
              size='small'
              margin={'normal'}
            />
          ),
          renderOption: (props, option) => (
            <li
              {...props}
              key={option.id}
            >
              {option.title}
            </li>
          ),
        }}
        sx={{ flexBasis: ['100%', '45%'] }}
        label='Статья ДДС*'
      />
    </Grid>
  );
};
