import { useGetCompletedProcessesQuery } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { renderActionsCompletedAdaptations } from 'widgets/adaptation/CurrentAdaptationProcessesTable/render-actions/renderActionsCompletedAdaptations';
import { AdaptationProcessesPage } from './AdaptationProcessesPage';

export const CompletedAdaptationProcessesPage = () => {
  return (
    <AdaptationProcessesPage
      useGetProcesses={useGetCompletedProcessesQuery}
      renderActions={renderActionsCompletedAdaptations}
    />
  );
};
