import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { array, mixed, object, ObjectSchema } from 'yup';
import {
  EmployeeOption,
  EmployeePosition,
  FormPreparedStagesValue,
} from './types';

const SCHEME_EMPLOYEE_WITH_POSITION = object()
  .shape({
    employee: mixed<EmployeeOption>().required(
      'Поле обязательное для заполнения'
    ),
    requeredPosition: mixed<EmployeePosition>().required(),
  })
  .required('Поле обязательное для заполнения');

export const SHEME_VALID_PREPARED_TASKS: ObjectSchema<FormPreparedStagesValue> =
  object().shape({
    stages: array(
      object()
        .shape({
          stageNumber: SCHEMA.NUMBER.required(),
          tasks: array(
            object().shape({
              stageNumber: SCHEMA.NUMBER.required(),
              stageTaskGuid: SCHEMA.STRING_REQUIRED,
              stageTaskTitle: SCHEMA.STRING_REQUIRED,

              creator: SCHEME_EMPLOYEE_WITH_POSITION,
              executor: SCHEME_EMPLOYEE_WITH_POSITION,
              coExecutors: array(SCHEME_EMPLOYEE_WITH_POSITION).required(),
              observers: array(SCHEME_EMPLOYEE_WITH_POSITION).required(),
            })
          ).required(),
        })
        .required()
    ).required(),
  });
