import { CustomTextField } from 'shared/ui/custom-text-field';

import { documentFormEdit } from '../model';

export const DocumentFormEditCounterpartyContractLink = () => {
  const counterpartyContractLinkValue =
    documentFormEdit.formSelects.useSelectValueOfKey(
      'counterpartyContractLink'
    );
  const isCounterpartyRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isCounterpartyRequired'
    );
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditCounterparty'
  );

  return (
    <CustomTextField
      propsTextField={{
        disabled: !isCounterpartyRequired || !isCanEdit,
        placeholder: 'Введите ссылку на договор',
        onChange: (event) => {
          documentFormEdit.api.setFiled({
            key: 'counterpartyContractLink',
            value: event.target.value,
          });
        },
        value: counterpartyContractLinkValue || '',
      }}
      sx={{
        flexBasis: ['100%', '45%'],
      }}
      label='Ссылка на договор'
    />
  );
};
