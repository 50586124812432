import { countNotUndef } from 'pages/tasks/libs/countNotUndef';
import { useCallback, useEffect } from 'react';
import { GetTasksMeExecutorQueryParams } from 'shared/api/services/types';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { TaskFilterModal } from '../modal-filter-my-tasks';
import { TaskFilterFormFields } from '../task-filter-form';
import { useConvertTaskFilter } from './useConvertTaskFilter';

export const INIT_TASK_PAGINATION = { Limit: 20, Page: 1 };

export const useTaskFilterModal = (
  taskListVariant: 'forMyTasks' | 'forControlTasks',
  filter: GetTasksMeExecutorQueryParams | undefined,
  setFilter: (filter: GetTasksMeExecutorQueryParams | undefined) => void
) => {
  const { close, isOpen, open, initValue } =
    useViewModalWithInitFields<TaskFilterFormFields>();

  const {
    convertFilterToForm,
    convertTaskFilterFormDataToFetch,
    getDefaultForm,
  } = useConvertTaskFilter();

  const onSubmit = useCallback((data: TaskFilterFormFields) => {
    setFilter({
      ...INIT_TASK_PAGINATION,
      ...filter,
      ...convertTaskFilterFormDataToFetch(data),
    });

    close();
  }, []);

  useEffect(() => {
    if (countNotUndef(filter) < 3) {
      // TODO: ПУПУПУ. Без setTimeout не работает(
      setTimeout(() => {
        setFilter({
          ...convertTaskFilterFormDataToFetch(getDefaultForm()),
          ...INIT_TASK_PAGINATION,
        });
      });
    }
  }, []);

  const openHandler = () => open(convertFilterToForm(filter));

  return {
    taskFilterModal: (
      <TaskFilterModal
        isOpen={isOpen}
        onClose={close}
        onSubmit={onSubmit}
        defaultValue={initValue}
        taskListVariant={taskListVariant}
      />
    ),
    open: openHandler,
  };
};
