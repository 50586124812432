// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Close, Send } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { useStore } from 'effector-react';
import { FC, useEffect, useState } from 'react';

import { DocumentCommentResponse } from 'shared/api/services-document-flow/types';
import { theme } from 'shared/theme/theme';
import { DetailsUi } from 'shared/ui/details-ui';
import { useDevice } from 'shared/utils/hook-type-device';

import localizedFormat from 'dayjs/plugin/localizedFormat';
import { documentIdComment } from './model';

export const DocumentIdComments = () => {
  const comments = useStore(documentIdComment.comments);

  dayjs.extend(localizedFormat);

  const [ref, setRef] = useState<null | HTMLElement>(null);

  useEffect(() => {
    return () => {
      documentIdComment.reset();
    };
  }, []);

  return (
    <DetailsUi
      textSummary={'Комментарии'}
      defaultOpen={true}
    >
      <>
        <Typography
          fontWeight='bold'
          textAlign={'center'}
          sx={{
            marginTop: [0, 0],
            marginBottom: [2, 0],
            display: ['flex', 'none'],
            justifyContent: 'center',
            padding: ['0px 16px'],
          }}
        >
          Комментарии
        </Typography>
        <Stack
          /* spacing={2} */
          marginBottom={[1, 2]}
          /* padding={[2, 0]} */
          paddingLeft={[2, 0]}
          paddingRight={[2, 0]}
          paddingTop={1}
          divider={
            <Divider
              sx={{
                borderColor: theme.palette.customGrey.dark,
                marginBottom: [1, 2],
              }}
            />
          }
        >
          {comments.map((item) => {
            const { id, parentId, comment, dateCreated, canDelete, creator } =
              item;
            const dateDayJs = dayjs(dateCreated as string)
              .locale('ru')
              .format('llll')
              .replace('г.,', '');
            const date = dateDayJs[0].toUpperCase() + dateDayJs.slice(1);

            return (
              <Box
                key={id}
                display={'flex'}
                flexDirection={'column'}
              >
                {/* @ts-ignore */}
                <a name={id}></a>
                {parentId && (
                  <CommentReply
                    commentReply={
                      comments.find((item) => item.id === parentId) || null
                    }
                  />
                )}
                <Typography
                  color={theme.palette.customGrey.dark}
                  fontSize={12}
                  /* marginBottom={"4px"} */
                >
                  {date}
                </Typography>
                <Typography fontWeight={700}>{creator.employeeName}</Typography>
                <Typography>{comment}</Typography>
                <Grid
                  container
                  justifyContent={'flex-end'}
                >
                  <Button
                    size='small'
                    onClick={() => {
                      if (ref) {
                        ref.focus();
                        ref.scrollIntoView({ behavior: 'smooth' });
                      }
                      documentIdComment.changeCommentReply(item);
                    }}
                    sx={{
                      textTransform: 'none',
                      color: theme.palette.customGrey.dark,
                      fontWeight: 700,
                    }}
                  >
                    Ответить
                  </Button>
                  <Button
                    disabled={!canDelete}
                    size='small'
                    sx={{
                      textTransform: 'none',
                      color: theme.palette.customGrey.dark,
                      fontWeight: 700,
                    }}
                    onClick={() =>
                      documentIdComment.deleteComment(id as number)
                    }
                  >
                    Удалить
                  </Button>
                </Grid>
              </Box>
            );
          })}
          {comments.length > 0 && <Box />}
        </Stack>
        <CommentsForm setRef={setRef} />
      </>
    </DetailsUi>
  );
};

const CommentsForm: FC<{
  setRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}> = ({ setRef }) => {
  const [isMobile] = useDevice();
  const commentReply = useStore(documentIdComment.commentReply);
  const comment = useStore(documentIdComment.input);

  return (
    <FormControl
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '& > div': {
          flexBasis: ['85%', '91%'],
          maxWidth: ['85%', '90%'],
          '& textarea': {
            boxSizing: 'border-box',
            borderRadius: ['5px', '10px'],
            padding: ['4px', 1],
            fontSize: [16, 16],
            border: ['none', `solid 1px ${theme.palette.customGrey.dark}`],
          },
        },
        paddingRight: [1, 0],
        paddingLeft: [1, 0],
        position: 'relative',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        ref={setRef}
      >
        <CommentReply
          commentReply={commentReply}
          close={() => documentIdComment.changeCommentReply(null)}
        />
        <TextareaAutosize
          tabIndex={99}
          /*   disabled={!canEditModify} */
          className={'TextareaAutosize_comment'}
          style={{ width: '100%', minHeight: isMobile ? 18 : 24 }}
          placeholder='Введите комментарий'
          aria-label='empty textarea'
          value={comment}
          onChange={(e) => documentIdComment.changeInput(e.target.value)}
        />
      </Box>
      <Button
        disabled={comment.length === 0 /* || !canEditModify */}
        variant='contained'
        color='secondary'
        size='small'
        sx={{
          position: ['absolute', 'relative'],
          transform: ['translate(0,-50%)', 'none'],
          textTransform: 'none',
          fontWeight: 'bold',
          minWidth: ['30px', '91px'],
          maxWidth: ['30px', '91px'],
          maxHeight: ['30px', '40px'],
          minHeight: ['30px', '40px'],
          borderRadius: ['5px', '10px'],
          top: ['50%', 0],
          fontSize: [35, 14],
          right: [16, 0],
          '& > svg': {
            display: ['block', 'none'],
          },
          '& > p': {
            display: ['none', 'block'],
          },
        }}
        onClick={documentIdComment.sendComment}
      >
        <Send />
        <Typography
          fontWeight={700}
          fontSize={14}
        >
          Отправить
        </Typography>
      </Button>
    </FormControl>
  );
};

const CommentReply: FC<{
  commentReply: DocumentCommentResponse | null;
  close?: (a: any) => any | undefined;
  onScrollToOrign?: (a: any) => any | undefined;
}> = ({ commentReply, close }) => {
  if (!commentReply) return <></>;

  const { id, comment, dateCreated, creator } = commentReply;
  const dateDayJs = dayjs(dateCreated as string)
    .locale('ru')
    .format('llll')
    .replace('г.,', '');
  const date = dateDayJs[0].toUpperCase() + dateDayJs.slice(1);

  return (
    <Grid
      container
      justifyContent={'space-between'}
      sx={{
        backgroundColor: close ? theme.palette.customGrey.main : 'white',
        borderLeft: `solid 6px ${theme.palette.secondary.main}`,
        borderRadius: '0px 10px 10px 0px',
        paddingLeft: 1,
        marginBottom: 1,
        color: theme.palette.customGrey.dark,
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        flexBasis={['85%', '90%']}
        position={'relative'}
        sx={{
          '& > a': {
            position: 'absolute',
            height: '100%',
            width: '100%',
          },
        }}
      >
        <Typography
          color={theme.palette.customGrey.dark}
          fontSize={12}

          /* marginBottom={"4px"} */
        >
          {date}
        </Typography>
        <Typography fontWeight={700}>{creator.employeeName}</Typography>
        <Typography
          sx={{
            maxWidth: ['80%'],
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {comment}
        </Typography>
        <a href={`#${id}`}></a>
      </Box>
      {close && (
        <IconButton onClick={close}>
          <Close sx={{ fontSize: [16, 20] }} />
        </IconButton>
      )}
    </Grid>
  );
};
