import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useGetTrainingProcessEmployesQuery } from 'shared/api/traning/rtk/trainingProviderApi';

import { theme } from 'shared/theme/theme';
import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTableRow,
} from 'shared/ui/table';

import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';
import { CURRENT_TRAINING_FIELD_MAP_SUBTABLE } from './constants';
import { TableRowWithSubTableProps } from './types';

export const TableRowWithSubTable: FC<TableRowWithSubTableProps> = ({
  training,
  visibleFields,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading, isFetching } = useGetTrainingProcessEmployesQuery(
    training.guid as string,
    { skip: !isOpen }
  );
  const isShowTable = (data && data?.length > 0) || isLoading || isFetching;

  return (
    <>
      <UiTableRow key={training.guid}>
        <UiTableCell
          key={'1'}
          sx={{
            borderBottom: 'unset',
            borderTop: 'solid 1px',
            borderColor: theme.palette.customGrey.main,
          }}
        >
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </UiTableCell>
        {visibleFields.map((el, index) => (
          <UiTableCell
            sx={{
              borderBottom: 'unset',
              borderTop: 'solid 1px',
              borderColor: theme.palette.customGrey.main,
            }}
            key={el.field + training.guid}
            {...el.getTableCellProps()}
          >
            {el.renderComponent(training, index) || 'Error'}
          </UiTableCell>
        ))}
      </UiTableRow>
      <UiTableRow>
        <UiTableCell colSpan={visibleFields.length + 2}>
          <Collapse
            in={isOpen}
            timeout={'auto'}
            unmountOnExit
          >
            {isShowTable && (
              <UiTable
                isUpdating={isFetching}
                isLoading={isLoading}
                skeleton={<UiTableSkeleton limit={2} />}
                header={
                  <UiTableHeader
                    showSettings={false}
                    viewCells={CURRENT_TRAINING_FIELD_MAP_SUBTABLE}
                    allCells={CURRENT_TRAINING_FIELD_MAP_SUBTABLE}
                  />
                }
                body={data?.map((employe, i) => (
                  <UiTableRow key={employe.guid}>
                    {CURRENT_TRAINING_FIELD_MAP_SUBTABLE.map((el) => (
                      <UiTableCell
                        key={el.field + training.guid}
                        {...el.getTableCellProps()}
                      >
                        {el.renderComponent({ ...employe, number: i + 1 }) ||
                          'Error'}
                      </UiTableCell>
                    ))}
                  </UiTableRow>
                ))}
              />
            )}
            {!isLoading && !isFetching && data && data.length === 0 && (
              <Typography textAlign={'center'}>Нет данных</Typography>
            )}
          </Collapse>
        </UiTableCell>
      </UiTableRow>
    </>
  );
};
