import { LoadingButton } from '@mui/lab';
import { Button, Grid, Paper, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { OnlineStoreRequestsFirebaseCreateMessage } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';

import { TextFieldWithController } from 'shared/fields-with-contoller';
import { ContainerActionsForm } from 'shared/ui/base-ui';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { TestNotificationFromProps } from './types';

export const TestNotificationFrom: FC<TestNotificationFromProps> = ({
  isOpen,
  onClose,
  isLoading,
  initialForm,
  onSubmit,
  messageRespons,
}) => {
  const theme = useTheme();
  const { control, handleSubmit, reset } =
    useForm<OnlineStoreRequestsFirebaseCreateMessage>({
      defaultValues: { ...initialForm, phone: '' },
    });

  useEffect(() => {
    if (initialForm) {
      reset(initialForm);
    }
  }, [initialForm]);

  return (
    <DialogForForm
      open={isOpen}
      disabledConteiner
    >
      <Grid
        gap={[2, 4]}
        display={'flex'}
        flexDirection={'column'}
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitleForForm title='Отправить тестовое уведомление' />
        <TextFieldWithController
          control={control}
          name='phone'
          label='Телефон'
        />
        {messageRespons && (
          <Grid
            display={'flex'}
            flexDirection={'column'}
            gap={2}
          >
            {messageRespons.map((message, index) => {
              const keys = Object.keys(message) as (keyof typeof message)[];

              return (
                <Grid
                  key={index}
                  component={Paper}
                  padding={2}
                  sx={{
                    color: message.error
                      ? theme.palette.error.main
                      : theme.palette.primary.main,
                  }}
                >
                  {keys.map((key) => {
                    return (
                      <Typography
                        color={'inherit'}
                        key={key}
                      >{`${key} : ${message[key]}`}</Typography>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        )}
        <ContainerActionsForm>
          <LoadingButton
            fullWidth
            loading={isLoading}
            variant='contained'
            type='submit'
          >
            Отправить
          </LoadingButton>
          <Button
            fullWidth
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
