import { FC } from 'react';

import { UiTableCard, UiTableCardList } from 'shared/ui/table';

import { ProtocolOpeningRunningTasksTableProps } from './types';

export const ProtocolOpeningRunningTasksList: FC<
  ProtocolOpeningRunningTasksTableProps
> = ({
  isUpdate,
  isLoading = false,
  onClick,
  fields,
  onChangeFields,
  visibleFields,
  data,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading || isUpdate}
    >
      {data.map((task) => (
        <UiTableCard
          data={task}
          id={task.guid}
          visibleFields={visibleFields}
          key={task.guid}
          onClick={() => {
            onClick && onClick(task);
          }}
          renderActions={() => <></>}
        />
      ))}
    </UiTableCardList>
  );
};
