export const TelergamSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='24'
      fill='none'
      viewBox='0 0 26 24'
    >
      <path
        fill='#45ADE2'
        d='M20.787 23.537c-.154 0-.257-.05-.411-.153l-5.75-4.402c-.153-.102-.358-.204-.564-.204-.205 0-.41.102-.616.204l-3.182 2.713a.584.584 0 01-.411.153c-.308 0-.565-.204-.667-.511l-2.208-7.473-5.698-2.252c-.359-.153-.513-.563-.359-.972a.62.62 0 01.36-.359L24.687.761a.464.464 0 01.257-.05c.205 0 .359.102.513.255.154.154.205.41.154.614l-4.158 21.394a.66.66 0 01-.667.563zm-.668-18.016c-.051 0-.051 0-.102.052l-11.14 7.216a1.33 1.33 0 00-.513 1.331l1.18 4.453c.052.153.155.204.258.204.154 0 .256-.102.308-.255l.308-2.457c.05-.46.256-.87.616-1.177l9.188-9.008c.051-.051.103-.154.051-.205 0-.051-.051-.102-.154-.154z'
      ></path>
    </svg>
  );
};
