import { useUserDataFetch } from 'entities/user/useUserDataFetch';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  useGetTrainingForAllowedSignUpUserQuery,
  useGetTrainingRetailsTreeQuery,
  usePostSignUpTrainingMutation,
} from 'shared/api/traning/rtk/trainingProviderApi';

import { ContainerActionsForm, CustomAutocomplete } from 'shared/ui/base-ui';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import { DEFAULT_VALUE_FORM } from './constants';

import {
  getOptionLabelDateTraining,
  getOptionLabelThemeTraining,
  getOptionLabelTraining,
  isOptionEqualToValueThemeTraining,
  isOptionEqualToValueTraining,
  renderOptionDateTraining,
  renderOptionThemeTraining,
  renderOptionTraining,
} from './libs';

import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';

import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import {
  getOptionLabelRetail,
  isOptionEqualToValueRetail,
  renderOptionRetail,
  treeToArray,
} from '../libs';
import {
  DialogFormSignUpForTrainingProps,
  FormSignUpForTrainingProps,
} from './types';

export const FormSignUpForTraining: FC<DialogFormSignUpForTrainingProps> = ({
  isOpen,
  onClose,
}) => {
  const { user } = useUserDataFetch();
  const { open: openSnackbar } = useSnackbar();

  const { data: retailsTree, isFetching: isFetchingRetailsTree } =
    useGetTrainingRetailsTreeQuery(undefined, { skip: !isOpen });

  const retailsOptions = useMemo(() => {
    if (retailsTree) {
      return treeToArray(retailsTree).filter((item) => item.guid);
    }

    return [];
  }, [retailsTree]);

  const { control, setValue, handleSubmit } =
    useForm<FormSignUpForTrainingProps>({
      defaultValues: DEFAULT_VALUE_FORM,
    });
  const retailsValue = useWatch({ control, name: 'retails' });
  const themeTrainerValue = useWatch({ control, name: 'themeTreining' });
  const dateValue = useWatch({ control, name: 'date' });
  const trainingValue = useWatch({ control, name: 'training' });

  const IsSkipFetchingAllowedTraining =
    !user?.employeeGuid || !isOpen || !retailsValue?.guid;

  const { data: allowedTraining, isFetching: isFetchingAllowedTraining } =
    useGetTrainingForAllowedSignUpUserQuery(retailsValue?.guid || '', {
      skip: IsSkipFetchingAllowedTraining,
    });

  useEffect(() => {
    setValue('themeTreining', null);
  }, [retailsValue]);

  useEffect(() => {
    setValue('date', null);
  }, [themeTrainerValue]);
  useEffect(() => {
    setValue('training', null);
  }, [dateValue]);

  const disabledThemeTreinerInput = !retailsValue;
  const disabledDateInput = !themeTrainerValue || isFetchingRetailsTree;
  const disabledTrainingInput =
    !dateValue || disabledDateInput || isFetchingRetailsTree;

  const [postSignUpTraining, { isLoading }] = usePostSignUpTrainingMutation();

  return (
    <DialogForForm
      onClose={onClose}
      open={isOpen}
      head={<DialogTitleForForm title={'Записаться на тренинг'} />}
    >
      <Grid
        container
        component='form'
        flexDirection={'column'}
        onSubmit={handleSubmit((form) => {
          if (form.training && user?.employeeGuid) {
            postSignUpTraining({
              type: 0,
              employeeGuid: user.employeeGuid,
              trainingEventGuid: form.training.guid,
            }).then((response) => {
              if ('error' in response) {
                openSnackbar({
                  type: 'error',
                  title: 'Упс :(',
                  text: 'Что то пошло не так',
                  duration: 3000,
                });
              } else {
                openSnackbar({
                  type: 'success',
                  title: 'Отлично!',
                  text: `Вы записались на тренинг :${form.training?.trainerName}`,
                  duration: 3000,
                });
                onClose();
              }
            });
          }
        })}
        gap={2}
      >
        <Controller
          control={control}
          name='retails'
          render={({ field: { value, onChange } }) => {
            return (
              <CustomAutocomplete
                options={retailsOptions || []}
                value={value}
                onChange={(_, value) => onChange(value)}
                getOptionLabel={getOptionLabelRetail}
                renderOption={renderOptionRetail}
                isOptionEqualToValue={isOptionEqualToValueRetail}
                loading={isFetchingRetailsTree}
                textFieldProps={{ label: 'Сеть' }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name='themeTreining'
          render={({ field: { value, onChange } }) => {
            return (
              <CustomAutocomplete
                options={allowedTraining || []}
                value={value}
                disabled={disabledThemeTreinerInput}
                onChange={(_, value) => onChange(value)}
                getOptionLabel={getOptionLabelThemeTraining}
                renderOption={renderOptionThemeTraining}
                isOptionEqualToValue={isOptionEqualToValueThemeTraining}
                loading={isFetchingAllowedTraining}
                textFieldProps={{ label: 'Тема тренинга' }}
              />
            );
          }}
        />

        <Controller
          control={control}
          name='date'
          render={({ field: { value, onChange } }) => {
            return (
              <CustomAutocomplete
                value={value}
                onChange={(_, value) => onChange(value)}
                options={
                  themeTrainerValue && themeTrainerValue.events
                    ? themeTrainerValue.events?.reduce<string[]>(
                        (uniqueDates, item) => {
                          if (!uniqueDates.includes(item.date)) {
                            return [...uniqueDates, item.date];
                          }

                          return uniqueDates;
                        },
                        []
                      )
                    : []
                }
                disabled={disabledDateInput}
                getOptionLabel={getOptionLabelDateTraining}
                filterOptions={(options) =>
                  options.filter((date) => {
                    return (
                      themeTrainerValue &&
                      themeTrainerValue.events &&
                      themeTrainerValue.events.some(
                        (event) => event.date === date
                      )
                    );
                  })
                }
                renderOption={renderOptionDateTraining}
                textFieldProps={{ label: 'Дата тренинга' }}
                loading={isFetchingAllowedTraining}
              />
            );
          }}
        />

        <Controller
          control={control}
          name='training'
          render={({ field: { value, onChange } }) => {
            return (
              <CustomAutocomplete
                value={value}
                onChange={(_, value) => onChange(value)}
                options={themeTrainerValue?.events || []}
                getOptionLabel={getOptionLabelTraining}
                filterOptions={(events) => {
                  return events.filter((item) => item.date === dateValue);
                }}
                isOptionEqualToValue={isOptionEqualToValueTraining}
                disabled={disabledTrainingInput}
                renderOption={renderOptionTraining}
                textFieldProps={{ label: 'Тренер' }}
                loading={isFetchingAllowedTraining}
              />
            );
          }}
        />
        <ContainerActionsForm>
          <LoadingButton
            variant='contained'
            size='large'
            type='submit'
            loading={isLoading}
            disabled={!trainingValue}
          >
            Записаться
          </LoadingButton>
          <Button
            color='customGrey'
            variant='contained'
            size='large'
            onClick={onClose}
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
