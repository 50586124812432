import { Grid, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';

import { RETAIL_OPENING_PROCESS_HELPER_LINKS } from 'entities/retail-opening-process/links';
import { Link } from 'shared/ui/base-ui';
import { ProcessesLinksProps } from './type';

export const ProcessesLinks: FC<ProcessesLinksProps> = ({
  openingProcesses,
  protocolGuid,
  isLoading,
}) => {
  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={1}
    >
      <Typography
        variant='h6'
        fontWeight={'bold'}
      >
        Процессы
      </Typography>
      {!openingProcesses && !isLoading && (
        <Typography>Нет запущенных процессов</Typography>
      )}
      {isLoading &&
        !openingProcesses &&
        [1, 2, 3].map((item) => {
          return (
            <Skeleton
              key={item}
              width={'100%'}
              variant='rectangular'
              height={'1.4em'}
            />
          );
        })}
      {openingProcesses &&
        openingProcesses.map((process) => {
          return (
            <Link
              key={process.guid}
              to={RETAIL_OPENING_PROCESS_HELPER_LINKS.createLinkRunningProcess({
                processGuid: process.guid,
                openingTemplateGuid: process.openingTemplate.guid,
                protocolGuid,
              })}
            >{`Процесс по шаблону : ${process.openingTemplate.title}`}</Link>
          );
        })}
    </Grid>
  );
};
