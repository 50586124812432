import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import { MouseEventHandler, useCallback, useMemo } from 'react';

import { theme } from 'shared/theme/theme';
import { useDevice } from 'shared/utils/hook-type-device';

import { DetailsUiComponent } from './types';

export const DetailsUi: DetailsUiComponent = ({
  textSummary,
  children,
  onMousOut,
  defaultOpen,
  isDetailsVariant = true,
}) => {
  const [isMobile] = useDevice();
  const preventDefault: MouseEventHandler<HTMLDetailsElement> = useCallback(
    (e) => {
      !isDetailsVariant && e.preventDefault();
    },
    [isDetailsVariant]
  );

  const isOpen = useMemo(() => {
    if (defaultOpen || isMobile || !isDetailsVariant) return true;

    return false;
  }, [defaultOpen, isMobile, isDetailsVariant]);

  return (
    <Box
      onMouseOut={onMousOut}
      component={'details'}
      display='flex'
      flexDirection='column'
      onClick={preventDefault}
      open={isOpen}
      sx={{
        listStyle: 'none',
        '& > summary': {
          listStyleType: 'none',
        },
        '& > summary::-webkit-details-marker': {
          display: 'none',
        },
        '&[open] > summary > svg': {
          transform: 'rotate(180deg)',
        },
        cursor: 'pointer',
        flexGrow: 1,
        mb: [0, 2],
      }}
    >
      <Box
        component={'summary'}
        sx={{
          display: ['none', 'flex'],
          alignItems: 'center',
          justifyContent: ['flex-start', 'center'],
          backgroundColor: theme.palette.customGrey.main,
          fontSize: [16],
          fontWeight: [400, 700],
          borderRadius: [0, '10px'],
        }}
        mb={[1, 2]}
        height={[40]}
      >
        <Typography fontWeight={'inherit'}>{textSummary}</Typography>
        {isDetailsVariant && <ExpandMoreIcon />}
      </Box>
      {children}
    </Box>
  );
};
