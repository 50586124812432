import dayjs from 'dayjs';
import { StatusTask } from 'features/task/task-filter-form';
import { useMemo } from 'react';
import {
  useGetAllEmployeesQuery,
  useGetOriginsQuery,
  useGetStatusesQuery,
} from 'shared/api/services/rtk/optionApi';
import { GetBoardsTasksBoardGuidQueryParams } from 'shared/api/services/types';
import { getObjByGuids } from 'shared/tarnsform-helper/getObjByGuids';
import { getObjByIds } from 'shared/tarnsform-helper/getObjByIds';

export const useGetDataForBoardFilter = (
  data?: GetBoardsTasksBoardGuidQueryParams
) => {
  const { data: statuses } = useGetStatusesQuery();
  const { data: employees } = useGetAllEmployeesQuery();
  const { data: origins } = useGetOriginsQuery();

  const initValue = useMemo(() => {
    if (!data) return undefined;
    if (!statuses || !employees || !origin) return undefined;

    return {
      coExecutors: getObjByGuids(employees, data.CoExecutorsGuids) || [],
      creators: getObjByGuids(employees, data.CreatorsGuids) || [],
      executors: getObjByGuids(employees, data.ExecutorsGuids) || [],
      finishDate: data.FinishDate ? dayjs(data.FinishDate) : null,
      startDate: data.StartDate ? dayjs(data.StartDate) : null,
      isTarget: data.IsTarget || null,
      origins: getObjByIds(origins, data.OriginsIds) || [],
      statuses:
        statuses.map(
          (status) =>
            ({
              ...status,
              selected: data.StatusIds?.some((el) => el === status.id) || false,
            }) as StatusTask
        ) || [],
    };
  }, [statuses, employees, origins, data]);

  return initValue;
};
