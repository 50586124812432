import { useStoreMap } from 'effector-react';

import { CustomTextField } from 'shared/ui/custom-text-field';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormTotalSum = () => {
  const value = useStoreMap(documentFormModel.secondValid, (secondValid) =>
    secondValid ? secondValid.totalSum || '' : ''
  );

  return (
    <CustomTextField
      propsTextField={{
        value: value,
        disabled: true,
        required: true,
        margin: 'none',
        /* placeholder: "Общая сумма", */
      }}
      sx={{
        flexBasis: ['100%', '45%'],
      }}
      label='Общая сумма'
    />
  );
};
