import { Dialog, Grid } from '@mui/material';
import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { DialogForFormProps } from './type';

/**
 * @description Обёртка диалогового окна для форм
 */
export const DialogForForm: FC<DialogForFormProps> = ({
  children,
  head,
  disabledConteiner,
  ...props
}) => {
  const [isMobile] = useDevice();

  return (
    <Dialog
      PaperProps={{ sx: { maxWidth: '600px', p: [0, 3], width: '100%' } }}
      fullScreen={isMobile}
      {...props}
    >
      <Grid
        container={disabledConteiner ? false : true}
        flexDirection={'column'}
        gap={2}
      >
        {head}
        {children}
      </Grid>
    </Dialog>
  );
};
