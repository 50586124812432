import { VisitingCreateRequest } from 'shared/api/visit/types';

import { CreateVisitFormFields } from '../type';

export const convertCreateVisitFormToFetch = (
  form: CreateVisitFormFields
): VisitingCreateRequest => {
  return {
    checklistId: form.checklistId,
    visitingDate: form.date,
    homiesIds: form.homies,
    retailIds: form.retailsIds,
  };
};
