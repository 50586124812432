import dayjs from 'dayjs';
import { BannersResponsesSlidersSliderModel } from 'shared/api/service-apteka-legko-banners/aptekaLegoBannersAutogenerateApi';
import { SliderViewDataOnMonth } from '../types';

export const normolizeGetAptekaLegkoBannersSlider = (
  response: BannersResponsesSlidersSliderModel[]
): SliderViewDataOnMonth[] => {
  const months: string[] = [];

  const responseNormolizeViewItems = response.map(
    ({ imageSite, imageMobile, sliderGuid, periodEnd, periodStart }) => {
      return {
        imageDesktopSrc: imageSite,
        imageMobileSrc: imageMobile,
        guid: sliderGuid,
        dateStart: periodStart,
        dateFinish: periodEnd,
      };
    }
  );

  responseNormolizeViewItems.forEach((slider) => {
    if (
      !months.some((month) => {
        return dayjs(slider.dateStart).isSame(month, 'month');
      })
    ) {
      months.push(slider.dateStart);
    }
    if (
      !months.some((month) => {
        return dayjs(slider.dateFinish).isSame(month, 'month');
      })
    ) {
      months.push(slider.dateFinish);
    }
  });

  return months
    .sort((a, b) => {
      return dayjs(a).isBefore(b) ? -1 : 1;
    })
    .map((month) => {
      return {
        date: month,
        viewDate: `${
          dayjs(month).format('MMMM YYYY')[0].toUpperCase() +
          dayjs(month).format('MMMM YYYY').slice(1)
        }`,
        sliders: responseNormolizeViewItems.filter((slider) => {
          return dayjs(month).isBetween(
            slider.dateStart,
            slider.dateFinish,
            'month',
            '[]'
          );
        }),
      };
    });
};
