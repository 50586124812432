import { EmptyWatchTaskMessage } from 'features/task/empty-watch-task-message/EmptyWatchTaskMessage';
import { useChangeTaskStatusModal } from 'features/task/hooks/useChangeTaskStatusModal';
import { useConvertWatchTaskFilter } from 'features/task/hooks/useConvertWatchTaskFilter';
import { TaskDrawer, useTaskDrawer } from 'features/task/task-drawer';
import { useCallback, useMemo } from 'react';

import { GetListTask } from 'shared/api/services/types';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { useDevice } from 'shared/utils/hook-type-device';

import { TaskTableActions } from 'widgets/tasks/task-table/actions/TaskTableActions';

import { AddTaskToBoardDialog } from 'features/boards/dialogs/AddTaskToBoardDialog';
import { AddTaskToBoardFormFields } from 'features/boards/forms/AddTaskToBoardForm';
import { useDeleteTask } from 'features/task/hooks/useDeleteTask';
import { useModalFilterWatchingTasks } from 'features/task/hooks/useModalFilterWatchingTasks';
import {
  MemoizedManagerBarTasks,
  MemoizedTaskTable,
} from 'features/task/memoComponents';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useMoveTaskToAnotherBoardMutation } from 'shared/api/services/rtk/boardApi';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { Tables } from 'shared/utils/controllers';
import { useWatchTask } from './contexts/watch-task-context';
import { taskFilterToOrder } from './libs/orderConvFun';

export const WatchingTasks = () => {
  const { isLoading } = useConvertWatchTaskFilter();

  if (isLoading) return <CustomBackdrop isLoading />;
  else return <WatchingTasksPage />;
};

const WatchingTasksPage = () => {
  const [isMobile] = useDevice();

  /* Delete task */
  const deleteTask = useDeleteTask();
  /* ===== */

  /* Table fields */
  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<GetListTask>(Tables.WATCH_TASKS);
  /* ===== */

  /* Tasks */
  const {
    isLoading,
    tasks,
    changeLimit,
    changePage,
    changeOrder,
    count,
    filter,
    setFilter,
    changeSearch,
    showMessage,
  } = useWatchTask();
  /* ===== */

  /* Changing statuses */
  const { modal: changeTaskStatusModal, open: openStatusChangeModal } =
    useChangeTaskStatusModal();
  /* ===== */

  /* Task Drawer */
  const { isOpenDrawer, selectedTaskGuid, closeDrawer, tableClickHandler } =
    useTaskDrawer();
  /* ===== */

  /* ===== */
  const {
    close: closeMove,
    isOpen: isOpenMove,
    initValue: moveTaskGuid,
    open: openMove,
  } = useViewModalWithInitFields<string>();
  const [moveTask, { isLoading: isMovingTask, error }] =
    useMoveTaskToAnotherBoardMutation();

  useShowFetchErrorInSnackbar(error);

  const handleSubmitMove = useCallback(
    (form: AddTaskToBoardFormFields) => {
      if (moveTaskGuid)
        moveTask({
          tasksGuids: [moveTaskGuid],
          boardColumnGuid: form.columnGuid,
        }).then(closeMove);
    },
    [moveTaskGuid]
  );

  const renderActions = useCallback((task: GetListTask) => {
    return (
      <TaskTableActions
        onChangeStatus={() => {
          openStatusChangeModal(task);
        }}
        onDelete={deleteTask}
        onMoveToBoard={openMove}
        task={task}
      />
    );
  }, []);

  const order = useMemo(() => {
    return taskFilterToOrder(filter);
  }, [filter]);

  const { modalFilterWatchingTasks, open: openModalFilterWatchingTasks } =
    useModalFilterWatchingTasks(filter, setFilter);

  return (
    <LayoutPage.Page>
      {changeTaskStatusModal}
      {modalFilterWatchingTasks}

      <AddTaskToBoardDialog
        isOpen={isOpenMove}
        onClose={closeMove}
        isLoading={isMovingTask}
        onSubmit={handleSubmitMove}
      />
      {!isMobile && (
        <TaskDrawer
          isOpen={isOpenDrawer}
          onClose={closeDrawer}
          taskGuid={selectedTaskGuid}
        />
      )}
      <LayoutPage.Bar>
        <MemoizedManagerBarTasks
          openSettings={openModalFilterWatchingTasks}
          onCompliteInput={changeSearch}
          initSearch={filter?.SearchText || ''}
        />
      </LayoutPage.Bar>

      <LayoutPage.Content>
        {showMessage ? (
          <EmptyWatchTaskMessage open={open} />
        ) : (
          <MemoizedTaskTable
            data={tasks || []}
            isLoading={isLoading}
            count={count}
            limit={filter?.Limit || 20}
            page={filter?.Page || 1}
            onChangeLimit={changeLimit}
            onChangePage={changePage}
            fields={fields}
            visibleFields={visibleFields}
            onChangeFields={setFields}
            order={order}
            onChangeOrder={changeOrder}
            onClick={tableClickHandler}
            renderActions={renderActions}
          />
        )}
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
