import { Delete, Edit } from '@mui/icons-material';
import { FC } from 'react';
import { theme } from 'shared/theme/theme';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';
import { AdaptationStageTasksTableActionsProps } from './type';

export const AdaptationStageTasksTableActions: FC<
  AdaptationStageTasksTableActionsProps
> = ({ onDelete, onEdit, task }) => {
  return (
    <UiTableActions>
      <UiButtonAction
        text='Удалить'
        color={theme.palette.error.main}
        icon={<Delete />}
        onClick={() => {
          onDelete?.(task.guid);
        }}
      />
      <UiButtonAction
        text='Изменить'
        color={theme.palette.primary.main}
        icon={<Edit />}
        onClick={() => {
          onEdit?.(task.guid);
        }}
      />
    </UiTableActions>
  );
};
