import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { AdaptationManagerResponsesGetListAdaptationProcessTask } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { useGetAdaptationProcessTasksQuery } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { Tables } from 'shared/utils/controllers';
import { AdaptationProcessTasksTable } from 'widgets/adaptation/AdaptationProcessTasksTable/AdaptationProcessTasksTable';
import { AdaptationProcessTasksProps } from './type';

export const AdaptationProcessTasks: FC<AdaptationProcessTasksProps> = ({
  processGuid,
}) => {
  const {
    data: stages,
    isLoading,
    isFetching,
  } = useGetAdaptationProcessTasksQuery(processGuid);

  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<AdaptationManagerResponsesGetListAdaptationProcessTask>(
      Tables.ADAPTATION_PROCESS_TASKS_TABLE
    );

  if (isLoading) return <LinearProgress />;

  return (
    <Grid
      container
      flexDirection={'column'}
      gap={2}
    >
      {stages?.map((el) => (
        <Grid
          key={el.title}
          container
          flexDirection={'column'}
          gap={1}
        >
          <Typography
            variant='h6'
            gutterBottom
            fontWeight={'bold'}
            component='div'
          >
            {el.title}
          </Typography>
          <Paper elevation={4}>
            <AdaptationProcessTasksTable
              data={el.items}
              fields={fields}
              onChangeFields={setFields}
              visibleFields={visibleFields}
              isLoading={isLoading}
              isUpdate={isFetching}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
