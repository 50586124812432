import { preparationFormTaskDataForPostTaskModel } from 'features/task/form-task';
import { IFormCreatedTaskUiBase } from 'features/task/form-task/type';
import { FC } from 'react';

import { CreateTaskDialog } from 'features/task/form-task/FormCreatedTaskUi';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useCreateTaskMutation } from 'shared/api/services/rtk/taskApi';
import { ModalProps } from './type';

export const ModalFormCreatedTask: FC<ModalProps> = ({ isOpen, onClose }) => {
  const [createTask, { isLoading, error }] = useCreateTaskMutation();

  useShowFetchErrorInSnackbar(error);

  const postTask = (formData: IFormCreatedTaskUiBase) => {
    const fetchData = preparationFormTaskDataForPostTaskModel(formData, false);

    if (fetchData) createTask(fetchData).then(onClose);
  };

  const saveDraftTask = (formData: IFormCreatedTaskUiBase) => {
    const fetchData = preparationFormTaskDataForPostTaskModel(formData, true);

    if (fetchData) createTask(fetchData).then(onClose);
  };

  return (
    <CreateTaskDialog
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={postTask}
      onDraftSubmit={saveDraftTask}
      isLoading={isLoading}
    />
  );
};
