import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { transformBaseFormFilterToUrlParams } from '../libs';
import {
  DocumentFlowFilterActionForm,
  GetDocumentsActionQueryParamsFromFilterActionForm,
} from './type';

/* NEW */
export const transformFilterActionToUrlParams = (
  formFilter: DocumentFlowFilterActionForm
): GetDocumentsActionQueryParamsFromFilterActionForm => {
  const urlParams: GetDocumentsActionQueryParamsFromFilterActionForm = {
    ...transformBaseFormFilterToUrlParams(formFilter),
    CreatorIdFilters: getIdsFromArray(formFilter.creators),
  };

  return urlParams;
};
