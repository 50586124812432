import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useDeleteAdaptationStageTaskMutation } from 'shared/api/service-adaptation/rtk/adaptationStageTasksProviderApi';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useDeleteStageTemplateTask = (
  templateGuid: string,
  stageGuid: string
) => {
  const { open: openSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [deleteTask, { isLoading: isDeleting }] =
    useDeleteAdaptationStageTaskMutation();

  const handleDeleteTask = useCallback(
    (taskGuid: string) => {
      confirm({
        actionTitle: 'Удаление шаблона задачи',
        submitButtonTitle: 'Удалить',
        actionBody: 'Вы точно хотите удалить шаблон задачи?',
        buttonColor: 'error',
        onSubmit: async () => {
          const res = await deleteTask({
            adaptationGuid: templateGuid,
            stageGuid,
            stageTaskGuids: [taskGuid],
          });

          if ('error' in res) {
            openSnackbar({
              duration: 5000,
              type: 'error',
              title: getMessageFromFetchBaseQueryError(res.error),
            });

            throw new Error('Ошибка обработана');
          }
        },
      });
    },
    [deleteTask, confirm, templateGuid, stageGuid, openSnackbar]
  );

  return { handleDeleteTask, isDeleting };
};
