import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useMemo } from 'react';
import { GetListBoardColumn } from 'shared/api/services/types';
import { useViewModal } from 'shared/hooks/useViewModal';
import { MenuOption } from 'shared/ui/icon-with-menu';

export const useMutableBoardColumnHeader = (
  column: GetListBoardColumn,
  actions: MenuOption[],
  canEdit: boolean,
  isLoading: boolean
) => {
  const { close, isOpen, open } = useViewModal();

  const formInitValue = useMemo(() => {
    return { title: column.title, color: column.color };
  }, [column.title, column.color]);

  const formActions = useMemo(() => {
    return (
      <>
        <LoadingButton type='submit'>Изменить</LoadingButton>
        <Button
          onClick={close}
          disabled={isLoading}
        >
          Закрыть
        </Button>
      </>
    );
  }, [isLoading]);

  const extraCardActions = useMemo(() => {
    return [
      {
        title: 'Редактировать',
        icon: <Edit />,
        onClick: open,
        disabled: !canEdit,
      },
      ...actions,
    ];
  }, [actions]);

  return {
    formState: { close, isOpen, open },
    formActions,
    formInitValue,
    extraCardActions,
  };
};
