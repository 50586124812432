import { STATUSES_NEW_TASK } from 'features/task/form-task/constants/statuses';
import { TaskCreate } from 'shared/api/services/types';
import { BoardTaskFormCardFields } from '../type';

export const prepareCreateTaskCardFormToFetch = (
  form: BoardTaskFormCardFields,
  boardColumnGuid: string
): TaskCreate => {
  return {
    coExecutorsGuids: [],
    isControl: false,
    isReminderCoExecutor: false,
    isReminderCreator: false,
    isReminderExecutor: false,
    isReminderObserver: false,
    isTarget: false,
    observersGuids: [],
    originId: 0,
    statusId: STATUSES_NEW_TASK.NEW.id,
    title: form.title,
    executorGuid: form.executor.guid,
    boardColumnGuid: boardColumnGuid,
  };
};
