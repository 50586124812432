import { FC } from 'react';
import { UiTableCard, UiTableCardList } from 'shared/ui/table';

import { PushNotificationsTableActions } from './actions';
import { PushNotificationsTableProps } from './types';

export const PushNotificationsList: FC<PushNotificationsTableProps> = ({
  data,
  fields,
  onChangeFields,
  visibleFields,
  isLoading,
  isUpdate,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading || isUpdate}
    >
      {data.map((pushNotification) => (
        <UiTableCard
          key={pushNotification.distributionGuid || Math.random()}
          data={pushNotification}
          id={pushNotification.distributionGuid || Math.random().toString()}
          visibleFields={visibleFields}
          renderActions={() => (
            <PushNotificationsTableActions {...pushNotification} />
          )}
        />
      ))}
    </UiTableCardList>
  );
};
