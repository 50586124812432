import { Grid } from '@mui/material';

import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useRouteContext } from 'shared/routes/context/useRouteContext';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';

import { ErrorAlert } from 'entities/error/error-alert';
import { $loadingApp } from 'entities/initial-app';
import { chekcIsValidTokenFx } from 'entities/user/check-is-valid-token';
import { PromptContextProvider } from 'entities/visit/promt/PromptContext';

import { ButtonNavigationDrawer } from './ButtonNavigationDrawer';
import { NavBarContext } from './navBarContext';
import { FiltersList } from './save-filter/FiltersList';
import { BreadcrumbsApp, ManualModal } from './ui';
import { CustomDrawer } from './ui/custom-drawer';
import { Header } from './ui/header';

export const MainLayoutApp: FC = NavBarContext.buildWithContextProvider(() => {
  const loadingApp = useStore($loadingApp);
  const { isChected } = useRouteContext();
  const { routes } = useRouteContext();

  const isOpen = NavBarContext.useIsOpen();
  const handleCloseDrawer = NavBarContext.useCloseFn();

  const handleOpenDrawer = NavBarContext.useOpenFn();

  useEffect(() => {
    chekcIsValidTokenFx();
  }, []);

  if (!loadingApp.token.loaded || !isChected)
    return (
      <CustomBackdrop
        isLoading
        sx={{ backgroundColor: 'none' }}
      />
    );

  return (
    <PromptContextProvider>
      <Grid
        container
        flexDirection='column'
        minHeight={'100vh'}
      >
        <Header />

        {loadingApp.token.isValid ? (
          <CustomDrawer.Layout
            Drawer={
              <CustomDrawer.Drawer
                open={isOpen}
                onClose={handleCloseDrawer}
              >
                <CustomDrawer.List sx={{ paddingBottom: 3 }}>
                  {routes.map((route) => (
                    <ButtonNavigationDrawer
                      key={route.fullPath}
                      route={route}
                      isOpenDrawer={isOpen}
                      handleCloseDrawer={handleCloseDrawer}
                      handleOpenDrawer={handleOpenDrawer}
                    />
                  ))}
                  <FiltersList
                    handleCloseDrawer={handleCloseDrawer}
                    handleOpenDrawer={handleOpenDrawer}
                    isOpenDrawer={isOpen}
                  />
                </CustomDrawer.List>
              </CustomDrawer.Drawer>
            }
          >
            <BreadcrumbsApp />
          </CustomDrawer.Layout>
        ) : (
          <Navigate to={'/authorization'} />
        )}
        <ErrorAlert />
        <ManualModal />
      </Grid>
    </PromptContextProvider>
  );
});
