import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ALL_ROUTES } from 'shared/routes/constants/types';

export const useAdaptationNavigation = () => {
  const navigate = useNavigate();

  const navigateToStages = useCallback(
    (templateGuid: string) => {
      navigate(
        generatePath(
          '/' +
            ALL_ROUTES.adaptation +
            '/' +
            ALL_ROUTES.adaptationTemplatesStages,
          { templateGuid }
        )
      );
    },
    [navigate]
  );

  return { navigateToStages };
};
