import { Typography } from '@mui/material';
import { FC } from 'react';

export const DocumentCounterpartyContractNumberUi: FC<{
  counterpartyContractNumber?: string;
}> = ({ counterpartyContractNumber }) => {
  return (
    <Typography sx={{ minWidth: ['auto', '120px'], width: 'max-content' }}>
      {counterpartyContractNumber ? counterpartyContractNumber : '-'}
    </Typography>
  );
};
