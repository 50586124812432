import { useMemo } from 'react';
import { AdaptationsListParams } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { convertURLToAdaptationTemplateList } from 'shared/url-helper/conventors/convertURLToAdaptationTemplateList';
import { useURLState } from 'shared/url-helper/useURLState';

const DEFAULT_VALUES = {
  Limit: 20,
  Page: 1,
  SearchText: '',
};

export const useAdaptationTemplateURLState = () => {
  const [filter, setFilter] = useURLState<AdaptationsListParams>(
    convertURLToAdaptationTemplateList,
    true
  );

  const memFilter = useMemo(() => {
    return {
      Limit: filter?.Limit || DEFAULT_VALUES.Limit,
      Page: filter?.Page || DEFAULT_VALUES.Page,
      SearchText: filter?.SearchText || DEFAULT_VALUES.SearchText,
    } as AdaptationsListParams;
  }, [filter]);

  return [memFilter, setFilter] as const;
};
