export const downloadFileFromUrlString =
  (fileName: (() => string) | string) => (blob: string) => {
    // 2. Create blob link to download
    const link = document.createElement('a');

    link.href = blob;

    const createFileName = typeof fileName === 'string' ? fileName : fileName();

    link.setAttribute('download', createFileName);

    // 3. Append to html pageF
    document.body.appendChild(link);

    // 4. Force download
    link.click();

    return { url: blob, name: createFileName };
  };
