import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useDeleteOpeningTemplatesStagesMutation } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useConfirmDeleteTemplateStage = (
  templateGuid: string | null,
  stageGuid: string
) => {
  const [deleteTemplateStage, { isLoading: isLoadingDeleteTemplateStage }] =
    useDeleteOpeningTemplatesStagesMutation();
  const { open: openSnackBar } = useSnackbar();
  const {
    isOpen: isOpenConfirmDialogDeleteTemplateStage,
    close: onCancelDeleteTemplateStage,
    open: onOpenDialogConfirmDeleteTempateStage,
  } = useViewModal();

  const onConfirmDeleteTemplateStage = useCallback(async () => {
    if (stageGuid && templateGuid) {
      await deleteTemplateStage({
        params: {
          stageGuids: [stageGuid],
          templateGuid,
        },
      }).then((response) => {
        if ('error' in response) {
          openSnackBar({
            text: getMessageFromFetchBaseQueryError(response.error),
            type: 'error',
            duration: 3000,
          });
        } else {
          openSnackBar({
            text: 'Этап успешно удален',
            type: 'success',
            duration: 3000,
          });
          onCancelDeleteTemplateStage();
        }
      });
    }
  }, [templateGuid]);

  return {
    onCancelDeleteTemplateStage,
    onConfirmDeleteTemplateStage,
    onOpenDialogConfirmDeleteTempateStage,
    isOpenConfirmDialogDeleteTemplateStage,
    isLoadingDeleteTemplateStage,
  };
};
