import { BannersApiSliderListParams } from 'shared/api/service-apteka-legko-banners/aptekaLegoBannersAutogenerateApi';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';
import { useURLState } from 'shared/url-helper/useURLState';

const DEFAULT_FILTER: BannersApiSliderListParams = {};

const CONVERT_APTEKA_LEGKO_BANNER_SLIDER_LIST_FILTER_MAP: ConvertMapWithType<BannersApiSliderListParams> =
  [
    { key: 'DateStart', type: ConvertTypes.string },
    { key: 'DateEnd', type: ConvertTypes.string },
  ];

const convertorURLToRetailOpeningTemplates: URLToObjConverter<
  BannersApiSliderListParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_APTEKA_LEGKO_BANNER_SLIDER_LIST_FILTER_MAP
  ) as BannersApiSliderListParams;
};

export const useUrlStateAptekaLegkoBannerSlider = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToRetailOpeningTemplates,
    true
  );

  return {
    filter: filter || DEFAULT_FILTER,
    setFilter,
  };
};
