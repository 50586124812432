import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { EmptyWatchTaskMessageProps } from './type';

export const EmptyWatchTaskMessage: FC<EmptyWatchTaskMessageProps> = ({
  open,
}) => {
  return (
    <Grid
      container
      justifyContent={'center'}
      paddingTop={2}
      sx={{
        marginTop: 2,
        padding: ['0px 8px 0px 8px', '0px 25px 0px 25px'],
      }}
    >
      <Typography
        fontWeight={700}
        fontSize={[14, 18]}
        textAlign={'center'}
        sx={{
          color: theme.palette.customGrey.dark,
          maxWidth: [290, 'inherit'],
          '& > a': {
            marginLeft: 1,
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        }}
      >
        Введите фамилию сотрудника в поиске или настройте
        <a onClick={open}>фильтр</a>
      </Typography>
    </Grid>
  );
};
