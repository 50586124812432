import { PageBannersMainBrand } from 'pages/apteka-legko/banners/brand/PageBannersMainBrand';
import { PageMainBanners } from 'pages/apteka-legko/banners/PageMainBanners';
import { PageBannersMainSlider } from 'pages/apteka-legko/banners/slider/PageBannersMainSlider';
import { PageBannersSliderCreated } from 'pages/apteka-legko/banners/slider/PageBannersSliderCreated';
import { PageBannersSliderEdit } from 'pages/apteka-legko/banners/slider/PageBannersSliderEdit';
import { PageBannersSliderList } from 'pages/apteka-legko/banners/slider/PageBannersSliderList';
import { PageByGuidNotification } from 'pages/apteka-legko/push-notifications/PageByGuidNotification';
import { PageCreatedNotification } from 'pages/apteka-legko/push-notifications/PageCreatedNotification';
import { PageEditNotification } from 'pages/apteka-legko/push-notifications/PageEditNotification';
import { PageListNotification } from 'pages/apteka-legko/push-notifications/PageListNotification';
import { PageMainNotification } from 'pages/apteka-legko/push-notifications/PageMainNotification';
import { RouteObject } from 'react-router-dom';
import { ALL_ROUTES } from './constants';

export const ROUTES_APTEKA_LEGKO_PUSH_CHILDREN: RouteObject[] = [
  {
    path: '',
    element: <PageMainNotification />,
  },
  {
    path: ALL_ROUTES.aptekaLegkoPushCreated,
    element: <PageCreatedNotification />,
  },
  {
    path: ALL_ROUTES.aptekaLegkoPushEdit,
    element: <PageEditNotification />,
  },
  {
    path: ALL_ROUTES.aptekaLegkoPushList,
    element: <PageListNotification />,
  },
  {
    path: ALL_ROUTES.aptekaLegkoPushByGuid,
    element: <PageByGuidNotification />,
  },
];
export const ROUTES_APTEKA_LEGKO_BANNERS_CHILDREN: RouteObject[] = [
  {
    path: '',
    element: <PageMainBanners />,
  },
  {
    path: ALL_ROUTES.aptekaLegkoBannersSliders,
    children: [
      {
        path: '',
        element: <PageBannersMainSlider />,
      },
      {
        path: ALL_ROUTES.aptekaLegkoBannersSlidersList,
        element: <PageBannersSliderList />,
      },
      {
        path: ALL_ROUTES.aptekaLegkoBannersSlidersCreated,
        element: <PageBannersSliderCreated />,
      },
      {
        path: ALL_ROUTES.aptekaLegkoBannersSlidersEdit,
        element: <PageBannersSliderEdit />,
      },
    ],
  },
  {
    path: ALL_ROUTES.aptekaLegkoBannersBrand,
    element: <PageBannersMainBrand />,
  },
];

export const ROUTES_APTEKA_LEGKO: RouteObject[] = [
  {
    path: ALL_ROUTES.aptekaLegko,
    children: [
      {
        path: ALL_ROUTES.aptekaLegkoPush,
        //element: <PageMainNotification />,
        children: ROUTES_APTEKA_LEGKO_PUSH_CHILDREN,
      },
      {
        path: ALL_ROUTES.aptekaLegkoBanners,
        //element: <PageMainNotification />,
        children: ROUTES_APTEKA_LEGKO_BANNERS_CHILDREN,
      },
    ],
  },
];
