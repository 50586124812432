import { KeyboardArrowDown } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC } from 'react';
import { useCardContext } from '../context';
import { CardCollapsButtonProps } from '../types';

export const CardCollapsButton: FC<CardCollapsButtonProps> = ({
  sx,
  onClick,
  ...otherProps
}) => {
  const { isCollapsed, toggleCollapsed } = useCardContext();

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
        toggleCollapsed();
      }}
      sx={{
        '& > .MuiButton-endIcon': {
          transition: '0.4s ease',
          rotate: !isCollapsed ? '0deg' : '180deg',
          marginLeft: 0.5,
        },
        ...sx,
      }}
      {...otherProps}
      endIcon={<KeyboardArrowDown />}
    >
      {!isCollapsed ? 'Развернуть' : 'Свернуть'}
    </Button>
  );
};
