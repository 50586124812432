import { Grid } from '@mui/material';
import { FC } from 'react';

import { ContainerActionsForm } from 'shared/ui/base-ui';

import { FormLayoutProps } from './type';

export const FormLayout: FC<FormLayoutProps> = ({ actions, ...props }) => {
  return (
    <Grid
      position={'relative'}
      pb={[12, 0]}
      component={'form'}
      container
      flexDirection={'column'}
      flexWrap={'nowrap'}
      gap={[0.5, 2]}
      {...props}
    >
      {props.children}

      <ContainerActionsForm>{actions}</ContainerActionsForm>
    </Grid>
  );
};
