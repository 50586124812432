import { Grid } from '@mui/material';
import { FC, ReactNode } from 'react';

export const DocumentForm: FC<{
  children: ReactNode[];
  onSubmit: (element: React.FormEvent<HTMLFormElement> | undefined) => void;
}> = ({ children, onSubmit }) => {
  return (
    <Grid
      container
      flexDirection={'row'}
      padding={['0px 0px', '0px 16px']}
      justifyContent='space-between'
      component={'form'}
      onSubmit={onSubmit}
    >
      {children}
    </Grid>
  );
};
