import { Button, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ALL_ROUTES } from 'shared/routes/constants/types';

import CompliteImage from './img/complite.png';

export const ComplitePage = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'center'}
      height={'80vh'}
    >
      <Paper
        maxWidth={580}
        elevation={4}
        component={Grid}
        padding={[1, 4]}
        container
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={1}
      >
        <img
          src={CompliteImage}
          width={100}
          height={100}
          alt='complite.png'
        />

        <Typography
          fontWeight={700}
          fontSize={20}
        >
          Чек-лист заполнен
        </Typography>
        <Button
          variant='contained'
          sx={{ borderRadius: ['0px', '8px'] }}
          fullWidth
          size='large'
          onClick={() => {
            navigate(
              '/' + ALL_ROUTES.visitingPharmacies + '/' + ALL_ROUTES.planning
            );
          }}
        >
          Вернутся в планирование
        </Button>
      </Paper>
    </Grid>
  );
};
