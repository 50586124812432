import { Clear, Edit, Star } from '@mui/icons-material';
import { AvatarGroup, Badge, Button, Grid, Tooltip } from '@mui/material';
import { useBoardPage } from 'pages/boards/context/BoardPageContext';
import { FC, useState } from 'react';
import { useUpdateBoardMutation } from 'shared/api/services/rtk/boardApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';
import { ButtonWithBadge } from 'shared/ui/ButtonWithBadge/ButtonWithBadge';
import { EmployeeAvatar } from 'shared/ui/EmployeeAvatar';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import { AddEmployeeToBoardModal } from 'widgets/boards/AddEmployeeToBoardModal';
import { BoardTaskFilterDialog } from '../dialogs/BoardTaskFilterDialog/BoardTaskFilterDialog';
import { UpdateBoardTitleDialog } from '../dialogs/UpdateBoardTitleDialog/UpdateBoardTitleDialog';
import { AddEmployeeToBoardFormFields } from '../forms/AddEmployeeToBoardForm';
import { INIT_BOARD_TASK_FILTER } from '../forms/BoardTaskFilterForm/constants';
import { useGetDataForBoardFilter } from '../forms/BoardTaskFilterForm/libs/useGetDataForBoardFilter';
import { BoardTaskFilterFormFields } from '../forms/BoardTaskFilterForm/type';
import { UpdateBoardTitleFormFields } from '../forms/UpdateBoardTitleForm';
import { BoardSearchBarProps } from './type';

export const BoardSearchBar: FC<BoardSearchBarProps> = ({
  onCompleteSearch,
  initSearch,
  onFilterSubmit,
  initValue,
}) => {
  const { close, isOpen, open } = useViewModal();
  const [search, setSearch] = useState(initSearch || '');
  const init = useGetDataForBoardFilter(initValue);
  const { board, boardGuid } = useBoardPage();
  const {
    close: closeEdit,
    isOpen: isOpenEdit,
    open: openEdit,
  } = useViewModal();
  const [updateTitle, { isLoading: isLoadingEdit }] = useUpdateBoardMutation();

  const {
    open: openAdd,
    close: closeAdd,
    isOpen: isOpenAdd,
    initValue: initValueAdd,
  } = useViewModalWithInitFields<AddEmployeeToBoardFormFields>();

  const clearSearch = () => {
    setSearch('');
    onCompleteSearch('');
  };

  const handleSubmit = (form: BoardTaskFilterFormFields) => {
    close();
    onFilterSubmit(form);
  };

  const handleSubmitEdit = async (form: UpdateBoardTitleFormFields) => {
    updateTitle({
      boardGuid,
      requestBody: { title: form.title },
    }).then(closeEdit);
  };

  return (
    <Grid
      container
      flexDirection={'row'}
      flexWrap={'nowrap'}
      alignItems={'center'}
      gap={1}
    >
      <UpdateBoardTitleDialog
        isOpen={isOpenEdit}
        onClose={closeEdit}
        onSubmit={handleSubmitEdit}
        isLoading={isLoadingEdit}
        initValue={{ title: board?.title || '', boardGuid }}
      />

      <BoardTaskFilterDialog
        isOpen={isOpen}
        onClose={close}
        onSubmit={handleSubmit}
        initValue={init}
      />

      <AddEmployeeToBoardModal
        creator={board?.creator}
        boardGuid={boardGuid}
        initValue={initValueAdd}
        onClose={closeAdd}
        isOpen={isOpenAdd}
        onUpdate={closeAdd}
      />

      {board && (
        <>
          <Tooltip
            title={board.title.length > 35 ? board.title : ''}
            arrow
          >
            <Grid>
              <ButtonWithBadge
                disable={!board.canEdit}
                badgeIcon={<Edit sx={{ width: 16, height: 16 }} />}
                buttonProps={{
                  sx: {
                    display: 'block',
                    maxWidth: 350,
                    width: '100%',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  },
                  component: 'div',
                }}
                onClick={openEdit}
              >
                {board?.title}
              </ButtonWithBadge>
            </Grid>
          </Tooltip>

          <Tooltip
            title={
              board.creator.name +
              ' ' +
              board.employees.map((employee) => employee.name).join(' ')
            }
          >
            <Grid>
              <ButtonWithBadge
                disable={!board.canEdit}
                onClick={() => {
                  openAdd({ employees: board?.employees || [] });
                }}
                badgeIcon={<Edit sx={{ width: 16, height: 16 }} />}
              >
                <AvatarGroup
                  slotProps={{
                    additionalAvatar: {
                      sx: { height: 30, width: 30, fontSize: 14 },
                    },
                  }}
                >
                  <Badge
                    badgeContent={
                      <Star
                        color='primary'
                        sx={{ width: 15, height: 15 }}
                      />
                    }
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiBadge-badge': {
                        right: 8,
                        top: 4,
                      },
                    }}
                  >
                    <EmployeeAvatar
                      employee={board?.creator}
                      avatarProps={{
                        sx: { height: 30, width: 30, fontSize: 14 },
                      }}
                    />
                  </Badge>
                  {board?.employees.map((employee) => (
                    <EmployeeAvatar
                      employee={employee}
                      key={employee.guid}
                      avatarProps={{
                        sx: { height: 30, width: 30, fontSize: 14 },
                      }}
                    />
                  ))}
                </AvatarGroup>
              </ButtonWithBadge>
            </Grid>
          </Tooltip>
        </>
      )}

      <UiSearchBar
        containterProps={{ sx: { flex: 1 } }}
        changeValue={setSearch}
        textValue={search}
        onClear={clearSearch}
        onCompliteInput={onCompleteSearch}
      >
        <Button
          startIcon={<Clear />}
          variant='contained'
          color='customBlue'
          onClick={() => {
            onFilterSubmit(INIT_BOARD_TASK_FILTER);
          }}
        >
          Сбросить фильтры
        </Button>

        <FilterButton onClick={open} />
      </UiSearchBar>
    </Grid>
  );
};
