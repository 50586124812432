import { DATE_FORMATS } from 'shared/date-helper/constants';
import { I_FormFilterTraining, TrainingProcessAllQueryParams } from './types';

export const prepareFormDataToQueryParams = (
  form: I_FormFilterTraining
): TrainingProcessAllQueryParams => {
  const { employe, trainer, statuses, dateFrom, dateTo, org, themeTraining } =
    form;

  return {
    EmployeeGuid: employe ? employe.guid : undefined,
    TrainerGuid: trainer ? trainer.guid : undefined,
    Statuses:
      statuses && statuses.length > 0
        ? statuses.map((status) => status.guid as string)
        : undefined,
    DateFrom: dateFrom
      ? dateFrom.format(DATE_FORMATS.MM_DD_YYYY_DOT)
      : undefined,
    DateTo: dateTo ? dateTo.format(DATE_FORMATS.MM_DD_YYYY_DOT) : undefined,
    OrgGuid: org ? org.guid : undefined,
    TrainingGuid: themeTraining ? themeTraining.guid : undefined,
  };
};
