export const changeElementPositionInArray = <T>(
  arrayData: T[],
  old_index: number,
  new_index: number
) => {
  const copy: (T | undefined)[] = [...arrayData];

  if (new_index >= copy.length) {
    let k = new_index - copy.length + 1;

    while (k--) {
      copy.push(undefined);
    }
  }
  copy.splice(new_index, 0, copy.splice(old_index, 1)[0]);

  return copy as T[];
};
