import { Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { VisitingGetListResponse } from 'shared/api/visit/types';
import { theme } from 'shared/theme/theme';

import { VisitBageProps } from './type';

const VISIT_COLORS = {
  red: theme.palette.error.main,
  grey: theme.palette.grey[600],
  blue: theme.palette.customBlue.main,
  green: theme.palette.primary.main,
};

const getVisitColor = (visit: VisitingGetListResponse) => {
  return visit.isCurrentEmployee ? VISIT_COLORS.green : VISIT_COLORS.grey;
};

export const VisitBage: FC<VisitBageProps> = ({ visit }) => {
  const color = useMemo(() => {
    return getVisitColor(visit);
  }, [visit]);

  return (
    <Grid
      container
      flexDirection={'row'}
      alignItems={'center'}
      flexWrap={'nowrap'}
      gap={1}
      height={'28px'}
      sx={{
        ':hover': {
          position: 'absolute',
          backgroundColor: 'white',
          border: '1px solid black',
          borderLeft: 'none',
          borderWidth: 1,
          borderColor: color,
          width: 'fit-content',
          zIndex: 10,
          pr: 1,
        },
      }}
    >
      <Grid
        sx={{
          height: '100%',
          width: 8,
          backgroundColor: color,
          borderRadius: 1,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        flexShrink={0}
      />

      <Typography
        fontWeight={700}
        noWrap
        flexShrink={1}
        flexGrow={0}
      >
        {visit.retailShortTitle} {visit.visitor.employeeName}{' '}
        {visit.visitor.employeePosition}
      </Typography>
    </Grid>
  );
};
