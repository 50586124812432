import { useCallback } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { usePostDoActionsMutation } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { compliteActiconCloseDocument } from '../../deprecated-effector';
import {
  FormDocumentDelegateActionValue,
  IDelegateActionsForm,
  IDelegateRequestCreated,
} from '../types';

const isNoNulableDelegateItem = (
  value: IDelegateActionsForm
): value is IDelegateRequestCreated => {
  if (value.action && value.employee) {
    return true;
  } else {
    return false;
  }
};

export const useDialogFormDocumentDelegateAction = (documentId: number) => {
  const {
    isOpen: isOpenDialogFormDelegateAction,
    close: onCloseDialogFormDelegateAction,
    open: onOpenDialogFormDelegateAction,
  } = useViewModal();

  const [
    postDocumentDoAction,
    { error, isLoading: isLoadingSubmitFormDelegateAction },
  ] = usePostDoActionsMutation();

  useShowFetchErrorInSnackbar(error);

  const { open: openSnackbar } = useSnackbar();

  const handleSubmitFormDelegateAction = useCallback(
    async ({ delegateActions }: FormDocumentDelegateActionValue) => {
      const filterNullableFields = delegateActions
        .filter(isNoNulableDelegateItem)
        .map(({ employee, action }) => ({
          actionTaskId: action.id,
          employeeId: employee.id,
        }));

      if (filterNullableFields.length === 0) {
        openSnackbar({
          title: 'Ошибка',
          text: 'Список пусть или заполнен не верно',
          duration: 3000,
          type: 'error',
        });
      } else {
        const response = await postDocumentDoAction({
          params: {
            documentId: documentId,
            requestBody: {
              actionTaskId: 14,
              delegateActionsRequest: filterNullableFields,
            },
          },
        });

        if ('data' in response) {
          openSnackbar({
            title: 'Успех',
            text:
              filterNullableFields.length > 1
                ? 'Действия делегированы'
                : 'Действие делегировано',
            duration: 3000,
            type: 'success',
          });
          compliteActiconCloseDocument({ params: { documentId } });
          onCloseDialogFormDelegateAction();
        }
      }
    },
    [postDocumentDoAction, onCloseDialogFormDelegateAction]
  );

  return {
    isOpenDialogFormDelegateAction,
    onCloseDialogFormDelegateAction,
    onOpenDialogFormDelegateAction,
    isLoadingSubmitFormDelegateAction,
    handleSubmitFormDelegateAction,
  };
};
