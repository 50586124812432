import {
  Autocomplete,
  AutocompleteProps,
  Grid,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { theme } from '../../theme/theme';

/**
 * @deprecated use CustomAutocompite from shared/ui/base-ui/CustomAutocompite
 */
export const CustomAutoCompliteAdaptive: <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: {
  propsAutocomplite: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;
  label?: string;
  sx?: SxProps<Theme> | undefined;
}) => JSX.Element = (props) => {
  return (
    <Grid
      container
      flexDirection={'column'}
      sx={{ flexBasis: '100%', ...props.sx }}
      justifyContent={'center'}
    >
      <Typography
        sx={{
          display: 'none',
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            backgroundColor: theme.palette.customGrey.main,
            /* position: "relative", */
            padding: '0px 8px',
            /* left: "-8px",
            width: "100%", */
          },
        }}
      >
        {props.label}
      </Typography>
      <Autocomplete
        {...props.propsAutocomplite}
        sx={{
          '& > .MuiFormControl-root > .MuiOutlinedInput-root': {
            '& > .MuiOutlinedInput-notchedOutline': {
              borderWidth: ['0px', '1px'],
            },
          },
          ...props.propsAutocomplite.sx,
        }}
      />
    </Grid>
  );
};
