import { createEffect, createStore } from 'effector';

import { getEmployeesPlanningVisitors } from 'shared/api/visit/services';
import { EmployeePlanningVisitorsGetListResponse } from 'shared/api/visit/types';

export const fetchPlanningRNVisitorsFX = createEffect({
  handler: async () => {
    return getEmployeesPlanningVisitors();
  },
});

export const $planningRNVisitors = createStore<
  EmployeePlanningVisitorsGetListResponse[]
>([]).on(fetchPlanningRNVisitorsFX.doneData, (_, res) => {
  return res.data;
});
