import { ListItemIcon, Skeleton } from '@mui/material';
import { FC } from 'react';
import { ContextMenuItem } from './ContextMenuItem';
import { ContextMenuItemSkeletonProps } from './types';

export const ContextMenuItemSkeleton: FC<ContextMenuItemSkeletonProps> = ({
  withIcon = true,
}) => {
  return (
    <ContextMenuItem>
      {withIcon && (
        <ListItemIcon>
          <Skeleton
            variant={'text'}
            width={'1.5em'}
            height={'1.5em'}
          />
        </ListItemIcon>
      )}
      <Skeleton
        variant={'text'}
        height={'1.5em'}
        sx={{ flexGrow: 1 }}
      />
    </ContextMenuItem>
  );
};
