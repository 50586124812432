import { Typography } from '@mui/material';
import { FC } from 'react';
import { ContextMenuItem, ContextMenuItemSkeleton } from '../context-menu-item';
import { ContextMenuItemIcon } from '../ContextMenuItemIcon';
import { ContextMenuListActionsUiProps } from './types';

export const ContextMenuListActionsUi: FC<ContextMenuListActionsUiProps> = ({
  isLoading,
  isEmpty,
  isUpdating,
  list,
}) => {
  if (isEmpty) {
    return (
      <ContextMenuItem disabled>
        <Typography variant='inherit'>{'Нет доступных действий'}</Typography>
      </ContextMenuItem>
    );
  }
  if (isLoading) {
    return (
      <>
        {new Array(4).fill(1).map((item, index) => {
          return <ContextMenuItemSkeleton key={index} />;
        })}
      </>
    );
  }

  return (
    <>
      {list &&
        list.map((action) => {
          return (
            <ContextMenuItem
              key={action.id}
              disabled={isUpdating}
              onClick={action.onHandleClick}
              color={action.color}
            >
              {action.Icon && (
                <ContextMenuItemIcon>{action.Icon}</ContextMenuItemIcon>
              )}

              <Typography variant='inherit'>{`${action.name}`}</Typography>
            </ContextMenuItem>
          );
        })}
    </>
  );
};
