// eslint-disable-next-line budapestian/global-constant-pattern
import { ContextMenuProvider, useContextMenu } from './context';
import {
  ContextMenuContainer,
  ContextMenuIconButton,
  ContextMenuItem,
  ContextMenuItemIcon,
  ContextMenuItemSkeleton,
  ContextMenuListActionsUi,
} from './ui';

export const ContextMenu = {
  Provider: ContextMenuProvider,
  IconButton: ContextMenuIconButton,
  ContainerMenu: ContextMenuContainer,
  MenuItem: ContextMenuItem,
  MenuItemIcon: ContextMenuItemIcon,
  MenuItemSkeleton: ContextMenuItemSkeleton,
  MenuItemList: ContextMenuListActionsUi,
  useContextMenu: useContextMenu,
};
