import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { useStore } from 'effector-react';
import React, { useMemo } from 'react';

import {
  $formAuth,
  authorizationFx,
  handleShowPassword,
  onAuthorized,
  onChangeLoginForm,
  onChangePasswordForm,
} from 'entities/user/authorization-effector';

export const FormAuthorization = () => {
  const formState = useStore($formAuth);
  const loadingFetchAuthorization = useStore(authorizationFx.pending);

  const onChangeLogin = useMemo(() => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onChangeLoginForm(e.currentTarget.value);
  }, []);

  const onChangePasword = useMemo(() => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onChangePasswordForm(e.currentTarget.value);
  }, []);

  const onShowPassword = useMemo(() => {
    return () => handleShowPassword();
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onAuthorized();
      }}
    >
      <Grid
        container
        spacing={2}
        flexDirection='column'
      >
        <Grid item>
          <TextField
            fullWidth
            autoComplete='username'
            value={formState.login}
            size='small'
            label='Логин'
            required
            onChange={onChangeLogin}
          />
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel
              size='small'
              htmlFor='outlined-adornment-password'
            >
              Пароль
            </InputLabel>
            <OutlinedInput
              autoComplete='current-password'
              value={formState.password}
              onChange={onChangePasword}
              required
              size='small'
              label='Пароль'
              id='outlined-adornment-password'
              type={formState.showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={onShowPassword}
                    edge='end'
                  >
                    {formState.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <LoadingButton
            size='small'
            variant='contained'
            fullWidth
            type='submit'
            color='secondary'
            loading={loadingFetchAuthorization}
            sx={{
              fontWeight: 700,
              fontSize: 16,
              height: '41px',
              textTransform: 'none',
              '&.Mui-disabled': {
                backgroundColor: '#fff',
                border: 'solid 2px #A4A4A4',
              },
            }}
          >
            Войти
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
