import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';

export const UiDelButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      sx={{ borderRadius: ['0px', '8px'] }}
    >
      Удалить
    </Button>
  );
};
