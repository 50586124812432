import { StatusTask } from '../type';

export const changeStatus = (
  allStatuses: StatusTask[],
  newStatus: StatusTask
) => {
  const index = allStatuses.findIndex((el) => el.id === newStatus.id);
  const newStatuses = [...allStatuses];

  newStatuses[index] = {
    ...newStatus,
    selected: !newStatus.selected,
  };

  return newStatuses;
};
