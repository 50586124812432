import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { IconWithMenuProps } from './type';

export const IconWithMenu: FC<IconWithMenuProps> = ({
  icon,
  options,
  menuProps,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>{icon}</IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...menuProps}
        // anchorPosition={{}}
      >
        {options.map((option) => (
          <MenuItem
            key={option.title}
            disabled={option.disabled}
            onClick={(e) => {
              option.onClick();
              handleClose(e);
            }}
            sx={{ display: 'flex', gap: 1 }}
          >
            {option.icon} {option.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
