import { CloseDocumentRequest } from 'shared/api/services-document-flow/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { FormCloseDocumentValue } from '../types';

export const prepareFormValueToRequestBodyActionCloseDocument = (
  form: FormCloseDocumentValue
): CloseDocumentRequest => {
  const {
    isCloseDocumentSumEquals,
    isLink,
    closeDocumentDate,
    closeDocumentLink,
    closeDocumentNumber,
    closeDocumentSum,
    closeDocumentTaxRate,

    equalsSumValue,
  } = form;

  const { documentSum, documentTaxRate } = equalsSumValue;

  /* tax rate id */

  let closeDocumentTaxRateId: number | undefined = undefined;
  let closeDocumentTaxSum: number | undefined = undefined;

  if (isCloseDocumentSumEquals) {
    if (documentTaxRate && typeof documentTaxRate === 'object') {
      closeDocumentTaxRateId = documentTaxRate.id;
      closeDocumentTaxSum = documentTaxRate.sum;
    } else if (documentTaxRate) {
      closeDocumentTaxSum = Number(documentTaxRate);
    }
  } else {
    if (closeDocumentTaxRate && typeof closeDocumentTaxRate === 'object') {
      closeDocumentTaxRateId = closeDocumentTaxRate.id;
      closeDocumentTaxSum = closeDocumentTaxRate.sum;
    } else if (closeDocumentTaxRate) {
      closeDocumentTaxSum = Number(closeDocumentTaxRate);
    }
  }

  return {
    closeDocumentDate:
      (closeDocumentDate && closeDocumentDate.format(DATE_FORMATS.server)) ||
      undefined,
    isCloseDocumentDiadok: isLink,
    isCloseDocumentSumEquals,
    closeDocumentDiadokLink: isLink
      ? closeDocumentLink || undefined
      : undefined,
    closeDocumentNumber: String(closeDocumentNumber) || undefined,
    closeDocumentSum: !isCloseDocumentSumEquals
      ? Number(closeDocumentSum) || undefined
      : Number(documentSum) || undefined,
    closeDocumentTaxRateId: closeDocumentTaxRateId,
    closeDocumentTaxSum: closeDocumentTaxSum,
  };
};
