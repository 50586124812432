// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';

import { postAuthentication } from 'shared/api/services-auth/services';
import {
  AuthRequestsAuthentication,
  CoreResponsesAuthentication,
} from 'shared/api/services-auth/types';
import { deviceIdLocalStorage } from 'shared/utils/deviceId';
import { token } from 'shared/utils/token';

import { addedError } from 'entities/error/error-alert';

/* -------------------------------------------------------------------------------------------- */

/* Effect authorization on api  */
export const authorizationFx = createEffect({
  name: 'authorizationFx',
  async handler(params: AuthRequestsAuthentication) {
    return await postAuthentication(params);
  },
});
/* -------------------------------------------------------------------------------------------- */

/* Event log out */
export const logOut = createEvent<any>('LogOut');
/* -------------------------------------------------------------------------------------------- */

/* Form */

/* Events for changes object-forms */
export const onChangeLoginForm = createEvent<string>('onChangeLoginForm');
export const onChangePasswordForm = createEvent<string>('onChangePasswordForm');
export const handleShowPassword = createEvent('handleShowPassword');

/* Store form */
const INITIAL_FORM_STATE: formLogIn = {
  login: '',
  password: '',
  showPassword: false,
  deviceId:
    deviceIdLocalStorage.get() ||
    deviceIdLocalStorage.save(Math.random().toString()), // Берем значение из localStorage или инициализируем его
};

interface formLogIn extends AuthRequestsAuthentication {
  showPassword: boolean;
}
export const $formAuth = createStore<formLogIn>(INITIAL_FORM_STATE, {
  name: '$formAuth',
})
  .on(onChangeLoginForm, (state, login) => {
    return { ...state, login };
  })
  .on(onChangePasswordForm, (state, password) => {
    return { ...state, password };
  })
  .on(handleShowPassword, (state) => {
    return { ...state, showPassword: !state.showPassword };
  });
/* -------------------------------------------------------------------------------------------- */

/* User-token */

/* Event for user-token */
export const getTokenFromLocalStorage = createEvent('getTokenFromLocalStorage');
export const saveResponseTokenToLocalStorage =
  createEvent<CoreResponsesAuthentication>('saveTokenToLocalStorage');
/* Store user-token */
export const $token = createStore<CoreResponsesAuthentication | null>(null, {
  name: '$token',
})
  .on(saveResponseTokenToLocalStorage, (_, tokenResponse) => {
    token.save(tokenResponse);

    return tokenResponse;
  })
  .on(getTokenFromLocalStorage, () => {
    const localToken = token.get();

    return localToken;
  })
  .on(logOut, () => {
    token.clear();

    return null;
  });
/* -------------------------------------------------------------------------------------------- */
/* Events for isAuthUser */
export const onAuthorized = createEvent<any>('onAuthorized');
/* При срабатывании onAuthorized  берем данные из  $formAuth передаем в Effect - authorizationFx, делаем запрос на авторизацию */
sample({
  clock: onAuthorized,
  source: $formAuth,
  target: authorizationFx,
});

/* Сохраняем токен при успешном ответе в стор и localStorage */
sample({
  source: authorizationFx.doneData,
  filter: (response) => response.status === 200,
  fn: (response) => response.data,
  target: saveResponseTokenToLocalStorage,
});

/* При неудачной авторизации, добавляем ошибку в стор $errorStack */
sample({
  clock: authorizationFx.fail,
  fn: (data) => {
    const error = data.error as AxiosError;

    return error;
  },
  target: addedError,
});
