// eslint-disable-next-line budapestian/global-constant-pattern
import { CustomDrawer as Drawer } from './CustomDrawer';
import { CustomDrawerLayout } from './CustomDrawerLayout';
import { CustomListDriwer } from './CustomListDrawer';
import { CustomListItemButtonMoreDrawer } from './CustomListItemButtonMoreDrawer';
import { CustomListItemLinkDrawer } from './CustomListItemDriwer';

export const CustomDrawer = {
  Drawer,

  ListItemLink: CustomListItemLinkDrawer,
  ListItemButtonMore: CustomListItemButtonMoreDrawer,
  List: CustomListDriwer,
  Layout: CustomDrawerLayout,
};
