import dayjs from 'dayjs';
import { DocumentModel } from 'shared/api/services-document-flow/types';
import { DEFAULT_VALUE } from '../constants';
import { FormCloseDocumentValue } from '../types';

export const preparaDocumentToCloseDocumentFormValue = (
  document: DocumentModel
): FormCloseDocumentValue => {
  const {
    closeDocumentDiadokLink,
    isCloseDocumentDiadok,
    closeDocumentFiles,

    closeDocumentNumber,
    closeDocumentDate,
    closeDocumentSum,
    closeDocumentTaxRate,
    closeDocumentTaxSum,
    isCloseDocumentSumEquals,
    totalSum,
    taxRate,
    taxSum,
  } = document;

  const formValue: FormCloseDocumentValue = {
    ...DEFAULT_VALUE,
    isLink: isCloseDocumentDiadok || DEFAULT_VALUE.isLink,
    closeDocumentLink:
      closeDocumentDiadokLink || DEFAULT_VALUE.closeDocumentLink,
    filesFromBackend:
      closeDocumentFiles?.map((file) => ({ file })) ||
      DEFAULT_VALUE.filesFromBackend,
    closeDocumentNumber:
      closeDocumentNumber || DEFAULT_VALUE.closeDocumentNumber,
    closeDocumentDate: (closeDocumentDate && dayjs(closeDocumentDate)) || null,
    closeDocumentSum: closeDocumentSum || DEFAULT_VALUE.closeDocumentSum,
    closeDocumentTaxRate:
      (closeDocumentTaxRate && {
        ...closeDocumentTaxRate,
        sum: closeDocumentTaxSum || 0,
      }) ||
      closeDocumentTaxSum ||
      DEFAULT_VALUE.closeDocumentTaxRate,

    isCloseDocumentSumEquals: Boolean(isCloseDocumentSumEquals),
    /* Данныe для подстановки если  isCloseDocumentSumEquals === true */
    equalsSumValue: {
      documentSum: totalSum || '',
      documentTaxRate:
        (taxRate && { ...taxRate, sum: taxSum || 0 }) || taxSum || '',
    },
  };

  return formValue;
};
