import img1 from 'shared/img/GZinFace/1.png';
import img2 from 'shared/img/GZinFace/2.png';
import img3 from 'shared/img/GZinFace/3.png';
import img4 from 'shared/img/GZinFace/4.png';
import img5 from 'shared/img/GZinFace/5.png';
import img6 from 'shared/img/GZinFace/6.png';
import img7 from 'shared/img/GZinFace/7.png';
import img8 from 'shared/img/GZinFace/8.png';

export const GZ_IN_FACE_IMAGES = [
  {
    src: img1,
    title: 'GZinFace',
    alt: 'GzInFace',
  },
  {
    src: img2,
    title: 'GZinFace',
    alt: 'GzInFace',
  },
  {
    src: img3,
    title: 'GZinFace',
    alt: 'GzInFace',
  },
  {
    src: img4,
    title: 'GZinFace',
    alt: 'GzInFace',
  },
  {
    src: img5,
    title: 'GZinFace',
    alt: 'GzInFace',
  },
  {
    src: img6,
    title: 'GZinFace',
    alt: 'GzInFace',
  },
  {
    src: img7,
    title: 'GZinFace',
    alt: 'GzInFace',
  },
  {
    src: img8,
    title: 'GZinFace',
    alt: 'GzInFace',
  },
];
