import { Theme } from '@emotion/react';
import { SxProps, Typography } from '@mui/material';
import { FC } from 'react';

import { theme } from '../theme/theme';

export const DocumentSectionTitle: FC<{
  text: string;
  sx?: SxProps<Theme> | undefined;
}> = ({ text, sx = {} }) => {
  return (
    <Typography
      variant='h2'
      fontSize={16}
      fontWeight={700}
      textAlign={'center'}
      sx={{
        backgroundColor: ['none', theme.palette.customGrey.main],
        borderRadius: '10px',
        padding: ['8px 0px'],
        marginTop: [2, 2],
        marginBottom: 1,
        flexBasis: '100%',
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};
