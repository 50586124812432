import { FC } from 'react';

import { useMediaQuery } from '@mui/material';
import { theme } from 'shared/theme/theme';
import { ProtocolOpeningRetailsList } from './ProtocolOpeningRetailsList';
import { ProtocolOpeningRetailsTable } from './ProtocolOpeningRetailsTable';
import { ProtocolOpeningRetailsTableProps } from './types';

export const ProtocolOpeningRetailsTableAdaptiveTable: FC<
  ProtocolOpeningRetailsTableProps
> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) return <ProtocolOpeningRetailsList {...props} />;
  else return <ProtocolOpeningRetailsTable {...props} />;
};
