import { PopperTriangleCreateStyleProps } from './types';

export const createStylesTriangle = ({
  keyPosition,
  deg,
  dataAtrrForHidenArrow,
  squareWidth,
  percentOfsquareWidth,
}: PopperTriangleCreateStyleProps) => {
  return {
    '&::before': {
      [keyPosition]: `${squareWidth * percentOfsquareWidth}px`,

      transform: `rotate(${deg}deg) scale(1)`,
    },
    [`&[${dataAtrrForHidenArrow}]::before`]: {
      transform: `rotate(${deg}deg) scale(0.01)`,
    },
  };
};
