import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getTaskLink } from 'entities/task/libs/getTaskLink';
import { TaskStatusChip } from 'features/task/task-status-chip/TaskStatusChip';
import { TaskStatuses } from 'features/task/task-status-chip/type';
import { AdaptationManagerResponsesGetListAdaptationProcessTask } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { Link } from 'shared/ui/base-ui';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const ADAPTATION_PROCESS_TASKS_MAP: FieldMapType<AdaptationManagerResponsesGetListAdaptationProcessTask>[] =
  [
    {
      field: 'title',
      title: 'Название',
      order: 1,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => (
        <Link
          onClick={(e) => e.stopPropagation()}
          to={getTaskLink(task.guid)}
        >
          {task.title}
        </Link>
      ),
    },
    {
      field: 'dateStart',
      title: 'Дата начала',
      order: 2,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => (
        <Typography>
          {dayjs(task.dateStart).format(DATE_FORMATS.client)}
        </Typography>
      ),
    },
    {
      field: 'dateFinish',
      title: 'Дата завершения',
      order: 3,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => (
        <Typography>
          {dayjs(task.dateFinish).format(DATE_FORMATS.client)}
        </Typography>
      ),
    },
    {
      field: 'status',
      title: 'Статус',
      order: 4,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => (
        <TaskStatusChip status={task.status as TaskStatuses} />
      ),
    },
    {
      field: 'creatorName',
      title: 'Постановщик',
      order: 5,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => <Typography>{task.creatorName}</Typography>,
    },
    {
      field: 'executorName',
      title: 'Исполнитель',
      order: 6,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => <Typography>{task.executorName}</Typography>,
    },
    {
      field: 'units',
      title: 'Юниты',
      order: 7,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (task) => <Typography>{task.units}</Typography>,
    },
    // {
    //   field: '',
    //   title: '',
    //   order: ,
    //   isShow: true,
    //   getTableCellProps: () => ({}),
    //   renderComponent: (task) => ,
    // },
  ];
