import { useURLState } from 'shared/url-helper/useURLState';

import { OpeningTemplatesListParams } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';

const convertorURLToRetailOpeningTemplates: URLToObjConverter<
  OpeningTemplatesListParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_RAIL_OPENING_TEMPLATE_LIST_FILTER_MAP
  ) as OpeningTemplatesListParams;
};

const CONVERT_RAIL_OPENING_TEMPLATE_LIST_FILTER_MAP: ConvertMapWithType<OpeningTemplatesListParams> =
  [
    { key: 'Page', type: ConvertTypes.number },
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'SearchText', type: ConvertTypes.string },
  ];

export const useUrlStateRetailOpeningTemplatesFilter = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToRetailOpeningTemplates,
    true
  );

  return {
    filter,
    setFilter,
  };
};
