import { useEffect, useState } from 'react';

import { getFiltersVisiting } from 'shared/api/visit/services';
import { VisitingFilterResponse } from 'shared/api/visit/types';

export const useGetFiltersVisiting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<VisitingFilterResponse | null>(null);

  const getFilters = async () => {
    setIsLoading(true);

    const { data, status } = await getFiltersVisiting();

    if (status === 200) setFilters(data);

    setIsLoading(false);
  };

  useEffect(() => {
    getFilters();
  }, []);

  return { isLoading, filters };
};
