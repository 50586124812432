import { Grid, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { FormPushNotificationsActions } from './actions';
import {
  FormPushNotifications,
  FormPushNotificationsProvider,
  useFormPushNotificationsContext,
} from './forms';
import { FORM_ID } from './forms/form-push-notifications/constants';

import dayjs from 'dayjs';
import { useWatch } from 'react-hook-form';

import { FormNotificationsWithActionsProps } from './types';
import {
  LayoutFirebaseNotificationFormPage,
  PreviewPushNotificationOnMobile,
  PreviewSavedNotificationOnLkUser,
} from './ui';
import { getTypeNotificaOnGuid } from './ui/preview-saved-notification-on-lk-user';

export const FormNotificationsWithActions: FC<
  FormNotificationsWithActionsProps
> = ({ isLoading, onSubmit, initialForm, disabledSendActions }) => {
  return (
    <FormPushNotificationsProvider>
      <LayoutFirebaseNotificationFormPage
        Actions={
          <FormPushNotificationsActions
            disabledTestSendAction={disabledSendActions}
            disabledCloseActions={isLoading}
            disabledSendAction={isLoading}
            isLoading={isLoading}
          />
        }
        Preview={<PreviewPushNotificationWithFormValue />}
      >
        <FormPushNotifications
          formId={FORM_ID}
          initialForm={initialForm}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      </LayoutFirebaseNotificationFormPage>
    </FormPushNotificationsProvider>
  );
};

const PreviewPushNotificationWithFormValue = () => {
  const { control } = useFormPushNotificationsContext();
  const title = useWatch({ control, name: 'distributionTitle' });
  const message = useWatch({ control, name: 'message' });
  const dateTimeSending = useWatch({ control, name: 'dateTimeSending' });
  const isSendingNow = useWatch({ control, name: 'isSendingNow' });
  const image = useWatch({ control, name: 'imageData.data' });
  const imageUrl = useWatch({ control, name: 'imageUrl' });
  const notificationTypeValue = useWatch({
    control,
    name: 'notificationTypeValue',
  });
  const notificationTypeGuid = useWatch({
    control,
    name: 'notificationType.notificationTypeGuid',
  });

  const typeNotificationlkPreview =
    notificationTypeGuid && getTypeNotificaOnGuid(notificationTypeGuid);

  const imageSrc =
    imageUrl && imageUrl !== ''
      ? imageUrl
      : (image && `data:image/png;base64,${image}`) || undefined;

  const sendingTime = isSendingNow ? dayjs() : dateTimeSending;
  const theme = useTheme();

  return (
    <Grid
      flex={1}
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      gap={4}
    >
      <Grid
        flexGrow={1}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
      >
        <PreviewPushNotificationOnMobile
          title={title !== '' ? title : undefined}
          message={message || undefined}
          dateTimeSending={sendingTime ? sendingTime.format() : undefined}
          image={imageSrc}
        />
        <Typography
          textAlign={'center'}
          color={theme.palette.customGrey.dark}
        >
          Отображение на экране смартфона
        </Typography>
      </Grid>

      <Grid
        flexGrow={1}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        gap={2}
      >
        {typeNotificationlkPreview && notificationTypeValue && title && (
          <>
            <PreviewSavedNotificationOnLkUser
              title={title}
              message={message || undefined}
              buttonValue={notificationTypeValue}
              type={typeNotificationlkPreview}
            />
            <Typography
              textAlign={'center'}
              color={theme.palette.customGrey.dark}
            >
              Отображение в профиле пользователя
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};
