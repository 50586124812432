// eslint-disable-next-line budapestian/global-constant-pattern
import { Box, Paper } from '@mui/material';

import { theme } from 'shared/theme/theme';
import { ComponentWithChildren } from './types';

const Container: ComponentWithChildren = ({ children }) => {
  return (
    <Box
      display={'grid'}
      gridTemplateColumns={'repeat(4, 1fr)'}
      gridTemplateRows={'1fr, 2fr, 3fr'}
      columnGap={2}
      rowGap={3}
    >
      {children}
    </Box>
  );
};
const LeftStickySection: ComponentWithChildren = ({ children }) => {
  return (
    <Box
      gridColumn={{ lg: '1', xs: '1/5' }}
      gridRow={{ lg: '1/4', xs: '2' }}
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Paper
        sx={{
          padding: 1,
          gap: 2,
          display: 'flex',
          flexDirection: 'column',

          height: 'fit-content',
          flexGrow: 1,
          ...theme.mixins.stickyTopFromAppBar(10),
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

const RightMainContent: ComponentWithChildren = ({ children }) => {
  return (
    <Box
      gridColumn={{ lg: '2/5', xs: '1/5' }}
      gridRow={{ lg: '2/4', xs: '3' }}
    >
      {children}
    </Box>
  );
};

const RightTop: ComponentWithChildren = ({ children }) => {
  return (
    <Box
      gridColumn={{ lg: '2/5', xs: '1/5' }}
      gridRow={{ lg: '1', xs: '1' }}
    >
      {children}
    </Box>
  );
};

export const GridLayout = {
  Container,
  LeftStickySection,
  RightTop,
  RightMainContent,
};
