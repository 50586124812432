import { yupResolver } from '@hookform/resolvers/yup';
import { Chip, Grid } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DATE_FORMATS } from 'shared/date-helper/constants';
import { hasNotDateInMonth } from 'shared/date-helper/hasNotDateInMonth';
import { findElementsByIds } from 'shared/tarnsform-helper/findElementsByIds';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { CustomAutocomplete, CustomDatePicker } from 'shared/ui/base-ui';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { FormLayout } from 'shared/ui/form';

import { useGetPlanningHomies } from 'entities/visit/options/useGetPlanningHomies';

import { editPlanningRNFormSchema } from './schema/validate';
import { EditPlanningRNFormFields, EditPlanningRNFormProps } from './type';

export const EditPlanningRNForm: FC<EditPlanningRNFormProps> = ({
  onSubmit,
  actions,
  initValue,
}) => {
  const { homies, isLoading: isLoadingHomies } = useGetPlanningHomies();
  const { control, handleSubmit } = useForm<EditPlanningRNFormFields>({
    defaultValues: initValue,
    resolver: yupResolver(editPlanningRNFormSchema),
  });

  /* ===== */
  const lockedHomie = useMemo(() => {
    if (!initValue?.departmentId) return null;

    return (
      homies.find(
        (el) => el.departmentsIds?.some((el2) => el2 === initValue.departmentId)
      ) || null
    );
  }, [initValue, homies]);
  /* ===== */

  /* ===== */
  const shouldDisableDate = useCallback(
    (day: Dayjs) => {
      return hasNotDateInMonth(
        day,
        initValue?.periodStart || initValue?.periodFinish
      );
    },
    [initValue]
  );
  /* ===== */

  return (
    <>
      <CustomBackdrop isLoading={isLoadingHomies} />
      <FormLayout
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
        actions={actions(handleSubmit)}
        container
        flexDirection={'column'}
        gap={2}
      >
        <Controller
          control={control}
          name='homiesIds'
          defaultValue={[]}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <CustomAutocomplete
              textFieldProps={{
                label: 'Участники',
                placeholder: 'Выберите участников',
                error: invalid,
              }}
              multiple
              value={findElementsByIds(homies, value || [])}
              onChange={(_, val) => {
                const isClear = val.length === 0;

                if (isClear && lockedHomie) {
                  onChange([lockedHomie.id]);

                  return;
                }
                onChange(getIdsFromArray(val));
              }}
              isOptionEqualToValue={(op, eq) => op.id === eq.id}
              options={homies.filter((el) => el.id !== initValue?.visitorId)}
              getOptionLabel={(option) =>
                option.employeeName + ' ' + option.employeePosition || ''
              }
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size='small'
                    label={option.employeeName + ' ' + option.employeePosition}
                    {...getTagProps({ index })}
                    disabled={lockedHomie?.id === option.id}
                  />
                ))
              }
            />
          )}
        />

        <Grid
          container
          flexDirection={'row'}
          gap={2}
        >
          <Controller
            control={control}
            name='periodStart'
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                label='С'
                referenceDate={dayjs(initValue?.periodStart)}
                slotProps={{ textField: { required: true } }}
                sx={{ width: '100%' }}
                value={dayjs(value)}
                onChange={(val) => {
                  onChange(val?.format(DATE_FORMATS.server));
                }}
                containerSx={{ flex: 1 }}
                shouldDisableDate={shouldDisableDate}
              />
            )}
          />

          <Controller
            control={control}
            name='periodFinish'
            render={({
              field: { onChange, value },
              fieldState: { error, invalid },
            }) => (
              <CustomDatePicker
                label='До'
                referenceDate={dayjs(initValue?.periodStart)}
                slotProps={{
                  textField: {
                    required: true,
                    helperText: error?.message,
                    error: invalid,
                  },
                }}
                value={dayjs(value)}
                onChange={(val) => {
                  onChange(val?.format(DATE_FORMATS.server));
                }}
                sx={{ width: '100%' }}
                containerSx={{ flex: 1 }}
                shouldDisableDate={shouldDisableDate}
              />
            )}
          />
        </Grid>
      </FormLayout>
    </>
  );
};
