import { ListItem } from '@mui/material';
import List, { ListProps } from '@mui/material/List/List';

import { FC } from 'react';

export const CustomListDriwer: FC<ListProps> = ({ children, ...props }) => {
  return (
    <List {...props}>
      {children}
      {/* TODO: Хак для отступа */}
      <ListItem />
      <ListItem />
      <ListItem />
      <ListItem />
      <ListItem />
    </List>
  );
};
