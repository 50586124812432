// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */
// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { SwaggerResponse } from 'shared/api/services-document-flow/config';
import {
  deleteDocumentsDocumentIdFilesCloseDocumentsFileId,
  deleteDocumentsDocumentIdFilesReasonDocumentsFileId,
  getDocumentsDocumentId,
  getDocumentsDocumentIdAgreeProcesses,
  getOrganizations,
  getOrganizationsAllowed,
  getProcessesProcessIdAdditionallyFields,
  postDocumentsDocumentIdDoAction,
  postDocumentsDocumentIdFilesCloseDocuments,
  postDocumentsDocumentIdFilesReasonDocuments,
  postDocumentsFirstStageValidate,
  postDocumentsSecondStageValidate,
  putDocumentsDocumentId,
} from 'shared/api/services-document-flow/services';
import {
  DocumentReasonDocumentFileModel,
  DocumetnDoActionRequest,
  ErrorResponse,
} from 'shared/api/services-document-flow/types';

import { ParamsPutDocument } from './type';

const getDocumentIdEditFx = createEffect<
  typeof getDocumentsDocumentId,
  AxiosError<ErrorResponse>
>(getDocumentsDocumentId);
const refetchDocumentIdEditFx = createEffect<
  typeof getDocumentsDocumentId,
  AxiosError<ErrorResponse>
>(getDocumentsDocumentId);

const fetchOrganizationsFx = createEffect({
  name: 'documentFormEditApi.getOrgOptions',
  async handler(params: {
    isOrganizationOnlyForMyOffice: boolean;
    processId?: number;
  }) {
    if (params.isOrganizationOnlyForMyOffice && params.processId) {
      return getOrganizationsAllowed({ processId: params.processId });
    }

    return getOrganizations();
  },
});
const fetchAdditionallyFields = createEffect<
  typeof getProcessesProcessIdAdditionallyFields,
  AxiosError<ErrorResponse>
>(getProcessesProcessIdAdditionallyFields);
const firstValidationFx = createEffect<
  typeof postDocumentsFirstStageValidate,
  AxiosError<ErrorResponse>
>(postDocumentsFirstStageValidate);
const initialFirstValidationFx = createEffect<
  typeof postDocumentsFirstStageValidate,
  AxiosError<ErrorResponse>
>(postDocumentsFirstStageValidate);
const secondaryValidationFx = createEffect<
  typeof postDocumentsSecondStageValidate,
  AxiosError<ErrorResponse>
>(postDocumentsSecondStageValidate);
const initialSecondaryValidationFx = createEffect<
  typeof postDocumentsSecondStageValidate,
  AxiosError<ErrorResponse>
>(postDocumentsSecondStageValidate);

const putDocumentFx = createEffect<
  ParamsPutDocument,
  SwaggerResponse<any>,
  AxiosError<ErrorResponse>
>({
  name: 'putDocumentFx',
  async handler(params: ParamsPutDocument) {
    return putDocumentsDocumentId(params.documentId, params.body);
  },
});
const saveDocumentFx = createEffect({
  name: 'putDocumentFx',
  async handler(params: ParamsPutDocument) {
    return putDocumentsDocumentId(params.documentId, params.body);
  },
});
const getAgreeChainFx = createEffect<
  typeof getDocumentsDocumentIdAgreeProcesses,
  AxiosError<ErrorResponse>
>(getDocumentsDocumentIdAgreeProcesses);

const postDocumentsSendFx = createEffect<
  { documentId: number; requestBody: DocumetnDoActionRequest },
  SwaggerResponse<any>,
  AxiosError<ErrorResponse>
>(
  async (params: {
    documentId: number;
    requestBody: DocumetnDoActionRequest;
  }) => {
    return postDocumentsDocumentIdDoAction(
      params.documentId,
      params.requestBody
    );
  }
);

/* files - reason */

/* DELETE */
const deleteReasonFileDocumentFx = createEffect<
  { documentId: number; fileId: number },
  SwaggerResponse<any>,
  AxiosError<ErrorResponse>
>(async (params: { documentId: number; fileId: number }) => {
  return deleteDocumentsDocumentIdFilesReasonDocumentsFileId(
    params.documentId,
    params.fileId
  );
});
const deleteReasonFilesDocumentFx = createEffect({
  name: 'deleteReasonFileDocumentFx',
  async handler(params: { documentId: number; files: number[] }) {
    return Promise.all(
      params.files.map((fileId) =>
        deleteReasonFileDocumentFx({ documentId: params.documentId, fileId })
      )
    );
  },
});
/* POST */
const postDocumentsDocumentIdFilesReasonDocumentsFx = createEffect<
  { id: number; file: File },
  SwaggerResponse<DocumentReasonDocumentFileModel>,
  AxiosError<ErrorResponse>
>({
  handler: async (params) => {
    const formFile = new FormData();

    formFile.append('formFile', params.file);

    return postDocumentsDocumentIdFilesReasonDocuments(params.id, {
      /* @ts-ignore */
      formFile: params.file,
    });
  },
});

const postAllDocumentsDocumentIdFilesReasonDocumentsFx = createEffect({
  async handler(params: { id: number; files: File[] }) {
    return await Promise.all(
      params.files.map((file) => {
        return postDocumentsDocumentIdFilesReasonDocumentsFx({
          id: params.id,
          file,
        });
      })
    );
  },
});

/* --------------------------------------FILE-CLOSE--------------------------- */

const deleteCloseFileDocumentFx = createEffect<
  { documentId: number; fileId: number },
  SwaggerResponse<any>,
  AxiosError<ErrorResponse>
>(async (params: { documentId: number; fileId: number }) => {
  return deleteDocumentsDocumentIdFilesCloseDocumentsFileId(
    params.documentId,
    params.fileId
  );
});
const deleteCloseFilesDocumentFx = createEffect({
  name: 'deleteCloseFilesDocumentFx',
  async handler(params: { documentId: number; files: number[] }) {
    return Promise.all(
      params.files.map((fileId) =>
        deleteCloseFileDocumentFx({ documentId: params.documentId, fileId })
      )
    );
  },
});

/* POST */
const postDocumentsDocumentIdFilesCloseDocumentsFx = createEffect<
  { id: number; file: File },
  SwaggerResponse<DocumentReasonDocumentFileModel>,
  AxiosError<ErrorResponse>
>({
  handler: async (params) => {
    const formFile = new FormData();

    formFile.append('formFile', params.file);

    return postDocumentsDocumentIdFilesCloseDocuments(params.id, {
      /* @ts-ignore */
      formFile: params.file,
    });
  },
});

const postAllDocumentsDocumentIdFilesCloseDocumentsFx = createEffect({
  async handler(params: { id: number; files: File[] }) {
    return await Promise.all(
      params.files.map((file) => {
        return postDocumentsDocumentIdFilesCloseDocumentsFx({
          id: params.id,
          file,
        });
      })
    );
  },
});

export const documentFormEditApi = {
  getDocument: getDocumentIdEditFx,
  getOrgOptions: fetchOrganizationsFx,
  getAdditionallyFields: fetchAdditionallyFields,
  firstValidation: firstValidationFx,
  secondValitation: secondaryValidationFx,
  initialFirstValidation: initialFirstValidationFx,
  initialSecondaryValidation: initialSecondaryValidationFx,
  putDocument: putDocumentFx,
  saveDocument: saveDocumentFx,
  getAgreeChain: getAgreeChainFx,
  postDocumentsSend: postDocumentsSendFx,
  /* reason-files */
  deleteReasonFilesDocument: deleteReasonFilesDocumentFx,
  deleteReasonFileDocument: deleteReasonFileDocumentFx,
  postAllDocumentsDocumentIdFilesReasonDocuments:
    postAllDocumentsDocumentIdFilesReasonDocumentsFx,
  postDocumentsDocumentIdFileReasonDocuments:
    postDocumentsDocumentIdFilesReasonDocumentsFx,
  /* close-files */
  deleteCloseFilesDocument: deleteCloseFilesDocumentFx,
  deleteCloseFileDocument: deleteCloseFileDocumentFx,
  postAllDocumentsDocumentIdFilesCloseDocuments:
    postAllDocumentsDocumentIdFilesCloseDocumentsFx,
  postDocumentsDocumentIdFileCloseDocuments:
    postDocumentsDocumentIdFilesCloseDocumentsFx,

  refetchDocumentIdEdit: refetchDocumentIdEditFx,
};
