import { AttachFile } from '@mui/icons-material';
import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { Controller, useWatch } from 'react-hook-form';
import {
  useGetFirebaseDistributionsUserCountQuery,
  useGetFirebaseNotificationTypesQuery,
  useGetFirebaseTopicsQuery,
} from 'shared/api/online-store-service/firebase/rtk';

import { SX_DISABLED_MARGIN, VARIANT_RECIPIENTS_FROM } from './constants';

import {
  getOptionLabelByFirebaseNotificationType,
  getOptionLabelByFirebaseTopic,
  isOptionEqualToValueByFirebaseNotificationType,
  isOptionEqualToValueByFirebaseTopic,
  renderOptionByFirebaseNotificationType,
  renderOptionByFirebaseTopic,
} from 'entities/apteka-legko/push-notifications';
import { FC, memo, useCallback, useEffect } from 'react';
import { CoreSystemEnumsObjectActions } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import {
  AutocompliteWithController,
  DatePikerWithController,
  TextFieldWithController,
  TimePikerWithController,
} from 'shared/fields-with-contoller';
import { SwitchWithController } from 'shared/fields-with-contoller/switch-with-controller';
import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';
import { FileListItem } from 'shared/ui/file-list-item';
import { UploadButton } from 'shared/ui/upload-button/UploadButton';
import { fileListToArrayBase64 } from 'shared/utils/files';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { getLabelNotificationTypesDepsField } from '../../libs/getLabelNotificationTypesDepsField';
import { ContainerRowTwoInputs } from '../../ui';
import { useFormPushNotificationsContext } from './hooks';
import { FormPushNotificationsProps } from './types';

const PADDING_LEFT = [1, 0];

export const FormPushNotifications: FC<FormPushNotificationsProps> = memo(
  ({ initialForm, onSubmit, isLoading = false, formId }) => {
    const {
      data: notificationTopicsOptions,
      isLoading: isLoadingNotificationTopicsOptions,
    } = useGetFirebaseTopicsQuery();
    const {
      data: notificationTypesOptions,
      isLoading: isLoadingNotificationTypesOptions,
    } = useGetFirebaseNotificationTypesQuery();

    const { control, setValue, handleSubmit, reset } =
      useFormPushNotificationsContext();

    useEffect(() => {
      if (initialForm) {
        reset(initialForm);
      }
    }, [initialForm]);

    const recipientsFrom = useWatch({ control, name: 'recipientsFrom' });
    const isSaveForUser = useWatch({ control, name: 'isSaveForUser' });
    const fileData = useWatch({ control, name: 'fileData' });
    const fileUrl = useWatch({ control, name: 'fileUrl' });
    const imageData = useWatch({ control, name: 'imageData' });
    const imageUrl = useWatch({ control, name: 'imageUrl' });
    const isSendingNow = useWatch({ control, name: 'isSendingNow' });
    const topics = useWatch({ control, name: 'topics' });
    const notificationType = useWatch({ control, name: 'notificationType' });

    const isSkipCalculateUserTargetOfTopics = topics.length === 0;
    const isSkipCalculateUserTargetOfFiles = !fileData;

    const isSkipCalculateUserTargetCount =
      recipientsFrom === 'file'
        ? isSkipCalculateUserTargetOfFiles
        : isSkipCalculateUserTargetOfTopics;

    const calculateUserTargetCountParams =
      recipientsFrom === 'file'
        ? { fileData: fileData?.data, topicGuids: [] }
        : { topicGuids: topics.map((item) => item.topicGuid) };

    const { data: userTargetCount, isFetching: isFetchingUserTargetCount } =
      useGetFirebaseDistributionsUserCountQuery(
        { params: calculateUserTargetCountParams },
        {
          skip:
            isSkipCalculateUserTargetCount || !calculateUserTargetCountParams,
        }
      );

    /* handler input IMAGE */
    const onChangeInputImage = useCallback(
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
          const formFiles = await fileListToArrayBase64(e.currentTarget.files);

          setValue('imageData', formFiles[0]);
          setValue('imageUrl', null);
          setValue('imageAction', CoreSystemEnumsObjectActions.Insert);
        }
      },
      [setValue]
    );

    const onDeleteInputImage = useCallback(() => {
      setValue('imageData', null);
      setValue('imageAction', CoreSystemEnumsObjectActions.Delete);
    }, [setValue]);

    const onDeleteImageUrl = useCallback(() => {
      setValue('imageUrl', null);
      setValue('imageAction', CoreSystemEnumsObjectActions.Delete);
    }, [setValue]);

    /* handler input FILE */
    const onChangeInputFile = useCallback(
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
          const formFiles = await fileListToArrayBase64(e.currentTarget.files);

          setValue('fileData', formFiles[0]);
          setValue('fileAction', CoreSystemEnumsObjectActions.Insert);
          setValue('fileUrl', null);
        }
      },
      [setValue]
    );

    const onDeleteInputFile = useCallback(() => {
      setValue('fileData', null);
      setValue('fileAction', CoreSystemEnumsObjectActions.Delete);
    }, [setValue]);

    const onDeleteFileUrl = useCallback(() => {
      setValue('fileUrl', null);
      setValue('fileAction', CoreSystemEnumsObjectActions.Delete);
    }, [setValue]);

    return (
      <Grid
        gap={3}
        display={'flex'}
        flexDirection={'column'}
        component={'form'}
        id={formId}
        onSubmit={handleSubmit(onSubmit, (invalid) => {
          console.error(invalid);
        })}
      >
        {/* ================================'Основная информация'=================================== */}
        <DocumentSectionTitle
          sx={SX_DISABLED_MARGIN}
          text='Основная информация'
        />
        <TextFieldWithController
          control={control}
          disabled={isLoading}
          name='distributionTitle'
          label='Заголовок'
        />
        <TextFieldWithController
          control={control}
          disabled={isLoading}
          name='message'
          multiline
          minRows={4}
          maxRows={8}
          label='Текст уведомления'
        />
        <TextFieldWithController
          control={control}
          disabled={isLoading}
          name='url'
          label='Ссылка'
        />
        {/* image */}

        <UploadButton
          disabled={isLoading}
          sx={{ width: 'fit-content', marginLeft: PADDING_LEFT }}
          color='customGrey'
          variant='outlined'
          startIcon={<AttachFile />}
          accept='image/*'
          id='imageBody'
          onChangeInput={onChangeInputImage}
        >
          Прикрепить
        </UploadButton>

        {imageData && (
          <FileListItem
            disableDownload
            fileName={imageData.title}
            onDelete={onDeleteInputImage}
          />
        )}
        {imageUrl && (
          <FileListItem
            fileName={imageUrl}
            onDelete={onDeleteImageUrl}
          />
        )}

        {/* ========================================================================================= */}
        {/* ================================'Получатели'============================================= */}
        <DocumentSectionTitle
          sx={SX_DISABLED_MARGIN}
          text='Получатели'
        />
        <Controller
          control={control}
          name={'recipientsFrom'}
          render={({ field: { onChange, value: formValue } }) => {
            return (
              <ToggleButtonGroup
                value={formValue}
                disabled={isLoading}
                fullWidth
                size='small'
                exclusive
                onChange={(e, recipentsFrom) => {
                  recipentsFrom && onChange(recipentsFrom);
                }}
              >
                {VARIANT_RECIPIENTS_FROM.map(({ value, title }) => {
                  return (
                    <ToggleButton
                      disabled={isLoading}
                      fullWidth
                      color='customGrey'
                      key={value}
                      value={value}
                    >
                      {title}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            );
          }}
        />

        {/* topics */}
        {recipientsFrom === 'topics' && (
          <AutocompliteWithController
            control={control}
            disabled={isLoading || isLoadingNotificationTopicsOptions}
            name='topics'
            loading={isLoadingNotificationTopicsOptions}
            options={notificationTopicsOptions || EMPT_ARR}
            multiple
            textFieldProps={{
              label: 'Темы',
            }}
            isOptionEqualToValue={isOptionEqualToValueByFirebaseTopic}
            getOptionLabel={getOptionLabelByFirebaseTopic}
            renderOption={renderOptionByFirebaseTopic}
          />
        )}
        {/* files */}
        {recipientsFrom === 'file' && (
          <>
            {!fileData && (
              <Controller
                control={control}
                name='fileData'
                render={({ fieldState: { error } }) => {
                  return (
                    <>
                      <UploadButton
                        disabled={isLoading}
                        fullWidth={false}
                        sx={{ width: 'fit-content', marginLeft: PADDING_LEFT }}
                        color='customGrey'
                        variant='outlined'
                        startIcon={<AttachFile />}
                        accept='.txt'
                        id='recipientsFromFiles'
                        onChangeInput={onChangeInputFile}
                      >
                        Прикрепить
                      </UploadButton>
                      {error && (
                        <Typography
                          fontSize={'small'}
                          color={'error'}
                        >
                          {error.message}
                        </Typography>
                      )}
                    </>
                  );
                }}
              />
            )}
            {fileData && (
              <FileListItem
                disableDownload
                fileName={fileData.title}
                onDelete={onDeleteInputFile}
              />
            )}
            {fileUrl && (
              <FileListItem
                fileName={fileUrl}
                onDelete={onDeleteFileUrl}
              />
            )}
          </>
        )}

        <Typography
          fontWeight={'bold'}
          sx={{ paddingLeft: PADDING_LEFT }}
        >{`Количество получателей: ${
          isFetchingUserTargetCount
            ? 'загрузка'
            : !isNaN(Number(userTargetCount))
            ? userTargetCount
            : '-'
        }`}</Typography>

        {/* ========================================================================================= */}
        {/* ================================'Дата и время отправки'================================== */}
        <DocumentSectionTitle
          sx={SX_DISABLED_MARGIN}
          text='Дата и время отправки'
        />
        <SwitchWithController
          control={control}
          name={'isSendingNow'}
          disabled={isLoading}
          size='medium'
          formControlLabelProps={{
            label: 'Отправить сейчас',
            sx: {
              paddingLeft: PADDING_LEFT,
            },
          }}
        />
        {!isSendingNow && (
          <ContainerRowTwoInputs>
            <DatePikerWithController
              fullWidth
              disabled={isSendingNow || isLoading}
              control={control}
              name={'dateTimeSending'}
              label={'Дата отправки'}
            />

            <TimePikerWithController
              fullWidth
              disabled={isSendingNow || isLoading}
              control={control}
              name={'dateTimeSending'}
              label={'Время отправки'}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
          </ContainerRowTwoInputs>
        )}
        {/* ========================================================================================= */}
        {/* ================================'Другие настройки'======================================= */}
        <DocumentSectionTitle
          sx={SX_DISABLED_MARGIN}
          text='Другие настройки'
        />
        <SwitchWithController
          disabled={isLoading}
          control={control}
          name={'isSaveForUser'}
          size='medium'
          formControlLabelProps={{
            label: 'Сохранить в профиле пользователя',
            sx: {
              paddingLeft: PADDING_LEFT,
            },
          }}
        />
        {isSaveForUser && (
          <ContainerRowTwoInputs>
            <AutocompliteWithController
              disabled={isLoading || isLoadingNotificationTypesOptions}
              control={control}
              loading={isLoadingNotificationTypesOptions}
              options={notificationTypesOptions || EMPT_ARR}
              name={'notificationType'}
              fullWidth
              getOptionLabel={getOptionLabelByFirebaseNotificationType}
              isOptionEqualToValue={
                isOptionEqualToValueByFirebaseNotificationType
              }
              renderOption={renderOptionByFirebaseNotificationType}
              textFieldProps={{ label: 'Тип уведомления' }}
            />

            <TextFieldWithController
              disabled={isLoading}
              control={control}
              name={'notificationTypeValue'}
              label={
                notificationType
                  ? getLabelNotificationTypesDepsField(notificationType)
                  : '-'
              }
            />
          </ContainerRowTwoInputs>
        )}

        {/* ========================================================================================= */}
      </Grid>
    );
  }
);
