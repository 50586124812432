import { yupResolver } from '@hookform/resolvers/yup';
import { FC, PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { DEFAULT_NOTIFICATION_CREATE_FORM_VALUE } from './constants';
import { NotificationCreateFormValue } from './types';
import { PUSH_NOTIFICATIONS_VALIDATION } from './validation';

export const FormPushNotificationsProvider: FC<
  PropsWithChildren<{ initialForm?: NotificationCreateFormValue }>
> = ({ children, initialForm }) => {
  const methods = useForm<NotificationCreateFormValue>({
    defaultValues: initialForm || DEFAULT_NOTIFICATION_CREATE_FORM_VALUE,
    resolver: yupResolver(PUSH_NOTIFICATIONS_VALIDATION),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
