import { FC, useMemo } from 'react';
import {
  AuthServiceResponsesPermissionWithIsLoading,
  ComponentWithUserPermissionProps,
} from './types';
import { useUserPermissions } from './useUserPermissions';

export const ComponentWithUserPermission: FC<
  ComponentWithUserPermissionProps
> = ({ children, moduleGuid }) => {
  const { permissions, isLoadingPermission } = useUserPermissions(moduleGuid);

  const memoPermission: AuthServiceResponsesPermissionWithIsLoading =
    useMemo(() => {
      if (!permissions[0]) {
        return {
          isDeletable: false,
          isLoadingPermission,
          isReadable: false,
          isWritable: false,
          moduleGuid: moduleGuid,
        };
      }

      return {
        ...permissions[0],
        isLoadingPermission,
      };
    }, [permissions, isLoadingPermission, moduleGuid]);

  return children(memoPermission);
};
