import { useMemo } from 'react';
import { useGetEmployeePermissionsQuery } from 'shared/api/services-auth/rtk/servicesUserProviderApi';

import { EMPT_ARR } from 'widgets/training/filter/constants';
import { useUserDataFetch } from './useUserDataFetch';

export const useUserPermissions = (moduleGuids?: string | string[]) => {
  const { user, loading } = useUserDataFetch();

  const { data: permissions, isLoading: isLoadingPermission } =
    useGetEmployeePermissionsQuery(undefined, {
      skip: loading || !user?.userGuid,
    });

  const permissionsValue = useMemo(() => {
    if (!permissions) return EMPT_ARR; // => Пустой массив

    if (typeof moduleGuids === 'string') {
      return permissions.filter(
        (permission) => permission.moduleGuid === moduleGuids
      );
    } else if (Array.isArray(moduleGuids)) {
      permissions.filter((permission) =>
        moduleGuids.includes(permission.moduleGuid)
      );
    }

    return permissions; // => Все permissions если не передан moduleGuids;
  }, [permissions, moduleGuids]);

  return {
    permissions: permissionsValue,
    isLoadingPermission: isLoadingPermission || loading,
  };
};
