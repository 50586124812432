import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { AdaptationTemplateTaskForm } from '../AdaptationTemplateTaskForm';
import { CreateAdaptationTemplateTaskDialogProps } from './type';

export const CreateAdaptationTemplateTaskDialog: FC<
  CreateAdaptationTemplateTaskDialogProps
> = ({ isOpen, onClose, onSubmit, isLoading }) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Создание шаблона задачи' />}
    >
      <AdaptationTemplateTaskForm
        onSubmit={onSubmit}
        disabled={isLoading}
        renderFormActions={() => [
          <LoadingButton
            type='submit'
            key={1}
            variant='contained'
            loading={isLoading}
          >
            Добавить
          </LoadingButton>,
          <Button
            onClick={onClose}
            key={2}
            variant='contained'
            color='customGrey'
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
