import { theme } from 'shared/theme/theme';

const palette = theme.palette;

export const COLUMN_COLORS = [
  palette.primary.main,
  '#FF595E',
  '#FF924C',
  '#FFCA3A',
  '#8AC926',
  '#1982C4',
  '#6A4C93',
];
