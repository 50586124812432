import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import {
  UiTableCard,
  UiTableCardList,
  UiTableCardPagination,
} from 'shared/ui/table';

import { VisitTableProps } from './type';

export const VisitsList: FC<VisitTableProps> = ({
  fields,
  onChangeFields,
  visibleFields,
  isLoading = false,
  count,
  data,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  renderActions,
  onClick,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading}
      pagination={
        <UiTableCardPagination
          count={count}
          onPageChange={onChangePage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowsPerPageChange={onChangeLimit}
        />
      }
    >
      {data.map((visit) => (
        <UiTableCard
          data={visit}
          id={String(visit.id)}
          visibleFields={visibleFields}
          key={visit.id}
          onClick={() => {
            onClick && onClick(visit);
          }}
          renderActions={(isOpen, setIsLoading) => {
            return renderActions(visit, { isOpen, setIsLoading });
          }}
          paperProps={{
            sx: visit.isCurrentEmployee
              ? {
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: theme.palette.primary.main,
                }
              : undefined,
          }}
        />
      ))}
    </UiTableCardList>
  );
};
