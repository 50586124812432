import { createSvgIcon } from '@mui/material';

export const PushNotificationSvg = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 45 52'
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M32.845 4.095C32.832 1.843 30.83.013 28.343 0H4.488C2.013 0 0 1.843 0 4.095v43.81C0 50.17 2.013 52 4.489 52h23.854c2.488 0 4.49-1.83 4.502-4.095v-6.129c-.885.141-1.783.218-2.693.218v3.122H2.68V8.048h27.472v1.958c.91 0 1.808.077 2.693.217V4.095zm-2.693 7.638c7.9 0 14.3 6.385 14.3 14.267s-6.4 14.266-14.3 14.266-14.3-6.384-14.3-14.266 6.4-14.267 14.3-14.267zM8.836 3.391c0-.486.398-.87.873-.87.474 0 .859.384.859.87 0 .307.128.588.346.793.205.218.487.346.795.346v-.013h9.414v.013c.307 0 .602-.128.808-.346.012-.012.025-.012.025-.025.193-.205.308-.474.308-.768 0-.486.385-.87.872-.87.475 0 .86.384.86.87 0 .755-.296 1.458-.783 1.97-.026.013-.039.039-.051.051a2.926 2.926 0 01-2.04.845H11.71a2.928 2.928 0 01-2.026-.845 2.852 2.852 0 01-.847-2.021zm6.708 46.037a.86.86 0 01-.86-.87c0-.474.385-.857.86-.857h1.744a.864.864 0 110 1.727h-1.744zm14.608-32.986c3.052 0 5.54 2.495 5.54 5.54v7.985l2.014 2.533H22.61l2-2.533v-7.985c0-3.045 2.488-5.54 5.54-5.54zm1.936 17.798a2.086 2.086 0 01-1.936 1.318 2.1 2.1 0 01-1.937-1.318h3.873z'
      clipRule='evenodd'
    ></path>
  </svg>,
  'PushNotificationSvg'
);
