import { Typography } from '@mui/material';

import { PROTOCOL_STATUS_TITLE } from 'entities/retail-opening-process/statuses';
import { ALL_ROUTES } from 'shared/routes/constants/types';
import { Link } from 'shared/ui/base-ui';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import { ProtocolOpeningRetailsItem } from '../types';

export const RUNNING_PROTOCOLS_FIELD_MAP: FieldMapType<ProtocolOpeningRetailsItem>[] =
  [
    {
      field: 'title',
      title: 'Протокол',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 1,
      renderComponent: (value) => {
        return (
          <Link
            to={`/${
              ALL_ROUTES.retailOpeningProcess
            }/${ALL_ROUTES.retailOpeningProcessProtocolGuid.replace(
              ':guid',
              value.guid
            )}`}
          >
            {value.title}
          </Link>
        );
      },
    },
    {
      field: 'department',
      title: 'Сеть',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 2,
      renderComponent: (value) => {
        return <Typography>{value.department.title}</Typography>;
      },
    },
    {
      field: 'retail',
      title: 'Аптека',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 3,
      renderComponent: (value) => {
        return <Typography>{value.retail?.title || '-'}</Typography>;
      },
    },
    {
      field: 'countdownDate',
      title: 'Начать отсчет от',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 4,
      renderComponent: (value) => {
        return <Typography>{value.countdownDate || '-'}</Typography>;
      },
    },
    {
      field: 'openingProcesses',
      title: 'Запущенные процессы',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 5,
      renderComponent: (value) => {
        return <Typography>{value.openingProcesses.length}</Typography>;
      },
    },

    {
      field: 'protocolStatus',
      title: 'Статус',
      getTableCellProps: () => {
        return {};
      },
      isShow: true,
      order: 6,
      renderComponent: (value) => {
        return (
          <Typography>{PROTOCOL_STATUS_TITLE[value.protocolStatus]}</Typography>
        );
      },
    },
  ];
