import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { useGetOpeningTemplatesPositionsQuery } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import {
  AutocompliteWithController,
  TextFieldWithController,
} from 'shared/fields-with-contoller';

import { yupResolver } from '@hookform/resolvers/yup';
import { AttachFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useDownloadFileFromServiceFile } from 'shared/api/service-files/rtk/hooks/use-download-file-from-service-file';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { FileListItem } from 'shared/ui/file-list-item';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { UploadButton } from 'shared/ui/upload-button/UploadButton';
import { checkIsNotEmptyArray } from 'shared/utils/checkIsNotEmptyArray';
import { fileListToArrayBase64 } from 'shared/utils/files';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { DEFAULT_FORM_VALUE } from './constants';
import {
  ContainerTemplateTaskReminderRowInputs,
  HELPER_RENDER_PROPS_AUTOCOMPLITE_POSITIONS,
} from './libs';
import { sortPositions } from './libs/autocomplite-position';
import { TASK_TEMPLATE_VALIDATE_VARIANT_MIXED } from './sheme';
import { DialogFormTemplateTaskProps, FormTemplateTaskValue } from './types';

export const DialogFormTemplateTask: FC<DialogFormTemplateTaskProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoadingSubmit = false,
  initialValue,
  isLoadingInitialValue,
  countdownFieldTitle,
}) => {
  const { control, handleSubmit, reset } = useForm<FormTemplateTaskValue>({
    defaultValues: initialValue
      ? { ...DEFAULT_FORM_VALUE, ...initialValue }
      : DEFAULT_FORM_VALUE,
    resolver: yupResolver(TASK_TEMPLATE_VALIDATE_VARIANT_MIXED),
  });
  const [downloadFile] = useDownloadFileFromServiceFile();

  const { data: optionsPositions, isFetching: isFetchingOptionsPositions } =
    useGetOpeningTemplatesPositionsQuery(undefined, {
      skip: !isOpen,
      selectFromResult: ({ data, ...oter }) => {
        if (!data) {
          return {
            data: EMPT_ARR,
            ...oter,
          };
        }

        return {
          data: sortPositions(data),
          ...oter,
        };
      },
    });

  const transformCountdownFieldTitle = countdownFieldTitle.replace(
    'Дата',
    'Даты'
  );

  const {
    fields: initialFiles,
    remove: removeFilesFromInitial,
    append: returFileToInitialFiles,
  } = useFieldArray<FormTemplateTaskValue, 'files'>({ name: 'files', control });

  const {
    fields: deletingFiles,
    append: appendFilesDeleteFromBackend,
    remove: removeFromDeletingFiles,
  } = useFieldArray<FormTemplateTaskValue, 'deletingFiles'>({
    name: 'deletingFiles',
    control,
  });

  const {
    fields: addingFiles,
    remove: removeFromAddingFiles,
    append: appendAddingFiles,
  } = useFieldArray<FormTemplateTaskValue, 'addingFiles'>({
    name: 'addingFiles',
    control,
  });

  useEffect(() => {
    if (initialValue && isOpen) {
      reset({ ...DEFAULT_FORM_VALUE, ...initialValue });
    } else {
      reset(DEFAULT_FORM_VALUE);
    }
  }, [initialValue, isOpen]);

  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Шаблон задачи' />}
    >
      <Grid
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 2,
          paddingBottom: [15, 0],
        }}
      >
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextFieldWithController
            control={control}
            name={'title'}
            multiline
            maxRows={4}
            minRows={2}
            disabled={isLoadingInitialValue}
            label={'Название'}
          />
          <TextFieldWithController
            control={control}
            name={'description'}
            disabled={isLoadingInitialValue}
            multiline
            maxRows={4}
            minRows={3}
            label={'Описание'}
          />

          <TextFieldWithController
            control={control}
            name={'startDaysCount'}
            disabled={isLoadingInitialValue}
            label={`Дата начала (кол-во дней от ${transformCountdownFieldTitle})`}
          />
          <TextFieldWithController
            control={control}
            name={'finishDaysCount'}
            disabled={isLoadingInitialValue}
            label={`Дата завершения (кол-во дней от ${transformCountdownFieldTitle})`}
          />

          <AutocompliteWithController
            control={control}
            name='creatorPosition'
            options={optionsPositions}
            loading={isFetchingOptionsPositions || isLoadingInitialValue}
            disabled={isFetchingOptionsPositions || isLoadingInitialValue}
            textFieldProps={{
              label: 'Постановщик (Должность)',
            }}
            {...HELPER_RENDER_PROPS_AUTOCOMPLITE_POSITIONS}
          />
          <AutocompliteWithController
            control={control}
            name='executorPosition'
            options={optionsPositions}
            loading={isFetchingOptionsPositions || isLoadingInitialValue}
            disabled={isFetchingOptionsPositions || isLoadingInitialValue}
            textFieldProps={{
              label: 'Исполнитель (Должность)',
            }}
            {...HELPER_RENDER_PROPS_AUTOCOMPLITE_POSITIONS}
          />
          <AutocompliteWithController
            control={control}
            multiple
            name='coExecutorsPositions'
            options={optionsPositions}
            loading={isFetchingOptionsPositions || isLoadingInitialValue}
            disabled={isFetchingOptionsPositions || isLoadingInitialValue}
            textFieldProps={{
              label: 'Соисполнители (Должности)',
            }}
            {...HELPER_RENDER_PROPS_AUTOCOMPLITE_POSITIONS}
          />
          <AutocompliteWithController
            control={control}
            multiple
            name='observersPositions'
            options={optionsPositions}
            loading={isFetchingOptionsPositions || isLoadingInitialValue}
            disabled={isFetchingOptionsPositions || isLoadingInitialValue}
            textFieldProps={{
              label: 'Наблюдатели (Должности)',
            }}
            {...HELPER_RENDER_PROPS_AUTOCOMPLITE_POSITIONS}
          />

          {/* isReminderCreator /  reminderPeriodCreator*/}
          <ContainerTemplateTaskReminderRowInputs
            control={control}
            SwitchProps={{
              name: 'isReminderCreator',
              label: 'Напомнить постановщику',
            }}
            TextFieldProps={{
              name: 'reminderPeriodCreator',
              label: 'Дней до даты завершения',
            }}
          />
          {/* isReminderCoExecutor /  reminderPeriodCoExecutor*/}
          <ContainerTemplateTaskReminderRowInputs
            control={control}
            SwitchProps={{
              name: 'isReminderCoExecutor',
              label: 'Напомнить соисполнителю',
            }}
            TextFieldProps={{
              name: 'reminderPeriodCoExecutor',
              label: 'Дней до даты завершения',
            }}
          />
          {/* isReminderExecutor /  reminderPeriodExecutor*/}
          <ContainerTemplateTaskReminderRowInputs
            control={control}
            SwitchProps={{
              name: 'isReminderExecutor',
              label: 'Напомнить исполнителю',
            }}
            TextFieldProps={{
              name: 'reminderPeriodExecutor',
              label: 'Дней до даты завершения',
            }}
          />

          {/* isReminderObserver /  reminderPeriodObserver*/}
          <ContainerTemplateTaskReminderRowInputs
            control={control}
            SwitchProps={{
              name: 'isReminderObserver',
              label: 'Напомнить наблюдателю',
            }}
            TextFieldProps={{
              name: 'reminderPeriodObserver',
              label: 'Дней до даты завершения',
            }}
          />
          <Controller
            control={control}
            name='addingFiles'
            render={() => {
              return (
                <UploadButton
                  variant='outlined'
                  startIcon={<AttachFile />}
                  id='upload-file-for-template-task'
                  onChangeInput={async (e) => {
                    const fileList = e.target.files;

                    if (fileList) {
                      const newFiles = await fileListToArrayBase64(fileList);

                      appendAddingFiles(newFiles);
                    }
                  }}
                >
                  Прикрепить
                </UploadButton>
              );
            }}
          />
          {checkIsNotEmptyArray(initialFiles) && (
            <Grid px={[1, 0]}>
              <Typography>Загруженные</Typography>
              <Divider sx={{ my: 1 }} />
              <Stack gap={1}>
                {initialFiles.map((file, index) => {
                  const { title, guid, path } = file;

                  return (
                    <FileListItem
                      key={guid}
                      fileName={title || 'not_file_name'}
                      onDelete={() => {
                        appendFilesDeleteFromBackend(file);
                        removeFilesFromInitial(index);
                      }}
                      onDownload={async () => {
                        downloadFile({ fileName: title, path });
                      }}
                    />
                  );
                })}
              </Stack>
            </Grid>
          )}
          {checkIsNotEmptyArray(deletingFiles) && (
            <Grid px={[1, 0]}>
              <Typography>Удалить</Typography>
              <Divider sx={{ my: 1 }} />
              <Stack gap={1}>
                {deletingFiles.map((file, index) => {
                  const { title, guid, path } = file;

                  return (
                    <FileListItem
                      key={guid}
                      fileName={title || 'not_file_name'}
                      variant='cancel'
                      onDelete={() => {
                        returFileToInitialFiles(file);
                        removeFromDeletingFiles(index);
                      }}
                      onDownload={async () => {
                        downloadFile({ fileName: title, path });
                      }}
                    />
                  );
                })}
              </Stack>
            </Grid>
          )}
          {checkIsNotEmptyArray(addingFiles) && (
            <Grid px={[1, 0]}>
              <Typography>Сохранить</Typography>
              <Divider sx={{ my: 1 }} />
              <Stack gap={1}>
                {addingFiles.map((file, index) => {
                  const { title } = file;

                  return (
                    <FileListItem
                      key={title}
                      fileName={title || 'not_file_name'}
                      disableDownload={true}
                      onDelete={() => {
                        removeFromAddingFiles(index);
                      }}
                    />
                  );
                })}
              </Stack>
            </Grid>
          )}
        </Grid>
        <ContainerActionsForm>
          <LoadingButton
            loading={isLoadingSubmit}
            disabled={isLoadingSubmit}
            variant='contained'
            type='submit'
          >
            {initialValue ? 'Сохранить' : 'Добавить'}
          </LoadingButton>
          <Button
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
