import { TextField } from '@mui/material';
import { FC } from 'react';

import { RetailModel } from 'shared/api/services-document-flow/types';
import { PropsAutocompliteDocumentForm } from 'shared/types/type';
import {
  CustomAutoCompliteAdaptive,
  renderOptionsCustomAutoCompliteAdaptive,
} from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

export const UiRetailAutocomplite: FC<
  PropsAutocompliteDocumentForm<RetailModel>
> = ({ value, options, onChange, required }) => {
  const optionsSort = options;
  const [isMobile] = useDevice();

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        sx: {
          width: '100%',
        },
        onChange,
        value: value,
        options: optionsSort,
        renderInput: (params) => (
          <TextField
            {...params}
            required={required}
            size='small'
            label={isMobile ? '' : 'Подразделение'}
            placeholder='Выберите подразделение'
            margin={'none'}
          />
        ),
        getOptionLabel: (option) => option.title || '',
        groupBy: (options) => options.groupTitle as string,
        renderOption: renderOptionsCustomAutoCompliteAdaptive,
      }}
      sx={{ flexBasis: ['100%'] }}
      label='Подразделение'
    />
  );
};
