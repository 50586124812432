import { useEffect, useState } from 'react';

import { getCompetitors } from 'shared/api/visit/services';
import { CompetitorGetListResponse } from 'shared/api/visit/types';

export const useGetCompetitors = (retailId?: number | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [competitors, setCompetitors] = useState<CompetitorGetListResponse[]>(
    []
  );

  const getMyCompetitors = async () => {
    if (retailId !== null) {
      setIsLoading(true);

      const { data, status } = await getCompetitors({ RetailId: retailId });

      if (status === 200 && data) setCompetitors(data);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMyCompetitors();
  }, [retailId]);

  return { competitors, isLoading };
};
