import { Dialog, DialogTitle } from '@mui/material';
import { FC, ReactNode } from 'react';

import { CustomBackdrop } from 'shared/ui/custom-backdrop';

interface ConfirmDialogProps {
  title: string;

  isOpen: boolean;
  onClose: () => void;

  actions: ReactNode;

  isLoading?: boolean;
}
export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  actions,
  isOpen,
  onClose,
  isLoading,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <CustomBackdrop isLoading={!!isLoading} />
      <DialogTitle
        fontWeight={700}
        fontSize={22}
      >
        {title}
      </DialogTitle>
      {actions}
    </Dialog>
  );
};
