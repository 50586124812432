import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { sample } from 'effector';
import { useStore, useStoreMap } from 'effector-react';
import { DocumentFormListAddiitionallyDate } from 'features/document/document-form-additionally-field-date/DocumentFormListAdditionallyDate';
import { DocumentFormListAdditionallyFieldDec } from 'features/document/document-form-additionally-field-dec/DocumentFormAdditionallyFieldDec';
import { DocumentFormListAdditionallyFieldInt } from 'features/document/document-form-additionally-field-int/DocumentFormAdditionallyFieldInt';
import { DocumentFormListAdditionalyFieldPeriod } from 'features/document/document-form-additionally-field-period/DocumentFormListAdditionallyFieldPeriod';
import { DocumentFormListAdditionalyFieldsString } from 'features/document/document-form-additionally-field-string/DocumentFormAdditionalyFieldString';
import { DocumentFormAgreeChain } from 'features/document/document-form-agree-chain/DocumentFormAgreeChain';
import { DocumentFormAlertPostDocument } from 'features/document/document-form-alert-error-post-document/DocumentFormAlertPostDocument';
import { DocumentFormAlertFirstValid } from 'features/document/document-form-alert-first-valid/DocumentFormAlertFirstValid';
import { DocumentFormArticlesAutocomplite } from 'features/document/document-form-articles-autocomplite';
import { DocumentFormComment } from 'features/document/document-form-comment/DocumentFormComment';
import { DocumentFormOrgAutocomplite } from 'features/document/document-form-oganizations-autocomplite';
import { DocumentFormProcessPostDocument } from 'features/document/document-form-process-post-document/DocumentFormProcessPostDocument';
import { ExpensesFormAlertSecondValid } from 'features/document/expenses-form-alert-error-second-valid/ExpensesFormAlertSecondValid';
import { ExpensesFormModal } from 'features/document/expenses-form-modal/ExpensesFormModal';
import { ExpensesFormModalButtonClose } from 'features/document/expenses-form-modal/ExpensesFormModalButtonClose';
import { modal } from 'features/document/expenses-form-modal/model';
import { ExpensesFormPeriodPicker } from 'features/document/expenses-form-period-picker/ExpensesFormPeriodPicker';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DocumentSectionTitle } from 'shared/ui/DocumentSectionTitle';
import { InputFileUi } from 'shared/ui/file-input-ui-DEPRECETED/FileInputUi';
import { UiDocumentFormFiles } from 'shared/ui/UiDocumentFormFiles';

import { ExpensesForm } from 'entities/document-form';
import { documentFormModel } from 'entities/document-form/model';
import { DocumentForm } from 'entities/document-form/ui/DocumentForm';

import dayjs from 'dayjs';
import {
  DocumentFormCloseTaxRatesAutocomplite,
  fetchCloseTaxRatesEditOptionsFx,
} from 'features/document/document-form-close-tax-rates-autocomplite/DocumentFormTaxRatesAutocomplite';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { CustomDatePicker, CustomTextField } from 'shared/ui/base-ui';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { SectionCounterParty } from 'widgets/document-create/counterparty/ui';
import {
  ListArticlesExpenseCardUi,
  prepareDocumentArticleSecondValidateResponseToArticlesView,
} from 'widgets/document-create/expenses-for-articles-viwe';
import { SectionReasonDocument } from 'widgets/document-create/reason-document/ui';
import { ExpensesFormListArticles } from 'widgets/expenses-form-article/ExpensesFormListArticles';
import { prepareDateToDateVal } from './DocumentEdit';

sample({
  clock: documentFormModel.process,
  target: documentFormModel.formEvent.initDocumentFormModel,
});

export const InvocePayment = () => {
  const { id } = useParams();

  documentFormModel.useInitFormModel(id);

  const processTitle = documentFormModel.useValueProcess('title');

  const onSubmit = (element: React.FormEvent<HTMLFormElement> | undefined) => {
    element?.preventDefault();
    documentFormModel.formEvent.postDocument();
  };
  const navigate = useNavigate();

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h1'
          fontWeight={700}
          fontSize={[18, 20]}
          width='100%'
        >
          {processTitle ? processTitle : '-'}
        </Typography>
      </LayoutPage.Bar>

      <DocumentForm onSubmit={onSubmit}>
        {/* MAIN INFORMATION */}
        <DocumentSectionTitle
          text={'Информация о документе'}
          sx={{ marginTop: [2, 4] }}
        />
        <DocumentFormOrgAutocomplite />
        <DocumentFormArticlesAutocomplite />

        {/* EXPENSES */}
        <SectionExpensesForArticles />
        <ExpensesFormModal>
          <ExpensesForm
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              documentFormModel.formEvent.onSecondValidation();
            }}
            error={<ExpensesFormAlertSecondValid />}
            bottomActions={[
              <Button
                size='large'
                variant='contained'
                type='submit'
                color={'secondary'}
                key={'saveButton'}
              >
                Сохранить
              </Button>,
              <ExpensesFormModalButtonClose key={'closeModalButton'} />,
            ]}
          >
            <ExpensesFormPeriodPicker />
            <ExpensesFormListArticles />
          </ExpensesForm>
        </ExpensesFormModal>

        {/* additionally fields */}
        <SectionDopInformation />

        {/* Counterparty */}
        <SectionCounterParty />

        {/* Документ - основание */}
        <SectionReasonDocument />

        {/* Закрывающий документ*/}
        <SectionDocumentClose />

        {/* Цепочка согласования */}
        <DocumentSectionTitle
          text={'Цепочка согласования'}
          sx={{ marginTop: [2, 4] }}
        />
        <DocumentFormAgreeChain />

        {/* Комментарии */}
        <DocumentSectionTitle
          text='Комментарии'
          sx={{ marginTop: [2, 4] }}
        />
        <DocumentFormComment />

        {/* ACTIONS */}
        <DocumentFormAlertPostDocument />

        {/* Progress post document */}
        <DocumentFormProcessPostDocument />

        <Grid
          container
          sx={{ marginTop: 4, marginBottom: 2 }}
          justifyContent='space-between'
        >
          <Button
            variant='contained'
            color='secondary'
            size='large'
            type='submit'
            sx={{
              flexBasis: ['100%', '32%'],
              borderRadius: ['0px', '8px'],
              marginBottom: [1, 0],
            }}
          >
            Отправить
          </Button>
          <Button
            variant='contained'
            color='customBlue'
            size='large'
            sx={{ flexBasis: ['50%', '32%'], borderRadius: ['0px', '8px'] }}
            onClick={() => documentFormModel.formEvent.postDocumentDraft()}
          >
            Сохранить черновик
          </Button>
          <Button
            variant='contained'
            color='customGrey'
            size='large'
            onClick={() => navigate('/document-flow/created-document')}
            sx={{ flexBasis: ['50%', '32%'], borderRadius: ['0px', '8px'] }}
          >
            Закрыть
          </Button>
        </Grid>
        <DocumentFormAlertFirstValid />
      </DocumentForm>
    </LayoutPage.Page>
  );
};

const SectionDocumentClose = () => {
  const reasonFiles = useStore(documentFormModel.formFilesClose);
  const link = useStoreMap(documentFormModel.form, (state) =>
    state && state.closeDocumentDiadokLink ? state.closeDocumentDiadokLink : ''
  );

  const checked = useStoreMap(documentFormModel.form, (state) => {
    if (state && state.isCloseDocumentDiadok) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (checked) {
      documentFormModel.formFilesResetEventClose();
    } else {
      documentFormModel.formEvent.setField({
        key: 'closeDocumentDiadokLink',
        value: undefined,
      });
    }
  }, [checked]);

  const isCloseDocumentRequired = documentFormModel.useValueProcess(
    'isCloseDocumentRequired'
  );

  const closeDocumentDate =
    documentFormModel.useValueDocumentFormModel('closeDocumentDate');

  /* close doc summ */
  const isCloseDocumentSumEquals = documentFormModel.useValueDocumentFormModel(
    'isCloseDocumentSumEquals'
  );
  const closeDocumentSum =
    documentFormModel.useValueDocumentFormModel('closeDocumentSum');

  const totalSum = useStoreMap(documentFormModel.secondValid, (secondValid) =>
    secondValid ? secondValid.totalSum || '' : ''
  );

  const closeDocumentSumValue = isCloseDocumentSumEquals
    ? totalSum
    : closeDocumentSum;

  const closeDocumentNumber = documentFormModel.useValueDocumentFormModel(
    'closeDocumentNumber'
  );

  if (!isCloseDocumentRequired) return null;

  return (
    <>
      <DocumentSectionTitle
        text={'Закрывающий документ'}
        sx={{ marginTop: [2, 4] }}
      />
      <Grid
        display={'flex'}
        flexDirection={['column', 'row']}
        flexBasis={'100%'}
        flexWrap={['nowrap', 'wrap']}
        paddingTop={2}
        justifyContent={'space-between'}
        gap={2}
      >
        <FormControlLabel
          sx={{ padding: '0px 8px', flexBasis: ['100%', '45%'], order: [1] }}
          control={
            <Switch
              checked={checked}
              onChange={(e) => {
                documentFormModel.formEvent.setField({
                  key: 'isCloseDocumentDiadok',
                  value: e.target.checked,
                });
              }}
            />
          }
          labelPlacement='end'
          label='Закрывающий документ в ЭДО'
        />

        <Grid
          display={'flex'}
          flexBasis={['100%', '45%']}
          justifyContent={'space-between'}
          order={[3, 2]}
          flexWrap={'wrap'}
        >
          <CustomTextField
            label={'№ документа'}
            value={closeDocumentNumber || null}
            containerSx={{ flexBasis: ['100%', '40%'] }}
            onChange={(e) => {
              documentFormModel.formEvent.setField({
                key: 'closeDocumentNumber',
                value: e.target.value,
              });
            }}
          />
          <CustomDatePicker
            label={'Дата документа'}
            containerSx={{ flexBasis: ['100%', '40%'] }}
            value={prepareDateToDateVal(closeDocumentDate)}
            sx={{ width: '100%' }}
            onChange={(value) => {
              documentFormModel.formEvent.setField({
                key: 'closeDocumentDate',
                value: dayjs(value).format(DATE_FORMATS.server),
              });
            }}
          />
        </Grid>

        {!checked && (
          <Box sx={{ flexBasis: ['100%', '45%'], order: [2, 3] }}>
            <UiDocumentFormFiles
              Input={
                <InputFileUi
                  onAddFile={documentFormModel.formFilesEventClose.addFile}
                  id={'fileClose'}
                />
              }
              List={
                <>
                  {reasonFiles.map((file) => {
                    return (
                      <Typography
                        sx={{
                          display: 'flex',
                          flexBasis: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        key={file.name + file.size}
                      >
                        {file.name}
                        <IconButton
                          color='error'
                          onClick={() =>
                            documentFormModel.formFilesEventClose.deleteFile(
                              file
                            )
                          }
                        >
                          <Delete />
                        </IconButton>
                      </Typography>
                    );
                  })}
                  {reasonFiles.length === 0 && (
                    <Typography sx={{ display: ['none', 'flex'] }}>
                      Наименование прикрепленного файла...
                    </Typography>
                  )}
                </>
              }
            />
          </Box>
        )}
        {checked && (
          <CustomTextField
            placeholder='Ссылка'
            containerSx={{ flexBasis: ['100%', '45%'], order: [2, 3] }}
            value={link || ''}
            onChange={(e) => {
              documentFormModel.formEvent.setField({
                key: 'closeDocumentDiadokLink',
                value: e.target.value,
              });
            }}
            label='Ссылка'
          />
        )}

        <FormControlLabel
          sx={{ padding: '0px 8px', flexBasis: ['100%'], order: [4] }}
          checked={Boolean(isCloseDocumentSumEquals)}
          label='Соответствует документу'
          onChange={(e, checked) =>
            documentFormModel.formEvent.setField({
              key: 'isCloseDocumentSumEquals',
              value: checked,
            })
          }
          control={<Switch />}
          labelPlacement='end'
        />
        <CustomTextField
          value={closeDocumentSumValue || ''}
          label={'Общая сумма'}
          onBlur={() => {
            closeDocumentSum &&
              fetchCloseTaxRatesEditOptionsFx({ Sum: closeDocumentSum });
          }}
          containerSx={{ flexBasis: ['100%', '45%'], order: [5] }}
          disabled={Boolean(isCloseDocumentSumEquals)}
          type='number'
          onChange={(e) => {
            documentFormModel.formEvent.setField({
              key: 'closeDocumentSum',
              value: e.target.value,
            });
          }}
        />
        <Grid
          flexBasis={['100%', '45%']}
          sx={{ order: [6] }}
        >
          <DocumentFormCloseTaxRatesAutocomplite />
        </Grid>
      </Grid>
    </>
  );
};

const SectionDopInformation = () => {
  const isManagerAgree = documentFormModel.useValueProcess('isManagerAgree');
  const isArrayAdditionalFields = useStoreMap(
    documentFormModel.processAdditionalFields,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.stringAdditionallyFields &&
        processAdditionalFields.stringAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );
  const isArrayAdditionalPeriod = useStoreMap(
    documentFormModel.processAdditionalFields,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.periodAdditionallyFields &&
        processAdditionalFields.periodAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );
  const isArrayAdditionalDate = useStoreMap(
    documentFormModel.processAdditionalFields,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.dateTimeAdditionallyFields &&
        processAdditionalFields.dateTimeAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );
  const isArrayAdditionalInt = useStoreMap(
    documentFormModel.processAdditionalFields,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.intAdditionallyFields &&
        processAdditionalFields.intAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );
  const isArrayAdditionalDec = useStoreMap(
    documentFormModel.processAdditionalFields,
    (processAdditionalFields) => {
      if (
        processAdditionalFields &&
        processAdditionalFields.decimalAdditionallyFields &&
        processAdditionalFields.decimalAdditionallyFields.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  );

  if (
    !isManagerAgree &&
    !isArrayAdditionalFields &&
    !isArrayAdditionalDate &&
    !isArrayAdditionalPeriod &&
    !isArrayAdditionalInt &&
    !isArrayAdditionalDec
  )
    return null;

  return (
    <Grid
      container
      justifyContent={'space-between'}
      gap={2}
    >
      <DocumentSectionTitle text={'Дополнительная информация'} />
      <DocumentFormListAdditionalyFieldsString />
      <DocumentFormListAdditionalyFieldPeriod />
      <DocumentFormListAddiitionallyDate />
      <DocumentFormListAdditionallyFieldInt />
      <DocumentFormListAdditionallyFieldDec />
    </Grid>
  );
};

const SectionExpensesForArticles = () => {
  const disabledButton = useStoreMap(documentFormModel.form, (state) => {
    if (state && state.articles) {
      return !(state.articles.length > 0);
    } else {
      return true;
    }
  });
  const responseSecondaryValidation = useStoreMap(
    documentFormModel.secondValid,
    (state) => state
  );

  return (
    <Grid
      container
      flexDirection={'column'}
    >
      <ListArticlesExpenseCardUi
        articles={prepareDocumentArticleSecondValidateResponseToArticlesView(
          responseSecondaryValidation?.documentArticles
        )}
        isDetailsVariant={false}
      />
      <Button
        disabled={disabledButton}
        variant='contained'
        color='secondary'
        size='large'
        sx={{ margin: '0 auto' }}
        onClick={() => modal.events.open()}
      >
        {responseSecondaryValidation?.documentArticles
          ? 'Редактировать'
          : 'Заполнить'}
      </Button>
    </Grid>
  );
};
