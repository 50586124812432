import { TextField, UseAutocompleteProps } from '@mui/material';
import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import {
  CustomAutoCompliteAdaptive,
  renderOptionsCustomAutoCompliteAdaptive,
} from '../custom-autocomplite-adaptive';

export const UiExpenseFormExpendetCondition: FC<{
  disabled: boolean;
  onChange: UseAutocompleteProps<
    { title?: string | undefined; id: number },
    undefined,
    undefined,
    undefined
  >['onChange'];
  value: UseAutocompleteProps<
    { title?: string | undefined; id: number },
    undefined,
    undefined,
    undefined
  >['value'];
  options: UseAutocompleteProps<
    { title?: string | undefined; id: number },
    undefined,
    undefined,
    undefined
  >['options'];
  getOptionsDisabled?: UseAutocompleteProps<
    { title?: string | undefined; id: number },
    undefined,
    undefined,
    undefined
  >['getOptionDisabled'];
}> = ({ disabled, onChange, value, options, getOptionsDisabled }) => {
  const [isMobile] = useDevice();

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        disabled: disabled,
        sx: {
          width: '100%',
        },
        getOptionDisabled: getOptionsDisabled,
        onChange,
        value,
        options,

        renderInput: (params) => (
          <TextField
            {...params}
            size='small'
            label={isMobile ? '' : 'Условие'}
            placeholder='Выберите условие'
            required={options && options.length > 0}
            margin={'normal'}
          />
        ),
        getOptionLabel: (option) => option.title || '-',
        renderOption: renderOptionsCustomAutoCompliteAdaptive,
      }}
      sx={{ flexBasis: ['100%', '45%'] }}
      label='Условие'
    />
  );
};
