import { Typography } from '@mui/material';
import { FC } from 'react';

export const DocumentStatusUi: FC<{ status?: string }> = ({ status }) => {
  return (
    <Typography sx={{ minWidth: 'max-content', flexGrow: [0, 1] }}>
      {status}
    </Typography>
  );
};
