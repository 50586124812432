import { Delete, Edit } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useConfirmDeleteOpeningTemplate } from 'features/retail-opeting-process/delete-opening-template';
import {
  DialogFormOpeningTemplates,
  useDialogFormCreateOpeningTemplates,
} from 'features/retail-opeting-process/form-opening-tamplate';
import { FC } from 'react';
import { RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';

import { theme } from 'shared/theme/theme';
import { DialogConfirmForm } from 'shared/ui/form';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';

export const ActionsOpeningTemplate: FC<{
  template: RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate;
}> = ({ template }) => {
  const {
    isOpen,
    onSubmit,
    open,
    close,
    initialValue,
    isLoadingInitialValue,
    isLoadingSubmit,
  } = useDialogFormCreateOpeningTemplates(template.guid);

  const {
    onCancelConfirmation,
    onConfirmDelete,
    onOpenDialogConfirm,
    isOpenConfirmDialog,
    isLoadingDeleteTemplate,
  } = useConfirmDeleteOpeningTemplate(template.guid);

  return (
    <UiTableActions>
      <UiButtonAction
        text='Изменить'
        onClick={open}
        color={theme.palette.customBlue.main}
        icon={<Edit />}
      />

      <UiButtonAction
        text='Удалить'
        onClick={onOpenDialogConfirm}
        icon={<Delete />}
        color={theme.palette.error.main}
      />

      <DialogFormOpeningTemplates
        variant='edit'
        isLoadingInitialValue={isLoadingInitialValue}
        initialValue={initialValue}
        isLoadingSubmit={isLoadingSubmit}
        isOpen={isOpen}
        onClose={close}
        onSumbit={onSubmit}
      />
      <DialogConfirmForm
        title='Удалить шаблон'
        open={isOpenConfirmDialog}
        onClose={onCancelConfirmation}
        isLoading={isLoadingDeleteTemplate}
        onConfirmation={onConfirmDelete}
      >
        <Typography>{`Вы уверены что хотите удалить шаблон ${template.title}?`}</Typography>
      </DialogConfirmForm>
    </UiTableActions>
  );
};
