import { VisitingPlanningUpdateRequest } from 'shared/api/visit/types';

import { EditPlanningRNFormFields } from '../type';

export const convertEditPlanningRNFormDataToFetch = (
  form: EditPlanningRNFormFields
): VisitingPlanningUpdateRequest => {
  return {
    departmentId: form.departmentId,
    periodStart: form.periodStart,
    periodFinish: form.periodFinish,
    visitorId: form.visitorId,
    homiesIds: form.homiesIds || [],
  };
};
