// eslint-disable-next-line budapestian/global-constant-pattern
import { NavigationCard } from './NavigationCard';
import { NavigationCardContainer } from './NavigationCardContainer';
import { NavigationCardSceleton } from './NavigationCardSceleton';

export type { NavigationCardProps, NavigationCardPropsBaseUi } from './types';

export const NavigationCardStack = {
  Card: NavigationCard,
  Container: NavigationCardContainer,
  CardSkeleton: NavigationCardSceleton,
};
