// eslint-disable-next-line budapestian/global-constant-pattern
import { FormProvider } from 'react-hook-form';
import { DocumentFlowBaseInputsFormFilterModal } from './document-flow-base-inputs-form-filter-modal';
import { DocumentFlowContainerBaseFormFilterModal } from './document-flow-container-base-form-filter-modal';

export const DocumentFlowBaseFormFilterModal = {
  Provider: FormProvider,
  BaseInputs: DocumentFlowBaseInputsFormFilterModal,
  Container: DocumentFlowContainerBaseFormFilterModal,
};
