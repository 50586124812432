import { useCallback } from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { RetailOpeningProcessResponsesOpeningTemplateGetListStage } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { useUpdateOpeningTemplatesStagesMutation } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { reorder } from 'shared/dnd';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useUpdateTemplateStagesDnD = (params: {
  templateGuid: string | null;
  stages: RetailOpeningProcessResponsesOpeningTemplateGetListStage[];
}) => {
  /* update stage */
  const { open: openSnackBar } = useSnackbar();
  const { templateGuid, stages } = params;

  const [updateStages, { isLoading: isLoadingUpdatesOrderStages }] =
    useUpdateOpeningTemplatesStagesMutation();

  const handleDragEnd: OnDragEndResponder = useCallback(
    async (result) => {
      if (result.source && result.destination && templateGuid) {
        const newArray = reorder(
          stages,
          result.source.index,
          result.destination?.index
        );

        const isEqualOrderItem =
          JSON.stringify(newArray.map((item) => item.guid)) ===
          JSON.stringify(stages.map((item) => item.guid));

        if (!isEqualOrderItem) {
          updateStages({
            templateGuid,
            body: {
              stages: newArray.map((item, index) => ({
                ...item,
                stageNumber: index + 1,
              })),
            },
          }).then((response) => {
            if ('error' in response) {
              openSnackBar({
                text: getMessageFromFetchBaseQueryError(response.error),
                type: 'error',
                duration: 3000,
              });
            }
          });
        }
      }
    },
    [stages, templateGuid]
  );

  return { isLoadingUpdatesOrderStages, handleDragEnd };
};
