import { FC } from 'react';
import { UiTableCard, UiTableCardList } from 'shared/ui/table';
import { AdaptationStageTasksTableProps } from './type';

export const AdaptationStageTasksList: FC<AdaptationStageTasksTableProps> = ({
  data: tasks,
  isLoading = false,
  onClick,
  renderActions,
  fields,
  onChangeFields,
  visibleFields,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading}
    >
      {tasks.map((task) => (
        <UiTableCard
          data={task}
          id={task.guid}
          visibleFields={visibleFields}
          key={task.guid}
          onClick={() => {
            onClick?.(task);
          }}
          renderActions={(isOpen, setIsLoading) => {
            return renderActions(task, { isOpen, setIsLoading });
          }}
        />
      ))}
    </UiTableCardList>
  );
};
