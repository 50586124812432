import { FC } from 'react';
import {
  UiTableCard,
  UiTableCardList,
  UiTableCardPagination,
} from 'shared/ui/table';
import { UserNoAdaptationProcessType } from './type';

export const UserNoAdaptationRequiredList: FC<UserNoAdaptationProcessType> = ({
  fields,
  onChangeFields,
  visibleFields,
  isLoading = false,
  count,
  data,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  renderActions,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading}
      pagination={
        <UiTableCardPagination
          count={count}
          onPageChange={onChangePage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowsPerPageChange={onChangeLimit}
        />
      }
    >
      {data.map((user) => (
        <UiTableCard
          data={user}
          id={String(user.guid)}
          visibleFields={visibleFields}
          key={user.guid}
          renderActions={(isOpen, setIsLoading) => {
            return renderActions(user, { isOpen, setIsLoading });
          }}
        />
      ))}
    </UiTableCardList>
  );
};
