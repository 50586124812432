import { FC } from 'react';
import { Container } from './Container';
import { SliderItem } from './SliderItem';
import { SliderListProps } from './types';

export const SliderList: FC<SliderListProps> = ({ items }) => {
  return (
    <Container>
      {items.map(({ guid, ...props }) => {
        return (
          <SliderItem
            key={guid}
            {...props}
            guid={guid}
          />
        );
      })}
    </Container>
  );
};
