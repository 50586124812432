import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import {
  useLazyGetDetailedAdaptationStageTaskQuery,
  useUpdateAdaptationStageTaskMutation,
} from 'shared/api/service-adaptation/rtk/adaptationStageTasksProviderApi';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { EditAdaptationTemplateTaskDialog } from '../forms/AdaptationTemplateTaskForm/Dialogs/EditAdaptationTemplateTaskDialog';
import { transformFromToAdaptationTemplateStageTaskUpdateFetch } from '../forms/AdaptationTemplateTaskForm/libs/transformFromToAdaptationTemplateStageTaskUpdateFetch';
import { AdaptationTemplateTaskFormFields } from '../forms/AdaptationTemplateTaskForm/type';

type InitFields = Partial<AdaptationTemplateTaskFormFields> & { guid: string };
export const useEditTemplateStageTask = (
  templateGuid: string,
  stageGuid: string
) => {
  const { open: openSnackbar } = useSnackbar();

  const { open, close, initValue, isOpen } =
    useViewModalWithInitFields<InitFields>();

  const [getDetailedTemplateTask, { isLoading }] =
    useLazyGetDetailedAdaptationStageTaskQuery();

  const [editTemplateTask, { isLoading: isEditingTemplateTask }] =
    useUpdateAdaptationStageTaskMutation();

  const openEditDialog = useCallback(
    (taskGuid: string) => {
      getDetailedTemplateTask({
        adaptationGuid: templateGuid,
        stageGuid,
        stageTaskGuid: taskGuid,
      }).then((res) => {
        if ('error' in res && res.error)
          openSnackbar({
            duration: 5000,
            type: 'error',
            title: getMessageFromFetchBaseQueryError(res.error),
          });
        else if (!res.data)
          openSnackbar({
            duration: 5000,
            type: 'error',
            title: 'Что-то пошло не так',
            text: 'Не удалось загрузить данные для формы шаблона задачи',
          });
        else open(res.data);
      });
    },
    [
      getDetailedTemplateTask,
      templateGuid,
      stageGuid,
      openSnackbar,
      close,
      open,
    ]
  );

  const handleEditTemplateTask = (form: AdaptationTemplateTaskFormFields) => {
    if (initValue) {
      editTemplateTask({
        adaptationGuid: templateGuid,
        stageGuid,
        stageTaskGuid: initValue.guid,
        data: transformFromToAdaptationTemplateStageTaskUpdateFetch(form),
      }).then((res) => {
        if ('error' in res)
          openSnackbar({
            duration: 5000,
            type: 'error',
            title: getMessageFromFetchBaseQueryError(res.error),
          });
        else close();
      });
    }
  };

  return {
    EditTemplateTaskDialog: (
      <>
        {isLoading ? (
          <CustomBackdrop isLoading={isLoading} />
        ) : (
          <EditAdaptationTemplateTaskDialog
            isOpen={isOpen}
            isLoading={isLoading || isEditingTemplateTask}
            initValue={initValue}
            onClose={close}
            onSubmit={handleEditTemplateTask}
          />
        )}
      </>
    ),
    openEditTemplateTaskDialog: openEditDialog,
    isEditingTemplateTask,
    isPreparingInitData: isLoading,
  };
};
