import { useMemo } from 'react';
import { FirebaseDistributionsPaginationListParams } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';
import { useURLState } from 'shared/url-helper/useURLState';

const convertorURLToDocumentActionFilter: URLToObjConverter<
  FirebaseDistributionsPaginationListParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_DOCUMENT_ACTION_LIST_FILTER_MAP
  ) as FirebaseDistributionsPaginationListParams;
};

const CONVERT_DOCUMENT_ACTION_LIST_FILTER_MAP: ConvertMapWithType<FirebaseDistributionsPaginationListParams> =
  [
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'Page', type: ConvertTypes.number },
  ];

const DEFAULT: FirebaseDistributionsPaginationListParams = {
  Limit: 20,
  Page: 1,
};

export const useUrlStatePushNotificationsFilter = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToDocumentActionFilter,
    true
  );
  const filterWithDefault = useMemo(() => {
    return Object.assign({ ...DEFAULT }, filter);
  }, [filter]);

  return {
    filter: filterWithDefault,
    setFilter,
  };
};
