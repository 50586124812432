import { useCallback } from 'react';
import { useDeleteAdaptationTemplateMutation } from 'shared/api/service-adaptation/rtk/adaptationTemplatesProviderApi';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useDeleteAdaptationTemplate = () => {
  const { open: openSnackbar } = useSnackbar();
  const openConfirm = useConfirm();

  const [deleteAdaptationTemplate, { isLoading: isDeleting }] =
    useDeleteAdaptationTemplateMutation();

  const deleteTemplate = useCallback(
    (templateGuid: string) => {
      openConfirm({
        actionTitle: 'Удаление шаблона',
        submitButtonTitle: 'Удалить',
        actionBody: 'Вы действительно хотите удалить шаблон?',
        buttonColor: 'error',
        onSubmit: async () => {
          const res = await deleteAdaptationTemplate({
            adaptationsGuids: [templateGuid],
          });

          if ('error' in res) return;

          openSnackbar({
            duration: 3000,
            type: 'success',
            title: 'Шаблон удалён',
          });
        },
      });
    },
    [openConfirm, openSnackbar]
  );

  return { deleteTemplate, isDeleting };
};
