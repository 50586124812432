import { NotificationType } from './types';

export const getTypeNotificaOnGuid = (
  guid: string
): NotificationType | null => {
  switch (guid) {
    case '7e113a1f-0aea-47cc-9801-01e50e035523':
      return 'podborka';
    case 'fa546997-d771-465f-8fed-b8c30ee2467d':
      return 'promocode';

    default:
      return null;
  }
};
