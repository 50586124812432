import { ObserveFilterItemModel } from 'shared/api/services-document-flow/types';
import { checkIntersectiOnNumberArray } from 'shared/utils/checkIntersectiOnNumberArray';
import { checkIsNotEmptyArray } from 'shared/utils/checkIsNotEmptyArray';
import { ObserveFilterItemModelWithMyKeys } from '../type';
import { observeFilterItemModelWithMyKeysToStringKeyArray } from './observeFilterItemModelWithMyKeysToStringKeyArray';
import { prepareObserveFilterItemModalWithMyKeys } from './prepareObserveFilterModalWithMyKeys';

export const createChecker = (array: ObserveFilterItemModel[]) => {
  const arrayKeys = observeFilterItemModelWithMyKeysToStringKeyArray(
    array.map(prepareObserveFilterItemModalWithMyKeys)
  );

  return (keys: string[]) => {
    if (checkIsNotEmptyArray(array)) {
      return checkIntersectiOnNumberArray(arrayKeys, keys);
    }

    return true;
  };
};

export const createFilterOnCheckers = (
  checkers: ((keys: string[]) => boolean)[]
) => {
  return (item: ObserveFilterItemModelWithMyKeys) => {
    const checkFlags = checkers.map((checker) => checker(item.parentMyKeys));

    return checkFlags.every((value) => value === true);
  };
};
