// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { FC } from 'react';

import {
  UiTableCard,
  UiTableCardList,
  UiTableCardPagination,
} from 'shared/ui/table';
import { ActionsOpeningTemplate } from './ActionsOpeningTemplate';
import { OpeningTemplatesListProps } from './types';

export const OpeningTemplatesList: FC<OpeningTemplatesListProps> = ({
  count,
  data,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  isUpdate,
  isLoading = false,
  onClick,
  fields,
  onChangeFields,
  visibleFields,
}) => {
  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading || isUpdate}
      pagination={
        <UiTableCardPagination
          count={count}
          onPageChange={onChangePage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowsPerPageChange={onChangeLimit}
        />
      }
    >
      {data.map((template) => (
        <UiTableCard
          data={template}
          id={String(template.guid)}
          visibleFields={visibleFields}
          key={template.guid}
          onClick={() => {
            onClick && onClick(template);
          }}
          renderActions={() => <ActionsOpeningTemplate template={template} />}
        />
      ))}
    </UiTableCardList>
  );
};
