import { SxProps, Theme } from '@mui/material';

export const DRAWER_WIDTH = 340;
export const DRAWER_WIDTH_CLOSED = 65;

export const LIST_ITEM_BUTTON_SX: SxProps<Theme> = {
  minHeight: 48,
  px: 2.5,
};
export const LIST_ITEM_ICON_SX: SxProps<Theme> = {
  minWidth: 0,
  mr: 2,
  justifyContent: 'center',
  color: 'inherit',
};
