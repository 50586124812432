import { getInitFormTaskData } from 'features/task/form-task/libs/getInitFormtaskData';
import { IFormCreatedTaskUiBase } from 'features/task/form-task/type';
import { FC } from 'react';

import { DATE_FORMATS } from 'shared/date-helper/constants';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

import { UpdateTaskDialog } from 'features/task/form-task/FormCreatedTaskUi';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useUpdateTaskMutation } from 'shared/api/services/rtk/taskApi';
import { GetTask, TaskUpdate } from 'shared/api/services/types';
import { getGuidsFromArray } from 'shared/tarnsform-helper/getGuidsFromArray';

export const prepareEditTaskFormDataToFetch = (
  form: IFormCreatedTaskUiBase,
  task: GetTask
): TaskUpdate => {
  return {
    // ...task,
    isReminderCoExecutor: task.isReminderCoExecutor,
    isReminderCreator: task.isReminderCreator,
    isReminderExecutor: task.isReminderExecutor,
    isReminderObserver: task.isReminderObserver,
    statusId: task.status.id,
    parentTaskGuid: task.parentGuid,
    reminderPeriodCoExecutor: task.reminderPeriodCoExecutor,
    reminderPeriodCreator: task.reminderPeriodCreator,
    reminderPeriodExecutor: task.reminderPeriodExecutor,
    reminderPeriodObserver: task.reminderPeriodObserver,

    title: form.title,
    description: form.description,
    executorGuid: form.executer ? form.executer.guid : task.executor?.guid,
    coExecutorsGuids: getGuidsFromArray(form.coExecuter),
    observersGuids: getGuidsFromArray(form.observers),
    dateStart: form.dateStart?.format(DATE_FORMATS.server) || undefined,
    dateFinish: form.dateFinish?.format(DATE_FORMATS.server) || undefined,
    units: form.units || undefined,
    isTarget: form.isTarget,
    isControl: form.isControl,
  };
};
export interface ModalEditTaskProps {
  isOpen: boolean;
  onClose: () => void;
  task: GetTask;
  onEdit?: (newTask: GetTask) => void;
}
export const ModalEditTask: FC<ModalEditTaskProps> = ({
  isOpen,
  onClose,
  task,
  onEdit,
}) => {
  const [edit, { isLoading, error }] = useUpdateTaskMutation();
  const { open: openSnackbar } = useSnackbar();

  useShowFetchErrorInSnackbar(error);

  const handleSubmit = async (form: IFormCreatedTaskUiBase) => {
    const toFetch = prepareEditTaskFormDataToFetch(form, task);

    edit({
      args: toFetch,
      taskGuid: task.guid,
    }).then((newTask) => {
      if ('data' in newTask) {
        if (newTask.data) onEdit && onEdit(newTask.data);

        openSnackbar({
          title: 'Изменения сохранены',
          type: 'success',
          duration: 3000,
        });

        onClose();
      }
    });
  };

  return (
    <UpdateTaskDialog
      initValue={getInitFormTaskData(task)}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};
