import { Warning } from '@mui/icons-material';
import { FC, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { AutocompliteWithController } from 'shared/fields-with-contoller';

import { useFormPreparedStagesTasksContext } from '../../hooks';
import {
  getOptionLabelEmployee,
  groupByPositions,
  isOptionEqualToValueGuid,
  renderOptionEmployee,
} from './helper-render-autocomplite';
import { EmployeeAutocompliteProps } from './types';

export const EmployeeAutocomplite: FC<EmployeeAutocompliteProps> = ({
  name,
  employeeOptions,
  isLoading,
  namePositions,
  label,
}) => {
  const { control } = useFormPreparedStagesTasksContext();

  const valueEmployee = useWatch({
    control,
    name: name,
  });
  const valuePositions = useWatch({
    control,
    name: namePositions,
  });

  const isEqualPositions =
    valueEmployee && valueEmployee.position.guid === valuePositions.guid;

  const startAdornment = useMemo(() => {
    if (!valueEmployee) return;
    if (!isEqualPositions) return <Warning color='warning' />;
    if (isEqualPositions) return <CheckCircleOutlineIcon color='primary' />;
  }, [isEqualPositions, valueEmployee]);

  return (
    <AutocompliteWithController
      control={control}
      name={name}
      loading={isLoading}
      options={employeeOptions}
      groupBy={groupByPositions}
      getOptionLabel={getOptionLabelEmployee}
      renderOption={renderOptionEmployee}
      isOptionEqualToValue={isOptionEqualToValueGuid}
      textFieldProps={{
        label,
        //TODO: раскомментировать после изменения темы
        //color: !isEqualPositions ? 'warning' : 'primary',
        helperText: !isEqualPositions
          ? `Рекомендуемая должность : "${valuePositions.title}"`
          : undefined,
        InputProps: {
          startAdornment,
        },
      }}
    />
  );
};
