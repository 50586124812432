import {
  CoreSystemEnumsObjectActions,
  OnlineStoreRequestsFirebaseEditDistribution,
} from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { NotificationCreateFormValue } from '../forms/form-push-notifications';

export const prepareFormNotificationToFetchBody = (
  form: NotificationCreateFormValue
): OnlineStoreRequestsFirebaseEditDistribution => {
  const {
    distributionTitle,
    dateTimeSending,
    fileAction,
    fileData,
    //fileUrl,
    imageData,
    isSaveForUser,
    isSendingNow,
    imageAction,
    //imageUrl,
    topics,
    message,
    url,
    recipientsFrom,
    notificationType,
    notificationTypeValue,
  } = form;

  const body: OnlineStoreRequestsFirebaseEditDistribution = {
    distributionTitle,
    message,
    url: url !== '' ? url : null,

    imageAction,

    topicGuids:
      recipientsFrom === 'topics'
        ? topics.map(({ topicGuid }) => topicGuid)
        : [],

    imageData:
      imageAction === CoreSystemEnumsObjectActions.Insert
        ? imageData?.data
        : null,
    /* file */
    fileAction,
    fileData:
      fileAction === CoreSystemEnumsObjectActions.Insert &&
      recipientsFrom === 'file'
        ? fileData?.data
        : null,
    /* save fo user */
    isSaveForUser,
    notificationTypeGuid: isSaveForUser
      ? notificationType?.notificationTypeGuid
      : null,
    notificationTypeValue: isSaveForUser ? notificationTypeValue : null,
    /* sending date  */
    dateTimeSending: isSendingNow ? null : dateTimeSending?.format(),
  };

  return body;
};
