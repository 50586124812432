import { AttachFile } from '@mui/icons-material';
import { Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';

import { UploadButton } from 'shared/ui/upload-button/UploadButton';

import { TextWithEndIconTooltip } from '../base-ui/text-with-end-icon-tooltip';
import { ImageUploaderPreviewProps } from './types';

export const ImageUploaderPreview: FC<ImageUploaderPreviewProps> = ({
  onChangeInput,
  imageData,
  imageUrl,
  textHeader,
  textHeaderTooltip,
  maxHeightContainerImage,
  maxWidthContainerImage,
  uploadButtonId,
  disabledButton,
  error,
  elevation = 0,
  borderRadius = 0,
  overflow,
}) => {
  const imageDesktopSrc =
    imageUrl && imageUrl !== ''
      ? imageUrl
      : (imageData && `data:image/png;base64,${imageData.data}`) || undefined;

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={1}
    >
      {textHeader && textHeaderTooltip ? (
        <TextWithEndIconTooltip
          text={textHeader}
          tooltipTitle={textHeaderTooltip}
        />
      ) : null}
      <UploadButton
        disabled={disabledButton}
        sx={{ width: 'fit-content' }}
        color='customGrey'
        variant='outlined'
        startIcon={<AttachFile />}
        accept='image/*'
        id={uploadButtonId}
        onChangeInput={onChangeInput}
      >
        {!imageDesktopSrc ? 'Прикрепить' : 'Заменить'}
      </UploadButton>

      {imageDesktopSrc && (
        <Grid
          component={Paper}
          sx={{ borderRadius, overflow }}
          elevation={elevation}
          maxHeight={maxHeightContainerImage}
          maxWidth={maxWidthContainerImage}
          position={'relative'}
        >
          <img
            src={imageDesktopSrc}
            style={{ objectFit: 'contain', width: '100%' }}
            height={'100%'}
            alt='data-image'
          />
        </Grid>
      )}
      {error && <Typography color={'error'}>{error}</Typography>}
    </Grid>
  );
};
