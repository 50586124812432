import { Typography } from '@mui/material';
import { FC, PropsWithChildren, useCallback } from 'react';

import { Link } from '../base-ui';
import { NavigationCardBaseUi } from './NavigationCardBaseUi';
import { NavigationCardProps } from './types';

export const NavigationCard: FC<NavigationCardProps> = ({
  to,
  title,
  Icon,
  disabledLink,
}) => {
  const Container: FC<PropsWithChildren> = useCallback(
    ({ children }) => {
      if (disabledLink) {
        return <>{children}</>;
      } else {
        return <Link to={to}>{children}</Link>;
      }
    },
    [disabledLink]
  );

  return (
    <Container>
      <NavigationCardBaseUi Icon={Icon}>
        <Typography
          title={title}
          display={'flex'}
          overflow={'hidden'}
          maxHeight={'4.5rem'}
        >
          {title}
        </Typography>
      </NavigationCardBaseUi>
    </Container>
  );
};
