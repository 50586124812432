import { creatRoutePathFromArray } from 'shared/routes';
import { ALL_ROUTES } from 'shared/routes/constants/types';

const BANNERS_BRAND = creatRoutePathFromArray([
  ALL_ROUTES.aptekaLegko,
  ALL_ROUTES.aptekaLegkoBanners,
  ALL_ROUTES.aptekaLegkoBannersBrand,
]);

export const APTEKALEGKO_BANNERS_BRANDS_HELPER_LINKS = {
  bannersMain: BANNERS_BRAND,
};
