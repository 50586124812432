import { Grid, Grow, LinearProgress } from '@mui/material';
import { FC } from 'react';

import { CustomLinerActivityIndicatorProps } from './type';

export const CustomLinerActivityIndicator: FC<
  CustomLinerActivityIndicatorProps
> = ({ isLoading, containerProps }) => {
  return (
    <Grid {...containerProps}>
      <Grow
        in={isLoading}
        timeout={500}
      >
        <LinearProgress sx={{ width: '100%' }} />
      </Grow>
    </Grid>
  );
};
