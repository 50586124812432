import { transformResponseError } from 'shared/api/libs';
import { adaptationApi } from '../adaptationApi';
import {
  AdaptationManagerRequestsAdaptationCreate,
  AdaptationManagerRequestsAdaptationUpdate,
  AdaptationManagerResponsesGetAdaptation,
  AdaptationManagerResponsesGetListAdaptation,
  AdaptationManagerResponsesGetListAdaptationEvent,
  AdaptationsDeleteParams,
  AdaptationsListParams,
  PaginatedAdaptationManagerResponsesGetListAdaptation,
} from '../adaptationAutogenerateApi';
import { adaptationProviderApi } from './adaptationProviderApi';

const adaptationTemplatesProviderApi = adaptationProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /** Получение списка шаблонов процессов адаптации */
    getAdaptationTemplatesList: build.query<
      PaginatedAdaptationManagerResponsesGetListAdaptation,
      AdaptationsListParams
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptations.AdaptationsList(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];

        return [
          'templates_list',
          ...res.items.map((el) => ({
            type: 'templates' as const,
            id: el.guid,
          })),
        ];
      },
    }),
    /* ======================================== */

    /** Получение подробной информации по шаблону адаптации */
    getAdaptationTemplate: build.query<
      AdaptationManagerResponsesGetAdaptation,
      string
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptations.AdaptationsDetail(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [{ type: 'templates', id: res.guid }];
      },
    }),
    /* ===================================================== */

    /** Создание шаблона адаптации */
    createAdaptationTemplate: build.mutation<
      AdaptationManagerResponsesGetListAdaptation,
      AdaptationManagerRequestsAdaptationCreate
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptations.AdaptationsCreate(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res, error) => {
        if (error) return [];
        return ['templates_list'];
      },
    }),
    /* =========================== */

    /** Удаление шаблонов процессов адаптации */
    deleteAdaptationTemplate: build.mutation<void, AdaptationsDeleteParams>({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.adaptations.AdaptationsDelete(args);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res, error) => {
        if (error) return [];
        return ['templates_list'];
      },
    }),
    /* ======================================== */

    /** Обновление информации по шаблону адаптации */
    updateAdaptationTemplate: build.mutation<
      AdaptationManagerResponsesGetListAdaptation,
      {
        adaptationGuid: string;
        data: AdaptationManagerRequestsAdaptationUpdate;
      }
    >({
      queryFn: async (args) => {
        try {
          const response = await adaptationApi.adaptations.AdaptationsUpdate(
            args.adaptationGuid,
            args.data
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res, err) => {
        if (!res || err) return [];

        return [{ type: 'templates', id: res.guid }];
      },
    }),
    /* ========================================== */

    /** Получение списка событий автозапуска */
    getAdaptationEvents: build.query<
      AdaptationManagerResponsesGetListAdaptationEvent[],
      void
    >({
      queryFn: async () => {
        try {
          const response =
            await adaptationApi.adaptationEvents.AdaptationEventsList();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: ['adaptation_events'],
    }),
    /* ======================================= */

    /** Получение списка шаблонов процессов адаптации */
    getAdaptationTemplates: build.query<
      AdaptationManagerResponsesGetListAdaptation[],
      void
    >({
      queryFn: async () => {
        try {
          const response =
            await adaptationApi.adaptations.AdaptationsNonPaginatedList();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [
          ...res.map((el) => ({ type: 'templates' as const, id: el.guid })),
          'templates_list',
        ];
      },
    }),
    /* ============================================== */
  }),
});

export const {
  useGetAdaptationTemplatesListQuery,
  useGetAdaptationTemplateQuery,
  useLazyGetAdaptationTemplateQuery,
  useGetAdaptationEventsQuery,
  useGetAdaptationTemplatesQuery,
  useCreateAdaptationTemplateMutation,
  useDeleteAdaptationTemplateMutation,
  useUpdateAdaptationTemplateMutation,
} = adaptationTemplatesProviderApi;
