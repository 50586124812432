import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';

import { SwaggerResponse } from 'shared/api/visit/config';
import {
  GetVisitingsActionQueryParams,
  GetVisitingsObserveQueryParams,
  VisitingGetListResponse,
  VisitingGetListResponsePaginationResponse,
} from 'shared/api/visit/types';

export type VisitingGetListResponseCount = {
  count: number;
  items: VisitingGetListResponse[];
};

export type useGetVisitsType = (
  getVisit: (
    queryParams?:
      | GetVisitingsActionQueryParams
      | GetVisitingsObserveQueryParams,
    configOverride?: AxiosRequestConfig
  ) => Promise<SwaggerResponse<VisitingGetListResponsePaginationResponse>>,
  filter:
    | undefined
    | GetVisitingsActionQueryParams
    | GetVisitingsObserveQueryParams
) => {
  count: number;
  visits: VisitingGetListResponse[];
  isLoading: boolean;
  update: () => void;
};

export const useGetVisits: useGetVisitsType = (getVisit, filter) => {
  const [count, setCount] = useState(-1);
  const [isLoading, setIsLoadig] = useState(false);

  const [visits, setVisits] = useState<VisitingGetListResponse[]>([]);

  const fetch = async () => {
    setIsLoadig(true);

    const { status, data } = await getVisit({
      ...filter,
      Page: filter && filter.Page ? filter.Page + 1 : 1,
    });

    setIsLoadig(false);

    if (status === 200 && data) return data;

    return null;
  };

  const getVisits = async () => {
    const data = (await fetch()) as VisitingGetListResponseCount | null;

    data && setCount(data.count);
    data && setVisits(data.items);
  };

  useEffect(() => {
    getVisits();
  }, [filter]);

  return {
    count,
    visits,
    isLoading,
    update: getVisits,
  };
};
