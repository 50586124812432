import { transformResponseError } from 'shared/api/libs';
import { getDocumentsDocumentIdDelegatedActions } from '../services';
import { GetListDelegatedAction } from '../types';
import { documentFlowProviderApi } from './documentFlowProviderApi';

export const documentFlowActionsApi = documentFlowProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /* Возвращает список действий, доступных для делегирования */
    getDocumentDelegateActions: build.query<
      GetListDelegatedAction[],
      { documentId: number }
    >({
      queryFn: async ({ documentId }) => {
        try {
          const response =
            await getDocumentsDocumentIdDelegatedActions(documentId);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
  }),
});
const getMockActions = (): GetListDelegatedAction[] => {
  return new Array(3).fill(0).map((item, index) => {
    return {
      id: index,
      title: `Действие  - ${index + 1}`,
    };
  });
};

export const { useGetDocumentDelegateActionsQuery } = documentFlowActionsApi;
