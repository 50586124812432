import FavoriteIcon from '@mui/icons-material/Star';
import FavoriteBorderIcon from '@mui/icons-material/StarBorder';
import { Grid, Rating, Typography } from '@mui/material';
import { FC } from 'react';

import { RatingItemProps } from './type';

export const RatingItem: FC<RatingItemProps> = ({
  title,
  value,
  onChange,
  invalid = false,
  isBold = false,
  disabled,
}) => {
  return (
    <Grid
      container
      flexDirection={'row'}
      width={['100%', '50%']}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Typography
        width={'50%'}
        fontWeight={isBold ? 700 : 400}
      >
        {title}
      </Typography>
      <Grid
        width={'50%'}
        container
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Rating
          disabled={disabled}
          icon={<FavoriteIcon fontSize='inherit' />}
          emptyIcon={
            <FavoriteBorderIcon
              fontSize='inherit'
              strokeWidth={1}
              stroke={invalid && value === null ? '#ff0000' : '#116E6B'}
            />
          }
          value={value}
          onChange={(e, newValue) => {
            onChange(newValue);
          }}
          max={4}
          sx={{
            color: '#116E6B',
          }}
        />
      </Grid>
    </Grid>
  );
};
