// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { createEffect, createEvent, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { getEmployeesOfficeAll } from 'shared/api/services-document-flow/services';
import {
  DocumentAgreeProcessActionResponse,
  EmployeeModel,
  ErrorResponse,
} from 'shared/api/services-document-flow/types';
import { UiDocumentFormAgreeChain } from 'shared/ui/UiDocumentFormAgreeChain';

import { documentFormEditApi } from '../api';
import { documentFormEdit } from '../model';

/* ---options--- */
const getEmployeesOfficeAllFx = createEffect<
  typeof getEmployeesOfficeAll,
  ErrorResponse
>(getEmployeesOfficeAll);

const init = createEvent();
const options = createStore<EmployeeModel[] | null>(null);

options.on(getEmployeesOfficeAllFx.doneData, (_, response) => response.data);
sample({ clock: init, target: getEmployeesOfficeAllFx });

const optionsEmployeesOfficeAll = {
  options: options,
  init,
};
/* -----end-options--- */

const setDocumentAgreeChain =
  createEvent<DocumentAgreeProcessActionResponse[]>();

export const $documentEditAgreeChain = createStore<
  DocumentAgreeProcessActionResponse[]
>([], {
  name: 'document-edit-agree-chain',
})
  .on(
    documentFormEditApi.secondValitation.doneData,
    (_, response) => response.data.agreeChain
  )
  .on(setDocumentAgreeChain, (_, agreeChain) => agreeChain)
  .on(
    documentFormEditApi.initialSecondaryValidation.doneData,
    (_, response) => response.data.agreeChain
  );

const $backendAgree = createStore<DocumentAgreeProcessActionResponse[]>([], {
  name: 'document-edit-agree-chain',
})
  .on(
    documentFormEditApi.secondValitation.doneData,
    (_, response) =>
      response.data.agreeChain?.filter((item) => item.isProcessAgreeChain)
  )
  .on(
    documentFormEditApi.initialSecondaryValidation.doneData,
    (_, response) =>
      response.data.agreeChain?.filter((item) => item.isProcessAgreeChain)
  );

const isAgreeResponse = createStore<boolean>(false)
  .on(documentFormEditApi.initialSecondaryValidation.doneData, () => true)
  .on(documentFormEditApi.secondValitation.doneData, () => true)
  .on(documentFormEditApi.initialSecondaryValidation.failData, () => true)
  .on(documentFormEditApi.secondValitation.failData, () => false);

export const DocumentFormEditAgreeChain = () => {
  const isCanEdit =
    documentFormEdit.formSelects.useSelectValueOfKey('canEditAgreeChain');
  const documentAgreeChain = useStore($documentEditAgreeChain);
  const isAgree = useStore(isAgreeResponse);
  const isManualAgree =
    documentFormEdit.formSelects.useSelectProcessValueOfKey('isManualAgree');
  const isExtendedAgree =
    documentFormEdit.formSelects.useSelectProcessValueOfKey('isExtendedAgree');
  const backendAgree = useStore($backendAgree);
  const options = useStore(optionsEmployeesOfficeAll.options);

  useEffect(() => {
    !options && optionsEmployeesOfficeAll.init();
  }, [options]);

  return (
    <UiDocumentFormAgreeChain
      disabled={Boolean(!isCanEdit)}
      initValue={documentAgreeChain}
      backendValue={backendAgree}
      isAgree={isAgree}
      isManualAgree={Boolean(isManualAgree)}
      isExtendedAgree={Boolean(isExtendedAgree)}
      options={options}
      setDocumentAgreeChain={(form) => {
        setDocumentAgreeChain(form);
      }}
    />
  );
};
