import dayjs from 'dayjs';
import { array, object, ObjectSchema } from 'yup';

import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';

import { EditPlanningRNFormFields } from '../type';

export const editPlanningRNFormSchema: ObjectSchema<EditPlanningRNFormFields> =
  object().shape({
    departmentId: SCHEMA.NUMBER.required(),
    periodFinish: SCHEMA.STRING_REQUIRED.when(
      'periodStart',
      ([periodStart], schema) => {
        return schema.test(
          'periodFinish',
          'Дата окончания не может быть раньше даты начала',
          (periodFinish) => {
            return (
              dayjs(periodFinish).isAfter(dayjs(periodStart)) ||
              dayjs(periodFinish).isSame(dayjs(periodStart))
            );
          }
        );
      }
    ),
    periodStart: SCHEMA.STRING_REQUIRED,
    visitorId: SCHEMA.NUMBER.required(),
    homiesIds: array(SCHEMA.NUMBER.required()).min(0).required(),
  });
