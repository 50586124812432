import { Button } from '@mui/material';
import { FC } from 'react';

import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import { VisitFilterForm } from 'widgets/visits/forms/visit-filter-form';

import { VisitFilterDialogProps } from './type';

export const VisitFilterDialog: FC<VisitFilterDialogProps> = ({
  isOpen,
  onClose,
  initValue,
  disableDates,
  onSubmit,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Фильтры' />}
    >
      <VisitFilterForm
        initValue={initValue}
        disabledFields={disableDates ? ['dateEnd', 'dateStart'] : undefined}
        actions={() => [
          <Button
            key={1}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
          <Button
            key={2}
            variant='contained'
            type='submit'
          >
            Применить
          </Button>,
        ]}
        onSubmit={onSubmit}
      />
    </DialogForForm>
  );
};
