import { array, mixed, object, ObjectSchema } from 'yup';

import {
  RetailOpeningProcessRequestsOpeningTemplateStakeTaskFileCreate,
  RetailOpeningProcessResponsesGetListPosition,
  RetailOpeningProcessResponsesOpeningTemplateGetListStageTaskFile,
} from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { FormTemplateTaskValue, numberForForm } from './types';

const MESSAGE_REQUIRED_FIELD = 'Это поле обязательно для заполнения';

const buildConditionalRequiredBuild = <T extends object | string | number>(
  keyObserver: string
) =>
  mixed<T>().when(keyObserver, ([observeField], schema) => {
    return schema.test(keyObserver, MESSAGE_REQUIRED_FIELD, (checkedField) => {
      if (!observeField) return true;
      if (observeField) {
        return Boolean(checkedField);
      }
    });
  });

const POSITION_MIXED =
  mixed<RetailOpeningProcessResponsesGetListPosition>().required(
    MESSAGE_REQUIRED_FIELD
  );
const FILE_ADDING_MIXED =
  mixed<RetailOpeningProcessRequestsOpeningTemplateStakeTaskFileCreate>().required(
    MESSAGE_REQUIRED_FIELD
  );
const FILES_MIXED =
  mixed<RetailOpeningProcessResponsesOpeningTemplateGetListStageTaskFile>().required(
    MESSAGE_REQUIRED_FIELD
  );

export const TASK_TEMPLATE_VALIDATE_VARIANT_MIXED: ObjectSchema<FormTemplateTaskValue> =
  object().shape({
    title: SCHEMA.STRING_REQUIRED,
    description: SCHEMA.STRING,
    startDaysCount: SCHEMA.NUMBER_POSITIVE.required(MESSAGE_REQUIRED_FIELD),
    finishDaysCount: SCHEMA.NUMBER_POSITIVE.required(
      MESSAGE_REQUIRED_FIELD
    ).when('startDaysCount', ([startDaysCount], scheme) => {
      return scheme.test(
        'startDaysCount',
        '"Дата завершения" должна быть больше или равна "Дате начала"',
        (finishDaysCount) => {
          if (
            finishDaysCount > startDaysCount ||
            finishDaysCount === startDaysCount
          ) {
            return true;
          }

          return false;
        }
      );
    }),
    creatorPosition: POSITION_MIXED,
    executorPosition: POSITION_MIXED,
    coExecutorsPositions: array(POSITION_MIXED).required(
      MESSAGE_REQUIRED_FIELD
    ),
    observersPositions: array(POSITION_MIXED).required(MESSAGE_REQUIRED_FIELD),
    isReminderCreator: SCHEMA.BOOLEAN,
    isReminderExecutor: SCHEMA.BOOLEAN,
    isReminderCoExecutor: SCHEMA.BOOLEAN,
    isReminderObserver: SCHEMA.BOOLEAN,
    reminderPeriodCreator: buildConditionalRequiredBuild<numberForForm>(
      'isReminderCreator'
    ).required(MESSAGE_REQUIRED_FIELD),
    reminderPeriodExecutor: buildConditionalRequiredBuild<numberForForm>(
      'isReminderExecutor'
    ).required(MESSAGE_REQUIRED_FIELD),
    reminderPeriodCoExecutor: buildConditionalRequiredBuild<numberForForm>(
      'isReminderCoExecutor'
    ).required(MESSAGE_REQUIRED_FIELD),
    reminderPeriodObserver: buildConditionalRequiredBuild<numberForForm>(
      'isReminderObserver'
    ).required(MESSAGE_REQUIRED_FIELD),
    addingFiles: array(FILE_ADDING_MIXED)
      .required(MESSAGE_REQUIRED_FIELD)
      .nullable(),
    deletingFiles: array(FILES_MIXED)
      .required(MESSAGE_REQUIRED_FIELD)
      .nullable(),
    files: array(FILES_MIXED).required(MESSAGE_REQUIRED_FIELD).nullable(),
  });
