import {
  RetailOpeningProcessResponsesOpeningTemplateGetListStageTask,
  RetailOpeningProcessResponsesOpeningTemplateGetStageTask,
} from '../retailOpeningProcessAutogenerateApi';

export const prepateTemplateTaskToTemplateTaskTistTtem = (
  templateTask: RetailOpeningProcessResponsesOpeningTemplateGetStageTask
): RetailOpeningProcessResponsesOpeningTemplateGetListStageTask => {
  const {
    guid,
    title,
    creatorPosition,
    executorPosition,
    finishDaysCount,
    startDaysCount,
  } = templateTask;

  return {
    guid,
    title,
    creatorPositionTitle: creatorPosition.title,
    executorPositionTitle: executorPosition.title,
    finishDaysCount,
    startDaysCount,
  };
};
