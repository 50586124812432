export const ChangeStatusSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23'
      height='23'
      fill='none'
      viewBox='0 0 23 23'
    >
      <path
        fill='#fff'
        d='M.102 12.16c.055 1.391.327 2.736.878 4.014a11.125 11.125 0 001.797 2.889 11.106 11.106 0 004.584 3.188c.973.366 1.984.567 3.022.651a10.977 10.977 0 003.349-.259c1.386-.313 2.65-.896 3.816-1.704a10.871 10.871 0 003.264-3.538 10.925 10.925 0 001.457-4.677 10.824 10.824 0 00-.517-4.292c-.083-.252-.09-.252-.277-.069-.074.074-.15.148-.222.222-.388.398-.867.578-1.414.59-.27.007-.54 0-.81.002-.248.001-.253.008-.181.234.263.804.39 1.63.383 2.473a7.929 7.929 0 01-1.066 3.943c-.899 1.57-2.199 2.694-3.853 3.408a7.752 7.752 0 01-2.525.597 7.82 7.82 0 01-2.714-.264 7.934 7.934 0 01-3.275-1.81 7.893 7.893 0 01-1.624-2.049 8.01 8.01 0 01-.577-6.386 7.807 7.807 0 011.656-2.827 7.92 7.92 0 014.29-2.479 7.77 7.77 0 013.999.174c.248.078.255.074.257-.18.002-.232.004-.464 0-.696-.012-.664.205-1.229.698-1.688.274-.254.318-.254-.079-.375A11.267 11.267 0 0012.013.81a10.675 10.675 0 00-5.06.806c-2.405 1.016-4.237 2.676-5.502 4.96C.741 7.86.328 9.236.166 10.688c-.032.282-.065.565-.064.85 0 .085.04.203-.102.234v.18c.129.023.099.13.102.21z'
      ></path>
      <path
        fill='#fff'
        d='M.044 12.127c.023.833.132 1.655.339 2.461 1.514 5.962 7.598 9.59 13.566 8.064 2.945-.753 5.211-2.459 6.807-5.036.927-1.5 1.44-3.14 1.578-4.901a11.49 11.49 0 00-.114-2.662 9.996 9.996 0 00-.524-1.984c-.418.382-.75.852-1.328.98-.548.122-1.106.03-1.677.075.647 1.87.658 3.73-.055 5.577-.716 1.855-1.96 3.26-3.717 4.184a7.92 7.92 0 01-9.378-1.447c-2.587-2.633-2.94-6.432-1.32-9.406 1.648-3.019 5.53-5.082 9.654-3.68 0-.335.014-.664-.004-.99-.037-.688.182-1.262.71-1.714.103-.09.193-.194.306-.307-1.753-.591-3.502-.77-5.29-.508-2.183.318-4.114 1.204-5.761 2.67C1.6 5.493.347 7.981.079 10.965c-.024.268.023.544-.079.805.14-.032.102-.148.102-.234a7.6 7.6 0 01.064-.85C.328 9.234.74 7.86 1.45 6.575c1.265-2.284 3.097-3.944 5.506-4.953a10.646 10.646 0 015.06-.806c.817.067 1.62.2 2.405.442.396.121.352.121.079.375-.494.459-.71 1.022-.698 1.688.005.232.001.464 0 .697-.002.252-.011.255-.258.18a7.77 7.77 0 00-4-.175A7.881 7.881 0 005.256 6.5 7.811 7.811 0 003.6 9.326a8.01 8.01 0 00.577 6.387c.421.773.97 1.45 1.624 2.049a7.935 7.935 0 003.275 1.81 7.823 7.823 0 002.714.264c.878-.067 1.724-.25 2.525-.597 1.652-.715 2.954-1.838 3.853-3.408a7.93 7.93 0 001.065-3.943 7.707 7.707 0 00-.382-2.474c-.075-.225-.067-.232.181-.234.27-.002.54.005.81-.002.548-.012 1.026-.193 1.414-.59l.222-.221c.187-.183.194-.183.277.069.46 1.396.63 2.825.517 4.291a10.902 10.902 0 01-1.457 4.678 10.89 10.89 0 01-3.264 3.538c-1.166.806-2.43 1.39-3.816 1.704a11.03 11.03 0 01-3.349.259c-1.039-.085-2.05-.285-3.023-.652a11.138 11.138 0 01-6.38-6.076C.43 14.9.16 13.555.104 12.164c-.003-.081.026-.187-.102-.211.084.037.04.114.042.174z'
      ></path>
      <path
        fill='#fff'
        d='M18.45.24c-.909.913-1.82 1.823-2.736 2.731-.113.113-.13.243-.128.39.001.897-.004 1.797.005 2.695a.714.714 0 01-.23.554c-1.484 1.474-2.96 2.954-4.443 4.427-.173.173-.284.36-.284.611a.708.708 0 00.996.648c.182-.08.325-.211.464-.35 1.43-1.43 2.866-2.856 4.292-4.292a.809.809 0 01.633-.262c.87.012 1.74 0 2.61.009a.665.665 0 00.519-.21c.805-.815 1.62-1.623 2.431-2.431.085-.084.163-.174.233-.273.147-.211.171-.433.05-.656a.64.64 0 00-.58-.36c-.78-.015-1.558-.061-2.337-.045-.332.007-.376-.044-.378-.377-.001-.322-.01-.644.002-.965.02-.472-.058-.94-.053-1.412.002-.225-.121-.392-.306-.519-.062-.042-.17-.04-.17-.153h-.316c-.03.148-.186.153-.275.24z'
      ></path>
      <path
        fill='#fff'
        d='M15.675 2.905a.424.424 0 00-.14.332c.006.958 0 1.917.008 2.875a.468.468 0 01-.157.37c-1.483 1.473-2.961 2.952-4.44 4.429-.08.079-.163.158-.228.248a.786.786 0 00.56 1.262c.302.03.528-.12.732-.324 1.505-1.505 3.013-3.007 4.515-4.512a.422.422 0 01.332-.139c.968.006 1.934.002 2.902.006a.41.41 0 00.318-.122 530.2 530.2 0 012.64-2.637c.243-.241.364-.512.222-.848-.138-.323-.404-.433-.741-.437-.787-.01-1.575-.035-2.361-.044-.157-.002-.201-.051-.203-.204-.009-.764-.044-1.526-.04-2.29.003-.428-.156-.717-.556-.868 0 .112.106.11.17.153.186.125.31.294.306.52-.005.471.072.94.053 1.411-.014.322-.004.644-.002.965.002.332.046.382.378.376.779-.015 1.557.029 2.336.046.27.005.459.136.58.36.124.223.1.445-.049.656a1.907 1.907 0 01-.233.273c-.811.81-1.626 1.616-2.432 2.43a.662.662 0 01-.518.21c-.87-.009-1.74.006-2.61-.009a.807.807 0 00-.633.263c-1.423 1.434-2.859 2.86-4.289 4.29-.14.139-.282.27-.464.35a.708.708 0 01-.996-.648c-.002-.252.11-.438.284-.61 1.481-1.474 2.959-2.954 4.442-4.426a.71.71 0 00.23-.555c-.01-.897-.004-1.795-.006-2.695 0-.146.016-.276.129-.389.915-.91 1.827-1.82 2.735-2.734.086-.088.243-.091.273-.239a1.422 1.422 0 00-.485.349c-.851.855-1.705 1.707-2.562 2.556z'
      ></path>
      <path
        fill='#fff'
        d='M16.875 9.587c-.665.67-1.334 1.336-2.005 2a.385.385 0 00-.12.294c-.066 2.162-1.838 3.712-3.977 3.483-1.82-.193-3.252-1.911-3.102-3.723.152-1.834 1.485-3.163 3.331-3.31a.477.477 0 00.33-.154c.596-.602 1.196-1.199 1.797-1.796.122-.12.256-.227.413-.368-.237-.082-.408-.154-.586-.198-.26-.066-.52-.134-.79-.17-2.34-.31-4.3.425-5.812 2.219-1.89 2.244-1.93 5.425-.168 7.78 1.852 2.475 5.335 3.227 8.036 1.734 2.769-1.528 3.973-4.78 2.875-7.75-.064-.173-.11-.154-.222-.041z'
      ></path>
    </svg>
  );
};
