import { Typography } from '@mui/material';
import { APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS } from 'entities/apteka-legko/push-notifications';
import {
  FormNotificationsWithActions,
  prepareFormNotificationToFetchBody,
} from 'features/apteka-legko/push-notifications/form-push-notifications';
import { FormNotificationsWithActionsProps } from 'features/apteka-legko/push-notifications/form-push-notifications/types';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { usePostFirebaseDistributionMutation } from 'shared/api/online-store-service/firebase/rtk';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

export const PageCreatedNotification = () => {
  const navigate = useNavigate();
  const [
    createDistrebutionNotifications,
    { isLoading: isLoadingDistributionNotifications, error },
  ] = usePostFirebaseDistributionMutation();

  const handleCreateDistributionNotifications = useCallback<
    FormNotificationsWithActionsProps['onSubmit']
  >(
    async (form) => {
      const prepareBody = prepareFormNotificationToFetchBody(form);

      const response = await createDistrebutionNotifications({
        params: prepareBody,
      });

      if ('data' in response) {
        navigate(APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS.list);
      }
    },
    [createDistrebutionNotifications, navigate]
  );

  useShowFetchErrorInSnackbar(error);

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h5'
          fontWeight={'bold'}
        >
          Отправить push-уведомление
        </Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content disabledPaddingXs>
        <FormNotificationsWithActions
          isLoading={isLoadingDistributionNotifications}
          onSubmit={handleCreateDistributionNotifications}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
