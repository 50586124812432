import { generatePath } from 'react-router-dom';

import { GetTask } from 'shared/api/services/types';
import { ALL_ROUTES } from 'shared/routes/constants/types';

interface SourceTask {
  title: string | null;
  link: string | null;
}
export const extractSourceTask = (task: GetTask): SourceTask | null => {
  const { originId, originEntityId, originEntityTitle } = task;

  const title: string | null = originEntityTitle || null;
  let link: string | null = null;

  if (originId === 3 && originEntityId) {
    link = generatePath(
      `/${ALL_ROUTES.visitingPharmacies}/${ALL_ROUTES.new}/${ALL_ROUTES.stage6}`,
      { id: String(originEntityId) }
    );
  }

  return { title, link };
};
