import { SxProps, Theme } from '@mui/material';

import { theme } from 'shared/theme/theme';

export const STYLES_TOGGLE_BUTTON: SxProps<Theme> = {
  textTransform: 'none',
  backgroundColor: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.customGrey.contrastText,
  color: 'black',
  borderRadius: '10px',
  height: '40px',
  flex: 1,
  minWidth: 140,
  fontSize: [14, 16],
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    borderColor: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
};
