import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';

import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

import { useNavigate } from 'react-router-dom';

import { ADAPTATION_PROCESS_HELPER_LINKS } from 'entities/adaptation/links';
import { AdaptationManagerRequestsPreparedStageTasksCreate } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { usePostRunAdaptationProcessMutation } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import {
  FormPreparedStagesValue,
  preparedFormValueStagesToSubmitStageTasks,
} from 'shared/ui/form-run-process-prepared-stages-tasks';

export const useRunAdaptationProcess = (
  params: Partial<AdaptationManagerRequestsPreparedStageTasksCreate>
) => {
  const navigate = useNavigate();
  const { open: openSnackBar } = useSnackbar();
  const [runProcess, { isLoading: isLoadingSubmiteRunAdaptationProcess }] =
    usePostRunAdaptationProcessMutation();

  const { adaptationGuid, newEmployeeGuid, mentorGuid, countdownDate } = params;

  const handleRunAdaptationProcess: (form: FormPreparedStagesValue) => unknown =
    useCallback(
      async ({ stages }) => {
        if (
          !countdownDate ||
          !newEmployeeGuid ||
          !mentorGuid ||
          !adaptationGuid
        ) {
          openSnackBar({
            title: 'Что то пошло не так',
            text: `countdownDate = ${countdownDate}, newEmployeeGuid = ${newEmployeeGuid}, mentorGuid = ${mentorGuid},  adaptationGuid = ${adaptationGuid}`,
            type: 'error',
            duration: 3000,
          });

          return;
        }

        runProcess({
          params: {
            adaptationGuid,
            newEmployeeGuid,
            mentorGuid,
            countdownDate,
            stageTasks: preparedFormValueStagesToSubmitStageTasks(stages),
          },
        }).then((response) => {
          if ('error' in response) {
            openSnackBar({
              text: getMessageFromFetchBaseQueryError(response.error),
              type: 'error',
              duration: 3000,
            });
          } else {
            openSnackBar({
              text: 'Процесс успешно запущен',
              type: 'success',
              duration: 3000,
            });
            navigate(
              ADAPTATION_PROCESS_HELPER_LINKS.createLinkCurrentAdaptationProcess()
            );
          }
        });
      },
      [runProcess, adaptationGuid, newEmployeeGuid, mentorGuid, countdownDate]
    );

  return {
    isLoadingSubmiteRunAdaptationProcess,
    handleRunAdaptationProcess,
  };
};
