import { useCallback } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { ALL_ROUTES } from 'shared/routes/constants/types';
import { delEmpty } from 'shared/utils/delEmpty';

export const useVisitNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToCheckList = useCallback(
    (id: number, stage?: number, serach?: object) => {
      navigate({
        pathname:
          '/' +
          ALL_ROUTES.visitingPharmacies +
          '/' +
          ALL_ROUTES.new +
          `/stage${stage || 1}/` +
          id,
        search: createSearchParams(
          delEmpty({ redirect: location.pathname + location.search, ...serach })
        ).toString(),
      });
    },
    []
  );

  const navigateToCreateChecklist = useCallback((visitId: number) => {
    navigate({
      pathname:
        '/' +
        ALL_ROUTES.visitingPharmacies +
        '/' +
        'visit-info' +
        '/' +
        visitId,
    });
  }, []);

  return {
    navigateToCheckList,
    navigateToCreateChecklist,
  };
};
