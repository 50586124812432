import { Add } from '@mui/icons-material';
import { Grid, IconButton, IconButtonProps, Typography } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

export const UiButtonAddedTypeArticle: FC<{
  onClick: IconButtonProps['onClick'];
}> = ({ onClick }) => {
  return (
    <Grid
      container
      alignItems={'center'}
    >
      <IconButton
        onClick={onClick}
        size='small'
        sx={{
          marginRight: 2,
          backgroundColor: theme.palette.secondary.main,
          borderRadius: '10px',
          color: 'white',
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
        }}
      >
        <Add />
      </IconButton>
      <Typography
        fontSize={14}
        color={theme.palette.customGrey.dark}
        fontWeight={700}
      >
        Добавить вид расхода
      </Typography>
    </Grid>
  );
};
