import { FC } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { DnDListProps } from './type';

export const DnDList: FC<DnDListProps> = ({
  onDragEnd,
  children,
  ...propsDroppable
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable {...propsDroppable}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
