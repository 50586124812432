import { AttachFile } from '@mui/icons-material';
import {
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';

import {
  getOptionLabelTaxRate,
  isOptionEqualToValueTaxRate,
  renderOptionTaxRate,
} from 'entities/document/inputs/tax-rate-autocomplite';
import { useCalculateTaxRateQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';

import {
  AutocompliteWithController,
  DatePikerWithController,
  TextFieldWithController,
} from 'shared/fields-with-contoller';

import { LoadingButton } from '@mui/lab';
import { SwitchWithController } from 'shared/fields-with-contoller/switch-with-controller';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { FileListItem } from 'shared/ui/file-list-item';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { UploadButton } from 'shared/ui/upload-button/UploadButton';
import { checkIsNotEmptyArray } from 'shared/utils/checkIsNotEmptyArray';

import { EMPT_ARR } from 'widgets/training/filter/constants';

import { yupResolver } from '@hookform/resolvers/yup';

import { useDebounce } from 'shared/hooks/useDebounce';
import { DEFAULT_VALUE } from './constants';
import { DialogFormCloseDocumentProps, FormCloseDocumentValue } from './types';
import { SCHEME_VALIDATION } from './validate';

export const DialogFormCloseDocument: FC<DialogFormCloseDocumentProps> = ({
  isOpen,
  onClose,
  initialValue,
  isLoading,
  onSubmit = (form) => {
    console.log('form', form);
  },
}) => {
  const theme = useTheme();

  const { control, reset, setValue, handleSubmit, trigger, formState } =
    useForm<FormCloseDocumentValue>({
      defaultValues: initialValue || DEFAULT_VALUE,
      resolver: yupResolver(SCHEME_VALIDATION),
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const onRevalidateFiles = useCallback(() => {
    trigger('addedFiles');
    trigger('filesDeleteFromBackend');
    trigger('filesFromBackend');
  }, []);
  const isLink = useWatch({ control, name: 'isLink' });
  const isCloseDocumentSumEquals = useWatch({
    control,
    name: 'isCloseDocumentSumEquals',
  });

  /* locale-files */
  const {
    fields: addedFiles,
    append: appendToAddedFile,
    remove: removeAddedFile,
  } = useFieldArray({
    control,
    name: 'addedFiles',
  });

  const onAppendFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const formFiles =
        e.currentTarget.files && Array.from(e.currentTarget.files);

      if (formFiles) {
        formFiles.map((formFile) => appendToAddedFile({ formFile }));
      }
      onRevalidateFiles();
    },
    [onRevalidateFiles]
  );

  /* backend files */
  const {
    fields: filesFromBackend,
    append: appendToFileFromBackend,
    remove: removeFileFromBackend,
  } = useFieldArray({ control, name: 'filesFromBackend' });

  /* deleting files */
  const {
    fields: filesDeleteFromBackend,
    append: appendToFileDeleteFromBackend,
    remove: removeFileDeleteFromBackend,
  } = useFieldArray({ control, name: 'filesDeleteFromBackend' });

  /* TAX RATE */
  const closeDocumentSum = useWatch({ control, name: 'closeDocumentSum' });
  const closeDocumentSumDeb = useDebounce(closeDocumentSum, 500);
  const skipCalculateTaxRate =
    !closeDocumentSum ||
    Boolean(formState.errors.closeDocumentSum?.message) ||
    isNaN(Number(closeDocumentSumDeb)) ||
    Number(closeDocumentSumDeb) < 1;

  const { data: optionsTaxRate, isLoading: isLoadingOptionsTaxRate } =
    useCalculateTaxRateQuery(
      { params: { Sum: closeDocumentSumDeb as number } },
      {
        skip: skipCalculateTaxRate,
      }
    );

  const taxRate = useWatch({ control, name: 'closeDocumentTaxRate' });

  useEffect(() => {
    /* обновляем closeDocumentTaxRate если это options выбранный из автокомплита */
    if (typeof taxRate === 'string' || typeof taxRate === 'number') {
      return;
    }
    if (taxRate && optionsTaxRate) {
      if (!optionsTaxRate) {
        setValue('closeDocumentTaxRate', null);

        return;
      }

      const find = optionsTaxRate.find((item) => item.id === taxRate.id);

      if (find) {
        setValue('closeDocumentTaxRate', {
          id: find.id,
          sum: find.sum,
          title: find.title,
        });
      } else {
        setValue('closeDocumentTaxRate', null);
      }
    }
  }, [optionsTaxRate]);

  /* Сброс формы при изменении initialValue - (при запросе данных с бека) */
  useEffect(() => {
    if (initialValue) {
      reset(initialValue);
    }
  }, [initialValue]);

  return (
    <DialogForForm
      open={isOpen}
      head={<DialogTitleForForm title='Прикрепить закрывающий документ' />}
      onClose={onClose}
    >
      <Grid
        display={'flex'}
        flexDirection={'column'}
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
        gap={2}
      >
        <Grid
          display={'flex'}
          flexDirection={['column', 'row']}
          justifyContent={['space-between']}
        >
          <TextFieldWithController
            control={control}
            name={'closeDocumentNumber'}
            label={'№ документа'}
            containerSx={{ flexBasis: ['40%'] }}
          />

          <DatePikerWithController
            control={control}
            name={'closeDocumentDate'}
            label={'Дата документа'}
            sx={{ width: '100%' }}
            containerSx={{ flexBasis: ['40%'] }}
          />
        </Grid>

        {/* SUM AND TAX_RATE */}
        <SwitchWithController
          control={control}
          name={'isCloseDocumentSumEquals'}
          size='medium'
          formControlLabelProps={{ label: 'Соответствует документу' }}
        />

        <Grid
          display={'flex'}
          flexDirection={['column', 'row']}
          justifyContent={'space-between'}
        >
          {!isCloseDocumentSumEquals && (
            <>
              <TextFieldWithController
                control={control}
                disabled={isCloseDocumentSumEquals}
                containerSx={{ flexBasis: ['100%', '240px'] }}
                name={'closeDocumentSum'}
                label={'Сумма документа'}
              />
              <AutocompliteWithController
                control={control}
                options={optionsTaxRate || EMPT_ARR}
                name={'closeDocumentTaxRate'}
                freeSolo={true}
                loading={isLoadingOptionsTaxRate}
                disabled={isLoadingOptionsTaxRate || isCloseDocumentSumEquals}
                isOptionEqualToValue={isOptionEqualToValueTaxRate}
                onInputChange={(_, strValue, reason) => {
                  if (reason === 'input') {
                    setValue('closeDocumentTaxRate', strValue);
                    trigger('closeDocumentTaxRate');
                  }
                }}
                getOptionLabel={getOptionLabelTaxRate}
                filterOptions={(options) => options}
                renderOption={renderOptionTaxRate}
                sx={{
                  flexBasis: ['100%', '240px'],
                }}
                textFieldProps={{
                  label: 'НДС',
                }}
              />
            </>
          )}
          {isCloseDocumentSumEquals && (
            <>
              <TextFieldWithController
                control={control}
                disabled={isCloseDocumentSumEquals}
                containerSx={{ flexBasis: ['100%', '240px'] }}
                name={'equalsSumValue.documentSum'}
                label={'Сумма документа'}
              />
              <AutocompliteWithController
                control={control}
                options={optionsTaxRate || EMPT_ARR}
                name={'equalsSumValue.documentTaxRate'}
                freeSolo={true}
                loading={isLoadingOptionsTaxRate}
                disabled={isLoadingOptionsTaxRate || isCloseDocumentSumEquals}
                isOptionEqualToValue={isOptionEqualToValueTaxRate}
                getOptionLabel={getOptionLabelTaxRate}
                filterOptions={(options) => options}
                renderOption={renderOptionTaxRate}
                sx={{
                  flexBasis: ['100%', '240px'],
                }}
                textFieldProps={{
                  label: 'НДС',
                }}
              />
            </>
          )}
        </Grid>
        {/* ============================ */}
        <SwitchWithController
          control={control}
          name={'isLink'}
          size='medium'
          formControlLabelProps={{ label: 'Закрывающий документ в ЭДО' }}
        />
        {isLink && (
          <TextFieldWithController
            control={control}
            name='closeDocumentLink'
            label='Ссылка'
          />
        )}

        {/* FILES */}
        {!isLink && (
          <>
            <UploadButton
              variant='outlined'
              startIcon={<AttachFile />}
              id='close_document_files'
              sx={{ width: 'fit-content' }}
              onChangeInput={onAppendFile}
              color={'secondary'}
            >
              Прикрепить
            </UploadButton>
            {(formState.errors.addedFiles?.message ||
              formState.errors.filesFromBackend?.message) && (
              <Typography color={theme.palette.error.main}>
                {formState.errors.addedFiles?.message ||
                  formState.errors.filesFromBackend?.message}
              </Typography>
            )}
            {checkIsNotEmptyArray([
              ...addedFiles,
              ...filesFromBackend,
              ...filesDeleteFromBackend,
            ]) && (
              <Stack
                spacing={1}
                divider={<Divider />}
              >
                {checkIsNotEmptyArray(filesFromBackend) && (
                  <Stack spacing={1}>
                    <Typography>Загруженные:</Typography>
                    {filesFromBackend.map((fileFromBackend, index) => {
                      return (
                        <FileListItem
                          key={fileFromBackend.id}
                          fileName={
                            fileFromBackend.file.fileTitle || 'not_title_file'
                          }
                          onDelete={() => {
                            removeFileFromBackend(index);
                            appendToFileDeleteFromBackend({
                              file: fileFromBackend.file,
                            });
                            onRevalidateFiles();
                          }}
                        />
                      );
                    })}
                  </Stack>
                )}
                {checkIsNotEmptyArray(addedFiles) && (
                  <Stack spacing={1}>
                    <Typography>Прикрепить:</Typography>
                    {addedFiles.map((file, index) => {
                      return (
                        <FileListItem
                          key={file.id}
                          fileName={file.formFile.name}
                          disableDownload
                          onDelete={() => {
                            removeAddedFile(index);
                            onRevalidateFiles();
                          }}
                        />
                      );
                    })}
                  </Stack>
                )}
                {checkIsNotEmptyArray(filesDeleteFromBackend) && (
                  <Stack spacing={1}>
                    <Typography>Будут удалены:</Typography>
                    {filesDeleteFromBackend.map((fileFromBackend, index) => {
                      return (
                        <FileListItem
                          key={fileFromBackend.id}
                          fileName={
                            fileFromBackend.file.fileTitle || 'not_title_file'
                          }
                          variant='cancel'
                          onDelete={() => {
                            removeFileDeleteFromBackend(index);
                            appendToFileFromBackend({
                              file: fileFromBackend.file,
                            });
                            onRevalidateFiles();
                          }}
                        />
                      );
                    })}
                  </Stack>
                )}
              </Stack>
            )}
          </>
        )}
        {/* ============================= */}
        <ContainerActionsForm>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            type={'submit'}
            variant='contained'
          >
            Сохранить
          </LoadingButton>
          <Button
            onClick={onClose}
            disabled={isLoading}
            color='customGrey'
            variant='contained'
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
