import { Grid, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { theme } from 'shared/theme/theme';

export const ExpensesForm: FC<{
  children: ReactNode[];
  error?: ReactNode;
  bottomActions?: ReactNode[] | ReactNode;
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
}> = ({ children, error, bottomActions, onSubmit }) => {
  return (
    <Grid
      component={'form'}
      height={'100%'}
      onSubmit={onSubmit}
    >
      <Grid
        container
        sx={{ height: '5%' }}
        justifyContent={'center'}
      >
        <Typography
          textAlign={'center'}
          fontWeight={700}
          fontSize={[16, 20]}
        >
          Расходы по статьям
        </Typography>
      </Grid>

      <Grid
        flexDirection={'column'}
        paddingRight={[1, 3]}
        paddingBottom={3}
        sx={{
          overflowY: 'scroll',
          flexGrow: 2,
          height: '90%',
          '::-webkit-scrollbar': {
            width: 8,
            position: 'relative',
            right: '1px',
            backgroundColor: theme.palette.customGrey.main,
            borderRadius: 20,
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.customGrey.dark,
            borderRadius: 20,
          },
        }}
      >
        {children}
      </Grid>
      <Grid
        sx={{ position: 'relative' }}
        flexBasis='100%'
      >
        {error}
      </Grid>
      <Grid
        sx={{
          width: ['100vw', 'auto'],
          position: ['fixed', 'relative'],
          paddingRight: ['0px', '29px'],
          paddingTop: [2],
          height: '5%',
          bottom: ['16px', '0px'],
          left: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          '& > .MuiButtonBase-root': {
            flexBasis: ['50%', '49%'],
            borderRadius: ['0px', '8px'],
          },
        }}
      >
        {bottomActions}
      </Grid>
      <Grid sx={{ height: ['40px', '0px'], width: '100%' }}></Grid>
    </Grid>
  );
};
