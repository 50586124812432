import { useCallback } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useDeleteAdaptationProcessesMutation } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useConfirmDeleteAdaptationProcess = (params: {
  adaptationProcessGuid: string;
}) => {
  const { adaptationProcessGuid } = params;
  const { open: openSnackbar } = useSnackbar();
  const [onDeleteAdaptationProcess, { error }] =
    useDeleteAdaptationProcessesMutation();

  useShowFetchErrorInSnackbar(error);

  const openConfirm = useConfirm();

  const handleOpenConfirmDeleteAdaptationProcess = useCallback(() => {
    openConfirm({
      actionTitle: 'Удаление процесса адаптации',
      actionBody: 'Вы действительно хотите удалить процесс адаптации?',
      buttonColor: 'error',
      submitButtonTitle: 'Удалить',
      onSubmit: async () => {
        const response = await onDeleteAdaptationProcess({
          params: { guid: adaptationProcessGuid },
        });

        if ('error' in response) {
          return Promise.reject('reject');
        }

        openSnackbar({
          duration: 3000,
          type: 'success',
          title: 'Процесс удалён',
        });

        return;
      },
    });
  }, [adaptationProcessGuid]);

  return { handleOpenConfirmDeleteAdaptationProcess };
};
