import { FC, useEffect } from 'react';

import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { getObjByIds } from 'shared/tarnsform-helper/getObjByIds';
import { isOptionEqualToValueById } from 'shared/tarnsform-helper/isOptionEqualToValueById';
import { CustomAutocomplete } from 'shared/ui/base-ui';

import { VisitorInputProps } from './type';

export const VisitorInput: FC<VisitorInputProps> = ({
  label,
  onChange,
  options,
  value,
  isLoading,
  disable = false,
}) => {
  // Проверка наличия значения в опциях
  // Если значения нет, то удаляем его из инпута
  useEffect(() => {
    const newVal: number[] = [];

    value.forEach((val) => {
      const consist = options.some((opt) => opt.id === val);

      if (consist) newVal.push(val);
    });

    onChange(newVal);
  }, [options]);

  return (
    <CustomAutocomplete
      textFieldProps={{
        label: `${label} посетителей`,
        placeholder: `Выберите ${label}`,
      }}
      disabled={disable}
      multiple
      value={getObjByIds(options, value) || []}
      onChange={(_, val) => {
        onChange(getIdsFromArray(val) || []);
      }}
      options={options}
      getOptionLabel={(op) => op.title || ''}
      isOptionEqualToValue={isOptionEqualToValueById}
      loading={isLoading}
    />
  );
};
