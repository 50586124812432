// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Delete, Edit } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';

import { theme } from 'shared/theme/theme';
import { UiTableCellWithActions } from 'shared/ui/table/ui-table-cell-with-actions';

import { CELL_SX } from '../style';
import { EventPlanningRNCellProps } from './type';

export const EventPlanningRNCell: FC<EventPlanningRNCellProps> = ({
  event,
  onDelete,
  onEdit,
  rowSpan = 1,
}) => {
  const dateStart = dayjs(event.startDate).get('D');
  const dateEnd = dayjs(event.endDate).get('D');
  const actions = [
    {
      onClick: onEdit,
      text: 'Редактировать',
      icon: <Edit sx={{ mr: 2 }} />,
    },
    {
      onClick: onDelete,
      text: 'Удалить',
      icon: <Delete sx={{ mr: 2 }} />,
    },
  ];

  const backgroundColor = event.isConference
    ? `${theme.palette.customBlue.main}44`
    : `${theme.palette.customGrey.main}44`;

  /* Tooltip */
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const open = () => {
    // Открывать тултип только если сообщение больше 13 символов
    if (event.title!.length > 13) setIsOpenTooltip(true);
  };
  const close = () => {
    setIsOpenTooltip(false);
  };
  /* ===== */

  return (
    <React.Fragment>
      <UiTableCellWithActions
        colSpan={1}
        rowSpan={rowSpan}
        sx={{
          borderLeft: CELL_SX.borderLeft,
          borderColor: CELL_SX.borderColor,
          backgroundColor: backgroundColor,
          maxWidth: 115,
        }}
        actions={actions}
      >
        {rowSpan > 1 ? (
          event.title
        ) : (
          <Tooltip
            title={event.title}
            open={isOpenTooltip}
            onClose={close}
            onOpen={open}
            arrow
          >
            <Typography
              sx={{
                fontSize: 14,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {event.title}
            </Typography>
          </Tooltip>
        )}
      </UiTableCellWithActions>

      <UiTableCellWithActions
        colSpan={1}
        rowSpan={rowSpan}
        align='center'
        sx={{
          borderRight: CELL_SX.borderRight,
          borderColor: CELL_SX.borderColor,
          backgroundColor: backgroundColor,
        }}
        actions={actions}
      >
        {dateStart}-{dateEnd}
      </UiTableCellWithActions>
    </React.Fragment>
  );
};
