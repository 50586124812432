import { transformResponseError } from 'shared/api/libs';
import { aptekaLegkoBannersApi } from '../aptekaLegoBannersApi';

import {
  BannersRequestsBrandBannersCreateBrandBanner,
  BannersRequestsBrandBannersEditBrandBanner,
  BannersResponsesBrandBannersBrandBannerModel,
} from '../aptekaLegoBannersAutogenerateApi';
import { aptekaLegkoBannersProviderApi } from './aptekaLegkoBannersProviderApi';

export const aptekaLegkoBannersBrandProviderApi =
  aptekaLegkoBannersProviderApi.injectEndpoints({
    endpoints: (build) => ({
      /** Получает список всех баннеров бренда */
      getAptekaLegkoBannersBrands: build.query<
        BannersResponsesBrandBannersBrandBannerModel[],
        void
      >({
        queryFn: async () => {
          try {
            const response =
              await aptekaLegkoBannersApi.brandBanner.bannersApiBrandBannerList();

            return {
              data: response.data,
            };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        providesTags: ['brands-list'],
      }),
      /* ======================================= */
      /** Получает баннер бренда */
      getAptekaLegkoBannersBrandsDetail: build.query<
        BannersResponsesBrandBannersBrandBannerModel,
        { BrandsGuid: string }
      >({
        queryFn: async ({ BrandsGuid }) => {
          try {
            const response =
              await aptekaLegkoBannersApi.brandBanner.bannersApiBrandBannerDetail(
                BrandsGuid
              );
            return { data: response.data };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        providesTags: ['brand-by-guid'],
      }),
      /* ======================================= */
      /** Удалить баннер бренда */
      deleteAptekaLegkoBannersBrands: build.mutation<
        void,
        { BrandsGuid: string }
      >({
        queryFn: async ({ BrandsGuid }) => {
          try {
            const response =
              await aptekaLegkoBannersApi.brandBanner.bannersApiBrandBannerDelete(
                {
                  BannerBrandGuid: BrandsGuid,
                }
              );
            return { data: response.data };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        invalidatesTags: ['brand-by-guid', 'brands-list'],
      }),
      /* ======================================= */
      /**  Создание баннеров брендов */
      createAptekaLegkoBannersBrands: build.mutation<
        string,
        { Brands: BannersRequestsBrandBannersCreateBrandBanner }
      >({
        queryFn: async ({ Brands }) => {
          try {
            const response =
              await aptekaLegkoBannersApi.brandBanner.bannersApiBrandBannerCreate(
                Brands
              );
            return { data: response.data };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        invalidatesTags: ['brand-by-guid', 'brands-list'],
      }),
      /* ======================================= */
      /** Редактирование слайдеров */
      updateAptekaLegkoBannersBrands: build.mutation<
        void,
        { Brands: BannersRequestsBrandBannersEditBrandBanner }
      >({
        queryFn: async ({ Brands }) => {
          try {
            const response =
              await aptekaLegkoBannersApi.brandBanner.bannersApiBrandBannerUpdate(
                Brands
              );
            return { data: response.data };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        invalidatesTags: ['brands-list', 'brand-by-guid'],
      }),
      /* ======================================= */
    }),
  });

export const {
  useGetAptekaLegkoBannersBrandsQuery,
  useGetAptekaLegkoBannersBrandsDetailQuery,
  useCreateAptekaLegkoBannersBrandsMutation,
  useUpdateAptekaLegkoBannersBrandsMutation,
  useDeleteAptekaLegkoBannersBrandsMutation,
} = aptekaLegkoBannersBrandProviderApi;
