// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button, Dialog, Grid, Slide, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useStore } from 'effector-react';
import { postDocumentsDocumentIdDoActionFx } from 'features/document/document-actions/api/document-actions';
import React from 'react';
import { TransitionProps } from 'react-transition-group/Transition';

import { DocumetnDoActionRequest } from 'shared/api/services-document-flow/types';
import { CustomDatePicker } from 'shared/ui/base-ui';
import { CustomTextField } from 'shared/ui/custom-text-field';
import { useDevice } from 'shared/utils/hook-type-device';

import { orderManagerAgreeRequestModal } from './models/order-manager-agree-request';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction='left'
      ref={ref}
      {...props}
    />
  );
});

export const DocumentActionManagerAgreeRequestOrderresetModal = () => {
  const modal = useStore(orderManagerAgreeRequestModal.store);
  const [isMobile] = useDevice();
  const postManagerAgree = (
    element: React.FormEvent<HTMLFormElement> | undefined
  ) => {
    element?.preventDefault();
    if (modal.actionId && modal.documentId) {
      const requestBody = {
        actionTaskId: modal.actionId,
        managerAgreeRequest: {
          orderDate: modal.orderDate,
          orderNumber: modal.orderNumber,
        },
      } as DocumetnDoActionRequest;

      postDocumentsDocumentIdDoActionFx({
        documentId: modal.documentId,
        requestBody,
      });
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      onClose={() => orderManagerAgreeRequestModal.events.reset(1)}
      open={Boolean(modal.documentId)}
      PaperProps={{
        sx: {
          maxWidth: '100vw',
        },
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid
        component={'form'}
        onSubmit={postManagerAgree}
        container
        flexDirection={'column'}
        sx={{
          backgroundColor: 'white',
          minWidth: ['320px', '500px'],
          minHeight: ['100%', '300px'],
          padding: [1, 3],
          height: '100%',
          justifyContent: 'space-between',
          alignContent: 'space-between',
        }}
      >
        <Typography textAlign={'center'}>Приказ</Typography>

        <Grid>
          <CustomTextField
            propsTextField={{
              value: modal.orderNumber,
              onChange: (e) => {
                orderManagerAgreeRequestModal.events.changeOrderNumber(
                  e.target.value
                );
              },
              size: 'small',
              required: true,
              inputProps: {
                placeholder: isMobile ? 'Введите номер приказа' : undefined,
              },
              sx: {
                margin: 0,
              },
            }}
            label={'Номер приказа'}
          ></CustomTextField>

          <Grid sx={{ flexBasis: ['100%', '35%'] }}>
            <CustomDatePicker
              format='DD.MM.YYYY'
              label={'Дата приказа'}
              value={modal.orderDate ? dayjs(modal.orderDate) : null}
              onChange={(value) => {
                orderManagerAgreeRequestModal.events.changeOrderDate(
                  value?.format('YYYY-MM-DDTHH:mm:ss') || null
                );
              }}
              slotProps={{
                textField: {
                  required: true,
                  inputProps: {
                    placeholder: 'Введите дату приказа',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={'space-between'}
        >
          <Button
            type='submit'
            size='small'
            variant='contained'
            sx={{ flexBasis: '47%', height: '40px', fontSize: 16 }}
          >
            Отправить
          </Button>
          <Button
            size='small'
            variant='contained'
            color='customGrey'
            onClick={() => {
              orderManagerAgreeRequestModal.events.reset(1);
            }}
            sx={{ flexBasis: '47%', height: '40px', fontSize: 16 }}
          >
            Закрыть
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
