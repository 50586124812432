import { Typography } from '@mui/material';
import { FC } from 'react';

import { UiCalendareHeadCell } from 'shared/ui/calendar/ui-calendare-head-cell';

import { VisitCalendarHeadCellProps } from './type';

export const VisitCalendarHeadCell: FC<VisitCalendarHeadCellProps> = ({
  weekDayName,
}) => {
  return (
    <UiCalendareHeadCell
      containerProps={{
        sx: { display: 'flex', alignItems: 'center', p: 1 },
      }}
    >
      <Typography
        fontWeight={700}
        color={'GrayText'}
      >
        {weekDayName}
      </Typography>
    </UiCalendareHeadCell>
  );
};
