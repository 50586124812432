import { Button } from '@mui/material';
import { FC } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';
import { OverPageSpeedDial } from '../over-page-speed-dial';
import { UiSearchBar } from '../ui-search-bar';
import { SearchBarWithActionsProps } from './type';

export const SearchBarWithActions: FC<SearchBarWithActionsProps> = ({
  actions,
  children,
  ...props
}) => {
  const [isMobile] = useDevice();

  return (
    <UiSearchBar {...props}>
      {isMobile && <OverPageSpeedDial actionMap={actions} />}

      {!isMobile && (
        <>
          {actions.map((el) => (
            <Button
              key={el.tooltip}
              variant='contained'
              color='customBlue'
              sx={{ fontSize: 16 }}
              startIcon={el.icon}
              onClick={el.action}
            >
              {el.tooltip}
            </Button>
          ))}
        </>
      )}

      {children}
    </UiSearchBar>
  );
};
