import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { CardStackItemProps } from '../types';

export const CardStackItem: FC<CardStackItemProps> = ({
  children,
  title,
  ...otherProps
}) => {
  return (
    <Grid
      container
      flexDirection={'row'}
      justifyContent={'space-between'}
      {...otherProps}
    >
      {title && title !== '' && (
        <Typography fontWeight={'bold'}>{`${title}:`}</Typography>
      )}

      {children}
    </Grid>
  );
};
