import { GetVisitingsActionQueryParams } from 'shared/api/visit/types';

import { VisitFilterFormFields } from '../type';

export const convertVisitFilterFormFieldsToFetchData = (
  form: VisitFilterFormFields
): Omit<GetVisitingsActionQueryParams, 'Limit' | 'Page' | 'SearchText'> => {
  return {
    BeginDate: form.dateStart,
    EndDate: form.dateEnd,

    VisitingDepartmentsIds: form.departmentsIds,
    VisitingDivisionsIds: form.divisionsIds,
    VisitingRegionsIds: form.regionsIds,
    VisitingRetailsIds: form.retailsIds,

    VisitorDepartmentsIds: form.visitorDepartmentsIds,
    VisitorOfficesIds: form.visitorOfficesIds,
    VisitorPositionsIds: form.visitorPositionsIds,
    VisitorRegionsIds: form.visitorRegionsIds,
    VisitorIds: form.visitorsIds,

    ChecklistStatuses:
      form.status && form.status !== undefined ? [form.status] : undefined,
  };
};
