export enum E_ModuleGuids {
  planningRN = 'bbe1b8db-8e42-412d-9272-61ffa46c14ae',
  /** Процесс открытия аптек. Конструктор */
  openingRetailsTemplate = '7009f360-7f26-47c7-abe0-e55c4bdd9b26',
  /** Процесс открытия аптек */
  openingRetailsProtocols = 'f0d2b814-d9ee-4b5f-9072-ffd9ea6a91ad',
  /** Рекламные поверхности  */
  marketingSurface = '99d0708c-5ede-40c5-9756-74a807777c59',
  /* Пуш уведомления Аптека легко (firebase) */
  alPushNotifications = 'fd6e2d5b-bcf2-4be2-a8a0-38ebaf971899',
  /** Адаптация шаблоны */
  adaptationTemplates = 'a1a5b030-b781-4a32-a2e8-6798487bc6d8',
  /** Адаптация процессы */
  adaptationProcesses = '7b79baed-285b-4266-aa76-6241abbbab95',
  /** Аптека Легко баннера брендов */
  aptekaLegkoBannerBrand = 'fe7d5b62-06b6-4b58-909e-9b0461849ea0',
  /** Аптека Легко баннера слайдов(главный экран) */
  aptekaLegkoBannerSlider = 'e0fe753f-ce6a-486b-90a5-523e792bdb01',
}

export const MODULE_GUIDS = Object.values(E_ModuleGuids);
