import { GetVisitingsActionQueryParams } from 'shared/api/visit/types';

import { INIT_VISIT_FILTER_FORM_VALUE } from '../constants';
import { VisitFilterFormFields } from '../type';

export const convertFetchDataToInitVisitFilterForm = (
  data?: Omit<GetVisitingsActionQueryParams, 'Limit' | 'Page'>
): Partial<VisitFilterFormFields> => {
  if (!data) return INIT_VISIT_FILTER_FORM_VALUE;

  return {
    dateEnd: data.EndDate,
    dateStart: data.BeginDate,
    departmentsIds: data.VisitingDepartmentsIds || [],
    divisionsIds: data.VisitingDivisionsIds || [],
    regionsIds: data.VisitingRegionsIds || [],
    retailsIds: data.VisitingRetailsIds || [],
    status: data.ChecklistStatuses?.[0],
    visitorDepartmentsIds: data.VisitorDepartmentsIds || [],
    visitorOfficesIds: data.VisitorOfficesIds || [],
    visitorPositionsIds: data.VisitorPositionsIds || [],
    visitorRegionsIds: data.VisitorRegionsIds || [],
    visitorsIds: data.VisitorIds || [],
  };
};
