import { Dayjs } from 'dayjs';

import { getDayjs } from 'shared/tarnsform-helper/getDayjs';

export type HasNotDateInRangeFun = (
  date: Dayjs,
  startDate: string | null | undefined,
  endDate: string | null | undefined
) => boolean;

export const hasNotDateInRange: HasNotDateInRangeFun = (
  date,
  startDate,
  endDate
) => {
  if (!startDate || !endDate) return false;

  const start = getDayjs(startDate);
  const end = getDayjs(endDate);

  return date.isBefore(start) || date.isAfter(end);
};
