import { BookmarkBorder } from '@mui/icons-material';
import { Collapse, List, useMediaQuery } from '@mui/material';
import { FC, Fragment, useState } from 'react';
import { theme } from 'shared/theme/theme';
import { useSaveFilters } from 'shared/user-app-settings';
import { CustomDrawer } from '../ui/custom-drawer';
import { FiltersListProps } from './type';

export const FiltersList: FC<FiltersListProps> = ({
  isOpenDrawer,
  handleCloseDrawer,
  handleOpenDrawer,
}) => {
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { allFilters } = useSaveFilters();
  const [isShowMore, setIsShowMore] = useState(false);

  return (
    <Fragment key={123}>
      <CustomDrawer.ListItemButtonMore
        isOpen={isShowMore}
        Icon={<BookmarkBorder />}
        textPrimary={'Закладки'}
        onClick={() => {
          handleOpenDrawer();
          setIsShowMore((prev) => {
            if (prev && isOpenDrawer) return false;
            else return true;
          });
        }}
      />

      <Collapse
        in={isShowMore && isOpenDrawer}
        timeout='auto'
        unmountOnExit
      >
        <List
          component='div'
          disablePadding
        >
          {allFilters.map((filter) => {
            return (
              <CustomDrawer.ListItemLink
                onClick={isDownSm ? handleCloseDrawer : undefined}
                key={filter.id}
                to={filter.url}
                textPrimary={filter.title}
                disableBackground
              />
            );
          })}
        </List>
      </Collapse>
    </Fragment>
  );
};
