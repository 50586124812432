import { Breadcrumbs, Grid, Skeleton, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useRouteContext } from 'shared/routes';
import { ALL_ROUTES, Routes } from 'shared/routes/constants/types';
import { theme } from 'shared/theme/theme';
import { Link } from 'shared/ui/base-ui';
import { BookmarkButton } from '../BookmarkButton';

export const BreadcrumbsApp = () => {
  const router = useLocation();
  const { routes, isLoading } = useRouteContext();

  const crumbs = buildBreadcrumbsArr(routes, router.pathname);

  return (
    <Grid
      container
      justifyContent={'space-between'}
      flexWrap={'nowrap'}
    >
      <Breadcrumbs
        sx={{
          paddingX: [1, 2],
          paddingY: [2, 2],
          '& > .MuiBreadcrumbs-ol > .MuiBreadcrumbs-li:last-child': {
            color: theme.palette.primary.main,
          },
        }}
      >
        {isLoading &&
          new Array(3).fill(0).map((_, i) => (
            <Skeleton
              key={i}
              variant='rectangular'
              width={'6em'}
              height={'1.5em'}
            />
          ))}
        {crumbs &&
          crumbs.map((rout) => {
            if (rout?.isLinkCrumbs) {
              return (
                <Link
                  to={rout?.fullPath || '/'}
                  key={rout?.fullPath}
                  color={'inherit'}
                >
                  {rout?.title}
                </Link>
              );
            }

            return <Typography key={rout?.fullPath}>{rout?.title}</Typography>;
          })}
      </Breadcrumbs>

      <BookmarkButton />
    </Grid>
  );
};

const flatten = (
  children: Routes[] | null,
  extractChildren: (route: Routes) => Routes[] | null
): Routes[] =>
  Array.prototype.concat.apply(
    children,
    children
      ? children.map((x) => flatten(extractChildren(x) || [], extractChildren))
      : []
  );

const extractChildren = (x: Routes): Routes[] | null => x.children;

const NOT_LINKS_CRUMB: ALL_ROUTES[] = [
  ALL_ROUTES.tasks,
  ALL_ROUTES.documentFlow,
  ALL_ROUTES.visitingPharmacies,
  ALL_ROUTES.training,
];

const buildBreadcrumbsArr = (routes: Routes[], pathUrl: string) => {
  const flattenRouts = flatten(routes, extractChildren);
  const pathsUrl = pathUrl === '/' ? ['/'] : pathUrl.split('/');
  const findRouts = pathsUrl
    .map((str) => {
      const find = flattenRouts.find((rout) => {
        return `/${rout.path}` === str || rout.path === str;
      });

      if (find) {
        return { ...find, isLinkCrumbs: !NOT_LINKS_CRUMB.includes(find.path) };
      }
    })
    .filter((i) => i);

  return findRouts;
};
