import { Add } from '@mui/icons-material';
import {
  Button,
  ClickAwayListener,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { useBoardPage } from 'pages/boards/context/BoardPageContext';
import { FC, useState } from 'react';
import { useCreateColumnMutation } from 'shared/api/services/rtk/boardApi';
import { theme } from 'shared/theme/theme';
import { ColumnFormCard } from '../ColumnFormCard/ColumnFormCard';
import { CreateBoardColumnButtonProps } from './type';

export const CreateBoardColumnButton: FC<CreateBoardColumnButtonProps> = () => {
  const { boardGuid } = useBoardPage();
  const [createColumn, { isLoading: isCreatingColumn, originalArgs }] =
    useCreateColumnMutation();
  const [isCreating, setIsCreating] = useState(false);

  const closeCreation = () => {
    setIsCreating(false);
  };

  const openCreation = () => {
    setIsCreating(true);
  };

  if (isCreatingColumn) {
    return (
      <Grid>
        <Paper
          elevation={2}
          sx={{
            width: 300,
            p: 2,
            position: 'relative',
            overflow: 'hidden',
            height: 72,
          }}
          component={Grid}
        >
          <LinearProgress
            sx={{
              height: 3,
              width: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              background:
                originalArgs?.queryArg.color || theme.palette.primary.main,
            }}
          />

          <Grid
            container
            flexDirection={'row'}
            gap={2}
            pt={0.5}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexWrap={'nowrap'}
          >
            <Typography
              fontWeight={'bold'}
              fontSize={20}
            >
              {originalArgs?.queryArg.title}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (isCreating)
    return (
      <Grid mr={1}>
        <ClickAwayListener onClickAway={closeCreation}>
          <Grid width={300}>
            <ColumnFormCard
              onSubmit={(form) => {
                createColumn({
                  boardGuid,
                  queryArg: { title: form.title, color: form.color },
                }).then(closeCreation);
              }}
              actions={
                <>
                  <Button type='submit'>Создать</Button>
                  <Button onClick={closeCreation}>Закрыть</Button>
                </>
              }
            />
          </Grid>
        </ClickAwayListener>
      </Grid>
    );

  return (
    <Grid>
      <Button
        variant='text'
        onClick={openCreation}
        sx={{ width: 200 }}
        startIcon={<Add />}
      >
        Создать колонку
      </Button>
    </Grid>
  );
};
