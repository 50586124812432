import { Chip } from '@mui/material';
import { FC, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import { getObjById } from 'shared/tarnsform-helper/getObjById';
import { getObjByIds } from 'shared/tarnsform-helper/getObjByIds';
import { CustomAutocomplete, CustomDatePicker } from 'shared/ui/base-ui';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { FormLayout } from 'shared/ui/form';

import { useGetDepartments } from 'entities/visit/options/useGetDepartments';
import { useGetPlanningHomies } from 'entities/visit/options/useGetPlanningHomies';
import { useGetPlanningVisitors } from 'entities/visit/options/useGetPlanningVisitors';

import { CreatePlanningRNFormFields, CreatePlanningRNFormProps } from './type';

export const CreatePlanningRNForm: FC<CreatePlanningRNFormProps> = ({
  actions,
  onSubmit,
  initValue,
  blocked,
}) => {
  /* Options */
  const { departments, isLoading: isLoadingDep } = useGetDepartments();
  const { homies, isLoading: isLoadingHomies } = useGetPlanningHomies();
  const { visitors, isLoading: isLoadingVisitors } = useGetPlanningVisitors();
  /* ===== */

  /* Form */
  const { control, handleSubmit, setValue } =
    useForm<CreatePlanningRNFormFields>({
      defaultValues: initValue,
    });

  const selectedDepartment = useWatch({
    control: control,
    name: 'departmentId',
  });

  const selectedMonth = useWatch({
    control: control,
    name: 'month',
  });

  const selectedVisitor = useWatch({
    control: control,
    name: 'visitorId',
  });
  /* ===== */

  /* Logic */
  const fixedHome = useMemo(() => {
    if (!selectedDepartment) return null;

    const homOfDep = homies.find(
      (hom) => hom.departmentsIds?.some((el) => el === selectedDepartment)
    );

    if (!homOfDep) return null;
    if (selectedVisitor === homOfDep.id) {
      setValue('homiesIds', []);

      return null;
    }

    setValue('homiesIds', [homOfDep.id]);

    return homOfDep.id;
  }, [selectedDepartment, homies, selectedVisitor]);
  /* ===== */

  const blockedDep = useMemo(() => {
    if (!selectedMonth || !blocked) return [];

    const monthNumber = selectedMonth.get('month') + 1;

    return blocked
      .filter((el) => el.month === monthNumber)
      .map((el) => el.departmentId);
  }, [selectedMonth]);

  const blockedMonth = useMemo(() => {
    if (!blocked || !selectedDepartment) return [];

    return blocked
      .filter((el) => el.departmentId === selectedDepartment)
      .map((el) => el.month);
  }, [selectedDepartment]);

  return (
    <>
      <CustomBackdrop
        isLoading={isLoadingDep || isLoadingHomies || isLoadingVisitors}
      />
      <FormLayout
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
        actions={actions(handleSubmit)}
        container
        flexDirection={'column'}
        gap={2}
      >
        <Controller
          control={control}
          name='departmentId'
          render={({ field: { onChange, value } }) => (
            <CustomAutocomplete
              value={getObjById(departments, value)}
              onChange={(_, value) => onChange(value?.id)}
              loading={isLoadingDep}
              isOptionEqualToValue={(val, eq) => val.id === eq.id}
              loadingText='Загрузка...'
              textFieldProps={{
                label: 'Сеть',
                placeholder: 'Выберите сеть',
                required: true,
              }}
              options={departments.filter(
                (dep) => !blockedDep.some((el) => el === dep.id)
              )}
              getOptionLabel={(option) => option.title || ''}
            />
          )}
        />

        <Controller
          control={control}
          name='visitorId'
          render={({ field: { onChange, value } }) => (
            <CustomAutocomplete
              value={getObjById(visitors, value)}
              onChange={(_, value) => onChange(value?.id)}
              isOptionEqualToValue={(val, eq) => val.id === eq.id}
              loading={isLoadingVisitors}
              loadingText='Загрузка...'
              textFieldProps={{
                label: 'Инициатор',
                placeholder: 'Выберите инициатора',
                required: true,
              }}
              options={visitors}
              getOptionLabel={getEmployeTitle}
            />
          )}
        />

        <Controller
          control={control}
          name='homiesIds'
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <CustomAutocomplete
              onChange={(_, value) => {
                const isClear = value.length === 0;

                if (isClear && fixedHome) {
                  onChange([fixedHome]);

                  return;
                }
                onChange(value.map((el) => el.id));
              }}
              isOptionEqualToValue={(val, eq) => val.id === eq.id}
              value={getObjByIds(homies, value) || []}
              loading={isLoadingHomies}
              loadingText='Загрузка...'
              textFieldProps={{
                label: 'Участники',
                placeholder: 'Выберите участников',
                error: invalid,
              }}
              multiple
              options={homies.filter((el) => el.id !== selectedVisitor)}
              getOptionLabel={getEmployeTitle}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size='small'
                    label={getEmployeTitle(option)}
                    {...getTagProps({ index })}
                    disabled={fixedHome === option.id}
                  />
                ))
              }
            />
          )}
        />

        <Controller
          control={control}
          name='month'
          render={({ field: { value, onChange } }) => (
            <CustomDatePicker
              value={value || null}
              onChange={onChange}
              label={'Месяц'}
              containerSx={{ flex: 1 }}
              sx={{ width: '100%' }}
              views={['month', 'year']}
              slotProps={{ textField: { required: true } }}
              shouldDisableMonth={(month) => {
                const monthNum = month.get('month') + 1;

                return blockedMonth.some((el) => el === monthNum);
              }}
            />
          )}
        />
      </FormLayout>
    </>
  );
};
