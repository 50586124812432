import { useStore } from 'effector-react';

import { $userData, fetchUserDataFx } from './is-auth-user';

export const useUserDataFetch = () => {
  const loading = useStore(fetchUserDataFx.pending);
  const user = useStore($userData);

  return { user, loading };
};
