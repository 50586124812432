import {
  CompetitiveAdvantagesForm,
  CompetitiveAdvantagesFormI,
  CompetitiveAdvantagesFormSkeleton,
  prepareDataToFetchStage3,
  prepareDataToInitStage3,
} from 'features/visit/Stage 3';
import { useMemo } from 'react';

import { postChecklistsVisitingChecklistIdStages2 } from 'shared/api/visit/services';

import { useChecklist } from 'entities/visit/checkList/context/ChecklistContext';

import { STAGES } from './VisitLayout';

export const Stage3Page = () => {
  const { checkList, getCheckList, isLoading, stageNavigate, status } =
    useChecklist();

  const onSubmit = async (data: CompetitiveAdvantagesFormI) => {
    if (checkList) {
      if (!checkList.canEdit) {
        stageNavigate(STAGES.stage4);

        return;
      }

      try {
        const fetchData = prepareDataToFetchStage3(data);

        await postChecklistsVisitingChecklistIdStages2(checkList.id, fetchData);
        await getCheckList();

        stageNavigate(STAGES.stage4);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const initVal = useMemo(() => {
    if (checkList) return prepareDataToInitStage3(checkList);
    else return undefined;
  }, [checkList]);

  if (isLoading && !checkList) return <CompetitiveAdvantagesFormSkeleton />;
  if (!checkList || status === 400) return <h1>Такого чеклиста нет</h1>;

  return (
    <CompetitiveAdvantagesForm
      onSubmit={onSubmit}
      onDraftSubmit={onSubmit}
      cometitors={checkList.cometitors || []}
      initValue={initVal}
      disableAll={!checkList.canEdit}
    />
  );
};
