import { Grid, Skeleton } from '@mui/material';

export const InformationAboutCompetitorsFormSkeleton = () => {
  return (
    <Grid
      container
      gap={1}
      flexDirection={'column'}
    >
      <Skeleton
        variant='rounded'
        height={35}
      />

      <Grid
        container
        gap={1}
      >
        {/* Имя конкурента */}
        <Grid
          container
          justifyContent={'space-between'}
        >
          <Skeleton
            variant='rounded'
            width={100}
            height={24}
          />
          <Skeleton
            variant='rounded'
            width={50}
            height={24}
          />
        </Grid>
        {/* === */}

        <Grid
          container
          flexDirection={'column'}
          gap={2}
        >
          <Skeleton
            variant='rounded'
            height={40}
          />
          <Skeleton
            variant='rounded'
            height={40}
          />

          <Grid
            container
            flexDirection={'column'}
            gap={2}
          >
            <Grid
              container
              justifyContent={'space-between'}
            >
              <Skeleton
                variant='rounded'
                height={40}
                width={'65%'}
              />
              <Skeleton
                variant='rounded'
                height={40}
                width={'30%'}
              />
            </Grid>

            <Grid
              container
              justifyContent={'space-between'}
            >
              <Skeleton
                variant='rounded'
                height={40}
                width={'65%'}
              />
              <Skeleton
                variant='rounded'
                height={40}
                width={'30%'}
              />
            </Grid>

            <Grid
              container
              justifyContent={'space-between'}
            >
              <Skeleton
                variant='rounded'
                height={40}
                width={'65%'}
              />
              <Skeleton
                variant='rounded'
                height={40}
                width={'30%'}
              />
            </Grid>

            <Grid
              container
              justifyContent={'space-between'}
            >
              <Skeleton
                variant='rounded'
                height={40}
                width={'65%'}
              />
              <Skeleton
                variant='rounded'
                height={40}
                width={'30%'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Skeleton
        variant='rounded'
        height={36}
        width={210}
      />
    </Grid>
  );
};
