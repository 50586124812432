import dayjs, { Dayjs } from 'dayjs';

export const hasNotDateInMonth = (
  date: Dayjs,
  month?: Dayjs | string | null
) => {
  if (!month) return false;

  const monthStart = dayjs(month).set('D', 1);
  const monthEnd = dayjs(month).set('D', monthStart.daysInMonth());

  return date.isBefore(monthStart) || date.isAfter(monthEnd);
};
