import { Skeleton } from '@mui/material';

const MAS = [1, 2, 3, 4, 5, 6, 7, 8];

const BoardListItemCardSkeleton = () => {
  return (
    <Skeleton
      variant='rounded'
      // width={'calc(calc(100% / 4) - 8px)'}
      height={127}
    />
  );
};

export const BoardListSkeleton = () => {
  return (
    <>
      {MAS.map((el) => (
        <BoardListItemCardSkeleton key={el} />
      ))}
    </>
  );
};
