import {
  ObserveFilterItemModel,
  ObserveFilterModel,
} from 'shared/api/services-document-flow/types';
import { createChecker, createFilterOnCheckers } from './checkers';
import { filteredNewValues } from './filteredNewValues';
import { prepareObserveFilterModalWithMyKeys } from './prepareObserveFilterModalWithMyKeys';

export interface FilterAllowedOptionsParams {
  observeFilterOptionsAll: ObserveFilterModel;
  directionsAndRegionsValues: ObserveFilterItemModel[];
  departmentsAndDeptsValue: ObserveFilterItemModel[];
  officeValue: ObserveFilterItemModel[];
  positionsValue: ObserveFilterItemModel[];
  employesValue: ObserveFilterItemModel[];
}

export const filterAllowedOptions = (params: FilterAllowedOptionsParams) => {
  const {
    directionsAndRegionsValues,
    observeFilterOptionsAll,
    departmentsAndDeptsValue,
    officeValue,
    positionsValue,
    employesValue,
  } = params || {};

  const newObserveFilterOptionsAll = {
    ...prepareObserveFilterModalWithMyKeys(observeFilterOptionsAll),
  };

  const checkIntersectiOndirectionsAndRegionsValuesKeys = createChecker(
    directionsAndRegionsValues
  );
  /* departmentsAndDepts */

  newObserveFilterOptionsAll.departmentsAndDepts =
    newObserveFilterOptionsAll.departmentsAndDepts.filter(
      createFilterOnCheckers([checkIntersectiOndirectionsAndRegionsValuesKeys])
    );

  const newDepartmentsAndDepsValue = filteredNewValues(
    departmentsAndDeptsValue,
    newObserveFilterOptionsAll.departmentsAndDepts
  );
  const checkIntersectiOnDepartmentsAndDepsValuesKeys = createChecker(
    newDepartmentsAndDepsValue
  );

  /* office */

  newObserveFilterOptionsAll.offices =
    newObserveFilterOptionsAll.offices.filter(
      createFilterOnCheckers([
        checkIntersectiOndirectionsAndRegionsValuesKeys,
        checkIntersectiOnDepartmentsAndDepsValuesKeys,
      ])
    );

  const newOfficeValue = filteredNewValues(
    officeValue,
    newObserveFilterOptionsAll.offices
  );
  const checkIntersectiOnOfficeValuesKeys = createChecker(newOfficeValue);

  /* positions */
  newObserveFilterOptionsAll.positions =
    newObserveFilterOptionsAll.positions.filter(
      createFilterOnCheckers([
        checkIntersectiOndirectionsAndRegionsValuesKeys,
        checkIntersectiOnDepartmentsAndDepsValuesKeys,
        checkIntersectiOnOfficeValuesKeys,
      ])
    );

  const newPositionsValue = filteredNewValues(
    positionsValue,
    newObserveFilterOptionsAll.positions
  );
  const checkIntersectiOnPositionsValuesKeys = createChecker(newPositionsValue);
  /* employees */

  newObserveFilterOptionsAll.employees =
    newObserveFilterOptionsAll.employees.filter(
      createFilterOnCheckers([
        checkIntersectiOndirectionsAndRegionsValuesKeys,
        checkIntersectiOnDepartmentsAndDepsValuesKeys,
        checkIntersectiOnOfficeValuesKeys,
        checkIntersectiOnPositionsValuesKeys,
      ])
    );

  const newEmployeesValue = filteredNewValues(
    employesValue,
    newObserveFilterOptionsAll.employees
  );

  return {
    newObserveFilterOptionsAll,
    filterDepartmentsAndOrgDepartmensValues: newDepartmentsAndDepsValue,
    filterOfficesValues: newOfficeValue,
    filterPositionsValues: newPositionsValue,
    filterEmployeesValues: newEmployeesValue,
    directionsAndRegionsValues,
  };
};
