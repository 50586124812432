import { Grid, Skeleton } from '@mui/material';

export const EvaluationPaperFormSkeleton = () => {
  return (
    <Grid
      container
      flexDirection={'column'}
      gap={5}
    >
      <Skeleton
        variant='rounded'
        height={35}
      />

      <Grid
        container
        flexDirection={'column'}
        gap={2}
      >
        <Skeleton
          variant='rounded'
          height={27}
          width={100}
        />
        <Skeleton
          variant='rounded'
          height={80}
        />
        <Skeleton
          variant='rounded'
          height={80}
        />
        <Skeleton
          variant='rounded'
          height={80}
        />
      </Grid>

      <Grid
        container
        flexDirection={'column'}
        gap={2}
      >
        <Skeleton
          variant='rounded'
          height={27}
          width={100}
        />
        <Skeleton
          variant='rounded'
          height={80}
        />
        <Skeleton
          variant='rounded'
          height={80}
        />
        <Skeleton
          variant='rounded'
          height={80}
        />
      </Grid>
    </Grid>
  );
};
