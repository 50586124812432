import {
  CoreSystemEnumsObjectActions,
  OnlineStoreResponsesFirebaseNotificationType,
  OnlineStoreResponsesFirebaseTopic,
} from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { Base64File } from 'shared/utils/files/fileListToArrayBase64';
import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';
import { array, mixed, object, ObjectSchema } from 'yup';
import { NotificationCreateFormValue, RecipientsFrom } from './types';

export const PUSH_NOTIFICATIONS_VALIDATION: ObjectSchema<NotificationCreateFormValue> =
  object({}).shape({
    distributionTitle: SCHEMA.STRING_REQUIRED,
    message: SCHEMA.STRING_REQUIRED,
    url: SCHEMA.STRING,

    /* image */
    imageAction: mixed<CoreSystemEnumsObjectActions>().required(),
    imageData: mixed<Base64File>().nullable(),
    imageUrl: SCHEMA.STRING.nullable(),

    /* recipients */
    recipientsFrom: mixed<RecipientsFrom>().required(),
    /* recipients - from topics */
    topics: array(mixed<OnlineStoreResponsesFirebaseTopic>().required())
      .required()
      .when('recipientsFrom', ([recipientsFrom], scheme) => {
        return scheme.test('topics', 'Выберите хотя бы одну тему', (topics) => {
          if (recipientsFrom === 'topics') {
            return topics.length > 0;
          }

          return true;
        });
      }),
    /* recipients - from file */
    fileAction: mixed<CoreSystemEnumsObjectActions>().required(),
    fileUrl: SCHEMA.STRING.nullable(),
    fileData: mixed<Base64File>()
      .nullable()
      .when(
        ['recipientsFrom', 'fileUrl'],
        ([recipientsFrom, fileUrl], scheme) => {
          return scheme.test('fileData', 'Загрузите файл', (fileData) => {
            if (recipientsFrom === 'file' && !fileUrl) {
              return Boolean(fileData);
            }

            return true;
          });
        }
      ),

    /* sending date */
    isSendingNow: SCHEMA.BOOLEAN.nonNullable(),
    dateTimeSending: SCHEMA.DATEJS.nullable().when(
      ['isSendingNow'],
      ([isSendingNow], scheme) => {
        return scheme.test(
          'isSendingNow',
          'Обязательное поле',
          (dateTimeSending) => {
            if (!isSendingNow) {
              return Boolean(dateTimeSending);
            }

            return true;
          }
        );
      }
    ),
    /* save for user */
    isSaveForUser: SCHEMA.BOOLEAN.nonNullable(),
    notificationType: mixed<OnlineStoreResponsesFirebaseNotificationType>()
      .nullable()
      .when(['isSaveForUser'], ([isSaveForUser], scheme) => {
        return scheme.test(
          'notificationType',
          'Выберите тип уведомления',
          (notificationType) => {
            if (isSaveForUser) {
              return Boolean(notificationType);
            }

            return true;
          }
        );
      }),
    notificationTypeValue: SCHEMA.STRING.when(
      ['isSaveForUser'],
      ([isSaveForUser], scheme) => {
        return scheme.test(
          'notificationType',
          'Обязательное поле',
          (notificationTypeValue) => {
            if (isSaveForUser) {
              return Boolean(notificationTypeValue);
            }

            return true;
          }
        );
      }
    ),
  });
