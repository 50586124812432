import { Button } from '@mui/material';
import { FC } from 'react';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import {
  DialogFormOpeningTemplates,
  useDialogFormCreateOpeningTemplates,
} from '../form-opening-tamplate';
import {
  DialogFormRunOpeningRetailProcess,
  useCreateDialogFormRunOpeningRetailProcess,
} from '../form-run-opening-retail-process';
import { useFilterOpeningRetailTemplates } from './useFilterOpeningRetailTemplates';

export const SearchBarOpeningRetailTemplates: FC = () => {
  const { onSubmit, open, isOpen, close } =
    useDialogFormCreateOpeningTemplates();
  const { onClearSearchText, onCompliteInput, searchText, setSearchText } =
    useFilterOpeningRetailTemplates();

  const {
    isOpenDialogRunProcess,
    onCloseDialogRunProcess,
    onOpenDialogRunProcess,
    handleSubmit,
  } = useCreateDialogFormRunOpeningRetailProcess();

  return (
    <UiSearchBar
      textValue={searchText}
      changeValue={setSearchText}
      onClear={onClearSearchText}
      onCompliteInput={onCompliteInput}
    >
      <Button
        variant='contained'
        color='customBlue'
        onClick={open}
      >
        Cоздать шаблон
      </Button>
      <DialogFormOpeningTemplates
        isOpen={isOpen}
        onClose={close}
        onSumbit={onSubmit}
      />
      <Button
        variant='contained'
        color='customBlue'
        onClick={onOpenDialogRunProcess}
      >
        Запустить процесс
      </Button>
      <DialogFormRunOpeningRetailProcess
        onSubmite={handleSubmit}
        isOpen={isOpenDialogRunProcess}
        onClose={onCloseDialogRunProcess}
      />
    </UiSearchBar>
  );
};
