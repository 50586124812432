import { AxiosError } from 'axios';
import { createEffect, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';

import { getTaxRatesCalculated } from 'shared/api/services-document-flow/services';
import { TaxRateCalculatedResponse } from 'shared/api/services-document-flow/types';
import {
  OnchangeTaxRate,
  UiDocumentFormTaxRatesAutocomplite,
} from 'shared/ui/UiDocumentFormTaxRatesAutocomplite';

import { documentFormEdit } from '../model';

const fetchTaxRatesEditOptionsFx = createEffect<
  typeof getTaxRatesCalculated,
  AxiosError<Error>
>(getTaxRatesCalculated);

const $taxRateEditOptions = createStore<TaxRateCalculatedResponse[]>([]).on(
  fetchTaxRatesEditOptionsFx.doneData,
  (_, response) => response.data
);

const totalSum = documentFormEdit.secondValid.map(
  (document) => document && document.totalSum
);

sample({
  clock: totalSum,
  filter: (totalSum) => Boolean(totalSum),
  fn: (totalSum) => ({ Sum: totalSum as number }),
  target: fetchTaxRatesEditOptionsFx,
});

export const DocumentFormEditTaxRatesAutocomplite: FC<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const options = useStore($taxRateEditOptions);
  const valueTaxRate =
    documentFormEdit.formSelects.useSelectValueOfKey('taxRate');

  const valueTaxSum =
    documentFormEdit.formSelects.useSelectValueOfKey('taxSum');

  const value =
    (valueTaxRate &&
      (valueTaxSum || valueTaxSum === 0) && {
        sum: valueTaxSum,
        ...valueTaxRate,
      }) ||
    null;
  const isCanEdit =
    documentFormEdit.formSelects.useSelectValueOfKey('canEditTax');

  const handleChangeTaxRate: OnchangeTaxRate = (event, value) => {
    if (typeof value === 'string' || typeof value === 'number') {
      documentFormEdit.api.setFiled({
        key: 'taxSum',
        value: value ? value : null,
      });
      documentFormEdit.api.setFiled({
        key: 'taxRate',
        value: null,
      });
    } else {
      documentFormEdit.api.setFiled({
        key: 'taxRate',
        value: value ? { ...value, title: value.title || '' } : null,
      });
      documentFormEdit.api.setFiled({
        key: 'taxSum',
        value: null,
      });
    }
  };

  useEffect(() => {
    if (options.length > 0 && value) {
      const find = options.find((item) => item.id === value.id);

      if (find?.sum !== value.sum) {
        documentFormEdit.api.setFiled({
          key: 'taxSum',
          value: find ? find.sum : null,
        });
      }
    }
  }, [options]);

  const isTaxCalculateRequired =
    documentFormEdit.formSelects.useSelectProcessValueOfKey(
      'isTaxCalculateRequired'
    );

  return (
    <UiDocumentFormTaxRatesAutocomplite
      disabled={!isTaxCalculateRequired || !isCanEdit || Boolean(disabled)}
      onChange={handleChangeTaxRate}
      options={options}
      required
      value={value || valueTaxSum}
    />
  );
};
