import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { RetailSelectDialog } from 'features/visit/RetailSelectDialog';
import { FC, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { DATE_FORMATS } from 'shared/date-helper/constants';
import { useViewModal } from 'shared/hooks/useViewModal';
import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import { getDayjs } from 'shared/tarnsform-helper/getDayjs';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { getObjById } from 'shared/tarnsform-helper/getObjById';
import { getObjByIds } from 'shared/tarnsform-helper/getObjByIds';
import { isOptionEqualToValueById } from 'shared/tarnsform-helper/isOptionEqualToValueById';
import { CustomAutocomplete, CustomTextField } from 'shared/ui/base-ui';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { FormLayout } from 'shared/ui/form';

import { getFullUserName } from 'entities/user/libs/getFullUserName';
import { useUserDataFetch } from 'entities/user/useUserDataFetch';
import { useGetCheckList } from 'entities/visit/options/useGetCheckList';
import { useGetEmployees } from 'entities/visit/options/useGetEmployees';
import { useGetRetails } from 'entities/visit/options/useGetRetails';

import { VisitDatePicker } from 'widgets/visits/forms/create-visit-from/components/VisitDatePicker';

import { CreateVisitFormFields, CreateVisitFormProps } from './type';

const FILTER = { IsOnlyAllowedRequired: true };

export const CreateVisitForm: FC<CreateVisitFormProps> = ({
  actions,
  onSubmit,
  initValue,
}) => {
  const { close, isOpen, open } = useViewModal();

  const { isLoading: isLoadingRetails, retails } = useGetRetails(FILTER);
  const { employees, isLoading: isEmployeesLoading } = useGetEmployees();
  const { checkLists, isLoading: isCheckListsLoading } = useGetCheckList();
  const { loading, user } = useUserDataFetch();

  const { control, handleSubmit } = useForm<CreateVisitFormFields>({
    defaultValues: initValue,
  });

  const selectedRetailsId = useWatch({ control, name: 'retailsIds' });
  const selectedRetails = useMemo(() => {
    return getObjByIds(retails, selectedRetailsId);
  }, [retails, selectedRetailsId]);

  const selectedDate = useWatch({ control, name: 'date' });

  return (
    <>
      <CustomBackdrop isLoading={loading} />
      <FormLayout
        onSubmit={handleSubmit(onSubmit, console.log)}
        actions={actions(handleSubmit)}
      >
        <CustomTextField
          label='Инициатор'
          value={getFullUserName(user)}
          InputProps={{ readOnly: true }}
        />

        <Controller
          control={control}
          name='date'
          render={({ field: { onChange, value } }) => (
            <VisitDatePicker
              onChange={(val) => {
                onChange(val?.format(DATE_FORMATS.server));
              }}
              value={getDayjs(value)}
              retails={selectedRetails}
            />
          )}
        />

        <Controller
          control={control}
          name='homies'
          render={({ field: { onChange, value } }) => (
            <CustomAutocomplete
              textFieldProps={{
                label: 'Участники посещений',
                placeholder: 'Выберите участников',
              }}
              multiple
              loading={isEmployeesLoading}
              value={getObjByIds(employees, value) || []}
              onChange={(_, val) => {
                onChange(getIdsFromArray(val) || []);
              }}
              options={employees}
              getOptionLabel={getEmployeTitle}
              isOptionEqualToValue={isOptionEqualToValueById}
            />
          )}
        />

        <Controller
          control={control}
          name='checklistId'
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <CustomAutocomplete
              textFieldProps={{
                label: 'Оценочный лист *',
                placeholder: 'Выберите оценочный лист',
                error: invalid,
              }}
              loading={isCheckListsLoading}
              value={getObjById(checkLists, value)}
              onChange={(_, val) => {
                onChange(val?.id);
              }}
              options={checkLists}
              getOptionLabel={(op) => op.title || '~~Error~~'}
              isOptionEqualToValue={isOptionEqualToValueById}
            />
          )}
        />

        <Controller
          control={control}
          name='retailsIds'
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => {
            const date = getDayjs(selectedDate) || undefined;

            return (
              <Grid
                container
                gap={2}
              >
                {!value || value.length === 0 ? (
                  <LoadingButton
                    loading={isLoadingRetails}
                    startIcon={<Add />}
                    onClick={open}
                    variant='contained'
                    sx={{ ml: [1, 0] }}
                    color={invalid ? 'error' : 'primary'}
                  >
                    Выбрать Аптеки
                  </LoadingButton>
                ) : (
                  <>
                    <CustomAutocomplete
                      sx={{ flex: 1 }}
                      textFieldProps={{ label: 'Аптеки *' }}
                      options={retails}
                      onChange={(_, val) => {
                        onChange(getIdsFromArray(val));
                      }}
                      multiple
                      value={getObjByIds(retails, value) || []}
                      getOptionLabel={(val) => val.title || ''}
                    />
                    <Button
                      onClick={open}
                      variant='outlined'
                    >
                      Изменить
                    </Button>
                  </>
                )}

                <RetailSelectDialog
                  isOpen={isOpen}
                  onClose={close}
                  multiple
                  value={getObjByIds(retails, value) || []}
                  onChange={(val) => {
                    onChange(getIdsFromArray(val));
                  }}
                  selectedDate={date}
                />
              </Grid>
            );
          }}
        />
      </FormLayout>
    </>
  );
};
