import { createStore } from 'effector';

import { authorizationFx, logOut } from './user/authorization-effector';
import { chekcIsValidTokenFx } from './user/check-is-valid-token';

export const $loadingApp = createStore({
  token: { isValid: false, loaded: false },
})
  .on(chekcIsValidTokenFx.done, (state) => {
    return { ...state, token: { isValid: true, loaded: true } };
  })
  .on(chekcIsValidTokenFx.fail, (state) => {
    return { ...state, token: { isValid: false, loaded: true } };
  })
  .on(authorizationFx.doneData, (state) => {
    return { ...state, token: { isValid: true, loaded: true } };
  })
  .on(logOut, () => {
    return { token: { isValid: false, loaded: true } };
  });
