import {
  prepareDataToFetchStage5,
  prepareDataToInitStage5,
  ProblemsForm,
  ProblemsFormI,
  ProblemsFormSkeleton,
} from 'features/visit/Stage 5';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  postChecklistsVisitingChecklistIdComplete,
  postChecklistsVisitingChecklistIdStages4,
} from 'shared/api/visit/services';
import { ALL_ROUTES } from 'shared/routes/constants/types';

import { useChecklist } from 'entities/visit/checkList/context/ChecklistContext';

import { STAGES } from './VisitLayout';

export const Stage5Page = () => {
  const navigate = useNavigate();
  const { checkList, getCheckList, isLoading, stageNavigate, status } =
    useChecklist();

  const canComplete = useMemo(() => {
    if (checkList) {
      const { isStageOneComplete, isStageTwoComplete, isStageThreeComplete } =
        checkList;

      if (checkList.isCompetitorComparison) {
        return isStageOneComplete && isStageTwoComplete && isStageThreeComplete;
      } else {
        return isStageThreeComplete;
      }
    } else return false;
  }, [checkList]);

  const onSubmit = async (data: ProblemsFormI) => {
    if (checkList) {
      if (!checkList.canEdit) {
        if (data.problems.length > 0) stageNavigate(STAGES.stage6);

        return;
      }

      try {
        const fetchData = await prepareDataToFetchStage5(data);

        await postChecklistsVisitingChecklistIdStages4(checkList.id, fetchData);
        await getCheckList();

        stageNavigate(STAGES.stage6);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onComplite = async () => {
    if (checkList) {
      if (!checkList.canEdit) {
        navigate(
          '/' + ALL_ROUTES.visitingPharmacies + '/' + ALL_ROUTES.complite
        );

        return;
      }
      try {
        await postChecklistsVisitingChecklistIdComplete(checkList.id);
        navigate(
          '/' + ALL_ROUTES.visitingPharmacies + '/' + ALL_ROUTES.complite
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const initVal = useMemo(() => {
    return prepareDataToInitStage5(checkList);
  }, [checkList]);

  const isReq = useMemo(() => {
    if (checkList) {
      if (checkList.blocks && checkList.blocks.length > 0)
        return !!checkList.blocks.find((el) => {
          const val = !!el.items?.find((el2) => el2.value === false);

          return val;
        });
      else {
        return false;
      }
    } else {
      return false;
    }
  }, [checkList]);

  if (isLoading && !checkList) return <ProblemsFormSkeleton />;
  if (!checkList || status === 400) return <h1>Такого чеклиста нет</h1>;

  return (
    <ProblemsForm
      onSubmit={onSubmit}
      onDraftSubmit={onSubmit}
      isReq={isReq}
      initValue={initVal}
      onComplite={onComplite}
      canComplete={canComplete}
      disableAll={!checkList.canEdit}
    />
  );
};
