import { FC } from 'react';
import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTableRow,
} from 'shared/ui/table';

import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';

import { ProtocolOpeningRunningTasksTableProps } from './types';

export const ProtocolOpeningRunningTasksTable: FC<
  ProtocolOpeningRunningTasksTableProps
> = ({ isLoading, isUpdate, data, visibleFields, fields, onChangeFields }) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={1} />}
      header={
        <UiTableHeader
          viewCells={visibleFields}
          allCells={fields}
          setViewCells={onChangeFields}
        />
      }
      body={data.map((task) => (
        <UiTableRow
          key={task.guid}
          hover
        >
          {visibleFields.map((el) => (
            <UiTableCell
              key={el.field + task.guid}
              {...el.getTableCellProps()}
            >
              {el.renderComponent(task) || 'Error'}
            </UiTableCell>
          ))}
        </UiTableRow>
      ))}
    />
  );
};
