import { sample } from 'effector';
import { useStore } from 'effector-react';
import { LayoutWithDocumentActionsModal } from 'features/document/document-actions';
import { postDocumentsDocumentIdDoActionFx } from 'features/document/document-actions/api/document-actions';
import {
  DocumentObserveFilterModal,
  useDocumentObserveFilterModal,
  useFilterDocumentFlowObservePage,
  useUrlStateDocumentObserveFilter,
} from 'features/document/filters/document-observe-filter';
import { documentObserverFilter } from 'features/document/filters/document-observe-filter/models/model-filter';

import { useEffect } from 'react';

import { useTableCellsFromLocalStorage } from 'shared/ui/table';

import { deleteDocumentIdFx } from 'entities/document/api/document-id-effector';
import { getDocumentsObserveFx } from 'entities/document/api/document-list-effector';
import { $documentObserveList } from 'entities/document/model/document-observe-list';

import { renderDocumentActionsContextMenu } from 'widgets/document/document-table';
import { AdeptiveDocumentTable } from 'widgets/document/document-table/AdeptiveDocumentTable';

import dayjs from 'dayjs';
import { compliteActiconCloseDocument } from 'features/document/document-actions/deprecated-effector';
import { DownloadExcelFileFromListWithHook } from 'features/download-file-excel-from-list/DownloadExcelFileFromListWithHook';
import { useLazyDownloadFileFromListDocumentObserveQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';
import { DocumentListItemResponse } from 'shared/api/services-document-flow/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';

import { Tables } from 'shared/utils/controllers';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { LayoutPageDocumentsListWithFilterBar } from './layouts';

sample({
  clock: [
    deleteDocumentIdFx.done,
    postDocumentsDocumentIdDoActionFx.done,
    compliteActiconCloseDocument,
  ],
  target: documentObserverFilter.upDateList,
});

const usePaginationDocumentRowObserve = () => {
  return useStore(documentObserverFilter.headerPaginations);
};

const createFileName = () =>
  `Документооборот(наблюдаю) от ${dayjs().format(
    DATE_FORMATS.clientWithTime
  )}.xlsx`;

export const PageDocumentObserve = () => {
  const [allFields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<DocumentListItemResponse>(Tables.OBSERVE_DOC);

  const { filter } = useUrlStateDocumentObserveFilter();
  const {
    searchInput,
    handleChangePage,
    handleChangePageSize,
    handleChangeSearchText,
    handleCompliteChangeSearchText,
    pageSize,
    currentPage,
  } = useFilterDocumentFlowObservePage();

  const documentsList = useStore($documentObserveList);
  const isLoading = useStore(getDocumentsObserveFx.pending);

  const pagination = usePaginationDocumentRowObserve();

  useEffect(() => {
    documentObserverFilter.events.setAll(filter);
  }, [filter]);

  const {
    isLoadingInitDocumentObserveFilterModalForm,
    isOpenDocumentObserveFilterModalForm,
    onCloseDocumentObserveFilterModalForm,
    onOpenDocumentObserveFilterModalForm,
    onSubmitDocumentObserveFilterModal,
    initialValueDocumentObserveFilterModalForm,
  } = useDocumentObserveFilterModal();

  return (
    <LayoutPageDocumentsListWithFilterBar
      inputSearch={searchInput}
      onChangeInputSearch={handleChangeSearchText}
      onCompliteInput={handleCompliteChangeSearchText}
      FilterAction={
        <>
          <DownloadExcelFileFromListWithHook
            filter={filter}
            useLazyDownloadFileFromList={
              useLazyDownloadFileFromListDocumentObserveQuery
            }
            createFileName={createFileName}
          />
          <FilterButton onClick={onOpenDocumentObserveFilterModalForm} />
          <DocumentObserveFilterModal
            isOpen={isOpenDocumentObserveFilterModalForm}
            onClose={onCloseDocumentObserveFilterModalForm}
            isLoading={isLoadingInitDocumentObserveFilterModalForm}
            onSubmit={onSubmitDocumentObserveFilterModal}
            initialValue={initialValueDocumentObserveFilterModalForm}
          />
        </>
      }
    >
      <LayoutWithDocumentActionsModal>
        <AdeptiveDocumentTable
          data={documentsList || EMPT_ARR}
          isLoading={isLoading}
          page={currentPage}
          onChangePage={handleChangePage}
          count={pagination.TotalCount}
          limit={pageSize}
          onChangeLimit={handleChangePageSize}
          fields={allFields}
          visibleFields={visibleFields}
          onChangeFields={setFields}
          renderActionsContextMenu={renderDocumentActionsContextMenu}
        />
      </LayoutWithDocumentActionsModal>
    </LayoutPageDocumentsListWithFilterBar>
  );
};
