import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useGetAdaptationProcessesRunParamsQuery } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import {
  AutocompliteWithController,
  DatePikerWithController,
} from 'shared/fields-with-contoller';

import { useGetAdaptationStagesQuery } from 'shared/api/service-adaptation/rtk/adaptationStagesProviderApi';
import { CustomTextField } from 'shared/ui/base-ui';
import { DialogForForm, FormLayout } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import {
  createIsOptionEqualToValueByKey,
  createLabelOnKey,
  createRenderOptionByKey,
} from 'shared/utils/autocomplite-helpers';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import {
  DialogPreparatoryRunAdaptationProcessFormProps,
  PreparatoryRunAdaptationProcessFormValue,
} from './types';
import { VALIDATE_SCHEMA_PREPARATORY_RUN_ADAPTATION_PROCESS } from './validate-schema';

export const DialogPreparatoryRunAdaptationProcessForm: FC<
  DialogPreparatoryRunAdaptationProcessFormProps
> = ({ isOpen, onClose, employeeGuid, employeeName, onSubmit }) => {
  const {
    data: adaptationProcessesRunParams,
    isLoading: isLoadingAdaptationProcessesRunParams,
  } = useGetAdaptationProcessesRunParamsQuery(
    {
      params: {
        employeeGuid,
      },
    },
    { skip: !isOpen }
  );

  const { control, setValue, handleSubmit } =
    useForm<PreparatoryRunAdaptationProcessFormValue>({
      defaultValues: {
        mentor: null,
        adaptation: null,
        skipStages: [],
        countdownDate: null,
      },
      resolver: yupResolver(VALIDATE_SCHEMA_PREPARATORY_RUN_ADAPTATION_PROCESS),
    });

  const selectedAdaptation = useWatch({ control, name: 'adaptation' });

  const { data: skipStagesOptions, isLoading: isLoadingSkipStagesOptions } =
    useGetAdaptationStagesQuery(selectedAdaptation?.guid as string, {
      skip: !selectedAdaptation,
      selectFromResult: ({ data, ...other }) => {
        if (!data) return { ...other, data: EMPT_ARR };

        return {
          ...other,
          data: data.map((item) => ({
            ...item,
            stageNumber: `${item.stageNumber} Этап`,
          })),
        };
      },
    });

  useEffect(() => {
    if (adaptationProcessesRunParams) {
      if (adaptationProcessesRunParams.countdownDate) {
        setValue(
          'countdownDate',
          dayjs(adaptationProcessesRunParams.countdownDate)
        );
      }
    }
  }, [adaptationProcessesRunParams]);

  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Параметры запуска процесса' />}
    >
      <FormLayout
        onSubmit={handleSubmit(onSubmit)}
        actions={[
          <LoadingButton
            key={1}
            variant='contained'
            type='submit'
            disabled={isLoadingAdaptationProcessesRunParams}
          >
            Продолжить
          </LoadingButton>,
          <Button
            key={2}
            variant={'contained'}
            color='customGrey'
            onClick={onClose}
          >
            Отменить
          </Button>,
        ]}
      >
        <CustomTextField
          value={employeeName}
          disabled
          label='Сотрудник'
        />
        <AutocompliteWithController
          control={control}
          loading={isLoadingAdaptationProcessesRunParams}
          disabled={isLoadingAdaptationProcessesRunParams}
          options={adaptationProcessesRunParams?.adaptations || EMPT_ARR}
          renderOption={createRenderOptionByKey({
            keyGuid: 'guid',
            keyLabel: 'title',
          })}
          isOptionEqualToValue={createIsOptionEqualToValueByKey('guid')}
          getOptionLabel={createLabelOnKey('title')}
          name={'adaptation'}
          textFieldProps={{
            label: 'Шаблон',
          }}
        />
        <AutocompliteWithController
          control={control}
          loading={isLoadingAdaptationProcessesRunParams}
          disabled={isLoadingAdaptationProcessesRunParams}
          renderOption={createRenderOptionByKey({
            keyGuid: 'guid',
            keyLabel: 'name',
          })}
          isOptionEqualToValue={createIsOptionEqualToValueByKey('guid')}
          getOptionLabel={createLabelOnKey('name')}
          options={adaptationProcessesRunParams?.mentors || EMPT_ARR}
          name={'mentor'}
          textFieldProps={{
            label: 'Наставник',
          }}
        />
        <AutocompliteWithController
          control={control}
          loading={
            isLoadingAdaptationProcessesRunParams || isLoadingSkipStagesOptions
          }
          disabled={
            isLoadingAdaptationProcessesRunParams ||
            isLoadingSkipStagesOptions ||
            !selectedAdaptation
          }
          renderOption={createRenderOptionByKey({
            keyGuid: 'guid',
            keyLabel: 'stageNumber',
          })}
          multiple
          isOptionEqualToValue={createIsOptionEqualToValueByKey('guid')}
          getOptionLabel={createLabelOnKey('stageNumber')}
          options={skipStagesOptions || EMPT_ARR}
          name={'skipStages'}
          textFieldProps={{
            label: 'Пропустить шаги',
          }}
        />
        <DatePikerWithController
          control={control}
          disabled={isLoadingAdaptationProcessesRunParams}
          name={'countdownDate'}
          label='Дата отсчета дней (Дата трудоустройства)'
        />
      </FormLayout>
    </DialogForForm>
  );
};
