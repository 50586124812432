import { transformResponseError } from 'shared/api/libs';
import { adaptationApi } from '../adaptationApi';
import {
  AdaptationManagerRequestsStageTaskCreate,
  AdaptationManagerRequestsStageTaskUpdate,
  AdaptationManagerResponsesGetListPosition,
  AdaptationManagerResponsesGetListStageTask,
  AdaptationManagerResponsesGetStageTask,
  AdaptationsStagesStageTasksDeleteParams,
} from '../adaptationAutogenerateApi';
import { adaptationProviderApi } from './adaptationProviderApi';

const adaptationStageTasksProviderApi = adaptationProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /** Получение списка шаблонов задач для этапа */
    getAdaptationStageTasks: build.query<
      AdaptationManagerResponsesGetListStageTask[],
      { adaptationGuid: string; stageGuid: string }
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.stageTasks.AdaptationsStagesStageTasksDetail(
              args.adaptationGuid,
              args.stageGuid
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [
          'stage_task_list',
          ...res.map((el) => ({ type: 'stage_task' as const, id: el.guid })),
        ];
      },
    }),
    /* ======================================= */

    /** Получение информации по шаблону задачи */
    getDetailedAdaptationStageTask: build.query<
      AdaptationManagerResponsesGetStageTask,
      { adaptationGuid: string; stageGuid: string; stageTaskGuid: string }
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.stageTasks.AdaptationsStagesStageTasksDetail2(
              args.adaptationGuid,
              args.stageGuid,
              args.stageTaskGuid
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [{ type: 'stage_task', id: res.guid }];
      },
    }),
    /* ==================================== */

    /** Создание шаблона задачи */
    createAdaptationStageTask: build.mutation<
      AdaptationManagerResponsesGetStageTask,
      {
        adaptationGuid: string;
        stageGuid: string;
        data: AdaptationManagerRequestsStageTaskCreate;
      }
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.stageTasks.AdaptationsStagesStageTasksCreate(
              args.adaptationGuid,
              args.stageGuid,
              args.data
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: ['stage_task_list'],
    }),
    /* =========================== */

    /** Удаление шаблонов задач */
    deleteAdaptationStageTask: build.mutation<
      void,
      AdaptationsStagesStageTasksDeleteParams
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.stageTasks.AdaptationsStagesStageTasksDelete(
              args
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: ['stage_task_list'],
    }),
    /* ======================== */

    /** Обновление шаблона задачи */
    updateAdaptationStageTask: build.mutation<
      AdaptationManagerResponsesGetStageTask,
      {
        adaptationGuid: string;
        stageGuid: string;
        stageTaskGuid: string;
        data: AdaptationManagerRequestsStageTaskUpdate;
      }
    >({
      queryFn: async (args) => {
        try {
          const response =
            await adaptationApi.stageTasks.AdaptationsStagesStageTasksUpdate(
              args.adaptationGuid,
              args.stageGuid,
              args.stageTaskGuid,
              args.data
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: (res) => {
        if (!res || 'error' in res) return [];

        return [{ type: 'stage_task', id: res.guid }];
      },
    }),
    /* =========================== */

    /** Получение списка должностей */
    getAdaptationEmployeePositions: build.query<
      AdaptationManagerResponsesGetListPosition[],
      void
    >({
      queryFn: async () => {
        try {
          const response = await adaptationApi.positions.PositionsList();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: ['position'],
    }),
    /* =========================== */
  }),
});

export const {
  useGetAdaptationStageTasksQuery,
  useCreateAdaptationStageTaskMutation,
  useDeleteAdaptationStageTaskMutation,
  useGetAdaptationEmployeePositionsQuery,
  useGetDetailedAdaptationStageTaskQuery,
  useLazyGetDetailedAdaptationStageTaskQuery,
  useUpdateAdaptationStageTaskMutation,
} = adaptationStageTasksProviderApi;
