import { E_ModuleGuids } from 'shared/moduleGuids';
import { ALL_ROUTES, Routes } from './types';

export const APTEK_ALEGKO: Routes = {
  title: 'Аптека Легко',
  path: ALL_ROUTES.aptekaLegko,
  isWork: true,
  children: [
    {
      title: 'Push-уведомления',
      path: ALL_ROUTES.aptekaLegkoPush,
      isWork: true,
      description: null,
      icon: null,
      fullPath: '',
      children: [
        {
          title: 'Отправить',
          path: ALL_ROUTES.aptekaLegkoPushCreated,
          isWork: true,
          description: null,
          icon: null,
          fullPath: '',
          children: null,
        },
        {
          title: 'Отправить',
          path: ALL_ROUTES.aptekaLegkoPushEdit,
          isWork: true,
          description: null,
          icon: null,
          fullPath: '',
          children: null,
        },
        {
          title: '',
          path: ALL_ROUTES.aptekaLegkoPushList,
          isWork: true,
          description: null,
          icon: null,
          fullPath: '',
          children: null,
        },
      ],
      premission: {
        permissionGuid: E_ModuleGuids.alPushNotifications,
        fields: ['isReadable'],
      },
    },
    {
      title: 'Баннеры',
      path: ALL_ROUTES.aptekaLegkoBanners,
      isWork: true,
      description: null,
      icon: null,
      fullPath: '',
      children: [
        {
          title: 'Слайдер',
          path: ALL_ROUTES.aptekaLegkoBannersSliders,
          isWork: true,
          description: null,
          icon: null,
          fullPath: '',
          children: [
            {
              title: 'Список',
              path: ALL_ROUTES.aptekaLegkoBannersSlidersList,
              isWork: true,
              description: null,
              icon: null,
              fullPath: '',
              children: null,
            },
            {
              title: 'Добавить',
              path: ALL_ROUTES.aptekaLegkoBannersSlidersCreated,
              isWork: true,
              description: null,
              icon: null,
              fullPath: '',
              children: null,
            },
            {
              title: 'Редактировать',
              path: ALL_ROUTES.aptekaLegkoBannersSlidersEdit,
              isWork: true,
              description: null,
              icon: null,
              fullPath: '',
              children: null,
            },
          ],
          premission: {
            permissionGuid: E_ModuleGuids.aptekaLegkoBannerSlider,
            fields: ['isReadable'],
          },
        },
        {
          title: 'Бренды',
          path: ALL_ROUTES.aptekaLegkoBannersBrand,
          isWork: true,
          description: null,
          icon: null,
          fullPath: '',
          children: null,
          premission: {
            permissionGuid: E_ModuleGuids.aptekaLegkoBannerBrand,
            fields: ['isReadable'],
          },
        },
      ],
    },
    {
      title: 'Колл-центр',
      path: ALL_ROUTES.aptekaLegkoCallCenter,
      isWork: true,
      description: null,
      icon: null,
      fullPath: '',
      target: '_blank',
      children: null,
      isOtherResource: true,
    },
    {
      title: 'Статьи',
      path: ALL_ROUTES.aptekaLegkoArticles,
      isWork: true,
      description: null,
      icon: null,
      fullPath: '',
      target: '_blank',
      children: null,
      isOtherResource: true,
    },
  ],
  description: 'Модули Аптека Легко',
  icon: null,
  fullPath: '',
};
