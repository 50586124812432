import { EventCreateRequest } from 'shared/api/visit/types';

import { CreateOtherEventFormFields } from '../type';

export const convertCreateOtherEventFormToFetch = (
  form: CreateOtherEventFormFields
): EventCreateRequest => {
  return {
    directionId: form.directionId,
    endDate: form.dateEnd,
    startDate: form.dateStart,
    isConference: false,
    isVisitingBlocked: form.isVisitingBlocked,
    members: form.homiesIds || [],
    title: form.title,
  };
};
