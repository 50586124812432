type IsDisabledFieldFun = <T>(
  disabledFieldsArray: (keyof T)[] | undefined,
  field: keyof T
) => boolean;

export const isDisabledField: IsDisabledFieldFun = (
  disabledFieldsArray,
  field
) => {
  if (!disabledFieldsArray) return false;

  return disabledFieldsArray.some((fieldFromArray) => fieldFromArray === field);
};
