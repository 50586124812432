// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { Button } from '@mui/material';
import { createEvent, createStore, sample } from 'effector';
import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import {
  UiDocumentFormProcessPostDocument,
  UiItemProcess,
} from 'shared/ui/UiDocumentFormProcessPostDocument';

import { documentFormEdit } from './model';

const $isOpen = createStore(false);

export const onOpen = createEvent();

const onClose = createEvent();

$isOpen.on(onOpen, () => true);
$isOpen.on(onClose, () => false);

sample({ clock: documentFormEdit.putDocument, target: onOpen });

const isEmptyArr = (arr: any[] | null) => {
  if (!arr) return true;
  if (arr.length === 0) return true;

  return false;
};

export const DocumentFormProcessPutDocument = () => {
  const isOpens = useStore($isOpen);
  const idDocument = documentFormEdit.formSelects.useSelectValueOfKey('id');
  const navigate = useNavigate();
  const progressPutDocument = useStore(documentFormEdit.progresses.putDocument);
  const progressSaveReasonDocument = useStore(
    documentFormEdit.progresses.saveReasonFiles
  );
  const deleteReasonFiles = useStore(
    documentFormEdit.progresses.deleteReasonFiles
  );
  const progressSaveCloseDocument = useStore(
    documentFormEdit.progresses.saveCloseFiles
  );
  const deleteCloseFiles = useStore(
    documentFormEdit.progresses.deleteCloseFiles
  );
  const sendDocument = useStore(documentFormEdit.progresses.sendDocument);
  const isNeedDoAction = useStore(
    documentFormEdit.isNeed.isNeedDoActionDocuments
  );
  const totalComplited =
    progressPutDocument.isComplite &&
    isEmptyArr(progressSaveReasonDocument.filter((item) => !item.isComplite)) &&
    isEmptyArr(deleteReasonFiles.filter((item) => !item.isComplite)) &&
    isEmptyArr(progressSaveCloseDocument.filter((item) => !item.isComplite)) &&
    isEmptyArr(deleteCloseFiles.filter((item) => !item.isComplite)) &&
    (!isNeedDoAction || sendDocument.isComplite);

  return (
    <UiDocumentFormProcessPostDocument
      isOpen={isOpens}
      ItemsProcess={
        <>
          <UiItemProcess
            loading={progressPutDocument.isLoading}
            complited={progressPutDocument.isComplite}
            error={progressPutDocument.error}
            onRepeat={() => documentFormEdit.putDocument(isNeedDoAction)}
            title={'Сохранение документа'}
          />
          {progressSaveReasonDocument.map((item) => {
            return (
              <UiItemProcess
                key={item.name}
                loading={item.isLoading}
                complited={item.isComplite}
                error={item.error}
                onRepeat={() => {}}
                title={`Сохранение файла - ${item.name}`}
              />
            );
          })}
          {deleteReasonFiles.map((item) => {
            return (
              <UiItemProcess
                key={item.id}
                loading={item.isLoading}
                complited={item.isComplite}
                error={item.error}
                onRepeat={() =>
                  documentFormEdit.repeatDeleteReasonFiles(item.id)
                }
                onDeletedFiles={() =>
                  documentFormEdit.removeFilesFromDeleteStore(item.id)
                }
                title={`Удаление файла - ${item.id}`}
              />
            );
          })}
          {progressSaveCloseDocument.map((item) => {
            return (
              <UiItemProcess
                key={item.name}
                loading={item.isLoading}
                complited={item.isComplite}
                error={item.error}
                onRepeat={() => {}}
                title={`Сохранение закрывающего документа - ${item.name}`}
              />
            );
          })}
          {deleteCloseFiles.map((item) => {
            return (
              <UiItemProcess
                key={item.id}
                loading={item.isLoading}
                complited={item.isComplite}
                error={item.error}
                onRepeat={() =>
                  documentFormEdit.repeatDeleteCloseFiles(item.id)
                }
                onDeletedFiles={() =>
                  documentFormEdit.removeFilesFromDeletCloseStore(item.id)
                }
                title={`Удаление закрывающего документа  - ${item.id}`}
              />
            );
          })}
          {isNeedDoAction && (
            <UiItemProcess
              loading={sendDocument.isLoading}
              complited={sendDocument.isComplite}
              error={sendDocument.error}
              onRepeat={() => documentFormEdit.postDocumentSend()}
              title={'Отправка на согласование'}
            />
          )}
        </>
      }
      ButtonsBottom={
        <>
          <Button
            size='large'
            variant='contained'
            disabled={false}
            sx={{ flexBasis: ['50%', '48%'], borderRadius: [0, 1] }}
            onClick={() => {
              navigate(`/document-flow/${idDocument}`);
              onClose();
              idDocument &&
                totalComplited &&
                documentFormEdit.updateDocument(idDocument);
            }}
          >
            Перейти к документу
          </Button>
          <Button
            variant='contained'
            size='large'
            color='customGrey'
            sx={{ flexBasis: ['50%', '48%'], borderRadius: [0, 1] }}
            onClick={() => {
              onClose();
              idDocument &&
                totalComplited &&
                documentFormEdit.updateDocument(idDocument);
            }}
          >
            Закрыть
          </Button>
        </>
      }
    />
  );
};
