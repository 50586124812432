import { Box } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import { SectionForMainPageProps } from './type';

export const SectionForMainPage: FC<SectionForMainPageProps> = ({
  children,
  title,
  mainProps,
}) => {
  return (
    <Box
      minWidth={300}
      sx={mainProps}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: theme.palette.primary.main,
          color: '#fff',
          fontWeight: '700',
          fontSize: '18px',
          //height: 45,
          textAlign: 'center',
          borderRadius: '10px',
          marginBottom: 3,
          paddingY: 1,
          paddingX: 2,
        }}
      >
        {title}
      </Box>
      {children}
    </Box>
  );
};
