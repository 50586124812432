import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isLateDateInfo } from 'entities/task/libs/isLateDateInfo';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { GetListBoardTask, StatusModel } from 'shared/api/services/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { TaskCard } from 'shared/ui/TaskCard';
import { getTaskCardStatus } from 'shared/ui/TaskCardStatusIcon';
import { BoardTaskCardProps } from './type';

const getTypoProps = (info: { isRed: boolean; isPink: boolean }) => {
  if (info.isRed) return { color: 'red' };
  if (info.isPink)
    return {
      sx: {
        background: 'rgba(255, 100, 100, 0.2)',
        borderRadius: 2,
        pl: 1,
      },
    };

  return {};
};

interface ColoredDateProps extends PropsWithChildren {
  task: GetListBoardTask;
}

const ColoredDate: FC<ColoredDateProps> = ({ task, children }) => {
  if (!task.dateFinish || task.status.id === 5)
    return <Typography>{children}</Typography>;

  const info = isLateDateInfo(task.dateFinish);

  return <Typography {...getTypoProps(info)}>{children}</Typography>;
};

const getDate = (date?: string): string | null => {
  const dateInDayjs = dayjs(date);
  const isValidStartDate = dateInDayjs.isValid();

  if (isValidStartDate) return `${dateInDayjs.format(DATE_FORMATS.client)}`;
  else return null;
};

export const BoardTaskCard: FC<BoardTaskCardProps> = ({
  task,
  onClick,
  onClickStatus,
  options,
  onTitleClick,
  cardProps,
  onAvatarClick,
  isLoading,
  isSelected,
  isGhost,
}) => {
  const subText = useMemo(() => {
    const start = getDate(task.dateStart);
    const end = getDate(task.dateFinish);

    if (!start && !end) return '';
    if (!start) return 'До ' + end;
    if (!end) return 'От ' + start;

    return `${start || ''} - ${end || ''}`;
  }, [task]);

  const [status, setStatus] = useState<StatusModel | undefined | null>(
    task.status
  );

  useEffect(() => {
    if (status?.id !== task.status.id) setStatus(task.status);
  }, [task]);

  const disableStatus = useMemo(() => {
    return (
      status?.id === task.accessActions.nextStatus?.id ||
      task.accessActions.nextStatus === null
    );
  }, [task, status]);

  return (
    <TaskCard
      cardProps={cardProps}
      actions={options}
      executor={task.executor}
      status={getTaskCardStatus(status || task.status)}
      title={task.taskTitle}
      subTitle={<ColoredDate task={task}>{subText}</ColoredDate>}
      isLoading={isLoading}
      isSelected={isSelected}
      isGhost={isGhost}
      onClick={(e) => {
        // e.preventDefault();
        onClick?.(task.taskGuid, e);
      }}
      onClickStatus={(e) => {
        if (onClickStatus) {
          e.stopPropagation();
          setStatus(task.accessActions.nextStatus);
          onClickStatus(task.taskGuid, e);
        }
      }}
      disableStatus={disableStatus}
      onTitleClick={(e) => {
        if (onTitleClick) {
          e.stopPropagation();
          onTitleClick(task.taskGuid, e);
        }
      }}
      onAvatarClick={(e) => {
        if (onAvatarClick) {
          e.stopPropagation();
          onAvatarClick(task.taskGuid, e);
        }
      }}
    />
  );
};
