import { TextField } from '@mui/material';
import { useStore } from 'effector-react';
import { FC } from 'react';

import { CounterpartyModel } from 'shared/api/services-document-flow/types';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormEdit } from '../model';
import { $counterpartyOptions } from './models';

export const DocumentFormEditCounterPartyReasonCode: FC = () => {
  const [isMobile] = useDevice();
  const options = useStore($counterpartyOptions);
  const value =
    documentFormEdit.formSelects.useSelectValueOfKey('counterparty');
  const inputValue = documentFormEdit.formSelects.useSelectValueOfKey(
    'counterpartyRegReasonCode'
  );
  const isCanEdit = documentFormEdit.formSelects.useSelectValueOfKey(
    'canEditCounterparty'
  );
  const inputValueReg = documentFormEdit.formSelects.useSelectValueOfKey(
    'counterpartyTaxNumber'
  );
  const onChangeCounterParty = (
    event: React.SyntheticEvent<Element, Event>,
    value: CounterpartyModel | string | null
  ) => {
    if (typeof value === 'string') {
      /* documentFormModel.formEvent.setField({ key: "counterpartyRegReasonCode", value: value }); */
    } else {
      documentFormEdit.api.setFiled({
        key: 'counterparty',
        value: value ? value : null,
      });
    }
  };

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        disabled: !isCanEdit,
        freeSolo: true,
        onChange: onChangeCounterParty,
        value: value,
        options: options.filter((item) => item.regReasonCode),
        inputValue: inputValue || '',
        onInputChange: (event, value) => {
          documentFormEdit.api.setFiled({
            key: 'counterpartyRegReasonCode',
            value: value,
          });
        },
        renderInput: (params) => (
          <TextField
            {...params}
            disabled={!isCanEdit}
            margin={'none'}
            size='small'
            label={isMobile ? '' : 'КПП'}
            placeholder='Введите КПП контрагента'
          />
        ),
        filterOptions: (options) => {
          return options.filter(
            (option) => option.taxNumber?.includes(inputValueReg || '')
          );
        },
        getOptionLabel: (option) => {
          if (typeof option === 'string') {
            return option;
          } else {
            return option.regReasonCode || '';
          }
        },
        renderOption: (props, option) => (
          <li
            {...props}
            key={option.id}
          >
            {option.regReasonCode}
          </li>
        ),
        isOptionEqualToValue: (option, value) => option.id === value.id,
      }}
      sx={{ flexBasis: ['100%', '45%'] }}
      label='КПП'
    />
  );
};
