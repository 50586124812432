import { EventGetListResponse } from 'shared/api/visit/types';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';

import { CreateConferenceEventFormFields } from '../type';

export const convertEventToConferencEventForm = (
  data?: EventGetListResponse
): CreateConferenceEventFormFields | undefined => {
  if (!data) return undefined;

  return {
    dateFinish: data.endDate,
    dateStart: data.startDate,
    departmentId: data.department.id,
    directionId: data.direction.id,
    homiesIds: getIdsFromArray(data.members) || [],
  };
};
