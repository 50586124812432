import { FC } from 'react';

import { documentFormModel } from 'entities/document-form';

import { textNormSumArticle, UiTotalSumArticle } from './UiTotalSumArticle';

export const ExpensesFormTotalSumArticle: FC<{ id: number }> = ({ id }) => {
  const sum = documentFormModel.useSelectArticleIdFormModel(id, 'sum');
  const normSumArticle = documentFormModel.useSelectorArticleIdFirstValid(
    id,
    'normSum'
  );
  const notValid = Boolean(normSumArticle && sum && normSumArticle < sum);

  return (
    <UiTotalSumArticle
      value={sum || null}
      onChange={(event) =>
        documentFormModel.formEvent.setSumArticleId({
          id,
          sum: Number(event.target.value),
        })
      }
      error={notValid}
      helperText={textNormSumArticle(normSumArticle)}
    />
  );
};
