import dayjs, { Dayjs } from 'dayjs';
import { FC, useMemo } from 'react';

import { DatePickerWithAllowedDates } from 'shared/ui/date-picker-with-allowed-dates';

import { VisitDatePickerProps } from './type';

export const VisitDatePicker: FC<VisitDatePickerProps> = ({
  onChange,
  value,
  retails,
  disable = false,
}) => {
  const isNoAllowedDate = useMemo(() => {
    if (!retails) return false;

    return retails.some(
      ({ allowedDates }) => allowedDates && allowedDates.length === 0
    );
  }, [retails]);

  // Свободные даты
  // undefined - все даты свободные
  // [] - нет свободныйх дат
  // Просто не спрашивай как это работает)
  const allowedDates = useMemo(() => {
    if (isNoAllowedDate) return [];
    if (!retails) return undefined;

    const allAllowedDates = retails.map((retail) => retail.allowedDates);

    if (allAllowedDates.every((date) => date === null)) return undefined;

    const filteredAllowedDates = allAllowedDates.filter(
      (date) => date !== null
    ) as string[][];
    const allFilteredAllowedDates = new Set(
      filteredAllowedDates.reduce((prev, cur) => [...prev, ...cur], [])
    );

    const ans: Dayjs[] = [];

    allFilteredAllowedDates.forEach((allowedDate) => {
      const date = dayjs(allowedDate);
      let isAllAllowed = true;

      retails.forEach((retail) => {
        if (retail.allowedDates) {
          let isRetAll = false;

          retail.allowedDates.forEach((retailDate) => {
            const retDate = dayjs(retailDate);

            if (retDate.isSame(date)) isRetAll = true;
          });
          if (!isRetAll) isAllAllowed = false;
        }
      });
      if (isAllAllowed) ans.push(date);
    });

    return ans;
  }, [retails, isNoAllowedDate]);

  return (
    <DatePickerWithAllowedDates
      onChange={onChange}
      value={value}
      label='Дата посещения'
      disabled={disable}
      allowedDates={allowedDates}
      slotProps={{
        actionBar: { actions: ['clear', 'accept'] },
        textField: { placeholder: 'Выберите дату', required: true },
      }}
    />
  );
};
