import { MenuItem } from '@mui/material';
import { FC } from 'react';
import { theme as constTheme } from 'shared/theme/theme';
import { useContextMenu } from '../../context';
import { ContextMenuItemProps } from './types';

export const ContextMenuItem: FC<ContextMenuItemProps> = ({
  onClick,
  skipCloseOnClick = false,
  sx,
  color = constTheme.palette.customGrey.dark,
  ...props
}) => {
  const { handleClose } = useContextMenu();

  return (
    <MenuItem
      divider
      sx={{
        ...sx,
        color: color,
        whiteSpace: 'normal',
        width: '320px',
      }}
      {...props}
      onClick={(e) => {
        onClick?.(e);
        !skipCloseOnClick && handleClose();
      }}
    />
  );
};
