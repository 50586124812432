import { E_SORT_FIELD } from 'entities/retail-opening-process/protocol/sort-field';
import { useCallback, useMemo, useState } from 'react';
import { OrderFilter } from 'shared/ui/table/types/order-props';
import { ProtocolOpeningRetailsItem } from 'widgets/retail-opening-process/protocol-opening-retails-table/types';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { useUrlStateRetailOpeningRetailProtocolFilter } from './use-url-state-retail-opening-retail-protocol-filter';

const MAP_ORDER_FIELD_TO_QUERY: { [key: string]: E_SORT_FIELD } = {
  titleasc: E_SORT_FIELD.ProtocolTitleAsc,
  titledesc: E_SORT_FIELD.ProtocolTitleDesc,
  departmentasc: E_SORT_FIELD.ProtocolDepartmentTitleAsc,
  departmentdesc: E_SORT_FIELD.ProtocolDepartmentTitleDesc,
  retailasc: E_SORT_FIELD.ProtocolRetailTitleAsc,
  retaildesc: E_SORT_FIELD.ProtocolRetailTitleDesc,
};

export const MAP_QUERY_TO_ORDER: {
  [key in E_SORT_FIELD]: OrderFilter<ProtocolOpeningRetailsItem>;
} = {
  [E_SORT_FIELD.ProtocolTitleAsc]: { orderBy: 'title', order: 'asc' },
  [E_SORT_FIELD.ProtocolTitleDesc]: { orderBy: 'title', order: 'desc' },
  [E_SORT_FIELD.ProtocolDepartmentTitleAsc]: {
    orderBy: 'department',
    order: 'asc',
  },
  [E_SORT_FIELD.ProtocolDepartmentTitleDesc]: {
    orderBy: 'department',
    order: 'desc',
  },

  [E_SORT_FIELD.ProtocolRetailTitleAsc]: {
    orderBy: 'retail',
    order: 'asc',
  },
  [E_SORT_FIELD.ProtocolRetailTitleDesc]: {
    orderBy: 'retail',
    order: 'desc',
  },
};

export const useFilterOpeningRetailProtocol = () => {
  const { filter, setFilter } = useUrlStateRetailOpeningRetailProtocolFilter();
  const [searchText, setSearchText] = useState(filter?.SearchText || '');

  const Page = filter?.Page || 1;
  const Limit = filter?.Limit || 20;
  const SearchText = filter?.SearchText || '';
  const OpeningTemplatesGuids = filter?.OpeningTemplatesGuids || EMPT_ARR;
  const ProtocolsGuids = filter?.ProtocolsGuids || EMPT_ARR;
  const Statuses = filter?.Statuses;
  const OrderBy = filter?.OrderBy as E_SORT_FIELD | undefined;

  const filterParams = useMemo(() => {
    return {
      Page,
      Limit,
      SearchText,
      OpeningTemplatesGuids,
      ProtocolsGuids,
      Statuses,
      OrderBy,
    };
  }, [
    Page,
    Limit,
    SearchText,
    OpeningTemplatesGuids,
    ProtocolsGuids,
    Statuses,
    OrderBy,
  ]);

  const onClearSearchText = useCallback(() => {
    setFilter({ ...filterParams, SearchText: '' });
    setSearchText('');
  }, [filterParams]);

  const onCompliteInput = useCallback(
    (value: string) => {
      setSearchText(value);

      setFilter({ ...filterParams, SearchText: value });
    },
    [filterParams]
  );

  const onChangeLimit = useCallback(
    (Limit: number) => {
      setFilter({ ...filterParams, Limit });
    },
    [filterParams]
  );
  const onChangePage = useCallback(
    (Page: number) => {
      setFilter({ ...filterParams, Page });
    },
    [filterParams]
  );

  const onChangeOrder = useCallback(
    ({ order, orderBy }: OrderFilter<ProtocolOpeningRetailsItem>) => {
      const index = orderBy && order && orderBy + order;
      const OrderBy = index && MAP_ORDER_FIELD_TO_QUERY[index];

      if (!OrderBy || OrderBy.length === 0) {
        setFilter({ ...filterParams, OrderBy: undefined });
      } else {
        setFilter({ ...filterParams, OrderBy: OrderBy });
      }
    },
    [filterParams]
  );

  return {
    onClearSearchText,
    setSearchText,
    searchText,
    onCompliteInput,
    filterParams,
    onChangeLimit,
    onChangePage,
    setFilter,
    onChangeOrder,
    order: OrderBy
      ? MAP_QUERY_TO_ORDER[OrderBy]
      : { orderBy: null, order: null },
  };
};
