import { Typography } from '@mui/material';
import { GetListServiceType } from 'shared/api/services-document-flow/types';

export const getOptionLabelServiceType = (option: GetListServiceType) => {
  return option.title;
};

export const renderOptionServiceType = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: GetListServiceType
) => (
  <Typography
    {...props}
    key={option.id}
  >
    {option.title}
  </Typography>
);

export const isOptionEqualToValueServiceType = (
  option: GetListServiceType,
  value: GetListServiceType
) => option.id === value.id;
