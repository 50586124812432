import { FC } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';
import { ControlTrainingList } from './ControlTrainingList';
import { ControlTrainingTable } from './ControlTrainingTable';
import { ControlTrainingTableProps } from './types';

export const ControlTrainingAdaptiveTable: FC<ControlTrainingTableProps> = (
  props
) => {
  const [isMobile] = useDevice();

  if (isMobile) {
    return <ControlTrainingList {...props} />;
  }

  return <ControlTrainingTable {...props} />;
};
