import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ModelsMyTrainingModel } from 'shared/api/traning/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const MY_TRAINING_FIELD_MAP: FieldMapType<ModelsMyTrainingModel>[] = [
  {
    field: 'trainingName',
    title: 'Название Тренинга',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{value.trainingName}</Typography>;
    },
  },
  {
    field: 'trainerFIO',
    title: 'Тренер',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{value.trainerFIO}</Typography>;
    },
  },
  {
    field: 'dateCreated',
    title: 'Дата тренинга',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return (
        <Typography>{dayjs(value.date).format(DATE_FORMATS.client)}</Typography>
      );
    },
  },
  {
    field: 'peopleCount',
    title: 'Кол-во человек',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{value.peopleCount}</Typography>;
    },
  },
  {
    field: 'statusName',
    title: 'Завершен',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{value.statusName}</Typography>;
    },
  },
  {
    field: 'timeInterval',
    title: 'Время',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{`${value.timeInterval}`}</Typography>;
    },
  },
  {
    field: 'departmentName',
    title: 'Сеть',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{`${value.departmentName}`}</Typography>;
    },
  },
  {
    field: 'comment',
    title: 'Комментарий',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{value.comment}</Typography>;
    },
  },
];
