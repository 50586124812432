import { AxiosError } from 'axios';
import { createEffect, createStore } from 'effector';

import { getRetails } from 'shared/api/services-document-flow/services';
import {
  ErrorResponse,
  RetailModel,
} from 'shared/api/services-document-flow/types';

export const fetchRetailFx = createEffect<
  typeof getRetails,
  AxiosError<ErrorResponse>
>(getRetails);

export const $retailsOptions = createStore<RetailModel[]>([], {
  name: '$retailsOptions',
}).on(fetchRetailFx.doneData, (_, payload) =>
  payload.data.sort((a, b) => {
    if (a.isMainGroup === b.isMainGroup) {
      if (a.isMainGroup === b.isMainGroup) {
        const index = a.groupTitle?.localeCompare(b.groupTitle || '');

        if (index === undefined || index === 0) {
          const nomForTitleA =
            (a.title &&
              a.title.includes('№') &&
              a.title.replace(/[^0-9]/g, '')) ||
            '0';
          const nomForTitleB =
            (b.title &&
              b.title.includes('№') &&
              b.title.replace(/[^0-9]/g, '')) ||
            '0';

          return parseInt(nomForTitleA) > parseInt(nomForTitleB) ? 1 : -1;
        }

        return index;
      }

      return 0;
    }

    return a.isMainGroup > b.isMainGroup ? -1 : 1;
  })
);
