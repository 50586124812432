import { RetailGetListResponseNew } from 'features/visit/Stage 1/type';

export const searchRetails = (
  retails: RetailGetListResponseNew[],
  search: string
) => {
  return retails.filter(
    (retail) =>
      prepareStringToFinding(retail.address || '').indexOf(
        prepareStringToFinding(search)
      ) !== -1 ||
      prepareStringToFinding(retail.title || '').indexOf(
        prepareStringToFinding(search)
      ) !== -1 ||
      prepareStringToFinding(retail.shortTitle || '').indexOf(
        prepareStringToFinding(search)
      ) !== -1
  );

  // return retails.filter(
  //   (retail) =>
  //     findStr(retail.address || '', search) !== -1 ||
  //     findStr(retail.title || '', search) !== -1 ||
  //     findStr(retail.shortTitle || '', search) !== -1
  // );
};

const prepareStringToFinding = (str: string) => {
  let prep = str.replace('.', '');

  prep = str.replace(',', '');
  prep = prep.toLowerCase();

  return prep;
};
