export interface I_FormCancelTrainingComment {
  comment: string;
}
export interface DialigFormCancelTrainingCommentProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmite: (form: I_FormCancelTrainingComment) => unknown;
  loadingSubmite?: boolean;
}

export interface TrainingTableActionsProps {
  training: {
    statusGuid: string;
    trainingEventGuid: string;
    employeeGuid?: string;
    trainingEmployeeGuid: string;
  };
  variant: 'myTraining' | 'controlTraining';
}
export enum E_TRAINING_GUID_STATUSES {
  'Создан' = '9710c2d5-4f41-410d-bd45-36943ac6d369',
  'Отклонил' = '5295f442-445b-49d2-8726-98baea3f069e',
  'Согласовал' = '5d32ee79-8ff0-417c-ab92-b698d384674b',
  'Согласовал НР' = 'dfc1306f-e153-437e-8669-0b71f987c356',
  'Посетил' = 'b6434973-725b-4ade-a5f1-ddc53f7f88c9',
  'Отсутствовал' = '80909d8b-5995-443c-a6a2-296ba97b21da',
}
