import { GetTasksMeExecutorQueryParams } from 'shared/api/services/types';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { getDayjs } from 'shared/tarnsform-helper/getDayjs';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { getObjByIds } from 'shared/tarnsform-helper/getObjByIds';

import {
  useGetAllEmployeesQuery,
  useGetOriginsQuery,
  useGetStatusesQuery,
} from 'shared/api/services/rtk/optionApi';
import { getGuidsFromArray } from 'shared/tarnsform-helper/getGuidsFromArray';
import { getObjByGuids } from 'shared/tarnsform-helper/getObjByGuids';
import {
  TARGET_OPTIONS,
  TASK_FILTER_FORM_DEFAULT_VALUE,
} from '../task-filter-form/constants';
import { TaskFilterFormFields } from '../task-filter-form/type';

export const useConvertTaskFilter = () => {
  const { data: employees, isLoading: isLoadingEmployees } =
    useGetAllEmployeesQuery();
  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetStatusesQuery();
  const { data: origins, isLoading: isLoadingOrigins } = useGetOriginsQuery();

  const convertFilterToForm = (
    data: GetTasksMeExecutorQueryParams | undefined
  ): TaskFilterFormFields => {
    if (data === undefined || Object.keys(data).length === 0)
      return getDefaultForm();

    return {
      coExecutor: getObjByGuids(employees, data.CoExecutorsGuids || []),
      creator: getObjByGuids(employees, data.CreatorsGuids || []),
      executor: getObjByGuids(employees, data.ExecutorsGuids || []),
      dateStart: getDayjs(data.StartDate),
      dateFinish: getDayjs(data.FinishDate),
      isTarget:
        data.IsTarget === undefined
          ? null
          : TARGET_OPTIONS.find((el) => el.value === data.IsTarget) || null,
      originValue: getObjByIds(origins, data.OriginsIds) || [],
      statuses: (statuses || []).map((status) => ({
        ...status,
        selected:
          data.StatusIds?.some((statusId) => statusId === status.id) || false,
      })),
    };
  };

  const getDefaultForm = (): TaskFilterFormFields => {
    return {
      ...TASK_FILTER_FORM_DEFAULT_VALUE,
      statuses: (statuses || []).map((status) => ({
        ...status,
        selected: status.id !== 5,
      })),
    };
  };

  const convertTaskFilterFormDataToFetch = (
    form: TaskFilterFormFields
  ): Omit<GetTasksMeExecutorQueryParams, 'Limit' | 'Page'> => {
    return {
      CoExecutorsGuids: getGuidsFromArray(form.coExecutor || []),
      ExecutorsGuids: getGuidsFromArray(form.executor || []),
      CreatorsGuids: getGuidsFromArray(form.creator || []),
      FinishDate: form.dateFinish?.format(DATE_FORMATS.server) || undefined,
      IsTarget: form.isTarget === null ? undefined : form.isTarget.value,
      OriginsIds: getIdsFromArray(form.originValue),
      StartDate: form.dateStart?.format(DATE_FORMATS.server) || undefined,
      StatusIds: getIdsFromArray(form.statuses?.filter((el) => el.selected)),
    };
  };

  return {
    convertFilterToForm,
    getDefaultForm,
    convertTaskFilterFormDataToFetch,
    isLoading: isLoadingEmployees || isLoadingOrigins || isLoadingStatuses,
  };
};
