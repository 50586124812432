import { CircularProgress, TextField } from '@mui/material';
import React, { FC } from 'react';

import { CompetitorGetListResponse } from 'shared/api/visit/types';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

interface CompetitorsInputProps {
  option: CompetitorGetListResponse[];
  value: CompetitorGetListResponse | null;
  onChange: (value: CompetitorGetListResponse | null) => void;
  disabled?: boolean;
  isError?: boolean;
  isLoading?: boolean;
}
export const CompetitorsInput: FC<CompetitorsInputProps> = ({
  onChange,
  value,
  option,
  disabled,
  isError = false,
  isLoading = false,
}) => {
  const [isMob] = useDevice();

  return (
    <CustomAutoCompliteAdaptive
      label='Название конкурента'
      propsAutocomplite={{
        loading: isLoading,
        loadingText: 'Загрузка конкурентов...',
        disabled,
        options: option,
        getOptionLabel: (option) => option.title || '',
        value: value || null,
        isOptionEqualToValue: (option, value) => option.id === value.id,
        onChange: (_, value) => {
          onChange(value);
        },
        renderOption: (props, option) => {
          return (
            <li
              {...props}
              key={option.id}
            >
              {option.title}
            </li>
          );
        },
        renderInput: (params) => (
          <TextField
            {...params}
            error={isError}
            size='small'
            label={isMob ? '' : 'Название конкурента'}
            placeholder='Выберите конкурента'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading && (
                    <CircularProgress
                      color='primary'
                      size={20}
                      sx={{ mr: 4 }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        ),
      }}
    />
  );
};
