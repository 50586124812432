import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useGetTrainingProcessEmployesQuery } from 'shared/api/traning/rtk/trainingProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { SwipeableDrawerUi } from 'shared/ui/swipeable-drawer-ui';
import { useTableCellsCurrentTreiningSubTableFromLocalStorage } from '../current-training-table/use-table-cells-current-treining-from-local-storage';
import { EmployeeForTrainingList } from './EmployeeForTrainingList';

export const SwipeableDrawerEmployesForTraining: FC<{
  trainingGuid: string;
}> = ({ trainingGuid }) => {
  const { isOpen, close, open } = useViewModal();
  const [fields, setFields, visibleFields] =
    useTableCellsCurrentTreiningSubTableFromLocalStorage();

  const { data, isFetching } = useGetTrainingProcessEmployesQuery(
    trainingGuid as string,
    { skip: !isOpen }
  );
  const isEmptyList = (!data || data.length === 0) && !isFetching;

  return (
    <>
      <Button
        onClick={open}
        fullWidth
      >
        Посмотреть список записавшихся
      </Button>
      <SwipeableDrawerUi
        onClose={close}
        onOpen={open}
        open={isOpen}
      >
        <EmployeeForTrainingList
          data={data || []}
          fields={fields.map((item, index) => ({ ...item, number: index }))}
          visibleFields={visibleFields.map((item, index) => ({
            ...item,
            number: index,
          }))}
          isLoading={isFetching}
          onChangeFields={setFields}
        />
        {isEmptyList && (
          <Typography
            textAlign={'center'}
            sx={{ padding: 2 }}
          >
            Данных нет
          </Typography>
        )}
      </SwipeableDrawerUi>
    </>
  );
};
