import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { DocumentAgreeProcessActionResponse } from 'shared/api/services-document-flow/types';
import { UiDocumentFormAgreeChain } from 'shared/ui/UiDocumentFormAgreeChain';

import {
  documentFormModel,
  secondaryValidationFx,
} from 'entities/document-form';

import { optionsEmployeesOfficeAll } from './model';

const setDocumentAgreeChain =
  createEvent<DocumentAgreeProcessActionResponse[]>();
const $documentAgreeChain = createStore<DocumentAgreeProcessActionResponse[]>(
  [],
  {
    name: 'document-agree-chain',
  }
)
  .on(secondaryValidationFx.doneData, (_, response) => response.data.agreeChain)
  .on(setDocumentAgreeChain, (_, form) => form)
  .reset(documentFormModel.resetFormModel);

documentFormModel.form.on($documentAgreeChain, (document, response) => {
  if (document) {
    return {
      ...document,
      agreeChain: response.map((item) => ({
        employeeId: item.employeeId,
        orderNumber: item.orderNumber,
        isProcessAgreeChain: item.isProcessAgreeChain,
      })),
    };
  }
});

const $backendAgree = createStore<DocumentAgreeProcessActionResponse[]>([], {
  name: 'document-agree-chain',
})
  .on(secondaryValidationFx.doneData, (_, response) => response.data.agreeChain)
  .reset(documentFormModel.resetFormModel);

const isAgreeResponse = createStore<boolean>(false)
  .on(secondaryValidationFx.doneData, () => true)
  .on(secondaryValidationFx.failData, () => false)
  .reset(documentFormModel.resetFormModel);

export const DocumentFormAgreeChain = () => {
  const documentAgreeChain = useStore($documentAgreeChain);
  const isAgree = useStore(isAgreeResponse);
  const isManualAgree = documentFormModel.useValueProcess('isManualAgree');
  const isExtendedAgree = documentFormModel.useValueProcess('isExtendedAgree');
  const backendAgree = useStore($backendAgree);
  const options = useStore(optionsEmployeesOfficeAll.options);

  useEffect(() => {
    !options && optionsEmployeesOfficeAll.init();
  }, [options]);

  return (
    <UiDocumentFormAgreeChain
      initValue={documentAgreeChain}
      backendValue={backendAgree}
      isAgree={isAgree}
      isManualAgree={Boolean(isManualAgree)}
      isExtendedAgree={Boolean(isExtendedAgree)}
      options={options}
      setDocumentAgreeChain={(form) => {
        setDocumentAgreeChain(form);
      }}
    />
  );
};
