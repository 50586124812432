import { Grid, Typography } from '@mui/material';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorAlert } from 'entities/error/error-alert';

import { FormAuthorization } from 'widgets/user/form-authorization';

import { $loadingApp } from '../../entities/initial-app';
import { LogoSvg } from '../../shared/svg/LogoSvg';
import { theme } from '../../shared/theme/theme';

export const Authorization = () => {
  const navigate = useNavigate();
  const loadingApp = useStore($loadingApp);

  useEffect(() => {
    if (loadingApp.token.isValid) navigate('/', { replace: true });
  }, [loadingApp.token.isValid, navigate]);

  return (
    <Grid
      container
      sx={{
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >
      <Grid
        item
        container
        sx={{
          backgroundColor: theme.palette.primary.main,
          flexBasis: { xs: '100%' },
          maxWidth: { xs: '100%', sm: '25%' },
          maxHeight: { xs: 'auto', sm: '100vh' },
          padding: {
            xs: '10px 0px',
          },
        }}
        alignItems='center'
        justifyContent='center'
      >
        <Grid
          item
          container
          sx={{
            backgroundColor: '#fff',
            borderRadius: '50%',
            padding: '10px',
            maxWidth: {
              xs: '100px',
              sm: '66.66%',
            },
          }}
          /* xs={8} */
          alignItems='center'
          justifyContent='center'
        >
          <LogoSvg />
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        sx={{
          maxWidth: {
            sm: '75%',
            xs: '100%',
          },
          minHeight: {
            xs: '70vh',
            sm: '100vh',
          },
        }}
      >
        <Grid
          item
          maxWidth={'280px'}
        >
          <Typography
            variant='h5'
            fontWeight={700}
            color={theme.palette.primary.main}
            maxWidth={450}
            marginBottom={2}
          >
            Добро пожаловать в ваш личный кабинет!
          </Typography>
          <FormAuthorization />
        </Grid>
      </Grid>
      <ErrorAlert />
    </Grid>
  );
};
