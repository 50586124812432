import { AccessActions } from 'shared/api/services/types';

export const checkCanEditTask = (accessActions: AccessActions) => {
  const {
    canAddFile,
    canDelete,
    canModifyCoExecutor,
    canModifyControl,
    canModifyDateFinish,
    canModifyDateStart,
    canModifyDescription,
    canModifyExecutor,
    canModifyObserver,
    canModifyTitle,
    canModifyUnits,
  } = accessActions;

  return (
    canAddFile ||
    canDelete ||
    canModifyCoExecutor ||
    canModifyControl ||
    canModifyDateFinish ||
    canModifyDateStart ||
    canModifyDescription ||
    canModifyExecutor ||
    canModifyObserver ||
    canModifyTitle ||
    canModifyUnits
  );
};
