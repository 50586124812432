import { TextField } from '@mui/material';
import { useStoreMap } from 'effector-react';
import { FC } from 'react';

import { CounterpartyModel } from 'shared/api/services-document-flow/types';
import { CustomAutoCompliteAdaptive } from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormCounterpartyTitle: FC<{
  options: CounterpartyModel[];
}> = ({ options }) => {
  const [isMobile] = useDevice();
  const value = useStoreMap({
    store: documentFormModel.form,
    keys: options,
    fn: (document, options) => {
      if (document) {
        return (
          options.find((item) => item.id === document.counterpartyId) || null
        );
      }

      return null;
    },
  });
  const onChangeCounterParty = (
    event: React.SyntheticEvent<Element, Event>,
    value: CounterpartyModel | string | null
  ) => {
    if (typeof value !== 'string') {
      documentFormModel.formEvent.setField({
        key: 'counterpartyId',
        value: value ? value.id : null,
      });
      documentFormModel.formEvent.setField({
        key: 'counterpartyTaxNumber',
        value: value ? value.taxNumber : null,
      });
      documentFormModel.formEvent.setField({
        key: 'counterpartyRegReasonCode',
        value: value ? value.regReasonCode : null,
      });
    }
  };

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        onChange: onChangeCounterParty,
        value: value,
        options: options,
        renderInput: (params) => (
          <TextField
            {...params}
            size='small'
            label={isMobile ? '' : 'Контрагент'}
            placeholder='Введите контрагента'
            margin={'none'}
          />
        ),
        getOptionLabel: (option) => option.title || '',
        renderOption: (props, option) => (
          <li
            {...props}
            key={option.id}
          >
            {option.title}
          </li>
        ),
        isOptionEqualToValue: (option, value) => option.id === value.id,
      }}
      sx={{ flexBasis: ['100%', '100%'] }}
      label='Контрагент'
    />
  );
};
