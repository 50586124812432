import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';

import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import { CreateVisitByPlanningRNForm } from 'widgets/visits/forms/create-visit-by-palnning-rn-form/CreateVisitByPlanningRNForm';

import { CreateVisitByPlanningRNDialogProps } from './type';

export const CreateVisitByPlanningRNDialog: FC<
  CreateVisitByPlanningRNDialogProps
> = ({ isOpen, onClose, isLoading = false, onSubmit, planningInfo }) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Создание посещения РН' />}
    >
      <CreateVisitByPlanningRNForm
        actions={() => [
          <Button
            key={1}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
          <LoadingButton
            key={2}
            loading={isLoading}
            variant='contained'
            type='submit'
          >
            Далее
          </LoadingButton>,
        ]}
        onSubmit={onSubmit}
        planningInfo={planningInfo}
      />
    </DialogForForm>
  );
};
