import { Grid } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const ContainerRowTwoInputs: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid
      display={'flex'}
      flexDirection={['column', 'row']}
      gap={[3, 5]}
    >
      {children}
    </Grid>
  );
};
