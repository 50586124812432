// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosError } from 'axios';
import { createEvent, createStore } from 'effector';

export const addedError = createEvent<AxiosError<any>>('addedError');
export const deleteError = createEvent<number>('deleteError');
export const addedErrorObj = createEvent<{
  status: string;
  message: string;
  statusText: string;
}>();
export const $errorStack = createStore<
  | null
  | {
      status: string | number;
      statusText: string;
      id: number;
      message?: string;
    }[]
>(null, {
  name: '$errorStack',
})
  .on(addedError, (state, error) => {
    const random = Math.random() * Math.random();

    /* @ts-ignore */
    if (error.response) {
      const newError = {
        status: error.response?.status || 'XXXX',
        statusText: error.response?.statusText || 'XXXX',
        message:
          (error.response?.data?.message ||
            error.response?.data ||
            error.response?.data.title) +
          '  ' +
          error.response.data.additionally,
      };

      return state
        ? [...state, { ...newError, id: random }]
        : [{ ...newError, id: random }];
    }
  })
  .on(addedErrorObj, (state, err) => {
    const random = Math.random() * Math.random();

    return state
      ? [...state, { ...err, id: random }]
      : [{ ...err, id: random }];
  })
  .on(deleteError, (state, id) => {
    if (!state) return null;

    return state.filter((error) => error.id !== id);
  });
