import { FC } from 'react';

import { useContextSwipeableDrawerImagesListUi } from './context';
import { SwipeableDrawerImagesListUi } from './SwipeableDrawerImagesListUi';

export const SwipeableDrawerImagesListUiWithContext: FC = () => {
  const {
    selectSurfaceGuid,
    onCloseImagesDrawerAndSelectSurfaceGuid,
    onOpenImagesDrawerAndSelectSurfaceGuid,
    isOpenImagesDrawer,
  } = useContextSwipeableDrawerImagesListUi();

  return (
    <SwipeableDrawerImagesListUi
      onOpenImagesDawer={
        () =>
          selectSurfaceGuid &&
          onOpenImagesDrawerAndSelectSurfaceGuid(selectSurfaceGuid) // ??
      }
      onCloseImagesDrawer={onCloseImagesDrawerAndSelectSurfaceGuid}
      isOpenImagesDrawer={isOpenImagesDrawer}
      surfaceGuid={selectSurfaceGuid}
    />
  );
};
