import { useStore } from 'effector-react';
import { $optionsDocumentReasonType } from 'features/document/document-form-reason-type-autocomplite/model';

import { UiDocumentFormReasonNumber } from 'shared/ui/UiDocumentFormReasonNumber';

import { documentFormModel } from 'entities/document-form';

export const DocumentFormReasonNumber = () => {
  const value = documentFormModel.useValueDocumentFormModel(
    'reasonDocumentNumber'
  );
  const isReasonDocumentRequired = documentFormModel.useValueProcess(
    'isReasonDocumentRequired'
  );
  const reasonDocumentTypeId = documentFormModel.useValueDocumentFormModel(
    'reasonDocumentTypeId'
  );
  const documentTypeOptions = useStore($optionsDocumentReasonType);

  const required = documentTypeOptions.find(
    (item) => reasonDocumentTypeId && item.id === reasonDocumentTypeId
  )?.isOtherInformationRequired;

  if (!isReasonDocumentRequired) return null;

  return (
    <UiDocumentFormReasonNumber
      value={value}
      required={Boolean(required)}
      onChange={(event) => {
        documentFormModel.formEvent.setField({
          key: 'reasonDocumentNumber',
          value: event.target.value,
        });
      }}
      disabled={!isReasonDocumentRequired}
    />
  );
};
