import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { BorderAllRounded, School } from '@mui/icons-material';
import { ReactNode } from 'react';
import { ALL_ROUTES } from 'shared/routes/constants/types';
import { AptekaLegkoSvg } from 'shared/svg/AptekaLegkoSvg';

export const ICONS_FOR_PATH: { [K in ALL_ROUTES]: ReactNode } = {
  [ALL_ROUTES.main]: <HomeOutlinedIcon />,
  [ALL_ROUTES.monitoringTasks]: undefined,
  [ALL_ROUTES.chargeTasks]: undefined,
  [ALL_ROUTES.myTasks]: undefined,
  [ALL_ROUTES.tasks]: <ChecklistIcon />,
  [ALL_ROUTES.tasksId]: undefined,
  [ALL_ROUTES.calendar]: undefined,
  [ALL_ROUTES.documentFlow]: <DescriptionOutlinedIcon />,
  [ALL_ROUTES.documentFlowId]: undefined,
  [ALL_ROUTES.documentFlowIdEdit]: undefined,
  [ALL_ROUTES.visitingPharmacies]: <MapOutlinedIcon />,
  [ALL_ROUTES.support]: <SupportAgentIcon />,
  [ALL_ROUTES.about]: undefined,
  [ALL_ROUTES.pharmacyAddresses]: undefined,
  [ALL_ROUTES.createdByMe]: undefined,
  [ALL_ROUTES.notification]: undefined,
  [ALL_ROUTES.templateTasks]: undefined,
  [ALL_ROUTES.myIdeas]: undefined,
  [ALL_ROUTES.user]: undefined,
  [ALL_ROUTES.action]: undefined,
  [ALL_ROUTES.observe]: undefined,
  [ALL_ROUTES.controls]: undefined,
  [ALL_ROUTES.createdDocument]: undefined,
  [ALL_ROUTES.invocePayment]: undefined,
  [ALL_ROUTES.invocePayment2]: undefined,
  [ALL_ROUTES.invocePayment3]: undefined,
  [ALL_ROUTES.new]: undefined,
  [ALL_ROUTES.my]: undefined,
  [ALL_ROUTES.whatch]: undefined,
  [ALL_ROUTES.stage1]: undefined,
  [ALL_ROUTES.stage2]: undefined,
  [ALL_ROUTES.stage3]: undefined,
  [ALL_ROUTES.stage4]: undefined,
  [ALL_ROUTES.stage5]: undefined,
  [ALL_ROUTES.stage6]: undefined,
  [ALL_ROUTES.counterparties]: undefined,
  [ALL_ROUTES.createdDocumentId]: undefined,
  [ALL_ROUTES.documentFlowIdComments]: undefined,
  [ALL_ROUTES.authorization]: undefined,
  [ALL_ROUTES.complite]: undefined,
  [ALL_ROUTES.planning]: undefined,
  [ALL_ROUTES.createPlanning]: undefined,
  [ALL_ROUTES.visit]: undefined,
  [ALL_ROUTES.visitInfo]: undefined,
  [ALL_ROUTES.planingRN]: undefined,
  [ALL_ROUTES.training]: <AutoStoriesIcon />,
  [ALL_ROUTES.currentTraining]: undefined,
  [ALL_ROUTES.compliteTraining]: undefined,
  [ALL_ROUTES.myTraining]: undefined,
  [ALL_ROUTES.controlTraining]: undefined,
  [ALL_ROUTES.retailOpeningProcess]: <AddBusinessIcon />,
  [ALL_ROUTES.openingTemplates]: undefined,
  [ALL_ROUTES.openingTemplate]: undefined,
  [ALL_ROUTES.openingTemplateRunProcess]: undefined,
  [ALL_ROUTES.retailOpeningProcessProtocol]: undefined,
  [ALL_ROUTES.retailOpeningProcessProtocolGuid]: undefined,
  [ALL_ROUTES.tasksGuid]: undefined,
  [ALL_ROUTES.marketingSurface]: <AdsClickIcon />,
  [ALL_ROUTES.myBoards]: undefined,
  [ALL_ROUTES.boards]: <BorderAllRounded />,
  [ALL_ROUTES.boardGuid]: undefined,
  [ALL_ROUTES.aptekaLegko]: <AptekaLegkoSvg />,
  [ALL_ROUTES.aptekaLegkoPush]: undefined,
  [ALL_ROUTES.aptekaLegkoPushList]: undefined,
  [ALL_ROUTES.aptekaLegkoPushCreated]: undefined,
  [ALL_ROUTES.aptekaLegkoPushByGuid]: undefined,
  [ALL_ROUTES.aptekaLegkoPushEdit]: undefined,
  [ALL_ROUTES.aptekaLegkoCallCenter]: undefined,
  [ALL_ROUTES.aptekaLegkoArticles]: undefined,
  [ALL_ROUTES.adaptation]: <School />,
  [ALL_ROUTES.adaptationTemplates]: undefined,
  [ALL_ROUTES.adaptationTemplatesStages]: undefined,
  //[ALL_ROUTES.adaptationTemplatesList]: undefined,
  [ALL_ROUTES.currentAdaptationProcesses]: undefined,
  [ALL_ROUTES.completedAdaptationProcesses]: undefined,
  [ALL_ROUTES.firedAdaptationProcesses]: undefined,
  [ALL_ROUTES.waitingAdaptationProcesses]: undefined,
  [ALL_ROUTES.adaptationTemplatesRunProcess]: undefined,
  [ALL_ROUTES.notAdaptationRequired]: undefined,
  [ALL_ROUTES.aptekaLegkoBanners]: undefined,
  [ALL_ROUTES.aptekaLegkoBannersSliders]: undefined,
  [ALL_ROUTES.aptekaLegkoBannersSlidersList]: undefined,
  [ALL_ROUTES.aptekaLegkoBannersSlidersCreated]: undefined,
  [ALL_ROUTES.aptekaLegkoBannersBrand]: undefined,
};
