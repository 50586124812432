import {
  DocumentArticleExpenseModel,
  DocumentArticleModel,
  DocumentArticleSecondValidateRequest,
  DocumentCreateRequest,
  DocumentExpenseSecondValidationRequest,
  DocumentModel,
} from 'shared/api/services-document-flow/types';

import {
  SetDefaultValueForPutDocumentForm,
  SetFieldDocumentForm,
  SetFieldDocumentFormComment,
  SetFieldExpense,
} from '.';

//expenseId - id вида расхода
//id - id записи бэка(при созданиии передаем 0)
export const setFieldExpense: SetFieldExpense = (
  document,
  { id, index, fild }
) => {
  if (!document) return document;

  const { key, value } = fild;

  return {
    ...document,
    articles: document.articles?.map((article) => {
      if (article.id === id) {
        if (article.expenses) {
          article.expenses.forEach((item, ind) => {
            if (index === ind) {
              item[key] = value;
            }
          });
        } else {
          article.expenses = [
            { id: 0, price: 0, count: 0, sum: 0, expenseId: 0, [key]: value },
          ];
        }
      }

      return article;
    }),
  };
};

export const setField: SetFieldDocumentForm = (document, payload) => {
  if (document) {
    return {
      ...document,
      [payload.key]: payload.value,
    };
  } else {
    return null;
  }
};

export const setFieldDocumentFormComment: SetFieldDocumentFormComment = (
  comment,
  fild
) => {
  return { ...(comment || { comment: '' }), [fild.key]: fild.value };
};

export const setDefaultValueForPutDocumentForm: SetDefaultValueForPutDocumentForm =
  (document, documentId) => {
    if (!document && documentId) {
      const newDocumentForm = transform(documentId);

      return { ...newDocumentForm };
    }
    if (document && documentId) {
      const newDocumentForm = transform(documentId);

      return { ...document, ...newDocumentForm };
    }

    return document;
  };

const transform = (document: DocumentModel): Partial<DocumentCreateRequest> => {
  return {
    organizationId: document.organization.id,
    articles: document.documentArticles
      ? transformArticles(document.documentArticles)
      : undefined,
  };
};

const transformArticles = (
  article: DocumentArticleModel[]
): DocumentArticleSecondValidateRequest[] => {
  return article.map((item) => ({
    id: item.articleId,
    expenses: item.documentArticleExpenses
      ? tarnsformArticleExpenses(item.documentArticleExpenses)
      : undefined,
  }));
};

const tarnsformArticleExpenses = (
  expenses: DocumentArticleExpenseModel[]
): DocumentExpenseSecondValidationRequest[] => {
  return expenses.map((item) => ({
    count: item.count,
    sum: item.sum,
    id: 0,
    expenseId: item.expenseId,
  }));
};
