import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { usePutMarketingSurfaceMutation } from 'shared/api/marketing-surface/rtk/marketingSurfaceProviderApi';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { preparedFormValueToPostBody } from './prepared-helper';
import { FormMarketingSurfaceValue } from './types';

export const useDialogMarketingSurfaceFormUpdate = (params: {
  retailGuid: string;
  guid: string | null | undefined;
}) => {
  const { open: openSnackBar } = useSnackbar();
  const {
    open: onOpenDialogFormUpdateMarketingSurface,
    isOpen: isOpenDialogFormUpdateMarketingSurface,
    close: onCloseDialogFormUpdateMarketingSurface,
  } = useViewModal();
  const [
    updateMarketingSurface,
    { isLoading: isLoadingUpdateMarketingSurface },
  ] = usePutMarketingSurfaceMutation();

  const onSubmitUpdateMarketingSurface = useCallback(
    async (form: FormMarketingSurfaceValue) => {
      if (!params.guid) {
        openSnackBar({
          title: 'Редактирование поверхности',
          text: 'Не указан guid поверхности',
          type: 'error',
          duration: 3000,
        });

        return;
      }

      const { advertisingType, surfaceType, width, height } = form;

      if (!advertisingType || !surfaceType || !width || !height) {
        openSnackBar({
          title: 'Ошибка валидации',
          text: 'Не указаны обязательные поля',
          type: 'error',
          duration: 3000,
        });

        return;
      }

      const preparedData = preparedFormValueToPostBody(form);

      if (!preparedData) {
        return;
      }

      updateMarketingSurface({
        data: {
          ...preparedData,
          retailGuid: params.retailGuid,
          guid: params.guid,
        },
      }).then((response) => {
        if ('error' in response) {
          openSnackBar({
            text: getMessageFromFetchBaseQueryError(response.error),
            type: 'error',
            duration: 3000,
          });
        } else {
          openSnackBar({
            title: 'Рекламная поверхность',
            text: 'Успешно изменена!',
            type: 'success',
            duration: 3000,
          });
          onCloseDialogFormUpdateMarketingSurface();
        }
      });
    },
    [params.retailGuid, params.guid]
  );

  return {
    onOpenDialogFormUpdateMarketingSurface,
    isOpenDialogFormUpdateMarketingSurface,
    onCloseDialogFormUpdateMarketingSurface,
    isLoadingUpdateMarketingSurface,
    onSubmitUpdateMarketingSurface,
  };
};
