import { Typography } from '@mui/material';
import { createEffect, createStore } from 'effector';
import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';

import { getProcesses } from 'shared/api/services-document-flow/services';
import { ProcessListItemModel } from 'shared/api/services-document-flow/types';

import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { NavigationCardStack } from 'shared/ui/navigation-card';
import { DocumentSvg } from '../../shared/svg/DocumentSvg';

const getProcessesFx = createEffect({
  async handler() {
    return await getProcesses();
  },
});
const $listProcesses = createStore<ProcessListItemModel[]>([]);

$listProcesses.on(getProcessesFx.doneData, (_, response) => response.data);

export const CreatedDocument: FC = () => {
  const listProcesses = useStore($listProcesses);
  const loading = useStore(getProcessesFx.pending);

  useEffect(() => {
    getProcessesFx();
  }, []);

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          fontWeight={700}
          fontSize={[16, 20]}
          width={'100%'}
        >
          Выберите документ
        </Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <NavigationCardStack.Container>
          {loading &&
            new Array(9).fill(0).map((_, index) => {
              return (
                <NavigationCardStack.CardSkeleton
                  key={index}
                  Icon={<DocumentSvg />}
                />
              );
            })}
          {listProcesses &&
            !loading &&
            listProcesses.map(({ id, title }) => (
              <NavigationCardStack.Card
                key={id}
                to={`/document-flow/created-document/${id}`}
                Icon={<DocumentSvg />}
                title={title}
              />
            ))}
        </NavigationCardStack.Container>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
