import { ALL_ROUTES, Routes } from './types';

export const TRAINING: Routes[] = [
  {
    title: 'Текущие',
    path: ALL_ROUTES.currentTraining,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Завершенные',
    path: ALL_ROUTES.compliteTraining,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Мои тренинги',
    path: ALL_ROUTES.myTraining,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Контролирую',
    path: ALL_ROUTES.controlTraining,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
];
