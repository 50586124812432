import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CustomTextField } from 'shared/ui/base-ui';
import { FormLayout } from 'shared/ui/form';
import { UpdateBoardTitleFormFields, UpdateBoardTitleFormProps } from './type';

export const UpdateBoardTitleForm: FC<UpdateBoardTitleFormProps> = ({
  onSubmit,
  renderFormActions,
  initForm,
}) => {
  const { handleSubmit, control } = useForm<UpdateBoardTitleFormFields>({
    defaultValues: initForm,
  });

  return (
    <FormLayout
      onSubmit={handleSubmit(onSubmit)}
      actions={renderFormActions(handleSubmit)}
    >
      <Controller
        control={control}
        name='title'
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <CustomTextField
            required
            label='Название'
            placeholder='Введите название'
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        )}
      />
    </FormLayout>
  );
};
