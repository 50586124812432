import { useState } from 'react';

import { putEventsPlanningId } from 'shared/api/visit/services';
import {
  EventGetListResponse,
  EventUpdateRequest,
} from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useEditVisitEvent = (
  afterUpdate?: (event: EventGetListResponse) => void
) => {
  const { open: openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const edit = async (planningId: number, fetchData: EventUpdateRequest) => {
    setIsLoading(true);
    try {
      const { data, status } = await putEventsPlanningId(planningId, fetchData);

      if (!data || status !== 200) throw new Error('Упс, что-то пошло не так');
      afterUpdate && afterUpdate(data);
      setIsLoading(false);

      return data;
    } catch (e) {
      console.error('useEditVisitEvent.edit >> ', e);
      setIsLoading(false);

      const error = e as { message: string };

      openSnackbar({
        duration: 6000,
        type: 'error',
        text: error.message,
      });

      return Promise.reject(e);
    }
  };

  return { isLoading, edit };
};
