import { useCallback } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import { useUpLoadMarketingSurfaceImagesMutation } from 'shared/api/marketing-surface/rtk/marketingSurfaceProviderApi';

import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
interface useMarketingSurfaceUploadImagesParams {
  surfaceGuid: string | null | undefined;
}
export const useMarketingSurfaceUploadImages = (
  params: useMarketingSurfaceUploadImagesParams
) => {
  const { open: openSnackBar } = useSnackbar();

  const [uploadImages, { isLoading }] =
    useUpLoadMarketingSurfaceImagesMutation();

  const onUploadImages = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!params.surfaceGuid) {
        openSnackBar({
          text: 'surfaceGuid - не указан',
          type: 'error',
          duration: 3000,
        });

        return;
      }
      if (e.target.files) {
        const imagesList = Array.from(e.target.files);

        uploadImages({
          surfaceGuid: params.surfaceGuid,
          data: { files: imagesList },
        }).then((response) => {
          if ('error' in response) {
            openSnackBar({
              text: getMessageFromFetchBaseQueryError(response.error),
              type: 'error',
              duration: 3000,
            });
          } else {
            openSnackBar({
              text: 'Изображения успешно загруженны',
              type: 'success',
              duration: 3000,
            });
          }
        });
      }
    },
    [params.surfaceGuid]
  );

  return {
    onUploadImages,
    isLoadingUploadImages: isLoading,
  };
};
