import { FC } from 'react';

import { useMediaQuery } from '@mui/material';
import { theme } from 'shared/theme/theme';
import { ProcessOpeningRetailsList } from './ProcessOpeningRetailsList';
import { ProcessOpeningRetailsTable } from './ProcessOpeningRetailsTable';
import { ProcessOpeningRetailsTableProps } from './types';

export const ProcessOpeningRetailsTableAdaptiveTable: FC<
  ProcessOpeningRetailsTableProps
> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) return <ProcessOpeningRetailsList {...props} />;
  else return <ProcessOpeningRetailsTable {...props} />;
};
