import { usePaginationStateHandlerWithFilter } from 'shared/hooks/usePaginationStateHandlerWithFilter';
import { useSearchStateHendlerWithFilter } from 'shared/hooks/useSearchStateHendlerWithFilter';
import { useUrlStateDocumentObserveFilter } from '../use-url-state-document-observe-filter';

export const useFilterDocumentFlowObservePage = () => {
  const { filter, setFilter } = useUrlStateDocumentObserveFilter();

  const {
    handleChangeSearchText,
    handleCompliteChangeSearchText,
    searchInput,
  } = useSearchStateHendlerWithFilter({
    filter,
    setFilter,
    keySearch: 'SearchText',
    keyPage: 'CurrentPage',
  });

  const { handleChangePage, handleChangePageSize, currentPage, pageSize } =
    usePaginationStateHandlerWithFilter({
      filter,
      setFilter,
      keyPage: 'CurrentPage',
      keyPageSize: 'PageSize',
    });

  return {
    searchInput,
    handleChangeSearchText,
    handleCompliteChangeSearchText,
    handleChangePage,
    handleChangePageSize,
    currentPage,
    pageSize,
  };
};
