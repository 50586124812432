import { FC } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';
import { MyTrainingList } from './MyTrainingList';
import { MyTrainingTable } from './MyTrainingTable';
import { MyTrainingTableProps } from './types';

export const MyTrainingAdaptiveTable: FC<MyTrainingTableProps> = (props) => {
  const [isMobile] = useDevice();

  if (isMobile) {
    return <MyTrainingList {...props} />;
  }

  return <MyTrainingTable {...props} />;
};
