import { CSSObject } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED } from './constants';

export const openedMixin = (theme: Theme): CSSObject => ({
  [theme.breakpoints.up('md')]: {
    width: DRAWER_WIDTH,
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: DRAWER_WIDTH_CLOSED,
  [theme.breakpoints.up('sm')]: {
    width: `${DRAWER_WIDTH_CLOSED}px`,
  },
});
