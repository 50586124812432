import { Delete, Edit } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS } from 'entities/apteka-legko/push-notifications';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { OnlineStoreResponsesFirebaseDistribution } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { useDeleteFirebaseDistributionsMutation } from 'shared/api/online-store-service/firebase/rtk';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';

export const PushNotificationsTableActions: FC<
  OnlineStoreResponsesFirebaseDistribution
> = ({ isEditable, distributionGuid }) => {
  const theme = useTheme();
  const navigation = useNavigate();
  const openConfirm = useConfirm();
  const [deletePush, { error }] = useDeleteFirebaseDistributionsMutation();

  useShowFetchErrorInSnackbar(error);

  return (
    <UiTableActions>
      {isEditable && (
        <UiButtonAction
          color={theme.palette.customBlue.main}
          onClick={() => {
            navigation(
              APTEKALEGKO_PUSH_NOTIFICATIONS_HELPER_LINKS.createLinkEdit(
                distributionGuid
              )
            );
          }}
          text={'Внести изменения'}
          icon={<Edit />}
        />
      )}
      {isEditable && (
        <UiButtonAction
          color={theme.palette.error.main}
          onClick={() => {
            openConfirm({
              actionTitle: 'Удалить push?',
              onSubmit: async () => {
                await deletePush({
                  params: { distributionGuids: [distributionGuid] },
                });
              },
              submitButtonTitle: 'Удалить',
              buttonColor: 'error',
            });
          }}
          text={'Удалить'}
          icon={<Delete />}
        />
      )}
    </UiTableActions>
  );
};
