import { Typography } from '@mui/material';

import { EmployeeOption } from '../../types';

export const groupByPositions = (option: EmployeeOption) =>
  option.position.title;

export const getOptionLabelEmployee = (option: EmployeeOption) =>
  `${option.name} (${option.position.title})`;

export const renderOptionEmployee = (
  params: React.HTMLAttributes<HTMLLIElement>,
  option: EmployeeOption
) => (
  <Typography
    {...params}
    key={option.guid}
  >
    {getOptionLabelEmployee(option)}
  </Typography>
);

export const isOptionEqualToValueGuid = (
  option: EmployeeOption,
  value: EmployeeOption
) => option.guid === value.guid;
