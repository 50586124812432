import { Button, Grid, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS } from 'entities/apteka-legko/banners/sliders/links';
import { FC, useCallback, useState } from 'react';

import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useDeleteAptekaLegkoBannersSliderMutation } from 'shared/api/service-apteka-legko-banners/rtk/aptekaLegkoBannersSliderProviderApi';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { useIsComliteLoadingImage } from 'shared/hooks/use-is-comlite-loading-image';
import { LinkButton } from 'shared/ui/base-ui/link';
import { useDevice } from 'shared/utils/hook-type-device';
import { SliderItemUi } from './SliderItemUi';
import { SliderItemProps } from './types';

export const SliderItem: FC<SliderItemProps> = ({
  imageDesktopSrc,
  imageMobileSrc,
  dateFinish,
  dateStart,
  guid,
}) => {
  const [isMobile] = useDevice();
  const theme = useTheme();
  const [ref, setRef] = useState<HTMLImageElement | null>(null);

  const [deleteSlider, { error }] = useDeleteAptekaLegkoBannersSliderMutation();

  useShowFetchErrorInSnackbar(error);

  const { isCompliteLoadImage } = useIsComliteLoadingImage(ref);

  const openConfirm = useConfirm();

  const handleDelete = useCallback(() => {
    openConfirm({
      actionTitle: 'Удалить слайдер',
      actionBody: 'Вы уверены?',
      submitButtonTitle: 'Удалить',
      buttonColor: 'error',
      onSubmit: async () => {
        await deleteSlider({ sliderGuid: guid });
      },
    });
  }, [openConfirm, deleteSlider, guid]);

  const isInactive =
    !dayjs().isBefore(dateFinish) && !dayjs().isSame(dateFinish, 'date');

  return (
    <SliderItemUi
      skipAfterSx={isCompliteLoadImage}
      Actions={
        <>
          <LinkButton
            variant='contained'
            size='small'
            fullWidth
            to={APTEKALEGKO_BANNERS_SLIDER_HELPER_LINKS.createLinkEdit(guid)}
          >
            Изменить
          </LinkButton>
          <Button
            onClick={handleDelete}
            variant='contained'
            color='customGrey'
            size='small'
            fullWidth
          >
            Удалить
          </Button>
        </>
      }
      AfterPeriod={
        <Typography>{`${dayjs(dateStart).format(DATE_FORMATS.client)} - ${dayjs(
          dateFinish
        ).format(DATE_FORMATS.client)}`}</Typography>
      }
    >
      <img
        ref={setRef}
        style={{ width: '100%', height: 'auto', borderRadius: 10 }}
        src={isMobile ? imageMobileSrc : imageDesktopSrc}
      />
      {isInactive && (
        <Grid
          sx={{
            position: 'absolute',
            backgroundColor: theme.palette.customGrey.main,
            borderRadius: '10px',
            width: '100%',
            height: '100%',
            opacity: 0.7,
          }}
        ></Grid>
      )}
    </SliderItemUi>
  );
};
