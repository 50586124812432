/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CoreResponsesError {
  /**
   * Код ошибки
   * @format int32
   */
  code: number;
  /** Сообщение */
  message?: string | null;
}

export interface PaginatedOnlineStoreResponsesFirebaseDistribution {
  /**
   * Общее кол-во элементов
   * @format int32
   */
  count: number;
  /** Элементы */
  items: OnlineStoreResponsesFirebaseDistribution[];
}

export enum CoreSystemEnumsObjectActions {
  None = 'None',
  Insert = 'Insert',
  Delete = 'Delete',
}

export interface OnlineStoreRequestsFirebaseCreateMessage {
  /**
   * Заголовок
   * @minLength 1
   */
  title: string;
  /** Сообщение */
  message?: string | null;
  /** Ссылка */
  url?: string | null;
  /** Изображение в Base64 формате */
  imageData?: string | null;
  /**
   * Телефон для тестового сообщения
   * @minLength 1
   */
  phone: string;
}

export interface OnlineStoreRequestsFirebaseEditDistribution {
  /**
   * Guid рассылки
   * @format uuid
   */
  distributionGuid?: string | null;
  /**
   * Наименование рассылки
   * @minLength 1
   */
  distributionTitle: string;
  /** Сообщение */
  message?: string | null;
  /** Ссылка */
  url?: string | null;
  imageAction: CoreSystemEnumsObjectActions;
  /** Изображение в Base64 формате */
  imageData?: string | null;
  fileAction: CoreSystemEnumsObjectActions;
  /** Файл в Base64 формате (телефоны в строку, 10 символов, без заголовка) */
  fileData?: string | null;
  /** Сохранить в профиле пользователя */
  isSaveForUser: boolean;
  /**
   * Время отправки
   * @format date-time
   */
  dateTimeSending?: string | null;
  /**
   * Guid типа уведомления
   * @format uuid
   */
  notificationTypeGuid?: string | null;
  /** Значение уведомления */
  notificationTypeValue?: string | null;
  /** Список топиков */
  topicGuids: string[];
}

export interface OnlineStoreRequestsFirebaseGetUserCount {
  /** Файл в Base64 формате (телефоны в строку, 10 символов, без заголовка) */
  fileData?: string | null;
  /** Список топиков */
  topicGuids: string[];
}

export interface OnlineStoreRequestsFirebaseSubscribe {
  /**
   * Список токенов
   * @minItems 1
   */
  tokens: string[];
  /**
   * Guid's топиков
   * @minItems 1
   */
  topicGuids: string[];
}

export interface OnlineStoreResponsesBacthOperationResult {
  /** Массив принятых идентификаторов */
  accepted?: string[] | null;
  /** Массив отклонённых идентификаторов */
  rejected?: OnlineStoreResponsesBacthOperationResultError[] | null;
}

export interface OnlineStoreResponsesBacthOperationResultError {
  /**
   * Идентификатор
   * @format uuid
   */
  guid?: string;
  /** Описание ошибки */
  description?: string | null;
}

export interface OnlineStoreResponsesFirebaseCreateMessage {
  /** Статус отправки */
  isSuccess: boolean;
  /** Идентификатор сообщения в случае успешной отправки */
  messageId?: string | null;
  /** Описание ошибки отправки при наличии */
  error?: string | null;
  /**
   * Тип отправки (Topic / Token)
   * @minLength 1
   */
  type: string;
}

export interface OnlineStoreResponsesFirebaseDistribution {
  /**
   * Создал
   * @minLength 1
   */
  userCreated: string;
  /**
   * Дата создания
   * @format date-time
   */
  dateCreated: string;
  /** Удалил */
  userDeleted?: string | null;
  /**
   * Дата удаления
   * @format date-time
   */
  dateDeleted?: string | null;
  /**
   * Guid рассылки
   * @format uuid
   */
  distributionGuid: string;
  /**
   * Наименование рассылки
   * @minLength 1
   */
  distributionTitle: string;
  /** Сообщение */
  message?: string | null;
  /** Ссылка */
  url?: string | null;
  /** Ссылка на изображение */
  imageUrl?: string | null;
  /** Ссылка на файл */
  fileUrl?: string | null;
  /** Сохранить в профиле пользователя */
  isSaveForUser: boolean;
  /**
   * Время отправки
   * @format date-time
   */
  dateTimeSending: string;
  /**
   * Guid типа уведомления
   * @format uuid
   */
  notificationTypeGuid?: string | null;
  /** Наименование типа уведомления */
  notificationTypeTitle?: string | null;
  /** Значение уведомления */
  notificationTypeValue?: string | null;
  /**
   * Статус
   * @minLength 1
   */
  status: string;
  /** Редактируемая рассылка */
  isEditable: boolean;
  /** Завершённая рассылка */
  isClosed: boolean;
  /**
   * Кол-во пользователей на рассылку
   * @format int32
   */
  userCount: number;
  /**
   * Кол-во успешных отправок
   * @format int32
   */
  successCount: number;
  /** Список топиков */
  topics: OnlineStoreResponsesFirebaseTopic[];
}

export interface OnlineStoreResponsesFirebaseNotificationType {
  /**
   * Guid типа уведомления
   * @format uuid
   */
  notificationTypeGuid: string;
  /**
   * Наименование типа уведомления
   * @minLength 1
   */
  notificationTypeTitle: string;
}

export interface OnlineStoreResponsesFirebaseTopic {
  /**
   * Guid топика
   * @format uuid
   */
  topicGuid: string;
  /**
   * Наименование топика
   * @minLength 1
   */
  topicTitle: string;
}

export interface FirebaseDistributionsPaginationListParams {
  /**
   * Количество элементов на странице
   * @format int32
   * @min 1
   * @max 2147483647
   */
  Limit: number;
  /**
   * Номер страницы
   * @format int32
   * @min 1
   * @max 2147483647
   */
  Page: number;
}

export interface FirebaseDistributionsListParams {
  /** Guid's рассылок */
  distributionGuids?: string[];
}

export interface FirebaseDistributionsDeleteParams {
  /** Идентификаторы рассылок */
  distributionGuids?: string[];
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Firebase API
 * @version v1
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  firebase = {
    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseNotificationTypesList
     * @summary Получает список типов уведомлений
     * @request GET:/v1/onlinestoreservice/api/Firebase/notificationTypes
     * @secure
     * @response `200` `(OnlineStoreResponsesFirebaseNotificationType)[]` Список типов уведомлений
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseNotificationTypesList: (params: RequestParams = {}) =>
      this.request<
        OnlineStoreResponsesFirebaseNotificationType[],
        void | CoreResponsesError
      >({
        path: `/v1/onlinestoreservice/api/Firebase/notificationTypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseTopicsList
     * @summary Получает список топиков
     * @request GET:/v1/onlinestoreservice/api/Firebase/topics
     * @secure
     * @response `200` `(OnlineStoreResponsesFirebaseTopic)[]` Список типов уведомлений
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseTopicsList: (params: RequestParams = {}) =>
      this.request<
        OnlineStoreResponsesFirebaseTopic[],
        void | CoreResponsesError
      >({
        path: `/v1/onlinestoreservice/api/Firebase/topics`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseTopicsSubscribeCreate
     * @summary Подписывает пользователей на топики
     * @request POST:/v1/onlinestoreservice/api/Firebase/topics/subscribe
     * @secure
     * @response `200` `void` Success
     * @response `204` `void`
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseTopicsSubscribeCreate: (
      data: OnlineStoreRequestsFirebaseSubscribe,
      params: RequestParams = {}
    ) =>
      this.request<void, void | CoreResponsesError>({
        path: `/v1/onlinestoreservice/api/Firebase/topics/subscribe`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseTopicsUnsubscribeCreate
     * @summary Отписывает пользователей от топиков
     * @request POST:/v1/onlinestoreservice/api/Firebase/topics/unsubscribe
     * @secure
     * @response `200` `void` Success
     * @response `204` `void`
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseTopicsUnsubscribeCreate: (
      data: OnlineStoreRequestsFirebaseSubscribe,
      params: RequestParams = {}
    ) =>
      this.request<void, void | CoreResponsesError>({
        path: `/v1/onlinestoreservice/api/Firebase/topics/unsubscribe`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseDistributionsPaginationList
     * @summary Получает список рассылок постранично
     * @request GET:/v1/onlinestoreservice/api/Firebase/distributions/pagination
     * @secure
     * @response `200` `PaginatedOnlineStoreResponsesFirebaseDistribution` Список рассылок
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseDistributionsPaginationList: (
      query: FirebaseDistributionsPaginationListParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PaginatedOnlineStoreResponsesFirebaseDistribution,
        void | CoreResponsesError
      >({
        path: `/v1/onlinestoreservice/api/Firebase/distributions/pagination`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseDistributionsList
     * @summary Получает список рассылок
     * @request GET:/v1/onlinestoreservice/api/Firebase/distributions
     * @secure
     * @response `200` `(OnlineStoreResponsesFirebaseDistribution)[]` Список рассылок
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseDistributionsList: (
      query: FirebaseDistributionsListParams,
      params: RequestParams = {}
    ) =>
      this.request<
        OnlineStoreResponsesFirebaseDistribution[],
        void | CoreResponsesError
      >({
        path: `/v1/onlinestoreservice/api/Firebase/distributions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseDistributionsCreate
     * @summary Добавляет/изменяет рассылку
     * @request POST:/v1/onlinestoreservice/api/Firebase/distributions
     * @secure
     * @response `200` `OnlineStoreResponsesFirebaseDistribution` Список рассылок
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - редактирование рассылки невозможно
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseDistributionsCreate: (
      data: OnlineStoreRequestsFirebaseEditDistribution,
      params: RequestParams = {}
    ) =>
      this.request<
        OnlineStoreResponsesFirebaseDistribution,
        CoreResponsesError | void
      >({
        path: `/v1/onlinestoreservice/api/Firebase/distributions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseDistributionsDelete
     * @summary Удаляет выбранные рассылки
     * @request DELETE:/v1/onlinestoreservice/api/Firebase/distributions
     * @secure
     * @response `200` `OnlineStoreResponsesBacthOperationResult`
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseDistributionsDelete: (
      query: FirebaseDistributionsDeleteParams,
      params: RequestParams = {}
    ) =>
      this.request<
        OnlineStoreResponsesBacthOperationResult,
        void | CoreResponsesError
      >({
        path: `/v1/onlinestoreservice/api/Firebase/distributions`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseDistributionsDetail
     * @summary Получает рассылку
     * @request GET:/v1/onlinestoreservice/api/Firebase/distributions/{distributionGuid}
     * @secure
     * @response `200` `OnlineStoreResponsesFirebaseDistribution` Список рассылок
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseDistributionsDetail: (
      distributionGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<
        OnlineStoreResponsesFirebaseDistribution,
        void | CoreResponsesError
      >({
        path: `/v1/onlinestoreservice/api/Firebase/distributions/${distributionGuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseDistributionsBatchCreate
     * @summary Добавляет/изменяет рассылки
     * @request POST:/v1/onlinestoreservice/api/Firebase/distributions/batch
     * @secure
     * @response `200` `OnlineStoreResponsesBacthOperationResult` Список рассылок
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseDistributionsBatchCreate: (
      data: OnlineStoreRequestsFirebaseEditDistribution[],
      params: RequestParams = {}
    ) =>
      this.request<
        OnlineStoreResponsesBacthOperationResult,
        void | CoreResponsesError
      >({
        path: `/v1/onlinestoreservice/api/Firebase/distributions/batch`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseDistributionsUsercountCreate
     * @summary Получает кол-во пользователей подпадающих под критерии рассылки
     * @request POST:/v1/onlinestoreservice/api/Firebase/distributions/usercount
     * @secure
     * @response `200` `number` Кол-во пользователей
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 403 - нет доступа к запрашиваемому ресурсу
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseDistributionsUsercountCreate: (
      data: OnlineStoreRequestsFirebaseGetUserCount,
      params: RequestParams = {}
    ) =>
      this.request<number, void | CoreResponsesError>({
        path: `/v1/onlinestoreservice/api/Firebase/distributions/usercount`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Firebase
     * @name FirebaseDistributionsMessageCreate
     * @summary Создаёт уведомление
     * @request POST:/v1/onlinestoreservice/api/Firebase/distributions/message
     * @secure
     * @response `200` `(OnlineStoreResponsesFirebaseCreateMessage)[]` Статусы отправки
     * @response `401` `void` Unauthorized
     * @response `403` `CoreResponsesError` Forbidden
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    FirebaseDistributionsMessageCreate: (
      data: OnlineStoreRequestsFirebaseCreateMessage,
      params: RequestParams = {}
    ) =>
      this.request<
        OnlineStoreResponsesFirebaseCreateMessage[],
        void | CoreResponsesError
      >({
        path: `/v1/onlinestoreservice/api/Firebase/distributions/message`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
