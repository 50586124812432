import { useURLState } from 'shared/url-helper/useURLState';

import { OpeningProcessesListParams } from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';

type OpeningProcessesListParamsWithSearchText = OpeningProcessesListParams;

const convertorURLToRProtocol: URLToObjConverter<
  OpeningProcessesListParamsWithSearchText
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_RAIL_OPENING_TEMPLATE_LIST_FILTER_MAP
  ) as OpeningProcessesListParamsWithSearchText;
};

const CONVERT_RAIL_OPENING_TEMPLATE_LIST_FILTER_MAP: ConvertMapWithType<OpeningProcessesListParamsWithSearchText> =
  [
    { key: 'Page', type: ConvertTypes.number },
    { key: 'Limit', type: ConvertTypes.number },
    { key: 'ProtocolsGuids', type: ConvertTypes.masString },
    { key: 'OpeningTemplatesGuids', type: ConvertTypes.masString },
    { key: 'SearchText', type: ConvertTypes.masString },
    { key: 'Statuses', type: ConvertTypes.masNumber },
    { key: 'OrderBy', type: ConvertTypes.string },
  ];

export const useUrlStateRetailOpeningRetailProtocolFilter = () => {
  const [filter, setFilter] = useURLState(convertorURLToRProtocol, true);

  return {
    filter,
    setFilter,
  };
};
