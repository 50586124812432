import { useEffect, useState } from 'react';

import { getChecklistsAllowed } from 'shared/api/visit/services';
import { ChecklistGetListResponse } from 'shared/api/visit/types';

export const useGetCheckList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [checkLists, setCheckLists] = useState<ChecklistGetListResponse[]>([]);

  const getCheckLists = async () => {
    setIsLoading(true);

    const { data, status } = await getChecklistsAllowed();

    if (status === 200 && data) setCheckLists(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getCheckLists();
  }, []);

  return { isLoading, checkLists };
};
