import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useMemo } from 'react';
import { GetListBoardTask } from 'shared/api/services/types';
import { useViewModal } from 'shared/hooks/useViewModal';
import { MenuOption } from 'shared/ui/icon-with-menu';
import { BoardTaskFormCardFields } from '../BoardTaskFormCard';

export const useMutableBoardTaskCard = (
  task: GetListBoardTask,
  options: MenuOption[],
  canEdit: boolean,
  isLoading?: boolean
) => {
  const { isOpen, close, open } = useViewModal();

  const initCardValue: Partial<BoardTaskFormCardFields> = useMemo(() => {
    return { executor: task.executor, title: task.taskTitle };
  }, [task.executor, task.taskTitle]);

  const allCardActions = useMemo(() => {
    return [
      {
        title: 'Редактировать',
        icon: <Edit />,
        onClick: open,
        disabled: !canEdit,
      },
      ...options,
    ];
  }, [options]);

  const formActions = useMemo(() => {
    return (
      <>
        <LoadingButton
          loading={isLoading}
          type='submit'
        >
          Изменить
        </LoadingButton>
        <Button
          disabled={isLoading}
          onClick={close}
        >
          Закрыть
        </Button>
      </>
    );
  }, [isLoading]);

  return {
    initCardValue,
    allCardActions,
    formState: { open, close, isOpen },
    formActions,
  };
};
