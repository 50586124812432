import {
  AdvertisingType,
  SurfaceType,
} from 'shared/api/marketing-surface/marketingSurfaceAutogenerateApi';
import { mixed, object, ObjectSchema, string } from 'yup';
import { FormMarketingSurfaceValue } from './types';

export const SCHEME_VALIDATION_MARKETING_SURFACE_FORM: ObjectSchema<FormMarketingSurfaceValue> =
  object({}).shape({
    advertisingType: mixed<AdvertisingType>()
      .nullable()
      .test('advertisingType', 'Обязательное поле', (option) =>
        Boolean(option)
      ),
    surfaceType: mixed<SurfaceType>()
      .nullable()
      .test('SurfaceType', 'Обязательное поле', (option) => Boolean(option)),
    description: string().nullable(),
    width: mixed<string | number>()
      .test(
        'width',
        'Обязательное поле',
        (option) => Boolean(option) || option === 0
      )
      .test(
        'width',
        'Необходимо ввести положительное число',
        (option) =>
          (typeof option === 'number' || typeof option === 'string') &&
          Number(option) > 0
      )
      .required(),
    height: mixed<string | number>()
      .test(
        'height',
        'Обязательное поле',
        (option) => Boolean(option) || option === 0
      )
      .test(
        'height',
        'Необходимо ввести положительное число',
        (option) =>
          (typeof option === 'number' || typeof option === 'string') &&
          Number(option) > 0
      )
      .required(),
  });
