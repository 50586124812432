import { useState } from 'react';

import { postVisitings } from 'shared/api/visit/services';
import {
  VisitingCreateRequest,
  VisitingGetResponse,
} from 'shared/api/visit/types';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useCreateVisit = () => {
  const { open: openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [checklist, setChecklist] = useState<VisitingGetResponse | null>(null);

  const createVisit = async (info: VisitingCreateRequest) => {
    setIsLoading(true);

    try {
      const { data, status } = await postVisitings(info);

      if (status === 200 && data) setChecklist(data[0]);

      setIsLoading(false);

      return data ? data : null;
    } catch (e) {
      console.error('useCreateVisit.createVisit >> ', e);
      setIsLoading(false);

      const error = e as { message: string };

      openSnackbar({
        duration: 6000,
        type: 'error',
        text: error.message,
      });

      return Promise.reject(e);
    }
  };

  return { isLoading, checklist, createVisit };
};
