import { ALL_ROUTES, Routes } from './types';

const CREATED_DOCUMENT_CHILD: Routes[] = [
  {
    title: 'Служебная записка на оплату счёта',
    path: ALL_ROUTES.invocePayment,
    description: null,
    icon: null,
    fullPath: '',
    children: null,
    isWork: false,
  },
  {
    title: 'Служебная записка на выдачу денежных средств в подотчёт',
    path: ALL_ROUTES.invocePayment2,
    description: null,
    icon: null,
    fullPath: '',
    children: null,
    isWork: false,
  },
  {
    title: 'Служебная записка на оплату командировки',
    path: ALL_ROUTES.invocePayment3,
    description: null,
    icon: null,
    fullPath: '',
    children: null,
    isWork: false,
  },
];

export const DOCUMENT_FLOW: Routes[] = [
  {
    title: 'Делаю',
    path: ALL_ROUTES.action,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Контролирую',
    path: ALL_ROUTES.controls,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Наблюдаю',
    path: ALL_ROUTES.observe,
    isWork: true,
    children: null,
    description: null,
    icon: null,
    fullPath: '',
  },
  {
    title: 'Создать документ',
    path: ALL_ROUTES.createdDocument,
    isWork: true,
    children: CREATED_DOCUMENT_CHILD,
    description: null,
    icon: null,
    fullPath: '',
  },
];
