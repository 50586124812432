// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  Grid,
  Slide,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useStore } from 'effector-react';
import React from 'react';
import { TransitionProps } from 'react-transition-group/Transition';

import { postDocumentsDocumentIdDoActionFx } from './api/document-actions';
import { documentActionReturnDcumentCommentModal } from './models/document-actions-return-document';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction='left'
      ref={ref}
      {...props}
    />
  );
});

export const DocumentActionsReturnDocumentCommentModal = () => {
  const modal = useStore(documentActionReturnDcumentCommentModal.store);
  const onSubmiteForm = (
    element: React.FormEvent<HTMLFormElement> | undefined
  ) => {
    element?.preventDefault();
    documentActionReturnDcumentCommentModal.events.submiteReturnDocument();
  };
  const loading = useStore(postDocumentsDocumentIdDoActionFx.pending);

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
      open={Boolean(modal.documentId)}
      PaperProps={{
        sx: {
          maxWidth: '100vw',
        },
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid
        component={'form'}
        onSubmit={onSubmiteForm}
        container
        flexDirection={'column'}
        sx={{
          backgroundColor: 'white',
          minWidth: ['320px', '500px'],
          minHeight: ['100%', '300px'],
          padding: [1, 3],
          height: '100%',
          justifyContent: 'space-between',
          alignContent: 'space-between',
        }}
      >
        <Grid>
          <Typography
            textAlign={'center'}
            marginBottom={2}
          >
            Комментарий
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
          >
            <TextareaAutosize
              tabIndex={99}
              required={true}
              className={'TextareaAutosize_comment'}
              style={{
                width: '100%',
                minHeight: '100px',
                outline: 'none',
                marginBottom: '16px',
              }}
              placeholder='Введите комментарий'
              aria-label='empty textarea'
              value={modal.comment}
              onChange={(e) =>
                documentActionReturnDcumentCommentModal.events.changeComment(
                  e.target.value
                )
              }
            />
          </Box>
        </Grid>
        <Grid
          container
          flexDirection={'column'}
          flexGrow={2}
        ></Grid>
        <Grid
          container
          justifyContent={'space-between'}
        >
          <LoadingButton
            loading={loading}
            type='submit'
            size='small'
            variant='contained'
            sx={{ flexBasis: '47%', height: '40px', fontSize: 16 }}
          >
            Отправить
          </LoadingButton>
          <Button
            size='small'
            variant='contained'
            color='customGrey'
            onClick={() =>
              documentActionReturnDcumentCommentModal.events.reset()
            }
            sx={{ flexBasis: '47%', height: '40px', fontSize: 16 }}
          >
            Закрыть
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
