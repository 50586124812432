import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useDeleteFileMutation } from 'shared/api/services/rtk/fileApi';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';

export const useDeleteTaskFile = (taskGuid: string) => {
  const openConfirm = useConfirm();
  const { open: openSnackbar } = useSnackbar();
  const [qDeleteFile, { isLoading: isDeletingFile, error }] =
    useDeleteFileMutation();

  useShowFetchErrorInSnackbar(error);

  const deleteFile = useCallback(
    (fileGuid: string) => {
      openConfirm({
        actionTitle: 'Удалить файл',
        actionBody: (
          <Typography color={'GrayText'}>
            Файл будет удалён без возможности восстановления
          </Typography>
        ),
        submitButtonTitle: 'Удалить',
        buttonColor: 'error',
        onSubmit: () => {
          qDeleteFile({ taskGuid, fileGuid: fileGuid }).then(() => {
            openSnackbar({
              text: <strong>Файл удалён</strong>,
              type: 'success',
              duration: 3000,
            });
          });
        },
      });
    },
    [taskGuid]
  );

  return { deleteFile, isDeletingFile };
};
