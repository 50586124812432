import { OPTIONS_PROTOCOL_STATUSES } from 'entities/retail-opening-process/statuses';
import { useMemo } from 'react';
import {
  useGetOpeningTemplatesProtocolQuery,
  useGetOpeningTemplatesQuery,
} from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { useUrlStateRetailOpeningRetailProtocolFilter } from '../use-url-state-retail-opening-retail-protocol-filter';
import { FormFilterProtocolValue } from './types';

export const useGetInitialValueForFormFilterProtocolFromUrlState = (arg?: {
  skip?: boolean;
}) => {
  const { filter } = useUrlStateRetailOpeningRetailProtocolFilter();

  const { OpeningTemplatesGuids, ProtocolsGuids, Statuses } = filter || {};

  const {
    data: protocols,
    isFetching: isFetchingProtocol,
    isLoading: isLoadingProtocol,
  } = useGetOpeningTemplatesProtocolQuery(
    { params: {} },
    { skip: !ProtocolsGuids || arg?.skip }
  );

  const {
    data: templates,
    isFetching: isFetchingTemplates,
    isLoading: isLoadingTemplates,
  } = useGetOpeningTemplatesQuery(
    { Page: 1, Limit: 9999 },
    { skip: !OpeningTemplatesGuids || arg?.skip }
  );

  const initialValue: FormFilterProtocolValue = useMemo(() => {
    const templatesValues =
      OpeningTemplatesGuids && templates?.items
        ? templates.items.filter((item) =>
            OpeningTemplatesGuids.includes(item.guid)
          )
        : EMPT_ARR;
    const protocolValues =
      protocols && ProtocolsGuids
        ? protocols.filter((item) => ProtocolsGuids.includes(item.guid))
        : EMPT_ARR;

    const statuses = Statuses
      ? OPTIONS_PROTOCOL_STATUSES.filter((item) => Statuses.includes(item.id))
      : EMPT_ARR;

    return {
      templates: templatesValues,
      protocols: protocolValues,
      statuses: statuses,
    };
  }, [
    templates?.items,
    OpeningTemplatesGuids,
    protocols,
    ProtocolsGuids,
    Statuses,
  ]);

  return {
    initialValue,
    isLoadingInitValue:
      isFetchingTemplates ||
      isLoadingTemplates ||
      isFetchingProtocol ||
      isLoadingProtocol,
  };
};
