import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';
import { OpeningTemplatesList } from './OpeningTemplatesList';
import { OpeningTemplatesTable } from './OpeningTemplatesTable';
import { OpeningTemplatesTableProps } from './types';

export const OpeningTemplatesAdaptiveTable: FC<OpeningTemplatesTableProps> = (
  props
) => {
  const [isMobile] = useDevice();

  if (isMobile) return <OpeningTemplatesList {...props} />;
  else return <OpeningTemplatesTable {...props} />;
};
