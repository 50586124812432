import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';

import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import { CreateOtherEventForm } from 'widgets/visits/forms/create-other-event-form';

import { EditOtherEventDialogProps } from './type';

export const EditOtherEventDialog: FC<EditOtherEventDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initValue,
  isLoading,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Редактирование мероприятия' />}
    >
      <CreateOtherEventForm
        onSubmit={onSubmit}
        initValue={initValue}
        actions={() => [
          <Button
            key={1}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
          <LoadingButton
            key={2}
            loading={isLoading}
            variant='contained'
            type='submit'
          >
            Создать
          </LoadingButton>,
        ]}
      />
    </DialogForForm>
  );
};
