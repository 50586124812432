import { Button, Grid } from '@mui/material';
import {
  getOptionLabelProtocol,
  getOptionLabelTemplate,
  renderOptionProtocol,
  renderOptionTemplates,
} from 'features/retail-opeting-process/form-run-opening-retail-process/libs/helper-render-autocomplite';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { OPTIONS_PROTOCOL_STATUSES } from 'entities/retail-opening-process/statuses';
import {
  useGetOpeningTemplatesProtocolQuery,
  useGetOpeningTemplatesQuery,
} from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { AutocompliteWithController } from 'shared/fields-with-contoller';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { DEFAULT_VALUE } from './constants';
import {
  DialogFormFilterProtocolProps,
  FormFilterProtocolValue,
} from './types';
import { useGetInitialValueForFormFilterProtocolFromUrlState } from './useGetInitialValueForFormFilterProtocolFromUrlState';

export const DialogFormFilterProtocol: FC<DialogFormFilterProtocolProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const {
    data: protocols,
    isFetching: isFetchingProtocol,
    isLoading: isLoadingProtocol,
  } = useGetOpeningTemplatesProtocolQuery({ params: {} }, { skip: !isOpen });

  const {
    data: templates,
    isFetching: isFetchingTemplates,
    isLoading: isLoadingTemplates,
  } = useGetOpeningTemplatesQuery({ Page: 1, Limit: 9999 }, { skip: !isOpen });
  const { initialValue } = useGetInitialValueForFormFilterProtocolFromUrlState({
    skip: !isOpen,
  });

  const { control, handleSubmit, reset } = useForm<FormFilterProtocolValue>({
    defaultValues: initialValue || DEFAULT_VALUE,
  });

  useEffect(() => {
    if (initialValue) reset(initialValue);
  }, [initialValue]);

  return (
    <DialogForForm
      open={isOpen}
      disabledConteiner
      onClose={close}
    >
      <Grid
        component={'form'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        gap={2}
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitleForForm title={'Фильтр'} />
        <Grid
          display={'flex'}
          flexDirection={'column'}
          gap={2}
        >
          <AutocompliteWithController
            loading={isLoadingTemplates || isFetchingTemplates}
            disabled={isLoadingTemplates || isFetchingTemplates}
            control={control}
            multiple={true}
            name={'templates'}
            isOptionEqualToValue={(option, value) => option.guid === value.guid}
            renderOption={renderOptionTemplates}
            freeSolo={false}
            disableCloseOnSelect
            getOptionLabel={getOptionLabelTemplate}
            options={templates?.items || EMPT_ARR}
            textFieldProps={{
              label: 'Шаблон',
            }}
          />

          <AutocompliteWithController
            loading={isLoadingProtocol || isFetchingProtocol}
            disabled={isLoadingProtocol || isFetchingProtocol}
            options={protocols || EMPT_ARR}
            multiple={true}
            control={control}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.guid === value.guid}
            renderOption={renderOptionProtocol}
            getOptionLabel={getOptionLabelProtocol}
            name={'protocols'}
            textFieldProps={{
              label: 'Протокол',
            }}
          />

          <AutocompliteWithController
            loading={isLoadingProtocol || isFetchingProtocol}
            disabled={isLoadingProtocol || isFetchingProtocol}
            control={control}
            name={'statuses'}
            options={OPTIONS_PROTOCOL_STATUSES}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            multiple={true}
            disableCloseOnSelect
            textFieldProps={{
              label: 'Статус',
            }}
          />
        </Grid>
        <ContainerActionsForm>
          <Button
            type={'submit'}
            variant='contained'
          >
            Сохранить
          </Button>
          <Button
            onClick={onClose}
            color='customGrey'
            variant='contained'
          >
            Закрыть
          </Button>
        </ContainerActionsForm>
      </Grid>
    </DialogForForm>
  );
};
