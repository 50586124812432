import { LoadingButton } from '@mui/lab';
import { Button, Grid, Switch, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  useGetAllEmployeesQuery,
  useGetAllowedCoExecutersQuery,
} from 'shared/api/services/rtk/optionApi';
import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import { CustomAutocomplete } from 'shared/ui/base-ui';
import { DialogForForm, FormLayout } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import {
  ChangeCoExecutersAndObserversFormFields,
  ChangeCoExecutersAndObserversFormProps,
} from './type';

export const ChangeCoExecutersAndObserversForm: FC<
  ChangeCoExecutersAndObserversFormProps
> = ({ onSubmit, renderFormActions, initValue }) => {
  const { data: coExecuters, isLoading: isLoadingCoExecutors } =
    useGetAllowedCoExecutersQuery();
  const { data: employees, isLoading: isLoadingEmloyees } =
    useGetAllEmployeesQuery();

  const { control, handleSubmit } =
    useForm<ChangeCoExecutersAndObserversFormFields>({
      defaultValues: initValue,
    });

  const isCoExecutorsChanged = useWatch({
    control: control,
    name: 'isCoExecutorsChanged',
  });

  const isObserversChanged = useWatch({
    control: control,
    name: 'isObserversChanged',
  });

  return (
    <FormLayout
      actions={renderFormActions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit, console.error)}
    >
      <Grid
        container
        gap={2}
        flexDirection={'row'}
      >
        <Controller
          control={control}
          name='isCoExecutorsChanged'
          render={({ field: { onChange, value } }) => (
            <Switch
              checked={value}
              onChange={(_, isChecked) => {
                onChange(isChecked);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name='coExecuter'
          rules={{ required: isCoExecutorsChanged }}
          render={({ field: { value, onChange } }) => (
            <CustomAutocomplete
              value={value}
              multiple
              loading={isLoadingCoExecutors}
              disabled={!isCoExecutorsChanged}
              onChange={(_, newValue) => {
                onChange(newValue);
              }}
              renderOption={(props, option) => (
                <Typography
                  {...props}
                  key={option.guid}
                >
                  {getEmployeTitle({
                    employeeName: option.name,
                    employeePosition: option.position?.title,
                  })}
                </Typography>
              )}
              sx={{ flex: 1 }}
              options={coExecuters || []}
              textFieldProps={{
                label: 'Соисполнители',
                placeholder: 'Выберите соисполнителей',
              }}
              getOptionLabel={(emp) =>
                getEmployeTitle({
                  employeeName: emp.name,
                  employeePosition: emp.position?.title,
                })
              }
            />
          )}
        />
      </Grid>

      <Grid
        container
        gap={2}
        flexDirection={'row'}
      >
        <Controller
          control={control}
          name='isObserversChanged'
          render={({ field: { onChange, value } }) => (
            <Switch
              checked={value}
              onChange={(_, isChecked) => {
                onChange(isChecked);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name='observers'
          rules={{ required: isObserversChanged }}
          render={({ field: { value, onChange } }) => (
            <CustomAutocomplete
              value={value}
              multiple
              loading={isLoadingEmloyees}
              disabled={!isObserversChanged}
              onChange={(_, newValue) => {
                onChange(newValue);
              }}
              sx={{ flex: 1 }}
              renderOption={(props, option) => (
                <Typography
                  {...props}
                  key={option.guid}
                >
                  {getEmployeTitle({
                    employeeName: option.name,
                    employeePosition: option.position?.title,
                  })}
                </Typography>
              )}
              options={employees || []}
              textFieldProps={{
                label: 'Наблюдатели',
                placeholder: 'Выберите наблюдателей',
              }}
              getOptionLabel={(emp) =>
                getEmployeTitle({
                  employeeName: emp.name,
                  employeePosition: emp.position?.title,
                })
              }
            />
          )}
        />
      </Grid>
    </FormLayout>
  );
};

interface ChangeCoExecutersAndObserversDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: ChangeCoExecutersAndObserversFormFields) => void;
  isLoading?: boolean;
  initValue?: ChangeCoExecutersAndObserversFormFields;
}
export const ChangeCoExecutersAndObserversDialog: FC<
  ChangeCoExecutersAndObserversDialogProps
> = ({ isOpen, onClose, onSubmit, isLoading, initValue }) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={
        <DialogTitleForForm title='Изменение соисполнителей и наблюдателей' />
      }
    >
      <ChangeCoExecutersAndObserversForm
        onSubmit={onSubmit}
        initValue={initValue}
        renderFormActions={() => [
          <LoadingButton
            loading={isLoading}
            key={1}
            variant='contained'
            type='submit'
          >
            Сохранить
          </LoadingButton>,
          <Button
            key={2}
            variant='contained'
            color='customGrey'
            onClick={onClose}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
