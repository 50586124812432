// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { Button, Typography } from '@mui/material';
import {
  BasicVisitInfoForm,
  prepareDataToInitStage1,
} from 'features/visit/Stage 1';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { ALL_ROUTES } from 'shared/routes/constants/types';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';

import { useCreateChecklist } from 'entities/visit/checkList/hooks/useCreateChecklist';
import { useGetVisit } from 'entities/visit/visit/hooks/useGetVisit';
import { useVisitNavigation } from 'entities/visit/visit/hooks/useVisitNavigation';

export const VisitInformationPage: FC = () => {
  const { id } = useParams();
  const { visit, isLoading, status } = useGetVisit(Number(id));
  const { createChecklist, isLoading: isLoadingCreate } = useCreateChecklist();
  const { navigateToCheckList } = useVisitNavigation();

  if (!isLoading && status !== 200)
    return (
      <Typography
        fontSize={24}
        fontWeight={'bold'}
      >
        Упс, что-то пошло не так. Возможно вы пытаетесь открыть страницу
        посещения, которой уже нет)
      </Typography>
    );

  return (
    <>
      <CustomBackdrop isLoading={isLoading || isLoadingCreate} />
      {visit && (
        <BasicVisitInfoForm
          header={
            <Typography
              fontSize={20}
              fontWeight={'bold'}
            >
              Посещение {visit.retail.shortTitle}
            </Typography>
          }
          onSubmit={() => {}}
          disableAll
          initValue={prepareDataToInitStage1(visit)}
          footer={
            visit.visitingChecklistId ? (
              <Button
                variant='contained'
                size='large'
                onClick={() => {
                  visit.visitingChecklistId &&
                    navigateToCheckList(visit.visitingChecklistId);
                }}
              >
                Открыть чек-лист
              </Button>
            ) : (
              <Button
                variant='contained'
                size='large'
                onClick={() => {
                  createChecklist(visit.id)
                    .then((checklist) => {
                      if (checklist) {
                        navigateToCheckList(checklist.id, 1, {
                          redirect:
                            '/' +
                            ALL_ROUTES.visitingPharmacies +
                            '/' +
                            ALL_ROUTES.planning,
                        });
                      }
                    })
                    .catch(() => {});
                }}
              >
                Создать чек-лист
              </Button>
            )
          }
        />
      )}
    </>
  );
};
