// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

export const delEmpty = (obj: object) => {
  const newObj: any = {};

  for (const key in obj) {
    // @ts-ignore
    const val = obj[key];

    if (val === undefined || (Array.isArray(val) && val.length === 0)) continue;
    newObj[key] = val;
  }

  return newObj;
};
