import { useChangeTaskStatusModal } from 'features/task/hooks/useChangeTaskStatusModal';
import { useConvertTaskFilter } from 'features/task/hooks/useConvertTaskFilter';
import { TaskDrawer, useTaskDrawer } from 'features/task/task-drawer';
import { useCallback, useMemo } from 'react';

import { GetListTask } from 'shared/api/services/types';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { useDevice } from 'shared/utils/hook-type-device';

import { TaskTableActions } from 'widgets/tasks/task-table/actions/TaskTableActions';

import { AddTaskToBoardDialog } from 'features/boards/dialogs/AddTaskToBoardDialog';
import { AddTaskToBoardFormFields } from 'features/boards/forms/AddTaskToBoardForm';
import { useDeleteTask } from 'features/task/hooks/useDeleteTask';
import { useTaskFilterModal } from 'features/task/hooks/useTaskFilterModal';
import {
  MemoizedManagerBarTasks,
  MemoizedTaskTable,
} from 'features/task/memoComponents';
import { useShowFetchErrorInSnackbar } from 'shared/api/hooks/useShowFetchErrorInSnackbar';
import { useMoveTaskToAnotherBoardMutation } from 'shared/api/services/rtk/boardApi';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { Tables } from 'shared/utils/controllers';
import { useControlTasks } from './contexts/control-task-context /ControlTaskContext';
import { taskFilterToOrder } from './libs/orderConvFun';

export const ControlTasks = () => {
  const { isLoading } = useConvertTaskFilter();

  if (isLoading) return <CustomBackdrop isLoading />;
  else return <ControlTasksPage />;
};

const ControlTasksPage = () => {
  const [isMobile] = useDevice();

  /* Delete task */
  const deleteTask = useDeleteTask();
  /* ===== */

  /* Table fields */
  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<GetListTask>(Tables.CONTROL_TASKS);
  /* ===== */

  /* Tasks */
  const {
    isLoading,
    tasks,
    changeLimit,
    changePage,
    changeOrder,
    count,
    filter,
    setFilter,
    changeSearch,
  } = useControlTasks();
  /* ===== */

  /* Changing statuses */
  const { modal: changeTaskStatusModal, open: openStatusChangeModal } =
    useChangeTaskStatusModal();
  /* ===== */

  /* Task Drawer */
  const { isOpenDrawer, selectedTaskGuid, closeDrawer, tableClickHandler } =
    useTaskDrawer();
  /* ===== */

  /* Filter modal */
  const { taskFilterModal, open: openTaskFilterDialog } = useTaskFilterModal(
    'forControlTasks',
    filter,
    setFilter
  );
  /* ===== */

  /* ===== */
  const {
    close: closeMove,
    isOpen: isOpenMove,
    initValue: moveTaskGuid,
    open: openMove,
  } = useViewModalWithInitFields<string>();
  const [moveTask, { isLoading: isMovingTask, error }] =
    useMoveTaskToAnotherBoardMutation();

  useShowFetchErrorInSnackbar(error);

  const handleSubmitMove = useCallback(
    (form: AddTaskToBoardFormFields) => {
      if (moveTaskGuid)
        moveTask({
          tasksGuids: [moveTaskGuid],
          boardColumnGuid: form.columnGuid,
        }).then(closeMove);
    },
    [moveTaskGuid]
  );
  /* ===== */

  const renderActions = useCallback((task: GetListTask) => {
    return (
      <TaskTableActions
        onChangeStatus={() => {
          openStatusChangeModal(task);
        }}
        onDelete={deleteTask}
        onMoveToBoard={openMove}
        task={task}
      />
    );
  }, []);

  const order = useMemo(() => {
    return taskFilterToOrder(filter);
  }, [filter]);

  return (
    <LayoutPage.Page>
      {changeTaskStatusModal}
      {taskFilterModal}
      <AddTaskToBoardDialog
        isOpen={isOpenMove}
        onClose={closeMove}
        isLoading={isMovingTask}
        onSubmit={handleSubmitMove}
      />
      <LayoutPage.Bar>
        <MemoizedManagerBarTasks
          openSettings={openTaskFilterDialog}
          onCompliteInput={(val) => {
            changeSearch(val);
          }}
          initSearch={filter?.SearchText || ''}
        />
      </LayoutPage.Bar>
      <LayoutPage.Content>
        {!isMobile && (
          <TaskDrawer
            isOpen={isOpenDrawer}
            onClose={closeDrawer}
            taskGuid={selectedTaskGuid}
          />
        )}
        <MemoizedTaskTable
          fields={fields}
          visibleFields={visibleFields}
          onChangeFields={setFields}
          data={tasks || []}
          isLoading={isLoading}
          count={count}
          limit={filter?.Limit || 20}
          page={filter?.Page || 1}
          onChangeLimit={changeLimit}
          onChangePage={changePage}
          order={order}
          onChangeOrder={changeOrder}
          onClick={tableClickHandler}
          renderActions={renderActions}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
