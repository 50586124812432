import { LinearProgress } from '@mui/material';
import { BoardSearchBar } from 'features/boards/BoardSearchBar/BoardSearchBar';
import { ColumnList } from 'features/boards/ColumnList';
import { prepareBoardTaskFilterForFetch } from 'features/boards/forms/BoardTaskFilterForm/libs/prepareBoardTaskFilterForFetch';
import { TaskDrawer } from 'features/task/task-drawer';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetColumnsQuery } from 'shared/api/services/rtk/boardApi';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { TaskMultiActions } from 'widgets/tasks/TaskMultiActions';
import {
  BoardDragDropProvider,
  useBoardDragDrop,
} from './context/BoardDragDropContext';
import { BoardPageProvider, useBoardPage } from './context/BoardPageContext';
import { useURLBoard } from './hooks/useURLBoard';

export const Board = () => {
  const { guid } = useParams();
  const boardGuid = guid as string;

  const [filter, setFilter] = useURLBoard();

  const { taskDrawer, showMainLoader } = useBoardPage();

  const { data, isFetching: isFetchingColumns } = useGetColumnsQuery(boardGuid);

  const sortedColumns = useMemo(() => {
    if (!data) return [];

    return [...data].sort((a, b) => a.boardOrderNumber - b.boardOrderNumber);
  }, [data]);

  const { selectedTasks, unSelectAll } = useBoardDragDrop();

  return (
    <>
      <TaskMultiActions
        taskGuids={selectedTasks}
        unselectAll={unSelectAll}
      />
      <LayoutPage.Page>
        <LayoutPage.Bar>
          <BoardSearchBar
            initValue={filter}
            initSearch={filter?.SearchText}
            onCompleteSearch={(search) => {
              setFilter({ ...filter, SearchText: search });
            }}
            onFilterSubmit={(form) => {
              const fil = prepareBoardTaskFilterForFetch(form);

              setFilter({ ...filter, ...fil });
            }}
          />
          <LinearProgress
            sx={{
              opacity: Number(showMainLoader || isFetchingColumns),
              mt: 1,
              borderRadius: 4,
            }}
          />
        </LayoutPage.Bar>
        <LayoutPage.Content>
          <TaskDrawer
            isOpen={taskDrawer.isOpen}
            onClose={taskDrawer.close}
            taskGuid={taskDrawer.taskGuid || ''}
          />
          <ColumnList columns={sortedColumns} />
        </LayoutPage.Content>
      </LayoutPage.Page>
    </>
  );
};

export const BoardPage = () => {
  const { guid } = useParams();

  const boardGuid = guid as string;

  return (
    <BoardPageProvider boardGuid={boardGuid}>
      <BoardDragDropProvider useBoardPage={useBoardPage}>
        <Board />
      </BoardDragDropProvider>
    </BoardPageProvider>
  );
};
