//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from 'axios';
import type { SwaggerResponse } from './config';
import { Http } from './httpRequest';
//@ts-ignore
import qs from 'qs';
import type {
  AuthRequestsAuthentication,
  AuthRequestsRefresh,
  AuthResponsesEmployeeInformation,
  AuthServiceRequestsUpdateAccountSettings,
  AuthServiceResponsesDepartment,
  AuthServiceResponsesOnlyPermission,
  AuthServiceResponsesPermission,
  AuthServiceResponsesRetail,
  CoreResponsesAuthentication,
  GetEmployeePermissionsRetailsQueryParams,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, 'i');
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

/**
 *
 * Возвращает информацию о сотруднике
 */
export const getEmployee = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthResponsesEmployeeInformation>> => {
  return Http.getRequest(
    getEmployee.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployee.key = '/v1/auth/api/Employee';

/**
 *
 * Возвращает список подразделений в которые трудоустроен сотрудник (для сотрудников ГО возвращаются все подразделения)
 */
export const getEmployeeDepartments = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthServiceResponsesDepartment[]>> => {
  return Http.getRequest(
    getEmployeeDepartments.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeeDepartments.key = '/v1/auth/api/Employee/departments';

/**
 *
 * Возвращает список аптек и офисов в которые трудоустроен сотрудник
 */
export const getEmployeeEmployments = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthServiceResponsesRetail[]>> => {
  return Http.getRequest(
    getEmployeeEmployments.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeeEmployments.key = '/v1/auth/api/Employee/employments';

/**
 *
 * Возвращает торговые точки к которым есть доступ исходя из места трудоустройства
 */
export const getEmployeePermissionsRetails = (
  queryParams: GetEmployeePermissionsRetailsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthServiceResponsesRetail[]>> => {
  return Http.getRequest(
    getEmployeePermissionsRetails.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getEmployeePermissionsRetails.key = '/v1/auth/api/Employee/permissions/retails';

/**
 *
 * Возвращает access token и refresh token
 */
export const postAuthentication = (
  requestBody: AuthRequestsAuthentication,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CoreResponsesAuthentication>> => {
  return Http.postRequest(
    postAuthentication.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAuthentication.key = '/v1/auth/api/Authentication';

/**
 *
 * Обновляется токен
 */
export const postAuthenticationRefresh = (
  requestBody: AuthRequestsRefresh,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CoreResponsesAuthentication>> => {
  return Http.postRequest(
    postAuthenticationRefresh.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAuthenticationRefresh.key = '/v1/auth/api/Authentication/refresh';

/**
 *
 * Возвращает права на указанные модули при наличии таковых
 */
export const postEmployeeOnlypermissions = (
  requestBody: string[],
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthServiceResponsesOnlyPermission[]>> => {
  return Http.postRequest(
    postEmployeeOnlypermissions.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployeeOnlypermissions.key = '/v1/auth/api/Employee/onlypermissions';

/**
 *
 * Возвращает права на указанные модули при наличии таковых в разбивке по группам
 */
export const postEmployeePermissions = (
  requestBody: string[],
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthServiceResponsesPermission[]>> => {
  return Http.postRequest(
    postEmployeePermissions.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployeePermissions.key = '/v1/auth/api/Employee/permissions';

/**
 *
 * Обновляет запись настроек ЛК для текущего пользователя
 */
export const postEmployeeUpdateAccountSettings = (
  requestBody: AuthServiceRequestsUpdateAccountSettings,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postEmployeeUpdateAccountSettings.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postEmployeeUpdateAccountSettings.key =
  '/v1/auth/api/Employee/updateAccountSettings';
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};
export const _CONSTANT1 = [{ Bearer: [] }];
