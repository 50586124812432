import { Typography } from '@mui/material';
import { FC } from 'react';

import { formatingDate } from 'shared/utils/formating-date';

export const DocumentPayBeforeDateUi: FC<{
  payBeforeDate?: string;
}> = ({ payBeforeDate }) => {
  return (
    <Typography sx={{ minWidth: ['max-content', '120px'] }}>
      {payBeforeDate ? formatingDate(payBeforeDate, false) : '-'}
    </Typography>
  );
};
