export const OkSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='34'
      fill='none'
      viewBox='0 0 20 34'
    >
      <path
        fill='#F6921E'
        d='M10.079.72C5.459.72 1.66 4.458 1.66 9.115c0 4.607 3.747 8.394 8.419 8.394 4.62 0 8.418-3.736 8.418-8.394A8.378 8.378 0 0010.08.721zm0 11.875c-1.9 0-3.491-1.536-3.491-3.48 0-1.945 1.54-3.48 3.49-3.48 1.9 0 3.491 1.535 3.491 3.48 0 1.944-1.54 3.48-3.49 3.48zM13.056 24.162c3.388-.665 5.39-2.252 5.492-2.354.976-.768 1.13-2.201.36-3.225-.77-.972-2.208-1.126-3.234-.358 0 0-2.156 1.638-5.698 1.689-3.542 0-5.75-1.638-5.75-1.69-.975-.767-2.412-.613-3.233.36-.77.972-.616 2.405.36 3.224.102.102 2.206 1.74 5.646 2.405L2.173 29.23c-.872.921-.872 2.354.052 3.224.462.41 1.026.615 1.591.615.616 0 1.18-.256 1.643-.717l4.466-4.76 4.927 4.811c.873.87 2.362.87 3.234 0 .873-.87.873-2.354 0-3.224l-5.03-5.016z'
      ></path>
    </svg>
  );
};
