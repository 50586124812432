import { Typography } from '@mui/material';

import { ResponsesTrainingEventEmployeeResponses } from 'shared/api/traning/types';

import { FieldMapType } from 'shared/ui/table/ui-menu-table';

type VurrentTableFields = FieldMapType<
  ResponsesTrainingEventEmployeeResponses & { number: number }
>[];

export const CURRENT_TRAINING_FIELD_MAP_SUBTABLE: VurrentTableFields = [
  {
    field: 'number',
    title: 'п/п',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 0,
    renderComponent: ({ number }) => {
      return <Typography>{number}</Typography>;
    },
  },
  {
    field: 'fio',
    title: 'Фио',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{value.fio}</Typography>;
    },
  },
  {
    field: 'employeePosition',
    title: 'Должность',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 2,
    renderComponent: (value) => {
      return <Typography>{value.employeePosition}</Typography>;
    },
  },
  {
    field: 'employment',
    title: 'Место трудоустройства',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 3,
    renderComponent: (value) => {
      return <Typography>{value.employment}</Typography>;
    },
  },
  {
    field: 'statusTitle',
    title: 'Статус',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 4,
    renderComponent: (value) => {
      return <Typography>{value.statusTitle}</Typography>;
    },
  },
  {
    field: 'comment',
    title: 'Комментарий сотрудника',
    getTableCellProps: () => {
      return {};
    },
    isShow: true,
    order: 1,
    renderComponent: (value) => {
      return <Typography>{value.comment}</Typography>;
    },
  },
];
