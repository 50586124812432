import { FC } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTableRowWithActions,
} from 'shared/ui/table';

import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';
import { ActionsOpeningTemplateTask } from './ActionsOpeningTemplateTask';
import { OpeningTemplatesTasksTableProps } from './types';

export const OpeningTemplatesTasksTable: FC<
  OpeningTemplatesTasksTableProps
> = ({
  isLoading,
  isUpdate,
  data,
  visibleFields,
  fields,
  onChangeFields,
  templateGuid,
  stageGuid,
  countdownFieldTitle,
}) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={1} />}
      header={
        <UiTableHeader
          viewCells={visibleFields}
          allCells={fields}
          setViewCells={onChangeFields}
        />
      }
      body={data.map((template) => (
        <UiTableRowWithActions
          key={template.guid}
          renderActions={() => (
            <ActionsOpeningTemplateTask
              templateTask={template}
              templateGuid={templateGuid}
              stageGuid={stageGuid}
              countdownFieldTitle={countdownFieldTitle}
            />
          )}
        >
          {visibleFields.map((el) => (
            <UiTableCell
              key={el.field + template.guid}
              {...el.getTableCellProps()}
            >
              {el.renderComponent(template) || 'Error'}
            </UiTableCell>
          ))}
        </UiTableRowWithActions>
      ))}
    />
  );
};
