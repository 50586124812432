import { Button, Dialog, Grid } from '@mui/material';
import { LatLngBoundsExpression, Map } from 'leaflet';
import { useEffect, useMemo, useState } from 'react';

import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { CustomLinerActivityIndicator } from 'shared/ui/custom-liner-activity-indicator';
import { CustomSwitcher } from 'shared/ui/custom-switcher';
import { getBound, UiMap } from 'shared/ui/map';
import { UiMarkerClusterGroup } from 'shared/ui/map/ui-marker-cluster-group/UiMarkerClusterGroup';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import { useDevice } from 'shared/utils/hook-type-device';

import { findBlockedRetails } from 'entities/retail/libs/findBlockedRetails';
import { searchRetails } from 'entities/retail/libs/searchRetails';
import { useGetDepartments } from 'entities/visit/options/useGetDepartments';
import { useGetRetails } from 'entities/visit/options/useGetRetails';

import { DepartmentSelect } from '../DepartmentSelect';
import { RetailMapMarker } from '../RetailMapMarker';
import { RetailsList } from '../RetailsList';
import { RetailGetListResponseNew } from '../Stage 1/type';
import { RetailSelectDialogComponent } from './type';

export const RetailSelectDialog: RetailSelectDialogComponent = ({
  selectedDate,
  multiple,
  value,
  onChange,
  isOpen,
  onClose,
}) => {
  const [isMobile] = useDevice();

  const { departments, isLoading: isLoadingDepartments } = useGetDepartments();

  const [map, setMap] = useState<Map | null>(null);
  const [search, setSearch] = useState('');
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<number>(0);
  const [isMapSelected, setIsMapSelected] = useState(true);

  const retailFiter = useMemo(() => {
    return {
      DepartmentsIds: selectedDepartmentId ? [selectedDepartmentId] : undefined,
      IsOnlyAllowedRequired: true,
    };
  }, [selectedDepartmentId]);
  const { retails, isLoading: isLoadingRetails } = useGetRetails(retailFiter);

  const filteredRetails = useMemo(() => {
    const filtered = searchRetails(retails, search);

    // DEV: TEST
    // if (filtered.length > 0) {
    //   const dat = new Date();
    //   dat.setDate(1);
    //   filtered[0].allowedDates = [dayjs(dat).format(DATE_FORMATS.server)];
    //   dat.setDate(2);
    //   filtered[0].allowedDates.push(dayjs(dat).format(DATE_FORMATS.server));

    //   dat.setDate(3);
    //   filtered[1].allowedDates = [dayjs(dat).format(DATE_FORMATS.server)];
    //   dat.setDate(4);
    //   filtered[1].allowedDates.push(dayjs(dat).format(DATE_FORMATS.server));
    // }

    return filtered;
  }, [search, retails]);

  const bound = useMemo(() => {
    if (filteredRetails.length > 0) {
      const b = getBound(
        filteredRetails.map((retail) => [
          retail.coordinateY || 0,
          retail.coordinateX || 0,
        ])
      );

      return b;
    }

    return undefined;
  }, [filteredRetails]);

  useEffect(() => {
    if (bound)
      map?.fitBounds(bound as LatLngBoundsExpression, {
        animate: false,
      });
  }, [bound, map]);

  const disabledRetails = useMemo(() => {
    if (!selectedDate) return null;

    return findBlockedRetails(filteredRetails, selectedDate);
  }, [filteredRetails, selectedDate]);

  const isRetailDisable = (retail: RetailGetListResponseNew) => {
    if (!disabledRetails) return false;

    return disabledRetails.some((disRet) => disRet === retail.id);
  };

  const selectHandler = (retail: RetailGetListResponseNew) => {
    if (!onChange) return;
    if (multiple) {
      if (value) onChange([...value, retail]);
      else onChange([retail]);
    } else {
      onChange(retail);
    }
  };

  const unSelectHandler = (retail: RetailGetListResponseNew) => {
    if (!onChange) return;
    if (multiple) {
      onChange(value?.filter((el) => el.id !== retail.id) || []);
    } else {
      onChange(null);
    }
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={isOpen}
      onClose={onClose}
      scroll='body'
      sx={{
        '& > .MuiDialog-container': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      PaperProps={{
        sx: {
          minWidth: '85vw',
          minHeight: '85vh',
          display: 'flex',
          overflow: 'hidden',
          position: 'relative',
        },
      }}
    >
      <Grid
        container
        flexDirection={'column'}
      >
        <CustomBackdrop isLoading={isLoadingDepartments} />
        <Grid p={1}>
          <UiSearchBar
            changeValue={setSearch}
            textValue={search}
            onClear={() => {
              setSearch('');
            }}
          >
            <CustomSwitcher
              options={['На карте', 'Списком']}
              onChange={(value) => {
                setIsMapSelected(value === 'На карте');
              }}
              value={isMapSelected ? 'На карте' : 'Списком'}
              buttonProps={{ color: 'customBlue', sx: { width: '100%' } }}
              buttonGroupProps={{ sx: { width: ['100%', 'auto'] } }}
            />

            <DepartmentSelect
              departments={departments}
              onSelectDepartment={setSelectedDepartmentId}
              selectedDepartmentId={selectedDepartmentId}
              selectProps={{ sx: { width: ['100%', 'auto'] } }}
            />

            <Button
              color='customBlue'
              variant='contained'
              onClick={onClose}
            >
              Сохранить
            </Button>
          </UiSearchBar>
        </Grid>

        <Grid
          container
          flexDirection={'column'}
          flexGrow={1}
          position={'relative'}
        >
          <CustomLinerActivityIndicator isLoading={isLoadingRetails} />

          {isMapSelected ? (
            <UiMap
              mapContainerProps={{ ref: setMap }}
              containerProps={{ flexGrow: 1, zIndex: 1 }}
            >
              <UiMarkerClusterGroup>
                {filteredRetails.map((retail) => (
                  <RetailMapMarker
                    key={retail.id}
                    retail={retail}
                    isSelected={
                      multiple
                        ? value?.some(({ id }) => id === retail.id)
                        : value?.id === retail.id
                    }
                    disable={isRetailDisable(retail)}
                    onUnSelect={() => {
                      unSelectHandler(retail);
                    }}
                    onSelect={() => {
                      selectHandler(retail);
                    }}
                  />
                ))}
              </UiMarkerClusterGroup>
            </UiMap>
          ) : (
            <RetailsList
              retails={filteredRetails}
              disabledRetailsId={disabledRetails}
              onSelect={selectHandler}
              selectedRetailsId={
                multiple
                  ? value?.map((el) => el.id) || []
                  : value
                  ? [value.id]
                  : []
              }
              onUnSelect={unSelectHandler}
            />
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};
