import CommentIcon from '@mui/icons-material/Comment';
import { Badge, Grid } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { theme } from 'shared/theme/theme';

export const DocumentCommentCountUi: FC<{
  commentCount?: number;
  hasUnreadComment?: boolean;
  id: number;
}> = ({ commentCount, hasUnreadComment, id }) => {
  const navigate = useNavigate();

  return (
    <Grid
      justifyContent={'center'}
      display={'flex'}
    >
      <Badge
        badgeContent={commentCount}
        sx={{
          '& > .MuiBadge-badge': {
            backgroundColor: hasUnreadComment
              ? theme.palette.customGreen.dark
              : theme.palette.customGrey.dark,
            color: 'white',
          },
        }}
      >
        <CommentIcon
          onClick={() => navigate(`/document-flow/${id}/comments`)}
          sx={{ cursor: 'pointer' }}
          color='action'
        />
      </Badge>
    </Grid>
  );
};
