import { Grid } from '@mui/material';
import { CompliteTrainingPage } from 'pages/training/CompliteTrainingPage';
import { ControlTrainingPage } from 'pages/training/ControlTrainingPage';
import { CurrentTrainingPage } from 'pages/training/CurrentTrainingPage';
import { MyTrainingPage } from 'pages/training/MyTrainingPage';
import { Outlet, RouteObject } from 'react-router-dom';
import { MAX_WIDTH } from 'shared/constant/size';
import { ALL_ROUTES } from 'shared/routes/constants/types';

export const ROUTES_TRAINING: RouteObject[] = [
  {
    path: ALL_ROUTES.training,
    element: (
      <Grid
        maxWidth={MAX_WIDTH}
        sx={{ margin: '0px auto', width: '100%' }}
        item
      >
        <Outlet />
      </Grid>
    ),
    children: [
      {
        path: '',
        element: <ControlTrainingPage />,
      },
      {
        path: ALL_ROUTES.currentTraining,
        element: <CurrentTrainingPage />,
      },
      {
        path: ALL_ROUTES.compliteTraining,
        element: <CompliteTrainingPage />,
      },
      {
        path: ALL_ROUTES.myTraining,
        element: <MyTrainingPage />,
      },
      {
        path: ALL_ROUTES.controlTraining,
        element: <ControlTrainingPage />,
      },
    ],
  },
];
