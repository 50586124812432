import {
  ResponsesTrainingEventEmployeeResponses,
  ResponsesTrainingProcessModel,
} from 'shared/api/traning/types';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { Tables } from 'shared/utils/controllers';

export const useTableCellsCurrentTreiningFromLocalStorage = () =>
  useTableCellsFromLocalStorage<ResponsesTrainingProcessModel>(
    Tables.TRAINING_CURRENT
  );

export const useTableCellsCurrentTreiningSubTableFromLocalStorage = () =>
  useTableCellsFromLocalStorage<ResponsesTrainingEventEmployeeResponses>(
    Tables.TRAINING_CURRENT_SUB_TABLE
  );
