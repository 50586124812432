import { Typography } from '@mui/material';
import { getRedirectTaskLink } from 'entities/task/libs/getTaskLink';
import { TaskStatusChip } from 'features/task/task-status-chip/TaskStatusChip';
import { TaskStatuses } from 'features/task/task-status-chip/type';

import { VisitingChecklistProblemTaskGetResponse } from 'shared/api/visit/types';
import { Link } from 'shared/ui/base-ui';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import { formatingDate } from 'shared/utils/formating-date';

export const TASK_PROBLEM_FIELD_MAP: FieldMapType<VisitingChecklistProblemTaskGetResponse>[] =
  [
    {
      field: 'id',
      title: 'ID задачи',
      renderComponent: (value) => <Typography>{value.id}</Typography>,
      order: 0,
      isShow: false,
      getTableCellProps: () => ({}),
    },
    {
      field: 'title',
      title: 'Название',
      isShow: true,
      order: 1,
      getTableCellProps: () => ({}),
      renderComponent: (task) => (
        <Link to={getRedirectTaskLink(task.id)}>{task.title}</Link>
      ),
    },
    {
      field: 'dateFinish',
      title: 'Дата завершения',
      renderComponent: (value) => (
        <Typography>
          {value.dateFinish ? formatingDate(value.dateFinish) : '-'}
        </Typography>
      ),
      order: 2,
      isShow: true,
      getTableCellProps: () => ({}),
    },
    {
      field: 'status',
      title: 'Статус',
      isShow: true,
      order: 3,
      getTableCellProps: () => ({}),
      renderComponent: (task) => (
        <TaskStatusChip status={task.status as TaskStatuses} />
      ),
    },
    {
      field: 'executor',
      title: 'Исполнитель',
      renderComponent: (value) => <Typography>{value.executor}</Typography>,
      order: 4,
      isShow: true,
      getTableCellProps: () => ({}),
    },
  ];
