import { Delete } from '@mui/icons-material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';

import { useChecklist } from 'entities/visit/checkList/context/ChecklistContext';
import { ProblemTaskActionsProps } from './type';

export const ProblemTaskActions: FC<ProblemTaskActionsProps> = ({
  task,
  onDeleteTask,
}) => {
  const { checkList } = useChecklist();

  return (
    <UiTableActions>
      <UiButtonAction
        text='Удалить'
        color={theme.palette.error.main}
        disable={!checkList?.canDelete || !!checkList.dateComplete}
        icon={<Delete />}
        onClick={() => onDeleteTask(task.id)}
      />
    </UiTableActions>
  );
};
