import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_API_URL } from 'shared/api/constants';

export const adaptationProviderApi = createApi({
  reducerPath: 'adaptationProviderApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL }),
  tagTypes: [
    'templates',
    'templates_list',
    'stages',
    'stage_task',
    'stage_task_list',
    'position',
    'adaptation_events',
    // TODO: ?
    'adaptation_process',
    'adaptation_process_list',
    'task',
    'filter',
    'employees',
    'banned_employee',
    'banned_employee_list',
  ],
  endpoints: (build) => ({}),
});

export const {} = adaptationProviderApi;
