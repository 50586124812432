import { transformResponseError } from 'shared/api/libs';
import { aptekaLegkoBannersApi } from '../aptekaLegoBannersApi';
import {
  BannersApiSliderListParams,
  BannersRequestsSlidersCreateSlider,
  BannersRequestsSlidersEditSlider,
  BannersResponsesSlidersSliderModel,
} from '../aptekaLegoBannersAutogenerateApi';
import { aptekaLegkoBannersProviderApi } from './aptekaLegkoBannersProviderApi';

export const aptekaLegkoBannersSliderProviderApi =
  aptekaLegkoBannersProviderApi.injectEndpoints({
    endpoints: (build) => ({
      /** Получает список всех слайдеров */
      getAptekaLegkoBannersSlider: build.query<
        BannersResponsesSlidersSliderModel[],
        { params: BannersApiSliderListParams }
      >({
        queryFn: async ({ params }) => {
          try {
            const response =
              await aptekaLegkoBannersApi.slider.bannersApiSliderList(params);

            return {
              data: response.data,
            };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        providesTags: (res, err) => (err ? [] : ['slider-list']),
      }),
      /* ======================================= */
      /** Получает полную информацию по слайдеру */
      getAptekaLegkoBannersSliderDetail: build.query<
        BannersResponsesSlidersSliderModel,
        { sliderGuid: string }
      >({
        queryFn: async ({ sliderGuid }) => {
          try {
            const response =
              await aptekaLegkoBannersApi.slider.bannersApiSliderDetail(
                sliderGuid
              );
            return { data: response.data };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        providesTags: ['slider-by-guid'],
      }),
      /* ======================================= */
      /** Удалить слайдер */
      deleteAptekaLegkoBannersSlider: build.mutation<
        void,
        { sliderGuid: string }
      >({
        queryFn: async ({ sliderGuid }) => {
          try {
            const response =
              await aptekaLegkoBannersApi.slider.bannersApiSliderDelete({
                SliderGuid: sliderGuid,
              });
            return { data: response.data };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        invalidatesTags: ['slider-by-guid', 'slider-list'],
      }),
      /* ======================================= */
      /**  Создание слайдеров */
      createAptekaLegkoBannersSlider: build.mutation<
        string,
        { slider: BannersRequestsSlidersCreateSlider }
      >({
        queryFn: async ({ slider }) => {
          try {
            const response =
              await aptekaLegkoBannersApi.slider.bannersApiSliderCreate(slider);
            return { data: response.data };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        invalidatesTags: ['slider-by-guid', 'slider-list'],
      }),
      /* ======================================= */
      /** Редактирование слайдеров */
      updateAptekaLegkoBannersSlider: build.mutation<
        void,
        { slider: BannersRequestsSlidersEditSlider }
      >({
        queryFn: async ({ slider }) => {
          try {
            const response =
              await aptekaLegkoBannersApi.slider.bannersApiSliderUpdate(slider);
            return { data: response.data };
          } catch (error) {
            return transformResponseError(error);
          }
        },
        invalidatesTags: ['slider-by-guid', 'slider-list'],
      }),
      /* ======================================= */
    }),
  });

export const {
  useGetAptekaLegkoBannersSliderQuery,
  useGetAptekaLegkoBannersSliderDetailQuery,
  useCreateAptekaLegkoBannersSliderMutation,
  useUpdateAptekaLegkoBannersSliderMutation,
  useDeleteAptekaLegkoBannersSliderMutation,
} = aptekaLegkoBannersSliderProviderApi;
