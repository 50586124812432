import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  ClickAwayListener,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { useGetColumnTasks } from 'entities/boards/hooks/useGetColumnTasks';
import { checkCanEditTask } from 'features/task/task-id-component/libs/checkCanEditTask';
import { useBoardPage } from 'pages/boards/context/BoardPageContext';
import { FC, useEffect, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useDeleteBoardColumnMutation } from 'shared/api/services/rtk/boardApi';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { useViewModal } from 'shared/hooks/useViewModal';
import { theme } from 'shared/theme/theme';
import { BoardColumnHeaderForBoardPage } from 'widgets/boards/BoardColumnHeaderForBoardPage';
import { BoardTaskCardForBoardPage } from 'widgets/boards/BoardTaskCardForBoardPage/BoardTaskCardForBoardPage';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import {
  BoardTaskFormCard,
  BoardTaskFormCardFields,
} from '../BoardTaskFormCard';
import { prepareCreateTaskCardFormToFetch } from '../BoardTaskFormCard/libs/prepareCreateTaskCardFormToFetch';
import { BoardColumnProps } from './type';

export const BoardColumn: FC<BoardColumnProps> = ({ column }) => {
  const { boardGuid, createTaskHandler, isCreatingTask, taskDrawer, board } =
    useBoardPage();
  const { tasks, isFetching, isLoading } = useGetColumnTasks(
    boardGuid,
    column.guid
  );

  /* Column delete  */
  const openConfirm = useConfirm();
  const [deleteColumn] = useDeleteBoardColumnMutation();
  /* ===== */

  const { close, isOpen, open } = useViewModal();

  const handleSubmit = async (form: BoardTaskFormCardFields) => {
    const fetchData = prepareCreateTaskCardFormToFetch(form, column.guid);

    if (!fetchData) return;

    await createTaskHandler(fetchData);
  };

  useEffect(() => {
    if (!isFetching) close();
  }, [isFetching]);

  const executers = useMemo(() => {
    if (!board) return EMPT_ARR;

    const newExecuters = [];

    newExecuters.push(board.creator);

    if (board.employees) {
      newExecuters.push(...board.employees);
    }

    return newExecuters;
  }, [board]);

  const boardColumnHeaderActions = useMemo(() => {
    return [
      {
        icon: <Delete />,
        title: 'Удалить',
        disabled: !board?.canEdit,
        onClick: () => {
          openConfirm({
            actionTitle: 'Вы точно хотите удалить столбец?',
            submitButtonTitle: 'Удалить',
            buttonColor: 'error',
            actionBody: (
              <Typography color={'GrayText'}>
                Все задачи столбца будут{' '}
                <u>
                  <b>безвозвратно</b>
                </u>{' '}
                удалены
              </Typography>
            ),
            onSubmit: async () => {
              deleteColumn({
                boardGuid,
                boardColumnGuid: column.guid,
              });
            },
          });
        },
      },
    ];
  }, [board?.canEdit, boardGuid, column.guid]);

  return (
    <>
      <Draggable
        isDragDisabled={!board?.canEdit}
        draggableId={column.guid}
        index={column.boardOrderNumber}
      >
        {(provided) => (
          <Grid
            container
            flexDirection={'column'}
            flexWrap={'nowrap'}
            gap={1}
            width={300}
            mx={1}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <BoardColumnHeaderForBoardPage
              canEdit={board?.canEdit}
              column={column}
              cardProps={{ ...provided.dragHandleProps }}
              actions={boardColumnHeaderActions}
            />

            <Button
              sx={{ width: 'fit-content' }}
              startIcon={<Add />}
              onClick={open}
            >
              Создать задачу
            </Button>

            <Droppable
              droppableId={column.guid}
              direction='vertical'
              type='TASK'
            >
              {(provided) => (
                <Grid
                  flex={1}
                  overflow={'auto'}
                  sx={{ ...theme.mixins.customScrollBar('light') }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {/* =============== */}
                  {isOpen && (
                    <ClickAwayListener onClickAway={close}>
                      <Grid>
                        <BoardTaskFormCard
                          executers={executers}
                          onSubmit={handleSubmit}
                          actions={
                            <>
                              <LoadingButton
                                loading={isCreatingTask || isFetching}
                                type='submit'
                              >
                                Создать
                              </LoadingButton>
                              <Button
                                disabled={isCreatingTask || isFetching}
                                onClick={close}
                              >
                                Закрыть
                              </Button>
                            </>
                          }
                        />
                      </Grid>
                    </ClickAwayListener>
                  )}
                  {/* =============== */}

                  {/* =============== */}
                  {isLoading && (
                    <Skeleton
                      height={150}
                      variant='rounded'
                    />
                  )}
                  {/* =============== */}

                  {/* =============== */}
                  {tasks.map((task, index) => (
                    <Draggable
                      isDragDisabled={!checkCanEditTask(task.accessActions)}
                      key={task.taskGuid}
                      draggableId={task.taskGuid}
                      index={index}
                    >
                      {(provided) => (
                        <BoardTaskCardForBoardPage
                          task={task}
                          onClick={taskDrawer.open}
                          boxProps={{
                            ref: provided.innerRef,
                            ...provided.draggableProps,
                            ...provided.dragHandleProps,
                          }}
                        />
                      )}
                    </Draggable>
                  ))}
                  {/* =============== */}

                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </Grid>
        )}
      </Draggable>
    </>
  );
};
