import {
  InformationAboutCompetitorsForm,
  InformationAboutCompetitorsFormI,
  InformationAboutCompetitorsFormSkeleton,
  prepareDataToFetchStage2,
  prepareDataToInitStage2,
} from 'features/visit/Stage 2';
import { useMemo } from 'react';

import { postChecklistsVisitingChecklistIdStages1 } from 'shared/api/visit/services';

import { useChecklist } from 'entities/visit/checkList/context/ChecklistContext';

import { STAGES } from './VisitLayout';

export const Stage2Page = () => {
  const { checkList, getCheckList, isLoading, stageNavigate, status } =
    useChecklist();

  const onSubmit = async (data: InformationAboutCompetitorsFormI) => {
    if (checkList) {
      if (!checkList.canEdit) {
        if (checkList?.isStageOneComplete) stageNavigate(STAGES.stage3);
        else stageNavigate(STAGES.stage4);

        return;
      }
      try {
        const fetchData = prepareDataToFetchStage2(data);

        await postChecklistsVisitingChecklistIdStages1(checkList.id, fetchData);

        const newCheckList = await getCheckList();

        if (newCheckList?.isStageOneComplete) stageNavigate(STAGES.stage3);
        else stageNavigate(STAGES.stage4);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const initVal = useMemo(() => {
    if (checkList) return prepareDataToInitStage2(checkList.cometitors);
    else return undefined;
  }, [checkList]);

  if (isLoading && !checkList)
    return <InformationAboutCompetitorsFormSkeleton />;
  if (!checkList || status === 400) return <h1>Такого чеклиста нет</h1>;

  return (
    <InformationAboutCompetitorsForm
      onDraftSubmit={onSubmit}
      onSubmit={onSubmit}
      retailId={checkList.retailId}
      initValue={initVal}
      disableAll={!checkList.canEdit}
    />
  );
};
