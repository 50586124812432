import { AddEmployeeToBoardDialog } from 'features/boards/dialogs/AddEmployeeToBoardDialog';
import { AddEmployeeToBoardFormFields } from 'features/boards/forms/AddEmployeeToBoardForm';
import { FC } from 'react';
import { useUpdateEmployeesOnBoardMutation } from 'shared/api/services/rtk/boardApi';
import { AddEmployeeToBoardModalProps } from './type';

export const AddEmployeeToBoardModal: FC<AddEmployeeToBoardModalProps> = ({
  isOpen,
  onClose,
  initValue,
  boardGuid,
  onUpdate,
  creator,
}) => {
  const [updateEmployees, { isLoading }] = useUpdateEmployeesOnBoardMutation();

  const handleSubmit = (form: AddEmployeeToBoardFormFields) => {
    updateEmployees({
      boardGuid,
      employees: form.employees,
    }).then(() => {
      onUpdate?.();
    });
  };

  return (
    <AddEmployeeToBoardDialog
      creator={creator}
      isOpen={isOpen}
      onClose={onClose}
      initValue={initValue}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
};
