import { Badge, Button } from '@mui/material';
import { forwardRef, useState } from 'react';
import { theme } from 'shared/theme/theme';
import { ButtonWithBadgeProps } from './type';

export const ButtonWithBadge = forwardRef<
  HTMLButtonElement,
  ButtonWithBadgeProps
>(
  ({
    children,
    badgeProps,
    buttonProps,
    badgeIcon,
    onClick,
    disable = false,
  }) => {
    const [isShowBadge, setIsShowBadge] = useState(false);

    return (
      <Badge
        invisible={!isShowBadge}
        badgeContent={badgeIcon}
        slotProps={{
          badge: {
            style: { backgroundColor: `${theme.palette.primary.main}40` },
          },
        }}
        {...badgeProps}
        // componentsProps={{
        //   badge: {
        //     ref: ref,
        //   },
        // }}
      >
        <Button
          disabled={disable}
          onPointerEnter={() => {
            setIsShowBadge(true);
          }}
          onPointerLeave={() => {
            setIsShowBadge(false);
          }}
          onClick={onClick}
          {...buttonProps}
          sx={{
            fontWeight: 'bold',
            fontSize: 22,
            '&.Mui-disabled': {
              color: theme.palette.primary.main,
            },
            ...buttonProps?.sx,
          }}
        >
          {children}
        </Button>
      </Badge>
    );
  }
);
