// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { STAGES } from 'features/visit/Stager/constants';
import { createContext, FC, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { VisitingChecklistGetResponse } from 'shared/api/visit/types';

import { useGetCheckList } from '../hooks/useGetCheckList';
import { useStageNavigator } from '../hooks/useStageNavigator';

interface ChecklistContextProps {
  isLoading: boolean;

  checkList: VisitingChecklistGetResponse | null;
  getCheckList: () => Promise<VisitingChecklistGetResponse | null>;

  navigateToStart: (isCompetitorComparison: boolean, id: number) => void;
  stageNavigate: (stage: STAGES) => void;

  status: number | null;
}

const CHECKLIST_CONTEXT_INIT: ChecklistContextProps = {
  isLoading: false,

  checkList: null,
  getCheckList: async () => null,

  navigateToStart: () => {},
  stageNavigate: () => {},

  status: null,
};

const ChecklistContext = createContext<ChecklistContextProps>(
  CHECKLIST_CONTEXT_INIT
);

export const useChecklist = () => {
  const context = useContext(ChecklistContext);

  // ??? что это такое
  // useEffect(() => {
  //   if (!context.checkList && id) {
  //     context.getCheckList();
  //   }
  // }, [id]);

  return context;
};

interface ChecklistProviderProps {
  children: ReactNode;
}
export const ChecklistProvider: FC<ChecklistProviderProps> = ({ children }) => {
  const { id } = useParams();

  const { checkList, getCheckList, isLoading, status } = useGetCheckList(
    id ? Number(id) : undefined
  );
  const { navigateToStart, stageNavigate } = useStageNavigator();

  return (
    <ChecklistContext.Provider
      value={{
        isLoading,
        checkList,
        getCheckList,
        navigateToStart,
        stageNavigate,
        status,
      }}
    >
      {children}
    </ChecklistContext.Provider>
  );
};
