import { AdaptationManagerRequestsStageTaskUpdate } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { getGuidsFromArray } from 'shared/tarnsform-helper/getGuidsFromArray';
import { AdaptationTemplateTaskFormFields } from '../type';
import { getReminderPeriod } from './getReminderPeriod';

export const transformFromToAdaptationTemplateStageTaskUpdateFetch = (
  form: AdaptationTemplateTaskFormFields
): AdaptationManagerRequestsStageTaskUpdate => {
  return {
    title: form.title,
    coExecutorsPositionsGuids: getGuidsFromArray(form.coExecutorsPositions),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    executorPositionGuid: form.executorPosition!.guid,
    finishDaysCount: form.finishDaysCount as number,
    isReminderCoExecutor: form.isReminderCoExecutor,
    isReminderCreator: form.isReminderCreator,
    isReminderExecutor: form.isReminderExecutor,
    isReminderObserver: form.isReminderObserver,
    observersPositionsGuids: getGuidsFromArray(form.observersPositions),
    startDaysCount: form.startDaysCount as number,
    creatorPositionGuid: form.creatorPosition?.guid,
    description: form.description,
    reminderPeriodCoExecutor: getReminderPeriod(form.reminderPeriodCoExecutor),
    reminderPeriodCreator: getReminderPeriod(form.reminderPeriodCreator),
    reminderPeriodExecutor: getReminderPeriod(form.reminderPeriodExecutor),
    reminderPeriodObserver: getReminderPeriod(form.reminderPeriodObserver),
    addingFiles: form.addingFiles,
    deletingFiles: form.deletingFiles?.map((el) => el.guid),
  };
};
