import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';
import { AptekaLegkoSliderFilterFormValue } from '../types';
import { useUrlStateAptekaLegkoBannerSlider } from './useUrlStateAptekaLegkoBannerSlider';

export const useAptekaLegkoFilterModalWithUrlState = () => {
  const { filter, setFilter } = useUrlStateAptekaLegkoBannerSlider();
  const { open, isOpen, close } = useViewModal();

  const initialValue = useMemo(() => {
    return {
      dateStart: (filter?.DateStart && dayjs(filter.DateStart)) || null,
      dateEnd: (filter?.DateEnd && dayjs(filter.DateEnd)) || null,
    };
  }, [filter]);

  const handleSubmit = useCallback(
    (form: AptekaLegkoSliderFilterFormValue) => {
      setFilter({
        DateEnd: form.dateEnd ? form.dateEnd?.format() : undefined,
        DateStart: form.dateStart ? form.dateStart?.format() : undefined,
      });
      close();
    },
    [close, setFilter]
  );

  return {
    onOpenDialogFilterBannersSlider: open,
    isOpenDialogFilterBannersSlider: isOpen,
    onCloseDialogFilterBannersSlider: close,
    initialValueDialogFilterBannersSlider: initialValue,
    handleSubmitFormFilterBanersSlider: handleSubmit,
  };
};
