import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { RetailSelectDialog } from 'features/visit/RetailSelectDialog';
import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { hasNotDateInRange } from 'shared/date-helper/hasDateInRange';
import { useViewModal } from 'shared/hooks/useViewModal';
import { getEmployeTitle } from 'shared/option-helper/getEmployeTitle';
import {
  CustomAutocomplete,
  CustomDatePicker,
  CustomTextField,
} from 'shared/ui/base-ui';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { FormLayout } from 'shared/ui/form';

import { useGetCheckList } from 'entities/visit/options/useGetCheckList';

import {
  CreateVisitByPlanningRNFormFields,
  CreateVisitByPlanningRNFormProps,
} from './type';

export const CreateVisitByPlanningRNForm: FC<
  CreateVisitByPlanningRNFormProps
> = ({ actions, onSubmit, planningInfo }) => {
  const { close, isOpen, open } = useViewModal();

  const { checkLists, isLoading: isLoadingChecklists } = useGetCheckList();

  const { control, handleSubmit } = useForm<CreateVisitByPlanningRNFormFields>({
    defaultValues: {
      homies: planningInfo?.members,
      planningId: planningInfo?.id,
    },
  });

  const shouldDisableDate = useCallback(
    (date: Dayjs) => {
      return hasNotDateInRange(
        date,
        planningInfo?.periodStart,
        planningInfo?.periodFinish
      );
    },
    [planningInfo]
  );

  return (
    <FormLayout
      actions={actions(handleSubmit)}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      container
      flexDirection={'column'}
      gap={2}
    >
      <CustomBackdrop isLoading={isLoadingChecklists} />

      <CustomTextField
        label='Посетитель'
        value={planningInfo?.visitor.employeeName}
        InputProps={{ readOnly: true }}
      />

      <Controller
        control={control}
        name='homies'
        render={({ field: { value } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Участники',
              placeholder: value.length === 0 ? 'Нет участников' : '',
            }}
            options={[]}
            multiple
            sx={{ '.MuiAutocomplete-popupIndicator': { display: 'none' } }}
            value={value || []}
            getOptionLabel={getEmployeTitle}
            readOnly
          />
        )}
      />

      <Controller
        control={control}
        name='date'
        render={({ field: { onChange, value } }) => (
          <CustomDatePicker
            label='Дата посещения'
            slotProps={{ textField: { required: true } }}
            value={value || null}
            referenceDate={dayjs(planningInfo?.periodStart)}
            shouldDisableDate={shouldDisableDate}
            onChange={onChange}
          />
        )}
      />

      <Controller
        control={control}
        name='checkList'
        render={({ field: { onChange, value } }) => (
          <CustomAutocomplete
            textFieldProps={{
              label: 'Чек-лист',
              placeholder: 'Выберите чек-лист',
              required: true,
            }}
            options={checkLists}
            value={value || null}
            getOptionLabel={(val) => val.title || ''}
            isOptionEqualToValue={(op, eq) => op.id === eq.id}
            onChange={(_, val) => onChange(val)}
          />
        )}
      />

      <Controller
        name='retails'
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          return (
            <Grid
              container
              gap={2}
            >
              {!value ? (
                <Button
                  onClick={open}
                  variant='contained'
                  startIcon={<Add />}
                >
                  Выбрать аптеку
                </Button>
              ) : (
                <>
                  <CustomTextField
                    label={'Аптека'}
                    InputProps={{ readOnly: true }}
                    value={value.title}
                    containerSx={{ flex: 1 }}
                  />
                  <Button
                    onClick={open}
                    variant='outlined'
                  >
                    Изменить
                  </Button>
                </>
              )}

              <RetailSelectDialog
                isOpen={isOpen}
                onClose={close}
                multiple={undefined}
                value={value || null}
                onChange={(val) => {
                  if (val !== null) close();
                  onChange(val);
                }}
              />
            </Grid>
          );
        }}
      />
    </FormLayout>
  );
};
