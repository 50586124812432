import { Divider, Grid, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';

import { CustomTextField } from 'shared/ui/custom-text-field';

import { RatingItem } from '../RatingItem/RatingItem';
import { RatingBlockProps } from './type';

export const RatingBlock: FC<RatingBlockProps> = ({
  title,
  cometitors,
  canAddComent,
  onChange,
  value,
  invalid = false,
  disabled,
}) => {
  const myChange = (newValue: number | null) => {
    onChange({ ...value, myValue: newValue });
  };

  const competitrChange = (compId: number, newValue: number | null) => {
    const comp = [...value.competitorsValue].map((el) =>
      el.competitorId === compId ? { ...el, value: newValue } : el
    );

    onChange({ ...value, competitorsValue: comp });
  };

  const comentChange = (str: string) => {
    onChange({ ...value, comment: str });
  };

  return (
    <Paper
      elevation={2}
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        width: '100%',
      }}
    >
      <Typography fontWeight={700}>{title}</Typography>
      <Grid
        container
        flexDirection={['column', 'row']}
        gap={[1, 0]}
        // width={'100%'}
      >
        <RatingItem
          title={'Моя аптека'}
          onChange={myChange}
          value={value.myValue || null}
          invalid={invalid}
          isBold
          disabled={disabled}
        />

        <Divider />

        {cometitors.map((el) => (
          <React.Fragment key={el.competitorId}>
            <RatingItem
              disabled={disabled}
              title={el.competitorTitle || 'null'}
              invalid={invalid}
              onChange={(val) => {
                competitrChange(el.competitorId, val);
              }}
              value={
                value.competitorsValue.find(
                  (el2) => el2.competitorId === el.competitorId
                )?.value || null
              }
            />
            {/* {i !== cometitors.length - 1 && <Divider />} */}
            <Divider />
          </React.Fragment>
        ))}
      </Grid>

      {canAddComent && (
        <CustomTextField
          label='Коментарий'
          propsTextField={{
            disabled: disabled,
            onChange: (e) => {
              comentChange(e.target.value);
            },
            value: value.comment || '',
            placeholder: 'Введите коментарий',
            multiline: true,
            maxRows: 4,
          }}
        />
      )}
    </Paper>
  );
};
