import { FC, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useGetAdaptationProcessesFilterQuery } from 'shared/api/service-adaptation/rtk/adaptationProcessesProviderApi';
import { useGetAdaptationTemplatesQuery } from 'shared/api/service-adaptation/rtk/adaptationTemplatesProviderApi';
import { AutocompliteWithController } from 'shared/fields-with-contoller';
import { BaseFormProps } from 'shared/form/type';
import { FormLayout } from 'shared/ui/form';
import {
  createIsOptionEqualToValueByKey,
  createRenderOptionByKey,
} from 'shared/utils/autocomplite-helpers';

import { useDependentFormFields } from 'shared/form/hooks/use-dependent-form-fields';
import { getTitleFromObject } from 'shared/utils/getTitleFromObject';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { INIT_VALUE } from './constants';
import { AdaptationProcessesFilterFormFields } from './type';

const isOptionEqualToValueByGuid = createIsOptionEqualToValueByKey('guid');
const renderOptionsWithGuidAndTitle = createRenderOptionByKey({
  keyGuid: 'guid',
  keyLabel: 'title',
});

export const AdaptationProcessesFilterForm: FC<
  BaseFormProps<AdaptationProcessesFilterFormFields>
> = ({ onSubmit, renderFormActions, initValue }) => {
  /* Form options */
  const { data: adaptations, isLoading: isLoadingAdaptations } =
    useGetAdaptationTemplatesQuery();
  const { data: filter, isLoading: isLoadingFilter } =
    useGetAdaptationProcessesFilterQuery();

  /* ======== */

  const { control, handleSubmit, setValue, reset } =
    useForm<AdaptationProcessesFilterFormFields>({
      defaultValues: { ...INIT_VALUE, ...initValue },
    });

  useEffect(() => {
    if (initValue) reset({ ...INIT_VALUE, ...initValue });
  }, [initValue, reset]);

  const selectedRegions = useWatch({ control, name: 'regions' });
  const selectedDepartments = useWatch({ control, name: 'departments' });
  const selectedRetails = useWatch({ control, name: 'retails' });
  const selectedEmployees = useWatch({ control, name: 'employees' });

  const { createHandlerDependentChange, filterState } = useDependentFormFields({
    defaultFilter: filter,
    keysForm: ['regions', 'departments', 'retails', 'employees'],
    selectedValues: {
      regions: selectedRegions,
      retails: selectedRetails,
      departments: selectedDepartments,
      employees: selectedEmployees,
    },
    setValue: setValue,
  });

  return (
    <FormLayout
      actions={renderFormActions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <AutocompliteWithController
        control={control}
        name='regions'
        options={filterState?.regions || EMPT_ARR}
        textFieldProps={{
          label: 'Регионы',
          placeholder: 'Выберите регионы',
        }}
        multiple
        onChange={createHandlerDependentChange('regions')}
        getOptionLabel={getTitleFromObject}
        loading={isLoadingFilter}
        isOptionEqualToValue={isOptionEqualToValueByGuid}
        loadingText='Загрузка...'
      />

      <AutocompliteWithController
        control={control}
        name='departments'
        options={filterState?.departments || EMPT_ARR}
        isOptionEqualToValue={isOptionEqualToValueByGuid}
        textFieldProps={{
          label: 'Сети',
          placeholder: 'Выберите сети',
        }}
        multiple
        onChange={createHandlerDependentChange('departments')}
        getOptionLabel={getTitleFromObject}
        renderOption={renderOptionsWithGuidAndTitle}
        loading={isLoadingFilter}
        loadingText='Загрузка...'
      />

      <AutocompliteWithController
        control={control}
        name='retails'
        options={filterState?.retails || EMPT_ARR}
        isOptionEqualToValue={isOptionEqualToValueByGuid}
        textFieldProps={{
          label: 'Аптеки',
          placeholder: 'Выберите аптеки',
        }}
        multiple
        onChange={createHandlerDependentChange('retails')}
        getOptionLabel={getTitleFromObject}
        renderOption={renderOptionsWithGuidAndTitle}
        loading={isLoadingFilter}
        loadingText='Загрузка...'
      />

      <AutocompliteWithController
        control={control}
        name='employees'
        options={filterState?.employees || EMPT_ARR}
        textFieldProps={{
          label: 'Сотрудники',
          placeholder: 'Выберите сотрудников',
        }}
        multiple
        onChange={createHandlerDependentChange('employees')}
        getOptionLabel={getTitleFromObject}
        loading={isLoadingFilter}
        loadingText='Загрузка...'
        isOptionEqualToValue={isOptionEqualToValueByGuid}
        renderOption={renderOptionsWithGuidAndTitle}
      />

      <AutocompliteWithController
        control={control}
        name='adaptations'
        options={adaptations || []}
        textFieldProps={{
          label: 'Шаблоны адаптаций',
          placeholder: 'Выберите шаблоны адаптаций',
        }}
        multiple
        getOptionLabel={getTitleFromObject}
        isOptionEqualToValue={isOptionEqualToValueByGuid}
        loading={isLoadingAdaptations}
        loadingText='Загрузка...'
        renderOption={renderOptionsWithGuidAndTitle}
      />
    </FormLayout>
  );
};
