import { Typography } from '@mui/material';

export const renderOptionByTitleAndId = <
  T extends { title: string | undefined; id: number },
>(
  params: React.HTMLAttributes<HTMLLIElement>,
  { id, title }: T
) => {
  return (
    <Typography
      {...params}
      key={id}
    >
      {title}
    </Typography>
  );
};
