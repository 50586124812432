import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { FC, useLayoutEffect } from 'react';
import { useFieldArray, useForm, useFormState } from 'react-hook-form';

import { useGetDocumentDelegateActionsQuery } from 'shared/api/services-document-flow/rtk/documentFlowActionsApi';

import { useGetDocumentFlowEmployeeAllQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';
import { AutocompliteWithController } from 'shared/fields-with-contoller';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import {
  createIsOptionEqualToValueByKey,
  createLabelOnKey,
  createRenderOptionByKey,
} from 'shared/utils/autocomplite-helpers';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { DEFAULT_VALUE } from './constants';
import {
  DialogFormDocumentDelegateActionProps,
  FormDocumentDelegateActionValue,
} from './types';
import { VALIDATE_SCHEME } from './validate';

export const DialogFormDocumentDelegateAction: FC<
  DialogFormDocumentDelegateActionProps
> = ({
  initialValue = DEFAULT_VALUE,
  isOpen,
  isLoading,
  onSubmitForm,
  documentId,
  onClose,
}) => {
  const { control, handleSubmit, reset } =
    useForm<FormDocumentDelegateActionValue>({
      defaultValues: initialValue,
      resolver: yupResolver(VALIDATE_SCHEME),
    });

  useLayoutEffect(() => {
    isOpen && reset(initialValue);
    !isOpen && reset(DEFAULT_VALUE);
  }, [initialValue, isOpen]);

  const { errors } = useFormState({ control, name: 'delegateActions' });

  const {
    fields: fieldsDelegateActions,
    append: appendFieldsDelegateAction,
    remove: removeFieldDelegateAction,
  } = useFieldArray({
    control,
    name: 'delegateActions',
  });

  /* ================ OPTIONS ============== */

  const { data: optionsEmployee, isLoading: isLoadingOptionsEmployee } =
    useGetDocumentFlowEmployeeAllQuery(undefined, {
      skip: !isOpen,
      selectFromResult: ({ data, ...other }) => {
        return {
          ...other,
          data: data?.map((item) => {
            let viewLabel = item.employeeName;

            if (item.employeePosition) {
              viewLabel = `${viewLabel} ( ${item.employeePosition} )`;
            }

            if (item.isVacation) {
              viewLabel = `${viewLabel} (В ОТПУСКЕ)`;
            }

            return {
              ...item,
              viewLabel,
            };
          }),
        };
      },
    });

  const {
    data: optionsDelegateAllowedActions,
    isLoading: isLoadingDelegateAllowedActions,
  } = useGetDocumentDelegateActionsQuery(
    { documentId: documentId },
    { skip: !isOpen }
  );
  /*  ======================================= */

  const handleAddedFieldAction = () =>
    appendFieldsDelegateAction({ employee: null, action: null });

  const isAnyLoading =
    isLoading || isLoadingDelegateAllowedActions || isLoadingOptionsEmployee;

  const disabledAddedFieldActionButton =
    isLoadingDelegateAllowedActions ||
    (optionsDelegateAllowedActions?.length || 0) <=
      fieldsDelegateActions.length;

  return (
    <DialogForForm
      open={isOpen}
      component={'form'}
      onSubmit={handleSubmit(onSubmitForm)}
      head={<DialogTitleForForm title='Делегировать' />}
    >
      <Stack
        gap={3}
        divider={<Divider />}
      >
        {fieldsDelegateActions.map((field, index) => {
          return (
            <Grid
              key={field.id}
              display={'flex'}
              flexDirection={'column'}
              gap={2}
            >
              {fieldsDelegateActions.length > 1 && (
                <Grid
                  display={'flex'}
                  justifyContent={'flex-end'}
                >
                  <Button
                    size='small'
                    onClick={() => removeFieldDelegateAction(index)}
                  >
                    Удалить
                  </Button>
                </Grid>
              )}
              <AutocompliteWithController
                disabled={isAnyLoading}
                loading={isAnyLoading}
                textFieldProps={{
                  label: 'Действие',
                }}
                options={optionsDelegateAllowedActions || EMPT_ARR}
                isOptionEqualToValue={createIsOptionEqualToValueByKey('id')}
                getOptionLabel={createLabelOnKey('title')}
                renderOption={createRenderOptionByKey({
                  keyGuid: 'id',
                  keyLabel: 'title',
                })}
                getOptionDisabled={(action) =>
                  fieldsDelegateActions.some(
                    (selectedAction) => selectedAction.action?.id === action.id
                  )
                }
                control={control}
                name={`delegateActions.${index}.action`}
              />
              <AutocompliteWithController
                disabled={isAnyLoading}
                loading={isAnyLoading}
                textFieldProps={{
                  label: 'Сотрудник',
                }}
                options={optionsEmployee || EMPT_ARR}
                isOptionEqualToValue={createIsOptionEqualToValueByKey('id')}
                getOptionLabel={createLabelOnKey('viewLabel')}
                renderOption={createRenderOptionByKey({
                  keyGuid: 'id',
                  keyLabel: 'viewLabel',
                })}
                control={control}
                name={`delegateActions.${index}.employee`}
              />
            </Grid>
          );
        })}
      </Stack>
      {errors?.delegateActions?.root?.message && (
        <Typography color={'error'}>
          {errors?.delegateActions?.root?.message}
        </Typography>
      )}
      <Button
        disabled={disabledAddedFieldActionButton}
        onClick={handleAddedFieldAction}
        fullWidth
      >
        Добавить
      </Button>
      <Grid height={[81, 0]} />
      <ContainerActionsForm>
        <LoadingButton
          loading={isAnyLoading}
          type='submit'
          variant={'contained'}
        >
          Делегировать
        </LoadingButton>
        <Button
          disabled={isAnyLoading}
          onClick={onClose}
          variant='contained'
          color='customGrey'
        >
          Закрыть
        </Button>
      </ContainerActionsForm>
    </DialogForForm>
  );
};
