import { Grid, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { LeftLabelWithChildren } from 'shared/ui/left-label-with-children';
import { TemplateInformationViewProps } from './type';

export const TemplateInformationView: FC<TemplateInformationViewProps> = ({
  template,
  isLoading,
}) => {
  return (
    <Paper
      component={Grid}
      container
      flexDirection={'column'}
      gap={1}
      elevation={2}
      sx={{ p: 1 }}
    >
      <Typography
        fontSize={20}
        fontWeight={'bold'}
      >
        Основная информация
      </Typography>
      <LeftLabelWithChildren
        label='Наименование'
        isLoading={isLoading}
      >
        {template?.title}
      </LeftLabelWithChildren>
      <LeftLabelWithChildren
        label='Создал'
        isLoading={isLoading}
      >
        {template?.createdBy}
      </LeftLabelWithChildren>
      <LeftLabelWithChildren
        label='Дата создания'
        isLoading={isLoading}
      >
        {dayjs(template?.createdAt).format(DATE_FORMATS.clientWithTime)}
      </LeftLabelWithChildren>
      <LeftLabelWithChildren
        label='Автозапуск'
        isLoading={isLoading}
      >
        {template?.isAutorun ? 'Да' : 'Нет'}
      </LeftLabelWithChildren>
    </Paper>
  );
};
