import { InstagramSvg } from 'shared/svg/instagramSvg';
import { OkSvg } from 'shared/svg/okSvg';
import { TelergamSvg } from 'shared/svg/telegramSvg';
import { VkSvg } from 'shared/svg/vkSvg';
import { YouTubeSvg } from 'shared/svg/youtubeSvg';

export const SOCIAL_LINKS = [
  {
    title: 'Аптечная сеть | Гармония здоровья',
    description: 'Группа аптечной сети Вконтакте',
    href: '#',
    background:
      'linear-gradient(to right, white, white), linear-gradient(to bottom, #1B75BB, #1B75BB)',
    componentSvg: <VkSvg />,
  },
  {
    title: 'Интернет-аптека Легко',
    description: 'Интернет-аптека Вконтакте',
    href: '#',
    background:
      'linear-gradient(to right, white, white), linear-gradient(to bottom, #1B75BB, #1B75BB)',
    componentSvg: <VkSvg />,
  },
  {
    title: 'Аптечная сеть | Гармония здоровья',
    description: 'Страница аптечной сети в instagram',
    href: '#',
    background:
      'linear-gradient(to right, white, white), linear-gradient(to bottom, #6A479B , #C11C76, #C84C4E,#E09B3C)',
    componentSvg: <InstagramSvg />,
  },
  {
    title: 'Интернет-аптека Легко',
    description: 'Интернет-аптека в instagramе',
    href: '#',
    background:
      'linear-gradient(to right, white, white), linear-gradient(to bottom, #6A479B , #C11C76, #C84C4E,#E09B3C)',
    componentSvg: <InstagramSvg />,
  },
  {
    title: 'Жизнь в Гармонии здоровья',
    description: 'Telegram-канал о жизни компании',
    href: 'https://t.me/+e83qTMKpShpmOWMy',
    background:
      'linear-gradient(to right, white, white), linear-gradient(to bottom, #45ADE2 , #45ADE2)',
    componentSvg: <TelergamSvg />,
  },
  {
    title: 'Обучение АС Гармония здоровья',
    description: 'Telegram-канал об обучении в компании',
    href: '#',
    background:
      'linear-gradient(to right, white, white), linear-gradient(to bottom, #45ADE2 , #45ADE2)',
    componentSvg: <TelergamSvg />,
  },
  {
    title: 'Интернет-аптека Легко',
    description: 'Интернет-аптека в Telegram ',
    href: '#',
    background:
      'linear-gradient(to right, white, white), linear-gradient(to bottom, #45ADE2 , #45ADE2)',
    componentSvg: <TelergamSvg />,
  },
  {
    title: 'Интернет-аптека Легко',
    description: 'Интернет-аптека в Одноклассниках',
    href: '#',
    background:
      'linear-gradient(to right, white, white), linear-gradient(to bottom, #F6921E, #F6921E)',
    componentSvg: <OkSvg />,
  },
  {
    title: 'Аптечная сеть | Гармония здоровья',
    description: 'YouTube канал аптечной сети',
    href: '#',
    background:
      'linear-gradient(to right, white, white), linear-gradient(to bottom, #ED2D2C, #ED2D2C)',
    componentSvg: <YouTubeSvg />,
  },
];
