// TODO: Решить проблему с Upper case
/* eslint-disable budapestian/global-constant-pattern */

import { createEvent, createStore, sample } from 'effector';

import { EmployeeModel } from 'shared/api/services-document-flow/types';

import { getEmployeesOfficeAllFx } from './api';

const init = createEvent();
const options = createStore<EmployeeModel[] | null>(null);

options.on(getEmployeesOfficeAllFx.doneData, (_, response) => response.data);
sample({ clock: init, target: getEmployeesOfficeAllFx });

export const optionsEmployeesOfficeAll = {
  options: options,
  init,
};
