import { useURLBoard } from 'pages/boards/hooks/useURLBoard';
import { FC, useCallback, useState } from 'react';
import { DragStart, DropResult } from 'react-beautiful-dnd';
import {
  useChangeColumnOrderMutation,
  useGetColumnsTasksQuery,
  useUpdateTaskPositionInSameBoardMutation,
} from 'shared/api/services/rtk/boardApi';
import {
  findFromColumnGuid,
  findTaskIndexInColumn,
  updateTasksListForBoardOnChangeTasksOrder,
} from 'shared/api/services/rtk/libs/updateTasksListForBoardOnChangeTasksOrder';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { BoardDragDropContext } from './BoardDragDropContext';
import { BoardDragDropProviderProps } from './type';

export const BoardDragDropProvider: FC<BoardDragDropProviderProps> = ({
  children,
  useBoardPage,
}) => {
  const [changeTaskOrder] = useUpdateTaskPositionInSameBoardMutation();
  const [filter] = useURLBoard();
  const [changeColumnOrder] = useChangeColumnOrderMutation();
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [dragTask, setDragTask] = useState<string | null>(null);
  const { boardGuid } = useBoardPage();
  const { data: tasks } = useGetColumnsTasksQuery({
    boardGuid,
    qParams: filter,
  });

  const selectTask = useCallback(
    (taskGuid: string) => {
      if (selectedTasks.some((el) => el === taskGuid)) unSelectTask(taskGuid);
      else setSelectedTasks((prev) => [...prev, taskGuid]);
    },
    [selectedTasks]
  );

  const unSelectAll = useCallback(() => {
    setSelectedTasks((prev) => {
      if (prev.length === 0) return prev;

      return EMPT_ARR;
    });
  }, [selectedTasks]);

  const unSelectTask = useCallback(
    (taskGuid: string) => {
      setSelectedTasks((prev) => prev.filter((task) => task !== taskGuid));
    },
    [selectedTasks]
  );

  const dropHandler = useCallback(
    (result: DropResult) => {
      if (result.type === 'COLUMN') {
        if (!result.destination) return;
        changeColumnOrder({
          boardGuid,
          columnGuid: result.draggableId,
          order: result.destination.index,
        });
      }

      if (result.type === 'TASK') {
        setDragTask(null);

        if (!result.destination || !tasks) return;

        const selectedTasksForDrop =
          selectedTasks.length === 0 ? [result.draggableId] : selectedTasks;

        const taskGuid = selectedTasksForDrop[0];
        const toColumnGuid = result.destination.droppableId;
        const fromColumnGuid = findFromColumnGuid(tasks, taskGuid);
        const toIndex = result.destination.index;
        const fromIndex = findTaskIndexInColumn(tasks, taskGuid);

        const newOrder = updateTasksListForBoardOnChangeTasksOrder({
          taskGuid,
          toColumnGuid,
          fromColumnGuid,
          fromIndex,
          toIndex,
        })(tasks).find((item) => item.taskGuid === taskGuid)
          ?.boardColumnOrderNumber;

        if (newOrder !== undefined) {
          changeTaskOrder({
            boardGuid: boardGuid,
            args: {
              boardColumnGuid: toColumnGuid,
              tasksGuids: selectedTasksForDrop,
              boardColumnOrderNumber: newOrder,
            },
            dropParams: {
              toColumnGuid,
              fromColumnGuid,
              fromIndex,
              toIndex,
              taskGuid,
            },
            qParams: filter,
          });
        }
      }
    },
    [boardGuid, tasks, selectedTasks]
  );

  const onDragStart = useCallback(
    (start: DragStart) => {
      if (start.type === 'TASK') {
        if (!selectedTasks.some((el) => el === start.draggableId)) {
          unSelectAll();
        }
        setDragTask(start.draggableId);
      }
    },
    [selectedTasks]
  );

  return (
    <BoardDragDropContext.Provider
      value={{
        selectTask,
        selectedTasks,
        unSelectAll,
        unSelectTask,
        dropHandler,
        onDragStart,
        dragTask,
      }}
    >
      {children}
    </BoardDragDropContext.Provider>
  );
};
