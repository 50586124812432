import { AttachFile, Delete, Edit, NotificationAdd } from '@mui/icons-material';

import { SpeedDialMap } from 'shared/ui/over-page-speed-dial';

export const TASK_PAGE_SPEED_DIAL_MAP: SpeedDialMap[] = [
  {
    tooltip: 'Редактировать',
    icon: <Edit />,
    action: () => {
      //
    },
  },
  {
    tooltip: 'Напоминания',
    icon: <NotificationAdd />,
    action: () => {
      //
    },
  },
  {
    tooltip: 'Прикрепить файл',
    icon: <AttachFile />,
    action: () => {
      //
    },
  },
  {
    tooltip: 'Удалить',
    icon: <Delete />,
    action: () => {
      //
    },
  },
];
