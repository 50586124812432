import { transformResponseError } from 'shared/api/libs';
import {
  getControllFilters,
  getEmployees,
  getEmployeesAllowedCoExecuters,
  getEmployeesAllowedExecuters,
  getOrigins,
  getStatuses,
} from '../services';
import {
  ControllFilterModel,
  GetListEmployee,
  GetListOrigin,
  StatusModel,
} from '../types';
import { taskProviderApi } from './taskProviderApi';

const optionApi = taskProviderApi.injectEndpoints({
  endpoints: (build) => ({
    //
    getAllEmployees: build.query<GetListEmployee[], void>({
      queryFn: async () => {
        try {
          const response = await getEmployees();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
    //
    //
    getAllowedExecuters: build.query<GetListEmployee[], void>({
      queryFn: async () => {
        try {
          const response = await getEmployeesAllowedExecuters();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
    //
    //
    getAllowedCoExecuters: build.query<GetListEmployee[], void>({
      queryFn: async () => {
        try {
          const response = await getEmployeesAllowedCoExecuters();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
    //
    //
    getOrigins: build.query<GetListOrigin[], void>({
      queryFn: async () => {
        try {
          const response = await getOrigins();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
    //
    //
    getStatuses: build.query<StatusModel[], void>({
      queryFn: async () => {
        try {
          const response = await getStatuses();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
    //
    //
    getControllFilters: build.query<ControllFilterModel, void>({
      queryFn: async () => {
        try {
          const response = await getControllFilters();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
  }),
});

export const {
  useGetAllEmployeesQuery,
  useLazyGetAllEmployeesQuery,
  useGetAllowedCoExecutersQuery,
  useGetAllowedExecutersQuery,
  useLazyGetOriginsQuery,
  useGetOriginsQuery,
  useGetStatusesQuery,
  useLazyGetStatusesQuery,
  useGetControllFiltersQuery,
} = optionApi;
