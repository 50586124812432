export const DOCUMENT_TITLE_COUNT = [
  'документов',
  'документ',
  'документа',
  'документа',
  'документа',
  'документов',
  'документов',
  'документов',
  'документов',
  'документов',
  'документов',
  'документов',
];
