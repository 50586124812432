import { FC, useLayoutEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { DEFAULT_BASE_FORM_VAUE } from '../constants';

import { SCHEME_BASE_FILTER_FORM } from '../scheme';
import { DocumentFlowFilterBaseForm } from '../types';
import { DocumentFlowBaseFormFilterModal } from '../ui';
import { DocumentFlowContainerBaseFormFilterModalProps } from '../ui/document-flow-container-base-form-filter-modal';

export const DocumentControlFilterModal: FC<
  Omit<DocumentFlowContainerBaseFormFilterModalProps, 'onSubmit'> & {
    isLoading?: boolean;
    onSubmit: (form: DocumentFlowFilterBaseForm) => void;
    initialValue: DocumentFlowFilterBaseForm | undefined;
  }
> = ({ isOpen, isLoading, onClose, onSubmit, initialValue }) => {
  const skip = !isOpen;
  const methods = useForm<DocumentFlowFilterBaseForm>({
    defaultValues: initialValue || DEFAULT_BASE_FORM_VAUE,
    resolver: yupResolver(SCHEME_BASE_FILTER_FORM),
  });

  const { handleSubmit, reset } = methods;

  useLayoutEffect(() => {
    if (isOpen) {
      reset(initialValue);
    }
  }, [isOpen, initialValue]);

  return (
    <DocumentFlowBaseFormFilterModal.Container
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit, console.error)}
    >
      <DocumentFlowBaseFormFilterModal.Provider {...methods}>
        <DocumentFlowBaseFormFilterModal.BaseInputs
          isLoading={isLoading}
          skip={skip}
        />
      </DocumentFlowBaseFormFilterModal.Provider>
    </DocumentFlowBaseFormFilterModal.Container>
  );
};
