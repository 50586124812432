import { BoardPage } from 'pages/boards/BoardPage';
import { MyBoardsPage } from 'pages/boards/MyBoardsPage';
import { RouteObject } from 'react-router-dom';
import { ALL_ROUTES } from './constants';

export const BOARD: RouteObject[] = [
  {
    path: ALL_ROUTES.boards,
    children: [
      {
        path: ALL_ROUTES.boardGuid,
        element: <BoardPage />,
      },
      {
        path: ALL_ROUTES.myBoards,
        element: <MyBoardsPage />,
      },
    ],
  },
];
