import { Grid } from '@mui/material';
import { FC } from 'react';

import { UiCalendarContainerProps } from './type';

export const UiCalendarContainer: FC<UiCalendarContainerProps> = ({
  children,
}) => {
  return (
    <Grid
      sx={{
        display: 'grid',
        gridTemplateColumns: '14.2% 14.3% 14.3% 14.3% 14.3% 14.3% 14.3%',
        borderRadius: 2,
        overflow: 'hidden',
      }}
    >
      {children}
    </Grid>
  );
};
