import { Button } from '@mui/material';
import { FC } from 'react';

import { VisitActionButtonsProps } from './type';

export const VisitActionButtons: FC<VisitActionButtonsProps> = ({
  visit,
  onOpen,
  onCreateChecklist,
  onDelete,
}) => {
  return (
    <>
      {visit.visitingChecklistId && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            if (visit.visitingChecklistId && onOpen) {
              onOpen(visit.visitingChecklistId);
            }
          }}
        >
          Открыть чек-лист
        </Button>
      )}

      {!visit.visitingChecklistId && visit.canEdit && (
        <Button
          onClick={async (e) => {
            e.stopPropagation();
            if (onCreateChecklist) {
              onCreateChecklist(visit.id);
            }
          }}
        >
          Создать чек-лист
        </Button>
      )}

      {visit.canDelete && (
        <Button
          color='error'
          onClick={(e) => {
            e.stopPropagation();
            if (onDelete) {
              onDelete(visit.id);
            }
          }}
        >
          Удалить
        </Button>
      )}
    </>
  );
};
