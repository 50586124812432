/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CoreResponsesError {
  /**
   * Код ошибки
   * @format int32
   */
  code: number;
  /** Сообщение */
  message?: string | null;
}

export interface PaginatedRetailOpeningProcessResponsesOpeningProcessGetListProtocolOpeningProcess {
  /**
   * Общее кол-во элементов
   * @format int32
   */
  count: number;
  /** Элементы */
  items: RetailOpeningProcessResponsesOpeningProcessGetListProtocolOpeningProcess[];
}

export interface PaginatedRetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate {
  /**
   * Общее кол-во элементов
   * @format int32
   */
  count: number;
  /** Элементы */
  items: RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate[];
}

export interface MicrosoftAspNetCoreMvcProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

/** Запрос для создания триггера автозапуска */
export interface RetailOpeningProcessRequestsAutorunTriggerCreate {
  /**
   * Наименование триггера автозапуска
   * @minLength 1
   */
  title: string;
}

/** Запрос для удаления триггеров автозапуска */
export interface RetailOpeningProcessRequestsAutorunTriggerDelete {
  /**
   * Guid'ы удаляемых триггеров автозапуска
   * @minItems 1
   */
  guids: string[];
}

/** Запрос для создания поля для отсчета дней */
export interface RetailOpeningProcessRequestsCountdownFieldCreate {
  /**
   * Наименование поля для отсчета дней
   * @minLength 1
   */
  title: string;
}

/** Запрос для удаления полей для отсчета дней */
export interface RetailOpeningProcessRequestsCountdownFieldDelete {
  /**
   * Guid'ы удаляемых полей для отсчета дней
   * @minItems 1
   */
  guids: string[];
}

export type RetailOpeningProcessRequestsOpeningProcessOpeningProcessTaskGetList =
  object;

export interface RetailOpeningProcessRequestsOpeningProcessPreparedStageTask {
  /**
   * Guid шаблона задачи
   * @format uuid
   */
  stageTaskGuid: string;
  /**
   * Guid сотрудника, подобранного для должности постановщика
   * @format uuid
   */
  creatorGuid: string;
  /**
   * Guid сотрудника, подобранного для должности исполнителя
   * @format uuid
   */
  executorGuid: string;
  /** Guid'ы сотрудников, подобранных для должностей соисполнителей */
  coExecutorsGuids: string[];
  /** Guid'ы сотрудников, подобранных для должностей наблюдателей */
  observersGuids: string[];
}

export interface RetailOpeningProcessRequestsOpeningProcessPreparedStageTasksCreate {
  /**
   * Guid шаблона процесса
   * @format uuid
   */
  openingTemplateGuid: string;
  /**
   * Guid протокола ЭР
   * @format uuid
   */
  protocolGuid: string;
  /**
   * Дата отсчета дней
   * @format date-time
   */
  countdownDate: string;
  /** Список задач с подобранными сотрудниками */
  stageTasks: RetailOpeningProcessRequestsOpeningProcessPreparedStageTask[];
}

export interface RetailOpeningProcessRequestsOpeningTemplateOpeningTemplateCreate {
  /**
   * Наименование
   * @minLength 1
   */
  title: string;
  /**
   * Guid поля для отсчета дней
   * @format uuid
   */
  countdownFieldGuid: string;
  /** Признак автозапуска */
  isAutorun: boolean;
  /**
   * Guid триггера автозапуска
   * @format uuid
   */
  autorunTriggerGuid?: string | null;
}

export interface RetailOpeningProcessRequestsOpeningTemplateOpeningTemplateUpdate {
  /**
   * Наименование
   * @minLength 1
   */
  title: string;
  /**
   * Guid поля для отсчета дней
   * @format uuid
   */
  countdownFieldGuid: string;
  /** Признак автозапуска */
  isAutorun: boolean;
  /**
   * Guid триггера автозапуска
   * @format uuid
   */
  autorunTriggerGuid?: string | null;
}

export interface RetailOpeningProcessRequestsOpeningTemplateStageCreate {
  /**
   * Номер этапа
   * @format int32
   */
  stageNumber: number;
}

/** Запрос на создание шаблона задачи */
export interface RetailOpeningProcessRequestsOpeningTemplateStageTaskCreate {
  /**
   * Наименование задачи
   * @minLength 1
   */
  title: string;
  /** Описание задачи */
  description?: string | null;
  /**
   * Кол-во дней отсчета для даты начала
   * @format int32
   */
  startDaysCount: number;
  /**
   * Кол-во дней отсчета для даты завершения
   * @format int32
   */
  finishDaysCount: number;
  /**
   * Guid должность - постановщик
   * @format uuid
   */
  creatorPositionGuid?: string | null;
  /**
   * Guid должность - исполнитель
   * @format uuid
   */
  executorPositionGuid: string;
  /** Список Guid'ов должностей - соисполнителей */
  coExecutorsPositionsGuids: string[];
  /** Список Guid'ов должностей - наблюдателей */
  observersPositionsGuids: string[];
  /** Уведомлять постановщика */
  isReminderCreator: boolean;
  /** Уведомлять исполнителя */
  isReminderExecutor: boolean;
  /** Уведомлять соисполнителя */
  isReminderCoExecutor: boolean;
  /** Уведомлять наблюдателя */
  isReminderObserver: boolean;
  /**
   * Период уведомления постановщика (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCreator?: number | null;
  /**
   * Период уведомления исполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodExecutor?: number | null;
  /**
   * Период уведомления соисполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCoExecutor?: number | null;
  /**
   * Период уведомления наблюдателя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodObserver?: number | null;
  /** Файлы */
  addingFiles?:
    | RetailOpeningProcessRequestsOpeningTemplateStakeTaskFileCreate[]
    | null;
}

export interface RetailOpeningProcessRequestsOpeningTemplateStageTaskUpdate {
  /**
   * Наименование задачи
   * @minLength 1
   */
  title: string;
  /** Описание задачи */
  description?: string | null;
  /**
   * Кол-во дней отсчета для даты начала
   * @format int32
   */
  startDaysCount: number;
  /**
   * Кол-во дней отсчета для даты завершения
   * @format int32
   */
  finishDaysCount: number;
  /**
   * Guid должность - постановщик
   * @format uuid
   */
  creatorPositionGuid?: string | null;
  /**
   * Guid должность - исполнитель
   * @format uuid
   */
  executorPositionGuid: string;
  /** Список Guid'ов должностей - соисполнителей */
  coExecutorsPositionsGuids: string[];
  /** Список Guid'ов должностей - наблюдателей */
  observersPositionsGuids: string[];
  /** Уведомлять постановщика */
  isReminderCreator: boolean;
  /** Уведомлять исполнителя */
  isReminderExecutor: boolean;
  /** Уведомлять соисполнителя */
  isReminderCoExecutor: boolean;
  /** Уведомлять наблюдателя */
  isReminderObserver: boolean;
  /**
   * Период уведомления постановщика (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCreator?: number | null;
  /**
   * Период уведомления исполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodExecutor?: number | null;
  /**
   * Период уведомления соисполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCoExecutor?: number | null;
  /**
   * Период уведомления наблюдателя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodObserver?: number | null;
  /** Добавляемые файлы */
  addingFiles?:
    | RetailOpeningProcessRequestsOpeningTemplateStakeTaskFileCreate[]
    | null;
  /** Guid'ы удаляемых файловы */
  deletingFiles?: string[] | null;
}

export interface RetailOpeningProcessRequestsOpeningTemplateStageUpdate {
  /**
   * Guid этапа
   * @format uuid
   */
  guid: string;
  /**
   * Номер этапа
   * @format int32
   */
  stageNumber: number;
}

export interface RetailOpeningProcessRequestsOpeningTemplateStagesUpdate {
  /** Обновляемые этапы */
  stages?: RetailOpeningProcessRequestsOpeningTemplateStageUpdate[] | null;
}

export interface RetailOpeningProcessRequestsOpeningTemplateStakeTaskFileCreate {
  /**
   * Наименование файла
   * @minLength 1
   */
  title: string;
  /**
   * Данные файла (строка base64)
   * @minLength 1
   */
  data: string;
}

/** Элемент списка триггеров автозапуска */
export interface RetailOpeningProcessResponsesGetListAutorunTrigger {
  /**
   * Guid триггера
   * @format uuid
   */
  guid: string;
  /**
   * Наименование триггера
   * @minLength 1
   */
  title: string;
}

/** Элемент списка полей для отсчета дней */
export interface RetailOpeningProcessResponsesGetListCountdownField {
  /**
   * Guid поля
   * @format uuid
   */
  guid: string;
  /**
   * Наименование поля
   * @minLength 1
   */
  title: string;
}

/** Сеть */
export interface RetailOpeningProcessResponsesGetListDepartment {
  /**
   * Guid сети
   * @format uuid
   */
  guid: string;
  /**
   * Наименование сети
   * @minLength 1
   */
  title: string;
}

/** Сотрудник */
export interface RetailOpeningProcessResponsesGetListEmployee {
  /**
   * Guid сотрудника
   * @format uuid
   */
  guid: string;
  /**
   * Имя сотрудника
   * @minLength 1
   */
  name: string;
  /** Должность */
  position: RetailOpeningProcessResponsesGetListPosition;
  /** Признак сотрудника в отпуске */
  isVacation: boolean;
}

/** Сотрудник, подобранный для определенной должности */
export interface RetailOpeningProcessResponsesGetListEmployeeByPosition {
  /** Должность */
  requeredPosition: RetailOpeningProcessResponsesGetListPosition;
  /** Сотрудник */
  employee?: RetailOpeningProcessResponsesGetListEmployee;
}

/** Должность */
export interface RetailOpeningProcessResponsesGetListPosition {
  /**
   * Guid
   * @format uuid
   */
  guid: string;
  /**
   * Наименование
   * @minLength 1
   */
  title: string;
  /** Категория должности */
  category: RetailOpeningProcessResponsesGetListPositionCategory;
}

/** Категория должности */
export interface RetailOpeningProcessResponsesGetListPositionCategory {
  /**
   * Guid
   * @format uuid
   */
  guid: string;
  /**
   * Наименование
   * @minLength 1
   */
  title: string;
  /** Признак головного офиса */
  isHead: boolean;
}

/** Протокол ЭР */
export interface RetailOpeningProcessResponsesGetListProtocol {
  /**
   * Guid протокола
   * @format uuid
   */
  guid: string;
  /**
   * Наименование протокола
   * @minLength 1
   */
  title: string;
  /** Сеть */
  department: RetailOpeningProcessResponsesGetListDepartment;
  /** Элемент списка аптек */
  retail?: RetailOpeningProcessResponsesGetListRetail;
  /**
   * Дата отсчета дней (заполнена, если в запросе указан OpeningTemplateGuid и у протокола заполнена дата, соответствующая указанному в шаблоне полю отсчета дней)
   * @format date-time
   */
  countdownDate: string | null;
}

/** Элемент списка аптек */
export interface RetailOpeningProcessResponsesGetListRetail {
  /**
   * Guid аптеки
   * @format uuid
   */
  guid: string;
  /**
   * Наименование аптеки
   * @minLength 1
   */
  title: string;
}

export interface RetailOpeningProcessResponsesOpeningProcessGetDictionaryOpeningProcess {
  /** @format int32 */
  openingProcessId?: number;
  openingProcessTitle?: string | null;
  isDeleted?: boolean;
}

export interface RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcess {
  /**
   * Guid запущееного процесса открытия
   * @format uuid
   */
  guid: string;
  /** Элемент списка шаблонов */
  openingTemplate: RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate;
}

export interface RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcessTask {
  /**
   * Guid задачи
   * @format uuid
   */
  guid: string;
  /**
   * Наименование задачи
   * @minLength 1
   */
  title: string;
  /**
   * Дата начала
   * @format date-time
   */
  dateStart: string | null;
  /**
   * Дата завершения
   * @format date-time
   */
  dateFinish: string | null;
  /**
   * Статус задачи
   * @minLength 1
   */
  status: string;
  /**
   * Постановщик
   * @minLength 1
   */
  creatorName: string | null;
  /**
   * Исполнитель
   * @minLength 1
   */
  executorName: string | null;
  /**
   * HR
   * @format int32
   */
  units: number | null;
  /**
   * Номер этапа
   * @format int32
   */
  stageNumber: number;
  /** Просрочена */
  isOverdue: boolean;
}

/** Подобранне для шаблона сотрудники */
export interface RetailOpeningProcessResponsesOpeningProcessGetListPreparedStageTask {
  /**
   * Номер этапа
   * @format int32
   */
  stageNumber: number;
  /**
   * Guid шаблона задачи
   * @format uuid
   */
  stageTaskGuid: string;
  /**
   * Наименование шаблона задачи
   * @minLength 1
   */
  stageTaskTitle: string;
  /** Сотрудник, подобранный для определенной должности */
  creator: RetailOpeningProcessResponsesGetListEmployeeByPosition;
  /** Сотрудник, подобранный для определенной должности */
  executor: RetailOpeningProcessResponsesGetListEmployeeByPosition;
  /** Сотрудники, подобранные для должностей соисполнителей */
  coExecutors: RetailOpeningProcessResponsesGetListEmployeeByPosition[];
  /** Сотрудники, подобранные для должностей наблюдателей */
  observers: RetailOpeningProcessResponsesGetListEmployeeByPosition[];
}

export interface RetailOpeningProcessResponsesOpeningProcessGetListProtocolOpeningProcess {
  /** Протокол ЭР */
  protocol: RetailOpeningProcessResponsesGetListProtocol;
  status: RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses;
  /** Запущенные процессы открытия */
  openingProcesses?:
    | RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcess[]
    | null;
}

/** @format int32 */
export enum RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/** Элемент списка шаблонов */
export interface RetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate {
  /**
   * Guid шаблона
   * @format uuid
   */
  guid: string;
  /**
   * Наименование шаблона
   * @minLength 1
   */
  title: string;
  /** Признак автозапуска */
  isAutorun: boolean;
  /**
   * Дата создания
   * @format date-time
   */
  createdAt: string;
  /**
   * Создатель
   * @minLength 1
   */
  createdBy: string;
}

/** Элемент списка этапов */
export interface RetailOpeningProcessResponsesOpeningTemplateGetListStage {
  /**
   * Guid этапа
   * @format uuid
   */
  guid: string;
  /**
   * Номер этапа
   * @format int32
   */
  stageNumber: number;
}

export interface RetailOpeningProcessResponsesOpeningTemplateGetListStageTask {
  /**
   * Guid шаблона задачи
   * @format uuid
   */
  guid: string;
  /**
   * Наименование шаблона задачи
   * @minLength 1
   */
  title: string;
  /**
   * Кол-во дней отсчета для даты начала
   * @format int32
   */
  startDaysCount: number;
  /**
   * Кол-во дней отсчета для даты завершения
   * @format int32
   */
  finishDaysCount: number;
  /**
   * Должность - постановщик
   * @minLength 1
   */
  creatorPositionTitle: string;
  /**
   * Должность - исполнитель
   * @minLength 1
   */
  executorPositionTitle: string;
}

export interface RetailOpeningProcessResponsesOpeningTemplateGetListStageTaskFile {
  /**
   * Guid файла
   * @format uuid
   */
  guid: string;
  /**
   * Путь файла
   * @minLength 1
   */
  path: string;
  /**
   * Наименование файла
   * @minLength 1
   */
  title: string;
}

/** Шаблон */
export interface RetailOpeningProcessResponsesOpeningTemplateGetOpeningTemplate {
  /**
   * Guid шаблона
   * @format uuid
   */
  guid: string;
  /**
   * Наименование шаблона
   * @minLength 1
   */
  title: string;
  /** Элемент списка полей для отсчета дней */
  countdownField: RetailOpeningProcessResponsesGetListCountdownField;
  /** Признак автозапуска */
  isAutorun: boolean;
  /** Элемент списка триггеров автозапуска */
  autorunTrigger?: RetailOpeningProcessResponsesGetListAutorunTrigger;
  /**
   * Дата создания
   * @format date-time
   */
  createdAt: string;
  /**
   * Создатель
   * @minLength 1
   */
  createdBy: string;
}

export interface RetailOpeningProcessResponsesOpeningTemplateGetStageTask {
  /**
   * Guid шаблона задачи
   * @format uuid
   */
  guid: string;
  /**
   * Наименование шаблона задачи
   * @minLength 1
   */
  title: string;
  /**
   * Описание задачи
   * @minLength 1
   */
  description: string | null;
  /**
   * Кол-во дней отсчета для даты начала
   * @format int32
   */
  startDaysCount: number;
  /**
   * Кол-во дней отсчета для даты завершения
   * @format int32
   */
  finishDaysCount: number;
  /** Должность */
  creatorPosition: RetailOpeningProcessResponsesGetListPosition;
  /** Должность */
  executorPosition: RetailOpeningProcessResponsesGetListPosition;
  /** Список должностей - соисполнителей */
  coExecutorsPositions: RetailOpeningProcessResponsesGetListPosition[];
  /** Список должностей - наблюдателей */
  observersPositions: RetailOpeningProcessResponsesGetListPosition[];
  /** Уведомлять постановщика */
  isReminderCreator: boolean;
  /** Уведомлять исполнителя */
  isReminderExecutor: boolean;
  /** Уведомлять соисполнителя */
  isReminderCoExecutor: boolean;
  /** Уведомлять наблюдателя */
  isReminderObserver: boolean;
  /**
   * Период уведомления постановщика (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCreator: number | null;
  /**
   * Период уведомления исполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodExecutor: number | null;
  /**
   * Период уведомления соисполнителя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodCoExecutor: number | null;
  /**
   * Период уведомления наблюдателя (кол-во дней до даты завершения)
   * @format int32
   */
  reminderPeriodObserver: number | null;
  /** Список файлов */
  files: RetailOpeningProcessResponsesOpeningTemplateGetListStageTaskFile[];
}

export interface OpeningProcessesPreparedStageTasksListParams {
  /**
   * Guid шаблона процесса
   * @format uuid
   */
  OpeningTemplateGuid: string;
  /**
   * Guid протокола ЭР
   * @format uuid
   */
  ProtocolGuid: string;
  /** Перечень Guid'ов этапов шаблона процесса, которые необходимо пропустить (в результате не будет задач по этим этапам) */
  StagesToSkipGuids?: string[];
}

export interface OpeningProcessesListParams {
  /**
   * Номер страницы
   * @format int32
   */
  Page: number;
  /**
   * Кол-во элементов на странице
   * @format int32
   */
  Limit: number;
  /** Guid'ы протоколов ЭР */
  ProtocolsGuids?: string[];
  /** Guid'ы шаблонов процессов */
  OpeningTemplatesGuids?: string[];
  /** Строка поиска по протоколам ЭР/аптекам */
  SearchText?: string;
  /**
   * Фильтр по статусам:<br />
   * 1. Новый - нет ни одного запущенного или завершенного процесса;<br />
   * 2. В процессе - есть хоть один запущенный, но незавершенный процесс;<br />
   * 3. Завершен - все процессы завершены;
   */
  Statuses?: RetailOpeningProcessResponsesOpeningProcessProtocolOpeningProcessStatuses[];
  /**
   * Сортировка.<br />
   * Указывается одно из следующих полей:<br />
   * ProtocolTitleAsc - наименование протокола по возрастанию;<br />
   * ProtocolTitleDesc - наименование протокола по убыванию;<br />
   * ProtocolDepartmentTitleAsc - наименование сети протокола по возрастанию;<br />
   * ProtocolDepartmentTitleDesc - наименование сети протокола по убыванию;<br />
   * ProtocolRetailTitleAsc - наименование аптеки, закрепленной за протоколом по возрастанию;<br />
   * ProtocolRetailTitleDesc - наименование аптеки, закрепленной за протоколом по убыванию;<br />
   */
  OrderBy?: string;
}

export interface OpeningProcessesOpeningProcessTasksDetailParams {
  request?: RetailOpeningProcessRequestsOpeningProcessOpeningProcessTaskGetList;
  /**
   * Guid запущенного процесса открытия
   * @format uuid
   */
  openingProcessGuid: string;
}

export interface OpeningTemplatesListParams {
  /**
   * Номер страницы
   * @format int32
   */
  Page: number;
  /**
   * Кол-во элементов на странице
   * @format int32
   */
  Limit: number;
  /** Строка поиска по наименованию */
  SearchText?: string;
}

export interface OpeningTemplatesDeleteParams {
  /** Guid'ы шаблонов */
  openingTemplateGuids?: string[];
}

export interface ProtocolsListParams {
  /** Guid'ы необходимых протоколов ЭР (если не указано, то возвращает весь список) */
  Guids?: string[];
  /**
   * Guid шаблона процесса (используется для определения даты отсчета дней CountdownDate, если Guid не указан, то всегда возвращается пустая дата)
   * @format uuid
   */
  OpeningTemplateGuid?: string;
}

export interface OpeningTemplatesStagesDeleteParams {
  /** Guid'ы этапов */
  stageGuids?: string[];
  /** @format uuid */
  templateGuid: string;
}

export interface OpeningTemplatesStagesStageTasksDeleteParams {
  stageTaskGuids?: string[];
  /** @format uuid */
  templateGuid: string;
  /** @format uuid */
  stageGuid: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title RetailOpeningProcess.Service
 * @version 1.0
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  autorunTriggers = {
    /**
     * No description
     *
     * @tags AutorunTriggers
     * @name AutorunTriggersList
     * @summary Получение списка триггеров запуска
     * @request GET:/v1/retailopeningprocess/api/AutorunTriggers
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesGetListAutorunTrigger)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AutorunTriggersList: (params: RequestParams = {}) =>
      this.request<
        RetailOpeningProcessResponsesGetListAutorunTrigger[],
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/AutorunTriggers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutorunTriggers
     * @name AutorunTriggersCreateCreate
     * @summary Создание триггера запуска
     * @request POST:/v1/retailopeningprocess/api/AutorunTriggers/Create
     * @secure
     * @response `201` `RetailOpeningProcessResponsesGetListAutorunTrigger` Created
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AutorunTriggersCreateCreate: (
      data: RetailOpeningProcessRequestsAutorunTriggerCreate,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesGetListAutorunTrigger,
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/AutorunTriggers/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AutorunTriggers
     * @name AutorunTriggersDeleteCreate
     * @summary Удаление триггеров запуска
     * @request POST:/v1/retailopeningprocess/api/AutorunTriggers/Delete
     * @secure
     * @response `200` `void` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    AutorunTriggersDeleteCreate: (
      data: RetailOpeningProcessRequestsAutorunTriggerDelete,
      params: RequestParams = {}
    ) =>
      this.request<void, CoreResponsesError | void>({
        path: `/v1/retailopeningprocess/api/AutorunTriggers/Delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  countdownFields = {
    /**
     * No description
     *
     * @tags CountdownFields
     * @name CountdownFieldsList
     * @summary Получение списка полей для отсчета дней
     * @request GET:/v1/retailopeningprocess/api/CountdownFields
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesGetListCountdownField)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    CountdownFieldsList: (params: RequestParams = {}) =>
      this.request<
        RetailOpeningProcessResponsesGetListCountdownField[],
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/CountdownFields`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountdownFields
     * @name CountdownFieldsCreateCreate
     * @summary Создание поля для отсчета дней
     * @request POST:/v1/retailopeningprocess/api/CountdownFields/Create
     * @secure
     * @response `201` `RetailOpeningProcessResponsesGetListCountdownField` Created
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    CountdownFieldsCreateCreate: (
      data: RetailOpeningProcessRequestsCountdownFieldCreate,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesGetListCountdownField,
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/CountdownFields/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountdownFields
     * @name CountdownFieldsDeleteCreate
     * @summary Удаление полей для отсчета дней
     * @request POST:/v1/retailopeningprocess/api/CountdownFields/Delete
     * @secure
     * @response `200` `void` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    CountdownFieldsDeleteCreate: (
      data: RetailOpeningProcessRequestsCountdownFieldDelete,
      params: RequestParams = {}
    ) =>
      this.request<void, CoreResponsesError | void>({
        path: `/v1/retailopeningprocess/api/CountdownFields/Delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  employees = {
    /**
     * No description
     *
     * @tags Employees
     * @name EmployeesList
     * @summary Получение списка сотрудников
     * @request GET:/v1/retailopeningprocess/api/Employees
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesGetListEmployee)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    EmployeesList: (params: RequestParams = {}) =>
      this.request<
        RetailOpeningProcessResponsesGetListEmployee[],
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/Employees`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  openingProcesses = {
    /**
     * No description
     *
     * @tags OpeningProcesses
     * @name OpeningProcessesPreparedStageTasksList
     * @summary Возвращает список подобранных сотрудников для задач шаблона процесса
     * @request GET:/v1/retailopeningprocess/api/OpeningProcesses/PreparedStageTasks
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesOpeningProcessGetListPreparedStageTask)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Протокол ЭР не указан или указан не верно;<br /> 2 - Шаблон процесса открытия не указан или указан не верно;<br /> 3 - Для данного протокола уже был запущен процесс с выбранным шаблоном процесса открытия;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `void` Server Error
     */
    OpeningProcessesPreparedStageTasksList: (
      query: OpeningProcessesPreparedStageTasksListParams,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningProcessGetListPreparedStageTask[],
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/OpeningProcesses/PreparedStageTasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OpeningProcesses
     * @name OpeningProcessesPreparedStageTasksCreate
     * @summary Генерирует задачи и запускает процесс открытия
     * @request POST:/v1/retailopeningprocess/api/OpeningProcesses/PreparedStageTasks
     * @secure
     * @response `200` `RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcess` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Протокол ЭР не указан или указан не верно;<br /> 2 - Шаблон процесса открытия не указан или указан не верно;<br /> 3 - Для данного протокола уже был запущен процесс с выбранным шаблоном процесса открытия;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `void` Server Error
     */
    OpeningProcessesPreparedStageTasksCreate: (
      data: RetailOpeningProcessRequestsOpeningProcessPreparedStageTasksCreate,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcess,
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/OpeningProcesses/PreparedStageTasks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OpeningProcesses
     * @name OpeningProcessesList
     * @summary Возвращает список протоколов ЭР, с запущенными процессами открытия
     * @request GET:/v1/retailopeningprocess/api/OpeningProcesses
     * @secure
     * @response `200` `PaginatedRetailOpeningProcessResponsesOpeningProcessGetListProtocolOpeningProcess` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `void` Server Error
     */
    OpeningProcessesList: (
      query: OpeningProcessesListParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PaginatedRetailOpeningProcessResponsesOpeningProcessGetListProtocolOpeningProcess,
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/OpeningProcesses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OpeningProcesses
     * @name OpeningProcessesDelete
     * @summary Удаление запущенного процесса открытия
     * @request DELETE:/v1/retailopeningprocess/api/OpeningProcesses/{openingProcessGuid}
     * @secure
     * @response `200` `void` Шаблоны удалены
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `CoreResponsesError` Not Found
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    OpeningProcessesDelete: (
      openingProcessGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void | CoreResponsesError>({
        path: `/v1/retailopeningprocess/api/OpeningProcesses/${openingProcessGuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OpeningProcesses
     * @name OpeningProcessesDictionaryList
     * @summary Возвращает список-справочник всех когда-либо запускаемых процессов открытия
     * @request GET:/v1/retailopeningprocess/api/OpeningProcesses/Dictionary
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesOpeningProcessGetDictionaryOpeningProcess)[]` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `void` Server Error
     */
    OpeningProcessesDictionaryList: (params: RequestParams = {}) =>
      this.request<
        RetailOpeningProcessResponsesOpeningProcessGetDictionaryOpeningProcess[],
        void
      >({
        path: `/v1/retailopeningprocess/api/OpeningProcesses/Dictionary`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  openingProcessTasks = {
    /**
     * No description
     *
     * @tags OpeningProcessTasks
     * @name OpeningProcessesOpeningProcessTasksDetail
     * @summary Возвращает список задач из задачника по запущенному процессу
     * @request GET:/v1/retailopeningprocess/api/OpeningProcesses/{openingProcessGuid}/OpeningProcessTasks
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcessTask)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `void` Server Error
     */
    OpeningProcessesOpeningProcessTasksDetail: (
      {
        openingProcessGuid,
        ...query
      }: OpeningProcessesOpeningProcessTasksDetailParams,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningProcessGetListOpeningProcessTask[],
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/OpeningProcesses/${openingProcessGuid}/OpeningProcessTasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  openingTemplates = {
    /**
     * No description
     *
     * @tags OpeningTemplates
     * @name OpeningTemplatesList
     * @summary Получение списка шаблонов процессов открытия аптек
     * @request GET:/v1/retailopeningprocess/api/OpeningTemplates
     * @secure
     * @response `200` `PaginatedRetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    OpeningTemplatesList: (
      query: OpeningTemplatesListParams,
      params: RequestParams = {}
    ) =>
      this.request<
        PaginatedRetailOpeningProcessResponsesOpeningTemplateGetListOpeningTemplate,
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OpeningTemplates
     * @name OpeningTemplatesCreate
     * @summary Создание шаблона процесса открытия аптек
     * @request POST:/v1/retailopeningprocess/api/OpeningTemplates
     * @secure
     * @response `201` `RetailOpeningProcessResponsesOpeningTemplateGetOpeningTemplate` Created
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанное поле для отсчета дней не указано или указано не верно;<br /> 2 - Указанный триггер запуска не указан или указан не верно;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    OpeningTemplatesCreate: (
      data: RetailOpeningProcessRequestsOpeningTemplateOpeningTemplateCreate,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetOpeningTemplate,
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OpeningTemplates
     * @name OpeningTemplatesDelete
     * @summary Удаление шаблонов процессов открытия аптек
     * @request DELETE:/v1/retailopeningprocess/api/OpeningTemplates
     * @secure
     * @response `200` `void` Шаблоны удалены
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    OpeningTemplatesDelete: (
      query: OpeningTemplatesDeleteParams,
      params: RequestParams = {}
    ) =>
      this.request<void, void | CoreResponsesError>({
        path: `/v1/retailopeningprocess/api/OpeningTemplates`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OpeningTemplates
     * @name OpeningTemplatesDetail
     * @summary Получение подробной ифнормации по шаблону процесса открытия аптек
     * @request GET:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}
     * @secure
     * @response `200` `RetailOpeningProcessResponsesOpeningTemplateGetOpeningTemplate` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    OpeningTemplatesDetail: (
      templateGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetOpeningTemplate,
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OpeningTemplates
     * @name OpeningTemplatesUpdate
     * @summary Обновление ифнормации по шаблону процесса открытия аптек
     * @request PUT:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}
     * @secure
     * @response `200` `RetailOpeningProcessResponsesOpeningTemplateGetOpeningTemplate` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанное поле для отсчета дней не указано или указано не верно;<br /> 2 - Указанный триггер запуска не указан или указан не верно;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    OpeningTemplatesUpdate: (
      templateGuid: string,
      data: RetailOpeningProcessRequestsOpeningTemplateOpeningTemplateUpdate,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetOpeningTemplate,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  positions = {
    /**
     * No description
     *
     * @tags Positions
     * @name PositionsList
     * @summary Получение списка должностей
     * @request GET:/v1/retailopeningprocess/api/Positions
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesGetListPosition)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    PositionsList: (params: RequestParams = {}) =>
      this.request<
        RetailOpeningProcessResponsesGetListPosition[],
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/Positions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  protocols = {
    /**
     * No description
     *
     * @tags Protocols
     * @name ProtocolsList
     * @summary Получение списка протоколов ЭР
     * @request GET:/v1/retailopeningprocess/api/Protocols
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesGetListProtocol)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанныей шаблон процесса открытия не найден; <br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    ProtocolsList: (query: ProtocolsListParams, params: RequestParams = {}) =>
      this.request<
        RetailOpeningProcessResponsesGetListProtocol[],
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/Protocols`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  stages = {
    /**
     * No description
     *
     * @tags Stages
     * @name OpeningTemplatesStagesDetail
     * @summary Получение списка этапов шаблона
     * @request GET:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}/Stages
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesOpeningTemplateGetListStage)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    OpeningTemplatesStagesDetail: (
      templateGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetListStage[],
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}/Stages`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stages
     * @name OpeningTemplatesStagesDelete
     * @summary Удаление этапов шаблона
     * @request DELETE:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}/Stages
     * @secure
     * @response `200` `void` Шаблоны удалены
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный шаблон не найден;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    OpeningTemplatesStagesDelete: (
      { templateGuid, ...query }: OpeningTemplatesStagesDeleteParams,
      params: RequestParams = {}
    ) =>
      this.request<void, CoreResponsesError | void>({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}/Stages`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stages
     * @name OpeningTemplatesStagesCreateCreate
     * @summary Создание этапа шаблона
     * @request POST:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}/Stages/Create
     * @secure
     * @response `201` `RetailOpeningProcessResponsesOpeningTemplateGetListStage` Created
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный шаблон не найден;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    OpeningTemplatesStagesCreateCreate: (
      templateGuid: string,
      data: RetailOpeningProcessRequestsOpeningTemplateStageCreate,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetListStage,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}/Stages/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stages
     * @name OpeningTemplatesStagesUpdateCreate
     * @summary Обновление этапов шаблона
     * @request POST:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}/Stages/Update
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesOpeningTemplateGetListStage)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный шаблон не найден;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    OpeningTemplatesStagesUpdateCreate: (
      templateGuid: string,
      data: RetailOpeningProcessRequestsOpeningTemplateStagesUpdate,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetListStage[],
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}/Stages/Update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  stageTasks = {
    /**
     * No description
     *
     * @tags StageTasks
     * @name OpeningTemplatesStagesStageTasksDetail
     * @summary Получение списка шаблонов задач для этапа
     * @request GET:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}/Stages/{stageGuid}/StageTasks
     * @secure
     * @response `200` `(RetailOpeningProcessResponsesOpeningTemplateGetListStageTask)[]` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    OpeningTemplatesStagesStageTasksDetail: (
      templateGuid: string,
      stageGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetListStageTask[],
        CoreResponsesError | void
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}/Stages/${stageGuid}/StageTasks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StageTasks
     * @name OpeningTemplatesStagesStageTasksCreate
     * @summary Создание шаблона задачи
     * @request POST:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}/Stages/{stageGuid}/StageTasks
     * @secure
     * @response `201` `RetailOpeningProcessResponsesOpeningTemplateGetStageTask` Created
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный этап не найден;<br /> 2 - Наименование задачи не может быть пустым;<br /> 3 - Кол-во дней для отсчета даты начала или даты завершения указаны не верно;<br /> 4 - Период напоминания для Постановщика не указан или указан не верно;<br /> 5 - Период Исполнителя для Постановщика не указан или указан не верно;<br /> 6 - Период напоминания для Соисполнителя не указан или указан не верно;<br /> 7 - Период напоминания для Наблюдателя не указан или указан не верно;<br /> 8 - Указанная должность Постановщика не найдена;<br /> 9 - Указанная должность Исполнителя не найдена;<br /> 10 - Как минимум одна из указанных должностей Соисполнителей не найдена;<br /> 11 - Как минимум одна из указанных должностей Наблюдателей не найдена;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    OpeningTemplatesStagesStageTasksCreate: (
      templateGuid: string,
      stageGuid: string,
      data: RetailOpeningProcessRequestsOpeningTemplateStageTaskCreate,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetStageTask,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}/Stages/${stageGuid}/StageTasks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StageTasks
     * @name OpeningTemplatesStagesStageTasksDelete
     * @summary Удаление шаблонов задач
     * @request DELETE:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}/Stages/{stageGuid}/StageTasks
     * @secure
     * @response `200` `void` Шаблоны задач удалены
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный этап шаблона не найден;<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `500` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 500 - Internal Server Error
     */
    OpeningTemplatesStagesStageTasksDelete: (
      {
        templateGuid,
        stageGuid,
        ...query
      }: OpeningTemplatesStagesStageTasksDeleteParams,
      params: RequestParams = {}
    ) =>
      this.request<void, CoreResponsesError | void>({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}/Stages/${stageGuid}/StageTasks`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StageTasks
     * @name OpeningTemplatesStagesStageTasksDetail2
     * @summary Получение информации по шаблону задачи
     * @request GET:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}/Stages/{stageGuid}/StageTasks/{stageTaskGuid}
     * @originalName OpeningTemplatesStagesStageTasksDetail2
     * @duplicate
     * @secure
     * @response `200` `RetailOpeningProcessResponsesOpeningTemplateGetStageTask` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    OpeningTemplatesStagesStageTasksDetail2: (
      templateGuid: string,
      stageGuid: string,
      stageTaskGuid: string,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetStageTask,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}/Stages/${stageGuid}/StageTasks/${stageTaskGuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StageTasks
     * @name OpeningTemplatesStagesStageTasksUpdate
     * @summary Обновление шаблона задачи
     * @request PUT:/v1/retailopeningprocess/api/OpeningTemplates/{templateGuid}/Stages/{stageGuid}/StageTasks/{stageTaskGuid}
     * @secure
     * @response `200` `RetailOpeningProcessResponsesOpeningTemplateGetStageTask` Success
     * @response `400` `CoreResponsesError` Расшифровка кодов ошибок:<br /> 1 - Указанный этап не найден;<br /> 2 - Наименование задачи не может быть пустым;<br /> 3 - Кол-во дней для отсчета даты начала или даты завершения указаны не верно;<br /> 4 - Период напоминания для Постановщика не указан или указан не верно;<br /> 5 - Период Исполнителя для Постановщика не указан или указан не верно;<br /> 6 - Период напоминания для Соисполнителя не указан или указан не верно;<br /> 7 - Период напоминания для Наблюдателя не указан или указан не верно;<br /> 8 - Указанная должность Постановщика не найдена;<br /> 9 - Указанная должность Исполнителя не найдена;<br /> 10 - Как минимум одна из указанных должностей Соисполнителей не найдена;<br /> 11 - Как минимум одна из указанных должностей Наблюдателей не найдена;<br /> 12 - Следующие удаляемые файлы не найдены: {FileGuids};<br />
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `MicrosoftAspNetCoreMvcProblemDetails` Not Found
     */
    OpeningTemplatesStagesStageTasksUpdate: (
      templateGuid: string,
      stageGuid: string,
      stageTaskGuid: string,
      data: RetailOpeningProcessRequestsOpeningTemplateStageTaskUpdate,
      params: RequestParams = {}
    ) =>
      this.request<
        RetailOpeningProcessResponsesOpeningTemplateGetStageTask,
        CoreResponsesError | void | MicrosoftAspNetCoreMvcProblemDetails
      >({
        path: `/v1/retailopeningprocess/api/OpeningTemplates/${templateGuid}/Stages/${stageGuid}/StageTasks/${stageTaskGuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
