import { Delete, Edit } from '@mui/icons-material';
import { Chip, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';
import { UiTableCard } from 'shared/ui/table';
import { UiButtonAction } from 'shared/ui/table/ui-button-action';
import { UiTableActions } from 'shared/ui/table/ui-table-actions';

import { EVENT_RN_MAP } from './constants';
import { EventRNListActionsProps, EventRNListProps } from './type';

export const EventRNList: FC<EventRNListProps> = ({
  events,
  onDelete,
  onEdit,
}) => {
  if (events.length === 0)
    return (
      <Typography textAlign={'center'}>
        Нет запланированных мероприятий
      </Typography>
    );

  return (
    <Stack gap={1}>
      {events.map((event) => (
        <UiTableCard
          key={event.id}
          data={event}
          id={String(event.id)}
          visibleFields={EVENT_RN_MAP}
          renderActions={() => (
            <EventRNListActions
              onDelete={onDelete}
              onEdit={onEdit}
              event={event}
            />
          )}
          renderHeaderChildren={() => (
            <Chip
              label='Мероприятие'
              sx={{ ml: 1 }}
              variant='outlined'
              color='primary'
              size='small'
            />
          )}
        />
      ))}
    </Stack>
  );
};

export const EventRNListActions: FC<EventRNListActionsProps> = ({
  onDelete,
  onEdit,
  event,
}) => {
  return (
    <UiTableActions>
      <UiButtonAction
        text='Редактировать'
        onClick={() => {
          onEdit(event);
        }}
        icon={<Edit />}
        color={theme.palette.customBlue.main}
      />

      <UiButtonAction
        text='Удалить'
        onClick={() => {
          onDelete(event);
        }}
        icon={<Delete />}
        color={theme.palette.error.main}
      />
    </UiTableActions>
  );
};
