import { TextField } from '@mui/material';
import { FC } from 'react';

import { OrganizationModel } from 'shared/api/services-document-flow/types';
import { PropsAutocompliteDocumentForm } from 'shared/types/type';
import {
  CustomAutoCompliteAdaptive,
  renderOptionsCustomAutoCompliteAdaptive,
} from 'shared/ui/custom-autocomplite-adaptive';
import { useDevice } from 'shared/utils/hook-type-device';

export const UiOrganizationsAutocomplite: FC<
  PropsAutocompliteDocumentForm<OrganizationModel>
> = ({ onChange, value, options, disabled }) => {
  const [isMobile] = useDevice();

  return (
    <CustomAutoCompliteAdaptive
      propsAutocomplite={{
        sx: {
          width: '100%',
        },
        disabled,
        loading: !options,
        onChange: onChange,
        value: value || null,
        options: options,
        renderInput: (params) => (
          <TextField
            {...params}
            size='small'
            label={isMobile ? '' : 'Юр. лицо'}
            required
            placeholder='Выберите юр.лицо'
            margin={'normal'}
          />
        ),
        getOptionLabel: (option) => option.title || '-',
        renderOption: renderOptionsCustomAutoCompliteAdaptive,
        isOptionEqualToValue: (option, value) => option.id === value.id,
      }}
      sx={{
        flexBasis: '100%',
      }}
      label='Юр. лицо'
    />
  );
};
