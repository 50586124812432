import { FC } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTablePagination,
  UiTableRowWithActions,
} from 'shared/ui/table';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';

import { VisitTableProps } from './type';

export const VisitsTable: FC<VisitTableProps> = ({
  data: visits,
  count,
  isLoading,
  limit,
  page,
  fields,
  onChangeFields,
  visibleFields,
  onClick,
  renderActions,
  onChangeLimit,
  onChangePage,
}) => {
  return (
    <UiTable
      isLoading={isLoading && !visits.length}
      isUpdating={isLoading}
      skeleton={<UiTableSkeleton limit={limit} />}
      header={
        <UiTableHeader
          allCells={fields}
          viewCells={visibleFields}
          setViewCells={onChangeFields}
        />
      }
      body={
        <>
          {visits &&
            visits.length !== 0 &&
            visits.map((visit) => (
              <UiTableRowWithActions
                key={visit.id}
                hover
                onClick={() => {
                  if (onClick) {
                    onClick(visit);
                  }
                }}
                renderActions={(isOpen, setIsLoading) => {
                  return renderActions(visit, { isOpen, setIsLoading });
                }}
                sx={{ cursor: 'pointer' }}
              >
                {visibleFields.map((el) => (
                  <UiTableCell
                    key={el.field + visit.id}
                    {...el.getTableCellProps()}
                  >
                    {el.renderComponent(visit) || 'Error'}
                  </UiTableCell>
                ))}
              </UiTableRowWithActions>
            ))}
        </>
      }
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage);
          }}
          page={page}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};
