import { Skeleton, Typography } from '@mui/material';
import { ButtonWithDialogBrandFormUpdate } from 'features/apteka-legko/banners/brands/brand-form';
import { BannerBrands } from 'features/apteka-legko/banners/brands/brands-list';

import { useGetAptekaLegkoBannersBrandsQuery } from 'shared/api/service-apteka-legko-banners/rtk/aptekaLegkoBannersBrandProviderApi';

import { LayoutPage } from 'shared/ui/layouts/layout-page';

export const PageBannersMainBrand = () => {
  const { data, isLoading } = useGetAptekaLegkoBannersBrandsQuery();

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <Typography
          variant='h5'
          fontWeight={'bold'}
        >
          Баннер бренда
        </Typography>
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <BannerBrands.Container>
          {isLoading &&
            new Array(10).fill(1).map((item, index) => {
              return (
                <BannerBrands.CardSkeleton
                  key={index}
                  ActionSkeleton={
                    <Skeleton
                      variant='rounded'
                      height={30.75}
                      width={'100%'}
                    ></Skeleton>
                  }
                />
              );
            })}
          {data?.map(({ imageSite, brandBannerGuid }) => {
            return (
              <BannerBrands.Card
                key={brandBannerGuid}
                imageUrl={imageSite}
                guid={brandBannerGuid}
                Action={
                  <ButtonWithDialogBrandFormUpdate guid={brandBannerGuid} />
                }
              />
            );
          })}
        </BannerBrands.Container>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
