import { OnlineStoreResponsesFirebaseNotificationType } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';

export const getLabelNotificationTypesDepsField = (
  notificationType: OnlineStoreResponsesFirebaseNotificationType
): string => {
  switch (notificationType.notificationTypeGuid) {
    case '7e113a1f-0aea-47cc-9801-01e50e035523':
      return 'Название кнопки';
    case 'fa546997-d771-465f-8fed-b8c30ee2467d':
      return 'Промокод';

    default:
      return '-';
  }
};
