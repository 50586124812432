import { Collapse } from '@mui/material';
import { FC } from 'react';
import { useCardContext } from '../context';
import { CardCollapseProps } from '../types';

export const CardCollapse: FC<CardCollapseProps> = ({
  children,
  ...otherProps
}) => {
  const { isCollapsed, collapsedVariant } = useCardContext();

  if (!collapsedVariant) {
    return <>{children}</>;
  }

  return (
    <Collapse
      in={isCollapsed}
      {...otherProps}
    >
      {children}
    </Collapse>
  );
};
