import { createSvgIcon } from '@mui/material';

export const ImagePrevieSvg = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 42 38'
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M18.057 22.2c.825 0 1.631-.249 2.317-.713a4.213 4.213 0 001.536-1.896 4.273 4.273 0 00.237-2.44 4.241 4.241 0 00-1.142-2.162 4.156 4.156 0 00-2.136-1.156 4.124 4.124 0 00-2.41.24 4.184 4.184 0 00-1.872 1.556 4.262 4.262 0 00.52 5.332 4.15 4.15 0 002.95 1.238zm21.974 8.002L30.32 16.53a1.895 1.895 0 00-.672-.585 1.87 1.87 0 00-2.395.585l-9.366 13.181-4.967-6.99a1.895 1.895 0 00-.672-.586 1.87 1.87 0 00-2.395.585L1.97 33.817V11.603H40.03v18.599zM3.855 38a3.836 3.836 0 01-2.724-1.145A3.933 3.933 0 010 34.096V3.904a3.933 3.933 0 011.13-2.76A3.836 3.836 0 013.856 0h34.29a3.836 3.836 0 012.725 1.145A3.933 3.933 0 0142 3.904v30.192a3.933 3.933 0 01-1.13 2.76A3.836 3.836 0 0138.145 38H3.855zM6.003 6.798h10.27a.978.978 0 00.696-.292 1.003 1.003 0 000-1.41.978.978 0 00-.696-.292H6.003a.978.978 0 00-.696.292 1.003 1.003 0 000 1.41.978.978 0 00.696.292z'
      clipRule='evenodd'
    ></path>
  </svg>,
  'ImagePrevieSvg'
);
