import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

const START_YEAR = 2023;
const CURRENT_YEAR = dayjs().get('year');

export const usePlanningRNYearNavigation = () => {
  const [selectedYear, setSelectedYear] = useState(CURRENT_YEAR);

  const canNext = useMemo(() => {
    return selectedYear <= CURRENT_YEAR + 1;
  }, [selectedYear]);

  const canPrev = useMemo(() => {
    return selectedYear > START_YEAR;
  }, [selectedYear]);

  const nextYear = useCallback(() => {
    if (!canNext) return;
    setSelectedYear((prev) => prev + 1);
  }, [canNext]);

  const prevYear = useCallback(() => {
    if (!canPrev) return;
    setSelectedYear((prev) => prev - 1);
  }, [canPrev]);

  return { selectedYear, nextYear, prevYear, canNext, canPrev };
};
