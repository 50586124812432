import dayjs from 'dayjs';

import { GetTask } from 'shared/api/services/types';

import { IFormCreatedTaskUiBase } from '../type';

export const getInitFormTaskData = (data: GetTask): IFormCreatedTaskUiBase => {
  return {
    ...data,

    title: data.title || '',
    isTarget: data.isTarget || false,
    isControl: data.isControl || false,
    executer: data.executor || null,
    coExecuter: data.coExecutors,
    description: data.description || undefined,
    dateStart:
      data.dateStart && dayjs(data.dateStart).isValid()
        ? dayjs(data.dateStart)
        : null,
    dateFinish:
      data.dateFinish && dayjs(data.dateFinish).isValid()
        ? dayjs(data.dateFinish)
        : null,
    originId: data.originId || undefined,
    originEntityId: data.originEntityId || undefined,
    originAdditionallyEntityId: data.originAdditionallyEntityId || undefined,
  };
};
