import { FC } from 'react';
import { UiTable, UiTableHeader, UiTablePagination } from 'shared/ui/table';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';
import { TableRowWithSubTable } from './TableRowWithSubTable';
import { TrainingTableProps } from './types';

export const TrainingTableWithSubTable: FC<TrainingTableProps> = ({
  isLoading,
  isUpdate,
  limit,
  data,
  visibleFields,
  fields,
  onChangeFields,
  page,
  onChangePage,
  onChangeLimit,
  count,
}) => {
  return (
    <UiTable
      isUpdating={isUpdate}
      isLoading={isLoading}
      skeleton={<UiTableSkeleton limit={limit} />}
      header={
        <UiTableHeader
          viewCells={[
            {
              field: 'guid',
              title: '',
              getTableCellProps: () => {
                return {};
              },
              isShow: true,
              renderComponent: () => {
                return <></>;
              },
              order: 0,
            },
            ...visibleFields,
          ]}
          allCells={fields}
          setViewCells={onChangeFields}
        />
      }
      body={data.map((training) => (
        <TableRowWithSubTable
          key={training.guid}
          visibleFields={visibleFields}
          training={training}
        />
      ))}
      pugination={
        <UiTablePagination
          count={count}
          component='div'
          onPageChange={(_, newPage) => {
            onChangePage(newPage + 1);
          }}
          page={page - 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};
