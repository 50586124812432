import { Typography } from '@mui/material';

import { UserNoAdaptationRequiredItem } from 'shared/api/service-adaptation/normolize-data/transformResponseBackendUserNoAdaptationRequiredToViewType';

import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const USER_NO_ADAPTATION_REQUIRED_MAP: FieldMapType<UserNoAdaptationRequiredItem>[] =
  [
    {
      field: 'name',
      title: 'Имя сотрудника',
      order: 1,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => <Typography>{process.name}</Typography>,
    },
    {
      field: 'retailTitle',
      title: 'Подразделение',
      order: 2,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.retailTitle}</Typography>
      ),
    },
    {
      field: 'comment',
      title: 'Комментарий',
      order: 3,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.comment || '-'}</Typography>
      ),
    },
    {
      field: 'isVacation',
      title: 'В отпуске',
      order: 4,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.isVacation ? 'Да' : 'Нет'}</Typography>
      ),
    },
    {
      field: 'position',
      title: 'Должность',
      order: 4,
      isShow: true,
      getTableCellProps: () => ({}),
      renderComponent: (process) => (
        <Typography>{process.position.title}</Typography>
      ),
    },
  ];
