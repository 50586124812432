/**
 * Сортировка.<br />
 * Указывается одно из следующих полей:<br />
 * ProtocolTitleAsc - наименование протокола по возрастанию;<br />
 * ProtocolTitleDesc - наименование протокола по убыванию;<br />
 * ProtocolDepartmentTitleAsc - наименование сети протокола по возрастанию;<br />
 * ProtocolDepartmentTitleDesc - наименование сети протокола по убыванию;<br />
 * ProtocolRetailTitleAsc - наименование аптеки, закрепленной за протоколом по возрастанию;<br />
 * ProtocolRetailTitleDesc - наименование аптеки, закрепленной за протоколом по убыванию;<br />
 */

export enum E_SORT_FIELD {
  ProtocolTitleAsc = 'ProtocolTitleAsc',
  ProtocolTitleDesc = 'ProtocolTitleDesc',
  ProtocolDepartmentTitleAsc = 'ProtocolDepartmentTitleAsc',
  ProtocolDepartmentTitleDesc = 'ProtocolDepartmentTitleDesc',
  ProtocolRetailTitleAsc = 'ProtocolRetailTitleAsc',
  ProtocolRetailTitleDesc = 'ProtocolRetailTitleDesc',
}
