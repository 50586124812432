import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { SubTaskList } from './SubTaskList';
import { SubTaskTable } from './SubTaskTable';
import { SubTaskTableProps } from './type';

export const AdeptiveSubTaskList: FC<SubTaskTableProps> = (props) => {
  const [isMobile] = useDevice();

  if (isMobile) return <SubTaskList {...props} />;
  else return <SubTaskTable {...props} />;
};
