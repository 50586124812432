import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { FC } from 'react';

import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';

import { EditPlanningRNForm } from 'widgets/visits/forms/edit-planning-rn-form';

import { EditPlanningRNDialogProps } from './type';

export const EditPlanningRNDialog: FC<EditPlanningRNDialogProps> = ({
  isOpen,
  onClose,
  initValue,
  onSubmit,
  isLoading = false,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Редактирование' />}
    >
      <EditPlanningRNForm
        initValue={initValue}
        actions={() => [
          <Button
            key={1}
            color='customGrey'
            variant='contained'
            onClick={onClose}
          >
            Закрыть
          </Button>,
          <LoadingButton
            key={2}
            loading={isLoading}
            variant='contained'
            type='submit'
          >
            Сохранить
          </LoadingButton>,
        ]}
        onSubmit={onSubmit}
      />
    </DialogForForm>
  );
};
