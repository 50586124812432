import { Grid } from '@mui/material';
import { FC } from 'react';
import { CardFooterProps } from './types';

export const CardFooter: FC<CardFooterProps> = ({
  children,
  ...otherProps
}) => {
  return <Grid {...otherProps}>{children}</Grid>;
};
