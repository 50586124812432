import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { AdeptLayoutForUiComponent } from '../adept-layout-for-ui-component';
import { CustomDatePickerProps } from './type';

/**
 * @description
 * Для работы с react-hook-form использовать
 * AutocompliteWithController / DatePikerWithController / TextFieldWithController / SwitchWithController.
 * @see {@link /shared/fields-with-contoller/ }
 */
export const CustomDatePicker: FC<CustomDatePickerProps> = ({
  containerSx,
  ...props
}) => {
  const [isMob] = useDevice();

  return (
    <AdeptLayoutForUiComponent
      containerSx={containerSx}
      label={props.label || ''}
    >
      <DatePicker
        className='subvariant-adept'
        {...props}
        label={!isMob ? props.label : undefined}
        slotProps={{
          actionBar: {
            actions: ['clear', 'accept'],
          },
          ...props.slotProps,
        }}
      />
    </AdeptLayoutForUiComponent>
  );
};
