import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_API_URL } from 'shared/api/constants';
import { transformResponseError } from 'shared/api/libs';
import {
  FirebaseDistributionsDeleteParams,
  FirebaseDistributionsPaginationListParams,
  OnlineStoreRequestsFirebaseCreateMessage,
  OnlineStoreRequestsFirebaseEditDistribution,
  OnlineStoreRequestsFirebaseGetUserCount,
  OnlineStoreResponsesBacthOperationResult,
  OnlineStoreResponsesFirebaseCreateMessage,
  OnlineStoreResponsesFirebaseDistribution,
  OnlineStoreResponsesFirebaseNotificationType,
  OnlineStoreResponsesFirebaseTopic,
  PaginatedOnlineStoreResponsesFirebaseDistribution,
} from '../autogenerate/onlineStoreServiceApi';
import { firebaseApi } from '../firebaseApi';

export const firebaseProviderApi = createApi({
  reducerPath: 'firebaseProviderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
  }),
  tagTypes: ['DistributionsList', 'DistributionItem'],
  endpoints: (build) => ({
    /* Получает список типов уведомлений */
    getFirebaseNotificationTypes: build.query<
      OnlineStoreResponsesFirebaseNotificationType[],
      void
    >({
      queryFn: async () => {
        try {
          const response =
            await firebaseApi.firebase.FirebaseNotificationTypesList();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
    /* Получает список топиков */
    getFirebaseTopics: build.query<OnlineStoreResponsesFirebaseTopic[], void>({
      queryFn: async () => {
        try {
          const response = await firebaseApi.firebase.FirebaseTopicsList();
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
    /*   Получает список рассылок постранично*/
    getFirebaseDistributions: build.query<
      PaginatedOnlineStoreResponsesFirebaseDistribution,
      { params: FirebaseDistributionsPaginationListParams }
    >({
      queryFn: async ({ params }) => {
        try {
          const response =
            await firebaseApi.firebase.FirebaseDistributionsPaginationList(
              params
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: ['DistributionsList'],
    }),
    /* Получает рассылку */
    getFirebaseDistributionBuyGuid: build.query<
      OnlineStoreResponsesFirebaseDistribution,
      { guid: string }
    >({
      queryFn: async ({ guid }) => {
        try {
          const response =
            await firebaseApi.firebase.FirebaseDistributionsDetail(guid);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: ['DistributionsList'],
    }),
    /*  Добавляет/изменяет рассылки */
    postFirebaseDistribution: build.mutation<
      OnlineStoreResponsesFirebaseDistribution,
      { params: OnlineStoreRequestsFirebaseEditDistribution }
    >({
      queryFn: async ({ params }) => {
        try {
          const response =
            await firebaseApi.firebase.FirebaseDistributionsCreate(params);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: ['DistributionsList', 'DistributionItem'],
    }),
    /*  Удаляет выбранные рассылки */
    deleteFirebaseDistributions: build.mutation<
      OnlineStoreResponsesBacthOperationResult,
      { params: FirebaseDistributionsDeleteParams }
    >({
      queryFn: async ({ params }) => {
        try {
          const response =
            await firebaseApi.firebase.FirebaseDistributionsDelete(params);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      invalidatesTags: ['DistributionsList', 'DistributionItem'],
    }),
    /* Получает кол-во пользователей подпадающих под критерии рассылки */
    getFirebaseDistributionsUserCount: build.query<
      number,
      { params: OnlineStoreRequestsFirebaseGetUserCount }
    >({
      queryFn: async ({ params }) => {
        try {
          const response =
            await firebaseApi.firebase.FirebaseDistributionsUsercountCreate(
              params
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
    /*  Создаёт тестовое уведомление */
    postFirebaseDistributionTest: build.mutation<
      OnlineStoreResponsesFirebaseCreateMessage[],
      { params: OnlineStoreRequestsFirebaseCreateMessage }
    >({
      queryFn: async ({ params }) => {
        try {
          const response =
            await firebaseApi.firebase.FirebaseDistributionsMessageCreate(
              params
            );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
    }),
  }),
});
