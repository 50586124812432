import { FC, useState } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';
import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import { AdaptationProcessesFilterDialog } from '../forms/AdaptationProcessesFilterForm/dialogs/AdaptationProcessesFilterDialog';
import { CurrentAdaptationProcessesSearchBarProps } from './type';

export const CurrentAdaptationProcessesSearchBar: FC<
  CurrentAdaptationProcessesSearchBarProps
> = ({
  onClear,
  onSearch,
  initValue,
  filter,
  onChangeFilter,
  isLoading = false,
}) => {
  const { close, isOpen, open } = useViewModal();
  const [search, setSearch] = useState(initValue || '');

  return (
    <>
      <AdaptationProcessesFilterDialog
        isOpen={isOpen}
        onClose={close}
        onSubmit={(filter) => {
          onChangeFilter(filter);
          close();
        }}
        initValue={filter}
        isLoading={isLoading}
      />
      <UiSearchBar
        onCompliteInput={onSearch}
        changeValue={setSearch}
        textValue={search}
        onClear={() => {
          onClear();
          setSearch('');
        }}
      >
        <FilterButton onClick={open} />
      </UiSearchBar>
    </>
  );
};
