import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { AddTaskToBoardForm } from 'features/boards/forms/AddTaskToBoardForm/AddTaskToBoardForm';
import { FC } from 'react';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { AddTaskToBoardDialogProps } from './type';

export const AddTaskToBoardDialog: FC<AddTaskToBoardDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initValue,
  isLoading,
}) => {
  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Перенос на доску' />}
    >
      <AddTaskToBoardForm
        onSubmit={onSubmit}
        initForm={initValue}
        renderFormActions={() => [
          <LoadingButton
            loading={isLoading}
            variant='contained'
            type='submit'
            key={1}
          >
            Прикрепить
          </LoadingButton>,
          <Button
            variant='contained'
            color='customGrey'
            onClick={onClose}
            key={2}
          >
            Закрыть
          </Button>,
        ]}
      />
    </DialogForForm>
  );
};
