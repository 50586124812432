import { FC, PropsWithChildren, useMemo } from 'react';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import { Tables, tablesController } from 'shared/utils/controllers';
import { mergeTableSettings } from '../libs/mergeTableSettings';
import { useTablesSettingsVersionMigrationController } from './hooks/useTablesSettingsVersionMigrationController';
import { useTablesSettingVersionControl } from './hooks/useTablesSettingVersionControl';
import { TableRawSettingsContext } from './table-raw-settings-context';

export const TableRawSettingsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  // TODO: Можно убрать после следующего обновления
  // Кто не успел, тот не успел)
  useTablesSettingsVersionMigrationController();

  const { appSettings, updateAppSettings } =
    useTablesSettingVersionControl(tablesController);

  const updateSettings = (table: Tables, settings: FieldMapType<never>[]) => {
    if (!appSettings) return;

    const version = tablesController.getTableInfo(table)?.version;

    if (!version) {
      throw new Error(
        `Не удалось обновить данные таблицы ${table}, так как не удалось получить её версию`
      );
    }

    let newTableSettings;
    const newSetting = { table, version, settings };

    if (!appSettings.tableRowSettings) {
      newTableSettings = [newSetting];
    } else {
      newTableSettings = [...appSettings.tableRowSettings];

      const settingsIndex = newTableSettings.findIndex(
        (el) => el.table === table
      );

      if (settingsIndex !== -1) newTableSettings[settingsIndex] = newSetting;
      else newTableSettings.push(newSetting);
    }

    updateAppSettings({ ...appSettings, tableRowSettings: newTableSettings });
  };

  const getSettings = <T,>(table: Tables) => {
    const defaultTableInfo = tablesController.getTableInfo(table);

    if (!defaultTableInfo)
      throw new Error(`Невозможно получить данные таблицы ${table}`);

    if (!appSettings || !appSettings.tableRowSettings)
      return defaultTableInfo.map as FieldMapType<T>[];

    const info = appSettings.tableRowSettings.find(
      (setting) => setting.table === table
    );

    if (!info) return defaultTableInfo.map as FieldMapType<T>[];

    return mergeTableSettings(
      defaultTableInfo.map,
      info.settings
    ) as FieldMapType<T>[];
  };

  const allTablesInfo = useMemo(() => {
    if (!appSettings || !appSettings.tableRowSettings) return [];
    else return appSettings.tableRowSettings;
  }, [appSettings?.tableRowSettings]);

  return (
    <TableRawSettingsContext.Provider
      value={{ updateSettings, allTablesInfo, getSettings }}
    >
      {children}
    </TableRawSettingsContext.Provider>
  );
};
