import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

type BreackPointsArr<T> =
  | [T]
  | [T, T]
  | [T, T, T]
  | [T, T, T, T]
  | [T, T, T, T, T];

export const useMyAppMediaQuery = <T,>(arg: BreackPointsArr<T>) => {
  const theme = useTheme();
  const isXsUp = useMediaQuery(theme.breakpoints.up('xs'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));

  const selectArgItem: T = useMemo(() => {
    const defaultValue = arg[0] as T;

    if (isXlUp) {
      return {
        ...defaultValue,
        ...(arg[1] || {}),
        ...(arg[2] || {}),
        ...(arg[3] || {}),
        ...(arg[4] || {}),
      };
    } else if (isLgUp) {
      return {
        ...defaultValue,
        ...(arg[1] || {}),
        ...(arg[2] || {}),
        ...(arg[3] || {}),
      };
    } else if (isMdUp) {
      return {
        ...defaultValue,
        ...(arg[1] || {}),
        ...(arg[2] || {}),
      };
    } else if (isSmUp) {
      return {
        ...defaultValue,
        ...(arg[1] || {}),
      };
    } else {
      return defaultValue;
    }
  }, [isLgUp, isMdUp, isSmUp, isXlUp, isXsUp]);

  return selectArgItem;
};
