import { AdaptationsListParams } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { CONVERT_ADAPTATION_TEMPLATE_LIST_MAP } from '../convertMaps';
import { convertURLToObject } from '../convertURLToObject';
import { URLToObjConverter } from '../type';

export const convertURLToAdaptationTemplateList: URLToObjConverter<
  AdaptationsListParams
> = (searchParams) => {
  return convertURLToObject(
    searchParams,
    CONVERT_ADAPTATION_TEMPLATE_LIST_MAP
  ) as AdaptationsListParams;
};
