import { Grid, GridProps } from '@mui/material';
import { FC, ReactNode, useMemo, useState } from 'react';

import { VisitingGetListResponse } from 'shared/api/visit/types';
import { isEqDay } from 'shared/date-helper/isEqDay';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { useDevice } from 'shared/utils/hook-type-device';

import { MobileVisitCalendar } from '../calendar/mobile-visit-calendar';
import { VisitignCalendar } from '../calendar/visitign-calendar';
import { MonthSelector } from '../MonthSelector';
import { ObserverVisitsList } from '../ObserverVisitsList';
import { VisitCalendarProps } from './type';

const EMPTY: VisitingGetListResponse[] = [];

export const VisitCalendar: FC<VisitCalendarProps> = ({
  visits,
  selectedMonth,
  onChangeMonth,
  onDeleteVisit,
  onOpenVisit,
  isLoading = false,
  onCreateChecklist,
  onChangeSelectedDay,
  selectedDay,
}) => {
  const [isMob] = useDevice();

  const visitsOfSelectedDay = useMemo(() => {
    const filteredVisits = visits.filter((visit) =>
      isEqDay(selectedDay, visit.visitingDate)
    );

    return filteredVisits.length === 0 ? EMPTY : filteredVisits;
  }, [selectedDay, visits]);

  return (
    <CalendarLayout
      calendar={
        isMob ? (
          <>
            <CustomBackdrop isLoading={isLoading} />
            <MobileVisitCalendar
              initDay={selectedDay}
              visits={visits}
              onChangeDay={onChangeSelectedDay}
              onChangeMonth={onChangeMonth}
            />
          </>
        ) : (
          <>
            <MonthSelector
              onChange={onChangeMonth}
              selectedDate={selectedMonth}
            />
            <VisitignCalendar
              onChangeDay={onChangeSelectedDay}
              selectedDay={selectedDay}
              selectedMonth={selectedMonth}
              visits={visits}
              isLoading={isLoading}
            />
          </>
        )
      }
      list={
        <ObserverVisitsList
          visits={visitsOfSelectedDay}
          onOpen={onOpenVisit}
          onDelete={onDeleteVisit}
          onCreateChecklist={onCreateChecklist}
        />
      }
    />
  );
};

export interface CalendarLayoutProps {
  calendar: ReactNode;
  list: ReactNode;
  containerProps?: GridProps;
}
export const CalendarLayout: FC<CalendarLayoutProps> = ({ calendar, list }) => {
  const [refContainer, setRefContainer] = useState<HTMLDivElement | null>(null);

  return (
    <Grid
      container
      flexDirection={['column', 'column', 'row']}
      flexWrap={'nowrap'}
      justifyContent={['flex-start', 'flex-start', 'space-between']}
      height={[undefined, undefined, refContainer?.offsetHeight || '100%']}
    >
      <Grid
        flexBasis={['100%', '100%', '70%']}
        flexGrow={1}
        ref={setRefContainer}
        width={['100vw', '100vw', '100px']}
        height={'fit-content'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {calendar}
      </Grid>
      <Grid
        flexBasis={['100%', '100%', '30%']}
        height={'100%'}
      >
        {list}
      </Grid>
    </Grid>
  );
};
