import { FC, useMemo } from 'react';

import { UiMapMarker } from 'shared/ui/map';

import { MapRetailPopup } from '../RetailCard/RetailCard';
import { getPointColor } from './libs/getPointColor';
import { getPointIcon } from './libs/getPointIcon';
import { RetailMapMarkerProps } from './type';

export const RetailMapMarker: FC<RetailMapMarkerProps> = ({
  disable = false,
  retail,
  onSelect,
  isSelected = false,
  onUnSelect,
}) => {
  const location = useMemo(() => {
    return [retail.coordinateY, retail.coordinateX] as [number, number];
  }, [retail]);

  const icon = useMemo(() => {
    return getPointIcon(getPointColor(disable, isSelected));
  }, [disable, isSelected]);

  return (
    <UiMapMarker
      icon={icon}
      location={location}
      popup={
        <MapRetailPopup
          retail={retail}
          disable={disable}
          onSelect={onSelect}
          onUnSelect={onUnSelect}
          isSelected={isSelected}
        />
      }
    />
  );
};
