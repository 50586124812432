import { yupResolver } from '@hookform/resolvers/yup';
import { Warning } from '@mui/icons-material';
import { Grid, Paper, Typography } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { LoadingButton } from '@mui/lab';
import { useGetOpeningTemplatesEmployeesQuery } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { AutocompliteWithController } from 'shared/fields-with-contoller';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { useFormPreparedStagesTasksContext } from './hooks';
import {
  getOptionLabelEmployee,
  groupByPositions,
  isOptionEqualToValueGuid,
  renderOptionEmployee,
} from './libs';
import { SHEME_VALID_PREPARED_TASKS } from './scheme';
import {
  EmployeeAutocompliteProps,
  FormPreparedStageProps,
  FormPreparedStagesTaskProps,
  FormPreparedStagesTasksProps,
} from './types';

const EmployeeAutocomplite: FC<EmployeeAutocompliteProps> = ({
  name,
  employeeOptions,
  isLoading,
  namePositions,
  label,
}) => {
  const { control } = useFormPreparedStagesTasksContext();

  const { isValid } = useFormState({ control, name });

  const valueEmployee = useWatch({
    control,
    name: name,
  });
  const valuePositions = useWatch({
    control,
    name: namePositions,
  });

  const isEqualPositions =
    !valueEmployee || valueEmployee.position.guid === valuePositions.guid;

  const startAdornment = useMemo(() => {
    if (!isEqualPositions) return <Warning color='warning' />;
    if (isEqualPositions && isValid)
      return <CheckCircleOutlineIcon color='primary' />;
  }, [isEqualPositions, isValid]);

  return (
    <AutocompliteWithController
      control={control}
      name={name}
      loading={isLoading}
      options={employeeOptions}
      groupBy={groupByPositions}
      getOptionLabel={getOptionLabelEmployee}
      renderOption={renderOptionEmployee}
      isOptionEqualToValue={isOptionEqualToValueGuid}
      sx={{ flexBasis: ['100%', '49%'] }}
      textFieldProps={{
        label,
        //TODO: раскомментировать после изменения темы
        //color: !isEqualPositions ? 'warning' : 'primary',
        helperText: !isEqualPositions
          ? `Рекомендуемая должность : "${valuePositions.title}"`
          : undefined,
        InputProps: {
          startAdornment,
        },
      }}
    />
  );
};

const FormPreparedStage: FC<FormPreparedStageProps> = ({
  indexStage,
  stageNumber,
}) => {
  const { control } = useFormPreparedStagesTasksContext();

  const { fields: tasksForStage } = useFieldArray({
    control,
    name: `stages.${indexStage}.tasks`,
  });

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={2}
    >
      <Typography
        fontWeight={'bold'}
        textAlign={'center'}
      >{`${stageNumber} Этап`}</Typography>
      {tasksForStage.map(({ id }, indexTask) => {
        return (
          <FormPreparedStagesTask
            key={id}
            indexStage={indexStage}
            indexTask={indexTask}
          />
        );
      })}
    </Grid>
  );
};

const FormPreparedStagesTask: FC<FormPreparedStagesTaskProps> = ({
  indexTask,
  indexStage,
}) => {
  const { control } = useFormPreparedStagesTasksContext();

  /* coExecutors fields */
  const { fields: coExecutors } = useFieldArray({
    control,
    name: `stages.${indexStage}.tasks.${indexTask}.coExecutors`,
  });

  /* observers fields */
  const { fields: observers } = useFieldArray({
    control,
    name: `stages.${indexStage}.tasks.${indexTask}.observers`,
  });

  /* task title */
  const stageTaskTitle = useWatch({
    control,
    name: `stages.${indexStage}.tasks.${indexTask}.stageTaskTitle`,
  });

  /* employessOptions */
  const { data: employees, isFetching: isFetchingEmployees } =
    useGetOpeningTemplatesEmployeesQuery();

  const employessOptions = useMemo(() => {
    if (!employees) return EMPT_ARR;

    return [...employees].sort((a, b) =>
      a.position.title > b.position.title ? 1 : -1
    );
  }, [employees]);

  return (
    <Paper>
      <Grid
        display={'flex'}
        flexWrap={'wrap'}
        gap={2}
        padding={[0, 2]}
      >
        <Typography
          flexBasis={'100%'}
          fontWeight={'bold'}
          textAlign={['center', 'start']}
        >{`Задача: ${stageTaskTitle}`}</Typography>
        <EmployeeAutocomplite
          name={`stages.${indexStage}.tasks.${indexTask}.creator.employee`}
          namePositions={`stages.${indexStage}.tasks.${indexTask}.creator.requeredPosition`}
          isLoading={isFetchingEmployees}
          employeeOptions={employessOptions}
          label='Постановщик'
        />
        <EmployeeAutocomplite
          name={`stages.${indexStage}.tasks.${indexTask}.executor.employee`}
          namePositions={`stages.${indexStage}.tasks.${indexTask}.executor.requeredPosition`}
          isLoading={isFetchingEmployees}
          employeeOptions={employessOptions}
          label='Исполнитель'
        />

        {coExecutors.map((coExecutor, index) => (
          <EmployeeAutocomplite
            key={coExecutor.id}
            name={`stages.${indexStage}.tasks.${indexTask}.coExecutors.${index}.employee`}
            namePositions={`stages.${indexStage}.tasks.${indexTask}.coExecutors.${index}.requeredPosition`}
            isLoading={isFetchingEmployees}
            employeeOptions={employessOptions}
            label='Соисполнитель'
          />
        ))}
        {observers.map((coExecutor, index) => (
          <EmployeeAutocomplite
            key={coExecutor.id}
            name={`stages.${indexStage}.tasks.${indexTask}.observers.${index}.employee`}
            namePositions={`stages.${indexStage}.tasks.${indexTask}.observers.${index}.requeredPosition`}
            isLoading={isFetchingEmployees}
            employeeOptions={employessOptions}
            label='Наблюдатель'
          />
        ))}
      </Grid>
    </Paper>
  );
};

export const FormPreparedStagesTasks: FC<FormPreparedStagesTasksProps> = ({
  initialValue,
  onSubmite,
  isLoadingSubmite,
}) => {
  const methods = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(SHEME_VALID_PREPARED_TASKS),
    reValidateMode: 'onChange',
    mode: 'all',
  });
  const { isValid } = useFormState({ control: methods.control });
  const { fields: stages } = useFieldArray({
    control: methods.control,
    name: 'stages',
  });

  useEffect(() => {
    methods.trigger();
  }, []);

  return (
    <FormProvider {...methods}>
      <Grid
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        component='form'
        onSubmit={methods.handleSubmit(onSubmite)}
      >
        {stages.map((stage, index) => {
          return (
            <FormPreparedStage
              key={stage.id}
              indexStage={index}
              stageNumber={stage.stageNumber}
            />
          );
        })}
        <LoadingButton
          loading={isLoadingSubmite}
          disabled={isLoadingSubmite || !isValid}
          type='submit'
          variant={'contained'}
        >
          Запустить процесс
        </LoadingButton>
      </Grid>
    </FormProvider>
  );
};
