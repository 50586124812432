import { useURLBoard } from 'pages/boards/hooks/useURLBoard';
import { useMemo } from 'react';
import { useGetColumnsTasksQuery } from 'shared/api/services/rtk/boardApi';

export const useGetColumnTasks = (boardGuid: string, columnGuid: string) => {
  const [filter] = useURLBoard();
  const { data, isLoading, isFetching } = useGetColumnsTasksQuery({
    boardGuid,
    qParams: filter,
  });

  const tasks = useMemo(() => {
    const tasks = data?.filter((task) => task.boardColumnGuid === columnGuid);

    if (!tasks) return [];

    return tasks.sort(
      (a, b) => a.boardColumnOrderNumber - b.boardColumnOrderNumber
    );
  }, [data]);

  return { tasks, isLoading, isFetching };
};
