export const concatArray = <T>(
  array1: T[] | undefined | null,
  array2: T[] | undefined | null
) => {
  if (!!array1 && !!array2) {
    return [...array1, ...array2];
  } else if (!array1 && !!array2) {
    return [...array2];
  } else if (!!array1 && !array2) {
    return [...array1];
  } else return null;
};
