import { Box, Grid, Typography } from '@mui/material';

import { LogoSvg } from 'shared/svg/LogoSvg';
import { theme } from 'shared/theme/theme';

export const Logo = () => {
  return (
    <Grid
      item
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Typography
        fontSize={[14, 20]}
        fontWeight={700}
        color={theme.palette.primary.main}
      >
        гармония
      </Typography>
      <Box sx={{ width: [35, 46], height: [35, 46], margin: '0px 10px' }}>
        <LogoSvg />
      </Box>
      <Typography
        fontSize={[14, 20]}
        fontWeight={700}
        color={theme.palette.primary.main}
      >
        здоровья
      </Typography>
    </Grid>
  );
};
