import { array, object, ObjectSchema } from 'yup';

import { SCHEMA } from '../../../../shared/yup-schema/YUP_SCHEMA';
import { IFormCreatedTaskUiBase } from '../type';

export const SCHEMA_TASK: ObjectSchema<IFormCreatedTaskUiBase> = object().shape(
  {
    boardColumnGuid: SCHEMA.STRING,
    parentTaskGuid: SCHEMA.STRING,
    guid: SCHEMA.STRING,
    title: SCHEMA.STRING_REQUIRED,
    executer: SCHEMA.EMPLOYEE_REQUIRED,
    coExecuter: array(SCHEMA.EMPLOYEE).nullable(),
    observers: array(SCHEMA.EMPLOYEE).nullable(),
    dateStart: SCHEMA.DATEJS.nullable(),
    dateFinish: SCHEMA.builderDateEnd('dateStart').nullable(),
    description: SCHEMA.STRING,
    units: SCHEMA.NUMBER_POSITIVE.nullable(),
    isTarget: SCHEMA.BOOLEAN,
    isControl: SCHEMA.BOOLEAN,
    originAdditionallyEntityId: SCHEMA.NUMBER_POSITIVE,
    originEntityId: SCHEMA.NUMBER_POSITIVE,
    originId: SCHEMA.NUMBER_POSITIVE,
  }
);
