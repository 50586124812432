import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { TaskList } from './TaskList';
import { TaskTable } from './TaskTable';
import { TaskTableProps } from './type';

export const AdeptiveTaskTable: FC<TaskTableProps> = (props) => {
  const [isMobile] = useDevice();

  if (isMobile) return <TaskList {...props} />;
  else return <TaskTable {...props} />;
};
