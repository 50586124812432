// TODO: Решить проблему с !
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// TODO: Решить проблему с emplty functions
/* eslint-disable @typescript-eslint/no-empty-function */

import { Divider, Grid, Typography } from '@mui/material';
import { CreateConferenceEventDialog } from 'features/visit/create-conference-event-dialog';
import { CreateOtherEventDialog } from 'features/visit/create-other-event-dialog/CreateOtherEventDialog';
import { CreatePlanningRNDialog } from 'features/visit/create-planningRN-dialog/CreatePlanningRNDialog';
import { CreateVisitByPlanningRNDialog } from 'features/visit/create-visit-by-planning-rn-dialog';
import { EditConferenceEventDialog } from 'features/visit/edit-conference-event-dialog';
import { EditOtherEventDialog } from 'features/visit/edit-other-event-dialog';
import { EditPlanningRNDialog } from 'features/visit/edit-planning-rn-dialog';
import { useEventsOnPlanningRNPage } from 'features/visit/hooks/useEventsOnPlanningRNPage';
import { usePlanningOnPlanningRNPage } from 'features/visit/hooks/usePlanningOnPlanningRNPage';
import { usePlanningRNYearNavigation } from 'features/visit/hooks/usePlanningRNYearNavigation';
import { PlanningRNSearchBar } from 'features/visit/planning-rn-search-bar';
import { PlanningRNTable } from 'features/visit/planning-rn-table/PlanningRNTable';
import { ReportPlanningRNTable } from 'features/visit/report-planning-rn-table';
import { memo, useCallback, useMemo, useState } from 'react';

import {
  EventGetListResponse,
  VisitingPlanningGetListResponse,
  VisitingPlanningUpdateRequest,
} from 'shared/api/visit/types';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { getIdsFromArray } from 'shared/tarnsform-helper/getIdsFromArray';
import { ArrowSelector } from 'shared/ui/arrow-selector';
import { CustomBackdrop } from 'shared/ui/custom-backdrop';
import { useDevice } from 'shared/utils/hook-type-device';

import { useCreateChecklist } from 'entities/visit/checkList/hooks/useCreateChecklist';
import { useGetDepartments } from 'entities/visit/options/useGetDepartments';
import { useGetPlanningVisitors } from 'entities/visit/options/useGetPlanningVisitors';
import { useCreatePlanningRN } from 'entities/visit/visit/hooks/useCreatePlanningRN';
import { useCreateVisit } from 'entities/visit/visit/hooks/useCreateVisit';
import { useCreateVisitsEvent } from 'entities/visit/visit/hooks/useCreateVisitsEvent';
import { useDeletePlanningRN } from 'entities/visit/visit/hooks/useDeletePlanningRN';
import { useDeleteVisitEvent } from 'entities/visit/visit/hooks/useDeleteVisitEvent';
import { useEditPlanningRN } from 'entities/visit/visit/hooks/useEditPlanningRN';
import { useEditVisitEvent } from 'entities/visit/visit/hooks/useEditVisitEvent';
import { useGetVisitRegions } from 'entities/visit/visit/hooks/useGetRegions';
import { useVisitNavigation } from 'entities/visit/visit/hooks/useVisitNavigation';

import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { EventRNList } from 'widgets/visits/event-rn-list/EventRNList';
import { CreateConferenceEventFormFields } from 'widgets/visits/forms/create-conference-event-form';
import { convertCreateConferenceEventFormToFetch } from 'widgets/visits/forms/create-conference-event-form/libs/convertCreateConferenceEventFormToFetch';
import { convertEventToConferencEventForm } from 'widgets/visits/forms/create-conference-event-form/libs/convertEventToConferencEventForm';
import { CreateOtherEventFormFields } from 'widgets/visits/forms/create-other-event-form';
import { convertCreateOtherEventFormToFetch } from 'widgets/visits/forms/create-other-event-form/libs/convertCreateOtherEventFormToFetch';
import { convertEventToOtherEventForm } from 'widgets/visits/forms/create-other-event-form/libs/convertEventToOtherEventForm';
import {
  convertCreatePlanningFormDataToFetch,
  CreatePlanningRNFormFields,
} from 'widgets/visits/forms/create-planning-rn-form';
import { convertCreateVisitRNToFetch } from 'widgets/visits/forms/create-visit-by-palnning-rn-form/libs/convertCreateVisitRNToFetch';
import { CreateVisitByPlanningRNFormFields } from 'widgets/visits/forms/create-visit-by-palnning-rn-form/type';
import {
  convertEditPlanningRNFormDataToFetch,
  EditPlanningRNFormFields,
} from 'widgets/visits/forms/edit-planning-rn-form';
import { PlanningRNList } from 'widgets/visits/planning-rn-list/PlanningRNList';

export const PlaningRNPage = () => {
  const { navigateToCheckList } = useVisitNavigation();
  const [isMobile] = useDevice();

  /* Search */
  const [search, setSearch] = useState('');
  /* ===== */

  /* Year navigation */
  const { nextYear, prevYear, selectedYear, canNext, canPrev } =
    usePlanningRNYearNavigation();
  /* ====== */

  const {
    events,
    isLoading: isLoadingEvents,
    addToEvents,
    changeEvent,
    removeFromEvents,
  } = useEventsOnPlanningRNPage(selectedYear, search);

  const { info, isLoading, addToInfo, removeFromInfo, changeInfo } =
    usePlanningOnPlanningRNPage(selectedYear, search);

  const { deletePlanning, isLoading: isDeleting } =
    useDeletePlanningRN(removeFromInfo);

  /* Options */
  const { regions, isLoading: isLoadingRegions } = useGetVisitRegions();
  const { departments, isLoading: isLoadingDep } = useGetDepartments();
  const { visitors, isLoading: isLoadingVisitors } = useGetPlanningVisitors();
  /* ===== */

  /* Create form */
  const { createPlanning, isLoading: isCreatingPlanning } =
    useCreatePlanningRN(addToInfo);

  const {
    close,
    isOpen,
    open,
    initValue: initCreateForm,
  } = useViewModalWithInitFields<Partial<CreatePlanningRNFormFields>>();

  const handleSubmit = useCallback((form: CreatePlanningRNFormFields) => {
    const prepData = convertCreatePlanningFormDataToFetch(form);

    createPlanning(prepData)
      .then(close)
      .catch(() => {});
  }, []);

  /* ===== */

  /* Edit form */
  const { isLoading: isLoadingEditPlanning, edit } =
    useEditPlanningRN(changeInfo);

  const {
    close: closeEdit,
    isOpen: isOpenEdit,
    open: openEdit,
    initValue: initEditForm,
  } = useViewModalWithInitFields<EditPlanningRNFormFields & { id: number }>();

  const handleEditSubmit = (form: EditPlanningRNFormFields) => {
    if (!initEditForm) return;

    const convertedData = convertEditPlanningRNFormDataToFetch(form);

    edit(initEditForm.id, convertedData)
      .then(closeEdit)
      .catch(() => {});
  };
  /* ===== */

  /* Create visiting form */
  const { createVisit, isLoading: isCreatingVisit } = useCreateVisit();
  const { createChecklist, isLoading: isCreatingChecklist } =
    useCreateChecklist();

  const {
    close: closeCreateVisiting,
    isOpen: isOpenCreateVisiting,
    open: openCreateVisiting,
    initValue: selectedPlanningInfo,
  } = useViewModalWithInitFields<VisitingPlanningGetListResponse>();

  const handleCreate = (form: CreateVisitByPlanningRNFormFields) => {
    const dataToFetch = convertCreateVisitRNToFetch(form);

    createVisit(dataToFetch)
      .then((visit) => {
        if (visit) {
          createChecklist(visit[0].id)
            .then((checklist) => {
              if (checklist) {
                if (checklist.isCompetitorComparison)
                  navigateToCheckList(checklist.id, 2);
                else navigateToCheckList(checklist.id, 4);
              }
            })
            .catch(() => {});
        }
      })
      .catch(() => {});
  };
  /* ===== */

  /* Events */
  const { deleteEvent, isLoading: isDeletingEvents } =
    useDeleteVisitEvent(removeFromEvents);

  /* Create Events */
  const { createEvent, isLoading: isCreatingEvent } =
    useCreateVisitsEvent(addToEvents);

  /* Create conference event form */
  const {
    close: closeCreateEvent,
    initValue: initCreateConfEvent,
    isOpen: isOpenCreateEvent,
    open: openCreateEvent,
  } = useViewModalWithInitFields<Partial<CreateConferenceEventFormFields>>();

  const handleCreateConfEventSubmit = (
    form: CreateConferenceEventFormFields
  ) => {
    const fetchData = convertCreateConferenceEventFormToFetch(form);

    createEvent(fetchData)
      .then(closeCreateEvent)
      .catch(() => {});
  };

  /* Create other event */
  const {
    close: closeCreateOtherEvent,
    initValue: initCreateOtherEvent,
    isOpen: isOpenCreateOtherEvent,
    open: openCreateOtherEvent,
  } = useViewModalWithInitFields<Partial<CreateOtherEventFormFields>>();

  const handleCreateOtherEventSubimt = (form: CreateOtherEventFormFields) => {
    const fetchData = convertCreateOtherEventFormToFetch(form);

    createEvent(fetchData)
      .then(closeCreateOtherEvent)
      .catch(() => {});
  };

  /* Edit event */
  const { edit: editEvent, isLoading: isLoadingEditEvent } =
    useEditVisitEvent(changeEvent);

  /* Edit other event */
  const {
    close: closeEditOtherEvent,
    initValue: initEditOtherEvent,
    isOpen: isOpenEditOtherEvent,
    open: openEditOtherEvent,
  } = useViewModalWithInitFields<EventGetListResponse>();

  const handleEditOtherEvent = (form: CreateOtherEventFormFields) => {
    if (initEditOtherEvent) {
      const fetchData = convertCreateOtherEventFormToFetch(form);

      editEvent(initEditOtherEvent.id, fetchData)
        .then(closeEditOtherEvent)
        .catch(() => {});
    }
  };

  /* Edit conferenc event */
  const {
    close: closeEditConfEvent,
    initValue: initEditConfEvent,
    isOpen: isOpenEditConfEvent,
    open: openEditConfEvent,
  } = useViewModalWithInitFields<EventGetListResponse>();

  const handleEditConfEvent = (form: CreateConferenceEventFormFields) => {
    if (initEditConfEvent) {
      const fetchData = convertCreateConferenceEventFormToFetch(form);

      editEvent(initEditConfEvent.id, fetchData)
        .then(closeEditConfEvent)
        .catch(() => {});
    }
  };

  /* ===== */

  const editEventHandler = useCallback((event: EventGetListResponse) => {
    if (event.isConference) openEditConfEvent(event);
    else openEditOtherEvent(event);
  }, []);

  const deleteEventHandler = useCallback((event: EventGetListResponse) => {
    deleteEvent(event.id);
  }, []);

  const createOtherEventHandler = useCallback(
    (initData: Partial<CreateOtherEventFormFields>) => {
      openCreateOtherEvent(initData);
    },
    []
  );

  const createConverencEventHandler = useCallback(
    (initData: Partial<CreateConferenceEventFormFields>) => {
      openCreateEvent(initData);
    },
    []
  );

  const createPlanningHandler = useCallback(
    (initData: Partial<CreatePlanningRNFormFields>) => {
      open(initData);
    },
    []
  );

  const createVisitHandler = useCallback(
    (initData: VisitingPlanningGetListResponse) => {
      openCreateVisiting(initData);
    },
    []
  );

  const deletePlanningHandler = useCallback(
    (planning: VisitingPlanningGetListResponse) => {
      deletePlanning(planning.id);
    },
    []
  );

  const editPlanningHandler = useCallback(
    (
      initData: VisitingPlanningUpdateRequest & {
        id: number;
      }
    ) => {
      openEdit(initData);
    },
    []
  );

  const vivsitorForReport = useMemo(() => {
    return visitors.filter((el) => el.employeePosition !== 'ДР');
  }, [visitors]);

  const editPlanningRNListHandler = useCallback(
    (planning: VisitingPlanningGetListResponse) => {
      openEdit({
        id: planning.id,
        homiesIds: getIdsFromArray(planning.members),
        departmentId: planning.department.id,
        periodFinish: planning.periodFinish,
        periodStart: planning.periodStart,
        visitorId: planning.visitor.id,
      });
    },
    []
  );

  if (isLoadingDep || isLoadingRegions || isLoadingVisitors) {
    return <CustomBackdrop isLoading />;
  }

  return (
    <>
      <CustomBackdrop
        isLoading={
          isLoading || isLoadingEvents || isDeleting || isDeletingEvents
        }
      />
      <CreatePlanningRNDialog
        isOpen={isOpen}
        onClose={close}
        onSubmit={handleSubmit}
        initValue={initCreateForm}
        isLoading={isCreatingPlanning}
        blocked={info.map((el) => ({
          departmentId: el.department.id,
          month: el.month,
        }))}
      />

      <EditPlanningRNDialog
        initValue={initEditForm}
        isOpen={isOpenEdit}
        onClose={closeEdit}
        onSubmit={handleEditSubmit}
        isLoading={isLoadingEditPlanning}
      />

      <CreateVisitByPlanningRNDialog
        isOpen={isOpenCreateVisiting}
        onClose={closeCreateVisiting}
        onSubmit={handleCreate}
        planningInfo={selectedPlanningInfo!}
        isLoading={isCreatingVisit || isCreatingChecklist}
      />

      {/* Create events */}
      <CreateConferenceEventDialog
        isOpen={isOpenCreateEvent}
        onClose={closeCreateEvent}
        onSubmit={handleCreateConfEventSubmit}
        isLoading={isCreatingEvent}
        initForm={initCreateConfEvent}
      />

      <CreateOtherEventDialog
        isOpen={isOpenCreateOtherEvent}
        onClose={closeCreateOtherEvent}
        initValue={initCreateOtherEvent}
        onSubmit={handleCreateOtherEventSubimt}
        isLoading={isCreatingEvent}
      />
      {/* ===== */}

      {/* Edit other event */}
      <EditOtherEventDialog
        isOpen={isOpenEditOtherEvent}
        onClose={closeEditOtherEvent}
        initValue={convertEventToOtherEventForm(initEditOtherEvent)}
        onSubmit={handleEditOtherEvent}
        isLoading={isLoadingEditEvent}
      />

      <EditConferenceEventDialog
        isOpen={isOpenEditConfEvent}
        onClose={closeEditConfEvent}
        onSubmit={handleEditConfEvent}
        isLoading={isLoadingEditEvent}
        initForm={convertEventToConferencEventForm(initEditConfEvent)}
      />
      {/* ===== */}

      <LayoutPage.Page>
        <LayoutPage.Bar>
          <PlanningRNSearchBar
            onCreateConferenceEvent={openCreateEvent}
            onCreateOtherEvent={openCreateOtherEvent}
            onCreatePlanning={open}
            onSearch={setSearch}
          />
        </LayoutPage.Bar>

        <LayoutPage.Content>
          <Grid
            container
            flexDirection={'column'}
            gap={2}
            mb={10}
          >
            <ArrowSelector
              disableBack={!canPrev}
              disableNext={!canNext}
              onBack={prevYear}
              onNext={nextYear}
              containerProps={{ justifyContent: ['center', 'start'] }}
            >
              <Typography
                fontWeight={'bold'}
                fontSize={24}
              >
                {selectedYear}
              </Typography>
            </ArrowSelector>

            {isMobile ? (
              <Grid
                container
                flexDirection={'column'}
                gap={4}
              >
                <Grid
                  container
                  flexDirection={'column'}
                  gap={1}
                >
                  <Typography
                    textAlign={'center'}
                    fontWeight={'bold'}
                  >
                    Запланированные посещения
                  </Typography>
                  <Divider />
                  <MemoizedPlanningRNList
                    plannings={info}
                    onCreateVisit={createVisitHandler}
                    onDelete={deletePlanningHandler}
                    onEdit={editPlanningRNListHandler}
                  />
                </Grid>

                <Grid
                  container
                  flexDirection={'column'}
                  gap={1}
                >
                  <Typography
                    textAlign={'center'}
                    fontWeight={'bold'}
                  >
                    Запланированные мероприятия
                  </Typography>
                  <Divider />
                  <EventRNList
                    events={events}
                    onDelete={(event) => {
                      deleteEvent(event.id);
                    }}
                    onEdit={(event) => {
                      if (event.isConference) openEditConfEvent(event);
                      else openEditOtherEvent(event);
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <MemoizedPlanningRNTable
                  departments={departments}
                  plannings={info}
                  visitors={visitors}
                  selectedYear={selectedYear}
                  events={events}
                  regions={regions}
                  onDeleteEvent={deleteEventHandler}
                  onEditEvent={editEventHandler}
                  onCreateOtherEvent={createOtherEventHandler}
                  onCreateConverenceEvent={createConverencEventHandler}
                  onCreatePlanning={createPlanningHandler}
                  onCreateVisit={createVisitHandler}
                  onDeletePlanning={deletePlanningHandler}
                  onEditPlanning={editPlanningHandler}
                />

                <MemoizedReportPlanningRNTable
                  info={info}
                  visitors={vivsitorForReport}
                />
              </>
            )}
          </Grid>
        </LayoutPage.Content>
      </LayoutPage.Page>
    </>
  );
};

const MemoizedPlanningRNTable = memo(PlanningRNTable);
const MemoizedReportPlanningRNTable = memo(ReportPlanningRNTable);
const MemoizedPlanningRNList = memo(PlanningRNList);
