import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useGetOptionsOrganizationsForAgreeOrganizationsActionQuery } from 'shared/api/services-document-flow/rtk/documentFlowProviderApi';
import { OrganizationModel } from 'shared/api/services-document-flow/types';
import { AutocompliteWithController } from 'shared/fields-with-contoller';
import { ContainerActionsForm } from 'shared/ui/container-actions-form';
import { DialogForForm } from 'shared/ui/form';
import { DialogTitleForForm } from 'shared/ui/form/dialog-for-form/components';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { mixed, object } from 'yup';
import { FormAgreeOrganizationValue } from './types';

const DEFAULT_VALUE: FormAgreeOrganizationValue = { organization: null };

export const DialogFormAgreeOrganization: FC<{
  isOpen: boolean;
  onClose: () => void;
  documentId: number;
  initialValue?: FormAgreeOrganizationValue;
  loading?: boolean; //идет загрузка initialValue или Отправка формы?
  onSubmit?: (form: FormAgreeOrganizationValue) => void | unknown;
}> = ({
  isOpen,
  onClose,
  documentId,
  initialValue,
  loading,
  onSubmit = (form) => {
    console.log(form);
  },
}) => {
  const { control, handleSubmit, reset } = useForm<FormAgreeOrganizationValue>({
    defaultValues: initialValue || DEFAULT_VALUE,
    resolver: yupResolver(
      object().shape({
        organization: mixed<OrganizationModel>()
          .test('organization', 'Обязательное поле', (option) =>
            Boolean(option)
          )
          .required('Обязательное поле')
          .nullable(),
      })
    ),
  });

  const { data: orgOptions, isLoading: isLoadingOrgOptions } =
    useGetOptionsOrganizationsForAgreeOrganizationsActionQuery(
      { documentId },
      { skip: !isOpen }
    );

  useEffect(() => {
    if (!isOpen) {
      reset(initialValue || DEFAULT_VALUE);
    }
  }, [isOpen]);

  useEffect(() => {
    reset(initialValue);
  }, [initialValue]);

  return (
    <DialogForForm
      open={isOpen}
      onClose={onClose}
      head={<DialogTitleForForm title='Согласование Юр.лица' />}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <AutocompliteWithController
        options={orgOptions || EMPT_ARR}
        control={control}
        loading={isLoadingOrgOptions || loading}
        getOptionLabel={(option) => option.title || 'undefine'}
        renderOption={(props, option) => (
          <Typography
            {...props}
            key={option.id}
          >
            {option.title}
          </Typography>
        )}
        isOptionEqualToValue={(value, option) => value.id === option.id}
        name={'organization'}
        textFieldProps={{
          label: 'Юр.лицо',
        }}
      />
      <ContainerActionsForm>
        <LoadingButton
          loading={isLoadingOrgOptions || loading}
          disabled={isLoadingOrgOptions || loading}
          variant='contained'
          type='submit'
        >
          Согласовать
        </LoadingButton>
        <Button
          variant='contained'
          color='customGrey'
          onClick={onClose}
        >
          Закрыть
        </Button>
      </ContainerActionsForm>
    </DialogForForm>
  );
};
