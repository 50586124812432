import { FC, memo } from 'react';
import { useDevice } from 'shared/utils/hook-type-device';
import { UserNoAdaptationProcessType } from './type';
import { UserNoAdaptationRequiredList } from './UserNoAdaptationRequiredList';
import { UserNoAdaptationRequiredTable } from './UserNoAdaptationRequiredTable';

export const UserNoAdaptationRequiredAdaptiveTable: FC<UserNoAdaptationProcessType> =
  memo((props) => {
    const [isMobile] = useDevice();

    if (isMobile) return <UserNoAdaptationRequiredList {...props} />;
    else return <UserNoAdaptationRequiredTable {...props} />;
  });
