import { GetListEmployee } from 'shared/api/services/types';

export const DEFAULT_OPTIONS_AUTOCOMPLETE = {
  getOptionLabel: (option: GetListEmployee) => {
    if (option.name && option.position) {
      return option.name + ', ' + option.position.title;
    }
    if (option.name) return option.name;

    return 'JonDoe';
  },
  isOptionEqualToValue: (option: GetListEmployee, value: GetListEmployee) =>
    option.guid === value.guid,
};
