import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_API_URL } from 'shared/api/constants';

export const taskProviderApi = createApi({
  reducerPath: 'taskProviderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
  }),
  tagTypes: [
    'board/boardList',
    'board/boardColumns',
    'board/boardTask',
    'task/task',
    'task/taskList',
    'task/file',
    'task/comment',
  ],
  endpoints: (build) => ({}),
});
