import { yupResolver } from '@hookform/resolvers/yup';
import { AttachFile } from '@mui/icons-material';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useGetAdaptationEmployeePositionsQuery } from 'shared/api/service-adaptation/rtk/adaptationStageTasksProviderApi';
import { useDownloadFileFromServiceFile } from 'shared/api/service-files/rtk/hooks/use-download-file-from-service-file';
import {
  AutocompliteWithController,
  TextFieldWithController,
} from 'shared/fields-with-contoller';
import { BaseFormProps } from 'shared/form/type';
import { FileListItem } from 'shared/ui/file-list-item';
import { FormLayout } from 'shared/ui/form';
import { UploadButton } from 'shared/ui/upload-button/UploadButton';
import { checkIsNotEmptyArray } from 'shared/utils/checkIsNotEmptyArray';
import { fileListToArrayBase64 } from 'shared/utils/files';
import { getTitleFromObject } from 'shared/utils/getTitleFromObject';
import { RemindFormRow } from './components/RemindFormRow/RemindFormRow';
import { INIT_VALUE } from './constants';
import { transformRemind } from './libs/transformRemind';
import { AdaptationTemplateTaskFormFields } from './type';
import { SCHEMA_TEMPLATE_TASK } from './validation';

export const AdaptationTemplateTaskForm: FC<
  BaseFormProps<AdaptationTemplateTaskFormFields>
> = ({ initValue, onSubmit, renderFormActions, disabled = false }) => {
  const { handleSubmit, control } = useForm<AdaptationTemplateTaskFormFields>({
    defaultValues: { ...INIT_VALUE, ...transformRemind(initValue) },
    resolver: yupResolver(SCHEMA_TEMPLATE_TASK),
  });

  const { data: positions, isLoading: isLoadingPositions } =
    useGetAdaptationEmployeePositionsQuery();

  const [downloadFile] = useDownloadFileFromServiceFile();

  const {
    fields: initialFiles,
    remove: removeFilesFromInitial,
    append: returFileToInitialFiles,
  } = useFieldArray<AdaptationTemplateTaskFormFields, 'files'>({
    name: 'files',
    control,
  });

  const {
    fields: deletingFiles,
    append: appendFilesDeleteFromBackend,
    remove: removeFromDeletingFiles,
  } = useFieldArray<AdaptationTemplateTaskFormFields, 'deletingFiles'>({
    name: 'deletingFiles',
    control,
  });

  const {
    fields: addingFiles,
    remove: removeFromAddingFiles,
    append: appendAddingFiles,
  } = useFieldArray<AdaptationTemplateTaskFormFields, 'addingFiles'>({
    name: 'addingFiles',
    control,
  });

  return (
    <FormLayout
      actions={renderFormActions(handleSubmit)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextFieldWithController
        control={control}
        name='title'
        label='Наименование задачи'
        placeholder='Введите наименование задачи'
        required
        multiline
        maxRows={4}
        minRows={1}
        disabled={disabled}
      />

      <TextFieldWithController
        control={control}
        name='description'
        label='Описание задачи'
        placeholder='Введите описание задачи'
        multiline
        maxRows={4}
        minRows={3}
        disabled={disabled}
      />

      <TextFieldWithController
        control={control}
        name='startDaysCount'
        disabled={disabled}
        required
        label={'Дата начала (кол-во дней)'}
      />
      <TextFieldWithController
        control={control}
        name='finishDaysCount'
        disabled={disabled}
        required
        label={'Дата завершения (кол-во дней)'}
      />

      <AutocompliteWithController
        control={control}
        name='creatorPosition'
        options={positions || []}
        loading={isLoadingPositions}
        textFieldProps={{ label: 'Постановщик (должность)', disabled }}
        getOptionLabel={getTitleFromObject}
        isOptionEqualToValue={(val, eq) => val.guid === eq.guid}
      />
      <AutocompliteWithController
        control={control}
        name='executorPosition'
        options={positions || []}
        loading={isLoadingPositions}
        textFieldProps={{
          label: 'Исполнитель (должность)',
          required: true,
          disabled,
        }}
        getOptionLabel={getTitleFromObject}
        isOptionEqualToValue={(val, eq) => val.guid === eq.guid}
      />
      <AutocompliteWithController
        control={control}
        name='coExecutorsPositions'
        options={positions || []}
        loading={isLoadingPositions}
        multiple
        textFieldProps={{ label: 'Соисполнители (должности)', disabled }}
        getOptionLabel={getTitleFromObject}
        isOptionEqualToValue={(val, eq) => val.guid === eq.guid}
      />
      <AutocompliteWithController
        control={control}
        name='observersPositions'
        options={positions || []}
        loading={isLoadingPositions}
        multiple
        textFieldProps={{ label: 'Наблюдатели (должности)', disabled }}
        getOptionLabel={getTitleFromObject}
        isOptionEqualToValue={(val, eq) => val.guid === eq.guid}
      />

      <Divider />
      <RemindFormRow
        control={control}
        fieldName='reminderPeriodCreator'
        switchName='isReminderCreator'
        fieldLabel='Дней до даты завершения'
        switchLabel='Напомнить постановщику'
        disabled={disabled}
      />
      <Divider />
      <RemindFormRow
        control={control}
        fieldName='reminderPeriodExecutor'
        switchName='isReminderExecutor'
        fieldLabel='Дней до даты завершения'
        switchLabel='Напомнить исполнителю'
        disabled={disabled}
      />
      <Divider />
      <RemindFormRow
        control={control}
        fieldName='reminderPeriodCoExecutor'
        switchName='isReminderCoExecutor'
        fieldLabel='Дней до даты завершения'
        switchLabel='Напомнить соисполнителям'
        disabled={disabled}
      />
      <Divider />
      <RemindFormRow
        control={control}
        fieldName='reminderPeriodObserver'
        switchName='isReminderObserver'
        fieldLabel='Дней до даты завершения'
        switchLabel='Напомнить наблюдателям'
        disabled={disabled}
      />
      <Divider />

      <Controller
        control={control}
        name='addingFiles'
        render={() => {
          return (
            <UploadButton
              variant='outlined'
              startIcon={<AttachFile />}
              id='upload-file-for-template-task'
              disabled={disabled}
              onChangeInput={async (e) => {
                const fileList = e.target.files;

                if (fileList) {
                  const newFiles = await fileListToArrayBase64(fileList);

                  appendAddingFiles(newFiles);
                }
              }}
            >
              Прикрепить
            </UploadButton>
          );
        }}
      />

      {checkIsNotEmptyArray(initialFiles) && (
        <Grid px={[1, 0]}>
          <Typography color={'GrayText'}>Загруженные</Typography>
          <Divider sx={{ my: 1 }} />
          <Stack gap={1}>
            {initialFiles.map((file, index) => {
              const { title, guid, path } = file;

              return (
                <FileListItem
                  key={guid}
                  fileName={title || 'not_file_name'}
                  disableDelete={disabled}
                  onDelete={() => {
                    appendFilesDeleteFromBackend(file);
                    removeFilesFromInitial(index);
                  }}
                  onDownload={async () => {
                    downloadFile({ fileName: title, path });
                  }}
                />
              );
            })}
          </Stack>
        </Grid>
      )}
      {checkIsNotEmptyArray(deletingFiles) && (
        <Grid px={[1, 0]}>
          <Typography color={'GrayText'}>Загруженные</Typography>
          <Divider sx={{ my: 1 }} />
          <Stack gap={1}>
            {deletingFiles.map((file, index) => {
              const { title, guid, path } = file;

              return (
                <FileListItem
                  key={guid}
                  fileName={title || 'not_file_name'}
                  variant='cancel'
                  disableDelete={disabled}
                  onDelete={() => {
                    returFileToInitialFiles(file);
                    removeFromDeletingFiles(index);
                  }}
                  onDownload={async () => {
                    downloadFile({ fileName: title, path });
                  }}
                />
              );
            })}
          </Stack>
        </Grid>
      )}
      {checkIsNotEmptyArray(addingFiles) && (
        <Grid px={[1, 0]}>
          <Typography color={'GrayText'}>Загруженные</Typography>
          <Divider sx={{ my: 1 }} />
          <Stack gap={1}>
            {addingFiles.map((file, index) => {
              const { title } = file;

              return (
                <FileListItem
                  key={title}
                  fileName={title || 'not_file_name'}
                  disableDownload={true}
                  disableDelete={disabled}
                  onDelete={() => {
                    removeFromAddingFiles(index);
                  }}
                />
              );
            })}
          </Stack>
        </Grid>
      )}
    </FormLayout>
  );
};
