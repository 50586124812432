import { Button, Dialog, Grid, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';

const STYLE = {
  width: 310,
};

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  text: string;
  buttonText?: string;
  onConfirm: () => void;
}
export const ConfirmModal: FC<ConfirmModalProps> = ({
  onConfirm,
  text,
  isOpen,
  onClose,
  buttonText = 'Удалить',
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <Paper
        sx={STYLE}
        square
      >
        <Stack
          gap={1}
          justifyContent={'center'}
        >
          <Typography
            alignSelf={'center'}
            p={2}
            fontWeight={700}
            fontSize={20}
          >
            {text}
          </Typography>
          <Grid>
            <Button
              sx={{
                width: '50%',
                borderRadius: 0,
              }}
              size='large'
              variant='contained'
              color='error'
              onClick={onConfirm}
            >
              {buttonText}
            </Button>
            <Button
              sx={{
                width: '50%',
                borderRadius: 0,
              }}
              size='large'
              variant='contained'
              color='customGrey'
              onClick={onClose}
            >
              Закрыть
            </Button>
          </Grid>
        </Stack>
      </Paper>
    </Dialog>
  );
};
