import { array, object, ObjectSchema } from 'yup';

import { SCHEMA } from 'shared/yup-schema/YUP_SCHEMA';

import {
  CompetitiveAdvantagesFormBlockI,
  CompetitiveAdvantagesFormBlockItemI,
  CompetitiveAdvantagesFormI,
  CompetitorsValueI,
} from './type';

/* Form schems */
export const CompetitorsValueSchema: ObjectSchema<CompetitorsValueI> =
  object().shape({
    competitorId: SCHEMA.NUMBER.required(),
    value: SCHEMA.NUMBER.nullable(),
  });

export const CompetitiveAdvantagesFormBlockItemSchema: ObjectSchema<CompetitiveAdvantagesFormBlockItemI> =
  object().shape({
    itemId: SCHEMA.NUMBER.required(),
    itemTitle: SCHEMA.STRING_REQUIRED,
    myValue: SCHEMA.NUMBER.nullable(),
    competitorsValue: array(CompetitorsValueSchema.required()).required(),
    comment: SCHEMA.STRING.nullable(),
    canAddComent: SCHEMA.BOOLEAN.required(),
  });

export const CompetitiveAdvantagesFormBlockSchema: ObjectSchema<CompetitiveAdvantagesFormBlockI> =
  object().shape({
    blockTitle: SCHEMA.STRING_REQUIRED,
    blockId: SCHEMA.NUMBER.required(),
    blockItems: array(
      CompetitiveAdvantagesFormBlockItemSchema.required()
    ).required(),
  });

export const CompetitiveAdvantagesFormSchema: ObjectSchema<CompetitiveAdvantagesFormI> =
  object().shape({
    blocks: array(CompetitiveAdvantagesFormBlockSchema.required()).required(),
  });
/* ==== */
