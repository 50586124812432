import { createSearchParams, URLSearchParamsInit } from 'react-router-dom';
import { AdaptationProcessesPreparedStageTasksListParams } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { ALL_ROUTES } from 'shared/routes/constants';

export interface CreateLinkRunningProcessParams
  extends AdaptationProcessesPreparedStageTasksListParams {
  countdownDate?: string;
  SkipStage?: string[];
}

export const ADAPTATION_PROCESS_HELPER_LINKS = {
  createLinkRunningProcess: ({
    NewEmployeeGuid,
    AdaptationGuid,
    MentorGuid,
    SkipStage,
    countdownDate,
  }: CreateLinkRunningProcessParams) => {
    let baseParams: URLSearchParamsInit = {
      NewEmployeeGuid,
      AdaptationGuid,
      MentorGuid,
    };

    if (SkipStage) {
      baseParams = { ...baseParams, SkipStage };
    }

    if (countdownDate) {
      baseParams = { ...baseParams, countdownDate };
    }

    return {
      pathname: `/${ALL_ROUTES.adaptation}/${ALL_ROUTES.adaptationTemplatesRunProcess}`,
      search: createSearchParams(baseParams).toString(),
    };
  },

  createLinkCurrentAdaptationProcess: () => {
    return `/${ALL_ROUTES.adaptation}/${ALL_ROUTES.currentAdaptationProcesses}`;
  },
};
