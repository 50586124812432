import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useStore } from 'effector-react';
import {
  $selectedVisitFormat,
  changeVisitFormat,
} from 'pages/visit/PlanningPage';
import { FC, useState } from 'react';
import { FilterButton } from 'shared/ui/base-ui/filter-button/FilterButton';
import { CustomSwitcher } from 'shared/ui/custom-switcher';
import { UiSearchBar } from 'shared/ui/ui-search-bar';
import { useDevice } from 'shared/utils/hook-type-device';
import { PlanningSearchBarProps } from './type';

export const PlanningSearchBar: FC<PlanningSearchBarProps> = ({
  onSubmitInput,
  onOpenFilter,
  onOpenPlanning,
  initSearch = '',
}) => {
  const [isMobile] = useDevice();
  const [search, setSearch] = useState(initSearch);
  const viewType = useStore($selectedVisitFormat);

  const clearSearch = () => {
    setSearch('');
    onSubmitInput('');
  };

  return (
    <UiSearchBar
      changeValue={setSearch}
      textValue={search}
      onCompliteInput={onSubmitInput}
      onClear={clearSearch}
    >
      {!isMobile && (
        <CustomSwitcher
          options={['Календарь', 'Таблица']}
          value={viewType}
          buttonGroupProps={{ color: 'primary' }}
          onChange={changeVisitFormat}
          buttonProps={{ sx: { width: '110px' }, color: 'customBlue' }}
        />
      )}

      <Button
        variant='contained'
        color='customBlue'
        sx={{ borderRadius: 2 }}
        startIcon={<Add />}
        onClick={onOpenPlanning}
      >
        Запланировать
      </Button>

      <FilterButton onClick={onOpenFilter} />
    </UiSearchBar>
  );
};
