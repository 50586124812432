import { transformResponseError } from 'shared/api/libs';
import {
  deleteTasksTaskGuidFilesFileGuid,
  getTasksTaskGuidFiles,
  getTasksTaskGuidFilesExternal,
  postTasksTaskGuidFiles,
} from '../services';
import { GetListExternalTaskMassFile, GetListTaskFile } from '../types';
import { taskProviderApi } from './taskProviderApi';

const fileApi = taskProviderApi.injectEndpoints({
  endpoints: (build) => ({
    /* ========================================== */
    /* == Получение прикреплённых файлов задач == */
    /* ========================================== */
    getTaskFiles: build.query<GetListTaskFile[], string>({
      queryFn: async (taskGuid) => {
        try {
          const response = await getTasksTaskGuidFiles(taskGuid);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];

        return res.map((file) => ({ type: 'task/file', id: file.guid }));
      },
    }),
    /* ========================================== */
    /* ========================================== */
    /* ========================================== */

    /* ============================================== */
    /* == Получение доп прикреплённых файлов задач == */
    /* ============================================== */
    getExternalTaskFiles: build.query<GetListExternalTaskMassFile[], string>({
      queryFn: async (taskGuid) => {
        try {
          const response = await getTasksTaskGuidFilesExternal(taskGuid);
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      providesTags: (res) => {
        if (!res) return [];

        return res.map((file) => ({ type: 'task/file', id: file.guid }));
      },
    }),
    /* ============================================== */
    /* ============================================== */
    /* ============================================== */

    /* ================================= */
    /* == Прикрепление файла к задаче == */
    /* ================================= */
    postFiles: build.mutation<
      GetListTaskFile,
      {
        taskGuid: string;
        file: string;
      }
    >({
      queryFn: async ({ file, taskGuid }) => {
        try {
          const response = await postTasksTaskGuidFiles(taskGuid, { file });
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async ({ taskGuid }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const patchResult = dispatch(
            fileApi.util.updateQueryData('getTaskFiles', taskGuid, (draft) => {
              draft.push(data);
            })
          );
        } catch (error) {
          transformResponseError(error);
        }
      },
    }),
    /* ================================= */
    /* ================================= */
    /* ================================= */

    /* =================================== */
    /* == Удаление прикреплённого файла == */
    /* =================================== */
    deleteFile: build.mutation<
      undefined,
      { taskGuid: string; fileGuid: string }
    >({
      queryFn: async ({ fileGuid, taskGuid }) => {
        try {
          const response = await deleteTasksTaskGuidFilesFileGuid(
            taskGuid,
            fileGuid
          );
          return { data: response.data };
        } catch (error) {
          return transformResponseError(error);
        }
      },
      onQueryStarted: async (
        { fileGuid, taskGuid },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          fileApi.util.updateQueryData('getTaskFiles', taskGuid, (draft) => {
            return draft.filter((file) => file.guid !== fileGuid);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    /* =================================== */
    /* =================================== */
    /* =================================== */
  }),
});

export const {
  useGetExternalTaskFilesQuery,
  useGetTaskFilesQuery,
  usePostFilesMutation,
  useDeleteFileMutation,
} = fileApi;
