// TODO: Решить проблему с any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createEvent, createStore } from 'effector';

export const openManual = createEvent<any>('openManual');
export const closeManual = createEvent<any>('closeManual');
export const $isOpenManual = createStore(false, { name: '$isOpenManual' })
  .on(openManual, () => true)
  .on(closeManual, () => false);
