import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { theme } from 'shared/theme/theme';

import { PushNotificationsList } from './PushNotificationsList';
import { PushNotificationsTable } from './PushNotificationsTable';
import { PushNotificationsTableProps } from './types';

export const PushNotificationsAdaptiveTable: FC<PushNotificationsTableProps> = (
  props
) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isMobile ? (
        <PushNotificationsList {...props} />
      ) : (
        <PushNotificationsTable {...props} />
      )}
    </>
  );
};
