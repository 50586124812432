import { Grid } from '@mui/material';
import { FC } from 'react';

import { theme } from 'shared/theme/theme';

import {
  UiTableCard,
  UiTableCardList,
  UiTableCardPagination,
} from 'shared/ui/table';

import { GetListTask } from 'shared/api/services/types';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';
import { TaskTableProps } from './type';

export const TaskList: FC<TaskTableProps> = ({
  count,
  data,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  renderActions,
  isLoading = false,
  onClick,
  fields,
  onChangeFields,
  visibleFields,
}) => {
  const statusField = fields.find(
    (el) => el.field === 'status'
  ) as FieldMapType<GetListTask>;

  return (
    <UiTableCardList
      fields={fields}
      onChangeFields={onChangeFields}
      isLoading={isLoading}
      pagination={
        <UiTableCardPagination
          count={count}
          onPageChange={onChangePage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowsPerPageChange={onChangeLimit}
        />
      }
    >
      {data.map((task) => (
        <UiTableCard
          data={task}
          id={task.guid}
          visibleFields={visibleFields.filter((el) => el.field !== 'status')}
          key={task.guid}
          renderHeaderChildren={() => {
            const comp = statusField.renderComponent(task);

            return <Grid pl={0.5}>{comp}</Grid>;
          }}
          onClick={() => {
            onClick && onClick(task);
          }}
          renderActions={(isOpen, setIsLoading) => {
            return renderActions(task, { isOpen, setIsLoading });
          }}
          paperProps={{
            sx: {
              border: task.isTarget
                ? `2px solid ${theme.palette.primary.main}`
                : undefined,
            },
          }}
        />
      ))}
    </UiTableCardList>
  );
};
