// TODO: Разобраться с ts-ignore
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useEffect, useState } from 'react';

import { getChecklistsVisitingChecklistId } from 'shared/api/visit/services';
import { VisitingChecklistGetResponse } from 'shared/api/visit/types';

export const useGetCheckList = (checkListId: number | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checkList, setCheckList] =
    useState<VisitingChecklistGetResponse | null>(null);
  const [status, setStatus] = useState<number | null>(null);

  const getCheckList = async () => {
    if (checkListId) {
      setIsLoading(true);

      try {
        const { data, status } =
          await getChecklistsVisitingChecklistId(checkListId);

        setStatus(status);
        setCheckList(data);
        setIsLoading(false);

        return data;
      } catch (e) {
        // @ts-ignore
        setStatus(e.status);
        setIsLoading(false);

        return null;
      }
    } else {
      setCheckList(null);

      return null;
    }
  };

  useEffect(() => {
    getCheckList();
  }, [checkListId]);

  return { isLoading, checkList, getCheckList, status };
};
