import { Button, CircularProgress, Grid } from '@mui/material';
import { FC } from 'react';

import { ALL_ROUTES } from 'shared/routes/constants/types';
import { theme } from 'shared/theme/theme';
import { useUrlRedirect } from 'shared/url-helper/useUrlRedirect';
import { useDevice } from 'shared/utils/hook-type-device';

import { VisitPagination } from './VisitPagination/VisitPagination';

const defaultRedirectUrl = `/${ALL_ROUTES.visitingPharmacies}/${ALL_ROUTES.planning}`;

interface NavButtonsProps {
  disable?: boolean;
  complite?: boolean;
  isLoading?: boolean;
}
export const NavButtons: FC<NavButtonsProps> = ({
  disable,
  complite,
  isLoading,
}) => {
  const redirect = useUrlRedirect();
  const [isMob] = useDevice();

  return (
    <Grid sx={{ marginTop: 1, width: '100%' }}>
      {isMob && (
        <VisitPagination
          complite={complite}
          isLoading={isLoading}
          nextDisable={disable}
        />
      )}
      <Grid
        container
        sx={{ marginTop: 2, marginBottom: [0, 2] }}
        justifyContent='space-between'
      >
        <Button
          variant='contained'
          color='customGrey'
          size='large'
          onClick={() => {
            redirect(defaultRedirectUrl);
          }}
          sx={{
            flexBasis: ['100%', '32%'],
            borderRadius: ['0px', '8px'],
          }}
        >
          Закрыть
        </Button>

        {!isMob && (
          <Button
            variant='contained'
            color='secondary'
            size='large'
            type='submit'
            disabled={disable || isLoading}
            sx={{
              width: ['0%', '100%'],
              flexBasis: ['0%', '32%'],
              borderRadius: ['0px', '8px'],
            }}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: theme.palette.primary.main,
                }}
              />
            ) : complite ? (
              'Завершить'
            ) : (
              'Далее'
            )}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
