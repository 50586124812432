import { Paper } from '@mui/material';
import { SearchBarOpeningRetailProtocol } from 'features/retail-opeting-process/filter-protocol';
import { useFilterOpeningRetailProtocol } from 'features/retail-opeting-process/filter-protocol/useFilterOpeningRetailProtocol';

import { useMemo } from 'react';
import { useGetOpeningTemplatesProcessQuery } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { ProtocolsOpeningRetails } from 'widgets/retail-opening-process/protocol-opening-retails-table';

export const PageRunningProcesses = () => {
  const { onChangeLimit, onChangePage, filterParams, onChangeOrder, order } =
    useFilterOpeningRetailProtocol();

  const {
    data: responseProtocols,
    isLoading: isLoadingProtocols,
    isFetching: isFetchingProtocols,
  } = useGetOpeningTemplatesProcessQuery({
    params: filterParams,
  });
  const tableData = useMemo(() => {
    if (!responseProtocols || !responseProtocols.items) return undefined;

    return ProtocolsOpeningRetails.prepareResponseToDataTeble(
      responseProtocols.items
    );
  }, [responseProtocols]);
  const [allFields, onChangeFields, visibleFields] =
    ProtocolsOpeningRetails.useCellFromLocalStorage();

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <SearchBarOpeningRetailProtocol />
      </LayoutPage.Bar>
      <LayoutPage.Content>
        {tableData && (
          <Paper>
            <ProtocolsOpeningRetails.Table
              isUpdate={isFetchingProtocols}
              isLoading={isLoadingProtocols}
              data={tableData}
              fields={allFields}
              visibleFields={visibleFields}
              onChangeFields={onChangeFields}
              onChangeLimit={onChangeLimit}
              onChangePage={onChangePage}
              page={filterParams.Page}
              limit={filterParams.Limit}
              count={responseProtocols?.count || 20}
              order={order}
              onChangeOrder={onChangeOrder}
            />
          </Paper>
        )}
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
