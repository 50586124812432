export const renderOptionsCustomAutoCompliteAdaptive = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: { title?: string; id: number }
) => (
  <li
    {...props}
    key={option.id}
  >
    {option.title}
  </li>
);
