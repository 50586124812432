import {
  useDeleteBoardMutation,
  useGetBoardsQuery,
  useIsArchiveBoardMutation,
} from 'shared/api/services/rtk/boardApi';
import { GetBoardsQueryParams } from 'shared/api/services/types';

export const useBoardListPage = (query: GetBoardsQueryParams) => {
  const { data, isFetching } = useGetBoardsQuery(query);
  const [deleteBoard, { isLoading: isDeleting }] = useDeleteBoardMutation();
  const [archiveBoard, { isLoading: isArchiving }] =
    useIsArchiveBoardMutation();

  const isLoadingData = isFetching || isDeleting || isArchiving;

  return { boardList: data, deleteBoard, archiveBoard, isLoadingData };
};
