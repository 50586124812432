import { TimePicker } from '@mui/x-date-pickers';
import { FC } from 'react';

import { useDevice } from 'shared/utils/hook-type-device';

import { AdeptLayoutForUiComponent } from '../adept-layout-for-ui-component';
import { CustomTimePickerProps } from './type';

export const CustomTimePicker: FC<CustomTimePickerProps> = ({
  containerSx,
  ...props
}) => {
  const [isMob] = useDevice();

  return (
    <AdeptLayoutForUiComponent
      containerSx={containerSx}
      label={props.label || ''}
    >
      <TimePicker
        className='subvariant-adept'
        {...props}
        slotProps={{
          actionBar: {
            actions: ['clear', 'accept'],
          },
          ...props.slotProps,
        }}
        label={!isMob ? props.label : undefined}
      />
    </AdeptLayoutForUiComponent>
  );
};
