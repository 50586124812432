import { ChipOwnProps } from '@mui/material';
import { ReactElement } from 'react';

export enum TaskStatuses {
  'Отложена' = 'Отложена',
  'Новая' = 'Новая',
  'В работе' = 'В работе',
  'Выполнена' = 'Выполнена',
  'Завершена' = 'Завершена',
  'Ожидание' = 'Ожидание',
  'Черновик' = 'Черновик',
  'На проверке' = 'На проверке',
}

export interface TaskStatusIcon {
  status: TaskStatuses;
  icon: ReactElement;
  color: ChipOwnProps['color'];
}

export interface TaskStatusChipProps {
  status: TaskStatuses;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
