import { Grid, Paper, Typography } from '@mui/material';
import { OpeningTemplateMainInformation } from 'entities/retail-opening-process/opening-template-main-information';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ProcessesLinks } from 'entities/retail-opening-process/process/processes-links';
import { ProtocolMainInformation } from 'entities/retail-opening-process/protocol/protocol-main-informations';
import {
  useGetOpeningTemplateDetailQuery,
  useGetOpeningTemplatesProcessQuery,
  useGetOpeningTemplatesProcessTasksQuery,
} from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { theme } from 'shared/theme/theme';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { convertURLToObject } from 'shared/url-helper/convertURLToObject';
import {
  ConvertMapWithType,
  ConvertTypes,
  URLToObjConverter,
} from 'shared/url-helper/type';
import { useURLState } from 'shared/url-helper/useURLState';
import { ProtocolsOpeningRetailsRunningTasks } from 'widgets/retail-opening-process/opening-running-tasks-table';
import { EMPT_ARR } from 'widgets/training/filter/constants';
import { GridLayout } from './layouts/lauout-with-stage-mainInfo-table';

export const PageOpeningProcessProtocol = () => {
  const params = useParams();
  const { filter } = useUrlStateRetailOpeningProcessProtocolParams();

  const { data, isFetching } = useGetOpeningTemplatesProcessQuery(
    {
      params: { Page: 1, Limit: 1, ProtocolsGuids: [params.guid as string] },
    },
    { skip: !params.guid }
  );

  const { protocol, openingProcesses } = data?.items?.[0] || {};

  /* GET TEMPLATE */
  const { data: openingTemplate, isFetching: isFetchingOpeningTemplate } =
    useGetOpeningTemplateDetailQuery(
      { guid: filter?.openingTemplateGuid as string },
      { skip: !filter?.openingTemplateGuid }
    );

  return (
    <LayoutPage.Page>
      <LayoutPage.Content>
        <GridLayout.Container>
          <GridLayout.LeftStickySection>
            {params.guid && (
              <ProcessesLinks
                openingProcesses={openingProcesses}
                protocolGuid={params.guid}
                isLoading={isFetching}
              />
            )}
          </GridLayout.LeftStickySection>
          <GridLayout.RightTop>
            <Paper sx={{ padding: [1] }}>
              <ProtocolMainInformation
                protocol={protocol}
                isLoading={isFetching}
              />
            </Paper>
          </GridLayout.RightTop>
          <GridLayout.RightMainContent>
            <Grid
              display={'flex'}
              flexDirection={'column'}
              gap={2}
            >
              <Paper sx={{ padding: [1] }}>
                <OpeningTemplateMainInformation
                  openingTemplate={openingTemplate}
                  isLoading={isFetchingOpeningTemplate}
                  title='Шаблон'
                />
              </Paper>

              {filter?.processGuid && (
                <TableTasksForOpeningProcessGuid
                  openingProcessGuid={filter?.processGuid}
                />
              )}
            </Grid>
          </GridLayout.RightMainContent>
        </GridLayout.Container>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};

const TableTasksForOpeningProcessGuid: FC<{ openingProcessGuid: string }> = ({
  openingProcessGuid,
}) => {
  const {
    data: tasks,
    isLoading,
    isFetching,
  } = useGetOpeningTemplatesProcessTasksQuery({
    params: {
      openingProcessGuid,
    },
  });
  const [allFields, onChangeFields, visibleFields] =
    ProtocolsOpeningRetailsRunningTasks.useCellFromLocalStorage();

  const tasksForStages = useMemo(() => {
    if (!tasks)
      return [
        {
          stageNumber: 1,
          tasks: EMPT_ARR,
        },
      ];

    return ProtocolsOpeningRetailsRunningTasks.prepareResponseTasksToTasksOnStages(
      tasks
    );
  }, [tasks]);

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={2}
    >
      {tasksForStages.map(({ stageNumber, tasks }) => {
        return (
          <Paper
            key={stageNumber}
            sx={{ overflow: 'hidden' }}
          >
            <Typography
              variant='h6'
              sx={{ backgroundColor: theme.palette.customGrey.main }}
              textAlign={'center'}
            >{`${stageNumber} Этап`}</Typography>
            <ProtocolsOpeningRetailsRunningTasks.Table
              isLoading={isFetching}
              isUpdate={isLoading}
              data={tasks}
              fields={allFields}
              onChangeFields={onChangeFields}
              visibleFields={visibleFields}
            />
          </Paper>
        );
      })}
    </Grid>
  );
};

interface Params {
  processGuid: string;
  openingTemplateGuid: string;
}

const convertorURLToRetailOpeningTemplates: URLToObjConverter<Params> = (
  searchParams
) => {
  return convertURLToObject(
    searchParams,
    CONVERT_RAIL_OPENING_TEMPLATE_LIST_FILTER_MAP
  ) as Params;
};

const CONVERT_RAIL_OPENING_TEMPLATE_LIST_FILTER_MAP: ConvertMapWithType<Params> =
  [
    { key: 'processGuid', type: ConvertTypes.string },
    { key: 'openingTemplateGuid', type: ConvertTypes.string },
  ];

const useUrlStateRetailOpeningProcessProtocolParams = () => {
  const [filter, setFilter] = useURLState(
    convertorURLToRetailOpeningTemplates,
    true
  );

  return {
    filter,
    setFilter,
  };
};
