import { Grid, Paper, Skeleton, Typography } from '@mui/material';

import { OpeningTemplateMainInformation } from 'entities/retail-opening-process/opening-template-main-information';
import { useParams } from 'react-router-dom';
import {
  useGetOpeningTemplateDetailQuery,
  useGetOpeningTemplatesProcessPreparedStageTasksQuery,
  useGetOpeningTemplatesProtocolQuery,
  useGetOpeningTemplatesStagesQuery,
} from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';
import { LayoutPage } from 'shared/ui/layouts/layout-page';

import dayjs from 'dayjs';
import {
  FormPreparedStagesTasks,
  preparedResponseToFormValue,
  useRunOpeningTemplateProcess,
} from 'features/retail-opeting-process/form-prepared-stages-tasks';
import { useUrlStateRetailOpeningTemplatesRunProcessQueryParams } from 'features/retail-opeting-process/form-run-opening-retail-process/hooks';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { getMessageFromFetchBaseQueryError } from 'shared/api/libs';
import {
  RetailOpeningProcessResponsesGetListProtocol,
  RetailOpeningProcessResponsesOpeningTemplateGetListStage,
} from 'shared/api/retail-opening-process/retailOpeningProcessAutogenerateApi';
import { useSnackbar } from 'shared/snackbar-helper/SnackbarContext';
import { checkIsNotEmptyArray } from 'shared/utils/checkIsNotEmptyArray';
import { GridLayout } from './layouts/lauout-with-stage-mainInfo-table';

export const PageRunProcessForTemplate = () => {
  const params = useParams();
  const { filter } = useUrlStateRetailOpeningTemplatesRunProcessQueryParams();
  const { StagesToSkipGuids, ProtocolGuid, countdownDate } = filter || {};
  const { open: openSnackbar } = useSnackbar();
  /* GET TEMPLATE */
  const { data: openingTemplate, isLoading: isLoadingOpeningTemplate } =
    useGetOpeningTemplateDetailQuery(
      { guid: params.guid as string },
      { skip: !params.guid }
    );

  /* InfoSettingsRunProcessSkipStages */
  /* GET STAGES */
  const { data: stages, isFetching: isFetchingSteges } =
    useGetOpeningTemplatesStagesQuery(
      { templateGuid: params.guid as string },
      {
        skip: !params.guid,
      }
    );
  const skipStages = useMemo(() => {
    if (!StagesToSkipGuids || isFetchingSteges) return undefined;

    return stages?.filter((stage) =>
      StagesToSkipGuids.some((guid) => stage.guid === guid)
    );
  }, [StagesToSkipGuids, stages, isFetchingSteges]);

  /* InfoSettingsRunProcessProtocol */
  /* GET protocols */
  const { data: protocols, isFetching: isFetchingProtocol } =
    useGetOpeningTemplatesProtocolQuery(
      { params: { OpeningTemplateGuid: params?.guid } },
      { skip: !params.guid }
    );
  const protocol = useMemo(() => {
    if (!protocols) return undefined;

    return protocols.find((protocol) => protocol.guid === ProtocolGuid);
  }, [ProtocolGuid, protocols]);

  const {
    data: preparedStageTasks,
    isFetching: isFetchingPreparedStageTasks,
    error: errorFetchPreparedStageTasks,
  } = useGetOpeningTemplatesProcessPreparedStageTasksQuery(
    {
      params: {
        OpeningTemplateGuid: params?.guid as string,
        ProtocolGuid: ProtocolGuid as string,
        StagesToSkipGuids: StagesToSkipGuids || [],
      },
    },
    { skip: !params?.guid || !ProtocolGuid }
  );

  const initFormValue = useMemo(() => {
    if (!preparedStageTasks) return undefined;

    return preparedResponseToFormValue(preparedStageTasks);
  }, [preparedStageTasks]);

  useEffect(() => {
    if (errorFetchPreparedStageTasks) {
      openSnackbar({
        duration: 3000,
        text: getMessageFromFetchBaseQueryError(errorFetchPreparedStageTasks),
        type: 'error',
      });
    }
  }, [errorFetchPreparedStageTasks]);

  const {
    isLoadingSubmiteRunOpeningTemplatesProcess,
    handleRunOpeningTemplatesProcess,
  } = useRunOpeningTemplateProcess({
    countdownDate: countdownDate,
    openingTemplateGuid: params.guid,
    protocolGuid: ProtocolGuid,
  });

  return (
    <LayoutPage.Page>
      <LayoutPage.Content>
        <GridLayout.Container>
          <GridLayout.LeftStickySection>
            {/* protocol */}
            <InfoSettingsRunProcessProtocol
              protocol={protocol}
              isLoading={isFetchingProtocol}
            />
            {/* Skip stages */}
            <InfoSettingsRunProcessSkipStages
              skipStages={skipStages}
              isLoading={isFetchingSteges}
            />
            {/* countdownDate */}
            <InfoSettingsRunProcessItemConteiner title='Отсчет дней от'>
              <Typography>
                {countdownDate
                  ? dayjs(countdownDate).format('DD.MM.YYYY')
                  : '-'}
              </Typography>
            </InfoSettingsRunProcessItemConteiner>
          </GridLayout.LeftStickySection>

          <GridLayout.RightTop>
            <Paper sx={{ p: 1 }}>
              <OpeningTemplateMainInformation
                openingTemplate={openingTemplate}
                isLoading={isLoadingOpeningTemplate}
              />
            </Paper>
          </GridLayout.RightTop>

          <GridLayout.RightMainContent>
            {!initFormValue && isFetchingPreparedStageTasks && (
              <Skeleton
                width={'100%'}
                height={400}
                component={Paper}
                variant='rectangular'
              />
            )}
            {!isFetchingPreparedStageTasks &&
              !checkIsNotEmptyArray(initFormValue?.stages) && (
                <Paper
                  sx={{
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography fontWeight={'bold'}>
                    У шаблона нет этапов
                  </Typography>
                </Paper>
              )}
            {initFormValue && checkIsNotEmptyArray(initFormValue?.stages) && (
              <FormPreparedStagesTasks
                initialValue={initFormValue}
                onSubmite={handleRunOpeningTemplatesProcess}
                isLoadingSubmite={isLoadingSubmiteRunOpeningTemplatesProcess}
              />
            )}
          </GridLayout.RightMainContent>
        </GridLayout.Container>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};

const InfoSettingsRunProcessItemConteiner: FC<{
  title: string;
  children: ReactNode;
}> = ({ title, children }) => {
  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      gap={1}
    >
      <Typography fontWeight={'bold'}>{`${title} :`}</Typography>
      <Grid
        display={'flex'}
        flexDirection={'column'}
        gap={1}
      >
        {children}
      </Grid>
    </Grid>
  );
};

const InfoSettingsRunProcessProtocol: FC<{
  protocol: RetailOpeningProcessResponsesGetListProtocol | undefined;
  isLoading?: boolean;
}> = ({ protocol, isLoading }) => {
  return (
    <InfoSettingsRunProcessItemConteiner title='Протокол'>
      {isLoading ? (
        <>
          <Skeleton
            variant='rectangular'
            width={'100%'}
            height={105}
          />
        </>
      ) : (
        <>
          <Typography key={1}>{protocol?.title}</Typography>
          <Typography key={2}>{protocol?.retail?.title}</Typography>
        </>
      )}
    </InfoSettingsRunProcessItemConteiner>
  );
};

const InfoSettingsRunProcessSkipStages: FC<{
  skipStages:
    | RetailOpeningProcessResponsesOpeningTemplateGetListStage[]
    | undefined;
  isLoading?: boolean;
}> = ({ skipStages, isLoading }) => {
  return (
    <InfoSettingsRunProcessItemConteiner title='Пропустить этапы'>
      {!skipStages && isLoading && (
        <Skeleton
          variant='rectangular'
          width={30}
          height={'1.5em'}
        />
      )}
      {!skipStages && !isLoading && <Typography>-</Typography>}
      {skipStages &&
        skipStages.map((skipStage) => (
          <Typography
            key={skipStage.guid}
          >{`${skipStage.stageNumber} Этап`}</Typography>
        ))}
    </InfoSettingsRunProcessItemConteiner>
  );
};
