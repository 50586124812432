import { AdaptationTemplatesSearchBar } from 'features/adaptation/AdaptationTemplatesSearchBar/AdaptationTemplatesSearchBar';
import { useAdaptationTemplatesPage } from 'features/adaptation/hooks/useAdaptationTemplatesPage';
import { useDeleteAdaptationTemplate } from 'features/adaptation/hooks/useDeleteAdaptationTemplate';
import { useUpdateAdaptationTemplate } from 'features/adaptation/hooks/useUpdateAdaptationTemplate';
import { memo, useCallback } from 'react';
import { AdaptationManagerResponsesGetListAdaptation } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { useGetAdaptationTemplatesListQuery } from 'shared/api/service-adaptation/rtk/adaptationTemplatesProviderApi';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { useTableCellsFromLocalStorage } from 'shared/ui/table';
import { Tables } from 'shared/utils/controllers';
import { AdaptationTemplatesTableActions } from 'widgets/adaptation/AdaptationTemplatesTable/actions/AdaptationTemplatesTableActions';
import { AdeptiveAdaptationTemplatesTable } from 'widgets/adaptation/AdaptationTemplatesTable/AdeptiveAdaptationTemplatesTable';
import { useAdaptationNavigation } from './hooks/useAdaptationNavigation';

export const AdaptationTemplatesPage = () => {
  const { navigateToStages } = useAdaptationNavigation();
  const { CreateTemplateDialog, isUpdatingTemplate, openUpdateTemplate } =
    useUpdateAdaptationTemplate();
  const { deleteTemplate, isDeleting } = useDeleteAdaptationTemplate();
  const {
    Limit,
    Page,
    SearchText,
    handleChangeLimit,
    handleChangePage,
    handleClearSearch,
    handleSearch,
  } = useAdaptationTemplatesPage();

  const [fields, setFields, visibleFields] =
    useTableCellsFromLocalStorage<AdaptationManagerResponsesGetListAdaptation>(
      Tables.ADAPTATION_TEMPLATES_TABLE
    );

  const { data, isLoading, isFetching } = useGetAdaptationTemplatesListQuery({
    Limit,
    Page,
    SearchText,
  });

  const renderActions = useCallback(
    (template: AdaptationManagerResponsesGetListAdaptation) => {
      return (
        <AdaptationTemplatesTableActions
          template={template}
          onDelete={deleteTemplate}
          onEdit={openUpdateTemplate}
        />
      );
    },
    [deleteTemplate]
  );

  return (
    <>
      {CreateTemplateDialog}

      <LayoutPage.Page>
        <LayoutPage.Bar>
          <AdaptationTemplatesSearchBar
            initSearch={SearchText}
            onClear={handleClearSearch}
            onSubmit={handleSearch}
          />
        </LayoutPage.Bar>

        <LayoutPage.Content>
          <MemoizedAdeptiveAdaptationTemplatesTable
            count={data?.count || 0}
            data={data?.items || []}
            fields={fields}
            limit={Limit}
            onChangeFields={setFields}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
            page={Page}
            renderActions={renderActions}
            visibleFields={visibleFields}
            isLoading={isLoading}
            isUpdate={isFetching || isDeleting || isUpdatingTemplate}
            onClick={(template) => {
              navigateToStages(template.guid);
            }}
          />
        </LayoutPage.Content>
      </LayoutPage.Page>
    </>
  );
};

const MemoizedAdeptiveAdaptationTemplatesTable = memo(
  AdeptiveAdaptationTemplatesTable
);
