import { FC, PropsWithChildren } from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster';

import { getClusterIcon } from '../libs/getClusterIcon';

export const UiMarkerClusterGroup: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MarkerClusterGroup
      removeOutsideVisibleBounds
      chunkedLoading
      iconCreateFunction={getClusterIcon}
    >
      {children}
    </MarkerClusterGroup>
  );
};
