import { FC } from 'react';

import {
  UiTable,
  UiTableCell,
  UiTableHeader,
  UiTablePagination,
} from 'shared/ui/table';
import { UiTableSkeleton } from 'shared/ui/table/ui-table-skeleton/UiTableSkeleton';

import { UiTableRowContextMenuWithActions } from 'shared/ui/table/ui-table-row-context-menu-with-actions';
import { DocumentTableProps } from './type';

export const DocumentsTable: FC<DocumentTableProps> = ({
  data: documents,
  fields,
  visibleFields,
  onChangeFields,
  count,
  page,
  limit,
  isLoading,
  onChangeLimit,
  onChangePage,
  onClick,
  renderActionsContextMenu,
}) => {
  return (
    <UiTable
      isLoading={isLoading && (!documents || documents?.length === 0)}
      isUpdating={isLoading}
      skeleton={<UiTableSkeleton limit={limit} />}
      header={
        <UiTableHeader
          viewCells={visibleFields}
          setViewCells={onChangeFields}
          allCells={fields}
        />
      }
      body={
        documents &&
        documents.length !== 0 &&
        documents.map((document) => (
          <UiTableRowContextMenuWithActions
            key={document.id}
            hover
            onClick={() => {
              onClick?.(document);
            }}
            Actions={renderActionsContextMenu(document)}
            sx={{ cursor: 'pointer' }}
          >
            {visibleFields
              .filter((el) => el.isShow)
              .map((el) => (
                <UiTableCell
                  key={el.field + document.id}
                  {...el.getTableCellProps()}
                >
                  {el.renderComponent(document) || 'Error'}
                </UiTableCell>
              ))}
          </UiTableRowContextMenuWithActions>
        ))
      }
      pugination={
        <UiTablePagination
          component={'div'}
          count={count}
          onPageChange={(_, page) => {
            onChangePage(page + 1);
          }}
          page={page ? page - 1 : 1}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => {
            onChangeLimit(Number(e.target.value));
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      }
    />
  );
};
