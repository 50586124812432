import { useGetOpeningTemplatesQuery } from 'shared/api/retail-opening-process/rtk/retailOpeningProcessProviderApi';

import { LayoutPage } from 'shared/ui/layouts/layout-page';

import {
  OpeningTemplatesAdaptiveTable,
  useTableCellsOpeningTamplatesFromLocalStorage,
} from 'widgets/retail-opening-process/opening-templates-table';

import {
  SearchBarOpeningRetailTemplates,
  useFilterOpeningRetailTemplates,
} from 'features/retail-opeting-process/filter';

export const PageRetailOpeningTemplates = () => {
  const { filterParams, onChangeLimit, onChangePage } =
    useFilterOpeningRetailTemplates();

  const [fields, setFields, visibleFields] =
    useTableCellsOpeningTamplatesFromLocalStorage();

  const { data, isLoading, isFetching } =
    useGetOpeningTemplatesQuery(filterParams);
  const { count, items } = data || { count: 100, items: [] };

  return (
    <LayoutPage.Page>
      <LayoutPage.Bar>
        <SearchBarOpeningRetailTemplates />
      </LayoutPage.Bar>
      <LayoutPage.Content>
        <OpeningTemplatesAdaptiveTable
          isLoading={isLoading}
          isUpdate={isFetching}
          data={items || []}
          fields={fields}
          visibleFields={visibleFields}
          onChangeFields={setFields}
          count={count || 100}
          page={filterParams.Page}
          limit={filterParams.Limit}
          onChangeLimit={onChangeLimit}
          onChangePage={onChangePage}
        />
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};
