import { Stage, StageStatuses, STAGE_STATUSES } from './type';

export enum STAGES {
  stage1 = 'stage1',
  stage2 = 'stage2',
  stage3 = 'stage3',
  stage4 = 'stage4',
  stage5 = 'stage5',
  stage6 = 'stage6',
}

export const INITIAL_STAGES: Stage[] = [
  {
    status: StageStatuses.selected,
    route: STAGES.stage1,
    title: 'Основная информаци',
    isView: true,
    compliteStatus: STAGE_STATUSES.UNTOUCHED,
  },
  {
    status: StageStatuses.disable,
    route: STAGES.stage2,
    title: 'Информация о конкурентах',
    isView: false,
    compliteStatus: STAGE_STATUSES.UNTOUCHED,
  },
  {
    status: StageStatuses.disable,
    route: STAGES.stage3,
    title: 'Оценка конкурентных преимуществ',
    isView: false,
    compliteStatus: STAGE_STATUSES.UNTOUCHED,
  },
  {
    status: StageStatuses.disable,
    route: STAGES.stage4,
    title: 'Оценочный лист',
    isView: false,
    compliteStatus: STAGE_STATUSES.UNTOUCHED,
  },
  {
    status: StageStatuses.disable,
    route: STAGES.stage5,
    title: 'Проблемы',
    isView: false,
    compliteStatus: STAGE_STATUSES.UNTOUCHED,
  },
  {
    status: StageStatuses.disable,
    route: STAGES.stage6,
    title: 'Мероприятия',
    isView: false,
    compliteStatus: STAGE_STATUSES.UNTOUCHED,
  },
];

export const FAIK_VISIT_LIST = {
  isCompetitorInformation: false,
  isStageOneComplete: false,
  isCompetitorComparison: false,
  isStageTwoComplete: false,
  isStageThreeComplete: false,
  isStageFourComplete: false,
  isStageFiveComplete: false,
  problems: undefined,
  id: undefined,
};
