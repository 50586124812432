import { useCallback, useLayoutEffect, useState } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';

import { GetDocumentsObserveQueryParams } from 'shared/api/services-document-flow/types';

import { transformFilterObserveToUrlParams } from '../libs/transformFilterObserveToUrlParams';
import { DocumentFlowFilterObserveForm } from '../type';
import { useUrlStateDocumentObserveFilter } from '../use-url-state-document-observe-filter';
import { useInitialObserveFilterFormFromUrlState } from './useInitialObserveFilterFormFromUrlState';

export const useDocumentObserveFilterModal = () => {
  const { isOpen, close, open } = useViewModal();
  const [onInitial, isLoadingInit] = useInitialObserveFilterFormFromUrlState();
  const { setFilter, filter } = useUrlStateDocumentObserveFilter();
  const [initialValue, setInitialValue] = useState<
    DocumentFlowFilterObserveForm | undefined
  >();
  const onSubmitDocumentObserveFilterModal = useCallback(
    (form: DocumentFlowFilterObserveForm) => {
      const urlParams: Omit<
        GetDocumentsObserveQueryParams,
        'CurrentPage' | 'PageSize'
      > = transformFilterObserveToUrlParams(form);

      setFilter({
        ...filter,
        ...urlParams,
        CurrentPage: 1,
      });
      close();
    },
    [filter, close]
  );

  useLayoutEffect(() => {
    if (isOpen) {
      onInitial().then((initValue) => {
        setInitialValue(initValue);
      });
    }
  }, [isOpen, setInitialValue]);

  return {
    onSubmitDocumentObserveFilterModal,
    initialValueDocumentObserveFilterModalForm: initialValue,
    isLoadingInitDocumentObserveFilterModalForm: isLoadingInit,
    onCloseDocumentObserveFilterModalForm: close,
    onOpenDocumentObserveFilterModalForm: open,
    isOpenDocumentObserveFilterModalForm: isOpen,
  };
};
