import { FormControlLabel, Switch } from '@mui/material';

import { Controller } from 'react-hook-form';
import { SwitchWithControllerComponent } from './types';
/**
 * @description связка Controller из react-hook-form и CustomDatePicker / CustomTextField / Autocomplete / Switch.
 * @see {@linkcode shared/ui/base-ui}
 */
export const SwitchWithController: SwitchWithControllerComponent = ({
  control,
  name,
  formControlLabelProps,
  ...propsSwitch
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControlLabel
            checked={value}
            onChange={(e, checked) => onChange(checked)}
            control={
              <Switch
                size='small'
                {...propsSwitch}
              />
            }
            labelPlacement='end'
            {...formControlLabelProps}
          />
        );
      }}
    />
  );
};
