import { GetTask } from 'shared/api/services/types';

import { RemindeFormFields } from '../type';

const isCustom = (value: number | null) => {
  switch (value) {
    case null:
      return false;
    case 1:
      return false;
    case 30:
      return false;
    case 7:
      return false;
    default:
      return true;
  }
};

export const getRemindeFormInitValue = (task: GetTask): RemindeFormFields => {
  return {
    creator: {
      isCustom: isCustom(task.reminderPeriodCreator || null),
      isReminde: task.isReminderCreator || false,
      ticks: task.reminderPeriodCreator || null,
    },
    executor: {
      isCustom: isCustom(task.reminderPeriodExecutor || null),
      isReminde: task.isReminderExecutor || false,
      ticks: task.reminderPeriodExecutor || null,
    },
    coExecutor: {
      isCustom: isCustom(task.reminderPeriodCoExecutor || null),
      isReminde: task.isReminderCoExecutor || false,
      ticks: task.reminderPeriodCoExecutor || null,
    },
    observer: {
      isCustom: isCustom(task.reminderPeriodObserver || null),
      isReminde: task.isReminderObserver || false,
      ticks: task.reminderPeriodObserver || null,
    },
  };
};
