import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';

export const FilterButton: FC<ButtonProps> = ({ sx, ...otherProps }) => {
  return (
    <Button
      color='customBlue'
      variant='contained'
      sx={{
        minWidth: 'auto',
        padding: ['6px 8px', '6px 8px', '6px 8px', '6px 8px', '6px 8px'],
        ...sx,
      }}
      {...otherProps}
    >
      <FilterAltIcon />
    </Button>
  );
};
