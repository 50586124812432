import { ClickAwayListener, Grid } from '@mui/material';
import { FC } from 'react';
import { BoardTaskCard } from '../BoardTaskCard';
import { BoardTaskFormCard } from '../BoardTaskFormCard';
import { useMutableBoardTaskCard } from './hook';
import { MutableBoardTaskCardProps } from './type';

export const MutableBoardTaskCard: FC<MutableBoardTaskCardProps> = ({
  task,
  actions,
  onClick,
  cardProps,
  isLoading,
  onTaskChangeSubmit,
  onStatusClick,
  isSelected,
  isGhost,
  canEdit = true,
  executers,
}) => {
  const {
    formState: { close, isOpen, open },
    initCardValue,
    allCardActions,
    formActions,
  } = useMutableBoardTaskCard(task, actions, canEdit, isLoading);

  if (isOpen && canEdit)
    return (
      <ClickAwayListener onClickAway={close}>
        <Grid>
          <BoardTaskFormCard
            executers={executers}
            actions={formActions}
            onSubmit={(form) => {
              onTaskChangeSubmit(form).then(close);
            }}
            initValue={initCardValue}
          />
        </Grid>
      </ClickAwayListener>
    );

  return (
    <BoardTaskCard
      isGhost={isGhost}
      task={task}
      options={allCardActions}
      onClickStatus={onStatusClick}
      onAvatarClick={open}
      onTitleClick={open}
      onClick={onClick}
      isLoading={isLoading}
      cardProps={cardProps}
      isSelected={isSelected}
    />
  );
};
