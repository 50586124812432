import { FC, useEffect } from 'react';

import { documentFormModel } from 'entities/document-form';

import { ExpensesFormExpense } from 'widgets/expenses-form-expense/ExpensesFormExpense';

export const ExpensesFormListExpense: FC<{ id: number }> = ({ id }) => {
  const expenses = documentFormModel.useSelectArticleIdFormModel(
    id,
    'expenses'
  );

  useEffect(() => {
    if (!expenses || expenses.length === 0)
      documentFormModel.formEvent.addedTypeArticles(id);
  }, [expenses]);

  if (!expenses) return null;

  return (
    <>
      {expenses.map((_, index) => (
        <ExpensesFormExpense
          key={index}
          idArticle={id}
          indexExpenses={index}
          expenses={expenses}
        />
      ))}
    </>
  );
};
