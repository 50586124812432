import { Divider, Grid } from '@mui/material';
import { useState } from 'react';
import { useCheckContainsEventTargetOfRef } from 'shared/hooks/use-check-contains-event-target-of-ref';
import { ContextMenu } from 'shared/ui/base-ui/context-menu';
import { Card } from 'shared/ui/Card';
import { UiTableCardWithContextMenuComponent } from './types';

export const UiTableCardWithContextMenu: UiTableCardWithContextMenuComponent =
  ({ ActionsContextMenu, visibleFields, data, HeaderContent, paperProps }) => {
    const isCollapsed = visibleFields.length > 3;

    const [ref, setRef] = useState<null | HTMLDivElement>(null);
    const onClickWithSkipOnConteinsCell = useCheckContainsEventTargetOfRef(
      ref,
      {
        cbOnNotContains: paperProps?.onClick,
      }
    );

    return (
      <Card.Container
        collapsedVariant={isCollapsed}
        paperProps={{
          elevation: 2,
          ...paperProps,
          onClick: onClickWithSkipOnConteinsCell,
          sx: {
            gap: 2,
            ...(paperProps?.sx || {}),
          },
        }}
      >
        {/* HEADER */}
        {(ActionsContextMenu || HeaderContent) && (
          <Card.Header
            container
            justifyContent={'flex-end'}
            paddingLeft={1}
          >
            <Grid flexGrow={1}>{HeaderContent}</Grid>
            <Grid ref={setRef}>
              {ActionsContextMenu && (
                <ContextMenu.Provider>
                  <ContextMenu.IconButton />
                  {ActionsContextMenu}
                </ContextMenu.Provider>
              )}
            </Grid>
          </Card.Header>
        )}
        {/* CONTENT */}
        <Card.Collapse collapsedSize={125}>
          <Card.Stack
            gap={1}
            px={1}
            py={ActionsContextMenu || HeaderContent ? 0 : 1}
            divider={<Divider />}
          >
            {visibleFields.map((item, index) => {
              return (
                <Card.StackItem
                  key={String(item.field)}
                  title={item.title}
                  container
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                >
                  {item.renderComponent(data, index)}
                </Card.StackItem>
              );
            })}
          </Card.Stack>
        </Card.Collapse>
        {/* FOOTER */}
        {isCollapsed && (
          <Card.Footer
            container
            justifyContent={'center'}
          >
            <Card.CollapsButton />
          </Card.Footer>
        )}
      </Card.Container>
    );
  };
