import { useCallback, useLayoutEffect, useState } from 'react';
import { useViewModal } from 'shared/hooks/useViewModal';

import { transformBaseFormFilterToUrlParams } from '../../libs';
import { DocumentFlowFilterBaseForm } from '../../types';
import { useUrlStateDocumentControlFilter } from '../use-url-state-document-control-filter';
import { useInitialControlFilterFormFromUrlState } from './useInitialControlFilterFormFromUrlState';

export const useDocumentControlFilterModal = () => {
  const { isOpen, close, open } = useViewModal();
  const [onInitial, isLoadingInit] = useInitialControlFilterFormFromUrlState();
  const { setFilter, filter } = useUrlStateDocumentControlFilter();
  const [initialValue, setInitialValue] = useState<
    DocumentFlowFilterBaseForm | undefined
  >();
  const onSubmitDocumentControlFilterModal = useCallback(
    (form: DocumentFlowFilterBaseForm) => {
      const urlParams = transformBaseFormFilterToUrlParams(form);

      setFilter({
        ...filter,
        ...urlParams,
        CurrentPage: 1,
      });
      close();
    },
    [filter, close]
  );

  useLayoutEffect(() => {
    if (isOpen) {
      onInitial().then((initValue) => {
        setInitialValue(initValue);
      });
    }
  }, [isOpen, setInitialValue]);

  return {
    onSubmitDocumentControlFilterModal,
    initialValueDocumentControlFilterModalForm: initialValue,
    isLoadingInitDocumentControlFilterModalForm: isLoadingInit,
    onCloseDocumentControlFilterModalForm: close,
    onOpenDocumentControlFilterModalForm: open,
    isOpenDocumentControlFilterModalForm: isOpen,
  };
};
