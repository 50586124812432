import { useEffect, useRef, useState } from 'react';

import { getProducts } from 'shared/api/visit/services';
import { ProductGetListResponse } from 'shared/api/visit/types';

export const useSearchProduct = (search: string) => {
  const control = useRef<null | AbortController>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<ProductGetListResponse[]>([]);

  const serachProducts = async () => {
    if (control.current) {
      control.current.abort();
      control.current = null;
    }

    if (search.length > 3) {
      control.current = new AbortController();

      setIsLoading(true);

      const { data, status } = await getProducts(
        { SearchText: search },
        { signal: control.current.signal }
      );

      if (status === 200 && data) setProducts(data);
      setIsLoading(false);
    } else {
      setProducts([]);
    }
  };

  useEffect(() => {
    serachProducts();
  }, [search]);

  return { products, isLoading };
};
