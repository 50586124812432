import { Typography } from '@mui/material';
import { HTMLAttributes, ReactNode } from 'react';
import {
  ResponsesEmployeeResponses,
  ResponsesTrainingResponses,
  StatusModel,
} from 'shared/api/traning/types';

/* AUTOCOMPLITE - EMPLOYE */
export const renderOptionThemeTreining = (
  props: HTMLAttributes<HTMLLIElement>,
  { title, guid }: ResponsesTrainingResponses
): ReactNode => {
  return (
    <Typography
      {...props}
      key={guid}
    >{`${title} `}</Typography>
  );
};

export const getOptionLabelThemeTreining = ({
  title,
}: ResponsesTrainingResponses): string => {
  return `${title} `;
};

export const isOptionEqualToValueThemeTreining = (
  option: ResponsesTrainingResponses,
  value: ResponsesTrainingResponses
) => {
  return value.guid === option.guid;
};
/* AUTOCOMPLITE - EMPLOYE */
export const renderOptionEmploye = (
  props: HTMLAttributes<HTMLLIElement>,
  { fio, employment, guid }: ResponsesEmployeeResponses
): ReactNode => {
  return (
    <Typography
      {...props}
      key={guid}
    >{`${fio} / ${employment} `}</Typography>
  );
};

export const getOptionLabelEmploye = ({
  fio,
  employment,
}: ResponsesEmployeeResponses): string => {
  return `${fio} / ${employment} `;
};

export const isOptionEqualToValueEmploye = (
  option: ResponsesEmployeeResponses,
  value: ResponsesEmployeeResponses
) => {
  return value.guid === option.guid;
};

/* AUTOCOMPLITE - TRAINER */

export const renderOptionTrainer = (
  props: HTMLAttributes<HTMLLIElement>,
  { fio, employeePosition, guid }: ResponsesEmployeeResponses
): ReactNode => {
  return (
    <Typography
      {...props}
      key={guid}
    >{`${fio} / ${employeePosition} `}</Typography>
  );
};

export const getOptionLabelTrainer = ({
  fio,
  employeePosition,
}: ResponsesEmployeeResponses): string => {
  return `${fio} / ${employeePosition} `;
};
/* AUTOCOMPLITE - STATUSES */
export const renderOptionStatuses = (
  props: HTMLAttributes<HTMLLIElement>,
  { title, guid }: StatusModel
): ReactNode => {
  return (
    <Typography
      {...props}
      key={guid}
    >{`${title} `}</Typography>
  );
};

export const getOptionLabelStatuses = (option: StatusModel) =>
  `${option.title}`;

export const isOptionEqualToValueStatuses = (
  option: StatusModel,
  value: StatusModel
) => {
  if (value.guid !== null) {
    return value.guid === option.guid;
  }

  return value.title === option.title;
};
