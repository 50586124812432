import { Button, ButtonGroup } from '@mui/material';

import { CustomSwitcherFun } from './type';

export const CustomSwitcher: CustomSwitcherFun = ({
  options,
  onChange,
  value,
  buttonProps,
  buttonGroupProps,
}) => {
  return (
    <ButtonGroup
      disableElevation
      {...buttonGroupProps}
    >
      {options.map((option) => (
        <Button
          {...buttonProps}
          key={option}
          sx={{
            borderRadius: 2,
            ...buttonProps?.sx,
          }}
          variant={value === option ? 'contained' : 'outlined'}
          onClick={() => {
            onChange && onChange(option);
          }}
        >
          {option}
        </Button>
      ))}
    </ButtonGroup>
  );
};
