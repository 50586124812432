import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { AdaptationManagerResponsesGetListAdaptation } from 'shared/api/service-adaptation/adaptationAutogenerateApi';
import { DATE_FORMATS } from 'shared/date-helper/constants';
import { FieldMapType } from 'shared/ui/table/ui-menu-table';

export const ADAPTATION_TEMPLATES_TABLE_MAP: FieldMapType<AdaptationManagerResponsesGetListAdaptation>[] =
  [
    {
      field: 'title',
      title: 'Название',
      isShow: true,
      order: 1,
      getTableCellProps: () => ({}),
      renderComponent: (template) => {
        return <Typography>{template.title}</Typography>;
      },
    },
    {
      field: 'isAutorun',
      title: 'Автозапуск',
      isShow: true,
      order: 2,
      getTableCellProps: () => ({}),
      renderComponent: (template) => {
        return <Typography>{template.isAutorun ? 'Да' : 'Нет'}</Typography>;
      },
    },
    {
      field: 'createdAt',
      title: 'Дата создания',
      isShow: true,
      order: 3,
      getTableCellProps: () => ({}),
      renderComponent: (template) => {
        return (
          <Typography>
            {dayjs(template.createdAt).format(DATE_FORMATS.clientWithTime)}
          </Typography>
        );
      },
    },
    {
      field: 'createdBy',
      title: 'Создал',
      isShow: true,
      order: 4,
      getTableCellProps: () => ({}),
      renderComponent: (template) => {
        return <Typography>{template.createdBy}</Typography>;
      },
    },
  ];
