import { Typography } from '@mui/material';
import { TaxRateCalculatedResponse } from 'shared/api/services-document-flow/types';

export const getOptionLabelTaxRate = (
  option: string | TaxRateCalculatedResponse
) => {
  if (typeof option === 'string' || typeof option === 'number') {
    return String(option);
  }

  return `${option.title} (${option.sum} Руб.)`;
};

export const renderOptionTaxRate = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: TaxRateCalculatedResponse
) => (
  <Typography
    {...props}
    key={option.id}
  >{`${option.title} (${option.sum} Руб.)`}</Typography>
);

export const isOptionEqualToValueTaxRate = (
  option: TaxRateCalculatedResponse,
  value: TaxRateCalculatedResponse
) => option.id === value.id;
