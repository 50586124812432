import { MyControllFilterItemModel } from 'entities/task/libs/generateFilterDeps';

export const isCheck = (
  item: MyControllFilterItemModel,
  arr: MyControllFilterItemModel[] | null | undefined
): boolean => {
  let is = true;

  if (arr && arr.length > 0) {
    is = item.deps.some((id) => arr && arr.some((item) => item.depId === id));
  }

  return is;
};
