import { useCallback } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { CoreSystemEnumsObjectActions } from 'shared/api/online-store-service/firebase/autogenerate/onlineStoreServiceApi';
import { fileListToArrayBase64 } from 'shared/utils/files';
import { SliderFormValue } from '../types';

export const useImageFormField = (params: {
  control: Control<SliderFormValue>;
  keyData: 'imageDesktopData' | 'imageMobileData';
  keyUrl: 'imageDesktopUrl' | 'imageMobileUrl';
  keyAction: 'imageDesktopDataAction' | 'imageMobileDataAction';
  setValue: UseFormSetValue<SliderFormValue>;
}) => {
  const { control, keyAction, keyData, keyUrl, setValue } = params;
  const imageDataValue = useWatch({ control, name: keyData });
  const imageUrlValue = useWatch({ control, name: keyUrl });

  const onChangeInputImage = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.files) {
        const formFiles = await fileListToArrayBase64(e.currentTarget.files);

        setValue(keyData, formFiles[0], { shouldValidate: true });
        setValue(keyAction, CoreSystemEnumsObjectActions.Insert);
        setValue(keyUrl, null);
      }
    },
    [setValue]
  );

  return {
    imageDataValue,
    imageUrlValue,
    onChangeInputImage,
  };
};
