import {
  VisitingChecklistCompetitorValueStageTwoRequest,
  VisitingChecklistStageTwoRequest,
} from 'shared/api/visit/types';

import {
  CompetitiveAdvantagesFormBlockItemI,
  CompetitiveAdvantagesFormI,
} from '../type';

type PrepareDataToFetchStage3Type = (
  data: CompetitiveAdvantagesFormI
) => VisitingChecklistStageTwoRequest;
export const prepareDataToFetchStage3: PrepareDataToFetchStage3Type = (
  data
) => {
  const allItems: CompetitiveAdvantagesFormBlockItemI[] = [];
  const forComp = data.blocks.map((el) => el.blockItems);

  forComp.forEach((el) => {
    allItems.push(...el);
  });

  const competitorValues: VisitingChecklistCompetitorValueStageTwoRequest[] =
    [];

  allItems.forEach((el) => {
    el.competitorsValue.forEach((el2) => {
      competitorValues.push({
        competitorItemId: el.itemId,
        competitorId: el2.competitorId,
        competitorValue: el2.value || (null as unknown as undefined),
      });
    });
  });

  return {
    competitorBlocks: data.blocks.map((el) => ({
      competitorBlockId: el.blockId,
      competitorItems: el.blockItems.map((el2) => ({
        competitorItemId: el2.itemId,
        value: el2.myValue || (null as unknown as undefined),
        comment: el2.comment || (null as unknown as undefined),
      })),
    })),
    competitorValues: competitorValues,
  };
};
