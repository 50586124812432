import { Grid, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { theme } from 'shared/theme/theme';

export const UiDocumentFormFiles: FC<{
  Input: ReactNode;
  List: ReactNode[] | ReactNode;
}> = ({ Input, List }) => {
  return (
    <>
      <Typography
        sx={{
          display: 'none',
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            backgroundColor: theme.palette.customGrey.main,
            /* position: "relative", */
            padding: '0px 8px',
            /*  left: "-8px", */
            //width: '100%',
            flexBasis: '100%',
          },
        }}
      >
        Файл
      </Typography>
      <Grid
        container
        alignItems='center'
        item
        flexBasis={['100%', '45%']}
        padding={['0px 8px', '0px 0px']}
      >
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            '& > label': { height: 40, width: 180 },
            /*  marginTop: [1, 2],
            marginBottom: [1, 2], */
          }}
        >
          {Input}
        </Grid>
        <Grid
          item
          container
          xs={12}
          lg={8}
        >
          {List}
        </Grid>
      </Grid>
    </>
  );
};
