import { Typography } from '@mui/material';
import { FC } from 'react';

import { formatingDate } from 'shared/utils/formating-date';

export const DocumentDateCreatedUi: FC<{ dateCreated?: string }> = ({
  dateCreated,
}) => {
  return (
    <Typography sx={{ minWidth: ['auto', '150px'] }}>
      {formatingDate(dateCreated)}
    </Typography>
  );
};
