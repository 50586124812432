import { Archive, Delete, Edit, Person } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { BoardListItemCard } from 'features/boards/BoardListItemCard/BoardListItemCard';
import { BoardListPagination } from 'features/boards/BoardListPagination';
import { BoardListSearchBar } from 'features/boards/BoardListSearchBar';
import { BoardListSkeleton } from 'features/boards/BoardListSkeleton';
import { UpdateBoardTitleDialog } from 'features/boards/dialogs/UpdateBoardTitleDialog/UpdateBoardTitleDialog';
import { AddEmployeeToBoardFormFields } from 'features/boards/forms/AddEmployeeToBoardForm';
import { UpdateBoardTitleFormFields } from 'features/boards/forms/UpdateBoardTitleForm';
import { useBoardNavigation } from 'features/boards/hooks/useBoardNavigation';
import { useUpdateBoardMutation } from 'shared/api/services/rtk/boardApi';
import { GetListBoard } from 'shared/api/services/types';
import { useConfirm } from 'shared/confirm/ConfirmContext';
import { useViewModal } from 'shared/hooks/useViewModal';
import { useViewModalWithInitFields } from 'shared/hooks/useViewModalWithInitFields';
import { theme } from 'shared/theme/theme';
import { LayoutPage } from 'shared/ui/layouts/layout-page';
import { AddEmployeeToBoardModal } from 'widgets/boards/AddEmployeeToBoardModal';
import { CreateBoardModal } from 'widgets/boards/CreateBoardModal';
import { useBoardListPage } from './hooks/useBoardListPage';
import { useQueryBoardList } from './hooks/useQueryBoardList';

export const MyBoardsPage = () => {
  const openConfirm = useConfirm();
  const { changeLimit, changePage, query, toggleArchive, changeSearch } =
    useQueryBoardList();
  const { navigateToBoard } = useBoardNavigation();
  const { archiveBoard, boardList, deleteBoard, isLoadingData } =
    useBoardListPage(query);
  const [updateBoardTitle, { isLoading: isUpdatingBoardTitle }] =
    useUpdateBoardMutation();

  const { close, open, isOpen } = useViewModal();

  const handleDelete = (guid: string) => {
    openConfirm({
      actionTitle: 'Удалить доску?',
      submitButtonTitle: 'Удалить',
      actionBody: (
        <Typography color={'GrayText'}>
          Все задачи доски будут{' '}
          <u>
            <b>безвозвратно</b>
          </u>{' '}
          удалены
        </Typography>
      ),
      buttonColor: 'error',
      onSubmit: async () => {
        await deleteBoard(guid);
      },
    });
  };

  const handleArchive = (guid: string, isArchive: boolean) => {
    archiveBoard({
      boardGuid: guid,
      isArchive: isArchive,
    });
  };

  const handleUpdateBoardTitle = async (form: UpdateBoardTitleFormFields) => {
    await updateBoardTitle({
      boardGuid: form.boardGuid,
      requestBody: { title: form.title },
    });

    closeUpdateTitle();
  };

  const {
    open: openAdd,
    close: closeAdd,
    isOpen: isOpenAdd,
    initValue: initValueAdd,
  } = useViewModalWithInitFields<{
    boardGuid: string;
    initValue: AddEmployeeToBoardFormFields;
  }>();

  const {
    close: closeUpdateTitle,
    initValue: initUpdateTitle,
    isOpen: isOpenUpdateTitle,
    open: openUpdateTitle,
  } = useViewModalWithInitFields<UpdateBoardTitleFormFields>();

  const getBoardOptions = (board: GetListBoard) => {
    return [
      {
        disabled: !board.canEdit,
        title: 'Изменить название',
        icon: <Edit />,
        onClick: () => {
          openUpdateTitle({ boardGuid: board.guid, title: board.title });
        },
      },
      {
        disabled: !board.canEdit,
        title: 'Участники',
        icon: <Person />,
        onClick: () => {
          openAdd({
            boardGuid: board.guid,
            initValue: { employees: board.employees },
          });
        },
      },
      {
        disabled: !board.canEdit,
        title: board.isArchive ? 'Восстановить' : 'Добавить в архив',
        icon: <Archive />,
        onClick: () => {
          handleArchive(board.guid, !board.isArchive);
        },
      },
      {
        disabled: !board.canEdit,
        title: 'Удалить',
        icon: <Delete />,
        onClick: () => {
          handleDelete(board.guid);
        },
      },
    ];
  };

  return (
    <LayoutPage.Page>
      <CreateBoardModal
        isOpen={isOpen}
        onClose={close}
        onCreate={close}
      />
      <AddEmployeeToBoardModal
        isOpen={isOpenAdd}
        onClose={closeAdd}
        initValue={initValueAdd?.initValue}
        boardGuid={initValueAdd?.boardGuid || ''}
        onUpdate={closeAdd}
      />
      <UpdateBoardTitleDialog
        isOpen={isOpenUpdateTitle}
        onClose={closeUpdateTitle}
        initValue={initUpdateTitle}
        onSubmit={handleUpdateBoardTitle}
        isLoading={isUpdatingBoardTitle}
      />
      <LayoutPage.Bar>
        <BoardListSearchBar
          isArchiveChecked={query.IsArchive}
          onPressCreate={open}
          onToggleIsArchive={toggleArchive}
          onSearchApply={changeSearch}
        />
      </LayoutPage.Bar>

      <LayoutPage.Content>
        <Grid
          sx={{ height: 'calc(100vh - 230px)' }}
          container
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          <Grid sx={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}>
            <Grid sx={LIST_CONTAINER_SX}>
              {isLoadingData ? (
                <BoardListSkeleton />
              ) : (
                boardList?.items.map((board) => (
                  <BoardListItemCard
                    menuOptions={getBoardOptions(board)}
                    key={board.guid}
                    board={board}
                    onClick={navigateToBoard}
                  />
                ))
              )}
            </Grid>
          </Grid>

          <BoardListPagination
            count={boardList?.count || 0}
            limit={query.Limit}
            page={query.Page}
            onChangeLimit={changeLimit}
            onChangePage={changePage}
          />
        </Grid>
      </LayoutPage.Content>
    </LayoutPage.Page>
  );
};

const LIST_CONTAINER_SX = {
  display: 'grid',
  m: 1,
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridGap: 8,
  [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
    gridTemplateColumns: '1fr',
  },
};
