import { SxProps, Theme } from '@mui/material';
import { useMemo } from 'react';
import { createStylesTriangle } from './create-styles-triangle';
import {
  PopperTriangleCreateStyleProps,
  useStylesPopperWithTringleParams,
} from './types';

const createSelectorTriangle = ({
  keyPosition,
  classNameTriangle,
}: Pick<useStylesPopperWithTringleParams, 'classNameTriangle'> &
  Pick<PopperTriangleCreateStyleProps, 'keyPosition'>) =>
  `&[data-popper-placement="${keyPosition}"] > .MuiPaper-root > .${classNameTriangle}`;

export const useStylesPopperWithTringle = ({
  dataAtrrForHidenArrow,
  squareWidth,
  percentOfsquareWidth,
  classNameTriangle,
}: useStylesPopperWithTringleParams) => {
  const popperStyle: SxProps<Theme> = useMemo(() => {
    return {
      zIndex: 3,
      [createSelectorTriangle({ keyPosition: 'bottom', classNameTriangle })]: {
        top: 0,
        left: 0,
        ...createStylesTriangle({
          keyPosition: 'bottom',
          deg: 45,
          squareWidth,
          percentOfsquareWidth,
          dataAtrrForHidenArrow,
        }),
      },
      [createSelectorTriangle({ keyPosition: 'top', classNameTriangle })]: {
        bottom: 0,
        left: 0,
        ...createStylesTriangle({
          keyPosition: 'top',
          deg: 225,
          squareWidth,
          percentOfsquareWidth,
          dataAtrrForHidenArrow,
        }),
      },
      [createSelectorTriangle({ keyPosition: 'right', classNameTriangle })]: {
        left: 0,
        ...createStylesTriangle({
          keyPosition: 'right',
          deg: 315,
          squareWidth,
          percentOfsquareWidth,
          dataAtrrForHidenArrow,
        }),
      },
      [createSelectorTriangle({ keyPosition: 'left', classNameTriangle })]: {
        right: 0,
        ...createStylesTriangle({
          keyPosition: 'left',
          deg: 135,
          squareWidth,
          percentOfsquareWidth,
          dataAtrrForHidenArrow,
        }),
      },
    };
  }, [squareWidth, percentOfsquareWidth, dataAtrrForHidenArrow]);

  const triangleStyle: SxProps<Theme> = useMemo(() => {
    return {
      width: squareWidth,
      height: squareWidth,
      '&::before': {
        content: '""',
        position: 'relative',
        margin: 'auto',
        display: 'block',
        width: squareWidth,
        height: squareWidth,
        backgroundColor: 'white',
        borderRadius: '1px',
        boxShadow: '-1px -1px 1px 0px rgba(0, 0, 0, 0.06)',
        transitionDuration: '0.2s',
        transitionProperty: 'transform',
      },
    };
  }, [squareWidth]);

  return { popperStyle, triangleStyle };
};
